import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import {
  Avatar,
  DropdownCard,
  EnumDropdownItemVariant,
  Icon,
  IDropdownItem,
  InputCheckbox,
  InputCheckboxProps,
  Typography,
} from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import { integrationsStore } from 'entities/Integrations'
import styles from './styles.module.scss'

type IContactDropdownCardProps = {
  item: Contact
  InputCheckboxProps?: InputCheckboxProps
  onChange?: (item: Contact) => void
  highlighter?: string
  showIconIntegration?: boolean
  ariaLabel?: string
}

export const ContactDropdownCard: FC<IContactDropdownCardProps> = observer(
  ({ item, onChange, InputCheckboxProps, showIconIntegration, highlighter, ariaLabel }) => {
    const name = getLabelAsNumberInternationalFormat(item.name)
    const number =
      (item.numberInternationalFormat &&
        getLabelAsNumberInternationalFormat(item.numberInternationalFormat)) ??
      item.phoneInfo

    const renderContent = (isCheckbox: boolean) => {
      return (
        <div
          className={classnames(styles.wrap, {
            [styles['wrap--checkbox']]: isCheckbox,
          })}
        >
          <div className={styles.avatar}>
            <Avatar info={item.avatarInfo} />
          </div>
          <div className={styles.box}>
            <div className={styles.info}>
              <Typography
                ellipsis
                ariaLabel={'title'}
                variant={'body-md-medium'}
                className={styles.title}
              >
                {highlighter ? (
                  <Highlighter
                    highlightClassName={classnames(
                      styles.highlighter,
                      styles['highlighter--label']
                    )}
                    className={styles.name}
                    searchWords={highlighter.split(' ')}
                    autoEscape={true}
                    textToHighlight={name}
                  />
                ) : (
                  name
                )}
              </Typography>
              <Typography
                ellipsis
                ariaLabel={'phoneInfo'}
                variant={'body-xs-regular'}
                className={styles.sub}
              >
                {highlighter ? (
                  <Highlighter
                    highlightClassName={styles.highlighter}
                    searchWords={highlighter.split(' ')}
                    autoEscape={true}
                    textToHighlight={number || ''}
                  />
                ) : (
                  number
                )}
              </Typography>
            </div>
            <div className={styles.emailInfo}>
              <Typography
                ellipsis
                ariaLabel={'email'}
                variant={'body-xs-regular'}
                className={styles.sub}
              >
                {highlighter ? (
                  <Highlighter
                    highlightClassName={styles.highlighter}
                    searchWords={highlighter.split(' ')}
                    autoEscape={true}
                    textToHighlight={item.email || ''}
                  />
                ) : (
                  item.email
                )}
              </Typography>
              {showIconIntegration && (
                <Icon
                  icon={
                    integrationsStore.getIntegration(item.integration_key)?.icon || 'salesmessage'
                  }
                />
              )}
            </div>
          </div>
        </div>
      )
    }
    const dropdown: IDropdownItem = {
      id: item.id,
      label: item.name,
      variant: EnumDropdownItemVariant.Custom,
      renderOption: () => {
        return renderContent(false)
      },
      className: styles.dropdown,
    }

    if (InputCheckboxProps) {
      return (
        <InputCheckbox
          className={styles.checkbox}
          {...InputCheckboxProps}
          customLabel={renderContent(true)}
        />
      )
    }

    return (
      <DropdownCard
        ariaLabel={ariaLabel}
        item={dropdown}
        onChange={() => {
          if (onChange) onChange(item)
        }}
      />
    )
  }
)
