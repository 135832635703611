import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react'
import { CompliancePageStore } from 'pages/settings/pages/compliance/store/compliancePageStore'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

const CompliancePageContext = createContext<CompliancePageStore | null>(null)

export const useCompliancePageContext = () => {
  const context = useContext(CompliancePageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CompliancePageProvider')

  return context
}

export const CompliancePageProvider = ({
  complianceLayerStore,
  children,
}: {
  complianceLayerStore: ComplianceLayerStore
  children: ReactNode
}) => {
  const store = useMemo(() => new CompliancePageStore(complianceLayerStore), [])

  useEffect(() => store.dispose, [])

  return <CompliancePageContext.Provider value={store}>{children}</CompliancePageContext.Provider>
}
