import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { TrialQuestionnaireAlert } from 'entities/Subscription/ui/TrialQuestionnaireAlert'
import { TrialTenLimitConversationsAlert } from 'entities/Subscription/ui/TrialTenLimitConversationsAlert'
import { conversationStore } from 'entities/Conversation'
import { AlertAddCredits, AlertAddTrialCredits, AlertMaxSegments } from 'widgets/Reminders'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { ConversationInboxAlert } from 'widgets/ConversationMessages/ui/ConversationMessageFieldAlerts/ConversationInboxAlert/ConversationInboxAlert'
import { ConversationChatbotAlerts } from './ConversationChatbotAlert'
import { ConversationAlerts } from './ConversationAlerts/ConversationAlerts'
import { ConversationOptOutAlert } from './ConversationOptOutAlert/ConversationOptOutAlert'
import { ConversationBlockedContactAlert } from './ConversationBlockedContactAlert/ConversationBlockedContactAlert'

export const ConversationMessageFieldAlerts = observer(() => {
  const store = useConversationMessagesContext()
  const { isOnboardingFlow, init, loading } = store.conversationMessageFieldAccessStore

  useEffect(() => {
    init()
  }, [])

  if (loading) return null
  if (!store.conversation) return null

  if (isOnboardingFlow) {
    return (
      <>
        <TrialQuestionnaireAlert conversation={store.conversation} />

        <ConversationInboxAlert />

        {store.enableTrialAlertLimitConversations && (
          <TrialTenLimitConversationsAlert
            totalAmount={conversationStore.itemsLimitTrialCount}
            onCloseTrialAlert={store.setHideTrialAlertNotForOwner}
          />
        )}

        <ConversationOptOutAlert />
        <ConversationAlerts />
        <ConversationBlockedContactAlert />

        <AlertAddCredits />
        <AlertMaxSegments />

        <ConversationChatbotAlerts conversation={store.conversation} />
      </>
    )
  }

  return (
    <>
      <ConversationInboxAlert />

      {store.enableTrialAlertLimitConversations && (
        <TrialTenLimitConversationsAlert
          totalAmount={conversationStore.itemsLimitTrialCount}
          onCloseTrialAlert={store.setHideTrialAlertNotForOwner}
        />
      )}

      <ConversationOptOutAlert />
      <ConversationAlerts />
      <ConversationBlockedContactAlert />

      <AlertAddCredits />
      <AlertMaxSegments />
      <AlertAddTrialCredits />

      <ConversationChatbotAlerts conversation={store.conversation} />
    </>
  )
})
