import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'

export const getPowerDialerSessionV2 = () => {
  return new Promise<false | number>((resolve) => {
    const cache = sessionStorage.getItem('power_dialer_id')

    const id = Number(cache)

    if (!Number.isInteger(id)) {
      return resolve(false)
    }

    resolve(id)
  })
}

export class CallPopUpPowerDialerSessionStoreV2 {
  private _powerDialerId: number | null = null
  private _disposeSyncCache: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this.reactionSyncCache()
  }

  reset = () => {
    this._disposeSyncCache?.()
    this._powerDialerId = null
    sessionStorage.removeItem('power_dialer_id')
  }

  setPowerDialerId = (id: number) => {
    this._powerDialerId = id
  }

  reactionSyncCache = () => {
    this._disposeSyncCache?.()
    this._disposeSyncCache = reaction(
      () => this._powerDialerId,
      (powerDialerId) => {
        if (powerDialerId) {
          sessionStorage.setItem('power_dialer_id', powerDialerId.toString())
        } else {
          sessionStorage.removeItem('power_dialer_id')
        }
      },
      {
        fireImmediately: true,
      }
    )
  }
}
