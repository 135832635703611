import { InternalAxiosRequestConfig, AxiosError } from 'axios'
import { API_URL_MICROSERVICE, LIST_MICROSERVICES, LOGGER_BASE_URL } from 'shared/config'
import { setTokenHeaderFromViaConfig } from 'entities/Auth/lib/setToken'

function isUrlIncludeKeyWord(url: string | undefined, keywords: string[]) {
  if (!url) {
    return false
  }
  const escapedKeywords = keywords.map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
  return RegExp(`(?:${escapedKeywords.join('|')})/`, 'i').test(url)
}

const mutationRequestConfig = (axiosConfig: InternalAxiosRequestConfig) => {
  setTokenHeaderFromViaConfig(axiosConfig)

  const is_logger = axiosConfig?.url?.includes('logger/v1/logs') && LOGGER_BASE_URL
  const is_microservice = isUrlIncludeKeyWord(axiosConfig?.url, LIST_MICROSERVICES)

  axiosConfig.headers['External-Referer'] = window.location.href

  if (is_microservice && !is_logger) {
    axiosConfig.baseURL = API_URL_MICROSERVICE
  }

  if (is_logger) {
    axiosConfig.baseURL = LOGGER_BASE_URL
  }

  return axiosConfig
}

export const interceptorsRequestSuccess = (config: InternalAxiosRequestConfig) => {
  return mutationRequestConfig(config)
}

export const interceptorsRequestError = (error: AxiosError) => {
  return Promise.reject(error)
}
