import { makeAutoObservable } from 'mobx'
import { type IGlobalAlert } from 'shared/ui/Banner'

class BannerStore {
  private _itemsMap: Map<string, IGlobalAlert> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  add = (item: IGlobalAlert) => {
    this._itemsMap.set(item.id, item)
  }

  remove = (id: string) => {
    this._itemsMap.delete(id)
  }

  removeAll = () => {
    this._itemsMap.clear()
  }

  dispose = () => {
    this.clearLocalStorage()
    this.removeAll()
  }

  clearLocalStorage = () => {
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i)

      if (!key) return
      if (/^alert_banner_hide(.+)/.test(key)) {
        window.localStorage.removeItem(key)
      }
    }
  }
}

export const bannerStore = new BannerStore()
