import { observer } from 'mobx-react-lite'
import React from 'react'
import { SendFromDropdown, type SendFromDropdownStore } from 'widgets/SendFromDropdown'

type KeywordsNumbersProps = {
  store: SendFromDropdownStore
}

export const KeywordsNumbers = observer(({ store }: KeywordsNumbersProps) => (
  <SendFromDropdown
    store={store}
    label='Text in numbers'
    questionTooltipProps={{
      label: 'The phone number(s) the contacts will text into to join your keyword.',
      width: 190,
      breakWord: true,
    }}
  />
))
