import { uiStore } from 'shared/store/uiStore'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { usersStore } from 'entities/Users'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'

export const usePowerDialerAvailability = () => {
  const inbox = inboxesStore.currentInbox
  const sharedInboxes = inboxesStore.sharedInboxes
  const sharedInbox = inbox?.type === 'inbox' ? inbox : null

  const countInActiveOutboundCallsInboxes = sharedInboxes.filter(
    (item) => !item.isNumberOutboundCalls
  )
  const countAircallInboxes = sharedInboxes.filter(
    (item) => item.isCallViaAircall || numbersStore.getItem(item.numberId)?.is_aircall
  )
  const hasAllAircall = countAircallInboxes.length === sharedInboxes.length
  const hasAllInActiveOutboundCalls =
    countInActiveOutboundCallsInboxes.length === sharedInboxes.length
  const hasCurrentInActiveOutboundCalls = !sharedInbox?.isNumberOutboundCalls

  const hideButton = Boolean(usersStore.user?.isViewOnlyRole || hasAllAircall)

  const isCallOutboundDisabled = sharedInbox
    ? hasCurrentInActiveOutboundCalls
    : hasAllInActiveOutboundCalls
  const disabled =
    isCallOutboundDisabled ||
    callPopUpGlobalStore.hasPowerDialerV2 ||
    !featureFlagsStore.inteliquent_calling

  const getTooltipLabel = () => {
    if (isCallOutboundDisabled) {
      if (
        usersStore.user?.isAdminRole ||
        usersStore.user?.isOwnerRole ||
        (inbox?.type === 'inbox' && inbox?.ownerId === usersStore.user?.id)
      ) {
        return (
          <>
            Outbound calling is disabled
            <br />
            <button
              onClick={(event) => {
                event.preventDefault()

                uiStore.changeRoute({
                  path: `/settings/organization/inboxes/all-inboxes/${inbox?.id}/calling`,
                  type: 'vue',
                })
              }}
            >
              Enable it here
            </button>
          </>
        )
      }

      return 'Outbound calling is disabled. Contact your admin to enable it.'
    }

    return ''
  }

  return {
    hideButton,
    isCallOutboundDisabled,
    disabled,
    getTooltipLabel,
  }
}
