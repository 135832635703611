import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { CampaignSettingsUi } from '../ui/CampaignSettingsUi'

export class CampaignSettingsStore {
  constructor() {
    makeAutoObservable(this)
  }
  modalId = nanoid()
  isUnEnroll = false
  isReEnter = false

  get params() {
    return {
      unenroll_on_response: this.isUnEnroll,
      allow_re_entering: this.isReEnter,
    }
  }

  openModal = () => {
    modalStore.addModal({
      id: this.modalId,
      width: 540,
      position: 'right',
      type: ModalTypeList.DEFAULT,
      disabledOverlayClose: true,
      pureContent: true,
      title: 'Settings',
      fullHeight: true,
      ModalContent: () => <CampaignSettingsUi store={this} />,
    })
  }

  onSave = ({ isUnEnroll, isReEnter }: { isUnEnroll: boolean; isReEnter: boolean }) => {
    if (isUnEnroll !== this.isUnEnroll) {
      toastStore.add({
        type: 'info',
        title: `Contacts will ${isUnEnroll ? '' : 'not'} be unenrolled upon reply`,
      })
    }
    if (isReEnter !== this.isReEnter) {
      toastStore.add({
        type: 'info',
        title: `Contacts can ${isReEnter ? '' : 'not'} be allowed to re-enter campaign`,
      })
    }
    this.isUnEnroll = isUnEnroll
    this.isReEnter = isReEnter

    modalStore.closeModal(this.modalId)
  }

  onCancel = () => {
    modalStore.closeModal(this.modalId)
  }
}
