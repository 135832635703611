// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VgTKwcE40jLknlytnOH6{display:flex;flex-grow:1;flex-direction:column}.F2Zun8RuTFuiRlUGxXk1{display:flex;flex-grow:1;flex-direction:column}.rRlCtuadfEv5qlIuKZhN{display:flex;padding:16px 24px 24px 24px;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignSettings/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,WAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,2BAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n}\n\n.content {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n}\n\n.actions {\n  display: flex;\n  padding: 16px 24px 24px 24px;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "VgTKwcE40jLknlytnOH6",
	"content": "F2Zun8RuTFuiRlUGxXk1",
	"actions": "rRlCtuadfEv5qlIuKZhN"
};
export default ___CSS_LOADER_EXPORT___;
