import { ChannelOrganization } from 'entities/WebSocket'
import { type OrganizationStore } from 'entities/Organization/store/organization'

class ChannelsOrganization {
  constructor() {}

  subscribeChannels = (store: OrganizationStore) => {
    if (!store.id) return

    new ChannelOrganization({
      id: store.id,
    })
  }
}

export const channelsOrganization = new ChannelsOrganization()
