import { observer } from 'mobx-react-lite'
import { Status } from 'shared/ui'
import { KnowledgeBaseDocument } from 'entities/KnowledgeBase'
import { getKnowledgeBaseDocumentStatusConfig } from 'widgets/KnowledgeBase/lib/getKnowledgeBaseDocumentStatusConfig'

interface KnowledgeBaseDocumentStatusLabelProps {
  document: KnowledgeBaseDocument
}

export const KnowledgeBaseDocumentStatusLabel = observer(
  ({ document }: KnowledgeBaseDocumentStatusLabelProps) => {
    const config = getKnowledgeBaseDocumentStatusConfig(document.status)

    if (!config) return null

    return <Status {...config} />
  }
)
