import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  KnowledgeBaseViewProvider,
  useKnowledgeBaseViewContext,
} from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'
import { KnowledgeBase } from 'pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/KnowledgeBase'
import { useKnowledgeBaseViewEventDocumentUpload } from 'pages/settings/pages/knowledgeBase/events/useKnowledgeBaseViewDocumentUpload'

export const KnowledgeBaseView = observer(() => {
  const store = useKnowledgeBaseViewContext()
  const { loadKnowledgeBaseViewInitialData } = store

  useKnowledgeBaseViewEventDocumentUpload(store)
  useEffect(() => {
    void loadKnowledgeBaseViewInitialData()
  }, [])

  return <KnowledgeBase />
})

export const KnowledgeBaseViewPage = observer(() => (
  <KnowledgeBaseViewProvider>
    <KnowledgeBaseView />
  </KnowledgeBaseViewProvider>
))
