import { makeAutoObservable } from 'mobx'
import type {
  IResponseComplianceServicesCampaign,
  IResponseGetComplianceServicesList,
} from 'entities/Compliance'
import { ComplianceServiceBrand } from 'entities/Compliance/model/ComplianceServicesList/ComplianceServiceBrand'
import { ComplianceServiceCampaign } from 'entities/Compliance/model/ComplianceServicesList/ComplianceServiceCampaign'

export class ComplianceServicesList {
  private _campaignsMap: Map<number, ComplianceServiceCampaign> = new Map()
  private _brand: ComplianceServiceBrand | null = null

  constructor(response: IResponseGetComplianceServicesList) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseGetComplianceServicesList) => {
    this.syncCampaigns(response.campaigns)

    this._brand = response.brand ? new ComplianceServiceBrand(response.brand) : null
  }

  syncCampaigns = (response: IResponseGetComplianceServicesList['campaigns']) => {
    response.forEach((res) => {
      const item = this._campaignsMap.get(res.id)

      if (item) {
        item.syncData(res)
      } else {
        this._campaignsMap.set(res.id, new ComplianceServiceCampaign(res))
      }
    })
  }

  syncCampaign = (response: IResponseComplianceServicesCampaign) => {
    const item = this._campaignsMap.get(response.id)

    if (item) {
      item.syncData(response)
    } else {
      this._campaignsMap.set(response.id, new ComplianceServiceCampaign(response))
    }
  }

  get campaigns() {
    return Array.from(this._campaignsMap.values())
  }

  get campaign() {
    if (!this.campaigns[0]) return null

    return this.campaigns[0] as ComplianceServiceCampaign
  }

  get brand() {
    return this._brand
  }

  get isEmpty() {
    return Boolean(!this.brand || !this.campaign)
  }

  get isStatusInProgress() {
    return Boolean(this._brand?.isStatusInProgress || this.campaign?.isStatusInProgress)
  }

  get isStatusPendingReview() {
    return Boolean(this._brand?.isStatusPendingReview || this.campaign?.isStatusPendingReview)
  }

  get isStatusInCarrierReview() {
    return Boolean(this._brand?.isStatusInCarrierReview || this.campaign?.isStatusInCarrierReview)
  }

  get isStatusApproved() {
    return Boolean(this._brand?.isStatusApproved && this.campaign?.isStatusApproved)
  }

  get isStatusFailed() {
    return Boolean(this._brand?.isStatusFailed || this.campaign?.isStatusFailed)
  }
}
