import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import { Alert, Button, ContainerHeader } from 'shared/ui'
import { type PageLayoutStore } from 'shared/layout'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { contactsHistoryStore } from '../store/ContactsHistoryStore'
import { ContactHistoryTable } from './ContactHistoryTable'
import styles from './styles.module.scss'

type IContactsHistoryProps = {
  pageLayoutStore: PageLayoutStore
}

export const ContactsHistory: FC<IContactsHistoryProps> = observer(({ pageLayoutStore }) => {
  const {
    totalHistory,
    pendingId,
    loadImportHistory,
    getImportContactProgress,
    checkIsExistHistory,
    contactsTagsModalStore,
  } = contactsHistoryStore
  const { isImportAllowed, startImportContact, getStatusImportContact, checkIsImportDisabled } =
    importContactsStore
  const { selectedListIds } = contactsTagsModalStore

  useEffect(() => {
    loadImportHistory()
    !isImportAllowed && getStatusImportContact()
  }, [])

  useEffect(() => {
    if (isImportAllowed && pendingId && !checkIsExistHistory(pendingId)) {
      startImportContact(pendingId, selectedListIds, loadImportHistory, getImportContactProgress)
    }
  }, [isImportAllowed])

  const onImport = () => {
    importContactsStore.openModal()
  }

  const checkImportDisabling = () => {
    return checkIsImportDisabled(totalHistory)
  }

  return (
    <div className={styles.historyContainer}>
      <Breadcrumbs />
      <ContainerHeader
        title='Import history'
        titleVariant={'heading-md'}
        titleIconProps={{
          icon: 'lineCollapseExpandLeft',
          fontSize: 20,
          color: 'var(--content-primary-tertiary)',
        }}
        titleIconAction={pageLayoutStore.handleToggleCollapse}
        actions={[
          {
            iconButtonComponent: (
              <Button
                text='Import'
                icon='upload'
                contained='tertiary'
                disabled={checkImportDisabling()}
                onClick={onImport}
              />
            ),
          },
        ]}
        paddingLeft={20}
      />

      {isImportAllowed === false && totalHistory > 0 && (
        <div className={styles.alertContainer}>
          <Alert
            item={{
              type: 'infoBlue',
              title:
                'Your import is under review, once processed you will be able to add more contacts',
            }}
          />
        </div>
      )}
      <ContactHistoryTable />
    </div>
  )
})
