import { observer } from 'mobx-react-lite'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import { RegisterSection } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import styles from './styles.module.scss'

export const CallingPage = observer(() => {
  const { callingPageStore } = useCompliancePageContext()

  return (
    <div className={styles.wrap}>
      <RegisterSection
        label={'Add-on'}
        type={'transparent'}
        titleType={'body-lg-medium'}
        {...callingPageStore.callingHeaderSectionProps}
      />
      <RegisterSection {...callingPageStore.callingVoiceIntegrityRegisterSectionProps} />
      <RegisterSection {...callingPageStore.callingStirShakenRegisterSectionProps} />
      <RegisterSection {...callingPageStore.callingCnamCallerIdRegisterSectionProps} />
    </div>
  )
})
