import { createContext, useContext } from 'react'
import { type BroadcastsPageStore } from 'pages/broadcasts/store/broadcastsPageStore'

export const BroadcastPageContext = createContext<BroadcastsPageStore | null>(null)

export const useBroadcastPageContext = () => {
  const context = useContext(BroadcastPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with BroadcastPageContext')

  return context
}
