import { makeAutoObservable, runInAction, reaction, type IReactionDisposer } from 'mobx'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { numbersStore } from 'entities/Phone'
import { organizationStore } from 'entities/Organization'
import { IntegrationsApi } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { ConnectTwilioModalActions } from 'entities/Phone/ui/ConnectTwilioModal/ui/ConnectTwilioModalActions'
import { ConnectTwilioModalContent } from 'entities/Phone/ui/ConnectTwilioModal/ui/ConnectTwilioModalContent'

export class ConnectTwilioModalStore {
  private _idModal = 'connectTwilioModal'
  private _accountSid = ''
  private _isPrefilled = false
  private _authToken = ''
  private _error: string | null = null
  private _loading = false
  private _disposeInit: IReactionDisposer | null = null
  private _disposeChange: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get isPrefilled() {
    return this._isPrefilled
  }

  get error() {
    return this._error
  }

  get accountSid() {
    return this._accountSid
  }

  get authToken() {
    return this._authToken
  }

  get loading() {
    return this._loading
  }

  get hasError() {
    return Boolean(this._error)
  }

  get disabled() {
    return !this._accountSid || !this._authToken || this.hasError
  }

  setAccountSid = (accountSid: string) => {
    this._accountSid = accountSid
  }

  setAuthToken = (authToken: string) => {
    this._authToken = authToken
  }

  init = async () => {
    this.reactionInit()
    this.reactionChange()

    const accountSid = new URLSearchParams(window.location.search).get('accountSid')
    if (accountSid) {
      this._accountSid = accountSid
      this._isPrefilled = true
    }

    await Promise.all([
      usersStore.initPromise,
      organizationStore.initPromise,
      numbersStore.initFetchOrganizationNumberPromise,
      numbersStore.initFetchNumberVendorIntegrationsPromise,
    ])

    if (organizationStore.isTwilioNumberVendor && !numbersStore.hasTwilioNumberVendorIntegration) {
      modalStore.addModal({
        id: this._idModal,
        showCloseIcon: false,
        showCloseButton: false,
        disabledOnAllClose: true,
        disabledOverlayClose: true,
        disabledOnEscClose: true,
        title: 'Twilio integration',
        paddingContent: '8px 24px 0 24px',
        width: 480,
        ModalContentProps: {
          store: this,
        },
        ModalActions: ConnectTwilioModalActions,
        ModalContent: ConnectTwilioModalContent,
        onClose: this.closeModal,
      })
    } else {
      this.reset()
      this.closeModal()
    }
  }

  closeModal = () => {
    this.reset()
    modalStore.removeModal(this._idModal)
  }

  reset = () => {
    this._authToken = ''
    this._accountSid = ''
    this._error = null
    this._loading = false
    this._disposeInit?.()
    this._disposeChange?.()
  }

  handleSubmit = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      await IntegrationsApi.connectTwilio({
        account_sid: this._accountSid,
        auth_token: this._authToken,
      })

      await window.publicApiVue?.fetchMe()
      await Promise.all([organizationStore.init(), numbersStore.fetchNumberVendorIntegrations()])

      this.closeModal()
      uiStore.changeRoute({
        path: '/settings/organization/numbers',
        query: 'unassigned',
        type: 'vue',
      })
    } catch (e) {
      console.error(e)
      if (e instanceof AxiosError) {
        this._error = e.response?.data?.error || 'Something went wrong, please try again.'
      }
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  reactionInit = () => {
    this._disposeInit?.()
    this._disposeInit = reaction(
      () => [
        organizationStore.isTwilioNumberVendor,
        numbersStore.hasTwilioNumberVendorIntegration,
        numbersStore.loading,
        organizationStore.loading,
      ],
      () => {
        this.init()
      }
    )
  }

  reactionChange = () => {
    this._disposeChange?.()
    this._disposeChange = reaction(
      () => [this._accountSid, this._authToken],
      () => {
        this._error = null
      }
    )
  }
}
