import { runInAction } from 'mobx'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { IResponseUserIntegrationDisconnected, integrationsStore } from 'entities/Integrations'

const IntegrationDisconnected = {
  handle: (data: IResponseUserIntegrationDisconnected) => {
    runInAction(() => {
      integrationsStore.disconnectUserIntegration(data.userIntegration)
    })
  },
}

export const EventIntegrationDisconnected = {
  subscribe: () =>
    websocket.on(WebsocketEvent.IntegrationDisconnected, IntegrationDisconnected.handle),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.IntegrationDisconnected, IntegrationDisconnected.handle),
}
