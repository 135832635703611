import React from 'react'
import { SpinnerLoader } from 'shared/ui/Loader/SpinnerLoader'

import styles from './styles.module.scss'

type ILoaderProps = {
  show: boolean
}

export const Loader: React.FC<ILoaderProps> = ({ show }) => {
  if (!show) return null

  return (
    <div className={styles.loading}>
      <SpinnerLoader
        size={14}
        determinatecolor={'var(--gray-70)'}
        indeterminatecolor={'var(--gray-30)'}
      />
    </div>
  )
}
