import { Box } from '@mui/material'
import { FC } from 'react'
import { Avatar, Typography } from 'shared/ui'
import { type User } from 'entities/Users/model/User'
import styles from './styles.module.scss'

type IWidgetMultiUsersTriggerProps = {
  items: User[]
}

export const WidgetMultiUsersTrigger: FC<IWidgetMultiUsersTriggerProps> = ({ items }) => {
  if (!items.length) return <>select</>

  return (
    <Box display={'flex'} gap={1} alignItems={'center'} height={32}>
      <div className={styles.group}>
        {items.map((user) => {
          return <Avatar key={user.id} info={user.avatarInfo} size={16} />
        })}
      </div>
      <Typography ariaLabel={'Inbox'} variant={'body-md-regular'}>
        {items.length > 1 ? `${items.length} members` : items[0].full_name}
      </Typography>
    </Box>
  )
}
