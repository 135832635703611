import { useEffect, FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DropdownContent,
  DropdownGroup,
  dropDownStore,
  DropdownTextInput,
  IDropdownItem,
  Scrollbar,
  SpinnerLoader,
} from 'shared/ui'
import { IDropdownItemSendFrom } from 'widgets/SendFromDropdown/store/types'
import { MultiSelectCard } from 'widgets/SendFromDropdown/ui/MultiSelect'
import { useSendFromDropdownContext } from '../context/sendFromDropdownContext'
import styles from './styles.module.scss'

type ISendFromContentProps = {
  dropdownId: string
}

export const SendFromContent: FC<ISendFromContentProps> = observer(({ dropdownId }) => {
  const store = useSendFromDropdownContext()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    store.setDropdownId(dropdownId)

    return () => {
      store.setSearch('')
    }
  }, [])

  if (store.loading) {
    return (
      <DropdownContent>
        <div className={styles.loader}>
          <SpinnerLoader size={20} />
        </div>
      </DropdownContent>
    )
  }

  return (
    <div className={styles.wrap}>
      {store.isMultiSelect && !!store.multiDropdownStore.multiSelectedValues.length && (
        <MultiSelectCard
          selectedItems={store.multiDropdownStore.multiSelectedValues}
          onDelete={store.multiDropdownStore.removeMultiSelectedItem}
          onChangeHeight={setHeight}
        />
      )}
      <DropdownContent
        noPadding
        propStyles={store.isMultiSelect ? { marginTop: -height, paddingTop: height } : undefined}
      >
        <DropdownTextInput
          textFieldProps={{
            value: store.search,
            onChange: store.setSearch,
            InputProps: {
              placeholder: 'Search',
            },
          }}
          ariaLabel='searchNumbers'
        />

        <Scrollbar
          autoHeight
          autoHeightMin={0}
          autoHeightMax={335}
          onScroll={() => {
            dropDownStore.allHide(dropdownId)
          }}
        >
          {store.itemsGroups.map((group) => {
            return (
              <DropdownGroup
                key={group.key}
                title={group.title}
                items={group.items}
                onChange={(item, parentItem) => {
                  if (!parentItem && group.key === 'inboxes') {
                    store.setCurrentInboxItem({ item: item })
                  } else {
                    store.setItem({
                      item: item as IDropdownItemSendFrom,
                      parentItem: parentItem as IDropdownItem,
                      key: group.key,
                    })
                  }
                }}
                isScroll={false}
              />
            )
          })}

          {!store.itemsGroups.length && <div className={styles.noResult}>No results found</div>}
        </Scrollbar>
      </DropdownContent>
    </div>
  )
})
