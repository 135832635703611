import { makeAutoObservable, runInAction } from 'mobx'
import { complianceStore } from 'entities/Compliance'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { usersStore } from 'entities/Users'
import { type SubscriptionStore } from 'entities/Subscription'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { Conversation } from 'entities/Conversation'
import { organizationStore } from 'entities/Organization'

export class TrialSubscriptionStore {
  private _loading = false
  private _conversation: Conversation | null = null

  constructor(private _subscriptionStore: SubscriptionStore) {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([
      usersStore.initPromise,
      organizationStore.initPromise,
      featureFlagsStore.initPromise,
      this._subscriptionStore.initPromise,
      complianceStore.fetchSummary({ cache: true }),
      complianceStore.fetchOrganizationBusinessProfile({ cache: true }),
      complianceStore.fetchOrganizationTollFree({ cache: true }),
      inboxesStore.fetchSmartInboxes({ cache: true }),
    ])

    runInAction(() => {
      this._loading = false
    })
  }

  setConversation = (item: Conversation | null) => {
    this._conversation = item
  }

  get loading() {
    return this._loading
  }

  get organizationTollFree() {
    return complianceStore.organizationTollFree || null
  }

  get compliance10DLCServicesList() {
    return complianceStore.complianceServicesList || null
  }

  get organizationBusinessProfile() {
    return complianceStore.organizationBusinessProfile
  }

  get businessProfile() {
    return this.organizationBusinessProfile?.businessProfile
  }

  get summary() {
    return complianceStore.summary
  }

  get isStatusCompleted() {
    return this.isComplianceNotRequired || this.isComplianceCompleted
  }

  get numbersTwilio() {
    const inboxes = inboxesStore.sharedInboxes

    return inboxes.reduce<number[]>((state, current) => {
      if (current.numberIds.length) {
        const ids = current.numberIds.filter((id) => !numbersStore.getItem(id)?.isPFT)

        state = [...ids, ...state]
      }

      return state
    }, [])
  }

  get isTollFreeNumber() {
    return this.numbersTwilio.some((id) => numbersStore.getItem(id)?.isTollFree)
  }

  get isLocalNumber() {
    return !this.isTollFreeNumber
  }

  get isComplianceNotRequired() {
    return Boolean(this.isLocalNumber && this.summary?.target_country === 'CA')
  }

  get isComplianceCompleted() {
    if (this.isLocalNumber) {
      return (
        this.businessProfile?.isStatusApproved && this.compliance10DLCServicesList?.isStatusApproved
      )
    }

    return this.organizationTollFree?.isStatusVerified
  }

  get isQuestionnaire() {
    return complianceStore.isQuestionnaire
  }

  get isAdminOrOwnerRole() {
    return usersStore.user?.isAdminOrOwnerRole
  }

  get isNumberSelected() {
    return !Boolean(usersStore.user?.isRequireNumber)
  }

  get conversationNumberType() {
    const item = this._conversation

    if (!item) return null

    const numberId = item.number_id
    const number = numbersStore.getItem(numberId)

    if (number?.isPFT) return 'pft'
    if (number?.isTollFree) return 'toll_free'

    return 'local'
  }

  get stateTollFree() {
    if (this.organizationTollFree?.isStatusBlocked) {
      return 'step_2_reject'
    }

    if (this.organizationTollFree?.isStatusRejected) {
      return 'step_1_reject'
    }

    if (this.organizationTollFree?.isStatusVerified) {
      return 'step_1_upgrade'
    }

    if (
      this.organizationTollFree?.isStatusPendingReview ||
      this.organizationTollFree?.isStatusInCarrierReview
    ) {
      return 'step_2'
    }

    if (this.organizationTollFree?.isStatusInProgress) {
      return 'step_1'
    }

    return 'default'
  }

  get stateBusinessProfile10DLC() {
    if (this.businessProfile?.isStatusFailed || this.compliance10DLCServicesList?.isStatusFailed) {
      return 'step_1_reject'
    }

    if (
      this._subscriptionStore.isTrial &&
      ((this.businessProfile?.isStatusApproved &&
        this.compliance10DLCServicesList?.isStatusApproved) ||
        this.isComplianceNotRequired)
    ) {
      return 'step_1_upgrade'
    }

    if (
      this.businessProfile?.isStatusApproved &&
      (this.compliance10DLCServicesList?.isStatusPendingReview ||
        this.compliance10DLCServicesList?.isStatusInCarrierReview)
    ) {
      return 'step_4'
    }

    if (
      (this.businessProfile?.isStatusPendingReview ||
        this.businessProfile?.isStatusInCarrierReview) &&
      (this.compliance10DLCServicesList?.isStatusPendingReview ||
        this.compliance10DLCServicesList?.isStatusInCarrierReview)
    ) {
      return 'step_3'
    }

    if (
      (this.businessProfile?.isStatusPendingReview ||
        this.businessProfile?.isStatusInCarrierReview) &&
      (this.compliance10DLCServicesList?.isEmpty ||
        this.compliance10DLCServicesList?.isStatusInProgress)
    ) {
      return 'step_2'
    }

    if (this.businessProfile?.isStatusInProgress) {
      return 'step_1'
    }

    if (this.businessProfile?.isStatusEmpty) {
      return 'step_0'
    }

    return 'default'
  }

  get state() {
    if (this.isAdminOrOwnerRole) {
      if (this.isNumberSelected) {
        if (this.isLocalNumber) return 'number_local_owner'

        return 'number_tollfree_owner'
      }
      if (this.isQuestionnaire) return 'questionnaire_ready_owner'

      return 'questionnaire_not_ready_owner'
    }

    if (this.isNumberSelected) {
      if (this.isLocalNumber) return 'number_local_member'

      return 'number_tollfree_member'
    }

    if (this.isQuestionnaire) return 'questionnaire_ready_member'

    return 'questionnaire_not_ready_member'
  }

  get hide() {
    if (organizationStore.isTwilioNumberVendor) return true

    if (!this._subscriptionStore.isTrial) {
      if (this.isComplianceNotRequired) return true
      if (!this.isQuestionnaire || !this.isNumberSelected) return true

      if (this.isTollFreeNumber) {
        return Boolean(this.organizationTollFree?.isStatusVerified)
      }

      if (this.isLocalNumber) {
        return Boolean(
          this.businessProfile?.isStatusApproved &&
            this.compliance10DLCServicesList?.isStatusApproved
        )
      }

      return true
    }

    return !organizationStore.trialEndsAtString
  }
}
