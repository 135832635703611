import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type {
  IParamsCreateCampaign,
  IParamsEnrolmentCampaign,
  IParamsGetList,
  IParamsSaveColumns,
  IResponseCreateCampaign,
  IResponseEnrolmentCampaign,
  IResponseGetFilters,
  IResponseGetList,
  IResponseSaveColumns,
} from './types'

class Api {
  createCampaign(params: IParamsCreateCampaign): Promise<AxiosResponse<IResponseCreateCampaign>> {
    return baseApi.post('campaign/campaigns', params)
  }

  getList(
    params: IParamsGetList,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetList>> {
    return baseApi.post('campaign/campaigns/list', params, { ...options })
  }

  getFilters(): Promise<AxiosResponse<IResponseGetFilters>> {
    return baseApi.get('campaign/campaigns/filters')
  }

  enrolmentCampaign({
    id,
  }: IParamsEnrolmentCampaign): Promise<AxiosResponse<IResponseEnrolmentCampaign>> {
    return baseApi.post(`campaign/campaigns/${id}/enroll`)
  }

  saveColumns = (params: IParamsSaveColumns): Promise<AxiosResponse<IResponseSaveColumns>> => {
    return baseApi.post('users/ui-settings/sync', params)
  }
  getColumns = (): Promise<AxiosResponse<IResponseSaveColumns>> => {
    return baseApi.get('users/ui-settings/campaigns-table-columns')
  }
}

export const CampaignApi = new Api()
