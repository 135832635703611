/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */
import { Ref, forwardRef } from 'react'
import { PatternFormat } from 'react-number-format'
import { IInputComponent, IInputPatternProps } from './types'

const InputPatternRef = (props: IInputPatternProps, ref: Ref<HTMLInputElement>) => (
  <PatternFormat
    getInputRef={ref}
    {...props}
    format={props.generateDynamicFormat?.(props?.value || '') || props.format}
  />
)

export const InputPattern: IInputComponent<IInputPatternProps> = forwardRef(InputPatternRef)

export const makeInputPattern: (
  defaults: IInputPatternProps
) => IInputComponent<Partial<IInputPatternProps>> = (defaults) =>
  forwardRef((props, ref) => InputPatternRef({ ...defaults, ...props }, ref))
