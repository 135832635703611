// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aTA2sFDvco4tVwBKke78{display:flex;flex-direction:column;gap:4px}.aTA2sFDvco4tVwBKke78 .SdK9TNuXiHSGDRdilDQe{margin-top:20px;padding:12px;font-weight:400;font-size:14px;line-height:20px}.aTA2sFDvco4tVwBKke78 .SdK9TNuXiHSGDRdilDQe.Cfpd7Rf77uzfC0P_tcKE{margin-bottom:4px}.aTA2sFDvco4tVwBKke78 .jw7scn5ybA_TJoTPh5aI{margin-top:20px}", "",{"version":3,"sources":["webpack://./src/widgets/Login/ui/LoginForm/TwoFaModal/Code/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,4CACE,eAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,iBAAA,CAIJ,4CACE,eAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  .textField {\n    margin-top: 20px;\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.error {\n      margin-bottom: 4px;\n    }\n  }\n\n  .wrapResend {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "aTA2sFDvco4tVwBKke78",
	"textField": "SdK9TNuXiHSGDRdilDQe",
	"error": "Cfpd7Rf77uzfC0P_tcKE",
	"wrapResend": "jw7scn5ybA_TJoTPh5aI"
};
export default ___CSS_LOADER_EXPORT___;
