import { observer } from 'mobx-react-lite'
import { EmptyState } from 'shared/ui'
import powerDialerPreviewImg from 'shared/assets/images/power_dialer_preview.png'
import { getCDNAssetURL } from 'shared/lib'
import { PowerDialerActions } from 'pages/powerDialer/pages/powerDialerList/ui/PowerDialerActions/PowerDialerActions'

export const PowerDialerEmpty = observer(() => {
  return (
    <EmptyState
      variant={'preview'}
      previewImg={<img alt='Power Dialer preview' src={getCDNAssetURL(powerDialerPreviewImg)} />}
      title={'Power Dialer'}
      subtitle={'Your fastest route to productivity'}
      actions={<PowerDialerActions />}
    />
  )
})
