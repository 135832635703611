import { observer } from 'mobx-react-lite'
import optInQrCodeApproved from 'shared/assets/images/optInQrCodeApproved.png'
import optInQrCodeRejected from 'shared/assets/images/optInQrCodeRejected.png'
import { ICommonOptInContent } from 'pages/settings/pages/compliance/ui/CommonOptInContent/store/type'
import { OptInContent } from './CommonOptInContent'
import styles from './styles.module.scss'

type ICommonOptInQrCodeFormContentProps = ICommonOptInContent & {
  getIsNextDisabled: () => boolean
}

export const CommonOptInQrCodeFormContent = observer(
  ({
    stepLoading,
    deleteAttachment,
    getOptInImagesAsAttach,
    uploadAttachment,
    increaseStep,
    errorBanner,
    clearAttachmentError,
    getIsNextDisabled,
    getAttachmentError,
    optInConsentStore,
  }: ICommonOptInQrCodeFormContentProps) => {
    const { openApprovedExampleModal } = optInConsentStore

    return (
      <div className={styles.optInContentWrap}>
        <OptInContent
          attachmentData={getOptInImagesAsAttach()}
          stepLoading={stepLoading}
          isNextDisabled={getIsNextDisabled()}
          attachmentError={getAttachmentError()}
          increaseStep={increaseStep}
          clearAttachmentError={clearAttachmentError}
          uploadOptInImageAttachment={uploadAttachment}
          deleteAttachment={(url: string) => deleteAttachment(url)}
          approvedExampleImage={optInQrCodeApproved}
          rejectedExampleImage={optInQrCodeRejected}
          errorBanner={errorBanner}
          seeApprovedExampleClick={() => {
            openApprovedExampleModal(
              [optInQrCodeApproved],
              ['Opt-in / Paper Form / Approved example']
            )
          }}
          approvedExampleClick={() =>
            openApprovedExampleModal(
              [optInQrCodeRejected, optInQrCodeApproved],
              ['Opt-in / Qr Code / Rejected example', 'Opt-in / Qr Code / Approved example']
            )
          }
          rejectedExampleClick={() =>
            openApprovedExampleModal(
              [optInQrCodeApproved, optInQrCodeRejected],
              ['Opt-in / Qr Code / Approved example', 'Opt-in / Qr Code / Rejected example']
            )
          }
          firstBlock={{
            title: 'Include compliance language to your QR Code',
            desc: 'If hyperlinking is not possible, include the full URL of your Terms of Service and Privacy Policy in the compliance language (e.g. “https:/example.com/terms-of-service/”).',
            copyTitle: 'Compliance language',
            copyText:
              'I agree to receive recurring automated text messages at the phone number provided. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our Terms of Service and Privacy Policy.',
            underlineText: ['Terms of Service', 'Privacy Policy'],
          }}
          secondBlock={{
            title: 'Include this paragraph to your Privacy Policy',
            desc: 'Sharing data is not allowed. If text messaging data/consent is not shared, include the paragraph below to your Privacy Policy.',
            copyText:
              'Text messaging originator opt-in data and consent will not be shared with any third parties, excluding aggregators and providers of the Text Message services.',
          }}
        />
      </div>
    )
  }
)
