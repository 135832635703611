import { IReactionDisposer, makeAutoObservable, runInAction, when } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { subscriptionStore } from 'entities/Subscription'
import {
  type IIntegrationKey,
  Integration,
  integrationsStore,
  isValidIntegrationKey,
} from 'entities/Integrations'
import { ManageIntegrationConnectionStore } from 'features/ManageIntegrationConnection'
import { SettingsRoutes } from 'pages/settings/route/type'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'

export class IntegrationDetailsStore {
  private readonly _integrationKey: IIntegrationKey | null = null
  private _integration: Integration | null = null
  private _isLoading = true
  private _isNotFound = false
  private _manageIntegrationConnectionStore = new ManageIntegrationConnectionStore()
  private _whenManageConnectionEndedReaction: IReactionDisposer | null = null

  constructor(integrationKey: string | undefined) {
    if (integrationKey && isValidIntegrationKey(integrationKey)) {
      this._integrationKey = integrationKey
    } else {
      this._isNotFound = true
      this._isLoading = false
    }
    makeAutoObservable(this)
  }

  getIntegrationDetails = async () => {
    if (!this._integrationKey) return
    runInAction(() => {
      this._isLoading = true
    })

    try {
      await integrationsStore.fetchIntegrations()
      const integration = integrationsStore.getIntegration(this._integrationKey)

      runInAction(() => {
        if (!integration) {
          this._isNotFound = true
          return
        }
        this._integration = integration
      })
    } catch (e) {
      console.error(e)
      runInAction(() => {
        this._isNotFound = true
      })
    } finally {
      runInAction(() => {
        this._isLoading = false
      })
    }
  }

  private _redirectToSettings = () => {
    uiStore.changeRouteViaVue({
      path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${this._integrationKey}/${IntegrationsRoutes.settings}`,
    })
  }

  handleEventIntegrationConnect = () => {
    if (!this.integration) return

    if (!this.isManageConnectionProcessing) {
      this._redirectToSettings()
      return
    }

    this._whenManageConnectionEndedReaction = when(
      () => !this.isManageConnectionProcessing,
      () => this._redirectToSettings()
    )
  }

  onConnect = async () => {
    if (!this._integration) return

    await this._manageIntegrationConnectionStore.connect(this._integration)
  }

  onDisconnect = async () => {
    if (!this._integration) return

    await this._manageIntegrationConnectionStore.disconnect(this._integration)
  }

  get isLoading() {
    return this._isLoading
  }

  get isManageConnectionProcessing() {
    return this._manageIntegrationConnectionStore.processing
  }

  get isNotFound() {
    return this._isNotFound
  }

  get integration() {
    return this._integration
  }

  get hasSiblingConnections() {
    return !!integrationsStore.connectedIntegrations.length
  }

  get hasConnectionLimit() {
    return (subscriptionStore.isTrial || subscriptionStore.isPAYGPlan) && this.hasSiblingConnections
  }

  dispose = () => {
    this._manageIntegrationConnectionStore.dispose()
    this._whenManageConnectionEndedReaction?.()
  }
}
