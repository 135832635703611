import { type IIconsVector, Icon, Box, BoxProps } from 'shared/ui'

import styles from './styles.module.scss'

interface INoResultsFoundProps {
  label?: string
  icon?: IIconsVector
  boxProps?: BoxProps
}

export const NoResultsFound = ({
  label = 'No results found',
  icon = 'search',
  boxProps,
}: INoResultsFoundProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height='100%'
      width='100%'
      {...boxProps}
    >
      <Icon color='var(--content-primary-tertiary)' fontSize={24} icon={icon} />
      <p className={styles.messageText}>{label}</p>
    </Box>
  )
}
