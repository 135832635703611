import { observer } from 'mobx-react-lite'
import { Navigate, Outlet } from 'react-router-dom'
import React, { useEffect, useMemo } from 'react'
import { SpinnerLoader } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { IntegrationsAccessStore } from 'pages/settings/pages/integrations/store/IntegrationsAccessStore'

export const IntegrationsAccessLayout = observer(() => {
  const store = useMemo(() => new IntegrationsAccessStore(), [])
  const { getItem } = userPermissionsStore
  const { salesmsg_design_v2_integrations } = featureFlagsStore
  const canManageIntegrations = getItem('can_manage_integrations')

  useEffect(() => {
    store.init()
  }, [])

  if (store.loading) {
    return <SpinnerLoader />
  }

  if (!canManageIntegrations) {
    return <Navigate to={'/'} />
  }

  if (!salesmsg_design_v2_integrations) {
    uiStore.changeRoute({
      path: '/settings/integrations',
      type: 'vue',
      method: 'replace',
    })
    return null
  }

  return (
    <>
      <Outlet />
    </>
  )
})
