// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LtooWw2yVw9fZBugeIro{flex:1;display:flex;align-items:center;justify-content:space-between;margin:0 4px}.LtooWw2yVw9fZBugeIro .lJs7mx8hEiQ3PrFB11vQ{font-size:11px;color:var(--yellow-100);border-radius:4px;padding:0 4px;border:1px solid var(--black-10);background:var(--yellow-20)}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/layout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,YAAA,CAEA,4CACE,cAAA,CACA,uBAAA,CACA,iBAAA,CACA,aAAA,CACA,gCAAA,CACA,2BAAA","sourcesContent":[".label {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 0 4px;\n\n  .badge {\n    font-size: 11px;\n    color: var(--yellow-100);\n    border-radius: 4px;\n    padding: 0 4px;\n    border: 1px solid var(--black-10);\n    background: var(--yellow-20);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "LtooWw2yVw9fZBugeIro",
	"badge": "lJs7mx8hEiQ3PrFB11vQ"
};
export default ___CSS_LOADER_EXPORT___;
