import { useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'

export const TollFreeAccessLayout = observer(() => {
  const store = useTollFreeStepsContext()

  useLayoutEffect(() => {
    uiStore.changeRouteViaVue({
      path: store.tollFreeNavigationStore.getCurrentUrl,
    })
  }, [])

  return (
    <>
      <Outlet />
    </>
  )
})
