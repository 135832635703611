import { observer } from 'mobx-react-lite'

import { QuickLinkTable } from './QuickLinkTable'
import { QuickLinkHeader } from './QuickLinkHeader'
import { QuickLinkSearch } from './QuickLinkSearch'
import styles from './styles.module.scss'

export const QuickLinkList = observer(() => {
  return (
    <section className={styles.page}>
      <QuickLinkHeader />
      <QuickLinkSearch />
      <QuickLinkTable />
    </section>
  )
})
