import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router'
import { useEffect } from 'react'
import { PageLayout } from 'shared/layout'
import { IMenuItemProps, Menu, Typography } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { integrationsStore } from 'entities/Integrations'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { chatbotLayoutStore } from 'pages/chatbot'

import styles from './styles.module.scss'
import { ChatbotRoute } from '../route/type'

const menuItems: IMenuItemProps[] = [
  {
    type: 'nav',
    icon: 'chatBot',
    to: ChatbotRoute.all,
    label: (
      <Typography variant={'body-md-regular'} className={styles.label} ariaLabel={'All Textbots'}>
        All Textbots <span className={styles.badge}>Beta</span>
      </Typography>
    ),
  },
]

export const ChatbotLayout = observer(() => {
  const { chatbot } = featureFlagsStore

  if (!chatbot) return <Navigate to={'/'} />

  useEffect(() => {
    Promise.all([integrationsStore.fetchIntegrations(), allMergeFieldsStore.loadAllFields()])
  }, [])

  return (
    <PageLayout
      leftContent={<Menu title='AI Textbot' menuItems={menuItems} />}
      store={chatbotLayoutStore}
    />
  )
})
