import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { CAMPAIGN_NAME_LIMIT } from 'shared/constants/limits'
import { NameInput } from 'features/createOrEdit/NameInput'
import { useCampaignViewContext } from 'pages/campaigns/ui/CampaignView/context/context'
import styles from './styles.module.scss'

export const Header = observer(() => {
  const { nameInputStore, onSettings } = useCampaignViewContext()
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapLabel}>
        <Typography ariaLabel={'newCampaign'} variant={'body-xs-regular'} className={styles.label}>
          New campaign
        </Typography>
      </div>
      <div className={styles.wrapActions}>
        <NameInput
          store={nameInputStore}
          placeholder={'Campaign name'}
          limit={CAMPAIGN_NAME_LIMIT}
        />
        <Button
          size={'medium'}
          contained={'secondary'}
          icon={'settings'}
          text={'Settings'}
          onClick={onSettings}
        />
      </div>
    </div>
  )
})
