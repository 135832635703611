import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { Alert } from 'shared/ui'
import { CHAR_MAX_LENGTH } from 'shared/constants/limits'
import { MessageField } from 'widgets/MessageField'
import type { NewSavedReplyStore } from 'widgets/SavedReplies'
import { SavedReplyActions } from './SavedReplyActions/SavedReplyActions'

import styles from './styles.module.scss'

type INewSavedReplyContentProps = {
  newSavedReplyStore: NewSavedReplyStore
  disabledVisibility: boolean
}

export const NewSavedReplyContent: FC<INewSavedReplyContentProps> = observer(
  ({ newSavedReplyStore, disabledVisibility }) => {
    const { messageFieldStore, title, setTitle, isEdit } = newSavedReplyStore
    const isLengthAlertShow = title.length > CHAR_MAX_LENGTH

    return (
      <div className={styles.wrap}>
        <div className={styles.alertContainer} ref={messageFieldStore.setAlertElement} />
        {isLengthAlertShow && (
          <Alert
            item={{
              type: 'alert',
              desc: `Title should not be longer than ${CHAR_MAX_LENGTH} characters`,
            }}
            marginBottom={28}
          />
        )}
        <TextField
          className={styles.textField}
          size='medium'
          variant='integrated'
          InputProps={{
            placeholder: 'Title',
          }}
          value={title}
          onChange={setTitle}
          mainActions={[makeTextFieldAutoFocus({ withFocus: !isEdit })]}
          disabled={messageFieldStore.recordAudioState}
        />
        <MessageField messageFieldStore={messageFieldStore} />
        <SavedReplyActions
          newSavedReplyStore={newSavedReplyStore}
          disabledVisibility={disabledVisibility}
        />
      </div>
    )
  }
)
