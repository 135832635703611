import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { type LoginStore } from 'widgets/Login/store/loginStore'
import { MethodItem } from './MethodItem'

import styles from './styles.module.scss'

type IProps = {
  store: LoginStore
}

export const TwoFaMethodModalContent = observer(({ store }: IProps) => {
  const { twoFaSettings } = store

  return (
    <div className={styles.wrap}>
      <Alert
        item={{
          type: 'infoBlue',
          title: 'Enable two-factor authentication',
          desc: 'Add a second layer of verification to secure your account',
        }}
      />

      <div className={styles.list}>
        {twoFaSettings?.map((item) => (
          <MethodItem key={item.type} item={item} store={store} />
        ))}
      </div>
    </div>
  )
})
