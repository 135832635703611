import { observer } from 'mobx-react-lite'
import { type IActiveTab } from 'pages/settings/pages/compliance/store/types'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import { type IStatusShortProps, StatusSection } from './StatusSection/StatusSection'
import styles from './styles.module.scss'

export const Menu = observer(() => {
  const { getIconConditionByTab, verificationItems } = useCompliancePageContext()
  const { required, recommended, optional } = verificationItems

  const generateVerificationItems = (
    items: IActiveTab[],
    tabMapping: Record<IActiveTab, string>
  ): IStatusShortProps[] =>
    items.map((item) => {
      const iconProps = getIconConditionByTab(item)

      return {
        title: tabMapping[item],
        icon: iconProps?.icon,
        iconColor: iconProps?.iconColor,
        customIcon: iconProps?.customIcon,
        tab: item,
      }
    })

  const tabMapping: Record<IActiveTab, string> = {
    BP: 'Business profile',
    LN: 'Local numbers',
    Calling: 'Calling',
    TF: 'Toll-free numbers',
  }

  const requiredVerificationItems = generateVerificationItems(required, tabMapping)
  const recommendedVerificationItems = generateVerificationItems(recommended, tabMapping)
  const optionalVerificationItems = generateVerificationItems(optional, tabMapping)

  return (
    <div className={styles.wrap}>
      <StatusSection
        title='Required'
        titleClassName={styles.requiredVerificationTitle}
        items={requiredVerificationItems}
      />
      <StatusSection
        title='Recommended'
        items={recommendedVerificationItems}
        titleClassName={styles.verificationTitle}
      />
      <StatusSection
        title='Optional'
        items={optionalVerificationItems}
        titleClassName={styles.verificationTitle}
      />
    </div>
  )
})
