import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { apiOptionsToDropdownItems, apiOptionToDropdownItem } from 'shared/lib'
import { IDropdownItem } from 'shared/ui'
import {
  AdminComplianceApi,
  IResponseAdminTenDLCById,
  IUpdateTenDLCCampaignRequest,
} from 'entities/Admin/compliance'
import { complianceStore, type IResponseOptInData, type ITenDLCStatus } from 'entities/Compliance'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { IOptInMethod } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/store/type'

class AdminTenDLCCampaignStore {
  constructor() {
    makeAutoObservable(this)
  }

  organization_id: number | null = null
  id: number | null = null

  isEdit = false
  isSaveLoading = false
  status: ITenDLCStatus | null = null
  registrationError = ''

  errorsObj: { [key: string]: string[] } = {}

  get isRejected() {
    return Boolean(
      this.status &&
        ['failed', 'rejected_by_twilio', 'rejected_by_tcr', 'rejected_by_admin'].includes(
          this.status
        )
    )
  }
  get isForReview() {
    return this.isRejected || this.status === 'internal_review'
  }

  get isForEdit() {
    return this.isForReview || !this.status
  }

  vendor_id: string | null = ''
  edit_vendor_id: typeof this.vendor_id = null
  get is_edit_vendor_id() {
    return this.vendor_id !== this.edit_vendor_id
  }
  set_edit_vendor_id = (value: typeof this.vendor_id) => {
    this.edit_vendor_id = value
  }

  use_case: IDropdownItem | null = null
  edit_use_case: typeof this.use_case = null
  get is_edit_use_case() {
    return this.use_case?.id !== this.edit_use_case?.id
  }
  set_edit_use_case = (value: typeof this.edit_use_case) => {
    this.edit_use_case = value
  }

  use_case_description: string | null = ''
  edit_use_case_description: typeof this.use_case_description = null
  get is_edit_use_case_description() {
    return this.use_case_description !== this.edit_use_case_description
  }
  set_edit_use_case_description = (value: typeof this.edit_use_case_description) => {
    this.edit_use_case_description = value
  }

  messageFlow: string | null = ''
  edit_messageFlow: typeof this.messageFlow = null
  get is_edit_messageFlow() {
    return this.messageFlow !== this.edit_messageFlow
  }
  set_edit_messageFlow = (value: typeof this.edit_messageFlow) => {
    this.edit_messageFlow = value
  }

  sample_message_1: string | null = ''
  edit_sample_message_1: typeof this.sample_message_1 = null
  get is_edit_sample_message_1() {
    return this.sample_message_1 !== this.edit_sample_message_1
  }
  set_edit_sample_message_1 = (value: typeof this.edit_sample_message_1) => {
    this.edit_sample_message_1 = value
  }

  sample_message_2: string | null = ''
  edit_sample_message_2: typeof this.sample_message_2 = null
  get is_edit_sample_message_2() {
    return this.sample_message_2 !== this.edit_sample_message_2
  }
  set_edit_sample_message_2 = (value: typeof this.edit_sample_message_2) => {
    this.edit_sample_message_2 = value
  }

  opt_int_types: IDropdownItem[] | null = null
  get edit_opt_int_types(): IDropdownItem[] {
    return this.edit_opt_in_methods.map((method) => ({
      id: method.id,
      label: method.title,
    }))
  }
  get is_edit_opt_int_types() {
    return (
      this.opt_int_types?.map((item) => item.id).toString() !==
      this.edit_opt_int_types?.map((item) => item.id).toString()
    )
  }
  set_edit_edit_opt_int_types = (value: typeof this.edit_opt_int_types) => {
    const oldIds = Array.from(this.edit_opt_in_methods_map.keys())
    const newIds = value.map((item) => item.id).filter((id) => typeof id === 'string')
    const removedMethodsIds = oldIds.filter((id) => !newIds.includes(id))
    removedMethodsIds.forEach((id) => {
      this.edit_opt_in_methods_map.delete(id)
    })
    value.forEach((item) => {
      if (typeof item.id !== 'string') return

      const oldMethod = this.edit_opt_in_methods_map.get(item.id)
      if (!oldMethod) {
        this.edit_opt_in_methods_map.set(item.id, {
          id: item.id,
          title: item.label,
          attachments: [],
          deletedIds: [],
        })
      }
    })
  }

  opt_in_data: IResponseOptInData[] | null = null
  opt_in_methods_map: Map<string, IOptInMethod> = new Map()
  edit_opt_in_methods_map: Map<string, IOptInMethod> = new Map()

  get opt_in_methods() {
    return Array.from(this.opt_in_methods_map.values())
  }

  get edit_opt_in_methods() {
    return Array.from(this.edit_opt_in_methods_map.values())
  }
  get is_edit_opt_in_methods() {
    const changesIds: string[] = []
    this.edit_opt_in_methods.forEach((method) => {
      const oldMethod = this.opt_in_methods_map.get(method.id)
      if (
        method.attachments.map((attachment) => attachment.id).toString() !==
        oldMethod?.attachments.map((attachment) => attachment.id).toString()
      ) {
        changesIds.push(method.id)
      }
    })
    return changesIds
  }

  remove_opt_in_attachment = (optInId: string, attachmentId: string | number) => {
    const old_edit_method = this.edit_opt_in_methods_map.get(optInId)

    if (old_edit_method) {
      this.edit_opt_in_methods_map.set(old_edit_method.id, {
        ...old_edit_method,
        attachments: [
          ...old_edit_method.attachments.filter((attachment) => attachment.id !== attachmentId),
        ],
        deletedIds: old_edit_method.deletedIds.filter((id) => id !== attachmentId),
      })
    }
  }

  add_opt_in_file = (optInId: string, file: File) => {
    const old_edit_method = this.edit_opt_in_methods_map.get(optInId)
    if (old_edit_method) {
      const attachment = new Attachment({ file })
      this.edit_opt_in_methods_map.set(old_edit_method.id, {
        ...old_edit_method,
        attachments: [...old_edit_method.attachments, attachment],
      })
    }
  }

  get hasChanges() {
    if (!this.isEdit) {
      return false
    }
    const changes = [
      this.is_edit_vendor_id,
      this.is_edit_use_case,
      this.is_edit_use_case_description,
      this.is_edit_messageFlow,
      this.is_edit_sample_message_1,
      this.is_edit_sample_message_2,
      this.is_edit_opt_int_types,
      !!this.is_edit_opt_in_methods.length,
    ]
    return changes.some((item) => item)
  }

  reset = () => {
    this.organization_id = null
    this.registrationError = ''
    this.errorsObj = {}
    this.id = null
    this.status = null
    this.vendor_id = null
    this.use_case = null
    this.use_case_description = null
    this.sample_message_1 = null
    this.sample_message_2 = null
    this.opt_int_types = null
    this.opt_in_data = null
    this.opt_in_methods_map.clear()
    this.isEdit = false
  }

  setCampaign(organization_id: number, campaign?: IResponseAdminTenDLCById['campaign']) {
    const { getOptInMethodName } = complianceStore
    this.organization_id = organization_id
    if (campaign?.id) {
      const { useCaseData, sampleMessagesData, optInData, messageFlow } = campaign.data

      this.registrationError = campaign.registration_errors
        ?.map((error) => error.message)
        .join(', ')
      this.id = campaign.id
      this.status = campaign.status as ITenDLCStatus
      this.vendor_id = campaign.vendor_id
      this.messageFlow = messageFlow
      this.use_case = apiOptionToDropdownItem({
        id: useCaseData?.useCase || '',
        label: useCaseData?.useCaseTitle || '',
      })
      this.use_case_description = useCaseData?.description || ''
      this.sample_message_1 = sampleMessagesData?.sampleMessage1 || ''
      this.sample_message_2 = sampleMessagesData?.sampleMessage2 || ''
      this.opt_int_types = apiOptionsToDropdownItems(
        optInData?.map((item) => ({
          id: item.optInMethod,
          label: getOptInMethodName(item.optInMethod),
        })) || []
      )
      this.opt_in_data = optInData

      if (optInData) {
        this.opt_in_methods_map = new Map(
          optInData.map((data) => [
            data.optInMethod,
            {
              id: data.optInMethod,
              title: getOptInMethodName(data.optInMethod),
              attachments: data.optInImages.map(
                (file) =>
                  new Attachment({
                    imageUrl: file.sourceUrl,
                    id: file.uid,
                  })
              ),
              deletedIds: data.optInImages.map((file) => file.uid),
            },
          ])
        )
      }
    }
  }

  onEdit = () => {
    this.edit_vendor_id = this.vendor_id
    this.edit_use_case = this.use_case
    this.edit_use_case_description = this.use_case_description
    this.edit_messageFlow = this.messageFlow
    this.edit_sample_message_1 = this.sample_message_1
    this.edit_sample_message_2 = this.sample_message_2
    this.edit_opt_in_methods_map = new Map(JSON.parse(JSON.stringify(this.opt_in_methods_map)))

    this.isEdit = true
  }

  onClose = () => {
    this.isEdit = false
  }

  onSave = async () => {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })

      if (!this.organization_id) return

      const data: IUpdateTenDLCCampaignRequest = {
        organization_id: this.organization_id,
        id: this.id || null,
        vendor_id: this.edit_vendor_id || null,
        status: this.status || '',
        useCaseData: this.edit_use_case
          ? {
              description: this.edit_use_case_description || '',
              useCase: String(this.edit_use_case.id),
              useCaseTitle: '',
            }
          : null,
        messageFlow: this.edit_messageFlow || '',
        sample_message_1: this.edit_sample_message_1 || '',
        sample_message_2: this.edit_sample_message_2 || '',
        opt_in_data: this.edit_opt_in_methods.map((method) => {
          return {
            opt_in_method: method.id,
            opt_in_old_files_ids: method.deletedIds,
            opt_in_files: method.attachments
              .filter((attachment) => attachment.file)
              .map((attachment) => ({ file: attachment.file as File })),
          }
        }),
      }

      return await AdminComplianceApi.updateTenDLCCampaign(data)
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data) {
            this.errorsObj = e.response.data
          }
        }
      })
      throw e
    } finally {
      runInAction(() => {
        this.isSaveLoading = false
      })
    }
  }

  getError = (key: string) => {
    return this.errorsObj && this.errorsObj[key] && this.errorsObj[key][0]
  }
}

export const adminTenDLCCampaignStore = new AdminTenDLCCampaignStore()
