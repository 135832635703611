// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oN9wi2KfgiPqDytPSaMs .YTfb9QMT3HdqhdWxotku{padding:11px 8px 11px 11px}.J3eG91a8b7zb8jcCBxfN{margin-top:24px}.PiVqpqkgwhuylQMvpPzT{margin-top:24px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/tollFree/pages/UseCase/ui/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,0BAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,eAAA","sourcesContent":[".wrap {\n  .select {\n    padding: 11px 8px 11px 11px;\n  }\n}\n\n.useCaseDesc {\n  margin-top: 24px;\n}\n\n.sampleMessageDesc {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "oN9wi2KfgiPqDytPSaMs",
	"select": "YTfb9QMT3HdqhdWxotku",
	"useCaseDesc": "J3eG91a8b7zb8jcCBxfN",
	"sampleMessageDesc": "PiVqpqkgwhuylQMvpPzT"
};
export default ___CSS_LOADER_EXPORT___;
