import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, type IIconButtonProps } from 'shared/ui'
import { shortLinkStore } from 'entities/ShortLink/store/shortLinkStore'

type IShortenLinkIconActionProps = {
  onAddShortLink: (link: string) => void
  iconButtonProps?: IIconButtonProps
}

export const ShortenLinkIconAction: FC<IShortenLinkIconActionProps> = observer(
  ({ onAddShortLink, iconButtonProps }) => {
    const onOpenShortenLinkModal = () => {
      shortLinkStore.onOpenModal({
        onAddLink: onAddShortLink,
      })
    }
    return (
      <IconButton
        icon={'link'}
        tooltipProps={{
          label: 'Shorten URL',
          placement: 'top',
        }}
        color={'tertiary'}
        size={'medium'}
        variant={'icon'}
        onClick={onOpenShortenLinkModal}
        ariaLabel='Shorten_URL'
        {...iconButtonProps}
      />
    )
  }
)
