import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import {
  Button,
  FullPageWrapper,
  Icon,
  Tabs,
  type ITabItem,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import { uiStore } from 'shared/store/uiStore'
import { getIntegrationDescriptionByKey } from 'entities/Integrations/lib/integrationsDescription'
import { IntegrationStatus } from 'widgets/Integrations'
import {
  IntegrationDetailsProvider,
  useIntegrationDetailsContext,
} from 'pages/settings/pages/integrations/context/integrationDetailsContext'
import { SettingsRoutes } from 'pages/settings/route/type'
import styles from './styles.module.scss'

const tabs: ITabItem[] = [
  {
    name: 'Settings',
    key: 'settings',
  },
  {
    name: 'Description',
    key: 'description',
  },
]

const IntegrationDetails = observer(() => {
  const { pathname } = useLocation()
  const {
    integration,
    isLoading,
    isNotFound,
    onConnect,
    onDisconnect,
    isManageConnectionProcessing,
    hasConnectionLimit,
  } = useIntegrationDetailsContext()

  if (isLoading) return <SpinnerLoader />
  if (isNotFound) {
    return <Navigate to={`/${SettingsRoutes.root}/${SettingsRoutes.integrations}`} replace />
  }
  if (!integration) return null

  const segments = pathname.split('/').filter(Boolean)
  const activeTab = segments[segments.length - 1]
  const handleSelectTab = (tab: ITabItem) => {
    uiStore.changeRouteViaVue({
      path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${integration.key}/${tab.key}`,
    })
  }
  const integrationData = getIntegrationDescriptionByKey(integration.key)

  const handleConnect = () => {
    if (integration.key === 'salesforce') {
      handleSelectTab(tabs[0])
      return
    }
    void onConnect()
  }

  return (
    <FullPageWrapper className={styles.container}>
      <div className={styles.content}>
        <Breadcrumbs customPath={`/${SettingsRoutes.root}/${SettingsRoutes.integrations}`} />
        <header>
          <Icon
            fontSize={24}
            icon={integration.icon}
            padding={11}
            insideBox
            className={styles.headerLogo}
          />
          <div className={styles.integrationDetailsHeader}>
            <div className={styles.title}>
              <Typography variant='heading-lg' ariaLabel='IntegrationDetails_title'>
                {integration.name}
              </Typography>
              <IntegrationStatus integration={integration} />
            </div>
          </div>
          <div className={styles.actions}>
            {integrationData?.docs && (
              <Button
                tag='a'
                text='Docs'
                size='medium'
                contained='secondary'
                iconProps={{ icon: 'link' }}
                href={integrationData.docs}
                target='_blank'
                rel='noreferrer'
                className={styles.docsButton}
              />
            )}
            {integration.isConnected ? (
              <Button
                text='Disconnect'
                onClick={onDisconnect}
                loading={isManageConnectionProcessing}
                typeBtn='outlined'
                contained='tertiary'
                iconProps={{ icon: 'unlink' }}
                size='medium'
              />
            ) : (
              <Button
                disabled={hasConnectionLimit}
                text='Connect'
                onClick={handleConnect}
                loading={isManageConnectionProcessing}
                iconProps={{ icon: 'plug' }}
                size='medium'
              />
            )}
          </div>
        </header>
        {integration.isSettingsAvailable && (
          <div className={styles.navBar}>
            <Tabs
              variant='default'
              tabs={tabs}
              activeTabKey={activeTab}
              handleSelectTab={handleSelectTab}
            ></Tabs>
          </div>
        )}
        <Outlet />
      </div>
    </FullPageWrapper>
  )
})

export const IntegrationDetailsLayout = () => (
  <IntegrationDetailsProvider>
    <IntegrationDetails />
  </IntegrationDetailsProvider>
)
