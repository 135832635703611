import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import type { TollFreeStepsStore } from 'pages/settings/pages/compliance/pages/tollFree/store/tollFreeStepsStore'
import { ExampleModalContent } from 'pages/settings/pages/compliance/ui/ExampleModalContent/ExampleModalContent'

export const useCaseDescMinTextLength = 40
export const useCaseDescMaxTextLength = 4096
export const sampleMessageMinTextLength = 20
export const sampleMessageMaxTextLength = 1024
const useCaseDescExampleMock = `This campaign sends appointment information - confirmation
   & reminder messages, as well as promotional material for special offers to our
   customers once they have booked an appointment with us on our website and opted-
   in to receive promotional and notification SMS from us.`

export class UseCaseStore {
  constructor(private _tollFreeStepsStore: TollFreeStepsStore) {
    makeAutoObservable(this)

    this._tollFreeStepsStore.complianceLayerStore.loadComplianceTollFreeFormFields()
  }

  private _useCaseValue: number | null = null
  private _messageVolumeValue: number | null = null
  private _useCaseDesc = ''
  private _sampleMessage = ''

  get useCases() {
    return this._tollFreeStepsStore.complianceLayerStore.tollFreeUseCases
  }

  get messageVolume() {
    return this._tollFreeStepsStore.complianceLayerStore.tollFreeMessageVolume
  }

  get activeUseCase() {
    return this.useCases.find((useCase) => useCase.id === this.useCaseValue)
  }

  get activeUseCaseTitle() {
    return this.activeUseCase?.label
  }

  get activeUseCaseValue() {
    return this.activeUseCase?.id
  }

  get useCaseValue() {
    return this._useCaseValue
  }

  get messageVolumeValue() {
    return this._messageVolumeValue
  }

  get sampleMessage() {
    return this._sampleMessage
  }

  get useCaseDesc() {
    return this._useCaseDesc
  }

  get isNextDisabled() {
    const ifWithinLimits = (message: string, minLimit: number, maxLimit: number) =>
      message.length >= minLimit && message.length <= maxLimit

    return (
      !this._messageVolumeValue ||
      !this.useCaseValue ||
      !ifWithinLimits(this.useCaseDesc, useCaseDescMinTextLength, useCaseDescMaxTextLength) ||
      !ifWithinLimits(this._sampleMessage, sampleMessageMinTextLength, sampleMessageMaxTextLength)
    )
  }

  setUseCase = (useCase: number) => {
    this._useCaseValue = useCase
  }

  setMessageVolume = (volume: number) => {
    this._messageVolumeValue = volume
  }

  setUseCaseDesc = (useCaseDesc: string) => {
    this._useCaseDesc = useCaseDesc
  }

  setSampleMessage = (sampleMessage: string) => {
    this._sampleMessage = sampleMessage
  }

  openUseCaseExampleModal = () => {
    const id = 'useCaseExampleModal'

    modalStore.addModal({
      id,
      width: 540,
      title: 'Use case description example',
      ModalContent: () => (
        <ExampleModalContent
          activeUseCase={this.activeUseCaseTitle || ''}
          example={useCaseDescExampleMock}
        />
      ),
      primaryAction: {
        text: 'Done',
        onAction: () => modalStore.closeModal(id),
      },
      showCloseButton: false,
    })
  }

  openSampleMessageModal = () => {
    const id = 'sampleMessageModal'
    modalStore.addModal({
      id,
      width: 540,
      title: 'Sample message example',
      hiddenContent: true,
      ModalContent: () => (
        <ExampleModalContent
          activeUseCase={this.activeUseCaseTitle || ''}
          withMustInclude
          example={useCaseDescExampleMock}
        />
      ),
      primaryAction: {
        text: 'Done',
        onAction: () => modalStore.closeModal(id),
      },
      showCloseButton: false,
    })
  }

  nextStepClick = async () => {
    uiStore.changeRoute({
      path: this._tollFreeStepsStore.tollFreeNavigationStore.getNextUrl,
    })
  }
}
