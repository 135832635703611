import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { ITabItem, toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { IResponseVoice, IVoiceType, textToSpeechStore } from 'entities/TextToSpeech'
import { RenameModalStore } from 'widgets/RenameModal'
import { NewCustomVoiceStore } from 'widgets/NewCustomVoiceModal'

type ITextToSpeechStoreProps = {
  onClose: () => void
  onCreateSpeech: (file: File) => void
}

export class TextToSpeechWidgetStore {
  constructor({ onClose, onCreateSpeech }: ITextToSpeechStoreProps) {
    makeAutoObservable(this)
    this.onClose = onClose
    this.onCreateSpeech = onCreateSpeech
    this.initBasicVoice()
  }
  onClose: () => void
  onCreateSpeech: (file: File) => void
  text = ''
  validError = ''

  selectedVoice: IResponseVoice | null = null

  loadingSpeech = false

  search = ''

  activeTabKey: IVoiceType = 'basic'

  private filterVoicesBySearch = (voice: IResponseVoice) =>
    voice.name.toLowerCase().includes(this.search.toLowerCase())

  get basicVoices(): IResponseVoice[] {
    return textToSpeechStore.basicVoices.filter(this.filterVoicesBySearch)
  }
  get premiumVoices(): IResponseVoice[] {
    return textToSpeechStore.premiumVoices.filter(this.filterVoicesBySearch)
  }
  get customVoices(): IResponseVoice[] {
    return textToSpeechStore.customVoices.filter(this.filterVoicesBySearch)
  }

  get isCreateCustomVoice(): boolean {
    return this.activeTabKey === 'custom' && !textToSpeechStore.customVoices.length
  }

  private get voicesObj(): { [key in IVoiceType]: IResponseVoice[] } {
    return {
      basic: this.basicVoices,
      premium: this.premiumVoices,
      custom: this.customVoices,
    }
  }

  get voices(): IResponseVoice[] {
    return this.voicesObj[this.activeTabKey]
  }

  get tabs(): ITabItem<IVoiceType>[] {
    return [
      {
        key: 'basic',
        count: this.basicVoices.length,
        name: 'Basic',
      },
      {
        key: 'premium',
        count: this.premiumVoices.length,
        name: 'Premium',
      },
      {
        key: 'custom',
        icon: 'stars',
        iconProps: {
          color: 'var(--orange-60)',
        },
        count: this.customVoices.length,
        name: 'Clone Your Voice™',
      },
    ]
  }

  private setActiveTabKey = (activeTabKey: IVoiceType) => {
    this.activeTabKey = activeTabKey
  }

  handleSelectTab = (tab: ITabItem<IVoiceType>) => {
    this.setActiveTabKey(tab.key)
  }

  get error() {
    return this.validError || this.errorLength
  }

  get isDisabledPreview() {
    return !this.selectedVoice || this.isEmpty || this.isErrorLength
  }

  get errorLength() {
    return this.isErrorLength && 'Your message must be under 3 minutes'
  }

  get isErrorLength() {
    return this.text.trim().length > 2160
  }

  get textKey() {
    return `${this.selectedVoice?.id}_${this.text.trim()}`
  }

  get audioSpeech() {
    return textToSpeechStore.speechesPreviewMap.get(this.textKey)
  }
  get audioSpeechSrc() {
    return this.audioSpeech?.src
  }

  get audioFileDuration() {
    return this.audioSpeech?.duration
  }

  get duration() {
    return Math.ceil(this.text.trim().length / 12)
  }

  private initBasicVoice = async () => {
    await textToSpeechStore.loadBasicVoices()
    if (textToSpeechStore.basicVoices.length) {
      this.setSelectedVoice(textToSpeechStore.basicVoices[0])
    }
  }

  get isDisabledCreate() {
    return !!this.error
  }

  loadVoices = () => {
    textToSpeechStore.loadPremiumVoices()
    textToSpeechStore.loadCustomVoices()
  }

  get isEmpty() {
    return !this.text.trim()
  }

  loadAudioSpeech = async () => {
    try {
      if (!this.selectedVoice?.id) {
        return
      }
      if (this.isEmpty) {
        return
      }
      if (!this.audioSpeech) {
        this.loadingSpeech = true
        await textToSpeechStore.getSpeechPreview({
          voice_id: this.selectedVoice?.id,
          text: this.text.trim(),
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingSpeech = false
      })
    }
  }

  setText = (text: string): void => {
    this.text = text
  }

  clearError = () => {
    this.validError = ''
  }

  onCreate = () => {
    this.validError = ''
    if (this.isEmpty) {
      this.validError = 'Message cannot be empty'
    }
    if (this.error) return
    new RenameModalStore({
      title: 'Audio name',
      element: 'Audio',
      onSave: async (name: string) => {
        if (!this.selectedVoice?.id) {
          return
        }
        try {
          const file = await textToSpeechStore.createSpeech({
            voice_id: this.selectedVoice?.id,
            text: this.text.trim(),
            title: `${name}.mp3`,
            group: 'broadcast',
          })
          if (file) {
            const audio = new File([file], `${name}.mp3`, {
              type: file.type,
              lastModified: file.lastModified,
            })
            this.onCreateSpeech(audio)
            this.onClose()
          }
        } catch (e) {
          console.error(e)
        }
      },
    })
  }

  setSelectedVoice = (item: IResponseVoice) => {
    this.selectedVoice = item
  }

  setSearch = (value: string) => {
    this.search = value
  }

  getVoicePreview = async (id: string) => textToSpeechStore.getVoicePreview(id, this.activeTabKey)

  onErrorLoadPreview = (id: string) => {
    textToSpeechStore.markAsInvalidPreview(id)
  }

  onCreateCustomVoice = () => {
    new NewCustomVoiceStore({
      onSuccessCreateVoice: (voice) => {
        this.setActiveTabKey('custom')
        this.setSelectedVoice(voice)
      },
    })
  }

  onDeleteCustomVoice = (voice_id: string) => {
    const modalId = nanoid()
    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.ALERT,
      title: 'Delete custom voice?',
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            await textToSpeechStore.deleteCustomVoice(voice_id)
            toastStore.add({
              type: 'success',
              title: 'Custom voice deleted',
            })

            if (this.selectedVoice?.id === voice_id) {
              this.setActiveTabKey('basic')
              this.setSelectedVoice(textToSpeechStore.basicVoices[0])
            }
            modalStore.closeModal(modalId)
          } catch (e) {
            console.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(modalId)
        },
      },
    })
  }
}
