import { FC } from 'react'
import classNames from 'classnames'
import { Icon, type IIconProps } from 'shared/ui/Icon'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

type IChoiceTileProps = {
  iconProps: IIconProps
  title: string
  value: string
  checked: boolean
  setChoiceTile: (value: string) => void
}

export const ChoiceTile: FC<IChoiceTileProps> = ({
  iconProps,
  title,
  value,
  checked,
  setChoiceTile,
}) => {
  return (
    <button
      className={classNames(styles.wrap, {
        [styles.checked]: checked,
      })}
      onClick={() => setChoiceTile(value)}
    >
      <div className={styles.iconWrap}>
        <Icon {...iconProps} />
      </div>
      <Typography ariaLabel='ChoiceTile' variant='body-md-regular'>
        {title}
      </Typography>
    </button>
  )
}
