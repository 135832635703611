import { observable, makeObservable } from 'mobx'
import type { ICrmUser, ICrmUserIntegrationMeta, ICrmUserMeta } from 'entities/Integrations'

export class IntegrationCrmUser<Meta = ICrmUserMeta> {
  id: number
  first_name!: string
  last_name!: string
  user_id!: number
  email!: string
  origin!: ICrmUser<Meta>

  constructor(item: ICrmUser<Meta>) {
    this.id = item.id
    this.first_name = item.first_name
    this.last_name = item.last_name
    this.user_id = item.user_id
    this.email = item.email
    this.origin = item

    makeObservable(this, {
      user_id: observable,
    })
  }

  get avatarInfo() {
    if ((this.origin.meta as ICrmUserIntegrationMeta)?.avatar) {
      return {
        firstName: this.first_name,
        lastName: this.last_name,
        color: this.color,
        number: this.email,
        image: (this.origin.meta as ICrmUserIntegrationMeta)?.avatar,
      }
    }

    return {
      firstName: this.first_name,
      lastName: this.last_name,
      color: this.color,
      number: this.email,
    }
  }

  get name() {
    let output = ''

    const { first_name, last_name, email } = this

    if (first_name) {
      output = first_name

      if (last_name) {
        output += ' ' + last_name
      }
    } else if (email) {
      output = email
    }

    return output
  }

  get color() {
    const palette = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']

    return palette[this.id % palette.length]
  }

  get checkStatus() {
    return Boolean(this.user_id)
  }

  get isDisabled() {
    return this.checkStatus || this.isPending
  }

  get isPending() {
    return !!this.origin.invitation
  }

  get invitationData() {
    return this.origin.invitation
  }
}
