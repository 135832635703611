import { FC, ReactNode, useEffect } from 'react'
import classNames from 'classnames'
import { EnumHelperText, HelperText } from 'shared/ui'
import { MessageField } from 'widgets/MessageField'
import { type SMSMessageFieldStore } from './store/SMSMessageFieldStore'
import styles from './styles.module.scss'

interface SMSMessageFieldProps {
  smsMessageFieldStore: SMSMessageFieldStore
  errorMessage: ReactNode
  setDraggingCondition?: (condition: boolean) => void
}

export const SMSMessageField: FC<SMSMessageFieldProps> = ({
  smsMessageFieldStore,
  errorMessage,
  setDraggingCondition,
}) => {
  const { messageFieldStore } = smsMessageFieldStore

  useEffect(() => {
    return () => {
      smsMessageFieldStore.dispose()
    }
  }, [])

  useEffect(() => {
    if (!!errorMessage) messageFieldStore.setHighlight('error')
    else messageFieldStore.clearHighlight()
  }, [errorMessage])

  return (
    <div className={styles.wrap}>
      <div
        className={classNames(styles.wrap, {
          [styles.activeState]: messageFieldStore.activeMessageField,
        })}
      >
        <div className={styles.alerts} ref={messageFieldStore.setAlertElement} />
        <MessageField messageFieldStore={messageFieldStore} onDragHandler={setDraggingCondition} />
      </div>
      {!!errorMessage && (
        <HelperText
          icon={'alertTriangleSmall'}
          variant={EnumHelperText.Error}
          text={errorMessage}
        />
      )}
    </div>
  )
}
