import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { IInputNumericProps, InputNumeric, makeTextFieldIcon, TextField } from 'shared/ui/TextField'
import { Tooltip } from 'shared/ui'
import { SchedulePeriod } from 'shared/ui/Schedule/ui/SchedulePeriod'
import { useCampaignScheduleContext } from 'pages/campaigns/ui/CampaignSchedule/context/context'
import styles from './styles.module.scss'

type ILaterContentProps = {
  dropdownId: string
}

export const LaterContent = observer(({ dropdownId }: ILaterContentProps) => {
  const { waitValue, setWaitValue, schedulePeriodStore, handleValidate, setLastValidValue } =
    useCampaignScheduleContext()
  const [isHideTooltip, setHideTooltip] = useState(false)

  const onFocus = () => {
    if (Number(waitValue)) {
      setLastValidValue(waitValue)
    }
    setHideTooltip(true)
  }

  const onBlur = () => {
    handleValidate()
    setHideTooltip(false)
  }

  useEffect(() => {
    return handleValidate
  }, [])

  return (
    <div className={styles.wrap}>
      <Tooltip label={'Wait'} placement='left' visibility={isHideTooltip ? 'hidden' : undefined}>
        <TextField
          size={'small'}
          variant={'outline'}
          leftActions={[
            makeTextFieldIcon({
              icon: 'sandGlasses',
            }),
          ]}
          Input={InputNumeric}
          value={waitValue}
          onChange={setWaitValue}
          InputProps={
            {
              maxLength: 3,
              allowNegative: false,
              decimalScale: 0,
            } as IInputNumericProps
          }
          onFocus={onFocus}
          onBlur={onBlur}
          className={styles.input}
        />
      </Tooltip>

      <SchedulePeriod store={schedulePeriodStore} parentsDropdownId={[dropdownId]} width={262} />
    </div>
  )
})
