import { FC } from 'react'
import { Status } from 'shared/ui'
import { type ISubscriptionStatus } from 'entities/Subscription'
import { subscriptionStatusConfig } from 'entities/Subscription/ui/Status/config'

type ISubscriptionStatusProps = {
  status: ISubscriptionStatus
}

export const SubscriptionStatus: FC<ISubscriptionStatusProps> = ({ status }) => {
  return <Status {...subscriptionStatusConfig[status]} />
}
