import React from 'react'
import { Icon, Typography } from 'shared/ui'
import type { IMultiSelectedValues } from 'widgets/SendFromDropdown/store/types'

import styles from './styles.module.scss'

type IMultiSelectCardInfoProps = {
  selectedItem: IMultiSelectedValues
}

export const MultiSelectCardInfo = ({ selectedItem }: IMultiSelectCardInfoProps) => {
  return (
    <>
      <div className={styles.icon}>
        <Icon icon={selectedItem.type === 'Short Code' ? 'hash2' : 'inboxOpen'} fontSize={16} />
      </div>
      <Typography
        className={styles.selectedItemLabel}
        variant={'text-input-field'}
        ariaLabel={'name'}
      >
        <div className={styles.selectedItem__name}>{selectedItem.name}</div>
        <Icon icon={'circleDot'} fontSize={2} className={styles.selectedItem__dot} />
        {selectedItem.is_aircall && (
          <Icon icon={'aircall'} fontSize={16} className={styles.selectedItem__aircallIcon} />
        )}
        <div className={styles.selectedItem__number}>{selectedItem.label}</div>
      </Typography>
    </>
  )
}
