import type { IDropdownItem } from 'shared/ui'

export enum IntegrationSyncType {
  oneWay = 'oneWay',
  twoWay = 'twoWay',
}

export const SYNC_TYPE_DICT = {
  [IntegrationSyncType.oneWay]: 'Sync to Salesmsg: HubSpot -> Salesmsg',
  [IntegrationSyncType.twoWay]: 'Two-way sync',
}

export const SYNC_TYPE_OPTIONS: IDropdownItem[] = [
  { id: IntegrationSyncType.oneWay, label: SYNC_TYPE_DICT[IntegrationSyncType.oneWay] },
  { id: IntegrationSyncType.twoWay, label: SYNC_TYPE_DICT[IntegrationSyncType.twoWay] },
]
