import React, { ReactNode, PropsWithChildren } from 'react'
import classnames from 'classnames'
import { Icon, TextStylesType, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type ITitledRowProps = {
  title: ReactNode
  subtitle?: ReactNode
  label?: ReactNode
  className?: string
  titleVariant?: TextStylesType
  subtitleVariant?: TextStylesType
  labelVariant?: TextStylesType
  align?: 'end' | 'start' | 'center' | 'normal'
  verticalAlign?: 'end' | 'start' | 'center' | 'normal'
  tooltip?: string | boolean
}

export const TitledRow: React.FC<PropsWithChildren<ITitledRowProps>> = ({
  title,
  subtitle,
  label,
  children,
  className,
  titleVariant = 'body-md-medium',
  subtitleVariant = 'body-md-medium',
  labelVariant = 'body-md-regular',
  align = 'end',
  verticalAlign = 'center',
  tooltip,
}) => {
  return (
    <div
      className={classnames(styles.root, { [styles.oneColumn]: !children }, className)}
      style={{ justifyItems: align, alignItems: verticalAlign }}
    >
      <div className={styles.main}>
        <div className={styles.header}>
          <Typography ariaLabel={'title'} variant={titleVariant}>
            {title}
          </Typography>
          {tooltip && (
            <Tooltip
              label={<div style={{ wordBreak: 'break-word' }}>{tooltip}</div>}
              placement={'top'}
            >
              <Icon fontSize={14} icon={'question'} color={'var(--content-neutral-primary)'} />
            </Tooltip>
          )}
          {subtitle &&
            (typeof subtitle === 'string' ? (
              <Typography ariaLabel={'subtitle'} variant={subtitleVariant} color={'tertiary'}>
                {subtitle}
              </Typography>
            ) : (
              subtitle
            ))}
        </div>
        {label && (
          <Typography ariaLabel={'label'} variant={labelVariant} color={'tertiary'}>
            {label}
          </Typography>
        )}
      </div>
      {children}
    </div>
  )
}
