import { observer } from 'mobx-react-lite'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'
import { usePathSegment } from 'pages/settings/pages/compliance/pages/localNumbers/hooks/usePathSegment'
import { CommonOptInMethod } from 'pages/settings/pages/compliance/ui/CommonOptInMethod/CommonOptInMethod'
import { RegistrationErrorAlert } from 'pages/settings/pages/compliance/pages/localNumbers/ui/RegistrationErrorAlert/RegistrationErrorAlert'

export const OptInMethod = observer(() => {
  const { optInMethodStore, stepsStore } = useLocalNumbersStepsContext()
  const { stepLoading } = stepsStore
  const { isStep3Disabled, setOptInMethods, isOptInMethodActive, campaignOptInMethodNextClick } =
    optInMethodStore
  const lastPathSegment = usePathSegment()

  return (
    <CommonOptInMethod
      stepLoading={stepLoading}
      isNextDisabled={isStep3Disabled}
      onNextClick={() => campaignOptInMethodNextClick(lastPathSegment)}
      isOptInMethodActive={isOptInMethodActive}
      setOptInMethods={setOptInMethods}
      errorAlert={<RegistrationErrorAlert />}
    />
  )
})
