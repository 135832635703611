export const ImageAccept = ['image/jpeg', 'image/png', 'image/gif']
export const VCardAccept = ['image/vcard', 'text/vcard', '.vcard']
export const VideoAccept = ['video/mp4', '.mov']
export const AudioAccept = ['audio/mpeg']
export const FilesAccept = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
export const AllFilesAccept = [
  ...ImageAccept,
  ...VCardAccept,
  ...VideoAccept,
  ...AudioAccept,
  ...FilesAccept,
]
export const ExtendedFilesAccept = [...FilesAccept, 'text/markdown', 'text/plain', 'text/html']
