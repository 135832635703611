// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h3nj0p2soINLkjxZ2QKx{display:flex;align-items:flex-start;justify-content:center;width:100%;height:100vh;padding:10px;line-height:22px}", "",{"version":3,"sources":["webpack://./src/shared/ui/ServiceError/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,sBAAA,CACA,UAAA,CACA,YAAA,CACA,YAAA,CACA,gBAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  width: 100%;\n  height: 100vh;\n  padding: 10px;\n  line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "h3nj0p2soINLkjxZ2QKx"
};
export default ___CSS_LOADER_EXPORT___;
