import { observer } from 'mobx-react-lite'
import React from 'react'
import { Dropdown, type IIconsVector } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { Integration } from 'entities/Integrations'
import { useIntegrationListContext } from 'pages/settings/pages/integrations/context/integrationListContext'
import { SettingsRoutes } from 'pages/settings/route/type'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'

type IIntegrationListCardActionsProps = {
  integration: Integration
}

export const IntegrationListCardActions = observer(
  ({ integration }: IIntegrationListCardActionsProps) => {
    const { onDisconnect, onViewWebhook, availableIntegrationsForWebhook } =
      useIntegrationListContext()

    const dropdownItems = [
      ...(availableIntegrationsForWebhook.has(integration.key)
        ? [
            {
              id: 'webhook',
              label: 'View webhook',
              iconL: 'link' as IIconsVector,
              onClick: () => onViewWebhook(integration),
            },
          ]
        : []),
      ...(integration.isConnectedSettingsAvailable
        ? [
            {
              id: 'settings',
              label: 'Settings',
              iconL: 'settings2' as IIconsVector,
              onClick: () =>
                uiStore.changeRouteViaVue({
                  path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${integration.key}/${IntegrationsRoutes.settings}`,
                }),
            },
          ]
        : []),
      {
        id: 'disconnect',
        label: 'Disconnect',
        iconL: 'unlink' as IIconsVector,
        onClick: () => onDisconnect(integration),
      },
    ]

    return (
      <Dropdown
        typeButton={'icon-button'}
        icon={'settings'}
        placement={'bottom-end'}
        items={dropdownItems}
        width={200}
        iconButtonProps={{
          size: 'small',
          ariaLabel: 'IntegrationDropdown_button',
          variant: 'icon',
        }}
        ariaLabel={'IntegrationDropdown'}
      />
    )
  }
)
