import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Button, Table } from 'shared/ui'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import { getColumns } from './constants'

export const CustomPaymentsScheduleTable = observer(() => {
  const { paymentPlanType, paymentScheduleTable, isEdit, retryPayment } =
    adminOrganizationCustomPlanStore

  const {
    paymentsPlanArray,
    handleDeletePaymentPlanItem,
    addPaymentPlan,
    handleChangeDatePaymentPlanItem,
  } = paymentScheduleTable

  const isCustom = paymentPlanType === 'custom' || isEdit

  return (
    <Box mb={2}>
      <Box
        sx={{
          position: 'relative',
          height: `calc(40px + 12px + ${paymentsPlanArray.length * 40}px)`,
        }}
      >
        <Table
          columns={getColumns(
            handleDeletePaymentPlanItem,
            handleChangeDatePaymentPlanItem,
            retryPayment
          )}
          rows={paymentsPlanArray}
          noPagination
        />
      </Box>

      {isCustom && <Button onClick={addPaymentPlan} text='Add Payment' typeBtn='text' icon='add' />}
    </Box>
  )
})
