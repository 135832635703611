import classNames from 'classnames'
import { TableRow as MuiTableRow } from '@mui/material'
import React, { isValidElement, useState } from 'react'
import commonStyles from 'shared/ui/Table/Table.module.scss'
import { TableCell } from 'shared/ui/Table/TableCell/TableCell'
import { PinnedCell } from 'shared/ui/Table/PinnedCell/PinnedCell'
import { Actions, IColumn, IRow, ITableProps, ITablePropsContent } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table/EmptyCell/EmptyCell'
import { DayjsFormats, getAriaLabel, isDateString, isEmptyOrSpacesString } from 'shared/lib'
import { CellWithoutContent } from 'shared/ui/Table'
import { uiStore } from 'shared/store/uiStore'
import { LoadMoreExpandedRow } from 'shared/ui/Table/LoadMoreExpandedRow/LoadMoreExpandedRow'
import styles from './styles.module.scss'
import { CheckboxCell } from '../CheckboxCell/CheckboxCell'

export type ITableRowProps<TRow> = {
  pinnedColumns: IColumn<TRow>[]
  withCheckbox: ITableProps<TRow>['withCheckbox']
  hideExpandColumnsIds?: ITableProps<TRow>['hideExpandColumnsIds']
  row: IRow<TRow>
  unpinnedColumns: IColumn<TRow>[]
  selected: boolean
  isScrollLeft: boolean
  inAction: boolean
  expanded?: boolean
  isExpandedChild?: boolean
  isMoreExpand?: boolean
  withActions?: boolean
  isHoveredRowActions?: boolean
  isCursor?: boolean
  onRowSelect: ITablePropsContent<TRow>['onRowSelect']
  onRowClick: ITableProps<TRow>['onRowClick']
  getRowLink: ITableProps<TRow>['getRowLink']
  activeRowId: ITableProps<TRow>['activeRowId']
  isCheckDate: ITableProps<TRow>['isCheckDate']
  defaultWidthColumn: ITableProps<TRow>['defaultWidthColumn']
  defaultMinWidthColumn: ITableProps<TRow>['defaultMinWidthColumn']
  defaultMaxWidthColumn: ITableProps<TRow>['defaultMaxWidthColumn']
}

export const TableRow = <TRow,>({
  pinnedColumns,
  withCheckbox,
  hideExpandColumnsIds = [],
  isExpandedChild,
  isMoreExpand,
  row,
  unpinnedColumns,
  selected,
  inAction,
  onRowSelect,
  onRowClick,
  activeRowId,
  isCheckDate,
  getRowLink,
  defaultWidthColumn,
  defaultMinWidthColumn,
  defaultMaxWidthColumn,
  isScrollLeft,
  withActions,
  isCursor,
  isHoveredRowActions,
}: ITableRowProps<TRow>) => {
  const [isHover, setIsHover] = useState(false)
  const [isRightOptionsOpen, setIsRightOptionsOpen] = useState(false)
  const defaultRenderRowCell = <TRow,>(column: IColumn<TRow>, row: IRow<TRow>) => {
    if (isExpandedChild && hideExpandColumnsIds?.includes(column.field as string)) return null

    if (column.renderRowCell) {
      const content = column.renderRowCell(row, column, {
        hidden: styles.hidedElem,
        isHover: isHover,
      })

      if (content === null || content === undefined || content === '') {
        return <EmptyCell />
      }

      return content
    }

    if (column.isCellWithoutContent) {
      const isWithoutContent = column.isCellWithoutContent(row)

      if (isWithoutContent) return <CellWithoutContent />
    }

    const cellValue = row[column.field as keyof TRow]

    if (typeof cellValue === 'number') {
      return <>{cellValue}</>
    }

    if (!cellValue || isEmptyOrSpacesString(String(cellValue))) {
      return <EmptyCell />
    }

    const isDateValue = isDateString(String(cellValue))

    if (isDateValue && isCheckDate) {
      return uiStore.dayjs(String(cellValue)).format(DayjsFormats.fullWithAtDash)
    }

    return isValidElement(cellValue) || typeof cellValue === 'string'
      ? cellValue
      : 'Error load data'
  }

  const activeRow = row.id === activeRowId

  const toggleSelected = (isSelected: boolean) => {
    onRowSelect?.(row, isSelected)
  }

  if (isMoreExpand) {
    return (
      <PinnedCell isScrollLeft={isScrollLeft}>
        <LoadMoreExpandedRow row={row} />
      </PinnedCell>
    )
  }

  const columnCellRender = (column: IColumn<TRow>, colSpan?: number) => {
    const getLink = () => {
      if (column.disableLink) {
        return
      }
      if (column.getIsDisableLink && column.getIsDisableLink(row)) {
        return
      }
      if (column.link) {
        return column.link
      }
      return getRowLink?.(row)
    }
    const link = getLink()
    const isPointer = () => {
      if (row.isDisabled) return false
      if (column.onCheckCellPointer) {
        return column.onCheckCellPointer(row)
      }

      return Boolean(column.onCellClick || onRowClick || link || isCursor)
    }

    const isCellClick = () => {
      if (column.onCellClick) {
        return () => column.onCellClick?.(row)
      }
      if (onRowClick) {
        return () => onRowClick(row)
      }
    }
    return (
      <TableCell
        key={column.name}
        ariaLabel={column.ariaLabel}
        className={classNames({ [styles.disabled]: row.isDisabled })}
        width={column.width || defaultWidthColumn}
        maxWidth={column.maxWidth || defaultMaxWidthColumn}
        minWidth={column.minWidth || defaultMinWidthColumn}
        padding={column.padding}
        link={link}
        isPointer={isPointer()}
        onCellClick={isCellClick()}
        sx={{
          paddingLeft: (isExpandedChild && column.paddingInExpand) || undefined,
          cursor: isPointer() ? 'pointer' : 'default',
        }}
        colSpan={colSpan}
        isOverflowInitial={column.isOverflowInitial}
      >
        {defaultRenderRowCell(column, row)}
      </TableCell>
    )
  }

  const rowContent = (
    <MuiTableRow
      key={row.id}
      aria-label={row.ariaLabel ? getAriaLabel(row.ariaLabel) : undefined}
      className={classNames(styles.row, commonStyles.tableRow, {
        [commonStyles.activeRow]: activeRow,
        [commonStyles.selectedRow]: selected,
        [commonStyles.inActionRow]: inAction,
        [commonStyles.tableRowHovered]: isRightOptionsOpen || isHover,
      })}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
    >
      {(!!pinnedColumns.length || withCheckbox) && (
        <PinnedCell
          isScrollLeft={isScrollLeft}
          width={!pinnedColumns.length ? 40 : undefined}
          withEmptyCell={pinnedColumns.length === 1 && !withCheckbox}
        >
          {withCheckbox && (
            <CheckboxCell
              disabled={row.isDisabled || row.isCheckboxDisabled}
              isHeader={true}
              selected={selected}
              onChangeValue={toggleSelected}
            />
          )}

          {pinnedColumns.map((column) => columnCellRender(column))}
        </PinnedCell>
      )}
      {unpinnedColumns.map((column, index, self) =>
        columnCellRender(column, index === self.length - 1 && !withActions ? 2 : undefined)
      )}

      {withActions && (
        <td
          className={classNames(styles.rowRightActions, commonStyles.rowRightActions, {
            [styles.hoveredRow]: isRightOptionsOpen,
            [styles.hoveredRowActions]: isHoveredRowActions,
          })}
        >
          {!row.isDisabled && (
            <Actions
              iconButtonProps={{ size: 'small', ariaLabel: 'TableRow_actions', color: 'tertiary' }}
              justifyContent={'flex-end'}
              noVisibleActions
              onChangeOpen={setIsRightOptionsOpen}
              {...row.actionsProps}
              showItems={row.actionsProps?.showItems ?? 0}
              actions={row.actionsProps?.actions || row.actions || []}
            />
          )}
        </td>
      )}
    </MuiTableRow>
  )

  return <>{rowContent}</>
}
