// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qa7av1yixM7IAvx8ikYE{width:283px;display:flex;gap:12px;align-items:center;padding:8px 12px}.EiLWRPl6PVj7uLapGtWQ{display:flex;gap:8px}.RurA9gXCZsNlS1szWZxi{height:24px;width:1px;background:var(--border-neutral-default)}.RhBnzjFlICQUVojvdFot input{color:var(--yellow-90)}", "",{"version":3,"sources":["webpack://./src/widgets/MergeField/ui/MergeFieldTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,WAAA,CACA,SAAA,CACA,wCAAA,CAIA,4BACE,sBAAA","sourcesContent":[".wrap {\n  width: 283px;\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  padding: 8px 12px;\n}\n\n.left {\n  display: flex;\n  gap: 8px;\n}\n\n.divider {\n  height: 24px;\n  width: 1px;\n  background: var(--border-neutral-default);\n}\n\n.warnInput {\n  & input {\n    color: var(--yellow-90);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "qa7av1yixM7IAvx8ikYE",
	"left": "EiLWRPl6PVj7uLapGtWQ",
	"divider": "RurA9gXCZsNlS1szWZxi",
	"warnInput": "RhBnzjFlICQUVojvdFot"
};
export default ___CSS_LOADER_EXPORT___;
