export type internalAutomatedInfoType =
  | 'ai'
  | 'broadcast'
  | 'campaign'
  | 'keyword'
  | 'triggers'
  | 'workflows'
export type externalAutomatedInfoSource =
  | 'hubspot'
  | 'activeCampaign'
  | 'keap'
  | 'pipedrive'
  | 'salesforce'
  | 'zapier'
  | 'make'
  | 'salesmessage'

export type internalAutomatedInfo = {
  isInternal: boolean
  source: externalAutomatedInfoSource | null
  type: internalAutomatedInfoType
  name: string | null
}

export type externalAutomatedInfo = {
  isInternal: boolean
  source: externalAutomatedInfoSource
  type: internalAutomatedInfoType | null
  name: string | null
}

export enum enumAutomatedInfoType {
  ai = 'AI Agent',
  broadcast = 'Broadcast',
  campaign = 'Campaign',
  keyword = 'Keyword',
  triggers = 'Trigger',
  workflows = 'Workflow',
}

export enum enumAutomatedInfoSource {
  hubspot = 'HubSpot',
  activeCampaign = 'ActiveCampaign',
  keap = 'Keap',
  pipedrive = 'Pipedrive',
  salesforce = 'Salesforce',
  zapier = 'Zapier',
  make = 'Make',
  salesmessage = 'Salesmessage',
}

export enum enumAutomatedInfoIcon {
  ai = 'chatBot',
  broadcast = 'broadcasts',
  campaign = 'campaigns',
  keyword = 'keywords',
  triggers = 'lightning2',
  workflows = 'workflows',
  hubspot = 'hubspot',
  activeCampaign = 'activecampaign',
  keap = 'infusionsoft',
  pipedrive = 'pipedrive',
  salesforce = 'salesforce',
  zapier = 'zapier',
  make = 'make',
  salesmessage = 'salesmessage',
}

export type automatedInfo = internalAutomatedInfo | externalAutomatedInfo
