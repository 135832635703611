import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { IconButton } from 'shared/ui'
import { ContactCard } from 'entities/Contacts'
import { ConversationRoutes } from 'entities/ExportRouters'
import { useConversationSearchContext } from 'widgets/ConversationHeaderSearch/context'
import styles from './styles.module.scss'

export const MobileSearch = observer(() => {
  const { searchDropdownStore, clear, onClose, isHide, clearFilters } =
    useConversationSearchContext()
  const navigate = useNavigate()
  const onBack = () => {
    clear(false)
    onClose()
    clearFilters()
    navigate(`/${ConversationRoutes.root}`)
  }
  if (!isHide && searchDropdownStore.searchDropdownContactsStore.selectedItems.length) {
    return (
      <div className={styles.wrap}>
        <IconButton
          ariaLabel={'back'}
          color={'secondary'}
          variant={'icon'}
          icon={'chevronLeft'}
          onClick={onBack}
        />
        {searchDropdownStore.searchDropdownContactsStore.selectedItems.map((contact) => (
          <ContactCard key={contact.id} item={contact} size={'mini'} />
        ))}
      </div>
    )
  }
  return null
})
