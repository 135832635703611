import { Blocker } from 'react-router'
import { isEqual } from 'lodash'
import { AxiosError } from 'axios'
import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import {
  Chatbot,
  ChatbotApi,
  ChatbotErrors,
  ChatbotInstructionType,
  type IChatbotInstruction,
  type IChatbotPayload,
} from 'entities/Chatbot'
import { ChatbotTemplateList } from 'pages/chatbot/config'
import { ChatbotKnowledgeBaseStore } from 'pages/chatbot/store/chatbotKnowledgeBaseStore'
import { ChatbotNumberId } from './chatbotNumberId'
import { ChatbotMessage } from './chatbotMessageStore'
import { ChatbotEnrollment } from './chatbotEnrollmentStore'
import { ChatbotFieldsControl } from './chatbotFieldsControlStore'
import { ChatbotAction } from './chatbotActionStore'
import { chatbotOperation } from './chatbotOperationStore'

export class ChatbotStore {
  id: number | null = null
  model = new Chatbot()
  errors = new ChatbotErrors(this.model)
  numberId = new ChatbotNumberId(this.model)
  message = new ChatbotMessage(this.model, this.numberId.store.isAircall)
  enrollment = new ChatbotEnrollment(this.model)
  fieldsControl = new ChatbotFieldsControl(this.model)
  successActions = new ChatbotAction(this.model.successActions, this.errors.successActions)
  fallbackActions = new ChatbotAction(this.model.fallbackActions, this.errors.fallbackActions)
  instructions = new Map<ChatbotInstructionType, IChatbotInstruction>()
  knowledgeBaseStore = new ChatbotKnowledgeBaseStore()

  private _isDragging = false
  private _initialized = false
  private _dirty = false
  private _originData: IChatbotPayload = this.model.toJSON()
  private _locked = false
  private _loaded = false
  private _disposeModelChange: IReactionDisposer | null = null
  private _disposeIsAircallReaction: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this.reactionIsAircallInbox()

    this._detectDirty()
  }

  get dirty() {
    return this._dirty
  }

  get locked() {
    return this._locked
  }

  get loaded() {
    return this._loaded
  }

  get initialized() {
    return this._initialized
  }

  get isDraggingCondition() {
    return this._isDragging
  }

  get activeTemplates() {
    return ChatbotTemplateList.filter(
      (template) =>
        this.instructions.has(template.type) ||
        template.type === ChatbotInstructionType.LeadQualification
    )
  }

  setDraggingCondition = (condition: boolean) => {
    this._isDragging = condition
  }

  reactionIsAircallInbox = () => {
    this._disposeIsAircallReaction?.()
    this._disposeIsAircallReaction = reaction(
      () => this.numberId.store.isAircall,
      (isAircall) => {
        this.message.field.setIsCurrentAirCall(isAircall)
      }
    )
  }

  checkChanges = () => this._dirty && !isEqual(this._originData, this.model.toJSON())

  createChatbot: (confirmTest?: boolean) => Promise<number | null> = async (confirmTest) => {
    if (confirmTest) {
      const confirmed = await chatbotOperation.confirmTest(this.model.name)

      if (!confirmed) return null
    }

    this._locked = true

    try {
      const { data } = await chatbotOperation.create(this.model.toJSON())

      this.errors.clear()

      if (data.is_active) await chatbotOperation.enroll(data.id, data.name, data.filters)

      return runInAction(() => {
        this.id = data.id

        this._detectDirty()

        return data.id
      })
    } catch (error) {
      if (error instanceof AxiosError) this.errors.sync(error.response?.data?.errors)

      console.error(error)

      return null
    } finally {
      runInAction(() => {
        this._locked = false
      })
    }
  }

  confirmCreateChatbot = async (blocker: Blocker) => {
    this._locked = true

    try {
      await chatbotOperation.confirmCreate(this.model.toJSON())

      blocker.proceed?.()

      return true
    } catch (error) {
      if (error instanceof AxiosError) this.errors.sync(error.response?.data?.errors)

      blocker.reset?.()
      return false
    } finally {
      runInAction(() => {
        this._locked = false
      })
    }
  }

  createAndPublishChatbot: (confirmTest?: boolean) => Promise<number | null> = async () => {
    this._locked = true

    try {
      const { data } = await chatbotOperation.create({
        ...this.model.toJSON(),
        is_active: true,
      })

      this.errors.clear()

      if (data.is_active) await chatbotOperation.enroll(data.id, data.name, data.filters)

      return runInAction(() => {
        this.id = data.id

        this._detectDirty()

        return data.id
      })
    } catch (error) {
      if (error instanceof AxiosError) this.errors.sync(error.response?.data?.errors)

      console.error(error)

      return null
    } finally {
      runInAction(() => {
        this._locked = false
      })
    }
  }

  initResources = async () => {
    try {
      const { data: instructions } = await ChatbotApi.getInstructions()

      return runInAction(async () => {
        instructions.map((instruction) => this.instructions.set(instruction.type, instruction))

        return (this._initialized = true)
      })
    } catch (error) {
      console.error(error)

      return runInAction(() => (this._initialized = false))
    }
  }

  loadChatbot = async (id: number) => {
    this._locked = true

    try {
      const { data } = await chatbotOperation.load(id)

      runInAction(async () => {
        this.id = data.id
        this.model.syncOrigin(data)
        await this.enrollment.syncFilters()

        this._originData = this.model.toJSON()
        this._loaded = true

        this._detectDirty()
      })

      return true
    } catch (error) {
      console.error(error)
      return false
    } finally {
      runInAction(() => {
        this._locked = false
      })
    }
  }

  publishChatbot = async () => {
    if (this.id == null) return false

    try {
      const { data } = await chatbotOperation.update(this.id, {
        ...this.model.toJSON(),
        is_active: true,
      })

      this.errors.clear()

      if (data.is_active) await chatbotOperation.enroll(data.id, data.name, data.filters)

      this._detectDirty()

      return true
    } catch (error) {
      if (error instanceof AxiosError) this.errors.sync(error.response?.data?.errors)

      console.error(error)
      return false
    }
  }

  updateChatbot = async (confirmTest?: boolean) => {
    if (this.id == null) return false

    if (confirmTest) {
      const confirmed = await chatbotOperation.confirmTest(this.model.name)

      if (!confirmed) return false
    }

    this._locked = true

    try {
      const payload = this.model.toJSON()
      await chatbotOperation.update(this.id, payload)

      this._originData = payload

      this.errors.clear()
      this._detectDirty()
      return true
    } catch (error) {
      if (error instanceof AxiosError) this.errors.sync(error.response?.data?.errors)

      console.error(error)
      return false
    } finally {
      runInAction(() => {
        this._locked = false
      })
    }
  }

  confirmUpdateChatbot = async (blocker: Blocker) => {
    if (this.id == null) return false

    this._locked = true

    try {
      const payload = this.model.toJSON()
      await chatbotOperation.confirmUpdate(this.id, payload)

      blocker.proceed?.()

      return true
    } catch (error) {
      if (error instanceof AxiosError) this.errors.sync(error.response?.data?.errors)

      blocker.reset?.()
      return false
    } finally {
      runInAction(() => {
        this._locked = false
      })
    }
  }

  dispose = () => {
    this.message.dispose()
    this.numberId.dispose()
    this.enrollment.dispose()
    this.fieldsControl.dispose()

    this._disposeModelChange?.()
  }

  private _detectDirty = () => {
    this._disposeModelChange?.()
    this._dirty = false

    this._disposeModelChange = reaction(
      () => this.model.toJSON(),
      () => {
        this._disposeModelChange?.()
        this._dirty = true
      }
    )
  }
}
