import classNames from 'classnames'
import { ReactNode } from 'react'
import { Typography } from 'shared/ui'
import { IconCopyButton } from 'shared/ui/IconButton/IconCopyButton/IconCopyButton'
import { Attachments } from 'widgets/MessageField'
import styles from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/styles.module.scss'
import {
  EditValue,
  IEditValueProps,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/EditValue'
import {
  BrandTwoFaVerificationStatus,
  IStatus,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/SelectStatusEditStatus/VerificationStatus'

export type IAdminValueContentProps = IEditValueProps & {
  isEdit?: boolean
  isError?: boolean
  isNoEdit?: boolean
  renderValue?: (value: IEditValueProps['value']) => ReactNode
  noCopy?: boolean
}

export const AdminValueContent = (props: IAdminValueContentProps) => {
  const { value, renderValue, typeEdit, isEdit, isError, isNoEdit } = props

  const getValueContent = () => {
    if (renderValue) {
      return renderValue(value)
    }
    if (typeEdit === 'select') {
      return value?.label
    }
    if (typeEdit === 'selectValue') {
      return value || ''
    }
    if (typeEdit === 'multiSelect') {
      return value?.map((item) => item.label).join(', ')
    }
    if (typeEdit === 'attachments') {
      return (
        <div className={styles.wrapImages}>
          <Attachments attachments={value} medium />
        </div>
      )
    }
    if (typeEdit === 'selectStatus') {
      return <BrandTwoFaVerificationStatus status={value as IStatus} />
    }
    return value
  }

  const valueContent = getValueContent()

  const copyValue =
    ((typeof valueContent === 'string' || typeof valueContent === 'number') && valueContent) ||
    (typeof value === 'string' && value)

  return isEdit && !isError && !isNoEdit ? (
    <EditValue {...props} />
  ) : (
    <div className={classNames(styles.value, copyValue && styles.withCopy)}>
      <Typography
        variant={'body-md-regular'}
        color={isError ? 'var(--red-100)' : undefined}
        ariaLabel={'value'}
      >
        {valueContent}
      </Typography>
      {copyValue && (
        <div className={styles.copy}>
          <IconCopyButton value={copyValue} />
        </div>
      )}
    </div>
  )
}
