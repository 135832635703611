import { useMemo } from 'react'
import { Button } from 'shared/ui/Button/ui/Button'
import { Typography } from 'shared/ui'
import { TableStore } from 'shared/ui/Table/store/tableStore'
import { numberFormat } from 'shared/lib'
import styles from './styles.module.scss'

type IUiHeaderCenterContentProps = Pick<
  TableStore,
  'bulkAllMode' | 'selectedIds' | 'changeBulkAllMode' | 'element' | 'total'
>

export const UiHeaderCenterContent = ({
  bulkAllMode,
  selectedIds,
  changeBulkAllMode,
  total,
  element,
}: IUiHeaderCenterContentProps) => {
  const correctText = total > 1 ? `${element}s are selected` : `${element} is selected`

  const selectedCountText = useMemo(
    () =>
      bulkAllMode
        ? `All ${numberFormat({ value: total })} ${correctText}`
        : `You have selected ${numberFormat({
            value: selectedIds.length,
          })} of ${numberFormat({
            value: total,
          })} ${element}s`,
    [bulkAllMode, selectedIds.length]
  )

  const buttonText = useMemo(
    () => (bulkAllMode ? 'Clear selection' : `Select all ${element}s`),
    [bulkAllMode]
  )

  const handleClick = () => {
    changeBulkAllMode(!bulkAllMode)
  }

  return (
    <>
      <Typography
        variant={'body-sm-medium'}
        className={styles.text}
        ariaLabel={'selectedCountText'}
      >
        {selectedCountText}
      </Typography>
      <Button
        text={buttonText}
        typeBtn='text'
        contained='secondary'
        size='small'
        intent={'positive'}
        textClassname={styles.selectAllButton}
        onClick={handleClick}
      />
    </>
  )
}
