import React from 'react'
import classNames from 'classnames'
import emoji from 'emoji-datasource'
import { EmojiKeys } from 'shared/ui/Emoji/types'
import styles from './styles.module.scss'

type IEmojiItem = (typeof emoji)[0]

const icons = emoji.reduce<{ [key: string]: IEmojiItem }>((state, current) => {
  return {
    ...state,
    [current.short_name]: current,
  }
}, {})

type IPropsEmoji = {
  icon?: EmojiKeys
  fontSize?: number
}

const Emoji: React.FC<IPropsEmoji> = ({ icon = 'evergreen_tree', fontSize = 16 }) => {
  return (
    <div
      className={classNames(styles.wrap, styles.tertiary)}
      style={{ fontSize, lineHeight: `${fontSize}px` }}
    >
      <div
        className={classNames(styles.icon)}
        dangerouslySetInnerHTML={{ __html: `&#x${icons[icon].unified}` }}
      />
    </div>
  )
}

export { Emoji }
