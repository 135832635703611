import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { ReactNode } from 'react'
import { isEqual, omit } from 'lodash'
import { Blocker } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import { ITabItem, toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import type {
  IParamsTestTrigger,
  IParamsTrigger,
  IResponseTrigger,
} from 'entities/Trigger/api/type'
import type { Phone } from 'entities/Phone/model/Phone'
import { numbersStore } from 'entities/Phone'
import { inboxesStore } from 'entities/Inbox'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import { TagsControl } from 'entities/Tags'
import { Integration } from 'entities/Integrations/model/Integration'
import { integrationsStore, IntegrationKey } from 'entities/Integrations'
import {
  EnumModeTrigger,
  EnumTypeTrigger,
  ITriggerCreateField,
  ITriggerCreateFieldEnum,
} from 'widgets/TriggerView/store/types'
import { TriggerSMSStore } from 'widgets/TriggerView/ui/TriggerSMS/store/TriggerSMSStore'
import { IntegrationDropdownStore } from 'widgets/IntegrationDropdown'
import { SendFromDropdownStore } from 'widgets/SendFromDropdown'
import { TrackConversionStore } from 'widgets/TrackConversions/store/trackConversionStore'
import { RinglessMessageFieldStore } from 'widgets/MessageFormFields/RinglessMessageField'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'

export class TriggerViewStore {
  loading = true
  isSaving = false
  name = ''
  mode: EnumModeTrigger = EnumModeTrigger.Create

  private _responseEditTrigger: IResponseTrigger | null = null
  private _type: EnumTypeTrigger = EnumTypeTrigger.Sms
  private _isOpenAdvanced = false
  private _cacheParams: IParamsTrigger | null = null
  private _modalKeepEditing = nanoid()

  triggerSMSStore = new TriggerSMSStore({
    onFocus: () => {
      this.setError('smsMessage', '')
    },
  })
  ringlessMessageFieldStore = new RinglessMessageFieldStore({
    clearParentError: () => {
      this.setError('ringlessMessage', '')
    },
  })
  errorsMap: Map<ITriggerCreateField, ReactNode> = new Map()
  integrationDropdownStore = new IntegrationDropdownStore({
    clearIntegrationError: () => {
      this.setError('integration', '')
    },
    clearPhoneFieldError: () => {
      this.setError('phoneField', '')
    },
  })
  sendFromDropdownStore = new SendFromDropdownStore({
    excludeDeprioritizedNumbers: true,
    clearError: () => {
      this.setError('sendFrom', '')
    },
  })
  trackConversionStore = new TrackConversionStore({
    clearError: () => {
      this.setError('conversion_id', '')
    },
  })
  tagsControlStore = new TagsControl()

  private _disposeIsAircallReaction: IReactionDisposer | null = null
  private _disposeIntegrationDropdownSelectedReaction: IReactionDisposer | null = null
  private _disposeTypeReaction: IReactionDisposer | null = null

  constructor(triggerResponse?: IResponseTrigger, isDuplicate?: boolean) {
    makeAutoObservable(this)

    void this.init(triggerResponse, isDuplicate)
  }

  init = async (triggerResponse?: IResponseTrigger, isDuplicate?: boolean) => {
    runInAction(() => {
      this.loading = true
    })
    await Promise.all([integrationsStore.fetchIntegrations(), allMergeFieldsStore.loadAllFields()])

    if (!triggerResponse) {
      void this.integrationDropdownStore.init({})
      void this.sendFromDropdownStore.init()
    }

    if (triggerResponse) {
      if (isDuplicate) {
        runInAction(() => {
          this.mode = EnumModeTrigger.Clone
          this.name = triggerResponse.name + ' (copy)'
        })
      } else {
        runInAction(() => {
          this._responseEditTrigger = triggerResponse
          this.mode = EnumModeTrigger.Edit
          this.name = triggerResponse.name
        })
      }

      if (triggerResponse.is_rvm) {
        runInAction(() => {
          this._type = EnumTypeTrigger.Ringless
        })
        const responseMediaShortUrl = triggerResponse?.media_url?.[0]
        if (responseMediaShortUrl) this.ringlessMessageFieldStore.init(responseMediaShortUrl)
      } else {
        this.triggerSMSStore.init(triggerResponse)
      }

      if (triggerResponse.tags?.length) {
        this.tagsControlStore.init(triggerResponse.tags)
      }

      if (triggerResponse.is_track_conversion) {
        this._isOpenAdvanced = true
      }
      void this.trackConversionStore.init({
        is_track_conversion: triggerResponse.is_track_conversion,
        conversion_id: triggerResponse.conversion_id,
      })

      await this.integrationDropdownStore.init({
        integration_id: triggerResponse.integration_id,
        integration: triggerResponse.integration,
        service_property: triggerResponse.service_property,
      })

      if (this.integrationDropdownStore.selectedIntegrationItem?.id) {
        await this.updateSendFromWithSelectedIntegration()
      }
      void this.sendFromDropdownStore.init(triggerResponse.send_from)
    }

    if (!isDuplicate) {
      runInAction(() => {
        this._cacheParams = this.triggerParams
      })
    }

    this.reactionIsAircallInbox()
    this.reactionIntegrationSelected()
    this.reactionType()

    runInAction(() => {
      this.loading = false
    })
  }

  reset = () => {
    this._disposeIsAircallReaction?.()
    this._disposeIntegrationDropdownSelectedReaction?.()
    this._disposeTypeReaction?.()
  }

  reactionType = () => {
    this._disposeTypeReaction?.()
    this._disposeTypeReaction = reaction(
      () => this._type,
      (status) => {
        this.sendFromDropdownStore.setUseNumberStatus(status !== EnumTypeTrigger.Ringless)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionIsAircallInbox = () => {
    this._disposeIsAircallReaction?.()
    this._disposeIsAircallReaction = reaction(
      () => this.sendFromDropdownStore.isAircall,
      (isAircall) => {
        this.triggerSMSStore.messageFieldStore.setIsCurrentAirCall(isAircall)
      }
    )
  }

  reactionIntegrationSelected = () => {
    this._disposeIntegrationDropdownSelectedReaction?.()
    this._disposeIntegrationDropdownSelectedReaction = reaction(
      () => this.integrationDropdownStore.selectedIntegrationItem?.id,
      () => {
        void this.updateSendFromWithSelectedIntegration()
      }
    )
  }

  updateSendFromWithSelectedIntegration = async () => {
    const selectedIntegration: Integration | undefined =
      this.integrationDropdownStore.selectedIntegrationItem?.data

    await this.sendFromDropdownStore.updateIntegrationSenders(selectedIntegration)
  }

  private saveTrigger = async (triggerParams: IParamsTrigger): Promise<boolean> => {
    const cacheParams = this._cacheParams

    try {
      runInAction(() => {
        this.isSaving = true
        this._cacheParams = triggerParams
      })

      if (this.mode === EnumModeTrigger.Edit) {
        await TriggerApi.updateTrigger(triggerParams)
      } else {
        await TriggerApi.createTrigger(triggerParams)
      }
      return true
    } catch (e) {
      if (e instanceof AxiosError) {
        const error = e.response?.data?.error

        if (error) {
          toastStore.add({
            type: 'error',
            title: Array.isArray(error) ? error[0] : error,
          })
        } else {
          Object.entries(e.response?.data).map(([key, value]) => {
            if (key in ITriggerCreateFieldEnum) {
              this.setError(key as ITriggerCreateFieldEnum, Array.isArray(value) ? value[0] : value)
            } else {
              toastStore.add({
                type: 'error',
                title: Array.isArray(value) ? value[0] : value,
              })
            }
          })
        }
      }
      this._cacheParams = cacheParams
      console.log(e)
      return false
    } finally {
      runInAction(() => {
        this.isSaving = false
      })
    }
  }

  private _checkValidate = () => {
    const {
      name,
      is_rvm,
      media_url,
      message,
      integration_id,
      service_property,
      send_from,
      is_track_conversion,
      conversion_id,
    } = this.triggerParams

    if (!name.trim().length) {
      this.setError('name', 'Please enter trigger name')
    }

    if (is_rvm) {
      if (!media_url.length) {
        this.setError('ringlessMessage', 'Your message is the key – please select an audio')
      }
    } else {
      if (!message.trim().length && !media_url.length) {
        this.setError('smsMessage', 'Your message is the key – please write it above')
      }
    }

    if (integration_id && !(integration_id === 'custom') && !service_property) {
      this.setError('phoneField', 'Please select the phone field')
    }

    if (!send_from) {
      this.setError('sendFrom', 'Please select the inbox to send from')
    }

    if (is_track_conversion && !conversion_id) {
      this.setError('conversion_id', 'Please select or create a conversion to continue')
    }
  }

  handleCreateTrigger = async () => {
    this.clearErrors()
    this._checkValidate()
    if (this.errorsMap.size) {
      return
    }
    if (this.type === 'sms' && this.triggerSMSStore.isError) {
      return
    }

    const isSaveSuccessful = await this.saveTrigger(this.triggerParams)
    if (isSaveSuccessful) {
      uiStore.changeRoute({ path: '/triggers' })
    }
  }

  get isExistChanges() {
    return !isEqual(omit(this._cacheParams, 'is_rvm'), omit(this.triggerParams, 'is_rvm'))
  }

  handleShowKeepEditingModal = (blocker: Blocker) => {
    if (blocker.state !== 'blocked') return

    modalStore.addModal({
      id: this._modalKeepEditing,
      type: ModalTypeList.WARNING,
      title: 'Leaving will discard your content',
      primaryAction: {
        text: 'Keep editing',
        onAction: () => {
          modalStore.closeModal(this._modalKeepEditing)
        },
      },
      secondaryAction: {
        text: 'Discard',
        onAction: () => {
          const path = blocker.location.pathname
          blocker.proceed()

          uiStore.setRoutesBlocker(null)
          uiStore.changeRoute({
            path,
            type: 'vue',
            method: 'replace',
          })
          modalStore.closeModal(this._modalKeepEditing)
        },
      },
    })
  }

  onTestSMSTrigger = (params: IParamsTestTrigger) => TriggerApi.createTest(params)

  selectTypeTab = (tab: ITabItem<EnumTypeTrigger>) => {
    this._type = tab.key
  }

  setName = (name: string) => {
    this.name = name
  }

  setOpenAdvanced = (value: boolean) => {
    this._isOpenAdvanced = value
  }

  clearErrors = () => {
    this.trackConversionStore.clearError()
    this.ringlessMessageFieldStore.clearError()
    this.sendFromDropdownStore.clearError?.()
    this.integrationDropdownStore.clearIntegrationError()
    this.integrationDropdownStore.clearPhoneFieldError()
    this.errorsMap.clear()
  }

  setError = (key: ITriggerCreateField, error: ReactNode) => {
    if (key === 'conversion_id') {
      this.trackConversionStore.setError(error)
    }
    if (key === 'ringlessMessage') {
      this.ringlessMessageFieldStore.setError(error)
    }
    if (key === 'sendFrom') {
      this.sendFromDropdownStore.setError(error)
    }
    if (key === 'integration') {
      this.integrationDropdownStore.setIntegrationError(error)
    }
    if (key === 'phoneField') {
      this.integrationDropdownStore.setPhoneFieldError(error)
    }
    if (!!error) this.errorsMap.set(key, error)
    else this.errorsMap.delete(key)
  }

  get tabs(): ITabItem<EnumTypeTrigger>[] {
    return [
      {
        key: EnumTypeTrigger.Sms,
        name: 'SMS',
      },
      {
        key: EnumTypeTrigger.Ringless,
        name: 'Ringless',
      },
    ]
  }

  get type() {
    return this._type
  }

  get isOpenAdvanced() {
    return this._isOpenAdvanced
  }

  get isNumberOptional() {
    const senderType = this.sendFromDropdownStore.params?.type
    const selectedIntegration = this.integrationDropdownStore.selectedIntegrationItem?.data

    return (
      senderType === 'from_integration' &&
      (selectedIntegration?.key === IntegrationKey.hubspot ||
        selectedIntegration?.key === IntegrationKey.infusionsoft)
    )
  }

  get number(): Phone | undefined | null {
    const sendFrom = this.sendFromDropdownStore.params
    if (!sendFrom) return

    if (sendFrom.options.number_id) {
      return numbersStore.getItem(sendFrom?.options.number_id)
    }

    if (sendFrom.options.team_id) {
      const inbox = inboxesStore.getItem(sendFrom.options.team_id)

      if (inbox && inbox.type === 'inbox') {
        const numberId = inbox?.sendingOptions?.find(
          (item) => item.key === sendFrom?.options?.smart_option
        )?.number_ids[0]

        return numbersStore.getItem(numberId)
      }
    }
  }

  get messageTypeParams() {
    if (this.type === 'sms') {
      return this.triggerSMSStore.messageDataParams
    }
    return this.ringlessMessageFieldStore.ringlessMessageDataParams
  }

  get triggerParams(): IParamsTrigger {
    return {
      id:
        this.mode === EnumModeTrigger.Edit && this._responseEditTrigger
          ? this._responseEditTrigger.id
          : null,
      name: this.name,
      is_rvm: this._type === EnumTypeTrigger.Ringless,

      send_from: this.sendFromDropdownStore.params,

      is_track_conversion: this.trackConversionStore.is_track_conversion,
      conversion_id: this.trackConversionStore.conversion_id,

      tags: this.tagsControlStore.tags.map((tag) => tag.id),
      ...this.integrationDropdownStore.params,
      ...this.messageTypeParams,
    }
  }
}
