import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type LoginStore } from 'widgets/Login/store/loginStore'

type IProps = {
  store: LoginStore
}

export const TwoFaCodeModalActions = observer(({ store }: IProps) => {
  const { loading, onVerifyCode, isValidTwoFaCode, disabledCodeResend } = store

  return (
    <Button
      text='Verify'
      disabled={loading || !isValidTwoFaCode || disabledCodeResend}
      onClick={onVerifyCode}
    />
  )
})
