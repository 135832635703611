import { observer } from 'mobx-react-lite'
import { EnrolmentStep } from 'widgets/ContactEnrollment'
import { useChatbotContext } from 'pages/chatbot/context'

export const StepEnrollment = observer(() => {
  const { enrollment, errors } = useChatbotContext()
  const enrollmentError = errors.getError('filters')

  const onClick = () => {
    enrollment.editEnrollment()
    errors.removeError('filters')
  }

  return (
    <EnrolmentStep
      tooltipLabel={'Choose the conditions that trigger a conversation with your Textbot'}
      enrollmentFilters={enrollment.enrollmentFilters}
      error={enrollmentError}
      onClick={onClick}
    />
  )
})
