import { IDropdownItem } from 'shared/ui'

export const reportItems: IDropdownItem[] = [
  {
    id: 'report',
    label: 'Monthly messaging export',
  },
  {
    id: 'totals-report',
    label: 'Messaging totals export',
  },
]
