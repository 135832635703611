// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GAD9gA1Vzc9mMAXjk5dT{display:flex;flex-direction:column}.qx9SLPkv_CztlfBOnGAP{display:flex;align-items:flex-end;margin-bottom:32px;border-radius:16px;overflow:hidden;min-height:276px}.qx9SLPkv_CztlfBOnGAP img{display:block;max-width:492px;width:100%;height:auto}.t1Mb6RqIG_mUQpfQPE1u{margin-top:4px;text-align:center;max-width:440px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseEmpty/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEA,0BACE,aAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CAIJ,sBACE,cAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".title {\n  display: flex;\n  flex-direction: column;\n}\n\n.previewImageContainer {\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 32px;\n  border-radius: 16px;\n  overflow: hidden;\n  min-height: 276px;\n\n  img {\n    display: block;\n    max-width: 492px;\n    width: 100%;\n    height: auto;\n  }\n}\n\n.subTitleText {\n  margin-top: 4px;\n  text-align: center;\n  max-width: 440px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "GAD9gA1Vzc9mMAXjk5dT",
	"previewImageContainer": "qx9SLPkv_CztlfBOnGAP",
	"subTitleText": "t1Mb6RqIG_mUQpfQPE1u"
};
export default ___CSS_LOADER_EXPORT___;
