// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Oxwf9TN0iVI24iGup4TN{padding-top:16px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/PageDescription/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".wrap {\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Oxwf9TN0iVI24iGup4TN"
};
export default ___CSS_LOADER_EXPORT___;
