import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { dateFromNow, dateFromNowStrict, numberFormat } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { channelsOrganization, OrganizationApi } from 'entities/Organization'
import { usersStore } from 'entities/Users'
import { segmentsStore } from 'entities/Segment'
import {
  IResponseEventOrganizationCalling,
  IResponseEventUpdateBalance,
  IResponseNumberVendor,
  IResponseOrganization,
  IResponseOrganizationCall,
} from 'entities/Organization/api/types'
import { subscriptionStore } from 'entities/Subscription'

class OrganizationStore {
  constructor() {
    makeAutoObservable(this)
    reaction(() => this.updateTrialEndsAtStringTrigger, this.updateTrialEndsAtString)
  }

  loading = false
  accountCredits = 0
  trialCredits = 0
  id: number | null = null
  owner_id: number | null = null
  account_balance: number | null = null
  name: string | null = null
  created_at: string | null = null
  organization_call: IResponseOrganizationCall | null = null
  double_opt_in: { is_active: boolean } = { is_active: false }
  trial_ends_at = ''
  number_vendor: IResponseNumberVendor | null = null
  is_agency = false

  trialEndsAtString = ''
  strictTrialEndsAtString = ''
  isTrialEnded = true
  updateTrialEndsAtStringTrigger = ''
  updateTrialEndsAtStringIdInterval: NodeJS.Timeout | null = null
  broadcast_limits: IResponseOrganization['broadcast_limits'] | null = null
  phone_checker: IResponseOrganization['phone_checker'] | null = null

  get owner() {
    return usersStore.getItem(this.owner_id)
  }

  get isTwilioNumberVendor() {
    return this.number_vendor?.key === 'twilio-integration'
  }

  updateTrialEndsAtString = () => {
    const remainingDiff = uiStore
      .dayjs(organizationStore.trialEndsAt)
      .diff(uiStore.dayjs(), 'day', true)
    const trialEndsAtDays = Math.ceil(remainingDiff)
    this.isTrialEnded = trialEndsAtDays <= 0

    if (!this.isTrialEnded) {
      this.trialEndsAtString = dateFromNow(organizationStore.trialEndsAt)
      this.strictTrialEndsAtString = dateFromNowStrict(organizationStore.trialEndsAt)
    } else {
      this.trialEndsAtString = ''
      this.strictTrialEndsAtString = ''
      this.updateTrialEndsAtStringIdInterval &&
        clearInterval(this.updateTrialEndsAtStringIdInterval)
    }
  }

  fetchOrganization = async () => {
    try {
      this.loading = true

      const { data: organization } = await OrganizationApi.getOrganizationCurrent()

      runInAction(() => {
        this.id = organization.id
        this.owner_id = organization.owner_id
        this.accountCredits = organization.account_credits
        this.trialCredits = organization.trial_credits
        this.account_balance = organization.account_balance
        this.name = organization.name
        this.created_at = organization.created_at
        this.organization_call = organization.organization_call
        this.double_opt_in = organization.double_opt_in
        this.trial_ends_at = organization.trial_ends_at
        this.number_vendor = organization.number_vendor
        this.is_agency = organization.is_agency
        this.broadcast_limits = organization.broadcast_limits
        this.phone_checker = organization.phone_checker

        if (organization.trial_ends_at) {
          this.updateTrialEndsAtStringTrigger = nanoid()
          this.updateTrialEndsAtStringIdInterval = setInterval(() => {
            runInAction(() => {
              this.updateTrialEndsAtStringTrigger = nanoid()
            })
          }, 1000)
        }

        channelsOrganization.subscribeChannels()
        segmentsStore.addItems(organization.organization_filters)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  updateBalance = (data: IResponseEventUpdateBalance) => {
    this.accountCredits = data.account_credits
    this.trialCredits = data.trial_credits
    this.account_balance = data.account_balance
  }

  updateOrganizationCall = (data: IResponseEventOrganizationCalling) => {
    this.organization_call = data.organization_call
  }

  updatePhoneCheckerEnable = async (status: boolean) => {
    try {
      const { data } = await OrganizationApi.updatePhoneCheckerEnable({
        check_existing_contacts: status,
      })

      this.phone_checker = data
    } catch (e) {
      console.log(e)
    }
  }

  get doubleOptInEnabled() {
    return this.double_opt_in.is_active
  }

  get isOutOfAccountCredits() {
    return this.accountCredits <= 0
  }

  get isOutOfCredits() {
    const { isTrial } = subscriptionStore
    const credits = isTrial ? this.trialCredits : this.accountCredits

    return credits <= 0
  }

  get trialEndsAt() {
    return this.trial_ends_at
  }

  get formattedAccountCredits() {
    return numberFormat({ value: this.accountCredits })
  }

  get formattedTrialCredits() {
    return numberFormat({ value: this.trialCredits })
  }

  get isOutOfTrialCredits() {
    return this.trialCredits <= 0
  }

  get isAgency() {
    return this.is_agency
  }

  get accountBalance() {
    if (!this.account_balance) return 0

    return this.account_balance
  }

  get isBillableNumberVendor() {
    return Boolean(this.number_vendor?.isBillable)
  }

  get isOrganizationOwner() {
    return usersStore.user?.id === this.owner_id
  }

  get isPhoneCheckerEnabled() {
    return Boolean(this.phone_checker?.is_active)
  }

  get isEnabledOrganisationCallAutoRecord() {
    return this.organization_call?.call_auto_record?.is_active
  }
}

export const organizationStore = new OrganizationStore()
