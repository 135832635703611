import { makeAutoObservable, runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { ConversationsApi } from 'entities/Conversation'
import { callStore } from 'entities/Call'
import { UsersApi } from 'entities/Users'
import { IOrderType } from 'entities/CallHistory/api/types'
import { CallHistoryStore } from 'widgets/CallHistory'
import { getColumns } from 'widgets/CallHistoryTable/ui/getColumns'

const defaultSortBy = 'created_at'
export class CallHistoryTableStore {
  constructor(private _callHistoryStore: CallHistoryStore) {
    makeAutoObservable(this)
  }

  visibleColumnsIds: string[] = []
  hideExpandColumnsIds = ['type']
  sortOrder: IOrderType = 'desc'
  sortBy = defaultSortBy

  getSortingParams = () => {
    return {
      field: this.sortBy,
      order: this.sortOrder,
    }
  }

  makeCall = (id: number) => {
    callStore.connectTwilio(id)
  }

  markAsRead = async (item: CallHistory) => {
    try {
      await ConversationsApi.updateMessagesByIdRead(item.conversation.id, {
        ids: [item.message.id],
      }).then(() => {
        const clonedItem = cloneDeep(item)
        clonedItem.message.unread = false
        this._callHistoryStore.updateItem(clonedItem)
      })
    } catch (e) {
      console.error(e)
    }
  }

  updateSelectedColumns = async (ids: string[]) => {
    const { data } = await UsersApi.updateUsersUiSettings({
      source_type: 'call-history-table-columns',
      items: ids,
    })

    runInAction(() => {
      if (data?.data?.items) {
        this.visibleColumnsIds = data.data.items
      }
    })
  }

  getSelectedColumns = async () => {
    try {
      const { data } = await UsersApi.getUsersUiSettings('call-history-table-columns')

      if (data.data?.items.length) {
        runInAction(() => {
          if (data?.data?.items) {
            this.visibleColumnsIds = data.data.items
          }
        })
      } else {
        this.updateSelectedColumns(
          getColumns([], this._callHistoryStore)
            .filter((column) => !column.isHideByDefault)
            .map((column) => column.field)
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  changeSortBy = (field: string) => {
    if (this.sortBy !== field) {
      this.sortBy = field
      this.sortOrder = 'desc'
    }
  }

  toggleSortOrder = () => {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc'
  }
}
