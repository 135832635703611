import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type {
  IParamsCnamTerms,
  IParamsCnamValidate,
  IParamsDeleteOrganizationCnam,
  IResponseOrganizationCnam,
} from 'entities/Compliance/api/cnamType'

class Api {
  updateOrganizationCnamTerms(
    params: IParamsCnamTerms
  ): Promise<AxiosResponse<IResponseOrganizationCnam>> {
    return baseApi.post('organization/cnam/terms', params)
  }

  updateOrganizationCnamValidate(params: IParamsCnamValidate): Promise<AxiosResponse<void>> {
    return baseApi.post('organization/cnam/validate', params)
  }

  getOrganizationCnam(): Promise<AxiosResponse<IResponseOrganizationCnam>> {
    return baseApi.get('organization/cnam')
  }

  deleteOrganizationCnam(params: IParamsDeleteOrganizationCnam): Promise<AxiosResponse<void>> {
    return baseApi.delete('organization/cnam', {
      params: params,
    })
  }
}

export const CnamApi = new Api()
