import { makeAutoObservable } from 'mobx'
import { MouseEvent } from 'react'
import { Blocker } from 'react-router'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { type IResponseFilterSegment, segmentsStore } from 'entities/Segment'
import { type ContactsTableStore } from 'widgets/ContactsTable'
import { ContactsFilterStore } from 'widgets/ContactsFilters'
import { ModalContent } from '../ui/ModalContent'
import { EditSegmentActions } from '../ui/EditSegmentActions'
import { NewSegmentActions } from '../ui/NewSegmentActions'
import { ModalNewSegmentError } from '../ui/ModalNewSegmentError'

interface IPropGetAskIfSaveModal {
  onToggle: () => void
  blocker?: Blocker
  disabled?: boolean
  noSetActive?: boolean
  filtersList: IResponseFilterSegment[]
}

interface IOpenAskIfSaveModal {
  onToggle: () => void
  filtersList: IResponseFilterSegment[]
  noSetActive?: boolean
}

export class SegmentModalStore {
  constructor(private _contactsFilterStore: ContactsFilterStore) {
    makeAutoObservable(this)
  }

  private _idModal = ''
  private _name = ''
  private _loading = false
  private _contactsTableStore: ContactsTableStore | null = null

  get contactsFilterStore() {
    return this._contactsFilterStore
  }

  get idModal() {
    return this._idModal
  }

  get segmentName() {
    return this._name
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get disabledCreating() {
    const { segment } = this._contactsFilterStore
    const onlySpacesInName = /^\s*$/.test(this.segmentName)
    const noEditedName = segment?.name === this.segmentName

    return this.segmentName.length < 1 || onlySpacesInName || noEditedName || this.isNameLengthError
  }

  get isNameLengthError() {
    return this._name.length > 100
  }

  setContactsTableStore = (store: ContactsTableStore) => {
    this._contactsTableStore = store
  }

  getAskIfSaveModal = ({
    onToggle,
    blocker,
    disabled,
    noSetActive = false,
    filtersList,
  }: IPropGetAskIfSaveModal) => {
    this._idModal = 'askSaveSegment_modal'
    const { segment } = this._contactsFilterStore

    return {
      disabledOnAllClose: true,
      type: 'warning' as ModalType['type'],
      title: `Save changes to segment “${segment?.name}” before closing?`,
      id: 'askSaveSegment_modal',
      zIndex: 2000,
      primaryAction: {
        text: 'Save changes',
        disabled: disabled,
        onAction: (e?: MouseEvent<HTMLButtonElement>) => {
          e?.stopPropagation()
          const { segment } = this._contactsFilterStore
          this._name = segment?.name || ''

          segment?.id === -1 ? this.createItem(filtersList) : this.editItem({ noSetActive })
          modalStore.closeModal(this._idModal)
          onToggle()
          blocker?.proceed && blocker?.proceed()
        },
      },
      additionalSecondaryAction: {
        text: 'Don’t save',
        onAction: (e?: MouseEvent<HTMLButtonElement>) => {
          e?.stopPropagation()
          onToggle()
          modalStore.closeModal(this._idModal)
          blocker?.proceed && blocker?.proceed()
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: (e?: MouseEvent<HTMLButtonElement>) => {
          e?.stopPropagation()
          modalStore.closeModal(this._idModal)
          blocker?.reset && blocker?.reset()
        },
      },
    }
  }

  openAskIfSaveModal = (props: IOpenAskIfSaveModal) => {
    modalStore.addModal(this.getAskIfSaveModal(props))
  }

  openNewSegmentModal = () => {
    this._idModal = 'newSegment_modal'

    modalStore.addModal({
      id: this._idModal,
      title: 'New segment',
      showHeader: true,
      zIndex: 2000,
      showCloseButton: false,
      showCloseIcon: false,
      width: 480,
      onClose: this.closeModal,
      ModalContentProps: {
        segmentModalStore: this,
      },
      ModalActions: NewSegmentActions,
      ModalContent: ModalContent,
      ModalBottomError: ModalNewSegmentError,
    })
  }

  openRenameSegmentModal = (name: string) => {
    this._idModal = 'renameSegment_modal'
    this._name = name

    modalStore.addModal({
      id: this._idModal,
      showHeader: true,
      zIndex: 2000,
      showCloseButton: false,
      showCloseIcon: false,
      width: 480,
      onClose: this.closeModal,
      ModalContentProps: {
        segmentModalStore: this,
      },
      title: 'Rename',
      paddingContent: '0 24px 24px 24px',
      ModalActions: EditSegmentActions,
      ModalContent: ModalContent,
      ModalBottomError: ModalNewSegmentError,
    })
  }

  openDeleteSegmentModal = (name: string, deleteHandler: () => void) => {
    this._idModal = 'deleteSegment_modal'
    this._name = name

    const titleName = name.length > 20 ? name.slice(0, 20) + '...' : name

    modalStore.addModal({
      id: this._idModal,
      showHeader: true,
      zIndex: 2000,
      showCloseButton: false,
      showCloseIcon: false,
      width: 480,
      onClose: this.closeModal,
      ModalContentProps: {
        segmentModalStore: this,
      },
      title: `Delete “${titleName}” segment?`,
      desc: 'This action cannot be undone',
      type: ModalTypeList.ALERT,
      secondaryAction: {
        text: 'Cancel',
        onAction: this.closeModal,
      },
      primaryAction: {
        text: 'Delete',
        onAction: deleteHandler,
      },
    })
  }

  deleteSegment = async (id: number) => {
    await segmentsStore.deleteItem(id)

    toastStore.add({
      title: 'Segment deleted',
      type: 'success',
    })
  }

  closeModal = () => {
    this._name = ''

    modalStore.removeModal(this._idModal)
  }

  handleChangeValue = (value: string) => {
    this._name = value
  }

  createItem = async (filtersList: IResponseFilterSegment[]) => {
    try {
      const newSegment = await segmentsStore.createItem({
        name: this._name,
        filters: {
          filtersList: filtersList ?? [],
        },
      })
      this._contactsFilterStore.setSegment(newSegment)

      toastStore.add({
        type: 'success',
        title: 'Segment created',
      })
    } catch (e) {
      this.errorHandler(e as AxiosError<{ name?: string[] }>)
    }
  }

  errorHandler = (error: AxiosError<{ name?: string[] }>) => {
    const errorMessage = error.response?.data?.name ? error.response.data.name[0] : error.message

    toastStore.add({
      title: errorMessage,
      type: 'error',
    })
  }

  editItem = async ({
    noSetActive,
    afterEdit,
  }: {
    noSetActive?: boolean
    afterEdit?: () => void
  }) => {
    const { segment, segmentFilter } = this._contactsFilterStore
    if (!segment) return

    try {
      const updatedSegment = await segmentsStore.updateItem(segment.id, {
        filters: segmentFilter,
        name: this._name.length ? this._name : segment.name,
      })

      toastStore.add({
        title: 'Segment saved',
        type: 'success',
      })

      if (!noSetActive) this._contactsFilterStore.setSegment(updatedSegment)

      afterEdit?.()
    } catch (e) {
      this.errorHandler(e as AxiosError<{ name?: string[] }>)
    }
  }
}
