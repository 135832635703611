import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, type ITabItem, Tabs, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { type GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'
import { ITabsEnum } from 'entities/Phone/ui/GetNumberModal/store/types'
import { NoAvailableTabContent } from './NoAvailableTabContent'
import { AvailableTabContent } from './AvailableTabContent'
import styles from './styles.module.scss'

type IGetNumberModalActionsProps = {
  store: GetNumberModalStore
}

export const GetNumberModalContent: FC<IGetNumberModalActionsProps> = observer(({ store }) => {
  const showAlert = store.showFirstRegAlert

  const handleActiveTab = (item: ITabItem) => {
    const tabKey = item.key as ITabsEnum
    store.setActiveTab(tabKey)
  }

  return (
    <div aria-label={getAriaLabel('GetNumberModalContent')}>
      {showAlert && (
        <Alert
          className={styles.alert}
          item={{
            type: 'success',
            desc: 'Great! Now let’s choose a number.',
          }}
        />
      )}
      <div
        className={styles.contentWrap}
        aria-label={getAriaLabel('GetNumberModalContent', 'contentWrap')}
      >
        <div className={styles.header}>
          <Typography variant={'heading-md'} ariaLabel='GetNumberModalContent_header'>
            Choose your number
          </Typography>
          <Typography
            variant={'body-md-regular'}
            ariaLabel='GetNumberModalContent_header_desc'
            color='var(--content-primary-tertiary'
            width={352}
          >
            You can always change this number later or replace with your existing number, landline,
            or Aircall number.
          </Typography>
        </div>

        <div className={styles.tabs}>
          <Tabs
            tabs={store.tabs}
            activeTabKey={store.activeTab}
            handleSelectTab={handleActiveTab}
          />
        </div>
        {store.noAvailableText ? (
          <NoAvailableTabContent infoText={store.noAvailableText} editAnswers={store.editAnswers} />
        ) : (
          <AvailableTabContent store={store} />
        )}
      </div>
    </div>
  )
})
