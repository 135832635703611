// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X2q6qfYHOnIOeeLRTys4{display:grid;gap:20px;--titled-row-action-width: 320px}.X2q6qfYHOnIOeeLRTys4 ul{display:grid;padding-left:20px;list-style-type:disc}.PfUnjyb2fnc_nt_Szvqv{display:grid;grid-template-columns:1fr 108px;gap:20px}.M2qvtfyx_aWxjf3TOQex{padding-left:12px}.M2qvtfyx_aWxjf3TOQex input{color:var(--content-primary-tertiary)}.JNjCg0h0FAt7G0k_v4rU{display:grid;width:100%;height:1px;background-color:var(--border-neutral-default)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsWebhook/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,gCAAA,CAEA,yBACE,YAAA,CACA,iBAAA,CACA,oBAAA,CAIJ,sBACE,YAAA,CACA,+BAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CAEA,4BACE,qCAAA,CAIJ,sBACE,YAAA,CACA,UAAA,CACA,UAAA,CACA,8CAAA","sourcesContent":[".root {\n  display: grid;\n  gap: 20px;\n  --titled-row-action-width: 320px;\n\n  ul {\n    display: grid;\n    padding-left: 20px;\n    list-style-type: disc;\n  }\n}\n\n.urlForm {\n  display: grid;\n  grid-template-columns: 1fr 108px;\n  gap: 20px;\n}\n\n.input {\n  padding-left: 12px;\n\n  input {\n    color: var(--content-primary-tertiary);\n  }\n}\n\n.divider {\n  display: grid;\n  width: 100%;\n  height: 1px;\n  background-color: var(--border-neutral-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "X2q6qfYHOnIOeeLRTys4",
	"urlForm": "PfUnjyb2fnc_nt_Szvqv",
	"input": "M2qvtfyx_aWxjf3TOQex",
	"divider": "JNjCg0h0FAt7G0k_v4rU"
};
export default ___CSS_LOADER_EXPORT___;
