import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, Tooltip } from 'shared/ui'
import { createElementFromHTML } from 'shared/lib'
import {
  IMessageSignatureErrorType,
  MessageSignatureStore,
} from 'widgets/MessageField/ui/MessageSignature'
import { getCaretIndex, placeCaretAtEnd, placeCaretAtStart, textToHtml } from 'widgets/MessageField'
import styles from './styles.module.scss'

type IMessageSignatureEditorProps = {
  store: MessageSignatureStore
}

export const MessageSignatureEditor: React.FC<IMessageSignatureEditorProps> = observer(
  ({ store }) => {
    const ref = useRef<HTMLDivElement>(null)
    const refInputKey = useRef('')
    const offsetRef = useRef(0)
    const focusNodeRef = useRef<Node | null>(null)
    const messageFieldStore = store.parent
    const messageSignatureStore = store
    const { disabled } = messageFieldStore
    const {
      handleUpdateMessageSignatureProperties,
      setFocus,
      setMessageSignatureError,
      isFocusMessageSignature,
      messageSignature,
      messageSignatureMaxLength,
      messageSignatureError,
      messageSignatureErrorMessage,
      messageSignatureLinkError,
      focusTrigger,
    } = messageSignatureStore

    const updateCaretPosition = () => {
      if (ref.current) {
        const { offset, focusNode } = getCaretIndex(ref.current)
        offsetRef.current = offset
        focusNodeRef.current = !ref.current.innerHTML ? null : focusNode || null
      }
    }

    const addContent = (content: string) => {
      if (!content || disabled) {
        return
      }

      const node = createElementFromHTML(content, true)

      let focusNodeLocal = focusNodeRef.current
      let offsetLocal = offsetRef.current

      if (ref.current) {
        if (!focusNodeLocal) {
          ref.current.focus()
          focusNodeLocal = getCaretIndex(ref.current).focusNode || null
          offsetLocal = getCaretIndex(ref.current).offset || 0
        }

        if (focusNodeLocal) {
          if (focusNodeLocal.nodeName === '#text') {
            if (focusNodeLocal.textContent) {
              const firstPart = focusNodeLocal.textContent.slice(0, offsetLocal)
              const firstNode = createElementFromHTML(firstPart, true)
              const secondPart = focusNodeLocal.textContent.slice(offsetLocal)
              const secondNode = createElementFromHTML(secondPart, true)
              const newNode = document.createElement('span')

              if (firstPart) {
                newNode.appendChild(firstNode)
              }
              newNode.appendChild(node)
              if (secondPart) {
                newNode.appendChild(secondNode)
              }
              if (newNode) {
                const parentNode = focusNodeLocal.parentNode
                if (parentNode) {
                  parentNode.replaceChild(newNode, focusNodeLocal)
                }
              }
              if (ref.current) {
                handleUpdateMessageSignatureProperties({
                  signature: ref.current.innerText.trim(),
                })
                try {
                  if (secondPart) {
                    placeCaretAtStart(secondNode)
                  } else {
                    placeCaretAtEnd(newNode)
                  }
                } catch (e) {
                  console.error(e)
                }
              }
            } else {
              const parentNode = focusNodeLocal.parentNode
              const newNode = createElementFromHTML(content, true)

              if (parentNode) {
                parentNode.replaceChild(newNode, focusNodeLocal)
              }
              handleUpdateMessageSignatureProperties({
                signature: ref.current.innerText.trim(),
              })
              if (focusNodeLocal) {
                placeCaretAtEnd(focusNodeLocal)
              } else {
                placeCaretAtEnd(ref.current)
              }
            }
          } else {
            if (focusNodeLocal.firstChild?.nodeName === 'BR') {
              focusNodeLocal.removeChild(focusNodeLocal.firstChild)
            }
            focusNodeLocal.appendChild(node)
            handleUpdateMessageSignatureProperties({
              signature: ref.current.innerText.trim(),
            })
            if (focusNodeLocal) {
              placeCaretAtEnd(focusNodeLocal)
            } else {
              placeCaretAtEnd(ref.current)
            }
          }

          const testFocusNodeLocal = getCaretIndex(ref.current).focusNode || null
          if (!testFocusNodeLocal) {
            placeCaretAtEnd(ref.current)
          }
        }
      }
      updateCaretPosition()
      messageFieldStore.setUpdateTriggerContent()
    }

    const onFocus = () => {
      if (disabled) return
      setFocus(true)
      if (ref.current) placeCaretAtEnd(ref.current)
    }

    const onBlur = () => {
      if (ref.current) {
        if (!messageSignature) {
          handleUpdateMessageSignatureProperties({
            is_active: false,
            is_on_blur: true,
          })
        } else {
          ref.current.innerText = messageSignature
        }
        setFocus(false)
        ref.current.blur()
      }
    }
    const onPaste: React.ClipboardEventHandler<HTMLDivElement> = (e) => {
      if (disabled) return

      e.preventDefault()

      const length = e.clipboardData.getData('text/plain').length
      const totalLength = messageSignature.length + length
      if (ref.current && totalLength > messageSignatureMaxLength) {
        setMessageSignatureError(IMessageSignatureErrorType.Length)
        if (messageSignatureError && totalLength > messageSignatureMaxLength) {
          ref.current.innerText = messageSignature
          placeCaretAtEnd(ref.current)

          return
        }
      }

      const text = e.clipboardData.getData('text/plain')
      window.getSelection()?.getRangeAt(0)?.deleteContents()
      updateCaretPosition()
      addContent(textToHtml({ text, extraSpace: true }))
    }

    const onInput = () => {
      if (disabled) return
      if (ref.current) {
        if (refInputKey.current === 'Enter') {
          ref.current.innerText = messageSignature
          placeCaretAtEnd(ref.current)

          return
        }

        if (
          ref.current.innerText.length >= messageSignatureMaxLength &&
          refInputKey.current !== 'Backspace'
        ) {
          setMessageSignatureError(IMessageSignatureErrorType.Length)
          if (ref.current.innerText.length > messageSignatureMaxLength) {
            ref.current.innerText = messageSignature
          }
          placeCaretAtEnd(ref.current)
        } else if (
          ref.current.innerText.length <= messageSignatureMaxLength &&
          messageSignatureError === IMessageSignatureErrorType.Length
        ) {
          setMessageSignatureError(null)
        }

        handleUpdateMessageSignatureProperties({
          signature: ref.current.innerText.trim(),
        })
      }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (disabled) return
      if (refInputKey) refInputKey.current = e.code
    }
    useEffect(() => {
      if (messageSignatureLinkError) {
        setMessageSignatureError(IMessageSignatureErrorType.Link)
      } else if (messageSignatureError === IMessageSignatureErrorType.Link) {
        setMessageSignatureError(null)
      }
    }, [messageSignatureError, messageSignatureLinkError])

    useEffect(() => {
      if (ref.current && !messageSignature) {
        ref.current.innerHTML = ''
        onFocus()
      }
    }, [messageSignature])

    useEffect(() => {
      if (ref.current && messageSignature) {
        ref.current.innerHTML = messageSignature
      }
    }, [])

    useEffect(() => {
      if (focusTrigger) {
        onFocus()
      }
    }, [focusTrigger])

    return (
      <div className={styles.textareaContent}>
        <div
          className={classNames(
            styles.textareaIcon,
            messageSignatureErrorMessage && styles.textareaIcon__error
          )}
          onClick={onFocus}
        >
          {messageSignatureErrorMessage ? (
            <Tooltip
              label={messageSignatureErrorMessage}
              placement={'top-start'}
              color={'red'}
              fullWidth={true}
            >
              <Icon icon={'alertTriangleSmall'} />
            </Tooltip>
          ) : (
            <Icon icon={'signature'} fontSize={16} />
          )}
        </div>
        <div
          ref={ref}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onInput={onInput}
          contentEditable={true}
          className={classNames(
            styles.textarea,
            isFocusMessageSignature && styles.active,
            messageSignatureErrorMessage && styles.error
          )}
          placeholder={'Add signature'}
          aria-hidden={true}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
    )
  }
)
