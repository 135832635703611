import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Icon, type IStatusProps, Status, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IRegisterSectionItemProps = {
  id: number
  title: string
  desc: string
  statusProps?: IStatusProps | null
  actionIcon?: {
    onClick: (id: number) => void
  }
}

export const RegisterSectionItem: FC<IRegisterSectionItemProps> = observer(
  ({ id, title, desc, statusProps, actionIcon }) => {
    return (
      <div className={styles.wrapItem}>
        <Typography ariaLabel='RegisterSection_title' variant={'body-md-medium'}>
          {title}
        </Typography>
        <Typography
          ariaLabel='RegisterSection_desc'
          variant='body-md-regular'
          color='var(--content-primary-tertiary)'
          className={styles.itemDesc}
          tag='div'
        >
          {desc}
        </Typography>
        {statusProps && (
          <div className={styles.status}>
            <Status {...statusProps} />
          </div>
        )}
        {actionIcon && (
          <div
            className={styles.arrowRight}
            onClick={() => {
              actionIcon.onClick(id)
            }}
          >
            <Icon icon={'chevronRight'} />
          </div>
        )}
      </div>
    )
  }
)
