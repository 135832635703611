import { createContext, useContext } from 'react'
import { type KeywordsListStore } from 'pages/keywords/store'

export const KeywordsListContext = createContext<KeywordsListStore | null>(null)

export const useKeywordsListContext = () => {
  const context = useContext(KeywordsListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with KeywordsListContext')

  return context
}
