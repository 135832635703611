import { useEffect } from 'react'
import { IResponseIntegrationRefreshed } from 'entities/Integrations'
import { websocket, WebsocketEvent } from 'entities/WebSocket'

type IHandlerEvent = (data: IResponseIntegrationRefreshed) => void

export const EventIntegrationUsersRefreshed = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.IntegrationUsersRefreshed, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.IntegrationUsersRefreshed, handler),
}

export const useEventIntegrationUsersRefreshed = (handler: IHandlerEvent) =>
  useEffect(() => {
    EventIntegrationUsersRefreshed.subscribe(handler)

    return () => {
      EventIntegrationUsersRefreshed.unsubscribe(handler)
    }
  }, [])
