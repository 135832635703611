import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Button, Icon, Tabs } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import { Contact } from 'entities/Contacts/model/Contact'
import {
  ContactsSearchList,
  ContactsSearchListVariantEnum,
  ContactsSearchParentStore,
  ContactsSearchVariantEnum,
  IIntegrationsTabKey,
  IntegrationsTabEnum,
} from 'widgets/ContactsSearch'

import { minSearchCountText } from 'widgets/constants'
import styles from './styles.module.scss'

export type IContactsSearchProps = {
  search?: string
  focusedSearch?: boolean
  maxHeight?: number | string
  minHeight?: number | string
  onChange?: (item: Contact, params?: { isNew?: boolean }) => void
  onErrorCreate?: () => void
  onChangeLoader?: (status: boolean) => void
  withBorder?: boolean
  disabledHelp?: boolean
  disabledAddNumber?: boolean
  variant?: ContactsSearchVariantEnum
  selectedMap?: Map<number, Contact>
  onClearSelected?: () => void
  paramsCreateContact?: {
    forLink?: boolean
    fromConversation?: boolean
  }
  hiddenIntegrationsTabs?: IIntegrationsTabKey[]
  isAutomaticallyCreateNewContact?: boolean
  isHideOnLoading?: boolean
  wrapContentClassName?: string
  contactCreateBtnClassName?: string
}

export const ContactsSearch: React.FC<IContactsSearchProps> = observer(
  ({
    search = '',
    focusedSearch = false,
    onChange = () => {},
    onErrorCreate = () => {},
    onChangeLoader = () => {},
    onClearSelected = () => {},
    withBorder,
    selectedMap = new Map(),
    maxHeight,
    minHeight,
    disabledHelp = false,
    disabledAddNumber = false,
    paramsCreateContact,
    variant = ContactsSearchVariantEnum.default,
    hiddenIntegrationsTabs = [],
    isAutomaticallyCreateNewContact,
    isHideOnLoading,
    wrapContentClassName,
    contactCreateBtnClassName,
  }) => {
    const [store] = useState<ContactsSearchParentStore>(() => new ContactsSearchParentStore())
    const {
      allowedIntegrations,
      loadingContacts,
      showTypeView,
      creatingNumberLoading,
      searchNumber,
      activeTab,
      tabs,
      loadingContactsLocal,
      selectedCount,
      hasSelected,
      canSearch,
      init,
      initTabs,
      initSelectedMap,
      handleInputSearch,
      handleAddItem,
      handleCreateContactSearchNumber,
      toggleSelectedTab,
      handleSelectTab,
      handleClearSelected,
    } = store

    useEffect(() => {
      init({
        disabledHelp: disabledHelp,
        disabledAddNumber: disabledAddNumber,
        hiddenIntegrationsTabs,
        paramsCreateContact: {
          forLink: paramsCreateContact?.forLink !== undefined ? paramsCreateContact.forLink : true,
          fromConversation:
            paramsCreateContact?.fromConversation !== undefined
              ? paramsCreateContact.fromConversation
              : true,
        },
      })
      initSelectedMap(selectedMap)
      initTabs(hiddenIntegrationsTabs)

      return () => {
        store.reset()
      }
    }, [])

    useEffect(() => {
      handleInputSearch(search)
    }, [search])

    useEffect(() => {
      onChangeLoader(loadingContacts)
    }, [loadingContacts])

    useEffect(() => {
      handleCreateNewContact()
    }, [isAutomaticallyCreateNewContact])

    const handleClearSelect = () => {
      handleClearSelected()
      onClearSelected()
    }

    const handleCreateNewContact = () => {
      if (
        isAutomaticallyCreateNewContact &&
        !disabledAddNumber &&
        showTypeView === ContactsSearchListVariantEnum.create_contact &&
        !creatingNumberLoading
      ) {
        handleCreateContactSearchNumber(onChange, onErrorCreate)
      }
    }

    const showSearchHelpText =
      !disabledHelp &&
      (showTypeView === ContactsSearchListVariantEnum.help || (!canSearch && focusedSearch))

    if (isHideOnLoading && loadingContacts) {
      return null
    }

    return (
      <div
        className={classnames(styles.content, {
          [styles.loading]: loadingContacts,
        })}
      >
        {showSearchHelpText && (
          <div className={classnames(styles.help, withBorder && styles.contentBorder)}>
            {minSearchCountText}
          </div>
        )}

        {!disabledAddNumber && showTypeView === ContactsSearchListVariantEnum.create_contact && (
          <div className={classnames(withBorder && styles.contentBorder)}>
            <div
              className={classnames(
                styles.button,
                {
                  [styles.disabled]: creatingNumberLoading,
                },
                contactCreateBtnClassName
              )}
              onClick={() => {
                handleCreateContactSearchNumber(onChange, onErrorCreate)
              }}
            >
              <div className={styles.button__icon}>
                <Icon icon={'add'} fontSize={18} />
              </div>
              <div className={styles.button__text}>
                Add {getLabelAsNumberInternationalFormat(searchNumber)}
              </div>
            </div>
          </div>
        )}
        {(showTypeView === ContactsSearchListVariantEnum.list_contacts ||
          showTypeView === ContactsSearchListVariantEnum.selected_contacts) && (
          <div className={classnames(withBorder && styles.contentBorder, wrapContentClassName)}>
            {canSearch && (
              <Tabs
                tabs={tabs}
                paddingInlineWrap={8}
                activeTabKey={activeTab}
                handleSelectTab={handleSelectTab}
                withStopPropagation
              />
            )}
            {allowedIntegrations.map(({ key, store: integrationStore }) => {
              if (activeTab !== key) return null

              return (
                <ContactsSearchList
                  key={key}
                  store={integrationStore}
                  parentStore={store}
                  handleAddItem={(contact) => {
                    handleAddItem(contact, onChange, variant)
                  }}
                  variant={variant}
                  search={search}
                  maxHeight={maxHeight}
                  minHeight={minHeight}
                  loadingParent={loadingContactsLocal}
                />
              )
            })}
          </div>
        )}

        {variant === ContactsSearchVariantEnum.checkbox && (
          <div className={styles.actionsFooter}>
            <Button
              disabled={!hasSelected}
              typeBtn={'text'}
              text={'Clear'}
              onClick={handleClearSelect}
            />
            <Button
              disabled={!hasSelected}
              active={activeTab === IntegrationsTabEnum.selected}
              typeBtn={'informative'}
              text={`${selectedCount} selected`}
              onClick={toggleSelectedTab}
            />
          </div>
        )}
      </div>
    )
  }
)
