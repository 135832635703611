import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, layoutStore } from 'shared/ui'
import { useSettingsContext } from 'pages/settings/context/settingsContext'
import styles from './styles.module.scss'

export const SettingsMenuCollapseButton = observer(() => {
  const { isMobileViewWithoutMSize } = layoutStore
  const {
    pageLayoutStore: { titleIconProps, headerMenuTooltipIconProps, handleToggleCollapse },
  } = useSettingsContext()

  if (!isMobileViewWithoutMSize) return null

  return (
    <div className={styles.collapseButtonContainer}>
      <IconButton
        iconProps={{
          fontSize: titleIconProps.fontSize,
          icon: titleIconProps.icon,
        }}
        tooltipProps={headerMenuTooltipIconProps}
        variant={'icon'}
        size={'medium'}
        color={'secondary'}
        onClick={handleToggleCollapse}
        ariaLabel='SettingsMenuCollapseButton'
      />
    </div>
  )
})
