import classNames from 'classnames'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { Icon, Typography } from 'shared/ui'
import { TriggerTooltip, ITriggerTooltipProps } from 'shared/ui/TriggerTooltip/TriggerTooltip'
import { TextField, makeTextFieldAutoFocus, makeTextFieldHighlightLimit } from 'shared/ui/TextField'
import { MERGE_FIELD_FALLBACK_LIMIT } from 'shared/constants/limits'

import styles from './styles.module.scss'

type Props = {
  withEdit?: boolean
  setUpdateTriggerContent?: () => void
}

export const MergeFieldTooltip: FC<ITriggerTooltipProps & Props> = ({
  trigger,
  refVisibleTooltip,
  onLeaveTooltip,
  withEdit,
  setUpdateTriggerContent,
  parent,
}) => {
  const [limitReached, setLimitReached] = useState(false)
  const [fallback, setFallback] = useState(trigger?.dataset.fallback || '')
  const refValue = useRef('')

  useEffect(() => {
    setFallback(trigger?.dataset.fallback || '')
    if (trigger) {
      if (refValue) {
        refValue.current = trigger?.dataset.fallback || ''
      }
    }
  }, [trigger])

  const onChangeValue = (value: string) => {
    refValue.current = value
    setFallback(value)
  }

  const title = trigger ? (
    <div className={styles.wrap} key={trigger?.dataset.fieldlabel} aria-label='MergeFieldTooltip'>
      <div className={styles.left}>
        <Icon icon={'fallback'} fontSize={14} tertiary />
        <Typography variant={'body-md-regular'} color={'tertiary'} ariaLabel={'Fallback'}>
          Fallback
        </Typography>
      </div>
      <div className={styles.divider} />
      <TextField
        className={classNames({
          [styles.warnInput]: limitReached,
        })}
        size='small'
        variant='integrated'
        InputProps={{
          autoComplete: 'off',
          placeholder: `If we can’t find ${
            trigger?.dataset.fieldlabel || trigger?.dataset.fieldname
          }`,
        }}
        viewOnly={!withEdit}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: withEdit }),
          makeTextFieldHighlightLimit({
            limit: MERGE_FIELD_FALLBACK_LIMIT,
            onChange: setLimitReached,
          }),
        ]}
        value={fallback}
        onChange={onChangeValue}
        ariaLabel='FallbackTextField'
      />
    </div>
  ) : (
    <Fragment />
  )

  const onBlurEdit = (trigger?: HTMLSpanElement | HTMLLinkElement) => {
    if (trigger) {
      setUpdateTriggerContent && setUpdateTriggerContent()
      trigger.dataset.fallback = refValue.current
      refValue.current = ''
    }
  }

  useEffect(() => {
    return () => {
      if (trigger) {
        withEdit && onBlurEdit(trigger)
      }
    }
  }, [trigger])

  return (
    <TriggerTooltip
      parent={parent}
      trigger={trigger}
      onLeaveTooltip={onLeaveTooltip}
      refVisibleTooltip={refVisibleTooltip}
      color={'white'}
      component={title}
      arrow
    />
  )
}
