import { FC } from 'react'
import { Button, type IButtonProps } from 'shared/ui'
import styles from './styles.module.scss'

type INextButtonProps = Omit<IButtonProps, 'fullWidth'>

export const NextButton: FC<INextButtonProps> = (props) => {
  return (
    <div className={styles.wrap}>
      <Button fullWidth size='medium' {...props} className={styles.btn} />
    </div>
  )
}
