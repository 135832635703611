import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { WorkflowListPage } from 'pages/workflows/pages'
import { WorkflowLayout } from 'pages/workflows/layout/WorkflowLayout'
import { WorkflowsAccessLayout } from 'pages/workflows/layout/WorkflowsAccessLayout'

export const WorkflowsRouter = observer(() => (
  <Routes>
    <Route element={<WorkflowsAccessLayout />}>
      <Route element={<WorkflowLayout />}>
        <Route index element={<WorkflowListPage />} />
      </Route>
    </Route>
  </Routes>
))
