// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JqloBSu_SkNBLhJTdeGK{display:flex;flex-direction:column;gap:24px}.d3M4Dv6z8DMbaR0byLTG{display:flex;padding:6px 0}.KmYOhDiAr81HMUtRFfON{display:flex;padding:0 8px;line-height:20px;border-radius:4px;background:var(--gray-a-15);color:var(--gray-80)}.U14HCjdUdsdfqV4oG8i1{display:flex;align-items:flex-start;justify-content:space-between;gap:12px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignView/ui/Header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,aAAA,CAEF,sBACE,YAAA,CACA,aAAA,CACA,gBAAA,CACA,iBAAA,CACA,2BAAA,CACA,oBAAA,CAEF,sBACE,YAAA,CACA,sBAAA,CACA,6BAAA,CACA,QAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n.wrapLabel {\n  display: flex;\n  padding: 6px 0;\n}\n.label {\n  display: flex;\n  padding: 0 8px;\n  line-height: 20px;\n  border-radius: 4px;\n  background: var(--gray-a-15);\n  color: var(--gray-80);\n}\n.wrapActions {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "JqloBSu_SkNBLhJTdeGK",
	"wrapLabel": "d3M4Dv6z8DMbaR0byLTG",
	"label": "KmYOhDiAr81HMUtRFfON",
	"wrapActions": "U14HCjdUdsdfqV4oG8i1"
};
export default ___CSS_LOADER_EXPORT___;
