import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IParamsAdminGetTenDLCs,
  IParamsAdminGetTollFrees,
  IParamsCreatePublishTenDLC,
  IParamsCreateRejectTenDLC,
  IParamsCreateRejectTollFree,
  IParamsResubmitTollFree,
  IParamsUpdateTenDLCBrand,
  IParamsUpdateTenDLCBusinessProfile,
  IParamsUpdateTollFreeBusinessContact,
  IParamsUpdateTollFreeBusinessInfo,
  IResponseAdminTenDCLsData,
  IResponseAdminTenDLCById,
  IResponseAdminTollFreeByID,
  IResponseAdminTollFreeData,
  IResponseGetComplianceInReviewCount,
} from 'entities/Admin/compliance'
import { ITenDLCStatus, ITollFreeStatus } from 'entities/Compliance'
import { ISubscriptionStatus } from 'entities/Subscription'

class Api {
  getTenDLCs(
    {
      business_profile_status_list = [],
      brand_status_list = [],
      campaign_status_list = [],
      subscription_statuses = [],
      ...params
    }: IParamsAdminGetTenDLCs,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseAdminTenDCLsData>> {
    return baseApi.get('admin/core/compliance/10dlc', {
      params: {
        ...business_profile_status_list.reduce((acc, status, currentIndex) => {
          acc[`business_profile_status_list[${currentIndex}]`] = status
          return acc
        }, {} as { [key: string]: ITenDLCStatus }),
        ...brand_status_list.reduce((acc, status, currentIndex) => {
          acc[`brand_status_list[${currentIndex}]`] = status
          return acc
        }, {} as { [key: string]: ITenDLCStatus }),
        ...campaign_status_list.reduce((acc, status, currentIndex) => {
          acc[`campaign_status_list[${currentIndex}]`] = status
          return acc
        }, {} as { [key: string]: ITenDLCStatus }),
        ...subscription_statuses.reduce((acc, status, currentIndex) => {
          acc[`subscription_statuses[${currentIndex}]`] = status
          return acc
        }, {} as { [key: string]: ISubscriptionStatus }),
        ...params,
      },
      ...options,
    })
  }

  getTollFrees(
    { statuses = [], subscription_statuses, ...params }: IParamsAdminGetTollFrees,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseAdminTollFreeData>> {
    return baseApi.get('admin/core/compliance/toll-free', {
      params: {
        ...statuses.reduce((acc, status, currentIndex) => {
          acc[`statuses[${currentIndex}]`] = status
          return acc
        }, {} as { [key: string]: ITollFreeStatus }),
        ...subscription_statuses.reduce((acc, status, currentIndex) => {
          acc[`subscription_statuses[${currentIndex}]`] = status
          return acc
        }, {} as { [key: string]: ISubscriptionStatus }),
        ...params,
      },
      ...options,
    })
  }

  getTenDLCById(id: number): Promise<AxiosResponse<IResponseAdminTenDLCById>> {
    return baseApi.get(`admin/core/compliance/10dlc/info/${id}`)
  }

  getTollFreeById(id: number): Promise<AxiosResponse<IResponseAdminTollFreeByID>> {
    return baseApi.get(`admin/core/compliance/toll-free/${id}`)
  }

  getComplianceInReviewCount(): Promise<AxiosResponse<IResponseGetComplianceInReviewCount>> {
    return baseApi.get('admin/core/compliance/services-count', {
      params: {
        status: 'internal_review',
        tollfree_status: 'Internal Review',
      },
    })
  }
  createPublishTenDLC(params: IParamsCreatePublishTenDLC): Promise<AxiosResponse<string[]>> {
    return baseApi.post('admin/core/compliance/10dlc/publish', params)
  }
  createRejectTenDLC(params: IParamsCreateRejectTenDLC): Promise<AxiosResponse<string[]>> {
    return baseApi.post('admin/core/compliance/10dlc/manual-reject', params)
  }

  updateTenDLCBusinessProfile(
    params: IParamsUpdateTenDLCBusinessProfile
  ): Promise<AxiosResponse<IResponseAdminTenDLCById>> {
    return baseApi.put('admin/core/compliance/10dlc/services/business-profile', params)
  }

  updateTenDLCBrand(
    params: IParamsUpdateTenDLCBrand
  ): Promise<AxiosResponse<IResponseAdminTenDLCById>> {
    return baseApi.put('admin/core/compliance/10dlc/services/brand', params)
  }
  updateTenDLCCampaign(data: FormData): Promise<AxiosResponse<IResponseAdminTenDLCById>> {
    return baseApi.post('admin/core/compliance/10dlc/services/campaign', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  updateTollFreeBusinessInfo(
    params: IParamsUpdateTollFreeBusinessInfo | IParamsUpdateTollFreeBusinessContact
  ): Promise<AxiosResponse<IResponseAdminTollFreeByID>> {
    return baseApi.put('admin/core/compliance/toll-free/business-info', params)
  }

  updateTollFreeCampaign(data: FormData): Promise<AxiosResponse<IResponseAdminTollFreeByID>> {
    return baseApi.post('admin/core/compliance/toll-free', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  createPublishTollFree(profile_id: number): Promise<AxiosResponse<string[]>> {
    return baseApi.post(`admin/core/compliance/toll-free/${profile_id}/publish`)
  }
  createRejectTollFree({
    profile_id,
    reason,
  }: IParamsCreateRejectTollFree): Promise<AxiosResponse<string[]>> {
    return baseApi.post(`admin/core/compliance/toll-free/${profile_id}/manual-reject`, { reason })
  }
  resubmitTollFree({
    profile_id,
    number_id,
  }: IParamsResubmitTollFree): Promise<AxiosResponse<string[]>> {
    return baseApi.post(`admin/core/compliance/toll-free/${profile_id}/resubmit`, { number_id })
  }
}

export const AdminComplianceApi = new Api()
