import { useState } from 'react'
import classNames from 'classnames'
import { MenuItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import type { Segment } from 'entities/Segment/model/Segment'
import { urlSegmentId } from 'widgets/constants'
import { useContactsMenuContext } from 'widgets/ContactsMenu/context/contactsMenuContext'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { useDropdownItems } from '../../hooks/useDropdownItems'
import styles from './styles.module.scss'

type ISegmentDropdownProps = {
  segment: Segment
}

export const SegmentItem = ({ segment }: ISegmentDropdownProps) => {
  const contactsMenuStore = useContactsMenuContext()
  const {
    activeSegment,
    isNewSegment,
    changeActiveSegment,
    contactsTableStore,
    segmentModalStore,
  } = contactsMenuStore
  const { onOpen, segmentBox } = contactsFilterStore
  const { hasChanges, setHasChanges } = segmentBox
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const items = useDropdownItems(segment, contactsMenuStore)
  const {
    noEmptyFilters,
    setLoadingContacts,
    debounceLoadContacts,
    changePaginationToFirst,
    tableStore,
  } = contactsTableStore
  const { resetSelected } = tableStore

  const changeSegment = () => {
    if (uiStore.navigate) {
      uiStore.navigate(`/contacts?${urlSegmentId}=${segment.id}`)
    }

    changePaginationToFirst()
    changeActiveSegment(segment)
    onOpen(false)
    setHasChanges(false)
    resetSelected()
    setLoadingContacts(true)
    debounceLoadContacts()
  }

  return (
    <MenuItem
      title={segment.name}
      icon='segment'
      key={segment.id}
      active={activeSegment?.id === segment.id}
      className={classNames(styles.segment, isOpenDropdown && styles.activeDropdown)}
      onClick={() => {
        if (activeSegment?.id === segment.id) return

        const needAsk = hasChanges && !isNewSegment

        needAsk
          ? segmentModalStore?.openAskIfSaveModal({
              onToggle: changeSegment,
              noSetActive: true,
              filtersList: noEmptyFilters,
            })
          : changeSegment()
      }}
      isOpenDropdown={isOpenDropdown}
      items={items}
      dropdownProps={{
        ariaLabel: 'SegmentItem',
        onChangeOpen: setIsOpenDropdown,
        className: styles.moreButton,
        icon: 'moreHorizontal',
        placement: 'bottom-end',
        tooltipProps: {
          label: 'Show actions',
          placement: 'top-end',
        },
      }}
    />
  )
}
