import classnames from 'classnames'
import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type IColumn, Button, Icon, Table, IconButton, Typography, Tooltip } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { AddIntegrationSettingProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsProperty/AddIntegrationSettingProperty'
import { TitledRow } from 'pages/settings/pages/integrations/pages/integrationSettings/TitledRow/TitledRow'
import { type SettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/model/SettingsProperty'
import { type IntegrationHubspotSettingsPropertyStore } from '../../store/IntegrationHubspotSettingsPropertyStore'
import styles from './styles.module.scss'

const COLUMNS: IColumn<SettingsProperty>[] = [
  {
    field: 'type',
    name: 'Salesmsg',
    width: '30%',
    maxWidth: '160px',
    renderHeaderCell: ({ name }) => (
      <div className={styles.headerCell}>
        <Icon fontSize={16} icon={'salesmsg'} />
        {name}
      </div>
    ),
    renderRowCell: ({ fieldName }) => (
      <div className={styles.row}>
        {fieldName === 'Phone' ? (
          <TitledRow
            title={fieldName}
            tooltip={
              'Salesmsg uses the first property and falls back to the second property if blank.'
            }
          />
        ) : (
          <Typography
            ariaLabel={'title'}
            variant={'body-md-medium'}
            ellipsis
            tooltipProps={{
              label: fieldName,
              placement: 'top',
            }}
          >
            {fieldName}
          </Typography>
        )}
      </div>
    ),
  },
  {
    field: 'options',
    name: 'HubSpot',
    renderHeaderCell: ({ name }) => (
      <div className={styles.headerCell}>
        <Icon fontSize={16} icon={'hubspot'} />
        {name}
      </div>
    ),
    renderRowCell: ({ value, options, disabled, onChange, error }) => (
      <Tooltip label={error} placement={'top-end'} open={!!error} color='red' isDisplayGrid>
        <SelectField
          disabled={disabled}
          className={classnames(styles.select, { [styles.error]: error })}
          withSearch
          value={value}
          setValue={onChange}
          items={options}
          size={'medium'}
          dropdownProps={{
            propsDropdownList: {
              className: styles.dropdown,
            },
          }}
          btnProps={{
            className: styles.selectBtn,
          }}
        />
      </Tooltip>
    ),
  },
  {
    field: 'mapped',
    name: 'Mapped',
    maxWidth: '120px',
    renderRowCell: ({ mapped }) =>
      mapped ? (
        <Icon fontSize={16} icon={'check1'} color={'var(--action-contained-primary-normal)'} />
      ) : (
        <Icon fontSize={16} icon={'close'} tertiary />
      ),
  },
  {
    field: 'control',
    name: ' ',
    maxWidth: '32px',
    renderRowCell: ({ onDelete, type }) =>
      type !== 'default' && (
        <IconButton
          className={styles.headerIcon}
          iconProps={{ fontSize: 16 }}
          tooltipProps={{
            label: 'Delete',
            placement: 'top',
          }}
          variant={'icon'}
          color={'tertiary'}
          size={'small'}
          icon={'delete'}
          ariaLabel='Refresh_data'
          onClick={onDelete}
        />
      ),
  },
]

type IIntegrationHubspotSettingsPropertyProps = { store: IntegrationHubspotSettingsPropertyStore }

export const IntegrationHubspotSettingsProperty: FC<IIntegrationHubspotSettingsPropertyProps> =
  observer(({ store }) => {
    const { rows, showSaveControl, saveNewProperties, clearNewProperties, loading } = store

    return (
      <div className={styles.root}>
        <div className={classnames(styles.table, { [styles.loading]: loading })}>
          <Table
            columns={COLUMNS}
            rows={rows}
            noPagination
            scrollbarProps={{
              autoHeight: true,
              autoHeightMin: 0,
              autoHeightMax: 850,
              viewClassName: styles.viewScrollClassName,
            }}
          />
        </div>
        <div className={styles.footer}>
          <AddIntegrationSettingProperty store={store} />
          {showSaveControl && (
            <div className={styles.control}>
              <Button
                text='Cancel'
                typeBtn='contained'
                contained='secondary'
                size='medium'
                onClick={clearNewProperties}
              />
              <Button
                text='Save'
                typeBtn='contained'
                contained='primary'
                size='medium'
                onClick={saveNewProperties}
              />
            </div>
          )}
        </div>
      </div>
    )
  })
