import axios, { CanceledError, CancelTokenSource } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { debounce } from 'lodash'

import { type IPreviewWorkflowData, WorkflowsApi, WorkflowStatus } from 'entities/Workflow'

const PER_PAGE = 10

export class WorkflowEnrollSearchStore {
  private _page = 1
  private _total = 0
  private _term = ''
  private _loading = true
  private _initialLoading = true
  private _cancelTokenSource: CancelTokenSource | null = null

  private _termItems = new Map<string, IPreviewWorkflowData>()

  constructor() {
    makeAutoObservable(this)
  }

  get term() {
    return this._term
  }

  get hasMore() {
    return this._loading || this._page * PER_PAGE < this._total
  }

  get loading() {
    return this._loading
  }

  get initialLoading() {
    return this._initialLoading
  }

  get termItems() {
    return Array.from(this._termItems.values())
  }

  get termItemsEmpty() {
    return !this._termItems.size
  }

  searchItems = async (term: string) => {
    this._term = term
    this._page = 1

    this._tryMakeRequest(async () => {
      runInAction(() => this._termItems.clear())

      const {
        data: { payload, meta },
      } = await WorkflowsApi.getWorkflows(
        {
          preview: true,
          'page[size]': PER_PAGE,
          'page[pointer]': this._page,
          'filters[name]': this._term || null,
          'filters[status]': WorkflowStatus.Active,
        },
        { cancelToken: this._cancelTokenSource?.token }
      )

      runInAction(() => {
        this._page = meta.pagination.pointer.current
        this._total = meta.pagination.total

        payload.forEach((item) => this._termItems.set(item.id, item))
      })
    })
  }

  loadMore = () =>
    this._tryMakeRequest(async () => {
      const {
        data: { payload, meta },
      } = await WorkflowsApi.getWorkflows(
        {
          preview: true,
          'page[pointer]': this._page + 1,
          'page[size]': PER_PAGE,
          'filters[name]': this._term || null,
          'filters[status]': WorkflowStatus.Active,
        },
        { cancelToken: this._cancelTokenSource?.token }
      )

      runInAction(() => {
        this._page = meta.pagination.pointer.current
        this._total = meta.pagination.total

        payload.forEach((item) => this._termItems.set(item.id, item))
      })
    })

  reset = () => {
    this._page = 0
    this._total = 0
    this._term = ''
    this._loading = true
    this._initialLoading = true

    this._termItems.clear()
    this._cancelTokenSource?.cancel()
  }

  private _initCancelTokenSource = () => {
    if (this._cancelTokenSource) this._cancelTokenSource.cancel()

    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _tryMakeRequest = debounce(async (action: () => Promise<void>) => {
    runInAction(() => {
      this._loading = true
      this._initCancelTokenSource()
    })

    try {
      await action()
      runInAction(() => {
        this._loading = false
        this._initialLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
        this._initialLoading = false
      })

      console.error(error)
    }
  }, 500)
}
