import { makeAutoObservable, reaction, type IReactionDisposer, runInAction } from 'mobx'
import { type IInboxCombine, inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { organizationStore } from 'entities/Organization'
import { MultiOrganizationsDropdownStore } from 'widgets/MultiOrganizationsDropdown'
import { menuItemsV2 } from 'widgets/Sidebar/constants/menuItems'
import { getPathAIAutomation } from 'widgets/Menu/ui/MenuAIAutomation'
import { getPathOutbound } from 'widgets/Menu/ui/MenuOutbound'

export class SidebarStore {
  private _loading = true
  private _inboxesMap: Map<number, IInboxCombine> = new Map()
  private _disposeInboxes: IReactionDisposer | null = null
  private _multiOrganizationsDropdownStore = new MultiOrganizationsDropdownStore()

  constructor() {
    makeAutoObservable(this)
  }

  get inboxesMap() {
    return this._inboxesMap
  }

  get multiOrganizationsDropdownStore() {
    return this._multiOrganizationsDropdownStore
  }

  get loading() {
    return this._loading
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([
      usersStore.initPromise,
      userPermissionsStore.initPromise,
      featureFlagsStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
    ])

    runInAction(() => {
      this._loading = false
    })
  }

  reset() {
    this._disposeInboxes?.()
    this._inboxesMap.clear()
  }

  initInboxes = () => {
    inboxesStore.inboxesList.forEach((item) => {
      this._inboxesMap.set(item.id, item)
    })
  }

  reInitInboxes = () => {
    inboxesStore.reFetchInboxes()
    this.initInboxes()
  }

  get unread() {
    return Array.from(this._inboxesMap.values())
      .map((item) => item.unread)
      .filter((item) => item)
      .reduce((state, value) => state + value, 0)
  }
  get unreadMessagesCount() {
    return Array.from(this._inboxesMap.values())
      .map((item) => item.unread_messages_count)
      .filter((item) => item)
      .reduce((state, value) => state + value, 0)
  }

  get menuItems() {
    if (subscriptionStore.isAgencyWithoutSubscription) return []

    const canManageAnalytics = userPermissionsStore.getItem('can_access_analytics')
    const pathAIAutomation = getPathAIAutomation(usersStore.user_owner_id)
    const pathOutbound = getPathOutbound(usersStore.user_owner_id)

    return menuItemsV2.filter((item) => {
      if (item.itemKey === 'analytics' && !canManageAnalytics) return false
      if (item.itemKey === 'AI_automation' && !pathAIAutomation) return false
      if (item.itemKey === 'outbound' && !pathOutbound) return false

      return true
    })
  }

  handleReactionInboxes = () => {
    this._disposeInboxes?.()
    this._disposeInboxes = reaction(
      () => inboxesStore.inboxesList.length,
      () => {
        this.initInboxes()
      },
      {
        fireImmediately: true,
      }
    )
  }
}
