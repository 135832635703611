// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErL0xy4QaADY4CcMvVEw{display:flex;flex-direction:column;gap:8px;padding:8px;border-radius:8px;background:var(--background-primary-inverted-primary)}.ErL0xy4QaADY4CcMvVEw.vUhBEaiHt2AMPdpFQdJn{box-shadow:0px 20px 50px 0px rgba(18,21,23,.05),0px 10px 30px -5px rgba(18,21,23,.1),0px 0px 0px 1px rgba(18,21,23,.05)}.M4WINaOs7CKSFgoYUGFd{display:flex;padding:8px 24px 0 24px;flex-direction:column;justify-content:center;align-items:flex-start;gap:8px;align-self:stretch}.U1berUgLLKeEdyjAuYa0{padding-left:6px}.p8ZVk7WjG4IWw05lb2KT{display:flex;width:20px;justify-content:center}.ei4LCQhQzuLptpKOco2N{width:2px;height:20px;border-radius:10px;background:var(--black-30)}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignMessage/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,WAAA,CACA,iBAAA,CACA,qDAAA,CACA,2CACE,uHAAA,CAIJ,sBACE,YAAA,CACA,uBAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,OAAA,CACA,kBAAA,CAEF,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CACA,sBAAA,CAEF,sBACE,SAAA,CACA,WAAA,CACA,kBAAA,CACA,0BAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 8px;\n  border-radius: 8px;\n  background: var(--background-primary-inverted-primary);\n  &.isDragging {\n    box-shadow: 0px 20px 50px 0px rgba(18, 21, 23, 0.05), 0px 10px 30px -5px rgba(18, 21, 23, 0.1),\n      0px 0px 0px 1px rgba(18, 21, 23, 0.05);\n  }\n}\n.scheduleWrap {\n  display: flex;\n  padding: 8px 24px 0 24px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 8px;\n  align-self: stretch;\n}\n.wrapArrow {\n  padding-left: 6px;\n}\n\n.wrapLine {\n  display: flex;\n  width: 20px;\n  justify-content: center;\n}\n.line {\n  width: 2px;\n  height: 20px;\n  border-radius: 10px;\n  background: var(--black-30);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ErL0xy4QaADY4CcMvVEw",
	"isDragging": "vUhBEaiHt2AMPdpFQdJn",
	"scheduleWrap": "M4WINaOs7CKSFgoYUGFd",
	"wrapArrow": "U1berUgLLKeEdyjAuYa0",
	"wrapLine": "p8ZVk7WjG4IWw05lb2KT",
	"line": "ei4LCQhQzuLptpKOco2N"
};
export default ___CSS_LOADER_EXPORT___;
