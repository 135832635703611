import dayjs from 'dayjs'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { Base } from 'models'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { ActivityType } from 'entities/Activity/model/types'
import { IResponseActivity } from 'entities/Activity/api/types'
import { IConversationsHistoryInfo } from 'entities/Message'

export class Activity extends Base {
  assignedUser: IResponseActivity['assignedUser']
  user: IResponseActivity['user']
  relatedContact: IResponseActivity['relatedContact']
  conversation_id: number
  created_at: string
  id: number
  item_type: 'activity_log'
  organization_id: number
  sorted_date: string
  type: ActivityType | string
  updated_at: string
  conversations_history_info?: IConversationsHistoryInfo

  constructor(item: IResponseActivity, conversationsHistoryInfo?: IConversationsHistoryInfo) {
    super(item.id)
    this.assignedUser = item.assignedUser
    this.conversation_id = item.conversation_id
    this.created_at = item.created_at
    this.id = item.id
    this.item_type = item.item_type
    this.organization_id = item.organization_id
    this.sorted_date = item.sorted_date
    this.type = item.type
    this.updated_at = item.updated_at
    this.user = item.user
    this.relatedContact = item.relatedContact

    if (conversationsHistoryInfo) {
      this.conversations_history_info = conversationsHistoryInfo
    }
  }

  get user_id() {
    return this.user?.user_id
  }

  get assigned_user_id() {
    return this.assignedUser?.user_id
  }

  get date() {
    return this.sorted_date || this.created_at
  }

  get sortDateTime() {
    return dayjs(this.sorted_date || this.date).valueOf()
  }

  get day() {
    return uiStore.dayjs(this.date).format(DayjsFormats.dateSort)
  }

  get text() {
    const user = usersStore.getItem(this.user.user_id)
    const assignedUser = this.assignedUser && usersStore.getItem(this.assignedUser?.user_id)
    const contact = this.relatedContact && contactsStore.getItem(this.relatedContact?.user_id)

    const userName = user?.displayInfo || `${this.user.first_name} ${this.user.last_name}`
    const assignedUserName =
      assignedUser?.displayInfo ||
      `${this.assignedUser?.first_name} ${this.assignedUser?.last_name}`

    if (this.type === 'reassign') {
      if (assignedUser) {
        return `${userName} reassigned the conversation to ${assignedUserName}`
      }
      return `${userName} unassigned the conversation`
    }
    if (this.type === 'marked') {
      return `${userName} made priority the conversation`
    }
    if (this.type === 'unmarked') {
      return `${userName} unmade priority the conversation`
    }
    if (this.type === 'reopened') {
      const contactName =
        contact?.name ||
        `${this.relatedContact.first_name} ${this.relatedContact.last_name}`.trim() ||
        this.relatedContact.number
      return `${userName || contactName} ${this.type} the conversation`
    }
    if (this.type === 'responded') {
      return `Marked as responded by ${userName}`
    }
    if (this.type === 'response_needed') {
      return `Marked as Needs Response by ${userName}`
    }

    return `${userName} ${this.type} the conversation`
  }
  get isConversationsHistory() {
    return Boolean(this.conversations_history_info)
  }
  get teamName() {
    return this.conversations_history_info?.teamName || ''
  }
  get teamId() {
    return this.conversations_history_info?.teamId
  }
}
