import { useState, FC } from 'react'
import { type IMenuItemProps, MenuItem } from 'shared/ui/Menu/ui/MenuItem/MenuItem'
import { MenuTitle } from 'shared/ui/Menu/ui/Title/MenuTitle'
import { MenuItemVue } from 'shared/ui/Menu/ui/MenuItemVue/MenuItemVue'

type IMenuGroupProps = {
  title: string
  items: IMenuItemProps[]
  className?: string
  marginBottom?: number
  initOpen?: boolean
  withExpand?: boolean
}

export const MenuGroup: FC<IMenuGroupProps> = ({
  title,
  items,
  className = '',
  initOpen = true,
  withExpand = false,
}) => {
  const [open, setOpen] = useState(initOpen)

  return (
    <div className={className}>
      <MenuTitle
        title={title}
        {...(withExpand && {
          open: open,
          handleToggle: () => setOpen(!open),
        })}
      />
      {(!withExpand || open) &&
        items.map((item) => {
          if (item.type === 'nav') {
            if (window.DesignV2API && !window.DesignV2API?.handleCheckPage(item.to)) {
              return <MenuItemVue {...item} key={item.key} />
            }

            return <MenuItem {...item} key={item.key} />
          }

          if (item.type === 'default') {
            return <MenuItem {...item} key={item.key} />
          }

          return null
        })}
    </div>
  )
}
