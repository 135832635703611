import { createContext, useContext } from 'react'
import { type SavedRepliesStore } from './../store/savedRepliesStore'

export const SavedRepliesContext = createContext<SavedRepliesStore | null>(null)

export const useSavedRepliesContext = () => {
  const context = useContext(SavedRepliesContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with SavedRepliesContext')

  return context
}
