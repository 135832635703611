import { ReactNode, useMemo, useRef, useState } from 'react'
import { Chip, Dropdown, IDropdownItem, IDropdownProps, Typography } from 'shared/ui'
import { makeTextFieldIcon, TextFieldPlaceholder } from 'shared/ui/TextField'
import styles from '../styles.module.scss'

export type ISelectMultiListProps = {
  type: 'multiList'
  text: ReactNode
  selectedItems?: IDropdownItem[]
  items: IDropdownItem[]
  dropdownProps?: IDropdownProps
  fullWidth?: boolean
  onDelete?: (item: IDropdownItem) => void
  onSelect?: (items: IDropdownItem) => void
}

export const SelectMultiList = ({
  text,
  dropdownProps,
  items,
  selectedItems,
  fullWidth,
  onDelete,
  onSelect,
}: ISelectMultiListProps) => {
  const [search, setSearch] = useState('')
  const ref = useRef<HTMLLabelElement | null>(null)

  const selectedIds = useMemo(
    () => new Set(selectedItems?.map((item) => item.id) || []),
    [selectedItems]
  )

  const showItems = useMemo(() => {
    const lowercasedSearch = search.toLowerCase()

    return items
      .filter(
        (item) =>
          !selectedIds.has(item.id) &&
          (!search || item.label.toLowerCase().includes(lowercasedSearch))
      )
      .map((item) => ({ ...item, disabledClosedTooltip: true }))
  }, [items, search, selectedIds])

  return (
    <Dropdown
      ariaLabel={'SelectMultiList'}
      width={fullWidth ? ref.current?.clientWidth : undefined}
      disableStopPropagation
      triggerComponent={() => (
        <TextFieldPlaceholder
          multiline
          variant='stroke'
          size='medium'
          ref={ref}
          rightActions={[makeTextFieldIcon({ icon: 'chevronDown' })]}
          placeholder={
            selectedItems?.length ? (
              <div className={styles.multiList}>
                <div className={styles.placeholderWrap}>
                  {selectedItems?.map((item) => (
                    <Chip
                      key={item.id}
                      label={item.label}
                      iconType='div'
                      onDelete={() => onDelete?.(item)}
                    />
                  ))}
                  <input
                    type='text'
                    className={styles.searchInput}
                    placeholder='Search'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <Typography
                ariaLabel='InitialText'
                variant={'text-input-field-r'}
                color='var(--content-neutral-primary)'
              >
                {text || 'Select'}
              </Typography>
            )
          }
          {...dropdownProps?.textFieldProps}
        />
      )}
      maxHeight={300}
      items={showItems}
      onChange={(item: IDropdownItem) => {
        onSelect?.(item)
        setSearch('')
      }}
      placement={'bottom-start'}
      {...dropdownProps}
    />
  )
}
