import type { IMenuItemProps } from 'shared/ui'
import { BroadcastsPath, TriggersPath, PowerDialerPath } from 'entities/ExportRouters'

export const OutboundKey = 'outbound'

export const subItemsOutboundActive = [
  BroadcastsPath.root,
  BroadcastsPath.all,
  BroadcastsPath.oneTime,
  BroadcastsPath.recurring,
  BroadcastsPath.ringless,
  BroadcastsPath.create,
  BroadcastsPath.edit,
  BroadcastsPath.clone,
  BroadcastsPath.view,

  TriggersPath.all,
  TriggersPath.sms,
  TriggersPath.ringless,
  TriggersPath.create,
  TriggersPath.edit,
  TriggersPath.clone,
  TriggersPath.view,

  PowerDialerPath.root,
]

export enum EnumMenuOutboundItemsKey {
  broadcasts = 'broadcasts',
  broadcasts_all = 'broadcasts_all',
  broadcasts_onetime = 'broadcasts_onetime',
  broadcasts_recurring = 'broadcasts_recurring',

  triggers = 'triggers',
  triggers_all = 'triggers_all',
  triggers_sms = 'triggers_sms',
  triggers_ringless = 'triggers_ringless',

  power_dialer = 'power_dialer',
}

export const listItemsOutbound = {
  [EnumMenuOutboundItemsKey.broadcasts_all]: BroadcastsPath.all,
  [EnumMenuOutboundItemsKey.broadcasts_onetime]: BroadcastsPath.oneTime,
  [EnumMenuOutboundItemsKey.broadcasts_recurring]: BroadcastsPath.recurring,

  [EnumMenuOutboundItemsKey.triggers_all]: TriggersPath.all,
  [EnumMenuOutboundItemsKey.triggers_sms]: TriggersPath.sms,
  [EnumMenuOutboundItemsKey.triggers_ringless]: TriggersPath.ringless,

  [EnumMenuOutboundItemsKey.power_dialer]: PowerDialerPath.root,
}

export const menuItems: IMenuItemProps<EnumMenuOutboundItemsKey>[] = [
  {
    key: EnumMenuOutboundItemsKey.broadcasts,
    type: 'sub',
    label: 'Broadcasts',
    icon: 'broadcasts',
    items: [
      {
        key: EnumMenuOutboundItemsKey.broadcasts_all,
        type: 'nav',
        to: BroadcastsPath.all,
        fullPath: true,
        label: 'All',
      },
      {
        key: EnumMenuOutboundItemsKey.broadcasts_onetime,
        type: 'nav',
        to: BroadcastsPath.oneTime,
        fullPath: true,
        label: 'One time',
      },
      {
        key: EnumMenuOutboundItemsKey.broadcasts_recurring,
        type: 'nav',
        to: BroadcastsPath.recurring,
        fullPath: true,
        label: 'Recurring',
      },
    ],
  },
  {
    key: EnumMenuOutboundItemsKey.triggers,
    type: 'sub',
    label: 'Triggers',
    icon: 'lightning2',
    items: [
      {
        key: EnumMenuOutboundItemsKey.triggers_all,
        type: 'nav',
        to: TriggersPath.all,
        fullPath: true,
        label: 'All',
      },
      {
        key: EnumMenuOutboundItemsKey.triggers_sms,
        type: 'nav',
        to: TriggersPath.sms,
        fullPath: true,
        label: 'SMS',
      },
      {
        key: EnumMenuOutboundItemsKey.triggers_ringless,
        type: 'nav',
        to: TriggersPath.ringless,
        fullPath: true,
        label: 'Ringless',
      },
    ],
  },
  {
    key: EnumMenuOutboundItemsKey.power_dialer,
    type: 'nav',
    to: PowerDialerPath.root,
    fullPath: true,
    label: 'Power Dialer',
    icon: 'phoneRightArrows',
    disabled: false,
  },
]
