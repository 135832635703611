import { observer } from 'mobx-react-lite'
import { EnumSpinnerLoaderPosition, FullPageWrapper, SpinnerLoader } from 'shared/ui'
import { ContactsDetails } from 'widgets/ContactsDetails'
import { useKeywordDetailsContext } from 'pages/keywords/context'
import {
  KeywordDetailsHeader,
  KeywordStatisticsFilter,
  KeywordStatisticsSearch,
  KeywordStatisticsTable,
} from './Details'

export const KeywordDetails = observer(() => {
  const { isLoading, contactsDetailsStore } = useKeywordDetailsContext()

  if (isLoading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }

  return (
    <FullPageWrapper>
      <KeywordDetailsHeader />
      <KeywordStatisticsFilter />
      <KeywordStatisticsSearch />
      <KeywordStatisticsTable />
      {contactsDetailsStore && <ContactsDetails store={contactsDetailsStore} />}
    </FullPageWrapper>
  )
})
