// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WHnd99fT02Uw4wAaj1JD{color:var(--content-primary-primary);margin:20px auto 4px;font-size:14px;font-weight:500}.GBHaFSQaD6KQVj3wjJlo{margin-top:4px;margin-bottom:21px;color:var(--content-primary-tertiary);font-size:13px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/AddFirstContactContent/AddFirstContactContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,oCAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CAEF,sBACE,cAAA,CACA,kBAAA,CACA,qCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".headerText {\n  color: var(--content-primary-primary);\n  margin: 20px auto 4px;\n  font-size: 14px;\n  font-weight: 500;\n}\n.additionText {\n  margin-top: 4px;\n  margin-bottom: 21px;\n  color: var(--content-primary-tertiary);\n  font-size: 13px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerText": "WHnd99fT02Uw4wAaj1JD",
	"additionText": "GBHaFSQaD6KQVj3wjJlo"
};
export default ___CSS_LOADER_EXPORT___;
