import { makeAutoObservable } from 'mobx'
import type {
  IOptInMethodTypes,
  IRegistrationError,
  IResponseComplianceServicesCampaign,
} from 'entities/Compliance'

export class ComplianceServiceCampaign {
  private _id: IResponseComplianceServicesCampaign['id'] | null = null
  private _status: IResponseComplianceServicesCampaign['status'] | null = null
  private _data: IResponseComplianceServicesCampaign['data'] | null = null
  private _registrationErrors: IResponseComplianceServicesCampaign['registration_errors'] = []
  private _createAt: IResponseComplianceServicesCampaign['create_at'] | null = null
  private _submittedAt: IResponseComplianceServicesCampaign['submitted_at'] | null = null

  constructor(response: IResponseComplianceServicesCampaign) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseComplianceServicesCampaign) => {
    this._id = response.id || null
    this._status = response.status || null
    this._data = response.data || null
    this._registrationErrors = response.registration_errors
    this._createAt = response.create_at
    this._submittedAt = response.submitted_at
  }

  get id() {
    return this._id
  }

  get status() {
    return this._status
  }

  get isSubmitted() {
    if (!this._status) return false

    return this._status !== 'in_progress'
  }

  get data() {
    return this._data
  }

  get useCaseTitle() {
    return this._data?.useCaseData?.useCaseTitle || ''
  }

  get useCaseData() {
    return this._data?.useCaseData
  }

  get createAt() {
    return this._createAt
  }

  get submittedAt() {
    return this._submittedAt
  }

  get optInData() {
    return this._data?.optInData
  }

  get registrationError(): IRegistrationError | undefined {
    return this._registrationErrors[0]
  }

  get isInternalRejected() {
    if (!this.isStatusFailed) return false

    return this.registrationError?.rejection_source === 'admin'
  }

  get isExternalRejected() {
    if (!this.isStatusFailed) return false

    return this.registrationError?.rejection_source !== 'admin'
  }

  get isStatusInProgress() {
    return this._status === 'in_progress'
  }

  get isStatusPendingReview() {
    return this._status === 'pending_review'
  }

  get isStatusInCarrierReview() {
    return this._status === 'in_carrier_review'
  }

  get isStatusApproved() {
    return this._status === 'approved'
  }

  get isStatusFailed() {
    return this._status === 'failed'
  }

  getOptInDataByMethod = (method: IOptInMethodTypes) => {
    return this._data?.optInData?.find((item) => item.optInMethod === method)
  }
}
