import { useLayoutEffect } from 'react'
import { useParams } from 'react-router'
import { PageLayout } from 'shared/layout'
import { conversationStore } from 'entities/Conversation'
import { ConversationMenu } from 'widgets/ConversationMenu'
import { useConversationsPageContext } from 'pages/conversations/context'

export const ConversationsLayoutContent = () => {
  const { conversationId } = useParams()
  const store = useConversationsPageContext()
  const { pageLayoutStore, conversationMenuStore } = store

  useLayoutEffect(() => {
    conversationStore.setModeNotOpen(conversationId === 'null')

    return () => {
      conversationStore.setCurrentItemId(null)
    }
  }, [])

  useLayoutEffect(() => {
    const id = conversationId !== 'null' ? Number(conversationId) : null

    conversationStore.setCurrentItemId(id)
  }, [conversationId])

  return (
    <PageLayout
      store={pageLayoutStore}
      leftContent={<ConversationMenu store={conversationMenuStore} />}
      mobileWithSizeM
    />
  )
}
