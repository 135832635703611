// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wP4_6n64YOutIMnsAiTz{box-sizing:border-box;padding:3.5px;padding-left:8px;display:inline-flex;align-items:center;gap:4px;border-radius:6px;border:1px solid var(--border-neutral-default);background:var(--background-primary-inverted-primary)}.wP4_6n64YOutIMnsAiTz .w5vOIlnyxfAuHg4XpDa_{cursor:pointer}.wP4_6n64YOutIMnsAiTz .wqRburiZOEkDF4jGTPE1{width:16px;height:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Chip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,aAAA,CACA,gBAAA,CACA,mBAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA,CACA,8CAAA,CACA,qDAAA,CAEA,4CACE,cAAA,CAEF,4CACE,UAAA,CACA,WAAA","sourcesContent":[".wrap {\n  box-sizing: border-box;\n  padding: 3.5px;\n  padding-left: 8px;\n  display: inline-flex;\n  align-items: center;\n  gap: 4px;\n  border-radius: 6px;\n  border: 1px solid var(--border-neutral-default);\n  background: var(--background-primary-inverted-primary);\n\n  .clickable {\n    cursor: pointer;\n  }\n  .icon {\n    width: 16px;\n    height: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wP4_6n64YOutIMnsAiTz",
	"clickable": "w5vOIlnyxfAuHg4XpDa_",
	"icon": "wqRburiZOEkDF4jGTPE1"
};
export default ___CSS_LOADER_EXPORT___;
