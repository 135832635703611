import axios, { AxiosResponse } from 'axios'
import type { AxiosOptions, IResponseStatus } from 'shared/api'
import type {
  IParamsKnowledgeBaseDocuments,
  IParamsKnowledgeBaseDocumentUploadLink,
  IResponseKnowledgeBase,
  IResponseKnowledgeBaseDocuments,
  IResponseKnowledgeBaseDocumentUploadLink,
} from 'entities/KnowledgeBase'
import { baseApi } from 'entities/Auth'

class Api {
  getKnowledgeBase(): Promise<AxiosResponse<IResponseKnowledgeBase>> {
    return baseApi.get('chatbot/api/knowledge-base/view')
  }

  getKnowledgeBaseDocuments(
    params: IParamsKnowledgeBaseDocuments,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseKnowledgeBaseDocuments>> {
    return baseApi.get('chatbot/api/knowledge-base/documents', { ...options, params })
  }

  delete(ids: Array<number>): Promise<AxiosResponse<IResponseStatus>> {
    return baseApi.delete('chatbot/api/knowledge-base/documents/delete/bulk', {
      params: {
        ids,
      },
    })
  }

  getDownloadLink(id: number): Promise<AxiosResponse<{ link: string }>> {
    return baseApi.post(`chatbot/api/knowledge-base/documents/download/${id}`)
  }

  getBulkDownloadLink(ids: Array<string | number>): Promise<AxiosResponse<{ link: string }>> {
    return baseApi.post('chatbot/api/knowledge-base/documents/download/bulk', {
      ids,
    })
  }

  getUploadDocumentLink(
    params: IParamsKnowledgeBaseDocumentUploadLink
  ): Promise<AxiosResponse<IResponseKnowledgeBaseDocumentUploadLink>> {
    return baseApi.post('chatbot/api/knowledge-base/documents/upload', params)
  }

  uploadDocument(url: string, file: File): Promise<AxiosResponse<IResponseStatus>> {
    return axios.put(url, file, {
      headers: { 'Content-Type': file.type },
    })
  }

  retryDocumentsIndexation(ids: Array<number>): Promise<AxiosResponse<IResponseStatus>> {
    return baseApi.put('chatbot/api/knowledge-base/documents/indexation-retry/bulk', {
      ids,
    })
  }
}

export const KnowledgeBaseApi = new Api()
