import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { PageLayoutStore } from 'shared/layout'
import { ActionItem, ContainerHeader } from 'shared/ui'
import {
  KeywordNewButton,
  KeywordsHelpGuideButton,
} from 'pages/keywords/pages/all/ui/KeywordsButtons'

type IKeywordsListHeaderProps = {
  title: string
  pageLayoutStore: PageLayoutStore
  isKeywordsActionShown: boolean
}

export const KeywordsListHeader = observer(
  ({ title, pageLayoutStore, isKeywordsActionShown }: IKeywordsListHeaderProps) => {
    const { titleIconProps, handleToggleCollapse } = pageLayoutStore

    const actions: ActionItem[] = useMemo(() => {
      if (!isKeywordsActionShown) {
        return []
      }
      return [
        {
          iconButtonComponent: <KeywordNewButton />,
        },
        { iconButtonComponent: <KeywordsHelpGuideButton /> },
      ]
    }, [isKeywordsActionShown])
    return (
      <ContainerHeader
        title={title}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        titleIconAction={handleToggleCollapse}
        paddingLeft={20}
        actions={actions}
        actionsGap={8}
      />
    )
  }
)
