import { Route, Routes } from 'react-router'
import { QuestionnairePage } from 'pages/settings/pages/compliance/pages/questionnaire/ui/questionnairePage'
import { QuestionnaireLayout } from 'pages/settings/pages/compliance/pages/questionnaire/layout/questionnaireLayout'

export const QuestionnaireRouter = () => {
  return (
    <Routes>
      <Route element={<QuestionnaireLayout />}>
        <Route path={'/'} element={<QuestionnairePage />} />
        <Route path='*' element={<div>Page Not Found</div>} />
      </Route>
    </Routes>
  )
}
