import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, IconButton, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { type TollFreeProfile } from 'entities/Compliance/model'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { StatusesTF } from './StatusesTF/StatusesTF'
import styles from './styles.module.scss'

type ISummaryTollFreeItemProps = {
  item: TollFreeProfile
}

export const SummaryTollFreeItem: FC<ISummaryTollFreeItemProps> = observer(({ item }) => {
  const step = 3
  const numbersCount = item.numberCount
  const isStatusInProgress = item.isStatusInProgress

  const continueText = (
    <Typography ariaLabel='Continue' variant='button-medium'>
      {'Continue: '}
      <Typography
        ariaLabel='Step'
        variant='button-medium'
        color='var(--content-primary-tertiary-inverted)'
      >
        {`Step ${step}/5`}
      </Typography>
    </Typography>
  )

  return (
    <div className={styles.wrap}>
      <div className={styles.info}>
        <div className={styles.leftContent}>
          <Typography ariaLabel='AfterRegisterSectionLN_title' variant='body-md-medium'>
            {item.useCaseName}
          </Typography>
          <Typography
            ariaLabel='AfterRegisterSectionLN_desc'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            {`${numbersCount} number`}
          </Typography>
        </div>
        <div className={styles.rightContent}>
          {isStatusInProgress && (
            <>
              <Button
                text={continueText}
                size='small'
                className={styles.button}
                onClick={() => {
                  uiStore.changeRouteViaVue({
                    path: `/${CompliancePath.compliance.tollFree.viewSteps(item.id)}`,
                  })
                }}
              />
              <IconButton
                ariaLabel='Delete'
                icon='delete'
                size='medium'
                color='transparent'
                onClick={() => {
                  modalStore.addModal({
                    id: 'deleteTollFreeNumber',
                    desc: 'Delete toll-free use case progress?',
                    title: 'This action cannot be undone',
                  })
                }}
              />
            </>
          )}
          {!isStatusInProgress && numbersCount && (
            <IconButton
              ariaLabel='Check_condition'
              icon='chevronRight'
              color='transparent'
              onClick={() => {
                uiStore.changeRouteViaVue({
                  path: `/${CompliancePath.compliance.tollFree.view(item.id)}`,
                })
              }}
            />
          )}
        </div>
      </div>

      <StatusesTF item={item} />
    </div>
  )
})
