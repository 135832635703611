import { makeAutoObservable } from 'mobx'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers/route/type'
import { type ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

const stepUrlMap: Record<number, string> = {
  0: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.campaignUseCases}`,
  1: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.campaignUseCases}`,
  2: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.messages}`,
  3: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.optInMethod}`,
  4: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.optInOnlineFormContent}`,
  5: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.forbiddenMessageTopics}`,
  6: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.package}`,
  7: `${CompliancePath.compliance.localNumbers.root}/${LocalNumbersRoutes.orderSummary}`,
}

export class LocalNumbersNavigationStore {
  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)
  }

  get correctStepForNext() {
    if (this.UIFormStep === 3 || this.UIFormStep === 4) {
      return 3
    }

    return this.UIFormStep + 1
  }

  get nextStep() {
    return stepUrlMap[this.correctStepForNext]
  }

  get formStep() {
    if (!this.data?.data?.registrationFormStep) return 0

    return this.data.data.registrationFormStep
  }

  getStepForContinue = (allStepsLength: number) => {
    const baseStepsLength = 7
    const { UIFormStep } = this

    if (UIFormStep < 3) return UIFormStep + 1
    if (UIFormStep === 3 || UIFormStep === 4) {
      return 3
    }

    return UIFormStep + (allStepsLength - baseStepsLength) + 1
  }

  get UIFormStep() {
    return this.formStep
  }

  get data() {
    return this._complianceLayerStore.complianceServiceCampaign
  }
}
