import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { ContactsApi } from 'entities/Contacts'
import { ContactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { History } from '../model/History'
import { HistoryModalContent } from '../ui/HistoryModalContent'

interface IIModalProps {
  title: string
  width: number
  desc?: ModalType['desc']
  ModalContent?: ModalType['ModalContent']
}

const defaultPagination = {
  page: 1,
  limit: 10,
}

class ContactsHistoryStore {
  private _historiesMap: Map<number, History> = new Map()
  private _deletedHistoriesMap: Map<number, History> = new Map()
  private _total = 0
  private _modalId = ''
  private _paginationData = defaultPagination
  private _loading = false
  private _disposeChangePagination: IReactionDisposer | null = null
  private _contactsTagsModalStore = new ContactsTagsModalStore()

  constructor() {
    makeAutoObservable(this)

    this.reactionChangePagination()
  }

  get contactsTagsModalStore() {
    return this._contactsTagsModalStore
  }

  get historyData() {
    return Array.from(this._historiesMap.values())
  }

  get totalHistory() {
    return this._total
  }

  get pagination() {
    return this._paginationData
  }

  get isLoading() {
    return this._loading
  }

  get isNoImportsYet() {
    return !this._loading && !this._historiesMap.size
  }

  get pendingId() {
    const pendingHistory = this.historyData.find(
      (history) => history.createdAt && history.finishedAt === null
    )

    return this.historyData.length === 1 && pendingHistory ? pendingHistory.id : null
  }

  checkIsExistHistory = (id: number) => {
    const history = this._historiesMap.get(id)

    return !!history
  }

  setIsLoading = (status: boolean) => {
    this._loading = status
  }

  dispose = () => {
    this._paginationData = defaultPagination
    this._historiesMap.clear()
    this._total = 0
    this._deletedHistoriesMap.clear()
    this._disposeChangePagination?.()
  }

  getDuplicateReasons = (id: number) => {
    return this._historiesMap.get(id)?.duplicateReasons || []
  }

  getSkippedReasons = (id: number) => {
    return this._historiesMap.get(id)?.skippedReasons || []
  }

  addHistory = (history: History) => {
    this._historiesMap.set(history.id, history)
  }

  getImportContactProgress = async (id: number) => {
    try {
      const { data: history } = await ContactsApi.getImportContactProgress(id)
      const newHistory = new History(history)

      this.addHistory(newHistory)

      if (newHistory.startedAt && !newHistory.finishedAt) {
        setTimeout(() => {
          this.getImportContactProgress(id)
        }, 60 * 1000)
      }
    } catch (e) {
      console.error(e)
    }
  }

  resetHistories = () => {
    this._historiesMap.clear()
  }

  loadImportHistory = async () => {
    try {
      this.setIsLoading(true)
      const { data } = await ContactsApi.loadImportHistory(this._paginationData)

      this._total = data.meta.total

      this.resetHistories()

      data.data.forEach((history) => {
        this.addHistory(new History(history))
      })
    } finally {
      this.setIsLoading(false)
    }
  }

  deleteHistoryLocal = (id: number) => {
    this._historiesMap.delete(id)
  }

  deleteHistory = (history: History) => {
    this._deletedHistoriesMap.set(history.id, history)

    const timeoutId = setTimeout(async () => {
      await ContactsApi.deleteImportHistory(history.id)
      this._deletedHistoriesMap.delete(history.id)
      this._total = this._total - 1
    }, 5000)

    this.deleteHistoryLocal(history.id)

    const toastId = nanoid()

    toastStore.add({
      id: toastId,
      title: 'Import record deleted',
      type: 'info',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(timeoutId)
          const deletedHistory = this._deletedHistoriesMap.get(history.id)
          if (deletedHistory) {
            this.addHistory(deletedHistory)

            const sortedHistories = this.historyData.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })

            this._historiesMap = new Map(sortedHistories.map((history) => [history.id, history]))
            this._deletedHistoriesMap.delete(history.id)
            toastStore.remove(toastId)
          }
        },
      },
    })
  }

  handleClose = () => {
    modalStore.removeModal(this._modalId)
  }

  openModal = (props: IIModalProps) => {
    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      ...props,
    })
  }

  openDuplicateModal = async (id: number) => {
    this._modalId = 'duplicate_modal'

    this.openModal({
      title: 'Duplicate numbers',
      width: 520,
      ModalContent: () => <HistoryModalContent data={this.getDuplicateReasons(id)} />,
    })
  }

  openSkippedModal = async (id: number) => {
    this._modalId = 'skipped_modal'

    this.openModal({
      title: 'Skipped numbers',
      width: 520,
      ModalContent: () => <HistoryModalContent data={this.getSkippedReasons(id)} />,
    })
  }

  changePagination = (page: number, limit: number) => {
    this._paginationData = {
      page,
      limit,
    }
  }

  reactionChangePagination = () => {
    this._disposeChangePagination?.()
    this._disposeChangePagination = reaction(
      () => this._paginationData,
      () => {
        this.loadImportHistory()
      }
    )
  }
}

export const contactsHistoryStore = new ContactsHistoryStore()
