import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'

export enum CreateContactErrors {
  trialLimit = 'To start a new conversation with this contact please upgrade your account here',
}

export class TrialContactsLimitModal {
  private _modalId = 'trialContactsLimit_modal'

  constructor() {
    makeAutoObservable(this)
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get loading() {
    return this.trialSubscription.loading
  }

  init = async () => {
    if (!subscriptionStore.isTrial) return

    await this.trialSubscription.init()
  }

  get title() {
    return 'You have reached the limit of 250 contacts on Trial plan'
  }

  get desc() {
    if (this.isAdminOrOwnerRole) {
      if (this.isCompleted) {
        return 'Upgrade to remove the limits'
      }

      return 'Get approved and upgrade to remove the limits'
    }

    if (this.isCompleted) {
      return 'To remove the limits, please ask one of your admins to upgrade.'
    }

    return 'To remove the limits, please ask one of your admins to get approved and upgrade.'
  }

  get action() {
    if (this.isCompleted) {
      return {
        text: 'Upgrade plan',
        onAction: () => {
          uiStore.changeRouteViaVue({
            path: '/settings/billing/overview/upgrade',
          })
          modalStore.closeModal(this._modalId)
        },
      }
    }

    return {
      text: 'Get approved',
      onAction: () => {
        uiStore.changeRouteViaVue({
          path: '/settings/organization/compliance',
        })
        modalStore.closeModal(this._modalId)
      },
    }
  }

  open = async (error: CreateContactErrors) => {
    await this.init()

    if (!subscriptionStore.isTrial) return
    if (error !== CreateContactErrors.trialLimit) return

    if (this.isAdminOrOwnerRole) {
      modalStore.addModal({
        id: this._modalId,
        type: ModalTypeList.INFO,
        title: this.title,
        desc: this.desc,
        primaryAction: this.action,
        secondaryAction: {
          text: 'Cancel',
          onAction: () => modalStore.closeModal(this._modalId),
        },
      })
    } else {
      modalStore.addModal({
        id: this._modalId,
        type: ModalTypeList.INFO,
        title: this.title,
        desc: this.desc,
        infoModalTextAction: 'Done',
      })
    }
  }

  get isCompleted() {
    return this.trialSubscription.isStatusCompleted
  }

  get isAdminOrOwnerRole() {
    return usersStore.user?.isAdminOrOwnerRole
  }
}
