import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Status } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { IParamsContacts } from 'entities/Contacts/api/types'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { numbersStore } from 'entities/Phone'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'
import { PowerDialerStore } from '../../store/powerDialerStore'

type IPowerDialerActionButtonProps = {
  total: number
  contactsParams: IParamsContacts
  selectedIds: number[]
  bulkAll: boolean
}

/**
 * @deprecated
 * use import { PowerDialerCampaignEditorActionButton } from 'widgets/PowerDialerCampaignEditor'
 */
export const PowerDialerActionButton: FC<IPowerDialerActionButtonProps> = observer(
  ({ total, contactsParams, selectedIds, bulkAll }) => {
    const [store] = useState(() => new PowerDialerStore())
    const inbox = inboxesStore.currentInbox
    const sharedInboxes = inboxesStore.sharedInboxes
    const sharedInbox = inbox?.type === 'inbox' ? inbox : null

    const countInActiveOutboundCallsInboxes = sharedInboxes.filter(
      (item) => !item.isNumberOutboundCalls
    )
    const countAircallInboxes = sharedInboxes.filter(
      (item) => item.isCallViaAircall || numbersStore.getItem(item.numberId)?.is_aircall
    )
    const hasAllAircall = countAircallInboxes.length === sharedInboxes.length
    const hasAllInActiveOutboundCalls =
      countInActiveOutboundCallsInboxes.length === sharedInboxes.length
    const hasCurrentAircallInbox =
      sharedInbox?.isCallViaAircall || !!numbersStore.getItem(sharedInbox?.numberId)?.is_aircall
    const hasCurrentInActiveOutboundCalls = !sharedInbox?.isNumberOutboundCalls

    const hideButton = Boolean(
      usersStore.user?.isViewOnlyRole || hasCurrentAircallInbox || hasAllAircall
    )

    if (hideButton) return null

    const isCallOutboundDisabled = sharedInbox
      ? hasCurrentInActiveOutboundCalls
      : hasAllInActiveOutboundCalls
    const disabled =
      isCallOutboundDisabled ||
      store.loading ||
      callPopUpGlobalStore.hasPowerDialer ||
      !featureFlagsStore.inteliquent_calling

    const getTooltipLabel = () => {
      if (isCallOutboundDisabled) {
        if (
          usersStore.user?.isAdminRole ||
          usersStore.user?.isOwnerRole ||
          (inbox?.type === 'inbox' && inbox?.ownerId === usersStore.user?.id)
        ) {
          return (
            <>
              Outbound calling is disabled
              <br />
              <button
                onClick={(event) => {
                  event.preventDefault()

                  uiStore.changeRoute({
                    path: `/settings/organization/inboxes/all-inboxes/${inbox?.id}/calling`,
                    type: 'vue',
                  })
                }}
              >
                Enable it here
              </button>
            </>
          )
        }

        return 'Outbound calling is disabled. Contact your admin to enable it.'
      }

      return ''
    }

    return (
      <Button
        text='Power Dialer'
        icon='phoneRightArrows'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        textRight={<Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />}
        onClick={() => {
          store.handleOpenModalContact(total, contactsParams, selectedIds, bulkAll)
        }}
        disabled={disabled}
        tooltipProps={{
          placement: 'bottom-start',
          label: getTooltipLabel(),
        }}
      />
    )
  }
)
