import { useEffect } from 'react'
import {
  EventIntegrationConnected,
  IntegrationKey,
  type IResponseUserIntegration,
} from 'entities/Integrations'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import type { IntegrationListStore } from 'pages/settings/pages/integrations/store/integrationListStore'
import type { IntegrationDetailsStore } from 'pages/settings/pages/integrations/store/integrationDetailsStore'

export const useEventIntegrationConnectedSettingsList = (
  integrationListStore: IntegrationListStore
) => {
  const handler = (userIntegrationResponse: IResponseUserIntegration) => {
    integrationListStore.syncUserIntegration(userIntegrationResponse)
  }

  useEffect(() => {
    EventIntegrationConnected.subscribe(handler)

    return () => {
      EventIntegrationConnected.unsubscribe(handler)
    }
  }, [])
}

export const useEventIntegrationConnectedSettingsDetails = (
  integrationDetailsStore: IntegrationDetailsStore
) => {
  const handler = (userIntegrationResponse: IResponseUserIntegration) => {
    if (!integrationDetailsStore.integration) return
    integrationDetailsStore.integration.syncUserIntegration(userIntegrationResponse)
    allMergeFieldsStore.updateIntegrationFields(integrationDetailsStore.integration.key)

    if (
      integrationDetailsStore.integration.isConnectedSettingsAvailable &&
      integrationDetailsStore.integration.key !== IntegrationKey.salesforce
    ) {
      integrationDetailsStore.handleEventIntegrationConnect()
    }
  }

  useEffect(() => {
    EventIntegrationConnected.subscribe(handler)

    return () => {
      EventIntegrationConnected.unsubscribe(handler)
    }
  }, [])
}
