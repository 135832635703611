import { makeAutoObservable } from 'mobx'
import { type IAlertProps } from 'shared/ui/Alert/types'
import { Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { type Conversation } from 'entities/Conversation'
import styles from '../ui/styles.module.scss'

export class TrialQuestionnaireAlertStore {
  constructor(private _conversation: Conversation) {
    makeAutoObservable(this)
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get stateTollFree() {
    return this.trialSubscription.stateTollFree
  }

  get stateBusinessProfile10DLC() {
    return this.trialSubscription.stateBusinessProfile10DLC
  }

  get state() {
    return this.trialSubscription.state
  }

  get conversationNumberType() {
    return this.trialSubscription.conversationNumberType
  }

  get configToolFree(): IAlertProps | null {
    if (this.stateTollFree === 'step_1_upgrade') return null

    if (this.stateTollFree === 'step_2_reject') {
      return {
        item: {
          type: 'error',
          title: 'Your application was rejected.',
          desc: this.trialSubscription.isAdminOrOwnerRole
            ? 'Please contact us for more information.'
            : 'Ask one of your admins to contact us for more information.',
        },
        rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
          <Button
            className={styles.btn}
            text='See details'
            typeBtn='outlined'
            size='small'
            contained='secondary'
            onClick={() => {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance/toll-free',
              })
            }}
          />
        ) : undefined,
      }
    }

    if (this.stateTollFree === 'step_1_reject') {
      return {
        item: {
          type: 'error',
          title: 'Your application was rejected.',
          desc: this.trialSubscription.isAdminOrOwnerRole
            ? 'Please update and resubmit to send texts.'
            : 'Ask one of your admins to update and resubmit to send texts.',
        },
        rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
          <Button
            className={styles.btn}
            text='See details'
            typeBtn='outlined'
            size='small'
            contained='secondary'
            onClick={() => {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance/toll-free',
              })
            }}
          />
        ) : undefined,
      }
    }

    if (this.stateTollFree === 'step_2') {
      return {
        item: {
          type: 'infoBlue',
          title: 'Your business application is under review.',
          desc: 'Once approved, you’ll be able to start texting. In the meantime, calling is ready for you.',
        },
        rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
          <Button
            className={styles.btn}
            text='Check status'
            typeBtn='outlined'
            size='small'
            contained='secondary'
            onClick={() => {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance/toll-free',
              })
            }}
          />
        ) : undefined,
      }
    }

    if (this.stateTollFree === 'step_1') {
      return {
        item: {
          type: 'infoBlue',
          title: this.trialSubscription.isAdminOrOwnerRole
            ? 'You must complete this process to start texting.'
            : 'One of your admins must complete this process to start texting.',
          desc: 'Once approved, you’ll be able to start texting. In the meantime, calling is ready for you.',
        },
        rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
          <Button
            className={styles.btn}
            text='Continue'
            typeBtn='outlined'
            size='small'
            contained='secondary'
            onClick={() => {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance/toll-free',
              })
            }}
          />
        ) : undefined,
      }
    }

    return {
      item: {
        type: 'infoBlue',
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Get approved to send texts.'
          : 'One of your admins must get approved to send texts.',
        desc: 'Fill out this quick form to get approved by the carriers to send texts.',
      },
      rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
        <Button
          className={styles.btn}
          text='Get approved'
          typeBtn='outlined'
          size='small'
          contained='secondary'
          onClick={() => {
            uiStore.changeRouteViaVue({
              path: '/settings/organization/compliance/toll-free',
            })
          }}
        />
      ) : undefined,
    }
  }

  get configBusinessProfileAnd10DLC(): IAlertProps | null {
    if (this.conversationNumberType === 'pft') {
      return this.configDefault
    }

    if (this.stateBusinessProfile10DLC === 'step_1_upgrade') return null

    if (this.stateBusinessProfile10DLC === 'step_1_reject') {
      return {
        item: {
          type: 'error',
          title: 'Your application was rejected.',
          desc: this.trialSubscription.isAdminOrOwnerRole
            ? 'Please update and resubmit to send texts.'
            : 'Ask one of your admins to update and resubmit to send texts.',
        },
        rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
          <Button
            className={styles.btn}
            text='Update'
            typeBtn='outlined'
            size='small'
            contained='secondary'
            onClick={() => {
              if (this.trialSubscription.businessProfile?.isStatusFailed) {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/business-profile',
                })
              } else if (this.trialSubscription.compliance10DLCServicesList?.isStatusFailed) {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/local-numbers',
                })
              } else {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance',
                })
              }
            }}
          />
        ) : undefined,
      }
    }

    if (
      this.stateBusinessProfile10DLC === 'step_3' ||
      this.stateBusinessProfile10DLC === 'step_4'
    ) {
      return {
        item: {
          type: 'infoBlue',
          title: 'Your business application is under review.',
          desc: 'Once approved, you’ll be able to start texting. In the meantime, calling is ready for you.',
        },
        rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
          <Button
            className={styles.btn}
            text='Check status'
            typeBtn='outlined'
            size='small'
            contained='secondary'
            onClick={() => {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance',
              })
            }}
          />
        ) : undefined,
      }
    }

    if (
      this.stateBusinessProfile10DLC === 'step_1' ||
      this.stateBusinessProfile10DLC === 'step_2'
    ) {
      return {
        item: {
          type: 'infoBlue',
          title: this.trialSubscription.isAdminOrOwnerRole
            ? 'You must complete this process to start texting.'
            : 'One of your admins must complete this process to start texting.',
          desc: 'Once approved, you’ll be able to start texting. In the meantime, calling is ready for you.',
        },
        rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
          <Button
            className={styles.btn}
            text='Continue'
            typeBtn='outlined'
            size='small'
            contained='secondary'
            onClick={() => {
              if (this.stateBusinessProfile10DLC === 'step_1') {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/business-profile/company-location',
                })
              } else {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/local-numbers/campaign-use-cases',
                })
              }
            }}
          />
        ) : undefined,
      }
    }

    return {
      item: {
        type: 'infoBlue',
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Get approved to send texts.'
          : 'One of your admins must get approved to send texts.',
        desc: 'Fill out this quick form to get approved by the carriers to send texts.',
      },
      rightAction: this.trialSubscription.isAdminOrOwnerRole ? (
        <Button
          className={styles.btn}
          text='Get approved'
          typeBtn='outlined'
          size='small'
          contained='secondary'
          onClick={() => {
            uiStore.changeRouteViaVue({
              path: '/settings/organization/compliance/business-profile/company-location',
            })
          }}
        />
      ) : undefined,
    }
  }

  get configDefault(): IAlertProps {
    return {
      item: {
        type: 'infoBlue',
        title: 'This number is just for testing during your free trial.',
        desc: `You’ve got ${organizationStore.trialEndsAtString} until your free trial ends.`,
      },
    }
  }

  get config(): IAlertProps | null {
    if (this.state === 'number_local_owner' || this.state === 'number_local_member') {
      return this.configBusinessProfileAnd10DLC
    }

    if (this.state === 'number_tollfree_owner' || this.state === 'number_tollfree_member') {
      if (this.conversationNumberType === 'toll_free') return this.configToolFree

      return null
    }

    if (this.conversationNumberType === 'toll_free') return null
    if (this.conversationNumberType === 'pft') return this.configDefault

    return null
  }

  get hide() {
    if (this.trialSubscription.loading) return true

    return this.trialSubscription.hide
  }
}
