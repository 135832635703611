import { makeAutoObservable, toJS } from 'mobx'
import type { IParamsQuickLinkTemplate } from 'entities/QuickLink/api'

export type IQuickLinkPayload = QuickLinkPayload

export class QuickLinkPayload {
  static Create(label: string, url: string) {
    const payload = new QuickLinkPayload()
    payload._label = label
    payload._url = url

    return payload
  }

  private _label = ''
  private _url = ''

  constructor() {
    makeAutoObservable(this)
  }

  get label() {
    return this._label
  }

  get url() {
    return this._url
  }

  get isLabelEmpty() {
    return !this._label.trim().length
  }

  get isUrlEmpty() {
    return !this._url.trim().length
  }

  get isEmpty() {
    return this.isLabelEmpty || this.isUrlEmpty
  }

  setLabel(label: string) {
    this._label = label
  }

  setUrl(url: string) {
    this._url = url
  }

  toJSON(): IParamsQuickLinkTemplate {
    return toJS({
      label: this._label,
      url: this._url,
    })
  }

  copy() {
    const quickLink = new QuickLinkPayload()
    quickLink._label = this._label
    quickLink._url = this._url

    return quickLink
  }
}
