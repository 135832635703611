import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { SpinnerLoader } from 'shared/ui'
import {
  ComplianceLayerProvider,
  useComplianceLayerContext,
} from 'pages/settings/pages/compliance/context'

const ComplianceLayoutContent = observer(() => {
  const store = useComplianceLayerContext()

  useEffect(() => {
    store.init()
  }, [])

  if (store.loading) {
    return <SpinnerLoader />
  }

  return (
    <>
      <Outlet />
    </>
  )
})

export const ComplianceLayout = () => {
  return (
    <ComplianceLayerProvider>
      <ComplianceLayoutContent />
    </ComplianceLayerProvider>
  )
}
