import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { authStore } from 'entities/Auth'
import { usersStore } from 'entities/Users'

import { ModalTenDLCContent } from './Modals'

const DELAY_DURATION = 8 * 60 * 60 * 1000

export class ReminderTenDLCStore {
  private _modalId = ''
  private _timeoutId: ReturnType<typeof setTimeout> | null = null
  private _disposeNewSession: IReactionDisposer | null = null
  private _disposeUserId: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get isAvailable() {
    return false
    // TODO: Wait until decision to show reminders over V1
    /*
    return Boolean(
      configStore.banner10DLC?.should_show_banner &&
        organizationSettingsStore.settings10DLC?.settings?.active
    )
    */
  }

  get isCommonRole() {
    return Boolean(
      usersStore.user?.isMemberRole ||
        usersStore.user?.isManagerRole ||
        usersStore.user?.isViewOnlyRole
    )
  }

  init = () => {
    this.reactionNewSession()
    this.reactionUserId()
  }

  dispose = () => {
    this._disposeNewSession?.()
    this._disposeUserId?.()
  }

  reactionNewSession = () => {
    this._disposeNewSession?.()
    this._disposeNewSession = reaction(
      () => [authStore.isNewSession, usersStore.loading],
      ([isNewSession, isUserLoading]) => {
        if (!isUserLoading) this.handleShow(isNewSession)
      }
    )
  }

  reactionUserId = () => {
    this._disposeUserId?.()
    this._disposeUserId = reaction(
      () => usersStore.user?.id,
      (userId) => {
        if (!userId) return

        // Turn off banner for V1
        const flagName = `${userId}_isShowTenDLCBanner`
        window.localStorage.setItem(flagName, 'false')
      }
    )
  }

  showModal = () => {
    this._modalId = nanoid()

    modalStore.addModal({
      id: this._modalId,
      disabledOverlayClose: true,
      disabledOnAllClose: true,
      title: '10DLC Compliance Registration',
      ModalContent: ModalTenDLCContent,
      width: 600,
      primaryAction: !this.isCommonRole
        ? {
            text: 'Register for 10DLC',
            onAction: () => {
              this.handleUpdate()
              uiStore.changeRoute({
                path: '/settings/organization/compliance',
                type: 'vue',
              })
            },
          }
        : undefined,
      closeText: this.isCommonRole ? 'Close' : 'Cancel',
      onClose: () => this.handleUpdate(),
    })
  }

  handleShow = (instantShow?: boolean) => {
    if (modalStore.hasModal(this._modalId)) return
    if (!usersStore.user || !this.isAvailable) return

    if (this._timeoutId) clearTimeout(this._timeoutId)

    if (instantShow) return this.showModal()

    const dateName = `${usersStore.user.id}_tenDLCBannerLastDate`
    const lastSeen = window.localStorage.getItem(dateName)
    if (!lastSeen) return this.showModal()

    const lastSeenDate = new Date(lastSeen).valueOf()
    const nowDate = Date.now()
    const lastSeenDuration = nowDate - lastSeenDate

    if (lastSeenDuration > DELAY_DURATION) this.showModal()
    else this._timeoutId = setTimeout(this.showModal, DELAY_DURATION - lastSeenDuration)
  }

  handleUpdate = () => {
    modalStore.removeModal(this._modalId)

    if (!usersStore.user) return

    const dateName = `${usersStore.user.id}_tenDLCBannerLastDate`
    window.localStorage.setItem(dateName, new Date().toISOString())
  }
}
