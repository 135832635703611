import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import {
  Button,
  DropdownContent,
  DropdownGroup,
  DropdownTextInput,
  type IDropdownItem,
  Scrollbar,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { SelectCard } from './SelectCard'
import type { IMultiselectedItem, INumbersGroup } from './type'
import styles from './styles.module.scss'

type IDropdownNumbersContentProps = {
  isLoading: boolean
  multiSelectedValues: IMultiselectedItem[]
  search: string
  setSearch: (value: string) => void
  numbersGroups: INumbersGroup[]
  setMultipleItem: (item: IDropdownItem) => void
  handleMultiSelect: () => void
  isDeselectState: boolean
  handleMultiDeselect: () => void
  removeMultiSelectedItem: (item: IMultiselectedItem) => void
}

export const DropdownNumbersContent = observer(
  ({
    search,
    setSearch,
    numbersGroups,
    isLoading,
    setMultipleItem,
    handleMultiSelect,
    isDeselectState,
    multiSelectedValues,
    handleMultiDeselect,
    removeMultiSelectedItem,
  }: IDropdownNumbersContentProps) => {
    const [height, setHeight] = useState(0)

    useEffect(() => {
      return () => {
        setSearch('')
      }
    }, [])

    if (isLoading) {
      return (
        <DropdownContent>
          <div className={styles.dropdownLoader}>
            <SpinnerLoader size={20} />
          </div>
        </DropdownContent>
      )
    }

    return (
      <div className={styles.wrap}>
        <DropdownContent noPadding>
          {!!multiSelectedValues.length && (
            <SelectCard
              selectedItems={multiSelectedValues}
              onDelete={removeMultiSelectedItem}
              onChangeHeight={setHeight}
            />
          )}
          <DropdownTextInput
            textFieldProps={{
              value: search,
              onChange: setSearch,
              InputProps: {
                placeholder: 'Search',
              },
            }}
            ariaLabel='searchNumbers'
          />

          <Scrollbar autoHeight autoHeightMin={34} autoHeightMax={520 - height}>
            {(!!numbersGroups.length || isDeselectState) && (
              <>
                <div
                  className={classNames(styles.dropdownTitleWrapper, {
                    [styles.dropdownDeselectAllWrapper]: isDeselectState,
                  })}
                >
                  {!isDeselectState && (
                    <div className={styles.dropdownTitle}>
                      <Typography
                        variant={'body-sm-medium'}
                        color={'var(--content-primary-tertiary)'}
                        ariaLabel={'DropdownGroupTitle'}
                      >
                        Select numbers
                      </Typography>
                    </div>
                  )}
                  <Button
                    text={isDeselectState ? 'Deselect all' : 'Select all'}
                    typeBtn='text'
                    contained='secondary'
                    size='small'
                    intent={'positive'}
                    textClassname={styles.selectAllButton}
                    onClick={() => (isDeselectState ? handleMultiDeselect() : handleMultiSelect())}
                  />
                </div>
              </>
            )}
            {numbersGroups.map((group) => {
              return (
                <DropdownGroup
                  key={group.key}
                  items={group.items}
                  onChange={(item) => setMultipleItem(item)}
                  isScroll={false}
                />
              )
            })}
            {!numbersGroups.length && !isDeselectState && (
              <div className={styles.noResult}>No results found</div>
            )}
          </Scrollbar>
        </DropdownContent>
      </div>
    )
  }
)
