import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'

import {
  IParamsQuickLinkListTemplate,
  IParamsQuickLinksList,
  IParamsQuickLinkTemplate,
  IResponseQuickLinkListTemplate,
  IResponseQuickLinksList,
  IResponseQuickLinkTemplate,
} from './types'

class Api {
  getLinkTemplates(
    params: IParamsQuickLinkListTemplate,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseQuickLinkListTemplate>> {
    return baseApi.get('contacts/quick-links', { ...options, params })
  }

  createLinkTemplate(
    params: IParamsQuickLinkTemplate
  ): Promise<AxiosResponse<IResponseQuickLinkTemplate>> {
    return baseApi.post('contacts/quick-links', params)
  }

  updateLinkTemplate(
    id: number,
    params: IParamsQuickLinkTemplate
  ): Promise<AxiosResponse<IResponseQuickLinkTemplate>> {
    return baseApi.put(`contacts/quick-links/${id}`, params)
  }

  deleteLinkTemplate(id: number): Promise<AxiosResponse<void>> {
    return baseApi.delete(`contacts/quick-links/${id}`)
  }

  getContactLinks(
    contactId: number,
    params: IParamsQuickLinksList,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseQuickLinksList>> {
    return baseApi.get(`contacts/quick-links/${contactId}`, { ...options, params })
  }
}

export const QuickLinkApi = new Api()
