import { makeAutoObservable, runInAction } from 'mobx'
import { type IParamsFiltersListGroups } from 'entities/Broadcast/api/types'
import { contactsStore } from 'entities/Contacts'
import { Segment } from 'entities/Segment/model/Segment'
import { TagsApi } from 'entities/Tags'
import { Tag } from 'entities/Tags/model/Tag'
import { segmentsStore } from 'entities/Segment'
import { ContactFiltersApi } from 'entities/Contacts/api/contactsFilters'
import { type IFullResponseCampaign } from 'entities/Campaign'
import { EnrollmentFilters, EnrollmentStore } from 'widgets/ContactEnrollment'

export class CampaignEnrollContactsStore {
  private _enrollmentFilters = EnrollmentFilters.Empty()
  private _enrollmentStore = new EnrollmentStore({
    filterConfig: {
      makeRequest: () => {
        return ContactFiltersApi.getFilters({
          workflowSource: 'campaign',
          skipValues: ['tag'],
        }).then(({ data }) => {
          return {
            filters: data.filters.filter((item) => item.key !== 'inbox'),
            operators: data.operators,
          }
        })
      },
    },
  })
  private _loading = false
  private _number_id: number | null = null

  constructor() {
    makeAutoObservable(this)
    this.initFilters()
  }

  get enrollmentFilters() {
    return this._enrollmentFilters
  }

  error = ''
  setError = (value: typeof this.error) => {
    this.error = value
  }

  get loading() {
    return this._loading
  }

  get isEmpty() {
    return !this._enrollmentFilters.allSize
  }

  get paramsFilters(): IParamsFiltersListGroups['filtersListGroups'] {
    return {
      contacts: this._enrollmentFilters.contacts.map((contact) => contact.id) ?? [],
      segments: this._enrollmentFilters.segments?.map((segment) => segment.id) ?? [],
      tags: this._enrollmentFilters.tags?.map((tag) => tag.id),
      advanced: this._enrollmentFilters.filters?.map((filter) => filter.filtersParams),
    }
  }

  initFilters = () => {
    this._enrollmentStore.filterEditorStore.initFilters()
  }

  init = async (filtersListGroups: IFullResponseCampaign['filters']) => {
    if (!filtersListGroups) return
    try {
      this._loading = true
      const tagsIds = filtersListGroups.tags || []
      const contactsIds = filtersListGroups.contacts || []
      const segmentsIds = filtersListGroups.segments || []

      const [tags, contacts] = await Promise.all([
        this.fetchTags(tagsIds),
        contactsStore.getByIds(contactsIds),
      ])
      const segments = segmentsIds.reduce<Segment[]>((state, id) => {
        const item = segmentsStore.getItem(id)

        if (item) {
          state.push(item)
        }

        return state
      }, [])
      const advanced = filtersListGroups.advanced?.map(
        (item) =>
          new Segment({
            id: 0,
            organization_id: 0,
            name: '',
            filters: item,
            is_favorite: false,
          })
      )

      this._enrollmentFilters.sync({
        tags: tags,
        segments: segments,
        contacts: contacts,
        filters: advanced,
        number_id: this._number_id,
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  fetchTags = async (ids: number[]) => {
    const tags = await TagsApi.getTagsById(ids)

    return tags.map((tag) => new Tag(tag))
  }

  editEnrollment = async () => {
    const filters = this._enrollmentFilters.clone()

    const cancelFilters = () => Promise.resolve(true)

    const confirmFilters = async (newFilters: EnrollmentFilters) => {
      runInAction(() => {
        this._enrollmentFilters = newFilters
      })
      return true
    }

    this._enrollmentStore.openModal({
      filters: filters,
      action: {
        cancel: cancelFilters,
        confirm: confirmFilters,
      },
      allowEmptyCount: true,
      title: 'Contacts',
    })
  }
}
