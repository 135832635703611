import { IIconsVector } from 'shared/ui'

export enum CrmEnum {
  hubSpot = 'hubSpot',
  activeCampaign = 'activeCampaign',
  keap = 'keap',
  salesforce = 'salesforce',
  pipedrive = 'pipedrive',
  none = 'none',
  other = 'other',
}

export type ICrm = {
  id: CrmEnum
  name: string
  icon: IIconsVector
}

export enum FieldsEnum {
  crm = 'crm',
}
