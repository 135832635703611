import { makeAutoObservable } from 'mobx'
import { debounce } from 'lodash'
import { showToast } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { GoogleAuthTypesEnum, IResponseGoogleRegister, IResponseGoogleLogin } from './types'
import { AuthRoutesEnum } from '../routes/authRoutesEnum'
export class GoogleAuthStore {
  private _debounceError: ReturnType<typeof debounce>
  private _authTimer: NodeJS.Timer | null = null
  private _loading = false
  private _authType: GoogleAuthTypesEnum | null = null
  private _registerCallback:
    | ((data: IResponseGoogleRegister | null, error: string | null) => void)
    | null = null

  constructor(loginCallback: (payload: IResponseGoogleLogin) => void) {
    makeAutoObservable(this)
    this.initProcessRegister()
    this.initProcessLogin(loginCallback)
    this._debounceError = debounce(this.handleError, 2000)
  }

  initProcessRegister = () => {
    window.processIncompleteRegister = async (payload: IResponseGoogleRegister) => {
      if (window.location.href.includes('/login')) {
        uiStore.changeRoute({
          path: `/${AuthRoutesEnum.register}`,
          type: 'default',
          method: 'push',
          options: {
            state: {
              authData: payload,
            },
          },
        })
      }
      this._loading = false
      this._debounceError.cancel()
      if (this._registerCallback) this._registerCallback(payload, null)

      this.reset()
    }
  }

  initProcessLogin = (loginCallback: (payload: IResponseGoogleLogin) => void) => {
    window.processSocialLogin = async (payload: IResponseGoogleLogin) => {
      loginCallback(payload)
      this.reset()
    }
  }

  get loading() {
    return this._loading
  }

  onRegister = async (
    callback?: (data: IResponseGoogleRegister | null, error: string | null) => void
  ) => {
    if (callback) this._registerCallback = callback

    await this.onAuth(GoogleAuthTypesEnum.register)
  }

  onLogin = async () => {
    await this.onAuth(GoogleAuthTypesEnum.login)
  }

  handleError = () =>
    showToast({
      type: 'warning',
      title: 'Something went wrong. Please try again...',
    })

  onAuth = async (authType: GoogleAuthTypesEnum, isMergeLogin?: boolean) => {
    this._loading = true
    this._authType = authType

    const authWindow = window.open(
      `/app/auth/google/${isMergeLogin ? 'merge-and-login' : 'login'}?sign_up_v2=true`,
      '_blank',
      'menubar=yes,location=no,toolbar=yes,scrollbars=yes,resizable=yes,directories=no,status=yes'
    )

    this._authTimer = setInterval(() => {
      if ((authWindow?.closed && this.loading) || (authWindow === null && this.loading)) {
        this.reset()
        this._debounceError()
      }
    }, 1000)
  }

  reset = () => {
    this._authType = null
    this._loading = false
    if (this._authTimer) {
      clearInterval(this._authTimer)
      this._authTimer = null
    }
    this._registerCallback = null
  }
}
