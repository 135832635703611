import { observer } from 'mobx-react-lite'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { ContactForm } from './ContactForm'
import styles from './styles.module.scss'

export const ContactInfo = observer(() => {
  const {
    fieldsError,
    deleteErrorValue,
    stepLoading,
    contactInfoStore: {
      contact,
      phoneInputStore,
      emailLocalError,
      isNextDisabled,
      nextStepClick,
      checkIsEmail,
      setFirstName,
      setLastName,
      setEmail,
      similarityDomainsWarning,
    },
  } = useTollFreeStepsContext()

  return (
    <div className={styles.wrap}>
      <PageDescription>
        We will contact your authorized representative to verify your identities. Please ensure that
        they are contactable via email and phone.
      </PageDescription>

      <ContactForm
        emailErrorMessage={(fieldsError && fieldsError['email']) || emailLocalError}
        phoneErrorMessage={fieldsError && fieldsError['phone_number']}
        isErrorBorderEmail={fieldsError ? Boolean(fieldsError['email']) : false}
        contact={contact}
        phoneInputStore={phoneInputStore}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        checkIsEmail={checkIsEmail}
        setValueCallback={() => {
          deleteErrorValue('phone_number')
        }}
        similarityDomainsWarning={similarityDomainsWarning}
      />
      <NextButton
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        text='Next'
        onClick={nextStepClick}
      />
    </div>
  )
})
