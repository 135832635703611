import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import { Avatar, IconButton, Typography } from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import styles from './styles.module.scss'

export type IDefaultContactCard = {
  item: Contact
  active?: boolean
  disabledInteractive?: boolean
  onChange?: (item: Contact) => void
  uiType?: 'bordered'
  size?: 'small' | 'medium'
  onRemove?: (item: Contact) => void
  highlighter?: string
}

export const DefaultContactCard: React.FC<IDefaultContactCard> = observer(
  ({
    item,
    onChange = () => {},
    active,
    disabledInteractive,
    uiType,
    size = 'medium',
    onRemove,
    highlighter,
  }) => {
    const avatarSize = useMemo(() => {
      if (size === 'small') {
        return 24
      }
      return 32
    }, [size])

    return (
      <div
        className={classnames(styles.wrap, {
          [styles.active]: active,
          [styles.disabledInteractive]: disabledInteractive,
          [styles[`ui_type--${uiType}`]]: uiType,
          [styles[`size--${size}`]]: size,
        })}
        onClick={() => {
          onChange(item)
        }}
      >
        <div className={styles.avatar}>
          <Avatar info={item.avatarInfo} size={avatarSize} />
        </div>

        <div className={styles.info}>
          <Typography
            ariaLabel={'contactName'}
            variant={size === 'small' ? 'body-md-regular' : 'body-md-medium'}
            ellipsis
          >
            {highlighter ? (
              <Highlighter
                highlightClassName={classnames(styles.highlighter, styles['highlighter--label'])}
                className={styles.name}
                searchWords={highlighter.split(' ')}
                autoEscape={true}
                textToHighlight={item.name}
              />
            ) : (
              item.name
            )}
          </Typography>
          {item.formatted_number && item.name !== item.formatted_number && (
            <Typography
              ariaLabel={'contactNumber'}
              variant={size === 'small' ? 'body-xs-regular' : 'body-md-medium'}
              color={'tertiary'}
            >
              {highlighter ? (
                <Highlighter
                  highlightClassName={classnames(styles.highlighter, styles['highlighter--label'])}
                  searchWords={highlighter.split(' ')}
                  autoEscape={true}
                  textToHighlight={item.formatted_number || ''}
                />
              ) : (
                item.formatted_number
              )}
            </Typography>
          )}
        </div>
        {onRemove && (
          <IconButton
            ariaLabel={'remove'}
            icon={'close'}
            size={'small'}
            variant={'icon'}
            color={'tertiary'}
            onClick={() => {
              onRemove(item)
            }}
          />
        )}
      </div>
    )
  }
)
