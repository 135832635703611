import { observer } from 'mobx-react-lite'
import { Table } from 'shared/ui'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'
import { useDocumentsWithActionsRows } from './ui/useDocumentsWithActionsRows'
import { DocumentsBulkActions } from './ui/DocumentsBulkActions'
import { columns } from './ui/columns'
import styles from './styles.module.scss'

export const DocumentsTable = observer(() => {
  const {
    documentsStore: { page, limit, total, isDocumentsLoading, onPaginationChange, tableStore },
  } = useKnowledgeBaseViewContext()

  const rows = useDocumentsWithActionsRows()

  return (
    <div className={styles.table}>
      <Table
        columns={columns}
        rows={rows}
        initPage={page}
        limit={limit}
        store={tableStore}
        isLoading={isDocumentsLoading}
        pinnedColumnNames={['name']}
        rowsCount={total}
        onChangePagination={onPaginationChange}
        startPaginationSize={10}
        headerLeftContentProps={{
          actionsContent: <DocumentsBulkActions />,
        }}
        withCheckbox
      />
    </div>
  )
})
