import { ComponentProps } from 'react'
import classnames from 'classnames'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

type ISpeedProps = ComponentProps<'button'> & {
  text: string
  active?: boolean
}

export const Speed = ({ text, active = false, ...props }: ISpeedProps) => {
  return (
    <button
      className={classnames(styles.wrap, {
        [styles.active]: active,
      })}
      {...props}
    >
      <Typography variant={'body-xs-medium'} className={styles.text} ariaLabel={'text'}>
        {text}
      </Typography>
    </button>
  )
}
