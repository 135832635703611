import { EmptyState } from 'shared/ui'
import campaigns_preview from 'shared/assets/images/campaigns/campaigns_preview.png'
import { getCDNAssetURL } from 'shared/lib'
import { CampaignsHelpGuideButton, NewCampaign } from 'pages/campaigns/actions'

export const EmptyStateCampaigns = () => {
  return (
    <>
      <EmptyState
        variant={'preview'}
        previewImg={<img alt='Campaigns preview' src={getCDNAssetURL(campaigns_preview)} />}
        title={'Create your first campaign'}
        subtitle={'Enroll contacts in a sequence of messages'}
        actions={
          <>
            <NewCampaign />
            <CampaignsHelpGuideButton />
          </>
        }
      />
    </>
  )
}
