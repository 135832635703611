import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import {
  IIntegrationHubspotPropertyFormatted,
  IResponseIntegrationHubspotTicket,
} from 'entities/Integrations'

import styles from '../styles.module.scss'

type IItemTicketsRowProps = {
  ticket: IResponseIntegrationHubspotTicket
  fullList: boolean
}

const ItemTicketsRow = ({ ticket, fullList }: IItemTicketsRowProps) => {
  const hubspotRows: IIntegrationHubspotPropertyFormatted[] = []

  for (const [key, value] of Object.entries(ticket)) {
    hubspotRows.push({
      key: key,
      label: value?.label ?? '',
      value: value?.value?.toString() ?? '',
    })
  }

  const hubspotAvailableRows = hubspotRows.filter(
    (item) => item.key !== 'subject' && item.key !== 'id'
  )

  const list = fullList ? hubspotAvailableRows : hubspotAvailableRows.slice(0, 5)

  return (
    <div className={styles.companyWrapper}>
      {list.map((item) => (
        <div className={styles.itemRow} key={item.key}>
          <div className={styles.label}>{item.label}</div>
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value || '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { ItemTicketsRow }
