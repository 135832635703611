import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class MenuAIAutomationLayoutStore {
  private _pageLayoutStore = new PageLayoutStore()

  constructor() {
    makeAutoObservable(this)
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }
}
