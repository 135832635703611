import { observer } from 'mobx-react-lite'
import { Tooltip, Typography } from 'shared/ui'
import { BoxIcon } from 'shared/ui/Icon/BoxIcon'
import { KeywordInvalidAlert, KeywordStatus } from 'entities/Keywords/ui'
import { useKeywordDetailsModalContext } from 'pages/keywords/context'
import styles from './styles.module.scss'

export const DetailsKeywordHeader = observer(() => {
  const keyword = useKeywordDetailsModalContext()
  const { name, title, status } = keyword
  return (
    <>
      <div className={styles.titleWrap}>
        <Typography
          tag={'div'}
          ariaLabel={'keyword.name'}
          variant={'heading-lg'}
          tooltipProps={{
            label: name,
          }}
          ellipsis
          className={styles.keywordName}
        >
          {name}
        </Typography>
        <Typography
          tag={'div'}
          ariaLabel={'keyword.title'}
          variant={'body-md-regular'}
          color={'tertiary'}
          tooltipProps={{
            label: title,
          }}
          ellipsis
          className={styles.keywordTitle}
        >
          {title}
        </Typography>
      </div>
      <div className={styles.info}>
        <KeywordStatus status={status} />
        <Tooltip label={'SMS'} placement={'top'}>
          <BoxIcon icon={'messageSmile'} tertiary />
        </Tooltip>
      </div>
      <KeywordInvalidAlert keyword={keyword} className={styles.alert} isBottomActions />
    </>
  )
})
