import { AxiosResponse } from 'axios'
import type { AxiosOptions, IResponseStatus } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type {
  IResponseConversation,
  IResponseConversations,
  IResponseConversationsSearch,
  IResponseConversationsFilter,
  IResponseConversationsOrder,
  IResponseExportConversation,
  IParamsExportConversation,
  IParamsConversationCreate,
  IParamsConversationsList,
  IParamsConversationsTeamsNumber,
  IParamsConversationsSearch,
  IParamsConversationsBatchQueueClose,
  IParamsConversationsBatchQueueOpen,
  IParamsConversationsBatchQueueRead,
  IParamsConversationsBatchQueueUnread,
  IParamsConversationsBatchQueueFavorite,
  IParamsConversationsFindByContacts,
  IParamsConversationsBatchQueueReassign,
  IParamsConversationsMessagesMarkRead,
  IParamsConversationsBatchQueueNeedResponse,
  IParamsConversationsNeedResponseById,
  IConversationsBatchQueueNeedResponseResponse,
  IResponseConversationsTeamsNumber,
} from 'entities/Conversation/api/types'

class Api {
  getById(id: number, options?: AxiosOptions): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.get(`conversations/${id}`, { ...options })
  }

  getConversations(
    params: IParamsConversationsList,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseConversations>> {
    return baseApi.get('conversations', { params, ...options })
  }

  updateConversationsBatchQueueClose(
    params: IParamsConversationsBatchQueueClose
  ): Promise<AxiosResponse<string>> {
    return baseApi.put('conversations/batch-queue/close', params)
  }

  updateConversationsBatchQueueOpen(
    params: IParamsConversationsBatchQueueOpen
  ): Promise<AxiosResponse<string>> {
    return baseApi.put('conversations/batch-queue/open', params)
  }

  updateConversationsBatchQueueRead(
    params: IParamsConversationsBatchQueueRead
  ): Promise<AxiosResponse<string>> {
    return baseApi.put('conversations/batch-queue/read', params)
  }

  updateConversationsBatchQueueUnread(
    params: IParamsConversationsBatchQueueUnread
  ): Promise<AxiosResponse<string>> {
    return baseApi.put('conversations/batch-queue/unread', params)
  }

  updateConversationsBatchQueueFavorite(
    params: IParamsConversationsBatchQueueFavorite
  ): Promise<AxiosResponse<string>> {
    return baseApi.put('conversations/batch-queue/favorite', params)
  }

  getConversationsSearch(
    params: IParamsConversationsSearch
  ): Promise<AxiosResponse<IResponseConversationsSearch>> {
    return baseApi.get('conversations/search', { params })
  }

  getFiltersList(): Promise<AxiosResponse<IResponseConversationsFilter[]>> {
    return baseApi.get('conversations/filters/list')
  }

  getOrdersList(): Promise<AxiosResponse<IResponseConversationsOrder[]>> {
    return baseApi.get('conversations/orders/list')
  }

  create(params: IParamsConversationCreate): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.post('conversations', params)
  }

  updateByIdReassign(
    id: number,
    user_id: number | null
  ): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.post(`conversations/${id}/reassign`, { user_id })
  }

  updateByIdReassignBulk(
    params: IParamsConversationsBatchQueueReassign
  ): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.put('conversations/batch-queue/reassign', params)
  }

  updateByIdOpen(id: number): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.put(`conversations/${id}/open`)
  }

  updateByIdClose(id: number): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.put(`conversations/${id}/close`)
  }

  updateByIdExport({
    id,
    format,
    includeNotes,
  }: IParamsExportConversation): Promise<AxiosResponse<IResponseExportConversation>> {
    return baseApi.get(`conversations/${id}/export`, {
      params: {
        format,
        includeNotes,
      },
    })
  }

  updateByIdRead(id: number): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.put(`conversations/${id}/read`)
  }

  updateByIdUnread(id: number): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.put(`conversations/${id}/unread`)
  }

  updatePriority(conversation_id: number, priority: boolean) {
    return baseApi.post('conversations/favorite', { conversation_id, favorite: priority })
  }

  getTeamsNumber(
    params: IParamsConversationsTeamsNumber
  ): Promise<AxiosResponse<IResponseConversationsTeamsNumber>> {
    return baseApi.post('conversations/teams/number', params)
  }

  getConversationsFindByContacts(
    params: IParamsConversationsFindByContacts
  ): Promise<AxiosResponse<IResponseConversation | undefined>> {
    return baseApi.get('conversations/find-by-contacts', {
      params: params,
    })
  }

  updateMessagesByIdRead(
    conversation_id: number,
    params: IParamsConversationsMessagesMarkRead
  ): Promise<AxiosResponse> {
    return baseApi.patch(`conversations/${conversation_id}/messages/mark-read`, params)
  }

  updateConversationsBatchQueueNeedResponse(
    params: IParamsConversationsBatchQueueNeedResponse
  ): Promise<AxiosResponse<IConversationsBatchQueueNeedResponseResponse>> {
    return baseApi.put('conversations/batch-queue/need_response', params)
  }

  updateConversationsNeedResponseById(
    id: number,
    params: IParamsConversationsNeedResponseById
  ): Promise<AxiosResponse<IResponseConversation>> {
    return baseApi.put(`conversations/${id}/need_response`, params)
  }

  claimConversationById(id: number): Promise<AxiosResponse<IResponseStatus>> {
    return baseApi.post(`conversations/${id}/claim`)
  }
}

export const ConversationsApi = new Api()
