import { FC } from 'react'
import { Box, Icon, Typography } from 'shared/ui'
import { type Inbox } from 'entities/Inbox/model/Inbox'
import styles from './styles.module.scss'

type IWidgetMultiUsersTriggerProps = {
  items: Inbox[]
}

export const WidgetMultiInboxesTrigger: FC<IWidgetMultiUsersTriggerProps> = ({ items }) => {
  if (!items.length) return <>select</>

  return (
    <Box display={'flex'} gap={1} alignItems={'center'} height={32}>
      <div className={styles.group}>
        {items.map((inbox) => {
          return <Icon key={inbox.id} icon={inbox.icon} tertiary />
        })}
      </div>
      <Typography ariaLabel={'Inbox'} variant={'body-md-regular'}>
        {items.length > 1 ? `${items.length} inboxes` : items[0].name}
      </Typography>
    </Box>
  )
}
