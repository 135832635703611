import { FC } from 'react'
import { Status } from 'shared/ui'
import { tollFreeStatusConfig } from 'entities/Compliance/ui/TollFreeStatus/config'
import { type ITollFreeStatus } from './type'

type ITollFreeStatusProps = {
  status: ITollFreeStatus
}

export const TollFreeStatus: FC<ITollFreeStatusProps> = ({ status }) => {
  if (!status) return null

  return <Status {...tollFreeStatusConfig[status]} />
}
