import { createContext, useContext } from 'react'
import { CampaignsListStore } from '../store/CampaignsListStore'

export const CampaignsListContext = createContext<CampaignsListStore | null>(null)

export const useCampaignsListContext = () => {
  const context = useContext(CampaignsListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignsListContext')

  return context
}
