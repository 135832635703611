import { observer } from 'mobx-react-lite'
import { IconButton, type IIconButtonProps } from 'shared/ui'

export type IFilterAddButtonProps = Pick<IIconButtonProps, 'ariaLabel'>

export const FilterAddButton = observer((props: IFilterAddButtonProps) => (
  <IconButton
    {...props}
    icon='add'
    color='secondary'
    variant='contained'
    size='medium'
    fontSize={16}
    tooltipProps={{
      label: 'Add filter',
      disableInteractive: true,
      placement: 'left',
    }}
  />
))

export type IFilterRemoveButtonProps = Pick<IIconButtonProps, 'ariaLabel' | 'onClick'>

export const FilterRemoveButton = observer((props: IFilterRemoveButtonProps) => (
  <IconButton
    {...props}
    icon='delete'
    variant='contained'
    color='secondary'
    size='medium'
    fontSize={16}
    tooltipProps={{
      label: 'Remove group',
      disableInteractive: true,
      placement: 'top',
    }}
  />
))
