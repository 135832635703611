// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sw7VRn77QNtXqONuS68f{overflow:auto;margin-top:9px}.D0jJIHRiEik_TKpXutw_{height:1px;background:var(--border-neutral-default);margin:15.5px 0}.J9QU_tPleyy7FWaqf3qi{display:block}.rFPNpRci5SPkOsa0dBV2{color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/localNumbers/ui/BrandModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,cAAA,CAEF,sBACE,UAAA,CACA,wCAAA,CACA,eAAA,CAGF,sBACE,aAAA,CAGF,sBACE,qCAAA","sourcesContent":[".wrap {\n  overflow: auto;\n  margin-top: 9px;\n}\n.divider {\n  height: 1px;\n  background: var(--border-neutral-default);\n  margin: 15.5px 0;\n}\n\n.learnMorelink {\n  display: block;\n}\n\n.trustScore {\n  color: var(--content-primary-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "sw7VRn77QNtXqONuS68f",
	"divider": "D0jJIHRiEik_TKpXutw_",
	"learnMorelink": "J9QU_tPleyy7FWaqf3qi",
	"trustScore": "rFPNpRci5SPkOsa0dBV2"
};
export default ___CSS_LOADER_EXPORT___;
