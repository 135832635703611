import React from 'react'
import { Box, Icon, IIconsVector, Typography, Tooltip } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'
import { ICallType, IOutcomeType } from 'entities/CallHistory/api/types'
import styles from 'widgets/CallHistoryTable/ui/styles.module.scss'

type ICellOutcomeProps = {
  type: ICallType | null
  label: IOutcomeType | null
  isForwarded: boolean
  isConferenceCall: boolean
}
const CellOutcome: React.FC<ICellOutcomeProps> = ({
  type,
  label,
  isForwarded,
  isConferenceCall,
}) => {
  const iconColor =
    label && ['missed', 'no_answer'].includes(label)
      ? 'var(--content-negative-primary)'
      : 'var(--content-primary-tertiary)'
  const getIcon = (): IIconsVector => {
    if (label === 'answered' && type === 'inbound') {
      return 'arrowLeftDown'
    }
    if (label === 'missed' && type === 'inbound') {
      return 'missed'
    }
    if (label === 'voicemail' && type === 'inbound') {
      return 'voicemail'
    }
    if (label === 'answered' && type === 'outbound') {
      return 'arrowRightUp'
    }
    if (label === 'no_answer' && type === 'outbound') {
      return 'noAnswer'
    }

    return 'noAnswer'
  }
  const getLabel = () => {
    if (isConferenceCall) {
      return 'Conference call'
    }
    if (label === 'answered') {
      return 'Answered'
    }

    if (label === 'missed') {
      return 'Missed'
    }

    if (label === 'voicemail') {
      return 'Voicemail'
    }

    if (label === 'no_answer') {
      return 'No answer'
    }

    return 'No answer'
  }

  return (
    <Box display={'flex'} alignItems={'center'} gap={'6px'}>
      {type ? (
        <>
          <Icon icon={getIcon()} color={iconColor} />
          <Typography variant={'body-md-regular'} ellipsis ariaLabel={'label'}>
            {getLabel()}
          </Typography>
          {isForwarded && (
            <Tooltip label='Forwarded' placement='top'>
              <Typography
                variant={'body-xs-medium'}
                ellipsis
                className={styles.label}
                ariaLabel={'Fwd'}
              >
                Fwd
              </Typography>
            </Tooltip>
          )}
        </>
      ) : (
        <EmptyCell />
      )}
    </Box>
  )
}

export { CellOutcome }
