// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J5gzBSmUtQsm5rVX3u2M{width:100%;padding:22px 16px;display:flex;flex-direction:column;align-items:center;gap:17px;border-radius:12px;border:1px solid var(--black-20);background:var(--background-primary-transparent);cursor:pointer}.J5gzBSmUtQsm5rVX3u2M:not(.aBPSoYIBYw32Luge8Brq):hover{background:var(--gray-10)}.aBPSoYIBYw32Luge8Brq{border:1px solid var(--border-brand-default);background:var(--light-blue-10)}.INfaNpncpmzgGdODyygD{display:flex;align-items:center;justify-content:center;height:41px;width:41px;border-radius:8px;border:1px solid var(--black-20);background:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/shared/ui/ChoiceTile/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA,CACA,gCAAA,CACA,gDAAA,CACA,cAAA,CAGF,uDACE,yBAAA,CAGF,sBACE,4CAAA,CACA,+BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,gCAAA,CACA,qDAAA","sourcesContent":[".wrap {\n  width: 100%;\n  padding: 22px 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 17px;\n  border-radius: 12px;\n  border: 1px solid var(--black-20);\n  background: var(--background-primary-transparent);\n  cursor: pointer;\n}\n\n.wrap:not(.checked):hover {\n  background: var(--gray-10);\n}\n\n.checked {\n  border: 1px solid var(--border-brand-default);\n  background: var(--light-blue-10);\n}\n\n.iconWrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 41px;\n  width: 41px;\n  border-radius: 8px;\n  border: 1px solid var(--black-20);\n  background: var(--background-primary-inverted-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "J5gzBSmUtQsm5rVX3u2M",
	"checked": "aBPSoYIBYw32Luge8Brq",
	"iconWrap": "INfaNpncpmzgGdODyygD"
};
export default ___CSS_LOADER_EXPORT___;
