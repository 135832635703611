import { createContext, useContext } from 'react'
import { type SidebarStore } from './../store/sidebarStore'

export const SidebarContext = createContext<SidebarStore | null>(null)

export const useSidebarContext = () => {
  const context = useContext(SidebarContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with SidebarContext')

  return context
}
