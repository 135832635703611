import { observer } from 'mobx-react-lite'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { TextareaWithLabel } from 'shared/ui/Textarea'
import { Box, Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'
import {
  useCaseDescMaxTextLength,
  useCaseDescMinTextLength,
} from 'pages/settings/pages/compliance/pages/localNumbers/pages/CampaignUseCase/store/UseCaseStore'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { LearnMoreLink } from 'pages/settings/pages/compliance/ui/LearnMoreLink/LearnMoreLink'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { RegistrationErrorAlert } from 'pages/settings/pages/compliance/pages/localNumbers/ui/RegistrationErrorAlert/RegistrationErrorAlert'
import styles from './styles.module.scss'

export const CampaignUseCase = observer(() => {
  const { stepLoading, useCaseStore } = useLocalNumbersStepsContext()
  const {
    useCaseAsItems,
    useCaseValue,
    useCaseDesc,
    isStep1Disabled,
    setUseCase,
    setUseCaseDesc,
    openUseCaseExampleModal,
    campaignUseCaseNextClick,
  } = useCaseStore

  return (
    <div className={styles.wrap}>
      <PageDescription>
        Select the campaign use case that best describes the type of messaging you plan to send. Add
        a description of your specific texting use case.
        <LearnMoreLink href={links.complianceLearnMoreLink}>Learn more</LearnMoreLink>
      </PageDescription>

      <RegistrationErrorAlert />

      <Box marginTop={5}>
        <SelectField
          labelText='Use case'
          items={useCaseAsItems}
          setValue={setUseCase}
          value={useCaseValue}
          btnProps={{
            size: 'extraLarge',
            className: styles.select,
          }}
        />
      </Box>

      {useCaseValue && (
        <TextareaWithLabel
          className={styles.useCaseDesc}
          value={useCaseDesc}
          limit={useCaseDescMaxTextLength}
          minLimit={useCaseDescMinTextLength}
          errorType='bottomText'
          name='description'
          placeholder='Add a description of how the selected use case applies to your business'
          onChange={setUseCaseDesc}
          labelProps={{
            marginBottom: 0,
            label: {
              text: 'Use case description',
              rightNode: (
                <Button
                  onClick={openUseCaseExampleModal}
                  text={'See example'}
                  size='small'
                  typeBtn='informative'
                />
              ),
            },
          }}
        />
      )}

      <NextButton
        loading={stepLoading}
        disabled={isStep1Disabled || stepLoading}
        text='Next'
        onClick={campaignUseCaseNextClick}
      />
    </div>
  )
})
