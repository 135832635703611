import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { ReactElement, ReactNode } from 'react'
import urlRegex from 'url-regex'
import { ActionItem, IButtonProps, IIconButtonProps } from 'shared/ui'
import { calculatorGSM, formatBytesToNumber } from 'shared/lib'
import { IParamsCreateMessage, IParamsUpdateMessage, MessageData } from 'entities/Message'
import {
  attachmentStore,
  type ILimitSizeError,
  type IResponseAttachmentUpload,
} from 'entities/Attachment'
import { subscriptionStore } from 'entities/Subscription'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { shortLinkStore } from 'entities/ShortLink'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { RequestAttachment } from 'entities/Attachment/model/RequestAttachment'
import { Message } from 'entities/Message/model/Message'
import { IResponseConversationDraftMessageData } from 'entities/Conversation/api/types'
import { inboxesStore } from 'entities/Inbox'
import { DropdownMentionsStore } from 'widgets/MessageField/ui/DropdownMentions'
import { htmlToText } from 'widgets/MessageField/lib/htmlToText'
import { textToHtml } from 'widgets/MessageField/lib/textToHtml'
import { IModalCloseProps } from 'widgets/Record'
import { RecordAudioStore } from 'widgets/RecordAudio'

import {
  EnumMessageFieldMode,
  EnumVariantMessageField,
  IInputAction,
  SHORT_URL_POSTFIX_DEFAULT,
} from 'widgets/MessageField/store/types'
import type {
  IMessageFieldEvents,
  IMessageFieldLimit,
  IMessageFieldStoreProps,
} from 'widgets/MessageField'
import { airCallTooltip } from 'widgets/constants'

export class MessageFieldStore {
  constructor(props?: IMessageFieldStoreProps) {
    this.showActionsItems = props?.showActionsItems || 2
    this.noBorder = props?.noBorder || false
    this.noCounter = props?.noCounter || false
    this.placeholder = props?.placeholder || this.placeholder
    this.minHeight = props?.minHeight || this.minHeight
    this.maxHeight = props?.maxHeight || this.maxHeight
    this.resetOnUnmount = props?.resetOnUnmount ?? true
    this.fromSavedReply = !!props?.fromSavedReply
    this.textLimit = Object.assign({}, this.textLimit, props?.textLimit)
    this.sendActionBtnProps = props?.sendActionBtnProps || null
    this.variant = props?.variant || EnumVariantMessageField.Default
    this.recordModalCloseProps = props?.recordModalCloseProps || null
    this.withTrialText = props?.withTrialText || false
    this.replaceMergeFieldsOnPaste = props?.replaceMergeFieldsOnPaste || false
    this._isShortUrlPostfix = props?.isShortUrlPostfix || false
    this._isCurrentAirCall = props?.isCurrentAirCall || false
    this._sub_source = props?.sub_source || ''

    if (props?.editMessage) {
      this.setEditMessage(props?.editMessage)
    }

    // Make events
    const events = props?.makeEvents?.(this)
    this.onFocus = events?.onFocus ?? null
    this.onClick = events?.onClick ?? null
    this.onKeyDown = events?.onKeyDown ?? null
    this.onKeyUp = events?.onKeyUp ?? null
    this.onInit = events?.onInit ?? null
    this.onCloseEdit = events?.onCloseEdit ?? null

    // Make actions
    this.actions = props?.makeActions?.(this) ?? []

    // Make input actions
    props?.makeInputActions?.(this)?.map(this.addInputAction)

    this.customSendAction = props?.customSendAction || null
    makeAutoObservable(this)

    this.reactionUploadingFiles()
    this.reactionChangeIsAircall()
  }

  promiseAttachmentLoading: Promise<void> | null = null
  promiseAttachmentLoadingResolve: ((value: void | PromiseLike<void>) => void) | null = null

  actions: Array<ActionItem>
  showActionsItems: number
  noBorder?: boolean
  noCounter?: boolean
  editId?: number | null = null
  messageHtml = ''
  messageInnerTextObj = {
    value: '',
  }

  additionalText = ''

  focusMessageFieldTrigger = ''
  blurMessageFieldTrigger = ''
  activeMessageField = false
  private _disabled = false
  private _sendDisabled = false
  messageSignatureSendDisabled = false
  loading = false
  isDoubleOptInEnabled = false
  replaceMergeFieldsOnPaste = false

  textLimit: IMessageFieldLimit = {
    maxLength: 1600,
    message: 'Message should not be longer than 1,600 characters',
    showAlert: true,
  }

  sendAlert: string | null = null

  attachmentsMap: Map<string | number, Attachment> = new Map()

  addContent: ((content: string) => void) | null = null
  replaceContent: ((content: string) => void) | null = null
  replaceSelectedContentObj: {
    fn: ((content: string) => void) | null
  } = {
    fn: null,
  }
  limitSizeErrors: ILimitSizeError[] = []
  extError = ''
  isAirCallNoSupportMms = false
  moreDropdownComponent: ((onCloseMenu?: () => void) => ReactNode) | null = null
  onCloseMoreDropdown: (() => void) | null = null

  replaceTriggerContent = ''
  updateTriggerContent = ''
  selectedContentObj = {
    value: '',
  }
  convertFileIdsMap: Map<number, string> = new Map()
  placeholder = '⌘K for shortcuts...'
  minHeight = 20
  maxHeight = 340
  fromSavedReply = false
  resetOnUnmount = true

  onFocus: IMessageFieldEvents['onFocus'] | null = null
  onClick: IMessageFieldEvents['onClick'] | null = null
  onKeyDown: IMessageFieldEvents['onKeyDown'] | null = null
  onKeyUp: IMessageFieldEvents['onKeyUp'] | null = null
  onInit: IMessageFieldEvents['onInit'] | null = null
  onCloseEdit: IMessageFieldEvents['onCloseEdit'] | null = null

  alertElement: HTMLDivElement | null = null
  messageFieldElement: { value: HTMLDivElement | null } = {
    value: null,
  }
  recordAudioState = false

  inputActions = new Map<string, IInputAction>()
  sendActionBtnProps: IMessageFieldStoreProps['sendActionBtnProps'] = null
  editMessage: Message | null = null
  mode: EnumMessageFieldMode = EnumMessageFieldMode.Sms
  variant: EnumVariantMessageField = EnumVariantMessageField.Default
  mentionsStore: DropdownMentionsStore = new DropdownMentionsStore({
    parent: this,
  })
  private _disposeParentMode: IReactionDisposer | null = null
  private _disposeUploadingFiles: IReactionDisposer | null = null
  private _disposeChangeIsAircall: IReactionDisposer | null = null
  private _isCurrentAirCall = false
  isHideSwitchMode = false

  customSendAction?: ReactElement | null = null
  recordModalCloseProps?: IModalCloseProps | null = null
  withTrialText = false
  recordAudioStore: RecordAudioStore = new RecordAudioStore()
  private _isShortUrlPostfix = false
  private _sub_source: string | null = null

  get disabled() {
    return this._disabled
  }

  get messageInnerText() {
    return this.messageInnerTextObj.value
  }

  get isCurrentAirCall() {
    return this._isCurrentAirCall
  }

  get attachments() {
    return Array.from(this.attachmentsMap.values())
  }

  get disabledFields() {
    if (this._isCurrentAirCall) {
      return {
        uploadImage: airCallTooltip,
        recordAudio: '',
      }
    }

    return {}
  }

  get attachmentsUploaded() {
    return this.attachments.filter((attachment) => !attachment.loading)
  }

  get iconButtonProps(): IIconButtonProps {
    return {
      variant: this.isModeNote ? 'contained' : 'icon',
      color: this.isModeNote ? 'passiveSecondary' : 'secondary',
      ariaLabel: 'SendAction_send',
    }
  }

  get buttonProps(): IButtonProps {
    return {
      intent: this.isModeNote ? 'passive' : 'default',
      contained: this.isModeNote ? 'primary' : 'secondary',
    }
  }

  get isVariantNote() {
    return this.variant === EnumVariantMessageField.Note
  }

  get isVariantConversation() {
    return this.variant === EnumVariantMessageField.Conversation
  }

  get isVariantBroadcast() {
    return this.variant === EnumVariantMessageField.Broadcast
  }

  get styles() {
    return {
      maxHeight: `${this.maxHeight}px`,
      minHeight: `${this.minHeight}px`,
    }
  }

  get countShortUrl() {
    const text = this.textWithAttachmentsNotForMedia

    const supportedDomains = shortLinkStore.allDomain?.supportedDomains || []
    const content = [...(text.match(urlRegex({ strict: false })) || [])].filter((url) =>
      supportedDomains.some((domain) => url.includes(domain))
    )

    return content.length
  }

  get fakeTextForConversion() {
    if (!this._isShortUrlPostfix) return ''

    return Array.from(Array(this.countShortUrl).keys()).reduce((state) => {
      state += SHORT_URL_POSTFIX_DEFAULT

      return state
    }, '')
  }

  get messageRemainsInfo() {
    if (this.isMMS) {
      return {
        messageCount: Math.ceil(this.textLength / 1600) || (this.attachments.length && 1) || 0,
        messageRemains: this.textLength === 1600 ? 0 : 1600 - (this.textLength % 1600),
      }
    }

    const messageInfo = calculatorGSM(
      this.textWithAttachmentsNotForMedia + this.fakeTextForConversion
    )

    return {
      messageCount: messageInfo.sms_count,
      messageRemains: messageInfo.chars_left,
    }
  }

  get textLimitReached() {
    return this.textLimit.maxLength - this.textLength < 0
  }

  get attachmentsForMms() {
    return this.attachments.filter(
      (attachment) => attachment.is_allowed_for_media_url || attachment.media_preview
    )
  }

  get uploadingFiles() {
    return this.attachments.filter((attachment) => attachment.loading)
  }

  get isAttachmentsCountLimitError() {
    return this.attachmentsForMms.length > 5
  }

  get messageRequestData(): IParamsCreateMessage {
    return {
      client_id: '',
      manual_created: true,
      media_url: this.media_url,
      message: this.messageTextForBackAnd,
      type: this.isModeNote ? 'note' : null,
      sub_source: this._sub_source || null,
    }
  }

  get messageDraftData(): IResponseConversationDraftMessageData {
    return {
      audio_url: [],
      docs_url: [],
      images_url: [],
      video_url: [],
      media_url: this.media_url.map((item) => item.attachmentDraft),
      manual_created: true,
      message: this.messageText,
      send_at: this.editMessage?.send_at || '',
      stop_on_response: this.editMessage?.stop_on_response || false,
    }
  }

  get isEmptyDraft() {
    return Boolean(!this.messageDraftData.media_url.length && !this.messageDraftData.message.length)
  }

  get paramsUpdateMessage(): IParamsUpdateMessage {
    return {
      manual_created: true,
      media_url: this.media_url,
      body: this.messageTextForBackAnd,
      stop_on_response: this.editMessage?.stop_on_response || false,
      send_at: this.editMessage?.send_at || '',
      is_contact_timezone: this.editMessage?.is_contact_timezone || false,
    }
  }

  get loadingSend() {
    return this.loading
  }

  get isSmallAirCallAttach() {
    return this.attachmentsForMms.some((attach) => {
      if (attach.type === 'video') {
        return formatBytesToNumber(attach.size || 0) < 600
      }

      if (attach.type === 'audio') {
        return formatBytesToNumber(attach.size || 0) < 5000
      }

      return attach.type === 'image' || attach.type === 'gif' || attach.type === 'vcard'
    })
  }

  get isMMSWithAirCallCheck() {
    if (!inboxesStore.isCurrentAirCall) {
      return this.isMMS
    }

    return this.isSmallAirCallAttach
  }

  get disablesSend() {
    if (
      this._sendDisabled ||
      this.textLimitReached ||
      this.isAttachmentsCountLimitError ||
      this.messageSignatureSendDisabled ||
      this.loadingAttachment
    ) {
      return true
    }

    if (inboxesStore.isCurrentAirCall && this.isSmallAirCallAttach) {
      return true
    }

    return !this.hasText && !this.attachments.length
  }

  get hasText() {
    return !!this.text.length
  }

  get text() {
    return htmlToText(this.messageHtml).trim()
  }

  get messageText() {
    return `${this.text}${this.attachmentsNotForMediaText}`
  }

  get messageTextForBackAnd() {
    const additionalText = this.editMessage ? '' : this.additionalText
    return `${this.messageText}${additionalText}`
  }

  get isMMS() {
    return this.attachments.some(
      (attachment) =>
        attachment.is_allowed_for_media_url || attachment.media_preview?.is_allowed_for_media_url
    )
  }

  get attachmentsNotForMedia() {
    return this.attachmentsUploaded.filter((attachment) => !attachment.is_allowed_for_media_url)
  }

  get media_url() {
    const media_url: RequestAttachment[] = []
    this.attachments.forEach((attachment) => {
      if (attachment.is_allowed_for_media_url) {
        media_url.push(new RequestAttachment(attachment))
      }
      if (attachment.media_preview) {
        attachment.media_preview.original_source = attachment.source
        const prevMedia = new RequestAttachment(attachment.media_preview)
        media_url.push(prevMedia)
      }
    })

    return media_url
  }

  get attachmentsNotForMediaText() {
    return this.attachmentsNotForMedia
      .map((attachment) => {
        if (attachment.media_preview) {
          return `\nHere is a video preview. To watch the whole video click on the link: ${attachment.source_short}`
        }
        return `\n${attachment.source_short || attachment.source}`
      })
      .join('')
  }

  get textWithAttachmentsNotForMedia() {
    const trialText = this.withTrialText && subscriptionStore.isTrial ? 'Salesmsg Trial: ' : ''
    const text = trialText + `${this.messageInnerText}${this.attachmentsNotForMediaText}`.trim()

    if (this.additionalText) {
      return `${text}${this.additionalText}`
    }

    return text
  }

  get textLength() {
    return this.textWithAttachmentsNotForMedia.length
  }

  get loadingAttachment() {
    return this.attachments.some((item) => item.loading)
  }

  get conversationContact() {
    const contactId = conversationStore.currentItem?.contact_id
    if (!contactId) return null

    const contact = contactsStore.getItem(contactId)
    if (!contact) return null

    return contact
  }

  get isModeSms() {
    return this.mode === EnumMessageFieldMode.Sms
  }

  get isModeNote() {
    return this.mode === EnumMessageFieldMode.Note
  }

  get isShowCustomShortUrlBanner() {
    return shortLinkStore.isShowCustomShortUrlBanner
  }

  setSubSource = (value: string) => {
    this._sub_source = value
  }

  setIsCurrentAirCall = (value: boolean) => {
    this._isCurrentAirCall = value
  }

  handleResetMode = () => {
    this.mode = EnumMessageFieldMode.Sms
    this.setPlaceholder('Write a message...')
  }

  handleToggleMode = () => {
    this.mode =
      this.mode === EnumMessageFieldMode.Sms ? EnumMessageFieldMode.Note : EnumMessageFieldMode.Sms

    this.setPlaceholder(this.isModeNote ? 'Type @ to mention...' : 'Write a message...')
  }

  setNoteMode = (isChangePlaceholder?: boolean) => {
    this.mode = EnumMessageFieldMode.Note

    if (isChangePlaceholder) {
      this.setPlaceholder('Type @ to mention...')
    }
  }

  setAlertElement = (alertElement: HTMLDivElement) => {
    this.alertElement = alertElement
  }

  setMessageFieldElement = (messageFieldElement: HTMLDivElement) => {
    this.messageFieldElement.value = messageFieldElement
  }

  setActions = (actions: typeof this.actions) => {
    this.actions = actions
  }

  addInputAction = (action: IInputAction) => {
    this.inputActions.set(action.value, action)
  }

  setPlaceholder = (value: string) => {
    this.placeholder = value
  }

  setEditId = (id: number | null) => {
    this.editId = id
  }

  clear = () => {
    this.messageInnerTextObj.value = ''
    this.messageHtml = ''
    this._disabled = false
    this.editId = null
  }

  clearWindowSelection = () => {
    window.getSelection()?.empty()
  }

  resetReactions = () => {
    this._disposeParentMode?.()
    this._disposeUploadingFiles?.()
  }

  reset = (resetActions?: boolean, noResetEditMessage?: boolean) => {
    this.loading = false
    if (this.onCloseMoreDropdown) {
      this.onCloseMoreDropdown()
    }
    if (resetActions) {
      this.actions = []
    }

    this.recordAudioState = false
    this.replaceTriggerContent = ''
    this.focusMessageFieldTrigger = ''
    this.blurMessageFieldTrigger = ''

    this.attachmentsMap.clear()
    this.messageInnerTextObj.value = ''
    this.messageHtml = ''

    this._disabled = false

    this.limitSizeErrors = []
    this.clearExtError()
    this.editId = null
    if (!noResetEditMessage) {
      this.editMessage = null
    }
    this.moreDropdownComponent = null
    this.mentionsStore.reset()
  }

  setMessageText = (html: string, innerText: string) => {
    this.messageHtml = html
    this.messageInnerTextObj.value = innerText
  }

  setFocusMessageFieldTrigger = () => {
    if (this.disabled) return

    this.focusMessageFieldTrigger = nanoid()
  }

  setBlurMessageFieldTrigger = () => {
    this.blurMessageFieldTrigger = nanoid()
  }

  setDisabledMessageField = (value: boolean) => {
    if (value && this.activeMessageField) {
      this.setBlurMessageFieldTrigger()
    }

    this._disabled = value
  }

  setSendDisabled = (value: boolean) => {
    this._sendDisabled = value
  }

  setMessageSignatureSendDisabled = (value: boolean) => {
    this.messageSignatureSendDisabled = value
  }

  setAddContentFn = (fn: typeof this.addContent) => {
    this.addContent = fn
  }

  setReplaceSelectedContentFn = (fn: typeof this.addContent) => {
    this.replaceSelectedContentObj.fn = fn
  }

  setReplaceContentFn = (fn: typeof this.addContent) => {
    this.replaceContent = fn
  }

  addAttachments(attachments: Attachment[]) {
    attachments.forEach((attachment) => {
      if (this.attachments.some((item) => item.id === attachment.id)) return

      attachmentStore.setPreviousAttachments([attachment])

      this.attachmentsMap.set(attachment.id, attachment)
    })
  }

  setLimitSizeError = (limitError: ILimitSizeError) => {
    if (this.limitSizeErrors.some((item) => item.label === limitError.label)) return

    this.limitSizeErrors.push(limitError)
    setTimeout(() => {
      this.limitSizeErrors = []
    }, 5000)
  }

  deleteLimitSizeError = (label: string) => {
    this.limitSizeErrors = this.limitSizeErrors.filter((item) => item.label !== label)
  }

  setExtError = (extError: string) => {
    this.extError = extError

    setTimeout(() => {
      this.clearExtError()
    }, 5000)
  }

  clearExtError = () => {
    this.extError = ''
  }

  setSendAlert(alert: string) {
    this.sendAlert = alert
  }

  clearSendAlert() {
    this.sendAlert = null
  }

  setConvertAttachment = (data?: IResponseAttachmentUpload) => {
    if (data?.id) {
      const convertId = this.convertFileIdsMap.get(data?.id)
      if (convertId) {
        const attachment = new Attachment({ responseUpload: data })
        this.convertFileIdsMap.delete(data.id)
        this.addAttachment(attachment)
      }
    }
  }

  async addMediaFiles(files: File[]) {
    await attachmentStore.addMediaFiles({
      files,
      addAttachment: this.addAttachment,
      setExtError: this.setExtError,
      setLimitSizeError: this.setLimitSizeError,
    })
  }

  addAttachment = (attachment: Attachment, update = false) => {
    if (update) {
      const exist = this.attachmentsMap.get(attachment.id)

      if (exist) {
        this.attachmentsMap.set(attachment.id, attachment)
      }
    } else {
      this.attachmentsMap.set(attachment.id, attachment)
    }
  }

  removeAttachment = (id: number | string) => {
    this.attachmentsMap.delete(id)
  }

  removeAttachments = (ids: Array<string | number>) => {
    ids.forEach((id) => {
      this.removeAttachment(id)
    })
  }

  setMoreDropdownComponent = (moreDropdownComponent: typeof this.moreDropdownComponent) => {
    this.moreDropdownComponent = moreDropdownComponent
  }

  setOnCloseMoreDropdown = (onCloseMoreDropdown: typeof this.onCloseMoreDropdown) => {
    this.onCloseMoreDropdown = onCloseMoreDropdown
  }

  setMessageData = ({
    message,
    attachments,
    noMergeField,
    isReset = true,
    isFocus = true,
    isReplace = false,
    replaceNewRow = true,
  }: MessageData) => {
    if (isReset) {
      this.reset(false, true)
    }

    const newMessageHtml = textToHtml({
      text: message,
      replaceNewRow: replaceNewRow,
      noMergeField,
      extraSpace: true,
    })
    const newMessageText = message

    if (isReplace) {
      this.attachmentsMap.clear()
      this.addAttachments(attachments)
      this.setMessageText(newMessageHtml, newMessageText)
    } else {
      this.addAttachments(attachments)
      this.addContent?.(newMessageHtml + '&nbsp;')
    }

    this.replaceTriggerContent = nanoid()
    if (isFocus) {
      this.focusMessageFieldTrigger = nanoid()
    }
  }

  replaceMessageText = (message: string) => {
    this.setMessageData({
      message,
      attachments: [],
      isReplace: true,
    })
  }

  setEditMessage = (message: Message | null) => {
    this.setMessageData({
      message: message?.body || '',
      attachments: message?.media || [],
      isReplace: true,
    })
    this.editMessage = message
  }

  setUpdateTriggerContent = () => {
    this.updateTriggerContent = nanoid()
  }

  setRecordAudioState = (value: boolean) => {
    this.recordAudioState = value
  }

  setSendActionBtnProps = (sendActionBtnProps: typeof this.sendActionBtnProps) => {
    this.sendActionBtnProps = sendActionBtnProps
  }

  setSelectedContent = (value: string) => {
    this.selectedContentObj.value = value
  }

  reactionParentMode = () => {
    this._disposeParentMode?.()
    this._disposeParentMode = reaction(
      () => this.mode,
      () => {
        this.reset()
        this.setFocusMessageFieldTrigger()
      }
    )
  }

  reactionUploadingFiles = () => {
    this._disposeUploadingFiles?.()
    this._disposeUploadingFiles = reaction(
      () => this.uploadingFiles.length,
      (size) => {
        if (size) {
          this.promiseAttachmentLoading = new Promise((resolve) => {
            this.promiseAttachmentLoadingResolve = resolve
          })
        } else {
          if (this.promiseAttachmentLoadingResolve) {
            this.promiseAttachmentLoadingResolve()
          }
        }
      }
    )
  }

  reactionChangeIsAircall = () => {
    this._disposeChangeIsAircall?.()
    this._disposeChangeIsAircall = reaction(
      () => [this._isCurrentAirCall, this.isSmallAirCallAttach],
      ([isCurrentAirCall, isSmallAirCallAttach]) => {
        if (isCurrentAirCall) {
          this.isAirCallNoSupportMms = isSmallAirCallAttach
        } else {
          this.isAirCallNoSupportMms = false
        }
      }
    )
  }

  setHideSwitchMode = (value: boolean) => {
    this.isHideSwitchMode = value
  }

  closeHideSwitchMode = () => {
    if (this.isHideSwitchMode) {
      this.setHideSwitchMode(false)
    }
  }

  handleCloseCustomShortUrlBanner = () => {
    shortLinkStore.handleCloseCustomShortUrlBanner()
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setIsDoubleOptInEnabled = (isDoubleOptInEnabled: boolean) => {
    this.isDoubleOptInEnabled = isDoubleOptInEnabled
  }

  setActiveMessageField = (value: boolean) => {
    this.activeMessageField = value
  }

  setAdditionalText = (text: string) => {
    this.additionalText = text
  }
}
