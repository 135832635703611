import { nanoid } from 'nanoid'
import { type IAvatarInfo } from 'shared/ui'
import { Base } from 'models'
import {
  IResponseContactActivecampaignIntegration,
  IResponseContactHubspotIntegration,
  IResponseContactInfusionsoftIntegration,
  IResponseContactPipedriveIntegration,
  IResponseContactSalesforceIntegration,
} from 'entities/Contacts/api/types'

type IResponseContactIntegration =
  | IResponseContactHubspotIntegration
  | IResponseContactActivecampaignIntegration
  | IResponseContactInfusionsoftIntegration
  | IResponseContactPipedriveIntegration
  | IResponseContactSalesforceIntegration

export class ContactIntegration extends Base {
  id: number | string
  integrationId: number | string
  email: string | null
  first_name: string
  last_name: string
  integration_key: string
  phone: string | null
  mobile_phone: string | null
  mobile_phone_number: string | null
  phone_number: string | null
  ariaLabel: string

  constructor(item: IResponseContactIntegration) {
    super()

    const phone = (): string | null => {
      if ('phone' in item) {
        return item.phone || null
      }

      if ('phones' in item) {
        if (Array.isArray(item.phones)) {
          return item.phones?.[0] || null
        }

        return item.phones?.Phone || null
      }

      return null
    }

    const mobile_phone = (): string | null => {
      if ('mobile_phone' in item) {
        return item.mobile_phone || null
      }
      return null
    }

    const mobile_phone_number = (): string | null => {
      if ('mobile_phone_number' in item) {
        return item.mobile_phone_number || null
      }
      return null
    }

    const phone_number = (): string | null => {
      if ('phone_number' in item) {
        return item.phone_number || null
      }
      return null
    }

    this.integrationId = item.id
    this.email = Array.isArray(item.email) ? item.email?.[0] || null : item.email || null
    this.first_name = item.first_name
    this.last_name = item.last_name
    this.integration_key = item.integration_type
    this.phone = phone()
    this.mobile_phone = mobile_phone()
    this.mobile_phone_number = mobile_phone_number()
    this.phone_number = phone_number()
    this.id = item.id || nanoid()
    this.ariaLabel = ''
  }

  get key() {
    return this.integration_key
  }

  get avatarInfo(): IAvatarInfo {
    const isPhoneAbbr = !this.first_name && !this.last_name

    return {
      isPhoneAbbr,
      firstName: this.first_name,
      lastName: this.last_name,
      number: this.phone_number || this.mobile_phone || this.mobile_phone_number || undefined,
    }
  }

  get fullName() {
    return `${this.first_name || ''} ${this.last_name || ''}`.trim()
  }
}
