import { ReactNode } from 'react'
import { Box, BoxProps } from 'shared/ui/Box'
import { Icon, IIconProps } from 'shared/ui/Icon'
import { type ITypographyProps, Typography } from 'shared/ui/Typography'
import { getAriaLabel } from 'shared/lib'

export type IDefaultEmptyStateProps = {
  variant?: 'default'
  iconProps?: IIconProps
  title?: ReactNode
  text?: ReactNode
  typographyProps?: ITypographyProps
  alignItems?: 'center' | 'flex-end' | 'flex-start'
  noIcon?: boolean
  actions?: ReactNode
  boxProps?: BoxProps
}

export const DefaultEmptyState = ({
  iconProps,
  typographyProps,
  text = 'No results found',
  title,
  alignItems = 'center',
  noIcon = false,
  actions,
  boxProps,
}: IDefaultEmptyStateProps) => {
  return (
    <Box
      display='flex'
      alignItems={alignItems}
      flexDirection='column'
      justifyContent='center'
      height='100%'
      width='100%'
      gap={'16px'}
      aria-label={getAriaLabel('EmptyState')}
      {...boxProps}
    >
      {!noIcon && (
        <Icon color='var(--content-primary-tertiary)' fontSize={24} icon='search' {...iconProps} />
      )}
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        gap={'4px'}
      >
        {title && (
          <Typography variant={'body-rg-medium'} ariaLabel={'title'}>
            {title}
          </Typography>
        )}
        <Typography
          variant={'body-rg-medium'}
          color={'var(--content-primary-tertiary)'}
          ariaLabel={'text'}
          {...typographyProps}
        >
          {text}
        </Typography>
      </Box>
      {actions && (
        <Box marginTop={'4px'} display='flex' gap={'12px'}>
          {actions}
        </Box>
      )}
    </Box>
  )
}
