import { uniqBy } from 'lodash'
import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import { IResponseGetExport } from 'entities/Export'
import { IResponseFilters } from 'entities/Contacts/api/filterTypes'
import { IContactsStatisticFilter } from 'entities/ContactStatistic'
import {
  IBroadcastType,
  IParamsBroadcast,
  IParamsEstimateBroadcast,
  IParamsGetBroadcastContacts,
  IParamsGetBroadcasts,
  IParamsGetRecurringBroadcasts,
  IParamsSaveBroadcastsColumns,
  IParamsTestBroadcast,
  IResponseBroadcastById,
  IResponseEstimateBroadcast,
  IResponseGetBroadcastContacts,
  IResponseGetBroadcasts,
  IResponseGetRecurringBroadcasts,
  IResponseOneTimeBroadcast,
  IResponseRecurringBroadcast,
  IResponseRecurringBroadcastById,
  IResponseSaveBroadcastsColumns,
  IResponseTestBroadcast,
} from './types'

const BOTTOM_ORDERED_OPERATORS_KEYS = ['is_known', 'is_unknown']

// TODO: move to BE
const normalizeFiltersResponse = (
  response: AxiosResponse<IResponseFilters>,
  type: IBroadcastType
) => {
  const { data } = response

  if (type === 'all') {
    response.data = {
      ...data,
      filters: uniqBy(data.filters, 'key')
        .sort((item) => (item.key.includes('broadcasts_recurring_settings') ? 1 : 0))
        .sort((item) => (item.key.includes('broadcasts_recurring_settings.status') ? -1 : 0))
        .map((filter) => {
          if (filter.key === 'broadcasts_recurring_settings.type') filter.name = 'Recurring type'
          if (filter.key === 'broadcasts_recurring_settings.status')
            filter.name = 'Recurring status'
          return {
            ...filter,
            category_new: filter.key.includes('broadcasts_recurring_settings')
              ? 'recurring'
              : filter.category_new,
          }
        }),
    }
  }

  if (type === 'recurring') {
    response.data = {
      ...data,
      filters: data.filters.sort((item) =>
        item.key.includes('broadcasts_recurring_settings.status') ? -1 : 0
      ),
    }
  }

  // mutation: re-order operators
  for (const key in response.data.operators) {
    response.data.operators[key] = response.data.operators[key].sort((a, b) => {
      const numberA = BOTTOM_ORDERED_OPERATORS_KEYS.includes(a.key) ? 1 : 0
      const numberB = BOTTOM_ORDERED_OPERATORS_KEYS.includes(b.key) ? 1 : 0

      return numberA - numberB
    })
  }

  return response
}

class Api {
  getBroadcasts(
    params: IParamsGetBroadcasts,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetBroadcasts>> {
    return baseApi.post('broadcasts/all', params, { ...options })
  }

  getBroadcastById(id: number | string): Promise<AxiosResponse<IResponseBroadcastById>> {
    return baseApi.get(`broadcasts/${id}`)
  }

  getRecurringBroadcasts(
    id: number,
    params: IParamsGetRecurringBroadcasts,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetRecurringBroadcasts>> {
    return baseApi.get(`broadcasts/recurring/${id}/details`, { params, ...options })
  }

  createBroadcast(params: IParamsBroadcast): Promise<AxiosResponse<IResponseOneTimeBroadcast>> {
    return baseApi.post('broadcasts', params)
  }

  updateBroadcast(params: IParamsBroadcast): Promise<AxiosResponse<IResponseOneTimeBroadcast>> {
    return baseApi.put(`broadcasts/${params.id}`, params)
  }

  estimateBroadcast(
    params: IParamsEstimateBroadcast
  ): Promise<AxiosResponse<IResponseEstimateBroadcast>> {
    return baseApi.post('broadcasts/count-estimated-credits', params)
  }

  getBroadcastContacts(
    id: number,
    params: IParamsGetBroadcastContacts
  ): Promise<AxiosResponse<IResponseGetBroadcastContacts>> {
    return baseApi.get(`broadcasts/contacts/${id}`, {
      params,
    })
  }

  createTest(params: IParamsTestBroadcast): Promise<AxiosResponse<IResponseTestBroadcast>> {
    return baseApi.post('broadcasts/test', params)
  }

  pauseOneTime(id: number): Promise<AxiosResponse<IResponseOneTimeBroadcast>> {
    return baseApi.put(`broadcasts/${id}/pause`)
  }
  resumeOneTime(id: number): Promise<AxiosResponse<IResponseOneTimeBroadcast>> {
    return baseApi.put(`broadcasts/${id}/in_progress`)
  }
  stopOneTime(id: number): Promise<AxiosResponse<IResponseOneTimeBroadcast>> {
    return baseApi.put(`broadcasts/${id}/stopped`)
  }
  deleteOneTime(id: number): Promise<void> {
    return baseApi.delete(`broadcasts/${id}`)
  }
  pauseRecurring(id: number): Promise<AxiosResponse<IResponseRecurringBroadcastById>> {
    return baseApi.put(`broadcasts/recurring/${id}/inactive`)
  }
  resumeRecurring(id: number): Promise<AxiosResponse<IResponseRecurringBroadcastById>> {
    return baseApi.put(`broadcasts/recurring/${id}/active`)
  }
  stopRecurring(id: number): Promise<AxiosResponse<IResponseRecurringBroadcastById>> {
    return baseApi.put(`broadcasts/recurring/${id}/finish`)
  }
  stopAllFutureRecurringChild(id: number): Promise<AxiosResponse<IResponseOneTimeBroadcast>> {
    return baseApi.put(`broadcasts/recurring/${id}/stop-all-future`)
  }
  deleteRecurring(id: number): Promise<AxiosResponse<IResponseRecurringBroadcastById>> {
    return baseApi.delete(`broadcasts/recurring/${id}/delete`)
  }
  getExport(
    id: number,
    filter: IContactsStatisticFilter
  ): Promise<AxiosResponse<IResponseGetExport>> {
    return baseApi.get(`/broadcasts/contacts/${id}/export`, {
      params: {
        filter: filter,
      },
    })
  }
  renameRecurring = (
    id: number,
    name: string
  ): Promise<AxiosResponse<IResponseRecurringBroadcast>> => {
    return baseApi.put(`broadcasts/recurring/${id}/rename`, { name })
  }

  getFilters = (type: IBroadcastType): Promise<AxiosResponse<IResponseFilters>> => {
    return baseApi
      .get('broadcasts/filters/all', {
        params: {
          type,
        },
      })
      .then((response) => normalizeFiltersResponse(response, type))
  }

  saveBroadcastsColumns = ({
    type,
    broadcast_fields,
  }: IParamsSaveBroadcastsColumns): Promise<AxiosResponse<IResponseSaveBroadcastsColumns>> => {
    return baseApi.post('broadcasts/fields', {
      type,
      broadcast_fields,
    })
  }
  getBroadcastsColumns = (
    type: IBroadcastType
  ): Promise<AxiosResponse<IResponseSaveBroadcastsColumns>> => {
    return baseApi.get(`users/ui-settings/broadcast-table-columns_${type}`)
  }
}

export const BroadcastApi = new Api()
