import { makeAutoObservable } from 'mobx'
import { ContactsApi } from 'entities/Contacts'
import { ContactsDuplicateTableStore } from 'widgets/ContactsTable/store/ContactsDuplicateTableStore'
import { ContactsUnlinkedTableStore } from 'widgets/ContactsTable/store/ContactsUnlinkedTableStore'

type TabTypes = 'unlink' | 'duplicate' | string

class ContactsCleanupStore {
  private _activeTab: TabTypes = 'duplicate'
  private _contactsUnlinkedTableStore = new ContactsUnlinkedTableStore()
  private _contactsDuplicateTableStore = new ContactsDuplicateTableStore()
  private _changeToUnlinked = false
  private _duplicate_count = 0
  private _unlinked_count = 0

  constructor() {
    makeAutoObservable(this)
  }

  get activeTab() {
    return this._activeTab
  }

  get contactsUnlinkedTable() {
    return this._contactsUnlinkedTableStore
  }

  get contactsDuplicateTable() {
    return this._contactsDuplicateTableStore
  }

  get isChangeToUnlinked() {
    return this._changeToUnlinked
  }

  get hasCleanupItems() {
    return (
      !this._contactsDuplicateTableStore.noResults || !this._contactsUnlinkedTableStore.noResults
    )
  }

  get cleanupTitle() {
    const getPlural = (value: number) => (value > 1 ? 'contacts' : 'contact')
    const duplicatesLength = this.duplicateCount
    const unlinkedLength = this.unlinkedCount
    const noDuplicates = this._contactsDuplicateTableStore.noResults
    const noLinked = this._contactsUnlinkedTableStore.noResults

    if (!noDuplicates && noLinked) {
      return `We found ${duplicatesLength} duplicate ${getPlural(duplicatesLength)}`
    }
    if (noDuplicates && !noLinked) {
      return `We found ${unlinkedLength} unlinked ${getPlural(unlinkedLength)}`
    }

    return `We found  ${duplicatesLength} duplicate and ${unlinkedLength} unlinked contacts`
  }

  get isExistOnlyUnlinked() {
    return (
      this._contactsDuplicateTableStore.noResults && !this._contactsUnlinkedTableStore.noResults
    )
  }

  get duplicateCount() {
    return this._duplicate_count
  }

  get unlinkedCount() {
    return this._unlinked_count
  }

  subtractDuplicateCount = () => {
    this._duplicate_count -= 1
  }

  loadDuplicateCount = async () => {
    const { data } = await ContactsApi.getContactsDuplicateCount()

    this._duplicate_count = data.duplicate_count
    this._unlinked_count = data.unlinked_count
  }

  init = () => {
    this._contactsDuplicateTableStore.init()
    this._contactsUnlinkedTableStore.init()
    this.loadDuplicateCount()
  }

  handleOpen = () => {
    if (!this._contactsDuplicateTableStore.noResults) {
      this._activeTab === 'duplicate'
      return
    }
  }

  handleActiveTab = (tab: TabTypes) => {
    this._activeTab = tab
  }

  setIsChangeToUnlinked = (value: boolean) => {
    this._changeToUnlinked = value
  }

  resetPaginationPage = () => {
    this._contactsDuplicateTableStore.resetPaginationPage()
    this._contactsUnlinkedTableStore.resetPaginationPage()
  }
}

export const contactsCleanupStore = new ContactsCleanupStore()
