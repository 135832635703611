import React, { useCallback, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Checkbox, EnumIconButtonShape, IconButton, Status, Tooltip, Typography } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { AttachmentLoader } from 'shared/ui/Loader/AttachmentLoader/AttachmentLoader'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { ImageCard, VideoCard } from 'entities/Attachment'
import styles from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryCard/styles.module.scss'
import { MediaLibraryCardActions } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryCard/MediaLibraryCardActions'
import { useMediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { getAttachmentFlags } from 'widgets/MediaLibrary/utils'
import { FileIcon } from 'widgets/FileIcon/FileIcon'
import { MAX_AUDIO_DURATION_RINGLESS_SELECT } from 'widgets/MediaLibrary/constants'

type IAttachmentCardProps = {
  attachment: Attachment
  attachments?: Attachment[]
  isSelected: boolean
  isLoading?: boolean
  handleCardClick: () => void
}

export const MediaLibraryCard = observer(
  ({ attachment, attachments, isSelected, isLoading, handleCardClick }: IAttachmentCardProps) => {
    const mediaLibraryStore = useMediaLibraryContext()
    const { onOpenPresentationModal, isSingleSelectMode, isRinglessSelectVariant } =
      mediaLibraryStore
    const [isCardHovered, setIsCardHovered] = useState(false)

    const isCardDisabled =
      isRinglessSelectVariant &&
      attachment.duration &&
      attachment?.duration > MAX_AUDIO_DURATION_RINGLESS_SELECT

    const {
      isAudio,
      isVideo,
      isImage,
      isVCard,
      isAttachmentPresentationAvailable,
      isAttachmentWithContent,
    } = getAttachmentFlags(attachment)

    const handleMouseEnter = () => {
      setIsCardHovered(true)
    }

    const handleMouseLeave = () => {
      setIsCardHovered(false)
    }

    const handleStopPropagation = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
    }

    const onOpenPresentation = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onOpenPresentationModal(attachment, attachments)
    }

    const fileTypeLabel = useCallback(() => {
      if (attachment.loading) return 'Processing'
      if (isImage) return 'Image'
      if (isVideo) return 'Video'
      if (isAudio) return 'Audio'
      if (isVCard) return 'vCard'
      return 'File'
    }, [isImage, isVideo, isAudio, isVCard, attachment.loading])

    const mediaContent = () => {
      if (isAttachmentWithContent) {
        if (isImage) {
          return <ImageCard thumbnail attachment={attachment} />
        }

        if (isVideo) {
          return attachment.thumbnail ? (
            <ImageCard thumbnail attachment={attachment} />
          ) : (
            <VideoCard thumbnail attachment={attachment} />
          )
        }
      }

      return <div className={styles.mediaStub}></div>
    }

    const fileIcon = useMemo(() => {
      if (isCardHovered && !attachment.loading && isAttachmentPresentationAvailable) return
      if (isAudio)
        return <FileIcon isAudio={isAudio} fontSize={20} insideBox={true} boxRounded={true} />
      if (isVCard)
        return <FileIcon isVCard={isVCard} fontSize={20} insideBox={true} boxRounded={true} />
      if (isImage && !isAttachmentWithContent)
        return <FileIcon isImage={isImage} fontSize={20} insideBox={true} boxRounded={true} />
      if (isVideo && !isAttachmentWithContent)
        return <FileIcon isVideo={isVideo} fontSize={20} insideBox={true} boxRounded={true} />

      return <FileIcon docType={attachment.extension || attachment.content_type} fontSize={32} />
    }, [isCardHovered, attachment.type, attachment.loading])

    return (
      <Tooltip
        placement={'top'}
        key={attachment.id}
        label={
          isCardDisabled ? (
            <Typography
              className={styles.tooltipText}
              variant={'body-md-regular'}
              ariaLabel={'DisabledMediaLibraryCard'}
            >
              The file cannot be uploaded because it exceeds the 3-minute limit.
            </Typography>
          ) : null
        }
        width={250}
      >
        <div className={styles.galleryCard}>
          <div
            className={classNames(styles.cardContainer, {
              [styles.selected]: isSelected,
              [styles.disabled]: isCardDisabled,
            })}
            onClick={() => !attachment.loading && !isCardDisabled && handleCardClick()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.mediaContainer}>{mediaContent()}</div>
            {isLoading && <AttachmentLoader isLibrary />}
            <div className={styles.overlay}></div>
            <div className={styles.topLeft}>
              {!attachment.loading && !isSingleSelectMode && (
                <Checkbox variant='bordered' size='original' checked={isSelected} />
              )}
            </div>
            <div className={styles.topRight} onClick={handleStopPropagation}>
              <Status
                title={fileTypeLabel()}
                intent={'white'}
                emphasis={'low'}
                size={'label'}
                className={styles.fileTypeLabel}
              />
            </div>
            <div className={styles.bottomLeft} onClick={handleStopPropagation}>
              {(isAudio || isVideo) && typeof attachment?.duration === 'number' && (
                <div className={styles.duration}>{secondsToTime(attachment.duration)}</div>
              )}
            </div>
            <div className={styles.bottomRight} onClick={handleStopPropagation}>
              {isCardHovered && !attachment.loading && !isRinglessSelectVariant && (
                <MediaLibraryCardActions attachment={attachment} />
              )}
            </div>
            <div className={styles.center}>
              {fileIcon}
              {isCardHovered && isAttachmentPresentationAvailable && !attachment.loading && (
                <IconButton
                  icon={isAudio || isVideo ? 'play1' : 'expand2'}
                  color={'darkPrimary'}
                  shape={EnumIconButtonShape.Rounded}
                  ariaLabel='ShowPresentationMode'
                  fontSize={16}
                  onClick={onOpenPresentation}
                  className={styles.openPresentationModeButton}
                />
              )}
            </div>
          </div>

          <div
            className={classNames(styles.fileName, {
              [styles.disabled]: isCardDisabled,
            })}
          >
            <Typography variant={'body-md-regular'} ariaLabel={'filename'}>
              {attachment.name}
            </Typography>
          </div>
        </div>
      </Tooltip>
    )
  }
)
