import { Route, Routes } from 'react-router-dom'
import React from 'react'
import { OrganizationDetails, Organizations } from 'pages/admin/pages/organizations'
import { CustomPlan } from 'pages/admin/pages/organizations/pages/CustomPlan'
import { AdminRoutes } from 'pages/admin/route/type'
import { NewOrganization } from '../../newOrganization/NewOrganization'

export const OrganizationsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Organizations />} />
      <Route path={'/:id'} element={<OrganizationDetails />} />
      <Route path={`/:id/${AdminRoutes.customPayments}`} element={<CustomPlan />} />
      <Route path={`${AdminRoutes.create}`} element={<NewOrganization />} />
    </Routes>
  )
}
