// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h2PYgTA8Ft_yXa85uolk{padding:16px 24px 24px 24px}", "",{"version":3,"sources":["webpack://./src/pages/keywords/pages/all/ui/KeywordsModals/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA","sourcesContent":[".wrap {\n  padding: 16px 24px 24px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "h2PYgTA8Ft_yXa85uolk"
};
export default ___CSS_LOADER_EXPORT___;
