import { FC, ReactNode } from 'react'
import { Label } from 'shared/ui/Label'
import {
  type ITextFieldErrorAction,
  makeTextFieldError,
  TextFieldPlaceholder,
} from 'shared/ui/TextField'
import { Dropdown, type IDropdownProps } from 'shared/ui/Dropdown'
import type { ITooltipProps } from 'shared/ui/Tooltip/ui/Tooltip'
import styles from './styles.module.scss'

export type IFieldDropdownErrorProps = ITextFieldErrorAction & {
  message?: ReactNode
}

export type IFieldDropdownProps = {
  labelText: string
  triggerComponent?: ReactNode
  customComponent: IDropdownProps['customComponent']
  customTriggerComponent?: IDropdownProps['triggerComponent']
  onToggle?: IDropdownProps['onToggle']
  offset?: { x?: number; y?: number }
  error?: IFieldDropdownErrorProps
  questionTooltipProps?: ITooltipProps
  placeholderClassName?: string
}

export const FieldDropdown: FC<IFieldDropdownProps> = ({
  labelText,
  triggerComponent,
  customTriggerComponent,
  customComponent,
  onToggle,
  offset,
  error,
  questionTooltipProps,
  placeholderClassName,
}) => {
  return (
    <div className={styles.wrap}>
      <Label
        label={{
          text: labelText,
          horizontal: true,
          className: styles.label,
        }}
        questionTooltipProps={questionTooltipProps}
      />

      <Dropdown
        ariaLabel={'FieldDropdown'}
        triggerComponent={
          customTriggerComponent ||
          (() => (
            <TextFieldPlaceholder
              multiline
              placeholder={triggerComponent}
              variant={'outline'}
              size={'small'}
              error={error?.message}
              rightActions={[
                makeTextFieldError({
                  placement: error?.placement ?? 'right',
                  boundaryElement: error?.boundaryElement,
                  offset: error?.offset,
                  anchor: error?.anchor,
                }),
              ]}
              className={placeholderClassName}
            />
          ))
        }
        customComponent={customComponent}
        className={styles.dropdown}
        width={338}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                offset: ({ reference }) => {
                  return [Number(offset?.x || 0), -reference.height + Number(offset?.y || 0)]
                },
              },
            },
          ],
        }}
        placement={'bottom-start'}
        onToggle={onToggle}
        margin={0}
      />
    </div>
  )
}
