import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Box,
  type IDropdownItem,
  type ITooltipProps,
  type PhoneInputStore,
  PhoneWithLabelInput,
  TextFieldWithLabel,
} from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import styles from './styles.module.scss'

type ContactFormProps = {
  isSecond?: boolean
  contact: {
    firstName: string
    lastName: string
    businessTitle: string
    jobPosition: number | null
    email: string
  }
  jobPositionList: IDropdownItem[]
  phoneInputStore: PhoneInputStore
  emailErrorMessage?: string
  phoneErrorMessage?: string | null
  isErrorBorderEmail: boolean
  similarityDomainsWarning: string | null
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setBusinessTitle: (value: string) => void
  setJobPosition: (value: number) => void
  setEmail: (value: string) => void
  checkIsEmail: () => void
  setValueCallback: () => void
}

const phoneTooltip: ITooltipProps = {
  type: 'description',
  width: 290,
  label: "Your representative's mobile phone number",
  desc: 'May also be used for verification codes',
}

export const ContactForm: FC<ContactFormProps> = observer(
  ({
    isSecond,
    contact,
    jobPositionList,
    phoneInputStore,
    emailErrorMessage,
    phoneErrorMessage,
    isErrorBorderEmail,
    similarityDomainsWarning,
    setFirstName,
    setLastName,
    setBusinessTitle,
    setJobPosition,
    setEmail,
    checkIsEmail,
    setValueCallback,
  }) => (
    <>
      <Box display='flex' justifyContent='space-between' marginBottom={3} gap={'12px'}>
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'First name',
            },
          }}
          textFieldProps={{
            value: contact.firstName,
            onChange: setFirstName,
            InputProps: {
              placeholder: 'e.g. Adam',
            },
            className: styles.inputPadding,
          }}
        />
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'Last name',
            },
          }}
          textFieldProps={{
            value: contact.lastName,
            onChange: setLastName,
            InputProps: {
              placeholder: 'e.g. Johnson',
            },
            className: styles.inputPadding,
          }}
        />
      </Box>
      <Box display='flex' justifyContent='space-between' marginBottom={3} gap={'12px'}>
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'Business title',
            },
            questionTooltipProps: isSecond
              ? undefined
              : {
                  type: 'description',
                  width: 247,
                  label: 'We’re just making sure you’re in a position to make important decisions.',
                  desc: 'So, president, founder, executive, manager, or even boss are legit options.',
                },
          }}
          textFieldProps={{
            value: contact.businessTitle,
            onChange: setBusinessTitle,
            InputProps: {
              placeholder: 'e.g. Founder',
            },
            className: styles.inputPadding,
          }}
        />
        <SelectField
          boxWidth='100%'
          labelProps={{
            label: {
              text: 'Job position',
            },
          }}
          items={jobPositionList}
          value={contact.jobPosition}
          setValue={setJobPosition}
          btnProps={{
            size: 'large',
            style: {
              color: !contact.jobPosition
                ? 'var(--content-neutral-primary)'
                : 'var(--content-primary-primary)',
              height: '40px',
            },
            className: styles.selectPadding,
          }}
        />
      </Box>

      <Box marginBottom={3}>
        <TextFieldWithLabel
          errorBorder={isErrorBorderEmail}
          errorMessage={emailErrorMessage}
          warningText={similarityDomainsWarning || undefined}
          labelProps={{
            marginBottom: 4,
            label: {
              text: 'Business email',
            },
          }}
          textFieldProps={{
            value: contact.email,
            onChange: setEmail,
            onBlur: checkIsEmail,
            InputProps: {
              placeholder: 'e.g. adam.johnson@example.com',
            },
            className: styles.inputPadding,
          }}
        />
      </Box>
      <PhoneWithLabelInput
        label='Phone number'
        questionTooltipProps={isSecond ? undefined : phoneTooltip}
        item={phoneInputStore.country}
        items={phoneInputStore.countries}
        number={phoneInputStore.number}
        numberError={phoneInputStore.numberError || phoneErrorMessage || null}
        isValidNumber={phoneInputStore.isValidNumber}
        numberRequestError={phoneInputStore.numberRequestError}
        setNumber={(value) => {
          setValueCallback()
          phoneInputStore.setNumber(value)
        }}
        setCountry={phoneInputStore.setCountry}
        triggerNumberValidation={phoneInputStore.triggerNumberValidation}
      />
    </>
  )
)
