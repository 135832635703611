import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { type IResponseConversationUnread } from 'entities/Conversation/api/types'
import { type SidebarStore } from 'widgets/Sidebar/store/sidebarStore'

const EventConversationUnread = {
  subscribe: (handler: (data: IResponseConversationUnread) => void) =>
    websocket.on(WebsocketEvent.ConversationUnread, handler),
  unsubscribe: (handler: (data: IResponseConversationUnread) => void) =>
    websocket.off(WebsocketEvent.ConversationUnread, handler),
}

export const useEventConversationUnread = (sidebarStore: SidebarStore) => {
  const handler = (data: IResponseConversationUnread) => {
    const { inboxesMap } = sidebarStore
    const inbox = inboxesMap.get(data.inbox_id)

    if (inbox) {
      runInAction(() => {
        inbox.unread = data.unread
        inbox.unread_messages_count = data.unread_messages_count
      })
    }
  }

  useEffect(() => {
    EventConversationUnread.subscribe(handler)

    return () => {
      EventConversationUnread.unsubscribe(handler)
    }
  }, [])
}
