import { useState, useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { TrialBroadcastLimitAlertStore } from 'entities/Subscription/ui/TrialBroadcastLimitAlert/store/trialBroadcastLimitAlertStore'

type ITrialBroadcastLimitAlertProps = {
  onCloseModal?: () => void
}

export const TrialBroadcastLimitAlert: FC<ITrialBroadcastLimitAlertProps> = observer(
  ({ onCloseModal }) => {
    const [store] = useState(
      () =>
        new TrialBroadcastLimitAlertStore({
          onCloseModal: onCloseModal,
        })
    )

    useEffect(() => {
      store.init()
    }, [])

    if (store.hide) return null

    return <Alert {...store.configAlert} />
  }
)
