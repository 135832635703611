import { observer } from 'mobx-react-lite'
import { layoutStore } from 'shared/layout'
import { authStore } from 'entities/Auth'
import { TrialSubscriptionBanner } from 'entities/Subscription/ui/TrialSubscriptionBanner/ui/TrialSubscriptionBanner'

export const GlobalBanners = observer(() => {
  if (layoutStore.isMobileView || !authStore.isLoggedIn) return null

  return (
    <>
      <TrialSubscriptionBanner />
    </>
  )
})
