import classNames from 'classnames'
import { CSSProperties, ChangeEvent, useMemo } from 'react'
import { Icon } from 'shared/ui/Icon'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type ISizes = 16 | 20 | 32

type ICheckboxProp = {
  intent?: 'none' | 'passive'
  size?: ISizes
  isIndeterminate?: boolean
  onChange?: (value: boolean, event: ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disabled?: boolean
  style?: CSSProperties
  ariaLabel?: string
}

export const Checkbox = ({
  size = 20,
  intent = 'none',
  isIndeterminate = false,
  onChange,
  checked,
  style,
  disabled,
  ariaLabel,
}: ICheckboxProp) => {
  const content = useMemo(() => {
    if (isIndeterminate) {
      return (
        <div className={styles.fill}>
          <Icon icon={'checkbox_mixed'} fontSize={size === 16 ? 14 : 16} />
        </div>
      )
    }
    if (checked) {
      return (
        <div className={styles.fill}>
          <Icon icon={'checkbox_checked'} fontSize={size === 16 ? 14 : 16} />
        </div>
      )
    }
    return <div className={styles.empty} />
  }, [isIndeterminate, checked])

  return (
    <span
      className={classNames(styles.wrap, {
        [styles.checked]: checked || isIndeterminate,
        [styles.disabled]: disabled,
        [styles[`intent__${intent}`]]: intent,
        [styles[`size__${size}`]]: size,
      })}
      style={style}
      aria-label={getAriaLabel('Checkbox', ariaLabel)}
    >
      <input
        type='checkbox'
        checked={checked}
        className={styles.input}
        disabled={disabled}
        onChange={(event) => onChange?.(event.target.checked, event)}
      />
      {content}
    </span>
  )
}
