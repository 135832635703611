import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { KnowledgeBaseViewPage } from 'pages/settings/pages/knowledgeBase/pages'
import { KnowledgeBaseAccessLayout } from 'pages/settings/pages/knowledgeBase/layout/KnowledgeBaseAccessLayout'

export const KnowledgeBaseRouter = observer(() => {
  return (
    <Routes>
      <Route element={<KnowledgeBaseAccessLayout />}>
        <Route path={'/'} element={<KnowledgeBaseViewPage />} />
      </Route>
    </Routes>
  )
})
