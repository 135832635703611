import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { usersStore } from 'entities/Users'

const AuthLayout = observer(() => {
  const { role } = usersStore.user || {}

  if (!role) return null

  return <Outlet />
})

export default AuthLayout
