import { observer } from 'mobx-react-lite'
import { useMemo, FC } from 'react'
import { TagsSelect } from 'entities/Tags'
import { type ContactsTagsModalStore } from 'widgets/ContactsTagsModal'

import styles from './styles.module.scss'

type IContactsTagsDropdownProps = {
  contactsTagsModalStore: ContactsTagsModalStore
}

export const ContactsTagsDropdown: FC<IContactsTagsDropdownProps> = observer(
  ({ contactsTagsModalStore }) => {
    const { mode, control } = contactsTagsModalStore

    const content = useMemo(() => {
      switch (mode) {
        case 'add':
          return <TagsSelect canCreate control={control} placeholder='Search or create' />
        case 'remove':
          return <TagsSelect control={control} placeholder='Search' />
        default:
          return null
      }
    }, [mode])

    return <div className={styles.dropdownContainer}>{content}</div>
  }
)
