import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, ContainerActions, Dropdown } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { type ContactsTableStore } from 'widgets/ContactsTable'
import { contactsHistoryStore } from 'widgets/ContactsHistory/store/ContactsHistoryStore'
import { ContactsFiltersHeader } from 'widgets/ContactsMenu/ui/ContactsFiltersHeader/ContactsFiltersHeader'
import styles from './styles.module.scss'

type IContactsContainerActionsProps = {
  contactsTableStore: ContactsTableStore
}

export const ContactsContainerActions: FC<IContactsContainerActionsProps> = observer(
  ({ contactsTableStore }) => {
    const { addNewContactManually, contactsMenuStore } = contactsTableStore
    const { checkIsImportDisabled } = importContactsStore
    const { totalHistory } = contactsHistoryStore
    const { handleChangeCollapse } = contactsDetailsGlobalStore

    const createDisabled = inboxesStore.isUnifiedInboxOnly
    const importAvailable = !usersStore.user?.isMemberRole && !usersStore.user?.isViewOnlyRole

    const onImport = () => {
      importContactsStore.openModal()
    }

    const checkImportDisabling = () => {
      return checkIsImportDisabled(totalHistory)
    }

    const addNewContact = () => {
      addNewContactManually()
      handleChangeCollapse(false)
    }

    useEffect(() => {
      return () => {
        contactsTableStore.resetSearch()
      }
    }, [])

    return (
      <div className={styles.wrapper}>
        <ContainerActions
          padding='0'
          leftActions={[
            {
              iconButtonComponent: contactsMenuStore ? (
                <ContactsFiltersHeader contactsMenuStore={contactsMenuStore} />
              ) : null,
            },
          ]}
          rightActions={[
            {
              iconButtonComponent: (
                <div className={styles.leftActions}>
                  {importAvailable && (
                    <Button
                      text='Import'
                      icon='upload'
                      disabled={checkImportDisabling()}
                      contained='tertiary'
                      onClick={onImport}
                    />
                  )}
                  <Button
                    text='Create'
                    disabled={createDisabled}
                    icon='add'
                    contained='tertiary'
                    onClick={addNewContact}
                  />
                  <Dropdown
                    ariaLabel={'ContactsContainerActions'}
                    width={165}
                    icon={'moreHorizontal'}
                    placement={'bottom-start'}
                    tooltipProps={{
                      label: 'Show actions',
                      placement: 'top',
                      disableInteractive: true,
                    }}
                    iconButtonProps={{
                      size: 'medium',
                      ariaLabel: 'ContactsContainerActions_trigger',
                    }}
                    items={[
                      {
                        id: 1,
                        label: 'Import history',
                        iconL: 'clockRecent',
                        ariaLabel: 'DropdownList_ImportHistory',
                        onClick: () => {
                          uiStore.changeRoute({
                            path: 'contacts/history',
                          })
                        },
                      },
                      {
                        id: 2,
                        label: 'Contact cleanup',
                        iconL: 'arrowLinesHorizontal',
                        ariaLabel: 'DropdownList_ContactCleanup',
                        onClick: () => {
                          uiStore.changeRoute({
                            path: 'contacts/cleanup',
                          })
                        },
                      },
                    ]}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    )
  }
)
