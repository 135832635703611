import { FC, useMemo, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { IconButton, SpinnerLoader, Typography } from 'shared/ui'
import { MoreDropdownProps } from 'shared/ui/Actions/Actions'
import { uiStore } from 'shared/store/uiStore'
import {
  TextField,
  makeTextFieldAutoFocus,
  makeTextFieldClear,
  makeTextFieldElement,
} from 'shared/ui/TextField'
import { Integration } from 'entities/Integrations/model/Integration'
import { MergeFieldContentItem } from 'widgets/MergeField/ui/MergeFieldContentItem'
import { MergeFieldProps, MergeFieldsStore } from 'widgets/MergeField/store/mergeFieldsStore'
import { DropdownIntegrationActionItem } from 'widgets/DropdownIntegrationActionItem'
import styles from './styles.module.scss'

export const MergeFieldContent: FC<MoreDropdownProps & MergeFieldProps> = observer((props) => {
  const { contact, onAddMergeField, integration, onCloseMenu, onClose } = props

  const [mergeFieldStore] = useState(
    () =>
      new MergeFieldsStore({
        contact,
        onAddMergeField,
        integration,
        isAllIntegration: props.isAllIntegration,
        isAllConnectedOrDisconnectedIntegration: props.isAllConnectedOrDisconnectedIntegration,
        isSelectDefault: props.isSelectDefault,
        useAllMergeFields: props.useAllMergeFields,
      })
  )

  const isAllIntegration = mergeFieldStore.isAllIntegration

  const {
    setSearch,
    loading,
    search,
    filteredMergeFields,
    onSelectedIntegration,
    onSelectedDefault,
    selectedIntegration,
    isSelectDefault,
    isSelectedIntegration,
    onBack,
    allIntegrations,
  } = mergeFieldStore

  const handleSearch = async (search: string) => {
    await setSearch(search)
  }

  const handleSelectedIntegration = (integration: Integration) => {
    if (integration.connected) {
      onSelectedIntegration(integration)
    } else {
      onCloseMenu?.()
      onClose?.()
      uiStore.changeRoute({
        path: '/settings/integrations',
      })
    }
  }

  const content = useMemo(() => {
    if (loading) {
      return (
        <div
          className={classNames(styles.loading, {
            [styles.withIntegration]:
              integration && !search && !isSelectedIntegration && !isSelectDefault,
          })}
        >
          <SpinnerLoader size={16} />
        </div>
      )
    }
    if (
      (!integration && !isAllIntegration) ||
      ((integration || isAllIntegration) && (isSelectDefault || selectedIntegration)) ||
      !allIntegrations.length ||
      search
    ) {
      return (
        <div className={styles.list}>
          {!filteredMergeFields.length && (
            <div className={styles.wrapEmpty}>
              <Typography
                variant={'body-sm-regular'}
                color={'var(--content-primary-tertiary)'}
                ariaLabel={'No results found'}
              >
                No results found
              </Typography>
            </div>
          )}
          {filteredMergeFields.map((field) => (
            <MergeFieldContentItem
              {...props}
              field={field}
              key={field.id}
              mergeFieldStore={mergeFieldStore}
              onCloseMenu={onCloseMenu}
            />
          ))}
        </div>
      )
    }

    if (integration) {
      return (
        <DropdownIntegrationActionItem
          nameDefault={'Salesmsg'}
          iconDefault={'salesmsg'}
          integration={integration}
          ariaLabel={'MergeField'}
          onClickDefault={() => onSelectedDefault(true)}
          onClickIntegrationBtn={(integration) => handleSelectedIntegration(integration)}
        />
      )
    }
    if (isAllIntegration) {
      return (
        <DropdownIntegrationActionItem
          nameDefault={'Salesmsg'}
          iconDefault={'salesmsg'}
          showAllIntegrations
          integrations={allIntegrations}
          ariaLabel={'MergeField'}
          onClickDefault={() => onSelectedDefault(true)}
          onClickIntegrationBtn={(integration) => handleSelectedIntegration(integration)}
        />
      )
    }
    return <></>
  }, [
    loading,
    integration,
    isSelectDefault,
    selectedIntegration,
    filteredMergeFields,
    search,
    allIntegrations,
  ])

  return (
    <div className={classNames(styles.wrap, 'top-dropdown-column-reverse-content')}>
      <div className={styles.header}>
        <TextField
          className={styles.textInput}
          size='small'
          variant='integrated'
          ariaLabel='MergeFieldSearch'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={handleSearch}
          mainActions={[makeTextFieldAutoFocus({ withFocus: !loading })]}
          leftActions={[
            makeTextFieldElement({
              element: isSelectedIntegration && (
                <IconButton
                  tag='div'
                  icon={'chevronLeft'}
                  size={'small'}
                  variant={'icon'}
                  color={'secondary'}
                  fontSize={15}
                  onClick={onBack}
                  ariaLabel='MergeFieldContent_back'
                />
              ),
            }),
          ]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>{content}</div>
    </div>
  )
})
