import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { organizationStore } from 'entities/Organization/store/organization'
import type { IResponseEventUpdateBalance } from 'entities/Organization/api/types'

const UpdateBalance = (data: IResponseEventUpdateBalance) => {
  organizationStore.updateBalance(data)
}

export const useEventUpdateBalance = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.AccountBalanceUpdated, UpdateBalance)
    }
  }, [websocket.isConnected])
}
