import { useState, useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, Tooltip } from 'shared/ui'
import { TrialContactsLimitTooltipStore } from 'entities/Subscription/ui/TrialContactsLimitTooltip/store/trialContactsLimitTooltipStore'

type ITrialTooltipContactsProps = {
  show: boolean
}

export const TrialContactsLimitTooltip: FC<ITrialTooltipContactsProps> = observer(({ show }) => {
  const [store] = useState(() => new TrialContactsLimitTooltipStore())

  useEffect(() => {
    store.init()
  }, [])

  if (!show) return null
  if (store.hide) return null

  return (
    <Tooltip {...store.configTooltip}>
      <Avatar
        info={{
          icon: 'lock',
        }}
        size={24}
      />
    </Tooltip>
  )
})
