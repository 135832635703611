// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RbKRqhAIgcS0xmxePMF4{border-bottom:1px solid var(--border-neutral-default);padding:6px 4px}.RbKRqhAIgcS0xmxePMF4:last-of-type{border-bottom:none}.MwdpOYuSliRlNjpxyc_F{padding:0}.Jb3dhLndv4a6GwvVxUuc{padding:8px}.gXV2NS6HP5wZrhBDzEwY{color:var(--light-blue-60)}.TkRSM53JXFjA1HrdcptR{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/shared/ui/Dropdown/ui/DropdownGroup/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qDAAA,CACA,eAAA,CAEA,mCACE,kBAAA,CAIJ,sBACE,SAAA,CAGF,sBACE,WAAA,CAGF,sBACE,0BAAA,CAGF,sBACE,YAAA,CACA,6BAAA","sourcesContent":[".wrap {\n  border-bottom: 1px solid var(--border-neutral-default);\n  padding: 6px 4px;\n\n  &:last-of-type {\n    border-bottom: none;\n  }\n}\n\n.multiSelectWrap {\n  padding: 0;\n}\n\n.title {\n  padding: 8px;\n}\n\n.selectAllButton {\n  color: var(--light-blue-60);\n}\n\n.titleWrapper {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "RbKRqhAIgcS0xmxePMF4",
	"multiSelectWrap": "MwdpOYuSliRlNjpxyc_F",
	"title": "Jb3dhLndv4a6GwvVxUuc",
	"selectAllButton": "gXV2NS6HP5wZrhBDzEwY",
	"titleWrapper": "TkRSM53JXFjA1HrdcptR"
};
export default ___CSS_LOADER_EXPORT___;
