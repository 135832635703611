import { AxiosResponse } from 'axios'
import { IAPiOption } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IFormFieldResponse,
  IParamsOptInTypes,
  IProviderResponse,
  IStateResponse,
} from 'entities/Compliance/api/type'

class Api {
  getOptInTypes({ filter }: IParamsOptInTypes): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/opt-in-data/types', {
      params: {
        filter,
      },
    })
  }
  getBusinessProfileStates(): Promise<AxiosResponse<IStateResponse[]>> {
    return baseApi.get('organization/business-profile/states')
  }
  getBusinessProfileBusinessTypes(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/business-types')
  }
  getBusinessProfileJobPositions(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/job-positions')
  }
  getBusinessProfileIndustries(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/industries')
  }
  getBusinessProfileRegionsOfOperation(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/regions-of-operation')
  }
  getBusinessProfileBusinessRegistrationTypes(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/business-registration-types')
  }
  getBusinessProfileStockExchanges(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/stock-exchanges')
  }
  getProviders(): Promise<AxiosResponse<Array<IProviderResponse>>> {
    return baseApi.get('compliance/providers/list ')
  }
  getTollFreeFormFields(): Promise<AxiosResponse<IFormFieldResponse>> {
    return baseApi.get('organization/toll-free/form-fields')
  }
}

export const ComplianceApi = new Api()
