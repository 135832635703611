import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { useParams } from 'react-router'
import { Space, ContainerHeader } from 'shared/ui'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context/contactsDetailsContext'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { ContactsContainerHeader } from 'widgets/ContactsDetails/ui/ContactsContainerHeader/ContactsContainerHeader'
import { ContactsProfileCard } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import { ContactsGroupCard } from 'widgets/ContactsDetails/ui/ContactsGroupCard/ContactsGroupCard'
import { ContactsNotesForm } from 'widgets/ContactsDetails/ui/ContactsNotesForm'
import { ContactsIntegrations } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ContactsIntegrations'
import { ContactsConversationsHistory } from 'widgets/ContactsDetails/ui/ContactsConversationsHistory/ContactsConversationsHistory'
import { ContactsConversationOpen } from 'widgets/ContactsDetails/ui/ContactsConversationOpen'
import { ContactsContentItems } from 'widgets/ContactsDetails/ui/ContactsContentItems/ContactsContentItems'
import styles from './styles.module.scss'
import { ContactsQuickLinksSearch } from './ContactsQuickLinks'

export const ContactsDetailsContent = observer(() => {
  const { conversationId } = useParams()
  const contactsDetailsStore = useContactsDetailsContext()
  const {
    isConversationGroup,
    isViewAll,
    customTitleIcon,
    viewAllCount,
    onStopCreateNewContact,
    onCloseContactDetails,
    selectedContact,
    isViewTypeNotes,
    isViewTypeConversationsHistory,
    isViewTypeQuickLinks,
    contactNoteStore,
    setConversationId,
    isVariantCreateContactModal,
    contactCreateModalStore,
    contactInfoFormStore,
    contactsProfileCardStore,
  } = contactsDetailsStore
  const { isCollapse, isShowContactDetailsOverlay, handleToggleCollapse } =
    contactsDetailsGlobalStore

  const { reset } = contactInfoFormStore

  useEffect(() => {
    return () => {
      setConversationId(conversationId)
    }
  }, [conversationId])

  useEffect(() => {
    contactsDetailsStore.load()

    return () => {
      reset()
    }
  }, [])

  const contact = selectedContact

  const handleBackNote = () => {
    contactNoteStore.messageFieldStore.clear()
  }

  const createNewContactMode = contact?.id === -1

  const renderHeader = () => {
    if (createNewContactMode && !isVariantCreateContactModal) {
      return (
        <ContainerHeader
          title='Create'
          isBorderBottom
          paddingLeft={20}
          actions={[
            {
              icon: 'close',
              text: 'Cancel',
              onAction: () => {
                onStopCreateNewContact?.()
                handleToggleCollapse()
              },
            },
          ]}
        />
      )
    }

    if (isViewTypeNotes) {
      return <ContactsContainerHeader backCallback={handleBackNote} />
    }

    if (contact?.id === 0 || isViewTypeConversationsHistory) {
      return <ContactsContainerHeader />
    }

    if (isViewAll) {
      return (
        <ContactsContainerHeader
          titleCount={viewAllCount}
          customTitleIcon={customTitleIcon}
          showCount
        />
      )
    }

    if (isVariantCreateContactModal) {
      return null
    }

    return (
      <ContactsContainerHeader
        backCallback={() => {
          onCloseContactDetails?.()
        }}
      />
    )
  }
  const renderContent = () => {
    if (contact?.id === 0) {
      return (
        <>
          <ContactsProfileCard store={contactsProfileCardStore} />
          {isConversationGroup && <ContactsGroupCard />}
          <Space height={60} />
        </>
      )
    }

    if (isViewTypeNotes) {
      return (
        <>
          <ContactsNotesForm fullView />
          <Space height={60} />
        </>
      )
    }

    if (isViewAll) {
      return (
        <>
          <ContactsIntegrations />
          <Space height={60} />
        </>
      )
    }

    if (isViewTypeQuickLinks) {
      return <ContactsQuickLinksSearch />
    }

    if (isViewTypeConversationsHistory) {
      return <ContactsConversationsHistory />
    }

    return (
      <div className={styles.infoWrapper}>
        <ContactsProfileCard store={contactsProfileCardStore} />
        <ContactsContentItems />
        {!isVariantCreateContactModal && <Space height={60} />}
      </div>
    )
  }

  const renderLeftContent = () => {
    if (contactsDetailsStore.isVariantOpenConversations) {
      return <ContactsConversationOpen store={contactsDetailsStore.contactsConversationOpenStore} />
    }

    return null
  }
  const isMobileOpened = isShowContactDetailsOverlay && !isVariantCreateContactModal
  const isCollapseOpened = isCollapse && !isVariantCreateContactModal

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.isCollapse]: isCollapseOpened,
        [styles.isMobileOpened]: isMobileOpened,
        [styles.isConversationsHistoryView]: isViewTypeConversationsHistory,
        [styles.modalView]: isVariantCreateContactModal,
        [styles.hideModalViewBorderTop]: contactCreateModalStore?.hasAlert,
      })}
    >
      <div className={styles.header}>{renderHeader()}</div>
      <div className={styles.content}>{renderContent()}</div>
      <div className={styles.leftContent}>{renderLeftContent()}</div>
    </div>
  )
})
