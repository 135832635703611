// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".khEVuWeoglEouISQwNGW{padding:0 8px 8px}.UlS4Py7gRcYyxsMu6PPt{width:100%;border-radius:6px;padding:16px;background:var(--background-primary-inverted-primary);box-shadow:0 4px 12px -2px rgba(18,19,23,.03)}.x8kwTwpoko1eQBcJW_jJ{color:var(--content-primary-primary);padding-bottom:12px}.dn8z2Gv6XcNcyVaH6Sa3{padding:4px 0 16px}.VLZE3M53X4oqYb6icZsV{height:4px;border-radius:4px;background-color:var(--black-20)}.VLZE3M53X4oqYb6icZsV>div{border-radius:4px}.VLZE3M53X4oqYb6icZsV.mSfeQhkwgCKDzCyQPruk>div{background-color:var(--light-blue-60)}.VLZE3M53X4oqYb6icZsV.OcetJ16zWGfZ4QMUcx4h>div{background-color:var(--yellow-60)}.VLZE3M53X4oqYb6icZsV.iSbj2JaSAJJchp1RzINQ>div{background-color:var(--red-60)}", "",{"version":3,"sources":["webpack://./src/entities/Subscription/ui/TrialProgress/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,UAAA,CACA,iBAAA,CACA,YAAA,CACA,qDAAA,CACA,6CAAA,CAGF,sBACE,oCAAA,CACA,mBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,UAAA,CACA,iBAAA,CACA,gCAAA,CAEA,0BACE,iBAAA,CAGF,+CACE,qCAAA,CAGF,+CACE,iCAAA,CAGF,+CACE,8BAAA","sourcesContent":[".wrap {\n  padding: 0 8px 8px;\n}\n\n.content {\n  width: 100%;\n  border-radius: 6px;\n  padding: 16px;\n  background: var(--background-primary-inverted-primary);\n  box-shadow: 0 4px 12px -2px rgba(18, 19, 23, 0.03);\n}\n\n.title {\n  color: var(--content-primary-primary);\n  padding-bottom: 12px;\n}\n\n.progressWrap {\n  padding: 4px 0 16px;\n}\n\n.progress {\n  height: 4px;\n  border-radius: 4px;\n  background-color: var(--black-20);\n\n  & > div {\n    border-radius: 4px;\n  }\n\n  &.default > div {\n    background-color: var(--light-blue-60);\n  }\n\n  &.yellow > div {\n    background-color: var(--yellow-60);\n  }\n\n  &.red > div {\n    background-color: var(--red-60);\n  }\n}\n\n.button {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "khEVuWeoglEouISQwNGW",
	"content": "UlS4Py7gRcYyxsMu6PPt",
	"title": "x8kwTwpoko1eQBcJW_jJ",
	"progressWrap": "dn8z2Gv6XcNcyVaH6Sa3",
	"progress": "VLZE3M53X4oqYb6icZsV",
	"default": "mSfeQhkwgCKDzCyQPruk",
	"yellow": "OcetJ16zWGfZ4QMUcx4h",
	"red": "iSbj2JaSAJJchp1RzINQ"
};
export default ___CSS_LOADER_EXPORT___;
