import { observer } from 'mobx-react-lite'
import { EnrolmentStep } from 'widgets/ContactEnrollment'
import { CampaignEnrollContactsStore } from './store/campaignEnrollContactsStore'

type ICampaignEnrollContactsProps = {
  store: CampaignEnrollContactsStore
}

export const CampaignEnrollContacts = observer(({ store }: ICampaignEnrollContactsProps) => {
  const { editEnrollment, loading, error, setError, enrollmentFilters } = store

  const handleClick = () => {
    if (loading) return
    setError('')
    editEnrollment()
  }

  return (
    <EnrolmentStep
      onClick={handleClick}
      error={error}
      enrollmentFilters={enrollmentFilters}
      errorPlacement={'right'}
      tooltipLabel={[
        'Select the conditions to enroll contacts',
        <br key={'br'} />,
        'in your campaign',
      ]}
    />
  )
})
