import { Box } from '@mui/material'
import { Avatar, IAvatarInfo, Typography } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'

export const getCellForNameDefault = ({
  avatarInfo,
  fullName,
}: {
  avatarInfo: IAvatarInfo
  fullName?: string
}) => {
  return (
    <Box display='flex' justifyContent='space-between'>
      <Box
        sx={{
          alignItems: 'center',
          width: '100%',
          height: '32px',
        }}
        gap={1}
        display='flex'
      >
        <Avatar info={avatarInfo} size={24} />

        {fullName ? (
          <Typography ellipsis variant={'body-md-regular'} tag={'div'} ariaLabel={'formattedValue'}>
            {fullName}
          </Typography>
        ) : (
          <EmptyCell />
        )}
      </Box>
    </Box>
  )
}
