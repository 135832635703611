import type { IResponseIntegrationWebhook } from 'entities/Webhook'
import { Integration } from 'entities/Integrations/model/Integration'

export class Webhook {
  id: number
  organization_id: number
  integration_id: number
  user_integration_id: number
  credential_id: null
  webhookable_type: string
  webhookable_id: number
  token: string
  created_at: string
  updated_at: string
  url: string
  integration: Integration | null = null

  constructor(item: IResponseIntegrationWebhook) {
    this.id = item.id
    this.organization_id = item.organization_id
    this.integration_id = item.integration_id
    this.user_integration_id = item.user_integration_id
    this.credential_id = item.credential_id
    this.webhookable_type = item.webhookable_type
    this.webhookable_id = item.webhookable_id
    this.token = item.token
    this.created_at = item.created_at
    this.updated_at = item.updated_at
    this.organization_id = item.organization_id
    this.url = item.url

    if (item.integration) {
      this.integration = new Integration(item.integration)
    }
  }
}
