import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, CopyWrapper } from 'shared/ui'
import { IResponseIntegrationHubspotCompany } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/AllView/styles.module.scss'
import { ItemCompaniesRow } from 'widgets/ContactsDetails/ui/ContactsIntegrations/AllView/AllCompanies/ItemCompaniesRow'
type ICompanyProps = {
  company: IResponseIntegrationHubspotCompany
  link: string
  contact: Contact
}

const Company = observer(({ company, link, contact }: ICompanyProps) => {
  const [open, setOpen] = useState(false)
  const hasShowMoreButton =
    Object.keys(company).filter((key) => key !== 'name' && key !== 'id').length > 5

  return (
    <div className={styles.item}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={company?.name?.value || null}
          data={{
            title: company?.name?.value || 'View company',
            link: link,
          }}
        />
      </div>
      <ItemCompaniesRow company={company} fullList={open} />
      <div className={styles.actions}>
        {hasShowMoreButton && (
          <div className={styles.action}>
            <Button
              size='small'
              typeBtn='text'
              disabled={!contact}
              text={open ? 'Hide properties' : 'See properties'}
              onClick={() => {
                setOpen(!open)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
})
export { Company }
