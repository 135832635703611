import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { AttachmentCard } from 'entities/Attachment'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { MessageCardType, MessageProps } from 'widgets/MessageCard/ui/types'
import { ConversationMessageCardItem } from 'widgets/MessageCard/ui/ConversationMessageCardItem/ConversationMessageCardItem'
import { DateContent } from 'widgets/MessageCard/ui/DateContent/DateContent'
import styles from './styles.module.scss'

export const OtherAttachmentContentContent = observer((props: MessageProps) => {
  const { otherAttachments, isConversationsHistory } = props.message

  const onOpenPresentationOtherAttachments = (index: number) => () => {
    presentationStore.setAttachments(otherAttachments, index)
  }

  if (!otherAttachments.length) {
    return <></>
  }

  return (
    <>
      {otherAttachments.map((attachment) => (
        <ConversationMessageCardItem
          key={attachment.id}
          message={props.message}
          type={MessageCardType.OtherAttachment}
        >
          <div className={classNames(styles.wrap)}>
            <AttachmentCard
              key={attachment.id}
              attachment={attachment}
              medium
              onCardClick={onOpenPresentationOtherAttachments(0)}
              isConversationsHistoryView={isConversationsHistory}
              onLoad={props.onLoad}
            />
          </div>
          <DateContent {...props} />
        </ConversationMessageCardItem>
      ))}
    </>
  )
})
