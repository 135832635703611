import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'

export const CampaignsHelpGuideButton = () => (
  <Button
    tag='a'
    text='Help guide'
    size='medium'
    contained='secondary'
    href={links.campaignsHelpGuide}
    target='_blank'
    rel='noreferrer'
    style={{ textDecoration: 'none' }}
    iconProps={{ icon: 'info' }}
  />
)
