import { makeAutoObservable, runInAction } from 'mobx'
import type { IToastType } from 'shared/ui'
import { errorHandler } from 'shared/api'
import { ContactIntegrationHubspotInfo } from 'entities/Integrations/model/ContactIntegrationHubspotInfo'
import { ContactIntegrationActiveCampaignInfo } from 'entities/Integrations/model/ContactIntegrationActiveCampaignInfo'
import { ContactIntegrationPipedriveInfo } from 'entities/Integrations/model/ContactIntegrationPipedriveInfo'
import { ContactIntegrationSalesforceInfo } from 'entities/Integrations/model/ContactIntegrationSalesforceInfo'
import { ContactIntegrationInfusionsoftInfo } from 'entities/Integrations/model/ContactIntegrationInfusionsoftInfo'
import {
  IDeal,
  IHubspotPropertiesObjectType,
  IIntegrationHubspotPropertyFormatted,
  type IIntegrationTagCreateDTO,
  IntegrationKey,
  IntegrationsApi,
  type IResponseErrorIntegrationActivecampaignTagsApply,
  type IResponseErrorIntegrationInfusionsoftTagsApply,
  IResponseIntegrationHubspotCompany,
  IResponseIntegrationHubspotData,
  IResponseIntegrationHubspotProperties,
  IResponseIntegrationHubspotTicket,
  type IResponseIntegrationsContactsActiveCampaignData,
} from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'

export class ContactIntegrationsStore {
  loadingIds: Map<number, number> = new Map()
  loading = false
  unlinking = false

  hubspotInfoMap: Map<number, ContactIntegrationHubspotInfo> = new Map()
  activeCampaignInfoMap: Map<number, ContactIntegrationActiveCampaignInfo> = new Map()
  pipedriveInfoMap: Map<number, ContactIntegrationPipedriveInfo> = new Map()
  salesforceInfoMap: Map<number, ContactIntegrationSalesforceInfo> = new Map()
  infusionsoftInfoMap: Map<number, ContactIntegrationInfusionsoftInfo> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  private _deleteContactIntegrationInfo = (id: number, key?: IntegrationKey | string) => {
    if (!key) return

    switch (key) {
      case IntegrationKey.hubspot: {
        this.hubspotInfoMap.delete(id)
        return
      }
      case IntegrationKey.salesforce: {
        this.salesforceInfoMap.delete(id)
        return
      }
      case IntegrationKey.activecampaign: {
        this.activeCampaignInfoMap.delete(id)
        return
      }
      case IntegrationKey.pipedrive: {
        this.pipedriveInfoMap.delete(id)
        return
      }
      case IntegrationKey.infusionsoft: {
        this.infusionsoftInfoMap.delete(id)
        return
      }
    }
  }

  unlinkContactsIntegrations = async (id: number, key?: IntegrationKey | string) => {
    try {
      this.unlinking = true

      const { data } = await IntegrationsApi.unlinkContactsByIdIntegrations(id)
      this._deleteContactIntegrationInfo(id, key)
      contactsStore.updateItem(data)
    } catch (e) {
      console.log('ERROR: unlinkContactsIntegrations: ', e)
    } finally {
      this.unlinking = false
    }
  }

  createContactsIntegrationsActiveCampaignTag = async (reqData: IIntegrationTagCreateDTO) => {
    const message: {
      message: string
      status: IToastType
    } = {
      message: '',
      status: 'success',
    }

    try {
      const { data } = await IntegrationsApi.createContactsIntegrationsActiveCampaignTag(reqData)

      data.message = data?.message || ''

      return message
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<IResponseErrorIntegrationActivecampaignTagsApply>(
        err
      )

      message.message = type === 'axios-error' ? error.response?.data.tag[0] || '' : ''
      message.status = 'error'

      return message
    }
  }

  createContactsIntegrationInfusionsoftTag = async (reqData: IIntegrationTagCreateDTO) => {
    try {
      const res = await IntegrationsApi.createContactsIntegrationsInfusionsoftTag(reqData)

      const data: {
        message: string
        status: IToastType
      } = {
        message: res?.data?.message || '',
        status: 'success',
      }

      return data
    } catch (e) {
      console.log('ERROR: createContactsIntegrationInfusionsoftTag: ', e)

      const err = e as Error
      const { type, error } = await errorHandler<IResponseErrorIntegrationInfusionsoftTagsApply>(
        err
      )

      const data: {
        message: string
        status: IToastType
      } = {
        message: '',
        status: 'error',
      }

      if (type === 'axios-error') {
        data.message = error?.response?.data?.tag?.[0] || ''
      }

      return data
    }
  }

  getContactsIntegrationInfusionsoftTags = async (value: string) => {
    try {
      const res = await IntegrationsApi.getContactsIntegrationsInfusionsoftTags(
        encodeURIComponent(value)
      )

      return res.data
    } catch (e) {
      console.log('ERROR: getContactsIntegrationInfusionsoftTags: ', e)
      return []
    }
  }

  initContactIntegrationHubspotInfo = async (id: number, use_contact_card_properties?: boolean) => {
    if (this.loadingIds.has(id)) return

    const emptyData: IResponseIntegrationHubspotData = {
      deals: [],
      properties: {},
      companies: [],
      contactId: id,
      tickets: [],
    }

    try {
      runInAction(() => {
        this.loading = true
        this.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsHubspotInfo({
        id,
        use_contact_card_properties,
      })

      if (!data) {
        const info = new ContactIntegrationHubspotInfo({ ...emptyData, contactId: id })

        runInAction(() => {
          this.hubspotInfoMap.set(id, info)
        })

        return
      }

      const info = new ContactIntegrationHubspotInfo({ ...data.data, contactId: id })

      runInAction(() => {
        this.hubspotInfoMap.set(id, info)
      })
    } catch (e) {
      console.log('ERROR: initContactIntegrationHubspotInfo: ', e)

      const info = new ContactIntegrationHubspotInfo({ ...emptyData, contactId: id })

      runInAction(() => {
        this.hubspotInfoMap.set(id, info)
      })
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationHubspotInfoByType = async (
    id: number,
    objectType: IHubspotPropertiesObjectType
  ) => {
    if (this.loadingIds.has(id)) return

    const emptyData: IResponseIntegrationHubspotData = {
      deals: [],
      properties: {},
      companies: [],
      contactId: id,
      tickets: [],
    }

    try {
      runInAction(() => {
        this.loading = true
        this.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsHubspotInfoByType({
        id,
        objectType: objectType,
      })

      const info = this.hubspotInfoMap.get(id)

      if (info) {
        const properties: IIntegrationHubspotPropertyFormatted[] =
          objectType === 'contact'
            ? Object.keys(data as IResponseIntegrationHubspotProperties).map((key) => {
                const current = data as IResponseIntegrationHubspotProperties
                return { ...current?.[key], key }
              })
            : info.properties

        const dataForUpdate = {
          deals: objectType === 'deal' ? (data as IDeal[]) : info.deals,
          properties: properties,
          companies:
            objectType === 'company'
              ? (data as IResponseIntegrationHubspotCompany[])
              : info.companies,
          contactId: id,
          tickets:
            objectType === 'ticket' ? (data as IResponseIntegrationHubspotTicket[]) : info.tickets,
        }

        const currentInfo = info.updateInfoItem(dataForUpdate, objectType)

        runInAction(() => {
          this.hubspotInfoMap.set(id, currentInfo)
        })
      } else {
        const properties =
          objectType === 'contact'
            ? (data as IResponseIntegrationHubspotProperties)
            : emptyData.properties

        const currentData = {
          deals: objectType === 'deal' ? (data as IDeal[]) : emptyData.deals,
          properties: properties,
          companies:
            objectType === 'company'
              ? (data as IResponseIntegrationHubspotCompany[])
              : emptyData.companies,
          contactId: id,
          tickets:
            objectType === 'ticket'
              ? (data as IResponseIntegrationHubspotTicket[])
              : emptyData.tickets,
        }

        const info = new ContactIntegrationHubspotInfo(currentData)

        runInAction(() => {
          this.hubspotInfoMap.set(id, info)
        })
      }
    } catch (e) {
      console.log('ERROR: initContactIntegrationHubspotInfo: ', e)

      const info = new ContactIntegrationHubspotInfo({ ...emptyData, contactId: id }, objectType)

      runInAction(() => {
        this.hubspotInfoMap.set(id, info)
      })
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationActiveCampaignInfo = async (id: number) => {
    if (this.loadingIds.has(id)) return

    const emptyData: IResponseIntegrationsContactsActiveCampaignData = {
      contactId: id,
      deals: [],
    }

    const emptyInfo = new ContactIntegrationActiveCampaignInfo(emptyData)

    try {
      runInAction(() => {
        this.loading = true
        this.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsActiveCampaignInfo(id)

      if (!data) {
        this.activeCampaignInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationActiveCampaignInfo({ ...data, contactId: id })
      this.activeCampaignInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationActiveCampaignInfo: ', e)

      this.activeCampaignInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationPipedriveInfo = async (id: number) => {
    if (this.loadingIds.has(id)) return

    const emptyData = {
      contactId: id,
      deal: [],
      properties: null,
      organization: null,
    }

    const emptyInfo = new ContactIntegrationPipedriveInfo(emptyData)

    try {
      runInAction(() => {
        this.loading = true
        this.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsPipedriveInfo(id)

      if (!data) {
        this.pipedriveInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationPipedriveInfo({ ...data, contactId: id })
      this.pipedriveInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationPipedriveInfo: ', e)

      this.pipedriveInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationSalesforceInfo = async (id: number) => {
    if (this.loadingIds.has(id)) return

    const emptyData = {
      contactId: id,
      properties: null,
      opportunities: [],
    }

    const emptyInfo = new ContactIntegrationSalesforceInfo(emptyData)

    try {
      runInAction(() => {
        this.loading = true
        this.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsSalesforceInfo(id)

      if (!data) {
        this.salesforceInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationSalesforceInfo({ ...data, contactId: id })
      this.salesforceInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationSalesforceInfo: ', e)

      this.salesforceInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationInfusionsoftInfo = async (id: number) => {
    if (this.loadingIds.has(id)) return
    const emptyInfo = new ContactIntegrationInfusionsoftInfo(null, id)

    try {
      runInAction(() => {
        this.loading = true
        this.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsInfusionsoftInfo(id)

      if (!data) {
        this.infusionsoftInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationInfusionsoftInfo(data, id)
      this.infusionsoftInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationInfusionsoftInfo: ', e)

      this.infusionsoftInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingIds.delete(id)
      })
    }
  }

  getContactIntegrationHubspotInfo = (
    id: number,
    use_contact_card_properties?: boolean,
    objectType?: IHubspotPropertiesObjectType
  ) => {
    if (objectType) {
      const info = this.hubspotInfoMap.get(id)
      if (
        !info ||
        (!info.dealsInited && objectType === 'deal') ||
        (!info.companiesInited && objectType === 'company') ||
        (!info.ticketsInited && objectType === 'ticket') ||
        (!info.propertiesInited && objectType === 'contact')
      ) {
        this.initContactIntegrationHubspotInfoByType(id, objectType)
      }

      return info
    } else {
      const info = this.hubspotInfoMap.get(id)

      if (!info) {
        this.initContactIntegrationHubspotInfo(id, use_contact_card_properties)
      }
      return info
    }
  }

  getContactIntegrationActiveCampaignInfo = (id: number) => {
    const info = this.activeCampaignInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationActiveCampaignInfo(id)
    }

    return info
  }

  getContactIntegrationPipedriveInfo = (id: number) => {
    const info = this.pipedriveInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationPipedriveInfo(id)
    }

    return info
  }

  getContactIntegrationSalesforceInfo = (id: number) => {
    const info = this.salesforceInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationSalesforceInfo(id)
    }

    return info
  }

  getContactIntegrationInfusionsoftInfo = (id: number) => {
    const info = this.infusionsoftInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationInfusionsoftInfo(id)
    }

    return info
  }
}
