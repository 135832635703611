import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Navigate, Outlet } from 'react-router'
import { PageLayout } from 'shared/layout'
import { type IMenuItemProps, Menu, Status, Typography } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import { useMenuAIAutomationLayoutContext } from 'widgets/Menu/ui/MenuAIAutomation'
import { WorkflowLayoutStore } from 'pages/workflows/store/workflowLayoutStore'
import { WorkflowLayoutContext } from 'pages/workflows/context/workflowLayoutContext'
import styles from './styles.module.scss'

const menuItems: IMenuItemProps[] = [
  {
    key: 'all_workflows',
    type: 'nav',
    icon: 'workflows',
    to: '/workflows',
    label: (
      <Typography variant={'body-md-regular'} className={styles.label} ariaLabel={'All workflows'}>
        All workflows
      </Typography>
    ),
    rightContent: <Status title={'Beta'} intent={'warning'} emphasis={'high'} size={'label'} />,
  },
]

export const WorkflowLayout = observer(() => {
  const { workflows } = featureFlagsStore
  const menuLayoutStore = useMenuAIAutomationLayoutContext()
  const pageStore = useMemo(() => new WorkflowLayoutStore(menuLayoutStore.pageLayoutStore), [])

  if (!workflows) return <Navigate to={'/'} />
  if (featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return (
      <WorkflowLayoutContext.Provider value={pageStore}>
        <Outlet />
      </WorkflowLayoutContext.Provider>
    )
  }

  return (
    <WorkflowLayoutContext.Provider value={pageStore}>
      <PageLayout
        leftContent={
          <Menu title='Workflows' menuItems={menuItems} bottomNode={<TrialProgress />} />
        }
        store={pageStore.pageLayoutStore}
      />
    </WorkflowLayoutContext.Provider>
  )
})
