import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { PageLayoutStore } from 'shared/layout'
import { DayjsFormats, removeSpacesFromNumericString } from 'shared/lib'
import type { ICardFilter } from 'shared/ui'
import { TableStore } from 'shared/ui/Table'
import { type IKeyword } from 'entities/Keywords'
import { KeywordsApi } from 'entities/Keywords/api/keywords'
import { Keyword } from 'entities/Keywords/model/Keyword'
import { ContactStatistic, type IParamsGetContactsStatistic } from 'entities/ContactStatistic'
import type {
  IParamsGetContacts,
  IResponseGetKeywordContactsStatistic,
  ISendContactDate,
} from 'entities/Keywords/api/type'
import { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'
import { toLocalISOString } from 'widgets/FilterEditor/Widget/WidgetDate'
import { ContactsTagsModalStore } from 'widgets/ContactsTagsModal'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'
import { KeywordsRoutes } from 'pages/keywords/route/type'
import { CalendarFilterEnum } from 'pages/keywords/pages/viewKeyword'
import type { ICalendarFilter } from './type'

export class KeywordDetailsStore {
  private readonly _keywordId: string | undefined
  private _keyword: IKeyword | null = null
  private _isLoading = true
  private _isContactLoading = false
  private _page = 1
  private _limit = 10
  private _total = 0
  private _search = ''
  private _filter: IParamsGetContactsStatistic['filter'] = 'sent_to'
  private _selectedCalendarFilter: ICalendarFilter = CalendarFilterEnum.allTime
  private _fromFilterDate: Date | null = null
  private _toFilterDate: Date | null = uiStore.dayjs().toDate()
  private _sendDate: ISendContactDate | null = null

  private _pageLayoutStore: PageLayoutStore
  private _contactStatisticsMap: Map<number, ContactStatistic> = new Map()
  private _contactsDetailsManageStore = new ContactsDetailsManageStore()
  private _contactStatisticsTableStore = new TableStore<ContactStatistic>({
    element: 'contact',
    withoutDefaultManageColumns: true,
  })
  private _contactsTagsModalStore = new ContactsTagsModalStore()

  private _disposeFetchContacts: IReactionDisposer | null = null
  private _disposeChangeParams: IReactionDisposer | null = null

  constructor(keywordId: string | undefined, pageLayoutStore: PageLayoutStore) {
    this._keywordId = keywordId
    this._pageLayoutStore = pageLayoutStore

    makeAutoObservable(this)

    this.reactionFetchContacts()
    this.reactionChangeParams()
    void this._contactsDetailsManageStore.init()
  }

  reactionChangeParams = () => {
    this._disposeChangeParams?.()
    this._disposeChangeParams = reaction(
      () => this.paramsWithoutPage,
      () => {
        this._isContactLoading = true
        this._page = 1
      }
    )
  }

  reactionFetchContacts = () => {
    this._disposeFetchContacts?.()
    this._disposeFetchContacts = reaction(() => this.params, this.fetchContacts, {
      delay: 500,
    })
  }

  dispose = () => {
    this._disposeFetchContacts?.()
    this._contactsDetailsManageStore.reset()
    this._disposeChangeParams?.()
  }

  get isLoading() {
    return this._isLoading
  }

  get isContactLoading() {
    return this._isContactLoading
  }

  get keyword() {
    return this._keyword
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get params() {
    return {
      page: this._page,
      ...this.paramsWithoutPage,
    }
  }

  get filter() {
    return this._filter
  }

  get search() {
    return this._search
  }

  get total() {
    return this._total
  }

  get page() {
    return this._page
  }

  get limit() {
    return this._limit
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }

  get paramsWithoutPage(): IParamsGetContacts {
    return {
      term: removeSpacesFromNumericString(this._search),
      limit: this._limit,
      filter: this._filter === 'sent_to' ? null : this._filter,
      from: this._sendDate?.from as string,
      to: this._sendDate?.to as string,
    }
  }

  get contactStatisticsItems() {
    return Array.from(this._contactStatisticsMap.values())
  }

  get statistics() {
    return this._keyword?.statistics
  }

  get filters() {
    const filters = this.statistics?.getFilters(
      'Contacts who subscribed to this keyword',
      'Subscribers'
    )
    const relevantColumnsIds = ['sent_to', 'subscribed', 'opted_out']

    return filters?.filter((column) => relevantColumnsIds.includes(column.key)) as ICardFilter<
      IParamsGetContactsStatistic['filter']
    >[]
  }

  get contactStatisticsTableStore() {
    return this._contactStatisticsTableStore
  }

  get isNoContactData() {
    return !this.isContactLoading && !this._contactStatisticsMap.size && !this.search
  }

  get selectedCalendarFilter() {
    return this._selectedCalendarFilter
  }

  get fromFilterDate() {
    return this._fromFilterDate
  }

  get toFilterDate() {
    return this._toFilterDate
  }

  get isCustomMode() {
    return this._selectedCalendarFilter === CalendarFilterEnum.custom
  }

  get contactsTagsModalStore() {
    return this._contactsTagsModalStore
  }

  get onOpenContactsDetails(): IOpenContactsDetails {
    return {
      disabled: this._contactsDetailsManageStore.disabled,
      open: (data) => {
        this._contactsDetailsManageStore.onOpenContactDetails(
          {
            contactId: data.contactId,
            inboxId: this.keyword?.inboxId,
          },
          'keywords',
          true
        )
      },
    }
  }

  init = async () => {
    try {
      const { data } = await KeywordsApi.getKeywordById(this._keywordId as string)
      this._keyword = new Keyword(data)
      this.setFromFilterDefaultDate()

      void this.fetchContacts()
    } catch (e) {
      console.error(e)
      uiStore.navigate && uiStore.navigate(`/${KeywordsRoutes.root}`)
    } finally {
      runInAction(() => {
        this._isLoading = false
      })
    }
  }

  fetchContacts = async () => {
    try {
      runInAction(() => {
        this._isContactLoading = true
      })
      const { data } = await KeywordsApi.getKeywordContacts(
        this._keyword?.id as number,
        this.params
      )

      this.setContactsData(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._isContactLoading = false
      })
    }
  }

  setContactsData = ({ data, meta }: IResponseGetKeywordContactsStatistic) => {
    this._contactStatisticsMap.clear()
    this._total = meta.total
    this._page = meta.current_page

    data.forEach((contactStatistics) => {
      this._contactStatisticsMap.set(contactStatistics.id, new ContactStatistic(contactStatistics))
    })
  }

  resetSearchAndFilters = () => {
    this.setSearch('')
    this.setSelectedCalendarFilter('All time')
    this.setParamsDate()
  }

  onActiveFilter = (filter: ICardFilter<IParamsGetContactsStatistic['filter']>) => {
    this._filter = filter.key

    this.resetSearchAndFilters()
  }

  setSearch = (value: string) => {
    this._search = value
  }

  setIsLoading = (isLoading: boolean) => {
    this._isLoading = isLoading
  }

  onPaginationTableChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }

  setSelectedCalendarFilter = (value: ICalendarFilter) => {
    this._selectedCalendarFilter = value

    switch (value) {
      case CalendarFilterEnum.last30days:
        this._fromFilterDate = uiStore.dayjs().subtract(30, 'd').toDate()
        this._toFilterDate = uiStore.dayjs().toDate()
        break
      case CalendarFilterEnum.thisMonth:
        this._fromFilterDate = uiStore.dayjs().date(1).toDate()
        this._toFilterDate = uiStore.dayjs().toDate()
        break
      case CalendarFilterEnum.lasMonth:
        this._fromFilterDate = uiStore.dayjs().subtract(1, 'M').date(1).toDate()
        this._toFilterDate = uiStore.dayjs().subtract(1, 'M').endOf('M').toDate()
        break
      case CalendarFilterEnum.last3month:
        this._fromFilterDate = uiStore.dayjs().subtract(3, 'M').date(1).toDate()
        this._toFilterDate = uiStore.dayjs().subtract(1, 'M').endOf('M').toDate()
        break
      case CalendarFilterEnum.last6month:
        this._fromFilterDate = uiStore.dayjs().subtract(6, 'M').date(1).toDate()
        this._toFilterDate = uiStore.dayjs().subtract(1, 'M').endOf('M').toDate()
        break
      case CalendarFilterEnum.lastYear:
        this._fromFilterDate = uiStore.dayjs().subtract(1, 'y').startOf('y').toDate()
        this._toFilterDate = uiStore.dayjs().subtract(1, 'y').endOf('y').toDate()
        break
      case CalendarFilterEnum.allTime:
        this._fromFilterDate = new Date(this._keyword?.createdAt as string)
        this._toFilterDate = uiStore.dayjs().toDate()
        break
    }
  }

  setFromFilterDefaultDate = () => {
    this._fromFilterDate = new Date(this._keyword?.createdAt as string)
  }

  rangeTriggerValue = () => {
    const from = uiStore.dayjs(this._fromFilterDate).format(DayjsFormats.full6)
    const to = uiStore.dayjs(this._toFilterDate).format(DayjsFormats.full6)

    return `${from} - ${to}`
  }

  setDateRangeValue = (from: Date | null, to: Date | null) => {
    this._fromFilterDate = from
    this._toFilterDate = to
  }

  setParamsDate = () => {
    if (!this._toFilterDate) {
      this._toFilterDate = uiStore.dayjs().toDate()
    }

    this._sendDate = {
      from: toLocalISOString(this._fromFilterDate) as string,
      to: toLocalISOString(this._toFilterDate) as string,
    }
  }

  onSuccessDelete = () => {
    this.contactStatisticsTableStore.unselectAllIds()
    void this.fetchContacts()
  }
}
