import { ReactNode, useState } from 'react'
import { Dropdown, IDropdownProps } from 'shared/ui'
import { ILabel, Label } from 'shared/ui/Label'
import { IDropdownItem } from 'shared/ui/Dropdown'
import { ITextFieldPlaceholderProps, TextFieldPlaceholder } from '../TextField'

export interface IDropdownPlaceholderProps {
  items?: IDropdownItem[]
  onChange?: (item: IDropdownItem) => void
  widthDropdown?: number
  maxHeight?: number
  topListComponent?: IDropdownProps['topListComponent']
  textPlaceholderProps?: Omit<ITextFieldPlaceholderProps, 'placeholder'>
  placeholder?: ReactNode
  parentsDropdownId?: string[]
  onToggle?: (open: boolean) => void
  label?: ILabel
  additionalWidth?: number
  offset?: { x?: number; y?: number }
  customComponent?: IDropdownProps['customComponent']
  onChangeOpen?: IDropdownProps['onChangeOpen']
  zIndex?: number
  bottomActions?: ReactNode
}

export const DropdownPlaceholder = ({
  items,
  onChange,
  widthDropdown,
  onToggle,
  maxHeight = 168,
  topListComponent,
  textPlaceholderProps,
  parentsDropdownId,
  placeholder,
  label,
  additionalWidth,
  offset,
  customComponent,
  onChangeOpen,
  bottomActions,
  zIndex,
}: IDropdownPlaceholderProps) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  return (
    <>
      <div>
        <Label label={label} />
        <Dropdown
          ariaLabel={'DropdownPlaceholder'}
          items={items}
          onChange={onChange}
          parentsDropdownId={parentsDropdownId}
          maxHeight={maxHeight}
          margin={0}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  offset: ({ reference }) => {
                    return [Number(offset?.x || 0), -reference.height + Number(offset?.y || 0)]
                  },
                },
              },
            ],
          }}
          onToggle={onToggle}
          width={widthDropdown || Number(ref?.offsetWidth || 0) + Number(additionalWidth || 0)}
          triggerComponent={() => (
            <div ref={setRef}>
              <TextFieldPlaceholder
                {...textPlaceholderProps}
                placeholder={placeholder || 'Select'}
              />
            </div>
          )}
          topListComponent={topListComponent}
          customComponent={customComponent}
          onChangeOpen={onChangeOpen}
          zIndex={zIndex}
          bottomActions={bottomActions}
        />
      </div>
    </>
  )
}
