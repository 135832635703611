import { ReactNode } from 'react'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

export type IPreviewEmptyStateProps = {
  variant: 'preview'
  previewImg: ReactNode
  title: ReactNode
  subtitle: ReactNode
  actions: ReactNode
}

export const PreviewEmptyState = ({
  previewImg,
  title,
  subtitle,
  actions,
}: IPreviewEmptyStateProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.preview}>{previewImg}</div>
      <Typography ariaLabel={'previewTitle'} variant={'heading-md'} marginBottom={8}>
        {title}
      </Typography>
      <Typography ariaLabel={'previewSubtitle'} variant={'body-rg-regular'} color={'tertiary'}>
        {subtitle}
      </Typography>
      <div className={styles.actions}>{actions}</div>
    </div>
  )
}
