import { observer } from 'mobx-react-lite'
import React, { FC, ReactNode, useMemo } from 'react'
import { Icon } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { InboxesTeamStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsSync/InboxesTeamSelect/store/InboxesTeamStore'
import styles from './styles.module.scss'

interface InboxesTeamSelectProps {
  label?: (v: Inbox) => ReactNode
  placeholder?: string
}

export const InboxesTeamSelect: FC<InboxesTeamSelectProps> = observer(
  ({ label = (v: Inbox) => `${v.name}: ${v.formatedNumbers[0]}`, placeholder }) => {
    const { selectedInboxId, selectInbox, inboxes } = useMemo(() => new InboxesTeamStore(), [])

    return (
      <SelectField
        withSearch={false}
        placeholder={placeholder}
        value={selectedInboxId}
        setValue={selectInbox}
        size={'medium'}
        items={inboxes.map((inbox) => ({
          id: inbox.id,
          label: '',
          labelContent: (
            <div className={styles.itemLabel}>
              <Icon fontSize={16} icon={'inboxOpen'} tertiary />
              <div className={styles.itemPhone}>{label(inbox)}</div>
            </div>
          ),
        }))}
      />
    )
  }
)
