import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import {
  IntegrationKey,
  IntegrationsApi,
  integrationsStore,
  IIntegrationHubspotPropertyFormatted,
  type IResponseMergeField,
  IHubspotPropertiesObjectType,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { UsersApi } from 'entities/Users'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import type { ContactIntegrationsStore } from 'widgets/ContactsDetails/store/contactIntegrationsStore'
import { NewHubspotPropertiesDropdownStore } from 'widgets/NewHubspotPropertiesDropdown'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'

const DEFAULT_PROPERTIES_KEYS = ['firstname', 'lastname']
const DEFAULT_DEALS_KEYS = ['dealname']
const DEFAULT_COMPANIES_KEYS = ['name']
const DEFAULT_TICKETS_KEYS = ['subject']

export class HubspotIntegrationStore {
  private _loading = false
  private _draggableContactsItemsMap: Map<string, string> = new Map()
  private _draggableDealsItemsMap: Map<string, string> = new Map()
  private _draggableCompaniesItemsMap: Map<string, string> = new Map()
  private _draggableTicketsItemsMap: Map<string, string> = new Map()
  private _hubspotFieldsMap: Map<string, IResponseMergeField> = new Map()
  private _loadingFields = false
  private _newHubspotPropertiesDropdownStore = new NewHubspotPropertiesDropdownStore(this)
  private _objectType: IHubspotPropertiesObjectType = 'contact'

  constructor(
    private _contact: Contact,
    private _contactIntegrationsStore: ContactIntegrationsStore
  ) {
    makeAutoObservable(this)
  }

  setItems = (keys: string[], source_type: IUiSettingsSourceType) => {
    this.reset(source_type)

    keys.forEach((key) => this.setItem(key, source_type))
  }

  setItem = (key: string, source_type: IUiSettingsSourceType) => {
    if (source_type === 'hubspot-contacts-draggable-items')
      this._draggableContactsItemsMap.set(key, key)
    if (source_type === 'hubspot-deals-draggable-items') this._draggableDealsItemsMap.set(key, key)
    if (source_type === 'hubspot-companies-draggable-items')
      this._draggableCompaniesItemsMap.set(key, key)
    if (source_type === 'hubspot-tickets-draggable-items')
      this._draggableTicketsItemsMap.set(key, key)
  }

  removeItem = (key: string, source_type: IUiSettingsSourceType) => {
    if (source_type === 'hubspot-contacts-draggable-items')
      this._draggableContactsItemsMap.delete(key)
    if (source_type === 'hubspot-deals-draggable-items') this._draggableDealsItemsMap.delete(key)
    if (source_type === 'hubspot-companies-draggable-items')
      this._draggableCompaniesItemsMap.delete(key)
    if (source_type === 'hubspot-tickets-draggable-items')
      this._draggableTicketsItemsMap.delete(key)
  }

  reset = (source_type: IUiSettingsSourceType) => {
    if (source_type === 'hubspot-contacts-draggable-items') this._draggableContactsItemsMap.clear()
    if (source_type === 'hubspot-deals-draggable-items') this._draggableDealsItemsMap.clear()
    if (source_type === 'hubspot-companies-draggable-items')
      this._draggableCompaniesItemsMap.clear()
    if (source_type === 'hubspot-tickets-draggable-items') this._draggableTicketsItemsMap.clear()
  }

  initInfo = async (source_type: IUiSettingsSourceType) => {
    let objectType: IHubspotPropertiesObjectType = 'contact'
    if (source_type === 'hubspot-contacts-draggable-items') objectType = 'contact'
    if (source_type === 'hubspot-deals-draggable-items') objectType = 'deal'
    if (source_type === 'hubspot-tickets-draggable-items') objectType = 'ticket'
    if (source_type === 'hubspot-companies-draggable-items') objectType = 'company'

    await this._contactIntegrationsStore.initContactIntegrationHubspotInfoByType(
      Number(this.contact.integration_vendor_id),
      objectType
    )
  }

  initDraggableHubspotItems = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await UsersApi.getUsersUiSettingsHubspotAllEntities()

      if (data.data?.length) {
        const contacts = data.data.find(
          (item) => item.source_type === 'hubspot-contacts-draggable-items'
        )

        const deals = data.data.find((item) => item.source_type === 'hubspot-deals-draggable-items')

        const companies = data.data.find(
          (item) => item.source_type === 'hubspot-companies-draggable-items'
        )
        const tickets = data.data.find(
          (item) => item.source_type === 'hubspot-tickets-draggable-items'
        )

        if (contacts) {
          this.setContactsItems(contacts.items)
        } else {
          this.setInitialContactsItems()
        }

        if (deals) {
          this.setDealsItems(deals.items)
        } else {
          this.setInitialDealsItems()
        }

        if (companies) {
          this.setCompaniesItems(companies.items)
        } else {
          this.setInitialCompaniesItems()
        }

        if (tickets) {
          this.setTicketsItems(tickets.items)
        } else {
          this.setInitialTicketsItems()
        }
      } else {
        this.setInitialContactsItems()
        this.setInitialDealsItems()
        this.setInitialCompaniesItems()
        this.setInitialTicketsItems()
      }
    } catch (e) {
      console.error(e)
      this.setCompaniesItems(DEFAULT_COMPANIES_KEYS)
      this.setDealsItems(DEFAULT_DEALS_KEYS)
      this.setContactsItems(this.propertiesKeys || DEFAULT_PROPERTIES_KEYS)
      this.setTicketsItems(DEFAULT_TICKETS_KEYS)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  setInitialContactsItems = () => {
    if (this.propertiesKeys === undefined) {
      this.updateDraggableHubspotItems(DEFAULT_PROPERTIES_KEYS, 'hubspot-contacts-draggable-items')
    }

    if (this.propertiesKeys) {
      this.updateDraggableHubspotItems(this.propertiesKeys, 'hubspot-contacts-draggable-items')
    }
  }

  setInitialCompaniesItems = () => {
    this.updateDraggableHubspotItems(DEFAULT_COMPANIES_KEYS, 'hubspot-companies-draggable-items')
  }

  setInitialDealsItems = () => {
    this.updateDraggableHubspotItems(DEFAULT_DEALS_KEYS, 'hubspot-deals-draggable-items')
  }

  setInitialTicketsItems = () => {
    this.updateDraggableHubspotItems(DEFAULT_TICKETS_KEYS, 'hubspot-tickets-draggable-items')
  }

  setContactsItems = (keys: string[]) => {
    this.setItems(keys, 'hubspot-contacts-draggable-items')
  }

  setDealsItems = (keys: string[]) => {
    this.setItems(keys, 'hubspot-deals-draggable-items')
  }

  setCompaniesItems = (keys: string[]) => {
    this.setItems(keys, 'hubspot-companies-draggable-items')
  }

  setTicketsItems = (keys: string[]) => {
    this.setItems(keys, 'hubspot-tickets-draggable-items')
  }

  getContactIntegrationHubspotFields = async () => {
    try {
      runInAction(() => {
        this._loadingFields = true
      })
      this._hubspotFieldsMap.clear()
      const { data: fields } = await IntegrationsApi.getIntegrationsByKeyFields('hubspot')
      fields.forEach((field) => {
        this._hubspotFieldsMap.set(nanoid(), field)
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._loadingFields = false
      })
    }
  }

  updateDraggableHubspotItems = async (ids: string[], source_type: IUiSettingsSourceType) => {
    try {
      this._loading = true

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type,
        items: ids,
      })

      runInAction(() => {
        if (data?.data?.items) {
          this.setItems(data.data.items, source_type)
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._loading = false
    }
  }

  addDraggableHubspotItem = async (id: string, source_type: IUiSettingsSourceType) => {
    try {
      this._loading = true

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type,
        items: [...this.getItemsBySourceType(source_type), id],
      })

      runInAction(() => {
        if (data?.data?.items) this.setItems(data.data.items, source_type)

        this.toggleBlockView(source_type)
      })
    } catch (e) {
      console.error(e)
    } finally {
      await this.initInfo(source_type)
      this._loading = false
    }
  }

  toggleBlockView = (source_type: IUiSettingsSourceType) => {
    if (
      this.hasShowMorePropertiesButton &&
      this.hasShowAllContactFields &&
      source_type === 'hubspot-contacts-draggable-items'
    ) {
      contactsDetailsGlobalStore.toggleBlockView('hubspotDetailsContactAllFields')
    }

    if (this.hasShowAllDealsFields && source_type === 'hubspot-deals-draggable-items') {
      contactsDetailsGlobalStore.toggleBlockView('hubspotDetailsDealsAllFields')
    }

    if (this.hasShowAllCompaniesFields && source_type === 'hubspot-companies-draggable-items') {
      contactsDetailsGlobalStore.toggleBlockView('hubspotDetailsCompaniesAllFields')
    }

    if (this.hasShowAllTicketsFields && source_type === 'hubspot-tickets-draggable-items') {
      contactsDetailsGlobalStore.toggleBlockView('hubspotDetailsTicketsAllFields')
    }
  }

  removeDraggableHubspotItem = async (id: string, source_type: IUiSettingsSourceType) => {
    try {
      this._loading = true

      runInAction(() => {
        this.removeItem(id, source_type)
      })

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type,
        items: this.getItemsBySourceType(source_type),
      })

      runInAction(() => {
        if (data?.data?.items) {
          this.setItems(data.data.items, source_type)
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._loading = false
    }
  }

  getItemsBySourceType = (source_type: IUiSettingsSourceType) => {
    if (source_type === 'hubspot-contacts-draggable-items') {
      return this.draggableContactsItemsMap
    }
    if (source_type === 'hubspot-deals-draggable-items') {
      return this.draggableDealsItemsMap
    }
    if (source_type === 'hubspot-companies-draggable-items') {
      return this.draggableCompaniesItemsMap
    }
    if (source_type === 'hubspot-tickets-draggable-items') {
      return this.draggableTicketsItemsMap
    }

    return []
  }

  setObjectType = (objectType: IHubspotPropertiesObjectType) => {
    this._objectType = objectType
  }

  get hubspotIntegration() {
    return integrationsStore.getIntegration(IntegrationKey.hubspot)
  }

  get contact() {
    return this._contact
  }

  get info() {
    return this._contactIntegrationsStore.getContactIntegrationHubspotInfo(this.contact.id, true)
  }

  get hubspotInfo() {
    return this._contactIntegrationsStore.getContactIntegrationHubspotInfo(
      Number(this.contact.integration_vendor_id),
      true,
      this._objectType
    )
  }

  get propertiesKeys() {
    return this.hubspotInfo?.properties
      .filter((property) => DEFAULT_PROPERTIES_KEYS.includes(property.key))
      .map((item) => item.key)
  }

  get propertiesList() {
    return this.hubspotInfo?.properties || []
  }

  get propertiesFullList() {
    const actualList = this.propertiesList.map((property) => {
      const label = this.hubspotFields.find((item) => {
        return item.name === property.key
      })

      return {
        key: property.key,
        label: label?.label || property.label,
        value: property.value,
      }
    })

    if (this.draggableContactsItemsMap.length) {
      const data: IIntegrationHubspotPropertyFormatted[] = []
      this.draggableContactsItemsMap.forEach((key) => {
        const result = actualList.find((item) => item.key === key)
        if (result) {
          data.push(result)
        }
      })

      return data
    }

    return this.propertiesList
  }

  get propertiesAvailableList() {
    return this.propertiesFullList.filter(
      (item) => item.key !== 'firstname' && item.key !== 'lastname'
    )
  }

  get properties() {
    return this.propertiesAvailableList.length && this.hasShowAllContactFields
      ? this.propertiesAvailableList.slice(0, 5)
      : this.propertiesAvailableList
  }

  get newHubspotPropertiesDropdownStore() {
    return this._newHubspotPropertiesDropdownStore
  }

  get draggableContactsItemsMap() {
    return Array.from(this._draggableContactsItemsMap.values())
  }

  get draggableCompaniesItemsMap() {
    return Array.from(this._draggableCompaniesItemsMap.values())
  }

  get draggableDealsItemsMap() {
    return Array.from(this._draggableDealsItemsMap.values())
  }

  get draggableTicketsItemsMap() {
    return Array.from(this._draggableTicketsItemsMap.values())
  }

  get showMorePropertiesLabel() {
    return this.hasShowAllContactFields ? 'See properties' : 'Hide properties'
  }

  get showMoreDealLabel() {
    return this.hasShowAllDealsFields ? 'See properties' : 'Hide properties'
  }

  get showMoreCompaniesLabel() {
    return this.hasShowAllCompaniesFields ? 'See properties' : 'Hide properties'
  }

  get showMoreTicketsLabel() {
    return this.hasShowAllTicketsFields ? 'See properties' : 'Hide properties'
  }

  get hasShowMorePropertiesButton() {
    return this.propertiesAvailableList.length > 5
  }

  get hasShowAllContactFields() {
    return contactsDetailsGlobalStore.isOpenBlock('hubspotDetailsContactAllFields')
  }

  get hasShowAllDealsFields() {
    return contactsDetailsGlobalStore.isOpenBlock('hubspotDetailsDealsAllFields')
  }

  get hasShowAllCompaniesFields() {
    return contactsDetailsGlobalStore.isOpenBlock('hubspotDetailsCompaniesAllFields')
  }

  get hasShowAllTicketsFields() {
    return contactsDetailsGlobalStore.isOpenBlock('hubspotDetailsTicketsAllFields')
  }

  get hubspotContactName() {
    const firstName = this.propertiesFullList.find((item) => item.key === 'firstname')?.value
    const lastName = this.propertiesFullList.find((item) => item.key === 'lastname')?.value

    let name = ''
    if (firstName) name = `${firstName} `
    if (lastName) name += `${lastName}`

    return name
  }

  get loading() {
    return this._loading
  }

  get hubspotFields() {
    return Array.from(this._hubspotFieldsMap.values())
  }

  get loadingFields() {
    return this._loadingFields
  }
}
