import { makeAutoObservable } from 'mobx'
import type { IResponseTenDlcVoiceIntegrity } from 'entities/Compliance'

export class OrganizationVoiceIntegrity {
  private _additionalInfo: IResponseTenDlcVoiceIntegrity['additionalInfo'] | null = null
  private _voiceIntegrity: IResponseTenDlcVoiceIntegrity['voiceIntegrity'] | null = null
  private _origin: IResponseTenDlcVoiceIntegrity | null = null

  constructor(response: IResponseTenDlcVoiceIntegrity) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseTenDlcVoiceIntegrity) => {
    this._additionalInfo = response.additionalInfo
    this._voiceIntegrity = response.voiceIntegrity
    this._origin = response
  }

  get origin() {
    return this._origin
  }

  get additionalInfo() {
    return this._additionalInfo
  }

  get voiceIntegrity() {
    return this._voiceIntegrity
  }

  get statusAdditionalInfo() {
    if (!this._additionalInfo) return

    return this._additionalInfo.status
  }

  get statusVoiceIntegrity() {
    if (!this._voiceIntegrity) return

    return this._voiceIntegrity.status
  }
}
