import { makeAutoObservable } from 'mobx'
import { type ITooltipProps } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { TooltipLink } from 'shared/ui/Tooltip/ui/components/TooltipLink/TooltipLink'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'

export class TrialContactsLimitTooltipStore {
  constructor() {
    makeAutoObservable(this)
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get loading() {
    return this.trialSubscription.loading
  }

  init = async () => {
    if (!subscriptionStore.isTrial) return

    await this.trialSubscription.init()
  }

  get isCompleted() {
    return this.trialSubscription.isStatusCompleted
  }

  get configTooltip(): ITooltipProps {
    if (this.isAdminOrOwnerRole) {
      return {
        placement: 'top',
        sx: { width: '280px' },
        type: 'description',
        label: this.isCompleted
          ? 'You have reached the limit of 250 contacts on Trial plan. Upgrade to remove the limits.'
          : 'You have reached the limit of 250 contacts on Trial plan. Get approved and upgrade to remove the limits.',
        desc: this.isCompleted ? (
          <TooltipLink
            text={'Upgrade'}
            onClick={() => {
              uiStore.changeRouteViaVue({
                path: '/settings/billing/overview/upgrade',
              })
            }}
          />
        ) : (
          <TooltipLink
            text={'Get approved'}
            onClick={() => {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance',
              })
            }}
          />
        ),
      }
    }

    return {
      placement: 'top',
      sx: { width: '280px' },
      type: 'description',
      label: this.isCompleted
        ? 'You have reached the limit of 250 contacts on Trial plan. To remove the limits, please ask one of your admins to upgrade.'
        : 'You have reached the limit of 250 contacts on Trial plan. To remove the limits, please ask one of your admins to get approved and upgrade.',
    }
  }

  get isAdminOrOwnerRole() {
    return usersStore.user?.isAdminOrOwnerRole
  }

  get hide() {
    if (this.loading) return true

    return !subscriptionStore.isTrial
  }
}
