import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'

type IProgressBar = {
  percent: number
}

export const ProgressBar = observer(({ percent }: IProgressBar) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.progress} style={{ width: `${percent}%` }}></div>
    </div>
  )
})
