import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { SpinnerLoader } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  EnumAccessPageKeys,
  promiseCheckAccessPage,
} from 'entities/ExportRouters/lib/checkAccessPage'
import { ConversationRoutes } from 'pages/conversations'

export const KnowledgeBaseAccessLayout = observer(() => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    promiseCheckAccessPage(EnumAccessPageKeys.knowledge_base).then((hasAccessPage) => {
      const canNotAccess = !hasAccessPage

      if (canNotAccess) {
        uiStore.changeRouteViaVue({
          path: ConversationRoutes.root,
        })
      }

      setLoading(false)
    })
  }, [])

  if (loading) {
    return <SpinnerLoader />
  }

  return (
    <>
      <Outlet />
    </>
  )
})
