import { observer } from 'mobx-react-lite'
import React, { ReactNode, useMemo, useState } from 'react'
import { ActionItem, Actions, Typography } from 'shared/ui'
import { TestSMS } from 'widgets/TestSMS'
import { useCampaignMessageContext } from 'pages/campaigns/ui/CampaignMessage'
import styles from './styles.module.scss'

export type IMessageActionsProps = {
  isHideDelete: boolean
  isDragging: boolean
  limitAddMessage?: ReactNode
}

export const MessageActions = observer(
  ({ isHideDelete, isDragging, limitAddMessage }: IMessageActionsProps) => {
    const { onDuplicate, onDelete, campaignSMSStore, campaignSendFromStore, onTestSMS } =
      useCampaignMessageContext()

    const [isShowTest, setShowTest] = useState(false)

    const { number } = campaignSendFromStore

    const testSMSDisabled = useMemo(() => {
      const isNoNumberId = !number?.id

      if (isNoNumberId) return true

      const isMessageEmpty =
        !campaignSMSStore.messageDataParams.message.trim().length &&
        !campaignSMSStore.messageDataParams.media_url.length
      const isError = campaignSMSStore.isError

      return isError || isMessageEmpty
    }, [
      campaignSMSStore.messageFieldStore.isAttachmentsCountLimitError,
      campaignSMSStore.isError,
      campaignSMSStore.messageDataParams.message,
      campaignSMSStore.messageDataParams.media_url,
      number?.id,
    ])

    const testAction = (
      <TestSMS
        disabled={testSMSDisabled}
        params={{
          media_url: campaignSMSStore.messageDataParams.media_url,
          message: campaignSMSStore.messageDataParams.message,
          number_id: number?.id || 0,
        }}
        request={onTestSMS}
        isShowTest={isShowTest}
        setShowTest={setShowTest}
        testSMSButtonProps={{ typeBtn: 'menuItem' }}
      />
    )

    const actions: Array<ActionItem | false> = [
      {
        dropdownItemComponent: () => testAction,
      },
      {
        icon: 'copy',
        text: 'Duplicate',
        onAction: onDuplicate,
        buttonProps: limitAddMessage
          ? {
              disabled: true,
              tooltipProps: {
                label: limitAddMessage,
                placement: 'right',
              },
            }
          : undefined,
      },
      !isHideDelete && {
        icon: 'delete',
        text: 'Delete',
        onAction: onDelete,
      },
    ]

    const onToggleDrop = (value: boolean) => {
      if (!value) {
        setShowTest(false)
      }
    }

    return (
      <Actions
        noVisibleActions
        actions={actions.filter(Boolean) as ActionItem[]}
        iconButtonProps={{
          icon: isDragging ? 'dotsDrag' : 'moreHorizontal',
          size: 'small',
        }}
        onToggle={onToggleDrop}
        moreDropdownComponent={
          isShowTest
            ? () => (
                <div className={styles.wrapTestSMS}>
                  <Typography ariaLabel={'testSMS'} variant={'body-md-semibold'}>
                    Send test SMS
                  </Typography>
                  {testAction}
                </div>
              )
            : undefined
        }
      />
    )
  }
)
