import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { EnumSpinnerLoaderPosition, IRow, SpinnerLoader, Table } from 'shared/ui'

import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { contactsHistoryStore } from '../store/ContactsHistoryStore'
import { History } from '../model/History'

import styles from './styles.module.scss'

import { getHistoryActions } from '../helpers/historyActions'
import { useHistoryColumnNames } from '../helpers/useHistoryColumnNames'

export const ContactHistoryTable = observer(() => {
  const {
    totalHistory,
    historyData,
    pagination,
    isLoading,
    isNoImportsYet,
    changePagination,
    dispose,
  } = contactsHistoryStore

  useEffect(() => {
    return () => {
      dispose()
    }
  }, [])

  const rows = useMemo(
    (): IRow<History>[] =>
      historyData.map((history) => {
        history.setActionsProps &&
          history.setActionsProps({
            showItems: 0,
            actions: getHistoryActions(history),
            noVisibleActions: true,
          })

        return history
      }),
    [historyData]
  )

  const columns = useHistoryColumnNames()

  if (isNoImportsYet) {
    return <NoResultsFound label='No imports found' />
  }

  return (
    <div className={styles.tableContainer}>
      {isLoading && <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />}

      <Table
        sxBox={{ padding: '0 16px' }}
        columns={columns}
        rows={rows}
        rowsCount={totalHistory}
        limit={pagination.limit}
        initPage={pagination.page}
        onChangePagination={(page: number, pageSize: number) => {
          changePagination(page, pageSize)
        }}
        rightHeaderContent={<div />}
      />
    </div>
  )
})
