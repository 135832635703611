import React from 'react'
import { SpinnerLoader } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type AttachmentLoaderProps = {
  isLibrary?: boolean
}

export const AttachmentLoader = ({ isLibrary = false }: AttachmentLoaderProps) => (
  <div className={styles.loader} aria-label={getAriaLabel('AttachmentLoader')}>
    <SpinnerLoader
      {...(isLibrary && {
        determinatecolor: 'var(--white-100)',
        indeterminatecolor: 'var(--gray-40)',
      })}
      size={16}
    />
  </div>
)
