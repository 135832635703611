import { FC } from 'react'
import { Box } from 'shared/ui/Box'
import { Typography } from 'shared/ui/Typography'
import { getAriaLabel } from 'shared/lib'

type IBooleanStatusProps = {
  value?: boolean
}

export const BooleanStatus: FC<IBooleanStatusProps> = ({ value }) => (
  <Box display={'flex'} gap={'10px'} aria-label={getAriaLabel('BooleanStatus')}>
    {value ? (
      <>
        <Typography variant={'body-md-regular'} color={'var(--green-60)'} ariaLabel={'dot'}>
          •
        </Typography>
        <Typography variant={'body-md-regular'} ariaLabel={'Yes'}>
          Yes
        </Typography>
      </>
    ) : (
      <>
        <Typography variant={'body-md-regular'} color={'var(--red-90)'} ariaLabel={'dot'}>
          •
        </Typography>
        <Typography variant={'body-md-regular'} ariaLabel={'No'}>
          No
        </Typography>
      </>
    )}
  </Box>
)
