import { Base } from 'models'
import {
  IResponseIntegrationHubspotDeal,
  IResponseIntegrationHubspotCompany,
  IResponseIntegrationHubspotData,
  IIntegrationHubspotPropertyFormatted,
  IResponseIntegrationHubspotTicket,
  IUpdateIntegrationHubspotData,
  IHubspotPropertiesObjectType,
} from 'entities/Integrations'

export class ContactIntegrationHubspotInfo extends Base {
  contactId: number
  companies: IResponseIntegrationHubspotCompany[]
  properties: IIntegrationHubspotPropertyFormatted[]
  deals: IResponseIntegrationHubspotDeal[]
  tickets: IResponseIntegrationHubspotTicket[]
  private _companiesInited = false
  private _propertiesInited = false
  private _dealsInited = false
  private _ticketsInited = false

  constructor(item: IResponseIntegrationHubspotData, objectType?: IHubspotPropertiesObjectType) {
    super()

    this.contactId = item.contactId
    this.deals = item.deals
    this.companies = item.companies
    this.tickets = item.tickets

    this.properties = Object.keys(item?.properties).map((key) => {
      return { ...item?.properties?.[key], key }
    })

    if (objectType) this.updateInitedState(objectType)
  }

  updateInfoItem = (
    data: IUpdateIntegrationHubspotData,
    objectType: IHubspotPropertiesObjectType
  ) => {
    this.contactId = data.contactId
    this.deals = data.deals
    this.companies = data.companies
    this.tickets = data.tickets
    this.properties = data.properties

    this.updateInitedState(objectType)

    return this
  }

  updateInitedState = (objectType: IHubspotPropertiesObjectType) => {
    this._dealsInited = objectType === 'deal' ? true : this._dealsInited
    this._companiesInited = objectType === 'company' ? true : this._companiesInited
    this._ticketsInited = objectType === 'ticket' ? true : this._ticketsInited
    this._propertiesInited = objectType === 'contact' ? true : this._propertiesInited
  }

  get dealsInited() {
    return this._dealsInited
  }
  get companiesInited() {
    return this._companiesInited
  }
  get ticketsInited() {
    return this._ticketsInited
  }
  get propertiesInited() {
    return this._propertiesInited
  }
}
