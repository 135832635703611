import { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import type { ComplianceServiceBrand } from 'entities/Compliance/model'
import { ModalHeader } from 'pages/settings/pages/compliance/ui/ModalHeader/ModalHeader'
import type { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { ModalInfoBlock } from 'pages/settings/pages/compliance/ui/ModalInfoBlock/ModalInfoBlock'
import type { IModalInfoRowProps } from 'pages/settings/pages/compliance/ui/ModalInfoRow/ModalInfoRow'
import styles from './styles.module.scss'

type IBrandModalContentProps = {
  complianceLayerStore: ComplianceLayerStore
  brand: ComplianceServiceBrand
  statusData: ReactNode
  updateModalAction: () => void
  learnMoreModalAction: () => void
}

export const BrandModalContent = observer(
  ({
    complianceLayerStore,
    brand,
    statusData,
    updateModalAction,
    learnMoreModalAction,
  }: IBrandModalContentProps) => {
    const { businessInformation } = complianceLayerStore
    const lowVolumePlan = brand.plan_type === 'LOW_VOLUME_STANDARD'
    const { registrationError, isPublicType, vetting } = brand

    const lines: IModalInfoRowProps[] = [
      {
        label: 'Name',
        value: businessInformation?.business_name || '',
      },
      {
        label: 'Package',
        value: lowVolumePlan ? 'Low volume standard' : 'Standard',
      },
      {
        label: 'Daily limits',
        value: lowVolumePlan
          ? 'up to 2,000 SMS / MMS segments'
          : 'up to 600,000 SMS / MMS segments',
      },
      {
        label: 'Company type',
        value: brand?.entityType || '',
      },
    ]

    const vettingScore = vetting?.score

    if (!lowVolumePlan) {
      lines.splice(1, 0, {
        label: 'Trust score',
        value: vettingScore || (
          <span className={styles.trustScore}>Will be determined upon brand approval</span>
        ),
        tooltip: [
          'Impacts the messaging throughput (MPS)',
          <a
            href={`${links.throughputMessageGuide}`}
            target='_blank'
            rel='noreferrer'
            className={styles.learnMorelink}
            key={'learnMore'}
          >
            Learn more
          </a>,
        ],
      })
    }

    if (isPublicType) {
      lines.push(
        {
          label: 'Stock exchange',
          value: brand?.stockExchange || '',
        },
        {
          label: ['Company Stock', <br key={'br'} />, 'Ticker Symbol'],
          value: brand?.stockSymbol || '',
        }
      )
    }

    return (
      <div className={styles.wrap}>
        <ModalHeader header={businessInformation?.business_name || ''} statusData={statusData} />

        {registrationError && (
          <Alert
            item={{
              type: 'error',
              title: 'Your brand application was rejected.',
              desc: registrationError.message,
            }}
            actions={
              <>
                <Button
                  text={'Update'}
                  typeBtn={'outlined'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={updateModalAction}
                />
                <Button
                  text={'Learn more'}
                  typeBtn={'text'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={learnMoreModalAction}
                />
              </>
            }
          />
        )}
        <div className={styles.divider} />

        <ModalInfoBlock lines={lines} />
      </div>
    )
  }
)
