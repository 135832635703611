import { observer } from 'mobx-react-lite'
import { Alert, Box, dropDownStore, Icon, SpinnerLoader, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'
import { UpgradeSubscriptionBtn } from 'widgets/Sidebar/ui/MainDropdown/UpgradeSubscriptionBtn'
import styles from './styles.module.scss'

type ICreditsProps = {
  isFreeMonthsAvailable: boolean
}

const RegularCredits = observer(({ isFreeMonthsAvailable }: ICreditsProps) => {
  const { formattedAccountCredits } = organizationStore
  const { subscriptionSuggestion } = subscriptionStore
  const isSubscriptionSuggestion = Boolean(subscriptionSuggestion)

  return (
    <div className={styles.block}>
      <Box className={styles.blockWrapper} marginBottom={'-5px'}>
        <div className={styles.label}>
          <div className={styles.icon}>
            <Icon icon='credits' />
          </div>
          <Typography
            variant={'body-md-regular'}
            ariaLabel={'Credits'}
            color='var(--content-primary-primary)'
          >
            Credits
          </Typography>
        </div>
        <Typography variant={'body-md-regular'} ariaLabel={'formattedAccountCredits'}>
          {formattedAccountCredits}
        </Typography>
      </Box>

      {isSubscriptionSuggestion && (
        <div className={styles.creditsInfo}>
          <Alert
            className={styles.creditsInfo__alert}
            item={{
              hideIcon: true,
              type: 'warning',
              title: 'Plan credits exceeded!',
              desc: 'Upgrade to the right plan and save more. No extra charges, just better value for you.',
            }}
          />
        </div>
      )}

      <UpgradeSubscriptionBtn
        onClick={() => {
          dropDownStore.allHide()
        }}
      />

      {isFreeMonthsAvailable && (
        <Typography
          className={styles.upgradeLabel}
          variant='body-sm-regular'
          ariaLabel={'get-2-months-free'}
        >
          Get 2 months free!
        </Typography>
      )}
    </div>
  )
})

const TrialCredits = observer(() => {
  return (
    <div className={styles.block} aria-label={getAriaLabel('MainDropdown', 'Block')}>
      <Box className={styles.blockWrapper} marginBottom={'-5px'}>
        <div className={styles.label}>
          <div className={styles.icon}>
            <Icon icon='credits' />
          </div>
          <Typography
            variant={'body-md-regular'}
            ariaLabel={'SwitchToOldSalesmsg'}
            color='var(--content-primary-primary)'
          >
            Trial credits
          </Typography>
        </div>

        <Typography
          ariaLabel={'formattedTrialCredits'}
          variant={'body-md-regular'}
          className={styles.right}
          color={'var(--content-primary-tertiary)'}
        >
          {organizationStore.formattedTrialCredits}
        </Typography>
      </Box>

      <UpgradeSubscriptionBtn
        onClick={() => {
          dropDownStore.allHide()
        }}
      />
    </div>
  )
})

export const Credits = observer(() => {
  const isLoading = organizationStore.loading || subscriptionStore.loading
  const isTrialing = subscriptionStore.isTrial
  const isSubscriptionSuggestion = !!subscriptionStore.subscriptionSuggestion
  const isAdminOrOwnerRole = !!usersStore.user?.isAdminOrOwnerRole
  const isMonthlyPlan = subscriptionStore.plan?.interval === 'monthly'
  const isCustomPlan = !!subscriptionStore.plan?.custom
  const isFreeMonthsAvailable =
    isAdminOrOwnerRole && !isCustomPlan && isMonthlyPlan && !isSubscriptionSuggestion

  if (isLoading) return <SpinnerLoader size={20} />

  return isTrialing ? (
    <TrialCredits />
  ) : (
    <RegularCredits isFreeMonthsAvailable={isFreeMonthsAvailable} />
  )
})
