import { Status } from 'shared/ui/Status/Status'
import { type IStatusConfig } from 'shared/ui/Status/type'
import { type IDropdownItem } from 'shared/ui'

export function getDropdownItemsFromStatusConfig(config: IStatusConfig): IDropdownItem[] {
  return Object.entries(config).map(([key, item]) => ({
    id: key,
    label: item.title,
    labelContent: <Status {...item} />,
  }))
}
