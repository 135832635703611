import React from 'react'
import { observer } from 'mobx-react-lite'
import { RinglessMessageField } from 'widgets/MessageFormFields/RinglessMessageField'
import { CampaignRinglessStore } from './store/CampaignRinglessStore'

type ICampaignRinglessProps = {
  store: CampaignRinglessStore
}

export const CampaignRingless = observer(({ store }: ICampaignRinglessProps) => {
  return <RinglessMessageField store={store.ringlessMessageFieldStore} />
})
