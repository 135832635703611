import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { ITabItem, Tabs } from 'shared/ui'
import { AvailableTabContent } from 'entities/Phone/ui/GetNumberModal'
import { ITabsEnum } from 'entities/Phone/ui/GetNumberModal/store'
import type { QuestionnaireOrSelectNumberModalStore } from 'entities/Phone/ui/QuestionnaireOrSelectNumberModal'
import styles from './styles.module.scss'

type IStepSelectNumberProps = {
  store: QuestionnaireOrSelectNumberModalStore
}

export const StepSelectNumber: FC<IStepSelectNumberProps> = observer(({ store }) => {
  const { numberModalStore, summary } = store

  useEffect(() => {
    numberModalStore.preselectCountry()
    numberModalStore.initActiveTab()
    numberModalStore.init()

    return numberModalStore.dispose
  }, [])

  const handleActiveTab = (item: ITabItem) => {
    const tabKey = item.key as ITabsEnum
    numberModalStore.setActiveTab(tabKey)
  }

  return (
    <div className={styles.wrap}>
      {summary?.isNumberAll && (
        <div className={styles.tabs}>
          <Tabs
            tabs={numberModalStore.tabs}
            activeTabKey={numberModalStore.activeTab}
            handleSelectTab={handleActiveTab}
          />
        </div>
      )}

      <AvailableTabContent store={store.numberModalStore} />
    </div>
  )
})
