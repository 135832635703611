import { websocket } from 'entities/WebSocket'

interface IEventInboxProps {
  id: number | string
}

class ChannelInbox {
  privateChannelName?: string
  presenceChannelName?: string

  constructor(props: IEventInboxProps) {
    if (props.id !== 0) {
      this.privateChannelName = `private-inbox.${props.id}`
      this.presenceChannelName = `presence-inbox.${props.id}`
      this.subscribe()
    }
  }

  subscribe() {
    websocket.subscribe(this.privateChannelName)
    websocket.subscribe(this.presenceChannelName)
  }
}

export { ChannelInbox }
