import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Box,
  type ITooltipProps,
  type PhoneInputStore,
  PhoneWithLabelInput,
  TextFieldWithLabel,
} from 'shared/ui'
import styles from './styles.module.scss'

type ContactFormProps = {
  isSecond?: boolean
  contact: {
    firstName: string
    lastName: string
    email: string
  }
  phoneInputStore: PhoneInputStore
  emailErrorMessage?: string
  phoneErrorMessage?: string | null
  isErrorBorderEmail: boolean
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setEmail: (value: string) => void
  checkIsEmail: () => void
  setValueCallback: () => void
  similarityDomainsWarning: string
}

const phoneTooltip: ITooltipProps = {
  type: 'description',
  width: 290,
  label: "Your representative's mobile phone number",
  desc: 'May also be used for verification codes',
}

export const ContactForm: FC<ContactFormProps> = observer(
  ({
    contact,
    phoneInputStore,
    emailErrorMessage,
    phoneErrorMessage,
    isErrorBorderEmail,
    setFirstName,
    setLastName,
    setEmail,
    checkIsEmail,
    setValueCallback,
    similarityDomainsWarning,
  }) => (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        marginBottom={3}
        gap={'10px'}
        marginTop={5}
      >
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'First name',
            },
          }}
          textFieldProps={{
            value: contact.firstName,
            onChange: setFirstName,
            InputProps: {
              placeholder: 'e.g. Adam',
            },
            className: styles.inputPadding,
          }}
        />
        <TextFieldWithLabel
          labelProps={{
            label: {
              text: 'Last name',
            },
          }}
          textFieldProps={{
            value: contact.lastName,
            onChange: setLastName,
            InputProps: {
              placeholder: 'e.g. Johnson',
            },
            className: styles.inputPadding,
          }}
        />
      </Box>
      <Box marginBottom={3}>
        <TextFieldWithLabel
          errorBorder={isErrorBorderEmail}
          errorMessage={emailErrorMessage}
          warningText={similarityDomainsWarning}
          labelProps={{
            marginBottom: 4,
            label: {
              text: 'Business email',
            },
          }}
          textFieldProps={{
            value: contact.email,
            onChange: setEmail,
            onBlur: checkIsEmail,
            InputProps: {
              placeholder: 'e.g. adam.johnson@example.com',
            },
            className: styles.inputPadding,
          }}
        />
      </Box>
      <PhoneWithLabelInput
        label='Phone number'
        questionTooltipProps={phoneTooltip}
        item={phoneInputStore.country}
        items={phoneInputStore.countries}
        number={phoneInputStore.number}
        numberError={phoneInputStore.numberError || phoneErrorMessage || null}
        isValidNumber={phoneInputStore.isValidNumber}
        numberRequestError={phoneInputStore.numberRequestError}
        setNumber={(value) => {
          setValueCallback()
          phoneInputStore.setNumber(value)
        }}
        setCountry={phoneInputStore.setCountry}
        triggerNumberValidation={phoneInputStore.triggerNumberValidation}
      />
    </>
  )
)
