import React from 'react'
import { type IIconsVector } from 'shared/ui'
import { Tooltip } from 'shared/ui/Tooltip'
import { BoxIcon } from 'shared/ui/Icon/BoxIcon'

const config = {
  sms: {
    icon: 'messageSmile' as IIconsVector,
    label: 'SMS',
  },
  ringless: {
    icon: 'voicemail2' as IIconsVector,
    label: 'Ringless',
  },
}

export type IType = keyof typeof config

type ITypeProps = { type: IType }

export const Type = ({ type }: ITypeProps) => {
  return (
    <Tooltip label={config[type]?.label} placement={'top'}>
      <BoxIcon icon={config[type]?.icon} tertiary />
    </Tooltip>
  )
}
