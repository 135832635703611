import { makeAutoObservable } from 'mobx'
import { ICardFilter } from 'shared/ui'
import { TableStore } from 'shared/ui/Table'
import { PageLayoutStore } from 'shared/layout'
import {
  IParamsGetBroadcastContacts,
  IResponseRecurringBroadcastById,
} from 'entities/Broadcast/api/types'
import { BroadcastRecurring } from 'entities/Broadcast'

export class BroadcastDetailRecurringStore {
  tableStore = new TableStore()
  broadcast: BroadcastRecurring

  constructor(
    broadcastResponse: IResponseRecurringBroadcastById,
    private _pageLayoutStore: PageLayoutStore
  ) {
    makeAutoObservable(this)
    this.broadcast = new BroadcastRecurring(broadcastResponse, true)
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get name() {
    return this.broadcast.name
  }
  get status() {
    return this.broadcast.status
  }
  get type() {
    return this.broadcast.type
  }
  get failed_reason() {
    return this.broadcast.failed_reason
  }
  get statistics() {
    return this.broadcast.statistics
  }

  get filters(): ICardFilter<IParamsGetBroadcastContacts['filter']>[] {
    if (!this.statistics) return []
    return this.statistics?.filters
      ?.filter((filter) => filter.key !== 'skipped')
      .map((column) => ({ ...column, notInteractable: true }))
  }
}
