import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { integrationsStore } from 'entities/Integrations'
import { contactsCleanupStore } from 'widgets/ContactsTable'
import { ContactsDetails } from 'widgets/ContactsDetails'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { contactsHistoryStore } from 'widgets/ContactsHistory/store/ContactsHistoryStore'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { type ContactsPageStore } from 'pages/contacts'
import { ContactsContent } from './ContactsContent/ContactsContent'
import styles from './styles.module.scss'

type IContactsProps = {
  contactsPageStore: ContactsPageStore
}

export const Contacts: FC<IContactsProps> = observer(({ contactsPageStore }) => {
  const { contactsMenuStore, contactsTableStore, segmentModalStore, contactsDetailsStore } =
    contactsPageStore
  const { tableStore } = contactsTableStore
  const { isImportAllowed, getStatusImportContact } = importContactsStore

  useEffect(() => {
    const disposeContactsFilterStore = contactsFilterStore.watchChanges()
    contactsTableStore.initReactions()
    integrationsStore.fetchHubspotLists()
    contactsFilterStore.initPreselectFilters()

    if (!contactsFilterStore.segmentBox.segment) {
      contactsTableStore.loadContacts()
    }

    contactsCleanupStore.init()
    contactsDetailsGlobalStore.handleSetVariant('contacts')
    contactsDetailsGlobalStore.handleChangeCollapse(true)
    !isImportAllowed && getStatusImportContact()
    contactsHistoryStore.loadImportHistory()
    contactsPageStore.init()

    return () => {
      disposeContactsFilterStore()
      contactsTableStore.dispose()
      contactsTableStore.resetNewContactMode()
      tableStore.resetSelected()
      contactsFilterStore.clearSegment()
      contactsTableStore.resetSorting()
      contactsPageStore.reset()
    }
  }, [])

  return (
    <div className={styles.container}>
      <ContactsContent
        contactsMenuStore={contactsMenuStore}
        contactsTableStore={contactsTableStore}
        segmentModalStore={segmentModalStore}
      />
      {contactsDetailsStore ? <ContactsDetails store={contactsDetailsStore} /> : null}
    </div>
  )
})
