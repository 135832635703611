import { useEffect, useRef, useState } from 'react'
import { Button, Icon, Typography } from 'shared/ui'
import { isLink } from 'shared/lib'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { shortLinkStore } from 'entities/ShortLink'
import styles from './styles.module.scss'

export const ShortLinkContent = () => {
  const [link, setLink] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { editLink, createShortLink, handleShortenLink, handleAddShortLink, handleSaveLink } =
    shortLinkStore

  const ref = useRef<HTMLTextAreaElement>(null)
  const isAddDisabled = !link.trim() || loading || !shortLinkStore.canShortUrl(link)

  const onChangeValue = (value: string) => {
    setError(false)
    setLink(value)
  }

  useEffect(() => {
    if (ref.current) {
      const end = editLink?.href.length || 0
      ref.current.setSelectionRange(end, end)
      ref.current.focus()
    }
  }, [editLink])

  useEffect(() => {
    if (editLink) {
      setLink(editLink.href)
    }
  }, [editLink])

  const onAdd = async () => {
    if (!isLink(link)) {
      setError(true)
      return
    }
    setError(false)
    try {
      const shortLink = await createShortLink(link)
      if (shortLink) {
        if (editLink) {
          handleShortenLink(shortLink)
        } else {
          handleAddShortLink(shortLink)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const onSave = () => {
    if (!isLink(link)) {
      setError(true)
      return
    }
    setError(false)
    handleSaveLink(link)
  }

  return (
    <div className={styles.wrap}>
      <TextField
        className={styles.textField}
        variant='integrated'
        size='small'
        InputProps={{ placeholder: 'Enter your URL' }}
        onChange={onChangeValue}
        value={link}
        mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
      />
      {error && (
        <div className={styles.helper}>
          <Icon
            icon={'alertTriangleSmall'}
            color={'var(--content-negative-primary)'}
            fontSize={16}
          />
          <Typography
            variant={'text-input-helper'}
            color={'negativePrimary'}
            ariaLabel={'Please enter'}
          >
            Please enter a valid URL
          </Typography>
        </div>
      )}
      <div className={styles.actions}>
        {editLink ? (
          <>
            <Button
              text={'Shorten URL'}
              size={'medium'}
              contained={'secondary'}
              disabled={isAddDisabled}
              onClick={onAdd}
            />
            <Button
              text={'Save'}
              size={'medium'}
              contained={'primary'}
              disabled={!link.trim()}
              onClick={onSave}
            />
          </>
        ) : (
          <Button
            text={'Add URL'}
            size={'medium'}
            contained={'secondary'}
            disabled={isAddDisabled}
            onClick={onAdd}
          />
        )}
      </div>
    </div>
  )
}
