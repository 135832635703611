import { createContext, useContext } from 'react'
import { type KeywordPageStore } from 'pages/keywords'

export const KeywordsPageContext = createContext<KeywordPageStore | null>(null)

export const useKeywordsPageContext = () => {
  const context = useContext(KeywordsPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with KeywordsPageProvider')

  return context
}
