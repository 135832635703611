import classNames from 'classnames'
import { TableRow } from '@mui/material'
import { Checkbox } from 'shared/ui/Checkbox'
import tableStyles from 'shared/ui/Table/Table.module.scss'
import { TableCell } from 'shared/ui/Table/TableCell/TableCell'
import { PinnedCell } from 'shared/ui/Table/PinnedCell/PinnedCell'
import type { IColumn, ITableProps, ITablePropsContent } from 'shared/ui'
import { SimpleFilterDropdown } from 'shared/ui/Table/SimpleFilterDropdown/SimpleFilterDropdown'
import { HeaderCenterContent } from 'shared/ui/Table/SelectedContent/HeaderCenterContent/HeaderCenterContent'
import {
  HeaderLeftContent,
  IHeaderLeftContentProps,
} from 'shared/ui/Table/SelectedContent/HeaderLeftContent/HeaderLeftContent'
import { DefaultHeaderCell } from 'shared/ui/Table/DefaultHeaderCell/DefaultHeaderCell'
import { getAriaLabel } from 'shared/lib'
import { CheckboxCell } from '../CheckboxCell/CheckboxCell'
import styles from './styles.module.scss'

export type ITableRowHeadProps<TRow> = {
  selected: boolean
  pinnedColumns: IColumn<TRow>[]
  withCheckbox: ITableProps<TRow>['withCheckbox']
  withSelectAll: ITableProps<TRow>['withSelectAll']
  unpinnedColumns: IColumn<TRow>[]
  toggleSelectAll?: ITablePropsContent<TRow>['toggleSelectAll']
  visibleColumnsIds?: ITableProps<TRow>['visibleColumnsIds']
  columns: ITableProps<TRow>['columns']
  setVisibleColumnsIds?: ITableProps<TRow>['setVisibleColumnsIds']
  rightHeaderContent?: ITableProps<TRow>['rightHeaderContent']
  withActions?: boolean
  isScrollLeft: boolean
  withoutSelectedCount?: boolean
  onSort?: (field: string | number) => void
  sortBy?: string
  sortOrder?: 'asc' | 'desc'
  headerLeftContentProps?: IHeaderLeftContentProps
}

export const TableRowHead = <TRow,>({
  selected,
  withCheckbox,
  withSelectAll,
  withoutSelectedCount,
  pinnedColumns,
  unpinnedColumns,
  toggleSelectAll,
  visibleColumnsIds,
  columns,
  setVisibleColumnsIds,
  rightHeaderContent,
  isScrollLeft,
  sortOrder,
  sortBy,
  onSort,
  withActions,
  headerLeftContentProps,
}: ITableRowHeadProps<TRow>) => {
  const defaultRenderHeaderCell = <TRow,>(column: IColumn<TRow>) => {
    if (column.renderHeaderCell) {
      return column.renderHeaderCell(column)
    }

    return <DefaultHeaderCell column={column} sortBy={sortBy} sortOrder={sortOrder} />
  }

  const toggleSelected = (isSelected: boolean) => {
    toggleSelectAll && toggleSelectAll(isSelected)
  }

  const columnCellRender = (column: IColumn<TRow>, index: number) => {
    const isSortable = column.isSortable && onSort
    const onSortAction = () => {
      if (isSortable) {
        onSort(column.sortKey || (column.field as string | number))
      }
    }
    return (
      <TableCell
        className={classNames({
          [tableStyles.visibilityHidden]: selected,
          [tableStyles.sortable]: isSortable,
        })}
        key={`${column.name}-${index}`}
        width={column.width}
        isHeader={true}
        maxWidth={column.maxWidth}
        minWidth={column.minWidth}
        padding={column.paddingHeader || column.padding}
        onCellClick={isSortable ? onSortAction : undefined}
      >
        {defaultRenderHeaderCell(column)}
      </TableCell>
    )
  }

  const isRightContent = visibleColumnsIds || rightHeaderContent || withActions

  return (
    <>
      <TableRow className={classNames(tableStyles.pinnedHeader, tableStyles.tableRowHead)}>
        {selected && !withoutSelectedCount ? (
          <>
            <th className={styles.selectedHeaderCell}>
              <div className={styles.content}>
                <div className={styles.absoluteContent}>
                  <div
                    className={styles.wrapCheckbox}
                    aria-label={getAriaLabel('TableCheckboxHead', 'Selected')}
                  >
                    <Checkbox
                      checked={selected}
                      isIndeterminate={selected}
                      onChange={toggleSelected}
                    />
                  </div>

                  <HeaderLeftContent {...headerLeftContentProps} />
                </div>
              </div>
            </th>
            <th
              colSpan={pinnedColumns.length + unpinnedColumns.length + (isRightContent ? -1 : 0)}
            />
          </>
        ) : (
          <>
            {(!!pinnedColumns.length || withCheckbox) && (
              <PinnedCell
                isHeader
                isScrollLeft={isScrollLeft}
                withEmptyCell={pinnedColumns.length === 1 && !withCheckbox}
              >
                {withCheckbox && (
                  <CheckboxCell
                    selected={selected}
                    isIndeterminate={selected}
                    onChangeValue={toggleSelected}
                    ariaLabel='SelectAll'
                  />
                )}

                {pinnedColumns.map(columnCellRender)}
              </PinnedCell>
            )}
            {unpinnedColumns.map(columnCellRender)}
          </>
        )}

        {isRightContent && (
          <th className={classNames(styles.stickyRightContent, tableStyles.stickyRightContent)}>
            <div className={styles.actions}>
              {rightHeaderContent}
              {visibleColumnsIds && (
                <SimpleFilterDropdown
                  columns={columns}
                  visibleColumnsIds={visibleColumnsIds}
                  setVisibleColumnsIds={setVisibleColumnsIds}
                />
              )}
            </div>
          </th>
        )}
      </TableRow>
      {withSelectAll && selected && (
        <tr className={styles.actionsCenterRow}>
          <td className={styles.actionsCenterCell}>
            <div className={styles.content}>{<HeaderCenterContent />}</div>
          </td>
          <td colSpan={pinnedColumns.length + unpinnedColumns.length - 1} />
        </tr>
      )}
    </>
  )
}
