import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { AttachmentLoader } from 'shared/ui/Loader/AttachmentLoader/AttachmentLoader'
import { checkCDN } from 'shared/lib/checkCDN'
import { getAriaLabel } from 'shared/lib'
import { Avatar } from 'shared/ui'
import { type Attachment } from 'entities/Attachment/model/Attachment'
import styles from './styles.module.scss'

type Props = {
  attachment?: Attachment
  file?: File
  loading?: boolean
  onCardClick?: () => void
  small?: boolean
  medium?: boolean
  fullWidth?: boolean
  thumbnail?: boolean
  imgFitType?: 'cover' | 'contain'
}

const exceptExtension = ['vcf']

export const ImageCard: FC<Props> = observer(
  ({
    attachment,
    onCardClick,
    fullWidth,
    small,
    thumbnail,
    medium,
    file,
    loading,
    imgFitType = 'cover',
  }) => {
    const attachmentSource =
      attachment && thumbnail && attachment.thumbnail ? attachment.thumbnail : attachment?.source

    const isNoImage =
      attachmentSource && exceptExtension.includes(attachmentSource.split('.').pop() || '')
    return (
      <div
        className={classNames(styles.wrap, {
          [styles.small]: small,
          [styles.medium]: medium,
          [styles.fullWidth]: fullWidth,
          [styles.thumbnail]: thumbnail,
        })}
        onClick={onCardClick}
        aria-hidden={true}
        aria-label={getAriaLabel('ImageCard')}
      >
        <div
          className={classNames(styles.imgContainer, styles.img, {
            [styles.isNoImage]: isNoImage,
          })}
          aria-label={getAriaLabel('ImageCard', 'img')}
        >
          {isNoImage ? (
            <Avatar
              size={32}
              color='var(--gray-60)'
              info={{
                icon: 'personFilled',
              }}
              iconProps={{ fontSize: 20 }}
            />
          ) : (
            <img
              src={attachment ? attachmentSource : file && URL.createObjectURL(file)}
              alt=''
              style={{
                objectFit: imgFitType,
              }}
              {...(checkCDN(attachmentSource) ? { crossOrigin: 'use-credentials' } : null)}
            />
          )}
        </div>
        {loading && <AttachmentLoader />}
      </div>
    )
  }
)
