import { makeAutoObservable, runInAction } from 'mobx'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { contactsStore } from 'entities/Contacts'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import type { ConversationMessagesStore } from 'widgets/ConversationMessages'

export class ConversationMessageFieldAccessStore {
  private _isHideInboxAlert = false
  private _loading = true

  constructor(private _conversationMessagesStore: ConversationMessagesStore) {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get isHideInboxAlert() {
    return this._isHideInboxAlert
  }

  get isOnboardingFlow() {
    if (organizationStore.isTwilioNumberVendor) return false

    const inbox = inboxesStore.getItem(this.conversation?.inbox_id)

    if (inbox?.type !== 'inbox') return false

    return Boolean(inbox.isMainIdentifier || inbox.isTrialIdentifier)
  }

  get conversation() {
    return this._conversationMessagesStore.conversation
  }

  get conversationContact() {
    const contactId = this.conversation?.contact_id
    if (!contactId) return null

    const contact = contactsStore.getItem(contactId)
    if (!contact) return null

    return contact
  }

  get conversationInbox() {
    const inbox = inboxesStore.getItem(this.conversation?.inbox_id)

    if (inbox?.id === 0) return inboxesStore.sortedFirstInbox

    return inboxesStore.currentInbox || inbox
  }

  get conversationInboxNumber() {
    const inbox =
      (this.conversationInbox instanceof Inbox && this.conversationInbox) ||
      (inboxesStore.currentInbox instanceof Inbox && inboxesStore.currentInbox)

    const numberFromCurrentConversation = numbersStore.getItem(this.conversation?.number_id)
    const numberFromInbox =
      inbox instanceof Inbox ? numbersStore.getItem(inbox.numberId) : undefined

    return numberFromCurrentConversation || numberFromInbox
  }

  get isMessageLocal() {
    const conversation = this.conversation
    let inbox = inboxesStore.getItem(conversation?.inbox_id)

    if (conversation?.isNew) {
      inbox = inboxesStore.currentInbox
    }

    if (inbox?.type !== 'inbox') return false

    const number = numbersStore.getItem(inbox?.numberId)
    const contact = this.conversationContact

    if (contact?.isOptOutHard) return false
    if (number?.isShortCode) return false

    return this.isOptOut || this.isOptOutCurrentInbox
  }

  get isMessageAll() {
    const conversation = this.conversation
    let inbox = inboxesStore.getItem(conversation?.inbox_id)

    if (conversation?.isNew) {
      inbox = inboxesStore.currentInbox
    }

    if (inbox?.type !== 'inbox') return false

    const number = numbersStore.getItem(inbox?.numberId)
    const contact = this.conversationContact

    if (!contact) return false
    if (number?.isShortCode) return this.isOptOutCurrentInbox
    if (contact?.isOptOutHard) return true

    return Boolean(
      contact?.optItems.length === contact?.optItems.filter((item) => !item.value).length
    )
  }

  get isOptOut() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.isOptOut)
  }

  get isOptOutCurrentInbox() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.isOptOutCurrentInbox)
  }

  get isStatusDeclined() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusDeclined &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isStatusUnverified() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusUnverified &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isStatusBlocked() {
    return Boolean(
      (this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusBlocked &&
        !this.conversationInboxNumber?.is_aircall) ||
        this.isStatusBlockedContact
    )
  }

  get isStatusPending() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        !this.conversationInboxNumber?.is_aircall &&
        (this.conversationInboxNumber?.isStatusUnderVerification ||
          this.conversationInboxNumber?.isStatusInternalReview)
    )
  }

  get isStatusBlockedContact() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.is_blocked)
  }

  get isStatusNumberBlocked() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusBlocked &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  setHideInboxAlert = () => {
    this._isHideInboxAlert = true
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([
      inboxesStore.fetchInboxes(),
      usersStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
    ])

    runInAction(() => {
      this._loading = false
    })
  }
}
