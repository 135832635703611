import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, CopyWrapper } from 'shared/ui'
import { IResponseIntegrationHubspotDeal } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/AllView/styles.module.scss'
import { type ContactsDetailsStore } from 'widgets/ContactsDetails'
import { ItemDealRow } from 'widgets/ContactsDetails/ui/ContactsIntegrations/AllView/AllDeals/ItemDealRow'
type IDealProps = {
  deal: IResponseIntegrationHubspotDeal
  link: string
  contact: Contact
  store: ContactsDetailsStore
}

const Deal = observer(({ deal, link, contact, store }: IDealProps) => {
  const [open, setOpen] = useState(false)
  const hasShowMoreButton =
    Object.keys(deal).filter((key) => key !== 'dealname' && key !== 'id').length > 5
  return (
    <div className={styles.item}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={deal.dealname?.value || null}
          data={{
            title: deal.dealname?.value || 'View deal',
            link: link,
          }}
        />
      </div>
      {store.customTitleIcon && (
        <ItemDealRow deal={deal} integrationKey={store.viewIntegrationKey} fullList={open} />
      )}
      <div className={styles.actions}>
        {hasShowMoreButton && (
          <div className={styles.action}>
            <Button
              size='small'
              typeBtn='text'
              disabled={!contact}
              text={open ? 'Hide properties' : 'See properties'}
              onClick={() => {
                setOpen(!open)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
})
export { Deal }
