import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type ConnectTwilioModalStore } from 'entities/Phone/ui/ConnectTwilioModal'

type IConnectTwilioModalActionsProps = {
  store: ConnectTwilioModalStore
}

export const ConnectTwilioModalActions: FC<IConnectTwilioModalActionsProps> = observer(
  ({ store }) => {
    const { handleSubmit, loading, disabled } = store

    return (
      <Button
        fullWidth
        loading={loading}
        disabled={loading || disabled}
        typeBtn={'contained'}
        text={'Submit'}
        onClick={handleSubmit}
      />
    )
  }
)
