import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { sizeAlertCreator } from 'shared/lib/sizeAlertCreator'
import { usersStore } from 'entities/Users'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { airCallTooltip } from 'widgets/constants'

export const Alerts = observer(() => {
  const messageFieldStore = useMessageFieldContext()
  const {
    sendAlert,
    extError,
    limitSizeErrors,
    isAirCallNoSupportMms,
    isAttachmentsCountLimitError,
    textLimitReached,
    textLimit,
    isShowCustomShortUrlBanner,
    deleteLimitSizeError,
    handleCloseCustomShortUrlBanner,
  } = messageFieldStore

  const user = usersStore.user

  const handleToCustomShortUrl = () => {
    uiStore.changeRoute({
      path: '/settings/organization/custom-url-shortener',
      type: 'vue',
    })
  }

  return (
    <>
      {isShowCustomShortUrlBanner &&
        !(user?.isViewOnlyRole || user?.isMemberRole || user?.isManagerRole) && (
          <Alert
            item={{
              desc: 'NEW! Customize short URLs with your own domain name.',
              type: 'infoBlue',
            }}
            actions={
              <Button
                text={'Try it now'}
                typeBtn={'outlined'}
                size={'small'}
                contained={'secondary'}
                onClick={handleToCustomShortUrl}
              />
            }
            onClose={handleCloseCustomShortUrlBanner}
          />
        )}
      {textLimit.showAlert && textLimitReached && (
        <Alert
          item={{
            desc: textLimit.message,
            type: 'error',
          }}
        />
      )}
      {isAttachmentsCountLimitError && (
        <Alert
          item={{
            desc: 'You can send up to 5 attachments in one MMS',
            type: 'error',
          }}
        />
      )}
      {Boolean(limitSizeErrors.length) &&
        limitSizeErrors.map(({ label, limit }) => {
          const alertData = sizeAlertCreator({ label, limit })

          return (
            <Alert
              key={label}
              item={{
                desc: `${alertData.title}. ${alertData.desc}`,
                type: 'error',
              }}
              onClose={() => {
                deleteLimitSizeError(label)
              }}
            />
          )
        })}
      {extError && (
        <Alert
          item={{
            desc: extError,
            type: 'error',
          }}
          onClose={messageFieldStore.clearExtError}
        />
      )}
      {sendAlert && (
        <Alert
          item={{
            desc: sendAlert,
            type: 'error',
          }}
        />
      )}
      {isAirCallNoSupportMms && !featureFlagsStore.aircall_new_api && (
        <Alert
          item={{
            desc: airCallTooltip,
            type: 'error',
          }}
        />
      )}
    </>
  )
})
