import { makeAutoObservable, type IReactionDisposer, reaction } from 'mobx'
import { layoutStore } from 'shared/layout'
import { ActionItem } from 'shared/ui'
import { replaceLink } from 'shared/lib'
import { inboxesStore } from 'entities/Inbox'
import { IAIActionTypeEnum } from 'entities/AIAssistant'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { ShortLinkDropdownItem, shortLinkStore } from 'entities/ShortLink'
import { websocket } from 'entities/WebSocket'
import { usersStore } from 'entities/Users'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import { ConversationMessageFieldDraftStore } from 'widgets/ConversationMessages/store/conversationMessageFieldDraftStore'
import {
  EmojiAction,
  EnumVariantMessageField,
  MessageFieldStore,
  ShortenLinkAction,
} from 'widgets/MessageField'
import { ConversationSendAction } from 'widgets/ConversationMessages/ui/ConversationSendAction/ConversationSendAction'
import {
  MessageSignatureDropdownItem,
  MessageSignatureIconButton,
  MessageSignatureStore,
} from 'widgets/MessageField/ui/MessageSignature'
import { AIAssistantAction, AiAssistantStore } from 'widgets/AIAssistant'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { ConversationSavedRepliesAction } from 'widgets/ConversationMessages/ui/ConversationSavedRepliesAction/ConversationSavedRepliesAction'
import { MeetingsIconAction } from 'widgets/Meetings'
import { MergeFieldsDropdownItem } from 'widgets/MergeField/ui/actions/MergeFieldsDropdownItem'
import { IScheduledData } from 'widgets/ConversationSchedule/store/conversationScheduleStore'

export class ConversationMessageFieldStore {
  private readonly _conversationMessageFieldDraftStore: ConversationMessageFieldDraftStore
  private readonly _messageFieldStore: MessageFieldStore
  private readonly _messageSignatureStore: MessageSignatureStore
  private readonly _aiAssistantStore: AiAssistantStore

  private _canSendTyping = true
  private _throttleTime = 1000

  private _disposeIntegration: IReactionDisposer | null = null
  private _disposeConversationId: IReactionDisposer | null = null
  private _disposeIsAircall: IReactionDisposer | null = null
  private _disposeShowDoubleOptInButton: IReactionDisposer | null = null
  private _disposeOnGroup: IReactionDisposer | null = null

  constructor(private _conversationMessagesStore: ConversationMessagesStore) {
    makeAutoObservable(this)

    const isCurrentAirCall =
      inboxesStore.currentInbox?.type === 'inbox' && inboxesStore.currentInbox?.is_aircall

    this._conversationMessageFieldDraftStore = new ConversationMessageFieldDraftStore(this)
    this._messageFieldStore = new MessageFieldStore({
      styles: {
        minHeight: layoutStore.isMobileView ? 44 : undefined,
        maxHeight: layoutStore.isMobileView ? 122 : undefined,
        gap: layoutStore.isMobileView ? 0 : undefined,
      },
      isCurrentAirCall,
      showActionsItems: 5,
      makeEvents: () => ({
        onFocus: this.handleRead,
        onClick: this.handleRead,
        onKeyDown: this.onTyping,
        onInit: this._conversationMessageFieldDraftStore.handleSetDraft,
        onCloseEdit: this._conversationMessagesStore.onCloseEdit,
      }),
      variant: EnumVariantMessageField.Conversation,
      replaceMergeFieldsOnPaste: true,
      customSendAction: <ConversationSendAction />,
      withTrialText: true,
    })
    this._messageSignatureStore = new MessageSignatureStore({ parent: this._messageFieldStore })
    this._aiAssistantStore = new AiAssistantStore(this._messageFieldStore, {
      actions: [
        IAIActionTypeEnum.SuggestResponse,
        IAIActionTypeEnum.SummarizeConversation,
        IAIActionTypeEnum.Expand,
        IAIActionTypeEnum.MoreFormal,
        IAIActionTypeEnum.MoreFriendly,
        IAIActionTypeEnum.Rephrase,
        IAIActionTypeEnum.Shorten,
      ],
      getParamsCreateSummaryAnswer: this._conversationMessagesStore.getParamsCreateSummaryAnswer,
      getParamsCreateSuggestAnswer: this._conversationMessagesStore.getParamsCreateSuggestAnswer,
      additionalCreateSummaryAnswerAction: () => {
        this._messageFieldStore.setNoteMode(true)
      },
      isVariantContained: this.isModeNoteObj,
    })

    this._messageFieldStore.setActions(this.getMessageFieldActions(this._messageFieldStore))
  }

  get isModeNoteObj() {
    return this._conversationMessagesStore.isModeNoteObj
  }

  get messageSignatureStore() {
    return this._messageSignatureStore
  }

  get messageFieldStore() {
    return this._messageFieldStore
  }

  get conversationMessagesStore() {
    return this._conversationMessagesStore
  }

  get conversationMessageFieldDraftStore() {
    return this._conversationMessageFieldDraftStore
  }

  get conversation() {
    return this._conversationMessagesStore.conversation
  }
  get conversationId() {
    return this._conversationMessagesStore.conversationId
  }

  get contact() {
    return this._conversationMessagesStore.contact
  }

  get integration() {
    return this._conversationMessagesStore.integration
  }

  get currentInbox() {
    return this._conversationMessagesStore.currentInbox
  }

  get isGroup() {
    return this._conversationMessagesStore.isGroup
  }

  get showDoubleOptInButton() {
    return this._conversationMessagesStore.showDoubleOptInButton
  }

  nonGroupItem = <T,>(item: T): T | null => {
    if (this.isGroup) return null

    return item
  }

  getMessageFieldActions = (store: MessageFieldStore) => {
    const actions: (ActionItem | null)[] = [
      {
        iconButtonComponent: <MediaAction />,
      },
      this.nonGroupItem({
        iconButtonComponent: <ConversationSavedRepliesAction noMergeField />,
      }),
      {
        iconButtonComponent: <EmojiAction />,
      },
      {
        iconButtonComponent: <AIAssistantAction aiAssistantStore={this._aiAssistantStore} />,
      },
      {
        iconButtonComponent: (
          <MeetingsIconAction
            onAddMeetingLink={(link) => {
              store?.addContent &&
                store?.addContent((store?.hasText ? '\n\n' : '') + replaceLink(link) + '&nbsp;')
              return
            }}
            contact={this.contact}
            integration={this.integration}
          />
        ),
      },
      {
        dropdownItemComponent: (onCloseMenu) => (
          <ShortLinkDropdownItem
            onOpenModal={() => {
              const messageInnerText = this._messageFieldStore.messageInnerText

              shortLinkStore.setIsMarketing(false)
              shortLinkStore.setMessageText(messageInnerText)
            }}
            onAddShortLink={(link) => {
              store.addContent && store.addContent(replaceLink(link) + '&nbsp;')
            }}
            onCloseMenu={onCloseMenu}
          />
        ),
        iconButtonComponent: <ShortenLinkAction />,
      },
      this.nonGroupItem({
        dropdownItemComponent: () => (
          <MergeFieldsDropdownItem
            onAddMergeField={(filed) => {
              store?.addContent && store?.addContent(filed.value + '&nbsp;')
              return
            }}
            contact={this.contact}
            integration={this.integration}
          />
        ),
      }),
      {
        iconButtonComponent: <MessageSignatureIconButton store={this._messageSignatureStore} />,
        dropdownItemComponent: (onCloseMenu) => (
          <MessageSignatureDropdownItem
            store={this._messageSignatureStore}
            onCloseMenu={onCloseMenu}
          />
        ),
      },
    ]

    return actions.filter((action) => !!action) as ActionItem[]
  }

  dispose = () => {
    this._messageFieldStore.reset()
    this._messageFieldStore.handleResetMode()
  }

  initReactions = () => {
    this._conversationMessageFieldDraftStore.initReactions()
    this.reactionConversationId()
    this.reactionIntegration()
    this.reactionIsAircallInbox()
    this.reactionShowDoubleOptInButton()
    this.reactionOnGroup()
  }

  disposeReactions = () => {
    this._conversationMessageFieldDraftStore.disposeReactions()
    this._disposeConversationId?.()
    this._disposeIntegration?.()
    this._disposeIsAircall?.()
    this._disposeShowDoubleOptInButton?.()
    this._disposeOnGroup?.()
  }

  handleRead = async () => {
    try {
      const conversation = this.conversation
      if (!conversation) return

      if (!conversation?.isUnread && !conversation?.isUnreadManual) return

      const { data } = await ConversationsApi.updateByIdRead(conversation.id)
      conversationStore.updateItem(data)
    } catch (e) {
      console.error(e)
    }
  }

  onTyping = () => {
    if (this._canSendTyping) {
      websocket.sendEvent(
        `private-inbox.${this.conversation?.inbox_id}.conversation`,
        'client-typing',
        {
          id: usersStore.user?.id,
          first_name: usersStore.user?.first_name,
          last_name: usersStore.user?.last_name,
          conversation_id: this.conversation?.id,
        }
      )
      this._canSendTyping = false
      setTimeout(() => {
        this._canSendTyping = true
      }, this._throttleTime)
    }
  }

  setFocusMessageField = () => {
    setTimeout(() => {
      this._messageFieldStore.setFocusMessageFieldTrigger()
    })
  }

  getMessageToSend = (scheduledData?: IScheduledData) => {
    return { ...this._messageFieldStore?.messageRequestData, ...scheduledData }
  }

  reactionIntegration = () => {
    this._disposeIntegration?.()
    this._disposeIntegration = reaction(
      () => this.integration,
      () => {
        this._messageFieldStore?.setActions(this.getMessageFieldActions(this._messageFieldStore))
      }
    )
  }

  reactionConversationId = () => {
    this._disposeConversationId?.()
    this._disposeConversationId = reaction(
      () => this.conversationId,
      () => this._messageFieldStore.clearSendAlert()
    )
  }

  reactionIsAircallInbox = () => {
    this._disposeIsAircall?.()
    this._disposeIsAircall = reaction(
      () => [this.currentInbox, this.conversation],
      () => {
        const currentInbox = inboxesStore.getItem(this.conversation?.inbox_id)

        this._messageFieldStore.setIsCurrentAirCall(
          currentInbox?.type === 'inbox' ? currentInbox?.is_aircall : false
        )
      }
    )
  }

  reactionShowDoubleOptInButton = () => {
    this._disposeShowDoubleOptInButton?.()
    this._disposeShowDoubleOptInButton = reaction(
      () => this.showDoubleOptInButton,
      (showDoubleOptInButton) => {
        this._messageFieldStore.setIsDoubleOptInEnabled(showDoubleOptInButton)
      }
    )
  }

  reactionOnGroup = () => {
    this._disposeOnGroup?.()
    this._disposeOnGroup = reaction(
      () => this.isGroup,
      () => {
        this._messageFieldStore.setActions(this.getMessageFieldActions(this._messageFieldStore))
      }
    )
  }
}
