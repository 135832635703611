import classNames from 'classnames'
import { Typography } from 'shared/ui'
import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'

import { usersStore } from 'entities/Users'
import styles from '../styles.module.scss'

type IItemPropertyRowProps = {
  keyValue?: string
  label: string | null
  value: string | null
  clearAction?: () => void
  emptyPadding?: boolean
}

const ContactRow = ({
  value,
  label,
  keyValue,
  clearAction,
  emptyPadding,
}: IItemPropertyRowProps) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole
  const rightActions = [
    makeTextFieldInteractions(
      { type: 'copy', active: !!value },
      { type: 'email', active: keyValue === 'email' }
    ),
  ]

  const rightActionsLabelField = [
    makeTextFieldInteractions({ type: 'clear', active: !isUserViewOnly, action: clearAction }),
  ]
  const mainActions = [makeTextFieldTooltip()]

  const labelField = () => {
    if (clearAction) {
      return (
        <TextField
          className={styles.label}
          size='small'
          variant='outline'
          readOnly
          value={label || '-'}
          rightActions={rightActionsLabelField}
        />
      )
    }

    return (
      <Typography
        width={110}
        variant={'body-md-regular'}
        color={'var(--content-primary-tertiary)'}
        tag={'div'}
        tooltipProps={{
          label,
        }}
        ellipsis
        ariaLabel={'label'}
      >
        {label || '-'}
      </Typography>
    )
  }

  return (
    <div
      className={classNames(styles.itemRow, {
        [styles.emptyPadding]: emptyPadding,
      })}
    >
      {labelField()}
      <TextField
        className={styles.value}
        size='small'
        variant='outline'
        readOnly
        value={value || '-'}
        rightActions={rightActions}
        mainActions={mainActions}
      />
    </div>
  )
}

export { ContactRow }
