import type { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type {
  IParamsCreatePowerDialer,
  IResponsePowerDialer,
  PowerDialerStatus,
  ContactFinalStatus,
} from 'entities/PowerDialer/api/types'

class Api {
  createPowerDialer(
    params: IParamsCreatePowerDialer
  ): Promise<AxiosResponse<IResponsePowerDialer>> {
    return baseApi.post('/pd-campaigns', params)
  }

  getPowerDialerById(id: number): Promise<AxiosResponse<IResponsePowerDialer>> {
    return baseApi.get(`/pd-campaigns/${id}/view`)
  }

  editPowerDialer(
    id: number,
    params: Partial<IParamsCreatePowerDialer>
  ): Promise<AxiosResponse<IResponsePowerDialer>> {
    return baseApi.put(`/pd-campaigns/${id}`, params)
  }

  changePowerDialerStatus(
    id: number,
    status: PowerDialerStatus
  ): Promise<AxiosResponse<IResponsePowerDialer>> {
    return baseApi.put(`/pd-campaigns/${id}/${status}`)
  }

  resolveContactInPowerDialer(
    id: number,
    contactId: number,
    status: ContactFinalStatus
  ): Promise<AxiosResponse<IResponsePowerDialer>> {
    return baseApi.put(`/pd-campaigns/${id}/${contactId}`, { status })
  }

  keepPowerDialerAlive(id: number): Promise<AxiosResponse<boolean>> {
    return baseApi.post(`/pd-campaigns/${id}/keep-alive`)
  }
}

export const powerDialerApi = new Api()
