import classNames from 'classnames'
import { CardFilter, type ICardFilter, type ICardFilterProps } from './CardFilter/CardFilter'
import styles from './styles.module.scss'

type ICardsFilterProps<T = string> = {
  items: ICardFilter<T>[]
  wrapperClassName?: string
} & ICardFilterProps<T>

export const CardsFilter = <T,>({ items, wrapperClassName, ...props }: ICardsFilterProps<T>) => {
  return (
    <div className={classNames(styles.wrap, wrapperClassName)}>
      {items.map((item) => (
        <CardFilter key={item.key as string} item={item} {...props} />
      ))}
    </div>
  )
}
