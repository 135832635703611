import { makeObservable, observable } from 'mobx'
import { IActionsProps } from 'shared/ui'
import type {
  IResponseMedia,
  IResponseAttachmentUpload,
  IResponseMediaShort,
} from 'entities/Attachment'
import { getAttachmentType } from 'entities/Attachment/lib/getAttachmentType'
import { type IParsedVcard } from 'entities/VCard'
import { VCard } from 'entities/VCard/model/VCard'
import { type RequestAttachment } from './RequestAttachment'

export class Attachment {
  id: number | string = ''
  media_preview?: Attachment
  content_type = ''
  source?: string
  thumbnail?: string | null = null
  extension?: string | null = null
  source_short?: string
  original_source?: string
  created_at?: string
  duration?: number
  name?: string | null
  size?: number | null
  is_allowed_for_media_url?: boolean
  parsedVCard: Array<IParsedVcard> = []
  type: 'image' | 'gif' | 'video' | 'audio' | 'previewGif' | 'attachment' | 'voice' | 'vcard' =
    'attachment'
  vCard: VCard | null = null
  file?: File | null = null
  searchFragments: string[] = []
  userId: number | null = null
  loading = false
  actionsProps: IActionsProps | null = null

  constructor({
    responseMedia,
    responseUpload,
    requestAttachment,
    file,
    imageUrl,
    id,
    fromMediaUrl,
    isSave,
    responseMediaShort,
    name,
    loading = false,
  }: {
    responseMedia?: IResponseMedia
    responseMediaShort?: IResponseMediaShort
    responseUpload?: IResponseAttachmentUpload
    requestAttachment?: RequestAttachment
    file?: File
    imageUrl?: string
    id?: number | string
    fromMediaUrl?: boolean
    isSave?: boolean
    name?: string
    loading?: boolean
  }) {
    this.loading = loading

    if (requestAttachment) {
      this.id = requestAttachment.url
      this.source = requestAttachment.url
      this.name = requestAttachment.name || requestAttachment.url.split('/').pop()
    }
    if (responseMediaShort) {
      this.id = responseMediaShort.url || ''
      this.name = responseMediaShort.name || responseMediaShort.source?.split('/').pop()
      this.source = responseMediaShort.source || responseMediaShort.url
      this.is_allowed_for_media_url = true
      this.content_type = responseMediaShort.content_type || ''
      this.type = getAttachmentType({
        content_type: responseMediaShort.content_type || '',
        name: this.name || '',
      })
    }
    if (responseMedia) {
      this.id = responseMedia.id
      this.thumbnail = responseMedia.thumbnail
      this.extension = responseMedia.extension
      this.created_at = responseMedia.created_at
      this.duration = responseMedia.duration
      this.source = responseMedia.source
      this.name = responseMedia.name || responseMedia.source.split('/').pop()
      this.loading = Boolean(responseMedia.processing)
      this.size = Number(responseMedia.size)
      this.userId = Number(responseMedia.user_id)
    }
    if (responseUpload) {
      this.thumbnail = responseUpload.thumbnail
      this.extension = responseUpload.extension
      this.id = responseUpload.id || responseUpload.source
      this.duration = responseUpload.duration
      this.source = responseUpload.source
      this.name = responseUpload.name || responseUpload.source.split('/').pop()
      this.size = Number(responseUpload.size)
    }

    if (file) {
      const url = URL.createObjectURL(file)
      this.file = file
      this.id = url
      this.source = url
      this.name = file.name
      this.type = getAttachmentType({
        content_type: file.type,
        name: this.name || '',
      })
    }
    if (imageUrl) {
      this.id = id || imageUrl
      this.source = imageUrl
      this.type = 'image'
      this.is_allowed_for_media_url = fromMediaUrl
      this.name = name || imageUrl.split('/').pop()
    }

    const media = requestAttachment || responseMedia || responseUpload
    if (media) {
      this.source = this.source || media.source
      this.original_source = media.original_source
      this.source_short = media.source_short
      this.is_allowed_for_media_url = fromMediaUrl || !!media.is_allowed_for_media_url
      this.media_preview =
        media.media_preview &&
        new Attachment({
          responseMedia: media.media_preview,
        })

      this.content_type = media.content_type
      this.parsedVCard = this.getParsedVCard(media.parsedVCard)
      this.type = getAttachmentType({
        content_type: media.content_type,
        name: this.name || '',
        original_source: this.original_source,
        parsedVCard: this.parsedVCard,
      })
      this.vCard =
        (this.parsedVCard[0] &&
          new VCard({
            ...this.parsedVCard[0],
            isSave,
            fileName: media.name,
            source: media.source,
          })) ||
        null
    }

    this.searchFragments = [
      this.name,
      this.vCard?.first_name,
      this.vCard?.last_name,
      this.vCard?.email,
      this.vCard?.phone,
    ].filter((fragment) => fragment != null && !!fragment.length) as string[]

    makeObservable(this, {
      loading: observable,
    })
  }

  getParsedVCard(parsedVCard?: Array<IParsedVcard> | IParsedVcard): Array<IParsedVcard> {
    if (parsedVCard) {
      if (Array.isArray(parsedVCard)) {
        return parsedVCard
      } else {
        return [parsedVCard]
      }
    }
    return []
  }

  setLoading = (status: boolean) => {
    this.loading = status
  }

  setActionsProps: (actionProps: IActionsProps) => void = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }

  get isVcard() {
    return this.type === 'vcard'
  }
}
