import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { DropResult } from 'react-beautiful-dnd'
import { SpinnerLoader, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { Properties } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/ui/Properties/Properties'
import { Deals } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/ui/Deals/Deals'
import { Companies } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/ui/Companies/Companies'
import { Tickets } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/ui/Tickets/Tickets'
import { HubspotIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/store/hubspotIntegrationStore'
import { Tabs } from './Tabs'
import styles from './styles.module.scss'

const HubspotIntegration = observer(({ store }: { store: HubspotIntegrationStore }) => {
  const { integrationTab, handleIntegrationTab, contactIntegrationsStore } =
    useContactsDetailsContext()

  const {
    hubspotIntegration,
    contact,
    hubspotInfo,
    properties,
    newHubspotPropertiesDropdownStore,
    removeDraggableHubspotItem,
    showMorePropertiesLabel,
    hasShowMorePropertiesButton,
    initDraggableHubspotItems,
    setObjectType,
  } = store

  useEffect(() => {
    if (activeTab === EnumIntegrationTabs.Contact) {
      initDraggableHubspotItems()
      setObjectType('contact')
      newHubspotPropertiesDropdownStore.reset()
    }
  }, [])

  const [activeTab, setActiveTab] = useState<EnumIntegrationTabs>(
    integrationTab || EnumIntegrationTabs.Contact
  )

  const handleActiveTab = (tab: EnumIntegrationTabs) => {
    setActiveTab(tab)
    handleIntegrationTab(tab)
    if (tab === EnumIntegrationTabs.Contact) {
      setObjectType('contact')
    }
    if (tab === EnumIntegrationTabs.Companies) {
      setObjectType('company')
    }
    if (tab === EnumIntegrationTabs.Deals) {
      setObjectType('deal')
    }
    if (tab === EnumIntegrationTabs.Tickets) {
      setObjectType('ticket')
    }
  }

  const handleReorder = (result: DropResult) => {
    if (result.destination) {
      //
    }
  }

  if (!hubspotIntegration?.isConnected)
    return (
      <div className={styles.reconnectWrapper}>
        <Button
          typeBtn={'contained'}
          size={'medium'}
          contained={'primary'}
          fullWidth
          text='Reconnect'
          onClick={() => {
            uiStore.changeRoute({
              path: '/settings/integrations',
            })
          }}
        />
      </div>
    )

  const loading = contactIntegrationsStore.loading || contactIntegrationsStore.unlinking

  if (loading)
    return (
      <div className={classnames(styles.loaderWrap)}>
        <SpinnerLoader size={24} />
      </div>
    )

  const ActiveTab = observer(() => {
    if (activeTab === EnumIntegrationTabs.Deals)
      return (
        <Deals
          contact={contact}
          store={store}
          handleClear={(id, source_type) => {
            removeDraggableHubspotItem(id, source_type)
          }}
        />
      )
    if (activeTab === EnumIntegrationTabs.Companies)
      return (
        <Companies
          contact={contact}
          store={store}
          handleClear={(id, source_type) => {
            removeDraggableHubspotItem(id, source_type)
          }}
        />
      )
    if (activeTab === EnumIntegrationTabs.Contact)
      return (
        <Properties
          properties={properties}
          contact={contact}
          showMoreLabel={showMorePropertiesLabel}
          hasShowMoreButton={hasShowMorePropertiesButton}
          handleReorder={handleReorder}
          handleClear={(id, source_type) => {
            removeDraggableHubspotItem(id, source_type)
          }}
        />
      )
    if (activeTab === EnumIntegrationTabs.Tickets)
      return (
        <Tickets
          contact={contact}
          store={store}
          handleClear={(id, source_type) => {
            removeDraggableHubspotItem(id, source_type)
          }}
        />
      )

    return null
  })

  return (
    <div className={classnames(styles.wrap)}>
      <Tabs
        handleTab={handleActiveTab}
        activeTab={activeTab}
        count={{
          [EnumIntegrationTabs.Deals]: hubspotInfo?.deals?.length || 0,
          [EnumIntegrationTabs.Companies]: hubspotInfo?.companies?.length || 0,
          [EnumIntegrationTabs.Tickets]: hubspotInfo?.tickets?.length || 0,
        }}
      />
      <ActiveTab />
    </div>
  )
})

export { HubspotIntegration }
