import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Switch, Typography, Tooltip } from 'shared/ui'
import { IS_CALLING_APP_LIGHT } from 'shared/config'
import { callStore } from 'entities/Call'
import { organizationStore } from 'entities/Organization'
import { type IDeviceSettingsContentProps } from 'entities/DeviceSettings'
import styles from './styles.module.scss'

export const RecordAutoContent: FC<IDeviceSettingsContentProps> = observer(
  ({ deviceSettingsStore }) => {
    const disabled =
      callStore.isRecordLoading || organizationStore.isEnabledOrganisationCallAutoRecord
    const {} = deviceSettingsStore

    useEffect(() => {
      if (organizationStore.isEnabledOrganisationCallAutoRecord && !callStore.isRecord) {
        callStore.handleChangeRecord()
      }
    }, [])
    const content = () => {
      return (
        <div className={styles.dropdownWrap}>
          <div className={styles.dropdownBox}>
            <Typography
              className={styles.label}
              variant={'body-md-regular'}
              ariaLabel={'Record automatically'}
            >
              Record automatically
            </Typography>
          </div>
          <div className={styles.dropdownBox}>
            <Switch
              className={styles.switch}
              value={callStore.isRecord}
              disabled={disabled}
              onChange={() => {
                callStore.handleChangeRecord()
              }}
            />
          </div>
        </div>
      )
    }

    return (
      <div
        className={classnames(styles.section, styles['section--record'], {
          [styles.disabled]: organizationStore.isEnabledOrganisationCallAutoRecord,
        })}
      >
        {organizationStore.isEnabledOrganisationCallAutoRecord ? (
          <Tooltip
            fullWidth
            label='Call recording enabled globally'
            placement={IS_CALLING_APP_LIGHT ? 'bottom' : 'right'}
          >
            {content()}
          </Tooltip>
        ) : (
          <>{content()}</>
        )}
      </div>
    )
  }
)
