export enum FieldsEnum {
  companyName = 'companyName',
  role = 'role',
}

export enum RolesEnum {
  entrepreneur = 'entrepreneur',
  salesManager = 'salesManager',
  marketingManager = 'marketingManager',
  customerSuccessManager = 'customerSuccessManager',
  operationsManager = 'operationsManager',
  ITAdministrator = 'ITAdministrator',
  other = 'other',
}

export type IRole = {
  id: RolesEnum
  name: string
}
