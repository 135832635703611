// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qecozKREETihmfmZODgR{height:60px;padding-top:24px;padding-right:20px;display:flex;justify-content:flex-end}.oJCLv6UcnJ_4TKvbbyvD{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignView/ui/MobilePreview/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CACA,kBAAA,CACA,YAAA,CACA,wBAAA,CAEF,sBACE,YAAA,CACA,sBAAA","sourcesContent":[".header {\n  height: 60px;\n  padding-top: 24px;\n  padding-right: 20px;\n  display: flex;\n  justify-content: flex-end;\n}\n.wrapPreview {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "qecozKREETihmfmZODgR",
	"wrapPreview": "oJCLv6UcnJ_4TKvbbyvD"
};
export default ___CSS_LOADER_EXPORT___;
