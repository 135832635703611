import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { type GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'
import styles from './styles.module.scss'

type IGetNumberModalActionsProps = {
  store: GetNumberModalStore
}

export const GetNumberModalActionsDefault: FC<IGetNumberModalActionsProps> = observer(
  ({ store }) => {
    const { handleAddNumber, selectedNumber, loading } = store

    return (
      <div className={styles.actionsWrap} aria-label={getAriaLabel('GetNumberModalActions')}>
        <Button
          disabled={!selectedNumber || loading}
          fullWidth
          typeBtn={'contained'}
          text={'Next'}
          onClick={() => {
            handleAddNumber()
          }}
        />
        <Typography
          variant={'body-xs-regular'}
          color={'var(--content-primary-tertiary)'}
          className={styles.text}
          ariaLabel={'Aircall number'}
        >
          You can always change this number later or replace with your existing number, landline, or
          Aircall number.
        </Typography>
      </div>
    )
  }
)
