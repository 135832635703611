import React from 'react'
import { Type } from 'shared/ui'

export type IBroadcastType = 'sms' | 'ringless'

type IBroadcastTypeProps = { type: IBroadcastType }

export const BroadcastType = ({ type }: IBroadcastTypeProps) => {
  return <Type type={type} />
}
