// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".znkucNut37r1B8MxMWCw{width:260px;border-radius:8px;background:var(--background-primary-inverted-primary);box-shadow:var(--menu-shadow)}.znkucNut37r1B8MxMWCw.W332177YhyoJt2e_LOjg{width:310px}", "",{"version":3,"sources":["webpack://./src/widgets/Meetings/ui/MeetingsIconAction/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,iBAAA,CACA,qDAAA,CACA,6BAAA,CAEA,2CACE,WAAA","sourcesContent":[".dropdown {\n  width: 260px;\n  border-radius: 8px;\n  background: var(--background-primary-inverted-primary);\n  box-shadow: var(--menu-shadow);\n\n  &.full {\n    width: 310px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "znkucNut37r1B8MxMWCw",
	"full": "W332177YhyoJt2e_LOjg"
};
export default ___CSS_LOADER_EXPORT___;
