import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { type ITrigger, Trigger } from 'entities/Trigger/model/Trigger'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import { TriggerDetail } from 'widgets/TriggerDetail'
import { TriggersRoutes } from 'pages/triggers'
import { useTriggersPageContext } from 'pages/triggers/context'

export const DetailTrigger = () => {
  const { pageLayoutStore } = useTriggersPageContext()
  const [loading, setLoading] = useState(true)
  const [trigger, setTrigger] = useState<ITrigger | null>(null)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      TriggerApi.getTriggerById(id)
        .then(({ data }) => {
          if (data) {
            const triggerInstance = new Trigger(data)
            setTrigger(triggerInstance)
          }
        })
        .catch((e) => {
          navigate(`/${TriggersRoutes.root}/${TriggersRoutes.all}`)
          console.error(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id])

  if (loading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }
  if (trigger) {
    return <TriggerDetail trigger={trigger} pageLayoutStore={pageLayoutStore} />
  } else {
    return <div>Not found</div>
  }
}
