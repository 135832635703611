import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { dateFromNow, dateFromNowStrict, numberFormat } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { channelsOrganization } from 'entities/Organization/channels/organization'
import { OrganizationApi } from 'entities/Organization/api/organization'
import { usersStore } from 'entities/Users'
import { segmentsStore } from 'entities/Segment'
import type {
  IResponseEventOrganizationCalling,
  IResponseEventUpdateBalance,
  IResponseNumberVendor,
  IResponseOrganization,
  IResponseOrganizationCall,
} from 'entities/Organization/api/types'
import { subscriptionStore } from 'entities/Subscription'

export class OrganizationStore {
  initPromise
  private _setFinishInit: ((value?: unknown) => void) | null = null
  private _loading = false
  private _accountCredits = 0
  private _trialCredits = 0
  private _id: number | null = null
  private _owner_id: number | null = null
  private _account_balance: number | null = null
  private _name: string | null = null
  private _created_at: string | null = null
  private _organization_call: IResponseOrganizationCall | null = null
  private _double_opt_in: { is_active: boolean } = { is_active: false }
  private _trial_ends_at = ''
  private _number_vendor: IResponseNumberVendor | null = null
  private _is_agency = false
  private _agency_id: number | null = null
  private _is_contract = false
  private _fallback_team_id: number | null = null

  private _trialDaysMaxTotal = 14
  private _isTrialEnded = true
  private _updateTrialEndsAtStringTrigger = ''
  private _updateTrialEndsAtStringIdInterval: NodeJS.Timeout | null = null
  private _broadcast_limits: IResponseOrganization['broadcast_limits'] | null = null
  private _phone_checker: IResponseOrganization['phone_checker'] | null = null

  constructor() {
    makeAutoObservable(this)
    reaction(() => this._updateTrialEndsAtStringTrigger, this.updateTrialEndsAtString)

    this.initPromise = new Promise((resolve) => {
      this._setFinishInit = resolve
    })
  }

  get is_contract() {
    return this._is_contract
  }

  get isTrialEnded() {
    return this._isTrialEnded
  }

  get loading() {
    return this._loading
  }

  get broadcast_limits() {
    return this._broadcast_limits
  }

  get trialDaysMaxTotal() {
    return this._trialDaysMaxTotal
  }

  get trialCredits() {
    return this._trialCredits
  }

  get accountCredits() {
    return this._accountCredits
  }

  get organization_call() {
    return this._organization_call
  }

  get agency_id() {
    return this._agency_id
  }

  get account_balance() {
    return this._account_balance
  }

  get name() {
    return this._name
  }

  get created_at() {
    return this._created_at
  }

  get id() {
    return this._id
  }

  get owner() {
    return usersStore.getItem(this._owner_id)
  }

  get isTwilioNumberVendor() {
    return this._number_vendor?.key === 'twilio-integration'
  }

  get trialEndsAtString() {
    if (this._isTrialEnded) return ''

    return dateFromNow(this.trialEndsAt)
  }

  get strictTrialEndsAtString() {
    if (this._isTrialEnded) return ''

    return dateFromNowStrict(this.trialEndsAt)
  }

  get isSinchNumberVendor() {
    return this._number_vendor?.key === 'sinch'
  }

  updateTrialEndsAtString = () => {
    const remainingDiff = uiStore
      .dayjs(organizationStore.trialEndsAt)
      .diff(uiStore.dayjs(), 'day', true)
    const trialEndsAtDays = Math.ceil(remainingDiff)
    this._isTrialEnded = trialEndsAtDays <= 0

    if (this._isTrialEnded) {
      this._updateTrialEndsAtStringIdInterval &&
        clearInterval(this._updateTrialEndsAtStringIdInterval)
    }
  }

  fetchOrganization = async () => {
    try {
      this._loading = true

      const { data: organization } = await OrganizationApi.getOrganizationCurrent()

      runInAction(() => {
        this._id = organization.id
        this._owner_id = organization.owner_id
        this._accountCredits = organization.account_credits
        this._trialCredits = organization.trial_credits
        this._account_balance = organization.account_balance
        this._name = organization.name
        this._created_at = organization.created_at
        this._organization_call = organization.organization_call
        this._double_opt_in = organization.double_opt_in
        this._trial_ends_at = organization.trial_ends_at
        this._number_vendor = organization.number_vendor
        this._is_agency = organization.is_agency
        this._agency_id = organization.agency_id
        this._broadcast_limits = organization.broadcast_limits
        this._phone_checker = organization.phone_checker
        this._is_contract = !!organization.is_contract
        this._fallback_team_id = organization.fallback_team?.id || null

        if (organization.trial_ends_at) {
          this._updateTrialEndsAtStringTrigger = nanoid()
          this._updateTrialEndsAtStringIdInterval = setInterval(() => {
            runInAction(() => {
              this._updateTrialEndsAtStringTrigger = nanoid()
            })
          }, 1000)
        }

        channelsOrganization.subscribeChannels(this)
        segmentsStore.addItems(organization.organization_filters)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  init = async () => {
    await this.fetchOrganization()
    this._setFinishInit?.()
  }

  updateBalance = (data: IResponseEventUpdateBalance) => {
    this._accountCredits = data.account_credits
    this._trialCredits = data.trial_credits
    this._account_balance = data.account_balance
  }

  updateOrganizationCall = (data: IResponseEventOrganizationCalling) => {
    this._organization_call = data.organization_call
  }

  updatePhoneCheckerEnable = async (status: boolean) => {
    try {
      const { data } = await OrganizationApi.updatePhoneCheckerEnable({
        check_existing_contacts: status,
      })

      this._phone_checker = data
    } catch (e) {
      console.log(e)
    }
  }

  updateFallbackTeamId = async (id: number) => {
    runInAction(() => {
      this._fallback_team_id = id
    })
    const { data } = await OrganizationApi.updateFallbackTeamId(id)
    if (data?.fallback_team_id !== this._fallback_team_id) {
      runInAction(() => {
        this._fallback_team_id = data?.fallback_team_id || null
      })
    }
  }

  get doubleOptInEnabled() {
    return this._double_opt_in.is_active
  }

  get isOutOfAccountCredits() {
    return this._accountCredits <= 0
  }

  get isOutOfCredits() {
    const { isTrial } = subscriptionStore
    const credits = isTrial ? this._trialCredits : this._accountCredits

    return credits <= 0
  }

  get trialEndsAt() {
    return this._trial_ends_at
  }

  get trialEndsAtDaysNumber() {
    return Math.round(Math.abs(uiStore.dayjs().diff(this._trial_ends_at, 'hour') / 24))
  }

  get formattedAccountCredits() {
    return numberFormat({ value: this._accountCredits })
  }

  get formattedTrialCredits() {
    return numberFormat({ value: this._trialCredits })
  }

  get isOutOfTrialCredits() {
    return this._trialCredits <= 0
  }

  get isAgency() {
    return this._is_agency
  }

  get accountBalance() {
    if (!this._account_balance) return 0

    return this._account_balance
  }

  get isBillableNumberVendor() {
    return Boolean(this._number_vendor?.isBillable)
  }

  get isOrganizationOwner() {
    return usersStore.user?.id === this._owner_id
  }

  get isPhoneCheckerEnabled() {
    return Boolean(this._phone_checker?.is_active)
  }

  get isEnabledOrganisationCallAutoRecord() {
    return this._organization_call?.call_auto_record?.is_active
  }

  get numberVendor() {
    return this._number_vendor
  }

  get fallbackTeamId() {
    return this._fallback_team_id
  }
}

export const organizationStore = new OrganizationStore()
