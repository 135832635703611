import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { ChatbotActionSendMessage } from 'entities/Chatbot'
import { Attachment } from 'entities/Attachment/model/Attachment'
import {
  EmojiAction,
  EnumVariantMessageField,
  MessageFieldStore,
  SavedRepliesAction,
  ShortenLinkAction,
  makeSavedReplyInputAction,
  mergeFieldReplacer,
} from 'widgets/MessageField'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import { SavedRepliesStore } from 'widgets/SavedReplies'

export class ChatbotActionMessageStore {
  private _disposeMessageReaction: IReactionDisposer | null = null
  private _savedRepliesStore = new SavedRepliesStore()

  message = new MessageFieldStore({
    placeholder: 'Write a message...',
    styles: {
      minHeight: 106,
    },
    variant: EnumVariantMessageField.Default,
    showActionsItems: 5,
    resetOnUnmount: false,
    textLimit: {
      maxLength: 1600,
      message: 'Message should not be longer than 1.600 characters',
    },
    makeInputActions: (store) => [makeSavedReplyInputAction(store, this._savedRepliesStore)],
    makeActions: () => [
      { iconButtonComponent: <MediaAction /> },
      { iconButtonComponent: <SavedRepliesAction store={this._savedRepliesStore} /> },
      { iconButtonComponent: <EmojiAction /> },
      {
        iconButtonComponent: (
          <MergeFieldsIconAction
            onAddMergeField={(value) => this._handleMergeField(value)}
            isAllIntegration
          />
        ),
      },
      { iconButtonComponent: <ShortenLinkAction /> },
    ],
  })

  constructor(private _model: ChatbotActionSendMessage) {
    makeAutoObservable(this)

    this._disposeMessageReaction = reaction(
      () => [this.message.text, this.message.attachments] as [string, Attachment[]],
      ([text, attachments]) => {
        const prevAttachments = this._model.attachments
        const attachmentsAreSame =
          prevAttachments.length === attachments.length &&
          prevAttachments.every((attachment) => attachments.includes(attachment))

        const prevText = this._model.message
        const textIsSame = prevText === text

        if (attachmentsAreSame && textIsSame) return

        this._model.sync(text, attachments)
      }
    )
  }

  syncMessage = () => {
    this.message.setMessageData({
      message: this._model.message,
      attachments: this._model.attachments,
      isReset: false,
      isReplace: true,
      isFocus: false,
    })
  }

  dispose = () => {
    this._disposeMessageReaction?.()
  }

  private _handleMergeField = (field: MergeField) => {
    if (!this.message.addContent) return

    const fieldContent = mergeFieldReplacer(field.template) + '&nbsp;'

    this.message.addContent(fieldContent)
  }
}
