import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, type IIconButtonProps } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallHoldPopUpButton: React.FC<Partial<IIconButtonProps>> = observer((props) => {
  const store = useCallPopUpDefaultContext()
  const { isHold, isHoldLoading, handleHoldToggle, isHoldDisabled, innerRefTooltipCurrent } =
    store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'blue'}
        variant={'custom'}
        active={isHold}
        ariaLabel='CallHoldPopUpButton_hold'
        onClick={() => {
          handleHoldToggle()
        }}
        loading={isHoldLoading}
        disabled={isHoldDisabled}
        spinnerLoaderProps={{
          determinatecolor: 'var(--gray-30)',
          indeterminatecolor: 'var(--gray-70)',
        }}
        iconProps={{
          icon: isHold ? 'play1' : 'pause1',
        }}
        tooltipProps={{
          label: isHold ? 'Resume' : 'Put on hold',
          placement: 'top',
          zIndex: 5001,
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
        {...props}
      />
    </div>
  )
})
