import React, { FC, useEffect, useState } from 'react'
import { Button } from 'shared/ui'

type IWebhookCopyButtonProps = {
  url: string
}

export const WebhookCopyButton: FC<IWebhookCopyButtonProps> = ({ url }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [copied])

  const onCopyUrl = () => {
    if (!url) return
    window.navigator.clipboard.writeText(url)
    setCopied(true)
  }

  const dynamicProps = copied
    ? ({
        text: 'Copied',
      } as const)
    : ({
        text: 'Copy URL',
        icon: 'copy',
        iconProps: {
          padding: '4px 0',
        },
      } as const)

  return <Button disabled={!url} onClick={onCopyUrl} fullWidth {...dynamicProps} />
}
