// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LHOazq0XZsLY03VQVcKw .FF617mY06rh6aKI6_AH3{padding:12px 8px 12px 12px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/tollFree/pages/ContactInfo/ui/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,0BAAA","sourcesContent":[".wrap {\n  .inputPadding {\n    padding: 12px 8px 12px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "LHOazq0XZsLY03VQVcKw",
	"inputPadding": "FF617mY06rh6aKI6_AH3"
};
export default ___CSS_LOADER_EXPORT___;
