import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Button, Divider, IconButton, Status, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers/route/type'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { EstimatedResponseInfo } from 'pages/settings/pages/compliance/ui/EstimatedResponseInfo/EstimatedResponseInfo'
import styles from './styles.module.scss'

export const SummaryLocalNumbers = observer(() => {
  const { localNumbersPageStore } = useCompliancePageContext()
  const {
    complianceServiceBrand,
    localNumberCampaignStatus,
    businessInformation,
    brandIconCondition,
    useCaseIconCondition,
    showUseCaseStatus,
    showBrandStatus,
    useCaseName,
    complianceServiceCampaign,
    localNumberBrandStatus,
    openBrandSummaryModal,
    openUseCaseSummaryModal,
  } = localNumbersPageStore

  return (
    <>
      {showBrandStatus && (
        <div className={classNames(styles.wrap, styles.afterRegisterBrandSection)}>
          {(complianceServiceBrand?.is2FAPending ||
            complianceServiceBrand?.is2FAActionRequired) && (
            <>
              <div className={classNames(styles.infoContainer, styles.verificationContainer)}>
                <div className={styles.leftContent}>
                  <Typography ariaLabel='AfterRegisterSection_2FA_header' variant='body-md-medium'>
                    Brand 2FA verification
                  </Typography>
                  <Typography
                    ariaLabel='AfterRegisterSection_descLine1'
                    variant='body-md-regular'
                    color='var(--content-primary-tertiary)'
                    marginTop='4px'
                    marginBottom='12px'
                  >
                    {`Check and verify your email "${complianceServiceBrand?.business_email_verification?.email}"`}
                  </Typography>
                  <Status emphasis='high' intent='informative' title={'Pending'} icon='clock' />
                </div>
              </div>
              <Divider className={styles.divider} style={{ margin: '20px 0' }} />
            </>
          )}

          <div className={styles.infoContainer} onClick={openBrandSummaryModal}>
            <div className={styles.leftContent}>
              <Typography ariaLabel='AfterRegisterSection_header' variant='body-md-medium'>
                Brand
              </Typography>
              <Typography
                ariaLabel='AfterRegisterSection_descLine1'
                variant='body-md-regular'
                color='var(--content-primary-tertiary)'
              >
                {businessInformation?.business_name || ''}
              </Typography>
              {brandIconCondition && (
                <EstimatedResponseInfo
                  dayCount={1}
                  labelPart='Brand'
                  iconData={brandIconCondition}
                  status={localNumberBrandStatus || null}
                  submittedAt={complianceServiceBrand?.submittedAt}
                />
              )}
            </div>
            <div className={styles.rightContent}>
              {complianceServiceBrand?.isStatusFailed && (
                <Button
                  text='Update'
                  onClick={(event) => {
                    event.stopPropagation()
                    uiStore.changeRoute({
                      path: `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.campaignUseCases}`,
                    })
                  }}
                />
              )}
              <IconButton
                ariaLabel='AfterRegisterSection_buttonRight'
                icon={'chevronRight'}
                size={'small'}
                variant='transparent'
              />
            </div>
          </div>
        </div>
      )}
      {showUseCaseStatus && (
        <div className={classNames(styles.wrap, styles.afterRegisterUseCaseSection)}>
          <div className={styles.infoContainer} onClick={openUseCaseSummaryModal}>
            <div className={styles.leftContent}>
              <Typography ariaLabel='AfterRegisterSection_header' variant='body-md-medium'>
                Use case campaign
              </Typography>
              <Typography
                ariaLabel='AfterRegisterSection_descLine1'
                variant='body-md-regular'
                color='var(--content-primary-tertiary)'
              >
                {useCaseName}
              </Typography>
              {useCaseIconCondition && (
                <EstimatedResponseInfo
                  dayCount={4}
                  labelPart='Use Case'
                  iconData={useCaseIconCondition}
                  status={localNumberCampaignStatus || null}
                  submittedAt={complianceServiceCampaign?.submittedAt}
                />
              )}
            </div>
            <div className={styles.rightContent}>
              {complianceServiceCampaign?.isStatusFailed && (
                <Button
                  text='Update'
                  onClick={(event) => {
                    event.stopPropagation()
                    uiStore.changeRoute({
                      path: `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.campaignUseCases}`,
                    })
                  }}
                />
              )}
              <IconButton
                ariaLabel='AfterRegisterSection_buttonRight'
                icon={'chevronRight'}
                size={'small'}
                variant='transparent'
                onClick={() => {
                  uiStore.changeRoute({
                    path: `/${SettingsPath.compliance.localNumbers.root}`,
                    type: 'vue',
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
})
