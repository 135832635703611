import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { airCallTooltip } from 'widgets/constants'
import { type SavedRepliesStore } from 'widgets/SavedReplies'
import { SavedRepliesContext } from 'widgets/SavedReplies/context'
import { LeftContent } from './LeftContent/LeftContent'
import { RightContent } from './RightContent/RightContent'
import { SavedRepliesSearch } from './Header/SavedRepliesSearch/SavedRepliesSearch'
import styles from './styles.module.scss'

type ISavedRepliesProps = {
  isCurrentAirCall?: boolean
}

const SavedReplies: FC<ISavedRepliesProps> = observer(({ isCurrentAirCall }) => {
  return (
    <div>
      {isCurrentAirCall && !featureFlagsStore.aircall_new_api && (
        <div className={styles.alerts}>
          <Alert item={{ type: 'warning', title: airCallTooltip }} />
        </div>
      )}
      <div className={styles.header}>
        <SavedRepliesSearch />
      </div>
      <div className={styles.content}>
        <LeftContent />
        <RightContent />
      </div>
    </div>
  )
})

type ISavedRepliesContentProps = {
  savedRepliesStore: SavedRepliesStore
  isCurrentAirCall?: boolean
}

export const SavedRepliesContent: FC<ISavedRepliesContentProps> = ({
  savedRepliesStore,
  isCurrentAirCall,
}) => {
  return (
    <SavedRepliesContext.Provider value={savedRepliesStore}>
      <SavedReplies isCurrentAirCall={isCurrentAirCall} />
    </SavedRepliesContext.Provider>
  )
}
