// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jWgk8DOuENp8kVHDfQAD{display:flex;padding:12px 16px 16px 16px;flex-direction:column;gap:12px;color:var(--content-primary-primary)}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignMessage/ui/MessageContent/MessageActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,2BAAA,CACA,qBAAA,CACA,QAAA,CACA,oCAAA","sourcesContent":[".wrapTestSMS {\n  display: flex;\n  padding: 12px 16px 16px 16px;\n  flex-direction: column;\n  gap: 12px;\n  color: var(--content-primary-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTestSMS": "jWgk8DOuENp8kVHDfQAD"
};
export default ___CSS_LOADER_EXPORT___;
