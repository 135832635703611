import { CircularProgress } from './CircularProgress'
import styles from './styles.module.scss'

export type ICircularProgressWithBackgroundProps = {
  variant?: 'determinate' | 'indeterminate'
  size?: number
  customColor?: string
  value: number
}

export const CircularProgressWithBackground = ({
  variant = 'determinate',
  size = 16,
  customColor = 'var(--light-blue-60)',
  value,
}: ICircularProgressWithBackgroundProps) => {
  return (
    <div className={styles.wrap}>
      <CircularProgress
        variant={variant}
        value={100}
        size={size}
        sx={{ color: 'var(--black-20)', position: 'absolute' }}
      />
      <CircularProgress variant={variant} value={value} customColor={customColor} size={size} />
    </div>
  )
}
