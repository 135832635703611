import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { type QuestionnaireOrSelectNumberModalStore } from 'entities/Phone/ui/QuestionnaireOrSelectNumberModal'
import styles from './styles.module.scss'

type IGetNumberPFTModalActionsProps = {
  store: QuestionnaireOrSelectNumberModalStore
}

export const QuestionnaireOrSelectNumberModalActions: FC<IGetNumberPFTModalActionsProps> = observer(
  ({ store }) => {
    return (
      <div className={styles.actionsWrap} aria-label={getAriaLabel('GetNumberModalActions')}>
        {store.hideBack && (
          <Button
            typeBtn={'contained'}
            contained={'secondary'}
            text={'Back'}
            size={'medium'}
            onClick={store.backStep}
          />
        )}

        <Button
          disabled={store.disabled}
          typeBtn={'contained'}
          text={store.textSubmit}
          size={'medium'}
          onClick={store.onNext}
        />
      </div>
    )
  }
)
