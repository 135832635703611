import { makeAutoObservable } from 'mobx'
import { type IResponseBusinessProfile } from 'entities/Compliance'
import { type BusinessInformation } from 'entities/Compliance/model'

export class BusinessProfile {
  private _twilio_service_errors: IResponseBusinessProfile['twilio_service_errors'] | null = null
  private _status: IResponseBusinessProfile['status'] | null = null
  private _created_at: IResponseBusinessProfile['created_at'] | null = null
  private _submitted_at: IResponseBusinessProfile['submitted_at'] | null = null

  constructor(
    response: IResponseBusinessProfile | null,
    private _businessInformation: BusinessInformation | null
  ) {
    makeAutoObservable(this)

    if (response) {
      this.syncData(response)
    }
  }

  syncData = (response: IResponseBusinessProfile) => {
    this._twilio_service_errors = response.twilio_service_errors
    this._status = response.status || null
    this._created_at = response.created_at
    this._submitted_at = response.submitted_at
  }

  get created_at() {
    return this._created_at
  }

  get submitted_at() {
    return this._submitted_at
  }

  get status() {
    return this._status
  }

  get twilio_service_errors() {
    return this._twilio_service_errors
  }

  get isStatusEmpty() {
    return !this._status
  }

  get isNotReadyForTollFree() {
    return this.isStatusEmpty || this.isStatusInProgress
  }

  get isNotReady() {
    return this.isStatusEmpty || this.isStatusInProgress || this.isStatusInCarrierReview
  }

  get isStatusInProgress() {
    return Boolean(this.isStatusEmpty && this._businessInformation?.reg_progress_status)
  }

  get isStatusInCarrierReview() {
    return this._status === 'under_review' // <=> In Carrier Review
  }

  get isStatusPendingReview() {
    return this._status === 'in_review' // <=> pending
  }

  get isStatusApproved() {
    return this._status === 'approved'
  }

  get isStatusFailed() {
    return this._status === 'failed'
  }
}
