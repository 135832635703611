import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { Box, Typography, Error, Button, Alert, InputCheckbox, Tooltip, Icon } from 'shared/ui'
import {
  makeTextFieldAutoFocus,
  makeInputPattern,
  TextField,
  makeTextFieldHighlight,
} from 'shared/ui/TextField'
import { type TwoFaModalStore } from 'widgets/TwoFaModal/store/twoFaModalStore'

import styles from './styles.module.scss'

const InputCode = makeInputPattern({
  format: '######',
  placeholder: '000000',
})

type IProps = {
  store: TwoFaModalStore
}

export const TwoFaCodeModalContent = observer(({ store }: IProps) => {
  const {
    setTwoFaCode,
    twoFaCode,
    loading,
    twoFaMethod,
    twoFaCodeError,
    isValidTwoFaCode,
    onVerifyCode,
    twoFaLabel,
    shouldSendTwoFaCode,
    resendTwoFaCode,
    openMethodModal,
    hasResendTimer,
    twoFaSettings,
    resendText,
    disabledCodeResend,
    twoFaCodeAlertError,
    toggleRemember,
    remember,
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValidTwoFaCode) {
      event.preventDefault()
      onVerifyCode()
    }
  }

  return (
    <div className={styles.wrap}>
      {twoFaCodeAlertError && (
        <Box marginBottom={'36px'}>
          <Alert
            item={{
              type: 'error',
              desc: twoFaCodeAlertError,
            }}
          />
        </Box>
      )}

      <Typography
        color={'var(--content-primary-primary)'}
        variant={'body-md-medium'}
        ariaLabel={'twoFaCodeModalTitle'}
      >
        Enter the 6-digit code
      </Typography>
      <Typography
        className={classNames(styles.twoFaLabel)}
        color={'var(--content-primary-tertiary)'}
        variant={'body-md-regular'}
        ariaLabel={'twoFaCodeModalLabel'}
      >
        {parse(twoFaLabel)}
      </Typography>
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: twoFaCodeError,
          [styles.disabled]: disabledCodeResend,
        })}
        Input={InputCode}
        InputProps={{ onKeyDown }}
        variant='stroke'
        size='medium'
        value={twoFaCode}
        disabled={loading || disabledCodeResend}
        onChange={setTwoFaCode}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlight({ mode: 'error', highlight: !!twoFaCodeError }),
        ]}
      />
      <Error type={'text'} error={twoFaCodeError} />
      {shouldSendTwoFaCode && !hasResendTimer && (
        <div className={styles.wrapResend}>
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'body-md-regular'}
            ariaLabel={'resendTwoFaCode'}
          >
            Haven’t received it?
          </Typography>
          <Button
            className={styles.linkBtn}
            typeBtn='link'
            type='button'
            onClick={resendTwoFaCode}
            disabled={loading}
            text={'Resend new code'}
          />
        </div>
      )}
      {!hasResendTimer && twoFaSettings?.length !== 1 && (
        <div className={classNames({ [styles.wrapResend]: twoFaMethod?.type === 'app' })}>
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'body-md-regular'}
            ariaLabel={'backToMethods'}
          >
            Use a different
          </Typography>
          <Button
            className={styles.linkBtn}
            typeBtn='link'
            type='button'
            onClick={() => openMethodModal(store)}
            disabled={loading}
            text={'method'}
          />
        </div>
      )}

      {hasResendTimer && (
        <div className={styles.wrapResend}>
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'body-md-regular'}
            ariaLabel={'resendTimer'}
          >
            {resendText}
          </Typography>
        </div>
      )}

      <InputCheckbox
        checked={remember}
        onChecked={toggleRemember}
        className={styles.rememberCheckbox}
        customLabel={
          <div className={styles.rememberCheckboxLabel}>
            <Typography
              color={'var(--content-primary-tertiary)'}
              variant={'body-md-regular'}
              ariaLabel={'rememberCheckbox'}
            >
              Don’t ask again for 2 weeks
            </Typography>
            <Tooltip
              placement={'top'}
              label={
                <span>
                  We won’t ask for a code on
                  <br />
                  this trusted device. Only
                  <br />
                  select this if the device isn’t
                  <br />
                  shared
                </span>
              }
            >
              <Icon icon={'question'} color={'var(--content-primary-disabled)'} fontSize={14} />
            </Tooltip>
          </div>
        }
      />
    </div>
  )
})
