import { makeAutoObservable } from 'mobx'
import { IResponsePowerDialer, PowerDialerStatus } from 'entities/PowerDialer/api/types'
import type { IParamsSendFrom } from 'entities/Inbox/api/types'

export class PowerDialer {
  id: number
  name: string
  contactsIdUnprocessed: number[]
  contactsIdEnrolled: number[]
  status: PowerDialerStatus
  sendFrom: IParamsSendFrom

  constructor(item: IResponsePowerDialer) {
    this.id = item.id
    this.name = item.name
    this.contactsIdUnprocessed = item.contacts_id_unprocessed
    this.contactsIdEnrolled = item.contacts_id_enrolled
    this.status = item.status
    this.sendFrom = item.send_from

    makeAutoObservable(this)
  }
}
