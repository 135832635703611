import { useEffect } from 'react'
import {
  EventIntegrationDisconnected,
  type IResponseIntegrationDisconnected,
} from 'entities/Integrations'
import type { IntegrationListStore } from 'pages/settings/pages/integrations/store/integrationListStore'
import type { IntegrationDetailsStore } from 'pages/settings/pages/integrations/store/integrationDetailsStore'

export const useEventIntegrationDisconnectedSettingsList = (
  integrationListStore: IntegrationListStore
) => {
  const handler = (data: IResponseIntegrationDisconnected) => {
    integrationListStore.syncUserIntegration(data.userIntegration)
  }

  useEffect(() => {
    EventIntegrationDisconnected.subscribe(handler)

    return () => {
      EventIntegrationDisconnected.unsubscribe(handler)
    }
  }, [])
}

export const useEventIntegrationDisconnectedSettingsDetails = (
  integrationDetailsStore: IntegrationDetailsStore
) => {
  const handler = (data: IResponseIntegrationDisconnected) => {
    integrationDetailsStore.integration?.syncUserIntegration(data.userIntegration)
  }

  useEffect(() => {
    EventIntegrationDisconnected.subscribe(handler)

    return () => {
      EventIntegrationDisconnected.unsubscribe(handler)
    }
  }, [])
}
