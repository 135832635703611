import { Alert, Button } from 'shared/ui'
import { IAlert } from 'shared/ui/Alert/types'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { openIntercomNewMessage } from 'entities/Intercom'
import { ChatbotStatus, IChatbotFullData } from 'entities/Chatbot'
import { ChatbotAnalyticDetailStore } from 'pages/chatbot'
import styles from './styles.module.scss'

type IChatbotInvalidAlertProps = {
  className?: string
  data: IChatbotFullData | ChatbotAnalyticDetailStore
  isBottomActions?: boolean
}

export const ChatbotInvalidAlert = ({
  className,
  data,
  isBottomActions,
}: IChatbotInvalidAlertProps) => {
  if (data.status !== ChatbotStatus.Invalid) return null

  const info: Partial<IAlert> = {
    title: 'Suspicious content detected',
    desc: 'Your link has been flagged as spam. If you believe this is an error, contact support for assistance.',
  }

  const handleOpenIntercomWidget = () => {
    openIntercomNewMessage()

    modalStore.closeAllModals()
  }

  const action = () => {
    return (
      <Button
        text='Support'
        typeBtn='outlined'
        size='small'
        contained='secondary'
        className={styles.link}
        onClick={() => handleOpenIntercomWidget()}
      />
    )
  }

  return (
    <div className={className}>
      <Alert
        item={{
          type: 'alert',
          ...info,
        }}
        rightAction={!isBottomActions && action()}
        actions={isBottomActions && action()}
      />
    </div>
  )
}
