import { ChannelProfile } from 'entities/WebSocket'

class ChannelsProfile {
  constructor() {}

  subscribeChannels = (profile_id: number) => {
    new ChannelProfile({
      id: profile_id,
    })
  }
}

export const channelsProfile = new ChannelsProfile()
