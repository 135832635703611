import { RequestAttachment } from 'entities/Attachment/model/RequestAttachment'
import { IFiltersListGroups, IResponseContact } from 'entities/Contacts/api/types'
import { IParamsSendFrom, IResponseSendFrom } from 'entities/Inbox/api/types'
import { Paths, ToErrorList } from '../utils'

export enum ChatbotInstructionType {
  LeadQualification = 1,
  Custom = 2,
  FAQ = 3,
  Promotion = 4,
  FollowUp = 5,
}

export type IChatbotSettings = {
  await_reply: IChatbotAwaitReply
  max_attempts: null | {
    value: number
  }
  about_conversation: null | {
    value: string
  }
  business_information: null | {
    value: string
  }
  how_to_respond: null | {
    value: string
  }
  instructions: null | {
    value: string
  }
  use_knowledge_base: null | {
    value: boolean
  }
}

export type IChatbotField = {
  key: string
  name: string
  type: string
}

export const ChatbotActionType = {
  AddTag: 1,
  RemoveTag: 2,
  ContactNote: 4,
  SendMessage: 200,
} as const

export type IChatbotActionType = (typeof ChatbotActionType)[keyof typeof ChatbotActionType]

export type ChatbotActionTypeMap = {
  [ChatbotActionType.AddTag]: IChatbotActionTag
  [ChatbotActionType.RemoveTag]: IChatbotActionTag
  [ChatbotActionType.ContactNote]: IChatbotActionNote
  [ChatbotActionType.SendMessage]: IChatbotActionSendMessage
}

export type IChatbotActionSetting<T extends IChatbotActionType> = {
  type: T
  settings: ChatbotActionTypeMap[T]
}

export type IChatbotActionTag = {
  tag_ids: number[]
}

export type IChatbotActionAddTagSetting = IChatbotActionSetting<typeof ChatbotActionType.AddTag>
export type IChatbotActionRemoveTagSetting = IChatbotActionSetting<
  typeof ChatbotActionType.RemoveTag
>

export type IChatbotActionNote = {
  note: string
}

export type IChatbotActionContactNoteSetting = IChatbotActionSetting<
  typeof ChatbotActionType.ContactNote
>

export type IChatbotActionSendMessage = IChatbotMessage

export type IChatbotActionSendMessageSetting = IChatbotActionSetting<
  typeof ChatbotActionType.SendMessage
>

export type IChatbotActionSettings =
  | IChatbotActionAddTagSetting
  | IChatbotActionRemoveTagSetting
  | IChatbotActionContactNoteSetting
  | IChatbotActionSendMessageSetting

export type IChatbotMessage = {
  message: string
  media_url: Omit<RequestAttachment, 'media_preview'>[]
}

export enum ChatbotStatus {
  Draft = 0,
  Active = 1,
  Disabled = 2,
  Invalid = 3,
}

export type IChatbotBase = {
  id: number
  name: string
  type: number
  is_active: boolean
  send_from: IResponseSendFrom | null
  organization_id: number
  status: ChatbotStatus
  created_at: string
  updated_at: string
  total_contacts_count: number
  terminated_contacts_count: number
  completed_contacts_count: number
  fallback_contacts_count: number
  in_progress_contacts_count: number
  filters: IChatbotFilters | null
  first_message: IChatbotMessage | null
}

export type IChatbotPayload = {
  type: ChatbotInstructionType
  name: string
  is_active: boolean
  send_from: IParamsSendFrom | null
  first_message: IChatbotMessage | null
  filters: IChatbotFilters | null
  settings: IChatbotSettings | null
  fields: IChatbotField[] | null
  success_actions: IChatbotActionSettings[] | null
  fallback_actions: IChatbotActionSettings[] | null
}

export type IChatbotPayloadError = ToErrorList<IChatbotPayload>
export type IChatbotPayloadPath = Paths<IChatbotPayload>

export type IChatbotData = IChatbotPayload & IChatbotBase

export type IChatbotFilters = IFiltersListGroups

export type IChatbotAwaitReply = {
  value: number
  unit: IChatbotAwaitReplyUnit
}

export const ChatbotAwaitReplyUnit = {
  Minutes: 'minutes',
  Hours: 'hours',
  Days: 'days',
  Weeks: 'weeks',
  Months: 'months',
} as const

export type IChatbotAwaitReplyUnit =
  (typeof ChatbotAwaitReplyUnit)[keyof typeof ChatbotAwaitReplyUnit]

export const ChatbotConversationStatus = {
  InProgress: 'in_progress',
  Completed: 'completed',
  Abandoned: 'abandoned',
  Terminated: 'terminated',
} as const

export type IChatbotConversationStatus =
  (typeof ChatbotConversationStatus)[keyof typeof ChatbotConversationStatus]

export type IChatbotAnalyticBase = {
  id: number
  chatbot_id: number
  contact_id: number
  uuid: string
  created_at: string
  contact: IResponseContact | null
} & (
  | {
      status: typeof ChatbotConversationStatus.Abandoned
      success_at: null
      fallback_at: string
    }
  | {
      status: typeof ChatbotConversationStatus.Completed
      success_at: string
      fallback_at: null
    }
  | {
      status: typeof ChatbotConversationStatus.InProgress
      success_at: null
      fallback_at: null
    }
  | {
      status: typeof ChatbotConversationStatus.Terminated
      success_at: null
      fallback_at: null
    }
)
