import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Alert } from 'shared/ui'
import { type BuyCreditsModalStore } from 'widgets/BuyCreditsModal'
import { DropdownCards } from 'widgets/DropdownCards/ui/DropdownCards'
import { DropdownCredits } from './DropdownCredits/DropdownCredits'
import { AutoRecharge } from './AutoRecharge/AutoRecharge'
import styles from './styles.module.scss'

type IBuyCreditsModalContentProps = {
  buyCreditsModalStore: BuyCreditsModalStore
}

export const BuyCreditsModalContent: FC<IBuyCreditsModalContentProps> = observer(
  ({ buyCreditsModalStore }) => {
    const {
      loadingModal,
      handleActivePrice,
      dropdownCardsStore,
      autoRechargeWidgetStore,
      isOutOfMessage,
    } = buyCreditsModalStore

    return (
      <div
        className={classnames(styles.wrap, {
          [styles.loading]: loadingModal,
        })}
      >
        {isOutOfMessage && (
          <Alert
            className={styles.alert}
            item={{
              type: 'warning',
              title: 'Your organization is out of message credits',
              desc: 'Add message credits to continue sending, receiving messages and calls',
            }}
          />
        )}
        <div className={styles.section}>
          <DropdownCredits
            onChange={(item) => {
              handleActivePrice(item)
            }}
          />
        </div>

        <div className={classnames(styles.section, styles['section--mrb15'])}>
          {dropdownCardsStore && <DropdownCards store={dropdownCardsStore} />}
        </div>

        <div className={styles.section}>
          <AutoRecharge
            openModal={() => {
              autoRechargeWidgetStore.openModal()
            }}
          />
        </div>
      </div>
    )
  }
)
