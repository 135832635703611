import { makeAutoObservable } from 'mobx'
import type { IResponseCnam, IResponseOrganizationCnam } from 'entities/Compliance/api/cnamType'
import { Cnam } from './OrganizationCnam/Cnam'

export class OrganizationCnam {
  private _cnamMap: Map<number, Cnam> = new Map()
  private _terms: IResponseOrganizationCnam['terms'] | null = null

  constructor(response: IResponseOrganizationCnam) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseOrganizationCnam) => {
    this.addItems(response.cnam)
    this._terms = response.terms
  }

  addItems = (items: IResponseOrganizationCnam['cnam']) => {
    items.forEach((item) => {
      this.addItem(item)
    })
  }

  addItem = (item: IResponseCnam) => {
    this._cnamMap.set(item.id, new Cnam(item))
  }

  deleteItem = (id: number) => {
    this._cnamMap.delete(id)
  }

  getItem = (id: number) => {
    return this._cnamMap.get(id)
  }

  get hasCnam() {
    return Boolean(this._cnamMap.size)
  }

  get cnam() {
    return Array.from(this._cnamMap.values())
  }

  get terms() {
    return this._terms
  }
}
