import classnames from 'classnames'
import React, { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography, Table, IColumn, Avatar, IconButton, Button } from 'shared/ui'
import { makeTextFieldClear, makeTextFieldIcon, TextField } from 'shared/ui/TextField'
import { IntegrationCrmUser } from 'entities/Integrations/model/IntegrationCrmUser'
import { ICrmUserIntegrationMeta } from 'entities/Integrations'
import { useEventIntegrationUsersRefreshed } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/events/EventIntegrationUsersRefreshed'
import { type IntegrationHubspotSettingsUsersStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsUsersStore'
import { IntegrationHubspotSettingsUsersCell } from './IntegrationHubspotSettingsUsersCell'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsUsersProps = {
  store: IntegrationHubspotSettingsUsersStore
}

export const IntegrationHubspotSettingsUsers: FC<IIntegrationHubspotSettingsUsersProps> = observer(
  ({ store }) => {
    const {
      users,
      pagination,
      isLoading,
      sendRefreshRequest,
      total,
      onChangePagination,
      search,
      onSearchChange,
      organizationMembers,
      onUserChange,
      refreshData,
      tableStore,
      onGroupInvite,
      onUserInvite,
      onManageInvite,
      usersCount,
      status,
    } = store

    // refresh event subscriber
    useEventIntegrationUsersRefreshed(refreshData)

    const COLUMNS: IColumn<IntegrationCrmUser<ICrmUserIntegrationMeta>>[] = useMemo(
      () => [
        {
          field: 'user',
          name: 'HubSpot user',
          width: '40%',
          renderRowCell: ({ avatarInfo, name, email }) => (
            <div className={styles.row}>
              <Avatar info={avatarInfo} size={32} />
              <div className={styles.content}>
                <Typography
                  variant={'body-md-regular'}
                  ellipsis
                  tooltipProps={{ label: name }}
                  ariaLabel={'displayInfo'}
                >
                  {name}
                </Typography>
                <Typography
                  variant={'body-sm-regular'}
                  ellipsis
                  ariaLabel={'email'}
                  color={'tertiary'}
                >
                  {email}
                </Typography>
              </div>
            </div>
          ),
        },
        {
          field: 'select',
          name: 'Salesmsg user',
          renderHeaderCell: (column) => (
            <div className={styles.refreshCell}>
              <div>{column.name}</div>
              <IconButton
                className={styles.headerIcon}
                iconProps={{ fontSize: 16 }}
                variant={'icon'}
                color={'tertiary'}
                size={'small'}
                icon={isLoading ? 'loading3' : 'refresh'}
                ariaLabel='Refresh_data'
                onClick={sendRefreshRequest}
              />
            </div>
          ),
          renderRowCell: (row) => (
            <IntegrationHubspotSettingsUsersCell
              row={row}
              members={organizationMembers}
              onUserChange={onUserChange(row.id)}
              onUserInvite={onUserInvite}
              onManageInvite={onManageInvite}
            />
          ),
        },
      ],
      [organizationMembers, onUserChange, refreshData, onUserInvite, onManageInvite, isLoading]
    )

    if (!usersCount) {
      return (
        <div className={styles.placeholder}>
          <Typography
            variant={'body-md-regular'}
            ellipsis
            ariaLabel={'empty_placeholder'}
            color={'tertiary'}
          >
            There are no HubSpot users
          </Typography>
        </div>
      )
    }

    const dataNotExist = !total && !status.isLoading && !status.isIdle

    return (
      <div className={styles.root}>
        <TextField
          size={'medium'}
          onChange={onSearchChange}
          variant={'integrated'}
          value={search}
          InputProps={{
            placeholder: 'Search...',
            className: styles.search,
          }}
          leftActions={[
            makeTextFieldIcon({
              icon: 'search',
              fontSize: 18,
              color: 'var(--content-primary-tertiary)',
              margin: '0 0 0 4px',
            }),
          ]}
          rightActions={[makeTextFieldClear()]}
        />

        <div className={classnames(styles.table, { [styles.loading]: isLoading })}>
          <Table
            store={tableStore}
            withCheckbox
            columns={COLUMNS}
            rows={users}
            rowsCount={total}
            startPaginationSize={10}
            initPage={pagination.page}
            limit={pagination.limit}
            noPagination={!total}
            onChangePagination={onChangePagination}
            scrollbarProps={{
              autoHeight: true,
              autoHeightMin: 0,
              autoHeightMax: 610,
              viewClassName: styles.viewScrollClassName,
            }}
            headerLeftContentProps={{
              actionsContent: (
                <Button
                  text='Invite users'
                  icon='add'
                  typeBtn='contained'
                  contained='primary'
                  size='small'
                  onClick={onGroupInvite}
                />
              ),
            }}
            bottomTableContent={
              dataNotExist &&
              search && (
                <tr>
                  <td colSpan={4}>
                    <div className={styles.emptySearch}>
                      <Typography
                        variant={'body-md-regular'}
                        ellipsis
                        ariaLabel={'empty_placeholder'}
                        color={'tertiary'}
                      >
                        No HubSpot users
                      </Typography>
                    </div>
                  </td>
                </tr>
              )
            }
          />
        </div>
      </div>
    )
  }
)
