import { Box, Button, Typography } from 'shared/ui'
import { getCDNAssetURL } from 'shared/lib'
import complianceStart from 'shared/assets/images/complianceStart.png'
import { useQuestionnaireContext } from 'pages/settings/pages/compliance/pages/questionnaire/context/questionnaireContext'
import styles from './styles.module.scss'

export const StartPage = () => {
  const store = useQuestionnaireContext()

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'8px'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <img
        src={getCDNAssetURL(complianceStart)}
        alt='complianceStart'
        className={styles.validateContentImg}
      />
      <Typography ariaLabel='StartPage_title' variant='heading-md'>
        Start your approval process
      </Typography>
      <Typography
        ariaLabel='StartPage_disc'
        variant='body-rg-regular'
        color='var(--content-primary-tertiary)'
      >
        Let’s get you ready to text!
      </Typography>
      <Button
        size='medium'
        text='Start now'
        onClick={() => {
          store.goToStart()
        }}
        className={styles.startNowButton}
      />
    </Box>
  )
}
