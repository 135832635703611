import { useState } from 'react'
import { Button, Dropdown, DropdownContent, showToast } from 'shared/ui'
import { TextField, makeTextFieldAutoFocus, makeTextFieldLoading } from 'shared/ui/TextField'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import styles from '../styles.module.scss'

const Actions = ({ id }: { id: string | number }) => {
  const {
    contactIntegrationsStore: { createContactsIntegrationsActiveCampaignTag },
  } = useContactsDetailsContext()
  const [open, setOpen] = useState(false)
  const [tag, setTag] = useState('')
  const [creating, setCreating] = useState(false)

  const onCreate = async () => {
    const data = {
      tag,
      contact_integration_id: id,
    }

    try {
      setCreating(true)
      const toast = await createContactsIntegrationsActiveCampaignTag(data)

      showToast({
        type: toast.status,
        title: 'Tag applied in ActiveCampaign',
      })
    } catch (e) {
      console.log(e)
    } finally {
      setTag('')
      setOpen(false)
      setCreating(false)
    }
  }

  return (
    <div className={styles.actionsWrapper}>
      <Dropdown
        ariaLabel={'Actions'}
        show={open}
        onToggle={setOpen}
        margin={-36}
        typeButton='button'
        buttonProps={{
          size: 'small',
          text: 'Apply tag',
          typeBtn: 'text',
          contained: 'secondary',
          icon: undefined,
        }}
        placement={'bottom-start'}
        customComponent={() => (
          <DropdownContent>
            <TextField
              size='small'
              variant='integrated'
              value={tag}
              onChange={setTag}
              InputProps={{
                placeholder: 'Add tag',
              }}
              mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
              rightActions={[makeTextFieldLoading({ loading: creating })]}
              disabled={creating}
            />
            <Button
              onClick={onCreate}
              disabled={!tag.length || creating}
              size={'medium'}
              text={'Apply tag'}
              typeBtn={'contained'}
              contained={'primary'}
              loading={creating}
              fullWidth
            />
          </DropdownContent>
        )}
      />
    </div>
  )
}

export { Actions }
