import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import {
  IIntegrationHubspotPropertyFormatted,
  IntegrationKey,
  IResponseIntegrationHubspotDeal,
} from 'entities/Integrations'

import styles from '../styles.module.scss'

type IItemDealRowProps = {
  deal: IResponseIntegrationHubspotDeal
  integrationKey: IntegrationKey | null
  fullList?: boolean
}

const ItemDealRow = ({ deal, integrationKey, fullList }: IItemDealRowProps) => {
  const hubspotRows: IIntegrationHubspotPropertyFormatted[] = []

  for (const [key, value] of Object.entries(deal)) {
    hubspotRows.push({
      key: key,
      label: value?.label ?? '',
      value: value?.value?.toString() ?? '',
    })
  }

  const hubspotAvailableRows = hubspotRows.filter(
    (item) => item.key !== 'id' && item.key !== 'dealname'
  )

  const pipedriveRows: IIntegrationHubspotPropertyFormatted[] = [
    {
      key: 'amount',
      label: 'Value',
      value: deal?.amount?.value?.toString() ?? '',
    },
    {
      key: 'pipeline',
      label: 'Pipeline',
      value: deal?.pipeline?.value?.toString() ?? '',
    },
    {
      key: 'dealstage',
      label: 'Stage',
      value: deal?.dealstage?.value?.toString() ?? '',
    },
    {
      key: 'owner',
      label: 'Owner',
      value: deal?.owner?.value?.toString() ?? '',
    },
  ]

  const activeCampaignRows: IIntegrationHubspotPropertyFormatted[] = [
    {
      key: 'amount',
      label: 'Value',
      value: deal?.amount?.value?.toString() ?? '',
    },
    {
      key: 'owner',
      label: 'Owner',
      value:
        deal?.owner?.first_name || deal?.owner?.last_name
          ? `${deal?.owner?.first_name} ${deal?.owner?.last_name}`
          : '',
    },
  ]

  let rows = fullList ? hubspotAvailableRows : hubspotAvailableRows.slice(0, 5)

  switch (integrationKey) {
    case 'pipedrive': {
      rows = pipedriveRows
      break
    }

    case 'activecampaign': {
      rows = activeCampaignRows
      break
    }
  }

  return (
    <div className={styles.dealWrapper}>
      {rows.map((item) => (
        <div className={styles.itemRow} key={item.key}>
          <div className={styles.label}>{item.label}</div>
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value || '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { ItemDealRow }
