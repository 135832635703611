import { makeAutoObservable } from 'mobx'
import { Status } from 'shared/ui'
import { subscriptionStore } from 'entities/Subscription'
import {
  EnumMenuAIAutomationItemsKey,
  menuItems,
} from 'widgets/Menu/ui/MenuAIAutomation/constants/menuItems'
import { MenuStore } from 'widgets/Menu/store/menuStore'
import { getStateAccessAIAutomation } from 'widgets/Menu/ui/MenuAIAutomation/lib/getPathAIAutomation'

export class MenuAIAutomationStore {
  private readonly _menuStore: MenuStore

  constructor(key: string) {
    makeAutoObservable(this)

    this._menuStore = new MenuStore({
      key: key,
    })
  }

  hideStateAccess = (key: EnumMenuAIAutomationItemsKey) => {
    if (subscriptionStore.isAgencyWithoutSubscription) return true

    switch (key) {
      case 'chatbot':
        return !getStateAccessAIAutomation(key)
      case 'knowledge_base':
        return !getStateAccessAIAutomation(key)
      case 'workflows':
        return !getStateAccessAIAutomation(key)
      case 'keywords':
        return !getStateAccessAIAutomation(key)
      default:
        return false
    }
  }

  disabledStateAccess = (key: EnumMenuAIAutomationItemsKey) => {
    switch (key) {
      case 'campaigns':
        return !getStateAccessAIAutomation(key)
      default:
        return false
    }
  }

  get menuStore() {
    return this._menuStore
  }

  get menuItems() {
    return menuItems
      .map((item) => {
        if (item.type === 'nav') {
          const disabled = this.disabledStateAccess(item.key)

          item.disabled = disabled
          item.rightContent = disabled ? (
            <Status title={'Coming soon'} intent={'white_label'} emphasis={'high'} size={'label'} />
          ) : undefined
        }

        return item
      })
      .filter((item) => {
        if (item.type === 'sub') {
          item.items = item.items.filter((child) => {
            return !this.hideStateAccess(child.key)
          })

          return Boolean(item.items.length)
        }

        return !this.hideStateAccess(item.key)
      })
  }
}
