import { CSSProperties, ReactNode } from 'react'
import { PopperProps } from '@mui/material/Popper'
import { TooltipProps } from '@mui/material/Tooltip'
import { TransitionProps } from '@mui/material/transitions/transition'
import {
  IAvatarProps,
  IButtonProps,
  IIconButtonProps,
  IIconProps,
  IIconsVector,
  ITooltipProps,
  IDropdownListProps,
} from 'shared/ui'
import { ITextFieldProps } from 'shared/ui/TextField'

export enum EnumDropdownItemVariant {
  Default = 'default',
  Checkbox = 'checkbox',
  CheckedRight = 'checked-right',
  CheckedLeft = 'checked-left',
  Deletable = 'deletable',
  Custom = 'custom',
}

export type IDropdownProps = {
  PopperProps?: Partial<PopperProps | null>
  TransitionProps?: Partial<TransitionProps | null>
  icon?: IIconsVector
  withSearch?: boolean
  withTextInput?: boolean
  bottomActions?: ReactNode
  topListComponent?: (onCloseMenu: () => void, dropdownId: string) => React.ReactNode
  width?: number
  childContainerWidth?: number
  maxHeight?: number
  margin?: number | string
  marginTop?: number | string
  marginLeft?: number | string
  placement?: TooltipProps['placement']
  typeButton?: 'icon-button' | 'button'
  sizeTriggerButton?: IIconButtonProps['size']
  variantTriggerButton?: IIconButtonProps['variant']
  triggerComponent?: (open: boolean) => ReactNode
  items?: IDropdownItem[]
  parent?: IDropdownItem
  onChange?: (item: IDropdownItem, child?: IDropdownItem) => void
  onPressEnter?: (text: string) => void
  onToggle?: (status: boolean) => void
  onToggleHover?: (status: boolean) => void
  buttonProps?: IButtonProps
  iconButtonProps?: IIconButtonProps
  tooltipProps?: ITooltipProps
  customComponent?: (onCloseMenu: () => void, dropdownId: string) => ReactNode
  disabled?: boolean
  show?: boolean
  onClose?: () => void
  isHover?: boolean
  parentsDropdownId?: string[]
  onChangeOpen?: (status: boolean) => void
  className?: string
  textFieldProps?: ITextFieldProps
  overlayModalsIds?: string[]
  autoOpen?: boolean
  disableStopPropagation?: boolean
  ariaLabel?: string
  zIndex?: number
  propsDropdownList?: Partial<IDropdownListProps>
}

export type IDropdownItem = {
  id: number | string
  height?: CSSProperties['height']
  value?: string
  data?: unknown
  customFields?: Record<string, string | boolean | number>
  avatarProps?: IAvatarProps
  iconR?: IIconsVector
  iconL?: IIconsVector
  label: string
  description?: string
  labelBrackets?: string
  link?: string
  short_label?: string
  labelContent?: ReactNode
  labelWidth?: string | number
  labelRight?: ReactNode
  hotKey?: string
  selectedLabelRight?: string
  wrapLabel?: string
  activeValue?: string | boolean
  noRenderEmptyActiveValue?: boolean
  selected?: boolean
  onClick?: () => void
  childrenDropdownProps?: IDropdownProps
  children?: IDropdownItem[]
  customChildren?: (onCloseMenu: () => void, dropdownId: string) => ReactNode
  disabled?: boolean
  disabledChildren?: boolean
  disabledClosedTooltip?: boolean
  separator?: boolean
  tooltipQuestionProps?: ITooltipProps
  iconQuestionProps?: IIconProps
  tooltipProps?: ITooltipProps
  variant?: EnumDropdownItemVariant
  parentTooltipProps?: ITooltipProps
  isCardClick?: boolean
  ariaLabel?: string
  onDelete?: () => void
  renderOption?: (item: IDropdownItem) => ReactNode
  className?: string
}

export type IDropdownItemProps = {
  onChange?: (item: IDropdownItem, parent?: IDropdownItem) => void
  onChangeCheckbox?: (status: boolean, item: IDropdownItem) => void
  item: IDropdownItem
  parent?: IDropdownItem
  isSelect?: boolean
  isHover?: boolean
  childContainerWidth?: number
  ariaLabel?: string
}

export type IDropdownGroupProps = {
  title?: string
  onChange?: (item: IDropdownItem, parent?: IDropdownItem) => void
  onChangeCheckbox?: (status: boolean, item: IDropdownItem) => void
  items?: IDropdownItem[]
  parent?: IDropdownItem
  isSelect?: boolean
  isScroll?: boolean
  stylesWrap?: CSSProperties
}
