import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

interface IDragAndDropFileProps {
  onDrop: (files: File[]) => void
  isDropZoneVisible?: boolean
  dropZoneInfo?: ReactNode
  dropZoneOverlayInfo?: ReactNode
  className?: string
  onDragHandler?: (condition: boolean) => void
}

const DragAndDropFile = ({
  onDrop,
  isDropZoneVisible = false,
  dropZoneInfo,
  dropZoneOverlayInfo,
  className,
  onDragHandler,
}: IDragAndDropFileProps) => {
  const [isDraggingOverDropZone, setIsDraggingOverDropZone] = useState(false)
  const [isDraggingOverWindow, setIsDraggingOverWindow] = useState(false)

  const onDropHandler = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles)
      setIsDraggingOverDropZone(false)
    },
    [onDrop]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler,
    onDragEnter: () => {
      setIsDraggingOverDropZone(true)
    },
    onDragLeave: () => {
      setIsDraggingOverDropZone(false)
    },
    noClick: true,
  })

  useEffect(() => {
    const handleDragEvent = (event: DragEvent) => {
      const isDraggingCondition = event.type === 'dragenter' || event.type === 'dragleave'

      event.preventDefault()
      onDragHandler?.(isDraggingCondition)
      switch (event.type) {
        case 'dragenter':
          setIsDraggingOverWindow(true)
          break
        case 'drop':
          setIsDraggingOverWindow(false)
          break
        case 'dragleave':
          if (event.clientX === 0 && event.clientY === 0) {
            onDragHandler?.(false)
            setIsDraggingOverWindow(false)
          }
          break
      }
    }

    window.addEventListener('drop', handleDragEvent)
    window.addEventListener('dragenter', handleDragEvent)
    window.addEventListener('dragleave', handleDragEvent)

    return () => {
      window.removeEventListener('drop', handleDragEvent)
      window.removeEventListener('dragenter', handleDragEvent)
      window.removeEventListener('dragleave', handleDragEvent)
    }
  }, [])

  return (
    <div
      {...getRootProps()}
      className={classNames(
        styles.dropZoneWrapper,
        {
          [styles.dragging]: isDraggingOverWindow,
          [styles.dropZoneVisible]: isDropZoneVisible,
        },
        className
      )}
      tabIndex={-1}
    >
      {isDropZoneVisible && !isDraggingOverDropZone && dropZoneInfo}
      <input {...getInputProps()} />
      {isDraggingOverDropZone && (
        <div
          className={classNames(styles.dropZone, {
            [styles.overlaying]: isDraggingOverDropZone,
          })}
        >
          {dropZoneOverlayInfo || (
            <Typography variant={'body-rg-medium'} ariaLabel={'DragAndDropLabel'}>
              Drop file here to upload
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}

export default DragAndDropFile
