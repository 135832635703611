import { createContext, useContext } from 'react'
import { type KeywordViewStore } from 'pages/keywords/store'

export const KeywordViewContext = createContext<KeywordViewStore | null>(null)

export function useKeywordViewContext() {
  const context = useContext(KeywordViewContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with KeywordViewContext')
  }
  return context
}
