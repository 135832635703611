import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, Typography, type IIconButtonProps } from 'shared/ui'
import { type ISavedRepliesProps } from 'entities/CannedMessages'
import { type SavedRepliesStore } from 'widgets/SavedReplies'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

import styles from './styles.module.scss'

type ISavedRepliesIconActionProps = ISavedRepliesProps & {
  iconButtonProps?: IIconButtonProps
  store: SavedRepliesStore
  useHotKey?: boolean
}

export const SavedRepliesIconAction: FC<ISavedRepliesIconActionProps> = observer(
  ({ contact, onAddSavedReplies, integration, iconButtonProps, store, useHotKey }) => {
    const onOpenMergeFieldContent = () =>
      store.openModal({ onAddSavedReplies, integration, contact, isCurrentAirCall })

    const messageFieldStore = useMessageFieldContext()
    const { isCurrentAirCall } = messageFieldStore

    return (
      <IconButton
        icon={'savedReplies'}
        tooltipProps={{
          label: useHotKey ? (
            <div className={styles.labelWrapper}>
              <Typography ariaLabel={'savedReplyLabel'} variant={'body-md-regular'}>
                Saved replies
              </Typography>
              <div className={styles.hotKey}>
                <Typography ariaLabel={'savedReplyHotKey'} variant={'body-sm-medium'}>
                  /
                </Typography>
              </div>
            </div>
          ) : (
            'Saved replies'
          ),
          placement: 'top',
        }}
        color={'tertiary'}
        size={'medium'}
        variant={'icon'}
        onClick={() => onOpenMergeFieldContent()}
        ariaLabel='SavedRepliesIconAction'
        {...iconButtonProps}
      />
    )
  }
)
