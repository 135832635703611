import { observer } from 'mobx-react-lite'
import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { Dropdown } from 'shared/ui'
import { MeetingsContent } from 'widgets/Meetings/ui/MeetingsContent'
import { MeetingsProps } from 'widgets/Meetings/store/meetingsStore'
import styles from './styles.module.scss'

export const MeetingsIconAction: FC<MeetingsProps> = observer((props) => {
  const [isNecessaryReconnect, setIsNecessaryReconnect] = useState(false)
  if (props.integration?.key !== 'hubspot') return null

  return (
    <Dropdown
      ariaLabel={'MeetingsIconAction'}
      placement={'top-start'}
      tooltipProps={{
        label: 'Meetings',
        placement: 'top',
      }}
      sizeTriggerButton={'medium'}
      variantTriggerButton={'icon'}
      icon={'calendar'}
      typeButton={'icon-button'}
      customComponent={(onCloseMenu) => (
        <div
          className={classNames(styles.dropdown, {
            [styles.full]: isNecessaryReconnect || !props.integration?.isConnected,
          })}
        >
          <div>
            <MeetingsContent
              onCloseMenu={onCloseMenu}
              onNecessaryReconnect={setIsNecessaryReconnect}
              {...props}
            />
          </div>
        </div>
      )}
    />
  )
})
