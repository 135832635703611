import { IResponsePlan } from '../api/types'

export class BillingPlan {
  id: string
  active: boolean
  price: number
  monthly_price: number
  interval: string
  credits: number
  additional_credit_amount: number
  for_clients: boolean
  for_users: boolean

  constructor(item: IResponsePlan) {
    this.id = item.id
    this.active = item.active
    this.price = item.price
    this.monthly_price = item.monthly_price
    this.interval = item.interval
    this.credits = item.credits
    this.additional_credit_amount = item.additional_credit_amount
    this.for_clients = item.for_clients
    this.for_users = item.for_users
  }

  get label() {
    const interval = this.interval === 'monthly' ? 'Monthly' : 'Yearly'
    const credits = Intl.NumberFormat('en', { notation: 'compact' }).format(this.credits)

    return `Pro ${interval} ${credits}`
  }
}
