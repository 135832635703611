import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'
import { complianceStore } from 'entities/Compliance'
import { billingStore } from 'entities/Billing'
import { QuestionnaireOrSelectNumberModalStore } from 'entities/Phone/ui/QuestionnaireOrSelectNumberModal'
import { type BillingPlan } from 'entities/Billing/model/BillingPlan'

export class UpgradePlanModalFinishStore {
  private _idFinishModal = 'upgradePlanFinishModal'
  private _plan: BillingPlan | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get plan() {
    if (!this._plan) return ''

    return this._plan.label
  }

  get title() {
    return (
      <>
        Congrats! <br /> You have just upgraded to {this.plan} plan
      </>
    )
  }

  get description() {
    if (this.isShowQuestionnaireOrSelectNumberModal) {
      return "We'll ask a few quick questions to tailor your experience"
    }

    return ''
  }

  get textButton() {
    if (this.isShowQuestionnaireOrSelectNumberModal) {
      return 'Get started'
    }

    return 'Done'
  }

  get trialSubscriptionStore() {
    return subscriptionStore.trialSubscriptionStore
  }

  get isShowQuestionnaireOrSelectNumberModal() {
    return (
      !this.trialSubscriptionStore.isQuestionnaire || !this.trialSubscriptionStore.isNumberSelected
    )
  }

  setPlan = (value: string) => {
    const plan = billingStore.billingPlansStore.getItem(value)

    if (plan) {
      this._plan = plan
    }
  }

  openFinishModal = async (planId: string) => {
    await Promise.all([
      usersStore.initPromise,
      complianceStore.fetchSummary(),
      billingStore.fetchPlans(),
    ])

    this.setPlan(planId)

    modalStore.addModal({
      id: this._idFinishModal,
      type: ModalTypeList.SUCCESS,
      title: this.title,
      desc: this.description,
      disabledOnAllClose: true,
      disabledOnEscClose: true,
      disabledOverlayClose: true,
      showCloseButton: false,
      primaryAction: {
        text: this.textButton,
        onAction: () => {
          this.onAction()
        },
      },
    })
  }

  onAction = () => {
    if (this.isShowQuestionnaireOrSelectNumberModal) {
      new QuestionnaireOrSelectNumberModalStore().init()
    }

    modalStore.removeModal(this._idFinishModal)
  }
}
