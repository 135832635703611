import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { TrialBroadcastLimitAlert } from 'entities/Subscription/ui/TrialBroadcastLimitAlert'
import { EnableAutoRechargeBtn } from 'widgets/AutoRechargeModal'
import { IReviewBroadcastProps } from '../type'
import styles from './styles.module.scss'

export const ReviewAlerts: FC<IReviewBroadcastProps> = observer(({ store }) => {
  const {
    isNotEnoughCredits,
    notEnoughCredits,
    isOverDailyLimit,
    package_count,
    isRecurring,
    isAutoRechargeAlert,
    autoRechargeWidgetStore,
    close,
  } = store
  const isMember = usersStore.user?.isMemberRole || usersStore.user?.isManagerRole

  let alert
  if (subscriptionStore.isTrial) {
    alert = <TrialBroadcastLimitAlert onCloseModal={close} />
  } else if (isNotEnoughCredits) {
    alert = isMember ? (
      <Alert
        item={{
          title: `You need ${notEnoughCredits} more credits to send your broadcast`,
          desc: 'Ask your organization owner to add message credits to your account',
          type: 'infoBlue',
        }}
      />
    ) : (
      <Alert
        item={{
          desc: `You need ${notEnoughCredits} more credits to send your broadcast`,
          type: 'infoBlue',
        }}
        actions={
          <div className={styles.wrapAutoRechargeBtn}>
            <EnableAutoRechargeBtn store={autoRechargeWidgetStore} />
          </div>
        }
      />
    )
  } else if (isAutoRechargeAlert) {
    alert = (
      <Alert
        item={{
          desc: 'To send your broadcast, please enable auto-recharge',
          type: 'infoBlue',
        }}
        actions={
          <div className={styles.wrapAutoRechargeBtn}>
            <EnableAutoRechargeBtn store={autoRechargeWidgetStore} />
          </div>
        }
      />
    )
  } else if (isOverDailyLimit) {
    alert = (
      <Alert
        item={{
          desc: `Your segment exceeds your daily limit of  ${numberFormat({
            value: package_count,
          })} messages per day and will be dripped over multiple days`,
          type: 'infoBlue',
        }}
      />
    )
  } else if (isRecurring) {
    alert = (
      <Alert
        item={{
          desc: 'The estimates apply only to the initial broadcast and do not account for any subsequent recurring broadcasts.',
          type: 'infoBlue',
        }}
      />
    )
  }
  if (!alert) return null
  return <div className={styles.wrap}>{alert}</div>
})
