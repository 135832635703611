import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { uiStore } from 'shared/store/uiStore'

import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type IRouterLinkProps = {
  children: ReactNode
  route: string
  callBack?: () => void
}

export const RouterLink: FC<IRouterLinkProps> = observer(
  ({ route, children = 'vue', callBack }) => {
    const type = Boolean(window.DesignV2API && !window.DesignV2API?.handleCheckPage(route))
      ? 'vue'
      : 'default'

    return (
      <div
        className={styles.link}
        onClick={() => {
          callBack?.()
          uiStore.changeRoute({
            path: route,
            type,
          })
        }}
        aria-label={getAriaLabel('RouterLink')}
      >
        {children}
      </div>
    )
  }
)
