import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Alert, Button, toastStore } from 'shared/ui'
import { WorkflowExecution } from 'entities/Workflow'
import { Contact } from 'entities/Contacts/model/Contact'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { subscribeConversationStateChange } from 'entities/Conversation/events/EventConversationStateChanged'
import { ChatbotExecutionProvider, useChatbotExecutionContext } from './ChatbotExecutionContext'

import styles from './styles.module.scss'

interface IConversationChatbotAlertProps {
  contact?: Contact
  execution?: WorkflowExecution
  terminate: (executionId: string) => void
}

const ConversationChatbotAlert = observer(
  ({ contact, execution, terminate }: IConversationChatbotAlertProps) => {
    const [terminating, setTerminating] = useState(false)

    const contactName = contact?.fullName || (contact?.formatted_number ?? 'user')
    const workflowName = execution?.workflowName ?? ''
    const desc = execution
      ? `${workflowName} is currently chatting with ${contactName}`
      : 'Something went wrong. Please claim conversation to continue.'

    const handleClick = () => {
      setTerminating(true)
      terminate(execution?.id ?? '')
    }

    return (
      <Alert
        item={{
          type: execution ? 'infoLight' : 'error',
          desc: desc,
        }}
        rightAction={
          <Button
            size='small'
            typeBtn='outlined'
            contained='secondary'
            text='Claim conversation'
            className={styles.alertButton}
            disabled={terminating}
            onClick={handleClick}
          />
        }
      />
    )
  }
)

const ConversationChatbotAlertsBody = observer(
  ({ conversation }: IConversationChatbotAlertsProps) => {
    const {
      executions,
      dispose,
      syncLatestExecutions,
      terminateExecution,
      loading,
      showTerminateExecution,
      setTerminateExecutionStatus,
    } = useChatbotExecutionContext()

    const conversationId = conversation?.id
    const conversationLocked = !!conversation?.is_locked
    const conversationContact = conversation?.contact

    useEffect(
      () =>
        subscribeConversationStateChange((lockState) => {
          if (lockState.id === conversationId && !lockState.locked)
            toastStore.add({
              type: 'info',
              title: 'Conversation claimed',
              desc: 'You can now send messages manually',
            })
        }),
      [conversationId]
    )

    useEffect(() => dispose(), [conversationId])

    useEffect(() => {
      if (conversationId == null) return dispose()

      if (conversationLocked) {
        syncLatestExecutions(conversationId)
        setTerminateExecutionStatus(true)
      } else dispose()
    }, [conversationId, conversationLocked])

    if (conversation == null) return null

    if (executions.length && conversation && conversationLocked) {
      return (
        <>
          {executions.map((execution) => (
            <ConversationChatbotAlert
              key={execution.id}
              execution={execution}
              contact={conversationContact}
              terminate={(executionId) => terminateExecution(executionId, conversation?.id)}
            />
          ))}
        </>
      )
    }

    if (showTerminateExecution && !loading) {
      return (
        <ConversationChatbotAlert
          contact={conversationContact}
          terminate={(executionId) => terminateExecution(executionId, conversation?.id)}
        />
      )
    }

    return null
  }
)

export interface IConversationChatbotAlertsProps {
  conversation?: Conversation
}

export const ConversationChatbotAlerts = (props: IConversationChatbotAlertsProps) => (
  <ChatbotExecutionProvider>
    <ConversationChatbotAlertsBody {...props} />
  </ChatbotExecutionProvider>
)
