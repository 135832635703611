import React from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, IconButton, IDropdownItem } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { type CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

type ICallDisconnectPopUpButtonProps = {
  fullWidth?: boolean
}

export const CallDisconnectPopUpButton: React.FC<ICallDisconnectPopUpButtonProps> = observer(
  ({ fullWidth = false }) => {
    const store = useCallPopUpDefaultContext()
    const { isCreatedConference, isConferenceParticipants, handleDisconnect, isDisconnect } =
      store as CallPopUpDefaultStore
    const items: IDropdownItem[] = [
      {
        id: 'end_all',
        label: 'End call for all',
        iconL: 'phoneHangUp',
      },
      {
        id: 'leave_call',
        label: 'Leave call',
        iconL: 'logOut',
      },
    ]

    if (isCreatedConference) {
      if (!isConferenceParticipants) {
        return (
          <div className={styles.wrap}>
            <IconButton
              size={'large'}
              color={'red'}
              icon={'phoneHangUp'}
              disabled={isDisconnect}
              tooltipProps={{
                zIndex: 5001,
                label: 'Hang up',
                placement: 'top',
              }}
              onClick={() => {
                handleDisconnect('end_all')
              }}
              ariaLabel='CallDisconnectPopUpButton_phoneHangUp'
            />
          </div>
        )
      }

      return (
        <div className={styles.wrap}>
          <Dropdown
            ariaLabel={'CallDisconnect'}
            zIndex={5001}
            triggerComponent={() => {
              return (
                <IconButton
                  size={'large'}
                  color={'red'}
                  icon={'phoneHangUp'}
                  disabled={isDisconnect}
                  tooltipProps={{
                    zIndex: 5001,
                  }}
                  ariaLabel='CallDisconnectPopUpButton_phoneHangUp'
                />
              )
            }}
            placement={'bottom-end'}
            tooltipProps={{
              zIndex: 5001,
            }}
            items={items}
            width={154}
            disabled={isDisconnect}
            onChange={(item) => {
              if (item.id === 'end_all') {
                handleDisconnect('end_all')
              }

              if (item.id === 'leave_call') {
                handleDisconnect('leave_call')
              }
            }}
          />
        </div>
      )
    }

    if (fullWidth) {
      return (
        <IconButton
          size={'large'}
          color={'red'}
          icon={'phoneHangUp'}
          fullWidth
          disabled={isDisconnect}
          tooltipProps={{
            zIndex: 5001,
            label: 'Decline',
            placement: 'top',
          }}
          onClick={() => {
            handleDisconnect()
          }}
          ariaLabel='CallDisconnectPopUpButton_decline'
        />
      )
    }

    return (
      <div className={styles.wrap}>
        <IconButton
          size={'large'}
          color={'red'}
          icon={'phoneHangUp'}
          disabled={isDisconnect}
          onClick={() => {
            handleDisconnect()
          }}
          tooltipProps={{
            zIndex: 5001,
            label: 'Hang up',
            placement: 'top',
          }}
          ariaLabel='CallDisconnectPopUpButton_phoneHangUp'
        />
      </div>
    )
  }
)
