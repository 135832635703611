import { makeAutoObservable } from 'mobx'
import { layoutStore } from 'shared/layout/Layout/store/LayoutStore'
import { type IIconProps } from 'shared/ui/Icon/Icon'
import { type ITooltipProps } from 'shared/ui/Tooltip/ui/Tooltip'

export class PageLayoutStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _isCollapse = false
  private _titleIconProps: IIconProps = {
    icon: 'lineCollapseExpandLeft',
    fontSize: 20,
    color: 'var(--content-primary-tertiary)',
  }
  private _isManualCollapse = false

  get titleIconProps() {
    return this._titleIconProps
  }

  get isCollapse() {
    return this._isCollapse
  }

  get isManualCollapse() {
    return this._isManualCollapse
  }

  get headerMenuTooltipIconProps(): ITooltipProps {
    return {
      label: this._isCollapse ? 'Show menu' : 'Hide menu',
      disableInteractive: true,
      PopperProps: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this._isCollapse ? 14 : 0, 0],
            },
          },
        ],
      },
    }
  }

  headerMenuIconAction = () => {
    this._isManualCollapse = true
    this.handleToggleCollapse()
  }

  handleToggleCollapse = () => {
    this._isCollapse = !this._isCollapse
  }

  handleChangeCollapse = (value: boolean) => {
    this._isCollapse = value
  }

  handleToggleCollapseIsMobile = () => {
    if (layoutStore.isMediumView) {
      this.handleToggleCollapse()
    }
  }

  contentContainer: HTMLDivElement | null = null
  setContentContainer = (contentContainer: typeof this.contentContainer) => {
    this.contentContainer = contentContainer
  }

  toStartContainer = () => {
    const contentContainer = this.contentContainer
    if (contentContainer) {
      contentContainer.scrollTo({
        left: 0,
      })
    }
  }
  toEndContainer = () => {
    const contentContainer = this.contentContainer
    if (contentContainer) {
      contentContainer.scrollTo({
        left: 350,
      })
    }
  }

  customCollapseWidth: number | null = null
  setCustomCollapseWidth = (value: typeof this.customCollapseWidth) => {
    this.customCollapseWidth = value
  }
}
