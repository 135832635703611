import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { showToast } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { ICustomPriceCreateForm } from 'entities/Admin/customPrices/api/type'
import { AdminCustomPriceApi } from 'entities/Admin'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'
import { AdminRoutes } from 'pages/admin'

class AdminCustomPricesCreate {
  loading = false
  form = new AdminCustomPricesForm()

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.form = new AdminCustomPricesForm()
  }

  saveCustomPrice = async () => {
    if (this.form.disableFormActions) {
      return
    }

    this.loading = true
    const data = this.form.formToCreate as ICustomPriceCreateForm

    try {
      const {
        data: { id },
      } = await AdminCustomPriceApi.saveCustomPrice(data)

      showToast({
        type: 'success',
        title: 'Custom plan successfully saved',
      })

      uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.customPrices}/${id}`)
    } catch (e) {
      if (e instanceof AxiosError) {
        showToast({
          type: 'error',
          title: e.response?.data.name[0] || 'Something went wrong. Please try again',
        })
      }
    } finally {
      this.loading = false
    }
  }

  publishCustomPrice = async () => {
    this.loading = true

    const data = this.form.formToCreate as ICustomPriceCreateForm

    try {
      const {
        data: { id },
      } = await AdminCustomPriceApi.saveCustomPrice(data)

      await AdminCustomPriceApi.publishCustomPrice(id)

      showToast({
        type: 'success',
        title: 'Custom plan successfully publish',
      })

      uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.customPrices}`)
    } catch (e) {
      console.error(e)
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    }
  }
}

export const adminCustomPricesCreate = new AdminCustomPricesCreate()
