import { observer } from 'mobx-react-lite'
import { Button, Dropdown, DropdownContent, ITabItem, Tabs } from 'shared/ui'
import { ICampaignScheduleType } from 'entities/Campaign'
import { useCampaignScheduleContext } from 'pages/campaigns/ui/CampaignSchedule/context/context'
import { campaignScheduleTypeTitle } from 'pages/campaigns/ui/CampaignSchedule/store/types'
import { LaterContent } from 'pages/campaigns/ui/CampaignSchedule/ui/LaterContent/LaterContent'
import styles from './styles.module.scss'

export const CampaignScheduleUi = observer(() => {
  const { scheduleText, scheduleType, setScheduleType } = useCampaignScheduleContext()

  const tabKeys: ICampaignScheduleType[] = ['immediately', 'delay']
  const tabs: ITabItem<ICampaignScheduleType>[] = tabKeys.map((key) => ({
    key,
    name: campaignScheduleTypeTitle[key],
  }))
  const handleSelectTab = (tab: ITabItem<ICampaignScheduleType>) => {
    setScheduleType(tab.key)
  }
  return (
    <Dropdown
      triggerComponent={() => (
        <Button size={'medium'} contained={'secondary'} icon={'schedule'} text={scheduleText} />
      )}
      placement={'bottom-start'}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              offset: ({ reference }) => {
                return [-8, -reference.height + -12]
              },
            },
          },
        ],
      }}
      customComponent={(_, dropdownId) => (
        <DropdownContent noPadding>
          <div className={styles.wrapTabs}>
            <Tabs
              tabs={tabs}
              activeTabKey={scheduleType}
              variant={'simpleWithoutBorder'}
              handleSelectTab={handleSelectTab}
            />
          </div>
          {scheduleType === 'delay' && <LaterContent dropdownId={dropdownId} />}
        </DropdownContent>
      )}
    />
  )
})
