import { observer } from 'mobx-react-lite'
import { TextInput } from 'shared/ui'
import { TRIGGER_NAME_LIMIT } from 'widgets/limits'
import { useTriggerViewContext } from 'widgets/TriggerView/context/triggerViewContext'

import styles from './styles.module.scss'

export const TriggerName = observer(() => {
  const { name, setName, setError, errorsMap } = useTriggerViewContext()

  const error = errorsMap.get('name')

  return (
    <TextInput
      size={'large'}
      placeholder={'Trigger name'}
      value={name}
      setValue={setName}
      error={error}
      setError={(value) => setError('name', value)}
      className={styles.triggerName}
      limit={TRIGGER_NAME_LIMIT}
    />
  )
})
