import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'

import { QuickLinksStore } from 'pages/settings/pages/quickLinks/store/QuickLinksStore'

const QuickLinksContext = createContext<QuickLinksStore | null>(null)

export const useQuickLinksContext = () => {
  const context = useContext(QuickLinksContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with QuickLinksProvider')

  return context
}

export const QuickLinksProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new QuickLinksStore(), [])

  useEffect(() => store.dispose, [])

  return <QuickLinksContext.Provider value={store}>{children}</QuickLinksContext.Provider>
}
