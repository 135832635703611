import { makeAutoObservable, runInAction } from 'mobx'
import { type IToastShow } from 'shared/ui'
import { errorHandler } from 'shared/api'
import { contactsStore } from 'entities/Contacts'
import { type IIntegrationLinkDTO, IntegrationKey, IntegrationsApi } from 'entities/Integrations'
import type {
  IResponseContactUnlinkedSource,
  IResponseContactUnlinkedConflictItem,
} from 'entities/Contacts/api/types'
import type { Contact } from 'entities/Contacts/model/Contact'

export type ContactLinkTabsType = 'matching' | 'manually' | string

export class LinkContactStore {
  private _modalId: string | null = null
  private _targetContact: IResponseContactUnlinkedSource | null = null
  private _selectedContact: Contact | null = null
  private _selectedMatchedId: number | null = null
  private _selectedMatchedPhone: string | null = null
  private _loading = false
  private _search = ''
  private _shouldUpdate = true
  private _activeTab: ContactLinkTabsType = 'matching'
  private _integrationKey: IntegrationKey | null = null
  private _matchedContactsMap: Map<number, IResponseContactUnlinkedConflictItem> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  get selectedMatchedPhone() {
    return this._selectedMatchedPhone
  }

  get search() {
    return this._search
  }

  get shouldUpdate() {
    return this._shouldUpdate
  }

  get integrationKey() {
    return this._integrationKey
  }

  get modalId() {
    return this._modalId
  }

  get selectedMatchedId() {
    return this._selectedMatchedId
  }

  get activeTab() {
    return this._activeTab
  }

  get loading() {
    return this._loading
  }

  get targetContact() {
    return this._targetContact
  }

  get selectedContact() {
    return this._selectedContact
  }

  get avatarInfo() {
    const colors = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']
    const color = this._targetContact?.id
      ? colors[+this._targetContact.id % colors.length]
      : undefined

    const firstName = this._targetContact?.full_name?.split(' ')?.[0] || undefined
    const lastName = this._targetContact?.full_name?.split(' ')?.[1] || undefined

    return {
      firstName,
      lastName,
      color,
      number: this._targetContact?.number,
      image: undefined,
    }
  }

  get matchedContactsList() {
    return Array.from(this._matchedContactsMap.values())
  }

  get matchedCount() {
    return this.matchedContactsList.length
  }

  get integrationName() {
    switch (this._integrationKey) {
      case IntegrationKey.hubspot:
        return 'HubSpot'
      case IntegrationKey.infusionsoft:
        return 'Keap'
      case IntegrationKey.activecampaign:
        return 'ActiveCampaign'
      case IntegrationKey.pipedrive:
        return 'Pipedrive'
      case IntegrationKey.salesforce:
        return 'Salesforce'
    }

    return ''
  }

  get integrationIcon() {
    switch (this._integrationKey) {
      case IntegrationKey.hubspot:
        return 'hubspot'
      case IntegrationKey.infusionsoft:
        return 'infusionsoft'
      case IntegrationKey.activecampaign:
        return 'activecampaign'
      case IntegrationKey.pipedrive:
        return 'pipedrive'
      case IntegrationKey.salesforce:
        return 'salesforce'
    }

    return null
  }

  init = (
    contact: IResponseContactUnlinkedSource,
    matchedContacts: IResponseContactUnlinkedConflictItem[],
    integrationKey: IntegrationKey | null,
    modalId: string
  ) => {
    this._targetContact = contact
    this._integrationKey = integrationKey
    this._modalId = modalId
    matchedContacts.forEach((item) => {
      this._matchedContactsMap.set(item.id, item)
    })
  }

  selectContact = (contact: Contact) => {
    this._selectedContact = contact
  }

  handleSelectedMatchedPhone = (value: string) => {
    this._selectedMatchedPhone = value
  }

  handleMatchedContactId = (id: number | null) => {
    this._selectedMatchedId = id
  }

  handleActiveTab = (tab: ContactLinkTabsType) => {
    this._activeTab = tab
  }

  toggleUpdate = (value: boolean) => {
    this._shouldUpdate = value
  }

  handleSearch = (search: string) => {
    this._search = search
  }

  reset = () => {
    this._loading = false
    this._selectedContact = null
    this._targetContact = null
    this._selectedMatchedId = null
    this._matchedContactsMap.clear()
    this._modalId = null
    this._shouldUpdate = true
    this._search = ''
    this._integrationKey = null
  }

  resetManual = () => {
    this._loading = false
    this._selectedContact = null
    this._search = ''
  }

  onLink = async (reqData: IIntegrationLinkDTO) => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await IntegrationsApi.linkContactsIntegrations(reqData)

      contactsStore.updateItem(data)

      const status: IToastShow = {
        title: `${reqData?.integration_name || ''} contact linked`,
        type: 'success',
      }

      return status
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<{
        message: string[]
        contact_integration_id: string[]
      }>(err)
      const status: IToastShow = {
        title: '',
        type: 'error',
      }

      if (type === 'axios-error') {
        status.title =
          error?.response?.data?.message?.[0] ||
          error?.response?.data?.contact_integration_id?.[0] ||
          'link contact error'
      }

      return status
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }
}

export const linkContactStore = new LinkContactStore()
