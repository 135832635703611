import { Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { CampaignRoutes } from 'pages/campaigns/route/type'
import { CampaignLayout } from 'pages/campaigns/layout/CampaignLayout'
import { AllCampaigns, CreateCampaign, EditCampaign, ViewCampaign } from 'pages/campaigns/pages'
import { CloneCampaign } from 'pages/campaigns/pages/clone/CloneCampaign'
import { CampaignsAccessLayout } from 'pages/campaigns/layout/CampaignsAccessLayout'

export const CampaignRouter = observer(() => {
  if (featureFlagsStore.campaigns) {
    return (
      <Routes>
        <Route element={<CampaignsAccessLayout />}>
          <Route element={<CampaignLayout />}>
            <Route index element={<Navigate to={CampaignRoutes.all} />} />
            <Route path={CampaignRoutes.all} element={<AllCampaigns />} />
            <Route path={CampaignRoutes.create} element={<CreateCampaign />} />
            <Route path={`${CampaignRoutes.edit}/:id`} element={<EditCampaign />} />
            <Route path={`${CampaignRoutes.clone}/:id`} element={<CloneCampaign />} />
            <Route path={`${CampaignRoutes.view}/:id`} element={<ViewCampaign />} />
          </Route>
        </Route>
      </Routes>
    )
  }
  if (featureFlagsStore.campaigns === null) {
    return <SpinnerLoader />
  }

  return <Navigate to={'/'} />
})
