import React from 'react'
import { observer } from 'mobx-react-lite'
import { Status, Tooltip } from 'shared/ui'
import type { Integration } from 'entities/Integrations'

type IIntegrationStatusProps = {
  integration: Integration
}

export const IntegrationStatus = observer(({ integration }: IIntegrationStatusProps) => {
  if (integration.isConnected) {
    return <Status title='Connected' intent='positive' icon='check2' emphasis='high' />
  }
  if (integration.shouldReconnect) {
    return (
      <Tooltip
        label='There were some issues with your integration. Please reconnect it to resolve the problem'
        width={230}
        placement='top'
        withoutPointer
      >
        <Status title='Reconnect' intent='warning' icon='exclamationCircle' emphasis='high' />
      </Tooltip>
    )
  }
  return null
})
