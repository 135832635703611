import { observer } from 'mobx-react-lite'
import { Box, Icon, Typography } from 'shared/ui'
import { integrationsStore } from 'entities/Integrations'

type IWidgetIntegrationLabelProps = {
  id: string | number
  insideBox?: boolean
}

export const WidgetIntegrationLabel = observer(
  ({ id, insideBox }: IWidgetIntegrationLabelProps) => {
    const integrationId = Number(id)
    const integration = integrationsStore.getIntegrationById(integrationId)

    return (
      <Box display='flex' gap={1} alignItems='center' height={32}>
        <Icon
          icon={integration?.icon || 'lightning2'}
          insideBox={insideBox}
          padding={3}
          fontSize={14}
          tertiary
        />
        <Typography ariaLabel='IntegrationLabel' variant='body-md-regular'>
          {integration?.name ?? 'Custom'}
        </Typography>
      </Box>
    )
  }
)
