import React from 'react'
import { observer } from 'mobx-react-lite'
import { SendFromDropdown } from 'widgets/SendFromDropdown'
import { CampaignSendFromStore } from './store/CampaignSendFromStore'

type ICampaignSMSProps = {
  store: CampaignSendFromStore
}

export const CampaignSendFrom = observer(({ store }: ICampaignSMSProps) => {
  return (
    <SendFromDropdown
      store={store.sendFromDropdownStore}
      error={{
        message: store.error,
        anchor: 'container',
        offset: [0, 0],
      }}
    />
  )
})
