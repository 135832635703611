import React from 'react'
import { observer } from 'mobx-react-lite'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'
import { SMSMessageField } from 'widgets/MessageFormFields'

export const BroadcastSMS = observer(() => {
  const { broadcastSMSStore, errorsMap, setDraggingCondition } = useBroadcastViewContext()
  const smsMessageError = errorsMap.get('smsMessage')

  return (
    <SMSMessageField
      smsMessageFieldStore={broadcastSMSStore.smsMessageFieldStore}
      errorMessage={smsMessageError}
      setDraggingCondition={setDraggingCondition}
    />
  )
})
