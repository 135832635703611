import { makeAutoObservable } from 'mobx'

import { IQuickLinksSearchStore, QuickLinksSearchStore } from './QuickLinksSearchStore'

const PREVIEW_LINKS_NUMBER = 5

export class QuickLinksPreviewStore {
  private _searchStore: IQuickLinksSearchStore

  constructor(private _contactId: number) {
    this._searchStore = new QuickLinksSearchStore(this._contactId)
    this._searchStore.search('', true)

    makeAutoObservable(this)
  }

  get loading() {
    return this._searchStore.loading
  }

  get quickLinks() {
    return this._searchStore.quickLinks.slice(0, PREVIEW_LINKS_NUMBER)
  }

  get hasMore() {
    return this._searchStore.quickLinks.length > PREVIEW_LINKS_NUMBER
  }

  get isEmpty() {
    return this._searchStore.isEmpty
  }

  dispose = () => this._searchStore.dispose()
}
