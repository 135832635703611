import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import styles from './styles.module.scss'

export const PowerDialerActions = observer(() => {
  return (
    <div
      className={classNames(styles.actionsContainer, {
        [styles.emptyView]: true,
      })}
    >
      <Button
        tag='a'
        text='Help guide'
        size='medium'
        contained='secondary'
        iconProps={{ icon: 'info' }}
        className={styles.helpButton}
        href={links.powerDialer}
        target='_blank'
        rel='noreferrer'
      />
      <Button text='New session' iconProps={{ icon: 'add' }} size='medium' onClick={() => {}} />
    </div>
  )
})
