import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Typography } from 'shared/ui'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'

type CustomPriceFormProps = {
  adminCustomPricesForm: AdminCustomPricesForm
}

export const CustomPriceSummary = observer(({ adminCustomPricesForm }: CustomPriceFormProps) => {
  return (
    <Box bgcolor='var(--background-neutral-subtle)' mt={2} p={2} borderRadius='8px'>
      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Summary'}
        >
          Summary
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography variant={'body-md-regular'} color='tertiary' ariaLabel={'Custom price name'}>
          Custom price name
        </Typography>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Custom price name value'}
        >
          {adminCustomPricesForm.customPriceLabel}
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography variant={'body-md-regular'} color='tertiary' ariaLabel={'Total Credits'}>
          Total Credits
        </Typography>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Total credits value'}
        >
          {adminCustomPricesForm.creditsSummary}
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography variant={'body-md-regular'} color='tertiary' ariaLabel={'Contract Term'}>
          Contract Term
        </Typography>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Contract term value'}
        >
          {adminCustomPricesForm.termLabel}
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography variant={'body-md-regular'} color='tertiary' ariaLabel={'Billing frequency'}>
          Billing frequency
        </Typography>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Billing frequency value'}
        >
          {adminCustomPricesForm.billingFrequencyLabel}
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography variant={'body-md-regular'} color='tertiary' ariaLabel={'Price per credits'}>
          Price per credits
        </Typography>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Price per credits value'}
        >
          {adminCustomPricesForm.pricePerCredits}
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography
          variant={'body-md-regular'}
          color='tertiary'
          ariaLabel={'Additional credits price'}
        >
          Additional credits price
        </Typography>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Additional credits price value'}
        >
          {adminCustomPricesForm.additionalCreditsPriceLabel}
        </Typography>
      </Box>

      {adminCustomPricesForm.billingFrequency === adminCustomPricesForm.contractTerm ? (
        <Box display='flex' justifyContent='space-between' mb={1}>
          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'Price'}
          >
            Price
          </Typography>
          <Typography
            variant={'body-md-semibold'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'Price value'}
          >
            {adminCustomPricesForm.priceLabel}
          </Typography>
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='space-between' mb={1}>
            <Typography
              variant={'body-md-regular'}
              color={'var(--content-primary-primary)'}
              ariaLabel={'Price for contract term'}
            >
              Price for contract term
            </Typography>
            <Typography
              variant={'body-md-semibold'}
              color={'var(--content-primary-primary)'}
              ariaLabel={'Price for contract term value'}
            >
              {adminCustomPricesForm.priceLabel}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='space-between' mb={1}>
            <Typography
              variant={'body-md-regular'}
              color={'var(--content-primary-primary)'}
              ariaLabel={'Price for billingFrequency'}
            >
              Price for {adminCustomPricesForm.billingFrequencyToMonth}
            </Typography>
            <Typography
              variant={'body-md-semibold'}
              color={'var(--content-primary-primary)'}
              ariaLabel={'Price for billingFrequency value'}
            >
              {adminCustomPricesForm.priceByBillingFrequency}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
})
