import { SxProps, TableCell as TableCellMu, Theme } from '@mui/material'
import classNames from 'classnames'
import { NavLink } from 'shared/ui/NavLink'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

interface ITableCellProps {
  minWidth?: string | number
  maxWidth?: string | number
  width?: string | number
  isHeader?: boolean
  isOverflowInitial?: boolean
  className?: string
  sx?: SxProps<Theme>
  children: React.ReactNode
  onCellClick?: () => void
  link?: string
  padding?: string | number
  colSpan?: number
  isPointer?: boolean
  ariaLabel?: string
}

export const TableCell = ({
  minWidth,
  maxWidth = '362px',
  width,
  isHeader = false,
  isOverflowInitial,
  className,
  sx,
  children,
  onCellClick,
  link,
  padding,
  colSpan,
  isPointer,
  ariaLabel,
}: ITableCellProps) => {
  const content = (
    <div
      className={classNames(styles.tableCell, className, {
        [styles.headerCell]: isHeader,
        [styles.overflowInitial]: isOverflowInitial,
      })}
      style={{ padding }}
    >
      {children}
    </div>
  )
  return (
    <TableCellMu
      sx={{
        border: 'none',
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: width,
        padding: 0,
        color: isHeader ? 'var(--content-primary-tertiary)' : 'var(--content-primary-primary)',
        fontWeight: isHeader ? 500 : 400,
        fontSize: 13,
        cursor: isPointer ? 'pointer' : undefined,
        ...sx,
      }}
      aria-label={ariaLabel ? getAriaLabel(ariaLabel) : undefined}
      colSpan={colSpan}
      // In className we can add styles which doesn't contain sx block. Sx is inline styles and has priority

      onClick={onCellClick}
    >
      {link ? (
        <NavLink to={link} className={styles.link}>
          {content}
        </NavLink>
      ) : (
        content
      )}
    </TableCellMu>
  )
}
