import { CampaignViewContext } from 'pages/campaigns/ui/CampaignView/context/context'
import { CampaignViewStore } from 'pages/campaigns/ui/CampaignView/store/CampaignViewStore'
import {
  CampaignViewUi,
  ICampaignViewUiProps,
} from 'pages/campaigns/ui/CampaignView/ui/CampaignViewUi'

type ICampaignViewProps = {
  store: CampaignViewStore
} & ICampaignViewUiProps

export const CampaignView = ({ store, ...props }: ICampaignViewProps) => {
  return (
    <CampaignViewContext.Provider value={store}>
      <CampaignViewUi {...props} />
    </CampaignViewContext.Provider>
  )
}
