import { observer } from 'mobx-react-lite'
import { Box, Button, SearchDropdownInput, TextFieldWithLabel } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import einExample from 'shared/assets/images/einExample.png'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { getCDNAssetURL } from 'shared/lib'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { LinkButton } from 'pages/settings/pages/compliance/ui/LinkButton/LinkButton'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import styles from './styles.module.scss'
import { WebsiteFields } from './WebsiteFields'

export const AutoBusinessFind = observer(() => {
  const { businessInfoStore, fieldsError } = useTollFreeStepsContext()
  const {
    einItem,
    einSearch,
    setFirstEinItem,
    einItemList,
    setEinSearch,
    errorMessage,
    checkEinNumber,
    setGeneralInfoActiveTab,
    websiteUrlLocalError,
    noWebsiteUrl,
    websiteUrl,
    setWebsiteUrl,
    handleSetNoWebsiteUrl,
    validateWebsiteUrl,
    states,
    socialMediaUrlError,
    socialMediaUrl,
    setSocialMediaUrl,
  } = businessInfoStore
  const linkToInfo = getCDNAssetURL(einExample)

  return (
    <Box>
      <div>
        <Label
          marginBottom={4}
          label={{
            text: 'Employer Identification Number (EIN)',
            rightNode: <LinkButton text={'Where can I find it?'} link={linkToInfo} />,
          }}
        />
        <SearchDropdownInput
          items={einItemList}
          search={einSearch}
          tipTitle='Search by EIN, e.g. 12-3456789'
          width={485}
          onChangeSearch={setEinSearch}
          errorMessageValue={errorMessage}
          onSetValue={setFirstEinItem}
          textFieldProps={{
            variant: 'stroke',
            size: 'medium',
            InputProps: {
              placeholder: 'Search',
              onBlur: checkEinNumber,
            },
            leftActions: [
              makeTextFieldIcon({
                icon: 'search',
                className: styles.searchIcon,
              }),
            ],
          }}
        />
        <Button
          text={'I don’t have a United States EIN number'}
          size={'small'}
          typeBtn='informative'
          contained='secondary'
          onClick={() => setGeneralInfoActiveTab(2)}
          className={styles.noHaveEinButton}
        />
      </div>

      {einItem?.ein && (
        <div className={styles.einFieldContainer}>
          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Employer Identification Number (EIN)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einSearch,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Legal business name',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.name,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street1,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address 2 (optional)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street2,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'City',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.city,
              className: styles.numberOfCreditsInput,
            }}
          />

          <Box display='flex' justifyContent='space-between' gap={'12px'} marginBottom={'24px'}>
            <Box flex={1}>
              <SelectField
                items={states}
                setValue={() => {}}
                value={einItem?.state || ''}
                btnProps={{
                  size: 'large',
                  disabled: true,
                  text: einItem?.state,
                  style: {
                    height: '40px',
                  },
                }}
                dropdownProps={{
                  disabled: true,
                }}
                labelProps={{
                  label: {
                    text: 'State',
                  },
                }}
              />
            </Box>
            <Box flex={1}>
              <TextFieldWithLabel
                marginBottom={0}
                labelProps={{
                  label: {
                    text: 'ZIP code',
                  },
                }}
                textFieldProps={{
                  disabled: true,
                  value: einItem?.zip,
                }}
              />
            </Box>
          </Box>
          <WebsiteFields
            {...{
              websiteUrlLocalError,
              noWebsiteUrl,
              websiteUrl,
              setWebsiteUrl,
              handleSetNoWebsiteUrl,
              validateWebsiteUrl,
              socialMediaUrlError,
              socialMediaUrl,
              setSocialMediaUrl,
              fieldsError,
            }}
          />
        </div>
      )}
    </Box>
  )
})
