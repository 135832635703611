import React, { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Avatar, Icon, IconButton, Tooltip, Typography } from 'shared/ui'
import { getLabelAsNumberInternationalFormat, isNumeric } from 'shared/lib'
import { type CallContact } from 'entities/Call/model/CallContact'
import { useCopyToClipboard } from 'widgets/CallPopUp/hooks/useCopyToClipboard'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { type CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

type IContactCardProps = {
  item: CallContact
  onChange?: (item: CallContact) => void
  onDelete?: (item: CallContact) => void
  innerRefCurrent?: HTMLDivElement
}

export const ContactCard: FC<IContactCardProps> = observer(
  ({ item, onChange, innerRefCurrent, onDelete }) => {
    const [showEllipsis, setShowEllipsis] = useState(false)
    const { onClickCopy, setOpen, open, copied } = useCopyToClipboard(
      getLabelAsNumberInternationalFormat(item.number)
    )

    const store = useCallPopUpDefaultContext()
    const { isHover } = store as CallPopUpDefaultStore

    const name = item.name || item.number
    const hasNumber = name.startsWith('+') && isNumeric(name)

    return (
      <div
        key={item.id}
        className={styles.item}
        onClick={() => {
          onChange?.(item)
        }}
      >
        <div className={styles.item__content}>
          <div className={styles.item__icon}>
            <Avatar {...item.avatarProps} />
          </div>
          <div className={styles.item__info}>
            <div
              className={styles.item__label}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            >
              <Typography
                ellipsis
                tag={'div'}
                className={styles.item__title}
                variant={'body-md-regular'}
                ariaLabel={'name'}
                tooltipProps={{ label: item.name, zIndex: 5001 }}
                onShowEllipsis={setShowEllipsis}
              >
                {item.name ||
                  (item.number && getLabelAsNumberInternationalFormat(item.number)) ||
                  item.formatted_number}
              </Typography>
              {item.number && (
                <Tooltip
                  placement='top'
                  padding={4}
                  label={
                    <>
                      {!hasNumber && showEllipsis && (
                        <Typography
                          tag={'div'}
                          ariaLabel='callPopUpTooltipTitle'
                          variant={'body-sm-regular'}
                          color={'var(--content-primary-tertiary-inverted'}
                          className={styles.item__tooltipTitle}
                        >
                          {item.name}
                        </Typography>
                      )}
                      <button className={styles.item__button} onClick={onClickCopy}>
                        <div className={styles.item__button__box}>
                          <Icon
                            icon='copy'
                            fontSize={12}
                            color={'var(--content-primary-primary-inverted'}
                          />
                          <Typography
                            tag={'div'}
                            ariaLabel='callPopUpNumber'
                            variant={'body-md-regular'}
                            color={'var(--content-primary-primary-inverted'}
                            className={styles.item__number}
                          >
                            {copied
                              ? 'Copied'
                              : hasNumber
                              ? 'Copy'
                              : getLabelAsNumberInternationalFormat(item.number)}
                          </Typography>
                        </div>
                      </button>
                    </>
                  }
                  zIndex={5001}
                  open={open}
                >
                  <Icon
                    className={classnames(styles.item__iconInfo, {
                      [styles.visible]: isHover,
                    })}
                    icon='infoCircle'
                    fontSize={12}
                    color={'var(--content-neutral-primary)'}
                  />
                </Tooltip>
              )}
            </div>
            <Typography
              className={styles.item__desc}
              variant={'body-xs-regular'}
              ariaLabel={'timeWait'}
            >
              {item.timeWait}
            </Typography>
          </div>
        </div>

        {onDelete && (
          <div className={styles.item__actions}>
            <IconButton
              ariaLabel={'Remove'}
              icon={'phoneHangUp'}
              size={'small'}
              color={'tertiary'}
              variant={'icon'}
              tooltipProps={{
                label: 'Remove from call',
                placement: 'top',
                zIndex: 5001,
                PopperProps: {
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'preventOverflow',
                        options: {
                          boundary: innerRefCurrent,
                        },
                      },
                    ],
                  },
                },
              }}
              onClick={() => {
                onDelete(item)
              }}
            />
          </div>
        )}
      </div>
    )
  }
)
