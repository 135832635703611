export const summaryData = {
  private: {
    brandRegistrationFee: '$4.00',
    brandRegistrationFeeCount: 4,
    campaignVerificationFee: '$15.00',
    campaignVerificationFeeCount: 15,
    useCaseFee: '$1.50/month',
    useCaseFeeCount: 1.5,
  },
  public: {
    brandRegistrationFee: '$44.00',
    brandRegistrationFeeCount: 44,
    campaignVerificationFee: '$15.00',
    campaignVerificationFeeCount: 15,
    useCaseFee: '$10.00 / month',
    useCaseFeeCount: 10,
  },
}
