import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { SpinnerLoader } from 'shared/ui'
import { authStore } from 'entities/Auth'
import { websocket } from 'entities/WebSocket'
import { clearSessionIntercom, initIntercom } from 'entities/Intercom'

import { CommonLayout } from './CommonLayout'

type IAuthRefreshLayoutProps = {
  isTryRefreshWithoutToken?: boolean
  withoutCommonComponents?: boolean
}

export const AuthRefreshLayout = observer(
  ({ isTryRefreshWithoutToken, withoutCommonComponents }: IAuthRefreshLayoutProps) => {
    const { refresh, error } = authStore
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      clearSessionIntercom()

      refresh({
        isTryRefreshWithoutToken,
      })
        .then((data) => {
          if (data?.is_login) {
            return websocket.connect(localStorage.getItem('token'))
          } else {
            initIntercom()
            authStore.removeToken()
            setLoading(false)
          }
        })
        .then(() => {
          setLoading(false)
        })
    }, [])

    if (loading) {
      return (
        <div className={'spinner'}>
          <SpinnerLoader />
        </div>
      )
    }

    if (error) {
      return <>{error}</>
    }

    return (
      <>
        {!withoutCommonComponents && <CommonLayout />}
        <Outlet />
      </>
    )
  }
)
