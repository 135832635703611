import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { CopyWrapper, Icon, Typography, Space } from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { ItemCompaniesRow } from './ItemCompaniesRow'

import styles from '../styles.module.scss'

const AllCompanies = observer(() => {
  const [search, setSearch] = useState('')

  const store = useContactsDetailsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)

  if (!store.viewIntegrationKey || !contact) return null

  const userIntegration = integrationsStore.userIntegrationsMap.get(store.viewIntegrationKey)

  const link = (id: number | null | undefined) => {
    const linkProps = {
      key: store.viewIntegrationKey,
      contact,
      integration: userIntegration,
      type: IIntegrationLinkTypes.company,
      id,
    }

    return integrationLink(linkProps)
  }
  const companies = store.viewAllData.companies.filter(
    (company) => company?.name?.value?.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const notFound = companies.length === 0

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.search}>
        <TextField
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
          leftActions={[makeTextFieldIcon({ icon: 'search' })]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.list}>
        {companies.map((company) => (
          <div key={company?.id?.value}>
            <div className={styles.headerWrapper}>
              <CopyWrapper
                copyValue={company?.name?.value || null}
                data={{
                  title: company?.name?.value || 'View company',
                  link: link(company?.id?.value),
                }}
              />
            </div>
            <ItemCompaniesRow company={company} />
          </div>
        ))}
      </div>
      {notFound ? (
        <div className={styles.epmtyList}>
          <Icon icon='search' fontSize={24} />
          <Typography variant={'body-rg-regular'} ariaLabel={'NoResultsFound'}>
            No results found
          </Typography>
        </div>
      ) : (
        <Space height={60} />
      )}
    </div>
  )
})

export { AllCompanies }
