// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cczUJgmHOHnx0l8kmYoS{overflow:auto;margin-top:9px}.JntzRIlrMVrbKj7RMUSW{border-top:1px solid var(--border-neutral-default)}.u8L9ppXwPnj5RfP91wIh,.qSa6ottPeyjXZHHDl9cs{display:inline-flex;margin-top:40px;margin-bottom:10px}.aSMnAXlkikfVlBIcjGRe{height:1px;background:var(--border-neutral-default);margin:15.5px 0}.xKaI6lEoZMZXUmlklUHf{display:flex;gap:4px;flex-wrap:wrap}.btOkzFAOyaJuqgN7LZrL{display:flex;padding:4px 8px;border-radius:6px;border:1px solid var(--border-neutral-default);background:var(--background-primary-inverted-primary);color:var(--content-primary-primary)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/BusinessProfileModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,cAAA,CAGF,sBACE,kDAAA,CAGF,4CAEE,mBAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,wCAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,eAAA,CACA,iBAAA,CACA,8CAAA,CACA,qDAAA,CACA,oCAAA","sourcesContent":[".wrap {\n  overflow: auto;\n  margin-top: 9px;\n}\n\n.topBorder {\n  border-top: 1px solid var(--border-neutral-default);\n}\n\n.link,\n.title {\n  display: inline-flex;\n  margin-top: 40px;\n  margin-bottom: 10px;\n}\n\n.divider {\n  height: 1px;\n  background: var(--border-neutral-default);\n  margin: 15.5px 0;\n}\n\n.regionsWrap {\n  display: flex;\n  gap: 4px;\n  flex-wrap: wrap;\n}\n\n.region {\n  display: flex;\n  padding: 4px 8px;\n  border-radius: 6px;\n  border: 1px solid var(--border-neutral-default);\n  background: var(--background-primary-inverted-primary);\n  color: var(--content-primary-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "cczUJgmHOHnx0l8kmYoS",
	"topBorder": "JntzRIlrMVrbKj7RMUSW",
	"link": "u8L9ppXwPnj5RfP91wIh",
	"title": "qSa6ottPeyjXZHHDl9cs",
	"divider": "aSMnAXlkikfVlBIcjGRe",
	"regionsWrap": "xKaI6lEoZMZXUmlklUHf",
	"region": "btOkzFAOyaJuqgN7LZrL"
};
export default ___CSS_LOADER_EXPORT___;
