import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ServiceError } from 'shared/ui/ServiceError'

import OverlayLayout from './layouts/OverlayLayout'

const routes = createRoutesFromElements(
  <Route path='/*' element={<OverlayLayout />} errorElement={<ServiceError name='overlay' />} />
)

const router = wrapCreateBrowserRouter(createBrowserRouter)(routes)

export const OverlayService = () => <RouterProvider router={router} />
