import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'

import styles from './styles.module.scss'

export const QuickLinksNoResults = observer(() => (
  <Typography className={styles.noResults} ariaLabel={'No results'} variant='body-sm-regular'>
    No search results
  </Typography>
))
