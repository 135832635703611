import {
  type IModalInfoRowProps,
  ModalInfoRow,
} from 'pages/settings/pages/compliance/ui/ModalInfoRow/ModalInfoRow'

import styles from './styles.module.scss'

type IModalInfoBlockProps = {
  lines: IModalInfoRowProps[]
}

export const ModalInfoBlock = ({ lines }: IModalInfoBlockProps) => {
  return (
    <div className={styles.grid}>
      {lines.map((line, index) => (
        <ModalInfoRow key={index} label={line.label} value={line.value} tooltip={line.tooltip} />
      ))}
    </div>
  )
}
