import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { nanoid } from 'nanoid'
import { IDropdownItem } from 'shared/ui'
import { MAX_LAST_NAME_LENGTH, MAX_FIRST_NAME_LENGTH } from 'shared/constants/auth'
import { IRegisterCookies } from 'entities/Auth/store/types'
import { CountryEnum } from 'widgets/Register/ui/Step3/store/type'
import { FieldsEnum } from './type'

export class Step4Store {
  private _loading = false
  private _firstName = ''
  private _lastName = ''
  private _number = ''
  private _country: IDropdownItem = {
    label: 'United States',
    id: CountryEnum.US,
    iconL: 'flagUSA',
    labelRight: '+1',
  }
  private _cardComplete = false
  private _cardError: string | null = null
  private _activeField: FieldsEnum | null = null
  private _showRetryAlert = false
  // Number validation
  private _disposeNumberValidation: IReactionDisposer | null = null
  private _numberError: string | null = null
  private _numberRequestError: string | null = null
  private _numberValidationTrigger: string | null = null

  constructor() {
    makeAutoObservable(this)
    this.reactionNumberValidation()
  }

  get payload() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      number: this.number,
      country: this.country.id as string,
    }
  }

  initCookies = (data: IRegisterCookies) => {
    if (data.first_name) this._firstName = data.first_name
    if (data.last_name) this._lastName = data.last_name
    if (data.number) this._number = data.number
    if (data.country) {
      const country = this.countries.find((item) => item.id === data.country)
      if (country) this._country = country
    }
  }

  get showRetryAlert() {
    return this._showRetryAlert
  }

  get firstName() {
    return this._firstName
  }

  get firstNameLength() {
    return this.firstName.length
  }

  get isMaxFirstNameLength() {
    return this.firstNameLength === MAX_FIRST_NAME_LENGTH
  }

  get isMaxLastNameLength() {
    return this.lastNameLength === MAX_LAST_NAME_LENGTH
  }

  get numberError() {
    return this._numberError
  }

  get numberRequestError() {
    return this._numberRequestError
  }

  get loading() {
    return this._loading
  }

  get lastName() {
    return this._lastName
  }

  get lastNameLength() {
    return this.lastName.length
  }

  get number() {
    return this._number
  }

  get isValidNumber() {
    return this.number.replace(/[^-.0-9]/g, '').length === 11
  }

  get isActiveLastNameField() {
    return this._activeField === FieldsEnum.lastName
  }

  get isActiveFirstNameField() {
    return this._activeField === FieldsEnum.firstName
  }

  get country() {
    return this._country
  }

  get cardComplete() {
    return this._cardComplete
  }

  get cardError() {
    return this._cardError
  }

  get countries(): IDropdownItem[] {
    return [
      {
        label: 'United States',
        id: CountryEnum.US,
        iconL: 'flagUSA',
        labelRight: '+1',
      },
      {
        label: 'Canada',
        id: CountryEnum.CA,
        iconL: 'flagCanada',
        labelRight: '+1',
      },
    ]
  }

  setShowRetryError = (value: boolean) => {
    this._showRetryAlert = value
  }

  triggerNumberValidation = () => {
    this._numberValidationTrigger = nanoid()
  }

  focusFirstNameField = () => {
    this.setActiveField(FieldsEnum.firstName)
  }

  focusLastNameField = () => {
    this.setActiveField(FieldsEnum.lastName)
  }

  blurFirstNameField = () => {
    this.blurActiveField(FieldsEnum.firstName)
  }

  blurLastNameField = () => {
    this.blurActiveField(FieldsEnum.lastName)
  }

  blurActiveField = (value: FieldsEnum) => {
    if (this._activeField !== value) return
    this._activeField = null
  }

  setActiveField = (value: FieldsEnum) => {
    this._activeField = value
  }

  clearCardError() {
    this._cardError = null
  }

  setCardError(value: string | null) {
    this._cardError = value
  }

  setFirstName = (value: string) => {
    if (value.length > MAX_FIRST_NAME_LENGTH) return
    this._firstName = value
  }

  setLastName = (value: string) => {
    if (value.length > MAX_LAST_NAME_LENGTH) return
    this._lastName = value
  }

  setNumber = (value: string) => {
    if (this._numberRequestError) this.setNumberRequestError('')
    this._number = value
  }

  setNumberError = (value: string) => {
    this._numberError = value
  }

  setNumberRequestError = (value: string) => {
    this._numberRequestError = value
  }

  setCountry = (value: IDropdownItem) => {
    this._country = value
  }

  onChangeCard = (event: StripeCardElementChangeEvent) => {
    this._cardComplete = event.complete
    this._cardError = event.error?.message || null
  }

  get disabledSignUp() {
    return !this._firstName || !this._lastName || !this.isValidNumber || !this._cardComplete
  }

  reactionNumberValidation = () => {
    this._disposeNumberValidation?.()
    this._disposeNumberValidation = reaction(
      () => [this._numberValidationTrigger, this.isValidNumber],
      ([trigger, isValid]) => {
        this._numberError = trigger && !isValid ? 'Please enter a valid phone number' : null
        if (isValid) this._numberValidationTrigger = null
      },
      {
        fireImmediately: true,
      }
    )
  }
}
