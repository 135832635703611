// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qP8KRRVc2YemEXgTPHnX{display:flex;align-items:center;border-radius:6px;padding:0 7px;border:none;outline:none;margin:0;height:24px;cursor:pointer}.baWlD7G5BiC8xFjP4eHA{color:var(--content-primary-primary);border:1px solid var(--black-10);background:var(--white-100)}.EoGIdvhOCk9WDuXu7Iuo{color:var(--background-primary-inverted-primary);border:1px solid var(--white-40);background:var(--white-transparency)}.gTv4MRdK4XE5XPAbl0Sb.EoGIdvhOCk9WDuXu7Iuo{color:var(--yellow-100);border:1px solid var(--black-10)}.URhuHeDRsfkY66gpS8ce.EoGIdvhOCk9WDuXu7Iuo{color:var(--light-blue-100);border:1px solid var(--black-10)}", "",{"version":3,"sources":["webpack://./src/shared/ui/AlertBanner/Button/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,aAAA,CACA,WAAA,CACA,YAAA,CACA,QAAA,CACA,WAAA,CACA,cAAA,CAEA,sBACE,oCAAA,CACA,gCAAA,CACA,2BAAA,CAGF,sBACE,gDAAA,CACA,gCAAA,CACA,oCAAA,CAMA,2CACE,uBAAA,CACA,gCAAA,CAMF,2CACE,2BAAA,CACA,gCAAA","sourcesContent":[".button {\n  display: flex;\n  align-items: center;\n  border-radius: 6px;\n  padding: 0 7px;\n  border: none;\n  outline: none;\n  margin: 0;\n  height: 24px;\n  cursor: pointer;\n\n  &--primary {\n    color: var(--content-primary-primary);\n    border: 1px solid var(--black-10);\n    background: var(--white-100);\n  }\n\n  &--secondary {\n    color: var(--background-primary-inverted-primary);\n    border: 1px solid var(--white-40);\n    background: var(--white-transparency);\n  }\n\n  &--banner_info {\n  }\n  &--banner_warning {\n    &.button--secondary {\n      color: var(--yellow-100);\n      border: 1px solid var(--black-10);\n    }\n  }\n  &--banner_error {\n  }\n  &--banner_info-light {\n    &.button--secondary {\n      color: var(--light-blue-100);\n      border: 1px solid var(--black-10);\n    }\n  }\n  &--banner_success {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "qP8KRRVc2YemEXgTPHnX",
	"button--primary": "baWlD7G5BiC8xFjP4eHA",
	"button--secondary": "EoGIdvhOCk9WDuXu7Iuo",
	"button--banner_warning": "gTv4MRdK4XE5XPAbl0Sb",
	"button--banner_info-light": "URhuHeDRsfkY66gpS8ce"
};
export default ___CSS_LOADER_EXPORT___;
