export type IResponseNumberVerifiedStatus =
  | 'Unverified'
  | 'Denied'
  | 'Blocked'
  | 'Verified'
  | 'Internal Review'
  | 'Under Review'

export type IResponseNumber = {
  id: number
  organization_id: number
  country: ICountriesEnum
  state: null
  city: null
  number: string
  number_vendor_id: number
  country_calling_code: string
  national_number: string
  formattedNumber: string
  formatted_number: string
  short_code: number
  is_landline: number
  is_zipwhip: number
  is_toll_free: boolean
  is_local: boolean
  is_aircall: boolean
  voice: boolean
  use_organization_call_settings: boolean
  voice_outbound: boolean
  mms: boolean
  disabled: null
  has_profile: boolean
  verified_status: IResponseNumberVerifiedStatus
  area_code: {
    id: number
    area_code: string
    state_code: string
    state_name: string
    country_code: string
    time_zone: string
  }
  landline: null
  number_vendor: {
    id: number
    key: string
    name: string
    is_active: boolean
  }
  type: 'Local' | 'Toll-Free' | string
  is_transcribe_voice_to_text: boolean
  conference_calling: boolean
  toll_free_verification_sid: null
  numberable_id?: number
  numberable?: IResponseNumberable
  call_forwarding?: IResponseCallForwarding
  number_caller_id: IResponseNumberCallerId | null
}

export type IResponseNumberCallerId = {
  caller_id_sid: string | null
  verified: boolean
  failed_reason: string | null
}

export type IResponseEventCallerIdVerificationResult = {
  organization_id: number
  inbox_id: number
  verificationResult: boolean
  number_id: number
}

export type IResponsePtfNumber = {
  pft_number: IResponseNumber
}

export type IResponseNumberVendorIntegrations = {
  'twilio-integration': {
    id: number
    user_id: number
    organization_id: number
    integration_id: number
    user_credential_id: number
    provider_user_id: string
    is_active: boolean
    url: null
    api_key: null
    client_id: null
    client_secret: null
    token: null
    sync_token: null
    webhook_id: null
    expires_at: null
    connection_error: number
    last_check_at: null
    created_at: string
    updated_at: string | null
    deleted_at: null
    identity: null
    webhook_url: string | null
    integration: {
      id: number
      key: string
      is_active: boolean
      name: string
      description: string | null
      created_at: string
      updated_at: string | null
      photo_url: string | null
      oauth: boolean
    }
  }
}

export type IResponseCallForwarding = {
  is_active: boolean
  is_screening: boolean
  is_call_whisper: boolean
  national_number: string | null
  number: string | null
  formatted_number: string | null
  extension: string | null
  call_whisper: string | null
}

export type IResponseNumberable = {
  id: number
  name: string
  numberable_type: 'App\\Users\\User' | 'App\\Teams\\Team' | 'App\\Numbers\\Shortcode'
}

export type IResponseNumberShortcodes = {
  created_at: string
  deleted_at: null
  id: number
  is_global: boolean
  name: string
  number: IResponseNumber
  organization_id: number
  owner_id: number
  team_id: null
  updated_at: string
}

export type IResponseNumberSearch = {
  country: string
  formatted: string
  number: string
}

export enum ICountriesEnum {
  US = 'US',
  CA = 'CA',
}

export type IParamsNumberSearch = {
  country: ICountriesEnum
  search: string
  state: string
  toll_free: string // "0" or "1" send "1" for toll-free numbers
}

export type IParamsNumbers = {
  page?: number
  limit?: number
  query?: string
  ids?: number[]
}

export type IParamsNumbersPool = {
  limit: number
}

export type IParamsAddNumber = {
  number: string
}

export type IResponseNumberPool = {
  formatted_number: string
  national_number: string
  number: string
  state: string
}

export type IResponseNumbersPool = IResponseNumberPool[]

export type IParamsUpdateNumberPtf = {
  pft_number: string
}

export type IResponseNumbers = {
  current_page: number
  data: IResponseNumber[]
  first_page_url: string | null
  from: null | number
  last_page: number
  last_page_url: string | null
  next_page_url: string | null
  path: string
  per_page: number
  prev_page_url: string | null
  to: null | number
  total: number
}
