import { CDN_FRONT, PUBLIC_PATH } from 'shared/config'

export const getCDNAssetURL = (url: string) => {
  const getValidUrl = () => {
    let newUrl = url
    if (PUBLIC_PATH && url.startsWith(PUBLIC_PATH)) {
      newUrl = newUrl.replace(PUBLIC_PATH, '')
    }
    if (newUrl.startsWith('/')) {
      newUrl = newUrl.replace('/', '')
    }
    return newUrl
  }

  return CDN_FRONT ? `https://${CDN_FRONT}/ui/v2/${getValidUrl()}` : url
}
