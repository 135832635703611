import { makeAutoObservable, reaction } from 'mobx'
import { type InboxesStore } from 'entities/Inbox/store/inboxes'
import { ChannelInbox, ChannelInboxConversation } from 'entities/WebSocket'

export class ChannelsInboxes {
  private _subscribersSet: Set<number> = new Set()

  constructor(private _inboxesStore: InboxesStore) {
    makeAutoObservable(this)
    reaction(() => this._inboxesStore.inboxesMap.size, this.subscribeChannels)
  }

  subscribeChannels = () => {
    this._inboxesStore.inboxesMap.forEach((inbox) => {
      this.subscribeChannel(inbox.id)
    })
  }

  subscribeChannel = (id: number) => {
    if (this._subscribersSet.has(id)) return
    new ChannelInbox({ id: id })
    new ChannelInboxConversation({ id: id })
    this._subscribersSet.add(id)
  }
}
