// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oRgX6RFOCLeOFzhNjNVp{gap:8px}", "",{"version":3,"sources":["webpack://./src/features/createOrEdit/NameInput/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,OAAA","sourcesContent":[".name {\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "oRgX6RFOCLeOFzhNjNVp"
};
export default ___CSS_LOADER_EXPORT___;
