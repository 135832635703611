import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import { nanoid } from 'nanoid'
import axios, { CancelTokenSource } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { type IDropdownItem, toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  type IResponseCannedMessage,
  CannedMessagesApi,
  SavedRepliesSortEnum,
  type ISavedRepliesProps,
  type IParamsGetCannedMessages,
  cannesMessageStore,
} from 'entities/CannedMessages'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { type MessageData } from 'entities/Message'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type Integration } from 'entities/Integrations/model/Integration'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { NewSavedReplyStore } from './newSavedReplyStore'
import { type ISavedRepliesStoreConfig } from './types'
import { SavedRepliesContent } from '../ui/SavedRepliesContent/SavedRepliesContent'
import { ListSavedRepliesStore } from './listSavedRepliesStore'

export class SavedRepliesStore {
  private _disposeReactionLoadData: IReactionDisposer | null = null
  private _savedRepliesMap = new Map<number, CannedMessage>()
  private _selectedSavedReply: CannedMessage | null = null
  private _selectedSavedReplyRef: React.RefObject<HTMLButtonElement> | null = null
  private _useScrollToElement = false
  private _contact: Contact | null = null
  private _integration: Integration | null = null
  private _onAddSavedReplies: ((message: MessageData) => void) | null = null
  private _search = ''
  private _loading = false
  private _visibleSavedRepliesActionsId = 0
  private _modalId = ''
  private _leftContentElement: HTMLDivElement | null = null
  private _cancelTokenSource: CancelTokenSource | null = null
  private _newSavedReplyStore: NewSavedReplyStore
  private _favoriteListStore: ListSavedRepliesStore
  private _unfavoriteListStore: ListSavedRepliesStore

  constructor(config?: ISavedRepliesStoreConfig) {
    this.reactionLoadData()

    this._newSavedReplyStore = new NewSavedReplyStore({
      savedRepliesStore: this,
      mergeFieldProps: config?.mergeFieldProps,
    })
    this._favoriteListStore = new ListSavedRepliesStore({
      filter: 'favorite',
      setSavedReplay: this.setSavedReplay,
    })
    this._unfavoriteListStore = new ListSavedRepliesStore({
      filter: 'unfavorite',
      setSavedReplay: this.setSavedReplay,
    })

    makeAutoObservable(this)
  }

  get leftContentElement() {
    return this._leftContentElement
  }

  get visibleSavedRepliesActionsId() {
    return this._visibleSavedRepliesActionsId
  }

  get selectedSavedReply() {
    return this._selectedSavedReply
  }

  get useScrollToElement() {
    return this._useScrollToElement
  }

  get selectedSavedReplyRef() {
    return this._selectedSavedReplyRef
  }

  get search() {
    return this._search
  }

  get unfavoriteListStore() {
    return this._unfavoriteListStore
  }

  get favoriteListStore() {
    return this._favoriteListStore
  }

  get modalId() {
    return this._modalId
  }

  get contact() {
    return this._contact
  }

  get newSavedReplyStore() {
    return this._newSavedReplyStore
  }

  setSavedReplay = (savedReplayResponse: IResponseCannedMessage) => {
    const savedReplay = new CannedMessage(savedReplayResponse)
    if (this._selectedSavedReply?.id === savedReplay.id) {
      this._selectedSavedReply = savedReplay
    }
    this._savedRepliesMap.set(savedReplay.id, savedReplay)
  }

  setSelectedSavedReplyRef = (ref: React.RefObject<HTMLButtonElement> | null) => {
    this._selectedSavedReplyRef = ref
  }

  setUseScrollToElement = (useScroll: boolean) => {
    this._useScrollToElement = useScroll
  }

  get order(): SavedRepliesSortEnum {
    return cannesMessageStore.order
  }

  get paramsGetItems(): IParamsGetCannedMessages {
    return {
      contact_id: this._contact?.id,
      integration_id: this._integration?.id,
      order: this.order,
      search: this._search,
    }
  }

  get loading() {
    return this._favoriteListStore.loading || this._unfavoriteListStore.loading || this._loading
  }

  get savedReplies() {
    const list = Array.from(this._savedRepliesMap.values())

    if (this.order === SavedRepliesSortEnum.letterDown) {
      list.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
        return 0
      })
    }

    if (this.order === SavedRepliesSortEnum.letterUp) {
      list.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) return -1
        if (a.title.toLowerCase() < b.title.toLowerCase()) return 1
        return 0
      })
    }

    if (this.order === SavedRepliesSortEnum.newest) {
      list.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp)
    }

    if (this.order === SavedRepliesSortEnum.oldest) {
      list.sort((a, b) => a.createdAtTimestamp - b.createdAtTimestamp)
    }

    return list
  }

  get hasFavorites() {
    return !!this.favoriteSavedReplies.length
  }

  get hasUnfavorites() {
    return !!this.unfavoriteSavedReplies.length
  }

  get hasSavedReplies() {
    return this.hasFavorites || this.hasUnfavorites
  }

  get favoriteSavedReplies() {
    return this.savedReplies.filter((reply) => reply.is_favorite)
  }

  get unfavoriteSavedReplies() {
    return this.savedReplies.filter((reply) => !reply.is_favorite)
  }

  get disabledVisibility() {
    return usersStore.user?.role === 'member' || usersStore.user?.role === 'manager'
  }

  nextItem = () => {
    this._useScrollToElement = true
    const fullList = [...this.favoriteSavedReplies, ...this.unfavoriteSavedReplies]

    if (!this._selectedSavedReply && fullList.length) this._selectedSavedReply = fullList[0]
    if (!this._selectedSavedReply || !fullList.length || fullList.length === 1) return

    const currentIdx = fullList.findIndex((item) => item.id === this._selectedSavedReply?.id)

    if (fullList.length > currentIdx + 1) {
      const item = fullList[currentIdx + 1]
      if (item) this._selectedSavedReply = item
    } else if (fullList.length) {
      this._selectedSavedReply = fullList[0]
    }
  }

  prevItem = () => {
    this._useScrollToElement = true
    const fullList = [...this.favoriteSavedReplies, ...this.unfavoriteSavedReplies]

    if (!this._selectedSavedReply && fullList.length) this._selectedSavedReply = fullList[0]
    if (!this._selectedSavedReply || !fullList.length || fullList.length === 1) return

    const currentIdx = fullList.findIndex((item) => item.id === this._selectedSavedReply?.id)

    if (currentIdx > 0) {
      const item = fullList[currentIdx - 1]
      if (item) this._selectedSavedReply = item
    } else {
      this._selectedSavedReply = fullList[fullList.length - 1]
    }
  }

  loadData = async () => {
    try {
      this._loading = true
      this.clear()

      await Promise.all([
        this._favoriteListStore.loadData(this.paramsGetItems),
        this._unfavoriteListStore.loadData(this.paramsGetItems),
      ])

      runInAction(() => {
        const firstSavedReplay = this.favoriteSavedReplies[0] || this.unfavoriteSavedReplies[0]
        this._selectedSavedReply = this.savedReplies.length ? firstSavedReplay : null
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._loading = false
    }
  }

  reset = () => {
    this._disposeReactionLoadData?.()
    this._contact = null
    this._integration = null
    this._onAddSavedReplies = null
    this._search = ''
    this.clear()
    this._favoriteListStore.reset()
    this._unfavoriteListStore.reset()
  }

  clear = () => {
    this._savedRepliesMap.clear()
    this._favoriteListStore.clear()
    this._unfavoriteListStore.clear()
    this._selectedSavedReply = null
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  openModal = ({
    contact,
    onAddSavedReplies,
    integration,
    isCurrentAirCall,
  }: ISavedRepliesProps) => {
    allMergeFieldsStore.loadAllFields()
    this.reset()
    this._contact = contact ?? null
    this._integration = integration ?? null
    this._onAddSavedReplies = onAddSavedReplies
    this._modalId = nanoid()
    this.reactionLoadData()
    this._favoriteListStore.reactionLoadData()
    this._unfavoriteListStore.reactionLoadData()

    modalStore.addModal({
      id: this._modalId,
      title: 'Saved replies',
      ModalContentProps: {
        savedRepliesStore: this,
        isCurrentAirCall: isCurrentAirCall,
      },
      ModalContent: SavedRepliesContent,
      pureContent: true,
      width: 720,
    })

    this.loadData()
  }

  closeModal = () => {
    modalStore.closeModal(this._modalId)
    this.reset()
  }

  handleSelectSort = (item: IDropdownItem) => {
    cannesMessageStore.setOrder(item.id as SavedRepliesSortEnum)
  }
  setSearch = (search: string) => {
    this._search = search
  }

  setSelectSavedReply = (savedReply: CannedMessage) => {
    this._selectedSavedReply = savedReply
  }

  handleAddSelectedSavedReplies = () => {
    if (this._selectedSavedReply) this.handleAddSavedReplies(this._selectedSavedReply)
  }

  handleAddSavedReplies = (savedReply: CannedMessage | null) => {
    let isDisconnected = false
    const mergeFields = savedReply?.value?.match(/{.*?}/g)

    mergeFields?.forEach((field) => {
      const split = field.replace(/{|}/g, '').trim().split('|')

      const fullMergeField = split[0]
      const splitKey = fullMergeField.split('.')
      const integrationKey = Object.values(IntegrationKey).includes(splitKey[0] as IntegrationKey)
        ? splitKey[0]
        : IntegrationKey.salesmessage

      const integration = integrationsStore.getIntegration(integrationKey)
      if (integrationKey !== IntegrationKey.salesmessage && !integration?.isConnected) {
        isDisconnected = true
      }
    })

    if (isDisconnected) {
      const id = nanoid()
      const onReconnect = () => {
        toastStore.remove(id)
        uiStore.changeRoute({
          path: '/settings/integrations',
        })
      }
      toastStore.add({
        id: id,
        title: 'Integration disconnected',
        type: 'error',
        desc: 'To use a saved reply that contains integration merge fields without fallback, please reconnect the integration',
        action: {
          text: 'Reconnect',
          onAction: onReconnect,
        },
      })
    } else {
      if (this._onAddSavedReplies && savedReply) {
        this._onAddSavedReplies({
          message: savedReply.value || savedReply.message,
          attachments: savedReply.attachments,
          isReset: false,
        })
        modalStore.closeModal(this._modalId)
      }
    }
  }

  handleEditSavedReplies = (savedReply: CannedMessage | null) => {
    this._newSavedReplyStore.handleNewSavedReply(savedReply)
  }

  handleDuplicateSavedReplies = (savedReply: CannedMessage | null) => {
    this._newSavedReplyStore.handleNewSavedReply(savedReply, true)
  }

  handleChangeVisibilitySavedReplies = async (
    savedReply: CannedMessage | null,
    isPublic: boolean
  ) => {
    try {
      if (savedReply) {
        const { data } = await CannedMessagesApi.updateCannedMessagesById({
          ...savedReply.updateData,
          public: isPublic,
          contact_id: this._contact?.id,
        })
        if (data) {
          this.setSavedReplay(data)
        }
        const id = nanoid()
        const onUndo = () => {
          toastStore.remove(id)
          this.handleChangeVisibilitySavedReplies(savedReply, !isPublic)
        }

        toastStore.add({
          id: id,
          title: `Visibility changed to ${data.public ? 'everyone' : 'personal'}`,
          type: 'info',
          action: {
            text: 'Undo',
            onAction: onUndo,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  handleDeleteSavedReplies = async (savedReply: CannedMessage | null) => {
    try {
      if (savedReply) {
        const index = this.savedReplies.findIndex((reply) => savedReply.id === reply.id)
        await CannedMessagesApi.deleteCannedMessages(savedReply.id)
        this._savedRepliesMap.delete(savedReply.id)

        if (this._selectedSavedReply?.id === savedReply.id) {
          this._selectedSavedReply = this.savedReplies[index] || null
        }
        toastStore.add({
          title: 'Saved reply deleted',
          type: 'info',
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  handleFavoriteSavedReplies = async (savedReply: CannedMessage) => {
    try {
      const { data } = await CannedMessagesApi.favoriteCannedMessagesById({
        id: savedReply.id,
        favorite: !savedReply.is_favorite,
      })
      if (data) {
        this.setSavedReplay(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  setVisibleSavedRepliesActionsId = (id: number) => {
    this._visibleSavedRepliesActionsId = id
  }

  setLeftContentElement = (leftContentElement: HTMLDivElement) => {
    this._leftContentElement = leftContentElement
  }

  reactionLoadData = () => {
    this._disposeReactionLoadData?.()
    this._disposeReactionLoadData = reaction(() => this.paramsGetItems, this.loadData, {
      delay: 500,
    })
  }
}
