import { createContext, useContext } from 'react'
import { BroadcastDetailRecurringStore } from 'widgets/BroadcastDetail/BroadcastDetailRecurring/store/broadcastDetailRecurringStore'

export const BroadcastDetailRecurringContext = createContext<BroadcastDetailRecurringStore | null>(
  null
)

export function useBroadcastDetailRecurringContext() {
  const context = useContext(BroadcastDetailRecurringContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with BroadcastDetailRecurringContext'
    )
  }
  return context
}
