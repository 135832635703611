import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Button, Icon, IIconsVector, Status, Typography } from 'shared/ui'
import icons from 'shared/ui/Icon/icons'
import { Integration, IntegrationKey } from 'entities/Integrations'
import styles from './styles.module.scss'

type IDropdownIntegrationActionItemProps = {
  integration?: Integration
  integrations?: Integration[]
  showAllIntegrations?: boolean
  ariaLabel: string
  onClickIntegrationBtn: (integration: Integration) => void
  customLabel?: string
  nameDefault?: string
  iconDefault?: IIconsVector
  onClickDefault?: () => void
  isDefaultIntegrationBtn?: boolean
  isNecessaryReconnect?: boolean
}
export const DropdownIntegrationActionItem: FC<IDropdownIntegrationActionItemProps> = observer(
  (props) => {
    const {
      integration,
      integrations,
      showAllIntegrations,
      ariaLabel,
      customLabel,
      nameDefault,
      iconDefault,
      onClickDefault,
      onClickIntegrationBtn,
      isDefaultIntegrationBtn = true,
      isNecessaryReconnect,
    } = props

    const getIntegrationIconByKey = (key: IIconsVector): IIconsVector => {
      if (key in IntegrationKey) {
        if (icons[key]) {
          return key
        }
      }

      return 'salesmsg'
    }

    const IntegrationBtn = observer(({ integration }: { integration: Integration }) => {
      const isConnected = !isNecessaryReconnect && integration.isConnected

      return (
        <Button
          key={integration.key}
          onClick={() => onClickIntegrationBtn(integration)}
          typeBtn={'menuItem'}
          icon={getIntegrationIconByKey(integration.icon)}
          paddingInline={8}
          aria-label={`${ariaLabel}_${integration.name}`}
          iconClassName={styles.icon}
          tooltipProps={
            isNecessaryReconnect
              ? {
                  label:
                    'Please reconnect your HubSpot integration to enable access to HubSpot meetings',
                  placement: 'top',
                  fullWidth: true,
                  breakWord: true,
                }
              : undefined
          }
          titleContent={
            <div className={styles.title}>
              <div className={'row8'}>
                <Typography variant={'body-md-regular'} ariaLabel={'name'}>
                  {integration.name} {customLabel ?? ''}
                </Typography>
                {!isDefaultIntegrationBtn && (
                  <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
                )}
                {isDefaultIntegrationBtn && !isConnected && <Icon icon={'alertTriangleYellow'} />}
              </div>

              {isConnected ? (
                <Icon icon={'chevronRight'} fontSize={16} tertiary />
              ) : isDefaultIntegrationBtn ? (
                <Typography
                  variant={'body-xs-regular'}
                  color={'var(--content-primary-tertiary)'}
                  ariaLabel={'Reconnect'}
                >
                  Reconnect
                </Typography>
              ) : (
                <span className={styles.action}>
                  <Typography
                    variant={'body-xs-regular'}
                    ariaLabel={'Reconnect'}
                    className={styles.button}
                  >
                    Reconnect
                  </Typography>
                  <Icon icon={'alertTriangleYellow'} />
                </span>
              )}
            </div>
          }
        />
      )
    })

    return (
      <>
        {nameDefault && iconDefault && (
          <Button
            key={iconDefault}
            onClick={onClickDefault}
            typeBtn={'menuItem'}
            icon={getIntegrationIconByKey(iconDefault)}
            aria-label={`${ariaLabel}_${nameDefault}`}
            paddingInline={8}
            iconClassName={styles.icon}
            titleContent={
              <div className={styles.title}>
                <Typography variant={'body-md-regular'} ariaLabel={nameDefault}>
                  {nameDefault}
                </Typography>
                <Icon icon={'chevronRight'} fontSize={16} tertiary />
              </div>
            }
          />
        )}
        {integration && <IntegrationBtn integration={integration} />}
        {!integration &&
          showAllIntegrations &&
          integrations?.map((integration) => (
            <IntegrationBtn key={integration.key} integration={integration} />
          ))}
      </>
    )
  }
)
