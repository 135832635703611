import { createContext, useContext } from 'react'
import { CampaignMessageStore } from '../store/CampaignMessageStore'

export const CampaignMessageContext = createContext<CampaignMessageStore | null>(null)

export const useCampaignMessageContext = () => {
  const context = useContext(CampaignMessageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignMessageContext')

  return context
}
