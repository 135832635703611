// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K1STpnwP00P0lcxYO8Rq{display:flex;flex-direction:column;gap:4px;width:100%}.K1STpnwP00P0lcxYO8Rq.fqmrS7m6NMrUzErcmGuv .nYJJqFnlgdEAxHJEDfLR{color:var(--content-primary-primary);background:var(--black-20)}.K1STpnwP00P0lcxYO8Rq.fqmrS7m6NMrUzErcmGuv .g5nl9mr9QUbk53_wZfWy{display:grid}.nYJJqFnlgdEAxHJEDfLR{color:var(--background-primary-inverted-primary);display:flex;height:24px;min-width:23px;padding:8px;align-items:center;justify-content:center;border-radius:6px;background:var(--content-brand-primary)}.g5nl9mr9QUbk53_wZfWy{width:100%;display:flex;justify-content:flex-start;align-items:center;grid-auto-flow:column;gap:8px;padding-block:4px;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignMessage/ui/MessageContent/Title/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,UAAA,CAEE,iEACE,oCAAA,CACA,0BAAA,CAEF,iEACE,YAAA,CAIN,sBACE,gDAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,uCAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,qBAAA,CACA,OAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  width: 100%;\n  &.collapse {\n    .counter {\n      color: var(--content-primary-primary);\n      background: var(--black-20);\n    }\n    .content {\n      display: grid;\n    }\n  }\n}\n.counter {\n  color: var(--background-primary-inverted-primary);\n  display: flex;\n  height: 24px;\n  min-width: 23px;\n  padding: 8px;\n  align-items: center;\n  justify-content: center;\n  border-radius: 6px;\n  background: var(--content-brand-primary);\n}\n.content {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  grid-auto-flow: column;\n  gap: 8px;\n  padding-block: 4px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "K1STpnwP00P0lcxYO8Rq",
	"collapse": "fqmrS7m6NMrUzErcmGuv",
	"counter": "nYJJqFnlgdEAxHJEDfLR",
	"content": "g5nl9mr9QUbk53_wZfWy"
};
export default ___CSS_LOADER_EXPORT___;
