import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Typography, Icon } from 'shared/ui'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { HubspotIntegration } from './HubspotIntegration/HubspotIntegration'
import { ActiveCampaignIntegration } from './ActiveCampaignIntegration/ActiveCampaignIntegration'
import { PipedriveIntegration } from './PipedriveIntegration/PipedriveIntegration'
import { InfusionsoftIntegration } from './InfusionsoftIntegration/InfusionsoftIntegration'
import { UnlinkActions } from './UnlinkActions/UnlinkActions'
import styles from './styles.module.scss'
import { AllDeals, AllCompanies, AllOpportunities, AllTickets } from './AllView'
import { LinkContact } from './LinkContact'
import { SalesforceIntegration } from './SalesforceIntegration/SalesforceIntegration'

export type IContactsIntegrationsProps = {
  isDragging?: boolean
  createNewContactMode?: boolean
  hideDraggingIcon?: boolean
}
export const ContactsIntegrations: React.FC<IContactsIntegrationsProps> = observer(
  ({ isDragging, createNewContactMode, hideDraggingIcon }) => {
    const [isOpenActions, setIsOpenActions] = useState(false)
    const store = useContactsDetailsContext()

    const onToggle = () => {
      if (createNewContactMode) return
      contactsDetailsGlobalStore.toggleBlockView('integration')
    }

    const user = usersStore.user
    const contact = store.selectedContact?.isNew
      ? store.selectedContact
      : contactsStore.getItem(store.selectedContact?.id)

    useEffect(() => {
      integrationsStore.fetchIntegrations()
    }, [])

    if (!contact) return null

    const integration = integrationsStore.getIntegration(contact.integration_key)
    const isIntegrationActive = integration?.isConnected

    const StatusIcon = observer(() => {
      if (isIntegrationActive) return <Icon icon={'check2'} />
      return <Icon icon={'alertTriangleYellow'} />
    })

    const Integration = observer(() => {
      if (!isIntegrationActive && contact.integration_key === IntegrationKey.salesmsg) {
        return <LinkContact contact={contact} contactsTableStore={store.contactsTableStore} />
      }
      if (
        contact.integration_key === IntegrationKey.hubspot &&
        store.contactHubspotIntegrationStore
      )
        return <HubspotIntegration store={store.contactHubspotIntegrationStore} />

      if (contact.integration_key === IntegrationKey.activecampaign)
        return <ActiveCampaignIntegration />

      if (contact.integration_key === IntegrationKey.pipedrive) return <PipedriveIntegration />

      if (contact.integration_key === IntegrationKey.infusionsoft)
        return <InfusionsoftIntegration />

      if (contact.integration_key === IntegrationKey.salesforce) return <SalesforceIntegration />

      return (
        <Typography variant={'body-md-regular'} ariaLabel={'name'}>
          {integration?.name}
        </Typography>
      )
    })

    const DraggingIcon = () => {
      return (
        <div
          className={classnames(styles.iconDotsDrag, {
            [styles.hideDraggingIcon]: hideDraggingIcon,
          })}
        >
          <Icon
            icon={'dotsDrag'}
            fontSize={12}
            color={
              contactsDetailsGlobalStore.isOpenBlock('integration')
                ? undefined
                : 'var(--content-primary-tertiary)'
            }
          />
        </div>
      )
    }

    const IntegrationTitle = () => {
      if (
        (!isIntegrationActive && contact.integration_key === IntegrationKey.salesmsg) ||
        createNewContactMode
      ) {
        return (
          <div className={styles.titleWrapper}>
            {DraggingIcon()}

            <span className={styles.integrationIcon}>
              <Icon icon={'add'} />
            </span>
            <span className={styles.title}>Integration</span>
          </div>
        )
      }

      return (
        <div className={styles.titleWrapper}>
          {DraggingIcon()}

          {integration && (
            <span className={styles.integrationIcon}>
              <Icon icon={integration.icon} />
            </span>
          )}
          <span className={styles.title}>{integration?.name}</span>
          <span
            className={classnames(styles.statusIcon, {
              [styles.active]: !!integration,
            })}
          >
            <StatusIcon />
          </span>
        </div>
      )
    }

    if (store.view === 'deals') {
      return <AllDeals />
    }

    if (store.view === 'companies') {
      return <AllCompanies />
    }

    if (store.view === 'opportunities') {
      return <AllOpportunities />
    }

    if (store.view === 'tickets') {
      return <AllTickets />
    }

    return (
      <div
        className={classnames(styles.wrap, {
          [styles.dragging]: isDragging,
          [styles.disabled]: createNewContactMode,
        })}
      >
        <div
          className={classnames(styles.header, {
            [styles.open]: createNewContactMode
              ? false
              : contactsDetailsGlobalStore.isOpenBlock('integration'),
          })}
          onClick={onToggle}
        >
          <IntegrationTitle />
          <div className={styles.actions}>
            {!!integration && isIntegrationActive && !user?.isViewOnlyRole && (
              <div
                className={classnames(styles.actionsIcon, {
                  [styles.open]: isOpenActions,
                })}
              >
                <UnlinkActions
                  contact={contact}
                  setStatus={setIsOpenActions}
                  contactsTableStore={store.contactsTableStore}
                />
              </div>
            )}
            <div className={styles.toggleIcon}>
              <Icon icon='chevronDown' />
            </div>
          </div>
        </div>
        {contactsDetailsGlobalStore.isOpenBlock('integration') && (
          <div className={classnames(styles.body, styles.open)}>
            <Integration />
          </div>
        )}
      </div>
    )
  }
)
