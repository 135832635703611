// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rMAYQxsx4B2bun0VD4zb{width:100%;max-width:760px;padding-inline:20px;margin:-8px auto 24px;display:flex;flex-direction:column;align-items:flex-end;gap:16px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/TypingContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA,CACA,mBAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,QAAA","sourcesContent":[".wrap{\n  width: 100%;\n  max-width: 760px;\n  padding-inline: 20px;\n  margin: -8px auto 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rMAYQxsx4B2bun0VD4zb"
};
export default ___CSS_LOADER_EXPORT___;
