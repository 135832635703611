import classNames from 'classnames'
import { ReactNode, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, IButtonProps, Icon, Status, Typography } from 'shared/ui'
import type { IStatusProps } from 'shared/ui/Status'
import { RegisterSectionItem } from './RegisterSectionItem'
import styles from './styles.module.scss'

export type IRegisterSectionProps = {
  title: string
  label?: string
  desc: ReactNode
  learnMoreLink?: string
  learnMoreLinkClassName?: 'newLineLink'
  isSecondStep?: boolean

  statusProps?: IStatusProps | null
  type?: 'default' | 'transparent'
  section?: 'default' | 'gray' | 'border'
  titleType?: 'body-md-medium' | 'body-lg-medium'

  actionButton?: IButtonProps
  actionIcon?: {
    onClick: () => void
  }

  items?: {
    id: number
    title: string
    desc: string
    statusProps?: IStatusProps | null
    actionIcon?: {
      onClick: (id: number) => void
    }
  }[]
}

export const RegisterSection: FC<IRegisterSectionProps> = observer(
  ({
    title,
    label,
    desc,
    type = 'default',
    section = 'default',
    titleType = 'body-md-medium',
    learnMoreLink,
    isSecondStep,
    statusProps,
    learnMoreLinkClassName = '',
    actionButton,
    actionIcon,
    items,
  }) => {
    return (
      <div
        className={classNames(styles.wrap, styles[`type-${type}`], styles[`section-${section}`], {
          [styles.secondStep]: isSecondStep,
        })}
      >
        <div className={styles.wrap__sub}>
          <div className={styles.content}>
            {label && (
              <Status
                className={styles.label}
                title={'Add-on'}
                intent={'purple'}
                emphasis={'high'}
                size={'small'}
              />
            )}

            <Typography ariaLabel='RegisterSection_title' variant={titleType}>
              {title}
            </Typography>
            <Typography
              ariaLabel='RegisterSection_desc'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
              className={styles.desc}
              tag='div'
            >
              {desc}
              {learnMoreLink && (
                <a
                  href={learnMoreLink}
                  target='_blank'
                  rel='noreferrer'
                  className={classNames(styles.learnMoreLink, styles[learnMoreLinkClassName])}
                >
                  <Typography
                    ariaLabel='TermsAndPrivacy_link'
                    variant='body-md-medium'
                    className={styles.learnMoreText}
                  >
                    Learn more
                  </Typography>
                </a>
              )}
            </Typography>
            {statusProps && (
              <div className={styles.status}>
                <Status {...statusProps} />
              </div>
            )}
          </div>

          {actionIcon && (
            <div className={styles.arrowRight} onClick={actionIcon.onClick}>
              <Icon icon={'chevronRight'} />
            </div>
          )}
          {actionButton && <Button className={styles.registerBtn} {...actionButton} />}
        </div>

        {!!items?.length && (
          <div className={styles.items}>
            {items.map((item) => {
              return <RegisterSectionItem key={item.id} {...item} />
            })}
          </div>
        )}
      </div>
    )
  }
)
