import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { IconButton, ITabItem, IType, Tabs, Type } from 'shared/ui'
import { useCampaignMessageContext } from 'pages/campaigns/ui/CampaignMessage'
import { CampaignSMS } from 'pages/campaigns/ui/CampaignSMS'
import { CampaignRingless } from 'pages/campaigns/ui/CampaignRingless'
import { CampaignSendFrom } from 'pages/campaigns/ui/CampaignSendFrom'
import {
  IMessageActionsProps,
  MessageActions,
} from 'pages/campaigns/ui/CampaignMessage/ui/MessageContent/MessageActions/MessageActions'
import { Title } from 'pages/campaigns/ui/CampaignMessage/ui/MessageContent/Title/Title'
import styles from './styles.module.scss'

export type IMessageContentProps = {
  index: number
} & IMessageActionsProps

export const MessageContent = observer(
  ({ index, ...messageActionsProps }: IMessageContentProps) => {
    const refContent = useRef<HTMLDivElement>(null)
    const {
      setStep,
      type,
      setType,
      campaignSMSStore,
      campaignRinglessStore,
      campaignSendFromStore,
      setCollapse,
      isCollapse,
    } = useCampaignMessageContext()

    useEffect(() => {
      setStep(index + 1)
    }, [index])

    const tabs: ITabItem<IType>[] = [
      {
        key: 'sms',
        name: 'SMS',
      },
      {
        key: 'ringless',
        name: 'Ringless',
      },
    ]

    const handleSelectTab = (tab: ITabItem<IType>) => {
      setType(tab.key)
    }

    return (
      <div className={classNames(styles.wrap, { [styles.collapse]: isCollapse })}>
        <div className={styles.content} ref={refContent}>
          <div className={styles.header} onClick={() => setCollapse(false)}>
            <Title contentElement={refContent.current} />
            <div className={styles.right} onClick={(event) => event.stopPropagation()}>
              <Type type={type} />
              <MessageActions {...messageActionsProps} />
            </div>
          </div>
          {!isCollapse && (
            <>
              <Tabs
                variant={'simple'}
                tabs={tabs}
                activeTabKey={type}
                handleSelectTab={handleSelectTab}
              />
              {type === 'sms' && <CampaignSMS store={campaignSMSStore} />}
              {type === 'ringless' && <CampaignRingless store={campaignRinglessStore} />}
              <div className={styles.wrapSendFrom}>
                <CampaignSendFrom store={campaignSendFromStore} />
              </div>
            </>
          )}
        </div>
        {!isCollapse && (
          <IconButton
            ariaLabel={'collaapse'}
            icon={'chevronUp'}
            variant={'icon'}
            color={'tertiary'}
            fullWidth
            onClick={() => setCollapse(true)}
          />
        )}
      </div>
    )
  }
)
