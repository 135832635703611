import { Navigate, Route, Routes } from 'react-router-dom'
import { BroadcastLayout } from 'pages/broadcasts/layout/BroadcastLayout'
import { CreateBroadcast } from 'pages/broadcasts/pages/createBroadcast'
import { DetailBroadcast } from 'pages/broadcasts/pages/detailBroadcast'
import {
  AllBroadcasts,
  OneTimeBroadcasts,
  RecurringBroadcasts,
} from 'pages/broadcasts/pages/broadcasts'
import { BroadcastTableLayout } from 'pages/broadcasts/layout/BroadcastTableLayout'
import { EditBroadcast } from 'pages/broadcasts/pages/editBroadcast'
import { BroadcastsRoutes } from 'pages/broadcasts/route/type'
import { BroadcastsAccessLayout } from 'pages/broadcasts/layout/BroadcastsAccessLayout'

export const BroadcastsRouter = () => {
  return (
    <Routes>
      <Route element={<BroadcastsAccessLayout />}>
        <Route element={<BroadcastLayout />}>
          <Route element={<BroadcastTableLayout />}>
            <Route path={BroadcastsRoutes.all} element={<AllBroadcasts />} />
            <Route path={`${BroadcastsRoutes.oneTime}/*`} element={<OneTimeBroadcasts />} />
            <Route path={BroadcastsRoutes.recurring} element={<RecurringBroadcasts />} />
          </Route>
          <Route path={BroadcastsRoutes.create} element={<CreateBroadcast />} />
          <Route path={`${BroadcastsRoutes.edit}/:id`} element={<EditBroadcast />} />
          <Route
            path={`${BroadcastsRoutes.clone}/:id`}
            element={<EditBroadcast isDuplicate={true} />}
          />
          <Route path={`${BroadcastsRoutes.view}/:id`} element={<DetailBroadcast />} />
          <Route path={'*'} element={<Navigate to={BroadcastsRoutes.all} />} />
        </Route>
      </Route>
    </Routes>
  )
}
