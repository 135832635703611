import { makeAutoObservable } from 'mobx'
import { type PageLayoutStore } from 'shared/layout'

export class TriggersPageStore {
  private _isTriggerActionShown = false

  constructor(private _pageLayoutStore: PageLayoutStore) {
    makeAutoObservable(this)
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get isTriggerActionShown() {
    return this._isTriggerActionShown
  }

  setIsTriggerActionShown = (isTriggerActionShown: boolean) => {
    this._isTriggerActionShown = isTriggerActionShown
  }
}
