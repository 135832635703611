import { observer } from 'mobx-react-lite'
import { Tabs } from 'shared/ui'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { ErrorBanner } from 'pages/settings/pages/compliance/pages/businessProfile/ui/ErrorBanner/ErrorBanner'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { AutoBusinessFind } from './AutoBusinessFind'
import { ManuallyBusinessFind } from './ManuallyBusinessFind'
import styles from './styles.module.scss'

export const GeneralInfo = observer(() => {
  const {
    generalInfoActiveTab,
    companyLocationStore,
    stepLoading,
    generalInfoStore,
    changeGeneralInfoTab,
  } = useBusinessProfileStepsContext()
  const { isUsaLocation } = companyLocationStore

  return (
    <div className={styles.wrap}>
      <div className={styles.bannerWrap}>
        <PageDescription>
          Make sure this information is an exact match to your official paperwork
        </PageDescription>

        <ErrorBanner />
      </div>

      {isUsaLocation && (
        <>
          <Tabs
            tabs={[
              { name: 'Find your business', key: 1 },
              { name: 'Enter manually', key: 2 },
            ]}
            activeTabKey={generalInfoActiveTab}
            handleSelectTab={(tab) => changeGeneralInfoTab(tab.key)}
            margin={'0 0 28px 0'}
          />
          {generalInfoActiveTab === 1 && <AutoBusinessFind />}
          {generalInfoActiveTab === 2 && <ManuallyBusinessFind />}
        </>
      )}

      {!isUsaLocation && <ManuallyBusinessFind />}

      <NextButton
        loading={stepLoading}
        disabled={generalInfoStore.isNextDisabled || stepLoading}
        text='Next'
        onClick={generalInfoStore.nextStepClick}
      />
    </div>
  )
})
