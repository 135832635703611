import { replaceLink, replaceEmail } from 'shared/lib'
import { mergeFieldReplacer } from 'widgets/MessageField/lib/mergeFieldReplacer'
import { tagReplacer } from 'widgets/MessageField/lib/tagReplacer'

const escapeHtml = (text: string) => {
  return text
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/`/g, '&#x60;')
}

export function textToHtml({
  text,
  replaceNewRow,
  noMergeField,
  noReplaceMergeField,
  isClearMergeField,
  ignoreLinks,
  extraSpace,
  noEscapeHtml,
}: {
  text: string
  replaceNewRow?: boolean
  noMergeField?: boolean
  isClearMergeField?: boolean
  ignoreLinks?: boolean
  noReplaceMergeField?: boolean
  extraSpace?: boolean
  noEscapeHtml?: boolean
}) {
  const safeText = noEscapeHtml ? text : escapeHtml(text)
  if (!safeText) return ''

  const content = extraSpace ? safeText + ' ' : safeText
  const normalizedContent = ignoreLinks
    ? content
    : content
        .split(/\n/)
        .map((text) =>
          text
            .split(' ')
            .map((word) => replaceLink(word))
            .map((word) => replaceEmail(word))
            .join(' ')
        )
        .join('\n')

  if (replaceNewRow) {
    return (
      normalizedContent
        .replace(/\[.*?\]/g, (match) => tagReplacer(match))
        .replace(/{.*?}/g, (match) =>
          noReplaceMergeField ? match : mergeFieldReplacer(match, noMergeField, isClearMergeField)
        )
        // .replace(/\n/g, '<br/>')
        .split('\n')
        .map((item, index, self) =>
          index > 1
            ? `<span>${item}${index === self.length ? '' : '<br/>'}</span>`
            : `<span>${item}<br/></span>`
        )
        .join('')
    )
  }

  return normalizedContent.replace(/{.*?}/g, (match) =>
    noReplaceMergeField ? match : mergeFieldReplacer(match, noMergeField, isClearMergeField)
  )
}
