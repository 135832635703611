import { nanoid } from 'nanoid'
import Cookies from 'js-cookie'
import { getTimestamp } from 'shared/lib'
import { configStore } from 'entities/Config'
import { organizationStore } from 'entities/Organization'
import { usersStore } from 'entities/Users'
import { numbersStore } from 'entities/Phone'
import { subscriptionStore } from 'entities/Subscription'

const id = nanoid()

const getAllCookies = () => {
  return document.cookie
    .split('; ')
    .map((item) => item.split('='))
    .reduce((c: { [key: string]: string }, n) => {
      const key = n[0]
      const value = n[1]

      if (key) {
        c[key] = value
      }

      return c
    }, {})
}

const payload = () => {
  const user = usersStore.user
  const number = numbersStore.organizationNumber
  const config = configStore.config
  const plan = subscriptionStore.plan
  const z_index = 30
  const anonymous_id = (() => {
    const exist_id = localStorage.getItem('intercom-anonymous_id')

    if (!exist_id) {
      localStorage.setItem('intercom-anonymous_id', id)
    }

    return localStorage.getItem('intercom-anonymous_id')
  })()

  if (!user || !number || !config || !plan || !organizationStore.id) {
    return {
      anonymous_id: anonymous_id,
      z_index: z_index,
    }
  }

  const company = {
    id: organizationStore.id,
    name: organizationStore.name,
    createdAt: organizationStore.created_at,
    ownerFirstName: user.first_name,
    ownerLastName: user.last_name,
    ownerName: user.full_name,
    phone: number?.formatted_number,
    plan: plan.id,
    planStatus: subscriptionStore.status,
  }

  return {
    email: user.email,
    id: String(user.id),
    user_id: String(user.id),
    created_at: getTimestamp(user.createdAt),
    name: user.name,
    phone: number.formatted_number,
    avatar: user.avatarInfo.image,
    user_hash: config.intercom,
    company,
    z_index: z_index,
  }
}

export const initIntercom = async (fromAuthorizedApp?: boolean) => {
  if (!window.dataLayer) return

  if (fromAuthorizedApp) {
    await Promise.all([
      organizationStore.initPromise,
      configStore.initPromise,
      numbersStore.initFetchOrganizationNumberPromise,
      subscriptionStore.initPromise,
    ])
  }

  window.dataLayer.push({ event: 'intercom_init', intercom_payload: payload() })
}

// https://developers.intercom.com/installing-intercom/web/methods
export const updateIntercom = () => {
  if (!window.Intercom) return

  window.Intercom('update', payload())
}

export const openIntercom = () => {
  if (!window.Intercom) return

  window.Intercom('show')
}

export const openIntercomMessages = () => {
  if (!window.Intercom) return

  window.Intercom('showMessages')
}

export const openIntercomNewMessage = (prePopulatedMessage?: string) => {
  window.Intercom?.('showNewMessage', prePopulatedMessage ?? '')
}

export const clearSessionIntercom = () => {
  if (!window.Intercom) {
    for (const key in getAllCookies()) {
      if (/^intercom-session-.+/.test(key)) {
        Cookies.remove(key, { domain: '.salesmessage.com' })
      }
    }

    return
  }

  window.Intercom('shutdown')
}

export const setHideIntercom = (value: boolean) => {
  if (!window.Intercom) return

  window.Intercom('update', {
    hide_default_launcher: value,
  })
}
