import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Alert, Icon } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { type IInputComponent, makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { type ConnectTwilioModalStore } from 'entities/Phone/ui/ConnectTwilioModal'

import styles from './styles.module.scss'

type IConnectTwilioModalContentProps = {
  store: ConnectTwilioModalStore
}

export const ConnectTwilioModalContent: FC<IConnectTwilioModalContentProps> = observer(
  ({ store }) => {
    const { setAccountSid, setAuthToken, loading, authToken, accountSid, error, isPrefilled } =
      store

    return (
      <div>
        <Box display={'grid'} gap={'16px'}>
          <div className={styles.iconWrapper}>
            <Icon icon='twilioIntegration' fontSize={200} />
          </div>

          {error && (
            <Alert
              item={{
                type: 'error',
                title: error,
              }}
            />
          )}

          <Box display={'grid'} gap={'4px'}>
            <Label
              label={{
                text: 'Twilio Account SID:',
              }}
            />
            <TextField
              Input={isPrefilled ? (InputPassword as IInputComponent) : undefined}
              variant={'stroke'}
              size={'small'}
              viewOnly={isPrefilled}
              InputProps={{
                placeholder: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
              }}
              value={accountSid}
              disabled={loading || isPrefilled}
              onChange={setAccountSid}
              rightActions={[makeTextFieldClear()]}
            />
          </Box>

          <Box display={'grid'} gap={'4px'}>
            <Label
              label={{
                text: 'Twilio Auth Token:',
              }}
            />
            <TextField
              variant={'stroke'}
              size={'small'}
              InputProps={{
                placeholder: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
              }}
              value={authToken}
              disabled={loading}
              onChange={setAuthToken}
              rightActions={[makeTextFieldClear()]}
            />
          </Box>
        </Box>
      </div>
    )
  }
)
