import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import classNames from 'classnames'
import { InputCheckbox, Typography } from 'shared/ui'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { ErrorBanner } from 'pages/settings/pages/compliance/pages/businessProfile/ui/ErrorBanner/ErrorBanner'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { ContactForm } from './ContactForm'
import styles from './styles.module.scss'

export const PeopleToContact = observer(() => {
  const {
    fieldsError,
    deleteErrorValue,
    stepLoading,
    peopleToContactStore: {
      primaryContact,
      secondaryContact,
      jobPositionList,
      isSecondContact,
      contactsConfirmed,
      phoneInputStore,
      emailLocalError,
      emailLocalError2,
      secondPhoneInputStore,
      isNextDisabled,
      similarityDomainsWarning,
      similarityDomainsWarning2,
      nextStepClick,
      checkIsEmail,
      setPrimaryFirstName,
      setPrimaryLastName,
      setPrimaryBusinessTitle,
      setPrimaryEmail,
      setPrimaryJobPosition,
      setSecondaryFirstName,
      setSecondaryLastName,
      setSecondaryBusinessTitle,
      setSecondaryEmail,
      setSecondaryJobPosition,
      toggleContactsConfirmed,
      setSecondContact,
      loadBusinessProfileJobPositions,
    },
  } = useBusinessProfileStepsContext()

  useEffect(() => {
    loadBusinessProfileJobPositions()
  }, [])

  return (
    <div className={styles.wrap}>
      <PageDescription>
        We will contact your authorized representative to verify your identities. Please ensure that
        they are contactable via email and phone.
      </PageDescription>

      <ErrorBanner />

      <Typography
        ariaLabel='AuthorizedUser_1_line'
        variant='body-lg-medium'
        marginBottom={24}
        marginTop={40}
        tag='div'
      >
        Authorized user #1
      </Typography>

      <ContactForm
        emailErrorMessage={(fieldsError && fieldsError['0.email']) || emailLocalError}
        phoneErrorMessage={fieldsError && fieldsError['0.phone_number']}
        isErrorBorderEmail={fieldsError ? Boolean(fieldsError['0.email']) : false}
        contact={primaryContact}
        jobPositionList={jobPositionList}
        phoneInputStore={phoneInputStore}
        similarityDomainsWarning={similarityDomainsWarning}
        setFirstName={setPrimaryFirstName}
        setLastName={setPrimaryLastName}
        setBusinessTitle={setPrimaryBusinessTitle}
        setJobPosition={setPrimaryJobPosition}
        setEmail={setPrimaryEmail}
        checkIsEmail={() => checkIsEmail(true)}
        setValueCallback={() => {
          deleteErrorValue('0.phone_number')
        }}
      />

      <InputCheckbox
        checked={isSecondContact}
        onChecked={() => setSecondContact(!isSecondContact)}
        color='var(--content-primary-tertiary)'
        label='I have a second authorized representative'
        variant='text-input-field-r'
        className={styles.secondContactCheckbox}
      />

      {isSecondContact && (
        <>
          <Typography
            ariaLabel='AuthorizedUser_2_line'
            variant='body-lg-medium'
            marginBottom={24}
            marginTop={40}
            tag='div'
          >
            Authorized user #2
          </Typography>

          <ContactForm
            isSecond
            isErrorBorderEmail={fieldsError ? Boolean(fieldsError['1.email']) : false}
            phoneErrorMessage={fieldsError && fieldsError['1.phone_number']}
            emailErrorMessage={(fieldsError && fieldsError['1.email']) || emailLocalError2}
            contact={secondaryContact}
            jobPositionList={jobPositionList}
            phoneInputStore={secondPhoneInputStore}
            similarityDomainsWarning={similarityDomainsWarning2}
            setFirstName={setSecondaryFirstName}
            setLastName={setSecondaryLastName}
            setBusinessTitle={setSecondaryBusinessTitle}
            setJobPosition={setSecondaryJobPosition}
            setEmail={setSecondaryEmail}
            checkIsEmail={() => checkIsEmail(false)}
            setValueCallback={() => {
              deleteErrorValue('1.phone_number')
            }}
          />
        </>
      )}

      <InputCheckbox
        className={classNames(styles.confirmDesc, { [styles.confirmed]: isSecondContact })}
        checked={contactsConfirmed}
        onChecked={toggleContactsConfirmed}
        variant='text-input-field-r'
        color='var(--content-primary-tertiary)'
        label='I confirm that my nominated authorized representative agree to be contacted by Salesmsg'
      />

      <NextButton
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        text='Next'
        onClick={nextStepClick}
      />
    </div>
  )
})
