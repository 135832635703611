import classNames from 'classnames'
import { Dropdown, IDropdownItem, Icon, DropdownList, Tooltip } from 'shared/ui'
import { links } from 'shared/constants/links'
import { getAriaLabel } from 'shared/lib'
import { commonTooltipProps } from '../../constants/menuItems'

import styles from './styles.module.scss'

export const SettingsDropdown = () => {
  const items: IDropdownItem[] = [
    {
      id: 1,
      iconL: 'support',
      label: 'Help articles',
      link: links.support,
    },
    {
      id: 2,
      iconL: 'lighthub',
      label: 'Feature requests',
      link: links.feedback,
    },
  ]

  return (
    <Dropdown
      ariaLabel={'SettingsDropdown'}
      width={186}
      icon={'question'}
      placement={'right-end'}
      variantTriggerButton={'icon'}
      triggerComponent={(open) => (
        <Tooltip label={'Support'} {...commonTooltipProps}>
          <div
            className={classNames(styles.button, {
              [styles.active]: open,
            })}
            aria-label={getAriaLabel('SettingsDropdownTrigger')}
          >
            <Icon icon={'question'} fontSize={20} />
          </div>
        </Tooltip>
      )}
      customComponent={() => <DropdownList items={items} />}
    />
  )
}
