import { FC } from 'react'
import { ProgressBar } from 'shared/ui/ProgressBar'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

type IProgressInfoProps = {
  title: string
  step: number
  total: number
}

export const ProgressSteps: FC<IProgressInfoProps> = ({ title, step, total }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.topLine}>
        <Typography ariaLabel='ProgressInfo_title' variant='heading-md' className={styles.title}>
          {title}
        </Typography>
        <Typography
          ariaLabel='ProgressInfo_title'
          variant='body-md-regular'
          color='var(--content-primary-tertiary)'
          className={styles.steps}
        >
          Step {step} / {total}
        </Typography>
      </div>
      <div className={styles.progressWrap}>
        <ProgressBar
          percent={(step / total) * 100}
          withAllBorders
          withBg
          className={styles.progressWrap__progress}
        />
      </div>
    </div>
  )
}
