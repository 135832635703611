import { CancelTokenSource } from 'axios'
import { type IReactionDisposer, makeAutoObservable } from 'mobx'
import type { INumberType, ITargetCountry } from 'entities/Compliance/api/complianceType'
import { Summary } from 'entities/Compliance/model'
import { QuestionnaireStepsStore } from 'entities/Compliance/store/questionnaireStepsStore/questionnaireStepsStore'
import { type ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

export class QuestionnaireStore {
  private _questionnaireStepsStore = new QuestionnaireStepsStore()
  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _step = 1
  private _firstUsing = true

  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)
  }

  get disabled() {
    return this._questionnaireStepsStore.disabledNumberType
  }

  get isStarted() {
    return this._step !== 1
  }

  get step() {
    return this._step
  }

  get targetCountry() {
    return this._questionnaireStepsStore.targetCountry
  }

  get isTaxId() {
    return this._questionnaireStepsStore.isTaxId
  }

  get numberType() {
    return this._questionnaireStepsStore.numberType
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get summary() {
    return this._complianceLayerStore.summary
  }

  init = () => {
    this.handleSetSummary(this.summary)
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setStep = (value: number) => {
    this._step = value
  }

  setTargetCountry = (country: ITargetCountry | null) => {
    this._questionnaireStepsStore.setTargetCountry(country)
  }

  goToStart = () => {
    const { organization_id } = this._complianceLayerStore.summary || {}
    const step = organization_id ? 4 : 2

    this.setStep(step)
  }

  resetProgress = () => {
    this.setStep(1)
    this.setTargetCountry(null)
    this.setIsTaxId(null)
    this.setNumberType(null)
  }

  handleSetTargetCountry = (country: ITargetCountry) => {
    this._questionnaireStepsStore.handleSetTargetCountry(country)
  }

  setIsTaxId = (isTaxId: boolean | null) => {
    this._questionnaireStepsStore.setIsTaxId(isTaxId)
  }

  handleSetIsTaxId = (isTaxId: boolean) => {
    this._questionnaireStepsStore.handleSetIsTaxId(isTaxId)
  }

  setNumberType = (numberType: INumberType | null) => {
    this._questionnaireStepsStore.setNumberType(numberType)
  }

  handleSetSummary = (summary: Summary | null) => {
    if (summary?.organization_id) {
      this._firstUsing = false

      this.setIsTaxId(summary.has_tax_id)
      this.setNumberType(summary.numbers_type)
      this.setTargetCountry(summary.target_country)
      this.setStep(4)
    }
  }

  saveSummary = () => {
    this._questionnaireStepsStore.saveSummary()
  }
}
