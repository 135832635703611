// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FphWESMVHhZ7QI6n0BF9{display:flex;width:100%;justify-content:space-between}.CzPJCqkAn336OXt7gCAP,.WWI18PmzQI4Kfx2o77Pj{display:flex;gap:8px;align-items:center}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignView/ui/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CAEA,6BAAA,CAEF,4CAEE,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".wrap {\n  display: flex;\n  width: 100%;\n\n  justify-content: space-between;\n}\n.left,\n.right {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "FphWESMVHhZ7QI6n0BF9",
	"left": "CzPJCqkAn336OXt7gCAP",
	"right": "WWI18PmzQI4Kfx2o77Pj"
};
export default ___CSS_LOADER_EXPORT___;
