import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseConversationDraftMessage } from 'entities/Conversation/api/types'
import { conversationStore } from 'entities/Conversation'

const ClientDraft = {
  handle: (data: IResponseConversationDraftMessage) => {
    conversationStore.addItemDraft(data)
  },
}

export const EventClientDraft = {
  subscribe: () => websocket.on(WebsocketEvent.ClientDraft, ClientDraft.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.ClientDraft, ClientDraft.handle),
}
