import { observer } from 'mobx-react-lite'
import React from 'react'
import classNames from 'classnames'
import { Alert, Button } from 'shared/ui'
import { KnowledgeBaseIndexationStatusAlert } from 'widgets/KnowledgeBase'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'
import { INDEXATION_RETRY } from 'pages/settings/pages/knowledgeBase/lib/constants'
import styles from './styles.module.scss'

export const KnowledgeBaseNotifications = observer(() => {
  const {
    isPageSpinnerShown,
    notificationStore: { notifications, clearNotification },
    knowledgeBaseStore: { knowledgeBase },
    documentsStore: {
      isEmpty,
      onRetryIndexation,
      documentIdsToRetry,
      isDocumentsIndexingRetryLoading,
    },
  } = useKnowledgeBaseViewContext()

  const isNotificationShown = !!knowledgeBase && !isPageSpinnerShown && !isEmpty

  return (
    <div
      className={classNames(styles.alertContainer, {
        [styles.emptyView]: isEmpty,
      })}
    >
      {isNotificationShown && <KnowledgeBaseIndexationStatusAlert knowledgeBase={knowledgeBase} />}
      {!!documentIdsToRetry.length && (
        <Alert
          item={{
            type: 'error',
            desc: INDEXATION_RETRY,
          }}
          rightAction={
            <Button
              text={'Try again'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              loading={isDocumentsIndexingRetryLoading}
              onClick={() => onRetryIndexation(documentIdsToRetry)}
              className={styles.tryAgain}
            />
          }
        />
      )}
      {notifications.map((notification) => (
        <Alert
          key={notification.id}
          item={{
            type: notification.type,
            desc: notification.message,
          }}
          onClose={() => clearNotification(notification.id)}
        />
      ))}
    </div>
  )
})
