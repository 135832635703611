import { createContext, useContext } from 'react'
import { CampaignMessagesStore } from '../store/CampaignMessagesStore'

export const CampaignMessagesContext = createContext<CampaignMessagesStore | null>(null)

export const useCampaignMessagesContext = () => {
  const context = useContext(CampaignMessagesContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignMessagesContext')

  return context
}
