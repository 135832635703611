import { makeAutoObservable } from 'mobx'
import { IOptInMethodTypes } from 'entities/Compliance'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { TollFreeRoutesSteps } from 'pages/settings/pages/compliance/pages/tollFree/route/type'
import { type OptInMethodStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInMethod/store/optInMethodStore'

export class TollFreeNavigationStore {
  private _profileId: number | null = null
  private _currentStep = 1

  constructor(private _optInMethodStore: OptInMethodStore) {
    makeAutoObservable(this)
  }

  setProfileId = (id: number) => {
    this._profileId = id
  }

  setCurrentStep = (step: number) => {
    this._currentStep = step
  }

  getStep = (direction: -1 | 1 | 0) => {
    const allSteps = Object.values(this.stepMapFiltered)
    const currentPageIndex = this._currentStep - 1

    return allSteps[currentPageIndex + direction]
  }

  getStepByPath = (tollFreeRoutes: TollFreeRoutesSteps) => {
    const allPaths = Object.entries(this.stepMapFiltered)
    const stepIndex = allPaths.findIndex((path) => {
      return path[1] === tollFreeRoutes
    })

    return stepIndex !== -1 ? stepIndex + 1 : 1
  }

  getStepUrlMap = (tollFreeRoutes: TollFreeRoutesSteps) => {
    return `${this.rootPath}/${tollFreeRoutes}`
  }

  get optInMethodStore() {
    return this._optInMethodStore
  }

  get rootPath() {
    return this._profileId
      ? CompliancePath.compliance.tollFree.viewSteps(this._profileId)
      : CompliancePath.compliance.tollFree.steps
  }

  get stepMap() {
    return {
      1: TollFreeRoutesSteps.numbers,
      2: TollFreeRoutesSteps.businessInfo,
      3: TollFreeRoutesSteps.contactInfo,
      4: TollFreeRoutesSteps.useCase,
      5: TollFreeRoutesSteps.optInMethod,
      6: TollFreeRoutesSteps.optInOnlineFormContent,
      7: TollFreeRoutesSteps.optInPaperFormContent,
      8: TollFreeRoutesSteps.optInViaTextContent,
      9: TollFreeRoutesSteps.optInQrCodeContent,
      10: TollFreeRoutesSteps.forbiddenMessageTopics,
    }
  }

  get stepMapFiltered() {
    const keys: TollFreeRoutesSteps[] = this.optInMethodStore.optInMethods.map((method) => {
      switch (method) {
        case IOptInMethodTypes.onlineForm:
          return TollFreeRoutesSteps.optInOnlineFormContent
        case IOptInMethodTypes.paperForm:
          return TollFreeRoutesSteps.optInPaperFormContent
        case IOptInMethodTypes.text:
          return TollFreeRoutesSteps.optInViaTextContent
        case IOptInMethodTypes.qrCode:
          return TollFreeRoutesSteps.optInQrCodeContent
        default:
          return null
      }
    }) as TollFreeRoutesSteps[]

    return Object.entries(this.stepMap).reduce<Record<string, TollFreeRoutesSteps>>(
      (state, [step, page]) => {
        if (
          [
            TollFreeRoutesSteps.optInOnlineFormContent,
            TollFreeRoutesSteps.optInPaperFormContent,
            TollFreeRoutesSteps.optInViaTextContent,
            TollFreeRoutesSteps.optInQrCodeContent,
          ].includes(page) &&
          !keys.includes(page)
        ) {
          return state
        }

        state[step] = page

        return state
      },
      {}
    )
  }

  get getCurrentStep() {
    return this.getStep(0)
  }

  get getNextStep() {
    const step = this.getStep(1)

    if (!step) return this.getCurrentStep

    return step
  }

  get getLastStep() {
    const step = this.getStep(-1)

    if (!step) return this.getCurrentStep

    return step
  }

  get getCurrentUrl() {
    return `${this.rootPath}/${this.getCurrentStep}`
  }

  get getNextUrl() {
    return `${this.rootPath}/${this.getNextStep}`
  }

  get getLastUrl() {
    return `${this.rootPath}/${this.getLastStep}`
  }

  get allStepsLength() {
    const allOriginStepLength = Object.keys(this.stepMap).length
    const allMutationStepLength = Object.keys(this.stepMapFiltered).length

    if (this._currentStep > 5) {
      return allMutationStepLength
    }

    return allOriginStepLength - 3
  }

  get formStep() {
    return this._currentStep
  }

  get UIFormStep() {
    return this.formStep
  }
}
