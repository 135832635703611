import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DatePicker,
  Dropdown,
  DropdownCard,
  DropdownContent,
  EnumDropdownItemVariant,
  Icon,
} from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { useKeywordDetailsContext } from 'pages/keywords/context'
import { ICalendarFilter } from 'pages/keywords/store/type'
import styles from './styles.module.scss'
import { CalendarFilterEnum } from './calendarEnum'

export const KeywordStatisticsSearch = observer(() => {
  const {
    setSearch,
    search,
    selectedCalendarFilter,
    setSelectedCalendarFilter,
    fromFilterDate,
    toFilterDate,
    rangeTriggerValue,
    setDateRangeValue,
    isCustomMode,
    setParamsDate,
  } = useKeywordDetailsContext()

  const [isDropdownOpen, setDropdownOpen] = useState(false)

  return (
    <div className={styles.searchWrap}>
      <div className={styles.search}>
        <Icon icon='search' fontSize={20} padding={6} color='var(--content-primary-tertiary)' />
        <TextField
          variant='integrated'
          InputProps={{
            placeholder: 'Search...',
          }}
          onChange={setSearch}
          value={search || ''}
          ariaLabel='StatisticSearch'
        />
      </div>
      <Dropdown
        ariaLabel={'StatisticDateFilter'}
        placement={'left-start'}
        withTextInput
        show={isDropdownOpen}
        triggerComponent={() => (
          <div className={styles.trigger}>
            <div className={styles.trigger__icon}>
              <Icon icon={'calendar'} fontSize={16} />
            </div>
            <span className={styles.trigger__value}>{rangeTriggerValue()}</span>
          </div>
        )}
        onClose={() => {
          setParamsDate()
        }}
        onToggle={(value) => {
          setDropdownOpen(value)
        }}
        customComponent={() => (
          <div className={styles.customComponentWrap}>
            <DropdownContent noStyle className={styles.contentContainer}>
              <div className={styles.optionsListWrap}>
                {Object.values(CalendarFilterEnum).map((filterValue) => (
                  <DropdownCard
                    item={{
                      id: filterValue,
                      label: filterValue,
                      activeValue: selectedCalendarFilter === filterValue,
                      variant: EnumDropdownItemVariant.CheckedRight,
                    }}
                    onChange={({ label }) => {
                      if (label) {
                        setSelectedCalendarFilter(label as ICalendarFilter)
                        if (label !== CalendarFilterEnum.custom) {
                          setDropdownOpen(false)
                        }
                      }
                    }}
                    key={filterValue}
                  />
                ))}
              </div>
              <DatePicker
                type={'range'}
                handleRangeDate={(from, to) => {
                  if (isCustomMode) {
                    setDateRangeValue(from, to)
                  }
                }}
                handleStartDate={(from) => {
                  if (isCustomMode) {
                    setDateRangeValue(from, toFilterDate)
                  }
                }}
                handleEndDate={(to) => {
                  if (isCustomMode) {
                    setDateRangeValue(fromFilterDate, to)
                  }
                }}
                startDate={fromFilterDate}
                endDate={toFilterDate}
                placeholder={'Select date'}
                hideTimePicker
                calendarClassName={styles.calendarClassName}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                className={styles.datePicker}
                minDate={!isCustomMode ? fromFilterDate : undefined}
                maxDate={!isCustomMode ? toFilterDate : undefined}
                isDisabled={!isCustomMode}
              />
            </DropdownContent>
          </div>
        )}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                offset: () => [-12, -12],
              },
            },
          ],
        }}
      />
    </div>
  )
})
