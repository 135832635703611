import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { Chatbot } from 'entities/Chatbot'
import { SendFromDropdownStore } from 'widgets/SendFromDropdown'

export class ChatbotNumberId {
  private _dispose: IReactionDisposer | null = null

  store = new SendFromDropdownStore({ excludeDeprioritizedNumbers: true })

  constructor(private _chatbot: Chatbot) {
    makeAutoObservable(this)

    this.store.handleChange((params) => {
      this._chatbot.setSendFrom(params)
    })

    this._dispose = reaction(
      () => this._chatbot.sendFrom,
      (send_from) => this.store.setSendFrom(send_from)
    )
  }

  dispose = () => {
    this._dispose?.()
  }
}
