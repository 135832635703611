// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y_ezu_qScd8xIJrQAxVj .PWmMGh8YDboYAc5hKdYg{margin-top:16px}.GZLOM506jCJs568ZQQcl{display:flex;flex-direction:column;gap:8px;padding-top:40px;margin:0 -17px}.rtfezq1gbxsRTw0CMA74{padding:20px 20px 10px;display:flex;flex-direction:column;gap:20px;position:relative;padding-bottom:32px}.rtfezq1gbxsRTw0CMA74::after{content:\"\";position:absolute;bottom:0;left:17px;right:17px;height:1px;background:var(--border-neutral-default)}.vQztUFdBqkQ6anfEwRqL{gap:12px;padding:20px}.xsTVe8GmUEXOiHkbJ6pj{border-color:var(--border-neutral-default) !important}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/ForbiddenMessageCommonTopics/ui/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,eAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,gBAAA,CACA,cAAA,CAEF,sBACE,sBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,iBAAA,CACA,mBAAA,CAGF,6BACE,UAAA,CACA,iBAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CACA,UAAA,CACA,wCAAA,CAEF,sBACE,QAAA,CACA,YAAA,CAGF,sBACE,qDAAA","sourcesContent":[".forbiddenTopicsWrap {\n  .forbiddenMessageAgreed {\n    margin-top: 16px;\n  }\n}\n\n.topicsWrap {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding-top: 40px;\n  margin: 0 -17px;\n}\n.collapseContent {\n  padding: 20px 20px 10px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  position: relative;\n  padding-bottom: 32px;\n}\n\n.collapseContent::after {\n  content: '';\n  position: absolute;\n  bottom: 0;\n  left: 17px;\n  right: 17px;\n  height: 1px;\n  background: var(--border-neutral-default);\n}\n.headerClassName {\n  gap: 12px;\n  padding: 20px;\n}\n\n.divider {\n  border-color: var(--border-neutral-default) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forbiddenTopicsWrap": "Y_ezu_qScd8xIJrQAxVj",
	"forbiddenMessageAgreed": "PWmMGh8YDboYAc5hKdYg",
	"topicsWrap": "GZLOM506jCJs568ZQQcl",
	"collapseContent": "rtfezq1gbxsRTw0CMA74",
	"headerClassName": "vQztUFdBqkQ6anfEwRqL",
	"divider": "xsTVe8GmUEXOiHkbJ6pj"
};
export default ___CSS_LOADER_EXPORT___;
