import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { SpinnerLoader, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { contactsStore } from 'entities/Contacts'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { Tabs } from './Tabs'
import { Properties } from './Properties/Properties'
import { Opportunities } from './Opportunities/Opportunities'
import styles from './styles.module.scss'

const SalesforceIntegration = observer(() => {
  const { integrationTab, selectedContact, handleIntegrationTab, contactIntegrationsStore } =
    useContactsDetailsContext()
  const [activeTab, setActiveTab] = useState<EnumIntegrationTabs>(
    integrationTab || EnumIntegrationTabs.Contact
  )

  const contact = contactsStore.getItem(selectedContact?.id)

  const handleActiveTab = (tab: EnumIntegrationTabs) => {
    setActiveTab(tab)
    handleIntegrationTab(tab)
  }

  useEffect(() => {
    integrationsStore.fetchIntegrations()
  }, [])

  if (!contact) return null

  const integration = integrationsStore.getIntegration(IntegrationKey.salesforce)

  if (!integration?.isConnected)
    return (
      <div className={styles.reconnectWrapper}>
        <Button
          typeBtn={'contained'}
          size={'medium'}
          contained={'primary'}
          fullWidth
          text='Reconnect'
          onClick={() => {
            uiStore.changeRoute({
              path: '/settings/integrations',
            })
          }}
        />
      </div>
    )

  const loading = contactIntegrationsStore.loading || contactIntegrationsStore.unlinking

  const info =
    integration.key === IntegrationKey.salesforce
      ? contactIntegrationsStore.getContactIntegrationSalesforceInfo(contact.id)
      : null

  if (loading)
    return (
      <div className={classnames(styles.loaderWrap)}>
        <SpinnerLoader size={24} />
      </div>
    )

  const ActiveTab = () => {
    if (activeTab === EnumIntegrationTabs.Contact)
      return <Properties properties={info?.properties || null} contact={contact} />

    if (activeTab === EnumIntegrationTabs.Opportunities)
      return <Opportunities opportunities={info?.opportunities || []} contact={contact} />

    return null
  }

  return (
    <div className={classnames(styles.wrap)}>
      <Tabs
        handleTab={handleActiveTab}
        activeTab={activeTab}
        isLead={info?.isLead}
        count={{
          [EnumIntegrationTabs.Opportunities]: info?.opportunities?.length || 0,
        }}
      />
      <ActiveTab />
    </div>
  )
})

export { SalesforceIntegration }
