import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { ComplianceApi, type IResponseOptInData, IOptInMethodTypes } from 'entities/Compliance'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers'
import { type LocalNumbersStepsStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersStepsStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'

export class OptInMethodStore {
  constructor(private _localNumbersStore: LocalNumbersStepsStore) {
    makeAutoObservable(this)
  }

  private _optInMethods: IOptInMethodTypes[] = []

  get optInMethods() {
    return this._optInMethods
  }

  get isStep3Disabled() {
    return !this.optInMethods.length
  }

  get localNumbersStore() {
    return this._localNumbersStore
  }

  init = (optInData: IResponseOptInData[] | null) => {
    if (!optInData) return

    this._optInMethods = optInData.map((item) => item.optInMethod)
  }

  campaignOptInMethodNextClick = async (path: LocalNumbersRoutes) => {
    if (!this.localNumbersStore.campaignId) return
    try {
      this.localNumbersStore.stepsStore.setStepLoading(true)

      const { data } = await ComplianceApi.updateComplianceServicesCampaign({
        step: 3,
        campaignId: this.localNumbersStore.campaignId,
        optInMethods: this.optInMethods,
      })

      const step = this.localNumbersStore.getNextStep(path)
      this.localNumbersStore.complianceLayerStore.set10DLCServiceCampaign(data)

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.localNumbers.root}/${step}`,
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._localNumbersStore.stepsStore.setStepLoading(false)
    }
  }

  setOptInMethods = (value: IOptInMethodTypes) => {
    this.optInMethods.includes(value)
      ? (this._optInMethods = this.optInMethods.filter((item) => item !== value))
      : (this._optInMethods = [...this.optInMethods, value])
  }

  isOptInMethodActive = (value: IOptInMethodTypes) => this.optInMethods.includes(value)
}
