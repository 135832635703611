import dayjs from 'dayjs'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState, useEffect, useMemo } from 'react'
import { DayjsFormats } from 'shared/lib'
import { DatePicker, Dropdown, DropdownContent, layoutStore } from 'shared/ui'
import type { IWidgetProps } from 'widgets/FilterEditor/types'

import { DateRangeOperators, DateSingleOperators, DateTimeTypes } from './definition'
import styles from './styles.module.scss'

export const WidgetDate = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [showValuesWarning, setShowValuesWarning] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)

    const [inputDate, setDateValue] = useState<Date | null>(null)

    useEffect(() => {
      if (!filterItem.payload.value) return
      setDateValue(new Date(filterItem.payload.value as Date))
    }, [])

    const [inputDateRange, setDateRangeValue] = useState<{
      start: Date | null
      end: Date | null
    }>({
      start: null,
      end: null,
    })

    useEffect(() => {
      if (!filterItem.payload.to || !filterItem.payload.from) return
      setDateValue(new Date(filterItem.payload.value as Date))
      setDateRangeValue({
        start: new Date(filterItem.payload.from as Date),
        end: new Date(filterItem.payload.to as Date),
      })
    }, [])

    const pickerType = useMemo(() => {
      switch (true) {
        case !!filterItem.operator && DateSingleOperators.has(filterItem.operator.key):
          return 'singleExpanded'
        case !!filterItem.operator && DateRangeOperators.has(filterItem.operator.key):
          return 'range'
        default:
          return 'single'
      }
    }, [filterItem.operator])

    const withTime = useMemo(
      () => DateTimeTypes.has(filterItem.config.type),
      [filterItem.config.type]
    )

    const handleDate = (date: Date | null) => filterItem.payload.updateHard({ value: date })

    const selectRangeDate = (from: Date | null, to: Date | null) =>
      filterItem.payload.updateHard({ from, to })

    const rangeTriggerValue = () => {
      const { from, to } = filterItem.payload

      if (!from || !to) {
        return 'select'
      }

      const startYear = new Date(from || '').getFullYear()
      const endYear = new Date(to || '').getFullYear()

      if (startYear === endYear) {
        return `${dayjs(from).format(withTime ? DayjsFormats.full7 : DayjsFormats.date)} - ${dayjs(
          to
        ).format(withTime ? DayjsFormats.full : DayjsFormats.full6)}`
      }

      return `${dayjs(from).format(withTime ? DayjsFormats.full : DayjsFormats.full6)} - ${dayjs(
        to
      ).format(withTime ? DayjsFormats.full : DayjsFormats.full6)}`
    }

    const singleTriggerValue = () => {
      if (filterItem.payload.value || inputDate) {
        const date = inputDate || (filterItem.payload.value as Date)
        const format = withTime ? DayjsFormats.full : DayjsFormats.full6

        return `${dayjs(date).format(format)}`
      }

      return 'select'
    }

    const singleType = pickerType === 'singleExpanded'

    useEffect(() => {
      setTimeout(() => setShowDropdown(expandByDefault), 100)
    }, [expandByDefault])

    const getCorrectUtcDate = (data: Date | null, fromStartOfDay = true) => {
      if (!data) {
        return null
      }

      const correctTime = fromStartOfDay ? '' : 'T23:59:59'
      const correctDate = dayjs(data).format('YYYY-MM-DD') + correctTime

      return new Date(correctDate)
    }

    const { isMobileView, isViewXs } = layoutStore

    const getPlacement = () => {
      if (isViewXs) return 'bottom-end'
      if (isMobileView) return 'bottom'

      return 'bottom-start'
    }

    return (
      <div className={styles.container}>
        <Dropdown
          ariaLabel={'DateContent'}
          placement={getPlacement()}
          margin={10}
          parentsDropdownId={parentsDropdownId}
          withTextInput
          show={showDropdown}
          triggerComponent={() => (
            <span
              className={classNames(
                styles.dropdownTriggerComponent,
                showValuesWarning && styles.warning
              )}
            >
              {singleType ? singleTriggerValue() : rangeTriggerValue()}
            </span>
          )}
          onClose={() => {
            singleType && handleDate(getCorrectUtcDate(inputDate))
            !singleType &&
              selectRangeDate(
                getCorrectUtcDate(inputDateRange.start),
                getCorrectUtcDate(inputDateRange.end, false)
              )

            setShowValuesWarning(filterItem.isEmpty)
          }}
          onToggle={(isOpen) => {
            isOpen && setShowValuesWarning(false)
          }}
          customComponent={(onClose, dropdownId) => (
            <DropdownContent
              noStyle={!singleType}
              className={classNames({ [styles.datePickerWrap]: singleType })}
            >
              <DatePicker
                type={pickerType}
                handleRangeDate={(start, end) => setDateRangeValue({ start, end })}
                handleStartDate={(start) => setDateRangeValue({ ...inputDateRange, start })}
                handleEndDate={(end) => setDateRangeValue({ ...inputDateRange, end })}
                startDate={inputDateRange.start}
                endDate={inputDateRange.end}
                placeholder={'Select date'}
                isClearable
                date={inputDate}
                handleDate={singleType ? setDateValue : undefined}
                disableTimePicker={!inputDate}
                hideTimePicker={!withTime}
                calendarClassName={styles.calendarClassName}
                parentsDropdownId={[dropdownId, ...(parentsDropdownId ?? [])]}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            </DropdownContent>
          )}
        />
      </div>
    )
  }
)
