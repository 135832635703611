import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet, useParams } from 'react-router-dom'
import { SpinnerLoader } from 'shared/ui'
import {
  TollFreeStepsProvider,
  useTollFreeStepsContext,
} from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import { useComplianceLayerContext } from 'pages/settings/pages/compliance/context'

const TollFreeProviderLayoutContent = observer(() => {
  const store = useTollFreeStepsContext()
  const { profile_id } = useParams()

  useEffect(() => {
    store.init(Number(profile_id))
  }, [])

  if (store.loading) {
    return <SpinnerLoader />
  }

  return (
    <>
      <Outlet />
    </>
  )
})

export const TollFreeProviderLayout = () => {
  const complianceLayerStore = useComplianceLayerContext()

  return (
    <TollFreeStepsProvider complianceLayerStore={complianceLayerStore}>
      <TollFreeProviderLayoutContent />
    </TollFreeStepsProvider>
  )
}
