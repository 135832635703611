export const forbiddenTopics = [
  {
    number: '1',
    title: 'High-risk financial services',
    content: [
      "This category includes payday loans, short-term high-interest loans, new loan solicitations, third-party loans, student loans, cryptocurrency, and stocks and investment platforms. 'Third-party' refers to loans originated by entities other than the one servicing the loan. Examples of third-party loans include auto, mortgage, and personal loans.",
      'First-party loan content is acceptable as long as it is non-promotional unless sent via an approved Short Code.',
      'Businesses focused solely on stocks, investing, or cryptocurrency are restricted to SMS traffic for 2FA or transactional messaging. Mixed-use cases, where these activities form only part of the business, may be approved depending on the other use case content.',
    ],
  },
  {
    number: '2',
    title: 'Third-party lead generation services and marketing',
    content: [
      'This includes companies, NGOs, or political campaigns that buy, sell, or share consumer information, as well as affiliate lending, affiliate marketing, and deceptive marketing. All third-party use cases are strictly prohibited, and consent must be obtained directly from end-users.',
      'Political customers sending SMS messages are not permitted to use voter registration databases to collect consent or contact end-users.',
      'Any business whose terms of service or privacy policy includes sharing or selling consumer data or opt-in information is considered noncompliant.',
    ],
  },
  {
    number: '3',
    title: 'Debt collection or forgiveness',
    content: [
      "This includes third-party debt collection, debt consolidation, debt reduction, debt relief, and credit or debt repair. 'Third-party' refers to any entity other than the one owed the debt. For example, a hospital may send billing messages to its own patients, provided they have opted in to receive such communication.",
      'While third-party debt collection is not allowed, a debt collection business with direct consent from end-users to send related messages is permitted.',
      'Debt consolidation, debt reduction, and credit repair programs are prohibited, even with first-party consent.',
    ],
  },
  {
    number: '4',
    title: '“Get rich quick” schemes',
    content: [
      'This includes deceptive work-from-home programs, high-risk investment opportunities, pyramid schemes, and mystery shopping. These use cases often promise maximum or guaranteed financial gains with minimal effort.',
      'Due to the high volume of consumer complaints associated with these categories, they are not allowed on carrier routes in the telecom industry.',
    ],
  },
  {
    number: '5',
    title: 'Illegal substances/articles',
    content: [
      'This includes cannabis, CBD, kratom, paraphernalia products, vape/e-cigarettes, and fireworks. Businesses associated with cannabis, CBD, kratom, or drug paraphernalia are strictly prohibited from using SMS/MMS messaging on Twilio in the US and Canada, regardless of the content being sent.',
      'These restrictions apply irrespective of federal or state legality. All use cases for these categories are disallowed, including SMS that does not reference cannabis, and even for 2FA purposes, such entities are not permitted to send messages.',
    ],
  },
  {
    number: '6',
    title: 'Prescription drugs',
    content: [
      'This includes drugs that require a prescription. Any offers for drugs that cannot be sold over the counter in the US or Canada are strictly prohibited, even if the business is operated by a licensed professional.',
    ],
  },
  {
    number: '7',
    title: 'Gambling',
    content: [
      'This includes casino apps, gambling websites, sweepstakes, 50/50 raffles, and betting or sports picks. Gambling-related messaging is prohibited in the US and Canada on all number types, including Toll-Free, Short Code, and Long Code.',
      'However, Bingo-related messages are permitted on an approved Short Code. Community or locally-based Bingo messaging is also allowed on carrier networks.',
    ],
  },
  {
    number: '8',
    title: '"S.H.A.F.T." use cases and electronic smoking devices',
    content: [
      'This includes messaging related to sex, hate, alcohol, firearms, tobacco, and vape/e-cigarettes. Alcohol-related messaging is permitted on Toll-Free, Short Code, and Long Code in the US, provided proper age-gating procedures are implemented. Age gating requires users to input their date of birth; it cannot be a simple yes or no question.',
      'Firearms, vape, and e-cigarette messaging are prohibited on Short Code, Toll-Free, and Long Code, regardless of age-gating. Tobacco messaging is allowed on Short Code with proper age-gating but is not permitted on Long Code or Toll-Free.',
      'Gun advocacy messaging does not fall under the firearms category.',
      'In Canada, all age-gated content must be blocked across Toll-Free, Short Code, and Long Code. Age-gated traffic is only allowed in Canada with a special carrier exemption. Permissible age-gated content with exemptions includes pocket knives, lighters, and non-alcoholic beverages.',
    ],
  },
]
