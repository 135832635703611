import { observer } from 'mobx-react-lite'
import React from 'react'
import { CardsFilter } from 'shared/ui'
import { useKeywordDetailsContext } from 'pages/keywords/context'
import styles from './styles.module.scss'

export const KeywordStatisticsFilter = observer(() => {
  const { filters, onActiveFilter, filter } = useKeywordDetailsContext()

  if (!filters) return null

  return (
    <>
      <CardsFilter
        items={filters}
        activeKey={filter}
        onActiveFilter={onActiveFilter}
        wrapperClassName={styles.filterWrapper}
      />
      <div className={styles.separator} />
    </>
  )
})
