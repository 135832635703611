import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ProgressSteps } from 'shared/ui/ProgressSteps'
import { Typography } from 'shared/ui'
import {
  getFirstBlockItems,
  getSecondBlockItems,
  getThirdBlockItems,
  QuestionSectionItems,
} from 'entities/Compliance/ui/Questionnaire'
import type { QuestionnaireOrSelectNumberModalStore } from 'entities/Phone/ui/QuestionnaireOrSelectNumberModal'
import { StepSelectNumber } from './StepSelectNumber/StepSelectNumber'
import styles from './styles.module.scss'

type IGetNumberPFTModalContentProps = {
  store: QuestionnaireOrSelectNumberModalStore
}

export const QuestionnaireOrSelectNumberModalContent: FC<IGetNumberPFTModalContentProps> = observer(
  ({ store }) => {
    const questionnaireStepsStore = store.questionnaireStepsStore

    return (
      <div className={styles.wrapContent}>
        <ProgressSteps title={store.titleStep} total={store.maxStep} step={store.step} />

        {Boolean(store.descriptionStep) && (
          <Typography
            tag={'div'}
            variant={'body-md-regular'}
            ariaLabel={'questionnaireOrSelectNumberModalContent_description'}
            className={styles.description}
          >
            {store.descriptionStep}
          </Typography>
        )}

        <div className={styles.content}>
          {store.step === 1 && (
            <QuestionSectionItems
              items={getFirstBlockItems(
                questionnaireStepsStore.targetCountry,
                questionnaireStepsStore.handleSetTargetCountry
              )}
            />
          )}
          {store.step === 2 && (
            <QuestionSectionItems
              items={getSecondBlockItems(
                questionnaireStepsStore.isTaxId,
                questionnaireStepsStore.handleSetIsTaxId
              )}
            />
          )}
          {store.step === 3 && (
            <QuestionSectionItems
              items={getThirdBlockItems(
                questionnaireStepsStore.numberType,
                questionnaireStepsStore.isTaxId,
                questionnaireStepsStore.targetCountry,
                questionnaireStepsStore.setNumberType
              )}
            />
          )}
          {store.step === 4 && <StepSelectNumber store={store} />}
        </div>
      </div>
    )
  }
)
