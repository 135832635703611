import { FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { type Segment } from 'entities/Segment/model/Segment'
import { type SegmentBox } from 'entities/Segment'
import { type IEnrollmentItem, EnrollmentItemType } from 'widgets/ContactEnrollment/types'
import { SearchDropdown } from 'widgets/SearchDropdown/ui'
import { EnumEnrollmentMode } from 'widgets/ContactEnrollment/store/types'
import { type FilterEditorStore } from 'widgets/FilterEditor'
import { type EnrollmentListStore } from 'widgets/ContactEnrollment/store'
import { EnrollmentAllPreview } from './../EnrollmentAllPreview/EnrollmentAllPreview'
import { EnrollmentActions } from '../EnrollmentActions'
import { EnrollmentFilter } from '../EnrollmentFilter'
import { EnrollmentList } from '../EnrollmentList'
import styles from './styles.module.scss'

type IEnrollmentIncludeProps = {
  enrollmentListStore: EnrollmentListStore
  filterEditorStore: FilterEditorStore
  segmentBox: SegmentBox
  mode: EnumEnrollmentMode
  showList?: boolean
}

export const EnrollmentInclude: FC<IEnrollmentIncludeProps> = observer(
  ({ enrollmentListStore, filterEditorStore, segmentBox, mode, showList }) => {
    const { preview } = enrollmentListStore

    const handleFilterApply = (filter: Segment) => {
      if (preview.active?.type === EnrollmentItemType.Segment) {
        preview.resetPreviewActive(preview.active)
      }

      enrollmentListStore.addOrUpdateItem({
        type: EnrollmentItemType.Filter,
        payload: filter,
      })
      segmentBox.setHasChanges(false)
      preview.resetItemActive(segmentBox)
      filterEditorStore.dispose()

      if (segmentBox.segment) {
        filterEditorStore.groups.populateSegment(segmentBox.segment)
      }
    }
    const handleFilterCancel = () => {
      preview.resetItemActive(segmentBox)
      filterEditorStore.dispose()
    }

    const handleListItemSelect = (selection: IEnrollmentItem) => {
      segmentBox.setHasChanges(false)
      preview.setItemActive(selection, segmentBox, filterEditorStore)
    }

    const handleListItemRemove = (selection: IEnrollmentItem) => {
      if (
        selection.type === EnrollmentItemType.Filter ||
        selection.type === EnrollmentItemType.Segment ||
        selection.type === EnrollmentItemType.Tag
      ) {
        segmentBox.removeSegment()
      }

      preview.resetPreviewActive(selection)
      filterEditorStore.dispose()
    }

    return (
      <div
        className={classnames(styles.contentContainer, {
          [styles['contentContainer--borderTop']]: mode === EnumEnrollmentMode.ViewOnly,
          [styles.contentContainer__alert]: enrollmentListStore.existOtherFiltersWithHubspotFilter,
        })}
      >
        {showList && (
          <div className={styles.asideContainer}>
            {mode === EnumEnrollmentMode.Edit && (
              <div
                className={classnames(styles.asideStickyHeader, {
                  [styles.disabled]: segmentBox.hasChanges,
                })}
                onClick={() => enrollmentListStore.searchDropdownStore.setOpen(true)}
              >
                <SearchDropdown store={enrollmentListStore.searchDropdownStore} />
              </div>
            )}

            <EnrollmentList
              active={preview.active}
              items={enrollmentListStore.allFiltersWithCount}
              onRemove={handleListItemRemove}
              onSelect={(selection) => handleListItemSelect(selection)}
              onMouseLeave={() => preview.resetItemHover(segmentBox, filterEditorStore)}
              onMouseEnter={(item) => preview.setItemHover(item, segmentBox, filterEditorStore)}
              mode={mode}
            />
          </div>
        )}

        <div className={styles.mainContainer}>
          <section
            className={classnames(styles.previewSection, {
              [styles['previewSection--disabledScroll']]: !preview.hasActive,
            })}
          >
            {preview.active && (
              <EnrollmentAllPreview
                filterEditorStore={filterEditorStore}
                mode={mode}
                store={preview}
                segmentBox={segmentBox}
                onApply={handleFilterApply}
                onCancel={handleFilterCancel}
              />
            )}

            {!preview.active && (
              <EnrollmentFilter
                filterEditorStore={filterEditorStore}
                segmentBox={segmentBox}
                onApply={handleFilterApply}
                onCancel={handleFilterCancel}
                isViewOnly={mode === EnumEnrollmentMode.ViewOnly}
                useHubspot={enrollmentListStore.existHubspotFilter}
              />
            )}
          </section>

          {mode === EnumEnrollmentMode.Edit && (
            <footer className={styles.footerActions}>
              <EnrollmentActions />
            </footer>
          )}
        </div>
      </div>
    )
  }
)
