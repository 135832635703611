import classNames from 'classnames'
import React from 'react'
import { Icon, Typography } from 'shared/ui'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import recordVideoStore from 'widgets/RecordVideo/store/recordVideoStore'

import styles from '../styles.module.scss'

export const RecordVideoItem = ({
  btnClass,
  disabled,
  onCloseMenu,
}: {
  btnClass: string
  disabled?: boolean
  onCloseMenu: () => void
}) => {
  const messageFieldStore = useMessageFieldContext()

  const onAddVideoRecord = (file: File) => {
    messageFieldStore.addMediaFiles([file])
  }

  const onRecord = () => {
    recordVideoStore.setOpenModal(onAddVideoRecord)
    onCloseMenu()
  }

  return (
    <button
      className={classNames(btnClass, styles.actionButton, disabled && styles.disabled)}
      onClick={onRecord}
    >
      <Icon icon={'record'} fontSize={16} tertiary />
      <Typography variant={'body-md-medium'} ariaLabel={'Record video'}>
        Record video
      </Typography>
    </button>
  )
}
