// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FY9cWOtfTejnfycIlcUg{display:flex;flex-direction:column;flex-grow:1;align-items:center;justify-content:center}.FY9cWOtfTejnfycIlcUg .mRncTEMSWsMlix4jBKe3{margin-bottom:32px;border-radius:12px;overflow:hidden;min-height:276px}.FY9cWOtfTejnfycIlcUg .mRncTEMSWsMlix4jBKe3 img{display:block;max-width:492px}.r6LgHymCH0UixDR0NQpa{margin-top:24px;display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/shared/ui/EmptyState/variants/PreviewEmptyState/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CAEA,4CACE,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEA,gDACE,aAAA,CACA,eAAA,CAIN,sBACE,eAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  align-items: center;\n  justify-content: center;\n\n  .preview {\n    margin-bottom: 32px;\n    border-radius: 12px;\n    overflow: hidden;\n    min-height: 276px;\n\n    img {\n      display: block;\n      max-width: 492px;\n    }\n  }\n}\n.actions {\n  margin-top: 24px;\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "FY9cWOtfTejnfycIlcUg",
	"preview": "mRncTEMSWsMlix4jBKe3",
	"actions": "r6LgHymCH0UixDR0NQpa"
};
export default ___CSS_LOADER_EXPORT___;
