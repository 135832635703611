import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button,
  ContainerHeader,
  EnumSpinnerLoaderPosition,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import { ManagePlan } from 'pages/admin/pages/organizations/pages/CustomPlan/ui/ManagePlan/ManagePlan'
import { PlanPreview } from 'pages/admin/pages/organizations/pages/CustomPlan/ui/PlanPreview/PlanPreview'
import { PaymentSchedule } from 'pages/admin/pages/organizations/pages/CustomPlan/ui/PaymentSchedule/PaymentSchedule'
import { CustomPaymentsScheduleTable } from 'pages/admin/pages/organizations/pages/CustomPlan/ui/CustomPaymentsScheduleTable/CustomPaymentsScheduleTable'
import { AdminRoutes } from 'pages/admin'
import { SummaryEditPayment } from 'pages/admin/pages/organizations/pages/CustomPlan/ui/SummaryEditPayment/SummaryPayment'
import { ManualInvoice } from 'pages/admin/pages/organizations/pages/CustomPlan/ui/ManualInvoice/ManualInvoice'
import { SummaryPayment } from './SummaryPayment/SummaryPayment'
import styles from './style.module.scss'

export const CustomPlan = observer(() => {
  const {
    loading,
    setId,
    isEdit,
    showSummary,
    saveLoading,
    save,
    cancel,
    organization,
    updatePayments,
    havePaymentPlan,
    reset,
  } = adminOrganizationCustomPlanStore
  const { id } = useParams()

  useEffect(() => {
    setId(Number(id))
    return () => {
      reset()
    }
  }, [])

  if (loading) {
    return (
      <div className={styles.wrap}>
        <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapContent}>
        <div className={styles.wrapLink}>
          <Link to={`/admin/${AdminRoutes.organizations}/${id}`} className={styles.link}>
            <Typography variant={'body-sm-regular'} pointer ariaLabel={'organizationName'}>
              {organization?.name}
            </Typography>
          </Link>
        </div>
        <ContainerHeader
          title={'Manage subscription'}
          titleIconProps={{
            icon: 'building',
            fontSize: 20,
            tertiary: true,
          }}
        />

        <div className={styles.wrapForm}>
          <ManagePlan />
          <PlanPreview />
          <ManualInvoice />
          <PaymentSchedule />
          {(havePaymentPlan || isEdit) && <CustomPaymentsScheduleTable />}
          {showSummary && <SummaryPayment />}
          {isEdit && <SummaryEditPayment />}
        </div>

        <div className={styles.wrapButtons}>
          <Button onClick={cancel} text={'Cancel'} contained={'secondary'} />
          <Button
            onClick={isEdit ? updatePayments : save}
            loading={saveLoading}
            text={'Apply'}
            typeBtn={'contained'}
          />
        </div>
      </div>
    </div>
  )
})
