import { type ICardFilter, Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { Base } from 'models'
import type { IParamsGetContactsStatistic } from 'entities/ContactStatistic'

type IStatisticProps = {
  reply_count?: number | null
  success_count?: number | null
  total_count: number | null
  clicks_count?: number | null
  contacts_count?: number
  conversions_count?: number | null
  revenue_cost?: string | number | null
  undelivered_count?: number | null
  unsubscribed_count?: number
  is_track_conversion?: boolean
  active_count?: number
}

export class Statistic extends Base {
  count_at: string | null = null
  created_at: string | null = null
  contacts_count: number | null = null
  skipped_count: number | null = null
  skipped_percent: number | null = null
  reply_count?: number | null = null
  success_count?: number | null = null
  total_count: number | null = 0
  clicks_count?: number | null = null
  conversions_count?: number | null
  revenue_cost?: string | number | null
  undelivered_count?: number | null
  unsubscribed_count?: number
  is_track_conversion?: boolean
  active_count?: number

  constructor({
    contacts_count = 0,
    reply_count,
    success_count,
    total_count = 0,
    clicks_count,
    conversions_count,
    revenue_cost,
    undelivered_count,
    unsubscribed_count,
    is_track_conversion,
    active_count,
  }: IStatisticProps) {
    super()
    this.contacts_count = contacts_count
    this.skipped_count = contacts_count - (total_count || 0)
    this.skipped_percent = Math.round((this.skipped_count / contacts_count) * 100)

    this.reply_count = reply_count
    this.success_count = success_count
    this.total_count = total_count
    this.clicks_count = clicks_count
    this.conversions_count = conversions_count
    this.revenue_cost = revenue_cost
    this.undelivered_count = undelivered_count
    this.unsubscribed_count = unsubscribed_count
    this.is_track_conversion = is_track_conversion
    this.active_count = active_count
  }

  get success_percent() {
    if (this.success_count && this.total_count) {
      return Math.round((this.success_count / this.total_count) * 100)
    }
    return 0
  }
  get reply_percent() {
    if (this.reply_count && this.total_count) {
      return Math.round((this.reply_count / this.total_count) * 100)
    }
    return 0
  }
  get clicks_percent() {
    if (this.clicks_count && this.total_count) {
      return Math.round((this.clicks_count / this.total_count) * 100)
    }
    return 0
  }
  get conversions_percent() {
    if (this.conversions_count && this.total_count) {
      return Math.round((this.conversions_count / this.total_count) * 100)
    }
    return 0
  }
  get undelivered_percent() {
    if (this.undelivered_count && this.total_count) {
      return Math.round((this.undelivered_count / this.total_count) * 100)
    }
    return 0
  }
  get unsubscribed_percent() {
    if (this.unsubscribed_count && this.total_count) {
      return Math.round((this.unsubscribed_count / this.total_count) * 100)
    }
    return 0
  }
  get active_percent() {
    if (this.active_count && this.total_count) {
      return Math.round((this.active_count / this.total_count) * 100)
    }
    return 0
  }

  public getFilters = (
    totalCountLabel = 'Contacts',
    totalCountTitle = 'Total'
  ): ICardFilter<IParamsGetContactsStatistic['filter']>[] => {
    return [
      {
        key: 'sent_to',
        title: totalCountTitle,
        amount: this.total_count,
        tooltipProps: {
          label: totalCountLabel,
          placement: 'top',
        },
      },
      {
        key: 'success',
        title: 'Delivered',
        amount: this.success_count,
        percentage: this.success_percent,
        tooltipProps: {
          label: ['Contacts who received', <br key={'br'} />, 'the message'],
          placement: 'top',
        },
      },
      {
        key: 'skipped',
        title: 'Skipped',
        amount: this.skipped_count,
        percentage: this.skipped_percent,
        tooltipProps: {
          label: [
            'This was canceled during sending',
            <br key={'br'} />,
            <Typography
              ariaLabel={'desc'}
              key={'desc'}
              tag={'div'}
              variant={'body-sm-regular'}
              color={'var(--content-primary-tertiary-inverted)'}
              marginTop={2}
            >
              Before canceling, it was sent to{' '}
              {numberFormat({
                value: this.total_count || 0,
              })}{' '}
              of <br />
              {numberFormat({
                value: this.contacts_count || 0,
              })}{' '}
              eligible contacts.
            </Typography>,
          ],
          placement: 'top',
        },
      },
      {
        key: 'replied',
        title: 'Replies',
        amount: this.reply_count,
        percentage: this.reply_percent,
        tooltipProps: {
          label: ['Contacts who replied', <br key={'br'} />, 'to the message'],
          placement: 'top',
        },
      },
      {
        key: 'clicked',
        title: 'Clicks',
        amount: this.clicks_count,
        percentage: this.clicks_percent,
        tooltipProps: {
          label: ['Contacts who clicked', <br key={'br'} />, 'on the link(s)'],
          placement: 'top',
        },
      },
      {
        key: 'conversions',
        title: 'Conversions',
        amount: this.conversions_count,
        percentage: this.conversions_percent,
        tooltipProps: {
          label: 'Contacts who converted',
          placement: 'top',
        },
      },
      {
        key: 'revenue',
        title: 'Revenue',
        amountContent: this.revenue_cost,
        tooltipProps: {
          label: ['Revenue generated by the', <br key={'br'} />, 'contacts who converted'],
          placement: 'top',
        },
      },
      {
        key: 'failed',
        title: 'Undelivered',
        amount: this.undelivered_count,
        percentage: this.undelivered_percent,
        tooltipProps: {
          label: ['Contacts who didn’t', <br key={'br'} />, 'received the message'],
          placement: 'top',
        },
      },
      {
        key: 'subscribed',
        title: 'Active',
        amount: this.active_count,
        percentage: this.active_percent,
        tooltipProps: {
          label: ['Active contacts'],
          placement: 'top',
        },
      },
      {
        key: 'opted_out',
        title: 'Unsubscribed',
        amount: this.unsubscribed_count,
        percentage: this.unsubscribed_percent,
        tooltipProps: {
          label: 'Contacts who opted-out',
          placement: 'top',
        },
      },
    ]
  }
}
