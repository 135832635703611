import { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import type { IPreviewWorkflowData } from 'entities/Workflow'
import { WorkflowEnrollModalStore } from 'widgets/WorkflowEnroll/store/WorkflowEnrollModalStore'

import { WorkflowEnrollModalFilters } from './WorkflowEnrollModalFilters'
import { WorkflowEnrollModalSearch } from './WorkflowEnrollModalSearch'
import styles from './styles.module.scss'

type IWorkflowEnrollModalProps = {
  contactIds: number[]
  onClose: () => void
}

export const WorkflowEnrollModal = observer(
  ({ contactIds, onClose }: IWorkflowEnrollModalProps) => {
    const store = useMemo(() => new WorkflowEnrollModalStore(), [])
    const [workflow, setWorkflow] = useState<IPreviewWorkflowData | null>(null)
    const [applyFilters, setApplyFilters] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)

    const enrollDisabled = !workflow

    const handleEnroll = () => {
      if (!workflow) return

      setLoading(true)
      store.enrollContacts(workflow.id, contactIds, applyFilters).then((success) => {
        setLoading(false)
        if (success) onClose()
      })
    }

    return (
      <div className={styles.content}>
        <div className={styles.contentRows}>
          <div className={styles.contentRow}>
            <span className={styles.contentRowTitle}>Workflow</span>
            <WorkflowEnrollModalSearch onSelect={setWorkflow} />
          </div>
          <div className={styles.contentRow}>
            <WorkflowEnrollModalFilters value={applyFilters} onChange={setApplyFilters} />
          </div>
        </div>
        <div className={styles.contentFooter}>
          <Button
            size='medium'
            text='Cancel'
            contained='secondary'
            disabled={loading}
            onClick={onClose}
          />
          <Button
            size='medium'
            text='Enroll'
            loading={loading}
            disabled={enrollDisabled}
            onClick={handleEnroll}
          />
        </div>
      </div>
    )
  }
)
