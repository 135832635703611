// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TgJywj6ij6n0bYYLceBB{display:inline-flex;cursor:pointer}.TgJywj6ij6n0bYYLceBB.ZWocbpb4JzcVCxJQHJ03{display:grid}.TgJywj6ij6n0bYYLceBB.ZWocbpb4JzcVCxJQHJ03>div{display:grid}.TgJywj6ij6n0bYYLceBB>div{display:inline-flex}.TgJywj6ij6n0bYYLceBB.YJp1k__3ay55ZhHjvqn7{width:100%}.TgJywj6ij6n0bYYLceBB.YJp1k__3ay55ZhHjvqn7>div{width:100%}.TgJywj6ij6n0bYYLceBB.YJp1k__3ay55ZhHjvqn7>div>div{width:100%}.NzYjtvoKPWJbEqRgPe47{cursor:default}", "",{"version":3,"sources":["webpack://./src/shared/ui/Tooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,cAAA,CAEA,2CACE,YAAA,CACA,+CACE,YAAA,CAIJ,0BACE,mBAAA,CAGF,2CACE,UAAA,CAEA,+CACE,UAAA,CAEA,mDACE,UAAA,CAKR,sBACE,cAAA","sourcesContent":[".wrap {\n  display: inline-flex;\n  cursor: pointer;\n  \n  &.grid{\n    display: grid;\n    & > div{\n      display: grid;\n    }\n  }\n\n  & > div{\n    display: inline-flex;\n  }\n\n  &.fullWidth {\n    width: 100%;\n\n    & > div {\n      width: 100%;\n\n      & > div {\n        width: 100%;\n      }\n    }\n  }\n}\n.withoutPointer {\n  cursor: default;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "TgJywj6ij6n0bYYLceBB",
	"grid": "ZWocbpb4JzcVCxJQHJ03",
	"fullWidth": "YJp1k__3ay55ZhHjvqn7",
	"withoutPointer": "NzYjtvoKPWJbEqRgPe47"
};
export default ___CSS_LOADER_EXPORT___;
