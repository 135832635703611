import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon } from 'shared/ui'
import { IQuickLink } from 'entities/QuickLink'

import styles from './styles.module.scss'

export interface IQuickLinkProps {
  quickLink: IQuickLink
}

const canParse = (url: string) => {
  try {
    new URL(url)

    return true
  } catch (_) {
    return false
  }
}

export const QuickLink = observer(({ quickLink }: IQuickLinkProps) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    className={classNames(styles.link, { [styles.disabled]: quickLink.disabled })}
    href={
      quickLink.disabled ? '' : canParse(quickLink.url) ? quickLink.url : `https://${quickLink.url}`
    }
    target='_blank'
    rel='noreferrer'
  >
    <span className={styles.label}>{quickLink.label}</span>
    <Icon icon='arrowRightUp' />
  </a>
))
