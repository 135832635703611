import { useRef, FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Avatar, Dropdown, DropdownContent, Icon, Switch, Typography, Tooltip } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { configStore } from 'entities/Config'
import { SettingsApi, userSettingsStore } from 'entities/Settings'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { authStore } from 'entities/Auth'
import { subscriptionStore } from 'entities/Subscription'
import { PersonalPath, ClientsPath, BillingPath } from 'entities/ExportRouters'
import { organizationStore } from 'entities/Organization'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { MultiOrganizationsDropdown } from 'widgets/MultiOrganizationsDropdown/ui/MultiOrganizationsDropdown'
import { type SidebarStore } from 'widgets/Sidebar/store/sidebarStore'
import { commonTooltipProps } from '../../constants/menuItems'
import { RouterLink } from './RouterLink'
import { Credits } from './Credits'
import styles from './styles.module.scss'

type IMainDropdownProps = {
  sidebarStore: SidebarStore
}

export const MainDropdown: FC<IMainDropdownProps> = observer(({ sidebarStore }) => {
  const canManageClients = userPermissionsStore.getItem('can_manage_clients')
  const switchingToOldVersion = useRef(false)
  const { user, toggleAwayStatus, awayLoading } = usersStore
  const { isDeveloper } = configStore
  const { multiOrganizationsDropdownStore } = sidebarStore
  const { activeOrganizationAvatarInfo, activeOrganisationName } = multiOrganizationsDropdownStore
  const { show_new_design_only } = featureFlagsStore

  const handleAwayStatus = () => {
    if (awayLoading) return
    toggleAwayStatus()
  }

  const switchToOldVersion = () => {
    if (switchingToOldVersion.current) return
    switchingToOldVersion.current = true

    SettingsApi.updateUsersSetting({
      featureKey: 'salesmsg-design-v2',
      settingsKey: 'active',
      value: false,
    })
      .then(() => window.location.reload())
      .catch(() => (switchingToOldVersion.current = false))
  }

  type IContentProps = { onClose: () => void; dropdownId: string }

  const clientsPath = useMemo(() => {
    return subscriptionStore.isTrial ? BillingPath.upgrade : ClientsPath.root
  }, [subscriptionStore.isTrial])

  const Content = ({ dropdownId, onClose }: IContentProps) => (
    <DropdownContent className={styles.container} noPadding>
      <div className={styles.block} aria-label={getAriaLabel('MainDropdown', 'Block')}>
        <RouterLink
          route={`/${PersonalPath.root}`}
          aria-label={getAriaLabel('MainDropdown', 'Link')}
        >
          <div
            className={styles.userWrapper}
            aria-label={getAriaLabel('MainDropdown', 'userWrapper')}
          >
            <div className={styles.avatar} aria-label={getAriaLabel('MainDropdown', 'avatar')}>
              {user && <Avatar online={!user.isAway} info={user.avatarInfo} size={32} />}
            </div>
            <div className={styles.info} aria-label={getAriaLabel('MainDropdown', 'info')}>
              <Typography
                variant={'body-rg-medium'}
                color={'var(--content-primary-primary)'}
                ariaLabel={'name'}
              >
                {user?.name}
              </Typography>
              <Typography
                variant={'body-sm-regular'}
                color={'var(--content-primary-tertiary)'}
                className={styles.role}
                ariaLabel={'role'}
              >
                {user?.role}
              </Typography>
            </div>
          </div>
        </RouterLink>
      </div>
      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'divider')} />
      <div
        className={classNames(styles.block, styles['block--away'])}
        aria-label={getAriaLabel('MainDropdown', 'Block')}
      >
        <div className={styles.blockWrapperHover} onClick={handleAwayStatus}>
          <div className={styles.label}>
            <div className={styles.icon}>
              <Icon icon='alarm' />
            </div>
            <Typography
              variant={'body-md-regular'}
              ariaLabel={'SetAsAway'}
              color='var(--content-primary-primary)'
            >
              Set as away
            </Typography>
          </div>
          <Switch value={user?.isAway} />
        </div>
      </div>

      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'Divider')} />
      <MultiOrganizationsDropdown
        store={multiOrganizationsDropdownStore}
        parentsDropdownId={[dropdownId]}
        placement={'right-start'}
        triggerComponent={(open) => (
          <div
            className={styles.block}
            aria-label={getAriaLabel('MainDropdown', 'ShowOrganizationsDropdown')}
          >
            <div
              className={classNames(styles.blockWrapperHover, {
                [styles.active]: open,
              })}
            >
              <div className={styles.label}>
                <div className={styles.icon}>
                  <Avatar info={activeOrganizationAvatarInfo} size={16} />
                </div>
                <Typography
                  variant={'body-md-regular'}
                  ariaLabel={'ShowOrganizationsDropdown'}
                  color='var(--content-primary-primary)'
                >
                  {activeOrganisationName}
                </Typography>
              </div>
              <div className={styles.iconRight}>
                <Icon icon={'chevronRight'} fontSize={16} />
              </div>
            </div>
          </div>
        )}
      />

      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'Divider')} />
      <Credits />
      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'Divider')} />
      <div>
        {!show_new_design_only && userSettingsStore.hasSalesmsgDesignV2SettingsKeyActive && (
          <div
            className={styles.block}
            onClick={switchToOldVersion}
            aria-label={getAriaLabel('MainDropdown', 'SwitchToOldSalesmsg')}
          >
            <div className={styles.blockWrapperHover}>
              <div className={styles.label}>
                <div className={styles.icon}>
                  <Icon icon='back' />
                </div>
                <Typography
                  variant={'body-md-regular'}
                  ariaLabel={'SwitchToOldSalesmsg'}
                  color='var(--content-primary-primary)'
                >
                  Switch to old Salesmsg
                </Typography>
              </div>
            </div>
          </div>
        )}

        {isDeveloper && !featureFlagsStore.salesmsg_design_v2_new_sidebar_menu && (
          <a
            href='/nova'
            className={classNames(styles.block, styles.link)}
            aria-label={getAriaLabel('MainDropdown', 'Nova')}
          >
            <div className={styles.blockWrapperHover}>
              <div className={styles.label}>
                <div className={styles.icon}>
                  <Icon icon='briefcase' />
                </div>
                <Typography
                  variant={'body-md-regular'}
                  ariaLabel={'Nova'}
                  color='var(--content-primary-primary)'
                >
                  Nova
                </Typography>
              </div>
            </div>
          </a>
        )}

        {featureFlagsStore.salesmsg_design_v2_new_sidebar_menu &&
          ((canManageClients && organizationStore.isAgency) ||
            user?.hasAccessibleOrganizations) && (
            <RouterLink route={`/${clientsPath}`} callBack={onClose}>
              <div
                className={styles.blockWrapperHover}
                aria-label={getAriaLabel('MainDropdown', 'Settings')}
              >
                <div className={styles.label}>
                  <div className={styles.icon}>
                    <Icon icon='building' />
                  </div>
                  <Typography
                    variant={'body-md-regular'}
                    ariaLabel={'Settings'}
                    color='var(--content-primary-primary)'
                  >
                    Clients
                  </Typography>
                </div>
              </div>
            </RouterLink>
          )}

        <RouterLink route={`/${PersonalPath.root}`} callBack={onClose}>
          <div
            className={styles.blockWrapperHover}
            aria-label={getAriaLabel('MainDropdown', 'Settings')}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <Icon icon='settings' />
              </div>
              <Typography
                variant={'body-md-regular'}
                ariaLabel={'Settings'}
                color='var(--content-primary-primary)'
              >
                Settings
              </Typography>
            </div>
          </div>
        </RouterLink>

        <div className={styles.block} aria-label={getAriaLabel('MainDropdown', 'logout')}>
          <div
            className={styles.blockWrapperHover}
            onClick={() => {
              authStore.logout()
            }}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <Icon icon='logOut' />
              </div>
              <Typography
                variant={'body-md-regular'}
                ariaLabel={'LogOut'}
                color='var(--content-primary-primary)'
              >
                Log out
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </DropdownContent>
  )

  const { subscriptionSuggestion } = subscriptionStore
  const isSubscriptionSuggestion = Boolean(subscriptionSuggestion)

  return (
    <Dropdown
      ariaLabel={'MainDropdown'}
      width={256}
      placement={'right-start'}
      triggerComponent={(open) =>
        user && (
          <Tooltip label={'Profile'} {...commonTooltipProps}>
            <div
              className={classNames(styles.button, {
                [styles.active]: open,
                [styles.isSuggestion]: isSubscriptionSuggestion,
              })}
              aria-label={getAriaLabel('MainDropdown', 'Trigger')}
            >
              <div className={styles.button__wrap}>
                <Avatar online={!user.isAway} size={24} info={user.avatarInfo} />
              </div>
            </div>
          </Tooltip>
        )
      }
      customComponent={(onClose, dropdownId) => (
        <Content onClose={onClose} dropdownId={dropdownId} />
      )}
    />
  )
})
