import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Box, BooleanStatus, Button, IIconsVector, Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { IOrganizationStatus, OrganizationStatus } from 'entities/Organization'
import { adminOrganizationDetailStore } from 'pages/admin/pages/organizations/pages/OrganizationDetails/store/adminOrganizationDetailStore'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminOrganizationStore } from 'pages/admin/pages/organizations'

export const GeneralInformation = observer(() => {
  const { generalInfo, organization, stripeInfo } = adminOrganizationDetailStore
  if (!organization) {
    return null
  }
  const { integrations, links } = organization
  const {
    id,
    ownerId,
    name,
    country,
    status,
    totalCredits,
    trialCredits,
    totalContacts,
    totalUsers,
    totalNumbers,
    limits,
    is_contract,
    is_agency,
    is_franchise,
    is_messaging_canada_only,
    is_10_dlc_exempt,
    agency_plan,
    master_plan,
    twilio,
    stripe,
    ip,
  } = generalInfo

  const { loadCustomPrice, getCustomPriceLabelById } = adminOrganizationStore

  useEffect(() => {
    if (is_franchise) {
      loadCustomPrice()
    }
  }, [is_franchise])
  const data: IAdminDetailCardRowProps[] = [
    {
      title: 'Organization id',
      value: id,
    },
    {
      title: 'Owner id',
      value: ownerId,
    },
    {
      title: 'Company name',
      value: name,
    },
    {
      title: 'Country',
      value: country,
    },
    {
      title: 'Organization status',
      value: status,
      renderValue: () => <OrganizationStatus status={status as IOrganizationStatus} />,
    },
    {
      title: 'Total credits',
      value: totalCredits,
      renderValue: () =>
        numberFormat({ value: stripeInfo.status === 'trialing' ? trialCredits : totalCredits }),
    },
    {
      title: 'Total contacts',
      value: totalContacts,
      renderValue: () => numberFormat({ value: Number(totalContacts) }),
    },
    {
      title: 'Total users',
      value: totalUsers,
      renderValue: () => numberFormat({ value: Number(totalUsers) }),
    },
    {
      title: 'Total numbers',
      value: totalNumbers,
    },
    {
      title: 'Limits',
      value: limits,
    },
    {
      title: 'Contract',
      value: is_contract ? 'Yes' : 'No',
      renderValue: () => <BooleanStatus value={is_contract} />,
    },
    {
      title: 'Agency',
      value: is_agency ? 'Yes' : 'No',
      renderValue: () => <BooleanStatus value={is_agency} />,
    },
    {
      title: 'Franchise',
      value: is_franchise ? 'Yes' : 'No',
      renderValue: () => <BooleanStatus value={is_franchise} />,
    },
    {
      title: 'Canada only',
      value: is_messaging_canada_only ? 'Yes' : 'No',
      renderValue: () => <BooleanStatus value={is_messaging_canada_only} />,
    },
    {
      title: '10DLC exempt',
      value: is_10_dlc_exempt ? 'Yes' : 'No',
      renderValue: () => <BooleanStatus value={is_10_dlc_exempt} />,
    },
    is_franchise && {
      title: 'Default plan for clients',
      value: getCustomPriceLabelById(master_plan),
    },
    is_franchise && {
      title: 'Plan for agency billing',
      value: getCustomPriceLabelById(agency_plan),
    },
    {
      title: 'Integrations',
      value: '',
      renderValue: () => (
        <Box display={'flex'} flexWrap={'wrap'} gap={1}>
          {integrations?.map((integration) => (
            <Button
              key={integration.key}
              icon={integration.key as IIconsVector}
              iconSize={14}
              text={integration.label}
              size={'small'}
              typeBtn={'outlined'}
              contained={'tertiary'}
              style={{ pointerEvents: 'none' }}
            />
          ))}
        </Box>
      ),
    },
    {
      title: 'HubSpot',
      value: '',
      renderValue: () =>
        links.hubspot && (
          <Box display={'flex'} flexWrap={'wrap'} gap={1}>
            {links.hubspot.company && (
              <>
                <Typography
                  variant={'body-md-regular'}
                  link
                  textDecoration={'none'}
                  pointer
                  ariaLabel={'View company'}
                >
                  <a href={links.hubspot.company} target={'_blank'} rel='noreferrer'>
                    View company
                  </a>
                </Typography>
                <Typography variant={'body-md-regular'} color={'tertiary'} ariaLabel={'dot'}>
                  •
                </Typography>
              </>
            )}

            {links.hubspot.owner && (
              <Typography
                variant={'body-md-regular'}
                link
                textDecoration={'none'}
                pointer
                ariaLabel={'View owner'}
              >
                <a href={links.hubspot.owner} target={'_blank'} rel='noreferrer'>
                  View owner
                </a>
              </Typography>
            )}
          </Box>
        ),
    },
    {
      title: 'Intercom',
      value: '',
      renderValue: () =>
        links.intercom && (
          <Box display={'flex'} flexWrap={'wrap'} gap={1}>
            {links.intercom.company && (
              <>
                <Typography
                  variant={'body-md-regular'}
                  link
                  textDecoration={'none'}
                  pointer
                  ariaLabel={'View company'}
                >
                  <a href={links.intercom.company} target={'_blank'} rel='noreferrer'>
                    View company
                  </a>
                </Typography>
                <Typography variant={'body-md-regular'} color={'tertiary'} ariaLabel={'dot'}>
                  •
                </Typography>
              </>
            )}

            {links.intercom.owner && (
              <Typography
                variant={'body-md-regular'}
                link
                textDecoration={'none'}
                pointer
                ariaLabel={'View owner'}
              >
                <a href={links.intercom.owner} target={'_blank'} rel='noreferrer'>
                  View owner
                </a>
              </Typography>
            )}
          </Box>
        ),
    },
    {
      title: 'Twilio SID',
      value: twilio,
      renderValue: () => (
        <Typography
          variant={'body-md-regular'}
          link
          textDecoration={'none'}
          pointer
          ariaLabel={'Twilio SID'}
        >
          <a href={twilio} target={'_blank'} rel='noreferrer'>
            {twilio.split('/').pop()}
          </a>
        </Typography>
      ),
    },
    {
      title: 'Stripe',
      value: stripe,
      renderValue: () => (
        <Typography
          variant={'body-md-regular'}
          link
          textDecoration={'none'}
          pointer
          ariaLabel={'Stripe'}
        >
          <a href={stripe} target={'_blank'} rel='noreferrer'>
            {stripe.split('/').pop()}
          </a>
        </Typography>
      ),
    },
    {
      title: 'IP address',
      value: ip,
    },
  ].filter((item) => Boolean(item)) as IAdminDetailCardRowProps[]

  return (
    <AdminDetailCard
      title={'General information'}
      data={data}
      cardStyles={{ gridArea: 'general' }}
    />
  )
})
