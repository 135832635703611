// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BJmYjPHaAXLmCqtR_Klh{padding:12px;font-weight:400;font-size:13px;line-height:20px}.BJmYjPHaAXLmCqtR_Klh.w0ZOnSWDoihsn4rvh677,.BJmYjPHaAXLmCqtR_Klh.G6OV6o3CL7EfGPZHn2st{margin-bottom:4px}.BJmYjPHaAXLmCqtR_Klh.FdzdAHj1C2o040dvP1Us{cursor:auto}.JsUCaUFqxwmn2ILg612I{margin-top:20px;margin-bottom:4px}.AvtLFBovNa3kNMtSYVUx{display:flex;align-items:center;gap:8px}.AvtLFBovNa3kNMtSYVUx .aTPwdsdfgsKsYesZzwIn{height:16px;width:1px;background-color:var(--border-neutral-primary)}.AvtLFBovNa3kNMtSYVUx .AFTadnIFKwniQ_1vBFd5{padding:0;font-size:14px;line-height:20px}", "",{"version":3,"sources":["webpack://./src/shared/ui/PhoneWithLabelInput/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,sFAEE,iBAAA,CAGF,2CACE,WAAA,CAGJ,sBACE,eAAA,CACA,iBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,4CACE,WAAA,CACA,SAAA,CACA,8CAAA,CAGF,4CACE,SAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".textField {\n  padding: 12px;\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 20px;\n\n  &.warning,\n  &.error {\n    margin-bottom: 4px;\n  }\n\n  &.disabled {\n    cursor: auto;\n  }\n}\n.inputLabel {\n  margin-top: 20px;\n  margin-bottom: 4px;\n}\n.leftContentNumber {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  .verticalDivider {\n    height: 16px;\n    width: 1px;\n    background-color: var(--border-neutral-primary);\n  }\n\n  .countryPicker {\n    padding: 0;\n    font-size: 14px;\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "BJmYjPHaAXLmCqtR_Klh",
	"warning": "w0ZOnSWDoihsn4rvh677",
	"error": "G6OV6o3CL7EfGPZHn2st",
	"disabled": "FdzdAHj1C2o040dvP1Us",
	"inputLabel": "JsUCaUFqxwmn2ILg612I",
	"leftContentNumber": "AvtLFBovNa3kNMtSYVUx",
	"verticalDivider": "aTPwdsdfgsKsYesZzwIn",
	"countryPicker": "AFTadnIFKwniQ_1vBFd5"
};
export default ___CSS_LOADER_EXPORT___;
