import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { remindersStore } from 'widgets/Reminders'

export const AlertAddTrialCredits = observer(() => {
  const { trialCreditsStore } = remindersStore

  if (!trialCreditsStore.show) return null
  if (trialCreditsStore.isOrganizationOwner) {
    return (
      <Alert
        item={{
          type: 'warning',
          title: 'Your organization is out of trial credits',
          desc: 'Upgrade now to continue sending messages',
        }}
        action={
          <Button
            text={'Upgrade now'}
            typeBtn={'outlined'}
            size={'small'}
            contained={'secondary'}
            onClick={() =>
              uiStore.changeRoute({
                path: '/settings/billing/overview/upgrade',
                type: 'vue',
              })
            }
          />
        }
      />
    )
  }

  return (
    <Alert
      item={{
        type: 'warning',
        title: 'Your organization is out of trial credits',
        desc: 'Ask your organization owner to upgrade to continue sending messages',
      }}
    />
  )
})
