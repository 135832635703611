import { FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

export type ITextareaProps = {
  placeholder: string
  error?: boolean | null
  value: string
  rows?: number
  onBlur?: (value: string) => void
  onChange: (value: string) => void
  className?: string
}

export const Textarea: FC<ITextareaProps> = ({
  placeholder,
  error,
  value,
  rows = 4,
  onBlur,
  onChange,
  className,
}) => {
  return (
    <textarea
      rows={rows}
      className={classNames(styles.textarea, className, {
        [styles.textareaError]: error,
      })}
      placeholder={placeholder}
      value={value}
      onBlur={({ target }) => onBlur?.(target.value)}
      onChange={({ target }) => onChange(target.value)}
    />
  )
}
