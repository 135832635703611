import { links } from 'shared/constants/links'
import { Alert, Button } from 'shared/ui'
import { IAlert } from 'shared/ui/Alert/types'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { openIntercomNewMessage } from 'entities/Intercom'
import styles from './styles.module.scss'

type IBroadcastErrorAlertProps = {
  isBottomActions?: boolean
  failed_reason?: string | null
}

export const BroadcastErrorAlert = ({
  isBottomActions,
  failed_reason,
}: IBroadcastErrorAlertProps) => {
  const isInvalidLink = failed_reason === 'invalid_link'

  let info: Partial<IAlert> = {
    title: 'An error with your contacts or CRM connection was encountered',
    desc: 'Please validate your contacts and try again.',
  }
  if (failed_reason === 'Selected Hubspot list does not exist') {
    info = {
      title: 'Selected HubSpot list does not exist',
    }
  }

  if (isInvalidLink) {
    info = {
      title: 'Suspicious content detected',
      desc: 'Your link has been flagged as spam. If you believe this is an error, contact support for assistance.',
    }
  }

  const handleOpenIntercomWidget = () => {
    openIntercomNewMessage()

    modalStore.closeAllModals()
  }

  const action = () => {
    if (isInvalidLink) {
      return (
        <Button
          text='Support'
          typeBtn='outlined'
          size='small'
          contained='secondary'
          className={styles.link}
          onClick={() => handleOpenIntercomWidget()}
        />
      )
    }

    return (
      <Button
        tag='a'
        href={links.massMessagingBestPracticesGuidelines}
        target={'_blank'}
        rel='noreferrer'
        text='Learn more'
        typeBtn='outlined'
        size='small'
        contained='secondary'
        className={styles.link}
      />
    )
  }

  return (
    <Alert
      item={{
        type: 'alert',
        ...info,
      }}
      rightAction={!isBottomActions && action()}
      actions={isBottomActions && action()}
    />
  )
}
