// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TCF4yQUrkC4wxc0O4MxC{padding:8px;border-bottom:1px soli var(--border-neutral-default);display:flex;gap:4px;align-items:center}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationHeaderSearch/ui/MobileSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,oDAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".wrap {\n  padding: 8px;\n  border-bottom: 1px soli var(--border-neutral-default);\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "TCF4yQUrkC4wxc0O4MxC"
};
export default ___CSS_LOADER_EXPORT___;
