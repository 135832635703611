// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d9fVCgYgHC4ja7LQwlsB{color:var(--content-primary-primary)}.PCNvi95jOaMMWRsso7jf{display:flex;flex-direction:column;align-items:center;gap:16px;margin-bottom:24px}.teT5KBPz6NfaekhafA9r{color:var(--content-primary-primary);font-size:40px;line-height:120%}.WmH09xRUU94W6qp9KzXN{text-align:center;color:var(--content-primary-primary);font-size:40px;font-style:normal;font-weight:500;line-height:133.3%;letter-spacing:-0.24px;margin-bottom:40px}.T_PpCBrOdTrnLeyAT78h>div{padding:11px 12px}.i9MYO1_GTsNTIJpU5r4s{display:flex;width:100%}.vYe1GMRz1fx0xSn45uLx{font-size:13px;font-style:normal;font-weight:500;line-height:normal;padding:2px 0}", "",{"version":3,"sources":["webpack://./src/entities/Phone/ui/SuccessModalContent/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,oCAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA,CAGF,sBACE,oCAAA,CACA,cAAA,CACA,gBAAA,CAGF,sBACE,iBAAA,CACA,oCAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAIA,0BACE,iBAAA,CAIJ,sBACE,YAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":[".wrap {\n}\n\n.title {\n  color: var(--content-primary-primary);\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 24px;\n}\n\n.icon {\n  color: var(--content-primary-primary);\n  font-size: 40px;\n  line-height: 120%;\n}\n\n.phone {\n  text-align: center;\n  color: var(--content-primary-primary);\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 133.3%;\n  letter-spacing: -0.24px;\n  margin-bottom: 40px;\n}\n\n.alert {\n  & > div {\n    padding: 11px 12px;\n  }\n}\n\n.actionsWrap {\n  display: flex;\n  width: 100%;\n}\n\n.button {\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  padding: 2px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "d9fVCgYgHC4ja7LQwlsB",
	"content": "PCNvi95jOaMMWRsso7jf",
	"icon": "teT5KBPz6NfaekhafA9r",
	"phone": "WmH09xRUU94W6qp9KzXN",
	"alert": "T_PpCBrOdTrnLeyAT78h",
	"actionsWrap": "i9MYO1_GTsNTIJpU5r4s",
	"button": "vYe1GMRz1fx0xSn45uLx"
};
export default ___CSS_LOADER_EXPORT___;
