import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { inboxesStore } from 'entities/Inbox/store/inboxes'
import type { IResponseEventInboxCalling } from 'entities/Inbox/api/types'

const InboxCalling = (data: IResponseEventInboxCalling) => {
  inboxesStore.updateInboxCalling(data)
}

export const useEventInboxCalling = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.InboxCalling, InboxCalling)
    }
  }, [websocket.isConnected])
}
