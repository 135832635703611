import { type MessageData } from 'entities/Message'
import { type SavedRepliesStore } from 'widgets/SavedReplies'
import { type MessageFieldStore } from 'widgets/MessageField/store/messageFieldStore'
import { type IInputAction } from 'widgets/MessageField/store/types'

export const makeSavedReplyInputAction = (
  store: MessageFieldStore,
  savedRepliesStore: SavedRepliesStore
): IInputAction => {
  const onAddSavedReplies = (data: MessageData) =>
    store.setMessageData({
      ...data,
      isReplace: false,
      replaceNewRow: false,
    })

  const action = () => savedRepliesStore.openModal({ onAddSavedReplies })

  return { value: '/', action }
}
