import React from 'react'
import { getAriaLabel } from 'shared/lib/ariaLabel'
import { Icon } from 'shared/ui/Icon'
import styles from './styles.module.scss'

export const Logo = () => {
  return (
    <div className={styles.wrap} aria-label={getAriaLabel('LogoSidebar')}>
      <a href='/' aria-label={getAriaLabel('LogoSidebar', 'link')}>
        <Icon icon={'logoSmallBlue'} fontSize={24} className={styles.icon} />
      </a>
    </div>
  )
}
