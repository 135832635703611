import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { QuestionnaireStore } from 'pages/settings/pages/compliance/pages/questionnaire/store/questionnaireStore'

const QuestionnaireStoreContext = createContext<QuestionnaireStore | null>(null)

export const useQuestionnaireContext = () => {
  const context = useContext(QuestionnaireStoreContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with QuestionnaireStore')

  return context
}

export const QuestionnaireProvider = ({
  complianceLayerStore,
  children,
}: {
  complianceLayerStore: ComplianceLayerStore
  children: ReactNode
}) => {
  const store = useMemo(() => new QuestionnaireStore(complianceLayerStore), [])

  useEffect(() => store.dispose, [])

  return (
    <QuestionnaireStoreContext.Provider value={store}>
      {children}
    </QuestionnaireStoreContext.Provider>
  )
}
