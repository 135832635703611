import { FC } from 'react'
import { Box, Typography } from 'shared/ui'
import type { IResponseEinItem } from 'entities/Compliance/api/complianceType'

type IEinItemProps = {
  einItem: IResponseEinItem | null
}

export const EinItem: FC<IEinItemProps> = ({ einItem }) => {
  if (!einItem) return null

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
      <Typography
        ariaLabel='EinItem_line'
        variant='body-md-regular'
        color='var(--content-primary-primary)'
      >
        {einItem.name}
      </Typography>
      <Typography
        ariaLabel='EinItem_line'
        variant='body-xs-regular'
        color='var(--content-primary-tertiary)'
      >
        {einItem.street1}
      </Typography>
      <Typography
        ariaLabel='EinItem_line'
        variant='body-xs-regular'
        color='var(--content-primary-tertiary)'
      >
        EIN: {einItem.ein}
      </Typography>
    </Box>
  )
}
