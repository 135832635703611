import classNames from 'classnames'
import { Dropdown, type IDropdownItem } from 'shared/ui'
import { makeTextFieldIcon, TextFieldPlaceholder } from 'shared/ui/TextField'
import { DropdownNumbersContent } from './DropdownNumbersContent'
import { SelectNumbersTrigger } from './SelectNumbersTrigger'
import type { IMultiselectedItem, INumbersGroup } from './type'
import styles from './styles.module.scss'

type ISelectNumbersTriggerProps = {
  multiSelectedKeys: number[]
  isLoading: boolean
  multiSelectedValues: IMultiselectedItem[]
  search: string
  setSearch: (value: string) => void
  numbersGroups: INumbersGroup[]
  setMultipleItem: (item: IDropdownItem) => void
  handleMultiSelect: () => void
  isDeselectState: boolean
  handleMultiDeselect: () => void
  removeMultiSelectedItem: (item: IMultiselectedItem) => void
}
export const SelectNumbers = ({
  multiSelectedKeys,
  isLoading,
  multiSelectedValues,
  search,
  setSearch,
  numbersGroups,
  setMultipleItem,
  handleMultiSelect,
  isDeselectState,
  handleMultiDeselect,
  removeMultiSelectedItem,
}: ISelectNumbersTriggerProps) => {
  return (
    <Dropdown
      ariaLabel={'SelectMultiListNumbers'}
      width={500}
      triggerComponent={() => (
        <TextFieldPlaceholder
          multiline
          variant='stroke'
          size='medium'
          rightActions={[makeTextFieldIcon({ icon: 'chevronDown' })]}
          className={classNames(styles.selectLabel, {
            [styles.multiSelectPlaceholder]: multiSelectedKeys.length,
          })}
          placeholder={
            <SelectNumbersTrigger isLoading={isLoading} multiSelectedValues={multiSelectedValues} />
          }
        />
      )}
      customComponent={() => (
        <DropdownNumbersContent
          search={search}
          setSearch={setSearch}
          numbersGroups={numbersGroups}
          isLoading={isLoading}
          setMultipleItem={setMultipleItem}
          handleMultiSelect={handleMultiSelect}
          isDeselectState={isDeselectState}
          multiSelectedValues={multiSelectedValues}
          handleMultiDeselect={handleMultiDeselect}
          removeMultiSelectedItem={removeMultiSelectedItem}
        />
      )}
      placement={'bottom-start'}
      margin={0}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              offset: ({ reference, popper }) => {
                const windowHeight = document.documentElement.clientHeight
                const availableHeight = windowHeight - reference.y
                const yOffset = availableHeight - popper.height

                if (yOffset > 1) {
                  return [0, -reference.height]
                }

                return [0, yOffset <= -35 ? -100 : -reference.height * 2]
              },
            },
          },
        ],
      }}
    />
  )
}
