import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponseKnowledgeBaseDocument } from 'entities/KnowledgeBase'

type IHandlerEvent = (data: IResponseKnowledgeBaseDocument) => void

export const EventKnowledgeBaseDocumentUpdated = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.KnowledgeBaseDocumentUpdated, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.KnowledgeBaseDocumentUpdated, handler),
}

export const useEventKnowledgeBaseDocumentUpdated = (handler: IHandlerEvent) =>
  useEffect(() => {
    EventKnowledgeBaseDocumentUpdated.subscribe(handler)

    return () => {
      EventKnowledgeBaseDocumentUpdated.unsubscribe(handler)
    }
  }, [])
