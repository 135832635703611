import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseCallQueueParticipant } from 'entities/Call'
import { callPopUpGlobalStore } from 'widgets/CallPopUp/store/callPopUpGlobalStore'

const CallQueueParticipantLeave = {
  handle: (data: IResponseCallQueueParticipant) => {
    callPopUpGlobalStore.deleteItem(data)
  },
}

export const EventCallQueueParticipantLeave = {
  subscribe: () =>
    websocket.on(WebsocketEvent.CallQueueParticipantLeave, CallQueueParticipantLeave.handle),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.CallQueueParticipantLeave, CallQueueParticipantLeave.handle),
}
