import { FC } from 'react'
import classNames from 'classnames'
import { type ITypographyProps } from 'shared/ui'
import { Typography } from 'shared/ui/Typography'
import { Icon } from 'shared/ui/Icon'
import { type IStatusProps } from 'shared/ui/Status/type'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export const Status: FC<IStatusProps> = ({
  title,
  intent,
  emphasis,
  icon,
  size,
  className,
  onlyIcon,
  isActive,
  value,
  rightIcon = false,
  customIcon,
  iconColor,
  rightIconSize = 14,
  onClick,
}) => {
  const typographyVariant = (): ITypographyProps['variant'] => {
    if (size === 'small') return 'body-xs-medium'
    if (size === 'label') return 'body-xs-medium'
    if (size === 'medium') return 'body-md-regular'

    return 'body-sm-medium'
  }

  const hasIcon = icon || customIcon
  const iconContent = hasIcon ? (
    <Icon icon={icon} customIcon={customIcon} fontSize={rightIconSize} color={iconColor} />
  ) : null

  return (
    <div
      className={classNames(
        styles.wrap,
        styles[`intent--${intent}`],
        styles[`emphasis--${emphasis}`],
        styles[`size--${size}`],
        {
          [styles['not-icon']]: !hasIcon,
          [styles['only-icon']]: !!onlyIcon,
          [styles.active]: isActive,
          [styles.interactive]: onClick,
          [styles.withRightIcon]: rightIcon,
        },
        className
      )}
      onClick={() => onClick?.(value)}
      aria-label={getAriaLabel('Status', title)}
    >
      {!rightIcon && iconContent}
      {!onlyIcon && (
        <Typography
          variant={typographyVariant()}
          className={styles.title}
          pointer
          ariaLabel={'title'}
        >
          {title}
        </Typography>
      )}
      {rightIcon && iconContent}
    </div>
  )
}
