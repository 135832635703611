import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import { RegisterSection } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import { SummaryBusinessProfile } from 'pages/settings/pages/compliance/ui/Summary/SummaryBusinessProfile'

export const BusinessProfilePage = observer(() => {
  const { businessProfilePageStore } = useCompliancePageContext()
  const { bpRegisterSectionProps, businessProfile } = businessProfilePageStore

  return (
    <>
      {businessProfile?.isStatusEmpty && <RegisterSection {...bpRegisterSectionProps} />}
      {!businessProfile?.isStatusEmpty && (
        <div>
          <Typography
            tag='div'
            marginBottom={'17px'}
            variant='body-lg-medium'
            color='var(--content-primary-primary)'
            ariaLabel='AfterRegisterSection_header'
          >
            Business profile
          </Typography>
          <SummaryBusinessProfile />
        </div>
      )}
    </>
  )
})
