import { makeAutoObservable, runInAction } from 'mobx'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'

export class TrialProgressStore {
  private _loading = true

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([
      usersStore.initPromise,
      organizationStore.initPromise,
      subscriptionStore.initPromise,
    ])

    runInAction(() => {
      this._loading = false
    })
  }

  get step() {
    return organizationStore.trialEndsAtDaysNumber
  }

  get percent() {
    const total = organizationStore.trialDaysMaxTotal
    const step = this.step

    return (step / total) * 100
  }

  get hide() {
    return this._loading || !usersStore.user?.isAdminOrOwnerRole || !subscriptionStore.isTrial
  }
}
