import { makeAutoObservable } from 'mobx'
import { integrationsStore } from 'entities/Integrations'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'

import { QuickLinkOperationStore } from './QuickLinkOperationStore'
import { QuickLinkListStore } from './QuickLinkListStore'

export class QuickLinksStore {
  private _operationStore = new QuickLinkOperationStore()
  private _listStore = new QuickLinkListStore()

  get operationStore() {
    return this._operationStore
  }

  get listStore() {
    return this._listStore
  }

  constructor() {
    makeAutoObservable(this)
  }

  init = () =>
    Promise.all([
      integrationsStore.fetchIntegrations(),
      allMergeFieldsStore.loadAllFields(),
      this.listStore.init(),
    ])

  dispose = () => {
    this._operationStore.dispose()
    this._listStore.dispose()
  }
}
