import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Icon, NavLink, NavLinkVue, Tooltip } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { configStore } from 'entities/Config'
import { AdminRoutes } from 'entities/ExportRouters'
import { usersStore } from 'entities/Users'
import { SidebarStore } from 'widgets/Sidebar/store/sidebarStore'
import { ContactDetailsContentOverlay } from 'widgets/ContactDetailsContentOverlay/ContactDetailsContentOverlay'
import { useSidebarContext } from 'widgets/Sidebar/context/sidebarContext'
import { Logo } from 'widgets/Sidebar/ui/Logo/Logo'
import { usePathAIAutomation } from 'widgets/Menu/ui/MenuAIAutomation'
import { usePathOutbound } from 'widgets/Menu/ui/MenuOutbound'
import { SettingsDropdown } from './SettingsDropdown/SettingsDropdown'
import { ProductUpdatesDropdown } from './ProductUpdatesDropdown/ProductUpdatesDropdown'
import { MainDropdown } from './MainDropdown/MainDropdown'
import { commonTooltipProps, type IMenuItemSidebar } from '../constants/menuItems'
import styles from './styles.module.scss'

type IMenuItemProps = IMenuItemSidebar & { sidebarStore: SidebarStore }

const MenuItem: FC<IMenuItemProps> = observer(
  ({ icon, route, label, itemKey, sidebarStore, activePaths }) => {
    const { pathname } = useLocation()
    const active = activePaths.some((item) => {
      return new RegExp(`^\/?${item}`).test(pathname)
    })

    const cacheAIAutomationPath = usePathAIAutomation(usersStore.user_owner_id)
    const cacheOutboundPath = usePathOutbound(usersStore.user_owner_id)
    const navRoute = useMemo(() => {
      if (itemKey === 'AI_automation') return cacheAIAutomationPath
      if (itemKey === 'outbound') return cacheOutboundPath

      return route
    }, [route, pathname, cacheOutboundPath, cacheAIAutomationPath])

    if (window.DesignV2API && !window.DesignV2API?.handleCheckPage(navRoute)) {
      return (
        <Tooltip label={label} {...commonTooltipProps}>
          <NavLinkVue to={`/${navRoute}`} originalPath={route}>
            <Icon
              icon={icon}
              count={itemKey === 'conversation' ? sidebarStore.unreadMessagesCount : undefined}
              fontSize={20}
            />
          </NavLinkVue>
        </Tooltip>
      )
    }

    return (
      <Tooltip label={label} {...commonTooltipProps}>
        <NavLink
          to={`/${navRoute}`}
          className={() => (active ? `${styles.button} ${styles.active}` : styles.button)}
        >
          <Icon
            icon={icon}
            count={itemKey === 'conversation' ? sidebarStore.unreadMessagesCount : undefined}
            fontSize={20}
          />
        </NavLink>
      </Tooltip>
    )
  }
)

export const SidebarV2 = observer(() => {
  const sidebarStore = useSidebarContext()

  return (
    <div className={styles.wrap} aria-label={getAriaLabel('Sidebar')}>
      <ContactDetailsContentOverlay />
      <div className={styles.wrapContent} aria-label={getAriaLabel('Sidebar', 'Content')}>
        <div className={styles.box} aria-label={getAriaLabel('Sidebar', 'BoxTop')}>
          <Logo />

          {!sidebarStore.loading && (
            <div
              className={classNames(styles.buttons)}
              aria-label={getAriaLabel('Sidebar', 'buttons')}
            >
              {sidebarStore.menuItems
                .filter(({ isShow }) => (isShow ? isShow() : true))
                .map((item) => (
                  <MenuItem key={item.itemKey} {...item} sidebarStore={sidebarStore} />
                ))}
            </div>
          )}
        </div>

        <div className={styles.box} aria-label={getAriaLabel('Sidebar', 'BoxBottom')}>
          {!sidebarStore.loading && (
            <>
              {configStore.developer && (
                <MenuItem
                  itemKey={'admin'}
                  route={AdminRoutes.admin}
                  label={'Admin'}
                  icon={'superAdmin'}
                  sidebarStore={sidebarStore}
                  activePaths={[AdminRoutes.admin]}
                />
              )}
              <ProductUpdatesDropdown />
              <SettingsDropdown />
              <MainDropdown sidebarStore={sidebarStore} />
            </>
          )}
        </div>
      </div>
    </div>
  )
})
