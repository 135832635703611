// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wPmhPmo9BOIYrbq88L2T{display:flex;position:relative;height:100%}.wPmhPmo9BOIYrbq88L2T .cZAmk507CFP8_3dxaePE{display:none}.wPmhPmo9BOIYrbq88L2T .c3GlwuHyeTI2Addf6A6s{position:relative;margin:0 auto;display:flex;flex-direction:column;align-items:stretch;width:100%;min-width:0}.wPmhPmo9BOIYrbq88L2T .c3GlwuHyeTI2Addf6A6s .uOZ0FtTgau6pIQ6vfzpz{position:absolute;top:24px;left:20px}.wPmhPmo9BOIYrbq88L2T .c3GlwuHyeTI2Addf6A6s .jHlWOKBe_EDX5J03W3nH{display:flex;flex-grow:1;width:100%}.zeMZsaVXD6RiEQeezzOp{position:absolute;z-index:200;height:100%}.epIXLZQt7s0weW3sHoFB{z-index:100;position:absolute;top:0;bottom:0;right:0;left:0;background-color:var(--black-30)}", "",{"version":3,"sources":["webpack://./src/shared/layout/PageLayout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,WAAA,CAEA,4CACE,YAAA,CAGF,4CACE,iBAAA,CACA,aAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,UAAA,CACA,WAAA,CACA,kEACE,iBAAA,CACA,QAAA,CACA,SAAA,CAGF,kEACE,YAAA,CACA,WAAA,CACA,UAAA,CAKN,sBACE,iBAAA,CACA,WAAA,CACA,WAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,OAAA,CACA,MAAA,CACA,gCAAA","sourcesContent":[".wrap {\n  display: flex;\n  position: relative;\n  height: 100%;\n\n  .hiddenLeftContent {\n    display: none;\n  }\n\n  .contentWrapper {\n    position: relative;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    width: 100%;\n    min-width: 0;\n    .collapseIcon {\n      position: absolute;\n      top: 24px;\n      left: 20px;\n    }\n\n    .content {\n      display: flex;\n      flex-grow: 1;\n      width: 100%;\n    }\n  }\n}\n\n.mobile {\n  position: absolute;\n  z-index: 200;\n  height: 100%;\n}\n\n.overlay {\n  z-index: 100;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  background-color: var(--black-30);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wPmhPmo9BOIYrbq88L2T",
	"hiddenLeftContent": "cZAmk507CFP8_3dxaePE",
	"contentWrapper": "c3GlwuHyeTI2Addf6A6s",
	"collapseIcon": "uOZ0FtTgau6pIQ6vfzpz",
	"content": "jHlWOKBe_EDX5J03W3nH",
	"mobile": "zeMZsaVXD6RiEQeezzOp",
	"overlay": "epIXLZQt7s0weW3sHoFB"
};
export default ___CSS_LOADER_EXPORT___;
