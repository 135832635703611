import { RefObject, useEffect, useMemo } from 'react'

export const useMergeFieldsCount = <Node extends HTMLElement>(
  ref: RefObject<Node>,
  setCount: (count: number) => void
) => {
  const observer = useMemo(
    () =>
      new MutationObserver(() => {
        const count = ref.current?.querySelectorAll('.mergeField-wrap').length ?? 0

        setCount(count)
      }),
    [setCount]
  )

  useEffect(() => {
    if (ref.current) observer.observe(ref.current, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
      setCount(0)
    }
  }, [observer, setCount])
}
