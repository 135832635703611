import { useMemo } from 'react'
import { CampaignView } from 'pages/campaigns/ui/CampaignView'
import { CampaignViewStore } from 'pages/campaigns/ui/CampaignView/store/CampaignViewStore'
import { useCampaignsPageContext } from 'pages/campaigns/context/context'

export const EditCampaign = () => {
  const store = useMemo(() => new CampaignViewStore(), [])
  const { pageLayoutStore } = useCampaignsPageContext()

  return <CampaignView store={store} pageLayoutStore={pageLayoutStore} />
}
