import { FC } from 'react'
import { type SegmentModalStore } from '../store/segmentModalStore'
import { ModalActions } from './ModalActions'

type INewSegmentActionsProps = {
  segmentModalStore: SegmentModalStore
}

export const NewSegmentActions: FC<INewSegmentActionsProps> = ({ segmentModalStore }) => {
  const { contactsTableStore, contactsFilterStore } = segmentModalStore

  return (
    <ModalActions
      successText='Create'
      successAction={() => {
        if (contactsTableStore) {
          segmentModalStore.createItem(contactsTableStore.noEmptyFilters)
        }
        contactsFilterStore.onOpen(false)
        contactsFilterStore.segmentBox.setHasChanges(false)
      }}
      segmentModalStore={segmentModalStore}
    />
  )
}
