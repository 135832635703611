import { makeAutoObservable } from 'mobx'

export class StepsStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _stepLoading = false

  get stepLoading() {
    return this._stepLoading
  }

  setStepLoading = (loading: boolean) => {
    this._stepLoading = loading
  }
}
