// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZVTzQJDZkkbPrdbvzzaW{display:flex;flex-direction:column;height:100%;overflow:auto}.aFjdfZNDV67R15GQeepA{display:flex;padding:8px 12px;flex-direction:column;align-items:flex-end;gap:8px;border-radius:6px;background:var(--background-brand-subtle-primary);margin-top:32px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/ExampleModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,oBAAA,CACA,OAAA,CACA,iBAAA,CACA,iDAAA,CACA,eAAA","sourcesContent":[".useCaseExampleModal {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: auto;\n}\n\n.useCaseExampleModalText {\n  display: flex;\n  padding: 8px 12px;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 8px;\n  border-radius: 6px;\n  background: var(--background-brand-subtle-primary);\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"useCaseExampleModal": "ZVTzQJDZkkbPrdbvzzaW",
	"useCaseExampleModalText": "aFjdfZNDV67R15GQeepA"
};
export default ___CSS_LOADER_EXPORT___;
