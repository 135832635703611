import { notification } from 'shared/assets/sounds'
import { inboxesStore } from 'entities/Inbox'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessagePopper } from 'entities/Message/api/types'
import { usersStore } from 'entities/Users'

const notificationAudio = notification.getAudio('notification')

const MessagePopper = {
  handle: (data: IResponseEventMessagePopper) => {
    const inbox = inboxesStore.getItem(data.inbox_id)
    const { isAwayStatus } = usersStore

    if (inbox && !inbox.isMuted && !isAwayStatus) {
      notificationAudio.play().catch(console.error)
    }
  },
}

export const EventMessagePopper = {
  subscribe: () => websocket.on(WebsocketEvent.MessagePopper, MessagePopper.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.MessagePopper, MessagePopper.handle),
}
