import { makeAutoObservable } from 'mobx'
import { EnumDropdownItemVariant, type IAvatarInfo, type IDropdownItem, showToast } from 'shared/ui'
import { organizationStore, multiOrganizationStore } from 'entities/Organization'
import { AuthApi } from 'entities/Auth/api'

export class MultiOrganizationsDropdownStore {
  private _searchValue = ''

  constructor() {
    makeAutoObservable(this)
  }

  get searchValue() {
    return this._searchValue
  }

  setSearchValue = (key: string) => {
    this._searchValue = key
  }

  filterItems = (items: IDropdownItem[]): IDropdownItem[] => {
    const key = this._searchValue.toLocaleLowerCase().replaceAll(/\s/g, '')
    const result = items?.filter((item) =>
      String(item.label).toLocaleLowerCase().replaceAll(/\s/g, '').includes(key)
    )

    if (result?.length) {
      return result
    }

    return []
  }

  changeActiveOrganization = async (item: IDropdownItem, onClose: () => void) => {
    try {
      const params = {
        organization_id: Number(item.id),
      }

      const { data } = await AuthApi.switch(params)

      if (data.switched) {
        onClose()
        window.location.href = '/'
      }

      if (data.error) {
        showToast({
          type: 'error',
          title: data.error,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  get items() {
    const data = multiOrganizationStore.multiOrganizations.map((organization) => {
      const itemInfo: IDropdownItem = {
        id: `${organization.id}`,
        label: organization.name,
        avatarProps: {
          info: {
            image: organization.photo_url || '',
            color: '',
            firstName: organization.name,
          },
          size: 16,
          iconProps: {
            fontSize: 12,
          },
        },
        activeValue: this.activeOrganisation?.id === organization.id,
        variant: EnumDropdownItemVariant.CheckedRight,
      }

      return itemInfo
    })

    if (this._searchValue) {
      return this.filterItems(data)
    }

    return data
  }

  get hasMultiOrganisations() {
    return Boolean(multiOrganizationStore.multiOrganizations?.length)
  }

  get multiOrganisationsCount() {
    return multiOrganizationStore.multiOrganizations?.length || 0
  }

  get activeOrganisation() {
    return multiOrganizationStore.multiOrganizations?.filter(
      (item) => item.id === organizationStore.id
    )[0]
  }

  get activeOrganisationName() {
    return this.activeOrganisation?.name || ''
  }

  get activeOrganizationAvatarInfo(): IAvatarInfo {
    return {
      image: this.activeOrganisation?.photo_url || '',
      color: '',
      firstName: this.activeOrganisationName,
    }
  }
}
