import classNames from 'classnames'
import { useState, FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { Icon } from 'shared/ui/Icon/Icon'
import { Dropdown, type IDropdownItem, type IDropdownProps } from 'shared/ui/Dropdown'
import { Badge, type IIconsVector, Tooltip, Typography } from 'shared/ui'
import styles from '../styles.module.scss'

export type IMenuItemDefaultProps<T = string | number> = {
  key: T
  type: 'default'
  id?: string
  active?: boolean
  className?: string
  title: string
  items?: IDropdownItem[]
  icon: IIconsVector
  iconColor?: string
  dropdownProps?: IDropdownProps
  isOpenDropdown?: boolean
  rightIcon?: boolean
  variantActiveState?: 'default' | 'blue'
  count?: number
  onClick?: () => void // Change to required
}

// https://www.figma.com/file/cjuBbR3ICQdDt1lZ4SFCy1/00-Design-System-(MTP-1.5)?type=design&node-id=3016%3A4258&mode=dev
export const MenuItemDefault: FC<IMenuItemDefaultProps> = ({
  active = false,
  isOpenDropdown,
  className = '',
  title,
  items,
  icon,
  iconColor,
  dropdownProps = {
    ariaLabel: 'MenuItemDefault',
  },
  rightIcon = false,
  variantActiveState = 'default',
  count,
  onClick,
}) => {
  const itemWrapClasses = classNames(
    styles.itemWrap,
    { [styles.active]: active, [styles.activeBlue]: variantActiveState === 'blue' && active },
    className
  )
  const dropdownClasses = classNames(styles.dropdown, dropdownProps.className)
  const [hover, setHover] = useState(false)
  const [hoverDropdown, setHoverDropdown] = useState(false)

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
  })

  const isHideTitleTooltip = inView || isOpenDropdown || hoverDropdown
  const hasCount = Boolean(count)

  return (
    <Tooltip
      label={title}
      fullWidth
      placement={'right'}
      visibility={isHideTitleTooltip ? 'hidden' : 'visible'}
    >
      <button
        className={itemWrapClasses}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {!rightIcon && <Icon icon={icon} color={iconColor} />}
        <div className={styles.titleWrap}>
          <Typography
            variant={hasCount ? 'body-md-semibold' : 'body-md-regular'}
            ellipsis
            ariaLabel={'title'}
          >
            {title}
          </Typography>
          <div className={styles.titleObserver} ref={ref}>
            <Typography
              variant={hasCount ? 'body-md-semibold' : 'body-md-regular'}
              ariaLabel={'titleObserver'}
            >
              {title}
            </Typography>
          </div>
        </div>

        {rightIcon && <Icon icon={icon} color={iconColor} />}

        {hasCount && (
          <div className={styles.boxBadge}>
            <Badge content={count} />
          </div>
        )}

        {(hover || isOpenDropdown) && items && (
          <Dropdown
            items={items}
            onToggleHover={setHoverDropdown}
            {...dropdownProps}
            className={dropdownClasses}
          />
        )}
      </button>
    </Tooltip>
  )
}
