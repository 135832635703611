import { observer } from 'mobx-react-lite'
import React from 'react'
import classNames from 'classnames'
import { Icon, Tooltip, Typography } from 'shared/ui'

import { CAMPAIGN_NAME_LIMIT } from 'shared/constants/limits'
import { makeTextFieldElement } from 'shared/ui/TextField'
import { NameInput } from 'features/createOrEdit/NameInput'
import { useCampaignMessageContext } from 'pages/campaigns/ui/CampaignMessage'
import styles from './styles.module.scss'

type ITitleProps = {
  contentElement: HTMLDivElement | null
}

export const Title = observer(({ contentElement }: ITitleProps) => {
  const { step, isCollapse, nameInputStore, error } = useCampaignMessageContext()

  const counter = (
    <div className={styles.counter}>
      <Typography ariaLabel={'counter'} variant={'body-md-medium'}>
        {step}
      </Typography>
    </div>
  )

  const placeholder = 'Message name'
  const name = nameInputStore.name

  const content = (
    <div className={classNames(styles.wrap, { [styles.collapse]: isCollapse })}>
      <div className={styles.content}>
        {isCollapse ? (
          <>
            {counter}
            <Typography
              ariaLabel={'messageName'}
              variant={'body-lg-medium'}
              ellipsis
              color={name ? 'var(--content-primary-primary' : 'var(--content-neutral-primary'}
            >
              {name || placeholder}
            </Typography>
            {error && (
              <Icon
                icon={'alertTriangle'}
                fontSize={16}
                color={'var(--content-negative-primary)'}
              />
            )}
          </>
        ) : (
          <NameInput
            store={nameInputStore}
            placeholder={placeholder}
            limit={CAMPAIGN_NAME_LIMIT}
            size={'lg-medium'}
            className={styles.wrap}
            leftActions={[
              makeTextFieldElement({
                element: counter,
              }),
            ]}
          />
        )}
      </div>
    </div>
  )

  if (isCollapse) {
    return (
      <Tooltip
        label={error}
        color={'red'}
        placement={'right'}
        fullWidth
        PopperProps={{
          anchorEl: contentElement,
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom'],
              },
            },
          ],
        }}
      >
        {content}
      </Tooltip>
    )
  }

  return content
})
