import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { SpinnerLoader } from 'shared/ui'
import { useComplianceLayerContext } from 'pages/settings/pages/compliance/context'
import { StepsCommonLayout } from 'pages/settings/pages/compliance/ui/StepsCommonLayout/StepsCommonLayout'
import {
  BusinessProfileStepsProvider,
  useBusinessProfileStepsContext,
} from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { useGetStep } from 'pages/settings/pages/compliance/hooks/useGetStep'
import { useProgressInfoTitle } from 'pages/settings/pages/compliance/pages/businessProfile/hooks/useProgressInfoTitle'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile/route/type'

const BusinessProfileLayoutContent = observer(() => {
  const {
    complianceLayerStore,
    setDataFromBusinessProfileRequest,
    setErrors,
    decreaseStep,
    closeSetting,
    getSettingsStep,
  } = useBusinessProfileStepsContext()
  const { businessProfile, loading, organizationBusinessProfile } = complianceLayerStore

  useEffect(() => {
    organizationBusinessProfile && setDataFromBusinessProfileRequest(organizationBusinessProfile)
  }, [])

  const settingsStep = useGetStep<BusinessProfileRoutes>(getSettingsStep)

  useEffect(() => {
    if (businessProfile?.twilio_service_errors) {
      setErrors(businessProfile?.twilio_service_errors)
    }
  }, [businessProfile?.twilio_service_errors])

  if (loading) {
    return <SpinnerLoader />
  }

  return (
    <StepsCommonLayout
      progressTitle={useProgressInfoTitle()}
      totalSteps={5}
      title='Business profile'
      settingsStep={settingsStep}
      closeSetting={closeSetting}
      decreaseStep={(lastPathSegment) => decreaseStep(lastPathSegment as BusinessProfileRoutes)}
    />
  )
})

export const BusinessProfileStepsLayout = () => {
  const complianceLayerStore = useComplianceLayerContext()

  return (
    <BusinessProfileStepsProvider complianceLayerStore={complianceLayerStore}>
      <BusinessProfileLayoutContent />
    </BusinessProfileStepsProvider>
  )
}
