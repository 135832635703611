import { useMemo, useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { TrialAlertTenLimitConversationsStore } from 'entities/Subscription/ui/TrialTenLimitConversationsAlert/store/trialTenLimitConversationsAlertStore'

type ITrialTenLimitConversationsAlertProps = {
  onCloseTrialAlert: () => void
  totalAmount: number
  onCloseModal?: () => void
  marginBottom?: number
}

export const TrialTenLimitConversationsAlert: FC<ITrialTenLimitConversationsAlertProps> = observer(
  ({ onCloseTrialAlert, marginBottom, onCloseModal, totalAmount }) => {
    const store = useMemo(
      () =>
        new TrialAlertTenLimitConversationsStore({
          totalAmount: totalAmount,
          onCloseTrialAlert: onCloseTrialAlert,
          marginBottom: marginBottom,
          onCloseModal: onCloseModal,
        }),
      [totalAmount]
    )

    useEffect(() => {
      store.init()
    }, [])

    if (store.hide) return null

    return <Alert {...store.configAlert} />
  }
)
