import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { Button } from 'shared/ui'
import { CampaignRoutes } from 'pages/campaigns/types'

export const NewCampaign = observer(() => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/${CampaignRoutes.root}/${CampaignRoutes.create}`)
  }

  return (
    <Button text='New campaign' iconProps={{ icon: 'add' }} size='medium' onClick={handleClick} />
  )
})
