import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { TextField, makeTextFieldHighlightLimit } from 'shared/ui/TextField'
import { EnumHelperText, HelperText } from 'shared/ui'
import { Warning } from 'shared/ui/Warning'
import { CHATBOT_NAME_LIMIT } from 'widgets/limits'
import { useChatbotContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export const ChatbotName = observer(() => {
  const { model, errors } = useChatbotContext()
  const [limitReached, setLimitReached] = useState(false)
  const nameError = errors.getError('name')

  const handleNameChange = (value: string) => {
    model.setName(value)
    errors.removeError('name')
  }

  return (
    <>
      <TextField
        className={styles.textField}
        variant='integrated'
        value={model.name}
        onChange={handleNameChange}
        InputProps={{ placeholder: 'Textbot name' }}
        mainActions={[
          makeTextFieldHighlightLimit({ limit: CHATBOT_NAME_LIMIT, onChange: setLimitReached }),
        ]}
      />
      {!!nameError && (
        <HelperText icon={'alertTriangleSmall'} variant={EnumHelperText.Error} text={nameError} />
      )}
      {limitReached && <Warning text={`${CHATBOT_NAME_LIMIT}-character limit reached`} />}
    </>
  )
})
