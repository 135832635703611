import { useMemo } from 'react'
import { uiStore } from 'shared/store/uiStore'
import { Avatar, IColumn, IIconsVector, Status, Box } from 'shared/ui'
import { DayjsFormats, numberFormat } from 'shared/lib'
import { IStatusProps } from 'shared/ui/Status'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { History } from '../model/History'

import styles from './styles.module.scss'
import { contactsHistoryStore } from '../store/ContactsHistoryStore'

enum IStatusTitles {
  PENDING = 'Pending',
  IN_PROGRESS = 'In progress',
  FINISHED = 'Finished',
  FAILED = 'Failed',
}

const getStatusInfo = (history: History, importAllowed: boolean | null) => {
  const statusInfo = {
    icon: 'clock' as IIconsVector,
    intent: 'informative' as IStatusProps['intent'],
    title: '',
  }

  if (importAllowed !== true) {
    statusInfo.icon = 'clock'
    statusInfo.intent = 'informative'
    statusInfo.title = IStatusTitles.PENDING

    return statusInfo
  }
  if (history.finishedAt === null && history.startedAt === null) {
    statusInfo.icon = 'close'
    statusInfo.intent = 'negative'
    statusInfo.title = IStatusTitles.FAILED

    return statusInfo
  }
  if (history.finishedAt === null) {
    statusInfo.icon = 'refresh'
    statusInfo.intent = 'informative'
    statusInfo.title = IStatusTitles.IN_PROGRESS

    return statusInfo
  }

  statusInfo.icon = 'check2'
  statusInfo.intent = 'positive'
  statusInfo.title = IStatusTitles.FINISHED

  return statusInfo
}

export const useHistoryColumnNames = (): IColumn<History>[] => {
  const { openDuplicateModal, openSkippedModal } = contactsHistoryStore
  const { importAllowed } = importContactsStore

  return useMemo(() => {
    return [
      {
        field: 'name',
        name: 'Name',
        renderRowCell: (row: History) => {
          return uiStore.dayjs(row.createdAt).format(DayjsFormats.fullWithAtDash2)
        },
      },
      {
        field: 'status',
        name: 'Status',
        renderRowCell: (row: History) => {
          const { icon, intent, title } = getStatusInfo(row, importAllowed)

          return <Status emphasis='high' icon={icon} intent={intent} title={title} />
        },
      },
      {
        field: 'total',
        name: 'Total',
        renderRowCell: (row: History) => {
          return numberFormat({ value: row.added + row.skippedCount + row.duplicateCount })
        },
      },
      {
        field: 'success',
        name: 'Success',
        renderRowCell: (row: History) => {
          return row.added
        },
      },
      {
        field: 'duplicate',
        name: 'Duplicate',
        renderRowCell: (row: History) => {
          const { duplicateCount } = row

          if (duplicateCount === 0) {
            return duplicateCount
          }
          return <span className={styles.clickableValue}>{duplicateCount}</span>
        },
        onCellClick: (row: History) => {
          if (row.duplicateCount > 0) {
            openDuplicateModal(row.id)
          }
        },
        onCheckCellPointer: (row: History) => {
          return row.skippedCount > 0
        },
      },
      {
        field: 'skipped',
        name: 'Skipped',
        renderRowCell: (row: History) => {
          const { skippedCount } = row

          if (skippedCount === 0) {
            return skippedCount
          }
          return <span className={styles.clickableValue}>{skippedCount}</span>
        },
        onCellClick: (row: History) => {
          if (row.skippedCount > 0) {
            openSkippedModal(row.id)
          }
        },
        onCheckCellPointer: (row: History) => {
          return row.skippedCount > 0
        },
      },
      {
        field: 'uploadedBy',
        name: 'Uploaded by',
        renderRowCell: (row: History) => {
          return (
            <Box
              sx={{
                alignItems: 'center',
              }}
              gap={1}
              display='flex'
            >
              <Avatar info={row.avatarInfo} size={24} />
              <div>{row.user.fullName}</div>
            </Box>
          )
        },
      },
    ]
  }, [importAllowed])
}
