import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { organizationStore } from 'entities/Organization'
import { IResponseEventOrganizationCalling } from 'entities/Organization/api/types'

const OrganizationCalling = {
  handle: (data: IResponseEventOrganizationCalling) => {
    organizationStore.updateOrganizationCall(data)
  },
}

export const EventOrganizationCalling = {
  subscribe: () => websocket.on(WebsocketEvent.OrganizationCalling, OrganizationCalling.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.OrganizationCalling, OrganizationCalling.handle),
}
