import { broadcastActionsStore } from 'widgets/BroadcastActions'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { TestSMS } from 'widgets/TestSMS'

export const TestContent = () => {
  const broadcast = useBroadcastDetailModalContext()

  if (broadcast.type === 'ringless') return null

  return (
    <TestSMS
      params={{
        media_url: broadcast.media_url,
        message: broadcast.message,
        number_id: broadcast.testNumberId,
      }}
      request={broadcastActionsStore.onTestSMSBroadcast}
      isTopError
      disabled={broadcast.isInvalid}
    />
  )
}
