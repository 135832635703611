import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  IntegrationKey,
  IIntegrationHubspotPropertyFormatted,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { usersStore } from 'entities/Users'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { DealsTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/TabsView/DealsTab/DealsTab'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/styles.module.scss'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { NewHubspotPropertiesDropdown } from 'widgets/NewHubspotPropertiesDropdown'
import { HubspotIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/store/hubspotIntegrationStore'

type IDealsProps = {
  contact: Contact
  store: HubspotIntegrationStore
  handleClear: (id: string, source_type: IUiSettingsSourceType) => void
}

const Deals = observer(({ contact, store, handleClear }: IDealsProps) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole
  const contactsDetailsStore = useContactsDetailsContext()

  const {
    newHubspotPropertiesDropdownStore,
    hubspotIntegration,
    hubspotInfo,
    showMoreDealLabel,
    draggableDealsItemsMap,
    hasShowAllDealsFields,
  } = store

  const deals = hubspotInfo?.deals || []

  const dealList = () => {
    const data: IIntegrationHubspotPropertyFormatted[] = []
    const deal = hubspotInfo?.deals?.[0]

    if (deal) {
      for (const [key, value] of Object.entries(deal)) {
        data.push({
          key: key,
          label: value?.label ?? '',
          value: value?.value?.toString() ?? '',
        })
      }
    }

    return data || []
  }

  const dealFullList = () => {
    if (draggableDealsItemsMap.length) {
      const data: IIntegrationHubspotPropertyFormatted[] = []
      draggableDealsItemsMap.forEach((key) => {
        const result = dealList().find((item) => item.key === key)
        if (result) {
          data.push(result)
        }
      })

      return data
    }

    return dealList()
  }

  const deal = hasShowAllDealsFields ? dealFullList().slice(0, 5) : dealFullList()

  const hasShowMoreDealButton = dealFullList().filter((deal) => deal.key !== 'dealname').length > 5

  const link = (id?: number | string | null) => {
    const linkProps = {
      key: IntegrationKey.hubspot,
      integration: hubspotIntegration,
      type: IIntegrationLinkTypes.deal,
      id,
    }

    return integrationLink(linkProps)
  }

  const handleView = () => {
    contactsDetailsStore.openView('deals', { deals }, 'hubspot', IntegrationKey.hubspot)
  }

  const { toggleBlockView } = contactsDetailsGlobalStore

  const actions = () => {
    const hasMore = deals.length > 1

    const hasShowActions = hasShowMoreDealButton || hasMore

    const objectId = deals[0]?.id.value as number

    return (
      <div className={styles.actions}>
        {!isUserViewOnly && (
          <div className={styles.action}>
            <NewHubspotPropertiesDropdown
              store={newHubspotPropertiesDropdownStore}
              sourceType={'hubspot-deals-draggable-items'}
              objectType={'deal'}
              objectId={objectId}
            />
          </div>
        )}
        {hasShowActions && (
          <div className={styles.actionsRow}>
            {hasShowMoreDealButton && (
              <div className={styles.action}>
                <Button
                  size='small'
                  typeBtn='text'
                  disabled={!contact}
                  text={showMoreDealLabel}
                  onClick={() => {
                    toggleBlockView('hubspotDetailsDealsAllFields')
                  }}
                />
              </div>
            )}

            {hasMore && (
              <div className={styles.action}>
                <Button size='small' typeBtn='text' text='See all deals' onClick={handleView} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <DealsTab
      deals={deals}
      deal={deal}
      link={link}
      integrationLabel={'HubSpot'}
      actions={actions()}
      handleClear={handleClear}
    />
  )
})

export { Deals }
