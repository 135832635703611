import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Alert, Box, Button, ButtonCopy, Icon, IconButton, Typography, UploadDoc } from 'shared/ui'
import { formatBytesToNumberWithType, getCDNAssetURL } from 'shared/lib'
import { links } from 'shared/constants/links'
import { AttachmentCard } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { BlockNumber } from 'pages/settings/pages/compliance/ui/BlockNumber/BlockNumber'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'

import styles from './styles.module.scss'

type IBlockInfo = {
  title: string
  desc: string
  copyTitle?: string
  copyText: string
  underlineText?: string[]
}

type IOptInContentProps = {
  children?: ReactNode
  stepLoading: boolean
  isNextDisabled: boolean
  attachmentError: { title?: string; desc?: string } | null
  firstBlock: IBlockInfo
  secondBlock: IBlockInfo
  attachmentData: Attachment[] | null
  increaseStep: () => void
  clearAttachmentError: () => void
  seeApprovedExampleClick: () => void
  approvedExampleClick: () => void
  rejectedExampleClick: () => void
  deleteAttachment: (url: string) => void
  uploadOptInImageAttachment: (files: File[]) => void
  approvedExampleImage: string
  rejectedExampleImage: string
  errorBanner?: ReactNode
}

export const OptInContent: FC<IOptInContentProps> = observer(
  ({
    children,
    stepLoading,
    isNextDisabled,
    attachmentError,
    firstBlock,
    secondBlock,
    attachmentData,
    increaseStep,
    deleteAttachment,
    clearAttachmentError,
    seeApprovedExampleClick,
    approvedExampleClick,
    rejectedExampleClick,
    uploadOptInImageAttachment,
    approvedExampleImage,
    rejectedExampleImage,
    errorBanner,
  }) => {
    const formatCopyText = (text: string, underlineWords: string[]) => {
      return text.split(new RegExp(`(${underlineWords.join('|')})`, 'g')).map((word, index) =>
        underlineWords.includes(word) ? (
          <span key={index} className={styles.underlinedText}>
            {word}
          </span>
        ) : (
          word
        )
      )
    }

    return (
      <div className={styles.optInContentWrap}>
        {errorBanner}

        <Alert
          className={styles.alert}
          item={{
            type: 'infoBlue',
            title: 'Did you know?',
            desc: 'Most registration rejections occur due to incorrect information submitted in the “Opt-in consent” step.',
          }}
          rightAction={
            <Button
              tag='a'
              text='Learn more'
              typeBtn='outlined'
              size='small'
              contained='secondary'
              className={styles.learnMore}
              target='_blank'
              rel='noreferrer'
              href={links.complianceOptInConsentLearnMoreLink}
            />
          }
        />

        <div className={styles.infoBlock}>
          <BlockNumber className={styles.blockNumber} number={1} />
          <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
            {firstBlock.title}
          </Typography>
          <Typography
            ariaLabel='infoBlockTitle'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            {firstBlock.desc}
          </Typography>

          <div className={styles.copyBlock}>
            <div className={styles.copyBlockLine}>
              <Typography ariaLabel='infoBlockTitle' variant='body-md-semibold'>
                {firstBlock.copyTitle}
              </Typography>
              <Button
                text='See approved example'
                size='small'
                typeBtn='informative'
                onClick={seeApprovedExampleClick}
              />
            </div>
            <Typography
              ariaLabel='infoBlockTitle'
              variant='body-md-regular'
              color='var(--content-primary-secondary)'
            >
              {formatCopyText(firstBlock.copyText, firstBlock.underlineText || [])}
            </Typography>

            <ButtonCopy value={firstBlock.copyText} />
          </div>
        </div>
        <div className={styles.infoBlock}>
          <BlockNumber className={styles.blockNumber} number={2} />
          <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
            {secondBlock.title}
          </Typography>
          <Typography
            ariaLabel='infoBlockTitle'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            {secondBlock.desc}
          </Typography>

          <div className={styles.copyBlock}>
            <Typography
              ariaLabel='infoBlockTitle'
              variant='body-md-regular'
              color='var(--content-primary-secondary)'
            >
              {secondBlock.copyText}
            </Typography>

            <ButtonCopy value={secondBlock.copyText} />
          </div>
        </div>
        <div className={styles.infoBlock}>
          <BlockNumber className={styles.blockNumber} number={3} />
          <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
            Upload your opt-in image
          </Typography>

          <Typography
            ariaLabel='infoBlockTitle'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            Provide an image of the opt-in process where the customer entered their phone number and
            agreed to receive SMS. Ensure it meets the above criteria.
          </Typography>

          <div className={styles.imagesWrap}>
            <div className={styles.imageBlock}>
              <div className={classNames(styles.conditionField, styles.conditionFieldRejected)}>
                <Icon icon='failed' color='var(--action-outlined-secondary-normal)' fontSize={18} />
                <Typography ariaLabel='infoBlockTitle' variant='body-sm-medium'>
                  Rejected example
                </Typography>
              </div>
              <img
                src={getCDNAssetURL(rejectedExampleImage)}
                alt='optInOnlineFormRejected'
                aria-hidden={true}
                className={styles.image}
                onClick={approvedExampleClick}
              />
            </div>
            <div className={styles.imageBlock}>
              <div className={classNames(styles.conditionField, styles.conditionFieldApproved)}>
                <Icon
                  icon='check2Correct'
                  color='var(--white-100)'
                  fontSize={18}
                  className={styles.approvedIcon}
                />
                <Typography ariaLabel='infoBlockTitle' variant='body-sm-medium'>
                  Approved example
                </Typography>
              </div>
              <img
                src={getCDNAssetURL(approvedExampleImage)}
                aria-hidden={true}
                alt='optInOnlineFormApproved'
                className={styles.image}
                onClick={rejectedExampleClick}
              />
            </div>
          </div>

          {attachmentError && (
            <Alert
              item={{ title: attachmentError.title, desc: attachmentError.desc, type: 'error' }}
              onClose={clearAttachmentError}
              marginBottom={16}
            />
          )}

          <UploadDoc
            uploadTypeText='Acceptable file types: jpg, png'
            onChange={(files) => uploadOptInImageAttachment(files as File[])}
          />
        </div>
        {attachmentData &&
          attachmentData.map((item) => (
            <Box
              key={item.id}
              display='flex'
              justifyContent={'space-between'}
              alignItems={'center'}
              marginTop={4}
            >
              <Box display='flex' gap={'12px'} alignItems={'center'}>
                <AttachmentCard attachment={item} loading={item.loading} />

                <Box display={'flex'} flexDirection={'column'} gap={1} justifyContent={'center'}>
                  <Typography
                    ariaLabel='attachmentName'
                    variant='body-md-regular'
                    color='var(--content-primary-primary)'
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    ariaLabel='attachmentName'
                    variant='body-sm-regular'
                    color='var(--content-primary-tertiary)'
                  >
                    {formatBytesToNumberWithType(item?.size || 0)}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                icon='close'
                ariaLabel='delete_document'
                size='small'
                variant='icon'
                color='tertiary'
                onClick={() => {
                  if (item.source) {
                    deleteAttachment(item.source)
                  }
                }}
              />
            </Box>
          ))}
        {children}

        <div className={styles.optInContentNextButton}>
          <NextButton
            loading={stepLoading}
            disabled={isNextDisabled || stepLoading}
            text='Next'
            onClick={increaseStep}
          />
        </div>
      </div>
    )
  }
)
