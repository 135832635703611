import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Divider, Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'

export const PlanPreview = observer(() => {
  const { planPreview, isEdit, remainingAmount } = adminOrganizationCustomPlanStore

  if (!planPreview && !isEdit) {
    return null
  }

  return (
    <Box bgcolor='var(--background-neutral-subtle)' mt={2} p={2} mb={2} borderRadius='8px'>
      {/* { TODO Remove items check } */}
      {planPreview?.new_subscription?.items?.map(({ description, amount, tier_amount }, key) => (
        <Box key={key} display='flex' justifyContent='space-between' mb={1}>
          <Typography variant={'body-md-regular'} color='tertiary' ariaLabel={'description'}>
            {description}
          </Typography>
          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'descriptionNumber'}
          >
            {numberFormat({ value: (tier_amount || amount) / 100, currency: 'USD' })}
          </Typography>
        </Box>
      ))}
      <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} />
      <Box display='flex' justifyContent='space-between' mb={1}>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Total contract value'}
        >
          Total contract value
        </Typography>
        <Typography
          variant={'body-md-semibold'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'TotalNumber'}
        >
          {numberFormat({ value: remainingAmount, currency: 'USD' })}
        </Typography>
      </Box>
    </Box>
  )
})
