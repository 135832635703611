import React from 'react'
import { Tooltip, Typography, Icon } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import type { Integration } from 'entities/Integrations/model/Integration'

export const TriggerDisconnectedIntegrationAlert = ({
  integration,
}: {
  integration: Integration | null
}) => {
  if (!integration || integration.is_connected) {
    return null
  }

  return (
    <Tooltip
      placement={'top'}
      label={
        <Typography ariaLabel={'IntegrationDisconnected'} variant={'body-md-regular'}>
          Integration disconnected.
          <br />
          <Typography
            variant={'body-md-medium'}
            ariaLabel={'ReconnectLearnMore'}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()

              uiStore.changeRoute({
                path: '/settings/integrations',
                type: 'vue',
              })
            }}
            linkLight
          >
            Reconnect
          </Typography>
        </Typography>
      }
    >
      <Icon icon={'alertTriangleYellow'} />
    </Tooltip>
  )
}
