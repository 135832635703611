import dayjs from 'dayjs'
import { makeAutoObservable } from 'mobx'
import { organizationStore } from 'entities/Organization'
import { integrationsStore } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { configStore } from 'entities/Config'
import { subscriptionStore } from 'entities/Subscription'
import { getPosthogSession } from 'entities/Auth/lib/setToken'
import { eventLogApi } from 'entities/EventLog/api'

class EventLogStore {
  constructor() {
    makeAutoObservable(this)
  }

  get sessionID() {
    return getPosthogSession()
  }

  setUserData = () => {
    const user = usersStore.user
    const intercom = configStore.intercom
    const { connectedIntegrations } = integrationsStore
    const integrations = connectedIntegrations.map((item) => item.key).join(' ,')

    if (!user) return
    if (!organizationStore.id) return

    eventLogApi.updateSegmentEvents({
      eventType: 'identify',
      payload: {
        userId: user.id,
        traits: {
          avatar: user.photo_url,
          name: user.full_name,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          role: user.role,
          createdAt: dayjs.utc(user.createdAt).unix(),
          signedUp: dayjs.utc(user.createdAt).format('YYYY-MM-DD'),
          company: organizationStore.name,
          integrations: integrations,
        },
        context: {
          Intercom: {
            user_hash: intercom,
          },
        },
      },
    })
  }

  setOrganizationData = () => {
    const user = usersStore.user

    if (!user) return
    if (!organizationStore.id) return
    if (!subscriptionStore.id) return

    eventLogApi.updateSegmentEvents({
      eventType: 'group',
      payload: {
        groupId: organizationStore.id,
        userId: user.id,
        traits: {
          createdAt: dayjs.utc(organizationStore.created_at).unix(),
          credits: organizationStore.account_balance,
          id: organizationStore.id,
          ownerFirstName: organizationStore.owner?.first_name,
          ownerLastName: organizationStore.owner?.last_name,
          ownerName: organizationStore.owner?.full_name,
          name: organizationStore.name,
          phone: organizationStore.owner?.number,
          plan: subscriptionStore.plan?.id || 'unknown',
          planStatus: subscriptionStore.status || 'unknown',
        },
      },
    })
  }

  logEvent = (event = '', payload = {}, options = {}) => {
    const user = usersStore.user

    const properties = Object.assign(payload, {
      ...(this.sessionID && { $session_id: this.sessionID }),
      context: options,
    })

    eventLogApi.updateSegmentEvents({
      eventType: 'track',
      payload: {
        userId: user ? user.id : 'anonymous_user',
        event: event,
        properties: properties,
        context: options,
      },
    })
  }
}

export const eventLogStore = new EventLogStore()
