import { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Alert, Button } from 'shared/ui'
import {
  IInputComponent,
  makeTextFieldAutoFocus,
  makeTextFieldHighlightLimit,
  TextField,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'shared/constants/auth'
import { ResetPasswordStore } from 'widgets/ResetPassword/store/resetPasswordStore'

import styles from './styles.module.scss'

type IFormProps = {
  store: ResetPasswordStore
}

export const ResetPasswordForm = observer(({ store }: IFormProps) => {
  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    triggerPasswordValidation,
    passwordError,
    loading,
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerPasswordValidation()
    }
  }

  return (
    <div className={styles.wrap}>
      {passwordError && (
        <Alert
          className={styles.error}
          item={{
            type: 'error',
            desc: passwordError,
          }}
        />
      )}

      <Label
        label={{
          text: 'New password',
          className: styles.inputLabel,
        }}
      />
      <TextField
        Input={InputPassword as IInputComponent}
        className={classNames(styles.textField)}
        variant='stroke'
        InputProps={{
          placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
          onKeyDown,
        }}
        disabled={loading}
        size='medium'
        onChange={setPassword}
        value={password}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH }),
        ]}
      />

      <Label
        label={{
          text: 'Confirm password',
          className: styles.inputLabel,
        }}
      />
      <TextField
        Input={InputPassword as IInputComponent}
        className={classNames(styles.textField)}
        variant='stroke'
        InputProps={{
          placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
          onKeyDown,
        }}
        disabled={loading}
        size='medium'
        onChange={setConfirmPassword}
        value={confirmPassword}
        mainActions={[makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH })]}
      />

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={triggerPasswordValidation}
        loading={loading}
        disabled={loading}
        text={'Send instructions'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default ResetPasswordForm
