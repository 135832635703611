import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { ContainerHeader, FullPageWrapper, SpinnerLoader } from 'shared/ui'
import { links } from 'shared/constants/links'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { GuidelineAlert, GuidelineStaticStore } from 'widgets/GuidelineAlert'
import { ChatbotNewButton } from 'pages/chatbot/ui'
import {
  ChatbotDetailsProvider,
  ChatbotListProvider,
  useChatbotListContext,
} from 'pages/chatbot/context'

import { useChatbotLayoutContext } from 'pages/chatbot/context/chatbotLayoutContext'
import { ChatbotListHeader } from './ChatbotListHeader'
import { ChatbotListTable } from './ChatbotListTable'
import { ChatbotListEmpty } from './ChatbotListEmpty'
import styles from './styles.module.scss'

const ChatbotList = observer(() => {
  const chatbotLayoutStore = useChatbotLayoutContext()
  const { titleIconProps, handleToggleCollapse } = chatbotLayoutStore.pageLayoutStore
  const { initialLoading, isEmpty, loadData } = useChatbotListContext()
  const guidelineStaticStore = useMemo(() => new GuidelineStaticStore('chatbotGuidelineHidden'), [])

  const headActions = useMemo(() => {
    if (initialLoading || isEmpty) return []

    return [
      {
        iconButtonComponent: <ChatbotNewButton />,
      },
    ]
  }, [initialLoading, isEmpty])

  useEffect(() => {
    loadData()

    return () => {
      guidelineStaticStore.dispose()
    }
  }, [])

  return (
    <FullPageWrapper>
      <ContainerHeader
        title={featureFlagsStore.salesmsg_design_v2_new_sidebar_menu ? 'Agents' : 'All Textbots'}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        titleIconAction={handleToggleCollapse}
        actions={headActions}
        paddingLeft={20}
      />
      <GuidelineAlert
        className={styles.guidelineAlert}
        store={guidelineStaticStore}
        link={links.chatbotGuidelines}
        message='Review our Textbot guidelines'
      />
      {initialLoading ? (
        <SpinnerLoader />
      ) : isEmpty ? (
        <ChatbotListEmpty />
      ) : (
        <>
          <ChatbotListHeader />
          <ChatbotListTable />
        </>
      )}
    </FullPageWrapper>
  )
})

export const ChatbotListPage = () => (
  <ChatbotListProvider>
    <ChatbotDetailsProvider>
      <ChatbotList />
    </ChatbotDetailsProvider>
  </ChatbotListProvider>
)
