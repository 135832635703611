import { makeAutoObservable } from 'mobx'
import type { IResponseOrganizationShakenStir } from 'entities/Compliance'

export class OrganizationShakenStir {
  private _shakenStir: IResponseOrganizationShakenStir['shakenStir'] | null = null
  private _terms: IResponseOrganizationShakenStir['terms'] | null = null

  constructor(response: IResponseOrganizationShakenStir) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseOrganizationShakenStir) => {
    this._shakenStir = response.shakenStir
    this._terms = response.terms
  }

  get shakenStir() {
    return this._shakenStir
  }

  get terms() {
    return this._terms
  }

  get status() {
    if (!this._shakenStir) return

    return this._shakenStir.status
  }
}
