// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VskfJtNBKkuB4uFoq50_{display:grid;gap:40px;--titled-row-action-width: 120px}.GFRUPE1FK4OkyngrKOJQ{gap:6px}.TTewe0WCSAyMCLG_NyZA{position:relative;display:flex;flex-direction:column;padding-bottom:46px}.TTewe0WCSAyMCLG_NyZA::after{content:\"\";position:absolute;bottom:20px;left:50%;height:1px;width:calc(100% + 40px);transform:translateX(-50%);background-color:var(--border-neutral-default)}.TTewe0WCSAyMCLG_NyZA>div{padding:22px 0 17px;border-bottom:1px solid var(--border-neutral-default)}.TTewe0WCSAyMCLG_NyZA>div:last-child{border-bottom:none}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsTimeline/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,gCAAA,CAGF,sBACE,OAAA,CAGF,sBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CAEA,6BACE,UAAA,CACA,iBAAA,CACA,WAAA,CACA,QAAA,CACA,UAAA,CACA,uBAAA,CACA,0BAAA,CACA,8CAAA,CAGF,0BACE,mBAAA,CACA,qDAAA,CAEA,qCACE,kBAAA","sourcesContent":[".root {\n  display: grid;\n  gap: 40px;\n  --titled-row-action-width: 120px;\n}\n\n.expanded {\n  gap: 6px;\n}\n\n.settings {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 46px;\n\n  &::after {\n    content: '';\n    position: absolute;\n    bottom: 20px;\n    left: 50%;\n    height: 1px;\n    width: calc(100% + 40px);\n    transform: translateX(-50%);\n    background-color: var(--border-neutral-default);\n  }\n\n  > div {\n    padding: 22px 0 17px;\n    border-bottom: 1px solid var(--border-neutral-default);\n\n    &:last-child {\n      border-bottom: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "VskfJtNBKkuB4uFoq50_",
	"expanded": "GFRUPE1FK4OkyngrKOJQ",
	"settings": "TTewe0WCSAyMCLG_NyZA"
};
export default ___CSS_LOADER_EXPORT___;
