import { observer } from 'mobx-react-lite'
import { TextInput } from 'shared/ui'
import { BROADCAST_NAME_LIMIT } from 'widgets/limits'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'

import styles from './styles.module.scss'

export const BroadcastName = observer(() => {
  const { setName, name, errorsMap, setError } = useBroadcastViewContext()

  const error = errorsMap.get('name')

  return (
    <TextInput
      size={'large'}
      placeholder={'Broadcast name'}
      value={name}
      setValue={setName}
      error={error && 'Please enter broadcast name'}
      setError={(value) => setError('name', value)}
      className={styles.broadcastName}
      limit={BROADCAST_NAME_LIMIT}
    />
  )
})
