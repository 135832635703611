import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseConversationUnread } from 'entities/Conversation/api/types'
import { dataLogStore } from 'entities/DataLog/store/dataLogStore'

const ConversationUnread = {
  handle: (data: IResponseConversationUnread) => {
    const { conversation } = data

    // TODO: SWR-14336: Will be uncommit and updated with SWR-14336
    // dataLogStore.setItem({
    //   logLevel: 'info',
    //   message: 'EventConversationUnread',
    //   details: {
    //     conversationId: conversation.id,
    //     totalUnread: conversation.unread_count,
    //     conversationUnread: Boolean(conversation.unread_count),
    //     lastReadAt: conversation.last_read_at,
    //     lastMessageAt: conversation.last_message_at,
    //   },
    // })
  },
}

export const EventConversationUnreadLog = {
  subscribe: () => websocket.on(WebsocketEvent.ConversationUnread, ConversationUnread.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.ConversationUnread, ConversationUnread.handle),
}
