import { IStatusProps, Status } from 'shared/ui/Status'

type IStatusConfig = Pick<IStatusProps, 'icon' | 'title' | 'intent'>

const ACTIVE_STATUS_CONFIG: IStatusConfig = {
  icon: 'check2',
  title: 'Active',
  intent: 'positive',
}

const DISABLED_STATUS_CONFIG: IStatusConfig = {
  icon: 'minusCircle',
  title: 'Disabled',
  intent: 'neutral',
}

const INVALID_STATTUS_CONFIG: IStatusConfig = {
  title: 'Invalid',
  icon: 'closeCircle',
  intent: 'negative',
}

export interface IWorkflowStatusBadge {
  active: boolean
  isInvalid: boolean
}

export const WorkflowStatusBadge = ({ active, isInvalid }: IWorkflowStatusBadge) => (
  <Status
    {...(isInvalid
      ? INVALID_STATTUS_CONFIG
      : active
      ? ACTIVE_STATUS_CONFIG
      : DISABLED_STATUS_CONFIG)}
    emphasis={'high'}
  />
)
