import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import type { GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'
import styles from './styles.module.scss'

type INewNumberSummaryModalActionsProps = {
  store: GetNumberModalStore
  number: string
}

export const NewNumberSummaryModalActions: FC<INewNumberSummaryModalActionsProps> = observer(
  ({ store }) => {
    return (
      <div className={styles.actionsWrap} aria-label={getAriaLabel('NewNumberSummaryModalActions')}>
        <Button
          fullWidth
          typeBtn={'contained'}
          size={'large'}
          text={'Let’s dive in!'}
          textClassname={styles.button}
          onClick={store.closeFinishModal}
        />
      </div>
    )
  }
)
