import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile/route/type'
import { type ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

const continueLinkBusinessProfileMap: Record<number, string> = {
  1: `${CompliancePath.compliance.businessProfile.root}/${BusinessProfileRoutes.companyLocation}`,
  2: `${CompliancePath.compliance.businessProfile.root}/${BusinessProfileRoutes.generalInfo}`,
  3: `${CompliancePath.compliance.businessProfile.root}/${BusinessProfileRoutes.businessInfo}`,
  4: `${CompliancePath.compliance.businessProfile.root}/${BusinessProfileRoutes.peopleToContact}`,
  5: `${CompliancePath.compliance.businessProfile.root}/${BusinessProfileRoutes.termsAndPrivacy}`,
}

export class BusinessProfileNavigationStore {
  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)
  }

  get nextStep() {
    if (this.formStep === 0) return continueLinkBusinessProfileMap[1]

    return continueLinkBusinessProfileMap[this.UIFormStep]
  }

  get formStep() {
    const formStep = this.data?.reg_progress_status

    if (formStep === 'form_step_1') return 1
    if (formStep === 'form_step_2') return 2
    if (formStep === 'form_step_3') return 3
    if (formStep === 'data_collected') return 4

    return 0
  }

  get UIFormStep() {
    return this.formStep + 1
  }

  get data() {
    return this._complianceLayerStore.businessInformation
  }

  updateBusinessProfile = () => {
    uiStore.changeRoute({
      path: `/${continueLinkBusinessProfileMap[1]}`,
    })
  }
}
