import React from 'react'
import { observer } from 'mobx-react-lite'
import { SMSMessageField } from 'widgets/MessageFormFields'
import { CampaignSMSStore } from './store/CampaignSMSStore'

type ICampaignSMSProps = {
  store: CampaignSMSStore
}

export const CampaignSMS = observer(({ store }: ICampaignSMSProps) => {
  return (
    <SMSMessageField smsMessageFieldStore={store.smsMessageFieldStore} errorMessage={store.error} />
  )
})
