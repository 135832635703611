import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { organizationStore } from 'entities/Organization'
import { IResponseEventUpdateBalance } from 'entities/Organization/api/types'

const UpdateBalance = {
  handle: (data: IResponseEventUpdateBalance) => {
    organizationStore.updateBalance(data)
  },
}

export const EventUpdateBalance = {
  subscribe: () => websocket.on(WebsocketEvent.AccountBalanceUpdated, UpdateBalance.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.AccountBalanceUpdated, UpdateBalance.handle),
}
