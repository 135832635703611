import { action, makeObservable, observable } from 'mobx'
import type { IActionsProps, IIconsVector } from 'shared/ui'
import {
  IResponseOneTimeBroadcast,
  IResponseOneTimeBroadcastStatus,
} from 'entities/Broadcast/api/types'
import type { IBroadcastType } from 'entities/Broadcast'
import type { ISendFromNumbersProps } from 'entities/Inbox/api/types'
import { usersStore } from 'entities/Users'
import { User } from 'entities/Users/model/User'
import { BroadcastOneTimeStatistic } from 'entities/Broadcast/model/BroadcastOneTimeStatistic'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { IResponseMediaShort } from 'entities/Attachment'
import { inboxesStore, getSmartOptionLabel } from 'entities/Inbox'

export class BroadcastOneTime {
  id: number
  recurring_parent_id?: number | null
  type!: IBroadcastType
  name!: string
  owner_id!: number
  status!: IResponseOneTimeBroadcastStatus
  failed_reason!: string | null
  broadcastOwner?: User
  created_at!: string

  sent_at!: string
  send_at!: string
  is_track_conversion?: boolean
  conversion_id?: number | null = null

  statistics: BroadcastOneTimeStatistic | null = null

  sendFromNumbersProps: ISendFromNumbersProps | null = null

  message = ''
  media_url: IResponseMediaShort[] = []
  attachments: Attachment[] = []
  contacts_count: number | null = null
  invalid_contacts_count: number | null = null
  short_urls: IResponseOneTimeBroadcast['short_urls'] | null = []
  package_count = 0
  sendFrom: IResponseOneTimeBroadcast['send_from'] | null = null
  number: IResponseOneTimeBroadcast['number'] | null = null
  response: IResponseOneTimeBroadcast
  testNumberId = 0

  constructor(item: IResponseOneTimeBroadcast) {
    this.id = item.id
    this.response = item
    this.syncOrigin(item)

    makeObservable(this, {
      status: observable,
      syncOrigin: action,
    })
  }

  syncOrigin = (item: IResponseOneTimeBroadcast) => {
    this.id = item.id
    this.recurring_parent_id = item.recurring_parent_id
    this.statistics = new BroadcastOneTimeStatistic(item)
    this.type = item.is_rvm ? 'ringless' : 'sms'
    this.status = item.status
    this.failed_reason = item.failed_reason
    this.sent_at = item.sent_at
    this.send_at = item.send_at || ''

    this.broadcastOwner = new User(item.user_owner)
    this.is_track_conversion = item.is_track_conversion
    this.conversion_id = item.conversion_id
    this.sendFromNumbersProps = this.getSendFromNumbersProps(item)
    this.testNumberId = this.getTestNumberId(item)
    this.owner_id = item.owner_id
    this.name = item.name
    this.created_at = item.created_at
    this.message = item.message
    this.media_url = item.media_url ?? []
    this.attachments = Array.isArray(item.media_url)
      ? item.media_url.map((media) => new Attachment({ responseMediaShort: media }))
      : []
    this.contacts_count = item.contacts_count
    this.invalid_contacts_count = item.invalid_contacts_count
    this.short_urls = item.short_urls
    this.package_count = item.package_count
    this.sendFrom = item.send_from
    this.number = item.number
  }

  get user() {
    const userFormStore = usersStore.getItem(this.owner_id)
    if (userFormStore) {
      return userFormStore
    }
    if (this.broadcastOwner) {
      return this.broadcastOwner
    } else {
      return usersStore.getItem(this.owner_id, true)
    }
  }

  actionsProps: IActionsProps | null = null

  setActionsProps: (actionProps: IActionsProps) => void = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }

  getSendIcon = (broadcast: IResponseOneTimeBroadcast): IIconsVector | undefined => {
    const number = broadcast.number
    if (number?.is_aircall) {
      return 'aircall'
    }
    if (broadcast.send_from?.type === 'from_smart_inbox' && broadcast.team) {
      return 'folder'
    }
    return 'inboxOpen'
  }

  getTestNumberId = (broadcast: IResponseOneTimeBroadcast): number => {
    if (broadcast.team) return broadcast.team.numbers[0]?.id ?? 0
    return broadcast.number?.id ?? 0
  }

  getSendFromNumbersProps = (broadcast: IResponseOneTimeBroadcast): ISendFromNumbersProps => {
    if (broadcast.send_from?.type === 'from_smart_inbox' && broadcast.team)
      return {
        icon: this.getSendIcon(broadcast),
        name: broadcast.team.name,
        value: getSmartOptionLabel(broadcast.send_from?.options.smart_option),
      }

    return {
      icon: this.getSendIcon(broadcast),
      name: broadcast.number?.numberable?.name,
      value: broadcast.number?.formatted_number,
    }
  }

  get inboxId() {
    if (this.sendFrom?.options.team_id) {
      const inbox = inboxesStore.getItem(this.sendFrom?.options.team_id)

      return inbox?.id
    }

    if (this.sendFrom?.options.number_id) {
      const inbox = inboxesStore.getInboxByNumber(this.sendFrom?.options.number_id)

      return inbox?.id
    }

    if (this.number?.id) {
      const inbox = inboxesStore.getInboxByNumber(this.number.id)

      return inbox?.id
    }
  }

  get isInvalid() {
    return this.status === 'invalid'
  }
}
