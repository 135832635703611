import { Route, Routes } from 'react-router'
import { LocalNumbersLayout } from 'pages/settings/pages/compliance/pages/localNumbers/layout/LocalNumbersLayout'
import { LocalNumbersPage } from 'pages/settings/pages/compliance/pages/localNumbers/ui/localNumbersPage'

export const LocalNumbersRouter = () => {
  return (
    <Routes>
      <Route element={<LocalNumbersLayout />}>
        <Route path={'/'} element={<LocalNumbersPage />} />
      </Route>
    </Routes>
  )
}
