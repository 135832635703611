import React, { useEffect, useState } from 'react'
import { uiStore } from 'shared/store/uiStore'
import type { PageLayoutStore } from 'shared/layout'
import type { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggersRoutes } from 'entities/ExportRouters'
import { TriggerDetailContext } from 'widgets/TriggerDetail/context/triggerDetailContext'
import { TriggerDetailStore } from 'widgets/TriggerDetail/store/triggerDetailStore'
import { TriggerDetailContent } from './TriggerDetailContent'

type ITriggerDetailContentProps = {
  trigger: ITrigger
  pageLayoutStore: PageLayoutStore
}

export const TriggerDetail = ({ trigger, pageLayoutStore }: ITriggerDetailContentProps) => {
  const [store] = useState(() => new TriggerDetailStore(trigger, pageLayoutStore))

  useEffect(() => {
    uiStore.setCustomPathLabel(
      `/${TriggersRoutes.root}/${TriggersRoutes.view}/${trigger.id}`,
      trigger.name
    )
  }, [])

  return (
    <TriggerDetailContext.Provider value={store}>
      <TriggerDetailContent />
    </TriggerDetailContext.Provider>
  )
}
