import { makeObservable, computed, observable, runInAction } from 'mobx'
import dayjs from 'dayjs'
import { IAvatarProps } from 'shared/ui'
import { dateDiff } from 'shared/lib/dayjs'
import { formatPhoneNumberToInternational } from 'shared/lib'
import { Base } from 'models'
import { ICallParticipantContact } from 'entities/Call'

export class CallContact extends Base {
  id: number
  name: string
  formatted_number: string
  number: string
  avatarProps: IAvatarProps
  sid: string
  startTime: string
  timeInterval: ReturnType<typeof setInterval> | null = null
  timestamp = 0

  constructor(item: ICallParticipantContact, useTimer = true) {
    super()

    this.id = item.contact.id
    this.name = item.contact.full_name
    this.formatted_number = item.contact.formatted_number
    this.number = item.contact.number
      ? item.contact.number
      : formatPhoneNumberToInternational(item.contact.formatted_number)
    this.avatarProps = {
      info: {
        firstName: item.contact.first_name,
        lastName: item.contact.last_name,
        color: item.contact.color,
        number: item.contact.number,
      },
      fontSize: 12,
    }
    this.sid = item.call.sid
    this.startTime = item.call.startTime || ''

    if (useTimer) {
      this.handleIncrementTime()
    }

    makeObservable(this, {
      timeWait: computed,
      timestamp: observable,
    })
  }

  clearIncrementTime = () => {
    if (this.timeInterval) {
      clearInterval(this.timeInterval)
    }
  }

  handleIncrementTime = () => {
    this.timestamp = dateDiff(this.startTime)

    this.timeInterval = setInterval(() => {
      runInAction(() => {
        this.timestamp += 1000
      })
    }, 1000)
  }

  get timeWait() {
    if (!this.startTime) return ''

    return `Waiting for ${dayjs(this.timestamp).format('mm:ss')}`
  }
}
