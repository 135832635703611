import { FC } from 'react'
import { InputRadio, type InputRadioProps } from 'shared/ui'
import styles from 'entities/Compliance/ui/Questionnaire/ui/QuestionSection/ui/styles.module.scss'

type IQuestionSectionItemsProps = {
  items: InputRadioProps[]
}

export const QuestionSectionItems: FC<IQuestionSectionItemsProps> = ({ items }) => {
  return (
    <div className={styles.inputContainer}>
      {items.map((radio, index) => (
        <InputRadio
          {...radio}
          key={index}
          variant={'bordered'}
          radioSize='small'
          className={styles.inputRadio}
          typographyVariant='body-rg-regular'
        />
      ))}
    </div>
  )
}
