import { captureException } from '@sentry/react'
import { ReactNode, useEffect } from 'react'
import { useRouteError } from 'react-router'

import styles from './styles.module.scss'

interface IServiceErrorProps {
  name: string
  fallback?: ReactNode
}

export const ServiceError = ({ name, fallback }: IServiceErrorProps) => {
  const error = useRouteError()

  useEffect(() => {
    captureException(error, { level: 'fatal', tags: { 'app.service': name } })
  }, [error])

  if (fallback !== undefined) return <>{fallback}</>

  return <div className={styles.wrap}>Error - {JSON.stringify(error)}</div>
}
