import { CampaignMessageStore } from './store/CampaignMessageStore'
import { CampaignMessageUi, ICampaignMessageUiProps } from './ui/CampaignMessageUi'
import { CampaignMessageContext } from './context/context'

type ICampaignMessageProps = {
  store: CampaignMessageStore
} & ICampaignMessageUiProps

export const CampaignMessage = ({ store, ...props }: ICampaignMessageProps) => {
  return (
    <CampaignMessageContext.Provider value={store}>
      <CampaignMessageUi {...props} />
    </CampaignMessageContext.Provider>
  )
}
