import { useLocalStorage } from 'shared/hooks/useLocalStorage'

export const usePathMenu = (key: string, getPath: (path: string | null) => string) => {
  let path = useLocalStorage(key)

  path = getPath(path)

  if (path && path[0] === '/') {
    path = path.slice(1)
  }

  return path
}
