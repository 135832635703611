import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Box, TextFieldWithLabel } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import styles from './styles.module.scss'
import { WebsiteFields } from './WebsiteFields'

export const ManuallyBusinessFind = observer(() => {
  const { businessInfoStore, fieldsError, fieldsErrorCommonText } = useTollFreeStepsContext()
  const {
    einItem,
    addBusinessNameManually,
    addBusinessAddressManually,
    addBusinessAddress2Manually,
    addCityManually,
    countries,
    setActiveCountry,
    activeCountryId,
    activeCountryInfo,
    states,
    addBusinessStateManually,
    zipLocalErrorMessage,
    zipCode,
    addZipManually,
    checkZipLengthManually,
    activeCountryLabel,
    websiteUrlLocalError,
    noWebsiteUrl,
    websiteUrl,
    setWebsiteUrl,
    validateWebsiteUrl,
    handleSetNoWebsiteUrl,
    socialMediaUrlError,
    socialMediaUrl,
    setSocialMediaUrl,
    init,
  } = businessInfoStore

  useEffect(() => {
    init()
  }, [])

  return (
    <Box>
      <Box marginBottom={'24px'}>
        <TextFieldWithLabel
          errorBorder={Boolean(fieldsError?.business_name)}
          labelProps={{
            label: {
              text: 'Legal business name',
            },
          }}
          textFieldProps={{
            className: styles.textInput,
            value: einItem?.name || '',
            onChange: addBusinessNameManually,
            InputProps: {
              placeholder: 'e.g. Example LLC',
            },
          }}
        />

        {fieldsErrorCommonText?.length && (
          <Alert item={{ desc: fieldsErrorCommonText, type: 'error' }} marginBottom={32} />
        )}
      </Box>
      <WebsiteFields
        {...{
          websiteUrlLocalError,
          noWebsiteUrl,
          websiteUrl,
          setWebsiteUrl,
          handleSetNoWebsiteUrl,
          validateWebsiteUrl,
          socialMediaUrlError,
          socialMediaUrl,
          setSocialMediaUrl,
          fieldsError,
        }}
      />
      <SelectField
        boxWidth='100%'
        labelProps={{
          label: {
            text: 'Country',
          },
        }}
        items={countries}
        value={activeCountryId}
        setValue={setActiveCountry}
        btnProps={{
          size: 'large',
          className: styles.countriesSelect,
        }}
        className={styles.countrySelectWrap}
        dropdownProps={{
          textFieldProps: {
            InputProps: {
              placeholder: 'Search',
            },
          },
        }}
      />

      {activeCountryId && (
        <>
          <TextFieldWithLabel
            marginBottom={3}
            marginTop={'24px'}
            errorBorder={Boolean(fieldsError?.street_address_1)}
            labelProps={{
              label: {
                text: 'Business address',
              },
              marginBottom: 4,
            }}
            textFieldProps={{
              className: styles.textInput,
              value: einItem?.street1 || '',
              onChange: addBusinessAddressManually,
              InputProps: {
                placeholder: 'Search or enter manually your address',
              },
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            errorBorder={Boolean(fieldsError?.street_address_2)}
            labelProps={{
              label: {
                text: 'Business address 2 (optional)',
              },
            }}
            textFieldProps={{
              className: styles.textInput,
              value: einItem?.street2 || '',
              onChange: addBusinessAddress2Manually,
              InputProps: {
                placeholder: 'e.g. Building name',
              },
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            errorBorder={Boolean(fieldsError?.city)}
            labelProps={{
              label: {
                text: 'City',
              },
            }}
            textFieldProps={{
              className: styles.textInput,
              value: einItem?.city || '',
              onChange: addCityManually,
              InputProps: {
                placeholder: activeCountryInfo.placeholder,
              },
            }}
          />

          <Box display='flex' justifyContent='space-between' gap={'12px'}>
            <Box flex={1} marginBottom={'0px'}>
              {activeCountryLabel == 'United States' || activeCountryLabel == 'Canada' ? (
                <SelectField
                  value={einItem?.state}
                  items={states}
                  setValue={addBusinessStateManually}
                  btnProps={{
                    size: 'large',
                    style: {
                      height: '40px',
                    },
                  }}
                  dropdownProps={{
                    textFieldProps: {
                      InputProps: {
                        placeholder: 'Search',
                      },
                    },
                  }}
                  labelProps={{
                    label: {
                      text: activeCountryInfo.state,
                    },
                  }}
                />
              ) : (
                <TextFieldWithLabel
                  labelProps={{
                    label: {
                      text: activeCountryInfo.state,
                    },
                  }}
                  textFieldProps={{
                    className: styles.textInput,
                    value: einItem?.state || '',
                    onChange: addBusinessStateManually,
                    InputProps: {
                      placeholder: activeCountryInfo.statePlaceholer,
                    },
                  }}
                />
              )}
            </Box>
            <Box flex={1} marginBottom={'0px'}>
              <TextFieldWithLabel
                errorBorder={Boolean(fieldsError?.zip_code)}
                errorMessage={zipLocalErrorMessage || undefined}
                labelProps={{
                  label: {
                    text: activeCountryInfo.code,
                  },
                }}
                textFieldProps={{
                  value: zipCode,
                  className: styles.numberOfCreditsInput,
                  onChange: addZipManually,
                  onBlur: checkZipLengthManually,
                  InputProps: {
                    placeholder: activeCountryInfo.codePlaceholder,
                  },
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
})
