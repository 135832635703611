import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { inboxesStore } from 'entities/Inbox'
import { KeywordDetailsStore } from 'pages/keywords/store'
import { useKeywordsPageContext } from './keywordsPageContext'

const KeywordDetailsContext = createContext<KeywordDetailsStore | null>(null)

export const useKeywordDetailsContext = () => {
  const context = useContext(KeywordDetailsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with KeywordDetailsProvider')

  return context
}

export const KeywordDetailsProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams()
  const { pageLayoutStore } = useKeywordsPageContext()
  const store = useMemo(() => new KeywordDetailsStore(id, pageLayoutStore), [])

  useEffect(() => {
    void store.init()
    inboxesStore.fetchInboxes()

    return store.dispose
  }, [])

  return <KeywordDetailsContext.Provider value={store}>{children}</KeywordDetailsContext.Provider>
}
