import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { EmptyState, Table } from 'shared/ui'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { ContactStatisticBulkActions } from 'widgets/ContactStatistic'
import { useKeywordDetailsContext } from 'pages/keywords/context'
import styles from './styles.module.scss'
import { getColumns } from './getColumns'

export const KeywordStatisticsTable = observer(() => {
  const {
    contactStatisticsItems,
    isNoContactData,
    total,
    isContactLoading,
    onPaginationTableChange,
    page,
    limit,
    contactStatisticsTableStore,
    filter,
    search,
    onSuccessDelete,
    contactsTagsModalStore,
    onOpenContactsDetails,
  } = useKeywordDetailsContext()
  const columns = useMemo(() => getColumns(filter, onOpenContactsDetails), [filter])

  if (isNoContactData) {
    return (
      <EmptyState text={'No contacts found'} noIcon boxProps={{ flexGrow: 1, height: undefined }} />
    )
  }

  if (!isContactLoading && !contactStatisticsItems.length) {
    return <NoResultsFound boxProps={{ flexGrow: 1, height: undefined }} />
  }

  return (
    <div className={styles.wrapTable}>
      <Table
        columns={columns}
        rows={contactStatisticsItems}
        rowsCount={total}
        isLoading={isContactLoading}
        onChangePagination={onPaginationTableChange}
        initPage={page}
        limit={limit}
        startPaginationSize={10}
        sxTable={{ marginRight: 2, width: 'calc(100% - 16px)' }}
        withCheckbox
        store={contactStatisticsTableStore}
        headerLeftContentProps={{
          actionsContent: (
            <ContactStatisticBulkActions
              search={search}
              selectedContactIds={contactStatisticsTableStore.selectedIds}
              onSuccessDelete={onSuccessDelete}
              contactsTagsModalStore={contactsTagsModalStore}
            />
          ),
        }}
      />
    </div>
  )
})
