import classNames from 'classnames'
import { ITextFieldProps, TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'

import styles from './styles.module.scss'

export type IDropdownTextInputProps = {
  textFieldProps?: ITextFieldProps
  withFocus?: boolean
  onPressEnter?: () => void
  errorMessageValue?: string
  dropdownTextInputClassName?: string
  ariaLabel?: string
}

export const DropdownTextInput = ({
  textFieldProps,
  withFocus = true,
  onPressEnter,
  errorMessageValue,
  dropdownTextInputClassName,
  ariaLabel,
}: IDropdownTextInputProps) => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    textFieldProps?.InputProps?.onKeyDown?.(event)

    if (event.code === 'Enter') {
      onPressEnter?.()
    }
  }

  return (
    <div className={classNames(styles.wrap, dropdownTextInputClassName)}>
      <TextField
        error={errorMessageValue}
        variant='integrated'
        size='small'
        {...textFieldProps}
        InputProps={{
          ...textFieldProps?.InputProps,
          placeholder: textFieldProps?.InputProps?.placeholder ?? 'Enter text value',
          onKeyDown: onKeyDown,
        }}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus }),
          ...(textFieldProps?.mainActions ?? []),
        ]}
        ariaLabel={getAriaLabel('DropdownTextInput', ariaLabel)}
      />
    </div>
  )
}
