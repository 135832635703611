import React from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, EmptyState } from 'shared/ui'
import { BroadcastsRoutes } from 'entities/ExportRouters'

export const EmptyStateBroadcasts = observer(() => {
  const navigate = useNavigate()
  const onClick = () => {
    navigate(`/${BroadcastsRoutes.root}/${BroadcastsRoutes.create}`)
  }

  return (
    <EmptyState
      iconProps={{ icon: 'broadcasts', color: 'var(--content-neutral-primary)' }}
      title={'Create your first broadcast'}
      text={'Send mass message to drive sales or for reminders'}
      actions={
        <Button
          text={'New broadcast'}
          onClick={onClick}
          iconProps={{ icon: 'add' }}
          size={'medium'}
        />
      }
    />
  )
})
