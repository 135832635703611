import { makeAutoObservable, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import { showToast } from 'shared/ui'
import {
  IntegrationsApi,
  type Integration,
  type IResponseGetIntegrationSettings,
  type IParamsGetIntegrationSettings,
  type IIntegrationSettingsProperties,
} from 'entities/Integrations'

const ERROR_NOTIFICATION = 'We couldn’t update your preference. Please try again later.'

type ISettingKey = IResponseGetIntegrationSettings['data'][number]['key']

type ReturnGetValue<T extends ISettingKey> = T extends 'phone_number_priority'
  ? string | undefined
  : T extends 'properties_mapping'
  ? IIntegrationSettingsProperties[] | undefined
  : number | undefined

export class IntegrationCustomSettingsStore {
  private _status = new RequestLoadingStatus()
  private _requestController = new AbortController()
  private _settingValues = new Map<
    ISettingKey,
    string | number | IIntegrationSettingsProperties[]
  >()
  private _settingsOptions = new Map<ISettingKey, { value: string | number; label: string }[]>()

  constructor(private _integration: Integration) {
    makeAutoObservable(this)
    this.init()
  }

  async init() {
    this.getInitialData()
  }

  getInitialData = () =>
    this._status.blockingLoadData(async () => {
      const [propsList, propsValue] = await Promise.all([
        IntegrationsApi.getIntegrationSettingsList(this._integration.key),
        IntegrationsApi.getIntegrationSettings(this._integration.key),
      ])

      propsValue.data.data.forEach(({ key, value }) => {
        this._settingValues.set(key, value)
      })

      Object.values(propsList.data).forEach((options) => {
        Object.entries(options).forEach(([key, value]) => {
          this._settingsOptions.set(key as ISettingKey, value)
        })
      })
    })

  patchData = (data: IParamsGetIntegrationSettings) => {
    runInAction(() => {
      data.forEach(({ key, value }) => {
        this._settingValues.set(key, value)
      })
    })

    return this._status.loadData(
      async () => {
        this._requestController.abort()
        this._requestController = new AbortController()
        const response = await IntegrationsApi.putIntegrationSettings(this._integration.key, data, {
          signal: this._requestController.signal,
        })
        runInAction(() => {
          response.data.data.forEach(({ key, value }) => {
            if (this._settingValues.get(key) !== value) {
              this._settingValues.set(key, value)
            }
          })
        })
        return response
      },
      (e) => {
        if ((e as Error)?.message === 'canceled') return

        showToast({
          type: 'error',
          title: ERROR_NOTIFICATION,
        })
      }
    )
  }

  getValue = <T extends ISettingKey>(key: T): ReturnGetValue<T> =>
    this._settingValues.get(key) as ReturnGetValue<T>

  getOptions = (key: ISettingKey) => this._settingsOptions.get(key)

  get status() {
    return this._status.status
  }
}
