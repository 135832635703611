import type { ActionItem, IRow } from 'shared/ui'
import type { IQuickLinkTemplate } from 'entities/QuickLink'
import { useQuickLinksContext } from 'pages/settings/pages/quickLinks/context/quickLinksContext'

export type IQuickLinkRow = {
  id: string
  template: IQuickLinkTemplate
}

export const useQuickLinksWithActions = (): IRow<IQuickLinkRow>[] => {
  const { operationStore, listStore } = useQuickLinksContext()

  const makeEditAction = (template: IQuickLinkTemplate): ActionItem => ({
    icon: 'edit',
    text: 'Edit',
    onAction: () => operationStore.edit(template).then(() => undefined),
  })

  const makeDeleteAction = (template: IQuickLinkTemplate): ActionItem => ({
    icon: 'delete',
    text: 'Delete',
    onAction: () =>
      operationStore.delete(template.id).then((deleted) => {
        if (deleted) listStore.refresh()
      }),
  })

  return listStore.quickLinkTemplates.map((template) => ({
    id: `${template.id}`,
    template,
    actionsProps: { actions: [makeEditAction(template), makeDeleteAction(template)] },
  }))
}
