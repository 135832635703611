import classnames from 'classnames'
import { CopyWrapper } from 'shared/ui'
import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseIntegrationPipedriveOrganization,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'

import styles from '../styles.module.scss'

type IOrganizationProps = {
  organization: IResponseIntegrationPipedriveOrganization | null
  contact: Contact
}

const EmptyOrganization = () => <div className={styles.emptyData}>No organization found</div>

const Organization = ({ organization, contact }: IOrganizationProps) => {
  if (!organization) return null
  if (organization.name === '' || !organization.owner) return <EmptyOrganization />

  const integration = integrationsStore.getIntegration(IntegrationKey.pipedrive)

  const linkProps = {
    key: IntegrationKey.pipedrive,
    contact,
    integration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = integrationLink(linkProps)

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={organization.name || null}
          data={{
            title: organization.name || 'View organization',
            link,
            label: 'Pipedrive',
          }}
        />
      </div>
      <div className={styles.itemRow}>
        <div className={styles.label}>Owner</div>
        <TextField
          className={styles.value}
          size='small'
          variant='outline'
          readOnly
          value={organization?.owner?.name || ''}
          rightActions={[
            makeTextFieldInteractions({ type: 'copy', active: !!organization?.owner?.name }),
          ]}
          mainActions={[makeTextFieldTooltip()]}
        />
      </div>
    </div>
  )
}

export { Organization }
