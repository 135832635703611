import React, { PropsWithChildren, ReactNode } from 'react'
import { Typography, TextStylesType } from 'shared/ui/Typography'
import { Icon, type IIconsVector } from 'shared/ui/Icon'
import { Collapse } from '../Collapse'
import styles from './styles.module.scss'

type ICollapseWithIconProps = {
  isOpen?: boolean
  title: ReactNode
  subtitle?: ReactNode
  label?: ReactNode
  icon: IIconsVector
  type?: 'withHover' | 'init'
  titleVariant?: TextStylesType
  subtitleVariant?: TextStylesType
  labelVariant?: TextStylesType
  onClick?: (opened: boolean) => void
  opened?: boolean
  className?: string
}

export const CollapseWithIcon: React.FC<PropsWithChildren<ICollapseWithIconProps>> = ({
  isOpen = false,
  title,
  subtitle,
  label,
  titleVariant = 'body-md-medium',
  subtitleVariant = 'body-md-medium',
  labelVariant = 'body-md-regular',
  type = 'withHover',
  icon,
  children,
  onClick,
  opened,
  className,
}) => {
  return (
    <Collapse
      className={className}
      opened={opened}
      onClick={onClick}
      isOpen={isOpen}
      leftContent={() => (
        <div className={styles.iconWrapper}>
          <Icon fontSize={16} icon={icon} tertiary />
        </div>
      )}
      title={
        <div className={styles.main}>
          <div className={styles.header}>
            <Typography ariaLabel={'title'} variant={titleVariant}>
              {title}
            </Typography>
            {subtitle &&
              (typeof subtitle === 'string' ? (
                <Typography ariaLabel={'subtitle'} variant={subtitleVariant} color={'tertiary'}>
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              ))}
          </div>
          {label && (
            <Typography ariaLabel={'label'} variant={labelVariant} color={'tertiary'}>
              {label}
            </Typography>
          )}
        </div>
      }
      type={type}
    >
      <div className={styles.content}>{children}</div>
    </Collapse>
  )
}
