import { observer } from 'mobx-react-lite'
import { Button, IconButton, Typography } from 'shared/ui'
import { EstimatedResponseInfo } from 'pages/settings/pages/compliance/ui/EstimatedResponseInfo/EstimatedResponseInfo'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import styles from './styles.module.scss'

export const SummaryBusinessProfile = observer(() => {
  const {
    businessProfile,
    businessInformation,
    openSummaryModal,
    getIconConditionByTab,
    businessProfilePageStore,
  } = useCompliancePageContext()

  if (!businessProfile) return null
  if (!businessInformation) return null

  return (
    <div className={styles.wrap} onClick={openSummaryModal}>
      <div className={styles.infoContainer}>
        <div className={styles.leftContent}>
          <Typography
            ariaLabel='AfterRegisterSection_header'
            variant='body-md-medium'
            marginBottom={'4px'}
          >
            {businessInformation.business_name}
          </Typography>
          <Typography
            ariaLabel='AfterRegisterSection_descLine1'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            {businessInformation.street_address_1}
          </Typography>
          <Typography
            ariaLabel='AfterRegisterSection_descLine1'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            {businessInformation.secondLine}
          </Typography>
        </div>
        <div className={styles.rightContent}>
          {businessProfile.isStatusFailed && (
            <Button text='Update' onClick={businessProfilePageStore.updateBusinessProfile} />
          )}
          <IconButton
            ariaLabel='AfterRegisterSection_buttonRight'
            icon={'chevronRight'}
            size={'small'}
            color='transparent'
          />
        </div>
      </div>
      <EstimatedResponseInfo
        dayCount={1}
        labelPart='Business Profile'
        iconData={getIconConditionByTab('BP')}
        status={businessProfile.status}
        submittedAt={businessProfile.submitted_at}
      />
    </div>
  )
})
