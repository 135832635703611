import { makeAutoObservable } from 'mobx'
import { IActionsProps } from 'shared/ui'
import {
  IOrganizationCustomFilter,
  IOrganizationFilter,
  IResponseFilterSegment,
  IResponseFiltersListSegment,
  IResponseSegment,
  IResponseSegmentOld,
} from '../api/types'

export class Segment {
  id: number
  organizationId!: number
  name!: string
  origin!: IResponseSegment | IResponseSegmentOld
  actionsProps?: IActionsProps | null = null
  private _habspotFiltersCount = 0
  private _isFavorite = false
  private _filters!: IResponseFilterSegment[]
  private _filtersParams!: IResponseFiltersListSegment

  constructor(item: IResponseSegment | IResponseSegmentOld) {
    this.id = item.id
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  syncOrigin = (origin: IResponseSegment | IResponseSegmentOld) => {
    this.id = origin.id
    this.organizationId = origin.organization_id
    this.name = origin.name
    this.origin = origin
    this._isFavorite = origin.is_favorite

    this._filters = this.initFilters(origin.filters)
    this._filtersParams = this.initParams(this._filters)

    this._habspotFiltersCount =
      this._filters.reduce((count, item) => {
        const total = item.filters.reduce((count, item) => {
          if (item.key === 'hubspot' || item.key === 'hubspot-lists') return count + 1
          return count
        }, 0)
        return count + total
      }, 0) +
      this._filters.reduce((count, item) => {
        if (!item.integration_lists) return 0

        const total = item.integration_lists.reduce((count, item) => {
          if (item.integration_key === 'hubspot' || item.integration_key === 'hubspot-lists')
            return count + 1
          return count
        }, 0)
        return count + total
      }, 0)
  }

  initFilters = (filters: IResponseFilterSegment[] | IResponseFiltersListSegment) => {
    if (Array.isArray(filters)) {
      return filters
    }

    return filters.filtersList || []
  }

  clearEmptyValue = (item: IOrganizationFilter | IOrganizationCustomFilter) => {
    if (!item.key.startsWith('custom.')) {
      delete item.type
    }

    if (!item.from) delete item.from
    if (!item.to) delete item.to
    if (!item.value) delete item.value

    return item
  }

  initParams = (filters: IResponseFilterSegment[]) => {
    return {
      filtersList: filters.map((item) => {
        item.custom_filters = item.custom_filters ?? []
        item.filter_tags = item.filter_tags ?? []
        item.filters = item.filters ?? []
        item.integration_lists = item.integration_lists ?? []

        item.filters = item.filters
          .map((filter) => this.clearEmptyValue(filter))
          .filter((filter) => filter.key !== 'hubspot' && filter.key !== 'hubspot-lists')

        const hubspotLists = item.filters.filter(
          (filter) => filter.key === 'hubspot' || filter.key === 'hubspot-lists'
        )

        item.integration_lists = hubspotLists.length
          ? hubspotLists.map((item) => ({
              integration_key: item.key,
              list_id: item.value as string,
            }))
          : item.integration_lists || []

        if (!item.integration_lists?.length) delete item.integration_lists

        return item
      }),
    }
  }

  get filtersList(): IResponseFilterSegment[] {
    return this._filters.map((item) => ({
      filters: item.filters ?? [],
      custom_filters: item.custom_filters ?? [],
      filter_tags: item.filter_tags ?? [],
      integration_lists: item.integration_lists ?? [],
    }))
  }

  get filtersParams(): IResponseFiltersListSegment {
    return this._filtersParams
  }

  get filtersCount() {
    return this.filtersParams.filtersList.reduce((state, current) => {
      const countFilters = current.filters?.length || 0
      const countFilterTags = current.filter_tags?.length || 0
      const countCustomFilters = current.custom_filters?.length || 0
      const countIntegrationLists = current.integration_lists?.length || 0

      state += countFilters + countFilterTags + countCustomFilters + countIntegrationLists

      return state
    }, 0)
  }

  get paramsFilterSegment(): IResponseFilterSegment[] {
    const filters: IResponseFilterSegment[] = []
    this.filtersParams.filtersList.forEach((list) => {
      if (
        list.filters.some((filter) =>
          Array.isArray(filter.value) ? filter.value.length : filter.value
        )
      ) {
        filters.push(list)
      }
    })
    return filters
  }

  get hasHubspotFilter() {
    return !!this._habspotFiltersCount
  }

  get hasMultipleHubspotFilter() {
    return this._habspotFiltersCount > 1
  }

  get hubspotFiltersCount() {
    return this._habspotFiltersCount
  }

  get isFavorite() {
    return this._isFavorite
  }

  setActionsProps?: ((actionProps: IActionsProps) => void) | null = (
    actionProps: IActionsProps
  ) => {
    this.actionsProps = actionProps
  }
}
