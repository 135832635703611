import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { PageLayoutStore } from 'shared/layout'
import { toastStore } from 'shared/ui'
import { Segment } from 'entities/Segment/model/Segment'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { segmentsStore } from 'entities/Segment'
import { ContactsTableStore } from 'widgets/ContactsTable'
import { SegmentModalStore } from 'widgets/ContactsMenu/ui/SegmentModal'
import { ContactsFilterStore } from 'widgets/ContactsFilters'
import { menuSegmentsStore } from './menuSegmentsStore'

export class ContactsMenuStore {
  private _search = ''
  private _segmentsSearch = ''
  private _hubspotListsSearch = ''
  private _favoritesSearch = ''

  constructor(
    private _segmentModalStore: SegmentModalStore,
    private _contactsTableStore: ContactsTableStore,
    private _pageLayoutStore: PageLayoutStore,
    private _contactsFilterStore: ContactsFilterStore
  ) {
    makeAutoObservable(this)
  }

  get contactsFilterStore() {
    return this._contactsFilterStore
  }

  get search() {
    return this._search
  }
  get segmentsSearch() {
    return this._segmentsSearch
  }

  get hubspotListsSearch() {
    return this._hubspotListsSearch
  }
  get favoritesSearch() {
    return this._favoritesSearch
  }

  get segmentsAll() {
    return menuSegmentsStore.items.filter((filter) =>
      filter.name
        .toLocaleLowerCase()
        .includes(this._segmentsSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase())
    )
  }
  get segments() {
    return menuSegmentsStore.items
      .filter((filter) => !filter.isFavorite)
      .filter((filter) => !filter.hasHubspotFilter)
      .filter((filter) =>
        filter.name
          .toLocaleLowerCase()
          .includes(this._segmentsSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase())
      )
  }

  get hubspotLists() {
    return this.hasHubspotConnected()
      ? menuSegmentsStore.items
          .filter((filter) => !filter.isFavorite)
          .filter((filter) => filter.hasHubspotFilter)
          .filter((filter) =>
            filter.name
              .toLocaleLowerCase()
              .includes(
                this._hubspotListsSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase()
              )
          )
      : []
  }

  get favorites() {
    return menuSegmentsStore.items
      .filter((filter) => {
        if (!this.hasHubspotConnected()) {
          return !filter.hasHubspotFilter
        }

        return true
      })
      .filter((filter) => filter.isFavorite)
      .filter((filter) =>
        filter.name
          .toLocaleLowerCase()
          .includes(this._favoritesSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase())
      )
  }

  get activeSegment() {
    return this._contactsFilterStore.segment
  }

  get isNewSegment() {
    return this._contactsFilterStore.segment?.id === -1
  }

  get activeSegmentId() {
    return this._contactsFilterStore.segment?.id ?? null
  }

  setActiveSegment = (segment: Segment) => {
    this._contactsFilterStore.setSegment(segment)
  }

  changeActiveSegment = (segment: Segment) => {
    this.setActiveSegment(segment)
    this._contactsTableStore?.setActiveContact(null)
    this._contactsTableStore?.changeNewContactMode(false)
  }

  dispose = () => {}

  resetActive = () => {
    this._contactsFilterStore.clearSegment()
  }

  setSearch = (value: string) => {
    this._search = value
  }

  setSegmentsSearch = (value: string) => {
    this._segmentsSearch = value
  }
  setHubspotListsSearch = (value: string) => {
    this._hubspotListsSearch = value
  }
  setFavoritesSearch = (value: string) => {
    this._favoritesSearch = value
  }

  hasHubspotConnected = () => {
    const integration = integrationsStore.getIntegration(IntegrationKey.hubspot)

    return !!integration?.isConnected
  }

  editFavorite = async ({ afterEdit, segment }: { afterEdit?: () => void; segment: Segment }) => {
    if (!segment) return

    try {
      await segmentsStore.updateItem(segment.id, {
        is_favorite: !segment.isFavorite,
      })

      afterEdit?.()
    } catch (e) {
      this.errorHandler(e as AxiosError<{ name?: string[] }>)
    }
  }

  errorHandler = (error: AxiosError<{ name?: string[] }>) => {
    const errorMessage = error.response?.data?.name ? error.response.data.name[0] : error.message

    toastStore.add({
      title: errorMessage,
      type: 'error',
    })
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get segmentModalStore() {
    return this._segmentModalStore
  }
}
