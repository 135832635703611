import { makeAutoObservable } from 'mobx'
import { type IActionsProps, IAvatarInfo } from 'shared/ui'
import type { IFullResponseCampaign, IShortResponseCampaign } from 'entities/Campaign/api/types'
import { type ICampaignStatus } from 'entities/Campaign/ui/CampaignStatus'
import { CampaignStatistics } from 'entities/Campaign/model/CampaignStatistics'

export class Campaign {
  id: number
  name: string
  status: ICampaignStatus
  statistics: CampaignStatistics | null
  steps: IFullResponseCampaign['steps'] | null = null
  user: IShortResponseCampaign['user'] | null = null

  constructor(item: IFullResponseCampaign | IShortResponseCampaign) {
    this.id = item.id
    this.name = item.name
    this.status = item.status
    if ('steps' in item) {
      this.steps = item.steps
    }
    if ('user' in item) {
      this.user = item.user
    }
    this.statistics = item.analytics ? new CampaignStatistics(item.analytics) : null

    makeAutoObservable(this)
  }

  syncOrigin = (item: IFullResponseCampaign) => {
    this.id = item.id
    this.name = item.name
    this.status = item.status
    this.steps = item.steps
    if (item.analytics) {
      if (this.statistics) {
        this.statistics?.syncOrigin(item.analytics)
      } else {
        this.statistics = new CampaignStatistics(item.analytics)
      }
    } else {
      this.statistics = null
    }
  }

  actionsProps: IActionsProps | null = null

  setActionsProps: (actionProps: IActionsProps) => void = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }

  get userAvatarInfo(): IAvatarInfo | null {
    if (this.user) {
      return {
        firstName: this.user.first_name,
        lastName: this.user.last_name,
        number: this.user.number,
        image: this.user.photo_url,
      }
    }
    return null
  }

  get userDisplayInfo() {
    if (this.user) {
      const name = `${this.user.first_name || ''} ${this.user.last_name || ''}`.trim()
      return name || this.user?.number
    }
    return ''
  }
}
