import React from 'react'
import { observer } from 'mobx-react-lite'
import { KeywordViewStore } from 'pages/keywords/store'
import { KeywordViewContext } from 'pages/keywords/context'
import { KeywordViewContent } from 'pages/keywords/pages/createKeyword'

type IEditKeywordViewProps = {
  store: KeywordViewStore
}

export const EditKeywordView = observer(({ store }: IEditKeywordViewProps) => {
  return (
    <KeywordViewContext.Provider value={store}>
      <KeywordViewContent />
    </KeywordViewContext.Provider>
  )
})
