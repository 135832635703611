import { FilterItem } from 'widgets/FilterEditor/model'

export const TriggerStatusKeys = new Set([
  'workflows.is_active',
  'keywords.is_active',
  'keywords.status',
  'workflows.status',
])

export const isTriggerStatusWidget = (filterItem: FilterItem) =>
  TriggerStatusKeys.has(filterItem.key)
