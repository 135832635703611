export enum TriggersRoutes {
  root = 'triggers',
  all = 'all',
  sms = 'sms',
  ringless = 'ringless',
  create = 'create',
  edit = 'edit',
  clone = 'clone',
  view = 'view',
}
