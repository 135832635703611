// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ua_9ms3TXhoHbcMpB9my{position:relative}.wIrrkYUVIkW21d_XHdXd{position:absolute;left:0;top:calc(100% + 4px)}.wIrrkYUVIkW21d_XHdXd>*:not(:empty){width:428px;background-color:var(--background-primary-inverted-primary);padding:4px;border-radius:8px;box-shadow:0 0 0 1px rgba(18,21,23,.05),0 10px 30px -5px rgba(18,21,23,.1),0 20px 50px 0px rgba(18,21,23,.05)}.JGHP7W6PPCOYOpteIla2{width:480px}.Eg7dsI6HCl0pUJbYs7N7{width:max-content;min-width:472px}.LWA6vfwPL_0mxCmTrPTM{font-size:13px;color:var(--content-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/ContactEnrollment/ui/EnrollmentSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAGJ,sBACI,iBAAA,CACA,MAAA,CACA,oBAAA,CAEA,oCACI,WAAA,CACA,2DAAA,CACA,WAAA,CACA,iBAAA,CACA,6GACI,CAMZ,sBACE,WAAA,CAGF,sBACE,iBAAA,CACA,eAAA,CAGF,sBACE,cAAA,CACA,oCAAA","sourcesContent":[".contactsDropdownContainer {\n    position: relative;\n}\n\n.contactsDropdownContent {\n    position: absolute;\n    left: 0;\n    top: calc(100% + 4px);\n\n    & > *:not(:empty) {\n        width: 428px;\n        background-color: var(--background-primary-inverted-primary);\n        padding: 4px;\n        border-radius: 8px;\n        box-shadow:\n            0 0 0 1px rgba(18, 21, 23, 0.05),\n            0 10px 30px -5px rgba(18, 21, 23, 0.10),\n            0 20px 50px 0px rgba(18, 21, 23, 0.05);\n    }\n}\n\n.dropdownContent--width480 {\n  width: 480px;\n}\n\n.dropdownContent--width472 {\n  width: max-content;\n  min-width: 472px;\n}\n\n.placeholder {\n  font-size: 13px;\n  color: var(--content-neutral-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactsDropdownContainer": "ua_9ms3TXhoHbcMpB9my",
	"contactsDropdownContent": "wIrrkYUVIkW21d_XHdXd",
	"dropdownContent--width480": "JGHP7W6PPCOYOpteIla2",
	"dropdownContent--width472": "Eg7dsI6HCl0pUJbYs7N7",
	"placeholder": "LWA6vfwPL_0mxCmTrPTM"
};
export default ___CSS_LOADER_EXPORT___;
