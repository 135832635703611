import { observer } from 'mobx-react-lite'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { TextareaWithLabel } from 'shared/ui/Textarea'
import { Box, Button } from 'shared/ui'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import {
  sampleMessageMaxTextLength,
  sampleMessageMinTextLength,
  useCaseDescMaxTextLength,
  useCaseDescMinTextLength,
} from 'pages/settings/pages/compliance/pages/tollFree/pages/UseCase/store/useCaseStore'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import styles from './styles.module.scss'

export const UseCase = observer(() => {
  const {
    stepLoading,
    useCaseStore: {
      useCases,
      useCaseValue,
      useCaseDesc,
      isNextDisabled,
      setUseCase,
      setUseCaseDesc,
      openUseCaseExampleModal,
      nextStepClick,
      messageVolume,
      setMessageVolume,
      messageVolumeValue,
      sampleMessage,
      setSampleMessage,
      openSampleMessageModal,
    },
  } = useTollFreeStepsContext()

  return (
    <div className={styles.wrap}>
      <PageDescription>
        Select the use case that best describes the type of messaging you plan to send.
      </PageDescription>
      <Box marginTop={5}>
        <SelectField
          labelText='Estimated monthly messages'
          items={messageVolume}
          setValue={setMessageVolume}
          value={messageVolumeValue}
          btnProps={{
            size: 'extraLarge',
            className: styles.select,
          }}
          dropdownProps={{
            textFieldProps: {
              InputProps: {
                placeholder: 'Search',
              },
            },
          }}
        />
      </Box>
      <Box marginTop={3}>
        <SelectField
          labelText='Use case'
          items={useCases}
          setValue={setUseCase}
          value={useCaseValue}
          btnProps={{
            size: 'extraLarge',
            className: styles.select,
          }}
          dropdownProps={{
            textFieldProps: {
              InputProps: {
                placeholder: 'Search',
              },
            },
          }}
        />
      </Box>

      {useCaseValue && (
        <>
          <TextareaWithLabel
            className={styles.useCaseDesc}
            value={useCaseDesc}
            limit={useCaseDescMaxTextLength}
            minLimit={useCaseDescMinTextLength}
            errorType='bottomText'
            name='description'
            placeholder='Add a brief description of how the use case you selected applies for your business'
            onChange={setUseCaseDesc}
            labelProps={{
              marginBottom: 0,
              label: {
                text: 'Use case description',
                rightNode: (
                  <Button
                    onClick={openUseCaseExampleModal}
                    text={'See example'}
                    size='small'
                    typeBtn='informative'
                  />
                ),
              },
            }}
          />
          <TextareaWithLabel
            className={styles.sampleMessageDesc}
            value={sampleMessage}
            limit={sampleMessageMaxTextLength}
            minLimit={sampleMessageMinTextLength}
            errorType='bottomText'
            placeholder='Enter the most common message you send to your contacts'
            onChange={setSampleMessage}
            labelProps={{
              marginBottom: 0,
              label: {
                text: 'Sample message',
                rightText: 'See example',
                rightNode: (
                  <Button
                    onClick={openSampleMessageModal}
                    text={'See example'}
                    size='small'
                    typeBtn='informative'
                  />
                ),
              },
            }}
          />
        </>
      )}

      <NextButton
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        text='Next'
        onClick={nextStepClick}
      />
    </div>
  )
})
