import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import Layout from 'shared/layout/Layout/ui/Layout'
import { initPosthog } from 'entities/Posthog'
import { initCanny } from 'entities/Canny'
import { eventHeartbeat } from 'entities/DataLog/events/EventHeartbeatLog'
import { authStore } from 'entities/Auth'
import { initIntercom } from 'entities/Intercom'
import { numbersStore } from 'entities/Phone'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { remindersStore } from 'widgets/Reminders'
import { Sidebar } from 'widgets/Sidebar'
import { getAppData } from 'app/shared/lib'
import { SidebarLayout } from './SidebarLayout'

type IAuthLayoutVueProps = {
  type?: 'default' | 'sidebar'
}

export const AuthLayoutVue: FC<IAuthLayoutVueProps> = observer(({ type }) => {
  useEffect(() => {
    if (!authStore.isLoggedIn) {
      return
    }

    getAppData()
    initPosthog()
    initCanny()
    initIntercom(true)
    numbersStore.initStartNumberModal()
    numbersStore.initConnectTwilioModal()
    remindersStore.init()
    allMergeFieldsStore.loadAllFields()

    eventHeartbeat()
  }, [authStore.isLoggedIn])

  if (!authStore.isLoggedIn) return null
  if (type === 'sidebar') {
    return <Sidebar />
  }

  if (type === 'default') {
    return <Layout />
  }

  return <SidebarLayout />
})
