import { makeAutoObservable } from 'mobx'
import type { IAlertProps } from 'shared/ui/Alert/types'
import { uiStore } from 'shared/store/uiStore'
import { Button } from 'shared/ui'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'
import styles from 'entities/Subscription/ui/TrialBroadcastLimitAlert/ui/styles.module.scss'

type ITrialBroadcastLimitAlertStoreConfig = {
  onCloseModal?: () => void
}

export class TrialBroadcastLimitAlertStore {
  constructor(private _config?: ITrialBroadcastLimitAlertStoreConfig) {
    makeAutoObservable(this)
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get loading() {
    return this.trialSubscription.loading
  }

  init = async () => {
    if (!subscriptionStore.isTrial) return

    await this.trialSubscription.init()
  }

  get isCompleted() {
    return this.trialSubscription.isStatusCompleted
  }

  get configAlert(): IAlertProps {
    if (this.isAdminOrOwnerRole) {
      return {
        item: {
          type: 'infoBlue',
          title: 'Your trial is limited to 25 contacts.',
          desc: this.isCompleted
            ? 'To remove the limits, please upgrade.'
            : 'To remove the limits, get approved and upgrade.',
        },
        rightAction: (
          <Button
            className={styles.btn}
            size='small'
            typeBtn='outlined'
            contained={'secondary'}
            text={this.isCompleted ? 'Upgrade' : 'Get approved'}
            onClick={() => {
              if (this.isCompleted) {
                uiStore.changeRouteViaVue({
                  path: '/settings/billing/overview/upgrade',
                })
              } else {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance',
                })
              }

              this._config?.onCloseModal?.()
            }}
          />
        ),
      }
    }

    return {
      item: {
        type: 'infoBlue',
        title: 'Your trial is limited to 25 contacts.',
        desc: this.isCompleted
          ? 'To remove the limits, please ask one of your admins to upgrade.'
          : 'To remove the limits, please ask one of your admins to get approved and upgrade.',
      },
    }
  }

  get isAdminOrOwnerRole() {
    return usersStore.user?.isAdminOrOwnerRole
  }

  get hide() {
    if (this.loading) return true

    return !subscriptionStore.isTrial
  }
}
