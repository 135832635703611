import { Route, Routes } from 'react-router'
import { TollFreeAccessLayout } from 'pages/settings/pages/compliance/pages/tollFree/layout/TollFreeAccessLayout'
import { TollFreeStepsLayout } from 'pages/settings/pages/compliance/pages/tollFree/layout/TollFreeStepsLayout'
import { Numbers } from 'pages/settings/pages/compliance/pages/tollFree/pages/Numbers/ui/Numbers'
import { BusinessInfo } from 'pages/settings/pages/compliance/pages/tollFree/pages/BusinessInfo/ui/BusinessInfo'
import { ContactInfo } from 'pages/settings/pages/compliance/pages/tollFree/pages/ContactInfo/ui/ContactInfo'
import { TollFreeProviderLayout } from 'pages/settings/pages/compliance/pages/tollFree/layout/TollFreeProviderLayout'
import { UseCase } from 'pages/settings/pages/compliance/pages/tollFree/pages/UseCase/ui/UseCase'
import { OptInMethod } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInMethod/ui/OptInMethod'
import { OptInViaTextConsent } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInConsent/ui/OptInViaTextConsent'
import { OptInOnlineFormConsent } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInConsent/ui/OptInOnlineFormConsent'
import { OptInPaperFormConsent } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInConsent/ui/OptInPaperFormConsent'
import { OptInQrCodeFormConsent } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInConsent/ui/OptInQrCodeFormConsent'
import { ForbiddenMessageTopics } from 'pages/settings/pages/compliance/pages/tollFree/pages/ForbiddenMessageTopics/ui/ForbiddenMessageTopics'
import { TollFreeRoutesSteps } from './type'

export const TollFreeStepsRouter = () => {
  return (
    <Routes>
      <Route element={<TollFreeProviderLayout />}>
        <Route element={<TollFreeAccessLayout />}>
          <Route element={<TollFreeStepsLayout />}>
            <Route path={TollFreeRoutesSteps.numbers} element={<Numbers />} />
            <Route path={TollFreeRoutesSteps.businessInfo} element={<BusinessInfo />} />
            <Route path={TollFreeRoutesSteps.contactInfo} element={<ContactInfo />} />
            <Route path={TollFreeRoutesSteps.useCase} element={<UseCase />} />
            <Route path={TollFreeRoutesSteps.optInMethod} element={<OptInMethod />} />
            <Route
              path={TollFreeRoutesSteps.optInOnlineFormContent}
              element={<OptInOnlineFormConsent />}
            />
            <Route
              path={TollFreeRoutesSteps.optInPaperFormContent}
              element={<OptInPaperFormConsent />}
            />
            <Route
              path={TollFreeRoutesSteps.optInViaTextContent}
              element={<OptInViaTextConsent />}
            />
            <Route
              path={TollFreeRoutesSteps.optInQrCodeContent}
              element={<OptInQrCodeFormConsent />}
            />
            <Route
              path={TollFreeRoutesSteps.forbiddenMessageTopics}
              element={<ForbiddenMessageTopics />}
            />

            <Route path='*' element={<div>Page Not Found</div>} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
