// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GrVDVi8FIHAAT0DKORiy{display:flex;gap:8px;margin-top:12px}.mklQuvOA0n2r3EBCkdxa{margin-top:24px;margin-bottom:-8px}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerActions/ui/TriggerDetailsModal/ui/DetailsTriggerHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,kBAAA","sourcesContent":[".info {\n  display: flex;\n  gap: 8px;\n  margin-top: 12px;\n}\n\n.alert {\n  margin-top: 24px;\n  margin-bottom: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "GrVDVi8FIHAAT0DKORiy",
	"alert": "mklQuvOA0n2r3EBCkdxa"
};
export default ___CSS_LOADER_EXPORT___;
