import { BroadcastsRoutes } from './type'

const broadcasts = `${BroadcastsRoutes.root}`
const broadcastsAll = `${broadcasts}/${BroadcastsRoutes.all}`
const broadcastsOneTime = `${broadcasts}/${BroadcastsRoutes.oneTime}`
const broadcastsRecurring = `${broadcasts}/${BroadcastsRoutes.recurring}`
const broadcastsCreate = `${broadcasts}/${BroadcastsRoutes.create}`
const broadcastsEdit = `${broadcasts}/${BroadcastsRoutes.edit}`
const broadcastsClone = `${broadcasts}/${BroadcastsRoutes.clone}`
const broadcastsView = `${broadcasts}/${BroadcastsRoutes.view}`
const broadcastsRingless = `${broadcasts}/${BroadcastsRoutes.ringless}`

export const BroadcastsPath = {
  root: broadcasts,
  all: broadcastsAll,
  oneTime: broadcastsOneTime,
  recurring: broadcastsRecurring,
  ringless: broadcastsRingless,
  create: broadcastsCreate,
  edit: broadcastsEdit,
  clone: broadcastsClone,
  view: broadcastsView,
}
