import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DropdownContent,
  DropdownGroup,
  dropDownStore,
  DropdownTextInput,
  Scrollbar,
  SpinnerLoader,
} from 'shared/ui'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { IHubspotPropertiesObjectType } from 'entities/Integrations'
import { useNewHubspotPropertiesDropdownContext } from 'widgets/NewHubspotPropertiesDropdown/context/newHubspotPropertiesDropdownContext'
import styles from './styles.module.scss'

type INewHubspotPropertiesDropdownContentProps = {
  dropdownId: string
  sourceType: IUiSettingsSourceType
  objectType?: IHubspotPropertiesObjectType
  objectId?: number
}

export const NewHubspotPropertiesDropdownContent: FC<INewHubspotPropertiesDropdownContentProps> =
  observer(({ dropdownId, sourceType, objectType, objectId }) => {
    const store = useNewHubspotPropertiesDropdownContext()

    useEffect(() => {
      store.setDropdownId(dropdownId)
      store.searchFields('', true, sourceType, objectType, objectId)

      return () => store.reset()
    }, [])

    return (
      <DropdownContent noPadding>
        <DropdownTextInput
          textFieldProps={{
            value: store.search,
            onChange: (term) => store.searchFields(term, false, sourceType, objectType, objectId),
            InputProps: {
              placeholder: 'Search',
            },
          }}
        />
        {store.loading && (
          <div className={styles.loader}>
            <SpinnerLoader size={20} />
          </div>
        )}

        {!store.loading && !!store.properties.length && (
          <Scrollbar
            autoHeight
            autoHeightMin={0}
            autoHeightMax={178}
            onScroll={() => {
              dropDownStore.allHide(dropdownId)
            }}
          >
            <DropdownGroup
              items={store.properties}
              onChange={(item) => {
                store.addToSelectedFields(item.id)
              }}
              isScroll={false}
            />
          </Scrollbar>
        )}

        {!store.loading && !store.properties.length && (
          <div className={styles.noResult}>No results found</div>
        )}
      </DropdownContent>
    )
  })
