import { observer } from 'mobx-react-lite'
import { Icon, InputCheckbox, Tooltip, Typography } from 'shared/ui'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import styles from './styles.module.scss'

export const ManualInvoice = observer(() => {
  const { isManualInvoice, setManualInvoice, isChangedPlan } = adminOrganizationCustomPlanStore
  if (!isChangedPlan) {
    return null
  }
  return (
    <div className={styles.wrap}>
      <InputCheckbox
        checked={isManualInvoice}
        onChecked={() => setManualInvoice(!isManualInvoice)}
        customLabel={
          <div className={styles.label}>
            <Typography
              ariaLabel={'Manual Invoice'}
              variant={'text-input-field'}
              color={'tertiary'}
            >
              Pay by Manual Invoice
            </Typography>
            <Tooltip
              label={[
                'If selected, an invoice will be emailed to',
                <br key={'br'} />,
                'the customer for manual payment',
                <br key={'br'} />,
                <br key={'br'} />,
                'Can’t be applied together with Payment',
                <br key={'br'} />,
                'schedule.',
              ]}
              placement={'top'}
            >
              <Icon icon={'question'} tertiary />
            </Tooltip>
          </div>
        }
      />
    </div>
  )
})
