const byteFormatter = new Intl.NumberFormat('en-US', {
  style: 'unit',
  unit: 'byte',
  notation: 'compact',
  unitDisplay: 'narrow',
})

const MeasureUnit = {
  SI: 1000,
  IEC: 1024,
}

const getUnitFactor = (value: number, unit: number) =>
  Math.pow(unit, Math.floor(Math.log(value) / Math.log(unit)))

export const formatBytes = (value: number, factor = 1) => {
  const iecBytes = value * factor

  const iecFactor = getUnitFactor(iecBytes, MeasureUnit.IEC)
  const siFactor = getUnitFactor(iecBytes, MeasureUnit.SI)

  const siBytes = (iecBytes / iecFactor) * siFactor

  return byteFormatter.format(siBytes).replace(/(\d[\d.,]*)\s*(\D)/, '$1 $2')
}

export const formatBytesToNumber = (value: number) => {
  const formattedBytes = formatBytes(value)
  const clearedValue = +formattedBytes.replace(/[^\d.]/g, '')

  return formattedBytes.includes('MB') ? clearedValue * 1000 : clearedValue
}

export const formatKilobytes = (value: number, factor = 1024) => formatBytes(value, factor)

export const formatMegabytes = (value: number, factor = 1024) => formatKilobytes(value, factor)
