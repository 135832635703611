import { useState, useEffect } from 'react'

export const setLocalStorageItem = (key: string, value: string) => {
  const data = window.localStorage.getItem(key)

  window.localStorage.setItem(key, value)

  const event = new StorageEvent('storage', {
    bubbles: true,
    cancelable: false,
    key: key,
    oldValue: data,
    newValue: value,
  })

  window.dispatchEvent(event)

  return value
}

export const useLocalStorage = (key: string) => {
  const value = window.localStorage.getItem(key)
  const [state, setState] = useState(value)

  const storageListener = (event: StorageEvent) => {
    if (event.key === key && event.newValue) {
      setState(event.newValue)
    }
  }

  useEffect(() => {
    window.addEventListener('storage', storageListener, false)

    return () => window.removeEventListener('storage', storageListener)
  }, [])

  return state
}
