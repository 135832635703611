import {
  ComponentPropsWithoutRef,
  useRef,
  useState,
  useEffect,
  CSSProperties,
  createElement,
} from 'react'
import classNames from 'classnames'
import { Tooltip, type ITooltipProps } from 'shared/ui/Tooltip'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

const variant = [
  'text-input-helper',
  'body-xs-regular',
  'body-xs-medium',
  'body-xxs-medium',
  'body-sm-regular',
  'body-sm-medium',
  'body-rg-regular',
  'body-rg-medium',
  'body-lg-medium',
  'body-lg-regular',
  'body-lg-semibold',
  'body-md-regular',
  'body-md-medium',
  'body-md-semibold',
  'heading-sm',
  'heading-md',
  'heading-lg',
  'text-input-label-small',
  'text-input-field',
  'button-small',
  'button-medium',
  'body-small-medium',
  'text-input-field-r',
] as const

export type TextStylesType = (typeof variant)[number]

export type ITypographyProps = ComponentPropsWithoutRef<'span'> & {
  className?: string
  variant?: TextStylesType
  tag?: 'span' | 'div'
  ellipsis?: boolean
  onShowEllipsis?: (status: boolean) => void
  link?: boolean
  linkLight?: boolean
  color?: 'tertiary' | 'negativePrimary' | string
  pointer?: boolean
  marginBottom?: CSSProperties['marginBottom']
  marginTop?: CSSProperties['marginBottom']
  textDecoration?: string
  tooltipProps?: ITooltipProps
  width?: number | string
  ariaLabel: string
}

export const Typography = ({
  className,
  variant = 'body-rg-regular',
  tag = 'span',
  ellipsis,
  onShowEllipsis,
  link,
  linkLight,
  color,
  pointer,
  marginBottom,
  marginTop,
  tooltipProps,
  width,
  ariaLabel,
  ...props
}: ITypographyProps) => {
  const objColor: { [key: string]: string } = {
    tertiary: 'var(--content-primary-tertiary)',
    negativePrimary: 'var(--content-negative-primary)',
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [elementWidth, setElementWidth] = useState<number | null>(null)
  useEffect(() => {
    if (!tooltipProps) return

    const element = ref.current
    if (!element) return

    const show = element.scrollWidth > element.clientWidth

    if (onShowEllipsis) {
      onShowEllipsis(show)
    } else {
      setShowTooltip(show)
    }

    if (show) setElementWidth(element.clientWidth)
  }, [])

  ariaLabel = `Typography_${ariaLabel}`

  const Component = createElement(tag, {
    ...props,
    style: {
      color: color ? objColor[color] || color : undefined,
      ...(pointer ? { pointer: 'pointer' } : null),
      marginBottom,
      marginTop,
      width: elementWidth || width || 'auto',
    },
    ref,
    className: classNames(
      styles.wrap,
      styles[variant],
      ellipsis && styles.ellipsis,
      link && styles.link,
      linkLight && styles.linkLight,
      className
    ),
    ...(ariaLabel
      ? {
          'aria-label': getAriaLabel(ariaLabel),
        }
      : null),
  })

  if (tooltipProps) {
    return (
      <Tooltip
        placement='top'
        disableInteractive
        {...tooltipProps}
        label={showTooltip ? tooltipProps.label : null}
      >
        {Component}
      </Tooltip>
    )
  }

  return Component
}
