import { ActionItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { type IKeyword } from 'entities/Keywords'
import { KeywordsRoutes } from 'pages/keywords'
import { KeywordActionsStore } from 'pages/keywords/store'
import { KeywordSwitch, KeywordSwitchAction } from './TableActions'

export const keywordActionsStore = new KeywordActionsStore()

const keywordActions = {
  active: (keyword: IKeyword): ActionItem => ({
    dropdownItemComponent: () => (
      <KeywordSwitchAction
        keyword={keyword}
        toggleKeywordStatus={keywordActionsStore.updateStatus}
      />
    ),
    buttonComponent: (
      <KeywordSwitch keyword={keyword} toggleKeywordStatus={keywordActionsStore.updateStatus} />
    ),
  }),
  edit: (keyword: IKeyword): ActionItem => ({
    icon: 'edit',
    onAction: () => {
      uiStore.changeRoute({
        path: `/${KeywordsRoutes.root}/${KeywordsRoutes.edit}/${keyword.id}`,
      })
    },
    text: 'Edit',
  }),
  detail: (keyword: IKeyword): ActionItem => ({
    icon: 'eye',
    onAction: () => keywordActionsStore.onDetails(keyword),
    text: 'Details',
  }),
  duplicate: (keyword: IKeyword): ActionItem => ({
    icon: 'copy',
    onAction: () => {
      uiStore.changeRoute({
        path: `/${KeywordsRoutes.root}/${KeywordsRoutes.clone}/${keyword.id}`,
      })
    },
    text: 'Duplicate',
  }),
  delete: (keyword: IKeyword, onSuccess?: () => void): ActionItem => ({
    icon: 'delete',
    onAction: () => keywordActionsStore.onDelete(keyword, onSuccess),
    text: 'Delete',
  }),
}

export const getKeywordActions = ({
  keyword,
  onSuccessDelete,
  isInvalid,
}: {
  keyword: IKeyword
  onSuccessDelete?: () => void
  isInvalid?: boolean
}): ActionItem[] => {
  const actions = [
    keywordActions.edit(keyword),
    keywordActions.detail(keyword),
    keywordActions.duplicate(keyword),
    keywordActions.delete(keyword, onSuccessDelete),
  ]

  if (!isInvalid) actions.unshift(keywordActions.active(keyword))

  return actions
}
