// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".li75k9Qej7Gm8oqixQhg{display:flex;justify-content:space-between}.T2aVQ1h7PMmMJMquBXlh{min-height:32px;flex:0 0 125px;color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/widgets/TagsDropdown/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,qCAAA","sourcesContent":[".wrap {\n  display: flex;\n  justify-content: space-between;\n}\n\n.label {\n  min-height: 32px;\n  flex: 0 0 125px;\n  color: var(--content-primary-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "li75k9Qej7Gm8oqixQhg",
	"label": "T2aVQ1h7PMmMJMquBXlh"
};
export default ___CSS_LOADER_EXPORT___;
