import { Modal } from 'shared/ui'

const OverlayLayout = () => {
  return (
    <>
      <Modal />
    </>
  )
}

export default OverlayLayout
