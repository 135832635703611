import { Route, Routes } from 'react-router'
import { TollFreeStepsRouter } from 'pages/settings/pages/compliance/pages/tollFree/route/tollFreeStepsRouter'
import { TollFreeRoutes } from 'pages/settings/pages/compliance/pages/tollFree/route/type'
import { ViewPage } from 'pages/settings/pages/compliance/pages/tollFree/pages/View/ViewPage'

export const TollFreeRootRouter = () => {
  return (
    <Routes>
      <Route path={`/${TollFreeRoutes.view}/:profile_id`} element={<ViewPage />} />
      <Route
        path={`/${TollFreeRoutes.view}/:profile_id/${TollFreeRoutes.steps}/*`}
        element={<TollFreeStepsRouter />}
      />
      <Route path={`/${TollFreeRoutes.steps}/*`} element={<TollFreeStepsRouter />} />
    </Routes>
  )
}
