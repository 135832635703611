import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import {
  Button,
  EmptyState,
  IIconsVector,
  InputRadio,
  Scrollbar,
  SpinnerLoader,
  type IDropdownItem,
  Box,
} from 'shared/ui'
import { Select } from 'shared/ui/Select/Select'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { type GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'
import { IResponseBillingAreaCode, IResponseBillingState } from 'entities/Billing/api/types'
import { ITabsEnum } from 'entities/Phone/ui/GetNumberModal/store/types'
import { ICountriesEnum } from 'entities/Phone/api/types'
import styles from './styles.module.scss'

type IAvailableTabContentProps = {
  store: GetNumberModalStore
}

export const AvailableTabContent = observer(({ store }: IAvailableTabContentProps) => {
  const {
    noResults,
    numbersList,
    selectedNumber,
    activeTab,
    country,
    countries,
    countryData,
    state,
    states,
    areaCode,
    areaCodes,
    canSearch,
    statePlaceholder,
    loading,
    loadingNumber,
    refresh,
    setNumber,
    setCountry,
    setState,
    setAreaCode,
  } = store

  const handleState = (item: IDropdownItem) => {
    if (loading) return
    setState(item.customFields as IResponseBillingState)
  }

  const handleAreaCode = (item: IDropdownItem) => {
    if (loading) return
    setAreaCode(item.customFields as IResponseBillingAreaCode)
  }

  const handleCountry = (item: ICountriesEnum) => {
    if (loading) return
    setCountry(item)
  }

  return (
    <>
      <Box marginBottom={3}>
        <SelectField
          labelText='Country'
          value={country}
          items={countries}
          setValue={handleCountry}
          selectProps={{
            dropdownProps: {
              margin: -40,
              withSearch: false,
              withTextInput: false,
              ariaLabel: 'Country',
            },
          }}
          btnProps={{
            className: styles.textField,
            text: countryData ? countryData.name : 'Select',
            textIcon: countryData ? (countryData.flag as IIconsVector) : undefined,
          }}
        />
      </Box>

      {activeTab === ITabsEnum.LOCAL && (
        <>
          <Box marginBottom={3}>
            <Select
              fullWidth
              btnProps={{
                className: styles.textField,
                fullWidth: true,
                typeBtn: 'stroke',
                justifyContent: 'space-between',
                fontWeight: !state ? 400 : undefined,
                style: {
                  color: !state ? 'var(--content-neutral-primary)' : undefined,
                },
                text: state ? `${state.state_code} - ${state.state_name}` : statePlaceholder,
                textIcon: 'makerPin',
              }}
              dropdownProps={{
                margin: -40,
                textFieldProps: {
                  leftActions: [
                    makeTextFieldIcon({ icon: 'makerPin', className: styles.textFieldIcon }),
                  ],
                  InputProps: {
                    placeholder: 'Search',
                  },
                },
                ariaLabel: 'state',
              }}
              textFieldPlaceholderProps={{
                size: 'medium',
                variant: 'stroke',
              }}
              text={''}
              value={state?.state_code}
              items={states}
              onSelect={handleState}
            />
          </Box>

          <Box marginBottom={3}>
            <Select
              fullWidth
              btnProps={{
                className: styles.textField,
                fullWidth: true,
                typeBtn: 'stroke',
                justifyContent: 'space-between',
                fontWeight: !areaCode ? 400 : undefined,
                style: {
                  color: !areaCode ? 'var(--content-neutral-primary)' : undefined,
                },
                text: areaCode
                  ? `${areaCode.state_name} - ${areaCode.area_code}`
                  : 'Enter an area code (e.g. 561)',
                textIcon: 'makerPin',
              }}
              dropdownProps={{
                margin: -40,
                textFieldProps: {
                  leftActions: [
                    makeTextFieldIcon({ icon: 'makerPin', className: styles.textFieldIcon }),
                  ],
                  InputProps: {
                    placeholder: 'Search',
                  },
                },
                ariaLabel: 'area',
              }}
              text={''}
              value={areaCode?.area_code}
              items={areaCodes}
              onSelect={handleAreaCode}
            />
          </Box>
        </>
      )}

      <div>
        {(!noResults || loading) && (
          <Button
            fullWidth={false}
            icon={'refresh'}
            text={'Refresh'}
            size={'small'}
            contained={'secondary'}
            onClick={refresh}
            disabled={loading || !canSearch}
          />
        )}
        {(canSearch || loading) && (
          <div
            className={styles.numbersWrap}
            aria-label={getAriaLabel('GetNumberModalContent', 'numbersWrap')}
          >
            {loading && !loadingNumber ? (
              <div
                className={styles.loader}
                aria-label={getAriaLabel('GetNumberModalContent', 'loader')}
              >
                <SpinnerLoader />
              </div>
            ) : (
              <div
                className={classnames({
                  [styles.disabledNumbers]: loadingNumber,
                })}
              >
                {noResults ? (
                  <div
                    className={styles.noResults}
                    aria-label={getAriaLabel('GetNumberModalContent', 'noResults')}
                  >
                    <EmptyState
                      noIcon
                      typographyProps={{ variant: 'body-sm-regular', ariaLabel: 'EmptyState' }}
                    />
                  </div>
                ) : (
                  <Scrollbar autoHeightMax={264} autoHeightMin={264} autoHeight={true}>
                    {numbersList.map((number) => (
                      <InputRadio
                        className={styles.radioInput}
                        radioSize={'small'}
                        checked={selectedNumber?.number === number.number}
                        onChecked={() => setNumber(number)}
                        key={number.number}
                        label={number.formatted}
                        rightText={store.textRadioLabel}
                      />
                    ))}
                  </Scrollbar>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
})
