import { observer } from 'mobx-react-lite'
import { Avatar, AutomatedAvatar, TypingIndicator } from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { ChatbotConversationMessage } from 'entities/Chatbot'

import styles from './styles.module.scss'

export interface IMessageProps {
  message: ChatbotConversationMessage
}

export const MessageOutgoing = observer(({ message }: IMessageProps) => {
  const user = usersStore.user
  const avatarInfo = user?.avatarInfo || {}

  return (
    <div className={styles.message} data-outgoing>
      <div className={styles.messageAvatar}>
        <Avatar info={avatarInfo} />
      </div>
      <div className={styles.messageBody}>
        <div className={styles.messageBodyText}>{message.body}</div>
        <div className={styles.messageBodyDate}>
          {uiStore.dayjs(message.date).format(DayjsFormats.time)}
        </div>
      </div>
    </div>
  )
})

export const MessageOutgoingLoading = observer(() => {
  const user = usersStore.user
  const avatarInfo = user?.avatarInfo || {}

  return (
    <div className={styles.message} data-outgoing>
      <div className={styles.messageAvatar}>
        <Avatar info={avatarInfo} />
      </div>
      <div className={styles.messageBody}>
        <div className={styles.messageBodyText}>
          <TypingIndicator />
        </div>
      </div>
    </div>
  )
})

export const MessageIncoming = observer(({ message }: IMessageProps) => {
  return (
    <div className={styles.message}>
      <div className={styles.messageAvatar}>
        <AutomatedAvatar />
      </div>
      <div className={styles.messageBody}>
        <div className={styles.messageBodyText}>{message.body}</div>
        <div className={styles.messageBodyDate}>
          {uiStore.dayjs(message.date).format(DayjsFormats.time)}
        </div>
      </div>
    </div>
  )
})

export const MessageIncomingLoading = observer(() => (
  <div className={styles.message}>
    <div className={styles.messageAvatar}>
      {' '}
      <AutomatedAvatar />
    </div>
    <div className={styles.messageBody}>
      <div className={styles.messageBodyText}>
        <TypingIndicator />
      </div>
    </div>
  </div>
))
