import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { inboxesStore } from 'entities/Inbox'
import { IResponseInboxDeleted } from 'entities/Inbox/api/types'

const InboxDeleted = {
  handle: (data: IResponseInboxDeleted) => {
    inboxesStore.deleteItem(data.id)
  },
}

export const EventInboxDeleted = {
  subscribe: () => websocket.on(WebsocketEvent.InboxDeleted, InboxDeleted.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.InboxDeleted, InboxDeleted.handle),
}
