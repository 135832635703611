// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(width < 658px){body[data-is-support-mobile-view=true] .FQR6Wh8QNn1f9nbp4f8z{flex:0 0 100vw;width:100vw}}.FQR6Wh8QNn1f9nbp4f8z{flex:0 0 220px;width:220px;background-color:var(--background-neutral-subtle);height:100%;display:grid;grid-template-rows:80px auto}.FQR6Wh8QNn1f9nbp4f8z.UtXt_PfnTaeDel8bEwmt{grid-template-rows:80px 54px auto}.FQR6Wh8QNn1f9nbp4f8z.UtXt_PfnTaeDel8bEwmt.voXI23jV_eUAHDMyTRkL{grid-template-rows:80px 54px auto max-content}.FQR6Wh8QNn1f9nbp4f8z.voXI23jV_eUAHDMyTRkL{grid-template-rows:80px auto max-content}.MF9nnxhodVvQrmUYngOL{padding:0 8px;margin-bottom:20px}.CxwjXDJ6VT9QcXUKoJtu{padding:0 8px;overflow-y:auto;display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/shared/ui/Menu/ui/styles.module.scss"],"names":[],"mappings":"AAGE,sBACE,6DACE,cAAA,CACA,WAAA,CAAA,CAKN,sBACE,cAAA,CACA,WAAA,CACA,iDAAA,CACA,WAAA,CACA,YAAA,CACA,4BAAA,CAEA,2CACE,iCAAA,CAEA,gEACE,6CAAA,CAIJ,2CACE,wCAAA,CAIJ,sBACE,aAAA,CACA,kBAAA,CAGF,sBACE,aAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\nbody[data-is-support-mobile-view='true'] {\n  @media (width < $breakpoint-xxs) {\n    .wrap {\n      flex: 0 0 100vw;\n      width: 100vw;\n    }\n  }\n}\n\n.wrap {\n  flex: 0 0 220px;\n  width: 220px;\n  background-color: var(--background-neutral-subtle);\n  height: 100%;\n  display: grid;\n  grid-template-rows: 80px auto;\n\n  &.withSearch {\n    grid-template-rows: 80px 54px auto;\n\n    &.withBottom {\n      grid-template-rows: 80px 54px auto max-content;\n    }\n  }\n\n  &.withBottom {\n    grid-template-rows: 80px auto max-content;\n  }\n}\n\n.wrapperSearch {\n  padding: 0 8px;\n  margin-bottom: 20px;\n}\n\n.wrapperContent {\n  padding: 0 8px;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "FQR6Wh8QNn1f9nbp4f8z",
	"withSearch": "UtXt_PfnTaeDel8bEwmt",
	"withBottom": "voXI23jV_eUAHDMyTRkL",
	"wrapperSearch": "MF9nnxhodVvQrmUYngOL",
	"wrapperContent": "CxwjXDJ6VT9QcXUKoJtu"
};
export default ___CSS_LOADER_EXPORT___;
