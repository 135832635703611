import { subscriptionStore } from 'entities/Subscription'
import { checkAccessPage, EnumAccessPageKeys } from 'entities/ExportRouters/lib/checkAccessPage'
import {
  EnumMenuOutboundItemsKey,
  listItemsOutbound,
  OutboundKey,
} from 'widgets/Menu/ui/MenuOutbound/constants/menuItems'

export const getStateAccessOutbound = (key: EnumMenuOutboundItemsKey) => {
  if (subscriptionStore.isAgencyWithoutSubscription) return false

  switch (key) {
    case 'broadcasts_all':
    case 'broadcasts_onetime':
    case 'broadcasts_recurring':
      return checkAccessPage(EnumAccessPageKeys.broadcasts)
    case 'triggers_all':
    case 'triggers_sms':
    case 'triggers_ringless':
      return checkAccessPage(EnumAccessPageKeys.triggers)
    case 'power_dialer':
      return checkAccessPage(EnumAccessPageKeys.power_dialer)
    default:
      return true
  }
}

const accessList = () => {
  return Object.entries(listItemsOutbound).reduce<string[]>((state, [id, path]) => {
    const key = id as EnumMenuOutboundItemsKey

    if (getStateAccessOutbound(key)) state.push(path)

    return state
  }, [])
}

export const getPath = (path: string | null) => {
  const list = accessList()

  if (!list.length) return ''
  if (!path) return list[0]
  if (!list.includes(path)) return list[0]

  return path
}

export const getPathOutbound = (userId: number) => {
  let path = window.localStorage.getItem(`${userId}_menu_${OutboundKey}`)

  path = getPath(path)

  if (path && path[0] === '/') {
    path = path.slice(1)
  }

  return path
}
