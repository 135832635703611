import { ReactNode, FC } from 'react'
import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IPageDescriptionProps = {
  children: ReactNode
}

export const PageDescription: FC<IPageDescriptionProps> = ({ children }) => {
  return (
    <Typography
      ariaLabel='PageDescription_line'
      color='var(--content-primary-tertiary)'
      variant='body-md-regular'
      tag='div'
      className={styles.wrap}
    >
      {children}
    </Typography>
  )
}
