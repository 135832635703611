import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { DropdownContent, Icon, Button, Switch } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import type { Contact } from 'entities/Contacts/model/Contact'
import {
  ContactsSearch,
  ContactsSearchVariantEnum,
  IntegrationsTabEnum,
} from 'widgets/ContactsSearch'
import { IntegrationContactCard } from 'widgets/ContactsDetails/ui/ContactsIntegrations/LinkContact/ui/LinkContactModalContent/IntegrationContactCard/IntegrationContactCard'
import type { LinkContactStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/LinkContact/store/linkContactStore'

import styles from './styles.module.scss'

type ILinkManualTabProps = {
  contact: Contact
  linkContactStore: LinkContactStore
}

const LinkManualTab = observer(({ contact, linkContactStore }: ILinkManualTabProps) => {
  const [focused, setFocused] = useState(false)

  const {
    search,
    handleSearch,
    selectedContact,
    resetManual,
    shouldUpdate,
    toggleUpdate,
    selectContact,
  } = linkContactStore

  return (
    <div className={classnames(styles.wrap)}>
      <IntegrationContactCard contact={contact} noAction />

      <div className={styles.divider}>
        <div className={styles.icon}>
          <Icon icon='arrowDown' fontSize={16} color='var(--content-primary-tertiary)' />
        </div>
      </div>

      <div className={styles.labelWrapper}>
        <Label
          label={{
            text: 'Integration contact',
          }}
        />
        {selectedContact && (
          <Button text='Remove' onClick={resetManual} typeBtn='text' size='small' />
        )}
      </div>

      {selectedContact ? (
        <IntegrationContactCard contact={selectedContact} isSelected />
      ) : (
        <>
          <TextField
            InputProps={{
              placeholder: focused ? 'Search' : 'Select',
            }}
            size='small'
            variant='stroke'
            value={search}
            onChange={handleSearch}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            rightActions={[
              makeTextFieldClear(),
              makeTextFieldIcon({ icon: 'chevronDown', hidden: focused || search.length !== 0 }),
            ]}
          />

          {(focused || search) && (
            <div className={styles.dropdownWrapper}>
              <DropdownContent>
                <ContactsSearch
                  focusedSearch={focused}
                  search={search}
                  onChange={(contact) => selectContact(contact)}
                  variant={ContactsSearchVariantEnum.default}
                  hiddenIntegrationsTabs={[IntegrationsTabEnum.salesmsg]}
                  paramsCreateContact={{
                    forLink: false,
                  }}
                />
              </DropdownContent>
            </div>
          )}
        </>
      )}

      {selectedContact && (
        <div className={styles.updateWrapper} onClick={() => toggleUpdate(!shouldUpdate)}>
          <Label
            label={{
              text: 'Update Salesmsg contact with the linked contact data',
            }}
          />
          <Switch value={shouldUpdate} onChange={toggleUpdate} size='small' />
        </div>
      )}
    </div>
  )
})

export { LinkManualTab }
