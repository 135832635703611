import React from 'react'
import { observer } from 'mobx-react-lite'
import { layoutStore, Status, Typography } from 'shared/ui'
import { Select } from 'shared/ui/Select/Select'
import { useIntegrationListContext } from 'pages/settings/pages/integrations/context/integrationListContext'
import styles from './styles.module.scss'

export const IntegrationCategory = observer(() => {
  const { isViewXs } = layoutStore
  const { categories, setActiveCategory, selectedCategory } = useIntegrationListContext()

  return (
    <div className={styles.categories}>
      <Typography
        ariaLabel='IntegrationCategory_title'
        variant='button-small'
        color='var(--content-primary-tertiary)'
        className={styles.categoriesTitle}
      >
        Categories
      </Typography>
      {isViewXs ? (
        <Select
          variant='stroke'
          value={selectedCategory}
          onSelect={(selected) => setActiveCategory(selected.id.toString())}
          items={categories.map((category) => ({ id: category.key, label: category.label }))}
          textFieldPlaceholderProps={{
            className: styles.categorySelect,
          }}
          dropdownProps={{
            withSearch: false,
          }}
        />
      ) : (
        <>
          {categories.map((category) => (
            <Status
              className={styles.categoryItem}
              key={category.key}
              emphasis='high'
              size='medium'
              intent='noBorder'
              title={category.label}
              onClick={() => setActiveCategory(category.key)}
              isActive={selectedCategory === category.key}
            />
          ))}
        </>
      )}
    </div>
  )
})
