import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IParamsGetSegmentsSearch,
  IParamsSegment,
  IResponseGetSegmentsSearch,
  IResponseSegment,
} from 'entities/Segment'

class Api {
  getSegmentsSearch(
    params: IParamsGetSegmentsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetSegmentsSearch>> {
    return baseApi.get('organization/segments/search', { ...options, params })
  }

  createItem(params: IParamsSegment): Promise<AxiosResponse<IResponseSegment>> {
    return baseApi.post('organization/segments/create', params)
  }

  editItem(id: number, params: IParamsSegment): Promise<AxiosResponse<IResponseSegment>> {
    return baseApi.put(`organization/segments/${id}/update`, params)
  }

  deleteItem(id: number): Promise<AxiosResponse<boolean>> {
    return baseApi.delete(`organization/segments/${id}/delete`)
  }
}

export const SegmentApi = new Api()
