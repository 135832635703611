// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s70RslWanX7_HSpr9_HU{--collapse-container-border-color: transparent}.zeRtO_vH0fQjlolouU8N{background:var(--yellow-20);color:var(--yellow-100);border:1px solid var(--black-10);border-radius:4px;padding:2px 4px}a.g3oePJxpJQgYjBmWR5Gi{text-decoration:underline;font-size:13px;font-style:normal;font-weight:500;line-height:16px}a.g3oePJxpJQgYjBmWR5Gi:hover{color:var(--light-blue-70)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,8CAAA,CAGF,sBACE,2BAAA,CACA,uBAAA,CACA,gCAAA,CACA,iBAAA,CACA,eAAA,CAGF,uBACE,yBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,6BACE,0BAAA","sourcesContent":[".withoutBorder {\n  --collapse-container-border-color: transparent;\n}\n\n.badge {\n  background: var(--yellow-20);\n  color: var(--yellow-100);\n  border: 1px solid var(--black-10);\n  border-radius: 4px;\n  padding: 2px 4px;\n}\n\na.link {\n  text-decoration: underline;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 16px;\n\n  &:hover {\n    color: var(--light-blue-70);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withoutBorder": "s70RslWanX7_HSpr9_HU",
	"badge": "zeRtO_vH0fQjlolouU8N",
	"link": "g3oePJxpJQgYjBmWR5Gi"
};
export default ___CSS_LOADER_EXPORT___;
