// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FmaToIZxzW4qji2XTk7C{display:flex;align-items:center;justify-content:center;border-radius:8px;background-color:var(--gray-a-15)}", "",{"version":3,"sources":["webpack://./src/entities/Phone/ui/ConnectTwilioModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,iCAAA","sourcesContent":[".iconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 8px;\n  background-color: var(--gray-a-15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": "FmaToIZxzW4qji2XTk7C"
};
export default ___CSS_LOADER_EXPORT___;
