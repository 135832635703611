import { observer } from 'mobx-react-lite'
import optInPaperFormApproved from 'shared/assets/images/optInPaperFormApproved.png'
import optInPaperFormRejected from 'shared/assets/images/optInPaperFormRejected.png'
import { ICommonOptInContent } from 'pages/settings/pages/compliance/ui/CommonOptInContent/store/type'
import { OptInContent } from './CommonOptInContent'
import styles from './styles.module.scss'

type ICommonOptInPaperFormContentProps = ICommonOptInContent & {
  getIsNextDisabled: () => boolean
}

export const CommonOptInPaperFormContent = observer(
  ({
    stepLoading,
    deleteAttachment,
    getOptInImagesAsAttach,
    uploadAttachment,
    increaseStep,
    errorBanner,
    clearAttachmentError,
    getIsNextDisabled,
    getAttachmentError,
    optInConsentStore,
  }: ICommonOptInPaperFormContentProps) => {
    const { openApprovedExampleModal } = optInConsentStore

    return (
      <div className={styles.optInContentWrap}>
        <OptInContent
          attachmentData={getOptInImagesAsAttach()}
          stepLoading={stepLoading}
          isNextDisabled={getIsNextDisabled()}
          attachmentError={getAttachmentError()}
          increaseStep={increaseStep}
          clearAttachmentError={clearAttachmentError}
          uploadOptInImageAttachment={uploadAttachment}
          deleteAttachment={(url: string) => deleteAttachment(url)}
          approvedExampleImage={optInPaperFormApproved}
          rejectedExampleImage={optInPaperFormRejected}
          errorBanner={errorBanner}
          seeApprovedExampleClick={() => {
            openApprovedExampleModal(
              [optInPaperFormApproved],
              ['Opt-in / Paper Form / Approved example']
            )
          }}
          approvedExampleClick={() =>
            openApprovedExampleModal(
              [optInPaperFormRejected, optInPaperFormApproved],
              ['Opt-in / Paper Form / Rejected example', 'Opt-in / Paper Form / Approved example']
            )
          }
          rejectedExampleClick={() =>
            openApprovedExampleModal(
              [optInPaperFormApproved, optInPaperFormRejected],
              ['Opt-in / Paper Form / Approved example', 'Opt-in / Paper Form / Rejected example']
            )
          }
          firstBlock={{
            title: 'Include compliance language to your Paper Form',
            desc: 'Include the full URL of your Terms of Service and Privacy Policy in the compliance language. (e.g. “https:/example.com/terms-of-service/”).',
            copyTitle: 'Compliance language',
            copyText:
              'I agree to receive recurring automated text messages at the phone number provided. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our terms of service - https:/example.com/terms-of-service and privacy policy - https:/example.com/privacy-policy/.',
          }}
          secondBlock={{
            title: 'Include this paragraph to your Privacy Policy',
            desc: 'Sharing data is not allowed. If text messaging data/consent is not shared, include the paragraph below to your Privacy Policy.',
            copyText:
              'Text messaging originator opt-in data and consent will not be shared with any third parties, excluding aggregators and providers of the Text Message services.',
          }}
        />
      </div>
    )
  }
)
