import { toastStore } from 'shared/ui'
import { WorkflowsApi } from 'entities/Workflow'

export class WorkflowEnrollModalStore {
  enrollContacts = (workflowId: string, contactIds: number[], applyFilters: boolean) =>
    WorkflowsApi.enrollIntoWorkflow(
      {
        contact_ids: contactIds,
        apply_trigger_filters: applyFilters,
      },
      workflowId
    ).then(
      () => {
        toastStore.add({
          type: 'success',
          title: `Contact${contactIds.length > 1 ? 's' : ''} enrolled in workflow`,
        })

        return true
      },
      () => {
        toastStore.add({
          type: 'error',
          title: `Unable to engage contact${contactIds.length > 1 ? 's' : ''}`,
        })

        return false
      }
    )
}
