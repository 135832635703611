import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { PowerDialerAccessLayout } from 'pages/powerDialer/layout/powerDialerAccessLayout'
import { PowerDialerListPage } from 'pages/powerDialer/pages/powerDialerList'

export const PowerDialerRouter = observer(() => {
  return (
    <Routes>
      <Route element={<PowerDialerAccessLayout />}>
        <Route index element={<PowerDialerListPage />} />
      </Route>
    </Routes>
  )
})
