import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import { useMessageCardContext } from 'widgets/MessageCard/context'

export const MessageSenderContent = observer(() => {
  const { conversationMessagesStore, avatarInfo, message } = useMessageCardContext()
  const isAutomated = message.isAutomated
  const isHideAvatars = conversationMessagesStore?.isHideAvatars

  const isMessageSenderInfo = !message.isConversationsHistory && isHideAvatars && avatarInfo

  const messageSender = isAutomated
    ? 'Salesmsg bot'
    : `${avatarInfo?.firstName || ''} ${avatarInfo?.lastName || ''}`.trim() ||
      (avatarInfo?.number && getLabelAsNumberInternationalFormat(avatarInfo.number))
  if (!isMessageSenderInfo) {
    return null
  }
  return (
    <Typography
      variant={'body-xs-medium'}
      ariaLabel={'MessageSender'}
      data-name={'message-sender'}
      color={'var(--content-primary-secondary)'}
      marginBottom={-4}
    >
      {messageSender}
    </Typography>
  )
})
