import { Statistic } from 'entities/ContactStatistic/model/Statistic'
import type { IResponseKeywordStatistic } from 'entities/Keywords/api/type'

export class KeywordStatistics extends Statistic {
  constructor({ total }: IResponseKeywordStatistic) {
    const total_count = total.total_subscribers
    const active_count = total.subscribers
    const unsubscribed_count = total.unsubscribed

    super({
      active_count,
      total_count,
      unsubscribed_count,
    })
  }
}
