import React, { MouseEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Avatar, IIconsVector, Icon, IconButton } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import styles from './styles.module.scss'

type IIntegrationContactCardProps = {
  contact: Contact
  isSelected?: boolean
  noAction?: boolean
}

type IDefaultIntegrationType = {
  icon: IIconsVector
  name: string
}

const defaultIntegration: IDefaultIntegrationType = {
  icon: 'salesmsg',
  name: 'Salesmsg',
}

const IntegrationContactCard = observer(
  ({ contact, isSelected, noAction }: IIntegrationContactCardProps) => {
    const key = (contact?.integration_key || contact?.integration_type) as IntegrationKey

    const isDefault = key === 'salesmsg'
    const integration = integrationsStore.getIntegration(key)
    const integrationContent = isDefault ? defaultIntegration : integration

    if (!integrationContent) return null

    const isHubspot = key === 'hubspot'

    const handleViewContact = (e: MouseEvent<HTMLButtonElement>, contactId: number) => {
      if (!key) return null

      const linkProps = {
        key,
        contact,
        integration,
        type: IIntegrationLinkTypes.contact,
        id: contactId,
      }

      const link = integrationLink(linkProps)
      if (!link) return

      e.stopPropagation()
      window.open(link, '_blank')
    }

    return (
      <div
        className={classnames(styles.wrap, {
          [styles.selected]: isSelected,
          [styles.noAction]: noAction,
        })}
      >
        <div className={styles.header}>
          <span className={styles.icon}>
            <Icon icon={integrationContent.icon} fontSize={11} />
          </span>
          <span className={styles.title}>{integrationContent.name}</span>
        </div>
        <div className={styles.content}>
          <div className={styles.avatar}>
            {isSelected ? (
              <div className={styles.selectedIcon}>
                <Icon
                  icon='checkboxChecked'
                  fontSize={16}
                  color='var(--content-primary-primary-inverted)'
                />
              </div>
            ) : (
              <Avatar size={24} info={contact.avatarInfo} />
            )}
          </div>
          <div className={styles.user}>
            <div className={styles.nameWrap}>
              <span className={styles.name}>
                {(contact.name && getLabelAsNumberInternationalFormat(contact.name)) ||
                  'View contact'}
              </span>
              {!isDefault && (
                <div className={styles.linkIcon}>
                  <IconButton
                    size={'small'}
                    color={'tertiary'}
                    variant={'icon'}
                    icon='linkExternal'
                    tooltipProps={{
                      placement: 'top',
                      label: `Open in ${integrationContent.name}`,
                      disableInteractive: true,
                    }}
                    onClick={(e) => handleViewContact(e, contact.id)}
                    ariaLabel='IntegrationContactCard_openIn'
                  />
                </div>
              )}
            </div>
            <span className={styles.email}>{contact.email}</span>
          </div>
          <div className={styles.phonesWrapper}>
            <div className={styles.phone}>
              <span className={styles.label}>Phone</span>
              <span className={styles.value}>
                {(contact.defaultPhoneInfo &&
                  getLabelAsNumberInternationalFormat(contact.defaultPhoneInfo)) ||
                  '-'}
              </span>
            </div>
            {isHubspot && (
              <div className={styles.phone}>
                <span className={styles.label}>Mobile Phone</span>
                <span className={styles.value}>
                  {(contact.mobile_phone &&
                    getLabelAsNumberInternationalFormat(contact.mobile_phone)) ||
                    '-'}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)

export { IntegrationContactCard }
