import React, { KeyboardEvent } from 'react'
import dayjs from 'dayjs'
import ReactDatePicker from 'react-datepicker'
import classNames from 'classnames'
import { DropdownContent, DropdownPlaceholder, IDatePickerProps, IDropdownProps } from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import icons from 'shared/ui/Icon/icons'
import { uiStore } from 'shared/store/uiStore'
import { ITextFieldPlaceholderProps, makeTextFieldIcon } from 'shared/ui/TextField'
import commonStyles from '../styles.module.scss'
import styles from './styles.module.scss'

type IDatePickerDropdown = {
  selected?: Date | null
  onChange: (date: Date | null) => void
  parentsDropdownId?: string[]
  disabled?: boolean
  textFieldPlaceholderProps?: Partial<ITextFieldPlaceholderProps>
  excludeDateIntervals?: IDatePickerProps['excludeDateIntervals']
  defaultValue?: Date
  onChangeOpen?: IDropdownProps['onChangeOpen']
}

export const DatePickerDropdown = ({
  selected,
  onChange,
  parentsDropdownId,
  disabled,
  textFieldPlaceholderProps,
  excludeDateIntervals,
  defaultValue,
  onChangeOpen,
}: IDatePickerDropdown) => {
  const handleChangeInput = (date: Date | null, onCloseMenu?: () => void) => {
    if (date) {
      onChange(date)
    } else {
      onChange(defaultValue || dayjs(selected).add(-uiStore.offsetMinute, 'minutes').toDate())
    }
    onCloseMenu && onCloseMenu()
  }

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>, onCloseMenu: () => void) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onCloseMenu()
    }
  }

  const time = selected ? dayjs(selected).format(DayjsFormats.full6) : 'Select date'

  return (
    <DropdownPlaceholder
      customComponent={(onCloseMenu) => (
        <DropdownContent noPadding>
          <div className={commonStyles.wrapTimePicker}>
            <ReactDatePicker
              showIcon
              placeholderText={'Select date'}
              selected={selected || null}
              onChange={(date) => handleChangeInput(date)}
              timeIntervals={15}
              disabled={disabled}
              dateFormat='MMM d, yyyy'
              icon={icons['calendar']}
              enableTabLoop={false}
              popperContainer={() => null}
              onKeyDown={(e) => onKeyDown(e, onCloseMenu)}
            />
          </div>
          <div className={styles.wrapCalendarClassNameDropdown}>
            <ReactDatePicker
              excludeDateIntervals={excludeDateIntervals}
              selected={selected || null}
              onChange={(date) => handleChangeInput(date, onCloseMenu)}
              icon={icons['calendar']}
              dateFormat='MMM d, yyyy'
              inline
              calendarClassName={classNames(commonStyles.calendarClassNameDropdown)}
            />
          </div>
        </DropdownContent>
      )}
      parentsDropdownId={parentsDropdownId}
      placeholder={time}
      textPlaceholderProps={{
        variant: 'outline',
        size: 'small',
        leftActions: [
          makeTextFieldIcon({ icon: 'calendar', className: commonStyles.timePickerActions }),
        ],
        ...textFieldPlaceholderProps,
        className: classNames(
          { [commonStyles.timePicker__placeholder]: !selected },
          textFieldPlaceholderProps?.className
        ),
      }}
      additionalWidth={8}
      offset={{ y: -4 }}
      onChangeOpen={onChangeOpen}
    />
  )
}
