import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ServiceError } from 'shared/ui/ServiceError'
import { AuthRoutesEnum } from 'entities/Auth'
import { MenuAIAutomationLayout } from 'widgets/Menu/ui/MenuAIAutomation'
import { MenuOutboundLayout } from 'widgets/Menu/ui/MenuOutbound'
import { PowerDialerRoutes, PowerDialerRouter } from 'pages/powerDialer'
import { TriggersRoutes, TriggersRouter } from 'pages/triggers'
import { KeywordsRoutes, KeywordsRouter } from 'pages/keywords'
import { BroadcastsRoutes, BroadcastsRouter } from 'pages/broadcasts'
import { ConversationRoutes, ConversationsRouter } from 'pages/conversations'
import { WorkflowsRoutes, WorkflowsRouter } from 'pages/workflows'
import { ChatbotRoutes, ChatbotRouter } from 'pages/chatbot'
import { ContactsRouter, ContactsRoutes } from 'pages/contacts'
import { AdminRoutes, AdminRouter } from 'pages/admin'
import { SettingsRoutes } from 'pages/settings/route/type'
import { SettingsRouter } from 'pages/settings/route/SettingsRouter'
import Register from 'pages/register'
import Login from 'pages/login'
import ForgotPassword from 'pages/forgotPassword'
import ResetPassword from 'pages/resetPassword'
import RegisterMultiorg from 'pages/registerMultiorg'
import MemberInvite from 'pages/memberInvite'
import AgencyInvite from 'pages/agencyInvite'
import { CampaignRouter } from 'pages/campaigns'
import { CampaignRoutes } from 'pages/campaigns/types'
import { ClientsRouter, ClientsRoutes } from 'pages/clients'
import { KnowledgeBaseRouter, KnowledgeBaseRoutes } from 'pages/settings/pages/knowledgeBase'
import {
  AuthLayoutVue,
  AuthRefreshLayout,
  LoginLayout,
  RootLayout,
  AccessOrganizationLayout,
} from 'app/shared/layouts'

const routes = createRoutesFromElements(
  <Route element={<RootLayout />} errorElement={<ServiceError name='next' />}>
    <Route element={<AuthRefreshLayout />}>
      <Route path={`/${AuthRoutesEnum.resetPassword}/:token`} element={<ResetPassword />} />
      <Route path={`/${AuthRoutesEnum.memberInvite}/:token`} element={<MemberInvite />} />
      <Route path={`/${AuthRoutesEnum.agencyInvite}/:token`} element={<AgencyInvite />} />
      <Route element={<AuthLayoutVue type='default' />}>
        <Route path={`/${AuthRoutesEnum.registerMultiOrg}`} element={<RegisterMultiorg />} />
      </Route>
      <Route element={<AuthLayoutVue />}>
        <Route element={<AccessOrganizationLayout />}>
          <Route element={<MenuAIAutomationLayout />}>
            <Route path={`/${ChatbotRoutes.root}/*`} element={<ChatbotRouter />} />
            <Route path={`/${CampaignRoutes.root}/*`} element={<CampaignRouter />} />
            <Route path={`/${WorkflowsRoutes.root}/*`} element={<WorkflowsRouter />} />
            <Route path={`/${KeywordsRoutes.root}/*`} element={<KeywordsRouter />} />
            <Route path={`/${KnowledgeBaseRoutes.root}/*`} element={<KnowledgeBaseRouter />} />
          </Route>
          <Route element={<MenuOutboundLayout />}>
            <Route path={`/${BroadcastsRoutes.root}/*`} element={<BroadcastsRouter />} />
            <Route path={`/${TriggersRoutes.root}/*`} element={<TriggersRouter />} />
            <Route path={`/${PowerDialerRoutes.root}/*`} element={<PowerDialerRouter />} />
          </Route>
          <Route path={`/${ClientsRoutes.root}/*`} element={<ClientsRouter />} />
          <Route path={`/${ConversationRoutes.root}/*`} element={<ConversationsRouter />} />
          <Route path={`/${ContactsRoutes.CONTACTS}/*`} element={<ContactsRouter />} />
          <Route path={`/${AdminRoutes.admin}/*`} element={<AdminRouter />} />
        </Route>
        <Route path={`/${SettingsRoutes.root}/*`} element={<SettingsRouter />} />
        <Route path='/*' element={<div />} />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path={`/${AuthRoutesEnum.register}`} element={<Register />} />
        <Route path={`/${AuthRoutesEnum.login}`} element={<Login />} />
        <Route path={`/${AuthRoutesEnum.forgotPassword}`} element={<ForgotPassword />} />
      </Route>
    </Route>
  </Route>
)

const router = wrapCreateBrowserRouter(createBrowserRouter)(routes)

export const NextService = () => <RouterProvider router={router} />
