import React, { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { CollapseWithIcon, Icon, Tooltip, Typography } from 'shared/ui'
import { Integration } from 'entities/Integrations'
import { IntegrationHubspotSettingsChannels } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsChannels/IntegrationHubspotSettingsChannels'
import { IntegrationHubspotSettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsProperty/IntegrationHubspotSettingsProperty'
import { IntegrationHubspotSettingsTimeline } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsTimeline/IntegrationHubspotSettingsTimeline'
import { IntegrationHubspotSettingsUsers } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsUsers/IntegrationHubspotSettingsUsers'
import { IntegrationHubspotSettingsWebhook } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsWebhook/IntegrationHubspotSettingsWebhook'
import { IntegrationHubspotSettingsSync } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsSync/IntegrationHubspotSettingsSync'
import { IntegrationHubspotSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsStore'
import { IntegrationHubspotSettingsLists } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsLists/IntegrationHubspotSettingsLists'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsProps = {
  integration: Integration
}

export const IntegrationHubspotSettings: FC<IIntegrationHubspotSettingsProps> = observer(
  ({ integration }) => {
    const {
      isOpenedSection,
      onCollapseClick,
      syncStore,
      webhookStore,
      userStore,
      timelineStore,
      propertyStore,
      channelsStore,
      listsStore,
    } = useMemo(() => new IntegrationHubspotSettingsStore(integration), [])

    return (
      <div>
        <CollapseWithIcon
          opened={isOpenedSection('webhook')}
          onClick={onCollapseClick('webhook')}
          icon={'webhookOutline'}
          title={'Webhook'}
          label={'Send HubSpot contacts into your Salesmsg account'}
        >
          <IntegrationHubspotSettingsWebhook store={webhookStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={isOpenedSection('users')}
          onClick={onCollapseClick('users')}
          icon={'userLine'}
          title={'Users'}
          subtitle={userStore.userInfo.subtitle}
          label={userStore.userInfo.label}
        >
          <IntegrationHubspotSettingsUsers store={userStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={isOpenedSection('sync')}
          onClick={onCollapseClick('sync')}
          icon={'arrowHorizontalOutline'}
          title={'Contact syncing'}
          label={syncStore.getLabel}
        >
          <IntegrationHubspotSettingsSync store={syncStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={isOpenedSection('inbox')}
          onClick={onCollapseClick('inbox')}
          icon={'messageSmileCircle'}
          title={'Help Desk & Inbox '}
          label={"Send, receive, and manage conversations in HubSpot's Help Desk or Inbox"}
          subtitle={
            <>
              <Tooltip
                label={
                  <div style={{ wordBreak: 'break-word' }}>
                    {
                      <div>
                        <div>Discover the power of HubSpot Help Desk & Inbox</div>
                        <a
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          href={
                            'https://help.salesmessage.com/en/articles/10265193-new-text-from-hubspot-inbox-help-desk-with-custom-channels'
                          }
                          rel={'noopener noreferrer'}
                          target={'_blank'}
                          className={styles.link}
                        >
                          Learn more
                        </a>
                      </div>
                    }
                  </div>
                }
                placement={'top'}
              >
                <Icon fontSize={14} icon={'question'} tertiary />
              </Tooltip>
              <Typography variant='body-xs-medium' ariaLabel='BetaLabel' className={styles.badge}>
                Beta
              </Typography>
            </>
          }
        >
          <IntegrationHubspotSettingsChannels store={channelsStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={isOpenedSection('properly')}
          onClick={onCollapseClick('properly')}
          icon={'workflowOutline'}
          title={'Property mapping'}
          label={`${propertyStore.mappedLength} properties mapped`}
        >
          <IntegrationHubspotSettingsProperty store={propertyStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          className={styles.withoutBorder}
          opened={isOpenedSection('timeline')}
          onClick={onCollapseClick('timeline')}
          icon={'listLayout'}
          title={'Timeline events'}
          label={'Track of every interaction directly in your HubSpot'}
        >
          <IntegrationHubspotSettingsTimeline store={timelineStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          className={styles.withoutBorder}
          opened={isOpenedSection('lists')}
          onClick={onCollapseClick('lists')}
          icon={'users2'}
          title={'HubSpot lists'}
          label={'Import and manage your HubSpot lists to keep your contacts organized'}
        >
          <IntegrationHubspotSettingsLists store={listsStore} />
        </CollapseWithIcon>
      </div>
    )
  }
)
