import { observer } from 'mobx-react-lite'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'

import styles from './styles.module.scss'
import { QuickLinksEmpty } from './QuickLinksEmpty'
import { QuickLinksList } from './QuickLinksList'

export interface IContactsQuickLinksProps {
  isNewContactMode?: boolean
}
export const ContactsQuickLinks = observer(() => {
  const { selectedContact } = useContactsDetailsContext()
  const contactId = selectedContact?.id

  return (
    <div className={styles.wrap}>
      {contactId != null ? <QuickLinksList contactId={contactId} /> : <QuickLinksEmpty />}
    </div>
  )
})
