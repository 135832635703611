import { FC, ReactNode } from 'react'
import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

type ILearnMoreLinkProps = {
  href: string
  children: ReactNode
}

export const LearnMoreLink: FC<ILearnMoreLinkProps> = ({ href, children }) => {
  return (
    <a href={href} target='_blank' rel='noreferrer' className={styles.learnMoreLink}>
      <Typography
        ariaLabel='TermsAndPrivacy_link'
        variant='body-md-medium'
        color='var(--content-brand-primary)'
        className={styles.learnMoreLink}
      >
        {children}
      </Typography>
    </a>
  )
}
