import { FC } from 'react'
import { Outlet } from 'react-router'
import { IconButton, Typography } from 'shared/ui'
import { ProgressSteps } from 'shared/ui/ProgressSteps'
import { useLastPathSegment } from 'pages/settings/pages/compliance/hooks/useLastPathSegment'
import styles from './styles.module.scss'

type IStepsCommonLayoutProps = {
  title: string
  progressTitle: string
  totalSteps: number
  settingsStep: number
  closeSetting: () => void
  decreaseStep: (lastPathSegment: string) => void
}

export const StepsCommonLayout: FC<IStepsCommonLayoutProps> = ({
  title,
  progressTitle,
  settingsStep,
  totalSteps,
  closeSetting,
  decreaseStep,
}) => {
  const lastPathSegment = useLastPathSegment()
  return (
    <>
      <IconButton
        className={styles.close}
        ariaLabel='close_setting'
        icon='close'
        variant='icon'
        color='tertiary'
        size='medium'
        onClick={closeSetting}
      />
      <div className={styles.content}>
        <div className={styles.content__sub}>
          <div className={styles.titleWrap}>
            <Typography
              className={styles.titleWrap__title}
              ariaLabel='stepsPage_title'
              variant='body-xs-regular'
              tag={'div'}
            >
              {title}
            </Typography>

            {settingsStep > 1 && (
              <div className={styles.backButton}>
                <IconButton
                  color='tertiary'
                  variant='icon'
                  ariaLabel='back_to_lastStep'
                  icon='arrowLeft'
                  size='medium'
                  onClick={() => decreaseStep(lastPathSegment)}
                />
              </div>
            )}
          </div>

          <div className={styles.localProgress}>
            <ProgressSteps title={progressTitle} total={totalSteps} step={settingsStep} />
          </div>

          <div className={styles.section}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}
