import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useMenuAIAutomationLayoutContext } from 'widgets/Menu/ui/MenuAIAutomation'
import { CampaignsPageStore } from 'pages/campaigns/store/campaignsPageStore'
import { CampaignRoutes } from 'pages/campaigns/types'
import { CampaignsPageContext } from 'pages/campaigns/context/context'

const items: IMenuItemProps[] = [
  {
    key: 'all_campaigns',
    type: 'nav',
    to: CampaignRoutes.all,
    label: 'All campaigns',
    icon: 'campaigns',
  },
]

export const CampaignLayout = observer(() => {
  const menuLayoutStore = useMenuAIAutomationLayoutContext()
  const pageStore = useMemo(() => new CampaignsPageStore(menuLayoutStore.pageLayoutStore), [])

  if (featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return (
      <CampaignsPageContext.Provider value={pageStore}>
        <Outlet />
      </CampaignsPageContext.Provider>
    )
  }

  return (
    <CampaignsPageContext.Provider value={pageStore}>
      <PageLayout
        leftContent={<Menu title='Campaigns' menuItems={items} />}
        store={pageStore.pageLayoutStore}
      />
    </CampaignsPageContext.Provider>
  )
})
