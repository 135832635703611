// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bjKz8bbNW7sYY8Zr8V_N{width:100%}.bjKz8bbNW7sYY8Zr8V_N:hover .iPoMg0vXnfJ4fFL6WCfX{width:100%}.iPoMg0vXnfJ4fFL6WCfX{width:unset;flex:unset}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/SchedulePeriod/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEE,kDACE,UAAA,CAIN,sBACE,WAAA,CACA,UAAA","sourcesContent":[".wrap {\n  width: 100%;\n  &:hover {\n    .placeholder {\n      width: 100%;\n    }\n  }\n}\n.placeholder {\n  width: unset;\n  flex: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "bjKz8bbNW7sYY8Zr8V_N",
	"placeholder": "iPoMg0vXnfJ4fFL6WCfX"
};
export default ___CSS_LOADER_EXPORT___;
