import { observer } from 'mobx-react-lite'
import { ContainerHeader, Typography } from 'shared/ui'
import { useQuickLinksContext } from 'pages/settings/pages/quickLinks/context/quickLinksContext'

import styles from './styles.module.scss'
import { CreateQuickLinkButton, GuideQuickLinkButton } from '../QuickLinkAction'

export const QuickLinkHeader = observer(() => {
  const { listStore } = useQuickLinksContext()

  const handleCreate = () => listStore.refresh()

  return (
    <ContainerHeader
      customTitle={
        <header className={styles.header}>
          <Typography variant='heading-lg' ariaLabel='Quick links header'>
            Quick links
          </Typography>
          <Typography
            className={styles.headerDescription}
            variant='body-md-regular'
            ariaLabel='Quick links description'
          >
            Your fastest route to productivity
          </Typography>
        </header>
      }
      paddingTop={64}
      paddingBottom={32}
      paddingLeft={4}
      paddingRight={4}
      actionsProps={{
        gap: 8,
        actions: [
          { iconButtonComponent: <GuideQuickLinkButton /> },
          { iconButtonComponent: <CreateQuickLinkButton onCreate={handleCreate} /> },
        ],
      }}
    />
  )
})
