import { Box, BoxProps } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SwitchField } from 'shared/ui'
import { TimePicker } from 'shared/ui/DatePicker/TimePicker/TimePicker'
import { ILabelProps } from 'shared/ui/Label'
import { uiStore } from 'shared/store/uiStore'
import { useAdvancedSchedulingContext } from 'shared/ui/Schedule/ui/AdvancedScheduling'
import { WeekDaysSelector } from 'shared/ui/Schedule'
import { RecipientsField } from 'shared/ui/Schedule/ui/AdvancedScheduling/ui/RecipientsField'

export type AdvancedSchedulingContentProps = {
  expandedBoxProps?: BoxProps
  withoutTimePicker?: boolean
}

export const AdvancedSchedulingContent: FC<AdvancedSchedulingContentProps> = observer(
  ({ expandedBoxProps, withoutTimePicker }) => {
    const {
      initDefault,
      weekDaysSelectorStore,
      isRequiredAdvancedSchedule,
      isAdvancedScheduling,
      isEnabled,
      toggleAdvancedScheduling,
      date,
      setDate,
      dropdownId,
      defaultTime,
    } = useAdvancedSchedulingContext()

    useEffect(initDefault, [])

    const label: ILabelProps['label'] = {
      leftIcon: 'sla2',
      text: 'Advanced scheduling',
    }
    return (
      <>
        <Box padding={1}>
          <SwitchField
            isHideSwitch={isRequiredAdvancedSchedule}
            value={isAdvancedScheduling}
            onClick={toggleAdvancedScheduling}
            labelProps={{
              label,
            }}
            buttonProps={{
              tooltipProps: {
                width: 258,
                visibility: isRequiredAdvancedSchedule ? 'visible' : 'hidden',
                fullWidth: true,
                label:
                  'Advanced scheduling is automatically activated when the number of contacts exceeds the daily limit',
                placement: 'right',
              },
            }}
          />
        </Box>

        {isEnabled && (
          <>
            <Box
              padding={2}
              borderTop={'1px solid var(--border-neutral-default)'}
              sx={{
                backgroundColor: 'var(--background-neutral-subtle)',
              }}
            >
              <RecipientsField />
            </Box>
            <Box
              padding={2}
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              sx={{ background: 'var(--background-neutral-subtle)' }}
              borderTop={'1px solid var(--border-neutral-default)'}
              {...expandedBoxProps}
            >
              <WeekDaysSelector store={weekDaysSelectorStore} />
              {!withoutTimePicker && (
                <TimePicker
                  defaultValue={defaultTime}
                  onChange={setDate}
                  selected={date}
                  parentsDropdownId={[dropdownId]}
                  offsetMinutes={uiStore.offsetMinute}
                />
              )}
            </Box>
          </>
        )}
      </>
    )
  }
)
