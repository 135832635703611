import { useEffect } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { integrationsStore } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { ContactMeetDropdown } from 'widgets/ContactMeetDropdown'
import { useContactsProfileCardContext } from 'widgets/ContactsDetails/ui/ContactsProfileCard/context'
import { EmailAction } from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/EmailAction/EmailAction'
import { ChatAction } from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/ChatAction/ChatAction'
import { ContactProfileInfo } from './ContactProfileInfo/ContactProfileInfo'
import { ContactActions } from './ContactActions/ContactActions'
import { CallButtonAction } from './CallButtonAction/CallButtonAction'
import styles from './styles.module.scss'

export const ContactsProfileCardContent = observer(() => {
  const store = useContactsProfileCardContext()
  let contact = store.contact

  if (contact && contact?.id !== -1) {
    const item = contactsStore.getItem(contact?.id)

    if (item) contact = item
  }
  const newContactDisabled = contact?.id === 0 || contact?.id === -1
  const isShouldRequestAccess = !!usersStore.user?.isShouldRequestAccess
  const disabled = newContactDisabled || isShouldRequestAccess

  useEffect(() => {
    integrationsStore.fetchIntegrations()
  }, [])

  if (!contact) {
    return (
      <div className={classnames(styles.wrap, styles.loader)}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <ContactProfileInfo />
      <div className={styles.actions}>
        {store.enableChat && (
          <ChatAction
            disabled={disabled}
            onClick={() => {
              contact && store.onClickMessage?.(contact)
            }}
          />
        )}

        {store.enableCall && <CallButtonAction />}

        <EmailAction contact={contact} disabled={newContactDisabled} />

        <ContactMeetDropdown contactId={contact.id} />
        <ContactActions contact={contact} />
      </div>
    </div>
  )
})
