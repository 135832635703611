import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { TextFieldWithLabel, Tooltip, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { IBusinessType, IPlaneTypes } from 'entities/Compliance'
import { tenDlcLimitsStore } from 'entities/TenDlc'
import { LearnMoreLink } from 'pages/settings/pages/compliance/ui/LearnMoreLink/LearnMoreLink'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { RegistrationErrorAlert } from 'pages/settings/pages/compliance/pages/localNumbers/ui/RegistrationErrorAlert/RegistrationErrorAlert'

import { PackageCard } from './PackageCard/PackageCard'
import styles from './styles.module.scss'

const tooltipDesc = (
  <div className={styles.tooltipDesc}>
    <Typography ariaLabel='tooltipDesc' variant='body-md-regular'>
      SMS segment - 160 characters
    </Typography>
    <Typography ariaLabel='tooltipDesc' variant='body-md-regular'>
      MMS segment - 1600 characters
    </Typography>
  </div>
)

export const Package = observer(() => {
  const { packageStore, stepLoading } = useLocalNumbersStepsContext()
  const {
    activePlaneType,
    businessType,
    stockExchangesItems,
    stockTicker,
    isNextDisabled,
    emailLocalError,
    verificationEmail,
    activeStockExchange,
    nextButtonText,
    packageShowingList,
    getStockExchanges,
    increaseStep,
    checkIsEmail,
    setStockTicker,
    setBusinessType,
    setStockExchange,
    setActivePlaneType,
    setVerificationEmail,
  } = packageStore
  const { dlcPlan } = tenDlcLimitsStore
  const isLowVolumePlanDisabled = dlcPlan?.availableForUpdatePlans === IPlaneTypes.STANDARD
  const isStandardPlan = activePlaneType === IPlaneTypes.STANDARD
  const isLowVolumePlan =
    activePlaneType === IPlaneTypes.LOW_VOLUME_STANDARD && !isLowVolumePlanDisabled

  useEffect(() => {
    getStockExchanges()
  }, [])

  return (
    <div className={styles.package}>
      <PageDescription>
        Select the 10DLC registration package that fits your business. Salesmsg passes through these
        fees at cost with no markup.
        <LearnMoreLink href={links.complianceLearnMoreLink}>{'Learn more'}</LearnMoreLink>
      </PageDescription>

      <RegistrationErrorAlert />

      <div className={styles.items}>
        {packageShowingList?.includes(IPlaneTypes.LOW_VOLUME_STANDARD) && (
          <Tooltip
            label={
              isLowVolumePlanDisabled
                ? 'You have reached the limit of 2,000 SMS / MMS segments per day for this package'
                : ''
            }
            placement='top'
            width={315}
            fullWidth
            className={styles.tooltip}
          >
            <PackageCard
              checked={isLowVolumePlan}
              title={'Low volume standard'}
              onClick={() => {
                setActivePlaneType(IPlaneTypes.LOW_VOLUME_STANDARD)
              }}
              infoOne={{
                title: 'up to 2,000 SMS / MMS segments per day',
                tooltip: tooltipDesc,
              }}
              infoTwo={{
                title: 'up to 3.75 MPS',
                tooltip: 'Messages per second',
              }}
              price={'$19'}
              disabled={isLowVolumePlanDisabled}
            />
          </Tooltip>
        )}

        {packageShowingList?.includes(IPlaneTypes.STANDARD) && (
          <PackageCard
            checked={isStandardPlan}
            title={'Standard'}
            titleLabel={'Recommended'}
            titleSub={'82% of companies your size use the "Standard" package'}
            onClick={() => {
              setActivePlaneType(IPlaneTypes.STANDARD)
            }}
            infoOne={{
              title: 'up to 600,000 SMS / MMS segments per day',
              tooltip: tooltipDesc,
            }}
            infoTwo={{
              title: 'up to 225 MPS',
              tooltip: 'Messages per second',
            }}
            price={'$59'}
          />
        )}
      </div>

      <SelectField
        withSearch={false}
        btnProps={{
          size: 'large',
        }}
        labelProps={{
          label: {
            text: 'Business type',
          },
        }}
        value={businessType}
        setValue={setBusinessType}
        items={[
          { id: IBusinessType.PRIVATE_PROFIT, label: 'Private' },
          { id: IBusinessType.PUBLIC_PROFIT, label: 'Public' },
        ]}
      />

      {businessType === IBusinessType.PUBLIC_PROFIT && (
        <div>
          <div className={styles.dataLine}>
            <SelectField
              boxWidth='500px'
              withSearch={false}
              btnProps={{
                size: 'extraLarge',
              }}
              labelProps={{
                label: {
                  text: 'Stock exchange',
                },
              }}
              value={activeStockExchange}
              setValue={setStockExchange}
              items={stockExchangesItems}
            />
            <TextFieldWithLabel
              textFieldProps={{
                value: stockTicker,
                onChange: setStockTicker,
                InputProps: {
                  placeholder: 'e.g. XYZD',
                },
              }}
              labelProps={{ label: { text: 'Company Stock Ticker Symbol' } }}
            />
          </div>
          <TextFieldWithLabel
            textFieldProps={{
              value: verificationEmail,
              onChange: setVerificationEmail,
              onBlur: checkIsEmail,
              InputProps: {
                placeholder: 'e.g. adam.johnson@example.com',
              },
            }}
            errorMessage={emailLocalError}
            labelProps={{ label: { text: 'Business verification email' } }}
            bottomLabelProps={{
              label: {
                text: 'Make sure to check this email, as the business verification letter will be sent to it',
              },
            }}
          />
        </div>
      )}

      <NextButton
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        text={nextButtonText}
        onClick={increaseStep}
      />
    </div>
  )
})
