import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Icon, IconButton } from 'shared/ui'
import {
  TextField,
  makeTextFieldAutoFocus,
  makeTextFieldElement,
  makeTextFieldLoading,
  makeTextFieldSelectionRange,
} from 'shared/ui/TextField'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactsSearch } from 'widgets/ContactsSearch'
import { CallSearchContactsStore } from 'widgets/CallSearchContacts'
import { CallDialpad } from 'widgets/CallDialpad'
import styles from './styles.module.scss'

type ICallSearchContactsProps = {
  onChange?: (value: string, contact?: Contact | null) => void
  error?: string
}

export const CallSearchContacts: React.FC<ICallSearchContactsProps> = observer(
  ({ onChange = () => {}, error }) => {
    const [store] = useState(() => new CallSearchContactsStore())
    const ref = useRef<HTMLInputElement | null>(null)

    const { positionFocus, setPositionFocus } = store

    useEffect(() => {
      onChange(store.search, store.contact)
    }, [store.search])

    const heightList = `min(calc(100vh - 474px - ${store.showDialpad ? 155 : 0}px), ${
      store.showDialpad ? 200 : 355
    }px)`

    return (
      <div className={styles.wrap}>
        <div
          className={styles.section}
          style={{
            marginBottom: store.search.length >= 3 || store.showDialpad ? '26px' : 0,
          }}
        >
          <div className={styles.input}>
            <div className={styles.input__wrap}>
              <TextField
                size='medium'
                variant='stroke'
                InputProps={{
                  placeholder: 'Enter name or phone number',
                  ref: ref,
                }}
                onChange={store.handleSearch}
                value={store.search}
                mainActions={[
                  makeTextFieldAutoFocus({ withFocus: true }),
                  makeTextFieldSelectionRange({ start: positionFocus, end: positionFocus }),
                ]}
                rightActions={[
                  makeTextFieldLoading({ loading: store.loading }),
                  makeTextFieldElement({
                    element: (
                      <div className={styles.input__button}>
                        <IconButton
                          size={'medium'}
                          color={'secondary'}
                          variant={'icon'}
                          active={store.showDialpad}
                          iconProps={{
                            icon: 'dialpad',
                          }}
                          ariaLabel='CallSearchContacts_dialpad'
                          onClick={() => {
                            store.handleToggleShowDialpad()
                            if (!store.showDialpad) ref.current?.focus()
                          }}
                        />
                      </div>
                    ),
                  }),
                ]}
              />
            </div>

            {error && (
              <div className={styles.error}>
                <div className={styles.error__icon}>
                  <Icon icon={'alertTriangleSmall'} />
                </div>
                <div className={styles.error__label}>{'Please enter a valid phone number'}</div>
              </div>
            )}
          </div>
        </div>

        {Boolean(store.search) && (
          <div className={styles.section}>
            <ContactsSearch
              search={store.search}
              onChange={(contact) => {
                store.handleAddContact(contact)
                onChange(store.search, store.contact)
              }}
              onChangeLoader={(status) => {
                store.handleChangeLoader(status)
              }}
              maxHeight={heightList}
              minHeight={160}
              disabledHelp={true}
              disabledAddNumber={true}
            />
          </div>
        )}

        {store.showDialpad && (
          <CallDialpad
            onChange={(item) => {
              store.handleDialpadSearch(item.value)

              setTimeout(() => {
                setPositionFocus(store.search.length)
              }, 10)
            }}
          />
        )}
      </div>
    )
  }
)
