import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react'
import { KnowledgeBaseViewStore } from 'pages/settings/pages/knowledgeBase/store/knowledgeBaseViewStore'

export const KnowledgeBaseViewContext = createContext<KnowledgeBaseViewStore | null>(null)

export const useKnowledgeBaseViewContext = () => {
  const context = useContext(KnowledgeBaseViewContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with KnowledgeBaseViewContext')

  return context
}

export const KnowledgeBaseViewProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new KnowledgeBaseViewStore(), [])

  useEffect(() => store.dispose, [])

  return (
    <KnowledgeBaseViewContext.Provider value={store}>{children}</KnowledgeBaseViewContext.Provider>
  )
}
