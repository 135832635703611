import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum, authStore } from 'entities/Auth'
import { ConversationRoutes } from 'pages/conversations'

export const LoginLayout = observer(() => {
  useEffect(() => {
    if (authStore.isLoggedIn) {
      if (authStore.closeAfterLogin) {
        setTimeout(() => {
          window.close()
        }, 100)
      } else {
        window.stop()
        window.location.href = `/${ConversationRoutes.root}`
      }
    } else if (authStore.hasOid === false) {
      if (window.location.pathname === `/${AuthRoutesEnum.register}`) return

      const params = new URLSearchParams(window.location.search)
      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.register}?${params}`,
      })
    }
  }, [authStore.isLoggedIn, authStore.hasOid])

  return <Outlet />
})
