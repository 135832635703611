import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { Button, Icon, SpinnerLoader } from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'

import { QuickLinksSearchStore } from '../store/QuickLinksSearchStore'
import { QuickLinksNoResults } from './QuickLinksNoResults'
import { QuickLink } from './QuickLink'
import styles from './styles.module.scss'

export interface IQuickLinksListProps {
  contactId: number
}

export const QuickLinksSearch = observer(({ contactId }: IQuickLinksListProps) => {
  const store = useMemo(() => new QuickLinksSearchStore(contactId), [contactId])

  useEffect(() => {
    store.search('')
    return store.dispose
  }, [])

  return (
    <section className={styles.search}>
      <div className={styles.searchInput}>
        <Icon icon='search' color='secondary' fontSize={20} padding={6} tertiary />
        <TextField
          className={styles.searchInputField}
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          onChange={store.search}
          value={store.term}
        />
      </div>
      {store.loading || store.loadingScheduled || store.initialLoading ? (
        <SpinnerLoader />
      ) : store.isEmpty && store.noTerm ? (
        <QuickLinksNoResults />
      ) : (
        <div className={styles.searchResults}>
          {store.isEmpty ? (
            <QuickLinksNoResults />
          ) : (
            <>
              {store.quickLinks.map((quickLink) => (
                <QuickLink key={quickLink.id} quickLink={quickLink} />
              ))}
              {!usersStore.user?.isViewOnlyRole && (
                <footer className={styles.footer}>
                  <div className={styles.actions}>
                    <Button
                      size='small'
                      typeBtn='text'
                      text={'Manage links'}
                      onClick={() => uiStore.changeRoute({ path: '/settings/quick-links' })}
                    />
                  </div>
                </footer>
              )}
            </>
          )}
        </div>
      )}
    </section>
  )
})
