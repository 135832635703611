import { Checkbox as CheckboxMu, CheckboxProps } from '@mui/material'

import { Icon } from 'shared/ui'

const sizer = {
  original: {
    padding: '0',
    transform: 'scale(1)',
  },
  16: {
    transform: 'scale(0.89)',
    padding: '1px',
  },
  20: {
    transform: 'scale(1.15)',
    padding: '1px',
  },
  32: {
    transform: 'scale(1.15)',
    padding: '6px',
  },
}

type ISizes = keyof typeof sizer

type CheckboxProp = Omit<CheckboxProps, 'size'> & {
  variant?: 'default' | 'bordered'
  size?: ISizes
  isIndeterminate?: boolean
  onChangeValue?: (value: boolean) => void
}

export const Checkbox = (props: CheckboxProp) => {
  const {
    variant = 'default',
    size = 20,
    isIndeterminate = false,
    onChangeValue = () => {},
    sx = {},
    ...otherProps
  } = props

  const isBordered = variant === 'bordered'

  return (
    <CheckboxMu
      disableRipple
      indeterminate={isIndeterminate}
      indeterminateIcon={<Icon icon={'checkbox_mixed'} />}
      checkedIcon={
        isBordered ? <Icon icon={'checkboxCheckedBordered'} /> : <Icon icon={'checkbox_checked'} />
      }
      icon={isBordered ? <Icon icon={'checkboxBordered'} /> : <Icon icon={'checkbox'} />}
      onChange={(e) => {
        onChangeValue(e.target.checked)
      }}
      {...otherProps}
      sx={{
        ...sx,
        ...sizer[size],
        ...(isBordered
          ? {
              '&.MuiCheckbox-root': {
                color: 'var(--black-30)',
                '&:hover': {
                  color: 'var(--black-50)',
                },
              },
              '&.Mui-checked': {
                color: 'var(--action-contained-primary-normal)',
                '&:hover': {
                  color: 'var(--action-contained-primary-hovered)',
                },
              },
              '&.Mui-disabled': {
                opacity: 0.5,
              },
            }
          : {
              '&.MuiCheckbox-root': {
                color: 'var(--interaction-neutral-hovered)',
                '&:hover': {
                  color: 'var(--interaction-neutral-primary)',
                },
              },
              '&.Mui-checked': {
                color: 'var(--action-contained-primary-normal)',
                '&:hover': {
                  color: 'var(--action-contained-primary-hovered)',
                },
              },
              '&.Mui-disabled': {
                color: 'var(--content-primary-disabled)',
                opacity: 0.5,
              },
            }),
      }}
    />
  )
}
