// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wC9R11FRQm5WfB0blDCx{min-width:100px;display:flex;align-items:center;gap:8px}.kK8iZegya0YQFwsuiEMj{overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsSync/InboxesTeamSelect/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,eAAA,CACA,sBAAA","sourcesContent":[".itemLabel {\n  min-width: 100px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.itemPhone {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemLabel": "wC9R11FRQm5WfB0blDCx",
	"itemPhone": "kK8iZegya0YQFwsuiEMj"
};
export default ___CSS_LOADER_EXPORT___;
