import { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { TrialContactsLimitAlertStore } from 'entities/Subscription/ui/TrialContactsLimitAlert/store/trialContactsLimitAlertStore'

type ITrialAlertContactsProps = {
  totalAmount: number
}

export const TrialContactsLimitAlert: FC<ITrialAlertContactsProps> = observer(({ totalAmount }) => {
  const [store] = useState(() => new TrialContactsLimitAlertStore())

  useEffect(() => {
    store.init()
  }, [])

  useEffect(() => {
    store.setTotalAmount(totalAmount)
  }, [totalAmount])

  if (store.hide) return null

  return <Alert {...store.configAlert} />
})
