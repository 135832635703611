import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FullPageWrapper, SpinnerLoader } from 'shared/ui'
import {
  QuickLinksProvider,
  useQuickLinksContext,
} from 'pages/settings/pages/quickLinks/context/quickLinksContext'

import { QuickLinkEmpty } from '../QuickLinkEmpty'
import { QuickLinkList } from '../QuickLinkList'
import styles from './styles.module.scss'

const QuickLinks = observer(() => {
  const [loading, isLoading] = useState(true)
  const { init, listStore } = useQuickLinksContext()

  useEffect(() => {
    init().then(() => isLoading(false))
  }, [])

  if (loading || listStore.initialLoading) return <SpinnerLoader />
  if (!listStore.loading && !listStore.loadingScheduled && listStore.isEmpty && listStore.noTerm)
    return <QuickLinkEmpty />

  return <QuickLinkList />
})

export const QuickLinksPage = observer(() => (
  <FullPageWrapper className={styles.page}>
    <QuickLinksProvider>
      <QuickLinks />
    </QuickLinksProvider>
  </FullPageWrapper>
))
