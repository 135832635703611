import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type GetNumberPFTModalStore } from 'entities/Phone/ui/GetNumberPFTModal'
import { SuccessModalContent } from 'entities/Phone/ui/SuccessModalContent/SuccessModalContent'

type IGetNumberPFTModalContentProps = {
  store: GetNumberPFTModalStore
}

export const GetNumberPFTModalContent: FC<IGetNumberPFTModalContentProps> = observer(
  ({ store }) => {
    const { loadingNumbers, number } = store

    return (
      <SuccessModalContent
        title='Ready to test?'
        desc='Here’s your trial number!'
        alertItem={{
          type: 'infoBlue',
          title: 'Start testing Salesmsg right away with your trial number.',
          desc: 'When you’re ready, pick your local or toll-free number and complete the steps.',
        }}
        loadingNumbers={loadingNumbers}
        formattedNumber={number?.formatted_number}
      />
    )
  }
)
