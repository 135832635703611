import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { Button } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  IIntegrationHubspotPropertyFormatted,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { usersStore } from 'entities/Users'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { ContactTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/TabsView/ContactTab/ContactTab'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/styles.module.scss'
import { NewHubspotPropertiesDropdown } from 'widgets/NewHubspotPropertiesDropdown'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'

type IPropertiesProps = {
  properties: IIntegrationHubspotPropertyFormatted[]
  contact: Contact
  showMoreLabel: string
  hasShowMoreButton: boolean
  handleReorder?: (result: DropResult) => void
  handleClear: (id: string, source_type: IUiSettingsSourceType) => void
}

const Properties = ({
  properties,
  contact,
  showMoreLabel,
  hasShowMoreButton,
  handleClear,
  handleReorder,
}: IPropertiesProps) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole
  const contactsDetailsStore = useContactsDetailsContext()
  const { contactHubspotIntegrationStore } = contactsDetailsStore

  if (!contactHubspotIntegrationStore) return null

  const { hubspotContactName, newHubspotPropertiesDropdownStore, hubspotIntegration } =
    contactHubspotIntegrationStore

  const { toggleBlockView } = contactsDetailsGlobalStore

  const linkProps = {
    key: IntegrationKey.hubspot,
    contact,
    integration: hubspotIntegration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = integrationLink(linkProps)

  const actions = () => {
    return (
      <div className={styles.actions}>
        {!isUserViewOnly && (
          <div className={styles.action}>
            <NewHubspotPropertiesDropdown
              store={newHubspotPropertiesDropdownStore}
              sourceType={'hubspot-contacts-draggable-items'}
            />
          </div>
        )}
        {hasShowMoreButton ? (
          <div className={styles.action}>
            <Button
              size='small'
              typeBtn='text'
              disabled={!contact}
              text={showMoreLabel}
              onClick={() => {
                toggleBlockView('hubspotDetailsContactAllFields')
              }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <ContactTab
      title={hubspotContactName}
      link={link}
      integrationLabel={'HubSpot'}
      data={properties}
      actions={actions()}
      handleClear={handleClear}
      handleReorder={handleReorder}
    />
  )
}

export { Properties }
