import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

export const ConversationBlockedContactAlert = observer(() => {
  const store = useConversationMessagesContext()
  const { isStatusBlockedContact } = store.conversationMessageFieldAccessStore

  if (!isStatusBlockedContact) return null

  return (
    <Alert
      item={{
        desc: <>{'This contact has been blocked and you won’t receive any more messages'}</>,
        type: 'warning',
      }}
    />
  )
})
