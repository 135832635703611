import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import { IResponseUser } from 'entities/Users/api/types'
import {
  IParamsTeamsFavorite,
  IParamsCreateTeamsGroupsByIdAddTeams,
  IParamsTeamsGroupsCreate,
  IParamsTeamsGroupsUpdate,
  IResponseTeamsFavorite,
  IResponseCreateTeamsGroupsByIdAddTeams,
  IResponseTeamsGroupsByIdRemoveTeamById,
  IResponseInboxGroup,
  IParamsPersonal,
  IParamsTeamsSearch,
  IResponseInboxesSearch,
  IResponseInbox,
  IParamsTeamsMuted,
  IResponseTeamsMuted,
  IParamsUpdateTeamsByIdMembers,
  IResponseGetTeamsByIdQueueBySid,
  IResponseUnifiedInbox,
  IParamsTeamsAll,
} from './types'

class Api {
  getGroupInboxes(): Promise<AxiosResponse<IResponseInboxGroup[]>> {
    return baseApi.get('teams/groups/personal').then((response) => {
      if (response.data.data) {
        response.data = response.data.data
      }

      return response
    })
  }

  getInboxesPersonal(
    data: IParamsPersonal = {}
  ): Promise<AxiosResponse<Array<IResponseInbox | IResponseUnifiedInbox>>> {
    return baseApi.get('teams/personal', { params: data })
  }

  updateTeamsFavorite(data: IParamsTeamsFavorite): Promise<AxiosResponse<IResponseTeamsFavorite>> {
    return baseApi.post('teams/favorite', data)
  }

  createTeamsGroupsByIdAddTeams(
    id: number | string,
    data: IParamsCreateTeamsGroupsByIdAddTeams
  ): Promise<AxiosResponse<IResponseCreateTeamsGroupsByIdAddTeams>> {
    return baseApi.post(`teams/groups/${id}/add-teams`, data)
  }

  deleteTeamsGroupsByIdRemoveTeamById(
    groupId: number | string,
    id: number | string
  ): Promise<AxiosResponse<IResponseTeamsGroupsByIdRemoveTeamById>> {
    return baseApi.delete(`teams/groups/${groupId}/remove-team/${id}`)
  }

  updateTeamsGroupsCreate(
    data: IParamsTeamsGroupsCreate
  ): Promise<AxiosResponse<IResponseInboxGroup>> {
    return baseApi.post('teams/groups/create', data)
  }

  deleteTeamsGroupsById(id: number | string): Promise<AxiosResponse<null>> {
    return baseApi.delete(`teams/groups/${id}`)
  }

  updateTeamsGroupsById(
    id: number | string,
    body: IParamsTeamsGroupsUpdate
  ): Promise<AxiosResponse<IResponseInboxGroup>> {
    return baseApi.post(`teams/groups/${id}`, body)
  }

  getTeamsSearch(
    params: IParamsTeamsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseInboxesSearch>> {
    return baseApi.get('teams/search', { ...options, params })
  }

  getTeamById(id: number): Promise<AxiosResponse<IResponseInbox>> {
    return baseApi.get(`teams/${id}`)
  }

  getTeamsAll(params?: IParamsTeamsAll): Promise<AxiosResponse<IResponseInbox[]>> {
    return baseApi.get('/teams/all', { params })
  }

  updateTeamsMute(
    id: number,
    params: IParamsTeamsMuted
  ): Promise<AxiosResponse<IResponseTeamsMuted>> {
    return baseApi.post(`/teams/${id}/mute`, params)
  }

  getTeamByIdMembers(id: number | string): Promise<AxiosResponse<IResponseUser[]>> {
    // TODO optimize flow from V1
    return baseApi.get(`teams/${id}/members?limit=1000`)
  }

  getInboxesTeam(id: number | string, params: { limit: number }) {
    return baseApi.get(`teams/${id}/members`, { params })
  }

  updateTeamsByIdMembers(
    inboxId: number | string,
    params: IParamsUpdateTeamsByIdMembers
  ): Promise<AxiosResponse<IResponseUser[]>> {
    return baseApi.post(`teams/${inboxId}/members`, params)
  }

  getTeamsByIdQueueBySid(
    inboxId: number | string,
    calSid: string
  ): Promise<AxiosResponse<IResponseGetTeamsByIdQueueBySid>> {
    return baseApi.get(`teams/${inboxId}/queue/${calSid}`)
  }
}

export const InboxesApi = new Api()
