import { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { DividerCustom, Switch, CircularProgress } from 'shared/ui'
import { SwitchAction } from 'shared/ui/SwitchAction'
import { ITrigger } from 'entities/Trigger/model/Trigger'

import type { IResponseTrigger } from 'entities/Trigger/api/type'
import styles from './styles.module.scss'

type TriggerActionProps = {
  trigger: ITrigger
  toggleTriggerStatus: (trigger: ITrigger) => Promise<IResponseTrigger>
}

export const TriggerSwitchAction = observer(
  ({ trigger, toggleTriggerStatus }: TriggerActionProps) => (
    <>
      <SwitchAction
        active={trigger.status === 'active'}
        disabled={trigger.isInvalid}
        onTrigger={() =>
          toggleTriggerStatus(trigger).then((updatedTrigger) => trigger.syncOrigin(updatedTrigger))
        }
      />
      <DividerCustom variant={'dropdown'} />
    </>
  )
)

export const TriggerSwitch = observer(({ trigger, toggleTriggerStatus }: TriggerActionProps) => {
  const [isActiveUpdating, setIsActiveUpdating] = useState(false)

  const handleToggleTriggerStatus = () => {
    setIsActiveUpdating(true)
    toggleTriggerStatus(trigger)
      .then((updatedTrigger) => trigger.syncOrigin(updatedTrigger))
      .finally(() => setIsActiveUpdating(false))
  }

  return (
    <div className={styles.fullSizeContainer}>
      {isActiveUpdating ? (
        <CircularProgress
          size={20}
          variant='indeterminate'
          customColor='var(--light-blue-70)'
          thickness={3}
        />
      ) : (
        <Switch
          className={classNames(styles.activeActionSwitch)}
          onChange={handleToggleTriggerStatus}
          disabled={trigger.isInvalid}
          size='middle'
          value={trigger.status === 'active'}
        />
      )}
    </div>
  )
})
