import { observer } from 'mobx-react-lite'
import { InView } from 'react-intersection-observer'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dropdown, DropdownContent, DropdownItem, SpinnerLoader } from 'shared/ui'
import { makeTextFieldIcon, TextField, TextFieldPlaceholder } from 'shared/ui/TextField'
import type { IPreviewWorkflowData } from 'entities/Workflow'
import { WorkflowEnrollSearchStore } from 'widgets/WorkflowEnroll/store/WorkflowEnrollSearchStore'

import styles from './styles.module.scss'

type IWorkflowEnrollSearchItemProps = {
  item: IPreviewWorkflowData
  onClick: (item: IPreviewWorkflowData) => void
}

const WorkflowEnrollSearchItem = observer(({ item, onClick }: IWorkflowEnrollSearchItemProps) => (
  <DropdownItem
    item={{
      id: item.id,
      label: item.name,
    }}
    onChange={() => onClick(item)}
  />
))

type IWorkflowEnrollModalSearchProps = {
  onSelect: (item: IPreviewWorkflowData) => void
}

export const WorkflowEnrollModalSearch = observer(
  ({ onSelect }: IWorkflowEnrollModalSearchProps) => {
    const [term, setTerm] = useState('')
    const ref = useRef<HTMLLabelElement | null>(null)
    const store = useMemo(() => new WorkflowEnrollSearchStore(), [])

    const [isActive, setActive] = useState(false)
    const [selection, setSelection] = useState<IPreviewWorkflowData | null>(null)

    const handleLoadMore = useCallback(() => {
      const loadMore = isActive && !store.loading && store.hasMore

      if (loadMore) store.loadMore()
    }, [isActive, store.loading, store.hasMore])

    const handleSelect = useCallback((item: IPreviewWorkflowData) => {
      setActive(false)
      setTerm('')
      setSelection(item)
      onSelect(item)
    }, [])

    const isSearchLoading = store.termItemsEmpty && store.loading
    const isSearchHasMore = !store.termItemsEmpty && store.hasMore
    const isSearchNoResults = store.termItemsEmpty && !store.loading

    useEffect(() => {
      if (isActive) {
        store.loadMore()
      } else {
        store.reset()
        setTerm('')
      }
    }, [isActive])

    useEffect(() => {
      if (!isActive) return
      store.searchItems(term)
    }, [term])

    return (
      <Dropdown
        show={isActive}
        onChangeOpen={setActive}
        placement='bottom-start'
        triggerComponent={() => (
          <TextFieldPlaceholder
            ref={ref}
            variant='stroke'
            size='medium'
            rightActions={[makeTextFieldIcon({ icon: 'chevronDown' })]}
            placeholder={
              selection ? (
                <div className={styles.dropdownSelection}>{selection.name}</div>
              ) : (
                <div className={styles.dropdownPlaceholder}>Select</div>
              )
            }
          />
        )}
        customComponent={() => (
          <DropdownContent
            noPadding
            width={ref.current?.offsetWidth}
            className={styles.dropdownContent}
          >
            <TextField
              size='medium'
              variant='integrated'
              InputProps={{ placeholder: 'Select' }}
              value={term}
              onChange={setTerm}
            />
            <div className={styles.dropdownList}>
              {store.initialLoading ? (
                isActive && <SpinnerLoader className={styles.dropdownSpinner} />
              ) : (
                <>
                  {store.termItems.map((item) => (
                    <WorkflowEnrollSearchItem key={item.id} item={item} onClick={handleSelect} />
                  ))}
                  {isSearchLoading && <SpinnerLoader className={styles.dropdownSpinner} />}
                  <InView onChange={(inView) => inView && handleLoadMore()}>
                    {({ ref }) => (
                      <div ref={ref}>
                        {isSearchHasMore && <SpinnerLoader className={styles.dropdownSpinner} />}
                        {isSearchNoResults && (
                          <div className={styles.dropdownNotFound}>No results found</div>
                        )}
                      </div>
                    )}
                  </InView>
                </>
              )}
            </div>
          </DropdownContent>
        )}
      />
    )
  }
)
