import React from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { IIconsVector } from 'shared/ui'
import {
  formatPhoneNumberInternationalNational,
  formatPhoneNumberToInternational,
  getLabelAsNumberInternationalFormat,
} from 'shared/lib'
import { TextField, makeTextFieldClear, makeTextFieldLoading } from 'shared/ui/TextField'
import { CallPeople } from 'entities/Call/model/CallPeople'
import { CallListItem } from 'widgets/CallPopUp'
import styles from './styles.module.scss'

type ICallListProps = {
  placeholder?: string
  searchValue?: string
  onChangeSearch?: (value: string) => void
  itemsGroups?: {
    name: string
    items: CallPeople[]
  }[]
  onChangeItem?: (item: CallPeople) => void
  loading?: boolean
  isSearch?: boolean
  isValidNumber?: boolean | null
  isTransfer?: boolean
}

export const CallList: React.FC<ICallListProps> = observer(
  ({
    itemsGroups = [],
    placeholder = '',
    searchValue = '',
    onChangeSearch,
    onChangeItem,
    loading = false,
    isSearch = true,
    isValidNumber = null,
    isTransfer = null,
  }) => {
    const isEmpty = !itemsGroups
      .map((group) => group.items.length)
      .reduce((state, length) => state + length, 0)
    const isNumberView = Boolean(isEmpty && isValidNumber !== null)
    const errorMessage: string | undefined = isValidNumber
      ? 'Please enter a valid phone number'
      : undefined
    const renderViewNumber = () => {
      if (!isNumberView) return null
      if (loading) return null

      const prefixName = isTransfer ? 'Transfer to' : 'Add'
      const icon: IIconsVector = isTransfer ? 'arrowRight' : 'add'

      const item = new CallPeople({
        id: formatPhoneNumberInternationalNational(searchValue),
        avatarProps: {
          info: {
            icon: icon,
          },
        },
        name: `${prefixName} ${getLabelAsNumberInternationalFormat(
          formatPhoneNumberToInternational(searchValue)
        )}`,
        type: 'number',
        disabled: !!isValidNumber,
      })

      return (
        <div className={styles.content}>
          <div className={styles.list}>
            <CallListItem item={item} onChange={onChangeItem} />
          </div>
        </div>
      )
    }

    return (
      <div className={styles.wrap}>
        {isSearch && (
          <div className={styles.search}>
            <TextField
              variant='stroke'
              size='small'
              value={searchValue}
              onChange={onChangeSearch}
              error={errorMessage}
              InputProps={{
                placeholder,
              }}
              rightActions={[
                makeTextFieldClear({ disabled: loading }),
                makeTextFieldLoading({ loading }),
              ]}
            />
          </div>
        )}

        <div
          className={classnames(styles.scroll, {
            [styles.loading]: loading,
          })}
        >
          {itemsGroups.map((group) => {
            if (!group.items.length) return null

            return (
              <div key={group.name} className={styles.content}>
                {group.name && <div className={styles.content__title}>{group.name}</div>}

                <div className={styles.list}>
                  {group.items.map((item) => (
                    <CallListItem
                      key={item.id}
                      item={item}
                      search={searchValue}
                      onChange={onChangeItem}
                    />
                  ))}
                </div>
              </div>
            )
          })}

          {renderViewNumber()}
          {isEmpty && !isNumberView && !loading && (
            <div className={styles.isEmpty}>No results found</div>
          )}
        </div>
      </div>
    )
  }
)
