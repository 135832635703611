import { useState } from 'react'
import classnames from 'classnames'
import { CopyWrapper, Icon, Typography, Space } from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { ItemRow } from './ItemRow'

import styles from '../styles.module.scss'

const AllOpportunities = () => {
  const [search, setSearch] = useState('')

  const store = useContactsDetailsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)

  if (!store.viewIntegrationKey || !contact) return null

  const userIntegration = integrationsStore.userIntegrationsMap.get(store.viewIntegrationKey)

  const link = (id: number | string | null) => {
    if (!id) {
      const linkProps = {
        key: store.viewIntegrationKey,
        contact,
        integration: userIntegration,
        type: IIntegrationLinkTypes.contact,
      }

      return integrationLink(linkProps)
    }

    const linkProps = {
      id,
      key: store.viewIntegrationKey,
      contact,
      integration: userIntegration,
      type: IIntegrationLinkTypes.opportunities,
    }

    return integrationLink(linkProps)
  }

  const opportunities = store.viewAllData.opportunities.filter(
    (item) => item?.title?.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const notFound = opportunities.length === 0

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.search}>
        <TextField
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
          leftActions={[makeTextFieldIcon({ icon: 'search' })]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.list}>
        {opportunities.map((item, idx: number) => (
          <>
            <div className={styles.headerWrapper}>
              <CopyWrapper
                copyValue={item.title || null}
                data={{
                  title: item.title || 'View opportunity',
                  link: link(item.id),
                }}
              />
            </div>
            {store.customTitleIcon && (
              <ItemRow data={item} key={idx} integrationKey={store.viewIntegrationKey} />
            )}
          </>
        ))}
        {notFound ? (
          <div className={styles.epmtyList}>
            <Icon icon='search' fontSize={24} />
            <Typography variant={'body-rg-regular'} ariaLabel={'NoResultsFound'}>
              No results found
            </Typography>
          </div>
        ) : (
          <Space height={60} />
        )}
      </div>
    </div>
  )
}

export { AllOpportunities }
