import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import { RegisterSection } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import { SummaryTollFree } from './SummaryTollFree/SummaryTollFree/SummaryTollFree'

export const TollFreePage = observer(() => {
  const { tollFreePageStore } = useCompliancePageContext()
  const {
    complianceLayerStore: { tollFreeUnverifiedNumbers },
  } = tollFreePageStore

  useEffect(() => {
    tollFreePageStore.init()
  }, [])

  return (
    <>
      {tollFreeUnverifiedNumbers.length > 0 && (
        <Alert
          item={{
            type: 'warning',
            desc: `You have ${tollFreeUnverifiedNumbers.length} unverified toll-free number`,
          }}
          marginBottom={16}
        />
      )}
      <RegisterSection {...tollFreePageStore.tfRegisterSectionProps} />
      {tollFreePageStore.isTollFreeDataExist && <SummaryTollFree />}
    </>
  )
})
