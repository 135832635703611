import { observable, makeObservable, computed } from 'mobx'
import { Base } from 'models'
import { IResponseContactFields } from 'entities/Contacts/api/types'
import { EnumUserRoles } from 'entities/Users/model/types'
import { IResponseOwnerUser } from 'entities/Users/api/types'
import { inboxesStore } from 'entities/Inbox'
import { ContactFields } from './ContactField'

export class UserOwner extends Base {
  id: number
  first_name!: string
  last_name!: string
  full_name!: string
  photo_url!: string
  currentInboxId!: number
  currentInboxType!: number
  currentInboxTypeId!: number
  timezone?: string
  email!: string
  role!: EnumUserRoles
  isAway!: boolean
  createdAt!: string
  number!: string
  contactFields?: ContactFields
  awayStatusLastDate!: string
  dlcBannerShouldShowAgain!: boolean
  needPasswordReset!: boolean
  item_type!: 'owner'
  hasAccessibleOrganizations = false
  isRequirePftNumber = false
  isRequireNumber = false
  inboxesCount = 0
  origin!: IResponseOwnerUser

  constructor(item: IResponseOwnerUser) {
    super()

    this.id = item.id
    this.syncOrigin(item)

    makeObservable(this, {
      isAway: observable,
      awayStatusLastDate: observable,
      dlcBannerShouldShowAgain: observable,
      hasAccessibleOrganizations: observable,
      isRequireNumber: observable,
      isRequirePftNumber: observable,
      isAdminOrOwnerRole: computed,
    })
  }

  syncOrigin = (origin: IResponseOwnerUser) => {
    this.id = origin.id
    this.first_name = origin.firstName
    this.last_name = origin.lastName
    this.full_name = origin.fullName
    this.photo_url = origin.photoUrl
    this.currentInboxId = origin.currentInboxId
    this.currentInboxType = origin.currentInboxType
    this.currentInboxTypeId = origin.currentInboxTypeId
    this.timezone = origin.timezone || undefined
    this.email = origin.email
    this.role = origin.role
    this.isAway = origin.isAway
    this.contactFields = origin.contact_fields
      ? new ContactFields(origin.contact_fields)
      : undefined
    this.createdAt = origin.createdAt
    this.number = origin.number || ''
    this.awayStatusLastDate = origin.awayStatusLastDate || ''
    this.dlcBannerShouldShowAgain = origin.dlcBannerShouldShowAgain || false
    this.needPasswordReset = origin.need_password_reset || false
    this.item_type = origin.item_type || 'owner'
    this.hasAccessibleOrganizations = origin.hasAccessibleOrganizations
    this.isRequirePftNumber = Boolean(origin.isRequirePftNumber)
    this.isRequireNumber = Boolean(origin.isRequireNumber)
    this.inboxesCount = origin.inboxesCount
    this.origin = origin
  }

  get avatarInfo() {
    return {
      firstName: this.first_name,
      lastName: this.last_name,
      image: this.photo_url,
      color: this.color,
      number: this.number,
    }
  }

  get name() {
    let output = ''

    const { first_name, last_name, full_name, email } = this

    if (first_name) {
      output = first_name

      if (last_name) {
        output += ' ' + last_name
      }
    } else if (full_name) {
      output = full_name
    } else if (email) {
      output = email
    }

    return output
  }

  get color() {
    const palette = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']

    return palette[this.id % palette.length]
  }

  get activeFields() {
    const defaultActiveFields = ['name', 'number', 'email', 'created_at']

    if (!this.contactFields) {
      return defaultActiveFields
    }

    return this.contactFields.active_fields.map((field) => {
      if (field === 'tags') return 'tag'

      return field
    })
  }

  get displayInfo() {
    const name = `${this.first_name || ''} ${this.last_name || ''}`.trim()
    return name || this.number
  }

  saveUpdatedContactFields = ({ contact_fields }: IResponseContactFields) => {
    if (!this.contactFields) return

    this.contactFields.updateContactFields(contact_fields)
  }

  get isOwnerRole() {
    return this.role === EnumUserRoles.OWNER
  }

  get isViewOnlyRole() {
    return this.role === EnumUserRoles.VIEW_ONLY
  }

  get isMemberRole() {
    return this.role === EnumUserRoles.MEMBER
  }

  get isManagerRole() {
    return this.role === EnumUserRoles.MANAGER
  }

  get isAdminRole() {
    return this.role === EnumUserRoles.ADMIN
  }

  get isAdminOrOwnerRole() {
    return this.isAdminRole || this.isOwnerRole
  }

  get shouldRequestAccess() {
    return !this.isOwnerRole && !this.isRequireNumber
  }

  get isShouldRequestAccess() {
    return inboxesStore.isUnifiedInboxOnly && this.shouldRequestAccess
  }
}
