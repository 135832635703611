import React, { ReactNode, useEffect, useState } from 'react'
import { renderToString } from 'react-dom/server'
import classNames from 'classnames'
import { Icon, ITypographyProps, Typography } from 'shared/ui'
import { parserHtml } from 'shared/lib'
import styles from './styles.module.scss'

export type ITextErrorProps = {
  wrapClassName?: string
  error: ReactNode
  typographyProps?: Partial<ITypographyProps>
  size?: 'small' | 'medium'
}

export const TextError = ({
  wrapClassName = '',
  error,
  typographyProps,
  size = 'small',
}: ITextErrorProps) => {
  const [isHtmlError, setIsHtmlError] = useState<boolean>(false)

  useEffect(() => {
    if (typeof error === 'string') {
      const htmlTagRegex = /<[a-z][\s\S]*>/i
      setIsHtmlError(htmlTagRegex.test(error))
    }
  }, [error])

  if (!error) {
    return null
  }

  return (
    <div className={classNames(styles.error, wrapClassName)}>
      <Icon icon={size === 'small' ? 'alertTriangleSmall' : 'alertTriangle'} fontSize={16} />
      <Typography
        ariaLabel={'error'}
        variant={size === 'small' ? 'text-input-helper' : 'body-sm-regular'}
        {...typographyProps}
        className={classNames(styles.text, typographyProps?.className)}
      >
        {isHtmlError ? (
          <span
            className={styles.errorHtml}
            dangerouslySetInnerHTML={{
              __html: renderToString(<>{parserHtml(error as string)}</>),
            }}
          />
        ) : (
          error
        )}
      </Typography>
    </div>
  )
}
