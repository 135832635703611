import { FC } from 'react'
import { Status } from 'shared/ui'
import { tenDLCStatusConfig } from 'entities/Compliance/ui/TenDLCStatus/config'
import { type ITenDLCStatus } from 'entities/Compliance'

type ITenDLCStatusProps = {
  status: ITenDLCStatus
}

export const TenDLCStatus: FC<ITenDLCStatusProps> = ({ status }) => {
  if (!status) return null

  return <Status {...tenDLCStatusConfig[status]} />
}
