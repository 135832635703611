import { makeAutoObservable, IReactionDisposer } from 'mobx'
import { PageLayoutStore } from 'shared/layout'
import { Segment } from 'entities/Segment/model/Segment'
import { ContactsTableStore } from 'widgets/ContactsTable'
import { SegmentModalStore } from 'widgets/ContactsMenu/ui/SegmentModal'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { menuSegmentsStore } from './menuSegmentsStore'

export class ContactsMenuStore {
  private _search = ''
  private _disposeChangeSegments: IReactionDisposer | null = null

  constructor(
    private _segmentModalStore: SegmentModalStore,
    private _contactsTableStore: ContactsTableStore,
    private _pageLayoutStore: PageLayoutStore
  ) {
    makeAutoObservable(this)
  }

  get search() {
    return this._search
  }

  get segments() {
    return menuSegmentsStore.items.filter((filter) =>
      filter.name.toLocaleLowerCase().includes(this._search.toLocaleLowerCase())
    )
  }

  get activeSegment() {
    return contactsFilterStore.segment
  }

  get isNewSegment() {
    return contactsFilterStore.segment?.id === -1
  }

  get activeSegmentId() {
    return contactsFilterStore.segment?.id ?? null
  }

  setActiveSegment = (segment: Segment) => {
    contactsFilterStore.setSegment(segment)
  }

  changeActiveSegment = (segment: Segment) => {
    this.setActiveSegment(segment)
    this._contactsTableStore?.setActiveContact(null)
    this._contactsTableStore?.changeNewContactMode(false)
  }

  reset = () => {
    this._disposeChangeSegments?.()
  }

  resetActive = () => {
    contactsFilterStore.clearSegment()
  }

  segmentsSearch = (value: string) => {
    this._search = value
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get segmentModalStore() {
    return this._segmentModalStore
  }
}
