// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VirzpC96Emw1UTrCFteN{padding-top:40px;margin-bottom:16px}.XZHzpYhPTSBwZ2kUyng7{margin-left:4px;text-decoration:none}.xb3G5_HI3jFhHmkPgAK9{margin-top:24px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/localNumbers/pages/Messages/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,oBAAA,CAGF,sBACE,eAAA","sourcesContent":[".messagesCheckboxesWrap {\n  padding-top: 40px;\n  margin-bottom: 16px;\n}\n\n.guidelinesLink {\n  margin-left: 4px;\n  text-decoration: none;\n}\n\n.sampleMessageDesc {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messagesCheckboxesWrap": "VirzpC96Emw1UTrCFteN",
	"guidelinesLink": "XZHzpYhPTSBwZ2kUyng7",
	"sampleMessageDesc": "xb3G5_HI3jFhHmkPgAK9"
};
export default ___CSS_LOADER_EXPORT___;
