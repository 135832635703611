import React from 'react'
import Highlighter from 'react-highlight-words'
import classnames from 'classnames'
import { Avatar, Typography } from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import styles from './styles.module.scss'

export type IMiniContactCardProps = {
  item: Contact
  size: 'mini'
  highlighter?: string
}

export const MiniContactCard = ({ item, highlighter }: IMiniContactCardProps) => {
  return (
    <div className={styles.wrap}>
      <Avatar info={item.avatarInfo} size={16} />
      <Typography ariaLabel={'contactName'} variant={'body-md-regular'} ellipsis>
        {highlighter ? (
          <Highlighter
            highlightClassName={classnames(styles.highlighter, styles['highlighter--label'])}
            searchWords={highlighter.split(' ')}
            autoEscape={true}
            textToHighlight={item.name}
          />
        ) : (
          item.name
        )}
      </Typography>
    </div>
  )
}
