import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import type { IResponseTrigger } from 'entities/Trigger/api/type'
import { TriggerViewStore } from 'widgets/TriggerView/store/triggerViewStore'
import { TriggerView } from 'widgets/TriggerView'
import { TriggersRoutes } from 'pages/triggers'

const SuccessContent = ({
  triggerResponse,
  isDuplicate,
}: {
  triggerResponse: IResponseTrigger
  isDuplicate?: boolean
}) => {
  const [store] = useState(() => new TriggerViewStore(triggerResponse, isDuplicate))
  return <TriggerView store={store} />
}

type IEditTriggerProps = {
  isDuplicate?: boolean
}

export const EditTrigger = ({ isDuplicate }: IEditTriggerProps) => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [triggerResponse, setTriggerResponse] = useState<IResponseTrigger | null>(null)
  useEffect(() => {
    if (id) {
      TriggerApi.getTriggerById(id)
        .then((res) => {
          if (res) {
            setTriggerResponse(res.data)
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [id])

  if (isLoading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }
  if (triggerResponse) {
    return <SuccessContent triggerResponse={triggerResponse} isDuplicate={isDuplicate} />
  }
  return <Navigate to={`/${TriggersRoutes.root}/${TriggersRoutes.all}`} />
}
