import { subscriptionStore } from 'entities/Subscription'
import { checkAccessPage, EnumAccessPageKeys } from 'entities/ExportRouters/lib/checkAccessPage'
import {
  AIAutomationKey,
  EnumMenuAIAutomationItemsKey,
  listItemsAIAutomation,
} from 'widgets/Menu/ui/MenuAIAutomation/constants/menuItems'

export const getStateAccessAIAutomation = (key: EnumMenuAIAutomationItemsKey) => {
  if (subscriptionStore.isAgencyWithoutSubscription) return false

  switch (key) {
    case 'chatbot':
      return checkAccessPage(EnumAccessPageKeys.chatbot)
    case 'knowledge_base':
      return checkAccessPage(EnumAccessPageKeys.knowledge_base)
    case 'workflows':
      return checkAccessPage(EnumAccessPageKeys.workflows)
    case 'keywords':
      return checkAccessPage(EnumAccessPageKeys.keywords)
    case 'campaigns':
      return checkAccessPage(EnumAccessPageKeys.campaigns)
    default:
      return true
  }
}

const accessList = () => {
  return Object.entries(listItemsAIAutomation).reduce<string[]>((state, [id, path]) => {
    const key = id as EnumMenuAIAutomationItemsKey

    if (getStateAccessAIAutomation(key)) state.push(path)

    return state
  }, [])
}

export const getPath = (path: string | null) => {
  const list = accessList()

  if (!list.length) return ''
  if (!path) return list[0]
  if (!list.includes(path)) return list[0]

  return path
}

export const getPathAIAutomation = (userId: number) => {
  let path = window.localStorage.getItem(`${userId}_menu_${AIAutomationKey}`)

  path = getPath(path)

  if (path && path[0] === '/') {
    path = path.slice(1)
  }

  return path
}
