// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EXKnqr58W5kYFmRBw6e3{display:flex;flex-direction:row;gap:8px;align-items:center;justify-content:center}.EXKnqr58W5kYFmRBw6e3 .Tgz0zedZgwagJaCgbDOc{cursor:pointer;color:var(--yellow-70)}.EXKnqr58W5kYFmRBw6e3 .Tgz0zedZgwagJaCgbDOc:hover{color:var(--yellow-80)}", "",{"version":3,"sources":["webpack://./src/widgets/MessageField/ui/FieldActions/MessageCounter/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CACA,sBAAA,CAEA,4CACE,cAAA,CACA,sBAAA,CAEA,kDACE,sBAAA","sourcesContent":[".messageCounterContent {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n  justify-content: center;\n\n  .warningIcon {\n    cursor: pointer;\n    color: var(--yellow-70);\n\n    &:hover {\n      color: var(--yellow-80);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageCounterContent": "EXKnqr58W5kYFmRBw6e3",
	"warningIcon": "Tgz0zedZgwagJaCgbDOc"
};
export default ___CSS_LOADER_EXPORT___;
