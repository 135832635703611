// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ddXCS_EK69y4hG03oZES{display:flex;flex-direction:column;gap:20px;width:180px}.YUuZvJ2oyJ7hjSkT0W6c{color:var(--green-70)}.jlUXFJ7_tOzoKdNjFtnW{color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/Menu/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,WAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,qCAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 180px;\n}\n\n.requiredVerificationTitle {\n  color: var(--green-70);\n}\n\n.verificationTitle {\n  color: var(--content-primary-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ddXCS_EK69y4hG03oZES",
	"requiredVerificationTitle": "YUuZvJ2oyJ7hjSkT0W6c",
	"verificationTitle": "jlUXFJ7_tOzoKdNjFtnW"
};
export default ___CSS_LOADER_EXPORT___;
