import { FC } from 'react'
import { Typography } from 'shared/ui'
import { TriggerStatus, getConfigByStatus } from 'entities/Trigger'

import { ITriggerStatus } from 'entities/Trigger/api/type'
import styles from './styles.module.scss'

type IWidgetTriggerStatusTriggerProps = {
  items: ITriggerStatus[]
}

export const WidgetTriggerStatusTrigger: FC<IWidgetTriggerStatusTriggerProps> = ({ items }) => {
  if (!items.length) return <>select</>

  return (
    <div className={styles.box}>
      <div className={styles.group}>
        {items.map((status) => {
          return <TriggerStatus key={status} status={status} onlyIcon emphasis='low' />
        })}
      </div>
      <Typography ariaLabel='TriggerStatus' variant='body-md-regular'>
        {items.length > 1 ? `${items.length} statuses` : getConfigByStatus(items[0]).title}
      </Typography>
    </div>
  )
}
