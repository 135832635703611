import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { InView } from 'react-intersection-observer'
import { type IColumn, SpinnerLoader, Table } from 'shared/ui'
import { useQuickLinksContext } from 'pages/settings/pages/quickLinks/context/quickLinksContext'

import styles from './styles.module.scss'
import { QuickLinkUrl } from './QuickLinkUrl'
import { QuickLinkLabel } from './QuickLinkLabel'
import { type IQuickLinkRow, useQuickLinksWithActions } from './useQuickLinksWithActions'

const COLUMNS: IColumn<IQuickLinkRow>[] = [
  {
    field: 'label',
    name: 'Label',
    width: '34%',
    renderRowCell: ({ template }) => <QuickLinkLabel template={template} />,
  },
  {
    field: 'url',
    name: 'URL',
    width: '66%',
    renderRowCell: ({ template }) => <QuickLinkUrl template={template} />,
  },
]

export const QuickLinkTable = observer(() => {
  const { listStore, operationStore } = useQuickLinksContext()

  const rows = useQuickLinksWithActions()

  const isSearchLoading = listStore.isEmpty && listStore.loading
  const isSearchHasMore = !listStore.isEmpty && listStore.hasMore
  const isSearchNoResults = listStore.isEmpty && !listStore.loading

  const handleLoadMore = useCallback(() => {
    const loadMore = !listStore.loading && listStore.hasMore

    if (loadMore) listStore.loadMore(true)
  }, [listStore.loading && listStore.hasMore])

  if (isSearchLoading) return <SpinnerLoader />

  return (
    <section className={styles.table}>
      <Table
        noPagination
        columns={COLUMNS}
        rows={rows}
        onRowClick={(row) => operationStore.edit(row.template)}
        bottomTableContent={
          <tr>
            <td className={styles.footer} colSpan={3}>
              <InView onChange={(inView) => inView && handleLoadMore()}>
                {({ ref }) => (
                  <div className={styles.footerContent} ref={ref}>
                    {isSearchHasMore && <SpinnerLoader />}
                    {isSearchNoResults && <div className={styles.noResults}>No results found</div>}
                  </div>
                )}
              </InView>
            </td>
          </tr>
        }
      />
    </section>
  )
})
