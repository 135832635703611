import { observer } from 'mobx-react-lite'
import { AttachmentCard } from 'entities/Attachment'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { MessageCardType, MessageProps } from 'widgets/MessageCard/ui/types'
import { ConversationMessageCardItem } from 'widgets/MessageCard/ui/ConversationMessageCardItem/ConversationMessageCardItem'
import { DateContent } from 'widgets/MessageCard/ui/DateContent/DateContent'

export const AttachmentContent = observer((props: MessageProps) => {
  const {
    message: { previewGifs },
    attachments,
  } = props

  const filterAttachments = attachments?.filter((attachment) =>
    attachment.type === 'video' ? !(previewGifs.length && attachment?.media_preview) : true
  )

  if (!filterAttachments?.length) {
    return <></>
  }

  const onOpenPresentationOtherAttachments = (index: number) => () => {
    presentationStore.setAttachments(filterAttachments, index)
  }

  return (
    <>
      {filterAttachments.map((attachment, index) => {
        return (
          <ConversationMessageCardItem
            key={attachment.id}
            message={props.message}
            type={MessageCardType.Attachment}
          >
            <AttachmentCard
              attachment={attachment}
              medium
              onCardClick={onOpenPresentationOtherAttachments(index)}
              hideVideo={!!previewGifs.length}
              onLoad={props.onLoad}
            />
            <DateContent {...props} />
          </ConversationMessageCardItem>
        )
      })}
    </>
  )
})
