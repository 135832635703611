import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, type IIconButtonProps } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallMicPopUpOutgoingButton: React.FC<Partial<IIconButtonProps>> = observer((props) => {
  const store = useCallPopUpDefaultContext()
  const { handleShowMic, isMic, isMute, handleMute, isMicDisabled, innerRefTooltipCurrent } =
    store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'red'}
        variant={'custom'}
        active={isMute}
        disabled={isMicDisabled}
        ariaLabel={`CallMicPopUpOutgoingButton_${isMute ? 'unmute' : 'mute'}`}
        onClick={() => {
          handleMute()
        }}
        iconProps={{
          icon: isMute ? 'microphoneMute' : 'microphone',
        }}
        tooltipProps={{
          label: isMute ? 'Unmute' : 'Mute',
          placement: 'top',
          zIndex: 5001,
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
        iconCircleButtonProps={{
          iconProps: {
            icon: isMic ? 'chevronUp' : 'chevronDown',
          },
          onClick: () => {
            handleShowMic()
          },
        }}
        {...props}
      />
    </div>
  )
})
