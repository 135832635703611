import { CampaignScheduleStore } from 'pages/campaigns/ui/CampaignSchedule/store/CampaignScheduleStore'
import { CampaignScheduleContext } from './context/context'
import { CampaignScheduleUi } from './ui/CampaignScheduleUi'

type ICampaignScheduleProps = {
  store: CampaignScheduleStore
}

export const CampaignSchedule = ({ store }: ICampaignScheduleProps) => {
  return (
    <CampaignScheduleContext.Provider value={store}>
      <CampaignScheduleUi />
    </CampaignScheduleContext.Provider>
  )
}
