import { FC } from 'react'
import styles from './styles.module.scss'

type ITooltipLinkProps = {
  text: string
  onClick: () => void
}

export const TooltipLink: FC<ITooltipLinkProps> = ({ text, onClick }) => {
  return (
    <div
      className={styles.upgradeButton}
      onClick={() => {
        onClick()
      }}
    >
      {text}
    </div>
  )
}
