import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import type { IStatusProps } from 'shared/ui/Status'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { type IResponseCallingVoiceStatus } from 'entities/Compliance/api/complianceType'
import { BusinessProfileModalContent } from 'pages/settings/pages/compliance/ui/BusinessProfileModalContent/BusinessProfileModalContent'
import { EstimatedResponseInfo } from 'pages/settings/pages/compliance/ui/EstimatedResponseInfo/EstimatedResponseInfo'
import { LocalNumbersStepsStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersStepsStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { BusinessProfilePageStore } from 'pages/settings/pages/compliance/pages/businessProfile'
import { StatusComplianceIcons } from 'pages/settings/pages/compliance/constants/statusIcon'
import { TollFreePageStore } from 'pages/settings/pages/compliance/pages/tollFree'
import { CallingPageStore } from 'pages/settings/pages/compliance/pages/calling'
import { LocalNumbersPageStore } from 'pages/settings/pages/compliance/pages/localNumbers'
import type { ComplianceLayerStore } from './complianceLayerStore'
import type { IActiveTab } from './types'

export class CompliancePageStore {
  private readonly _localNumbersStore: LocalNumbersStepsStore
  private readonly _businessProfilePageStore: BusinessProfilePageStore
  private readonly _tollFreePageStore: TollFreePageStore
  private readonly _callingPageStore: CallingPageStore
  private readonly _localNumbersPageStore: LocalNumbersPageStore
  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    this._localNumbersStore = new LocalNumbersStepsStore(this._complianceLayerStore)

    this._businessProfilePageStore = new BusinessProfilePageStore(this)
    this._tollFreePageStore = new TollFreePageStore(this)
    this._callingPageStore = new CallingPageStore(this)
    this._localNumbersPageStore = new LocalNumbersPageStore(this)

    makeAutoObservable(this)
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  get localNumbersStore() {
    return this._localNumbersStore
  }

  get businessProfilePageStore() {
    return this._businessProfilePageStore
  }

  get tollFreePageStore() {
    return this._tollFreePageStore
  }

  get callingPageStore() {
    return this._callingPageStore
  }

  get localNumbersPageStore() {
    return this._localNumbersPageStore
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get businessProfile() {
    return this._complianceLayerStore.businessProfile
  }

  get tollFreeProfileItems() {
    return this._complianceLayerStore.tollFreeProfileItems
  }

  get complianceErrors() {
    return this.complianceLayerStore.businessProfile?.twilio_service_errors
  }

  get complianceErrorsMessage() {
    return this.complianceErrors?.map((error) => error.error_message).join('\n')
  }

  get businessInformation() {
    return this.complianceLayerStore.businessInformation
  }

  openSummaryModal = () => {
    const modalId = 'business_profile_summary'
    const iconData = this.getIconConditionByTab('BP')

    modalStore.addModal({
      id: modalId,
      title: 'Business profile',
      width: 540,
      showCloseButton: false,
      hiddenContent: true,
      ModalContent: () => {
        return (
          <BusinessProfileModalContent
            statusData={
              <EstimatedResponseInfo
                dayCount={1}
                labelPart='Business Profile'
                iconData={iconData}
                status={this.businessProfile?.status}
                submittedAt={this.businessProfile?.submitted_at}
              />
            }
            closeModal={() => modalStore.closeModal(modalId)}
            errorMessage={this.complianceErrorsMessage}
            editBusinessProfile={() => {
              uiStore.changeRoute({
                path: `/${SettingsPath.compliance.businessProfile.companyLocation}`,
              })
            }}
            complianceLayerStore={this.complianceLayerStore}
          />
        )
      },
    })
  }

  // Add own methods for calling and toll-free as localNumberCampaignStatus and businessProfileDataStatus
  getIconCondition = ({
    status,
  }: {
    status?: IResponseCallingVoiceStatus | 'alert' | undefined | null
  }) => {
    if (status === 'under_review') {
      return StatusComplianceIcons.carrierReview
    }

    if (status === 'failed') {
      return StatusComplianceIcons.reject
    }

    if (status === 'approved') {
      return StatusComplianceIcons.approved
    }

    if (status === 'alert') {
      return StatusComplianceIcons.alert
    }

    if (status === 'pending') {
      return StatusComplianceIcons.pendingReview
    }

    return null
  }

  get standartVerificationItems(): Record<string, IActiveTab[]> {
    const { summary } = this.complianceLayerStore || {}
    const isCanadaTarget = summary?.target_country === 'CA'
    const isTaxId = summary?.has_tax_id
    const numbersType = summary?.numbers_type

    const commonConfigs = {
      required: [],
      recommended: [],
      optional: [],
    }

    if (!isCanadaTarget) {
      if (isTaxId) {
        if (numbersType === 'local') {
          return {
            ...commonConfigs,
            required: ['BP', 'LN'],
            recommended: ['Calling'],
            optional: ['TF'],
          }
        }
        if (numbersType === 'toll_free') {
          return {
            ...commonConfigs,
            required: ['TF'],
            recommended: ['BP', 'Calling'],
            optional: ['LN'],
          }
        }
        return { ...commonConfigs, required: ['BP', 'LN', 'TF'], recommended: ['Calling'] }
      }

      if (numbersType === 'toll_free') {
        return { ...commonConfigs, required: ['TF'], optional: ['BP', 'LN', 'Calling'] }
      }

      return commonConfigs
    }

    if (isCanadaTarget) {
      if (isTaxId) {
        if (numbersType === 'local') {
          return { ...commonConfigs, recommended: ['BP', 'Calling'], optional: ['LN', 'TF'] }
        }
        return {
          ...commonConfigs,
          required: ['TF'],
          recommended: ['BP', 'Calling'],
          optional: ['LN'],
        }
      }
      if (numbersType === 'local') {
        return { ...commonConfigs, optional: ['TF', 'BP', 'LN', 'Calling'] }
      }
      return { ...commonConfigs, required: ['TF'], optional: ['BP', 'LN', 'Calling'] }
    }

    return { ...commonConfigs, required: ['TF'], optional: ['BP', 'LN', 'Calling'] }
  }

  get verificationItems(): Record<string, IActiveTab[]> {
    const config = this.standartVerificationItems
    const isTollFreeNums = this.complianceLayerStore.tollFreeUnverifiedNumbers.length > 0

    if (isTollFreeNums && !config.required.includes('TF')) {
      return {
        required: [...config.required, 'TF'],
        optional: config.optional.filter((item) => item !== 'TF'),
        recommended: config.recommended.filter((item) => item !== 'TF'),
      }
    }
    return config
  }

  getIconConditionByTab = (tab: IActiveTab) => {
    if (tab === 'BP')
      return this._businessProfilePageStore.businessProfileDataStatus as IStatusProps

    if (tab === 'TF')
      return this.getIconCondition({
        status: this._tollFreePageStore.tollFreeDataStatus,
      }) as IStatusProps
    if (tab === 'LN') return this._localNumbersPageStore.localNumberDataStatus as IStatusProps

    return null
  }
}
