import { CSSProperties, FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

type IDividerProps = {
  style?: CSSProperties
  className?: string
  variant?: 'dropdown' | 'text'
  children?: ReactNode | undefined
}

/**
 * @deprecated
 * use import { Divider } from 'shared/ui/Divider'
 */
export const DividerCustom: FC<IDividerProps> = ({ style, className, variant, children }) => {
  return (
    <div
      className={classNames(styles.divider, variant && styles[variant], className)}
      style={style}
    >
      {children}
    </div>
  )
}
