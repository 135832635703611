import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import { ContainerHeader, variantActionsProps } from 'shared/ui'
import {
  TriggerIntegration,
  TriggerInvalidAlert,
  TriggerStatus,
  TriggerType,
} from 'entities/Trigger'
import { TriggersRoutes } from 'entities/ExportRouters'
import { useTriggerDetailContext } from 'widgets/TriggerDetail/context/triggerDetailContext'
import { getTriggerActions } from 'widgets/TriggerActions'
import styles from './styles.module.scss'

export const TriggerDetailHeader = observer(() => {
  const { trigger, pageLayoutStore } = useTriggerDetailContext()
  const { titleIconProps, headerMenuTooltipIconProps, headerMenuIconAction } = pageLayoutStore
  const { name, type, integration } = trigger

  const navigate = useNavigate()

  const onSuccessDelete = () => {
    navigate(`${TriggersRoutes.root}/${TriggersRoutes.all}`)
  }

  return (
    <div>
      <Breadcrumbs />
      <ContainerHeader
        paddingTop={16}
        paddingBottom={12}
        title={name}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        actionIconTooltipProps={headerMenuTooltipIconProps}
        actionsProps={{
          ...variantActionsProps['headerMedium'],
          showItems: 3,
          actions: getTriggerActions({ trigger, onSuccessDelete }),
        }}
        titleIconAction={headerMenuIconAction}
        paddingLeft={20}
        ellipsis
      />
      <div className={styles.info}>
        <TriggerStatus status={trigger.status} />
        <TriggerType type={type} />
        <TriggerIntegration integration={integration} />
      </div>
      <TriggerInvalidAlert trigger={trigger} className={styles.alert} />
    </div>
  )
})
