import React, { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import {
  DropdownPlaceholder,
  EnumDropdownItemVariant,
  IDropdownItem,
  IDropdownProps,
} from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { ITextFieldPlaceholderProps, makeTextFieldIcon, TextField } from 'shared/ui/TextField'
import styles from '../styles.module.scss'

type ITimePickerProps = {
  selected?: Date | null
  onChange: (date: Date | null) => void
  filteredPassedTime?: Date
  offsetMinutes?: number
  parentsDropdownId?: string[]
  disabled?: boolean
  textFieldPlaceholderProps?: Partial<ITextFieldPlaceholderProps>
  defaultValue?: Date
  onChangeOpen?: IDropdownProps['onChangeOpen']
}

export const TimePicker = ({
  selected,
  onChange,
  filteredPassedTime,
  offsetMinutes = 0,
  parentsDropdownId,
  disabled,
  textFieldPlaceholderProps,
  defaultValue,
  onChangeOpen,
}: ITimePickerProps) => {
  const refSearch = useRef('')
  const [search, setSearch] = useState('')
  const refFocus = useRef(false)
  const handleChange = (item: IDropdownItem) => {
    const value = new Date(String(item.value))
    refFocus.current = false

    onChange(value)
  }

  const onBlur = () => {
    const promise = new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (!refFocus.current) {
          setSearch('')
          refSearch.current = ''
          return
        }
        refFocus.current = false
        let date: Date | null = null
        const search = refSearch.current
        try {
          if (!search.trim()) {
            throw 'Date invalid'
          }
          const split = search.split(' ')
          let hours = 0
          let minutes = 0
          const left = split[0]
          const right = split[1]

          if (left) {
            const leftSplit = left.split(':')
            const hoursPart = leftSplit[0]
            const minutesPart = leftSplit[1]
            if (hoursPart) {
              const parseHours = parseInt(hoursPart)
              if (parseHours <= 12) {
                hours = parseHours
              } else {
                throw 'Date invalid'
              }
            }
            if (minutesPart) {
              const parseMinutes = parseInt(minutesPart)
              if (parseMinutes <= 60) {
                minutes = parseMinutes
              } else {
                throw 'Date invalid'
              }
            }
          }
          const isAm = right?.toLowerCase() === 'am'
          let isPm = right?.toLowerCase() === 'pm'

          if (right && !isAm && !isPm) {
            throw 'Date invalid'
          }
          if (!right && !isPm) {
            isPm = dayjs().get('hours') > 12
          }
          const pmHours = isPm && hours < 12 ? 12 : 0

          let additionHours = 0
          if (isAm) {
            additionHours = hours < 12 ? hours : 0
          } else {
            additionHours = hours + pmHours
          }

          date = dayjs(selected)
            .startOf('day')
            .add(additionHours, 'hour')
            .add(minutes, 'minutes')
            .toDate()
          onChange(date)
        } catch (e) {
          reject(e)

          onChange(defaultValue || dayjs(selected).toDate())
        } finally {
          setSearch('')
          refSearch.current = ''
        }
      }, 100)
    })

    promise.catch((error) => console.error(error))
  }

  const onChangeRaw = (value: string) => {
    setSearch(value)
    refSearch.current = value
  }

  const time = selected ? dayjs(selected).format(DayjsFormats.time2) : ''

  const onFocus = () => {
    refFocus.current = true
  }

  const items: IDropdownItem[] = useMemo(() => {
    const selectedDate = selected || new Date()

    return Array(96)
      .fill(0)
      .map((_, index) => {
        const dayjsDate = dayjs(selectedDate)
          .startOf('day')
          .add(index * 15 * 60, 'seconds')
        const label = dayjsDate.format(DayjsFormats.time2)
        const isPassedTime = () => {
          const currentDate = uiStore
            .dayjs(filteredPassedTime)
            .add(offsetMinutes, 'minutes')
            .toDate()
          const selectedDate = dayjsDate.toDate()

          return currentDate.getTime() > selectedDate.getTime()
        }
        return {
          id: dayjsDate.toString(),
          value: dayjsDate.toString(),
          disabled: filteredPassedTime && isPassedTime(),
          label,
          variant: EnumDropdownItemVariant.CheckedRight,
          activeValue: label === time,
        }
      })
  }, [selected, time])

  const filterItems = search
    ? items.filter((item) => item.label.toLowerCase().startsWith(search.trim().toLowerCase()))
    : items

  const inputValue = refFocus.current ? search : time

  return (
    <DropdownPlaceholder
      items={filterItems.length ? filterItems : items}
      onChange={handleChange}
      parentsDropdownId={parentsDropdownId}
      topListComponent={(onCloseMenu) => (
        <div className={styles.wrapTimePicker}>
          <TextField
            variant={'integrated'}
            size={'small'}
            InputProps={{
              placeholder: 'Select time',
              onKeyDown: (event) => {
                if (event.code === 'Enter') {
                  onBlur()
                  onCloseMenu()
                }
              },
            }}
            disabled={disabled}
            value={inputValue}
            onChange={onChangeRaw}
            onFocus={onFocus}
            onBlur={onBlur}
            leftActions={[
              makeTextFieldIcon({ icon: 'clock', className: styles.timePickerActions }),
            ]}
          />
        </div>
      )}
      placeholder={time}
      textPlaceholderProps={{
        variant: 'stroke',
        size: 'small',
        leftActions: [makeTextFieldIcon({ icon: 'clock', className: styles.timePickerActions })],
        ...textFieldPlaceholderProps,
        className: classNames(
          styles.timePicker,
          { [styles.timePicker__placeholder]: !selected },
          textFieldPlaceholderProps?.className
        ),
      }}
      additionalWidth={8}
      offset={{ y: -4 }}
      onChangeOpen={onChangeOpen}
    />
  )
}
