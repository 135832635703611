import { Button } from 'shared/ui'
import styles from './styles.module.scss'

type ILinkButtonProps = {
  text: string
  link: string
}

export const LinkButton = ({ text, link }: ILinkButtonProps) => {
  return (
    <Button
      className={styles.linkButton}
      text={text}
      size='small'
      typeBtn='informative'
      tag='a'
      href={link}
      target='_blank'
    />
  )
}
