import { makeAutoObservable, reaction, IReactionDisposer } from 'mobx'
import {
  IResponseFilter,
  IResponseCustomFilter,
  IResponseFilterOperator,
} from 'entities/Contacts/api/filterTypes'
import { FilterPayload, IFilterPayload } from 'widgets/FilterEditor/model/FilterPayload'
import { FilterOperators } from 'widgets/FilterEditor/model/FilterOperators'
import { getWidgetType } from 'widgets/FilterEditor/Widget/widgetComponent'
import { EmptyOperators } from 'widgets/FilterEditor/model/definition'

export type IFilterItem = Partial<IFilterPayload> & {
  key: string
  type: string
  operator: string | null
}

export class FilterItem {
  private _key = ''
  private _type = ''
  private _payload: FilterPayload
  private _operators: FilterOperators
  private _disposerChangeView: IReactionDisposer | null = null
  private _showSpawned = true

  constructor(
    item: IFilterItem,
    operators: IResponseFilterOperator[],
    private _config: IResponseFilter | IResponseCustomFilter
  ) {
    this._key = item.key
    this._type = item.type
    this._payload = new FilterPayload(item)
    this._operators = new FilterOperators(operators, item.operator)

    this.reactionChangeView()

    makeAutoObservable(this)
  }

  get showSpawned() {
    return this._showSpawned
  }

  get key() {
    return this._key
  }

  get type() {
    return this._type
  }

  get payload() {
    return this._payload
  }

  get payloadFilter(): IFilterPayload | null {
    if (this.isEmpty) return null

    return {
      key: this._key,
      type: this._type,
      ...this._operators.operator?.payload,
      ...this._payload.payload,
    }
  }

  get operator() {
    return this._operators.operator
  }

  get operators() {
    return this._operators.operators
  }

  get config() {
    return this._config
  }

  get counter() {
    return this._config.counter
  }

  get isEmpty() {
    if (!!this.operator && EmptyOperators.has(this.operator.key)) return false

    return this._payload.isEmpty
  }

  reactionChangeView = () => {
    this._disposerChangeView?.()
    this._disposerChangeView = reaction(
      () => getWidgetType(this),
      () => {
        this._payload.clear()
      }
    )
  }

  setOperator(operator: string | null) {
    this._operators.setOperator(operator)
  }

  setShowSpawned(value: boolean) {
    this._showSpawned = value
  }

  populateJson(data: Record<string, unknown>) {
    this._key = (data.key as IFilterItem['key']) ?? ''
    this._type = ((data.type as IFilterItem['type']) || (data.key as IFilterItem['key'])) ?? ''

    this._operators.setOperator((data.operator as IFilterItem['operator']) ?? null)
    this._operators.operator?.setIsEmpty((data.is_empty as IFilterItem['is_empty']) ?? null)
    this._payload.populateJson(data)
  }

  dispose = () => {
    this._disposerChangeView?.()
  }
}
