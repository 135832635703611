import { createContext, useContext } from 'react'
import { type WorkflowLayoutStore } from 'pages/workflows'

export const WorkflowLayoutContext = createContext<WorkflowLayoutStore | null>(null)

export const useWorkflowLayoutContext = () => {
  const context = useContext(WorkflowLayoutContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with WorkflowLayoutProvider')

  return context
}
