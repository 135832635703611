import { useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { actionWakeMode } from 'shared/lib'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { SidebarV1 } from 'widgets/Sidebar/ui/SidebarV1'
import { SidebarV2 } from 'widgets/Sidebar/ui/SidebarV2'
import { SidebarContext, useSidebarContext } from 'widgets/Sidebar/context/sidebarContext'
import { SidebarStore } from 'widgets/Sidebar/store/sidebarStore'
import { useEventConversationUnread } from 'widgets/Sidebar/events/EventConversationUnread'

export const SidebarContent = observer(() => {
  const store = useSidebarContext()
  const isNewMenu = featureFlagsStore.salesmsg_design_v2_new_sidebar_menu

  useEventConversationUnread(store)

  useEffect(() => {
    store.init()
    store.handleReactionInboxes()

    return () => {
      store.reset()
    }
  }, [])

  useEffect(() => {
    actionWakeMode(() => {
      store.reInitInboxes()
    })
  }, [])

  if (isNewMenu) {
    return <SidebarV2 />
  }

  return <SidebarV1 />
})

export const Sidebar = () => {
  const store = useMemo(() => new SidebarStore(), [])

  return (
    <SidebarContext.Provider value={store}>
      <SidebarContent />
    </SidebarContext.Provider>
  )
}
