import { Contact } from 'entities/Contacts/model/Contact'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { UserIntegration } from 'entities/Integrations/model/UserIntegration'

const emptyLink = '#'

export enum IIntegrationLinkTypes {
  contact = 'contact',
  deal = 'deal',
  company = 'company',
  opportunities = 'opportunities',
}

type IIntegrationLinkProps = {
  key: IntegrationKey | null
  integration?: UserIntegration | null
  contact?: Contact | null
  contactId?: number | string
  type: IIntegrationLinkTypes
  id?: string | number | null | undefined
}

export const integrationLink = (props: IIntegrationLinkProps) => {
  const { key, integration, contact, type, id } = props

  switch (key) {
    case IntegrationKey.hubspot: {
      const providerIdentifier = integration?.user_credential?.provider_identifier
      const contactId = props.contactId || contact?.integration_vendor_id || contact?.id

      if (!providerIdentifier) return emptyLink
      if (type === IIntegrationLinkTypes.contact) {
        if (!contactId) return emptyLink

        return `https://app.hubspot.com/contacts/${providerIdentifier}/contact/${contactId}`
      }

      if (type === IIntegrationLinkTypes.deal) {
        if (!id) return emptyLink

        return `https://app.hubspot.com/contacts/${providerIdentifier}/deal/${id}`
      }

      if (type === IIntegrationLinkTypes.company) {
        if (!id) return emptyLink

        return `https://app.hubspot.com/contacts/${providerIdentifier}/company/${id}`
      }

      return emptyLink
    }

    case IntegrationKey.activecampaign: {
      const contactId = props.contactId || contact?.integration_vendor_id || contact?.id
      const defaultURL = integration?.url?.split('.')[0]

      if (!defaultURL) return emptyLink

      if (type === IIntegrationLinkTypes.contact) {
        if (!contactId) return emptyLink

        return `${defaultURL}.activehosted.com/app/contacts/${contactId}`
      }

      if (type === IIntegrationLinkTypes.deal) {
        if (!contactId) return emptyLink

        return `${defaultURL}.activehosted.com/app/contacts/${contactId}`
      }

      if (type === IIntegrationLinkTypes.company) {
        if (!contactId) return emptyLink

        return `${defaultURL}.activehosted.com/app/contacts/${contactId}`
      }

      return emptyLink
    }

    case IntegrationKey.infusionsoft: {
      const contactId = props.contactId || contact?.integration_vendor_id || contact?.id
      const defaultURL = integration?.user_credential?.meta?.appUrl

      if (!defaultURL) return emptyLink

      if (type === IIntegrationLinkTypes.contact) {
        if (!contactId) return emptyLink

        return `${defaultURL}/Contact/manageContact.jsp?view=edit&ID=${contactId}`
      }

      if (type === IIntegrationLinkTypes.opportunities) {
        if (!contactId) return emptyLink

        return `${defaultURL}/Contact/manageContact.jsp?view=edit&ID=${contactId}`
      }

      if (type === IIntegrationLinkTypes.company) {
        if (!contactId) return emptyLink

        return `${defaultURL}/Contact/manageContact.jsp?view=edit&ID=${contactId}`
      }

      return emptyLink
    }

    case IntegrationKey.pipedrive: {
      const contactId = props.contactId || contact?.integration_vendor_id || contact?.id
      const defaultURL = integration?.url

      if (!defaultURL) return emptyLink

      if (type === IIntegrationLinkTypes.contact) {
        if (!contactId) return emptyLink

        return `${defaultURL}/person/${contactId}`
      }

      if (type === IIntegrationLinkTypes.deal) {
        if (!contactId) return emptyLink

        return `${defaultURL}/person/${contactId}`
      }

      if (type === IIntegrationLinkTypes.company) {
        if (!contactId) return emptyLink

        return `${defaultURL}/person/${contactId}`
      }

      return emptyLink
    }

    case IntegrationKey.salesforce: {
      const contactId = props.contactId || contact?.integration_vendor_id || contact?.id
      const defaultURL = integration?.url ? new URL(integration.url) : null

      if (!defaultURL) return emptyLink

      if (type === IIntegrationLinkTypes.contact) {
        if (!contactId) return emptyLink

        return `${defaultURL.origin}/${contactId}`
      }

      if (type === IIntegrationLinkTypes.opportunities) {
        if (!id) return emptyLink

        return `${defaultURL.origin}/${id}`
      }

      return emptyLink
    }
  }

  return emptyLink
}
