import { observer } from 'mobx-react-lite'
import { ITextInputProps, TextInput } from 'shared/ui'
import { CHAR_MAX_LENGTH } from 'shared/constants/limits'
import { useNameInputContext } from 'features/createOrEdit/NameInput/context/context'
import styles from './styles.module.scss'

export type INameInputUiProps = {
  placeholder: ITextInputProps['placeholder']
  limit?: ITextInputProps['limit']
} & Omit<ITextInputProps, 'value' | 'setValue'>

export const NameInputUi = observer((props: INameInputUiProps) => {
  const { name, setName, error, setError } = useNameInputContext()
  return (
    <TextInput
      size={'large'}
      value={name}
      setValue={setName}
      error={error}
      limit={CHAR_MAX_LENGTH}
      onFocus={() => setError('')}
      className={styles.name}
      {...props}
    />
  )
})
