import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { apiOptionToDropdownItem, numberFormat } from 'shared/lib'
import { IDropdownItem } from 'shared/ui'
import { AdminComplianceApi, IResponseAdminTollFreeByID } from 'entities/Admin/compliance'
import { ITollFreeStatus } from 'entities/Compliance'
import { Attachment } from 'entities/Attachment/model/Attachment'
import type { IOptInMethodOld } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/store/type'

class AdminTollFreeCampaignStore {
  constructor() {
    makeAutoObservable(this)
  }

  id: number | null = null
  number_id: number | null = null

  isEdit = false
  isSaveLoading = false
  status: ITollFreeStatus | null = null
  rejectReason = ''

  errorsObj: { [key: string]: string[] } = {}

  get isForReview() {
    return this.status === 'Internal Review' || this.status === 'Denied'
  }

  get isForEdit() {
    return this.isForReview || this.status === 'Unverified' || !this.status
  }

  vendor_id: string | null = ''
  edit_vendor_id: typeof this.vendor_id = null
  get is_edit_vendor_id() {
    return this.vendor_id !== this.edit_vendor_id
  }
  set_edit_vendor_id = (value: typeof this.vendor_id) => {
    this.edit_vendor_id = value
  }

  use_case: IDropdownItem | null = null
  edit_use_case: typeof this.use_case = null
  get is_edit_use_case() {
    return this.use_case?.id !== this.edit_use_case?.id
  }
  set_edit_use_case = (value: typeof this.edit_use_case) => {
    this.edit_use_case = value
  }

  explanation_description: string | null = ''
  edit_explanation_description: typeof this.explanation_description = null
  get is_edit_explanation_description() {
    return this.explanation_description !== this.edit_explanation_description
  }
  set_edit_explanation_description = (value: typeof this.edit_explanation_description) => {
    this.edit_explanation_description = value
  }

  use_case_description: string | null = ''
  edit_use_case_description: typeof this.use_case_description = null
  get is_edit_use_case_description() {
    return this.use_case_description !== this.edit_use_case_description
  }
  set_edit_use_case_description = (value: typeof this.edit_use_case_description) => {
    this.edit_use_case_description = value
  }

  sample_message_1: string | null = ''
  edit_sample_message_1: typeof this.sample_message_1 = null
  get is_edit_sample_message_1() {
    return this.sample_message_1 !== this.edit_sample_message_1
  }
  set_edit_sample_message_1 = (value: typeof this.edit_sample_message_1) => {
    this.edit_sample_message_1 = value
  }

  sample_message_2: string | null = ''
  edit_sample_message_2: typeof this.sample_message_2 = null
  get is_edit_sample_message_2() {
    return this.sample_message_2 !== this.edit_sample_message_2
  }
  set_edit_sample_message_2 = (value: typeof this.edit_sample_message_2) => {
    this.edit_sample_message_2 = value
  }

  opt_int_type: IDropdownItem | null = null
  edit_opt_int_type: IDropdownItem | null = null
  get is_edit_opt_int_type() {
    return this.opt_int_type?.id !== this.edit_opt_int_type?.id
  }
  set_edit_edit_opt_int_type = (value: typeof this.edit_opt_int_type) => {
    if (!value) {
      return
    }
    if (value?.id === this.edit_opt_int_type?.id) {
      return
    }
    this.edit_opt_int_type = value
    this.edit_opt_in_method = {
      opt_in_method_id: Number(value.id),
      title: value.label,
      attachments: [],
      description: '',
    }
  }

  opt_in_data: IResponseAdminTollFreeByID['profile_data']['opt_in_data'] = []
  opt_in_method: IOptInMethodOld | null = null
  edit_opt_in_method: IOptInMethodOld | null = null

  get is_edit_opt_in_method() {
    return (
      this.opt_in_method?.attachments.map((attachment) => attachment.id).toString() !==
      this.edit_opt_in_method?.attachments.map((attachment) => attachment.id).toString()
    )
  }

  get is_edit_opt_in_method_description() {
    return this.opt_in_method?.description !== this.edit_opt_in_method?.description
  }

  remove_opt_in_attachment = (attachmentId: string | number) => {
    if (this.edit_opt_in_method) {
      this.edit_opt_in_method = {
        ...this.edit_opt_in_method,
        attachments: [
          ...this.edit_opt_in_method.attachments.filter(
            (attachment) => attachment.id !== attachmentId
          ),
        ],
      }
    }
  }

  add_opt_in_file = (file: File) => {
    if (this.edit_opt_in_method) {
      const attachment = new Attachment({ file })
      this.edit_opt_in_method = {
        ...this.edit_opt_in_method,
        attachments: [...this.edit_opt_in_method.attachments, attachment],
      }
    }
  }

  add_opt_in_description = (description: string) => {
    if (this.edit_opt_in_method) {
      this.edit_opt_in_method = {
        ...this.edit_opt_in_method,
        description,
      }
    }
  }

  message_volume: IDropdownItem | null = null
  edit_message_volume: typeof this.message_volume = null
  get is_edit_message_volume() {
    return this.message_volume?.id !== this.edit_message_volume?.id
  }
  set_edit_message_volume = (value: typeof this.edit_message_volume) => {
    this.edit_message_volume = value
  }

  get hasChanges() {
    if (!this.isEdit) {
      return false
    }
    const changes = [
      this.is_edit_vendor_id,
      this.is_edit_use_case,
      this.is_edit_explanation_description,
      this.is_edit_use_case_description,
      this.is_edit_sample_message_1,
      this.is_edit_sample_message_2,
      this.is_edit_opt_int_type,
      this.is_edit_opt_in_method,
      this.is_edit_opt_in_method_description,
      this.is_edit_message_volume,
    ]
    return changes.some((item) => item)
  }

  reset = () => {
    this.number_id = null
    this.errorsObj = {}
    this.id = null
    this.status = null
    this.vendor_id = null
    this.use_case = null
    this.use_case_description = null
    this.explanation_description = null
    this.sample_message_1 = null
    this.opt_int_type = null
    this.opt_in_data = []
    this.opt_in_method = null
    this.message_volume = null
    this.isEdit = false
  }

  setCampaign(number_id: number, response?: IResponseAdminTollFreeByID) {
    this.number_id = number_id
    this.status = response?.number?.verified_status as ITollFreeStatus
    this.vendor_id = response?.number?.vendor_id || ''
    const profile = response?.profile_data
    this.rejectReason =
      response?.number.tollFreeVerificationErrors
        ?.map((reason) => reason?.rejection_reason)
        .join(', ') || ''
    if (profile?.id) {
      this.id = profile.id
      this.use_case = apiOptionToDropdownItem(profile.use_case)
      this.explanation_description = profile.explanation_description
      this.use_case_description = profile.use_case_description
      this.sample_message_1 = profile.sample_message_1
      this.sample_message_2 = profile.sample_message_2
      this.opt_int_type =
        profile.opt_in_methods && apiOptionToDropdownItem(profile.opt_in_methods[0])
      this.opt_in_data = profile.opt_in_data
      if (profile.opt_in_data && profile.opt_in_data[0]) {
        const data_item = profile.opt_in_data[0]
        this.opt_in_method = {
          opt_in_method_id: data_item.opt_in_method_id,
          title:
            profile.opt_in_methods.find((method) => method.id === data_item.opt_in_method_id)
              ?.title || '',
          description: data_item.description,
          attachments: data_item.files.map(
            (file) =>
              new Attachment({
                imageUrl: file.source_url,
                id: file.id,
              })
          ),
        }
      } else if (profile.opt_in_methods && profile.opt_in_methods[0]) {
        const opt_in_method = profile.opt_in_methods[0]
        this.opt_in_method = {
          opt_in_method_id: opt_in_method.id,
          title: opt_in_method?.title || '',
          description: opt_in_method.description || '',
          attachments: [],
        }
      }

      this.message_volume = {
        id: profile.monthly_volume,
        label: numberFormat({ value: profile.monthly_volume }),
      }
    }
  }

  onEdit = () => {
    this.edit_vendor_id = this.vendor_id
    this.edit_use_case = this.use_case
    this.edit_explanation_description = this.explanation_description
    this.edit_use_case_description = this.use_case_description
    this.edit_sample_message_1 = this.sample_message_1
    this.edit_sample_message_2 = this.sample_message_2
    this.edit_opt_int_type = this.opt_int_type
    this.edit_opt_in_method = this.opt_in_method
    this.edit_message_volume = this.message_volume

    this.isEdit = true
  }

  onClose = () => {
    this.isEdit = false
  }

  onSave = async () => {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })
      const formData = new FormData()

      if (this.id) {
        formData.append('profile_id', String(this.id))
      }
      formData.append('number_id', String(this.number_id))
      formData.append('use_case_id', String(this.edit_use_case?.id || ''))
      formData.append('explanation_description', String(this.edit_explanation_description || ''))
      formData.append('use_case_description', String(this.edit_use_case_description || ''))
      formData.append('sample_message_1', String(this.edit_sample_message_1 || ''))
      formData.append('sample_message_2', String(this.edit_sample_message_2 || ''))

      if (this.edit_opt_in_method) {
        const opt_in_old_files_ids: number[] = []
        const newFiles: File[] = []
        this.edit_opt_in_method.attachments.forEach((attachment) => {
          if (attachment.file) {
            newFiles.push(attachment.file)
          } else {
            opt_in_old_files_ids.push(Number(attachment.id))
          }
        })
        formData.append(
          'opt_in_data[0][opt_in_method_id]',
          String(this.edit_opt_in_method.opt_in_method_id)
        )
        formData.append('opt_in_data[0][relatable_service_type]', String(2))
        formData.append('opt_in_data[0][opt_in_old_files_ids]', String(opt_in_old_files_ids))
        formData.append(
          'opt_in_data[0][description]',
          String(this.edit_opt_in_method.description || '')
        )

        newFiles.forEach((file, indexFile) => {
          formData.append(`opt_in_data[0][opt_in_files][${indexFile}][file]`, file)
        })
      }

      formData.append('monthly_volume', String(this.edit_message_volume?.id || ''))

      return await AdminComplianceApi.updateTollFreeCampaign(formData)
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data) {
            this.errorsObj = e.response.data
          }
        }
      })
      throw e
    } finally {
      runInAction(() => {
        this.isSaveLoading = false
      })
    }
  }

  getError = (key: string) => {
    return this.errorsObj && this.errorsObj[key] && this.errorsObj[key][0]
  }
}

export const adminTollFreeCampaignStore = new AdminTollFreeCampaignStore()
