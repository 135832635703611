// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rBlae2sQzD64tyhUWAla{display:flex;justify-content:space-between;align-items:flex-start;padding:12px 1px 12px 0}.nT_82as3Vo8ba3PHlLcx{padding:4px 0}.ZbDcs5giK6X1LGi34AHr{height:4px;border-radius:6px}.QXNxIzedUY5wwntL5QFr{text-align:left}.tw1Y0YmAZ4Dy9BZ2a7V6{white-space:nowrap;padding-top:5px}", "",{"version":3,"sources":["webpack://./src/shared/ui/ProgressSteps/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,uBAAA,CAGF,sBACE,aAAA,CAEA,sBACE,UAAA,CACA,iBAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,kBAAA,CACA,eAAA","sourcesContent":[".wrap {\n}\n\n.topLine {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding: 12px 1px 12px 0;\n}\n\n.progressWrap {\n  padding: 4px 0;\n\n  &__progress {\n    height: 4px;\n    border-radius: 6px;\n  }\n}\n\n.title {\n  text-align: left;\n}\n\n.steps {\n  white-space: nowrap;\n  padding-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topLine": "rBlae2sQzD64tyhUWAla",
	"progressWrap": "nT_82as3Vo8ba3PHlLcx",
	"progressWrap__progress": "ZbDcs5giK6X1LGi34AHr",
	"title": "QXNxIzedUY5wwntL5QFr",
	"steps": "tw1Y0YmAZ4Dy9BZ2a7V6"
};
export default ___CSS_LOADER_EXPORT___;
