import { IResponseToken } from '../api/types'

export enum GoogleAuthTypesEnum {
  login = 'login',
  register = 'register',
}

export type IOrganizationStatus = 'empty' | 'in_progress' | 'completed'

export type IResponseGoogleRegister = {
  token: IResponseToken
}

export type IResponseGoogleLogin = {
  token: IResponseToken
}

export type IParamsSignUpAnswers = {
  first_name: string
  last_name: string
  organization_name: string
  number: string
  email: string
  country: string
  answer_country: string
  answer_countries: string[]
  answer_otherCountry: string
  answer_team_size: string
  answer_integration: string
  answer_role: string
  answer_contactsCount: string
}
