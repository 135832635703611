import { SettingsRoutes } from 'pages/settings/route/type'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { PersonalPath } from 'pages/settings/pages/personal/route/personalPath'

const settings = `${SettingsRoutes.root}`

export const SettingsPath = {
  root: settings,
  compliance: CompliancePath.compliance,
  personal: PersonalPath,
}
