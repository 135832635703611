import { useMemo } from 'react'
import type { ActionItem, IActionsProps } from 'shared/ui'
import type { KnowledgeBaseDocument } from 'entities/KnowledgeBase/model/KnowledgeBaseDocument'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'

type IDocumentWithActionsRow = KnowledgeBaseDocument & {
  actionsProps: IActionsProps
  isCheckboxDisabled: boolean
}

type IUseDocumentsWithActionsRows = () => IDocumentWithActionsRow[]

export const useDocumentsWithActionsRows: IUseDocumentsWithActionsRows = () => {
  const {
    documentsStore: { documents, onDeleteDocuments, onDownloadDocument, onRetryIndexation },
  } = useKnowledgeBaseViewContext()

  return useMemo(() => {
    return documents.map((item) => {
      const isActionsNotAvailable = item.getIsUploading() || item.getIsIndexing()

      const makeDownloadAction: ActionItem = {
        icon: 'download',
        text: 'Download',
        onAction: () => onDownloadDocument(item.id),
      }

      const makeDeleteAction: ActionItem = {
        icon: 'delete',
        text: 'Delete',
        onAction: () => onDeleteDocuments([item.id]),
      }

      const tryAgainAction: ActionItem = {
        icon: 'refresh',
        text: 'Try again',
        onAction: () => onRetryIndexation([item.id]),
      }

      const documentActions = [
        ...(item.getIsIndexationFailed() ? [tryAgainAction] : []),
        makeDownloadAction,
        makeDeleteAction,
      ]

      return {
        ...item,
        actionsProps: {
          actions: isActionsNotAvailable ? [] : documentActions,
        },
        isCheckboxDisabled: isActionsNotAvailable,
      }
    })
  }, [documents])
}
