import React from 'react'
import { observer } from 'mobx-react-lite'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { Icon, Switch } from 'shared/ui'
import { InboxesTeamSelect } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsSync/InboxesTeamSelect'
import { SYNC_TYPE_OPTIONS } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/lib'
import { type IntegrationHubspotSettingsSyncStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsSyncStore'
import { TitledRow } from 'pages/settings/pages/integrations/pages/integrationSettings/TitledRow/TitledRow'
import styles from './styles.module.scss'

const HUBSPOT_CHECKBOXES = [
  {
    title: 'Contact ownership sync',
    label: 'Update the contact owner in HubSpot if the contact owner is updated on Salesmsg.',
    field: 'update_owner',
  },
  {
    title: 'Contact property sync',
    label: 'If contact is updated in Salesmsg, then update contact in HubSpot',
    field: 'update_contacts',
  },
  {
    title: 'Contact creation',
    label:
      "If contact doesn't exist in HubSpot, then create a new contact if contact is added to Salesmsg",
    field: 'create_non_existing_contacts',
  },
] as const

type IIntegrationHubspotSettingsSyncProps = {
  store: IntegrationHubspotSettingsSyncStore
}

export const IntegrationHubspotSettingsSync: React.FC<IIntegrationHubspotSettingsSyncProps> =
  observer(({ store }) => {
    const { isTwoWaySync, setSyncType, syncType, setCheckbox, getCheckbox } = store

    return (
      <div className={styles.root}>
        <div className={styles.integration}>
          <div className={styles.integrationIcon}>
            <Icon fontSize={98} icon={'integrationHubspot'} tertiary />
          </div>
          <div className={styles.typeIcon}>
            <Icon
              fontSize={16}
              icon={isTwoWaySync ? 'arrowHorizontalOutline' : 'arrowRight'}
              tertiary
            />
          </div>
          <div className={styles.integrationIcon}>
            <Icon fontSize={114} icon={'integrationSalessms'} tertiary />
          </div>
          <SelectField
            className={styles.select}
            withSearch={false}
            value={syncType}
            setValue={setSyncType}
            items={SYNC_TYPE_OPTIONS}
            size={'medium'}
          />
        </div>
        {isTwoWaySync &&
          HUBSPOT_CHECKBOXES.map(({ field, title, label }) => (
            <TitledRow key={field} title={title} label={label} verticalAlign={'start'}>
              <Switch size='middle' value={getCheckbox(field)} onChange={setCheckbox(field)} />
            </TitledRow>
          ))}
        <TitledRow
          title={'Fallback inbox'}
          label={'If HubSpot contact owner is not recognized, send from this inbox'}
          align={'normal'}
        >
          <InboxesTeamSelect />
        </TitledRow>
      </div>
    )
  })
