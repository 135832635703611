import { FC } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui/Typography'
import { Scrollbar } from 'shared/ui/Scrollbar/Scrollbar'
import { DropdownItem } from 'shared/ui/Dropdown/ui/DropdownItem'
import { Button } from 'shared/ui/Button'

import styles from './styles.module.scss'
import type { IDropdownGroupProps } from '../types'

export const DropdownGroup: FC<IDropdownGroupProps> = observer(
  ({
    title,
    isScroll = true,
    items = [],
    onChange,
    stylesWrap = {},
    isMultiSelect = false,
    handleMultiSelect,
  }) => {
    const renderContent = () => {
      return (
        <>
          <div className={styles.titleWrapper}>
            {title && (
              <div className={styles.title}>
                <Typography
                  variant={'body-sm-medium'}
                  color={'var(--content-primary-tertiary)'}
                  ariaLabel={'DropdownGroupTitle'}
                >
                  {title}
                </Typography>
              </div>
            )}
            {isMultiSelect && (
              <Button
                text='Select all'
                typeBtn='text'
                contained='secondary'
                size='small'
                intent={'positive'}
                textClassname={styles.selectAllButton}
                onClick={() => {
                  handleMultiSelect?.(items)
                }}
              />
            )}
          </div>
          <div className={styles.list}>
            {items.map((item) => (
              <DropdownItem
                key={item.id}
                item={item}
                onChange={(item, parentItem) => {
                  onChange?.(item, parentItem)
                }}
              />
            ))}
          </div>
        </>
      )
    }

    if (isScroll) {
      return (
        <div
          className={classNames(styles.wrap, { [styles.multiSelectWrap]: isMultiSelect })}
          style={stylesWrap}
        >
          <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={192}>
            {renderContent()}
          </Scrollbar>
        </div>
      )
    }

    return (
      <div
        className={classNames(styles.wrap, { [styles.multiSelectWrap]: isMultiSelect })}
        style={stylesWrap}
      >
        {renderContent()}
      </div>
    )
  }
)
