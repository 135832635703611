import { makeAutoObservable, toJS } from 'mobx'
import { clampNumber } from 'shared/lib/number'

import {
  ChatbotAwaitReplyUnit,
  IChatbotAwaitReply,
  IChatbotAwaitReplyUnit,
  IChatbotSettings,
} from './type'

export class ChatbotAwaitReply {
  static DefaultValue = 1
  static DefaultUnit = ChatbotAwaitReplyUnit.Days

  value = ChatbotAwaitReply.DefaultValue
  unit: IChatbotAwaitReplyUnit = ChatbotAwaitReply.DefaultUnit

  constructor(item?: IChatbotAwaitReply) {
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  setValue(value: number) {
    this.value = value
  }

  setUnit(unit: IChatbotAwaitReplyUnit) {
    this.unit = unit
  }

  syncOrigin(item?: IChatbotAwaitReply | null) {
    this.value = item?.value ?? ChatbotAwaitReply.DefaultValue
    this.unit = item?.unit ?? ChatbotAwaitReply.DefaultUnit
  }

  reset() {
    this.value = ChatbotAwaitReply.DefaultValue
    this.unit = ChatbotAwaitReply.DefaultUnit
  }

  toJSON(): IChatbotAwaitReply {
    return toJS({
      value: this.value,
      unit: this.unit,
    })
  }
}

export class ChatbotSettings {
  static DefaultAttempts = 10
  static MaxAttempts = 20
  static MinAttempts = 1

  knowledgeBaseUsage = false
  maxAttempts = ChatbotSettings.DefaultAttempts
  awaitReply = new ChatbotAwaitReply()
  aboutConversation = ''
  businessInformation = ''
  howToRespond = ''
  instruction = ''

  constructor(item?: IChatbotSettings) {
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  setKnowledgeBaseUsage(isEnabled: boolean) {
    this.knowledgeBaseUsage = isEnabled
  }

  setMaxAttempts(maxAttempts: number) {
    this.maxAttempts = clampNumber(
      maxAttempts,
      ChatbotSettings.MinAttempts,
      ChatbotSettings.MaxAttempts
    )
  }

  setAboutConversation(aboutConversation: string) {
    this.aboutConversation = aboutConversation
  }

  setBusinessInformation(businessInformation: string) {
    this.businessInformation = businessInformation
  }

  setHowToRespond(howToRespond: string) {
    this.howToRespond = howToRespond
  }

  setInstruction(instruction: string) {
    this.instruction = instruction
  }

  syncOrigin(item?: IChatbotSettings | null) {
    this.maxAttempts = item?.max_attempts?.value ?? ChatbotSettings.DefaultAttempts
    this.knowledgeBaseUsage = item?.use_knowledge_base?.value ?? false
    this.aboutConversation = item?.about_conversation?.value ?? ''
    this.businessInformation = item?.business_information?.value ?? ''
    this.howToRespond = item?.how_to_respond?.value ?? ''
    this.instruction = item?.instructions?.value ?? ''

    this.awaitReply.syncOrigin(item?.await_reply)
  }

  toJSON(): IChatbotSettings {
    const about_conversation = this.aboutConversation
      ? {
          value: this.aboutConversation,
        }
      : null

    const business_information = this.businessInformation
      ? {
          value: this.businessInformation,
        }
      : null

    const how_to_respond = this.howToRespond
      ? {
          value: this.howToRespond,
        }
      : null

    const instructions = this.instruction
      ? {
          value: this.instruction,
        }
      : null

    return toJS({
      await_reply: this.awaitReply.toJSON(),
      max_attempts: { value: this.maxAttempts },
      use_knowledge_base: { value: this.knowledgeBaseUsage },
      about_conversation,
      business_information,
      how_to_respond,
      instructions,
    })
  }

  reset() {
    this.aboutConversation = ''
    this.businessInformation = ''
    this.howToRespond = ''
    this.maxAttempts = ChatbotSettings.DefaultAttempts

    this.awaitReply.reset()
  }
}
