import { Icon, type IIconProps } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export const BoxIcon = (props: IIconProps) => {
  return (
    <div className={styles.wrap} aria-label={getAriaLabel('BoxIcon', props.ariaLabel)}>
      <Icon fontSize={14} {...props} />
    </div>
  )
}
