import { uiStore } from 'shared/store/uiStore'
import type { IParamsUpdateCannedMessages, IResponseCannedMessage } from 'entities/CannedMessages'
import { usersStore } from 'entities/Users/store/users'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { RequestAttachment } from 'entities/Attachment/model/RequestAttachment'

export class CannedMessage {
  id: number
  owner_id: number
  organization_id: number
  integration_id: number
  public: boolean
  is_favorite: boolean
  title: string
  message: string
  value: string
  attachments: Attachment[]
  disabled: boolean
  isMyReply: boolean
  created_at: string

  constructor(savedReplyResponseItem: IResponseCannedMessage) {
    this.id = savedReplyResponseItem.id
    this.owner_id = savedReplyResponseItem.owner_id
    this.public = savedReplyResponseItem.public
    this.is_favorite = savedReplyResponseItem.is_favorite
    this.organization_id = savedReplyResponseItem.organization_id
    this.integration_id = savedReplyResponseItem.integration_id
    this.title = savedReplyResponseItem.title
    this.message = savedReplyResponseItem.message
    this.value = savedReplyResponseItem.value
    this.disabled = Boolean(savedReplyResponseItem.disabled)
    this.created_at = savedReplyResponseItem.created_at
    this.attachments = Array.isArray(savedReplyResponseItem.media_url)
      ? savedReplyResponseItem.media_url.map(
          (media) => new Attachment({ requestAttachment: media, fromMediaUrl: true })
        )
      : []
    this.isMyReply = savedReplyResponseItem.owner_id === usersStore.user_owner_id
  }

  get updateData(): IParamsUpdateCannedMessages {
    return {
      integration_id: this.integration_id,
      media_url: this.attachments.map((attachment) => new RequestAttachment(attachment)),
      id: this.id,
      title: this.title,
      public: this.public,
      is_favorite: this.is_favorite,
      message: this.message,
    }
  }

  get createdAtTimestamp() {
    return uiStore.dayjs(this.created_at).valueOf()
  }
}
