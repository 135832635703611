import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'

const TollFreeLayoutContent = observer(() => {
  return (
    <>
      <Outlet />
    </>
  )
})

export const TollFreeLayout = () => {
  return <TollFreeLayoutContent />
}
