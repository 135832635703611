// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".atQnBLGVz_XeADouisqt .j9ja4QqKr1q78EN_R_qO{padding:11px 8px 11px 12px}.atQnBLGVz_XeADouisqt .Inls2alAalP18JsdjzQQ{padding:12px 8px 12px 12px}.YlNZY8tsnFKkLnGNxXe4{margin-top:24px}.KyrsoluJlvovSQSYxmWM{margin-top:24px}.Df6tAApeBDfdc1ApCFaQ{align-items:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/businessProfile/pages/PeopleToContact/ui/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,0BAAA,CAGF,4CACE,0BAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,eAAA,CAGF,sBACE,sBAAA","sourcesContent":[".wrap {\n  .selectPadding {\n    padding: 11px 8px 11px 12px;\n  }\n\n  .inputPadding {\n    padding: 12px 8px 12px 12px;\n  }\n}\n\n.secondContactCheckbox {\n  margin-top: 24px;\n}\n\n.confirmed {\n  margin-top: 24px;\n}\n\n.confirmDesc {\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "atQnBLGVz_XeADouisqt",
	"selectPadding": "j9ja4QqKr1q78EN_R_qO",
	"inputPadding": "Inls2alAalP18JsdjzQQ",
	"secondContactCheckbox": "YlNZY8tsnFKkLnGNxXe4",
	"confirmed": "KyrsoluJlvovSQSYxmWM",
	"confirmDesc": "Df6tAApeBDfdc1ApCFaQ"
};
export default ___CSS_LOADER_EXPORT___;
