import React from 'react'
import { observer } from 'mobx-react-lite'
import { Icon } from 'shared/ui'
import { type IInboxCombine } from 'entities/Inbox'
import styles from './styles.module.scss'

export type PropsInboxTag = {
  inbox: IInboxCombine
}

const InboxTag: React.FC<PropsInboxTag> = observer(({ inbox }) => {
  return (
    <div className={styles.wrap}>
      <span className={styles.icon}>
        <Icon icon={inbox.icon} />
      </span>
      <span className={styles.name}>{inbox?.name}</span>
    </div>
  )
})

export { InboxTag }
