import { makeAutoObservable } from 'mobx'
import { BreakpointsEnum } from 'shared/constants/breakpoints'
import { IS_APP_LIGHT } from 'shared/config'

const IS_ALLOWED_XXS = IS_APP_LIGHT

export class LayoutStore {
  activeBreakpoint: BreakpointsEnum | null = null

  constructor() {
    makeAutoObservable(this)
  }

  handleActiveBreakpoint = (breakpoint: BreakpointsEnum) => {
    if (breakpoint === BreakpointsEnum.XXS && !IS_ALLOWED_XXS) {
      this.activeBreakpoint = BreakpointsEnum.XS
    } else {
      this.activeBreakpoint = breakpoint
    }
  }

  get isViewXXs() {
    return this.activeBreakpoint === BreakpointsEnum.XXS
  }

  get isViewXs() {
    return this.activeBreakpoint === BreakpointsEnum.XS
  }

  get isMobileView() {
    return (
      this.activeBreakpoint === BreakpointsEnum.XXS ||
      this.activeBreakpoint === BreakpointsEnum.XS ||
      this.activeBreakpoint === BreakpointsEnum.S ||
      this.activeBreakpoint === BreakpointsEnum.M
    )
  }
  get isLargeView() {
    return (
      this.activeBreakpoint === BreakpointsEnum.L || this.activeBreakpoint === BreakpointsEnum.XL
    )
  }
  get isMobileViewWithoutMSize() {
    return (
      this.activeBreakpoint === BreakpointsEnum.XXS ||
      this.activeBreakpoint === BreakpointsEnum.XS ||
      this.activeBreakpoint === BreakpointsEnum.S
    )
  }
}

export const layoutStore = new LayoutStore()
