import { Route, Routes } from 'react-router-dom'
import { ClientsLayout } from 'pages/clients/layout/ClientsLayout'
import { ClientsPage } from 'pages/clients/ui/clientsPage'

export const ClientsRouter = () => {
  return (
    <Routes>
      <Route element={<ClientsLayout />}>
        <Route path={'/'} element={<ClientsPage />} />
      </Route>
    </Routes>
  )
}
