import { Route, Routes } from 'react-router'
import { BusinessProfileLayout } from 'pages/settings/pages/compliance/pages/businessProfile/layout/BusinessProfileLayout'
import { BusinessProfilePage } from 'pages/settings/pages/compliance/pages/businessProfile/ui/businessProfilePage'

export const BusinessProfileRouter = () => {
  return (
    <Routes>
      <Route element={<BusinessProfileLayout />}>
        <Route path={'/'} element={<BusinessProfilePage />} />
      </Route>
    </Routes>
  )
}
