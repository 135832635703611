import { observer } from 'mobx-react-lite'
import { ContainerHeader } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { CustomPricesTable } from 'pages/admin/pages/customPrices/pages/CustomPrices/ui/CustomPricesTable/CustomPricesTable'
import { CustomPricesSearch } from 'pages/admin/pages/customPrices/pages/CustomPrices/ui/CustomPricesSearch/CustomPricesSearch'
import { AdminRoutes } from 'pages/admin'
import styles from './styles.module.scss'

export const CustomPrices = observer(() => {
  return (
    <>
      <ContainerHeader
        actions={[
          {
            text: 'New price',
            icon: 'add',
            medium: true,
            onAction: () => {
              uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.customPrices}/create`)
            },
          },
        ]}
        title={'Custom Prices'}
        titleIconProps={{
          icon: 'dollar',
          fontSize: 20,
          tertiary: true,
        }}
      />
      <CustomPricesSearch />
      <div className={styles.table}>
        <CustomPricesTable />
      </div>
    </>
  )
})
