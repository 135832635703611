import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import axios, { AxiosResponse } from 'axios'
import { bannerStore, EnumAlertBannerVariant } from 'shared/ui'
import { storeCache } from 'shared/api/storeCache'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { GET_API_URL, IS_APP_LIGHT, IS_CALLING_APP_LIGHT } from 'shared/config'
import { updateDesignV2Api } from 'shared/lib'
import type {
  IParamsAcceptAgencyInvite,
  IParamsAcceptMemberInvite,
  IParamsLogin,
  IParamsRegister,
  IParamsSendTwoFaCode,
  IParamsVerifyTwoFaCode,
  IResponseAuthRefresh,
  IResponseToken,
  IParamsSignUpRegister,
  IParamsSignUpOrganization,
} from 'entities/Auth'
import { AuthRoutesEnum } from 'entities/Auth/routes/authRoutesEnum'
import { AuthApi } from 'entities/Auth/api'
import {
  getAssumeToken,
  getToken,
  removeTokenLocalStorage,
  setToken,
} from 'entities/Auth/lib/setToken'
import { websocket } from 'entities/WebSocket'
import { resetPosthog } from 'entities/Posthog'
import { clearSessionIntercom } from 'entities/Intercom'
import { UsersApi, usersStore } from 'entities/Users'
import { type IResponseUsersProfilesUiSettings } from 'entities/Users/api/types'
import { userSettingsStore } from 'entities/Settings'
import { sessionManager } from '../lib/sessionManager'
import { GoogleAuthStore } from './googleAuth'
import type { IOrganizationStatus, IResponseGoogleLogin } from './types'

export class AuthStore {
  private _idOrgModal = 'orgModal'
  private _error = ''
  private _loading = true
  private _isAssume = false
  private _isLoggedIn = false
  private _token = ''
  private _organizationStatus: IOrganizationStatus | null = null

  constructor() {
    makeAutoObservable(this)
    this.initToken()
    this.checkAssuming()
    this.reactionOnLoginFromAppLight()
  }

  get isLoggedIn() {
    return this._isLoggedIn
  }

  get hasToken() {
    return !!this._token
  }

  get hasOid() {
    const token = this._token
    if (!this._token) return null

    const [, tokenData] = token.split('.')

    try {
      const parsedData = JSON.parse(atob(tokenData))

      return !!parsedData.oid
    } catch (e) {
      console.error(e)
      return false
    }
  }

  initToken = () => {
    const token = getToken()
    if (token) this._token = token
  }

  socialLoginCallback = (payload: IResponseGoogleLogin) => {
    this.socialLogin(payload.token)
  }

  isNewSession = false
  googleAuthStore: GoogleAuthStore = new GoogleAuthStore(this.socialLoginCallback)

  external_source = ''
  closeAfterLogin = false

  private _disposeReactionOnLoginFromAppLight: IReactionDisposer | null = null

  reactionOnLoginFromAppLight = () => {
    this._disposeReactionOnLoginFromAppLight?.()
    this._disposeReactionOnLoginFromAppLight = reaction(
      () => this.isLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) {
          if (this.closeAfterLogin) {
            setTimeout(() => {
              window.close()
            }, 100)
          }
        }
      }
    )
  }

  getOrganizationStatus = async () => {
    try {
      const {
        data: { organization, organization_in_progress },
      } = await UsersApi.getUsersProfile()

      let status: IOrganizationStatus = organization_in_progress ? 'in_progress' : 'empty'
      if (organization) status = organization.completed ? 'completed' : 'in_progress'

      return status
    } catch (e) {
      return null
    }
  }

  setSuccessAuthToken = (token: string, withoutV2Logic?: boolean) => {
    this.setToken(token)
    this.setLoggedIn(!!this.hasOid)
    if (!withoutV2Logic) {
      this.setDesignV2API()
      document.body.classList.remove('is-auth')
    }

    this.isNewSession = sessionManager.syncSession(true)
  }

  validateToken = async (token: IResponseToken) => {
    const [, tokenData] = token.access_token.split('.')
    let parsedData = null

    try {
      parsedData = JSON.parse(atob(tokenData))
    } catch (e) {
      console.error(e)
    }

    if (!parsedData.oid) {
      try {
        const {
          data: { data },
        }: AxiosResponse<IResponseUsersProfilesUiSettings> = await axios.get(
          'users/profiles/ui-settings/registration-steps',
          {
            baseURL: GET_API_URL(),
            headers: {
              'Content-type': 'application/json',
              'Salesmsg-App': 'react',
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          }
        )

        if (data) {
          this.setSuccessAuthToken(token.access_token)
          return
        }

        modalStore.addModal({
          id: this._idOrgModal,
          type: ModalTypeList.WARNING,
          title: 'Oh-oh! Looks like the Salesmsg account you were part of is no longer available.',
          primaryAction: {
            text: 'Create a new account',
            onAction: () => {
              modalStore.closeModal(this._idOrgModal)
              this.setSuccessAuthToken(token.access_token)
            },
          },
          secondaryAction: {
            text: 'Cancel',
            onAction: () => {
              modalStore.closeModal(this._idOrgModal)
              this.removeToken()
            },
          },
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      this.setSuccessAuthToken(token.access_token)
    }
  }

  async login(body: IParamsLogin) {
    const { data } = await AuthApi.login(body)

    if (data.data?.is_2fa) {
      return data.data.settings
    }

    if (data.token) {
      await this.validateToken(data.token)
    }
  }

  socialLogin(data: IResponseToken) {
    this.setSuccessAuthToken(data.access_token)
  }

  setGoogleAuthStore = (data: IResponseToken) => {
    this.setSuccessAuthToken(data.access_token)
  }

  async register(body: IParamsRegister) {
    const { data } = await AuthApi.register(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  async signUpRegister(body: IParamsSignUpRegister) {
    const { data } = await AuthApi.signUpRegister(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  async signUpOrganization(body: IParamsSignUpOrganization) {
    const { data } = await AuthApi.signUpOrganization(body)
    return data
  }

  async acceptMemberInvite(body: IParamsAcceptMemberInvite) {
    const { data } = await AuthApi.acceptMemberInvite(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  async acceptAgencyInvite(body: IParamsAcceptAgencyInvite) {
    const { data } = await AuthApi.acceptAgencyInvite(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  checkAssuming = () => {
    this._isAssume = !!getAssumeToken()

    if (this._isAssume) {
      bannerStore.add({
        id: nanoid(),
        action: {
          text: 'Stop assuming',
          onAction: () => {
            window.close()
          },
        },
        alert: {
          title: 'You have assumed another user’s account',
          variant: EnumAlertBannerVariant.Warning,
          ariaLabel: 'Assuming',
        },
      })
    }
  }

  setToken = (token: string) => {
    setToken(token)
    this._token = token
  }
  removeToken = () => {
    removeTokenLocalStorage(this._isAssume)
    this._token = ''
  }
  setLoggedIn = (value: boolean) => {
    this._isLoggedIn = value
  }
  logout = async () => {
    storeCache.dispose()
    clearSessionIntercom()

    if (this._isAssume) {
      window.close()
      return
    }
    try {
      localStorage.removeItem('showedAwayStatusDate')
      localStorage.removeItem('contactDetailsOpenStatus')
      localStorage.setItem(`first_logout_${usersStore.user_owner_id}`, '1')
      await AuthApi.logout()
      this.removeToken()
      bannerStore.dispose()
      websocket.disconnect()
      resetPosthog()
      this.setLoggedIn(false)
      window.userpilot?.destroy?.()
    } catch (e) {
      console.error(e)
    } finally {
      if (
        window.location.href.includes(AuthRoutesEnum.login) ||
        window.location.pathname === `/${AuthRoutesEnum.register}`
      )
        return

      if (IS_APP_LIGHT || IS_CALLING_APP_LIGHT) {
        window.location.reload()
        return
      }

      window.stop()
      window.location.href = `/${AuthRoutesEnum.login}`
    }
  }

  refresh = async ({ enableLoader = true, isTryRefreshWithoutToken = false } = {}): Promise<
    IResponseAuthRefresh | undefined
  > => {
    try {
      if (enableLoader) {
        runInAction(() => {
          this._loading = true
        })
      }

      const { data } = await AuthApi.updateAuthRefresh()
      const isLogin = Boolean(data.is_login)

      if (isTryRefreshWithoutToken && !isLogin && getToken()) {
        this.removeToken()
        return await this.refresh()
      }

      runInAction(() => {
        this.setLoggedIn(!!this.hasOid)

        if (enableLoader) {
          this.isNewSession = sessionManager.isNewSession()
          sessionManager.syncSession(false)
        }
      })

      return data
    } catch (e) {
      runInAction(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._error = e.stack || 'Error'
      })
    } finally {
      if (enableLoader) {
        runInAction(() => {
          this._loading = false
        })
      }
    }
  }

  sendTwoFaCode = async (params: IParamsSendTwoFaCode) => {
    const { data } = await AuthApi.sendTwoFaCode(params)
    return data
  }

  verifyTwoFaCode = async (params: IParamsVerifyTwoFaCode) => {
    const { data } = await AuthApi.verifyTwoFaCode(params)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  setExternalSource = (external_source: string) => {
    this.external_source = external_source
  }

  setCloseAfterLogin = (value: boolean) => {
    this.closeAfterLogin = value
  }

  setDesignV2API = () => {
    if (!this.hasOid) {
      updateDesignV2Api({
        is_enable_new_design: true,
        sign_up_v2: true,
      })
    } else {
      updateDesignV2Api({
        is_enable_new_design: userSettingsStore.isSalesmsgDesignV2,
      })
    }
  }

  get isAssume() {
    return this._isAssume
  }

  get error() {
    return this._error
  }
}

export const authStore = new AuthStore()
