import { useEffect, useState } from 'react'
import { type ISetIsKeywordsActionShown, KeywordsListStore } from 'pages/keywords/store'
import { KeywordsListContext } from 'pages/keywords/context/keywordsListContext'
import { KeywordsListContent } from 'pages/keywords/pages/all/ui/KeywordsListContent'

type IKeywordsListProps = {
  setIsKeywordsActionShown: ISetIsKeywordsActionShown
}

export const KeywordsList = ({ setIsKeywordsActionShown }: IKeywordsListProps) => {
  const [store] = useState(() => new KeywordsListStore(setIsKeywordsActionShown))

  useEffect(() => {
    return store.clearReactions
  }, [])

  return (
    <KeywordsListContext.Provider value={store}>
      <KeywordsListContent />
    </KeywordsListContext.Provider>
  )
}
