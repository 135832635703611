import React, { ReactNode } from 'react'
import { Switch, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type ICardProps = {
  title: ReactNode
  desc: ReactNode
  value: boolean
  setValue: (value: boolean) => void
}

export const Card = ({ title, desc, value, setValue }: ICardProps) => (
  <div className={styles.card}>
    <div className={styles.left}>
      <Typography ariaLabel={'title'} variant={'body-md-medium'}>
        {title}
      </Typography>
      <Typography ariaLabel={'desc'} variant={'body-md-regular'} color={'tertiary'}>
        {desc}
      </Typography>
    </div>
    <div className={styles.right}>
      <Switch value={value} onChange={setValue} />
    </div>
  </div>
)
