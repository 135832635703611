import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type LoginStore } from 'widgets/Login/store/loginStore'

type IProps = {
  store: LoginStore
}

export const TwoFaMethodModalActions = observer(({ store }: IProps) => {
  const { openCodeModal, twoFaMethod, loading } = store

  return (
    <Button
      text='Continue'
      disabled={!twoFaMethod || loading}
      loading={loading}
      onClick={() => openCodeModal(store)}
    />
  )
})
