import classnames from 'classnames'
import { ReactNode } from 'react'
import { CopyWrapper } from 'shared/ui'
import { IDeal, IIntegrationHubspotPropertyFormatted } from 'entities/Integrations'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { DealsRow } from './DealsRow'

import styles from '../styles.module.scss'
export type IDealsRowType = 'amount' | 'closeDate' | 'stage' | 'owner' | 'pipeline' | 'value'

type IDealsProps = {
  deals: IDeal[]
  deal?: IIntegrationHubspotPropertyFormatted[]
  rows?: IDealsRowType[]
  link: (id?: number | string | null) => string
  integrationLabel: string
  actions?: ReactNode
  handleClear?: (key: string, source_type: IUiSettingsSourceType) => void
}

const EmptyDeals = () => <div className={styles.emptyData}>No deals found</div>

const DealsTab = ({
  deals,
  link,
  rows,
  deal,
  integrationLabel,
  actions,
  handleClear,
}: IDealsProps) => {
  if (!deals.length) return <EmptyDeals />

  return (
    <div className={classnames(styles.contentWrapper)}>
      {deals.slice(0, 1).map((deal) => (
        <div key={deal?.id?.value}>
          <div className={styles.headerWrapper}>
            <CopyWrapper
              copyValue={deal.dealname?.value || null}
              data={{
                title: deal.dealname?.value || 'View deal',
                link: link(deal?.id?.value),
                label: integrationLabel,
              }}
            />
          </div>
          {rows && <DealsRow deal={deal} rows={rows} clearAction={handleClear} />}
        </div>
      ))}
      {!rows && <DealsRow dealFormatted={deal} clearAction={handleClear} />}
      {actions && actions}
    </div>
  )
}

export { DealsTab }
