import classNames from 'classnames'
import { Icon } from 'shared/ui/Icon/Icon'

import { EmptyCell } from 'shared/ui/Table'
import { IColumn, Tooltip } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

interface IDefaultHeaderCell<TRow> {
  column: IColumn<TRow>
  sortOrder?: 'asc' | 'desc'
  sortBy?: string
}

export const DefaultHeaderCell = <TRow,>({
  column,
  sortOrder,
  sortBy,
}: IDefaultHeaderCell<TRow>) => {
  if (!column.name) {
    return <EmptyCell />
  }

  const isSortActive =
    column.isSortable &&
    (column.sortKey || typeof column.field === 'number' || typeof column.field === 'string') &&
    (column.sortKey ? column.sortKey === sortBy : column.field === sortBy)

  return (
    <div className={styles.container}>
      <span
        className={classNames({ [styles.activeNameSpan]: isSortActive })}
        aria-label={getAriaLabel('TableHeadColumn', column.name)}
      >
        {column.name}
      </span>
      {column.tooltipQuestionProps && (
        <Tooltip {...column.tooltipQuestionProps}>
          <Icon icon={'question'} tertiary />
        </Tooltip>
      )}
      {isSortActive && (
        <Icon icon={sortOrder === 'asc' ? 'arrowUp' : 'arrowDown'} color='var(--gray-100)' />
      )}
    </div>
  )
}
