import { makeAutoObservable } from 'mobx'
import type { IResponseQuickLinkTemplate } from 'entities/QuickLink/api'
import { QuickLinkPayload } from './QuickLinkPayload'

export type IQuickLinkTemplate = QuickLinkTemplate

export class QuickLinkTemplate {
  constructor(private _origin: IResponseQuickLinkTemplate) {
    makeAutoObservable(this)
  }

  get id() {
    return this._origin.id
  }

  get url() {
    return this._origin.url
  }

  get label() {
    return this._origin.label
  }

  get payload() {
    return QuickLinkPayload.Create(this._origin.label, this._origin.url)
  }

  syncOrigin(template?: IResponseQuickLinkTemplate | null) {
    this._origin = template ?? this._origin
  }
}
