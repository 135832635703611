import { FC } from 'react'
import { Icon, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type ISampleMessageModalProps = {
  includesLinks?: boolean
  includesPhoneNumbers?: boolean
}

export const SampleMessageModal: FC<ISampleMessageModalProps> = ({
  includesLinks,
  includesPhoneNumbers,
}) => {
  return (
    <div className={styles.sampleMessageModalMustIncludeWrap}>
      <Typography
        ariaLabel='mustInclude'
        variant='body-md-regular'
        color='var(--content-primary-secondary)'
        className={styles.mustIncludeTitle}
        marginTop={'8px'}
      >
        Your sample message must include:
      </Typography>

      <Typography
        ariaLabel='businessName'
        variant='body-md-semibold'
        tag='div'
        className={styles.mustIncludeLine}
      >
        <Icon icon='check2' color='var(--green-60)' /> Your business name:
      </Typography>

      <Typography
        ariaLabel='example'
        variant='body-md-regular'
        color={'var(--content-primary-secondary)'}
        className={styles.mustIncludeExampleLine}
      >
        e.g. “Hey, this is Example.”
      </Typography>

      <Typography
        ariaLabel='businessName'
        variant='body-md-semibold'
        tag='div'
        className={styles.mustIncludeLine}
      >
        <Icon icon='check2' color='var(--green-60)' /> Call-to-action:
      </Typography>

      <Typography
        ariaLabel='example'
        variant='body-md-regular'
        color={'var(--content-primary-secondary)'}
        className={styles.mustIncludeExampleLine}
      >
        Why are you sending the text? What&apos;s the action to be taken?
      </Typography>

      {includesLinks && (
        <>
          <Typography
            ariaLabel='businessName'
            variant='body-md-semibold'
            tag='div'
            className={styles.mustIncludeLine}
          >
            <Icon icon='check2' color='var(--green-60)' /> Link
          </Typography>
          <Typography
            ariaLabel='example'
            variant='body-md-regular'
            className={styles.mustIncludeExampleLine}
          >
            e.g. “Visit https://www.example.com/...”
          </Typography>
        </>
      )}

      {includesPhoneNumbers && (
        <>
          <Typography
            ariaLabel='businessName'
            variant='body-md-semibold'
            tag='div'
            className={styles.mustIncludeLine}
          >
            <Icon icon='check2' color='var(--green-60)' /> Phone number
          </Typography>
          <Typography
            ariaLabel='example'
            variant='body-md-regular'
            className={styles.mustIncludeExampleLine}
          >
            e.g. “Call us at (561) 123-1234...”
          </Typography>
        </>
      )}

      <Typography
        ariaLabel='businessName'
        variant='body-md-semibold'
        tag='div'
        className={styles.mustIncludeLine}
      >
        <Icon icon='check2' color='var(--green-60)' /> Opt-out instructions:
      </Typography>

      <Typography
        ariaLabel='example'
        variant='body-md-regular'
        color={'var(--content-primary-secondary)'}
        marginBottom={0}
        className={styles.mustIncludeExampleLine}
      >
        “Reply STOP to end.”
      </Typography>
    </div>
  )
}
