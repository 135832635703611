import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IDeal,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { DealsTab } from '../../TabsView/DealsTab/DealsTab'

type IDealsProps = {
  deals: IDeal[]
  contact: Contact
}

const Deals = ({ deals, contact }: IDealsProps) => {
  const store = useContactsDetailsContext()

  const userIntegration = integrationsStore.userIntegrationsMap.get(IntegrationKey.pipedrive)

  const linkProps = {
    key: IntegrationKey.pipedrive,
    contact,
    integration: userIntegration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = () => integrationLink(linkProps)

  const handleView = () => {
    store.openView('deals', { deals }, 'pipedrive', IntegrationKey.pipedrive)
  }

  return (
    <DealsTab
      deals={deals}
      viewAll={handleView}
      link={link}
      rows={['value', 'pipeline', 'stage', 'owner']}
    />
  )
}

export { Deals }
