import { CalendarFilterEnum } from 'pages/keywords/pages/viewKeyword'

export enum IKeywordCreateFieldEnum {
  name = 'name',
  title = 'title',
  message = 'message',
  numbers = 'numbers',
  tags = 'tags',
  is_active = 'is_active',
}

export type IKeywordCreateField = `${IKeywordCreateFieldEnum}`

export enum EnumModeKeyword {
  create = 'create',
  edit = 'edit',
}

export type ICalendarFilter = `${CalendarFilterEnum}`
