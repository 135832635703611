import { useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useBroadcastsListContext } from 'widgets/BroacastList/context'
import { BroadcastsTable } from 'widgets/BroacastList/ui/BroadcastTable/BroadcastsTable'
import { FiltersAndSearch } from 'widgets/FiltersAndSearch'

export const BroadcastListContent = observer(() => {
  const { isHideFilters, filtersAndSearchStore, dispose } = useBroadcastsListContext()
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    return () => {
      dispose()
    }
  }, [])

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      {!isHideFilters && <FiltersAndSearch store={filtersAndSearchStore} parent={ref.current} />}
      <BroadcastsTable />
    </div>
  )
})
