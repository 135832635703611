import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponseKnowledgeBase } from 'entities/KnowledgeBase'

type IHandlerEvent = (data: IResponseKnowledgeBase) => void

export const EventKnowledgeBaseUpdated = {
  subscribe: (handler: IHandlerEvent) => websocket.on(WebsocketEvent.KnowledgeBaseUpdated, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.KnowledgeBaseUpdated, handler),
}

export const useEventKnowledgeBaseUpdated = (handler: IHandlerEvent) =>
  useEffect(() => {
    EventKnowledgeBaseUpdated.subscribe(handler)

    return () => {
      EventKnowledgeBaseUpdated.unsubscribe(handler)
    }
  }, [])
