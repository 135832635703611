import classnames from 'classnames'
import { FC, ReactNode } from 'react'
import { Checkbox, Icon, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IPackageCardProps = {
  checked: boolean
  title: string
  titleSub?: string
  titleLabel?: string
  infoOne?: {
    title: string
    tooltip: ReactNode
  }
  infoTwo?: {
    title: string
    tooltip: ReactNode
  }
  price: string
  disabled?: boolean
  onClick: () => void
}

export const PackageCard: FC<IPackageCardProps> = ({
  checked,
  title,
  titleSub,
  titleLabel,
  infoOne,
  infoTwo,
  price,
  disabled,
  onClick,
}) => {
  return (
    <div
      className={classnames(styles.wrap, {
        [styles['wrap--active']]: checked,
        [styles['wrap--disabled']]: disabled,
      })}
      onClick={() => !disabled && onClick()}
    >
      <Checkbox checked={checked} disabled={disabled} />

      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.titleWrap}>
            <div className={styles.titleWrap__top}>
              <Typography ariaLabel='packagePriceItem' variant='body-lg-semibold' tag='div'>
                {title}
              </Typography>
              {titleLabel && <div className={styles.titleLabel}>Recommended</div>}
            </div>

            {titleSub && (
              <Typography
                className={styles.titleSub}
                ariaLabel='packagePriceItem'
                variant='body-md-regular'
                color='var(--green-90)'
                tag='div'
                marginBottom={16}
              >
                {titleSub}
              </Typography>
            )}
          </div>

          <div className={styles.info}>
            {infoOne && (
              <div className={styles.info__item}>
                <Typography
                  ariaLabel='packagePriceItem'
                  variant='body-md-regular'
                  color='var(--content-primary-primary)'
                >
                  {infoOne.title}
                </Typography>
                <Tooltip label={infoOne.tooltip} placement='top'>
                  <Icon icon={'question'} color='var(--content-neutral-primary)' fontSize={14} />
                </Tooltip>
              </div>
            )}
            {infoTwo && (
              <div className={styles.info__item}>
                <Typography
                  ariaLabel='packagePriceItem'
                  variant='body-md-regular'
                  color='var(--content-primary-primary)'
                >
                  {infoTwo.title}
                </Typography>
                <Tooltip label={infoTwo.tooltip} placement='top'>
                  <Icon icon={'question'} color='var(--content-neutral-primary)' fontSize={14} />
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <Typography
          tag={'div'}
          ariaLabel='packagePriceItem'
          variant='body-lg-regular'
          className={styles.price}
        >
          {price}
        </Typography>
      </div>
    </div>
  )
}
