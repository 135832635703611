import { useState } from 'react'
import { ITabItem, Tabs } from 'shared/ui'
import type { IOptInMethodTypes, IResponseOptInData } from 'entities/Compliance'
import { ImageCard } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { ModalInfoBlock } from 'pages/settings/pages/compliance/ui/ModalInfoBlock/ModalInfoBlock'
import { IModalInfoRowProps } from 'pages/settings/pages/compliance/ui/ModalInfoRow/ModalInfoRow'
import styles from './styles.module.scss'

type IUseCaseModalContentProps = {
  optInData: IResponseOptInData[]
  getOptInDataByMethod: (method: IOptInMethodTypes) => IResponseOptInData | undefined
  openImageModal: (urls: string, name: string) => void
}

const tabsMap = {
  ONLINE_FORM: 'Online Form',
  PAPER_FORM: 'Paper Form',
  TEXT: 'Text',
  QR_CODE: 'QR Code',
}

export const UseCaseModalTabs = ({
  optInData,
  getOptInDataByMethod,
  openImageModal,
}: IUseCaseModalContentProps) => {
  const [activeTab, setActiveTab] = useState(optInData?.[0].optInMethod || '')
  const activeOptInData = getOptInDataByMethod(activeTab as IOptInMethodTypes)

  if (!optInData?.length || !activeOptInData) return null

  const tabs: ITabItem[] =
    optInData.map((item) => ({
      key: item.optInMethod,
      name: tabsMap[item.optInMethod],
    })) || []

  const lines: IModalInfoRowProps[] = [
    {
      label: 'Opt-in image',
      value: (
        <div className={styles.images}>
          {activeOptInData?.optInImages.map((item) => (
            <ImageCard
              key={item.uid}
              attachment={
                new Attachment({
                  imageUrl: item.sourceUrl,
                })
              }
              onCardClick={() => openImageModal(item.sourceUrl, item.name)}
            />
          ))}
        </div>
      ),
    },
  ]

  if (activeOptInData?.onlineFormUrl) {
    lines.push({
      label: 'Online Form URL',
      value: activeOptInData?.onlineFormUrl,
    })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        margin={'0 0 12px 0'}
        activeTabKey={activeTab}
        handleSelectTab={(tab) => setActiveTab(tab.key as IOptInMethodTypes)}
      />

      <ModalInfoBlock lines={lines} />
    </>
  )
}
