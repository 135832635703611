import { IAvatarInfo } from 'shared/ui'
import { Base } from 'models'
import { IDuplicateReason, IHistoryResponse, ISkippedReason } from 'entities/Contacts/api/types'

export class History extends Base {
  id: number
  contacts: number
  skipped: number
  skippedCount: number
  skippedReasons: ISkippedReason[]
  createdAt: string
  startedAt: string | null
  current: number
  finishedAt: string | null
  added: number
  duplicateCount: number
  duplicateReasons: IDuplicateReason[]
  user: {
    firstName: string
    lastName: string
    fullName: string
    image: string
  }

  constructor(item: IHistoryResponse) {
    super()

    this.id = item.id
    this.contacts = item.contacts
    this.skipped = item.skipped
    this.skippedCount = item.skipped_count || 0
    this.skippedReasons = Array.isArray(item.skipped_reasons)
      ? item.skipped_reasons
      : Object.values(item.skipped_reasons)
    this.createdAt = item.created_at
    this.current = item.current
    this.finishedAt = item.finished_at
    this.startedAt = item.started_at
    this.added = item.added
    this.duplicateCount = item.duplicate_count || 0
    this.duplicateReasons = Array.isArray(item.duplicate_reasons)
      ? item.duplicate_reasons
      : Object.values(item.duplicate_reasons)
    this.user = {
      firstName: item.user?.first_name || '',
      lastName: item.user?.last_name || '',
      fullName: item.user?.full_name || '',
      image: item.user?.photo_url || '',
    }
  }

  get avatarInfo(): IAvatarInfo {
    return {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      image: this.user.image,
    }
  }
}
