// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vAY0YnjZiPt0Cc_pAlrO{margin-bottom:14px}.HEdCnmhiAhdZrbxwqK0O{display:flex;align-items:center;justify-content:space-between;padding:0 9px 0 6px}.YZK1vH4xeVBJP55JH2jQ{height:390px;padding:24px 5px 30px;margin-right:-22px}.g2chUqSLgr73qhJki2E4 [aria-label=Scrollbar_View]{padding-right:20px}.tdZFKUET2qYQsvRHbtID{padding:0 5px}.tdZFKUET2qYQsvRHbtID [aria-label=Tooltip]{width:100%}.tdZFKUET2qYQsvRHbtID [aria-label=Tooltip_Content]{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/PowerDialer/ui/PowerDialerContactsModal/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,mBAAA,CAMF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAGE,kDACE,kBAAA,CAKN,sBACE,aAAA,CACA,2CACE,UAAA,CAGF,mDACE,UAAA","sourcesContent":[".wrap {\n}\n\n.alert {\n  margin-bottom: 14px;\n}\n\n.section {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 9px 0 6px;\n}\n\n.box {\n}\n\n.table {\n  height: 390px;\n  padding: 24px 5px 30px;\n  margin-right: -22px;\n\n  &__scroll {\n    [aria-label='Scrollbar_View'] {\n      padding-right: 20px;\n    }\n  }\n}\n\n.wrapBtn {\n  padding: 0 5px;\n  & [aria-label='Tooltip'] {\n    width: 100%;\n  }\n\n  & [aria-label='Tooltip_Content'] {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "vAY0YnjZiPt0Cc_pAlrO",
	"section": "HEdCnmhiAhdZrbxwqK0O",
	"table": "YZK1vH4xeVBJP55JH2jQ",
	"table__scroll": "g2chUqSLgr73qhJki2E4",
	"wrapBtn": "tdZFKUET2qYQsvRHbtID"
};
export default ___CSS_LOADER_EXPORT___;
