import { useEffect, useMemo } from 'react'
import { CampaignsList, CampaignsListStore } from 'pages/campaigns/ui/CampaignsList'
import { useCampaignsPageContext } from 'pages/campaigns/context/context'

export const AllCampaigns = () => {
  const store = useMemo(() => new CampaignsListStore(), [])
  const { pageLayoutStore } = useCampaignsPageContext()
  useEffect(() => {
    return store.dispose
  }, [])
  return <CampaignsList store={store} pageLayoutStore={pageLayoutStore} />
}
