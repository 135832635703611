import { observer } from 'mobx-react-lite'
import { EmptyState } from 'shared/ui'
import keywordsPreviewImg from 'shared/assets/images/keywords/keywords_preview.png'
import { getCDNAssetURL } from 'shared/lib'
import { KeywordNewButton, KeywordsHelpGuideButton } from '../KeywordsButtons/KeywordButtons'

export const EmptyStateKeywords = observer(() => {
  return (
    <EmptyState
      variant={'preview'}
      previewImg={<img alt='Keywords preview' src={getCDNAssetURL(keywordsPreviewImg)} />}
      title={'Create your first keyword'}
      subtitle={'Use keywords to capture leads'}
      actions={
        <>
          <KeywordNewButton />
          <KeywordsHelpGuideButton />
        </>
      }
    />
  )
})
