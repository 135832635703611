import { observer } from 'mobx-react-lite'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Button } from 'shared/ui'
import { CampaignMessage } from 'pages/campaigns/ui/CampaignMessage'
import { useCampaignMessagesContext } from '../context/context'
import styles from './styles.module.scss'

export const CampaignMessagesUi = observer(() => {
  const { messages, limitAddMessage, addMessage, reorderDraggableItems } =
    useCampaignMessagesContext()

  const handleReorder = (result: DropResult) => {
    if (result.destination) reorderDraggableItems(result.source.index, result.destination.index)
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleReorder}>
        <Droppable droppableId='contacts-details'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {messages.map((message, index) => (
                <Draggable key={message.uid} draggableId={message.uid} index={index}>
                  {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
                    return (
                      <div
                        {...draggableProps}
                        {...dragHandleProps}
                        ref={innerRef}
                        key={message.uid}
                      >
                        <CampaignMessage
                          store={message}
                          index={index}
                          isHideDelete={index === 0 && messages.length === 1}
                          isDragging={isDragging}
                          limitAddMessage={limitAddMessage}
                        />
                      </div>
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={styles.wrapAddBtn}>
        <Button
          typeBtn={'text'}
          icon={'add'}
          text={'Add message'}
          onClick={addMessage}
          size={'medium'}
          disabled={!!limitAddMessage}
          tooltipProps={{
            label: limitAddMessage,
            placement: 'top',
          }}
        />
      </div>
    </div>
  )
})
