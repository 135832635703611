import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  ContainerHeader,
  Dropdown,
  DropdownContent,
  DropdownItem,
  Icon,
  Switch,
  Typography,
  CircularProgress,
} from 'shared/ui'
import { ChatbotStatus } from 'entities/Chatbot'
import { ChatbotRoutes, ChatbotAnalyticDetailStore } from 'pages/chatbot'
import { useChatbotDetailsContext } from 'pages/chatbot/context'
import { ChatbotStatusBadge } from 'pages/chatbot/ui/ChatbotStatus'
import { ChatbotInvalidAlert } from 'pages/chatbot/ui'
import { useChatbotLayoutContext } from 'pages/chatbot/context/chatbotLayoutContext'
import styles from './styles.module.scss'

export interface IChatbotAnalyticHeaderProps {
  details: ChatbotAnalyticDetailStore
}

export const ChatbotAnalyticHeader = observer(({ details }: IChatbotAnalyticHeaderProps) => {
  const chatbotLayoutStore = useChatbotLayoutContext()
  const { titleIconProps, handleToggleCollapse } = chatbotLayoutStore.pageLayoutStore
  const { showDetails, showCurrentDetails } = useChatbotDetailsContext()
  const [deleting, setDeleting] = useState(false)
  const navigate = useNavigate()

  const handleNavigate = () => navigate(`/${ChatbotRoutes.root}/${details.id}`)

  const handleShowDetails = () =>
    details.data ? showCurrentDetails(details.data) : showDetails(details.id)

  const handleDelete = async () => {
    setDeleting(true)

    try {
      const deleted = await details.delete()
      if (deleted) navigate(`/${ChatbotRoutes.root}/${ChatbotRoutes.all}`)
      else setDeleting(false)
    } catch (_) {
      setDeleting(false)
    }
  }

  return (
    <header className={styles.header}>
      <div className={styles.headerRow}>
        <Link to={`/${ChatbotRoutes.root}/${ChatbotRoutes.all}`} className={styles.headerLink}>
          <Icon icon={'chevronLeft'} />
          <Typography variant={'body-sm-regular'} pointer ariaLabel={'Back'}>
            Back to All Textbots
          </Typography>
        </Link>
      </div>
      <div className={styles.headerTitle}>
        <ContainerHeader
          title={details.name}
          titleVariant={'heading-md'}
          titleIconProps={titleIconProps}
          titleIconAction={handleToggleCollapse}
        />
        <div className={styles.headerActions}>
          {details.updating ? (
            <CircularProgress size={16} />
          ) : (
            <Switch
              value={details.active}
              disabled={details.status === ChatbotStatus.Invalid}
              onChange={(value) => details.updateStatus(value)}
            />
          )}
          <div className={styles.headerActionsGroup}>
            <Button
              text='Edit'
              disabled={details.updating || details.active}
              contained='secondary'
              size='medium'
              icon='edit'
              onClick={handleNavigate}
            />
            <Button
              text='Details'
              contained='secondary'
              size='medium'
              icon='eye'
              onClick={handleShowDetails}
              disabled={details.initialLoading || details.updating}
            />
            <Dropdown
              width={112}
              placement='bottom-end'
              icon={'moreHorizontal'}
              tooltipProps={{
                label: 'Show actions',
                placement: 'top-end',
              }}
              iconButtonProps={{
                fontSize: 20,
                variant: 'contained',
                size: 'medium',
                ariaLabel: 'Actions_showActions',
              }}
              customComponent={(close) => (
                <DropdownContent>
                  <DropdownItem
                    item={{
                      id: 1,
                      iconL: 'delete',
                      label: deleting ? 'Deleting' : 'Delete',
                      disabled: deleting,
                      onClick: () => {
                        close()
                        handleDelete()
                      },
                    }}
                  />
                </DropdownContent>
              )}
            />
          </div>
        </div>
      </div>
      <div className={styles.headerRow}>
        <ChatbotStatusBadge status={details.status} />
      </div>
      <ChatbotInvalidAlert data={details} className={styles.invalidAlertContainer} />
    </header>
  )
})
