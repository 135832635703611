import { makeAutoObservable } from 'mobx'
import { isIconKey } from 'shared/ui/Icon'
import {
  IntegrationKey,
  type IIntegrationKey,
  type IResponseIntegration,
  type IResponseUserIntegration,
} from 'entities/Integrations/api/types'
import { UserIntegration } from 'entities/Integrations/model/UserIntegration'

export class Integration {
  id: number
  key: IIntegrationKey
  name: string
  is_active: boolean
  connected: UserIntegration | null
  disconnected: UserIntegration | null
  oauth: boolean
  canModifySettings: boolean

  constructor(item: IResponseIntegration) {
    this.id = item.id
    this.key = item.key
    this.name = item.name
    this.is_active = item.is_active
    this.oauth = item.oauth
    this.canModifySettings = item.can_modify
    this.connected = item.connected ? new UserIntegration(item.connected) : null
    this.disconnected = item.disconnected ? new UserIntegration(item.disconnected) : null

    makeAutoObservable(this)
  }

  get icon() {
    return isIconKey(this.key) ? this.key : 'salesmsg'
  }

  get isConnected() {
    return Boolean(this.connected?.is_active && this.connected.connection_error === 0)
  }

  get shouldReconnect() {
    return Boolean(this.connected?.is_active && this.connected.connection_error !== 0)
  }

  get url() {
    return this.connected?.url
  }

  get user_credential() {
    return this.connected?.user_credential
  }

  get isConnectedSettingsAvailable() {
    return Boolean(this.isConnected && this.canModifySettings)
  }

  get isSettingsAvailable() {
    return this.isConnectedSettingsAvailable || this.key === IntegrationKey.salesforce
  }

  syncOrigin = (origin: IResponseIntegration) => {
    this.id = origin.id
    this.key = origin.key
    this.name = origin.name
    this.is_active = origin.is_active
    this.oauth = origin.oauth
    this.canModifySettings = origin.can_modify
    this.connected = origin.connected ? new UserIntegration(origin.connected) : null
    this.disconnected = origin.disconnected ? new UserIntegration(origin.disconnected) : null
  }

  syncUserIntegration = (userIntegration: IResponseUserIntegration) => {
    if (userIntegration.is_active) {
      this.disconnected = null
      this.connected = new UserIntegration(userIntegration)
    } else {
      this.connected = null
      this.disconnected = new UserIntegration(userIntegration)
    }
  }
}
