import type { IStatusProps } from 'shared/ui/Status'
import { KnowledgeBaseDocumentStatus } from 'entities/KnowledgeBase'

const INDEXATION_CONFIG: IStatusProps = {
  title: 'Processing',
  icon: 'refreshCircle',
  emphasis: 'high',
  intent: 'informative',
}

const INDEXATION_FAILED_CONFIG: IStatusProps = {
  title: 'Failed',
  icon: 'cancelFilled',
  emphasis: 'high',
  intent: 'negative',
}

const INDEXATION_SUCCESS_CONFIG: IStatusProps = {
  title: 'Ready',
  icon: 'check2',
  emphasis: 'high',
  intent: 'positive',
}

export const getKnowledgeBaseDocumentStatusConfig = (status: KnowledgeBaseDocumentStatus) => {
  switch (status) {
    case KnowledgeBaseDocumentStatus.Indexation:
      return INDEXATION_CONFIG
    case KnowledgeBaseDocumentStatus.IndexationSuccess:
      return INDEXATION_SUCCESS_CONFIG
    case KnowledgeBaseDocumentStatus.IndexationFailed:
      return INDEXATION_FAILED_CONFIG
    default:
      return null
  }
}
