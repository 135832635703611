import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { AuthLayout } from 'shared/layout'
import { SpinnerLoader } from 'shared/ui'
import { RegisterMultiorgStore } from 'widgets/RegisterMultiorg/store/registerMultiorgStore'
import { RegisterMultiorgForm } from 'widgets/RegisterMultiorg/ui/RegisterMultiorgForm/RegisterMultiorgForm'
import { RegisterMultiorgFormBottom } from './RegisterMultiorgFormBottom/RegisterMultiorgFormBottom'
import { EventTokenCreated } from '../events/EventTokenCreated'
import { EventRegisterOrganizationError } from '../events/EventRegisterOrganizationError'

import styles from './styles.module.scss'

export const RegisterMultiorgContent = observer(({ store }: { store: RegisterMultiorgStore }) => {
  useEffect(() => {
    EventTokenCreated.subscribe(store)
    EventRegisterOrganizationError.subscribe(store)

    return () => {
      EventTokenCreated.unsubscribe()
      EventRegisterOrganizationError.unsubscribe()
    }
  }, [])

  if (store.preloading) {
    return (
      <div className={styles.wrap}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <AuthLayout
        title={'Start your 14-day trial'}
        subtitle={<div className={styles.subtitle}>Get started in just 30 seconds</div>}
        formBottom={<RegisterMultiorgFormBottom />}
        form={<RegisterMultiorgForm store={store} />}
      />
    </div>
  )
})

export const RegisterMultiorg = () => {
  const store = new RegisterMultiorgStore()

  return <RegisterMultiorgContent store={store} />
}

export default RegisterMultiorg
