import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IResponseUsersMe,
  IResponseUpdateUsersTeam,
  IBodyUpdateUsersTeam,
  IParamsUsersSearch,
  IResponseUsersSearch,
  IResponseAwayStatus,
  IBodyUpdateAwayStatus,
  IParamsUsersToggleRecordAutomatically,
  IParamsUpdateMessageSignature,
  IResponseMessageSignatureAnswer,
  IResponseBusinessHours,
  IResponseUser,
  IResponseOwnerUser,
  IResponseUiSettings,
  IUiSettingsSourceType,
  IParamsUpdateUsersUiSettings,
  IResponseRequestInboxAccess,
  IResponseProfile,
  IUsersProfileUiSettingsSourceType,
  IResponseUsersProfilesUiSettings,
  IParamsUpdateUsersProfilesUiSettings,
  IResponseUiSettingsHubspotAllEntities,
} from 'entities/Users/api/types'

class Api {
  getUsersMe(): Promise<AxiosResponse<IResponseUsersMe>> {
    return baseApi.get('users/me')
  }

  getUser(): Promise<AxiosResponse<IResponseOwnerUser>> {
    return baseApi.get<IResponseOwnerUser>('user').then((response) => {
      response.data.item_type = 'owner'

      return response
    })
  }

  getUserById(id: number): Promise<AxiosResponse<IResponseUser>> {
    return baseApi.get(`user/${id}`)
  }

  getUsers(): Promise<AxiosResponse<IResponseUser[]>> {
    return baseApi.get('users')
  }

  toggleAwayStatus(body: IBodyUpdateAwayStatus): Promise<AxiosResponse<IResponseAwayStatus>> {
    return baseApi.post('users/away-status', body)
  }

  updateUsersTeam(body: IBodyUpdateUsersTeam): Promise<AxiosResponse<IResponseUpdateUsersTeam>> {
    return baseApi.put('users/team', body)
  }

  getUsersSearch(
    params: IParamsUsersSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseUsersSearch>> {
    return baseApi.get('users/search', { ...options, params })
  }

  getUsersBusinessHours(): Promise<AxiosResponse<IResponseBusinessHours>> {
    return baseApi.get('users/business-hours')
  }

  updateUsersToggleRecordAutomatically(params: IParamsUsersToggleRecordAutomatically) {
    return baseApi.post('users/toggle-record-automatically', params)
  }

  getMessageSignature(): Promise<AxiosResponse<IResponseMessageSignatureAnswer>> {
    return baseApi.get('users/message-signature')
  }

  updateMessageSignature(
    params: IParamsUpdateMessageSignature
  ): Promise<AxiosResponse<IResponseMessageSignatureAnswer>> {
    return baseApi.post('users/message-signature', params)
  }

  getUsersProfilesUiSettings(
    source_type: IUsersProfileUiSettingsSourceType
  ): Promise<AxiosResponse<IResponseUsersProfilesUiSettings>> {
    return baseApi.get(`users/profiles/ui-settings/${source_type}`)
  }

  updateUsersProfilesUiSettings(
    params: IParamsUpdateUsersProfilesUiSettings
  ): Promise<AxiosResponse<IResponseUsersProfilesUiSettings>> {
    return baseApi.post('users/profiles/ui-settings/sync', params)
  }

  getUsersUiSettings(
    source_type: IUiSettingsSourceType
  ): Promise<AxiosResponse<IResponseUiSettings>> {
    return baseApi.get(`users/ui-settings/${source_type}`)
  }

  getUsersUiSettingsHubspotAllEntities(): Promise<
    AxiosResponse<IResponseUiSettingsHubspotAllEntities>
  > {
    return baseApi.get('users/ui-settings/hubspot-all-entities-draggable-items')
  }

  updateUsersUiSettings(
    params: IParamsUpdateUsersUiSettings
  ): Promise<AxiosResponse<IResponseUiSettings>> {
    return baseApi.post('users/ui-settings/sync', params)
  }

  getRequestInboxAccess(): Promise<AxiosResponse<IResponseRequestInboxAccess>> {
    return baseApi.get('users/assign-inbox-request')
  }

  updateRequestInboxAccess(): Promise<AxiosResponse<IResponseRequestInboxAccess>> {
    return baseApi.post('users/assign-inbox-request')
  }

  getUsersProfile(): Promise<AxiosResponse<IResponseProfile>> {
    return baseApi.get('users/profile')
  }
}

export const UsersApi = new Api()
