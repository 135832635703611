import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IOpportunity,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { OpportunitiesTab } from '../../TabsView/OpportunitiesTab/OpportunitiesTab'

type IOpportunitiesProps = {
  opportunities: IOpportunity[]
  contact: Contact
}

const Opportunities = ({ opportunities, contact }: IOpportunitiesProps) => {
  const store = useContactsDetailsContext()

  const integration = integrationsStore.getIntegration(IntegrationKey.infusionsoft)

  const linkProps = {
    key: IntegrationKey.infusionsoft,
    contact,
    integration,
    type: IIntegrationLinkTypes.opportunities,
  }

  const link = () => integrationLink(linkProps)

  const handleView = () => {
    store.openView('opportunities', { opportunities }, 'infusionsoft', IntegrationKey.infusionsoft)
  }

  return (
    <OpportunitiesTab
      opportunities={opportunities}
      viewAll={handleView}
      link={link}
      rows={['stage', 'owner']}
      integrationLabel={'Infusionsoft'}
    />
  )
}

export { Opportunities }
