import { Base } from 'models'
import type { IResponseUserCredential, IResponseUserIntegration } from 'entities/Integrations'

export class UserIntegration extends Base {
  id: number
  integration_id!: number
  integration_key!: string
  integration_name!: string
  connection_error!: number
  url!: string
  user_credential?: IResponseUserCredential
  origin!: IResponseUserIntegration

  constructor(item: IResponseUserIntegration) {
    super()

    this.id = item.id
    this.syncOrigin(item)
  }

  get key() {
    return this.integration_key
  }

  get name() {
    return this.integration_name
  }

  syncOrigin = (origin: IResponseUserIntegration) => {
    this.id = origin.id
    this.url = origin.url
    this.integration_id = origin.integration.id
    this.integration_key = origin.integration.key
    this.integration_name = origin.integration.name
    this.connection_error = origin.connection_error
    this.user_credential = origin.user_credential
    this.origin = origin
  }
}
