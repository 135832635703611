// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E7QtEXVcE3bkEMHr_0JG{font-size:13px;font-weight:500;line-height:16px;margin-right:28px;flex:0 1 auto;min-width:74px;max-width:110px}.DJx3AWQb9XWbey2Mm2r8{display:flex;gap:6px;flex:1}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/SelectedContent/HeaderLeftContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,MAAA","sourcesContent":[".container {\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 16px;\n  margin-right: 28px;\n  flex: 0 1 auto;\n  min-width: 74px;\n  max-width: 110px;\n}\n\n.actionsWrap {\n  display: flex;\n  gap: 6px;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "E7QtEXVcE3bkEMHr_0JG",
	"actionsWrap": "DJx3AWQb9XWbey2Mm2r8"
};
export default ___CSS_LOADER_EXPORT___;
