import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { Box, Button, SpinnerLoader, Typography } from 'shared/ui'
import { usersStore } from 'entities/Users'
import {
  CompliancePageProvider,
  useComplianceLayerContext,
  useCompliancePageContext,
} from 'pages/settings/pages/compliance/context'
import styles from 'pages/settings/pages/compliance/ui/styles.module.scss'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { Menu } from 'pages/settings/pages/compliance/ui/Menu/Menu'

const CompliancePageLayoutContent = observer(() => {
  const { complianceLayerStore } = useCompliancePageContext()
  const { loading } = complianceLayerStore

  if (loading) return <SpinnerLoader />

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Typography
          ariaLabel='ComplianceMainPageContext_title'
          variant='heading-lg'
          marginBottom={'3px'}
        >
          Compliance
        </Typography>
        <Typography
          ariaLabel='ComplianceMainPageContext_desc'
          variant='body-md-regular'
          color='var(--content-primary-tertiary)'
        >
          This is based on the number your business needs.
          {usersStore.user?.isRequireNumber && (
            <Button
              text='Edit'
              typeBtn='link'
              contained='secondary'
              paddingInline={0}
              className={styles.editBtn}
              onClick={() => {
                uiStore.changeRoute({
                  path: CompliancePath.compliance.questionnaire.root,
                })
              }}
            />
          )}
        </Typography>

        <Box display={'flex'} marginTop={'49px'} gap={'40px'} alignItems={'flex-start'}>
          <Menu />
          <Box display={'flex'} flexDirection={'column'} width={'500px'}>
            <Outlet />
          </Box>
        </Box>
      </div>
    </div>
  )
})

export const CompliancePageLayout = () => {
  const complianceLayerStore = useComplianceLayerContext()

  return (
    <CompliancePageProvider complianceLayerStore={complianceLayerStore}>
      <CompliancePageLayoutContent />
    </CompliancePageProvider>
  )
}
