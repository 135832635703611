// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qhBsFpSvdhekftY1Bp2w{width:100%;height:1px;background-color:var(--border-neutral-default)}.my5QLzzzMh0XegsNVibd{margin:4px 0 4px -4px;width:calc(100% + 8px)}.KI_OfQKfkxfQnJEythCs{display:flex;height:auto;white-space:nowrap;text-align:center;background-color:unset;gap:10px}.KI_OfQKfkxfQnJEythCs::before,.KI_OfQKfkxfQnJEythCs::after{width:100%;border-top:thin solid var(--gray-a-25);content:\"\";align-self:center}", "",{"version":3,"sources":["webpack://./src/shared/ui/DividerCustom/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,UAAA,CACA,8CAAA,CAEF,sBACE,qBAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CACA,iBAAA,CACA,sBAAA,CACA,QAAA,CAEA,2DAEE,UAAA,CACA,sCAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":[".divider {\n  width: 100%;\n  height: 1px;\n  background-color: var(--border-neutral-default);\n}\n.dropdown {\n  margin: 4px 0 4px -4px;\n  width: calc(100% + 8px);\n}\n\n.text {\n  display: flex;\n  height: auto;\n  white-space: nowrap;\n  text-align: center;\n  background-color: unset;\n  gap: 10px;\n\n  &::before,\n  &::after {\n    width: 100%;\n    border-top: thin solid var(--gray-a-25);\n    content: '';\n    align-self: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "qhBsFpSvdhekftY1Bp2w",
	"dropdown": "my5QLzzzMh0XegsNVibd",
	"text": "KI_OfQKfkxfQnJEythCs"
};
export default ___CSS_LOADER_EXPORT___;
