import { observer } from 'mobx-react-lite'
import { ChoiceTile } from 'shared/ui'
import { type ICompanyLocation } from 'entities/Compliance/api/complianceType'
import { ErrorBanner } from 'pages/settings/pages/compliance/pages/businessProfile/ui/ErrorBanner/ErrorBanner'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import styles from './styles.module.scss'

export const CompanyLocation = observer(() => {
  const { companyLocationStore, stepLoading } = useBusinessProfileStepsContext()
  const { companyLocation, isNextDisabled, setCompanyLocation, nextStepClick } =
    companyLocationStore

  return (
    <div className={styles.wrap}>
      <PageDescription>
        Select the country where your company is registered so we can customize the registration
        process for you
      </PageDescription>

      <ErrorBanner />

      <div className={styles.content}>
        <ChoiceTile
          iconProps={{ icon: 'flagUSA', fontSize: 22 }}
          title='United States'
          value='US'
          checked={companyLocation === 'US'}
          setChoiceTile={(value) => setCompanyLocation(value as ICompanyLocation)}
        />
        <ChoiceTile
          iconProps={{ icon: 'flagCanada', fontSize: 22 }}
          title='Canada'
          value='CA'
          checked={companyLocation === 'CA'}
          setChoiceTile={(value) => setCompanyLocation(value as ICompanyLocation)}
        />
      </div>

      <NextButton
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        text='Next'
        onClick={nextStepClick}
      />
    </div>
  )
})
