import React from 'react'
import { Button } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IDeal,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/styles.module.scss'
import { DealsTab } from '../../TabsView/DealsTab/DealsTab'

type IDealsProps = {
  deals: IDeal[]
  contact: Contact
}

const Deals = ({ deals, contact }: IDealsProps) => {
  const store = useContactsDetailsContext()

  const integration = integrationsStore.getIntegration(IntegrationKey.activecampaign)

  const linkProps = {
    key: IntegrationKey.activecampaign,
    contact,
    integration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = () => integrationLink(linkProps)

  const handleView = () => {
    store.openView('deals', { deals }, 'activecampaign', IntegrationKey.activecampaign)
  }

  const actions = () => {
    const hasMore = deals.length > 1

    return (
      <div className={styles.actions}>
        <div className={styles.actionsRow}>
          {hasMore && (
            <div className={styles.action}>
              <Button size='small' typeBtn='text' text='See all deals' onClick={handleView} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <DealsTab
      deals={deals}
      link={link}
      integrationLabel={'ActiveCampaign'}
      rows={['value', 'owner']}
      actions={actions()}
    />
  )
}

export { Deals }
