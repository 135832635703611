import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { IntegrationListStore } from 'pages/settings/pages/integrations/store/integrationListStore'
import { useEventIntegrationDisconnectedSettingsList } from 'pages/settings/pages/integrations/events/EventIntegrationDisconnectedSettings'
import { useEventIntegrationConnectedSettingsList } from 'pages/settings/pages/integrations/events/EventIntegrationConnectedSettings'

const IntegrationListContext = createContext<IntegrationListStore | null>(null)

export const useIntegrationListContext = () => {
  const context = useContext(IntegrationListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with IntegrationListProvider')

  return context
}

export const IntegrationListProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new IntegrationListStore(), [])

  useEventIntegrationConnectedSettingsList(store)
  useEventIntegrationDisconnectedSettingsList(store)

  useEffect(() => store.dispose, [])

  return <IntegrationListContext.Provider value={store}>{children}</IntegrationListContext.Provider>
}
