// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E8KduAR8Pq0CZ_pKwqyH{display:flex;align-items:center;margin-top:12px}.AEVRZROWCQS63vESWSTw{margin:0 4px 0 12px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/EstimatedResponseInfo/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,mBAAA","sourcesContent":[".statusLine {\n  display: flex;\n  align-items: center;\n  margin-top: 12px;\n}\n\n.statusText {\n  margin: 0 4px 0 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusLine": "E8KduAR8Pq0CZ_pKwqyH",
	"statusText": "AEVRZROWCQS63vESWSTw"
};
export default ___CSS_LOADER_EXPORT___;
