import { makeAutoObservable } from 'mobx'
import { Integration } from 'entities/Integrations'
import { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationCustomSettingsStore'
import { IntegrationHubspotSettingsChannelsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsChannelsStore'
import { IntegrationHubspotSettingsPropertyStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsPropertyStore'
import { IntegrationHubspotSettingsSyncStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsSyncStore'
import { IntegrationHubspotSettingsTimelineStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsTimelineStore'
import { IntegrationHubspotSettingsUsersStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsUsersStore'
import { IntegrationWebhookStore } from 'pages/settings/pages/integrations/store/IntegrationWebhookStore'
import { IntegrationHubspotSettingsListsStore } from './IntegrationHubspotSettingsListsStore'

type SettingSectionUnion =
  | 'sync'
  | 'webhook'
  | 'users'
  | 'properly'
  | 'timeline'
  | 'inbox'
  | 'lists'

export class IntegrationHubspotSettingsStore {
  private _syncStore
  private _webhookStore
  private _userStore
  private _channelsStore
  private _timelineStore
  private _propertyStore
  private _customSettingsStore
  private _listsStore
  private _openedSection: Null<SettingSectionUnion> = null

  constructor(integration: Integration) {
    this._webhookStore = new IntegrationWebhookStore()
    this._customSettingsStore = new IntegrationCustomSettingsStore(integration)
    this._channelsStore = new IntegrationHubspotSettingsChannelsStore(integration)
    this._userStore = new IntegrationHubspotSettingsUsersStore(integration)
    this._syncStore = new IntegrationHubspotSettingsSyncStore(this._customSettingsStore)
    this._propertyStore = new IntegrationHubspotSettingsPropertyStore(
      integration,
      this._customSettingsStore
    )
    this._timelineStore = new IntegrationHubspotSettingsTimelineStore(this._customSettingsStore)
    this._listsStore = new IntegrationHubspotSettingsListsStore()

    makeAutoObservable(this)
    this.init(integration)
  }

  init = (integration: Integration) => {
    this._webhookStore.initIntegrationWebhookState(integration)

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    this._openedSection = (params.get('opened') as SettingSectionUnion) || null
  }

  onCollapseClick = (key: SettingSectionUnion) => () => {
    this._openedSection = this._openedSection === key ? null : key
  }

  isOpenedSection = (key: SettingSectionUnion) => {
    return key === this._openedSection
  }

  get syncStore() {
    return this._syncStore
  }

  get webhookStore() {
    return this._webhookStore
  }

  get userStore() {
    return this._userStore
  }

  get timelineStore() {
    return this._timelineStore
  }

  get propertyStore() {
    return this._propertyStore
  }

  get channelsStore() {
    return this._channelsStore
  }

  get listsStore() {
    return this._listsStore
  }
}
