// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vGWfx_wFJmaOu69vQenY{border-top:1px solid var(--border-neutral-default);display:flex;padding:8px;align-items:flex-start;gap:4px;align-self:stretch}.E5wQIjyigpMaHfgmM2Pl{width:64px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignSchedule/ui/LaterContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kDAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,OAAA,CACA,kBAAA,CAEF,sBACE,UAAA","sourcesContent":[".wrap {\n  border-top: 1px solid var(--border-neutral-default);\n  display: flex;\n  padding: 8px;\n  align-items: flex-start;\n  gap: 4px;\n  align-self: stretch;\n}\n.input {\n  width: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "vGWfx_wFJmaOu69vQenY",
	"input": "E5wQIjyigpMaHfgmM2Pl"
};
export default ___CSS_LOADER_EXPORT___;
