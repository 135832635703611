import { FC } from 'react'
import classNames from 'classnames'
import { Icon, type ITooltipProps, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type ISummaryLineProps = {
  label: string
  value: string | number
  tooltip?: ITooltipProps
  isTotal?: boolean
}

export const SummaryLine: FC<ISummaryLineProps> = ({ label, value, tooltip, isTotal }) => {
  return (
    <div className={classNames(styles.summaryLine, { [styles.totalSummaryLine]: isTotal })}>
      <Typography
        variant='body-md-regular'
        color={isTotal ? 'var(--content-primary-primary)' : 'var(--content-primary-tertiary)'}
        ariaLabel='Total'
        className={styles.lineDesc}
      >
        {label}
        {tooltip && (
          <Tooltip type='description' width='200px' placement='top' {...tooltip}>
            <Icon icon={'question'} color='var(--content-neutral-primary)' fontSize={14} />
          </Tooltip>
        )}
      </Typography>
      <Typography
        variant={isTotal ? 'body-md-semibold' : 'body-md-regular'}
        color='var(--content-primary-primary)'
        ariaLabel='USD'
      >
        {value}
      </Typography>
    </div>
  )
}
