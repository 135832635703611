import { makeAutoObservable, runInAction } from 'mobx'
import { type CallStore } from 'entities/Call'
import { usersStore } from 'entities/Users/store/users'
import { websocket, WebsocketEvent } from 'entities/WebSocket'

export class ActiveCallingStore {
  constructor(private callStore: CallStore) {
    makeAutoObservable(this)
    this.initActiveCallsEvent()
  }

  private initActiveCallsEvent = async () => {
    await usersStore.initPromise
    websocket.subscribe(`calling.${usersStore.user_owner_id}.user`)
    websocket.on(WebsocketEvent.ClientCalling, this._setHasActiveCall)
  }

  private _hasActiveCall = false
  get hasActiveCall() {
    return this._hasActiveCall
  }

  private _activeCallTimeOutId: NodeJS.Timeout | null = null
  private _setHasActiveCall = (value: boolean) => {
    if (this._activeCallTimeOutId) {
      clearTimeout(this._activeCallTimeOutId)
    }
    this._hasActiveCall = value
    this._activeCallTimeOutId = setTimeout(() => {
      runInAction(() => {
        this._hasActiveCall = false
      })
    }, 10000)
  }

  private _callingEventTimeOutId: NodeJS.Timeout | null = null
  sendCallingEvent = (value: boolean) => {
    if (this._callingEventTimeOutId) {
      clearTimeout(this._callingEventTimeOutId)
    }
    websocket.sendEvent(`calling.${usersStore.user_owner_id}.user`, 'client-calling', value)
    if (value) {
      this._callingEventTimeOutId = setTimeout(() => {
        this.sendCallingEvent(this.callStore.startedCall)
      }, 5000)
    }
  }
}
