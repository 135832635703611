import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Dropdown } from 'shared/ui'
import { MergeFieldContent, MergeFieldProps } from 'widgets/MergeField'
import styles from './styles.module.scss'

export const MergeFieldsIconAction: FC<MergeFieldProps> = observer((props) => {
  return (
    <Dropdown
      ariaLabel={'MergeFieldsIconAction'}
      placement={'bottom-start'}
      tooltipProps={{
        label: 'Merge fields',
        placement: 'top',
      }}
      zIndex={props.zIndex}
      sizeTriggerButton={'medium'}
      variantTriggerButton={'icon'}
      icon={'user'}
      typeButton={'icon-button'}
      customComponent={(onCloseMenu) => (
        <div className={styles.dropdown}>
          <MergeFieldContent onCloseMenu={onCloseMenu} {...props} />
        </div>
      )}
    />
  )
})
