import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessage } from 'entities/Message'
import { dataLogStore } from 'entities/DataLog/store/dataLogStore'

const MessageReceivedNew = {
  handle: (data: IResponseEventMessage) => {
    const { message, conversation } = data

    // TODO: SWR-14336: Will be uncommit and updated with SWR-14336
    // dataLogStore.setItem({
    //   logLevel: 'info',
    //   message: 'EventMessageReceivedNewLog',
    //   details: {
    //     conversationId: conversation.id,
    //     messageId: message.id,
    //   },
    // })
  },
}

export const EventMessageReceivedNewLog = {
  subscribe: () => websocket.on(WebsocketEvent.MessageReceivedNew, MessageReceivedNew.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.MessageReceivedNew, MessageReceivedNew.handle),
}
