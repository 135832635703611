import { AttachmentLoader, Icon, type IIconProps } from 'shared/ui'
import styles from './styles.module.scss'

type IAttachmentPdfProps = {
  loading: boolean
  iconProps?: IIconProps
}

export const AttachmentPdf = ({ iconProps, loading }: IAttachmentPdfProps) => {
  return (
    <div className={styles.attachmentPdfContainer}>
      <Icon icon='filePdf' fontSize={32} {...iconProps} />
      {loading && <AttachmentLoader />}
    </div>
  )
}
