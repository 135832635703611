import classNames from 'classnames'
import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { IIntegrationHubspotPropertyFormatted } from 'entities/Integrations'

import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { usersStore } from 'entities/Users'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/styles.module.scss'

type IItemTicketsRowProps = {
  ticket: IIntegrationHubspotPropertyFormatted[]
  clearAction?: (key: string, source_type: IUiSettingsSourceType) => void
}

const ItemTicketsRow = ({ ticket, clearAction }: IItemTicketsRowProps) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole
  return (
    <div className={styles.companyWrapper}>
      {ticket.map((item) => (
        <div
          key={item.key}
          className={classNames(styles.itemRow, {
            [styles.emptyPadding]: true,
          })}
        >
          <TextField
            className={styles.label}
            size='small'
            variant='outline'
            readOnly
            value={item.label || '-'}
            rightActions={[
              makeTextFieldInteractions({
                type: 'clear',
                active: !isUserViewOnly,
                action: () => clearAction?.(item.key, 'hubspot-tickets-draggable-items'),
              }),
            ]}
          />
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value || '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { ItemTicketsRow }
