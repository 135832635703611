import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, DropdownContent, layoutStore } from 'shared/ui'
import { BreakpointsEnum } from 'shared/constants/breakpoints'
import { ContactsSearch } from 'widgets/ContactsSearch'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'

type IConversationSearchDropDownProps = {
  anchorEl: HTMLDivElement
}

export const ConversationSearchDropDown = observer(
  ({ anchorEl }: IConversationSearchDropDownProps) => {
    const {
      handleCloseDropDown,
      openDropDown,
      search,
      handleAddItem,
      handleAutomaticallyCreateNewContact,
      automaticallyCreateNewContact,
      setAlert,
      setIsFirstEditMode,
    } = useConversationNewSearchContext()

    const onErrorCreate = (onCloseMenu: () => void) => () => {
      onCloseMenu()
    }

    const dropdownWidth = useMemo(() => {
      if (layoutStore.globalActiveBreakpoint) {
        if (layoutStore.globalActiveBreakpoint < BreakpointsEnum.MobileS) {
          return 'calc(100vw - 32px)'
        }
        if (layoutStore.globalActiveBreakpoint < BreakpointsEnum.XS) {
          return 364
        }
      }
      return 409
    }, [layoutStore.globalActiveBreakpoint])

    return (
      <Dropdown
        ariaLabel={'ConversationSearchDropDown'}
        show={openDropDown}
        placement={'bottom-start'}
        triggerComponent={() => null}
        PopperProps={{
          anchorEl: () => anchorEl as HTMLInputElement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: () => {
                  return [55, 4]
                },
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 20,
              },
            },
          ],
        }}
        customComponent={(onCloseMenu) => (
          <DropdownContent>
            <ContactsSearch
              search={search}
              onChange={(contact, params) => {
                if (params?.isNew) {
                  setAlert({
                    type: 'success',
                    desc: 'Contact created',
                  })
                  setIsFirstEditMode(true)
                }
                handleAddItem(contact)
              }}
              onErrorCreate={onErrorCreate(onCloseMenu)}
              isAutomaticallyCreateNewContact={automaticallyCreateNewContact}
            />
          </DropdownContent>
        )}
        onClose={() => {
          handleCloseDropDown()
          handleAutomaticallyCreateNewContact()
        }}
        width={dropdownWidth}
        margin={0}
      />
    )
  }
)
