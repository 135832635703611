// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gQKSFmGMbfj5ZOo3dHnb{display:flex;flex-direction:row;align-items:center;gap:8px}.gQKSFmGMbfj5ZOo3dHnb.xOfSVWahFmlA4a9LaUCT{flex-direction:row-reverse}.LTLqYTLFgDqEHQaxSUka{text-decoration:none;line-height:normal}", "",{"version":3,"sources":["webpack://./src/pages/powerDialer/pages/powerDialerList/ui/PowerDialerActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAEA,2CACE,0BAAA,CAIJ,sBACE,oBAAA,CACA,kBAAA","sourcesContent":[".actionsContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n\n  &.emptyView {\n    flex-direction: row-reverse;\n  }\n}\n\n.helpButton {\n  text-decoration: none;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsContainer": "gQKSFmGMbfj5ZOo3dHnb",
	"emptyView": "xOfSVWahFmlA4a9LaUCT",
	"helpButton": "LTLqYTLFgDqEHQaxSUka"
};
export default ___CSS_LOADER_EXPORT___;
