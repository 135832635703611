import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

export const ConversationOptOutAlert = observer(() => {
  const store = useConversationMessagesContext()
  const { isMessageLocal, isMessageAll } = store.conversationMessageFieldAccessStore

  if (isMessageLocal) {
    return (
      <Alert
        item={{
          desc: 'This contact has been opted-out and will not receive messages',
          type: 'warning',
        }}
      />
    )
  }

  if (isMessageAll) {
    return (
      <Alert
        item={{
          desc: 'This contact has unsubscribed and will not receive messages',
          type: 'warning',
        }}
      />
    )
  }

  return null
})
