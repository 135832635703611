import {
  useEventKnowledgeBaseUpdated,
  useEventKnowledgeBaseDocumentUpdated,
  KnowledgeBaseDocument,
} from 'entities/KnowledgeBase'
import type { KnowledgeBaseViewStore } from 'pages/settings/pages/knowledgeBase/store/knowledgeBaseViewStore'
import { INDEXATION_FAILED, UPLOAD_FAILED } from 'pages/settings/pages/knowledgeBase/lib/constants'

export const useKnowledgeBaseViewEventDocumentUpload = (
  knowledgeBaseViewStore: KnowledgeBaseViewStore
) => {
  const {
    documentsStore: { handleUploadingDocument, upsertDocument, removeDocument },
    knowledgeBaseStore: { updateKnowledgeBase },
    notificationStore: { addError },
  } = knowledgeBaseViewStore
  useEventKnowledgeBaseDocumentUpdated((documentResponse) => {
    const updatedDocument = new KnowledgeBaseDocument(documentResponse)
    if (updatedDocument.getIsUploadingSuccess()) {
      handleUploadingDocument(updatedDocument, true)
      return
    }
    if (updatedDocument.getIsUploadingFailed()) {
      addError(UPLOAD_FAILED)
      removeDocument(updatedDocument.id)
      return
    }
    if (updatedDocument.getIsIndexationFailed()) {
      addError(INDEXATION_FAILED)
    }
    upsertDocument(updatedDocument)
  })

  useEventKnowledgeBaseUpdated((data) => {
    updateKnowledgeBase(data)
  })
}
