import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { type IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { MenuOutbound, useMenuOutboundLayoutContext } from 'widgets/Menu/ui/MenuOutbound'
import { TriggersRoutes } from 'pages/triggers/route/type'
import { TriggersPageStore } from 'pages/triggers/store/triggersPageStore'
import { TriggersPageContext } from 'pages/triggers/context'

const items: IMenuItemProps[] = [
  {
    key: 'all_triggers',
    type: 'nav',
    to: TriggersRoutes.all,
    label: 'All triggers',
    icon: 'lightning2',
  },
  {
    key: 'sms',
    type: 'nav',
    to: TriggersRoutes.sms,
    label: 'SMS',
    icon: 'messageSmile',
  },
  {
    key: 'ringless',
    type: 'nav',
    to: TriggersRoutes.ringless,
    label: 'Ringless',
    icon: 'voicemail',
  },
]

export const TriggersLayout = observer(() => {
  const menuLayoutStore = useMenuOutboundLayoutContext()
  const [triggerPageStore] = useState(() => new TriggersPageStore(menuLayoutStore.pageLayoutStore))

  if (featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return (
      <TriggersPageContext.Provider value={triggerPageStore}>
        <Outlet />
      </TriggersPageContext.Provider>
    )
  }

  return (
    <TriggersPageContext.Provider value={triggerPageStore}>
      <PageLayout
        leftContent={
          featureFlagsStore.salesmsg_design_v2_new_sidebar_menu ? (
            <MenuOutbound />
          ) : (
            <Menu title='Triggers' menuItems={items} bottomNode={<TrialProgress />} />
          )
        }
        store={triggerPageStore.pageLayoutStore}
      />
    </TriggersPageContext.Provider>
  )
})
