import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { complianceStore } from 'entities/Compliance'
import { numbersStore } from 'entities/Phone'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { type IGoToQuestionnaireModalType } from './types'

export class GoToQuestionnaireModalStore {
  private _modalId = 'GoToQuestionnaire'
  private _type: IGoToQuestionnaireModalType | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get desc() {
    if (this._type === 'numbers') {
      return "Before adding a number, we'll ask a few quick questions to tailor your experience."
    }

    if (this._type === 'inboxes') {
      if (complianceStore.isQuestionnaire) {
        return 'Before creating an inbox, pick your phone number to kick off the approval process'
      }

      return "Before creating an inbox, we'll ask a few quick questions to tailor your experience."
    }

    if (complianceStore.isQuestionnaire) {
      return 'Before inviting members, pick your phone number to kick off the approval process'
    }

    return "Before inviting members, we'll ask a few quick questions to tailor your experience."
  }

  get title() {
    if (complianceStore.isQuestionnaire) {
      return 'Choose your number'
    }

    return 'Let’s personalize your approval process'
  }

  get buttonText() {
    if (complianceStore.isQuestionnaire) {
      return 'Choose number'
    }

    return 'Get started'
  }

  onAction = () => {
    if (complianceStore.isQuestionnaire) {
      uiStore.changeRouteViaVue({
        path: '/settings/organization/numbers',
      })
      numbersStore.initSelectNumberModal()

      return
    }

    uiStore.changeRouteViaVue({
      path: `/${SettingsPath.compliance.questionnaire.root}`,
      name: 'settings.organization.compliance.questionnaire',
      options: {
        state: 'started',
      },
    })
  }

  open = (type: IGoToQuestionnaireModalType) => {
    this._type = type

    if (complianceStore.isQuestionnaire && type === 'numbers') {
      numbersStore.initSelectNumberModal()
    } else {
      modalStore.addModal({
        id: this._modalId,
        type: ModalTypeList.INFO,
        title: this.title,
        desc: this.desc,
        primaryAction: {
          text: this.buttonText,
          onAction: () => {
            this.onAction()
            modalStore.removeModal(this._modalId)
          },
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: () => {
            modalStore.removeModal(this._modalId)
          },
        },
      })
    }
  }
}
