import { observer } from 'mobx-react-lite'
import { MessageContent, SendFromListContent, TagsContent } from 'widgets/DetailsContent'
import { useKeywordDetailsModalContext } from 'pages/keywords/context'
import styles from './styles.module.scss'

export const DetailsKeywordBody = observer(() => {
  const keyword = useKeywordDetailsModalContext()
  const { message, attachments, textInNumbersProps, tags } = keyword

  return (
    <div className={styles.bodyWrap}>
      <MessageContent message={message} attachments={attachments} type={'sms'} />
      <div className={styles.keywordParamsWrap}>
        {!!textInNumbersProps && <SendFromListContent list={textInNumbersProps} />}
        <TagsContent tags={tags} />
      </div>
    </div>
  )
})
