// files signature https://gist.github.com/overtrue/0a2aec7c2fbe9621a869
import { getExtension } from 'shared/lib/getExtension'

const getFileSignature = (signature: string) => {
  switch (signature) {
    case '42454749':
      return 'text/vcard'
    case '89504e47':
      return 'image/png'
    case '25504446':
      return 'application/pdf'
    case 'd0cf11e0':
      return 'application/msword'
    case 'ffd8ffe0':
      return 'image/jpeg'
    case 'fffb9064':
      return 'audio/mpeg'
    case '00020':
      return 'video/mp4'
    default:
      return 'unsupported'
  }
}

const isMarkdownFile = (file: File) => {
  const extension = getExtension(file.name)
  return ['markdn', 'markdown', 'md', 'mdown'].includes(extension)
}

export const getContentType = (data: File): Promise<string> => {
  return new Promise((resolve) => {
    if (data.type) {
      return resolve(data.type)
    }

    if (isMarkdownFile(data)) {
      return resolve('text/markdown')
    }

    const fileReader = new FileReader()

    fileReader.onloadend = (e) => {
      const target = e.target
      const result = target?.result

      if (result instanceof ArrayBuffer) {
        const arr = new Uint8Array(result).subarray(0, 4)
        let header = ''
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16)
        }

        const type = getFileSignature(header)

        return resolve(type)
      }

      resolve('')
    }

    fileReader.readAsArrayBuffer(data)
  })
}
