import { observer } from 'mobx-react-lite'
import { CSSProperties, ReactNode } from 'react'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type IRightModalProps = {
  hide: boolean
  modal: {
    id: string
    ariaLabel?: string
    type: 'right'
    zIndex?: number
    contentStyle?: CSSProperties
    content: ReactNode
  }
}

export const RightModal = observer(({ modal, hide }: IRightModalProps) => {
  const { contentStyle, content } = modal
  const ariaLabel = 'RightModal'
  return (
    <div className={styles.wrap}>
      <div
        className={styles.content}
        style={{ visibility: hide ? 'hidden' : 'unset', ...contentStyle }}
        onClick={(event) => event.stopPropagation()}
        aria-label={getAriaLabel(ariaLabel, 'card')}
      >
        {content}
      </div>
    </div>
  )
})
