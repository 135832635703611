// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kBC4nWvfIarHVgO2DCJH{display:inline-flex;border-radius:4px;border:1px solid var(--black-10);background:var(--yellow-20);color:var(--yellow-100);font-size:10px;font-weight:500;padding:2px;margin-top:7px;height:16px}", "",{"version":3,"sources":["webpack://./src/widgets/CallPopUp/ui/components/BaseContent/HoldStatus/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,iBAAA,CACA,gCAAA,CACA,2BAAA,CACA,uBAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA","sourcesContent":[".status {\n  display: inline-flex;\n  border-radius: 4px;\n  border: 1px solid var(--black-10);\n  background: var(--yellow-20);\n  color: var(--yellow-100);\n  font-size: 10px;\n  font-weight: 500;\n  padding: 2px;\n  margin-top: 7px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "kBC4nWvfIarHVgO2DCJH"
};
export default ___CSS_LOADER_EXPORT___;
