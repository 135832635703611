// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shGlKSexELfPsx1oKsAV{margin-top:24px;height:100%;overflow:auto}.xKedZkJeGfs7WxL2Q0Um{margin-bottom:40px}.AYheDFYfDFZFPkjv0BuQ{margin-bottom:8px}.sTNNemiwsaliXP5FXnPX{margin-top:7px;margin-left:1px}.JWhLyjTMuGpEz1PFHD1f{padding-left:12px}.Zt2aHNYxRGD3UrUmE4oW{padding:12px}.M1pr82FqIRilni3mGr4K{margin-top:8px}.GYz_3VPKu5taUVoE3gPp{margin-top:24px}.L4rQz8vr1kVMlkitZcXM{padding:11px 8px 11px 12px}.L4rQz8vr1kVMlkitZcXM>div{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/tollFree/pages/BusinessInfo/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,WAAA,CACA,aAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,YAAA,CAGF,sBACE,cAAA,CAGF,sBACE,eAAA,CAGF,sBACE,0BAAA,CAEA,0BACE,aAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".einFieldContainer {\n  margin-top: 24px;\n  height: 100%;\n  overflow: auto;\n}\n\n.bannerWrap {\n  margin-bottom: 40px;\n}\n\n.numberOfCreditsInput {\n  margin-bottom: 8px;\n}\n\n.noHaveEinButton {\n  margin-top: 7px;\n  margin-left: 1px;\n}\n\n.textInput {\n  padding-left: 12px;\n}\n\n.selectPadding {\n  padding: 12px;\n}\n\n.noEmailCheckbox {\n  margin-top: 8px;\n}\n\n.countrySelectWrap {\n  margin-top: 24px;\n}\n\n.countriesSelect {\n  padding: 11px 8px 11px 12px;\n\n  & > div {\n    display: block;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"einFieldContainer": "shGlKSexELfPsx1oKsAV",
	"bannerWrap": "xKedZkJeGfs7WxL2Q0Um",
	"numberOfCreditsInput": "AYheDFYfDFZFPkjv0BuQ",
	"noHaveEinButton": "sTNNemiwsaliXP5FXnPX",
	"textInput": "JWhLyjTMuGpEz1PFHD1f",
	"selectPadding": "Zt2aHNYxRGD3UrUmE4oW",
	"noEmailCheckbox": "M1pr82FqIRilni3mGr4K",
	"countrySelectWrap": "GYz_3VPKu5taUVoE3gPp",
	"countriesSelect": "L4rQz8vr1kVMlkitZcXM"
};
export default ___CSS_LOADER_EXPORT___;
