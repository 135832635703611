import { observer } from 'mobx-react-lite'
import { Box, Grid } from '@mui/material'
import { Alert, Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { TextField } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { DatePickerTextField } from 'shared/ui/DatePicker/DatePickerTextField'
import { uiStore } from 'shared/store/uiStore'
import { InputIntegerNumber } from 'shared/ui/TextField/InputPresets/InputIntegerNumber'
import { adminManageTrialsStore } from 'pages/admin/pages/organizations/modals/AdminManageTrials/store/adminManageTrialsStore'
import styles from './styles.module.scss'

export const AdminManageTrialsContent = observer(() => {
  const { credits, setCredits, setTrialDateEnd, currentCredits, currentTrialEnds, trialDateEnd } =
    adminManageTrialsStore

  return (
    <>
      <Box
        mb={2}
        display={'flex'}
        gap={'16px'}
        justifyContent={'space-around'}
        alignItems={'center'}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Label
            label={{
              text: 'Trial credits',
            }}
          />
          <Typography variant={'heading-md'} ariaLabel={'value'}>
            {numberFormat({ value: currentCredits })}
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Label
            label={{
              text: 'Trial date end',
            }}
          />
          <Typography variant={'heading-md'} ariaLabel={'value'}>
            {currentTrialEnds}
          </Typography>
        </Box>
      </Box>
      <Alert
        marginBottom={16}
        item={{
          type: 'infoBlue',
          title: 'Removing credits',
          desc: 'To remove credits add a negative value',
        }}
      />
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Label
            label={{
              text: 'Trial credits',
            }}
          />
          <TextField
            Input={InputIntegerNumber}
            variant={'stroke'}
            size={'medium'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={String(credits)}
            onChange={setCredits}
          />
        </Grid>
        <Grid item xs={12}>
          <Label
            label={{
              text: 'Trial date',
            }}
          />
          <DatePickerTextField
            wrapperClassName={styles.calendarWrap}
            popperPlacement='bottom-end'
            placeholder={'Select date'}
            date={trialDateEnd?.toDate()}
            handleDate={setTrialDateEnd}
            variant={'stroke'}
            size={'medium'}
            minDate={uiStore.dayjs().toDate()}
          />
        </Grid>
      </Grid>
    </>
  )
})
