import { makeAutoObservable } from 'mobx'
import { type IResponseTollFree, type IResponseTollFreeNumber } from 'entities/Compliance'
import { TollFreeProfileNumber } from 'entities/Compliance/model/OrganizationTollFree/TollFreeProfileNumber'

export class TollFreeProfile {
  private _id: IResponseTollFree['id'] | null = null
  private _title: IResponseTollFree['title'] | null = null
  private _numbersMap: Map<number, TollFreeProfileNumber> = new Map()
  private _use_case: IResponseTollFree['use_case'] | null = null

  constructor(response: IResponseTollFree) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseTollFree) => {
    this._id = response.id
    this._title = response.title
    this._use_case = response.use_case
    this.addNumbers(response.numbers)
  }

  addNumbers = (numbers: IResponseTollFree['numbers']) => {
    numbers.forEach((number) => {
      this.addNumber(number)
    })
  }

  addNumber = (number: IResponseTollFreeNumber) => {
    this._numbersMap.set(number.id, new TollFreeProfileNumber(number))
  }

  get id() {
    return this._id || 0
  }

  get title() {
    return this._title
  }

  get numberCount() {
    return this._numbersMap.size
  }

  get numbers() {
    return Array.from(this._numbersMap.values())
  }

  get use_case() {
    return this._use_case
  }

  get useCaseName() {
    return this._use_case?.title || ''
  }

  get numbersStatusCounts() {
    return this.numbers.reduce(
      (state, current) => {
        const statusKey = current.status.toLowerCase().replace(/ /g, '_')

        state[statusKey as keyof typeof state] = (state[statusKey as keyof typeof state] || 0) + 1

        return state
      },
      {
        unverified: 0,
        denied: 0,
        blocked: 0,
        verified: 0,
        internal_review: 0,
        under_review: 0,
      }
    )
  }

  get isStatusInUnderReview() {
    return this.numbers.every((item) => item.isStatusInCarrierReview || item.isStatusPendingReview)
  }

  get isStatusInProgress() {
    return this.numbers.some((item) => item.isStatusInProgress)
  }

  get isStatusInCarrierReview() {
    return this.numbers.some((item) => item.isStatusInCarrierReview)
  }

  get isStatusVerified() {
    return this.numbers.every((item) => item.isStatusVerified)
  }

  get isStatusPendingReview() {
    return this.numbers.some((item) => item.isStatusPendingReview)
  }

  get isStatusRejected() {
    return this.numbers.some((item) => item.isStatusRejected)
  }

  get isStatusBlocked() {
    return this.numbers.some((item) => item.isStatusBlocked)
  }
}
