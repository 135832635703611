import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, NavLink, NavLinkVue, Tooltip } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { configStore } from 'entities/Config'
import { usersStore } from 'entities/Users'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { SidebarStore } from 'widgets/Sidebar/store/sidebarStore'
import { ContactDetailsContentOverlay } from 'widgets/ContactDetailsContentOverlay/ContactDetailsContentOverlay'
import { useSidebarContext } from 'widgets/Sidebar/context/sidebarContext'
import { Logo } from 'widgets/Sidebar/ui/Logo/Logo'
import { SettingsDropdown } from './SettingsDropdown/SettingsDropdown'
import { ProductUpdatesDropdown } from './ProductUpdatesDropdown/ProductUpdatesDropdown'
import { MainDropdown } from './MainDropdown/MainDropdown'
import { commonTooltipProps, type IMenuItem, menuItemsV1 } from '../constants/menuItems'

import styles from './styles.module.scss'

const MenuItem = observer(
  ({ icon, route, label, itemKey, sidebarStore }: IMenuItem & { sidebarStore: SidebarStore }) => {
    const user = usersStore.user
    const canManageClients = userPermissionsStore.getItem('can_manage_clients')
    const canManageTextBots = userPermissionsStore.getItem('can_manage_text_bots')
    const canManageBroadcasts = userPermissionsStore.getItem('can_manage_broadcasts')
    const canManageTriggers = userPermissionsStore.getItem('can_manage_triggers')
    const canManageAnalytics = userPermissionsStore.getItem('can_access_analytics')

    const navRoute = useMemo(() => {
      if (subscriptionStore?.isTrial && route === 'clients') {
        return 'settings/billing/overview/upgrade'
      }
      return route
    }, [route, subscriptionStore?.isTrial])

    if (itemKey === 'workflows' && (!featureFlagsStore.canOpenWorkflow || user?.isViewOnlyRole)) {
      return null
    }

    if (
      itemKey === 'clients' &&
      !((canManageClients && organizationStore.isAgency) || user?.hasAccessibleOrganizations)
    )
      return null

    if (itemKey !== 'clients' && subscriptionStore.isAgencyWithoutSubscription) {
      return null
    }

    if (itemKey === 'chatbot' && (!featureFlagsStore.chatbot || !canManageTextBots)) {
      return null
    }

    if (itemKey === 'broadcasts' && !canManageBroadcasts) {
      return null
    }

    if (itemKey === 'triggers' && !canManageTriggers) {
      return null
    }

    if (itemKey === 'analytics' && !canManageAnalytics) {
      return null
    }

    if (window.DesignV2API && !window.DesignV2API?.handleCheckPage(navRoute)) {
      return (
        <Tooltip label={label} {...commonTooltipProps}>
          <NavLinkVue to={`/${navRoute}`} originalPath={route}>
            <Icon
              icon={icon}
              count={itemKey === 'conversation' ? sidebarStore.unreadMessagesCount : undefined}
              fontSize={20}
            />
          </NavLinkVue>
        </Tooltip>
      )
    }

    return (
      <Tooltip label={label} {...commonTooltipProps}>
        <NavLink
          to={`/${navRoute}`}
          className={({ isActive }) =>
            isActive ? `${styles.button} ${styles.active}` : styles.button
          }
        >
          <Icon
            icon={icon}
            count={itemKey === 'conversation' ? sidebarStore.unreadMessagesCount : undefined}
            fontSize={20}
          />
        </NavLink>
      </Tooltip>
    )
  }
)

export const SidebarV1 = observer(() => {
  const sidebarStore = useSidebarContext()

  return (
    <div className={styles.wrap} aria-label={getAriaLabel('Sidebar')}>
      <ContactDetailsContentOverlay />
      <div className={styles.wrapContent} aria-label={getAriaLabel('Sidebar', 'Content')}>
        <div className={styles.box} aria-label={getAriaLabel('Sidebar', 'BoxTop')}>
          <Logo />

          {!sidebarStore.loading && (
            <div
              className={classNames(styles.buttons)}
              aria-label={getAriaLabel('Sidebar', 'buttons')}
            >
              {menuItemsV1
                .filter(({ isShow }) => (isShow ? isShow() : true))
                .map((item) => (
                  <MenuItem key={item.itemKey} {...item} sidebarStore={sidebarStore} />
                ))}
            </div>
          )}
        </div>

        <div className={styles.box} aria-label={getAriaLabel('Sidebar', 'BoxBottom')}>
          {!sidebarStore.loading && (
            <>
              {configStore.developer && (
                <MenuItem
                  itemKey={'admin'}
                  route={'admin'}
                  label={'Admin'}
                  icon={'admin'}
                  sidebarStore={sidebarStore}
                />
              )}
              <ProductUpdatesDropdown />
              <SettingsDropdown />
              <MainDropdown sidebarStore={sidebarStore} />
            </>
          )}
        </div>
      </div>
    </div>
  )
})
