import { runInAction } from 'mobx'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventConferenceParticipant } from 'entities/Call'
import type { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'

const ConferenceParticipantLeave = {
  handle: (data: IResponseEventConferenceParticipant, store: CallPopUpDefaultStore) => {
    runInAction(() => {
      const item = store.getParticipant(data.participant_label)

      if (item) {
        item.updateStatus('default')
        store.deleteParticipant(item.uniqueId)
      }
    })
  },
}

export const EventConferenceParticipantLeave = {
  subscribe: (store: CallPopUpDefaultStore) =>
    websocket.on(
      WebsocketEvent.ConferenceParticipantLeave,
      (data: IResponseEventConferenceParticipant) => {
        ConferenceParticipantLeave.handle(data, store)
      }
    ),
}
