import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useRef } from 'react'
import { Button, Dropdown, DropdownContent, InputRadio, Tooltip } from 'shared/ui'
import {
  makeTextFieldAutoFocus,
  makeTextFieldHighlightLimit,
  makeTextFieldIcon,
  TextField,
  TextFieldPlaceholder,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { Warning } from 'shared/ui/Warning'
import { MAX_COMPANY_NAME_LENGTH } from 'shared/constants/auth'
import { RegisterStore } from 'widgets/Register'
import { DropdownRolesContent } from './DropdownRolesContent'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
}

export const Step1Form = observer(({ store }: IFormProps) => {
  const selectRef = useRef<HTMLLabelElement>(null)

  const {
    step1Store: {
      role,
      companyName,
      companyNameLength,
      isMaxCompanyLengthName,
      isActiveCompanyNameField,
      handleCompanyNameFieldFocus,
      setCompanyName,
      companySize,
      companySizes,
      setCompanySize,
      isNextDisabled,
      handleBlur,
    },
    handleNextStep,
  } = store

  return (
    <div className={styles.wrap}>
      <Label
        marginTop={0}
        label={{
          text: 'What is your company name?',
          className: styles.inputLabel,
          rightText: isActiveCompanyNameField ? (
            <Tooltip label={`Limit: ${MAX_COMPANY_NAME_LENGTH} characters`} placement='top'>
              <>{`${companyNameLength} of ${MAX_COMPANY_NAME_LENGTH}`}</>
            </Tooltip>
          ) : null,
        }}
      />
      <TextField
        className={classNames(styles.textField, {
          [styles.warning]: isMaxCompanyLengthName,
        })}
        variant='stroke'
        onFocus={handleCompanyNameFieldFocus}
        onBlur={handleBlur}
        size='medium'
        InputProps={{ placeholder: 'Enter company name' }}
        onChange={setCompanyName}
        value={companyName}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlightLimit({ limit: MAX_COMPANY_NAME_LENGTH }),
        ]}
      />
      <Warning
        text={
          isMaxCompanyLengthName && isActiveCompanyNameField
            ? `${MAX_COMPANY_NAME_LENGTH}-character limit reached`
            : ''
        }
      />

      <Label
        label={{
          text: 'What is your role?',
          className: styles.inputLabel,
        }}
      />
      <Dropdown
        placement={'bottom-start'}
        width={selectRef.current?.clientWidth}
        margin={selectRef.current?.clientHeight ? -selectRef.current?.clientHeight : undefined}
        triggerComponent={() => (
          <TextFieldPlaceholder
            ref={selectRef}
            rightActions={[
              makeTextFieldIcon({
                icon: 'chevronDown',
              }),
            ]}
            placeholder={role?.name || 'Select'}
            variant={'stroke'}
            size={'small'}
            className={classNames(styles.textField, {
              [styles.placeholder]: !role,
            })}
          />
        )}
        customComponent={(onClose) => (
          <DropdownContent noPadding>
            <DropdownRolesContent store={store} onClose={onClose} />
          </DropdownContent>
        )}
      />

      <Label
        label={{
          text: 'What is the size of your company?',
          className: styles.inputLabel,
        }}
      />
      <div className={styles.wrapSizes}>
        {companySizes.map((size) => (
          <InputRadio
            className={classNames(styles.inputSize, {
              [styles.active]: size.id === companySize,
            })}
            checked={size.id === companySize}
            onChecked={() => setCompanySize(size.id)}
            key={size.id}
            label={size.name}
          />
        ))}
      </div>
      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={handleNextStep}
        disabled={isNextDisabled}
        text={'Next'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default Step1Form
