import { observer } from 'mobx-react-lite'
import { Box, Button } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { getAriaLabel } from 'shared/lib'
import { openIntercomNewMessage } from 'entities/Intercom'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { SelectNumbers } from 'pages/settings/pages/compliance/ui/MultiSelectNumbers/SelectNumbers'
import styles from './styles.module.scss'

export const Numbers = observer(() => {
  const {
    stepLoading,
    numbersStore: {
      numbersNextClick,
      isStepDisabled,
      multiSelectedKeys,
      search,
      setSearch,
      numbersGroups,
      isLoading,
      setMultipleItem,
      handleMultiSelect,
      isDeselectState,
      multiSelectedValues,
      handleMultiDeselect,
      removeMultiSelectedItem,
    },
  } = useTollFreeStepsContext()

  const handleOpenIntercomWidget = () => {
    openIntercomNewMessage()
  }

  return (
    <div className={styles.wrap}>
      <PageDescription>
        Add all the toll-free numbers you want to verify. If you want to increase throughput on your
        toll-free numbers to send more messages,
        <Button
          text='contact us.'
          typeBtn='link'
          size='small'
          paddingInline={0}
          contained='secondary'
          className={styles.link}
          onClick={handleOpenIntercomWidget}
        />
      </PageDescription>

      <Box
        display='grid'
        gap='4px'
        marginTop='42px'
        aria-label={getAriaLabel('SelectUnverifedTollFreeNumbers')}
      >
        <Label
          label={{
            text: 'Unverified toll-free numbers',
          }}
        />
        <SelectNumbers
          multiSelectedKeys={multiSelectedKeys}
          search={search}
          setSearch={setSearch}
          numbersGroups={numbersGroups}
          isLoading={isLoading}
          setMultipleItem={setMultipleItem}
          handleMultiSelect={handleMultiSelect}
          isDeselectState={isDeselectState}
          multiSelectedValues={multiSelectedValues}
          handleMultiDeselect={handleMultiDeselect}
          removeMultiSelectedItem={removeMultiSelectedItem}
        />
      </Box>

      <NextButton
        loading={stepLoading}
        disabled={isStepDisabled || stepLoading}
        text='Next'
        onClick={numbersNextClick}
      />
    </div>
  )
})
