import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import type {
  IResponseOneTimeBroadcast,
  IResponseRecurringBroadcastById,
} from 'entities/Broadcast/api/types'
import { broadcastStore } from 'entities/Broadcast'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import { BroadcastDetailOneTime, BroadcastDetailRecurring } from 'widgets/BroadcastDetail'
import { useBroadcastPageContext } from 'pages/broadcasts/context/broadcastsPageContext'

export const DetailBroadcast = () => {
  const [loading, setLoading] = useState(true)
  const [broadcastResponse, setBroadcastResponse] = useState<
    IResponseOneTimeBroadcast | IResponseRecurringBroadcastById | null
  >(null)
  const { id } = useParams()
  const navigate = useNavigate()
  const broadcastPageStore = useBroadcastPageContext()

  useEffect(() => {
    if (id) {
      broadcastStore
        .getItemById(id)
        .then(({ data }) => {
          if (data) {
            setBroadcastResponse(data)
          }
        })
        .catch((e) => {
          navigate(`/${BroadcastsRoutes.root}/${BroadcastsRoutes.all}`)
          console.error(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id])

  if (loading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }

  if (broadcastResponse) {
    if (broadcastResponse.status === 'draft' || broadcastResponse.status === 'scheduled') {
      navigate(`/${BroadcastsRoutes.root}/${BroadcastsRoutes.edit}/${broadcastResponse.id}`)
      return <div />
    }

    if (broadcastResponse.status !== 'recurring_template') {
      return (
        <BroadcastDetailOneTime
          broadcastResponse={broadcastResponse}
          pageLayoutStore={broadcastPageStore.pageLayoutStore}
        />
      )
    }

    return (
      <BroadcastDetailRecurring
        broadcastResponse={broadcastResponse}
        pageLayoutStore={broadcastPageStore.pageLayoutStore}
      />
    )
  }

  return <div>Not found</div>
}
