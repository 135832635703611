import { observer } from 'mobx-react-lite'
import React from 'react'
import { Typography } from 'shared/ui'
import { MessageSMSPreview } from 'widgets/MessagePreview/MessageSMSPreview'
import { MessageRinglessPreview } from 'widgets/MessagePreview/MessageRinglessPreview'
import { CampaignMessageStore } from 'pages/campaigns/ui/CampaignMessage'
import styles from './styles.module.scss'

type IPreviewMessageProps = {
  message: CampaignMessageStore
}

export const PreviewMessage = observer(({ message }: IPreviewMessageProps) => {
  const { scheduleText, type, campaignSMSStore, campaignRinglessStore } = message
  const messageFieldStore = campaignSMSStore.messageFieldStore
  const { ringlessMessageFieldStore } = campaignRinglessStore
  return (
    <div className={styles.wrap}>
      <Typography
        ariaLabel={'scheduleText'}
        variant={'body-xxs-medium'}
        className={styles.scheduleText}
      >
        {scheduleText}
      </Typography>
      {type === 'sms' && <MessageSMSPreview messageFieldStore={messageFieldStore} />}
      {type === 'ringless' && (
        <MessageRinglessPreview attachment={ringlessMessageFieldStore.attachment} />
      )}
    </div>
  )
})
