import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu } from 'shared/ui'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import { MenuAIAutomationStore } from 'widgets/Menu/ui/MenuAIAutomation/store/menuAIAutomationStore'
import { AIAutomationKey } from 'widgets/Menu/ui/MenuAIAutomation/constants/menuItems'

export const MenuAIAutomation = observer(() => {
  const key = AIAutomationKey
  const [store] = useState(() => new MenuAIAutomationStore(key))

  useEffect(() => {
    store.menuStore.init()
  }, [])

  if (store.menuStore.loading) return null

  const menuStore = store.menuStore

  return (
    <Menu
      title={'AI & Automation'}
      menuItems={store.menuItems}
      bottomNode={<TrialProgress />}
      onCheckRoute={menuStore.setCachePath}
    />
  )
})
