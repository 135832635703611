// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D9gvnQRmziUoBNUl8YgQ{display:flex;flex-direction:column;border-radius:12px;border:1px solid var(--border-neutral-default);padding:20px}._4GPVeEAArrdtEBRKSYpQ{display:flex;justify-content:space-between}._3BlH4kLNvM6LQD1gzZe{display:flex;flex-direction:column;gap:4px}.qCrnJAlg4bukr4fnDsp_{display:flex;gap:8px}.s1XPErlwjot_4tzeD1id{height:32px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/tollFree/ui/SummaryTollFree/SummaryTollFree/SummaryTollFreeItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,8CAAA,CACA,YAAA,CAGF,uBACE,YAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,WAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  border-radius: 12px;\n  border: 1px solid var(--border-neutral-default);\n  padding: 20px;\n}\n\n.info {\n  display: flex;\n  justify-content: space-between;\n}\n\n.leftContent {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.rightContent {\n  display: flex;\n  gap: 8px;\n}\n\n.button {\n  height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "D9gvnQRmziUoBNUl8YgQ",
	"info": "_4GPVeEAArrdtEBRKSYpQ",
	"leftContent": "_3BlH4kLNvM6LQD1gzZe",
	"rightContent": "qCrnJAlg4bukr4fnDsp_",
	"button": "s1XPErlwjot_4tzeD1id"
};
export default ___CSS_LOADER_EXPORT___;
