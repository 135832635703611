import React, { useEffect, useState } from 'react'
import { uiStore } from 'shared/store/uiStore'
import type { PageLayoutStore } from 'shared/layout'
import { type IResponseOneTimeBroadcast } from 'entities/Broadcast/api/types'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import { BroadcastDetailOneTimeStore } from 'widgets/BroadcastDetail/BroadcastDetailOneTime/store/broadcastDetailOneTimeStore'
import { Content } from './Content'
import { BroadcastDetailOneTimeContext } from '../context/context'

type IBroadcastDetailOneTimeProps = {
  broadcastResponse: IResponseOneTimeBroadcast
  pageLayoutStore: PageLayoutStore
}

export const BroadcastDetailOneTime = ({
  broadcastResponse,
  pageLayoutStore,
}: IBroadcastDetailOneTimeProps) => {
  const [store] = useState(
    () => new BroadcastDetailOneTimeStore(broadcastResponse, pageLayoutStore)
  )

  useEffect(() => {
    uiStore.setCustomPathLabel(
      `/${BroadcastsRoutes.root}/${BroadcastsRoutes.view}/${broadcastResponse.id}`,
      broadcastResponse.name
    )
  }, [])

  return (
    <BroadcastDetailOneTimeContext.Provider value={store}>
      <Content />
    </BroadcastDetailOneTimeContext.Provider>
  )
}
