import { observer } from 'mobx-react-lite'

import { ChatbotInstructionType } from 'entities/Chatbot'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useDetailsContext } from '../detailsContext'
import { ConversationCard } from './ConversationCard'
import styles from './styles.module.scss'

export const TabConversation = observer(() => {
  const { rag } = featureFlagsStore
  const { data } = useDetailsContext()

  const maxAttempts = `${data.settings?.max_attempts?.value ?? '0'}`
  const aboutConversation = data.settings?.about_conversation?.value
  const businessInformation = data.settings?.business_information?.value
  const howToRespond = data.settings?.how_to_respond?.value
  const instructions = data.settings?.instructions?.value
  const useKnowledgeBase = data.settings?.use_knowledge_base?.value ? 'Yes' : 'No'

  return (
    <section className={styles.conversationCards}>
      {data.type === ChatbotInstructionType.LeadQualification ? (
        <>
          <ConversationCard
            value={maxAttempts}
            name='Set a message limit'
            placeholder='Message limit'
          />
          {rag && (
            <ConversationCard
              value={useKnowledgeBase}
              name='Use knowledge base?'
              placeholder='Knowledge base?'
            />
          )}
          <ConversationCard
            value={aboutConversation}
            name={`Explain the topic 
of the conversation`}
            placeholder='This conversation is about qualifying a lead by collecting their email, address, and required roofing services'
          />
          <ConversationCard
            value={businessInformation}
            name={`Briefly describe 
your business`}
            placeholder='We are a roofing company specializing in residential and commercial roofing services'
          />
          <ConversationCard
            value={howToRespond}
            name={`Define the style 
and tone of your 
messages`}
            placeholder='The Textbot should respond in a polite and professional manner, asking clear and direct questions'
          />
        </>
      ) : (
        <ConversationCard
          value={instructions}
          name={`Add instructions for 
the Textbot to follow`}
          placeholder='Answer customer questions about store hours, return policies, or product specifications.'
        />
      )}
    </section>
  )
})
