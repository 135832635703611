import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { FieldDropdown, IFieldDropdownErrorProps, type ITooltipProps } from 'shared/ui'
import type { SendFromDropdownStore } from 'widgets/SendFromDropdown'
import { SendFromMultiTrigger } from 'widgets/SendFromDropdown/ui/MultiSelect'
import { SendFromDropdownContext } from '../context/sendFromDropdownContext'
import { SendFromTrigger } from './SendFromTrigger'
import { SendFromContent } from './SendFromContent'
import styles from './styles.module.scss'

type ISendFromDropdownProps = {
  store: SendFromDropdownStore
  error?: IFieldDropdownErrorProps
  onClearError?: () => void
  label?: string
  questionTooltipProps?: ITooltipProps
}

export const SendFromDropdown: FC<ISendFromDropdownProps> = observer(
  ({ store, error, onClearError, label = 'Send from', questionTooltipProps }) => {
    const { isMultiSelect, multiDropdownStore } = store

    useEffect(() => {
      store.reactionParams()

      return () => {
        store.reset()
      }
    })

    return (
      <SendFromDropdownContext.Provider value={store}>
        <FieldDropdown
          offset={{ x: -4 }}
          labelText={label}
          questionTooltipProps={questionTooltipProps}
          error={error || store.error}
          onToggle={() => {
            onClearError?.()
            store.clearError?.()
          }}
          triggerComponent={isMultiSelect ? <SendFromMultiTrigger /> : <SendFromTrigger />}
          customComponent={(_, dropdownId) => <SendFromContent dropdownId={dropdownId} />}
          placeholderClassName={
            isMultiSelect && multiDropdownStore.multiSelectedValues.length
              ? styles.multiSelectPlaceholder
              : undefined
          }
        />
      </SendFromDropdownContext.Provider>
    )
  }
)
