import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import { RinglessBroadcasts, SMSBroadcasts } from 'pages/broadcasts'
import { OneTimeBroadcastLayout } from './layout/OneTimeBroadcastLayout'

export const OneTimeBroadcasts = () => {
  return (
    <Routes>
      <Route element={<OneTimeBroadcastLayout />}>
        <Route path={BroadcastsRoutes.sms} element={<SMSBroadcasts />} />
        <Route path={BroadcastsRoutes.ringless} element={<RinglessBroadcasts />} />
        <Route path={'*'} element={<Navigate to={BroadcastsRoutes.sms} />} />
      </Route>
    </Routes>
  )
}
