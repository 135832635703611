import {
  ComponentProps,
  ComponentPropsWithRef,
  createElement,
  forwardRef,
  Ref,
  useEffect,
} from 'react'
import classnames from 'classnames'
import {
  Emoji,
  Icon,
  IIconProps,
  ISpinnerLoaderProps,
  ITooltipProps,
  SpinnerLoader,
  Tooltip,
} from 'shared/ui'
import { IIconsVector } from 'shared/ui/Icon/icons'
import { EmojiKeys } from 'shared/ui/Emoji/types'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type IIconCircleButtonProps = ComponentProps<'div'> & {
  iconProps?: IIconProps
}

export type IIconButtonColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary2'
  | 'neutral'
  | 'transparent'
  | 'quaternary'
  | 'red'
  | 'green'
  | 'blue'
  | 'orange'
  | 'passivePrimary'
  | 'passiveSecondary'
  | 'noop'
  | 'lightSecondary'
  | 'darkPrimary'

export enum EnumIconButtonShape {
  Rounded = 'rounded',
  Square = 'square',
  None = 'none',
}

export type IIconButtonSize = 'medium' | 'small' | 'mini' | 'large' | 'origin_icon'

export type IIconButtonProps<T extends keyof HTMLElementTagNameMap = 'button'> =
  ComponentPropsWithRef<T> & {
    tag?: T
    icon?: IIconsVector
    iconProps?: IIconProps
    emoji?: EmojiKeys
    size?: IIconButtonSize
    color?: IIconButtonColor
    shape?: EnumIconButtonShape
    transparentBackground?: boolean
    transparentInactive?: boolean
    transparentColor?: boolean
    variant?: 'contained' | 'icon' | 'outlined' | 'custom' | 'transparent'
    fontSize?: number
    active?: boolean
    isEmoji?: boolean
    onDestroy?: () => void
    tooltipProps?: ITooltipProps
    disabled?: boolean
    loading?: boolean
    spinnerLoaderProps?: ISpinnerLoaderProps
    fullWidth?: boolean
    iconCircleButtonProps?: IIconCircleButtonProps
    ariaLabel: string
  }

export type IIconButton = <T extends keyof HTMLElementTagNameMap = 'button'>(
  props: IIconButtonProps<T>
) => JSX.Element | null

const IconButton = forwardRef(function IconButtonForwardRef<
  T extends keyof HTMLElementTagNameMap = 'button'
>(
  {
    tag,
    icon = 'home',
    size = 'medium',
    color = 'primary',
    variant = 'contained',
    shape = EnumIconButtonShape.Square,
    tooltipProps,
    fontSize,
    emoji,
    active,
    className,
    transparentBackground,
    transparentInactive,
    transparentColor,
    onDestroy,
    loading,
    spinnerLoaderProps,
    fullWidth,
    iconProps,
    iconCircleButtonProps,
    ariaLabel,
    ...props
  }: IIconButtonProps<T>,
  ref: Ref<HTMLElementTagNameMap[T]>
) {
  const defaultIconSize = size === 'small' ? 16 : 20
  const iconFontSize = fontSize || defaultIconSize
  useEffect(() => {
    return onDestroy
  }, [])

  const renderContentButton = () => {
    if (loading) {
      return (
        <SpinnerLoader
          size={16}
          determinatecolor={'var(--white-40)'}
          indeterminatecolor={'var(--white-100)'}
          {...spinnerLoaderProps}
        />
      )
    }

    if (!!emoji) {
      return <Emoji icon={emoji} fontSize={iconFontSize} />
    }

    return <Icon icon={icon} fontSize={iconFontSize} {...iconProps} />
  }

  const label = `IconButton_${ariaLabel}`
  const btn = createElement(
    tag ?? 'button',
    {
      ...props,
      className: classnames(
        styles.button,
        styles[size],
        styles[variant],
        styles[color],
        styles[shape],
        className,
        {
          [styles.transparentBackground]: transparentBackground,
          [styles.transparentInactive]: transparentInactive,
          [styles.transparentColor]: transparentColor,
          [styles.active]: active,
          [styles.fullWidth]: fullWidth,
        }
      ),
      ref,
      'aria-label': getAriaLabel(label, 'default'),
    },
    renderContentButton()
  )

  const circleBtn = () => {
    if (!iconCircleButtonProps) return

    const { iconProps, ...circleButtonProps } = iconCircleButtonProps

    return (
      <div
        className={classnames(
          styles.circleButton,
          styles.button,
          styles.circle,
          styles[variant],
          styles[color],
          active && styles.active,
          props.disabled && styles.disabled
        )}
        {...circleButtonProps}
        aria-label={getAriaLabel(label, 'circle')}
      >
        <Icon {...iconProps} fontSize={10} />
      </div>
    )
  }

  if (tooltipProps) {
    return (
      <div className={styles.wrap} aria-label={getAriaLabel(label, 'tooltip')}>
        <Tooltip {...tooltipProps} fullWidth={fullWidth}>
          {btn}
        </Tooltip>
        {circleBtn()}
      </div>
    )
  }
  return (
    <div className={styles.wrap} aria-label={getAriaLabel(label)}>
      {btn}
      {circleBtn()}
    </div>
  )
}) as IIconButton

export { IconButton }
