import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { ComplianceApi, type ICompanyLocation } from 'entities/Compliance'
import { OrganizationBusinessProfile } from 'entities/Compliance/model'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile/route/type'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { type BusinessProfileStepsStore } from 'pages/settings/pages/compliance/pages/businessProfile/store/businessProfileStepsStore'

export class CompanyLocationStore {
  constructor(private _businessProfileStore: BusinessProfileStepsStore | null) {
    makeAutoObservable(this)
  }

  private _companyLocation: ICompanyLocation | null = null

  get isNextDisabled() {
    return this._companyLocation === null
  }

  get companyLocation() {
    return this._companyLocation
  }

  get isUsaLocation() {
    return this._companyLocation === 'US'
  }

  setCompanyLocation = (value: ICompanyLocation) => {
    this._companyLocation = value
  }

  nextStepClick = async () => {
    if (this._companyLocation === null) return

    const { data } = await ComplianceApi.setBpBusinessInformationLocation({
      business_information: {
        country: this._companyLocation,
      },
      step: 1,
    })

    const organizationBusinessProfile = new OrganizationBusinessProfile(data)

    this._businessProfileStore?.complianceLayerStore.setOrganizationBusinessProfile(
      organizationBusinessProfile
    )
    this._businessProfileStore?.setDataFromBusinessProfileRequest(organizationBusinessProfile)

    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.businessProfile.root}/${BusinessProfileRoutes.generalInfo}`,
    })
  }
}
