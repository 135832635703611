import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type { IResponseUser } from 'entities/Users/api/types'
import type {
  IResponseOrganization,
  IParamsOrganizationMessagingRegion,
  IResponseMultiOrganizationsList,
  IResponseUpdatePhoneCheckerEnable,
  IParamsUpdatePhoneCheckerEnable,
  IResponseUpdateFallbackTeamId,
} from 'entities/Organization/api/types'

class Api {
  getOrganizationMembers(): Promise<AxiosResponse<IResponseUser[]>> {
    return baseApi.get('organization/members')
  }
  getBusinessProfileBusinessTypes(): Promise<AxiosResponse<IResponseUser[]>> {
    return baseApi.get('organization/business-profile/business-types')
  }

  getAllBusinessProfileTypes(): Promise<AxiosResponse<IResponseUser[]>> {
    return baseApi.get('organization/business-profile/business-types')
  }

  getOrganizationCurrent(): Promise<AxiosResponse<IResponseOrganization>> {
    return baseApi.get('organization/current')
  }

  updateOrganizationMessagingRegion(
    params: IParamsOrganizationMessagingRegion
  ): Promise<AxiosResponse<IResponseOrganization>> {
    return baseApi.post('organization/update-messaging-canada-only', params)
  }

  getMultiOrganizationsList(): Promise<AxiosResponse<IResponseMultiOrganizationsList>> {
    return baseApi.get('organization/multiorganizations/list', {
      params: { sortOrder: 'asc' },
    })
  }

  updatePhoneCheckerEnable(
    params: IParamsUpdatePhoneCheckerEnable
  ): Promise<AxiosResponse<IResponseUpdatePhoneCheckerEnable>> {
    return baseApi.post('organization/phone-checker/enable', params)
  }

  updateFallbackTeamId(
    fallback_teamId: number
  ): Promise<AxiosResponse<IResponseUpdateFallbackTeamId>> {
    return baseApi.post(`organization/set-fallback-inbox/${fallback_teamId}`, {
      fallback_team: fallback_teamId,
    })
  }
}

export const OrganizationApi = new Api()
