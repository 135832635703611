import { FC } from 'react'
import { Box, Typography } from 'shared/ui'
import { BroadcastStatus, type IBroadcastStatus } from 'entities/Broadcast'
import config from 'entities/Broadcast/ui/BroadcastStatus/config'
import styles from './styles.module.scss'

type IWidgetMultiStatusTriggerProps = {
  items: IBroadcastStatus[]
}

export const WidgetBroadcastStatusTrigger: FC<IWidgetMultiStatusTriggerProps> = ({ items }) => {
  if (!items.length) return <>select</>

  return (
    <Box display='flex' gap={1} alignItems='center' height={32}>
      <div className={styles.group}>
        {items.map((key) => {
          return <BroadcastStatus key={key} status={key} onlyIcon emphasis={'low'} />
        })}
      </div>
      <Typography ariaLabel='BroadcastStatus' variant='body-md-regular'>
        {items.length > 1 ? `${items.length} statuses` : config[items[0]].title}
      </Typography>
    </Box>
  )
}
