import { observer } from 'mobx-react-lite'
import { useMemo, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionItem, Button, ContainerHeader } from 'shared/ui'
import { PageLayoutStore } from 'shared/layout'
import { broadcastStore } from 'entities/Broadcast'
import { BroadcastsRoutes } from 'entities/ExportRouters'

type IBroadcastsHeaderProps = {
  title: string
  pageLayoutStore: PageLayoutStore
}

export const BroadcastsHeader: FC<IBroadcastsHeaderProps> = observer(
  ({ title, pageLayoutStore }) => {
    const { isEmptyState } = broadcastStore
    const { titleIconProps, headerMenuTooltipIconProps, headerMenuIconAction } = pageLayoutStore

    const navigate = useNavigate()
    const onClick = () => {
      navigate(`/${BroadcastsRoutes.root}/${BroadcastsRoutes.create}`)
    }

    const actions: ActionItem[] = useMemo(() => {
      if (isEmptyState) {
        return []
      }
      return [
        {
          iconButtonComponent: (
            <Button
              text={'New broadcast'}
              onClick={onClick}
              iconProps={{ icon: 'add' }}
              size={'medium'}
            />
          ),
        },
      ]
    }, [isEmptyState])
    return (
      <ContainerHeader
        height={72}
        title={title}
        titleVariant={'heading-md'}
        actions={actions}
        titleIconProps={titleIconProps}
        actionIconTooltipProps={headerMenuTooltipIconProps}
        titleIconAction={headerMenuIconAction}
        paddingLeft={20}
        paddingRight={20}
      />
    )
  }
)
