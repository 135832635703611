import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type {
  IResponseTenDlcBanner,
  IResponseTenDlcLimits,
  IResponseTenDlcUseCases,
} from 'entities/TenDlc/api/type'

class Api {
  getTenDlcLimits(): Promise<AxiosResponse<IResponseTenDlcLimits>> {
    return baseApi.get('organization/10-dlc/limits')
  }

  getTenDlcUseCases(): Promise<AxiosResponse<IResponseTenDlcUseCases[]>> {
    return baseApi.get('compliance/enum/use-cases')
  }

  updateTenDlcBanner(): Promise<AxiosResponse<IResponseTenDlcBanner>> {
    return baseApi.post('users/dlc-banner-showed', { banner_showed: true })
  }
}

export const TenDlcApi = new Api()
