import React from 'react'
import { observer } from 'mobx-react-lite'
import { AttachmentLoader, type IColumn, Typography } from 'shared/ui'
import { DayjsFormats, formatBytes } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { EmptyCell } from 'shared/ui/Table'
import { type KnowledgeBaseDocument, KnowledgeBaseDocumentStatus } from 'entities/KnowledgeBase'
import { FileIcon } from 'widgets/FileIcon/FileIcon'
import {
  KnowledgeBaseDocumentStatusLabel,
  getKnowledgeBaseDocumentStatusConfig,
} from 'widgets/KnowledgeBase'
import styles from './styles.module.scss'

export const columns: IColumn<KnowledgeBaseDocument>[] = [
  {
    field: 'name',
    name: 'Name',
    minWidth: '145px',
    maxWidth: '245px',
    renderRowCell: (row) => <NameCell file={row} />,
  },
  {
    field: 'status',
    name: 'Status',
    renderRowCell: (row) => <StatusCell file={row} />,
    minWidth: 110,
  },
  {
    field: 'size',
    name: 'Size',
    fullName: 'Size',
    renderRowCell: (row) => (row.size ? formatBytes(row.size) : <EmptyCell />),
    minWidth: 60,
  },
  {
    field: 'uploadedTime',
    name: 'Uploaded time',
    fullName: 'Uploaded time',
    renderRowCell: (row) =>
      row.uploadedAt ? (
        uiStore.dayjs(row.uploadedAt).format(DayjsFormats.fullWithAtDash2)
      ) : (
        <EmptyCell />
      ),
    minWidth: 180,
  },
]

const NameCell = observer(({ file }: { file: KnowledgeBaseDocument }) => {
  const isUploading = file.status === KnowledgeBaseDocumentStatus.Uploading

  return (
    <div className={styles.nameContainer}>
      <div className={styles.iconContainer}>
        <FileIcon docType={file.extension || file.contentType} fontSize={20} />
        {isUploading && <AttachmentLoader isLibrary />}
      </div>
      <Typography
        variant='body-md-regular'
        ariaLabel={`KnowledgeBaseDocument_${file.name}`}
        className={styles.name}
      >
        {file.name}
      </Typography>
    </div>
  )
})

const StatusCell = observer(({ file }: { file: KnowledgeBaseDocument }) =>
  getKnowledgeBaseDocumentStatusConfig(file.status) ? (
    <KnowledgeBaseDocumentStatusLabel document={file} />
  ) : (
    <EmptyCell />
  )
)
