import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { isEqual, omit } from 'lodash'
import { Blocker } from 'react-router-dom'
import { ReactNode } from 'react'
import { AxiosError } from 'axios'
import { ITabItem, toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { integrationsStore } from 'entities/Integrations'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import { IParamsBroadcast, IResponseBroadcastById } from 'entities/Broadcast/api/types'
import { numbersStore } from 'entities/Phone'
import { Phone } from 'entities/Phone/model/Phone'
import { organizationStore } from 'entities/Organization'
import { IParamsSendFrom } from 'entities/Inbox/api/types'
import { inboxesStore } from 'entities/Inbox'
import {
  EnumModeBroadcast,
  EnumTypeBroadcast,
  IBroadcastCreateField,
  IBroadcastCreateFieldEnum,
} from 'widgets/BroadcastView/store/types'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { BroadcastContactsStore } from 'widgets/BroadcastView/ui/ContactsContent/store/broadcastContactsStore'
import { BroadcastScheduleStore } from 'widgets/BroadcastView/ui/BrooadcastSchedule/store/broadcastScheduleStore'
import { SendFromDropdownStore } from 'widgets/SendFromDropdown'
import { BroadcastSMSStore } from 'widgets/BroadcastView/ui/BroadcastSMS/store/BroadcastSMSStore'
import { TrackConversionStore } from 'widgets/TrackConversions/store/trackConversionStore'
import { RinglessMessageFieldStore } from 'widgets/MessageFormFields/RinglessMessageField'
import { BroadcastReviewStore } from 'widgets/BroadcastView/ui/ReviewBroadcast/store/broadcastReviewStore'
import { BroadcastBadNumberStore } from 'widgets/BroadcastView/ui/BroadcastBadNumbers'

export class BroadcastViewStore {
  get tabs(): ITabItem<EnumTypeBroadcast>[] {
    return [
      {
        key: EnumTypeBroadcast.Sms,
        name: 'SMS',
        disabled: this.type !== EnumTypeBroadcast.Sms && this.isEditRecurringChild,
      },
      {
        key: EnumTypeBroadcast.Ringless,
        name: 'Ringless',
        disabled: this.type !== EnumTypeBroadcast.Ringless && this.isEditRecurringChild,
      },
    ]
  }

  loading = true
  loadingCreate = false
  modalSaveDraft = nanoid()
  mode: EnumModeBroadcast = EnumModeBroadcast.Create
  name = ''
  sendFrom: IParamsSendFrom | null = null

  private _isDragging = false
  private _numbersCheckType = 0
  private _cacheParams: IParamsBroadcast | null = null
  private _type: EnumTypeBroadcast = EnumTypeBroadcast.Sms
  private _disposeBlockerModal: IReactionDisposer | null = null
  private _disposeTotalContacts: IReactionDisposer | null = null
  private _disposeBroadcastLimitPackageCount: IReactionDisposer | null = null
  private _disposeAttachmentsLimit: IReactionDisposer | null = null
  private _disposeFileSizeLimit: IReactionDisposer | null = null
  private _disposeNumbersCheckType: IReactionDisposer | null = null
  private _disposeSendFromNumber: IReactionDisposer | null = null
  private _disposeIsAircallReaction: IReactionDisposer | null = null
  private _disposeTypeReaction: IReactionDisposer | null = null

  broadcastSMSStore = new BroadcastSMSStore({
    onFocus: () => {
      this.setError('smsMessage', '')
    },
  })
  ringlessMessageFieldStore = new RinglessMessageFieldStore({
    clearParentError: () => {
      this.setError('ringlessMessage', '')
    },
  })
  errorsMap: Map<IBroadcastCreateField, ReactNode> = new Map()
  sendFromDropdownStore = new SendFromDropdownStore({ excludeDeprioritizedNumbers: true })
  broadcastContactsStore = new BroadcastContactsStore()
  broadcastScheduleStore = new BroadcastScheduleStore()
  broadcastReviewStore: BroadcastReviewStore = new BroadcastReviewStore(this)
  trackConversionStore = new TrackConversionStore({
    clearError: () => {
      this.setError('conversion_id', '')
    },
  })
  private _broadcastBadNumberStore = new BroadcastBadNumberStore()
  private _isOpenAdvanced = false
  private _responseEditBroadcast: IResponseBroadcastById | null = null

  constructor(response?: IResponseBroadcastById, isDuplicate?: boolean) {
    makeAutoObservable(this)

    this.init(response, isDuplicate)
  }

  init = async (response?: IResponseBroadcastById, isDuplicate?: boolean) => {
    this.loading = true
    this.sendFromDropdownStore.init()

    await Promise.all([integrationsStore.fetchIntegrations(), allMergeFieldsStore.loadAllFields()])

    let promise: Promise<void> | null = null

    if (response) {
      if (isDuplicate) {
        this.mode = EnumModeBroadcast.Clone
        this.name = response.name + ' (copy)'
      } else {
        this._responseEditBroadcast = response
        this.mode = EnumModeBroadcast.Edit
        this.name = response.name
      }

      const send_from = response.send_from
      const numberId = response.number?.id || response.team?.number?.id

      if (send_from) {
        this.setSendFrom(send_from)
        this.sendFromDropdownStore.setSendFrom(send_from, 'init')
      } else if (numberId) {
        this.sendFromDropdownStore.setNumberId(numberId, 'init')
      }

      if (response.is_rvm) {
        this.setType(EnumTypeBroadcast.Ringless)
        const responseMediaShortUrl = response?.media_url?.[0]
        if (responseMediaShortUrl) this.ringlessMessageFieldStore.init(responseMediaShortUrl)
      } else {
        this.broadcastSMSStore.init(response)
      }

      promise = this.broadcastContactsStore.init(response)

      this.broadcastScheduleStore.init({
        response,
        isEditRecurring: this.isEditRecurring,
        isEditRecurringChild: this.isEditRecurringChild,
      })

      if (response.is_track_conversion) {
        this._isOpenAdvanced = true
      }

      this.setNumbersCheckType(response.numbers_check_type ? 1 : 0)
      this._broadcastBadNumberStore.setChecked(Boolean(response.numbers_check_type))

      this.trackConversionStore.init({
        is_track_conversion: response.is_track_conversion,
        conversion_id: response.conversion_id,
      })
    }

    await promise

    this._broadcastBadNumberStore.setTotal(this.broadcastContactsStore.total)

    if (!isDuplicate) {
      this._cacheParams = this.params
    }

    this.reactionBlockerModal()
    this.reactionTotalContacts()
    this.reactionBroadcastLimitPackageCount()
    this.reactionNumbersCheckType()
    this.reactionSendFromNumber()
    this.reactionIsAircallInbox()
    this.reactionType()

    this.broadcastScheduleStore.setSchedulingRecipientsMax(this.broadcast_limits_package_count)

    if (response?.package_count) {
      this.broadcastScheduleStore.setSchedulingRecipients(response.package_count)
    }

    this.loading = false
  }

  reset = () => {
    this._disposeBlockerModal?.()
    this._disposeAttachmentsLimit?.()
    this._disposeFileSizeLimit?.()
    this._disposeTotalContacts?.()
    this._disposeBroadcastLimitPackageCount?.()
    this._disposeNumbersCheckType?.()
    this._broadcastBadNumberStore.reset()
    this._disposeIsAircallReaction?.()
    this._disposeTypeReaction?.()

    uiStore.setRoutesBlocker(null)
  }

  reactionType = () => {
    this._disposeTypeReaction?.()
    this._disposeTypeReaction = reaction(
      () => this._type,
      (status) => {
        this.sendFromDropdownStore.setUseNumberStatus(status !== EnumTypeBroadcast.Ringless)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionIsAircallInbox = () => {
    this._disposeIsAircallReaction?.()
    this._disposeIsAircallReaction = reaction(
      () => this.sendFromDropdownStore.isAircall,
      (isAircall) => {
        this.broadcastSMSStore.messageFieldStore.setIsCurrentAirCall(isAircall)
      }
    )
  }

  reactionBlockerModal = () => {
    this._disposeBlockerModal?.()
    this._disposeBlockerModal = reaction(
      () => uiStore.routeBlocker,
      (blocker) => {
        if (blocker) {
          this.handleShowUnsavedModal(blocker)
        }
      }
    )
  }

  reactionTotalContacts = () => {
    this._disposeTotalContacts?.()
    this._disposeTotalContacts = reaction(
      () => [this.broadcastContactsStore.total, this.broadcast_limits_package_count],
      ([total, limit]) => {
        this.broadcastScheduleStore.setRequiredAdvancedSchedule(total > limit)
        this._broadcastBadNumberStore.setTotal(total)
      }
    )
  }

  reactionBroadcastLimitPackageCount = () => {
    this._disposeBroadcastLimitPackageCount?.()
    this._disposeBroadcastLimitPackageCount = reaction(
      () => this.broadcast_limits_package_count,
      this.broadcastScheduleStore.setSchedulingRecipientsMax,
      {
        fireImmediately: true,
      }
    )
  }

  reactionNumbersCheckType = () => {
    this._disposeNumbersCheckType?.()
    this._disposeNumbersCheckType = reaction(
      () => this._broadcastBadNumberStore.checked,
      (value) => {
        this.setNumbersCheckType(value ? 1 : 0)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionSendFromNumber = () => {
    this._disposeSendFromNumber?.()
    this._disposeSendFromNumber = reaction(
      () => this.sendFrom,
      (value) => {
        this.broadcastContactsStore.setNumberId(value?.options.number_id || null)
        this.broadcastContactsStore.loadCount(value?.options.number_id || null)
      },
      {
        fireImmediately: true,
      }
    )
  }

  setDraggingCondition = (condition: boolean) => {
    this._isDragging = condition
  }

  setName = (name: string) => {
    this.name = name
  }

  private setType = (type: EnumTypeBroadcast) => {
    this._type = type
  }

  selectTypeTab = (tab: ITabItem<EnumTypeBroadcast>) => {
    this.setType(tab.key)
  }

  setNumbersCheckType = (value: number) => {
    this._numbersCheckType = value
  }

  setSendFrom = (params: IParamsSendFrom | null) => {
    this.sendFrom = params
  }

  setOpenAdvanced = (value: boolean) => {
    this._isOpenAdvanced = value
  }

  handleShowUnsavedModal = (blocker: Blocker) => {
    if (blocker.state !== 'blocked') return

    const title = this.name
      ? `Save broadcast “${this.name}” before closing?`
      : 'Save broadcast before closing?'

    const isSaveChanges =
      this.mode === EnumModeBroadcast.Edit && this._responseEditBroadcast?.status !== 'draft'

    modalStore.addModal({
      id: this.modalSaveDraft,
      type: ModalTypeList.INFO,
      title: title,
      primaryAction: {
        text: isSaveChanges ? 'Save changes' : 'Save as draft',
        onAction: async () => {
          this._checkValidate(['name'])

          if (this.errorsMap.size) {
            blocker.reset()
            uiStore.setRoutesBlocker(null)
            modalStore.closeModal(this.modalSaveDraft)

            return
          }

          if (isSaveChanges) {
            await this.handleReviewBroadcast()
          } else {
            await this.handleCreateDraftBroadcast(() =>
              uiStore.changeRoute({
                path: blocker.location.pathname,
                type: uiStore.checkRouteType(blocker.location.pathname),
              })
            )
          }
          blocker.reset()
          uiStore.setRoutesBlocker(null)
          modalStore.closeModal(this.modalSaveDraft)
          this.broadcastReviewStore.close()
        },
      },
      additionalSecondaryAction: {
        text: 'Don’t save',
        onAction: () => {
          blocker.proceed()

          uiStore.setRoutesBlocker(null)
          uiStore.changeRoute({
            path: blocker.location.pathname,
            type: 'vue',
            method: 'replace',
          })
          modalStore.closeModal(this.modalSaveDraft)
          this.broadcastReviewStore.close()
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(this.modalSaveDraft)
        },
      },
    })
  }

  private createBroadcast = async (params: IParamsBroadcast, onSuccess: () => void) => {
    const cacheParams = this._cacheParams

    try {
      this.loadingCreate = true

      this._cacheParams = this.params

      if (this.mode === EnumModeBroadcast.Edit) {
        await BroadcastApi.updateBroadcast(params)
      } else {
        await BroadcastApi.createBroadcast(params)
      }

      onSuccess()
    } catch (e) {
      if (e instanceof AxiosError) {
        const error = e.response?.data?.error

        if (error) {
          toastStore.add({
            type: 'error',
            title: Array.isArray(error) ? error[0] : error,
          })
        } else {
          Object.entries(e.response?.data).map(([key, value]) => {
            if (key in IBroadcastCreateFieldEnum) {
              this.setError(key as IBroadcastCreateField, Array.isArray(value) ? value[0] : value)
            } else {
              toastStore.add({
                type: 'error',
                title: Array.isArray(value) ? value[0] : value,
              })
            }
          })
        }
      }

      this._cacheParams = cacheParams

      console.log(e)
    } finally {
      runInAction(() => {
        this.loadingCreate = false
      })
    }
  }

  handleCreateBroadcast = async (onSuccess: () => void) => {
    await this.createBroadcast(this.params, onSuccess)
  }

  handleCreateDraftBroadcast = (onSuccess: () => void) =>
    this.createBroadcast({ ...this.params, is_draft: true }, onSuccess)

  private _checkValidate = (exclude: string[] = []) => {
    if (!this.params.name.trim().length && !exclude.includes('name')) {
      this.setError('name', 'Please enter broadcast name')
    }

    if (this.params.is_rvm) {
      if (!this.params.media_url.length) {
        this.setError('ringlessMessage', 'Your message is the key – please select an audio')
      }
    } else {
      if (!this.params.message.trim().length && !this.params.media_url.length) {
        this.setError('smsMessage', 'Your message is the key – please write it above')
      }
    }

    if (!this.params.send_from) {
      this.setError('sendFrom', 'Please select the inbox to send from')
    }
    if (this.params.is_track_conversion && !this.params.conversion_id) {
      this.setError('conversion_id', 'Please select or create a conversion to continue')
    }

    if (!this.broadcastContactsStore.total) {
      this.setError('contacts', [
        'Please select the contacts',
        <br key={'br'} />,
        'to receive the message',
      ])
    }
  }

  setError = (key: IBroadcastCreateField, error: ReactNode) => {
    if (key === 'conversion_id') {
      this.trackConversionStore.setError(error)
    }
    if (key === 'ringlessMessage') {
      this.ringlessMessageFieldStore.setError(error)
    }
    if (!!error) this.errorsMap.set(key, error)
    else this.errorsMap.delete(key)
  }

  clearErrors = () => {
    this.trackConversionStore.clearError()
    this.ringlessMessageFieldStore.clearError()
    this.errorsMap.clear()
  }

  handleReviewBroadcast = async () => {
    this.clearErrors()
    this._checkValidate()
    if (this.errorsMap.size) {
      return
    }
    if (this.type === 'sms' && this.broadcastSMSStore.isError) {
      return
    }

    await this.broadcastReviewStore.open()
  }

  get isDraggingCondition() {
    return this._isDragging
  }

  get isImmediately() {
    return this.broadcastScheduleStore.scheduleStore.type === 'immediately'
  }

  get isEditRecurring() {
    return Boolean(
      this.mode === EnumModeBroadcast.Edit &&
        this._responseEditBroadcast?.recurring_template_settings
    )
  }

  get isEditRecurringChild() {
    return this.isEditRecurring && this._responseEditBroadcast?.status !== 'recurring_template'
  }

  get isExistChanges() {
    return !isEqual(omit(this._cacheParams, 'is_rvm'), omit(this.params, 'is_rvm'))
  }

  get number(): Phone | undefined | null {
    if (!this.sendFrom) return

    if (this.sendFrom.options.number_id) {
      return numbersStore.getItem(this.sendFrom?.options.number_id)
    }

    if (this.sendFrom.options.team_id) {
      const inbox = inboxesStore.getItem(this.sendFrom.options.team_id)

      if (inbox && inbox.type === 'inbox') {
        const numberId = inbox?.sendingOptions?.find(
          (item) => item.key === this.sendFrom?.options?.smart_option
        )?.number_ids[0]

        return numbersStore.getItem(numberId)
      }
    }
  }

  get broadcast_limits_package_count() {
    if (this.number?.is_toll_free) {
      return organizationStore.broadcast_limits?.broadcasts_toll_free || 0
    }
    if (this.number?.isShortCode) {
      return organizationStore.broadcast_limits?.broadcasts_short_code || 0
    }
    return organizationStore.broadcast_limits?.broadcasts_long_code || 0
  }

  get package_count() {
    const { scheduleStore, advanced_package_count } = this.broadcastScheduleStore

    if (
      !scheduleStore.isAdvancedSchedule &&
      this.broadcastContactsStore.total > this.broadcast_limits_package_count
    ) {
      return advanced_package_count || this.broadcast_limits_package_count
    }

    const packageCount =
      scheduleStore.isAdvancedSchedule && advanced_package_count
        ? advanced_package_count
        : this.broadcast_limits_package_count

    return packageCount
  }

  get typeParams() {
    if (this.type === 'sms') {
      return this.broadcastSMSStore.messageDataParams
    }
    return this.ringlessMessageFieldStore.ringlessMessageDataParams
  }

  get params(): IParamsBroadcast {
    return {
      id:
        this.mode === EnumModeBroadcast.Edit && this._responseEditBroadcast
          ? this._responseEditBroadcast.id
          : null,
      send_from: this.sendFrom,
      name: this.name,
      filters: this.broadcastContactsStore.paramsFilters,

      is_draft: false,

      isSharedShortCode: true,
      isShortCode: true,

      is_track_conversion: this.trackConversionStore.is_track_conversion,
      conversion_id: this.trackConversionStore.conversion_id,
      package_count: this.package_count,

      is_cloned: false,
      is_rvm: false,
      short_domain: null,
      numbers_check_type: this._numbersCheckType,

      ...this.typeParams,
      ...this.broadcastScheduleStore.scheduleDataParams,
    }
  }

  get type() {
    return this._type
  }

  get badNumberStore() {
    return this._broadcastBadNumberStore
  }

  get isOpenAdvanced() {
    return this._isOpenAdvanced
  }
}
