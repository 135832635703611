import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useBlocker } from 'react-router'
import classnames from 'classnames'
import { SpinnerLoader, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { MessagePreview } from 'widgets/MessagePreview'
import { SMSMessageField } from 'widgets/MessageFormFields'
import { TagsDropdown } from 'widgets/TagsDropdown'
import {
  KeywordDescription,
  KeywordName,
  KeywordsNumbers,
} from 'pages/keywords/pages/createKeyword/ui/KeywordFields'
import { useKeywordViewContext } from 'pages/keywords/context'
import { KeywordActions } from './KeywordActions'
import styles from './styles.module.scss'

export const KeywordViewContent = observer(() => {
  const {
    keywordSMSStore: { smsMessageFieldStore, messageFieldStore },
    errorsMap,
    setDraggingCondition,
    isDraggingCondition,
    tagsControlStore,
    textInNumbersStore,
    handleSaveKeyword,
    isSaving,
    isExistChanges,
    handleShowKeepEditingModal,
    reset,
    isLoading,
    isEditMode,
  } = useKeywordViewContext()
  const smsMessageError = errorsMap.get('message')
  const blocker = useBlocker(() => isExistChanges)

  const beforeUnloadHandler = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault()
  }, [])

  useEffect(() => {
    return () => {
      reset()
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [])

  useEffect(() => {
    uiStore.setRoutesBlocker(blocker)
    handleShowKeepEditingModal(blocker)
  }, [blocker])

  useEffect(() => {
    uiStore.setRoutesBlocker(isExistChanges ? blocker : null)

    if (isExistChanges) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [isExistChanges])

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <div
        className={classnames(styles.keywordViewBox, {
          [styles.keywordViewBox__isRelative]: isDraggingCondition,
        })}
      >
        <Typography variant={'body-xs-regular'} className={styles.label} ariaLabel={'NewKeyword'}>
          New keyword
        </Typography>
        <KeywordName />
        <KeywordDescription />
        <SMSMessageField
          smsMessageFieldStore={smsMessageFieldStore}
          errorMessage={smsMessageError}
          setDraggingCondition={setDraggingCondition}
        />
        <div className={styles.section}>
          <div className={styles.section__box}>
            <KeywordsNumbers store={textInNumbersStore} />
          </div>
          <div className={styles.section__box}>
            <TagsDropdown store={tagsControlStore} />
          </div>
        </div>
        <KeywordActions
          handleSave={handleSaveKeyword}
          isSaving={isSaving}
          isEditMode={isEditMode}
        />
      </div>

      <div className={styles.keywordPreviewBox}>
        <MessagePreview type={'sms'} messageStore={messageFieldStore} />
      </div>
    </div>
  )
})
