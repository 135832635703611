import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import { Icon, type IIconsVector, NavLink, Typography } from 'shared/ui'
import styles from '../styles.module.scss'

export type IMenuItemNavProps<T = string | number> = {
  key: T
  type: 'nav'
  to: string
  label: ReactNode
  icon?: IIconsVector
  onClick?: () => void
  checkActive?: (pathname: string) => void
  onCheckRoute?: (pathname: string) => void
  className?: string
  fullPath?: boolean
  disabled?: boolean
  rightContent?: ReactNode
}

export const MenuItemNav: FC<IMenuItemNavProps> = (item) => {
  const { pathname } = useLocation()
  const handleActive = (isActive: boolean) => {
    if (item.checkActive) {
      return isActive && item.checkActive(pathname)
    }

    if (isActive) item.onCheckRoute?.(item.to)

    return isActive
  }
  const path = item.fullPath ? `/${item.to}` : item.to
  const onClick = () => {
    if (item.disabled) return

    item.onClick?.()
  }

  return (
    <NavLink
      key={item.to}
      to={path}
      onClick={onClick}
      className={({ isActive }) =>
        classNames(styles.itemWrap, styles.itemWrap__typeNav, styles.nav, item.className, {
          [styles.active]: handleActive(isActive),
          [styles.disabled]: item.disabled,
        })
      }
    >
      <div className={styles.itemWrap__box}>
        {item.icon && <Icon icon={item.icon} fontSize={16} className={styles.icon} />}
        {typeof item.label === 'string' ? (
          <Typography
            variant={'body-md-regular'}
            pointer
            className={classNames(styles.titleWrap)}
            ariaLabel={item.label}
          >
            {item.label}
          </Typography>
        ) : (
          item.label
        )}
      </div>

      {item.rightContent && <div className={styles.itemWrap__box}>{item.rightContent}</div>}
    </NavLink>
  )
}
