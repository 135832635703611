import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { PageLayout } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { ContactsMenu } from 'widgets/ContactsMenu'
import { ContactsPageContext, useContactsPageContext } from 'pages/contacts/context/contactsContext'
import { ContactsPageStore } from 'pages/contacts/store/contactsPageStore'

const ContactsLayoutContent = observer(() => {
  const { contactsMenuStore, pageLayoutStore } = useContactsPageContext()

  useEffect(() => {
    return () => {
      contactsStore.reset()
    }
  }, [])

  return (
    <PageLayout store={pageLayoutStore} leftContent={<ContactsMenu store={contactsMenuStore} />} />
  )
})

export const ContactsLayout = () => {
  const contactsPageStore = useMemo(() => new ContactsPageStore(), [])

  return (
    <ContactsPageContext.Provider value={contactsPageStore}>
      <ContactsLayoutContent />
    </ContactsPageContext.Provider>
  )
}
