import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Avatar, IAvatarInfo, Typography } from 'shared/ui'
import { VCard } from 'entities/VCard/model/VCard'
import styles from './styles.module.scss'

export const CompareVCard = ({
  vCard,
  onCardClick,
  selected,
  status,
}: {
  vCard: VCard
  onCardClick: () => void
  selected: boolean
  status: 'Current' | 'New'
}) => {
  const { first_name, last_name, phone, email, name, color } = vCard

  const avatarInfo: IAvatarInfo = useMemo(() => {
    return {
      firstName: first_name,
      lastName: last_name,
      icon: !name ? 'user' : undefined,
      number: phone,
      color: (name && color) || 'var(--content-primary-tertiary)',
    }
  }, [first_name, last_name, phone, name, color])
  return (
    <div
      className={classNames(styles.compareCard, selected && styles.selected)}
      onClick={onCardClick}
    >
      <div className={styles.header}>
        <Avatar info={avatarInfo} size={40} />
        <Typography
          variant={'text-input-helper'}
          className={styles.status}
          tag={'div'}
          ariaLabel={'status'}
        >
          {status}
        </Typography>
      </div>
      <div className={styles.info}>
        <Typography variant={'body-rg-medium'} ellipsis ariaLabel={'name'}>
          {name}
        </Typography>
        <Typography
          variant={'body-md-regular'}
          ellipsis
          color={'var(--content-primary-secondary)'}
          ariaLabel={'phone'}
        >
          {phone}
        </Typography>
        <Typography
          variant={'body-md-regular'}
          ellipsis
          color={'var(--content-primary-secondary)'}
          ariaLabel={'email'}
        >
          {email}
        </Typography>
      </div>
    </div>
  )
}
