import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ServiceError } from 'shared/ui/ServiceError'
import { CallPopUp } from 'widgets/CallPopUp'

import CallPopUpLayout from './layouts/CallPopUpLayout'

const routes = createRoutesFromElements(
  <Route element={<CallPopUpLayout />} errorElement={<ServiceError name='call-popup' />}>
    <Route path='/*' element={<CallPopUp />} />
  </Route>
)

const router = wrapCreateBrowserRouter(createBrowserRouter)(routes)

export const CallPopupService = () => <RouterProvider router={router} />
