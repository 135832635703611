import { makeAutoObservable, IReactionDisposer, reaction, runInAction } from 'mobx'
import { StripeCardElementChangeEvent, TokenResult } from '@stripe/stripe-js'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { bannerStore, IDropdownItem, showToast } from 'shared/ui'
import { MIN_ORGANIZATION_NAME_LENGTH } from 'shared/constants/auth'
import { authStore, IParamsSignUpOrganization } from 'entities/Auth'
import { IResponseProfile } from 'entities/Users/api/types'
import { UsersApi } from 'entities/Users'
import { channelsProfile } from 'entities/Users/channels/profile'
import { CountryEnum } from './type'

export class RegisterMultiorgStore {
  private _lastName = ''
  private _firstName = ''
  private _number = ''
  private _country: IDropdownItem = {
    label: 'United States',
    id: CountryEnum.US,
    iconL: 'flagUSA',
    labelRight: '+1',
  }
  private _email = ''
  private _organizationName = ''
  private _cardToken = ''
  private _timezone: string | null = null
  private _loading = false
  private _showRetryAlert = false
  private _cardComplete = false
  private _cardError: string | null = null
  private _preloading = true

  private _disposeOrganizationNameValidation: IReactionDisposer | null = null
  private _disposeOrganizationNameValidationTrigger: IReactionDisposer | null = null
  private _organizationNameError: string | null = null
  private _organizationNameValidationTrigger: string | null = null

  constructor() {
    makeAutoObservable(this)
    this.reactionOrganizationNameValidation()
    this.reactionOrganizationNameValidationTrigger()
    this.init()
  }

  get preloading() {
    return this._preloading
  }

  get organizationNameError() {
    return this._organizationNameError
  }

  get countries(): IDropdownItem[] {
    return [
      {
        label: 'United States',
        id: CountryEnum.US,
        iconL: 'flagUSA',
        labelRight: '+1',
      },
    ]
  }

  get disabledRegister() {
    return !this._organizationName || !!this.cardError
  }

  get email() {
    return this._email
  }

  get cardError() {
    return this._cardError
  }

  get organizationName() {
    return this._organizationName
  }

  get loading() {
    return this._loading
  }

  get timezone() {
    return this._timezone || dayjs.tz.guess()
  }

  get payload(): IParamsSignUpOrganization {
    return {
      first_name: this._firstName,
      last_name: this._lastName,
      email: this._email,
      organization_name: this._organizationName,
      token: this._cardToken,
      number: this._number,
      country: this.country.id as string,
      timezone: this.timezone,
      number_vendor_key: this.numberVendorKey,
      plan: '',
      terms: true,
      lead_source: '',
      ga_source: '',
      ga_medium: '',
      ga_term: '',
      ga_content: '',
      ga_campaign: '',
      ga_referurl: '',
      ga_ip: '',
      city: '',
      region: '',
      multiorganization_registration: true,
      crm_id: 0,
      smsCode: '',
      utm_campaign: '',
      utm_content: '',
      utm_medium: '',
      utm_source: '',
      coupon: '',
      noCardTest: false,
      new_billing: true,
      answer_country: '',
      answer_team_size: '',
      answer_integration: '',
    }
  }

  get showRetryAlert() {
    return this._showRetryAlert
  }

  get firstName() {
    return this._firstName
  }

  get lastName() {
    return this._lastName
  }

  get number() {
    return this._number
  }

  get country() {
    return this._country
  }

  get isValidOrganizationName() {
    return this._organizationName.length >= MIN_ORGANIZATION_NAME_LENGTH
  }

  get numberVendorKey() {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('twilio')) return 'twilio-integration'
    return ''
  }

  init = async () => {
    bannerStore.removeAll()
    try {
      runInAction(() => {
        this._preloading = true
      })

      const { data } = await UsersApi.getUsersProfile()
      channelsProfile.subscribeChannels(data.id)
      runInAction(() => this.initData(data))
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._preloading = false
      })
    }
  }

  clearCardError() {
    this._cardError = null
  }

  clearErrors = () => {
    if (this._organizationNameError) this._organizationNameError = ''
  }

  setCardError(value: string | null) {
    this._cardError = value
  }

  onChangeCard = (event: StripeCardElementChangeEvent) => {
    this._cardComplete = event.complete
    this._cardError = event.error?.message || null
  }

  setShowRetryError = (value: boolean) => {
    this._showRetryAlert = value
  }

  setOrganizationName = (value: string) => {
    if (this._organizationNameError) this._organizationNameError = null
    this._organizationName = value
  }

  initData(data: IResponseProfile) {
    this._email = data.email
    this._firstName = data.first_name
    this._lastName = data.last_name
    this._number = data.formatted_number.replace(/[^-.0-9]/g, '')
  }

  private _onSubmitCard: (() => Promise<TokenResult | undefined>) | null = null

  setOnSubmit = (onSubmit: () => Promise<TokenResult | undefined>) => {
    this._onSubmitCard = onSubmit
  }

  onAddCard = async () => {
    if (this._cardError) return
    if (this._onSubmitCard) {
      try {
        const res = await this._onSubmitCard()
        if (!res || res?.error) {
          runInAction(() => {
            this.setCardError(res?.error.message || 'Please provide another credit card')
          })
          return
        }

        this._cardToken = res.token.id
        this.setCardError(null)
      } catch (e) {
        console.error(e)
      }
    }
  }

  onRegister = async () => {
    try {
      this.setLoading(true)
      await this.onAddCard()
      await authStore.signUpOrganization(this.payload)
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        this.setLoading(false)

        if (error.response?.status === 500) this.setShowRetryError(true)
        const data = error.response?.data
        if (!data) return

        const errorKeys = Object.keys(data)
        errorKeys.map((errorKey) => {
          const error = data[errorKey]?.[0] || data[errorKey]

          if (typeof error === 'string') {
            showToast({
              type: 'error',
              title: error,
            })
          }
        })
      }
    }
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  triggerOrganizationNameValidation = () => {
    this._organizationNameValidationTrigger = nanoid()
  }

  reactionOrganizationNameValidation = () => {
    this._disposeOrganizationNameValidation?.()
    this._disposeOrganizationNameValidation = reaction(
      () => [this._organizationNameValidationTrigger, this.isValidOrganizationName],
      (isValid) => {
        if (isValid) this.clearErrors()
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionOrganizationNameValidationTrigger = () => {
    this._disposeOrganizationNameValidationTrigger?.()
    this._disposeOrganizationNameValidationTrigger = reaction(
      () => this._organizationNameValidationTrigger,
      (value) => {
        this._organizationNameError =
          value && !this.isValidOrganizationName
            ? `Organization name must contain at least ${MIN_ORGANIZATION_NAME_LENGTH} characters`
            : null
        if (this.isValidOrganizationName) {
          this.clearErrors()
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
          this.onRegister()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }
}
