import { Route, Routes } from 'react-router'
import { TollFreeLayout } from 'pages/settings/pages/compliance/pages/tollFree/layout/TollFreeLayoutContent'
import { TollFreePage } from 'pages/settings/pages/compliance/pages/tollFree/ui/tollFreePage'

export const TollFreeMainPageRouter = () => {
  return (
    <Routes>
      <Route element={<TollFreeLayout />}>
        <Route path={'/'} element={<TollFreePage />} />
      </Route>
    </Routes>
  )
}
