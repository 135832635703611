import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ServiceError } from 'shared/ui/ServiceError'
import { SettingsMenu } from 'pages/settings/layout/SettingsMenu'
import { RootLayout } from 'app/shared/layouts'

import AuthLayout from './layouts/AuthLayout'

const routes = createRoutesFromElements(
  <Route element={<RootLayout />} errorElement={<ServiceError name='settings-menu' />}>
    <Route element={<AuthLayout />}>
      <Route path='/*' element={<SettingsMenu />} />
    </Route>
  </Route>
)

const router = wrapCreateBrowserRouter(createBrowserRouter)(routes)

export const SettingsMenuService = () => <RouterProvider router={router} />
