import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Actions } from 'shared/ui/Actions/Actions'
import { MessageCounter } from 'widgets/MessageField/ui/FieldActions/MessageCounter/MessageCounter'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import styles from './styles.module.scss'
import { SendAction } from './SendAction/SendAction'

type IFieldActionsProps = {
  isCollapse: boolean
}

export const FieldActions = observer(({ isCollapse }: IFieldActionsProps) => {
  const messageFieldStore = useMessageFieldContext()
  const onToggle = (open: boolean) => {
    if (!open) {
      messageFieldStore.setMoreDropdownComponent(null)
    }
  }

  const renderAside = () => {
    return (
      <>
        {!messageFieldStore?.noCounter && !isCollapse && <MessageCounter />}
        <SendAction isCollapse={isCollapse} />
      </>
    )
  }

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.isUnderline]: messageFieldStore.isVariantUnderline,
        [styles.collapse]: isCollapse,
      })}
    >
      {!isCollapse && (
        <Actions
          actions={messageFieldStore.actions}
          showItems={messageFieldStore.showActionsItems}
          moreDropdownComponent={messageFieldStore.moreDropdownComponent}
          setOnCloseMoreDropdown={messageFieldStore.setOnCloseMoreDropdown}
          onToggle={onToggle}
          placementTooltip={'top'}
          placementDropdown={'bottom-start'}
          iconButtonProps={messageFieldStore.iconButtonProps}
        />
      )}
      <div className={styles.aside}>{renderAside()}</div>
    </div>
  )
})
