import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { layoutStore } from 'shared/layout'
import { BreakpointsEnum } from 'shared/constants/breakpoints'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { ConversationMessages } from 'widgets/ConversationMessages'
import { ConversationHeaderSearch } from 'widgets/ConversationHeaderSearch'
import { ConversationLayout } from 'widgets/ConversationLayout'
import { ContactsDetails } from 'widgets/ContactsDetails'
import { CallHistory } from 'widgets/CallHistory'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { useConversationsPageContext } from 'pages/conversations/context'
import styles from './styles.module.scss'

export const Conversations = observer(() => {
  const conversation = conversationStore.currentItem
  const {
    pageLayoutStore,
    callHistoryStore,
    conversationMenuStore,
    callModalStore,
    conversationSearchStore,
    conversationLayoutStore,
    conversationListStore,
    conversationMessagesStore,
    contactCreateModalStore,
    contactsDetailsStore,
  } = useConversationsPageContext()

  const { state } = useLocation()

  const refContentContainer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (conversation) {
      contactsStore.getById(conversation.contact_id, true)
    }
  }, [conversation])

  const { activeBreakpoint, isLargeView, isMobileViewWithoutMSize, isMobileView } = layoutStore

  const { isShowChats, isShowCalls } = conversationLayoutStore
  const { isShowContactDetailsOverlay, handleSetVariant } = contactsDetailsGlobalStore
  const isOpenConversations = Boolean(contactsDetailsStore?.isVariantOpenConversations)

  handleSetVariant('conversation')

  useEffect(() => {
    if (isOpenConversations) return

    if (isLargeView) {
      contactsDetailsGlobalStore.handleChangeCollapse(false)
      conversationMenuStore.handleChangeCollapse(false)

      return
    }

    if (activeBreakpoint === BreakpointsEnum.M) {
      contactsDetailsGlobalStore.handleChangeCollapse(false)
      conversationMenuStore.handleChangeCollapse(true)

      return
    }

    if (isMobileViewWithoutMSize) {
      contactsDetailsGlobalStore.handleChangeCollapse(true)
      conversationMenuStore.handleChangeCollapse(true)

      return
    }
  }, [activeBreakpoint, isLargeView, isMobileViewWithoutMSize, isOpenConversations])

  useEffect(() => {
    if (refContentContainer.current) {
      pageLayoutStore.setContentContainer(refContentContainer.current)
      if (state?.from === 'openExistingConversation' && layoutStore.isMobileView) {
        pageLayoutStore.toEndContainer()
      }
    }

    return () => {
      conversationListStore.handleChangeVisibleUI(false)
      conversationSearchStore.clear(false)
      conversationSearchStore.onClose()
    }
  }, [])

  const showConversationsMenuOverlay = isMobileViewWithoutMSize && !conversationMenuStore.isCollapse

  const showOverlay = isShowContactDetailsOverlay || showConversationsMenuOverlay

  const isContactsDetails = contactsDetailsStore && !isMobileView

  return (
    <div className={styles.wrap}>
      <div className={styles.headerSearchWrap}>
        {conversationSearchStore.isOpened && (
          <ConversationHeaderSearch store={conversationSearchStore} />
        )}
        <div className={styles.searchContentWrap}>
          <div
            className={classNames(styles.contentWrapper, {
              [styles.disabledScroll]: showOverlay,
            })}
          >
            <div className={styles.content} ref={refContentContainer}>
              <ConversationLayout
                callHistoryStore={callHistoryStore}
                callModalStore={callModalStore}
                conversationMenuStore={conversationMenuStore}
                conversationSearchStore={conversationSearchStore}
                conversationLayoutStore={conversationLayoutStore}
                conversationListStore={conversationListStore}
                contactCreateModalStore={contactCreateModalStore}
              />

              {isShowChats && <ConversationMessages store={conversationMessagesStore} />}
              {isShowCalls &&
                (isMobileView ? (
                  <ConversationMessages store={conversationMessagesStore} />
                ) : (
                  <CallHistory store={callHistoryStore} />
                ))}
            </div>
          </div>
          {isContactsDetails && <ContactsDetails store={contactsDetailsStore} />}
        </div>
      </div>
    </div>
  )
})

export default Conversations
