import { websocket } from 'entities/WebSocket'

interface IEventInboxConversationProps {
  id: number | string
}

class ChannelInboxConversation {
  privateChannel?: string
  presenceChannel?: string

  constructor(props: IEventInboxConversationProps) {
    if (props.id !== 0) {
      this.presenceChannel = `presence-inbox.${props.id}.conversation`
      this.privateChannel = `private-inbox.${props.id}.conversation`
      this.subscribe()
    }
  }

  subscribe() {
    websocket.subscribe(this.presenceChannel)
    websocket.subscribe(this.privateChannel)
  }
}

export { ChannelInboxConversation }
