import classnames from 'classnames'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

type ITabsProps = {
  activeTab: EnumIntegrationTabs | string
  handleTab: (tab: EnumIntegrationTabs) => void
  count: {
    [EnumIntegrationTabs.Deals]: number
    [EnumIntegrationTabs.Companies]: number
    [EnumIntegrationTabs.Tickets]: number
  }
}

const Tabs = ({ activeTab, handleTab, count }: ITabsProps) => {
  const isActiveContactTab = activeTab === EnumIntegrationTabs.Contact
  const isActiveCompaniesTab = activeTab === EnumIntegrationTabs.Companies
  const isActiveDealsTab = activeTab === EnumIntegrationTabs.Deals
  const isActiveTicketsTab = activeTab === EnumIntegrationTabs.Tickets

  return (
    <div className={classnames(styles.tabs)}>
      <div
        className={classnames(styles.tab, {
          [styles.active]: isActiveContactTab,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Contact)}
      >
        Contact
      </div>
      <div
        className={classnames(styles.tab, {
          [styles.active]: isActiveCompaniesTab,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Companies)}
      >
        Companies
        {isActiveCompaniesTab && (
          <div className={styles.count}>{count[EnumIntegrationTabs.Companies]}</div>
        )}
      </div>
      <div
        className={classnames(styles.tab, {
          [styles.active]: isActiveDealsTab,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Deals)}
      >
        Deals
        {isActiveDealsTab && <div className={styles.count}>{count[EnumIntegrationTabs.Deals]}</div>}
      </div>
      <div
        className={classnames(styles.tab, {
          [styles.active]: isActiveTicketsTab,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Tickets)}
      >
        Tickets
        {isActiveTicketsTab && (
          <div className={styles.count}>{count[EnumIntegrationTabs.Tickets]}</div>
        )}
      </div>
    </div>
  )
}

export { Tabs }
