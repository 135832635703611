import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { BusinessProfileStepsStore } from '../store/businessProfileStepsStore'
import type { ComplianceLayerStore } from '../../../store/complianceLayerStore'

const BusinessProfileStepsContext = createContext<BusinessProfileStepsStore | null>(null)

export const useBusinessProfileStepsContext = () => {
  const context = useContext(BusinessProfileStepsContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with BusinessProfileStepsProvider'
    )

  return context
}

export const BusinessProfileStepsProvider = ({
  complianceLayerStore,
  children,
}: {
  complianceLayerStore: ComplianceLayerStore
  children: ReactNode
}) => {
  const store = useMemo(() => new BusinessProfileStepsStore(complianceLayerStore), [])

  useEffect(() => store.dispose, [])

  return (
    <BusinessProfileStepsContext.Provider value={store}>
      {children}
    </BusinessProfileStepsContext.Provider>
  )
}
