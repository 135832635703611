import { type AxiosResponse } from 'axios'
import { type AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type {
  IParamsContactsSearch,
  IResponseContactsSearch,
  IParamsCreateContact,
  IResponseContacts,
  IParamsContacts,
  IResponseFilters,
  IParamsUpdateActiveFields,
  IResponseContactFields,
  IParamsContact,
  IResponseContact,
  IResponseContactsCustomFields,
  IParamsValidation,
  IParamsValidateNumber,
  IParamsContactsOptOut,
  IParamsContactOptOut,
  IParamsCreateContactTags,
  IParamsDeleteContactTags,
  IParamsUpdateContactTags,
  IResponseDeleteContactTags,
  IParamsContactsByIdFields,
  IResponseContactOptInRequestById,
  IResponseUploadImportContactsFile,
  IResponseGetStatusImportContact,
  IResponseStartImportContact,
  IParamsStartImportContact,
  IResponseLoadImportHistory,
  IResponseContactsUnlinked,
  IResponseContactsDuplicate,
  IParamsContactsDuplicatePair,
  IResponseContactDuplicatePair,
  IParamsContactsDuplicatePairResolve,
  IParamsStartCheckLookupStatistic,
  IResponseCheckLookupStatistic,
  IParamsBulkCheck,
  IResponseBulkCheck,
  IParamsOptInCheck,
  IDataDeleteContacts,
  IParamsLoadImportHistory,
  IResponseReviewImport,
  IHistoryResponse,
  IParamsGetExport,
  IParamsContactFiltersCount,
  IResponseContactFiltersCount,
  IResponseContactsDuplicateCount,
  IParamsFilters,
  IParamsConversationsHistoryMessagesByContactId,
  IResponseConversationsHistoryMessages,
  IResponseContactCByIds,
  IParamsContactsFiltersTags,
  IResponseContactsFiltersTags,
  IResponseImportFields,
  IResponseHubspotContacts,
} from 'entities/Contacts/api/types'
import type { IResponseTag } from 'entities/Tags'
import type { INoteCreate, INoteUpdate, IResponseNote } from 'entities/Contacts'
import type { IResponseGetExport } from 'entities/Export'

class Api {
  getContacts(params?: IParamsContacts): Promise<AxiosResponse<IResponseContacts>> {
    return baseApi.get('contacts', { params })
  }

  getContactsList(
    params?: IParamsContacts,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContacts>> {
    // withIntegrationList for the task https://salesmsg.atlassian.net/browse/SWC-6948
    return baseApi.post('contacts/list', { ...params, withIntegrationList: true }, options)
  }

  getContactsHubspotList(
    params?: IParamsContacts,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseHubspotContacts>> {
    return baseApi.post('contacts/hubspot-list', params, options)
  }

  getContactsUnlinked(params?: IParamsContacts): Promise<AxiosResponse<IResponseContactsUnlinked>> {
    return baseApi.get('contacts/merge/unlinked', { params })
  }

  getContactsDuplicate(
    params?: IParamsContacts
  ): Promise<AxiosResponse<IResponseContactsDuplicate>> {
    return baseApi.get('contacts/merge/duplicate', { params })
  }

  getContactsDuplicateCount(): Promise<AxiosResponse<IResponseContactsDuplicateCount>> {
    return baseApi.get('contacts/merge/all/count')
  }

  getContactsDuplicatePair(
    params?: IParamsContactsDuplicatePair
  ): Promise<AxiosResponse<IResponseContactDuplicatePair>> {
    return baseApi.get('contacts/merge/duplicate/pair', { params })
  }

  updateContactsDuplicatePair(
    params?: IParamsContactsDuplicatePairResolve
  ): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.post('contacts/merge/duplicate/resolve', params)
  }

  getContactsSearch(
    params?: IParamsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('contacts/search', { params, ...options })
  }

  getContactsCustomFields(
    id: number,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsCustomFields>> {
    return baseApi.get(`contacts/custom-fields/${id}`, { ...options })
  }

  createContact(params: IParamsCreateContact): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.post('contacts', params)
  }

  updateContact(id: number, params: IParamsContact): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.put(`contacts/${id}`, params)
  }

  updateContactsByIdFields(
    id: number,
    params?: IParamsContactsByIdFields
  ): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.patch(`contacts/${id}/fields`, params)
  }

  updateContactsOptOuts(params: IParamsContactOptOut): Promise<AxiosResponse<IResponseContact[]>> {
    return baseApi.put('contacts/opt-out', params)
  }

  createContactsTags(params: IParamsCreateContactTags): Promise<AxiosResponse<IResponseTag[]>> {
    return baseApi.post('contacts/tags', params)
  }

  deleteContactsTags(
    params: IParamsDeleteContactTags
  ): Promise<AxiosResponse<IResponseDeleteContactTags>> {
    return baseApi.delete('contacts/tags/remove', { data: params })
  }

  updateContactTags(
    contactId: number,
    tags: IParamsUpdateContactTags
  ): Promise<AxiosResponse<IResponseTag[]>> {
    return baseApi.post(`contacts/${contactId}/tags`, { tags })
  }

  createContactsByIdNotes(id: number, params: INoteCreate): Promise<AxiosResponse<IResponseNote>> {
    return baseApi.post(`contacts/note/${id}/create`, params)
  }

  updateContactsByIdNotes(id: number, params: INoteUpdate): Promise<AxiosResponse<IResponseNote>> {
    return baseApi.patch(`contacts/note/${id}`, params)
  }

  deleteContactsByIdNotes(id: number): Promise<AxiosResponse<IResponseNote>> {
    return baseApi.delete(`contacts/note/${id}`)
  }

  getContactById(id: number): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.get(`contacts/${id}`)
  }

  getContactsByIds(ids: number[]): Promise<AxiosResponse<IResponseContactCByIds>> {
    return baseApi.post('contacts/by-ids', { ids })
  }

  blockContactById(id: number): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.post(`contacts/block/${id}`)
  }

  unblockContactById(id: number): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.post(`contacts/unblock/${id}`)
  }

  /**
   * @deprecated
   */
  getContactsFilters(params: IParamsFilters = {}): Promise<AxiosResponse<IResponseFilters>> {
    return baseApi.get('contacts/filters', { params })
  }

  /**
   * @deprecated
   */
  getContactsFiltersTags(
    params: IParamsContactsFiltersTags,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsFiltersTags>> {
    return baseApi.get('contacts/filters/values/tag', { ...options, params })
  }

  getContactsFiltersCount(
    payload: IParamsContactFiltersCount
  ): Promise<AxiosResponse<IResponseContactFiltersCount>> {
    return baseApi.post('contacts/filters/count', payload)
  }

  getDuplicates(params: IParamsContact): Promise<AxiosResponse<IResponseContact[]>> {
    return baseApi.get('contacts/merge/duplicate/pair', {
      params,
    })
  }

  updateActiveFields(
    params: IParamsUpdateActiveFields
  ): Promise<AxiosResponse<IResponseContactFields>> {
    return baseApi.post('contacts/fields', params)
  }

  createValidation(data: IParamsValidation) {
    return baseApi.post('contacts/validation', data)
  }

  validateNumber(params: IParamsValidateNumber): Promise<AxiosResponse<boolean>> {
    return baseApi.post('contacts/validate-number', params)
  }

  updateContactsOptOut(params: IParamsContactsOptOut): Promise<AxiosResponse<string[]>> {
    return baseApi.put('contacts/opt-out/bulk', params)
  }

  updateContactOptInRequestById(
    conversationId: number
  ): Promise<AxiosResponse<IResponseContactOptInRequestById>> {
    return baseApi.post(`contacts/opt-in/request/${conversationId}`)
  }

  uploadImportContactsFile(
    formData: FormData
  ): Promise<AxiosResponse<IResponseUploadImportContactsFile>> {
    return baseApi.post('contacts/import/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  getImportFields(): Promise<AxiosResponse<IResponseImportFields>> {
    return baseApi.get('contacts/import/fields')
  }

  reviewImport(id: number, mapping: string[]): Promise<AxiosResponse<IResponseReviewImport>> {
    return baseApi.post(`contacts/import/request/review/${id}`, mapping)
  }

  getStatusImportContact(): Promise<AxiosResponse<IResponseGetStatusImportContact>> {
    return baseApi.get('contacts/import/status')
  }

  loadImportHistory(
    params: IParamsLoadImportHistory
  ): Promise<AxiosResponse<IResponseLoadImportHistory>> {
    return baseApi.get('contacts/import', { params })
  }

  deleteImportHistory(id: number): Promise<AxiosResponse> {
    return baseApi.delete(`contacts/import/${id}`)
  }

  startImportContact(
    id: number,
    params: IParamsStartImportContact
  ): Promise<AxiosResponse<IResponseStartImportContact>> {
    return baseApi.post(`contacts/import/start/${id}`, params)
  }

  getImportContactProgress(id: number): Promise<AxiosResponse<IHistoryResponse>> {
    return baseApi.get(`contacts/import/progress/${id}`)
  }

  getExport(params: IParamsGetExport): Promise<AxiosResponse<IResponseGetExport>> {
    return baseApi.get('contacts/export', { params })
  }

  checkLookupStatistic(
    params: IParamsStartCheckLookupStatistic
  ): Promise<AxiosResponse<IResponseCheckLookupStatistic>> {
    return baseApi.post('contacts/lookup/statistics', params)
  }

  bulkCheck(params: IParamsBulkCheck): Promise<AxiosResponse<IResponseBulkCheck>> {
    return baseApi.post('contacts/bulk-check', params)
  }

  optInCheck(params: IParamsOptInCheck) {
    return baseApi.put('contacts/bulk', params)
  }

  deleteContacts(data: IDataDeleteContacts) {
    return baseApi.delete('contacts', { data })
  }

  getConversationsHistoryByContactId(
    params: IParamsConversationsHistoryMessagesByContactId,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseConversationsHistoryMessages>> {
    return baseApi.get(`contacts/${params.contactId}/conversation-history/messages`, {
      params: {
        limit: params.per_page,
        page: params.page,
        with_activity_logs: params.with_activity_logs,
      },
      ...options,
    })
  }
}

export const ContactsApi = new Api()
