import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { Button, Icon, IconButton, SpinnerLoader, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { tooltipText } from 'shared/constants/tooltipText'
import { callStore } from 'entities/Call'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import styles from './styles.module.scss'

interface RenderContentProps {
  icon: 'inbox' | 'conversation'
  mainText: string
  secondaryText: string
  actionButton: ReactNode
}

export const EmptyConversationsState = observer(() => {
  const store = useConversationMessagesContext()
  const { isActionDisabled } = conversationStore

  if (store.conversationListStore?.loadingConversations) {
    return (
      <div className={styles.wrap}>
        <SpinnerLoader />
      </div>
    )
  }

  const inteliquentCalling = featureFlagsStore.inteliquent_calling
  const inbox = inboxesStore.currentInbox
  const isPFTInbox = inbox?.isPFTInbox
  const disabledCall =
    !callStore.isShowCall || callStore.loading || (isPFTInbox && !inteliquentCalling)
  const user = usersStore.user
  const isShouldRequestAccess = !!usersStore.user?.isShouldRequestAccess

  const tooltipCall = () => {
    if (isPFTInbox) return tooltipText.disabledCall(inboxesStore.nonPFTInboxNumberType)
    if (!inbox) return ''
    if (inbox.isUnifiedInbox) return ''
    if (!callStore.isShowCall) {
      if (
        user?.isAdminRole ||
        user?.isOwnerRole ||
        (inbox.type === 'inbox' && inbox.ownerId === user?.id)
      ) {
        return (
          <>
            Outbound calling is disabled
            <button
              onClick={(e) => {
                e.preventDefault()

                uiStore.changeRoute({
                  path: `/settings/organization/inboxes/all-inboxes/${inbox.id}/calling`,
                  type: 'vue',
                })
              }}
            >
              Enable it here
            </button>
          </>
        )
      }

      return 'Outbound calling is disabled. Contact your admin to enable it.'
    }

    return ''
  }

  const tooltipButton = () => {
    if (isActionDisabled) {
      return 'Disabled; verify toll-free number.'
    }

    return ''
  }

  const renderContent = ({ icon, mainText, secondaryText, actionButton }: RenderContentProps) => (
    <div className={styles.wrap}>
      <div className={styles.icon}>
        <Icon icon={icon} fontSize={24} />
      </div>
      <Typography variant={'body-rg-medium'} className={'mb4'} ariaLabel={'CreateNewConversation'}>
        {mainText}
      </Typography>
      <Typography
        variant={'body-md-regular'}
        className={'tertiary'}
        ariaLabel={'EngageWithYourContacts'}
      >
        {secondaryText}
      </Typography>
      <div className={styles.actions}>{actionButton}</div>
    </div>
  )

  return isShouldRequestAccess
    ? renderContent({
        icon: 'inbox',
        mainText: 'Request inbox access',
        secondaryText: 'Engage with your contacts',
        actionButton: (
          <Button
            icon={'unlock'}
            text={'Request access'}
            loading={usersStore.requestInboxAccessLoading}
            onClick={() => usersStore.fetchRequestInboxAccess()}
          />
        ),
      })
    : renderContent({
        icon: 'conversation',
        mainText: 'Create new conversation',
        secondaryText: 'Engage with your contacts',
        actionButton: (
          <>
            <Button
              icon={'add'}
              text={'New message'}
              disabled={isActionDisabled}
              tooltipProps={{
                placement: 'top',
                label: tooltipButton(),
              }}
              onClick={() => conversationStore.handleOpenNewConversation()}
            />
            <IconButton
              icon={'phone'}
              color={'secondary'}
              onClick={store.callModalStore?.openModal}
              disabled={disabledCall}
              tooltipProps={{
                placement: 'bottom',
                label: tooltipCall(),
              }}
              ariaLabel='EmptyConversationsState_tooltipCall'
            />
          </>
        ),
      })
})
