import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { TollFreeStepsStore } from 'pages/settings/pages/compliance/pages/tollFree/store/tollFreeStepsStore'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

const TollFreeStepsContext = createContext<TollFreeStepsStore | null>(null)

export const useTollFreeStepsContext = () => {
  const context = useContext(TollFreeStepsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with TollFreeStepsProvider')

  return context
}

export const TollFreeStepsProvider = ({
  complianceLayerStore,
  children,
}: {
  complianceLayerStore: ComplianceLayerStore
  children: ReactNode
}) => {
  const store = useMemo(() => new TollFreeStepsStore(complianceLayerStore), [])

  useEffect(() => store.dispose, [])

  return <TollFreeStepsContext.Provider value={store}>{children}</TollFreeStepsContext.Provider>
}
