// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OxGycuAPv8Zn1oTzqbco{text-decoration:none}.ukF7KLnO80K7gQLuDDjy{display:flex;flex-direction:column;gap:8px;padding:0 24px}", "",{"version":3,"sources":["webpack://./src/features/ManageIntegrationConnection/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,cAAA","sourcesContent":[".learnMore {\n  text-decoration: none;\n}\n\n.field {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 0 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"learnMore": "OxGycuAPv8Zn1oTzqbco",
	"field": "ukF7KLnO80K7gQLuDDjy"
};
export default ___CSS_LOADER_EXPORT___;
