import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { EmptyState, MenuItem, MenuTitle } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { useContactsMenuContext } from 'widgets/ContactsMenu/context/contactsMenuContext'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import styles from './styles.module.scss'
import { SegmentItem } from '../SegmentItem/SegmentItem'

export const SegmentMenu = observer(() => {
  const contactsMenuStore = useContactsMenuContext()
  const {
    segments,
    activeSegment,
    resetActive,
    search,
    segmentsSearch,
    contactsTableStore,
    segmentModalStore,
  } = contactsMenuStore
  const { hasChanges, setHasChanges } = contactsFilterStore.segmentBox
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isAllContactsActive, setIsAllContactsActive] = useState<boolean>(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    contactsTableStore.reactionFilters()

    return () => {
      contactsMenuStore.reset()
    }
  }, [])

  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    const isActiveAllContacts =
      (pathname === '/contacts' || pathname.startsWith('/contacts?')) && !activeSegment

    setIsAllContactsActive(isActiveAllContacts)
  }, [location])

  const navToAllContacts = () => {
    resetActive()
    contactsTableStore.setLoadingContacts(true)
    contactsTableStore.debounceLoadContacts()
    contactsFilterStore.onOpen(false)
    setHasChanges(false)

    if (uiStore.navigate) {
      uiStore.navigate('/contacts')
    }
  }

  return (
    <>
      <MenuItem
        title='All contacts'
        icon='userFilled'
        className={styles.allContacts}
        active={isAllContactsActive}
        onClick={() => {
          const newFilterMode = activeSegment?.id === -1
          !newFilterMode && hasChanges
            ? segmentModalStore?.openAskIfSaveModal({
                onToggle: navToAllContacts,
                noSetActive: true,
                filtersList: contactsTableStore.noEmptyFilters,
              })
            : navToAllContacts()
        }}
      />

      {(!!segments.length || search) && (
        <MenuTitle
          useSearch={{
            handleSearch: segmentsSearch,
            search: search,
            placeholder: 'Search segments',
            autofocus: true,
            variant: 'transparent',
          }}
          title={'Segments'}
          count={0}
          open={isOpen}
          handleToggle={handleToggle}
        />
      )}

      {Boolean(!segments.length && search.length) && (
        <div className={styles.empty}>
          <EmptyState
            noIcon
            alignItems={'flex-start'}
            typographyProps={{
              variant: 'body-md-regular',
              color: 'var(--content-primary-disabled)',
              ariaLabel: 'EmptyState',
            }}
          />
        </div>
      )}

      {isOpen && (
        <div className={styles.segments}>
          {segments.map((segment) => (
            <SegmentItem key={segment.id} segment={segment} />
          ))}
        </div>
      )}
    </>
  )
})
