// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DyCBABZI3O8zSdVbfUXU{display:flex;flex-direction:column;gap:4px}.DyCBABZI3O8zSdVbfUXU.K5NSphIcRoNiRb1I3u1K>:last-child{margin-bottom:32px}.DyCBABZI3O8zSdVbfUXU>:last-child{margin-bottom:24px}.iSrDWIBs250EARlOpZtq{white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseNotifications/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,uDACE,kBAAA,CAGF,kCACE,kBAAA,CAIJ,sBACE,kBAAA","sourcesContent":[".alertContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  &.emptyView > :last-child {\n    margin-bottom: 32px;\n  }\n\n  & > :last-child {\n    margin-bottom: 24px;\n  }\n}\n\n.tryAgain {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertContainer": "DyCBABZI3O8zSdVbfUXU",
	"emptyView": "K5NSphIcRoNiRb1I3u1K",
	"tryAgain": "iSrDWIBs250EARlOpZtq"
};
export default ___CSS_LOADER_EXPORT___;
