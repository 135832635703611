import { useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'

export const LocalNumbersAccessLayout = observer(() => {
  const store = useLocalNumbersStepsContext()

  useLayoutEffect(() => {
    if (
      store.complianceLayerStore.complianceServiceBrand?.isStatusFailed ||
      store.complianceLayerStore.complianceServiceCampaign?.isStatusFailed
    )
      return

    uiStore.changeRouteViaVue({
      path: store.localNumbersNavigationStore.nextStep,
    })
  }, [])

  return (
    <>
      <Outlet />
    </>
  )
})
