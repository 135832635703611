// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kDwWm9fBSU95z_0cCbT2{width:100%;border-radius:4px}.skPyoDoyuNt45bt5QUTb{display:flex;gap:12px;align-items:center;padding:16px;cursor:pointer;border-radius:6px;background:var(--background-neutral-default)}._mBeXFzT18IzEhz5BqQE{padding:10px}.quApG5AXibvzGgQt093B{margin-left:auto}.UArc0OCVuyAvsYgbGGZ1{background:var(--background-primary-inverted-primary)}.UArc0OCVuyAvsYgbGGZ1.xtATixW753PvLrV0E1oT{background:var(--background-neutral-default)}.UArc0OCVuyAvsYgbGGZ1:hover{background:var(--background-neutral-subtle)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Collapse/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CACA,YAAA,CACA,cAAA,CACA,iBAAA,CACA,4CAAA,CAGF,sBACE,YAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,qDAAA,CAEF,2CACE,4CAAA,CAEF,4BACE,2CAAA","sourcesContent":[".container {\n  width: 100%;\n  border-radius: 4px;\n}\n\n.header {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  padding: 16px;\n  cursor: pointer;\n  border-radius: 6px;\n  background: var(--background-neutral-default);\n}\n\n.content {\n  padding: 10px;\n}\n.toggleIcon {\n  margin-left: auto;\n}\n.withHover {\n  background: var(--background-primary-inverted-primary);\n}\n.withHover.isOpen {\n  background: var(--background-neutral-default);\n}\n.withHover:hover {\n  background: var(--background-neutral-subtle);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "kDwWm9fBSU95z_0cCbT2",
	"header": "skPyoDoyuNt45bt5QUTb",
	"content": "_mBeXFzT18IzEhz5BqQE",
	"toggleIcon": "quApG5AXibvzGgQt093B",
	"withHover": "UArc0OCVuyAvsYgbGGZ1",
	"isOpen": "xtATixW753PvLrV0E1oT"
};
export default ___CSS_LOADER_EXPORT___;
