import { observer } from 'mobx-react-lite'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { PageLayout } from 'shared/ui'

import { useSettingsContext } from 'pages/settings/context/settingsContext'
import { SettingsMenu } from 'pages/settings/layout/SettingsMenu'
import { SettingsMenuCollapseButton } from 'pages/settings/layout/SettingsMenuCollapseButton'

export const SettingsLayout = observer(() => {
  const { pageLayoutStore } = useSettingsContext()
  const rightContent = (
    <>
      <SettingsMenuCollapseButton />
      <Outlet />
    </>
  )

  return (
    <PageLayout
      leftContent={<SettingsMenu />}
      rightContent={rightContent}
      store={pageLayoutStore}
    />
  )
})
