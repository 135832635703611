import { makeAutoObservable, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'

export class TrialInboxesLimitModalStore {
  private _modalId = 'trialInboxesLimit_modal'
  private _loading = true

  constructor() {
    makeAutoObservable(this)
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get loading() {
    return this._loading
  }

  init = async () => {
    if (!subscriptionStore.isTrial) {
      runInAction(() => {
        this._loading = false
      })

      return
    }

    runInAction(() => {
      this._loading = true
    })

    await this.trialSubscription.init()

    runInAction(() => {
      this._loading = false
    })
  }

  get title() {
    if (this.isAdminOrOwnerRole) {
      if (this.isCompleted) {
        return 'Upgrade to create a new inbox'
      }

      return 'Get approved and upgrade to add a number'
    }

    if (this.isCompleted) {
      return 'To create a new inbox, please ask one of your admins to upgrade'
    }

    return 'To create a new inbox, please ask one of your admins to get approved and upgrade'
  }

  get action() {
    if (this.isCompleted) {
      return {
        text: 'Upgrade plan',
        onAction: () => {
          uiStore.changeRouteViaVue({
            path: '/settings/billing/overview/upgrade',
          })
          this.onClose()
        },
      }
    }

    return {
      text: 'Get approved',
      onAction: () => {
        uiStore.changeRouteViaVue({
          path: '/settings/organization/compliance',
        })
        this.onClose()
      },
    }
  }

  open = async () => {
    this.init()

    if (!subscriptionStore.isTrial) return

    if (this.isAdminOrOwnerRole) {
      modalStore.addModal({
        id: this._modalId,
        type: ModalTypeList.INFO,
        title: this.title,
        primaryAction: this.action,
        secondaryAction: {
          text: 'Cancel',
          onAction: () => {
            this.onClose()
          },
        },
      })
    } else {
      modalStore.addModal({
        id: this._modalId,
        type: ModalTypeList.INFO,
        title: this.title,
        infoModalTextAction: 'Done',
      })
    }
  }

  onClose = () => {
    modalStore.closeModal(this._modalId)
  }

  get isCompleted() {
    return this.trialSubscription.isStatusCompleted
  }

  get isAdminOrOwnerRole() {
    return usersStore.user?.isAdminOrOwnerRole
  }
}
