// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aGt86kcB2xht2UWRSnV_{display:flex;align-items:center;justify-content:center;width:24px;height:24px;border-radius:6px;background:var(--background-neutral-default);font-size:12px;font-weight:500}.aGt86kcB2xht2UWRSnV_.w8aW3ixrxxyHUcEU3R15{background:var(--background-primary-inverted-primary)}.aGt86kcB2xht2UWRSnV_.t2YYnTPvWtypFsjKbcwG{background:var(--background-neutral-default)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/BlockNumber/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,4CAAA,CACA,cAAA,CACA,eAAA,CAEA,2CACE,qDAAA,CAGF,2CACE,4CAAA","sourcesContent":[".blockNumber {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 24px;\n  height: 24px;\n  border-radius: 6px;\n  background: var(--background-neutral-default);\n  font-size: 12px;\n  font-weight: 500;\n\n  &.blockNumberOpen {\n    background: var(--background-primary-inverted-primary);\n  }\n\n  &.blockNumberClosed {\n    background: var(--background-neutral-default);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockNumber": "aGt86kcB2xht2UWRSnV_",
	"blockNumberOpen": "w8aW3ixrxxyHUcEU3R15",
	"blockNumberClosed": "t2YYnTPvWtypFsjKbcwG"
};
export default ___CSS_LOADER_EXPORT___;
