import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'

import styles from './styles.module.scss'

type IKeywordActionsProps = {
  handleSave: () => void
  isSaving: boolean
  isEditMode: boolean
}

export const KeywordActions = observer(
  ({ handleSave, isSaving, isEditMode }: IKeywordActionsProps) => {
    const onCancel = () => {
      uiStore.changeRoute({
        path: 'keywords',
      })
    }

    return (
      <div className={styles.actionsWrap}>
        <Button
          text={isEditMode ? 'Save changes' : 'Create'}
          loading={isSaving}
          onClick={handleSave}
        />
        <Button text={'Cancel'} contained={'secondary'} onClick={onCancel} />
      </div>
    )
  }
)
