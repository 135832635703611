import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IOpportunity,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { OpportunitiesTab } from '../../TabsView/OpportunitiesTab/OpportunitiesTab'

type IOpportunitiesProps = {
  opportunities: IOpportunity[] | []
  contact: Contact
}

const Opportunities = ({ opportunities, contact }: IOpportunitiesProps) => {
  const store = useContactsDetailsContext()

  const integration = integrationsStore.getIntegration(IntegrationKey.salesforce)

  const linkProps = {
    key: IntegrationKey.salesforce,
    contact,
    integration,
    type: IIntegrationLinkTypes.opportunities,
  }

  const link = (id: number | string | null) => integrationLink({ ...linkProps, id })

  const handleView = () => {
    store.openView('opportunities', { opportunities }, 'salesforce', IntegrationKey.salesforce)
  }

  return (
    <OpportunitiesTab
      opportunities={opportunities}
      viewAll={handleView}
      link={link}
      rows={['amount', 'stage']}
      integrationLabel={'Salesforce'}
    />
  )
}

export { Opportunities }
