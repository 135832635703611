import React, { useState } from 'react'
import { Switch, Typography } from 'shared/ui'
import { IResponseStatusService } from 'entities/Admin/status/api/type'
import { AdminStatusApi } from 'entities/Admin/status/api/adminStatus'
import styles from 'pages/admin/pages/status/ui/styles.module.scss'

export const ServiceStatusItem = ({ item }: { item: IResponseStatusService }) => {
  const [loading, setLoading] = useState(false)
  const [online, setOnline] = useState(item.online)

  const handleChange = async (status: boolean) => {
    try {
      setLoading(true)
      const updatedService = await AdminStatusApi.changeStatusService(item.id, status)
      setOnline(updatedService.data.online)
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.item} key={item.id}>
      <Switch disabled={loading} size={'small'} onChange={handleChange} value={online} />
      <Typography variant={'body-lg-regular'} color={'tertiary'} ariaLabel={'name'}>
        {item.name}
      </Typography>
    </div>
  )
}
