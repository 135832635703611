import classnames from 'classnames'
import { FC, ReactNode } from 'react'
import { type IIconsVector, Icon } from 'shared/ui/Icon'
import { Typography } from 'shared/ui'
import {
  type IMenuItemNavProps,
  MenuItemNav,
} from 'shared/ui/Menu/ui/MenuItem/MenuItemNav/MenuItemNav'
import styles from './styles.module.scss'

export type IMenuSubProps<T = string | number> = {
  key: T
  type: 'sub'
  label: ReactNode
  icon: IIconsVector
  items: IMenuItemNavProps<T>[]
  onCheckRoute?: (pathname: string) => void
}

export const MenuSub: FC<IMenuSubProps> = ({ label, icon, items, onCheckRoute }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.label}>
        <Icon icon={icon} className={classnames(styles.label__content, styles.label__icon)} />
        <Typography
          tag={'div'}
          className={styles.label__content}
          variant={'body-md-regular'}
          ariaLabel={'MenuSub_label'}
        >
          {label}
        </Typography>
      </div>

      <div className={styles.section}>
        {items.map((item) => {
          return (
            <MenuItemNav
              {...item}
              key={item.key}
              onCheckRoute={onCheckRoute}
              className={styles.section__item}
            />
          )
        })}
      </div>
    </div>
  )
}
