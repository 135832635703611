import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type ContactsTagsModalStore } from 'widgets/ContactsTagsModal'

type IContactsTagsModalActionsProps = {
  contactsTagsModalStore: ContactsTagsModalStore
}

export const ContactsTagsModalActions: FC<IContactsTagsModalActionsProps> = observer(
  ({ contactsTagsModalStore }) => {
    const { handleSave, loading, mode: activeTabKey, hasSelected } = contactsTagsModalStore

    const btnText = activeTabKey === 'add' ? 'Add' : 'Remove'

    return (
      <Button
        typeBtn={'contained'}
        text={btnText}
        disabled={loading || !hasSelected}
        onClick={handleSave}
      />
    )
  }
)
