import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { organizationStore } from 'entities/Organization/store/organization'
import type { IResponseEventOrganizationCalling } from 'entities/Organization/api/types'

const OrganizationCalling = (data: IResponseEventOrganizationCalling) => {
  organizationStore.updateOrganizationCall(data)
}

export const useEventOrganizationCalling = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.OrganizationCalling, OrganizationCalling)
    }
  }, [websocket.isConnected])
}
