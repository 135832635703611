import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { DropdownContent, Icon, Typography } from 'shared/ui'
import { useSearchDropdownContext } from 'widgets/SearchDropdown'
import { DropdownAllContentSearchItems } from 'widgets/SearchDropdown/ui'
import styles from './styles.module.scss'

type IDropdownAllContentSearchProps = {
  onCloseMenu?: () => void
}

export const DropdownAllContentSearch: FC<IDropdownAllContentSearchProps> = observer(
  ({ onCloseMenu }) => {
    const { search, hasAllSearchFilters, options } = useSearchDropdownContext()

    const handleSelectSearch = () => {
      options?.handleSelectedSearch?.(search)
    }

    return (
      <DropdownContent noPadding className={classnames(styles.searchWrapper, styles['width-480'])}>
        <div className={styles.selectSearchWrap} onClick={handleSelectSearch}>
          <div className={styles.selectSearchContent}>
            <Icon icon={'search'} tertiary fontSize={20} />
            <Typography
              className={styles.selectValue}
              variant={'body-md-regular'}
              ariaLabel={'Search'}
            >
              <span className={styles.label}>Search conversations for </span>
              <span className={styles.value}>{`"${search}"`}</span>
            </Typography>
          </div>
        </div>

        {!hasAllSearchFilters && <div className={styles.divider}></div>}

        <DropdownAllContentSearchItems onCloseMenu={onCloseMenu} />
      </DropdownContent>
    )
  }
)
