import { FC } from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'

type IProgressBar = {
  percent: number
  withAllBorders?: boolean
  withBg?: boolean
  className?: string
}

export const ProgressBar: FC<IProgressBar> = ({ percent, withAllBorders, withBg, className }) => {
  return (
    <div
      className={classnames(styles.wrap, className, {
        [styles.withBg]: withBg,
        [styles.allBorders]: withAllBorders,
      })}
    >
      <div className={styles.progress} style={{ width: `${percent}%` }} />
    </div>
  )
}
