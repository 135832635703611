import { EnumUserRoles, usersStore } from 'entities/Users'
import { organizationStore } from 'entities/Organization'
import { integrationsStore } from 'entities/Integrations'
import { subscriptionStore } from 'entities/Subscription'
import { Integration } from 'entities/Integrations/model/Integration'

const appID = '5f7b3eb7e197fd4f8b4c70a4'

export type ICannyProps = {
  appID: string
  position?: 'right' | 'left'
  align?: 'bottom'
  user?: {
    email: string | null
    name: string | null
    id: number
    avatarURL: string | null
    created: string
    companies: [
      {
        created: string | null
        id: number | null
        name: string | null
      }
    ]
    customFields: {
      role: EnumUserRoles
      company: string | null
      integration: Integration[]
      plan?: string
    }
  }
}

export const initChangeLogCanny = () => {
  if (!window.Canny) return

  window.Canny('initChangelog', {
    appID: '5f7b3eb7e197fd4f8b4c70a4',
    position: 'right',
    align: 'bottom',
  })
}

export const initCanny = async () => {
  if (!window.Canny) return
  await Promise.all([usersStore.initPromise, organizationStore.initPromise])
  const { user } = usersStore

  if (!user) return

  const cannyProps: ICannyProps = {
    appID,
    user: {
      email: user.email,
      name: user.name,
      id: user.id,
      avatarURL: user.photo_url,
      created: user.createdAt,
      companies: [
        {
          created: organizationStore.created_at,
          id: organizationStore.id,
          name: organizationStore.name,
        },
      ],
      customFields: {
        role: user.role,
        company: organizationStore.name,
        integration: integrationsStore.integrations,
        plan: subscriptionStore.plan?.name,
      },
    },
  }

  window.Canny('identify', cannyProps, initChangeLogCanny)

  window.Canny('initChangelog', {
    appID: '5f7b3eb7e197fd4f8b4c70a4',
    position: 'right',
    align: 'bottom',
  })
}
