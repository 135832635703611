import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { TableStore } from 'shared/ui/Table'
import {
  Campaign,
  CampaignApi,
  IParamsGetList,
  IResponseGetList,
  IShortResponseCampaign,
} from 'entities/Campaign'
import { FiltersAndSearchStore } from 'widgets/FiltersAndSearch'

export class CampaignsListStore {
  filtersAndSearchStore
  tableStore
  _disposeParamsGetItems
  _disposeParamsWithoutPage
  constructor() {
    this.filtersAndSearchStore = new FiltersAndSearchStore({
      getFilters: () => CampaignApi.getFilters().then(({ data }) => data.data),
    })
    this.tableStore = new TableStore<Campaign>({
      saveColumns: this._saveColumns,
      getColumns: this._getColumns(),
    })
    makeAutoObservable(this)
    this.init()
    this._disposeParamsGetItems = reaction(
      () => this.paramsGetItems,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
    this._disposeParamsWithoutPage = reaction(
      () => this.paramsWithoutPage,
      () => {
        this.loading = true
        this.page = 1
      }
    )
  }

  dispose = () => {
    this._disposeParamsGetItems()
    this._disposeParamsWithoutPage()
  }

  get paramsWithoutPage(): Omit<IParamsGetList, 'page'> {
    return {
      limit: this.limit,
      search: this.filtersAndSearchStore.search,
      filters: this.filtersAndSearchStore.noEmptyFilters,
    }
  }

  get paramsGetItems(): IParamsGetList {
    return {
      ...this.paramsWithoutPage,
      page: this.page,
    }
  }

  itemsMap = new Map<number, Campaign>()
  get items() {
    return Array.from(this.itemsMap.values())
  }

  setItem = (response: IShortResponseCampaign) => {
    const campaign: Campaign = new Campaign(response)
    this.itemsMap.set(campaign.id, campaign)
  }

  setItems = (items: Array<IShortResponseCampaign>) => {
    items.forEach((item) => {
      this.setItem(item)
    })
  }

  firstLoading = true
  loading = true
  setLoading = (value: boolean) => {
    this.loading = value
    this.firstLoading = false
  }

  init = () => {
    this.loadData()
  }

  startPaginationSize = 10
  limit = 10
  page = 1
  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }
  total = 0

  get isEmpty() {
    return !this.total && !this.filtersAndSearchStore.hasSearchParams
  }

  private _cancelTokenSource: CancelTokenSource | null = null
  private initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }
  private loadData = async (noLoading?: boolean) => {
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        if (!noLoading) {
          this.loading = true
        }
      })
      const { data } = await CampaignApi.getList(this.paramsGetItems, {
        cancelToken: this._cancelTokenSource?.token,
      })
      this._setData(data)
    } catch (e) {
      runInAction(() => {
        this.loading = e instanceof CanceledError
      })
      this.setLoading(e instanceof CanceledError)
    } finally {
      this.setLoading(false)
    }
  }

  private _setData = ({ data, meta }: IResponseGetList) => {
    this.itemsMap.clear()
    this.setItems(data)
    this.total = meta.total
  }

  onSuccessDelete = (id: number) => {
    this.itemsMap.delete(id)
    if (this.itemsMap.size === 0 && this.page > 1) {
      this.page -= 1
    }
  }

  private _saveColumns = async (items: string[]) => {
    try {
      await CampaignApi.saveColumns({
        source_type: 'campaigns-table-columns',
        items,
      })
    } catch (e) {
      console.error(e)
    }
  }
  private _getColumns = async () => {
    try {
      const { data } = await CampaignApi.getColumns()

      if (!data.data) return

      return data.data.items
    } catch (e) {
      console.error(e)
    }
  }
}
