import React from 'react'
import { observer } from 'mobx-react-lite'
import { ContainerHeader, FullPageWrapper, Typography } from 'shared/ui'
import { IntegrationListContent } from 'pages/settings/pages/integrations/pages/integrationList/ui/IntegrationListContent'
import { IntegrationListProvider } from 'pages/settings/pages/integrations/context/integrationListContext'
import styles from './styles.module.scss'

const IntegrationList = observer(() => {
  return (
    <FullPageWrapper className={styles.container}>
      <ContainerHeader
        customTitle={
          <div className={styles.integrationListHeader}>
            <Typography variant='heading-lg' ariaLabel='IntegrationList_title' marginBottom={4}>
              App Marketplace
            </Typography>
            <Typography
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
              ariaLabel='IntegrationList_desc'
            >
              Connect Salesmsg to your favorite tools
            </Typography>
          </div>
        }
        titleVariant='heading-lg'
        paddingTop={64}
        paddingBottom={0}
        height={116}
      />
      <IntegrationListContent />
    </FullPageWrapper>
  )
})

export const IntegrationListPage = () => (
  <IntegrationListProvider>
    <IntegrationList />
  </IntegrationListProvider>
)
