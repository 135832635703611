import { CHAR_MAX_LENGTH } from 'shared/constants/limits'

export const getFileWithTruncatedName = (file: File) => {
  const fileNameParts = file.name.split('.')
  const extension = fileNameParts.length > 1 ? '.' + fileNameParts.pop() : ''
  const baseName = fileNameParts.join('.')

  const maxBaseNameLength = CHAR_MAX_LENGTH - extension.length

  const truncatedName =
    file.name.length > CHAR_MAX_LENGTH ? baseName.slice(0, maxBaseNameLength) : baseName

  return new File([file], truncatedName + extension, {
    type: file.type,
    lastModified: file.lastModified,
  })
}
