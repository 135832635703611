import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { ITabItem, toastStore } from 'shared/ui'
import { TableStore } from 'shared/ui/Table'
import { isMediaUrl } from 'shared/lib/isMediaUrl'
import { DayjsFormats, getBlob, isLink, writeTextToClipboard } from 'shared/lib'
import { FilesAccept, VideoAccept } from 'shared/constants/accept'
import { uiStore } from 'shared/store/uiStore'
import { AttachmentsApi, attachmentStore, ILimitSizeError } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import vCardStore from 'entities/VCard/store/vCardStore'
import { ContactsApi } from 'entities/Contacts'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { Contact } from 'entities/Contacts/model/Contact'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { downloadWithToast } from 'features/FileDownload'
import { MediaLibraryModalContent } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryModalContent'
import {
  IMediaLibraryStoreProps,
  IMediaLibraryType,
  IMediaLibraryViewType,
  IOnAddAttachment,
  MediaLibrarySelectMode,
  MediaLibraryVariant,
} from 'widgets/MediaLibrary/types'
import { MEGABYTES_IN_GIGABYTE } from 'widgets/MediaLibrary/constants'
import {
  getAttachmentFetchTypeByContentType,
  getAttachmentFlags,
  getMediaLibraryTypeByAttachmentType,
  isPicsnippetsUrl,
  sortAttachmentsByDate,
} from 'widgets/MediaLibrary/utils'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { EnumVariantMessageField } from 'widgets/MessageField'
import { RenameModalStore } from 'widgets/RenameModal'

export class MediaLibraryStore {
  private _modalId = ''
  private _contentType: IMediaLibraryType = 'all'
  private _viewType: IMediaLibraryViewType = 'grid'

  private _allAttachments: Map<string | number, Attachment> = new Map()
  private _activeTabAttachments: Attachment[] = []

  private _uploadInProgressIds: Set<string | number> = new Set()
  private _updatedTabs: Set<IMediaLibraryType> = new Set()
  private _uploadedCount = 0

  private _visibleColumnsIds: string[] = []
  private _isAttachmentLoading = false
  private _page = 1

  private readonly _onAddAttachment: IOnAddAttachment = () => {}
  private readonly _titleReaction: IReactionDisposer | null = null
  private readonly _perPage = 100

  selectMode: MediaLibrarySelectMode = MediaLibrarySelectMode.Multiple
  mediaLibraryVariant: MediaLibraryVariant = MediaLibraryVariant.MessageField

  isSearchResettingForUpload = false
  isAircallInbox = false
  isConversationMessageField = false
  conversationContact: Contact | null = null
  limitSizeErrors = new Map<string, number>()
  searchTerm = ''
  error = ''
  errorTitle = ''
  selectedAttachments: Map<string | number, Attachment> = new Map()
  spaceUsed: number | null = null
  totalAttachments: number | null = null
  tableStore = new TableStore<Attachment>({
    withoutDefaultManageColumns: true,
    element: 'attachment',
  })

  constructor({
    onAddAttachment,
    messageFieldStore,
    mediaLibraryVariant,
    selectMode,
  }: IMediaLibraryStoreProps) {
    makeAutoObservable(this)

    this._onAddAttachment = onAddAttachment
    this.mediaLibraryVariant = mediaLibraryVariant

    if (selectMode) this.selectMode = selectMode
    if (this.mediaLibraryVariant === MediaLibraryVariant.MessageField && messageFieldStore) {
      this.isConversationMessageField =
        messageFieldStore.variant === EnumVariantMessageField.Conversation
      this.conversationContact = messageFieldStore.conversationContact || null
      this.isAircallInbox = messageFieldStore.isCurrentAirCall
    }

    if (this.mediaLibraryVariant === MediaLibraryVariant.RinglessSelect) {
      this._contentType = 'audio'
    }

    this._titleReaction = reaction(
      () => this.spaceUsed,
      (currentSpaceUsed) => {
        const modal = modalStore.getModal(this._modalId)
        if (modal && currentSpaceUsed !== null && 'subtitle' in modal) {
          modal.subtitle =
            currentSpaceUsed === 0
              ? '0 / 3 GB used'
              : `${(currentSpaceUsed / MEGABYTES_IN_GIGABYTE).toFixed(2)} / 3 GB used`
        }
      }
    )
  }

  onMediaLibraryModalClose = () => {
    this.searchTerm = ''
    this._viewType = 'grid'
    this._page = 1
    modalStore.removeModal(this._modalId)
    this._titleReaction?.()
  }

  handleOpenMediaLibraryModal = () => {
    this._modalId = nanoid()

    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      title: 'Media',
      subtitle: '',
      width: 712,
      showCloseButton: false,
      ModalContent: MediaLibraryModalContent,
      ModalContentProps: {
        mediaLibraryStore: this,
      },
      onClose: this.onMediaLibraryModalClose,
    })

    this.logMediaLibraryEvent('Open')
  }

  onSingleModeSelect = (id: string | number) => {
    const attachment = this._allAttachments.get(id)
    if (attachment) {
      this._onAddAttachment(attachment)
      this.logMediaLibraryEvent('File Selected')
      this.onMediaLibraryModalClose()
    }
  }

  onAddToConversation = (attachments: Attachment[], fromBulk = false) => {
    attachments.forEach((attachment) => {
      this._onAddAttachment(attachment)
    })
    if (fromBulk) {
      this.selectedAttachments.clear()
      this.tableStore.resetSelected()
    } else {
      attachments.forEach(({ id }) => {
        this.selectedAttachments.delete(id)
        const attachment = this.allAttachments.get(id)
        if (attachment) this.tableStore.toggleSelected(attachment, false)
      })
    }
    this.onMediaLibraryModalClose()
  }

  onRenameAttachment = (attachment: Attachment) => {
    const renameModalId = nanoid()

    const handleCloseRenameModal = () => {
      modalStore.removeModal(renameModalId)
    }

    new RenameModalStore({
      id: renameModalId,
      title: 'Rename',
      name: attachment.name || '',
      withPostfix: true,
      element: 'File',
      isEditExtensionDisabled: true,
      secondaryAction: {
        text: 'Cancel',
        onAction: handleCloseRenameModal,
      },
      onClose: () => {
        handleCloseRenameModal()
      },

      onSave: async (name: string) => {
        try {
          const { data } = await AttachmentsApi.rename(attachment.id, name)
          const attachmentItem = this._allAttachments.get(attachment.id)
          if (attachmentItem && data) {
            attachmentItem.name = data.name
            this._allAttachments.set(attachment.id, attachmentItem)
          }
          this.setActiveTabAttachments()
          toastStore.add({
            type: 'success',
            title: 'File renamed',
          })
          this.logMediaLibraryEvent('File Renamed')
        } catch (e) {
          console.error(e)
        } finally {
          handleCloseRenameModal()
        }
      },
    })
  }

  isVCardContactAbsent = async (phone?: string) => {
    if (phone) {
      const { data: isValid } = await ContactsApi.validateNumber({
        number: phone,
      })
      const { data } = await ContactsApi.getContactsList({
        search: phone,
      })

      return isValid && data.total === 0
    }
  }

  checkVCardIsSave = async (phone: string) => {
    vCardStore.isLoading = true
    const isAbsent = await this.isVCardContactAbsent(phone)
    vCardStore.isLoading = false

    if (vCardStore.modalVCard) {
      vCardStore.modalVCard.isSave = isAbsent
    }
  }

  onCopyAttachment = (attachment: Attachment) => {
    writeTextToClipboard(attachment?.source_short || '').then(() => {
      toastStore.add({
        type: 'info',
        title: 'Copied',
      })
      this.logMediaLibraryEvent('Copy URL')
    })
  }

  onOpenPresentationModal = (attachment: Attachment, attachments?: Attachment[]) => {
    const { isAudio, isVideo, isImage, isVCardPresentationAvailable } =
      getAttachmentFlags(attachment)

    if (attachments?.length) {
      const index = attachments.findIndex((elem) => elem.id === attachment.id)
      presentationStore.setAttachments(attachments, index)
    } else {
      if (isVCardPresentationAvailable && attachment.vCard) {
        vCardStore.openVCard(attachment.vCard)
        this.checkVCardIsSave(attachment.vCard.phone)
        return
      }

      if (isAudio || isVideo || isImage) {
        presentationStore.setAttachments([attachment])
      }
    }
  }

  deleteAttachment = (id: number | string) => {
    this._allAttachments.delete(id)
  }

  onDeleteAttachments = async (ids: Array<string | number>, fromBulk = false) => {
    const deleteModalId = nanoid()

    const handleCloseDeleteModal = () => {
      modalStore.removeModal(deleteModalId)
    }

    const onDeleteAttachments = async () => {
      try {
        await AttachmentsApi.delete(ids)
        toastStore.add({
          type: 'success',
          title: ids.length > 1 ? ids.length + ' files deleted' : 'File deleted',
        })
        this._page = 1
        void this.fetchAttachmentList()
        this.logMediaLibraryEvent('File Deleted')
        if (fromBulk) {
          this.selectedAttachments.clear()
          this.tableStore.resetSelected()
        } else {
          ids.forEach((id) => {
            this.selectedAttachments.delete(id)
            const attachment = this.allAttachments.get(id)
            if (attachment) this.tableStore.toggleSelected(attachment, false)
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        handleCloseDeleteModal()
      }
    }

    modalStore.addModal({
      id: deleteModalId,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      zIndex: 2000,
      width: 280,
      type: ModalTypeList.ALERT,
      title: ids.length > 1 ? `Delete ${ids.length} files?` : 'Delete file?',
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: onDeleteAttachments,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: handleCloseDeleteModal,
      },
      onClose: handleCloseDeleteModal,
    })
  }

  onDownloadAttachment = async (attachment: Attachment) => {
    await downloadWithToast(
      async () => {
        const name = attachment.name || attachment.id.toString()
        const url = attachment.source

        if (!url) throw new Error('The attachment does not have a download URL')

        const blob = await getBlob(url)

        if (!blob) {
          throw new Error('Failed to download Blob from ' + url)
        }

        return { blob, name }
      },
      () => this.onDownloadAttachment(attachment)
    )
  }

  onBulkDownloadMedia = async (ids: Array<string | number>) => {
    await downloadWithToast(
      async () => {
        const { data } = await AttachmentsApi.getDownloadLink(ids)

        const name =
          ids.length === 1
            ? this._allAttachments.get(ids[0])?.name || 'attachment'
            : 'Salesmsg_' + uiStore.dayjs(new Date()).format(DayjsFormats.downloadFile)
        const blob = await getBlob(data.link)

        if (!blob) {
          throw new Error('Failed to download Blob from ' + data.link)
        }

        return { blob, name }
      },
      () => this.onBulkDownloadMedia(ids)
    )
  }

  handleSelectContentType = (tab: ITabItem<IMediaLibraryType>) => {
    this._contentType = tab.key
    this._page = 1
    this.setActiveTabAttachments()
  }

  getAttachmentsByType = (type: IMediaLibraryType): Attachment[] => {
    return Array.from(this._allAttachments.values()).filter((attachment) => {
      switch (type) {
        case 'images':
          return (
            attachment.type === 'image' ||
            attachment.type === 'gif' ||
            attachment.type === 'previewGif'
          )
        case 'videos':
          return attachment.type === 'video'
        case 'audio':
          return attachment.type === 'audio' || attachment.type === 'voice'
        case 'files':
          return attachment.type === 'attachment' || attachment.type === 'vcard'
        case 'all':
        default:
          return true
      }
    })
  }

  setActiveTabAttachments = () => {
    this._activeTabAttachments = this.getAttachmentsByType(this._contentType).sort(
      sortAttachmentsByDate
    )
  }

  setVisibleColumnsIds = (ids: string[]) => {
    this._visibleColumnsIds = ids
  }

  toggleViewType = () => {
    if (this._viewType === 'grid') {
      this._viewType = 'list'
      this.logMediaLibraryEvent('Switch to List view')
    } else {
      this._viewType = 'grid'
      this.logMediaLibraryEvent('Switch to Grid view')
    }
  }

  toggleAttachmentSelection = (id: string | number, skipLog = false) => {
    if (this.selectedAttachments.has(id)) {
      this.selectedAttachments.delete(id)
    } else {
      const attachment = this._allAttachments.get(id)
      if (attachment) {
        this.selectedAttachments.set(id, attachment)
        if (!skipLog) {
          this.logMediaLibraryEvent('File Selected')
        }
      }
    }
  }

  toggleAllSelected = () => {
    if (this.isAllSelected) {
      this.selectedAttachments.clear()
      this.tableStore.resetSelected()
    } else {
      this.tableStore.selectAllOnPageIds()
      this._activeTabAttachments.forEach((attachment: Attachment) => {
        this.selectedAttachments.set(attachment.id, attachment)
      })
      this.logMediaLibraryEvent('File Selected')
    }
  }

  setSelectedIdsFromTable = (ids: Array<string | number>) => {
    const newIdsSet = new Set(ids)
    const idsToSelect = ids.filter((id) => !this.selectedAttachments.has(id))
    const idsToUnselect = [...this.selectedAttachments.keys()].filter((id) => !newIdsSet.has(id))

    const allChanges = [...idsToSelect, ...idsToUnselect]

    allChanges.forEach((id) => this.toggleAttachmentSelection(id, true))
    if (idsToSelect.length > 0) {
      this.logMediaLibraryEvent('File Selected')
    }
  }

  setSearchTerm = (searchTerm: string) => {
    this.searchTerm = searchTerm
  }

  isSelected = (id: string | number) => {
    return this.selectedAttachments.has(id)
  }

  loadNextPage = () => {
    this._page = this._page + 1
  }

  fetchAttachmentList = async (searchTerm?: string) => {
    try {
      runInAction(() => {
        this._isAttachmentLoading = true
      })

      const attachmentFetchType = getAttachmentFetchTypeByContentType(this._contentType)
      const { data } = await AttachmentsApi.list(
        this._page,
        this._perPage,
        attachmentFetchType,
        searchTerm
      )

      if (searchTerm) {
        this.logMediaLibraryEvent('Search')
      }

      runInAction(() => {
        const allAttachmentsArray: [string | number, Attachment][] = [
          ...data.attachments.image.map((attachment): [string | number, Attachment] => [
            attachment.id,
            new Attachment({ responseMedia: attachment }),
          ]),
          ...data.attachments.video.map((attachment): [string | number, Attachment] => [
            attachment.id,
            new Attachment({ responseMedia: attachment }),
          ]),
          ...data.attachments.audio.map((attachment): [string | number, Attachment] => [
            attachment.id,
            new Attachment({ responseMedia: attachment }),
          ]),
          ...data.attachments.docs.map((attachment): [string | number, Attachment] => {
            return [attachment.id, new Attachment({ responseMedia: attachment })]
          }),
        ]

        if (this._page === 1) {
          this._allAttachments = new Map(allAttachmentsArray)
        } else {
          this._allAttachments = new Map([...this._allAttachments, ...allAttachmentsArray])
        }

        this.spaceUsed = data.space_used
        this.totalAttachments = data.total
        this.setActiveTabAttachments()
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._isAttachmentLoading = false
      })
    }
  }

  setLimitSizeError = ({ label, limit }: ILimitSizeError) => {
    if (this.limitSizeErrors.has(label)) return

    this.limitSizeErrors.set(label, limit)
    setTimeout(() => {
      runInAction(() => {
        this.limitSizeErrors.delete(label)
      })
    }, 5000)
  }

  deleteLimitSizeError = (id: string) => {
    this.limitSizeErrors.delete(id)
  }

  setError = (extError: string, titleExtError?: string) => {
    this.error = extError
    this.errorTitle = titleExtError || ''
    setTimeout(() => {
      runInAction(() => {
        this.error = ''
        this.errorTitle = ''
      })
    }, 5000)
  }

  addAttachmentViaUrl = (url: string) => {
    const isUrl = isLink(url)

    const enableText = 'Unable to upload the file'
    const enterValidText = 'Please enter a valid file URL'

    if (!isUrl) {
      this.setError(enterValidText, enableText)
      return
    }

    const extension = url.split('.').pop()

    if (!isPicsnippetsUrl(url) && extension && extension.length > 4) {
      this.setError(enterValidText, enableText)

      return
    }

    const isValidUrl = isMediaUrl(url) || isPicsnippetsUrl(url)

    if (!isValidUrl) {
      this.setError('Please upload the correct file format (JPG, PNG, GIF, VCF)', enableText)
      return
    }

    const attachment = new Attachment({ imageUrl: url, id: url + nanoid(), fromMediaUrl: true })

    if (attachment) {
      this._onAddAttachment(attachment)
      this.onMediaLibraryModalClose()
    }
  }

  addAttachment = (attachment: Attachment, updated = false) => {
    this._allAttachments.set(attachment.id, attachment)
    this.setActiveTabAttachments()

    if (updated) {
      if (this._uploadInProgressIds.has(attachment.id)) {
        this._uploadInProgressIds.delete(attachment.id)
        this._updatedTabs.add(getMediaLibraryTypeByAttachmentType(attachment.type))
      }
    } else {
      this._uploadInProgressIds.add(attachment.id)
      this._uploadedCount++
      return
    }

    if (this._uploadInProgressIds.size === 0 && this._uploadedCount !== 0) {
      const toastMessage =
        this._contentType !== 'all' &&
        (!this._updatedTabs.has(this._contentType) || this._updatedTabs.size > 1)
          ? `${this._uploadedCount} file${
              this._uploadedCount > 1 ? 's' : ''
            } uploaded to different tabs`
          : `${this._uploadedCount} file${this._uploadedCount > 1 ? 's' : ''} uploaded`
      this._uploadedCount = 0
      this._updatedTabs.clear()

      toastStore.add({
        type: 'success',
        title: toastMessage,
      })

      this.logMediaLibraryEvent('File Uploaded')
    }
  }

  addFilesToLibrary = async (files: File[]) => {
    if (this.searchTerm) {
      this.isSearchResettingForUpload = true

      await this.fetchAttachmentList()

      this.isSearchResettingForUpload = false
    }
    if (this.isAircallInbox && !featureFlagsStore.aircall_new_api) {
      const isValidAircallUpload = files.every((file) => {
        const fileType = file.type
        const fileExtension = file.name.split('.').pop()?.toLowerCase()

        return (
          FilesAccept.includes(fileType) ||
          VideoAccept.includes(fileType) ||
          VideoAccept.includes(`.${fileExtension}`)
        )
      })

      if (!isValidAircallUpload) {
        toastStore.add({
          type: 'error',
          title: 'One or more files are not allowed to be uploaded.',
        })
        return
      }
    }
    await attachmentStore.addMediaFiles({
      files,
      addAttachment: this.addAttachment,
      setExtError: this.setError,
      setLimitSizeError: this.setLimitSizeError,
    })
  }

  logMediaLibraryEvent = (eventName: string) => {
    eventLogStore.logEvent(
      'Media Library Used',
      {
        event_id: 'media_library_used',
        action: eventName,
      },
      { groupId: organizationStore.id }
    )
  }

  get selectedViewType() {
    return this._viewType
  }

  get visibleColumnsIds() {
    return this._visibleColumnsIds
  }

  get selectedContentType() {
    return this._contentType
  }

  get isActiveTabEmpty() {
    return this._activeTabAttachments.length === 0
  }

  get activeTabAttachments() {
    if (this.isAircallInbox && !featureFlagsStore.aircall_new_api) {
      return this._activeTabAttachments.filter((attachment) =>
        ['video', 'attachment'].includes(attachment.type)
      )
    }
    return this._activeTabAttachments
  }

  get isAllSelected() {
    return this._activeTabAttachments.every((attachment) =>
      this.selectedAttachments.has(attachment.id)
    )
  }

  get isAnyChecked() {
    return this.selectedAttachments.size > 0
  }

  get isIndeterminate() {
    return this.selectedAttachments.size > 0 && !this.isAllSelected
  }

  get allAttachments() {
    return this._allAttachments
  }

  get allAttachmentsIds() {
    return Array.from(this._allAttachments.keys())
  }

  get isAnyFileWaitingForUpload() {
    return this._uploadInProgressIds.size > 0
  }

  get currentPage() {
    return this._page
  }

  get isAttachmentInitListLoading() {
    return this.currentPage === 1 && this._isAttachmentLoading
  }

  get isLoadingMore() {
    return this.currentPage > 1 && this._isAttachmentLoading
  }

  get hasMore() {
    return this.totalAttachments && this.totalAttachments > this._page * this._perPage
  }

  get isRinglessSelectVariant() {
    return this.mediaLibraryVariant === MediaLibraryVariant.RinglessSelect
  }

  get isSingleSelectMode() {
    return this.selectMode === MediaLibrarySelectMode.Single
  }
}
