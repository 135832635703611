import { ReactNode } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Alert, Button, Typography } from 'shared/ui'
import type { ComplianceServiceCampaign } from 'entities/Compliance/model'
import { ModalHeader } from 'pages/settings/pages/compliance/ui/ModalHeader/ModalHeader'
import { ModalInfoBlock } from 'pages/settings/pages/compliance/ui/ModalInfoBlock/ModalInfoBlock'
import type { IModalInfoRowProps } from 'pages/settings/pages/compliance/ui/ModalInfoRow/ModalInfoRow'
import styles from './styles.module.scss'
import { UseCaseModalTabs } from '../UseCaseModalTabs/UseCaseModalTabs'

type IUseCaseModalContentProps = {
  serviceCampaign: ComplianceServiceCampaign
  statusData: ReactNode
  updateModalAction: () => void
  learnMoreModalAction: () => void
  openImageModal: (urls: string, name: string) => void
}

export const UseCaseModalContent = observer(
  ({
    serviceCampaign,
    statusData,
    updateModalAction,
    learnMoreModalAction,
    openImageModal,
  }: IUseCaseModalContentProps) => {
    const { registrationError, data } = serviceCampaign
    const { useCaseData, sampleMessagesData } = data || {}

    const lines: IModalInfoRowProps[] = [
      {
        label: 'Use case',
        value: useCaseData?.useCaseTitle || '',
      },
      {
        label: 'Description',
        value: useCaseData?.description || '',
      },
      {
        label: 'Sample message 1',
        value: sampleMessagesData?.sampleMessage1 || '',
      },
      {
        label: 'Sample message 2',
        value: sampleMessagesData?.sampleMessage2 || '',
      },
    ]

    return (
      <div className={styles.wrap}>
        <ModalHeader header={useCaseData?.useCaseTitle || ''} statusData={statusData} />

        <div
          className={classNames({
            [styles.topBorder]: !registrationError,
          })}
        >
          {registrationError && (
            <Alert
              item={{
                type: 'error',
                desc: registrationError.message,
                title: 'Your use case campaign application was rejected.',
              }}
              actions={
                <>
                  <Button
                    text={'Update'}
                    typeBtn={'outlined'}
                    size={'small'}
                    contained={'secondary'}
                    onClick={updateModalAction}
                  />
                  <Button
                    text={'Learn more'}
                    typeBtn={'text'}
                    size={'small'}
                    contained={'secondary'}
                    onClick={learnMoreModalAction}
                  />
                </>
              }
            />
          )}
          <div className={styles.divider} />

          <div className={styles.generalInfo}>
            <Typography ariaLabel='GeneralInfo' variant='body-rg-medium' className={styles.title}>
              General info
            </Typography>
            <ModalInfoBlock lines={lines} />
          </div>

          {!!serviceCampaign.optInData?.length && (
            <div className={styles.optInMethod}>
              <Typography
                ariaLabel='OptInMethod'
                variant='body-rg-medium'
                className={classNames(styles.title, styles.optInMethodTitle)}
              >
                Opt-in method
              </Typography>

              <UseCaseModalTabs
                optInData={serviceCampaign.optInData}
                getOptInDataByMethod={serviceCampaign.getOptInDataByMethod}
                openImageModal={openImageModal}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
)
