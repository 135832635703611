import { makeAutoObservable, runInAction } from 'mobx'
import { Icon, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { numbersStore } from 'entities/Phone'
import { featureFlagsStore } from 'entities/FeatureFlags'
import type { CompliancePageStore } from 'pages/settings/pages/compliance/store/compliancePageStore'
import type { IRegisterSectionProps } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import styles from 'pages/settings/pages/compliance/ui/styles.module.scss'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'

export class TollFreePageStore {
  private _loading = false

  constructor(private _compliancePageStore: CompliancePageStore) {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([window.publicApiVue?.fetchNumbers()])

    runInAction(() => {
      this._loading = false
    })
  }

  get disabled() {
    return this._loading
  }

  get businessProfile() {
    return this._compliancePageStore.complianceLayerStore.organizationBusinessProfile
      ?.businessProfile
  }

  get tfRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'Toll-free verification',
      titleType: 'body-lg-medium',
      desc: (
        <Typography
          ariaLabel='Toll-free verification_desc'
          variant='body-md-regular'
          tag='div'
          className={styles.tollFreeVerificationDesc}
        >
          Required for sending SMS <Icon icon='arrowRight' className={styles.arrow} /> to
          <Icon className={styles.flag} icon='flagUSA' />
          US and <Icon className={styles.flag} icon='flagCanada' /> Canada.
        </Typography>
      ),
      learnMoreLink: links.complianceTollFreeLearnMoreLink,
      learnMoreLinkClassName: 'newLineLink',
      actionButton: {
        text: this.isTollFreeDataExist ? 'Add use case' : 'Register',
        disabled: this.disabled,
        onClick: () => {
          if (usersStore.user?.isRequireNumber) {
            numbersStore.initSelectNumberModal()
            return
          }

          if (featureFlagsStore.salesmsg_design_v2_compliance_toll_free) {
            uiStore.changeRouteViaVue({
              path: CompliancePath.compliance.tollFree.steps,
            })
          } else {
            window.publicApiVue?.onCheckToolFreeNumbers()
          }
        },
        ...(this.isTollFreeDataExist
          ? {
              contained: 'secondary',
              icon: 'add',
            }
          : null),
      },
      isSecondStep: this.isTollFreeDataExist,
    }
  }

  get complianceLayerStore() {
    return this._compliancePageStore.complianceLayerStore
  }

  get organizationTollFree() {
    return this._compliancePageStore.complianceLayerStore.organizationTollFree
  }

  get tollFreeProfileItems() {
    if (!this.organizationTollFree) return []

    return this.organizationTollFree.items
  }

  get isTollFreeDataExist() {
    return Boolean(this.tollFreeProfileItems.length)
  }

  get tollFreeDataStatus() {
    if (this.complianceLayerStore.tollFreeUnverifiedNumbers.length > 0) return 'alert'
    if (this.tollFreeProfileItems.length === 0) return

    if (this.organizationTollFree?.isStatusBlocked) return 'failed'
    if (this.organizationTollFree?.isStatusRejected) return 'failed'
    if (this.organizationTollFree?.isStatusPendingReview) return 'alert'
    if (this.organizationTollFree?.isStatusInCarrierReview) return 'alert'
    if (this.organizationTollFree?.isStatusInUnderReview) return 'under_review'
    if (this.organizationTollFree?.isStatusVerified) return 'approved'

    return
  }
}
