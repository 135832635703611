import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { contactsStore } from 'entities/Contacts'
import { IResponseContact } from 'entities/Contacts/api/types'

const ContactUpdated = {
  handle: (data: IResponseContact) => contactsStore.updateItem(data),
}

export const EventContactUpdated = {
  subscribe: () => websocket.on(WebsocketEvent.ContactUpdated, ContactUpdated.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.ContactUpdated, ContactUpdated.handle),
}
