import type { AxiosResponse } from 'axios'
import type { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type { IResponseFilters } from 'entities/Contacts/api/filterTypes'
import type {
  IParamsGetKeywords,
  IResponseGetKeywords,
  IParamsKeyword,
  IResponseKeyword,
  IParamsGetContacts,
  IResponseGetKeywordContactsStatistic,
} from './type'

// TODO: move to BE
const normalizeFiltersResponse = (response: AxiosResponse<IResponseFilters>) => {
  response.data.filters = response.data.filters.map((filter) => {
    if (filter.key === 'keywords.is_active') {
      filter.values = [
        { key: 'active', label: 'Active' },
        { key: 'disabled', label: 'Disabled' },
      ]
    }
    return filter
  })

  return response
}
class Api {
  getKeywords(
    params: IParamsGetKeywords,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetKeywords>> {
    return baseApi.post('keywords/all', params, { ...options })
  }

  getFilters(): Promise<AxiosResponse<IResponseFilters>> {
    return baseApi.get('keywords/filters').then(normalizeFiltersResponse)
  }

  createKeyword(params: IParamsKeyword): Promise<AxiosResponse<IResponseKeyword>> {
    return baseApi.post('keywords', params)
  }

  toggleKeywordStatus(id: number): Promise<AxiosResponse<IResponseKeyword>> {
    return baseApi.put(`keywords/toggle/${id}`)
  }

  getKeywordById(id: string): Promise<AxiosResponse<IResponseKeyword>> {
    return baseApi.get(`keywords/${id}`)
  }

  updateKeyword(params: IParamsKeyword): Promise<AxiosResponse<IResponseKeyword>> {
    return baseApi.put(`keywords/${params.id}`, params)
  }

  deleteKeyword(id: number): Promise<void> {
    return baseApi.delete(`keywords/${id}`)
  }

  deleteBulkKeywords(ids: number[], bulkAll: boolean): Promise<AxiosResponse> {
    return baseApi.delete('keywords', { params: { ids, bulk_all: bulkAll } })
  }

  getKeywordContacts(
    id: number,
    params: IParamsGetContacts
  ): Promise<AxiosResponse<IResponseGetKeywordContactsStatistic>> {
    return baseApi.get(`keywords/${id}/contacts`, {
      params,
    })
  }
}

export const KeywordsApi = new Api()
