import { DetailsKeywordBody, DetailsKeywordHeader } from './Details'
import styles from './styles.module.scss'

export const DetailsKeywordModalContent = () => {
  return (
    <div className={styles.wrap}>
      <DetailsKeywordHeader />
      <DetailsKeywordBody />
    </div>
  )
}
