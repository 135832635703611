import React, { useLayoutEffect, useRef } from 'react'
import { Icon } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { CardInfo } from './CardInfo'
import { IMultiselectedItem } from './type'
import styles from './styles.module.scss'

type IMultiSelectCardProps = {
  selectedItems: IMultiselectedItem[]
  onDelete?: (item: IMultiselectedItem) => void
  onChangeHeight: (height: number) => void
}

export const SelectCard = ({ selectedItems, onDelete, onChangeHeight }: IMultiSelectCardProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (ref.current) {
      onChangeHeight?.(ref.current.clientHeight)
    }

    return () => {
      onChangeHeight?.(0)
    }
  }, [selectedItems.length])

  return (
    <div className={styles.wrapper} ref={ref}>
      {selectedItems.map((selectedItem) => (
        <div
          className={styles.selectedItem}
          key={selectedItem.id}
          aria-label={getAriaLabel('MultiSelect', 'selectedItem')}
        >
          <CardInfo selectedItem={selectedItem} />
          <div className={styles.closeIcon} onClick={() => onDelete?.(selectedItem)}>
            <Icon icon='close' />
          </div>
        </div>
      ))}
    </div>
  )
}
