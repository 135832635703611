import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseConversation } from 'entities/Conversation/api/types'

const ConversationStateChanged = {
  handle: (data: IResponseConversation) => {
    conversationStore.updateItem(data)
  },
}

export const EventConversationStateChanged = {
  subscribe: () =>
    websocket.on(WebsocketEvent.ConversationStateChanged, ConversationStateChanged.handle),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.ConversationStateChanged, ConversationStateChanged.handle),
}

interface IConversationState {
  id: number
  locked: boolean
}

export const subscribeConversationStateChange = (callback: (state: IConversationState) => void) =>
  websocket.on(WebsocketEvent.ConversationStateChanged, (conversation) => {
    const id = conversation.id
    const locked = conversation.is_locked

    if (locked != null) callback({ id, locked })
  })
