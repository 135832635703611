import { useEffect } from 'react'
import { type IResponseKnowledgeBase, EventKnowledgeBaseUpdated } from 'entities/KnowledgeBase'

export const useChatbotKnowledgeBaseUpdate = (
  updateKnowledgeBase: (knowledgeBaseResponse: IResponseKnowledgeBase) => void,
  rag?: boolean
) => {
  useEffect(() => {
    if (!rag) return

    EventKnowledgeBaseUpdated.subscribe(updateKnowledgeBase)

    return () => {
      EventKnowledgeBaseUpdated.unsubscribe(updateKnowledgeBase)
    }
  }, [rag, updateKnowledgeBase])
}
