import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Alert } from 'shared/ui'
import type { KnowledgeBase } from 'entities/KnowledgeBase'

interface KnowledgeBaseStatusAlertProps {
  knowledgeBase: KnowledgeBase
}

export const KnowledgeBaseIndexationStatusAlert = observer(
  ({ knowledgeBase }: KnowledgeBaseStatusAlertProps) => {
    const [showActiveAlert, setShowActiveAlert] = useState(false)
    const [wasProcessing, setWasProcessing] = useState(false)

    useEffect(() => {
      if (knowledgeBase.isProcessingStatus) {
        setWasProcessing(true)
      }
      if (knowledgeBase.isActiveStatus && wasProcessing) {
        setShowActiveAlert(true)

        const timer = setTimeout(() => {
          setShowActiveAlert(false)
          setWasProcessing(false)
        }, 5000)

        return () => clearTimeout(timer)
      }
    }, [knowledgeBase.isProcessingStatus, knowledgeBase.isActiveStatus, wasProcessing])

    if (!knowledgeBase || knowledgeBase.isInitStatus) {
      return null
    }

    if (knowledgeBase.isProcessingStatus) {
      return (
        <Alert
          item={{
            type: 'infoBlue',
            desc: 'Processing your documents. They’ll be ready for your textbot soon.',
          }}
        />
      )
    }

    if (knowledgeBase.isActiveStatus && showActiveAlert) {
      return (
        <Alert
          item={{
            type: 'success',
            desc: 'Your knowledge base is ready to use',
          }}
          onClose={() => setShowActiveAlert(false)}
        />
      )
    }

    return null
  }
)
