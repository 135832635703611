import { INameInputUiProps, NameInputUi } from 'features/createOrEdit/NameInput/ui/NameInputUi'
import { NameInputContext } from 'features/createOrEdit/NameInput/context/context'
import { NameInputStore } from 'features/createOrEdit/NameInput/store/nameInputStore'

export type INameInputProps = {
  store: NameInputStore
} & INameInputUiProps

export const NameInput = ({ store, ...props }: INameInputProps) => {
  return (
    <NameInputContext.Provider value={store}>
      <NameInputUi {...props} />
    </NameInputContext.Provider>
  )
}
