import { IStatusProps, Status, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IWidgetStatusTriggerProps<T> = {
  items: T[]
  getConfigByStatus: (status: T) => IStatusProps
}

export const WidgetStatusTrigger = <T,>({
  items,
  getConfigByStatus,
}: IWidgetStatusTriggerProps<T>) => {
  if (!items.length) return <>select</>

  return (
    <div className={styles.box}>
      <div className={styles.group}>
        {items.map((status) => {
          return (
            <Status
              key={status as string}
              {...getConfigByStatus(status)}
              emphasis={'low'}
              onlyIcon
            />
          )
        })}
      </div>
      <Typography ariaLabel='TriggerStatus' variant='body-md-regular'>
        {items.length > 1 ? `${items.length} statuses` : getConfigByStatus(items[0]).title}
      </Typography>
    </div>
  )
}
