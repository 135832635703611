import { Checkbox, Typography, Grid } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { RolloverPeriod } from 'entities/Admin/customPrices/api/type'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'

type RolloverCreditsProps = {
  disabled?: boolean
  isRollover: boolean
  rolloverPercent: number | null
  rolloverPeriod: RolloverPeriod | null
  setIsRollover?: (rollover: boolean) => void
  setRolloverPeriod?: (rolloverPeriod: RolloverPeriod | null) => void
  setRolloverPercent?: (rolloverPercent: number | null) => void
}

export const RolloverForm = ({
  isRollover,
  rolloverPeriod,
  setRolloverPeriod = () => {},
  setIsRollover = () => {},
  setRolloverPercent = () => {},
  rolloverPercent,
  disabled = false,
}: RolloverCreditsProps) => {
  return (
    <Grid container spacing={3} mb={1} mt={1}>
      <Grid item xs={12} display='flex' alignItems='center' gap={1}>
        <Checkbox checked={isRollover} disabled={disabled} onChange={setIsRollover} />
        <Typography
          ariaLabel={'Rollover'}
          variant={'text-input-field'}
          color={disabled ? 'var(--content-primary-disabled)' : 'var(--content-primary-tertiary)'}
        >
          Rollover credits
        </Typography>
      </Grid>
      {isRollover && (
        <>
          <Grid item xs={6}>
            <SelectField
              selectProps={{
                dropdownProps: { disabled, ariaLabel: 'Rollover credits' },
              }}
              btnProps={{
                fullWidth: true,
                typeBtn: 'stroke',
                size: 'medium',
                disabled: disabled,
                justifyContent: 'space-between',
                fontWeight: disabled ? 400 : undefined,
                style: {
                  color: disabled ? 'var(--content-neutral-primary)' : undefined,
                },
              }}
              labelText={'Period'}
              items={AdminCustomPricesForm.rolloverPeriodItems}
              value={rolloverPeriod}
              setValue={setRolloverPeriod}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              selectProps={{
                dropdownProps: { disabled, ariaLabel: 'Rollover percent' },
              }}
              btnProps={{
                fullWidth: true,
                typeBtn: 'stroke',
                size: 'medium',
                disabled: disabled,
                justifyContent: 'space-between',
                fontWeight: disabled ? 400 : undefined,
                style: {
                  color: disabled ? 'var(--content-neutral-primary)' : undefined,
                },
              }}
              labelText={'% of credits carried over'}
              items={AdminCustomPricesForm.rolloverPercentItems}
              value={rolloverPercent}
              setValue={setRolloverPercent}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
