import { observer } from 'mobx-react-lite'
import React from 'react'
import classNames from 'classnames'
import {
  Alert,
  ButtonCopy,
  Divider,
  EnumSpinnerLoaderPlacement,
  Icon,
  SpinnerLoader,
  Switch,
  Tooltip,
  Typography,
} from 'shared/ui'
import { makeTextFieldElement, TextFieldPlaceholder } from 'shared/ui/TextField'
import { Select } from 'shared/ui/Select/Select'
import type { IntegrationWebhookStore } from 'pages/settings/pages/integrations/store/IntegrationWebhookStore'
import styles from './styles.module.scss'

export const IntegrationWebhookModalContent = observer(
  ({ integrationWebhookStore }: { integrationWebhookStore: IntegrationWebhookStore }) => {
    const {
      webhookUrl,
      integrationName,
      sendFromLeadOwner,
      handleSendFromLeadOwnerToggle,
      canSendFromLeadOwner,
      phoneFieldsItems,
      handleSelectedPhoneFieldChange,
      selectedPhoneField,
      fieldsLoading,
    } = integrationWebhookStore

    return (
      <div className={styles.modalContent}>
        <Alert
          item={{
            type: 'infoBlue',
            title: `Use this Webhook URL on ${integrationName} to send Contacts into your Salesmsg account. This will:`,
            desc: (
              <ul className={styles.alertList}>
                <li>
                  <Typography
                    ariaLabel={'IntegrationWebhookCreate'}
                    variant={'body-md-regular'}
                    color={'var(--light-blue-100)'}
                  >
                    Create a contact on your account if one doesn’t exist
                  </Typography>
                </li>
                <li>
                  <Typography
                    ariaLabel={'IntegrationWebhookLink'}
                    variant={'body-md-regular'}
                    color={'var(--light-blue-100)'}
                  >
                    Link the Contact to it’s counterpart on {integrationName}
                  </Typography>
                </li>
              </ul>
            ),
          }}
        />
        <div className={styles.configuration}>
          {canSendFromLeadOwner && (
            <>
              <div className={classNames(styles.section, styles.leadOwner)}>
                <Typography
                  ariaLabel={'KnowledgeBaseLabel'}
                  variant={'body-md-medium'}
                  className={styles.sectionLabel}
                >
                  Create Contact on Lead Owner
                  <Tooltip
                    marginLeft={4}
                    breakWord
                    placement='top'
                    label={`You can choose to have this Contact assigned to it's owner on ${integrationName} by selecting Lead Owner toggle below. Otherwise the Contact will be assigned to you.`}
                  >
                    <Icon icon='question' color='var(--content-neutral-primary)' />
                  </Tooltip>
                </Typography>
                <Switch value={sendFromLeadOwner} onChange={handleSendFromLeadOwnerToggle} />
              </div>
              <Divider style={{ margin: '7.5px 0' }} />
            </>
          )}
          <div className={classNames(styles.section, styles.phoneField)}>
            <Typography
              ariaLabel={'KnowledgeBaseLabel'}
              variant={'body-md-medium'}
              className={styles.sectionLabel}
            >
              Phone field
              <Tooltip
                marginLeft={4}
                placement='top'
                breakWord
                label={
                  'By default we have selected the standard phone number field. If you want this Webhook URL to use a different field, select a new one from the dropdown before copying the URL.'
                }
              >
                <Icon icon='question' color='var(--content-neutral-primary)' />
              </Tooltip>
            </Typography>
            <div className={styles.selectContainer}>
              {fieldsLoading ? (
                <SpinnerLoader size={16} placement={EnumSpinnerLoaderPlacement.Right} />
              ) : (
                <Select
                  variant='stroke'
                  items={phoneFieldsItems}
                  value={selectedPhoneField}
                  onSelectValue={handleSelectedPhoneFieldChange}
                  dropdownProps={{
                    withSearch: false,
                  }}
                  textFieldPlaceholderProps={{
                    size: 'medium',
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <TextFieldPlaceholder
          className={styles.linkContainer}
          size={'medium'}
          variant={'stroke'}
          placeholder={
            <Tooltip placement='top' label={webhookUrl} fullWidth>
              <Typography
                className={styles.linkText}
                tag='div'
                ariaLabel='WebhookUrl'
                variant='text-input-field'
                color='var(--content-primary-primary)'
              >
                {webhookUrl}
              </Typography>
            </Tooltip>
          }
          rightActions={[
            makeTextFieldElement({
              element: <ButtonCopy value={webhookUrl} tag='div' show />,
            }),
          ]}
        />
      </div>
    )
  }
)
