import classnames from 'classnames'
import { CopyWrapper } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseInfusionsoftInfoCompany,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import styles from '../styles.module.scss'

type ICompaniesProps = {
  company: IResponseInfusionsoftInfoCompany | null
  contact: Contact
}

const Empty = () => <div className={styles.emptyData}>No company found</div>

const Company = ({ company, contact }: ICompaniesProps) => {
  if (!company || Array.isArray(company)) return <Empty />

  const userIntegration = integrationsStore.userIntegrationsMap.get(IntegrationKey.infusionsoft)

  const linkProps = {
    key: IntegrationKey.infusionsoft,
    contact,
    integration: userIntegration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = integrationLink(linkProps)

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={company.company_name || null}
          data={{
            title: company.company_name || 'View company',
            link,
          }}
        />
      </div>
    </div>
  )
}

export { Company }
