import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { IconButton } from 'shared/ui'
import { PageLayoutStore } from 'shared/layout'
import { AdditionalBreakpointsEnum } from 'shared/constants/breakpoints'
import { Preview } from 'pages/campaigns/ui/CampaignView/ui/Preview/Preview'
import { useCampaignViewContext } from 'pages/campaigns/ui/CampaignView/context/context'
import { CampaignBlocker } from 'pages/campaigns/ui/CampaignBlocker'

import { CampaignEnrollContacts } from 'pages/campaigns/ui/CampaignEnrollContacts'
import { CampaignMessages } from 'pages/campaigns/ui/CampaignMessages'
import { Header } from 'pages/campaigns/ui/CampaignView/ui/Header/Header'
import { Actions } from 'pages/campaigns/ui/CampaignView/ui/Actions/Actions'
import { MobilePreview } from 'pages/campaigns/ui/CampaignView/ui/MobilePreview/MobilePreview'
import styles from './styles.module.scss'

export type ICampaignViewUiProps = {
  pageLayoutStore: PageLayoutStore
}

export const CampaignViewUi = observer(({ pageLayoutStore }: ICampaignViewUiProps) => {
  const { campaignBlockerStore, campaignMessagesStore, campaignEnrollContactsStore } =
    useCampaignViewContext()
  const messages = campaignMessagesStore.messages

  const {
    titleIconProps,
    handleToggleCollapse,
    headerMenuTooltipIconProps,
    setCustomCollapseWidth,
  } = pageLayoutStore

  useEffect(() => {
    setCustomCollapseWidth(AdditionalBreakpointsEnum['1280'])
    return () => {
      setCustomCollapseWidth(null)
    }
  }, [])

  return (
    <div className={styles.wrap}>
      <CampaignBlocker store={campaignBlockerStore} />
      <div className={styles.wrapCollapseBtn}>
        <IconButton
          ariaLabel={'collapseBtn'}
          iconProps={titleIconProps}
          variant={'icon'}
          color={'secondary'}
          onClick={handleToggleCollapse}
          tooltipProps={headerMenuTooltipIconProps}
        />
      </div>
      <div className={styles.wrapMobilePreviewBtn}>
        <MobilePreview />
      </div>
      <div className={styles.left}>
        <div className={styles.content}>
          <div className={styles.wrapHeader}>
            <Header />
          </div>
          <div className={styles.wrapBody}>
            <CampaignEnrollContacts store={campaignEnrollContactsStore} />
            <CampaignMessages store={campaignMessagesStore} />
          </div>
        </div>

        <div className={styles.actions}>
          <Actions />
        </div>
      </div>
      <div className={styles.right}>
        <Preview orderedMessages={messages} />
      </div>
    </div>
  )
})
