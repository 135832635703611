import { Route, Routes, Navigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import {
  BusinessProfileStepsRouter,
  BusinessProfileRoutes,
  BusinessProfileRouter,
} from 'pages/settings/pages/compliance/pages/businessProfile'
import { CompliancePage } from 'pages/settings/pages/compliance/ui/compliancePage'
import {
  QuestionnaireRouter,
  QuestionnaireRoutes,
} from 'pages/settings/pages/compliance/pages/questionnaire'
import { ComplianceLayout } from 'pages/settings/pages/compliance/layout/complianceLayout'
import { ComplianceAccessLayout } from 'pages/settings/pages/compliance/layout/complianceAccessLayout'
import { CompliancePageLayout } from 'pages/settings/pages/compliance/layout/compliancePageLayout'
import {
  TollFreeRoutes,
  TollFreeMainPageRouter,
  TollFreeRootRouter,
} from 'pages/settings/pages/compliance/pages/tollFree'
import { CallingPage, CallingRoutes } from 'pages/settings/pages/compliance/pages/calling'
import {
  LocalNumbersRouter,
  LocalNumbersRoutes,
  LocalNumbersStepsRouter,
} from 'pages/settings/pages/compliance/pages/localNumbers'
import { useComplianceLayerContext } from 'pages/settings/pages/compliance/context'
import { ViewPage } from 'pages/settings/pages/compliance/pages/tollFree/pages/View/ViewPage'

const NavigateIndex = observer(() => {
  const store = useComplianceLayerContext()

  if (store.summary?.isRequiredTollFree) return <Navigate to={TollFreeRoutes.root} />

  return <Navigate to={BusinessProfileRoutes.root} />
})

export const ComplianceRouter = observer(() => {
  return (
    <Routes>
      <Route element={<ComplianceLayout />}>
        <Route element={<ComplianceAccessLayout />}>
          <Route element={<CompliancePageLayout />}>
            <Route index element={<NavigateIndex />} />
            <Route path={'/'} element={<CompliancePage />} />
            <Route path={BusinessProfileRoutes.root} element={<BusinessProfileRouter />} />
            <Route path={LocalNumbersRoutes.root} element={<LocalNumbersRouter />} />
            <Route path={TollFreeRoutes.root} element={<TollFreeMainPageRouter />} />
            <Route path={CallingRoutes.root} element={<CallingPage />} />
            <Route path='*' element={<div>Page Not Found</div>} />
          </Route>
        </Route>

        <Route path={`${BusinessProfileRoutes.root}/*`} element={<BusinessProfileStepsRouter />} />
        <Route path={`${LocalNumbersRoutes.root}/*`} element={<LocalNumbersStepsRouter />} />
        <Route path={`${TollFreeRoutes.root}/*`} element={<TollFreeRootRouter />} />
        <Route path={`${QuestionnaireRoutes.root}/*`} element={<QuestionnaireRouter />} />
        <Route path='*' element={<div>Page Not Found</div>} />
      </Route>
    </Routes>
  )
})
