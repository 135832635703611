// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R3OVbBcyQbKQsaYkzhg0{position:relative;display:flex;flex-grow:1;margin-left:16px}.LUlEF3vXytiH3H8Ul287{border-radius:6px;background:var(--black-10);padding:4px 8px}.wcd2Xog3Eoa_zcAwAFBy{display:flex}", "",{"version":3,"sources":["webpack://./src/pages/keywords/pages/all/ui/KeywordsTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,iBAAA,CACA,0BAAA,CACA,eAAA,CAGF,sBACE,YAAA","sourcesContent":[".wrap {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  margin-left: 16px;\n}\n\n.keywordName {\n  border-radius: 6px;\n  background: var(--black-10);\n  padding: 4px 8px;\n}\n\n.cellWrapper {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "R3OVbBcyQbKQsaYkzhg0",
	"keywordName": "LUlEF3vXytiH3H8Ul287",
	"cellWrapper": "wcd2Xog3Eoa_zcAwAFBy"
};
export default ___CSS_LOADER_EXPORT___;
