import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { type IRow, Table, variantActionsProps } from 'shared/ui'
import { Campaign } from 'entities/Campaign'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { useCampaignsListContext } from 'pages/campaigns/ui/CampaignsList/context/context'
import { CampaignRoutes } from 'pages/campaigns/types'
// import { getCampaignActions } from 'pages/campaigns/actions'
import { getColumns } from './getColumns'
import styles from './styles.module.scss'

export const CampaignsTable = observer(() => {
  const {
    tableStore,
    items,
    startPaginationSize,
    limit,
    page,
    onPaginationModelChange,
    total,
    loading,
    // onSuccessDelete,
  } = useCampaignsListContext()

  const getRowLink = (row: IRow<Campaign>) =>
    `/${CampaignRoutes.root}/${
      row.status === 'draft' ? CampaignRoutes.edit : CampaignRoutes.view
    }/${row.id}`

  const rows = useMemo(() => {
    return items.map((item) => {
      item.setActionsProps({
        ...variantActionsProps.table,
        // TODO add actions
        // actions: getCampaignActions(item, () => onSuccessDelete(item.id)),
        actions: [],
      })
      return item
    })
  }, [items])

  const columns = useMemo(getColumns, [])

  if (!rows.length) {
    return <NoResultsFound />
  }

  return (
    <div className={styles.wrap}>
      <Table
        pinnedColumnNames={['name']}
        store={tableStore}
        columns={columns}
        rows={rows}
        getRowLink={getRowLink}
        rowsCount={total}
        isLoading={loading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        limit={limit}
        startPaginationSize={startPaginationSize}
        sxTable={{ marginRight: '10px', width: 'calc(100% - 10px)' }}
      />
    </div>
  )
})
