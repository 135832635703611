import classNames from 'classnames'
import { FC, useEffect } from 'react'
import { Icon, Tooltip, Dropdown } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { initCanny } from 'entities/Canny'
import { commonTooltipProps } from '../../constants/menuItems'

import styles from './styles.module.scss'

const ProductUpdatesDropdown: FC = () => {
  useEffect(() => {
    // We should delete this init when the 1.0 version will be deleted
    // This fix problem when we toggling 1.0 to 2.0 version and can't open frame with Canny
    initCanny()
  }, [])

  return (
    <Dropdown
      ariaLabel={'ProductUpdatesDropdown'}
      width={186}
      icon={'question'}
      placement={'right-end'}
      variantTriggerButton={'icon'}
      triggerComponent={(open) => (
        <Tooltip label={'Product updates'} {...commonTooltipProps}>
          <div
            className={classNames(styles.button, {
              [styles.active]: open,
            })}
            data-canny-changelog
            aria-label={getAriaLabel('ProductUpdatesDropdownTrigger')}
          >
            <Icon icon={'announcement'} fontSize={20} />
          </div>
        </Tooltip>
      )}
      customComponent={() => <></>}
    />
  )
}

export { ProductUpdatesDropdown }
