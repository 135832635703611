import React, { FC } from 'react'
import { TestSMSBtn } from 'widgets/TestSMS/ui/TestSMSBtn/TestSMSBtn'
import { SendTestModalStore } from 'widgets/TestSMS/ui/TestSmsWithMergeField/store/sendTestModalStore'
import type { ITestSMSDefaultProps } from 'widgets/TestSMS/ui/TestSmsDefault/TestSMSDefault'

type ITestSmsWithMergeFieldProps = ITestSMSDefaultProps & {
  mergeFieldsTemplates: string[]
  sendTestModalStore: SendTestModalStore
}

export const TestSmsWithMergeField: FC<ITestSmsWithMergeFieldProps> = ({
  disabled,
  sendTestModalStore,
  ...props
}) => {
  const onClick = () => {
    sendTestModalStore.openModal(props)
  }
  return <TestSMSBtn onClick={onClick} disabled={disabled} />
}
