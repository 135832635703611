import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useRef } from 'react'
import classNames from 'classnames'
import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import { ExtendedFilesAccept } from 'shared/constants/accept'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'
import styles from './styles.module.scss'

export const KnowledgeBaseActions = observer(() => {
  const {
    documentsStore: { isEmpty, onUploadDocuments },
    isUploadDisabled,
  } = useKnowledgeBaseViewContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.click()
    }
  }

  const onInputFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files) {
      void onUploadDocuments(Array.from(files))
    }
  }

  return (
    <div
      className={classNames(styles.actionsContainer, {
        [styles.emptyView]: isEmpty,
      })}
    >
      <Button
        tag='a'
        text='Help guide'
        size='medium'
        contained='secondary'
        iconProps={{ icon: 'info' }}
        className={styles.helpButton}
        href={links.knowledgeBaseHelpGuide}
        target='_blank'
        rel='noreferrer'
      />
      <input
        type={'file'}
        className={styles.fileInput}
        ref={inputRef}
        accept={ExtendedFilesAccept.join(',')}
        disabled={isUploadDisabled}
        multiple
        onChange={onInputFileChange}
      />
      <Button
        text='Upload files'
        iconProps={{ icon: 'upload' }}
        size='medium'
        onClick={handleUploadClick}
      />
    </div>
  )
})
