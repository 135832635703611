import { websocket } from 'entities/WebSocket'

interface IEventUserProps {
  id: number | string
}

class ChannelUser {
  privateChannelName: string
  presenceChannelName: string

  constructor(props: IEventUserProps) {
    this.privateChannelName = `private-user.${props.id}`
    this.presenceChannelName = `presence-user.${props.id}`

    this.subscribe()
  }

  subscribe() {
    websocket.subscribe(this.privateChannelName)
    websocket.subscribe(this.presenceChannelName)
  }
}

export { ChannelUser }
