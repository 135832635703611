import { observer } from 'mobx-react-lite'
import { Tabs } from 'shared/ui'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import { AutoBusinessFind } from './AutoBusinessFind'
import { ManuallyBusinessFind } from './ManuallyBusinessFind'
import styles from './styles.module.scss'

export const BusinessInfo = observer(() => {
  const { stepLoading, businessInfoStore } = useTollFreeStepsContext()
  const {
    generalInfoActiveTab,
    changeGeneralInfoTab,
    nextStepClick,
    isNextDisabled,
    isHideAutoBusinessTab,
  } = businessInfoStore

  return (
    <div className={styles.wrap}>
      <div className={styles.bannerWrap}>
        <PageDescription>Enter your business details for carrier verification</PageDescription>
      </div>

      {!isHideAutoBusinessTab && (
        <>
          <Tabs
            tabs={[
              { name: 'Find your business', key: 1 },
              { name: 'Enter manually', key: 2 },
            ]}
            activeTabKey={generalInfoActiveTab}
            handleSelectTab={(tab) => changeGeneralInfoTab(tab.key)}
            margin={'0 0 28px 0'}
          />
          {generalInfoActiveTab === 1 && <AutoBusinessFind />}
          {generalInfoActiveTab === 2 && <ManuallyBusinessFind />}
        </>
      )}

      {isHideAutoBusinessTab && <ManuallyBusinessFind />}

      <NextButton
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        text='Next'
        onClick={nextStepClick}
      />
    </div>
  )
})
