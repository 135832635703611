import { makeAutoObservable } from 'mobx'
import type { IResponseCnam } from 'entities/Compliance/api/cnamType'

export class Cnam {
  private _id: IResponseCnam['id'] | null = null
  private _name: IResponseCnam['name'] | null = null
  private _count: IResponseCnam['count'] | null = null
  private _status: IResponseCnam['status'] | null = null
  private _origin: IResponseCnam

  constructor(response: IResponseCnam) {
    makeAutoObservable(this)

    this._origin = response

    this.syncData(response)
  }

  syncData = (response: IResponseCnam) => {
    this._id = response.id
    this._name = response.name
    this._count = response.count
    this._status = response.status
    this._origin = response
  }

  get origin() {
    return this._origin
  }

  get id() {
    return this._id || 0
  }

  get name() {
    return this._name || ''
  }

  get count() {
    return this._count
  }

  get status() {
    return this._status
  }

  get isStatusPending() {
    return this._status === 'pending'
  }

  get showActions() {
    return !this.isStatusPending
  }
}
