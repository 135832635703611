import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { Divider, ActionItem, ContainerHeader, SpinnerLoader } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { CustomPriceForm } from 'pages/admin/pages/customPrices/ui/CustomPriceForm/CustomPriceForm'
import { CustomPriceSummary } from 'pages/admin/pages/customPrices/ui/CustomPriceSummary/CustomPriceSummary'
import { AdminRoutes } from 'pages/admin'
import { adminCustomPricesUpdate } from 'pages/admin/pages/customPrices/pages/CustomPricesUpdate/store/adminCustomPricesUpdate'
import { RolloverCredits } from 'pages/admin/pages/customPrices/ui/RolloverCredits/RolloverCredits'
import styles from './styles.module.scss'
import { CustomPriceLayout } from '../../../ui/CustomPriceLayout/CustomPriceLayout'

export const CustomPriceUpdate = observer(() => {
  const {
    form,
    loading,
    setId,
    updateCustomPrice,
    loadingUpdate,
    customPriceStatus,
    updateAndPublishCustomPrice,
    isSameForm,
  } = adminCustomPricesUpdate
  const { id } = useParams()

  useEffect(() => {
    setId(Number(id))
  }, [])

  const actions: ActionItem[] = [
    {
      text: 'Cancel',
      onAction: () => {
        uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.customPrices}`)
      },
      buttonProps: {
        disabled: loading,
      },
      medium: true,
    },
  ]

  if (customPriceStatus === 'draft') {
    actions.push({
      text: 'Save',
      medium: true,
      buttonProps: {
        disabled: form.disableFormActions || loading || loadingUpdate || isSameForm,
      },
      onAction: () => updateCustomPrice(),
    })

    actions.push({
      text: 'Publish',
      medium: true,
      buttonProps: {
        contained: 'primary',
        disabled: form.disableFormActions || loading || loadingUpdate || !isSameForm,
      },
      onAction: () => updateAndPublishCustomPrice(),
    })
  } else {
    actions.push({
      text: 'Update Existing',
      medium: true,
      buttonProps: {
        contained: 'primary',
        disabled: form.disableFormActions || loading || loadingUpdate,
      },
      onAction: () => updateCustomPrice(),
    })
  }

  return (
    <CustomPriceLayout>
      <ContainerHeader
        actionsProps={{
          gap: 8,
          actions,
        }}
        title={'Edit Custom Price'}
        titleIconProps={{
          icon: 'dollar',
          fontSize: 20,
          tertiary: true,
        }}
      />

      <Divider sx={{ marginBottom: 1.5 }} />

      <div className={styles.wrap}>
        {loading ? (
          <SpinnerLoader />
        ) : (
          <>
            <CustomPriceForm edit={customPriceStatus !== 'draft'} adminCustomPricesForm={form} />
            <RolloverCredits
              disabled={customPriceStatus !== 'draft'}
              adminCustomPricesForm={form}
            />
            {form.showSummary && <CustomPriceSummary adminCustomPricesForm={form} />}
          </>
        )}
      </div>
    </CustomPriceLayout>
  )
})
