import { TriggersRoutes } from './type'

const triggers = `${TriggersRoutes.root}`
const triggersAll = `${triggers}/${TriggersRoutes.all}`
const triggersSms = `${triggers}/${TriggersRoutes.sms}`
const triggersRingless = `${triggers}/${TriggersRoutes.ringless}`
const triggersCreate = `${triggers}/${TriggersRoutes.create}`
const triggersEdit = `${triggers}/${TriggersRoutes.edit}`
const triggersClone = `${triggers}/${TriggersRoutes.clone}`
const triggersView = `${triggers}/${TriggersRoutes.view}`

export const TriggersPath = {
  root: triggers,
  all: triggersAll,
  sms: triggersSms,
  ringless: triggersRingless,
  create: triggersCreate,
  edit: triggersEdit,
  clone: triggersClone,
  view: triggersView,
}
