import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import {
  TriggerIntegration,
  TriggerInvalidAlert,
  TriggerStatus,
  TriggerType,
} from 'entities/Trigger'
import { useDetailsTriggerModalContext } from 'widgets/TriggerActions/ui/TriggerDetailsModal/context/detailsTriggerModalContext'
import styles from './styles.module.scss'

export const DetailsTriggerHeader = observer(() => {
  const trigger = useDetailsTriggerModalContext()
  const { type, name, integration } = trigger
  return (
    <div>
      <Typography
        tag={'div'}
        ariaLabel={'trigger.name'}
        variant={'heading-lg'}
        tooltipProps={{
          label: name,
        }}
        ellipsis
      >
        {name}
      </Typography>
      <div className={styles.info}>
        <TriggerStatus status={trigger.status} />
        <TriggerType type={type} />
        <TriggerIntegration integration={integration} />
      </div>
      <TriggerInvalidAlert trigger={trigger} className={styles.alert} isBottomActions />
    </div>
  )
})
