import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import type { IQuickLinkTemplate } from 'entities/QuickLink'

import styles from './styles.module.scss'

export interface IQuickLinkLabelProps {
  template: IQuickLinkTemplate
}

export const QuickLinkLabel = observer(({ template }: IQuickLinkLabelProps) => (
  <Typography
    className={styles.linkLabel}
    variant={'body-md-regular'}
    tooltipProps={{
      label: template.label,
    }}
    ariaLabel={'label'}
  >
    {template.label}
  </Typography>
))
