import {
  DefaultEmptyState,
  IDefaultEmptyStateProps,
} from 'shared/ui/EmptyState/variants/DefaultEmptyState/DefaultEmptyState'
import {
  IPreviewEmptyStateProps,
  PreviewEmptyState,
} from 'shared/ui/EmptyState/variants/PreviewEmptyState/PreviewEmptyState'

export type IEmptyStateProps = IDefaultEmptyStateProps | IPreviewEmptyStateProps

export const EmptyState = (props: IEmptyStateProps) => {
  if ('variant' in props && props.variant === 'preview') {
    return <PreviewEmptyState {...props} />
  }

  return <DefaultEmptyState {...props} />
}
