import { RefObject, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { CallPowerDialerBaseContent } from 'widgets/CallPopUp/ui/CallPowerDialer/CallPowerDialerBaseContent/CallPowerDialerBaseContent'
import { CallPowerDialerSection } from 'widgets/CallPopUp/ui/CallPowerDialer/CallPowerDialerSection/CallPowerDialerSection'
import { CallPowerDialerBaseContent as CallPowerDialerBaseContentV2 } from 'widgets/CallPopUp/ui/CallPowerDialerV2/CallPowerDialerBaseContent/CallPowerDialerBaseContent'
import { CallPowerDialerSection as CallPowerDialerSectionV2 } from 'widgets/CallPopUp/ui/CallPowerDialerV2/CallPowerDialerSection/CallPowerDialerSection'
import { CallQueueSection } from 'widgets/CallPopUp/ui/CallQueue/CallQueueSection/CallQueueSection'
import { CallPopUpContent } from 'widgets/CallPopUp/ui/CallPopUpBase/CallPopUpContent/CallPopUpContent'
import styles from './styles.module.scss'

type ICallPopUpViewDefaultProps = {
  refWrap: RefObject<HTMLDivElement>
}

export const CallPopUpViewDefault: FC<ICallPopUpViewDefaultProps> = observer(({ refWrap }) => {
  const store = useCallPopUpDefaultContext()
  const { salesmsg_power_dialer_v2 } = featureFlagsStore
  const {
    callPopUpQueueStore,
    callPopUpPowerDialerStore,
    callPopUpPowerDialerStoreV2,
    isStatusClose,
  } = store

  const renderContent = () => {
    if (isStatusClose) {
      return salesmsg_power_dialer_v2 ? (
        <CallPowerDialerBaseContentV2 refWrap={refWrap} />
      ) : (
        <CallPowerDialerBaseContent refWrap={refWrap} />
      )
    }

    return <CallPopUpContent refWrap={refWrap} />
  }

  return (
    <>
      {renderContent()}

      {salesmsg_power_dialer_v2
        ? callPopUpPowerDialerStoreV2.hasContactsToCall && (
            <div className={classnames(styles.section, styles.section__indent)}>
              <CallPowerDialerSectionV2 />
            </div>
          )
        : callPopUpPowerDialerStore.hasItems && (
            <div className={classnames(styles.section, styles.section__indent)}>
              <CallPowerDialerSection />
            </div>
          )}

      {callPopUpQueueStore.hasItems && (
        <div className={classnames(styles.section, styles.section__indent)}>
          <CallQueueSection type={'default'} />
        </div>
      )}
    </>
  )
})
