import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseSalesforceProperty,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactTab } from '../../TabsView/ContactTab/ContactTab'

type IPropertiesProps = {
  properties: IResponseSalesforceProperty | null
  contact: Contact
}

const Properties = ({ properties, contact }: IPropertiesProps) => {
  const integration = integrationsStore.getIntegration(IntegrationKey.salesforce)

  const linkProps = {
    key: IntegrationKey.salesforce,
    contact,
    integration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = integrationLink(linkProps)

  let name = null
  if (properties?.first_name) name = `${properties?.first_name} `
  if (properties?.last_name) name += `${properties?.last_name}`

  const data = properties
    ? [
        {
          label: 'Email',
          key: 'email',
          value: typeof properties?.email === 'string' ? properties?.email : null,
        },
      ]
    : null

  return <ContactTab title={name} link={link} data={data} integrationLabel={'Salesforce'} />
}

export { Properties }
