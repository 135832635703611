// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XV5YINb8PLRZl_dh_tFK{display:flex;flex-direction:column;flex-grow:1;position:relative}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignsList/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "XV5YINb8PLRZl_dh_tFK"
};
export default ___CSS_LOADER_EXPORT___;
