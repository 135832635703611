import { reaction, makeObservable, observable } from 'mobx'
import { IIconsVector } from 'shared/ui'
import icons from 'shared/ui/Icon/icons'
import { Base } from 'models'
import { integrationsStore, IResponseIntegration } from 'entities/Integrations'

export enum IntegrationKey {
  salesmessage = 'salesmessage',
  hubspot = 'hubspot',
  activecampaign = 'activecampaign',
  infusionsoft = 'infusionsoft',
  pipedrive = 'pipedrive',
  salesforce = 'salesforce',
  marketo = 'marketo',
  aircall = 'aircall',
  slack = 'slack',
  zipwhip = 'zipwhip',
  intercom = 'intercom',
  zapier = 'zapier',
  webhook = 'webhook',
  make = 'make',
  salesmsg = 'salesmsg',
}

export type IIntegrationKey = keyof typeof IntegrationKey

export class Integration extends Base {
  id: number
  key!: IIntegrationKey
  name!: string
  is_connected = false
  origin!: IResponseIntegration

  constructor(item: IResponseIntegration) {
    super()

    this.id = item.id
    this.syncOrigin(item)

    makeObservable(this, {
      is_connected: observable,
    })

    this.reactionConnected()
  }

  get icon(): IIconsVector {
    if (this.key === IntegrationKey.hubspot) return IntegrationKey.hubspot

    return this.key in icons ? (this.key as IIconsVector) : 'salesmsg'
  }

  syncOrigin = (origin: IResponseIntegration) => {
    this.id = origin.id
    this.key = origin.key
    this.name = origin.name
    this.origin = origin
  }

  reactionConnected = () => {
    reaction(
      () => integrationsStore.hasUserIntegration(this.key),
      (value) => {
        this.is_connected = value
      },
      {
        fireImmediately: true,
      }
    )
  }
}
