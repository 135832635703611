import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { inboxesStore } from 'entities/Inbox'
import { IResponseInboxCombine } from 'entities/Inbox/api/types'

const InboxUpdated = {
  handle: (data: IResponseInboxCombine) => {
    inboxesStore.addItem(data)
  },
}

export const EventInboxUpdate = {
  subscribe: () => websocket.on(WebsocketEvent.InboxUpdated, InboxUpdated.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.InboxUpdated, InboxUpdated.handle),
}
