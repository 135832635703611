// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FGzb0fZ9WiwBba5TVOO2{position:relative}", "",{"version":3,"sources":["webpack://./src/entities/Attachment/ui/AttachmentFile/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA","sourcesContent":[".attachmentPdfContainer {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachmentPdfContainer": "FGzb0fZ9WiwBba5TVOO2"
};
export default ___CSS_LOADER_EXPORT___;
