import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponseIntegrationDisconnected } from 'entities/Integrations'
import { integrationsStore } from 'entities/Integrations/store/integrations'

type IHandlerEvent = (data: IResponseIntegrationDisconnected) => void

export const EventIntegrationDisconnected = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.IntegrationDisconnected, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.IntegrationDisconnected, handler),
}

export const useEventIntegrationDisconnected = () => {
  const handler = (data: IResponseIntegrationDisconnected) => {
    integrationsStore.syncUserIntegration(data.userIntegration)
  }

  useEffect(() => {
    if (websocket.isConnected) {
      EventIntegrationDisconnected.subscribe(handler)
    }
  }, [websocket.isConnected])
}
