import classnames from 'classnames'
import { Button, CopyWrapper } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseIntegrationHubspotCompany,
} from 'entities/Integrations'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { ItemCompaniesRow } from './ItemCompaniesRow'

import styles from '../styles.module.scss'

type ICompaniesProps = {
  companies: IResponseIntegrationHubspotCompany[]
}

const Empty = () => <div className={styles.emptyData}>No companies found</div>

const Companies = ({ companies }: ICompaniesProps) => {
  const store = useContactsDetailsContext()

  if (!companies.length) return <Empty />

  const userIntegration = integrationsStore.userIntegrationsMap.get(IntegrationKey.hubspot)
  const hasMore = companies.length > 1

  const link = (id: number | null | undefined) => {
    const linkProps = {
      key: IntegrationKey.hubspot,
      integration: userIntegration,
      type: IIntegrationLinkTypes.company,
      id,
    }

    return integrationLink(linkProps)
  }

  const handleView = () => {
    store.openView('companies', { companies }, 'hubspot', IntegrationKey.hubspot)
  }

  return (
    <div className={classnames(styles.contentWrapper)}>
      {companies.slice(0, 1).map((company) => (
        <div key={company?.id?.value}>
          <div className={styles.headerWrapper}>
            <CopyWrapper
              copyValue={company?.name?.value || null}
              data={{
                title: company?.name?.value || 'View company',
                link: link(company?.id?.value),
              }}
            />
          </div>
          <ItemCompaniesRow company={company} />
        </div>
      ))}
      {hasMore && (
        <div className={styles.showMore}>
          <Button size='medium' typeBtn='text' text='See all' onClick={handleView} />
        </div>
      )}
    </div>
  )
}

export { Companies }
