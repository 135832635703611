import { FC } from 'react'
import { Alert, SpinnerLoader, Typography } from 'shared/ui'
import type { IAlert } from 'shared/ui/Alert/types'
import styles from './styles.module.scss'

type ISuccessModalContentProps = {
  title: string
  desc?: string
  loadingNumbers?: boolean
  formattedNumber?: string
  alertItem: IAlert
}

export const SuccessModalContent: FC<ISuccessModalContentProps> = ({
  title,
  desc,
  loadingNumbers,
  formattedNumber,
  alertItem,
}) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.icon}>🥳</div>
        <Typography
          className={styles.title}
          variant={'heading-md'}
          ariaLabel={'getNumberOnboardingModalContent_title'}
        >
          {title}
          {desc && <br />}
          {desc}
        </Typography>
      </div>
      <div className={styles.phone}>
        {loadingNumbers ? <SpinnerLoader /> : formattedNumber || 'number not found'}
      </div>
      <Alert className={styles.alert} item={alertItem} />
    </div>
  )
}
