import { Navigate, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { KeywordsRoutes } from 'pages/keywords/route/type'
import { KeywordsPageLayout } from 'pages/keywords/layout'
import { AllKeywords, CreateKeyword, EditKeyword, ViewKeyword } from 'pages/keywords/pages'
import { KeywordsAccessLayout } from 'pages/keywords/layout/KeywordsAccessLayout'

export const KeywordsRouter = observer(() => (
  <Routes>
    <Route element={<KeywordsAccessLayout />}>
      <Route element={<KeywordsPageLayout />}>
        <Route index element={<AllKeywords />} />
        <Route path={`${KeywordsRoutes.create}`} element={<CreateKeyword />} />
        <Route path={`${KeywordsRoutes.edit}/:id`} element={<EditKeyword />} />
        <Route path={`${KeywordsRoutes.clone}/:id`} element={<EditKeyword isDuplicate={true} />} />
        <Route path={`${KeywordsRoutes.view}/:id`} element={<ViewKeyword />} />
        <Route path={'*'} element={<Navigate to={''} />} />
      </Route>
    </Route>
  </Routes>
))
