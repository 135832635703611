import { observer } from 'mobx-react-lite'
import React from 'react'
import { SpinnerLoader, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { CardInfo } from './CardInfo'
import type { IMultiselectedItem } from './type'
import styles from './styles.module.scss'

type ISelectNumbersTriggerProps = {
  limit?: number
  isLoading: boolean
  multiSelectedValues: IMultiselectedItem[]
}
export const SelectNumbersTrigger = observer(
  ({ limit = 3, isLoading, multiSelectedValues }: ISelectNumbersTriggerProps) => {
    const restNumsCount = multiSelectedValues.length - limit
    const limitedNums = multiSelectedValues.slice(0, limit)
    const showNumsRestCount = restNumsCount > 0

    const content = () => {
      if (isLoading) {
        return (
          <div className={styles.loaderTrigger}>
            <SpinnerLoader size={10} indeterminatecolor={'var(--content-primary-tertiary)'} />
          </div>
        )
      }

      if (!multiSelectedValues.length) {
        return (
          <Typography
            ariaLabel='Select number'
            variant={'text-input-field-r'}
            color='var(--content-neutral-primary)'
          >
            Select
          </Typography>
        )
      }

      return (
        <div className={styles.triggerContainer}>
          {limitedNums.map((item) => (
            <div
              className={styles.triggerItem}
              key={item.id}
              aria-label={getAriaLabel('MultiSelectTrigger', 'selectedItem')}
            >
              <CardInfo selectedItem={item} />
            </div>
          ))}
          {showNumsRestCount && <div className={styles.remainderCount}>+{restNumsCount}</div>}
        </div>
      )
    }

    return <div className={styles.trigger}>{content()}</div>
  }
)
