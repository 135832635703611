// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AZ4ptJk4EZs3_0ofyouo{display:flex;padding:0 24px;align-items:flex-start;align-self:stretch;justify-content:space-between}.AZ4ptJk4EZs3_0ofyouo .Q9mCcvXUzs_XOmcRZVRn{padding:16px 24px 16px 0;display:flex;flex-direction:column;gap:4px}.AZ4ptJk4EZs3_0ofyouo .LZODGjmZjZheLnhGNdd3{padding:14px 0 0 20px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignSettings/ui/Card/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,sBAAA,CACA,kBAAA,CACA,6BAAA,CACA,4CACE,wBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,4CACE,qBAAA","sourcesContent":[".card {\n  display: flex;\n  padding: 0 24px;\n  align-items: flex-start;\n  align-self: stretch;\n  justify-content: space-between;\n  .left {\n    padding: 16px 24px 16px 0;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n  }\n  .right {\n    padding: 14px 0 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "AZ4ptJk4EZs3_0ofyouo",
	"left": "Q9mCcvXUzs_XOmcRZVRn",
	"right": "LZODGjmZjZheLnhGNdd3"
};
export default ___CSS_LOADER_EXPORT___;
