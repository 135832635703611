import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { getDescendants } from '@minoru/react-dnd-treeview'
import { nanoid } from 'nanoid'
import { showToast, toastStore } from 'shared/ui'
import { PageLayoutStore } from 'shared/layout'
import { uiStore } from 'shared/store/uiStore'
import { InboxTree } from 'entities/Inbox/model/InboxTree'
import {
  IInboxCombine,
  InboxesApi,
  inboxesStore,
  NAME_INBOX,
  TreeRootInboxes,
} from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { Phone } from 'entities/Phone/model/Phone'
import { numbersStore } from 'entities/Phone'
import { IResponseInboxCombine } from 'entities/Inbox/api/types'
import { groupInboxTemplate } from 'entities/Inbox/templates/groupInboxTemplate'
import type { GroupInbox } from 'entities/Inbox/model/GroupInbox'
import { conversationStore } from 'entities/Conversation'
import {
  IAddItemTree,
  IConversationMenuStoreConfig,
  IHandleAddTeamsGroupsCreateLocal,
  IHandleUpdateTeamsFavorite,
} from 'widgets/ConversationMenu/store/types'
import { type CallModalStore } from 'widgets/CallModal'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'

export class ConversationMenuStore {
  private _inboxesTreeMap: Map<number | string, InboxTree> = new Map()
  private _search = ''
  private _searchSimple = ''
  private _searchFavorite = ''
  private _error = false
  private _loading = false
  private _loadingCreateGroupTeam = false
  private _loadingUpdateTeam = false
  private _disabledActive = false
  private _createFromGroupInbox: GroupInbox | null = null
  private _disposerInboxSize: IReactionDisposer | null = null

  public onOpenSearch: IConversationMenuStoreConfig['onOpenSearch'] | null = null
  public onToggleSearch: IConversationMenuStoreConfig['onToggleSearch'] | null = null
  public onShowChats: IConversationMenuStoreConfig['onShowChats'] | null = null
  public resetConversations: IConversationMenuStoreConfig['resetConversations'] | null = null
  public setConversationsLoading: IConversationMenuStoreConfig['setConversationsLoading'] | null =
    null

  constructor(
    private _pageLayoutStore: PageLayoutStore,
    private _callModalStore: CallModalStore,
    private _contactCreateModalStore: ContactCreateModalStore
  ) {
    makeAutoObservable(this)
  }

  reactionInboxSize = () => {
    this._disposerInboxSize?.()
    this._disposerInboxSize = reaction(
      () => inboxesStore.inboxesMap.size,
      () => {
        this.reset()
        this.initInboxesTree()
      }
    )
  }

  setConfig = (config?: IConversationMenuStoreConfig) => {
    this.onOpenSearch = config?.onOpenSearch
    this.onToggleSearch = config?.onToggleSearch
    this.onShowChats = config?.onShowChats
    this.setConversationsLoading = config?.setConversationsLoading
    this.resetConversations = config?.resetConversations
  }

  setDisabledActive = (value: boolean) => {
    this._disabledActive = value
  }

  resetReactions = () => {
    this._disposerInboxSize?.()
  }

  reset = () => {
    this._inboxesTreeMap.clear()
    this._disabledActive = false
  }

  initInboxes = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      await inboxesStore.fetchInboxes()

      this.reset()
      this.initInboxesTree()

      runInAction(() => {
        this._error = false
        this._loading = false
      })
    } catch (e) {
      console.error(e)
    }
  }

  initInboxesTree = () => {
    this._inboxesTreeMap = this.addItemsTree(inboxesStore.inboxesMap)
  }

  handleUpdateSearch = (value: string) => {
    this._search = value
  }

  handleUpdateSearchSimple = (value: string) => {
    this._searchSimple = value
  }

  handleUpdateSearchFavorite = (value: string) => {
    this._searchFavorite = value
  }

  addItemsTree = (inboxes: Map<number | string, IInboxCombine>) => {
    inboxes.forEach((inbox) => {
      this.addItemTree({
        inbox,
      })
    })

    return this._inboxesTreeMap
  }

  addItemTree = ({ inbox, groupInbox }: IAddItemTree) => {
    this._inboxesTreeMap.set(inbox.idTree, new InboxTree(inbox))

    if (groupInbox) {
      this._createFromGroupInbox = groupInbox as GroupInbox
    }

    if (inbox.type === 'inbox_group')
      inbox.teamsIds.forEach((teamId) => {
        const inboxChild = inboxesStore.getItem(teamId)

        if (!inboxChild) return

        const id = `${inbox.root_tree}_${inbox.id}_${inboxChild.id}`
        inboxChild.root_tree = inbox.root_tree

        this._inboxesTreeMap.set(id, new InboxTree(inboxChild, inbox))
      })

    return this._inboxesTreeMap
  }

  updateItemTree = (id: string, props: { id?: string; parent?: string } = {}) => {
    const inboxTree = this._inboxesTreeMap.get(id)

    if (inboxTree) {
      this.deleteItemTree(inboxTree.id, 'child')

      for (const key in props) {
        if (key === 'id' && props.id) inboxTree.id = props.id
        if (key === 'parent' && props.parent) {
          inboxTree.parent = props.parent
          inboxTree.data.root_tree = props.parent
        }
      }

      this._inboxesTreeMap.set(inboxTree.id, inboxTree)

      if (!inboxTree.data.isGroupInbox) {
        this.updateItemTreeChild(inboxTree.id)
      }

      return inboxTree
    }
  }

  updateItemTreeChild = (id: number | string) => {
    const inboxTree = this._inboxesTreeMap.get(id)
    const inbox = inboxTree?.data

    if (inboxTree) {
      this._inboxesTreeMap.forEach((item) => {
        const idSplit = item.id.toString().split('_')
        const hasChild = idSplit.length === 3

        if (hasChild) {
          const currentRootTreeParent = idSplit[0]
          const currentIdTreeParent = +idSplit[1]
          const currentIdTreeChild = +idSplit[2]

          if (currentIdTreeChild === inbox?.id) {
            const groupInbox = inboxesStore.getItem(currentIdTreeParent)

            if (!groupInbox) return

            const oldIdTree = `${currentRootTreeParent}_${currentIdTreeParent}_${currentIdTreeChild}`
            const newIdTree = `${groupInbox.root_tree}_${currentIdTreeParent}_${currentIdTreeChild}`
            const newRootTree = `${groupInbox.root_tree}_${currentIdTreeParent}`

            this.deleteItemTree(oldIdTree, 'child')

            if (groupInbox?.type === 'inbox_group') {
              if (groupInbox?.teamsIds.map((teamId) => teamId).includes(inbox.id)) {
                item.id = newIdTree
                item.parent = newRootTree
              }
            }

            setTimeout(() => {
              this._inboxesTreeMap.set(newIdTree, item)
            }, 10)
          }
        }
      })
    }
  }

  deleteItemTree = (id: string | number, type: 'all' | 'child' = 'all') => {
    const inbox = inboxesStore.getItem(id)

    if (inbox) {
      if (type === 'all') {
        inboxesStore.deleteItem(inbox.id)

        this._inboxesTreeMap.delete(inbox.idTree)
      }
    }

    if (type === 'child') {
      this._inboxesTreeMap.delete(id)
    }
  }

  getItemTree = (id?: number | string) => {
    if (!id) return

    return this._inboxesTreeMap.get(id)
  }

  updateRootIdTree = (inbox: IInboxCombine, dropTargetId: string) => {
    const idTree = inbox.idTree

    this.updateItemTree(idTree, {
      id: `${dropTargetId}_${inbox.id}`,
      parent: dropTargetId,
    })
  }

  handleSearchFilter = (inbox: IInboxCombine, search: string) => {
    let numbers: Phone[] = []
    const inboxes: Inbox[] = []
    const searchByName = inbox.name.toUpperCase().includes(search.toUpperCase())

    if (inbox.type === 'inbox_group') {
      const teamsNumberIds = inbox.teamsIds
        .map((id) => {
          const inboxTemp = inboxesStore.getItem(id)

          if (inboxTemp?.type !== 'inbox') return []

          return inboxTemp?.numberIds
        })
        .filter((item) => item)
        .reduce((state, ids) => {
          return [...(state ? state : []), ...(ids ? ids : [])]
        }, [])

      inbox.teamsIds.map((id) => {
        const inboxTemp = inboxesStore.getItem(id)

        if (inboxTemp?.type !== 'inbox') return null

        inboxes.push(inboxTemp)
      })

      numbers = Array.isArray(teamsNumberIds)
        ? teamsNumberIds.reduce<Phone[]>((state, id) => {
            const item = numbersStore.getItem(id)

            return item ? [...state, item] : state
          }, [])
        : []
    } else if (inbox.type === 'inbox') {
      numbers = inbox.numberIds.reduce<Phone[]>((state, id) => {
        const item = numbersStore.getItem(id)

        return item ? [...state, item] : state
      }, [])
    }

    const hasNameIntersection =
      searchByName ||
      !!inboxes.filter((inbox) => inbox.name.toUpperCase().includes(search.toUpperCase())).length
    const hasFormattedNumber = !!numbers
      .map((number) => number?.formatted_number)
      .filter((number) => number?.includes(search)).length
    const hasNationalNumber = !!numbers
      .map((number) => number?.national_number)
      .filter((number) => number?.includes(search)).length
    const hasNumber = !!numbers
      .map((number) => number?.number)
      .filter((number) => number?.includes(search)).length

    return Boolean(
      searchByName || hasFormattedNumber || hasNationalNumber || hasNumber || hasNameIntersection
    )
  }

  get itemsFavorites() {
    return getDescendants(this.selectInboxesFavoriteFreeFilter, TreeRootInboxes.FAVORITE)
  }

  get itemsInboxes() {
    return getDescendants(this.selectInboxesSimpleFreeFilter, TreeRootInboxes.SIMPLE)
  }

  get filteredItemsInboxes() {
    const inboxCount = inboxesStore.sharedInboxes.length
    // Hide 'All inboxes' for case with 1 inbox only
    return inboxCount === 1
      ? this.itemsInboxes.filter(({ data }) => data?.type !== 'unified-inbox')
      : this.itemsInboxes
  }

  get selectInboxesFree() {
    return Array.from(this._inboxesTreeMap.values())
  }

  get selectInboxesFreeSort() {
    const sort = {
      unified: 0,
      group: 1,
      shared: 3,
    }

    return this.selectInboxesFree
      .sort((a, b) => a.data.name.localeCompare(b.data.name))
      .sort((a, b) => {
        const inboxA = a.data
        const inboxB = b.data
        const aValue = sort[inboxA.typeKey]
        const bValue = sort[inboxB.typeKey]

        return aValue - bValue
      })
      .sort((a, b) => {
        const inboxA = a.data.isMuted
        const inboxB = b.data.isMuted

        return Number(inboxA) - Number(inboxB)
      })
  }

  get selectInboxesFreeFilter() {
    if (!this._search) return this.selectInboxesFreeSort

    return this.selectInboxesFreeSort.filter((inboxTree) => {
      return this.handleSearchFilter(inboxTree.data, this._search)
    })
  }

  get selectInboxesSimpleFreeFilter() {
    if (!this._searchSimple) return this.selectInboxesFreeSort

    return this.selectInboxesFreeSort.filter((inboxTree) => {
      return this.handleSearchFilter(inboxTree.data, this._searchSimple)
    })
  }

  get selectInboxesFavoriteFreeFilter() {
    if (!this._searchFavorite) return this.selectInboxesFreeSort

    return this.selectInboxesFreeSort.filter((inboxTree) => {
      return this.handleSearchFilter(inboxTree.data, this._searchFavorite)
    })
  }

  get selectInboxesFilter() {
    if (!this._search) return inboxesStore.inboxesList

    return inboxesStore.inboxesList.filter((inbox) => {
      return this.handleSearchFilter(inbox, this._search)
    })
  }

  get selectInboxesFavorites() {
    return this.selectInboxesFilter.filter((item) => item.isFavoriteType)
  }

  get selectInboxesFavoritesCount() {
    return this.selectInboxesFavorites
      .map((item) => item.unread)
      .reduce((state, value) => state + value, 0)
  }

  get selectInboxesSimple() {
    return this.selectInboxesFilter.filter((item) => !item.isFavoriteType)
  }

  get selectInboxesSimpleCount() {
    return this.selectInboxesSimple
      .map((item) => item.unread)
      .reduce((state, value) => state + value, 0)
  }

  get selectInboxesGroupViews() {
    return this.selectInboxesFilter.filter((item) => item.isGroupInbox)
  }

  get isCollapse() {
    return this._pageLayoutStore.isCollapse
  }

  get headerMenuTitleIconProps() {
    return this._pageLayoutStore.titleIconProps
  }
  get headerMenuTooltipIconProps() {
    return this._pageLayoutStore.headerMenuTooltipIconProps
  }
  get headerMenuIconAction() {
    return this._pageLayoutStore.headerMenuIconAction
  }

  handleChangeCollapse = (state: boolean) => this._pageLayoutStore.handleChangeCollapse(state)

  handleDeleteTeamsGroupsById = async (inbox: IInboxCombine) => {
    this.deleteItemTree(inbox.id)
    await inboxesStore.handleDeleteTeamsGroupsById(inbox)
  }

  handleCreateTeamsGroupsByIdAddTeams = async (inbox: IInboxCombine, groupId: number | string) => {
    const groupInbox = inboxesStore.getItem(groupId)

    if (groupInbox) {
      await inboxesStore.handleCreateTeamsGroupsByIdAddTeams(inbox, groupId)
      this.addItemTree({
        inbox: groupInbox,
      })
    }
  }

  handleDeleteTeamsGroupsByIdRemoveTeamById = async (
    inbox: IInboxCombine,
    groupInbox?: GroupInbox
  ) => {
    if (!groupInbox) return

    await inboxesStore.handleDeleteTeamsGroupsByIdRemoveTeamById(inbox, groupInbox)

    if (!groupInbox.teamsIds.length) {
      this.handleDeleteTeamsGroupsById(groupInbox)
    }

    const nanoId = nanoid()
    let isUndoActionExecuted = false

    toastStore.add({
      id: nanoId,
      noCloseButton: true,
      type: 'info',
      title: `Inbox '${inbox.name}' removed from folder`,
      action: {
        text: 'Undo',
        onAction: async () => {
          if (isUndoActionExecuted) return

          isUndoActionExecuted = true

          runInAction(() => {
            toastStore.remove(nanoId)
          })

          if (groupInbox.teamsIds.length) {
            this.handleCreateTeamsGroupsByIdAddTeams(inbox, groupInbox.id)
          } else {
            const newGroupInbox = await this.handleUpdateTeamsGroupsCreate(groupInbox.name, {
              ...groupInbox,
              teamsIds: [inbox.id],
            } as IInboxCombine)

            if (inbox.isFavoriteType && newGroupInbox) {
              groupInbox.syncOrigin({ ...groupInbox.origin, id: newGroupInbox.id })
              groupInbox.is_favorite = false
              this.addItemTree({
                inbox,
              })
              this.handleUpdateTeamsFavorite({
                inbox: newGroupInbox,
                status: true,
              })
            }

            groupInbox.handleUpdateTeamsIds([inbox.id])
          }
        },
      },
    })

    if (inbox.is_favorite !== groupInbox.is_favorite) {
      this.deleteItemTree(`${groupInbox.idTree}_${inbox.id}`, 'child')

      const root_tree = inbox.is_favorite ? TreeRootInboxes.FAVORITE : TreeRootInboxes.SIMPLE
      this._inboxesTreeMap.delete(`${inbox.root_tree}_${inbox.id}`)

      inbox.root_tree = root_tree
      this.addItemTree({
        inbox,
      })
    } else {
      this.deleteItemTree(`${groupInbox.idTree}_${inbox.id}`, 'child')
      this.deleteItemFromGroup(groupInbox)
    }
  }

  deleteItemFromGroup = (group: IInboxCombine) => {
    const groupInbox = inboxesStore.getItem(group.id)

    if (groupInbox) {
      this.addItemTree({
        inbox: groupInbox,
      })
    }
  }

  handleAddTeamsGroupsCreateLocal = ({ inbox, groupInbox }: IHandleAddTeamsGroupsCreateLocal) => {
    const newInboxCreateId = NAME_INBOX.Create
    const newInboxCreateResponse = {
      ...groupInboxTemplate,
      id: newInboxCreateId,
      teams: [inbox],
      is_favorite: inbox.is_favorite,
    }

    this.deleteItemTree(newInboxCreateId)
    inboxesStore.addItem(newInboxCreateResponse)

    const newInboxGroup = inboxesStore.getItem(newInboxCreateId)

    if (newInboxGroup) {
      this.addItemTree({
        inbox: newInboxGroup,
        groupInbox: groupInbox,
      })
    }
  }

  handleUpdateTeamsGroupsCreate = async (name: string, inbox: IInboxCombine) => {
    const inboxCreateId = NAME_INBOX.Create

    if (inbox.type !== 'inbox_group') return

    try {
      runInAction(() => {
        this._loadingCreateGroupTeam = true
      })

      const params = {
        name: name,
        team_ids: inbox.teamsIds,
      }
      const item = inboxesStore.getItem(inbox.teamsIds[0])

      if (this._createFromGroupInbox && item) {
        await this.handleDeleteTeamsGroupsByIdRemoveTeamById(item, this._createFromGroupInbox)

        this._createFromGroupInbox = null
      }

      const { data } = await InboxesApi.updateTeamsGroupsCreate(params)

      const inboxGroupResponse = {
        ...data,
        type: 'inbox_group',
      } as IResponseInboxCombine

      inboxesStore.addItem(inboxGroupResponse)

      const inboxGroup = inboxesStore.getItem(inboxGroupResponse.id)

      this.deleteItemTree(inboxCreateId)

      if (inboxGroup) {
        this.addItemTree({
          inbox: inboxGroup,
        })

        if (inbox.isFavoriteType) {
          this.handleUpdateTeamsFavorite({
            inbox: inboxGroup,
            status: !inboxGroup.isFavoriteType,
          })
        }
      }

      return inboxGroup
    } catch (e) {
      console.error(e)

      this.deleteItemTree(inboxCreateId)

      showToast({
        type: 'error',
        title: 'Folder name should contain min 3 characters',
      })
    } finally {
      runInAction(() => {
        this._loadingCreateGroupTeam = false
      })
    }
  }

  handleUpdateTeamsFavorite = async ({ inbox, groupInbox, status }: IHandleUpdateTeamsFavorite) => {
    const rootId = status ? TreeRootInboxes.FAVORITE : TreeRootInboxes.SIMPLE

    if (groupInbox) {
      await this.handleDeleteTeamsGroupsByIdRemoveTeamById(inbox, groupInbox as GroupInbox)
    }

    await inboxesStore.handleUpdateTeamsFavorite(inbox, status)
    this.updateRootIdTree(inbox, rootId)

    if (inbox.type === 'inbox_group') {
      const requests: Promise<void>[] = []

      inbox.teamsIds.forEach((itemId) => {
        const inbox = inboxesStore.getItem(itemId)
        if (!inbox) return
        requests.push(
          this.handleUpdateTeamsFavorite({
            inbox: inbox,
            status: status,
          })
        )
      })

      await Promise.all(requests)
    }
  }

  resetCurrentConversation = () => {
    uiStore.changeRoute({
      path: '/conversations',
    })
    conversationStore.setCurrentItemId(null)
  }

  handleUpdateTeamInbox = async (inboxId: string | number) => {
    runInAction(() => {
      this._loadingUpdateTeam = true
    })

    await inboxesStore.handleUpdateTeamInbox(inboxId)

    runInAction(() => {
      this._loadingUpdateTeam = false
    })
  }

  get callModalStore() {
    return this._callModalStore
  }

  get contactCreateModalStore() {
    return this._contactCreateModalStore
  }

  get searchFavorite() {
    return this._searchFavorite
  }

  get searchSimple() {
    return this._searchSimple
  }

  get loading() {
    return this._loading
  }

  get loadingCreateGroupTeam() {
    return this._loadingCreateGroupTeam
  }

  get loadingUpdateTeam() {
    return this._loadingUpdateTeam
  }

  get disabledActive() {
    return this._disabledActive
  }

  get search() {
    return this._search
  }
}
