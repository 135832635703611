import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { type Conversation } from 'entities/Conversation'
import { TrialQuestionnaireAlertStore } from 'entities/Subscription/ui/TrialQuestionnaireAlert/store/trialQuestionnaireAlertStore'

type ITrialAlertQuestionnaireProps = {
  conversation: Conversation
}

export const TrialQuestionnaireAlert: FC<ITrialAlertQuestionnaireProps> = observer(
  ({ conversation }) => {
    const store = useMemo(() => new TrialQuestionnaireAlertStore(conversation), [conversation.id])

    if (store.hide) return null
    if (!store.config) return null

    return <Alert {...store.config} />
  }
)
