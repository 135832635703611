import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { type GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'
import styles from './styles.module.scss'

type IGetNumberModalActionsProps = {
  store: GetNumberModalStore
}

export const GetNumberModalActions: FC<IGetNumberModalActionsProps> = observer(({ store }) => {
  return (
    <div className={styles.actionsWrap} aria-label={getAriaLabel('GetNumberModalActions')}>
      <Button text='Cancel' contained='tertiary' onClick={store.closeModal} />
      <Button
        disabled={store.disabled}
        loading={store.loadingNumber}
        fullWidth
        typeBtn={'contained'}
        text={'Confirm number'}
        onClick={() => {
          store.handleAddNumber()
        }}
      />
    </div>
  )
})
