import React, { FC } from 'react'
import { Box, AvatarGroupInLine, Typography } from 'shared/ui'
import { type User } from 'entities/Users/model/User'

type IWidgetMultiUsersTriggerProps = {
  items: User[]
}

export const WidgetMultiUsersTrigger: FC<IWidgetMultiUsersTriggerProps> = ({ items }) => {
  if (!items.length) return <>select</>

  const info = items.map((item) => item.avatarInfo)

  return (
    <Box display={'flex'} gap={1} alignItems={'center'} height={32}>
      <AvatarGroupInLine items={info} size={16} />
      <Typography ariaLabel={'Inbox'} variant={'body-md-regular'}>
        {items.length > 1 ? `${items.length} members` : items[0].full_name}
      </Typography>
    </Box>
  )
}
