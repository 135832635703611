// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HcZrbSf5Z0Oc194Fskyv{display:flex;gap:24px;flex-direction:column}.IlvIGzdyvZ6o99q6sQOQ{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/LinkContact/ui/LinkContactModalActions/LinkWithPhonesModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,OAAA","sourcesContent":[".contentWrap {\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n}\n\n.actionsWrap {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrap": "HcZrbSf5Z0Oc194Fskyv",
	"actionsWrap": "IlvIGzdyvZ6o99q6sQOQ"
};
export default ___CSS_LOADER_EXPORT___;
