import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, Typography, Space } from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { Ticket } from './Ticket'

import styles from '../styles.module.scss'

const AllTickets = observer(() => {
  const [search, setSearch] = useState('')

  const store = useContactsDetailsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)

  if (!store.viewIntegrationKey || !contact) return null

  const integration = integrationsStore.getIntegration(store.viewIntegrationKey)

  const link = (id: number | null | undefined) => {
    const linkProps = {
      key: store.viewIntegrationKey,
      contact,
      integration,
      type: IIntegrationLinkTypes.ticket,
      id,
    }

    return integrationLink(linkProps)
  }
  const tickets = store.viewAllData.tickets.filter(
    (ticket) => ticket?.subject?.value?.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const notFound = tickets.length === 0

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.search}>
        <TextField
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
          leftActions={[makeTextFieldIcon({ icon: 'search' })]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.list}>
        {tickets.map((ticket) => (
          <Ticket
            key={ticket?.id?.value}
            ticket={ticket}
            link={link(ticket?.id?.value)}
            contact={contact}
          />
        ))}
      </div>
      {notFound ? (
        <div className={styles.epmtyList}>
          <Icon icon='search' fontSize={24} />
          <Typography variant={'body-rg-regular'} ariaLabel={'NoResultsFound'}>
            No results found
          </Typography>
        </div>
      ) : (
        <Space height={60} />
      )}
    </div>
  )
})

export { AllTickets }
