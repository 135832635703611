// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yqTBtRfqrSGXB6nVoHpc{display:flex;flex-direction:column;gap:16px;border:1px solid var(--border-neutral-default);border-radius:12px;padding:20px;height:140px}.yqTBtRfqrSGXB6nVoHpc:hover{cursor:pointer;border-color:var(--interaction-neutral-hovered)}.yqTBtRfqrSGXB6nVoHpc .zjuQsw2Vq2gpio2REgjX{display:flex;justify-content:space-between;align-items:flex-start}.yqTBtRfqrSGXB6nVoHpc .g7Jisy9cLll_56bD4K4G{display:flex;align-items:center;gap:10px}.Qyl24s7gWfYOfXSnONUh{display:flex;flex-direction:column;gap:8px}.Qyl24s7gWfYOfXSnONUh .yOePUHazE2MUV0lvvrcA{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;word-break:break-word;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationList/ui/IntegrationListCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,8CAAA,CACA,kBAAA,CACA,YAAA,CACA,YAAA,CAEA,4BACE,cAAA,CACA,+CAAA,CAGF,4CACE,YAAA,CACA,6BAAA,CACA,sBAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CAKN,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,4CACE,mBAAA,CACA,2BAAA,CACA,oBAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".card {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  border: 1px solid var(--border-neutral-default);\n  border-radius: 12px;\n  padding: 20px;\n  height: 140px;\n\n  &:hover {\n    cursor: pointer;\n    border-color: var(--interaction-neutral-hovered);\n  }\n\n  .cardHeader {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n\n    &Right {\n      display: flex;\n      align-items: center;\n      gap: 10px;\n    }\n  }\n}\n\n.data {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  .subheadline {\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 2;\n    word-break: break-word;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "yqTBtRfqrSGXB6nVoHpc",
	"cardHeader": "zjuQsw2Vq2gpio2REgjX",
	"cardHeaderRight": "g7Jisy9cLll_56bD4K4G",
	"data": "Qyl24s7gWfYOfXSnONUh",
	"subheadline": "yOePUHazE2MUV0lvvrcA"
};
export default ___CSS_LOADER_EXPORT___;
