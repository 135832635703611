import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { EnumDropdownItemVariant, type IDropdownItem, type ITabItem, showToast } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { GetNumberModalActionsDefault } from 'entities/Phone/ui/GetNumberModal/ui/GetNumberModalDefault/GetNumberModalActionsDefault'
import { GetNumberModalContentDefault } from 'entities/Phone/ui/GetNumberModal/ui/GetNumberModalDefault/GetNumberModalContentDefault'
import { NewNumberSummaryModalContent } from 'entities/Phone/ui/GetNumberModal/ui/NewNumberSummaryModal/NewNumberSummaryModalContent'
import { NewNumberSummaryModalActions } from 'entities/Phone/ui/GetNumberModal/ui/NewNumberSummaryModal/NewNumberSummaryModalActions'
import { numbersStore, PhoneApi } from 'entities/Phone'
import {
  type IResponseNumberSearch,
  type IParamsNumberSearch,
  ICountriesEnum,
} from 'entities/Phone/api/types'
import type { IResponseBillingState, IResponseBillingAreaCode } from 'entities/Billing/api/types'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'
import { billingStore } from 'entities/Billing'
import { organizationStore } from 'entities/Organization'
import { GetNumberModalActions } from 'entities/Phone/ui/GetNumberModal/ui/GetNumberModal/GetNumberModalActions'
import { GetNumberModalContent } from 'entities/Phone/ui/GetNumberModal/ui/GetNumberModal/GetNumberModalContent'
import {
  type IGetNumberModalStoreAddNumber,
  ITabsEnum,
} from 'entities/Phone/ui/GetNumberModal/store/types'
import { complianceStore } from 'entities/Compliance'

const tabs: ITabItem[] = [
  {
    name: 'Local number',
    key: ITabsEnum.LOCAL,
  },
  {
    name: 'Toll-free number',
    key: ITabsEnum.TOLL_FREE,
  },
]

export class GetNumberModalStore {
  private _idModal = 'getNumberModal'
  private _idModalFinish = 'getNumberFinishModal'
  private _selectedNumber: IResponseNumberSearch | null = null
  private _numbersMap: Map<string, IResponseNumberSearch> = new Map()
  private _loading = false
  private _loadingNumber = false
  private _state: IResponseBillingState | null = null
  private _country: ICountriesEnum = ICountriesEnum.US
  private _areaCode: IResponseBillingAreaCode | null = null
  private _activeTab: ITabsEnum = ITabsEnum.TOLL_FREE
  private _disposeAreaCode: IReactionDisposer | null = null
  private _disposeInit: IReactionDisposer | null = null
  private _disposeState: IReactionDisposer | null = null
  private _disposeCountry: IReactionDisposer | null = null
  private _disposeActiveTab: IReactionDisposer | null = null
  private _showFirstRegAlert = true
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor() {
    makeAutoObservable(this)
  }

  init = () => {
    this.reactionCountry()
    this.reactionState()
    this.reactionAreaCode()
    this.reactionActiveTab()
    this.reactionInit()
    this.load()
  }

  initActiveTab = () => {
    this._activeTab = this.numberType === 'toll_free' ? ITabsEnum.TOLL_FREE : ITabsEnum.LOCAL
  }

  get areaCode() {
    return this._areaCode
  }

  get loadingNumber() {
    return this._loadingNumber
  }

  get state() {
    return this._state
  }

  get country() {
    return this._country
  }

  get activeTab() {
    return this._activeTab
  }

  get loading() {
    return this._loading
  }

  get selectedNumber() {
    return this._selectedNumber
  }

  get canSearch() {
    if (this._activeTab === ITabsEnum.LOCAL) {
      return Boolean(this._areaCode && this._country && this._state)
    }

    return Boolean(this._country)
  }

  get alertDescription() {
    return `${
      this._activeTab === ITabsEnum.LOCAL ? 'Local' : 'Toll-Free'
    } numbers will require verification of your business`
  }

  get statePlaceholder() {
    if (this._country === ICountriesEnum.CA) return 'Enter a province name'
    return 'Enter a state name'
  }

  get states(): IDropdownItem[] {
    return billingStore.states
      .filter((state) => state.country_code === this._country)
      .map((state) => ({
        label: `${state.state_code} - ${state.state_name}`,
        id: state.state_code,
        variant: EnumDropdownItemVariant.CheckedRight,
        customFields: state,
      }))
  }

  get areaCodes(): IDropdownItem[] {
    return billingStore.areaCodes
      .filter((areaCode) => areaCode.state_code === this._state?.state_code)
      .map((areaCode) => ({
        label: `${areaCode.state_name} - ${areaCode.area_code}`,
        id: areaCode.area_code,
        variant: EnumDropdownItemVariant.CheckedRight,
        customFields: areaCode,
      }))
  }

  get countries(): IDropdownItem[] {
    return [
      {
        label: 'United States',
        id: ICountriesEnum.US,
        iconL: 'flagUSA',
        variant: EnumDropdownItemVariant.CheckedRight,
      },
      {
        label: 'Canada',
        id: ICountriesEnum.CA,
        iconL: 'flagCanada',
        variant: EnumDropdownItemVariant.CheckedRight,
      },
    ]
  }

  get countryData() {
    switch (this._country) {
      case ICountriesEnum.US:
        return { flag: 'flagUSA', name: 'United States' }
      case ICountriesEnum.CA:
        return { flag: 'flagCanada', name: 'Canada' }
      default:
        return null
    }
  }

  get searchParams(): IParamsNumberSearch {
    return {
      country: this._country,
      state: this._state?.state_code || '',
      search: this._areaCode?.area_code || '',
      toll_free: this._activeTab === ITabsEnum.LOCAL ? '0' : '1',
    }
  }

  get numbersList() {
    return Array.from(this._numbersMap.values())
  }

  get noResults() {
    return this.numbersList.length === 0
  }

  get showFirstRegAlert() {
    return this._showFirstRegAlert
  }

  get numberType() {
    return complianceStore.summary?.numbers_type
  }

  get tabs() {
    return this.numberType === 'toll_free' ? [...tabs].reverse() : tabs
  }

  get noAvailableText() {
    if (this.numberType === 'toll_free' && this._activeTab === ITabsEnum.LOCAL) {
      return (
        <>
          Based on your business needs, <br /> local numbers are not available
        </>
      )
    }
    if (this.numberType === 'local' && this._activeTab === ITabsEnum.TOLL_FREE) {
      return (
        <>
          Based on your business needs, <br /> toll-free numbers are not available
        </>
      )
    }

    return null
  }

  get textRadioLabel() {
    if (this._state) {
      return `${this._state.state_name}, ${this._state.state_code}`
    }

    if (this._activeTab === ITabsEnum.TOLL_FREE) {
      return 'Toll-free'
    }

    return ''
  }

  get disabled() {
    return Boolean(!this._selectedNumber || this._loading || this._loadingNumber)
  }

  setNumber = (number: IResponseNumberSearch) => {
    this._selectedNumber = number
  }

  setActiveTab = (activeTab: ITabsEnum) => {
    if (activeTab === 'LOCAL') this.resetCancelTokenSource()

    this._activeTab = activeTab
  }

  setState = (state: IResponseBillingState) => {
    this._state = state
  }

  setAreaCode = (areaCode: IResponseBillingAreaCode) => {
    this._areaCode = areaCode
  }

  setCountry = (country: ICountriesEnum) => {
    this._country = country
  }

  editAnswers = () => {
    uiStore.changeRouteViaVue({
      path: '/settings/organization/compliance/questionnaire',
      name: 'settings.organization.compliance.questionnaire',
      options: {
        state: 'started',
      },
    })

    this.closeModal()
  }

  openDefault = async () => {
    await Promise.all([
      usersStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
    ])

    if (organizationStore.isSinchNumberVendor) this._activeTab = ITabsEnum.LOCAL

    if (
      usersStore.user?.isRequireNumber &&
      !subscriptionStore.isAgencyWithoutSubscription &&
      !organizationStore.isTwilioNumberVendor &&
      !usersStore.user.inboxesCount
    ) {
      this.init()

      modalStore.addModal({
        id: this._idModal,
        showCloseIcon: false,
        showCloseButton: false,
        disabledOnAllClose: true,
        disabledOnEscClose: true,
        disabledOverlayClose: true,
        title: 'Choose number',
        paddingContent: '8px 24px 0 24px',
        width: 480,
        ModalContentProps: {
          store: this,
        },
        ModalActions: GetNumberModalActionsDefault,
        ModalContent: GetNumberModalContentDefault,
        onClose: this.closeModal,
      })
    }
  }

  preselectCountry = () => {
    const country = complianceStore.summary?.target_country

    if (country === ICountriesEnum.CA) {
      this.setCountry(ICountriesEnum.CA)
    }

    if (country === ICountriesEnum.US) {
      this.setCountry(ICountriesEnum.US)
    }
  }

  openOnboarding = async () => {
    await Promise.all([
      usersStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
      complianceStore.fetchSummary(),
    ])

    if (
      usersStore.user?.isRequireNumber &&
      !subscriptionStore.isAgencyWithoutSubscription &&
      !organizationStore.isTwilioNumberVendor
    ) {
      this.checkFirstOpen()
      this.preselectCountry()
      this.initActiveTab()
      this.init()

      modalStore.addModal({
        id: this._idModal,
        showCloseIcon: false,
        showCloseButton: false,
        disabledOnAllClose: true,
        disabledOnEscClose: true,
        disabledOverlayClose: true,
        hiddenContent: true,
        paddingContent: '8px 24px 0 24px',
        width: 480,
        ModalContentProps: {
          store: this,
        },
        showHeader: false,
        ModalActions: GetNumberModalActions,
        ModalContent: GetNumberModalContent,
        onClose: () => {
          this.closeModal()
        },
      })
    }
  }

  openNewNumberSummaryModal = (number: string) => {
    modalStore.addModal({
      id: this._idModalFinish,
      showCloseIcon: false,
      showCloseButton: false,
      disabledOnAllClose: true,
      disabledOnEscClose: true,
      disabledOverlayClose: true,
      paddingContent: '40px 24px 16px 24px',
      width: 480,
      showHeader: false,
      ModalContentProps: {
        store: this,
        number: number,
      },
      ModalActions: NewNumberSummaryModalActions,
      ModalContent: NewNumberSummaryModalContent,
      onClose: this.closeFinishModal,
    })
  }

  closeFinishModal = () => {
    modalStore.removeModal(this._idModalFinish)
  }

  closeModal = () => {
    this.setFirstOpen()
    this.dispose()
    modalStore.removeModal(this._idModal)
  }

  checkFirstOpen = () => {
    const value = Boolean(
      localStorage.getItem(`${this._idModal}_alert_open_${usersStore.user_owner_id}`)
    )

    this._showFirstRegAlert = !value
  }

  setFirstOpen = () => {
    localStorage.setItem(`${this._idModal}_alert_open_${usersStore.user_owner_id}`, '1')
  }

  dispose = () => {
    this._numbersMap.clear()
    this._selectedNumber = null
    this._loading = false
    this._state = null
    this._country = ICountriesEnum.US
    this._areaCode = null
    this._activeTab = ITabsEnum.TOLL_FREE
    this._disposeAreaCode?.()
    this._disposeInit?.()
    this._disposeState?.()
    this._disposeCountry?.()
    this._disposeActiveTab?.()
  }

  handleAddNumber = async ({ isRedirect = true }: IGetNumberModalStoreAddNumber = {}) => {
    if (!this._selectedNumber) return

    try {
      runInAction(() => {
        this._loading = true
        this._loadingNumber = true
      })

      const { data } = await PhoneApi.addNumber({ number: this._selectedNumber?.number })

      numbersStore.addItem(data)

      await window.publicApiVue?.fetchMe()
      await window.publicApiVue?.fetchNumbers()
      await Promise.all([usersStore.fetchUsersMe(), numbersStore.fetchOrganizationNumber()])

      if (isRedirect) {
        uiStore.changeRouteViaVue({
          path: '/settings/organization/compliance',
        })
      }

      this.openNewNumberSummaryModal(data.formatted_number)
      this.closeModal()
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data.message[0] === 'Your card was declined.') {
          showToast({
            type: 'error',
            title: 'Your card was declined.',
          })
        } else {
          showToast({
            type: 'error',
            title: 'This number is already reserved for another account',
            desc: 'Please choose another number',
          })
        }
      }
      console.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
        this._loadingNumber = false
      })
    }
  }

  initCancelTokenSource = () => {
    this.resetCancelTokenSource()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  resetCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
  }

  refresh = async () => {
    if (!this.canSearch) return

    try {
      runInAction(() => {
        this._loading = true
        this._numbersMap.clear()
        this._selectedNumber = null
      })

      this.initCancelTokenSource()

      const { data } = await PhoneApi.getNumbersSearch(this.searchParams, {
        cancelToken: this._cancelTokenSource?.token,
      })

      this.addNumbers(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  load = async () => {
    if (!this.canSearch) return

    try {
      runInAction(() => {
        this._loading = true
      })

      this.initCancelTokenSource()

      const { data } = await PhoneApi.getNumbersSearch(this.searchParams, {
        cancelToken: this._cancelTokenSource?.token,
      })

      this.addNumbers(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  addNumbers = (numbers: IResponseNumberSearch[]) => {
    numbers.forEach((item) => {
      this._numbersMap.set(item.number, item)
    })
  }

  reactionActiveTab = () => {
    this._disposeActiveTab?.()
    this._disposeActiveTab = reaction(
      () => this._activeTab,
      () => {
        this._state = null
        this._areaCode = null
        this._selectedNumber = null
        this._numbersMap.clear()
        this.load()
      }
    )
  }

  reactionCountry = () => {
    this._disposeCountry?.()
    this._disposeCountry = reaction(
      () => this._country,
      () => {
        this._state = null
        this._areaCode = null
        this._selectedNumber = null
        this._numbersMap.clear()
        this.load()
      }
    )
  }

  reactionState = () => {
    this._disposeState?.()
    this._disposeState = reaction(
      () => this._state,
      () => {
        this._areaCode = null
        this._selectedNumber = null
        this._numbersMap.clear()
      }
    )
  }

  reactionInit = () => {
    this._disposeInit?.()
    this._disposeInit = reaction(
      () => [
        subscriptionStore.isAgencyWithoutSubscription,
        usersStore.user?.isViewOnlyRole,
        usersStore.user?.isRequireNumber,
      ],
      () => {
        this._selectedNumber = null
        this._numbersMap.clear()
        this.load()
      }
    )
  }

  reactionAreaCode = () => {
    this._disposeAreaCode?.()
    this._disposeAreaCode = reaction(
      () => this._areaCode,
      () => {
        this._selectedNumber = null
        this._numbersMap.clear()
        this.load()
      }
    )
  }
}
