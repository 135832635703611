import { observer } from 'mobx-react-lite'
import { Avatar, Box, IColumn, Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { EmptyCell } from 'shared/ui/Table'
import { Campaign } from 'entities/Campaign'
import { CampaignStatus } from 'entities/Campaign/ui/CampaignStatus'

export const getColumns = (): IColumn<Campaign>[] => {
  return [
    {
      field: 'name',
      name: 'Name',
      width: '100%',
      maxWidth: 362,
      renderRowCell: (row) => <NameCell row={row} />,
      isDisabledChangeVisible: true,
    },
    {
      field: 'status',
      name: 'Status',
      renderRowCell: (row) => row.status && <CampaignStatus status={row.status} />,
      isDisabledChangeVisible: true,
    },
    {
      field: 'steps',
      name: 'Steps',
      renderRowCell: (row) => <NumberCell value={row.statistics?.steps_count} />,
    },
    {
      field: 'enrolled_contacts',
      name: 'Enrolled',
      renderRowCell: (row) => <NumberCell value={row.statistics?.enrolled_contacts_count} />,
    },
    {
      field: 'in_progress',
      name: 'In progress',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.in_progress === null}
          isHover={isHover}
          value={row.statistics?.in_progress}
          percent={row.statistics?.in_progress_percent}
        />
      ),
    },
    {
      field: 'completed',
      name: 'Completed',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.completed === null}
          isHover={isHover}
          value={row.statistics?.completed}
          percent={row.statistics?.completed_percent}
        />
      ),
    },
    {
      field: 'unsubscribed',
      name: 'Unsubscribed',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.unsubscribed === null}
          isHover={isHover}
          value={row.statistics?.unsubscribed}
          percent={row.statistics?.unsubscribed_percent}
        />
      ),
    },
    {
      field: 'stopped_by_user',
      name: 'Stopped by user',
      isHideByDefault: true,
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.stopped_by_user === null}
          isHover={isHover}
          value={row.statistics?.stopped_by_user}
          percent={row.statistics?.stopped_by_user_percent}
        />
      ),
    },
    {
      field: 'created_by',
      name: 'Created by',
      renderRowCell: (row) => {
        if (!row.user) return <EmptyCell />
        return (
          <Box display={'flex'} gap={'6px'}>
            {row.userAvatarInfo && <Avatar info={row.userAvatarInfo} size={16} />}
            <Typography
              variant={'body-md-regular'}
              ellipsis
              tooltipProps={{ label: row.userDisplayInfo }}
              ariaLabel={'displayInfo'}
            >
              {row.userDisplayInfo}
            </Typography>
          </Box>
        )
      },
    },
  ]
}

const NameCell = observer(({ row }: { row: Campaign }) => (
  <Typography
    variant={'body-md-regular'}
    ellipsis
    tooltipProps={{
      label: row.name,
    }}
    ariaLabel={'name'}
  >
    {row.name}
  </Typography>
))

const StatisticCell = ({
  isEmpty,
  isHover,
  value,
  percent,
}: {
  isEmpty?: boolean | undefined
  isHover: boolean | undefined
  value: number | undefined | null
  percent: number | undefined
}) => {
  if (isEmpty) {
    return <EmptyCell />
  }
  return <>{isHover ? `${percent}%` : value && numberFormat({ value })}</>
}

const NumberCell = ({ value }: { value: number | undefined | null }) => {
  if (Number.isFinite(value)) {
    return <>{numberFormat({ value: value as number })}</>
  }
  return <EmptyCell />
}
