import {
  type IMenuItemDefaultProps,
  MenuItemDefault,
} from 'shared/ui/Menu/ui/MenuItem/MenuItemDefault/MenuItemDefault'
import {
  type IMenuItemNavProps,
  MenuItemNav,
} from 'shared/ui/Menu/ui/MenuItem/MenuItemNav/MenuItemNav'
import { type IMenuSubProps, MenuSub } from 'shared/ui/Menu/ui/MenuSub/MenuSub'

export type IMenuItemProps<T = string | number> =
  | IMenuItemNavProps<T>
  | IMenuItemDefaultProps<T>
  | IMenuSubProps<T>

export const MenuItem = (props: IMenuItemProps) => {
  if (props.type === 'sub') {
    return <MenuSub {...props} />
  }

  if (props.type === 'nav') {
    return <MenuItemNav {...props} />
  }

  if (props.type === 'default') {
    return <MenuItemDefault {...props} />
  }

  return null
}
