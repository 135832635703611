import type { IStatusProps } from 'shared/ui/Status'

const statuses = {
  active: {
    title: 'Active',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  draft: {
    title: 'Draft',
    icon: 'draft',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
  disabled: {
    title: 'Disabled',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
}

export default statuses
