import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import { ContainerHeader, NameValueRecord, Tooltip, variantActionsProps } from 'shared/ui'
import { BoxIcon } from 'shared/ui/Icon/BoxIcon'
import { KeywordInvalidAlert, KeywordStatus } from 'entities/Keywords/ui'
import { KeywordsRoutes } from 'pages/keywords'
import { getKeywordActions } from 'pages/keywords/pages/all/ui/KeywordsTable/getKeywordActions'
import { useKeywordDetailsContext } from 'pages/keywords/context'
import styles from './styles.module.scss'

export const KeywordDetailsHeader = observer(() => {
  const { keyword, pageLayoutStore } = useKeywordDetailsContext()
  const { titleIconProps, headerMenuIconAction, headerMenuTooltipIconProps } = pageLayoutStore
  const navigate = useNavigate()

  if (!keyword) return null

  const { name, title, status, textInNumbersProps } = keyword

  const onSuccessDelete = () => {
    navigate(`${KeywordsRoutes.root}`)
  }

  return (
    <div>
      <Breadcrumbs customPath={`/${KeywordsRoutes.root}/${KeywordsRoutes.view}`} />
      <ContainerHeader
        paddingTop={16}
        paddingBottom={12}
        title={name}
        rightTitleContent={<span className={styles.headerTitle}>{title}</span>}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        titleIconAction={headerMenuIconAction}
        actionIconTooltipProps={headerMenuTooltipIconProps}
        actionsProps={{
          ...variantActionsProps['headerMedium'],
          showItems: 3,
          actions: getKeywordActions({ keyword, onSuccessDelete }),
        }}
        paddingLeft={20}
        paddingRight={20}
        ellipsis
      />
      <div className={styles.info}>
        <KeywordStatus status={status} />
        <Tooltip label={'SMS'} placement={'top'}>
          <BoxIcon icon={'messageSmile'} tertiary ariaLabel='SMS' />
        </Tooltip>
      </div>
      <div className={styles.keywordNumbersWrap}>
        {textInNumbersProps.map((data, index) => (
          <div className={styles.listValue} key={index}>
            <NameValueRecord {...data} />
          </div>
        ))}
      </div>

      <KeywordInvalidAlert keyword={keyword} className={styles.alert} />
    </div>
  )
})
