import { usersStore } from 'entities/Users'
import { configStore } from 'entities/Config'
import { organizationSettingsStore, userSettingsStore } from 'entities/Settings'
import { organizationStore, multiOrganizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { numbersStore } from 'entities/Phone'
import { billingStore } from 'entities/Billing'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { complianceStore } from 'entities/Compliance'

export async function getAppData() {
  await Promise.all([
    usersStore.init(),
    usersStore.fetchUsersBusinessHours(),
    userPermissionsStore.fetchUserPermissions(),
    usersStore.initUsersOrganization(),

    subscriptionStore.init(),
    configStore.init(),
    userSettingsStore.fetchUsersSettings(),
    organizationSettingsStore.fetchOrganizationSettings(),
    organizationStore.init(),
    numbersStore.fetchOrganizationNumber(),
    numbersStore.fetchNumberVendorIntegrations(),
    numbersStore.fetchNumbers(),
    billingStore.fetchAutorecharge(),
    billingStore.applyCoupon(),
    billingStore.fetchBillingAreaCodes(),
    billingStore.fetchBillingStates(),
    featureFlagsStore.fetchFeatureFlags(),
    multiOrganizationStore.fetchMultiOrganizations(),
    complianceStore.fetchSummary(),
    complianceStore.fetchOrganizationBusinessProfile(),
    complianceStore.fetchComplianceServicesList(),
    complianceStore.fetchOrganizationTollFree(),
  ])
}
