// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C9xmzLBVh9xhB7yPxNUv{display:flex;flex-direction:column;padding:12px 24px 24px;gap:32px}.aZsaUnjZ_QOShvfRNE07{display:flex;flex-direction:column;gap:16px}.UQGeaPQnUPHkJDl2PC5A{display:flex;flex-direction:column;gap:8px}.UQGeaPQnUPHkJDl2PC5A [contenteditable]{font-size:13px}.Se7kg1Q11a_aviFHjZb_{overflow:hidden;text-overflow:ellipsis;font-size:13px;font-weight:400;color:var(--content-primary-tertiary)}.iY3inCzpjrEzuidI3xLI{display:flex;flex-direction:row;justify-content:flex-end;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/quickLinks/ui/QuickLinkModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CAEA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,wCACE,cAAA,CAGF,sBACE,eAAA,CACA,sBAAA,CACA,cAAA,CACA,eAAA,CACA,qCAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,OAAA","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  padding: 12px 24px 24px;\n  gap: 32px;\n\n  &Rows {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n\n  &Row {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n\n    & [contenteditable] {\n      font-size: 13px;\n    }\n\n    &Title {\n      overflow: hidden;\n      text-overflow: ellipsis;\n      font-size: 13px;\n      font-weight: 400;\n      color: var(--content-primary-tertiary);\n    }\n  }\n\n  &Footer {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "C9xmzLBVh9xhB7yPxNUv",
	"contentRows": "aZsaUnjZ_QOShvfRNE07",
	"contentRow": "UQGeaPQnUPHkJDl2PC5A",
	"contentRowTitle": "Se7kg1Q11a_aviFHjZb_",
	"contentFooter": "iY3inCzpjrEzuidI3xLI"
};
export default ___CSS_LOADER_EXPORT___;
