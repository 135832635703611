import { AxiosResponse, AxiosError } from 'axios'
import { setTokenLocalStorageViaConfig } from 'entities/Auth/lib/setToken'
import { rejectRefreshToken } from 'entities/Auth/lib/rejectRefreshToken'

export const interceptorsResponseSuccess = (config: AxiosResponse, isAssume: boolean) => {
  setTokenLocalStorageViaConfig(config, isAssume)

  return config
}

export const interceptorsResponseError = async (error: AxiosError<{ auth_required: boolean }>) => {
  return rejectRefreshToken(error)
}
