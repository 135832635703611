export const createWindowCloseWatcher = (
  win: Window,
  pollInterval: number
): { windowCloseWatcherPromise: Promise<void>; timerId: number } => {
  let resolveFn: () => void

  const windowCloseWatcherPromise = new Promise<void>((resolve) => {
    resolveFn = resolve
  })

  const timerId = window.setInterval(() => {
    if (win.closed) {
      resolveFn()
    }
  }, pollInterval)

  return { windowCloseWatcherPromise, timerId }
}
