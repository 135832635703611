import { ReactNode, FC } from 'react'
import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IModalContentProps = {
  header: string
  statusData: ReactNode
}

export const ModalHeader: FC<IModalContentProps> = ({ header, statusData }: IModalContentProps) => {
  return (
    <div className={styles.wrap}>
      <Typography
        ariaLabel='AfterRegisterModalContent_header'
        variant='heading-lg'
        tag='div'
        marginBottom={'12px'}
      >
        {header}
      </Typography>
      {statusData}
    </div>
  )
}
