// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".esfV3bPX7MFTO8tTdXw2{display:flex;flex-direction:column;overflow:auto;align-items:flex-end}.TfZ7vaQB7IaUHIMs_3sN{display:flex;justify-content:space-between;width:100%;gap:16px;padding-top:40px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/businessProfile/pages/CompanyLocation/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,oBAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,UAAA,CACA,QAAA,CACA,gBAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  align-items: flex-end;\n}\n\n.content {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  gap: 16px;\n  padding-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "esfV3bPX7MFTO8tTdXw2",
	"content": "TfZ7vaQB7IaUHIMs_3sN"
};
export default ___CSS_LOADER_EXPORT___;
