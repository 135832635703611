import { observer } from 'mobx-react-lite'
import { type IColumn, Tooltip, Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { EmptyCell } from 'shared/ui/Table'
import type { IKeyword } from 'entities/Keywords'
import { KeywordStatus } from 'entities/Keywords/ui'

import styles from './styles.module.scss'

export const columns: IColumn<IKeyword>[] = [
  {
    isSortable: true,
    field: 'name',
    name: 'Keyword',
    width: '100%',
    minWidth: '212px',
    maxWidth: '212px',
    isDisabledChangeVisible: true,
    renderRowCell: (row) => (
      <div className={styles.cellWrapper}>
        <Typography
          variant={'body-md-regular'}
          ariaLabel={'keywordName'}
          className={styles.keywordName}
          ellipsis
          tooltipProps={{
            label: row.name,
          }}
        >
          {row.name}
        </Typography>
      </div>
    ),
  },
  {
    isSortable: true,
    field: 'title',
    name: 'Description',
    minWidth: '250px',
    maxWidth: '300px',
    isDisabledChangeVisible: true,
    renderRowCell: (row) =>
      row.title ? (
        <div className={styles.cellWrapper}>
          <Typography
            variant={'body-md-regular'}
            ariaLabel={'keywordDescription'}
            color={'tertiary'}
            ellipsis
            tooltipProps={{
              label: row.title,
            }}
          >
            {row.title}
          </Typography>
        </div>
      ) : (
        <EmptyCell />
      ),
  },
  {
    isSortable: true,
    field: 'status',
    name: 'Status',
    width: '110px',
    minWidth: '110px',
    maxWidth: '110px',
    isDisabledChangeVisible: true,
    renderRowCell: (row) => <StatusCell row={row} />,
  },
  {
    isSortable: true,
    field: 'total_count',
    sortKey: 'statistics.total_subscribers',
    name: 'Subscribers',
    minWidth: '153px',
    maxWidth: '320px',
    renderRowCell: (row) => (
      <span aria-label='KeywordSubscribers'>{row.statistics.total_count}</span>
    ),
  },
  {
    isSortable: true,
    field: 'active_count',
    sortKey: 'statistics.subscribers',
    name: 'Active',
    minWidth: '153px',
    maxWidth: '320px',
    renderRowCell: (row, _, { isHover }) => (
      <StatisticCell
        isHover={isHover}
        value={row.statistics.active_count}
        percent={row.statistics.active_percent}
        ariaLabel='KeywordActiveSubscribers'
      />
    ),
  },
  {
    isSortable: true,
    field: 'unsubscribed_count',
    sortKey: 'statistics.unsubscribed',
    name: 'Unsubscribed',
    minWidth: '153px',
    maxWidth: '320px',
    renderRowCell: (row, _, { isHover }) => (
      <StatisticCell
        isHover={isHover}
        value={row.statistics.unsubscribed_count}
        percent={row.statistics.unsubscribed_percent}
        ariaLabel='KeywordUnsubscribed'
      />
    ),
  },
]

const StatusCell = observer(({ row }: { row: IKeyword }) => {
  return (
    <Tooltip label={row.isInvalid && 'Suspicious content detected'} placement={'top'}>
      <KeywordStatus status={row.status} />
    </Tooltip>
  )
})

const StatisticCell = ({
  isHover,
  value,
  percent,
  ariaLabel,
}: {
  isHover: boolean | undefined
  value: number | undefined | null
  percent: number | undefined
  ariaLabel: string
}) => {
  return (
    <span aria-label={ariaLabel}>{isHover ? `${percent}%` : value && numberFormat({ value })}</span>
  )
}
