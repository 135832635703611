import { IResponseEventMessagePopper } from 'entities/Message'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { dataLogStore } from 'entities/DataLog/store/dataLogStore'

const MessagePopper = {
  handle: (data: IResponseEventMessagePopper) => {
    const { user_id, inbox_id } = data

    // TODO: SWR-14336: Will be uncommit and updated with SWR-14336
    // dataLogStore.setItem({
    //   logLevel: 'info',
    //   message: 'EventMessagePopper',
    //   details: {
    //     inboxId: inbox_id,
    //     userId: user_id,
    //   },
    // })
  },
}

export const EventMessagePopperLog = {
  subscribe: () => websocket.on(WebsocketEvent.MessagePopper, MessagePopper.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.MessagePopper, MessagePopper.handle),
}
