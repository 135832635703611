// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TZpDB5HMcVviAfXt8cbl{display:flex;flex-grow:1;justify-content:flex-end}.EqvxcXYvf9qnU5izxZ0W{background:var(--background-primary-inverted-primary);box-shadow:var(--shadow-dropdown2);display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/shared/ui/Modal/ui/RightModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,wBAAA,CAGF,sBACE,qDAAA,CACA,kCAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-grow: 1;\n  justify-content: flex-end;\n}\n\n.content {\n  background: var(--background-primary-inverted-primary);\n  box-shadow: var(--shadow-dropdown2);\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "TZpDB5HMcVviAfXt8cbl",
	"content": "EqvxcXYvf9qnU5izxZ0W"
};
export default ___CSS_LOADER_EXPORT___;
