import { observer } from 'mobx-react-lite'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import { SummaryTollFreeItem } from './SummaryTollFreeItem/SummaryTollFreeItem'
import styles from './styles.module.scss'

export const SummaryTollFree = observer(() => {
  const { complianceLayerStore } = useCompliancePageContext()
  const { organizationTollFree } = complianceLayerStore

  if (!organizationTollFree?.items.length) return null

  return (
    <div className={styles.wrap}>
      {organizationTollFree?.items.map((item) => {
        return <SummaryTollFreeItem key={item.id} item={item} />
      })}
    </div>
  )
})
