import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { PageLayout } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import {
  MenuOutboundLayoutContext,
  useMenuOutboundLayoutContext,
} from 'widgets/Menu/ui/MenuOutbound/context/menuOutboundLayoutContext'
import { MenuOutbound } from 'widgets/Menu/ui/MenuOutbound/ui/MenuOutbound'
import { MenuOutboundLayoutStore } from 'widgets/Menu/ui/MenuOutbound/store/menuOutboundLayoutStore'

const MenuOutboundLayoutContent = observer(() => {
  const menuLayoutStore = useMenuOutboundLayoutContext()

  if (!featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return <Outlet />
  }

  return <PageLayout leftContent={<MenuOutbound />} store={menuLayoutStore.pageLayoutStore} />
})

export const MenuOutboundLayout = observer(() => {
  const [menuLayoutStore] = useState(() => new MenuOutboundLayoutStore())

  return (
    <MenuOutboundLayoutContext.Provider value={menuLayoutStore}>
      <MenuOutboundLayoutContent />
    </MenuOutboundLayoutContext.Provider>
  )
})
