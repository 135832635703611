import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { ActionItem, showToast } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { AdminCustomPriceApi, AdminOrganizationsApi, ICustomPrice } from 'entities/Admin'
import { AdminRoutes } from 'pages/admin'

class AdminCustomPricesTableStore {
  loading = false

  items: ICustomPrice[] = []
  visibleColumnsIds: string[] = []

  search = ''
  page = 1
  length = 10
  total = 0

  constructor() {
    makeAutoObservable(this)
    reaction(() => this.paramsGetOrganizations, this.loadData, {
      delay: 500,
    })
  }

  get paramsGetOrganizations() {
    return {
      search: this.search,
      page: this.page,
      length: this.length,
    }
  }

  updateSearch = (value: string) => {
    runInAction(() => {
      this.search = value
      this.page = 1
    })
  }

  loadData = async () => {
    runInAction(() => {
      this.loading = true
    })

    const { data } = await AdminOrganizationsApi.getCustomPrice({
      page: this.page,
      length: this.length,
      search: this.search,
    })

    runInAction(() => {
      this.items = data.data
      this.loading = false
      this.total = data.total
    })
  }

  onPaginationModelChange = (page: number, length: number) => {
    this.page = page
    this.length = length
  }

  setVisibleColumnsIds = (ids: string[]) => {
    this.visibleColumnsIds = ids
  }

  deleteCustomPrice = async (id: number) => {
    this.loading = true

    try {
      await AdminCustomPriceApi.removeCustomPrices(id)

      showToast({
        type: 'success',
        title: 'Custom plan will be successfully deleted',
      })

      await this.loadData()
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
      this.loading = false
    }
  }

  publishCustomPrice = async (id: number) => {
    this.loading = true

    try {
      await AdminCustomPriceApi.publishCustomPrice(id)

      showToast({
        type: 'success',
        title: 'Custom plan will be successfully deleted',
      })

      await this.loadData()
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
      this.loading = false
    }
  }

  get rows() {
    return this.items.map((item) => ({
      ...item,
      actionsProps: {
        showItems: 0,
        actions: this.getCustomPriceActions(item),
      },
    }))
  }

  navigateToEdit = (id: number) => {
    uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.customPrices}/${id}`)
  }

  getCustomPriceActions = (customPrice: ICustomPrice) => {
    const items: ActionItem[] = [
      {
        icon: 'edit02',
        text: 'Edit',
        onAction: () => this.navigateToEdit(customPrice.id),
      },
    ]

    if (customPrice.status === 'draft') {
      items.unshift({
        icon: 'forward',
        text: 'Publish',
        onAction: () => this.publishCustomPrice(customPrice.id),
      })

      items.push({
        icon: 'delete',
        text: 'Delete',
        onAction: () => {
          const id = nanoid()

          modalStore.addModal({
            disabledOnAllClose: true,
            id,
            type: ModalTypeList.FAIL,
            title: 'Delete custom price?',
            desc: 'This action cannot be undone.',
            primaryAction: {
              text: 'Delete',
              onAction: async () => {
                await this.deleteCustomPrice(customPrice.id)
                modalStore.removeModal(id)
              },
            },
            secondaryAction: {
              text: 'Cancel',
              onAction: () => modalStore.closeModal(id),
            },
          })
        },
      })
    }

    return items
  }
}

export const adminCustomPricesTableStore = new AdminCustomPricesTableStore()
