import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Avatar, IconButton } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'
import styles from './styles.module.scss'

export const ConversationSearchItems = observer(() => {
  const { items, handleDeleteItem, readOnly } = useConversationNewSearchContext()

  return (
    <>
      {items.map((item) => {
        return (
          <div
            key={item.id}
            className={classnames(styles.item, {
              [styles['item--readOnly']]: readOnly,
            })}
          >
            <div className={styles.item__avatar}>
              <Avatar size={16} fontSize={10} info={item.avatarInfo} />
            </div>
            <div className={styles.item__name}>
              {getLabelAsNumberInternationalFormat(item.name)}
            </div>

            {!readOnly && (
              <div
                className={styles.item__delete}
                onClick={() => {
                  handleDeleteItem(item)
                }}
              >
                <IconButton
                  icon={'close'}
                  color={'transparent'}
                  size={'small'}
                  fontSize={16}
                  ariaLabel='ConversationSearchItems_delete'
                />
              </div>
            )}
          </div>
        )
      })}
    </>
  )
})
