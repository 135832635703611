import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionItem, Button, ContainerHeader } from 'shared/ui'
import type { PageLayoutStore } from 'shared/layout'
import { TriggersRoutes } from 'entities/ExportRouters'

type ITriggersHeaderProps = {
  title: string
  pageLayoutStore: PageLayoutStore
  isTriggerActionShown: boolean
}

export const TriggersHeader = observer(
  ({ title, pageLayoutStore, isTriggerActionShown }: ITriggersHeaderProps) => {
    const { titleIconProps, headerMenuTooltipIconProps, headerMenuIconAction } = pageLayoutStore

    const navigate = useNavigate()
    const onCreateClick = () => {
      navigate(`/${TriggersRoutes.root}/${TriggersRoutes.create}`)
    }

    const actions: ActionItem[] = useMemo(() => {
      if (!isTriggerActionShown) {
        return []
      }
      return [
        {
          iconButtonComponent: (
            <Button
              text={'New trigger'}
              onClick={onCreateClick}
              iconProps={{ icon: 'add' }}
              size={'medium'}
            />
          ),
        },
      ]
    }, [isTriggerActionShown])

    return (
      <ContainerHeader
        height={72}
        title={title}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        actionIconTooltipProps={headerMenuTooltipIconProps}
        titleIconAction={headerMenuIconAction}
        paddingLeft={20}
        paddingRight={20}
        actions={actions}
      />
    )
  }
)
