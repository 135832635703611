import React, { FC, useMemo } from 'react'
import { TriggerTooltip, ITriggerTooltipProps } from 'shared/ui/TriggerTooltip/TriggerTooltip'
import { Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { integrationsStore } from 'entities/Integrations'
import styles from './styles.module.scss'

export const MergeFieldConnectedTooltip: FC<ITriggerTooltipProps> = ({
  trigger,
  refVisibleTooltip,
  onLeaveTooltip,
  parent,
}) => {
  const onReconnect = () => {
    modalStore.closeAllModals()
    uiStore.changeRoute({
      path: '/settings/integrations',
      type: 'vue',
    })
  }

  const title = useMemo(() => {
    if (trigger) {
      const integrationTitle =
        (trigger?.dataset.integration &&
          integrationsStore.integrationsMap.get(trigger?.dataset.integration)?.name) ||
        trigger?.dataset.integration
      return (
        <Typography
          variant={'body-md-regular'}
          tag={'div'}
          className={styles.wrap}
          ariaLabel={'connected'}
        >
          {trigger?.dataset.connected === 'connected'
            ? integrationTitle
            : `${integrationTitle} disconnected.`}
          {trigger?.dataset.connected === 'disconnected' && (
            <span onClick={onReconnect} className={styles.link}>
              Reconnect
            </span>
          )}
        </Typography>
      )
    }
    return <></>
  }, [trigger])

  if (!trigger) {
    return <></>
  }

  return (
    <TriggerTooltip
      trigger={trigger}
      onLeaveTooltip={onLeaveTooltip}
      refVisibleTooltip={refVisibleTooltip}
      color={'black'}
      component={title}
      arrow
      parent={parent}
    />
  )
}
