import { observer } from 'mobx-react-lite'
import { ChatbotInstructionType } from 'entities/Chatbot'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotCollapse } from 'pages/chatbot/ui/ChatbotCollapse'

import styles from './styles.module.scss'
import { PointMaxAttempts } from './PointMaxAttempts'
import { PointAboutConversation } from './PointAboutConversation'
import { PointBusinessInformation } from './PointBusinessInformation'
import { PointHowToRespond } from './PointHowToRespond'
import { PointInstructions } from './PointInstructions'
import { PointKnowledgeBaseUsage } from './PointKnowledgeBaseUsage'

export const StepConfigure = observer(() => {
  const { errors, model } = useChatbotContext()
  const { rag } = featureFlagsStore

  const isNestedError =
    errors.hasError('settings.max_attempts.value') ||
    (rag && errors.hasError('settings.use_knowledge_base')) ||
    errors.hasError('settings.about_conversation') ||
    errors.hasError('settings.business_information') ||
    errors.hasError('settings.how_to_respond') ||
    errors.hasError('settings.instructions')

  const isCustomInstruction = model.type !== ChatbotInstructionType.LeadQualification

  const configureError = isNestedError ? 'Please complete all the fields' : null

  return (
    <ChatbotCollapse
      icon='settings'
      title='Set your conversation style'
      tooltip='Configure the length, tone, and topic of your Textbot’s conversation'
      error={configureError}
    >
      <div className={styles.container}>
        {rag && <PointKnowledgeBaseUsage />}
        <PointMaxAttempts />
        {isCustomInstruction ? (
          <PointInstructions />
        ) : (
          <>
            <PointAboutConversation />
            <PointBusinessInformation />
            <PointHowToRespond />
          </>
        )}
      </div>
    </ChatbotCollapse>
  )
})
