import type { IParamsSendFrom, IResponseSendFrom } from 'entities/Inbox/api/types'

export enum PowerDialerStatus {
  Draft = 'draft',
  InProgress = 'in_progress',
  Paused = 'paused',
  Finished = 'finished',
}

export type ContactFinalStatus = 'contacted' | 'skipped'

export type IParamsCreatePowerDialer = {
  name: string
  contacts_id: number[]
  status: PowerDialerStatus.Draft | PowerDialerStatus.InProgress
  send_from: IParamsSendFrom
}

export type IResponsePowerDialer = {
  id: number
  name: string
  contacts_id_unprocessed: number[]
  contacts_id_enrolled: number[]
  status: PowerDialerStatus
  send_from: IResponseSendFrom
}
