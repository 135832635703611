import { makeAutoObservable } from 'mobx'
import { Icon, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import { type CompliancePageStore } from 'pages/settings/pages/compliance/store/compliancePageStore'
import type { IRegisterSectionProps } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import styles from 'pages/settings/pages/compliance/ui/styles.module.scss'

export class CallingPageStore {
  constructor(private _compliancePageStore: CompliancePageStore) {
    makeAutoObservable(this)
  }

  get billingComplianceInfo() {
    return this._compliancePageStore.complianceLayerStore.billingComplianceInfo
  }

  get summary() {
    return this._compliancePageStore.complianceLayerStore.summary
  }

  get complianceLayerStore() {
    return this._compliancePageStore.complianceLayerStore
  }

  get businessProfile() {
    return this.complianceLayerStore.businessProfile
  }

  get disabledDefault() {
    return !this.isGetAddOn
  }

  get disabledAddOn() {
    if (this.businessProfile?.isStatusEmpty) return false

    return !this.businessProfile?.isStatusApproved
  }

  get isGetAddOn() {
    return Boolean(this.billingComplianceInfo?.subscribed)
  }

  get callingVoiceIntegrityRegisterSectionProps(): IRegisterSectionProps {
    const status = this.voiceIntegrity?.statusVoiceIntegrity
    const sectionType: IRegisterSectionProps['section'] = status
      ? 'border'
      : this.isGetAddOn
      ? 'default'
      : 'gray'

    return {
      section: sectionType,
      title: 'Voice Integrity',
      desc: "Remediate 'Spam Likely' labels on your calls.",
      actionButton: !Boolean(status)
        ? {
            text: 'Register',
            disabled: this.disabledDefault,
            onClick: () => {
              window.publicApiVue?.showVoiceIntegrityModal()
            },
          }
        : undefined,
      statusProps: this._compliancePageStore.getIconCondition({
        status: status,
      }),
    }
  }

  get callingStirShakenRegisterSectionProps(): IRegisterSectionProps {
    const status = this.shakenStir?.status
    const sectionType: IRegisterSectionProps['section'] = status
      ? 'border'
      : this.isGetAddOn
      ? 'default'
      : 'gray'

    return {
      section: sectionType,
      title: 'STIR / SHAKEN',
      desc: 'Increase trust by showing "Caller Verified."',
      actionIcon: Boolean(status)
        ? {
            onClick: () => {
              window.publicApiVue?.showShakenStirDeleteModal()
            },
          }
        : undefined,
      actionButton: !Boolean(status)
        ? {
            text: 'Register',
            disabled: this.disabledDefault,
            onClick: () => {
              window.publicApiVue?.showShakenStirAdvanced()
            },
          }
        : undefined,
      statusProps: this._compliancePageStore.getIconCondition({
        status: status,
      }),
    }
  }

  get callingCnamCallerIdRegisterSectionProps(): IRegisterSectionProps {
    const hasCnam = Boolean(this.organizationCnam?.hasCnam)
    const sectionType: IRegisterSectionProps['section'] = hasCnam
      ? 'border'
      : this.isGetAddOn
      ? 'default'
      : 'gray'

    return {
      section: sectionType,
      title: 'CNAM Caller ID',
      desc: "Brand your outgoing calls with your company's name.",
      actionButton: {
        text: hasCnam ? 'Add Caller ID' : 'Register',
        icon: hasCnam ? 'add' : undefined,
        contained: hasCnam ? 'secondary' : undefined,
        disabled: this.disabledDefault,
        onClick: () => {
          window.publicApiVue?.showCNAMCallerID()
        },
      },
      items: this.organizationCnam?.cnam.length
        ? this.organizationCnam.cnam.map((item) => {
            return {
              id: item.id,
              title: item.name,
              desc: `${item.count} ${item.count === 1 ? 'number' : 'numbers'}`,
              statusProps: this._compliancePageStore.getIconCondition({
                status: item.status,
              }),
              actionIcon: item.showActions
                ? {
                    onClick: (id) => {
                      const item = this.organizationCnam?.getItem(id)

                      if (item) {
                        window.publicApiVue?.showCNAMCallerDelete(item.origin)
                      }
                    },
                  }
                : undefined,
            }
          })
        : [],
    }
  }

  get callingHeaderSectionProps(): IRegisterSectionProps {
    return {
      section: this.isGetAddOn ? 'default' : 'gray',
      title: 'Trusted Calling',
      desc: (
        <Typography ariaLabel='calling_verification_desc' variant='body-md-regular' tag='div'>
          Recommended for calling <Icon icon='arrowRight' fontSize={15} className={styles.arrow} />
          to
          <Icon className={styles.flag} icon='flagUSA' />
          US and <Icon className={styles.flag} icon='flagCanada' /> Canada. $10.00 / month.
        </Typography>
      ),
      learnMoreLink: links.complianceCallingLearnMoreLink,
      learnMoreLinkClassName: 'newLineLink',
      actionButton: !this.isGetAddOn
        ? {
            text: 'Get add-on',
            disabled: this.disabledAddOn,
            onClick: () => {
              if (this.businessProfile?.isNotReady) {
                this.showBusinessProfileRegisterModal()

                return
              }

              window.publicApiVue?.showTrustedCallingBuy('trust_calling')
            },
          }
        : undefined,
    }
  }

  showBusinessProfileRegisterModal = () => {
    this._compliancePageStore.businessProfilePageStore.openBusinessProfileRegisterModal('calling')
  }

  get voiceIntegrity() {
    return this.complianceLayerStore.voiceIntegrity
  }

  get shakenStir() {
    return this.complianceLayerStore.shakenStir
  }

  get organizationCnam() {
    return this.complianceLayerStore.organizationCnam
  }
}
