import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownCard, type IDropdownItem, Status } from 'shared/ui'
import { links } from 'shared/constants/links'
import { usePowerDialerAvailability } from 'widgets/PowerDialerEditor/hooks/usePowerDialerAvailability'
import {
  type IPowerDialerEditorContactsProps,
  PowerDialerEditorStore,
} from 'widgets/PowerDialerEditor/store/powerDialerEditorStore'
import styles from './styles.module.scss'

type IPowerDialerActionDropdownItemProps = {
  onClick: () => void
  contactsData?: IPowerDialerEditorContactsProps
}

export const PowerDialerEditorDropdownItem = observer(
  ({ onClick, contactsData }: IPowerDialerActionDropdownItemProps) => {
    const fromContacts = !!contactsData
    const [store] = useState(() => new PowerDialerEditorStore())
    const { isCallOutboundDisabled, hideButton, disabled, getTooltipLabel } =
      usePowerDialerAvailability()
    const isDropdownItemDisabled = disabled || store.loading

    if (hideButton) return null

    const label = getTooltipLabel() || (
      <>
        Autodial multiple contacts <br />
        <a href={`${links.powerDialer}`} target='_blank' rel='noreferrer'>
          Learn more
        </a>
      </>
    )

    const item: IDropdownItem = {
      id: 'power_dialer',
      iconL: 'phoneRightArrows',
      label: 'Power Dialer',
      disabled: isDropdownItemDisabled,
      labelContent: (
        <div className={styles.dropdownLabel}>
          Power Dialer
          <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
        </div>
      ),
      ...(!fromContacts
        ? {
            iconQuestionProps: {
              fontSize: 14,
            },
            tooltipQuestionProps: {
              margin: 18,
              type: isCallOutboundDisabled ? 'default' : 'description',
              placement: 'right',
              label: label,
            },
          }
        : {}),
    }

    return (
      <DropdownCard
        item={item}
        onChange={async () => {
          if (fromContacts) {
            await store.handleStartPowerDialerEditorFromContacts(
              contactsData.total,
              contactsData.contactsParams,
              contactsData.selectedIds,
              contactsData.bulkAll
            )
          } else {
            store.handleStartPowerDialerEditorFromFilters()
          }
          onClick?.()
        }}
      />
    )
  }
)
