import { makeAutoObservable } from 'mobx'
import { IResponseQuickLink } from '../api'

export type IQuickLink = QuickLink

export class QuickLink {
  constructor(private _origin: IResponseQuickLink) {
    makeAutoObservable(this)
  }
  get id() {
    return this._origin.id
  }

  get url() {
    return this._origin.url
  }

  get label() {
    return this._origin.label
  }

  get disabled() {
    return this._origin.is_disabled
  }

  syncOrigin(origin: IResponseQuickLink) {
    this._origin = origin
  }
}
