// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dRc1pdXSvkHA7OVHZVMl{text-decoration:none}.dRc1pdXSvkHA7OVHZVMl .tH8xEIl8wDhJixtUH4dG{white-space:nowrap}.MqSPNgfjnQooGR8La1RB{margin:0}.sd43p72FJHkvHoH72UEb{display:flex;flex-direction:column;gap:24px;margin-top:32px}.jiiMXj9pMTxV4ZqEgzuW{display:flex;flex-direction:column;gap:12px}.jiiMXj9pMTxV4ZqEgzuW .aGyW_Xyde8GBjEwORzom{text-align:center}.KiG5xvk4B6KRLpcYmQcJ{margin-top:16px}.KiG5xvk4B6KRLpcYmQcJ .WPg_DFbX50NmpQU0HrqQ{min-height:200px;display:flex;align-items:center;justify-content:center}.KiG5xvk4B6KRLpcYmQcJ .lCseooguSQctZEU2ZpdA{min-height:40px}", "",{"version":3,"sources":["webpack://./src/entities/Phone/ui/GetNumberModal/ui/GetNumberModalDefault/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAEA,4CACE,kBAAA,CAIJ,sBACE,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,4CACE,iBAAA,CAIJ,sBACE,eAAA,CAEA,4CACE,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,4CACE,eAAA","sourcesContent":[".alertAction {\n  text-decoration: none;\n\n  .alertActionText {\n    white-space: nowrap;\n  }\n}\n\n.textFieldIcon {\n  margin: 0;\n}\n\n.contentWrap {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 32px;\n}\n\n.actionsWrap {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  .text {\n    text-align: center;\n  }\n}\n\n.numbersWrap {\n  margin-top: 16px;\n\n  .loader {\n    min-height: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .noResults {\n    min-height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertAction": "dRc1pdXSvkHA7OVHZVMl",
	"alertActionText": "tH8xEIl8wDhJixtUH4dG",
	"textFieldIcon": "MqSPNgfjnQooGR8La1RB",
	"contentWrap": "sd43p72FJHkvHoH72UEb",
	"actionsWrap": "jiiMXj9pMTxV4ZqEgzuW",
	"text": "aGyW_Xyde8GBjEwORzom",
	"numbersWrap": "KiG5xvk4B6KRLpcYmQcJ",
	"loader": "WPg_DFbX50NmpQU0HrqQ",
	"noResults": "lCseooguSQctZEU2ZpdA"
};
export default ___CSS_LOADER_EXPORT___;
