// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZQm0dx9Wfmy2dqJ71ZuW{margin-top:32px;margin-bottom:16px}.jlHJaEYXhQEyxLv3AUcB{display:flex;gap:4px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/pages/CustomPlan/ui/ManualInvoice/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,OAAA","sourcesContent":[".wrap {\n  margin-top: 32px;\n  margin-bottom: 16px;\n}\n.label {\n  display: flex;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ZQm0dx9Wfmy2dqJ71ZuW",
	"label": "jlHJaEYXhQEyxLv3AUcB"
};
export default ___CSS_LOADER_EXPORT___;
