import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { RouterLink } from 'widgets/Sidebar/ui/MainDropdown/RouterLink'
import styles from 'widgets/Sidebar/ui/MainDropdown/styles.module.scss'

type IUpgradeSubscriptionBtnProps = {
  onClick?: () => void
}

export const UpgradeSubscriptionBtn: FC<IUpgradeSubscriptionBtnProps> = observer(({ onClick }) => {
  const isUpgradeDisabled =
    !subscriptionStore.subscriptionSuggestion &&
    (!!subscriptionStore.plan?.custom || organizationStore.is_contract)

  if (!usersStore.user?.isAdminOrOwnerRole) return null

  return (
    <div
      className={styles.blockWrapper}
      aria-label={getAriaLabel('UpgradeSubscriptionBtn')}
      onClick={() => {
        onClick?.()
      }}
    >
      {isUpgradeDisabled ? (
        <Button
          id='intercom-custom-plan_sidebar-button'
          text='Upgrade'
          intent='positive'
          fullWidth
        />
      ) : (
        <RouterLink route='/settings/billing/overview/upgrade'>
          <Button text='Upgrade' intent='positive' fullWidth />
        </RouterLink>
      )}
    </div>
  )
})
