import { observer } from 'mobx-react-lite'
import { Navigate, Outlet } from 'react-router'
import { useEffect, useMemo } from 'react'
import { PageLayout } from 'shared/layout'
import { type IMenuItemProps, Menu, Status, Typography } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { integrationsStore } from 'entities/Integrations'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { useMenuAIAutomationLayoutContext } from 'widgets/Menu/ui/MenuAIAutomation'
import { ChatbotLayoutStore } from 'pages/chatbot'
import { ChatbotRoutes } from 'pages/chatbot/route/type'
import { ChatbotLayoutContext } from 'pages/chatbot/context/chatbotLayoutContext'
import styles from './styles.module.scss'

const menuItems: IMenuItemProps[] = [
  {
    key: 'all_textbots',
    type: 'nav',
    icon: 'chatBot',
    to: ChatbotRoutes.all,
    label: (
      <Typography variant={'body-md-regular'} className={styles.label} ariaLabel={'All Textbots'}>
        All Textbots
      </Typography>
    ),
    rightContent: <Status title={'Beta'} intent={'warning'} emphasis={'high'} size={'label'} />,
  },
]

export const ChatbotLayout = observer(() => {
  const menuLayoutStore = useMenuAIAutomationLayoutContext()
  const pageStore = useMemo(() => new ChatbotLayoutStore(menuLayoutStore.pageLayoutStore), [])
  const { chatbot } = featureFlagsStore

  if (!chatbot) return <Navigate to={'/'} />

  useEffect(() => {
    Promise.all([integrationsStore.fetchIntegrations(), allMergeFieldsStore.loadAllFields()])
  }, [])

  if (featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return (
      <ChatbotLayoutContext.Provider value={pageStore}>
        <Outlet />
      </ChatbotLayoutContext.Provider>
    )
  }

  return (
    <ChatbotLayoutContext.Provider value={pageStore}>
      <PageLayout
        leftContent={
          <Menu title='AI Textbot' menuItems={menuItems} bottomNode={<TrialProgress />} />
        }
        store={pageStore.pageLayoutStore}
      />
    </ChatbotLayoutContext.Provider>
  )
})
