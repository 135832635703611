import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
  DropdownItem,
  DropdownPlaceholder,
  EnumDropdownItemVariant,
  Icon,
  IDropdownItem,
  Tooltip,
  Typography,
} from 'shared/ui'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { Source } from 'entities/Billing/model/Source'
import { billingStore } from 'entities/Billing'
import { useDropdownCarsContext } from 'widgets/DropdownCards'
import styles from './styles.module.scss'

export const DropdownCardsUi = observer(() => {
  const {
    activeId,
    onChange,
    activeItem,
    sources,
    labelText,
    labelTooltip,
    onAddPaymentMethod,
    paymentError,
    setFirstActive,
  } = useDropdownCarsContext()
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  useEffect(() => {
    !activeId && setFirstActive()
  }, [])

  const renderItem = (item: Source | null, active?: boolean) => {
    if (!item) {
      return (
        <Typography
          variant={'text-input-field'}
          color={'var(--content-neutral-primary)'}
          ariaLabel={'DropdownCardsPlaceholder'}
        >
          Select card
        </Typography>
      )
    }

    const isBackup = billingStore.autorecharge?.backup === item.id

    return (
      <div
        className={classnames(styles.dropdownItem, {
          [styles['dropdownItem--active']]: active,
        })}
      >
        <div className={styles.dropdownItem__box}>
          <div className={styles.dropdownItem__icon}>
            <Icon icon={item.icon} fontSize={item.object === 'card' ? 32 : 20} />
          </div>

          <Typography
            tag={'div'}
            variant={'text-input-field'}
            className={styles.dropdownItem__name}
            ariaLabel={'name'}
          >
            {item.name}
          </Typography>
        </div>
        <div className={styles.dropdownItem__box}>
          {item.isPrimary && (
            <Typography
              tag={'div'}
              variant={'body-md-regular'}
              className={styles.dropdownItem__tag}
              ariaLabel={'Primary'}
            >
              Primary
            </Typography>
          )}
          {!item.isPrimary && isBackup && (
            <Typography
              tag={'div'}
              variant={'body-md-regular'}
              className={styles.dropdownItem__tag}
              ariaLabel={'Primary'}
            >
              Backup
            </Typography>
          )}
          <Typography
            tag={'div'}
            variant={'text-input-field'}
            className={styles.dropdownItem__exp}
            ariaLabel={'exp'}
          >
            {item.exp}
          </Typography>
        </div>
      </div>
    )
  }

  const items: IDropdownItem[] = sources.map((item) => {
    return {
      id: item.id,
      data: item,
      label: `${item.name}`,
      labelContent: renderItem(item),
      activeValue: item.id === activeId,
      renderOption: (item) => renderItem(item.data as Source, item.id === activeId),
      variant: EnumDropdownItemVariant.Custom,
      className: styles.dropdownWrap,
    }
  })

  return (
    <DropdownPlaceholder
      items={items}
      onChange={onChange}
      show={isDropdownOpen}
      onToggle={(value) => {
        setDropdownOpen(value)
      }}
      placeholder={renderItem(activeItem, true)}
      error={paymentError}
      topListComponent={() => (
        <Label
          label={{
            text: 'Select card',
            className: styles.labelInnerDropdown,
          }}
        />
      )}
      textPlaceholderProps={{
        size: 'medium',
        variant: 'stroke',
        rightActions: [makeTextFieldIcon({ icon: 'chevronDown', margin: 0 })],
        className: styles.textPlaceholder,
      }}
      label={{
        text: [
          labelText,
          labelTooltip && (
            <Tooltip {...labelTooltip}>
              <Icon icon={'question'} color={'var(--content-neutral-primary)'} />
            </Tooltip>
          ),
        ],
        textClassName: styles.labelTextDropdown,
        className: styles.labelDropdown,
      }}
      zIndex={5110}
      bottomActions={
        <DropdownItem
          item={{
            id: 'add_new_card',
            label: 'Add new card',
            iconL: 'add',
            onClick: () => {
              onAddPaymentMethod()
              setDropdownOpen(!isDropdownOpen)
            },
          }}
        />
      }
    />
  )
})
