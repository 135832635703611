import { useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import classnames from 'classnames'
import { TooltipEmailDomain } from 'shared/ui/Tooltip/ui/TooltipEmailDomain/TooltipEmailDomain'
import {
  Avatar,
  Button,
  Dropdown,
  DropdownContent,
  EmptyState,
  Icon,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { DatePickerTextField } from 'shared/ui/DatePicker/DatePickerTextField'
import {
  InputText,
  InputPhone,
  InputNumeric,
  TextField,
  makeTextFieldActionTooltip,
  makeTextFieldInteractions,
  makeTextFieldClear,
  makeTextFieldTooltip,
  makeTextFieldBlurOnEnter,
  makeTextFieldAutoFocus,
} from 'shared/ui/TextField'
import { contactsStore, CustomFieldTypes, IResponseCustomField } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { IUser, usersStore } from 'entities/Users'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { ContactsSearch } from 'widgets/ContactsSearch'
import { PhoneInput } from '../ui/PhoneInput'
import { MainFiledTypes } from '../store/contactInfoFormStore'
import { OrganizationMember } from './OrganizationMember'

import styles from './styles.module.scss'

export type IContactsInfoFormProps = {
  isNewContactMode?: boolean
}
export const ContactsInfoForm: FC<IContactsInfoFormProps> = observer(({ isNewContactMode }) => {
  const {
    handleUpdateContact,
    contactInfoFormStore,
    selectedContact,
    isVariantCreateContactModal,
    addNewContactLocallyAfterCreate,
    isFirstEditMode,
    setIsFirstEditMode,
  } = useContactsDetailsContext()
  const {
    hasCustomFields,
    getVisibleCustomFieldsList,
    mainFields,
    searchOwner,
    errors,
    updatingFields,
    hasMembers,
    membersList,
    membersCount,
    handleSaveOwner,
    clearSearchOwner,
    handleSearchOwner,
    handleSaveMainField,
    handleSaveCustomField,
    handleUpdateCustomField,
    handleUpdateCustomDateField,
    handleUpdateMainField,
    formattedNumber,
    errorText,
    isValidNumberLoading,
    debounceIsValidNumber,
  } = contactInfoFormStore
  const { toggleBlockView, isOpenBlock } = contactsDetailsGlobalStore

  const { pathname } = useLocation()
  const [isValidated, setIsValidated] = useState(false)
  const [firstEditInputNumber, setFirstEditInputNumber] = useState(0)

  const isContactPage = pathname.includes('contacts')
  const contact = contactsStore.getItem(selectedContact?.id)

  const checkFirstEditMode = (isEnterPressed: boolean, nextActiveInputNumber?: number) => {
    if (!nextActiveInputNumber || !isEnterPressed) {
      setIsFirstEditMode(false)

      return
    }

    setFirstEditInputNumber(nextActiveInputNumber)
  }

  const saveMainField = (field: MainFiledTypes, contact: Contact) => {
    handleSaveMainField(field, contact, handleUpdateContact)
  }

  const customFieldIcon = (fieldType: CustomFieldTypes | undefined) => {
    switch (fieldType) {
      case CustomFieldTypes.date:
        return 'calendar'
      case CustomFieldTypes.number:
        return 'number'
      case CustomFieldTypes.url:
        return 'link'
      case CustomFieldTypes.text:
        return 'textInput'
      default:
        return 'textInput'
    }
  }

  const customFieldType = (fieldType: CustomFieldTypes | undefined) => {
    switch (fieldType) {
      case CustomFieldTypes.number:
        return InputNumeric
      default:
        return InputText
    }
  }

  const CustomFieldLabel: FC<{ customField: IResponseCustomField }> = ({ customField }) => (
    <span className={styles.label}>
      <div className={styles.icon}>
        <Icon icon={customFieldIcon(customField.type)} />
      </div>
      <Typography className={styles.name} variant='text-input-field-r' tag='div' ariaLabel={'name'}>
        {customField.name}
      </Typography>
    </span>
  )

  const phoneField = () => {
    const maxFormattedNumberLength = formattedNumber.includes('+') ? 13 : 12

    const fullyNumber =
      formattedNumber.length >= 10 && formattedNumber.length <= maxFormattedNumberLength

    const showContactSearch = !errorText && fullyNumber && !isValidNumberLoading

    const validateNumber = async (phoneNumber: string) => {
      debounceIsValidNumber?.(String(phoneNumber))
    }

    return (
      <>
        <PhoneInput
          value={formattedNumber}
          errorText={errorText}
          isLoading={isValidNumberLoading}
          modalView={isVariantCreateContactModal}
          onBlurField={() => {
            if (!isValidated) validateNumber(formattedNumber)
          }}
          onChangeValue={(value) => {
            const hasPlusSign = value.startsWith('+')

            let correctFormatNumber = value.replace(/\D/g, '')

            if (hasPlusSign) {
              correctFormatNumber = '+' + correctFormatNumber
            }

            const numberLength = correctFormatNumber.length
            const maxNumberLength = correctFormatNumber.includes('+') ? 13 : 12

            if (numberLength > maxNumberLength) return

            if (correctFormatNumber.length >= 10 && correctFormatNumber.length <= maxNumberLength) {
              setIsValidated(true)
              validateNumber(correctFormatNumber)
            }
            if (numberLength < 10 && isValidated) {
              setIsValidated(false)
            }

            handleUpdateMainField(MainFiledTypes.number, correctFormatNumber)
          }}
        />
        {showContactSearch && (
          <div
            className={classnames(styles.contactsSearch, {
              [styles.modalView]: isVariantCreateContactModal,
            })}
          >
            <ContactsSearch
              withBorder
              search={formattedNumber}
              onChange={(contact, params) => {
                addNewContactLocallyAfterCreate?.(contact, params)
              }}
              paramsCreateContact={{ fromConversation: false }}
            />
          </div>
        )}
      </>
    )
  }

  const isVisibleOnlyCustomFields = !isContactPage && !isVariantCreateContactModal

  if (!contact && !isNewContactMode) return null

  return (
    <div className={styles.wrap}>
      {isNewContactMode && <div className={styles.contactDetailsField}>{phoneField()}</div>}
      {!isNewContactMode && (
        <div className={styles.field}>
          <span className={styles.label}>
            <div className={styles.icon}>
              <Icon icon='phone' />
            </div>
            <Typography
              className={styles.name}
              variant='text-input-field-r'
              tag='div'
              ariaLabel={'Phone'}
            >
              Phone
            </Typography>
          </span>
          <TextField
            className={classnames(styles.value, {
              [styles.modalView]: isVariantCreateContactModal,
            })}
            Input={InputPhone}
            InputProps={{
              placeholder: 'Phone',
            }}
            size='small'
            variant='outline'
            value={mainFields.formatted_number}
            readOnly={updatingFields.has(MainFiledTypes.number)}
            disabled={updatingFields.has(MainFiledTypes.number) || !contact}
            onBlur={() => {
              contact && saveMainField(MainFiledTypes.number, contact)
            }}
            onChange={(value) => handleUpdateMainField(MainFiledTypes.number, value, true)}
            rightActions={[
              makeTextFieldInteractions({
                type: 'copy',
                active: Boolean(mainFields.formatted_number),
              }),
              makeTextFieldClear(),
            ]}
            mainActions={[
              makeTextFieldBlurOnEnter(),
              makeTextFieldActionTooltip({
                color: 'red',
                placement: 'left',
                active: errors.has('formatted_number'),
                message: errors.get('formatted_number'),
                onClick: () => errors.delete('formatted_number'),
              }),
            ]}
          />
        </div>
      )}
      <div className={styles.field}>
        <span
          className={classnames(styles.label, {
            [styles.disabled]: isNewContactMode,
          })}
        >
          <div className={styles.icon}>
            <Icon icon='user' />
          </div>
          <Typography
            className={styles.name}
            variant='text-input-field-r'
            tag='div'
            ariaLabel={'FirstName'}
          >
            First name
          </Typography>
        </span>
        <TextField
          className={classnames(styles.value, {
            [styles.modalView]: isVariantCreateContactModal,
          })}
          size='small'
          variant='outline'
          InputProps={{
            placeholder: 'First name',
            autoComplete: 'off',
          }}
          value={mainFields.first_name}
          disabled={updatingFields.has(MainFiledTypes.firstName) || !contact}
          onChange={(value) => handleUpdateMainField(MainFiledTypes.firstName, value, true)}
          onBlur={(_, { isTrusted }) => {
            checkFirstEditMode(!isTrusted, 1)

            contact && saveMainField(MainFiledTypes.firstName, contact)
          }}
          rightActions={[
            makeTextFieldInteractions({ type: 'copy', active: Boolean(mainFields.first_name) }),
            makeTextFieldClear(),
          ]}
          mainActions={[
            makeTextFieldAutoFocus({ withFocus: isFirstEditMode && firstEditInputNumber === 0 }),
            makeTextFieldBlurOnEnter(),
            makeTextFieldTooltip(),
            makeTextFieldActionTooltip({
              color: 'red',
              placement: 'left',
              active: errors.has('first_name'),
              message: errors.get('first_name'),
              onClick: () => errors.delete('first_name'),
            }),
          ]}
        />
      </div>
      <div className={styles.field}>
        <span
          className={classnames(styles.label, {
            [styles.disabled]: isNewContactMode,
          })}
        >
          <div className={styles.icon}>
            <Icon icon='user' />
          </div>
          <Typography
            className={styles.name}
            variant='text-input-field-r'
            tag='div'
            ariaLabel={'LastName'}
          >
            Last name
          </Typography>
        </span>
        <TextField
          className={classnames(styles.value, {
            [styles.modalView]: isVariantCreateContactModal,
          })}
          size='small'
          variant='outline'
          InputProps={{
            placeholder: 'Last name',
            autoComplete: 'off',
          }}
          value={mainFields.last_name}
          disabled={updatingFields.has(MainFiledTypes.lastName) || !contact}
          onChange={(value) => handleUpdateMainField(MainFiledTypes.lastName, value, true)}
          onBlur={(_, { isTrusted }) => {
            checkFirstEditMode(!isTrusted, 2)

            contact && saveMainField(MainFiledTypes.lastName, contact)
          }}
          rightActions={[
            makeTextFieldInteractions({ type: 'copy', active: Boolean(mainFields.last_name) }),
            makeTextFieldClear(),
          ]}
          mainActions={[
            makeTextFieldBlurOnEnter(),
            makeTextFieldAutoFocus({ withFocus: isFirstEditMode && firstEditInputNumber === 1 }),
            makeTextFieldTooltip(),
            makeTextFieldActionTooltip({
              color: 'red',
              placement: 'left',
              active: errors.has('last_name'),
              message: errors.get('last_name'),
              onClick: () => errors.delete('last_name'),
            }),
          ]}
        />
      </div>
      <div className={styles.field}>
        <span
          className={classnames(styles.label, {
            [styles.disabled]: isNewContactMode,
          })}
        >
          <div className={styles.icon}>
            <Icon icon='email' />
          </div>
          <Typography
            className={styles.name}
            variant='text-input-field-r'
            tag='div'
            ariaLabel={'Email'}
          >
            Email
          </Typography>
        </span>
        <TextField
          className={classnames(styles.value, {
            [styles.modalView]: isVariantCreateContactModal,
          })}
          size='small'
          variant='outline'
          InputProps={{
            placeholder: 'Email',
            autoComplete: 'off',
          }}
          value={mainFields.email}
          disabled={updatingFields.has(MainFiledTypes.email) || !contact}
          onChange={(value) => handleUpdateMainField(MainFiledTypes.email, value, true)}
          onBlur={(_, { isTrusted }) => {
            checkFirstEditMode(!isTrusted)

            contact && saveMainField(MainFiledTypes.email, contact)
          }}
          rightActions={[
            makeTextFieldInteractions(
              { type: 'copy', active: Boolean(mainFields.email) },
              { type: 'email', active: Boolean(mainFields.email) }
            ),
            makeTextFieldClear(),
          ]}
          mainActions={[
            makeTextFieldBlurOnEnter(),
            makeTextFieldAutoFocus({ withFocus: isFirstEditMode && firstEditInputNumber === 2 }),
            makeTextFieldTooltip({
              label: <TooltipEmailDomain email={mainFields.email} />,
              interactive: true,
            }),
            makeTextFieldActionTooltip({
              color: 'red',
              placement: 'left',
              active: errors.has('email'),
              message: errors.get('email'),
              onClick: () => errors.delete('email'),
            }),
          ]}
        />
      </div>
      <div className={styles.field}>
        <span
          className={classnames(styles.label, {
            [styles.disabled]: isNewContactMode,
          })}
        >
          <div className={styles.icon}>
            <Icon icon='userFilled' />
          </div>
          <Typography
            className={styles.name}
            variant='text-input-field-r'
            tag='div'
            ariaLabel={'Owner'}
          >
            Owner
          </Typography>
        </span>

        <div
          className={classnames(styles.valueWrapper, {
            [styles.modalView]: isVariantCreateContactModal,
          })}
        >
          <Dropdown
            ariaLabel={'ContactsInfoForm'}
            width={192}
            margin={-32}
            placement={'bottom'}
            typeButton={'icon-button'}
            disabled={usersStore.user.isViewOnlyRole || !contact}
            onClose={clearSearchOwner}
            triggerComponent={() => {
              if (updatingFields.has(MainFiledTypes.owner)) {
                return (
                  <span className={classnames(styles.value)}>
                    <div className={styles.icon}>
                      <SpinnerLoader size={16} />
                    </div>
                    <Typography
                      className={styles.name}
                      variant='text-input-field-r'
                      pointer
                      ariaLabel={'Reassigning'}
                    >
                      Reassigning
                    </Typography>
                  </span>
                )
              }

              if (!mainFields.owner) {
                return (
                  <span
                    className={classnames(styles.value, styles.action, {
                      [styles.disabled]: isNewContactMode,
                    })}
                  >
                    <div className={styles.icon}>
                      <Icon icon='userFilled' />
                    </div>
                    <Typography
                      className={styles.name}
                      variant='text-input-field-r'
                      pointer
                      ariaLabel={'No owner'}
                    >
                      No owner
                    </Typography>
                  </span>
                )
              }

              return (
                <span className={classnames(styles.value, styles.action)}>
                  <div className={styles.icon}>
                    <Avatar info={mainFields.owner.avatarInfo} size={16} />
                  </div>
                  <Typography
                    className={styles.name}
                    variant='text-input-field-r'
                    ellipsis
                    pointer
                    ariaLabel={'name'}
                  >
                    {mainFields.owner.name}
                  </Typography>
                </span>
              )
            }}
            customComponent={(onCloseMenu) => (
              <DropdownContent noPadding>
                <div className={styles.dropdownWrapper}>
                  <div className={styles.searchWrapper}>
                    <TextField
                      size='small'
                      variant='integrated'
                      InputProps={{
                        placeholder: 'Search',
                      }}
                      value={searchOwner}
                      onChange={handleSearchOwner}
                      mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
                    />
                  </div>
                  <div
                    className={classnames(styles.membersWrapper, {
                      [styles.withScroll]: membersCount > 5,
                    })}
                  >
                    {hasMembers ? (
                      <>
                        {membersList.map((member: IUser, idx: number) => (
                          <OrganizationMember
                            handleOwner={(ownerId) => {
                              onCloseMenu()
                              contact && handleSaveOwner(ownerId, contact, handleUpdateContact)
                            }}
                            active={contact?.owner_id === member.id}
                            key={idx}
                            member={member}
                          />
                        ))}
                      </>
                    ) : (
                      <EmptyState noIcon />
                    )}
                  </div>
                </div>
              </DropdownContent>
            )}
          />
        </div>
      </div>
      {isOpenBlock('contactDetailsAllFields') &&
        getVisibleCustomFieldsList(isVisibleOnlyCustomFields).map((customField) => (
          <div className={styles.field} key={customField.field_key}>
            <CustomFieldLabel customField={customField} />
            {customField.type === CustomFieldTypes.date ? (
              <span
                className={classnames(styles.value, {
                  [styles.modalView]: isVariantCreateContactModal,
                })}
              >
                <DatePickerTextField
                  popperPlacement='bottom-end'
                  placeholder={customField.name}
                  dateFormat='MMM d, yyyy'
                  date={customField.value?.value ? new Date(customField.value?.value) : null}
                  handleClose={() =>
                    contact && handleSaveCustomField(customField, contact, handleUpdateContact)
                  }
                  handleDate={(value) => handleUpdateCustomDateField(customField, value)}
                  withCopy={Boolean(customField.value?.value)}
                  variant='outline'
                  size='small'
                />
              </span>
            ) : (
              <TextField
                className={classnames(styles.value, {
                  [styles.modalView]: isVariantCreateContactModal,
                })}
                size='small'
                variant='outline'
                Input={customFieldType(customField.type)}
                InputProps={{
                  placeholder: customField.name,
                }}
                value={customField.value?.value || ''}
                onChange={(value) => handleUpdateCustomField(customField, value, true)}
                onBlur={() => {
                  contact && handleSaveCustomField(customField, contact, handleUpdateContact)
                }}
                readOnly={updatingFields.has(customField.field_key)}
                rightActions={[
                  makeTextFieldInteractions(
                    { type: 'copy', active: Boolean(customField.value?.value) },
                    {
                      type: 'url',
                      active:
                        customField.type === CustomFieldTypes.url &&
                        Boolean(customField.value?.value),
                    }
                  ),
                  makeTextFieldClear(),
                ]}
                mainActions={[
                  makeTextFieldBlurOnEnter(),
                  makeTextFieldTooltip(),
                  makeTextFieldActionTooltip({
                    color: 'red',
                    placement: 'left',
                    active: errors.has(customField.field_key),
                    message: errors.get(customField.field_key),
                    onClick: () => errors.delete(customField.field_key),
                  }),
                ]}
                disabled={!contact}
              />
            )}
          </div>
        ))}
      {hasCustomFields(isVisibleOnlyCustomFields) && (
        <div className={styles.showMore}>
          <Button
            size='small'
            typeBtn='text'
            disabled={!contact}
            text={isOpenBlock('contactDetailsAllFields') ? 'Hide fields' : 'See fields'}
            onClick={() => toggleBlockView('contactDetailsAllFields')}
          />
        </div>
      )}
    </div>
  )
})
