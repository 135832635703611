export const links = {
  support: 'https://help.salesmessage.com',
  website: 'https://www.salesmessage.com',
  featureRequests: 'https://feedback.salesmessage.com',
  failedMessages:
    'https://help.salesmessage.com/en/articles/6458077-understanding-message-statuses?_ga=2.248595143.1147785932.1692521653-61008499.1688465089',
  enableMicrophone:
    'https://help.salesmessage.com/en/articles/5463800-inbound-outbound-calling-with-salesmsg?&_ga=2.106169539.1569855396.1700134216-710223279.1678805118#h_313708a719',
  importYourContacts: 'https://help.salesmessage.com/en/articles/3192286-import-your-contacts',
  useCustomTriggers: 'https://help.salesmessage.com/en/articles/4232459-how-to-use-custom-triggers',
  termsConditions: 'https://www.salesmessage.com/terms-conditions',
  privacyPolicy: 'https://www.salesmessage.com/privacy-policy',
  conversionRevenueTracking:
    'https://help.salesmessage.com/en/articles/6142934-conversion-revenue-tracking',
  dupleMergeLearnMore: 'https://help.salesmessage.com/en/articles/6896247-contact-cleanup',
  smartInboxes: 'https://help.salesmessage.com/en/articles/6188086-smart-inboxes',
  massMessagingBestPracticesGuidelines:
    'https://help.salesmessage.com/en/articles/3279821-mass-messaging-best-practices-guidelines',
  chatbotGuidelines: 'https://help.salesmessage.com/en/articles/9675140-salesmsg-ai-textbot',
  powerDialer: 'https://help.salesmessage.com/en/articles/9706177-salesmsg-power-dialer',
  phoneСheckr: 'https://help.salesmessage.com/en/articles/5803296-phonecheckr',
  broadcastGuidelines:
    'https://help.salesmessage.com/en/articles/3279821-mass-messaging-best-practices-guidelines',
  appStore: 'https://apps.apple.com/us/app/salesmsg-business-texting/id1450828104',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.salesmessage.arcadia.app',
  workflowHelpGuide:
    'https://help.salesmessage.com/en/articles/7851828-new-salesmsg-workflows-beta',
}
