import { Button, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum } from 'entities/Auth'

export const ExpiredFormBottom = () => {
  const onClick = () => {
    uiStore.changeRoute({
      path: `/${AuthRoutesEnum.login}`,
    })
  }

  return (
    <div>
      <Typography variant={'body-sm-medium'} ariaLabel={'loginLink'}>
        Already have a Salesmsg account?
        <Button onClick={onClick} typeBtn={'link'} text={'Sign in'} />
      </Typography>
    </div>
  )
}
