import { makeAutoObservable, runInAction } from 'mobx'
import { toastStore } from 'shared/ui'
import type { IParamsAutorecharge, IResponseBillingAutorecharge } from 'entities/Billing/api/types'
import { BillingApi } from 'entities/Billing/api/billing'

export class AutorechargeStore {
  private autorecharge: IResponseBillingAutorecharge | null = null
  private _loading = false
  private _backup: null | string = null

  constructor(item: IResponseBillingAutorecharge) {
    makeAutoObservable(this)

    this.setAutoRechargeResponse(item)
  }

  get loading() {
    return this._loading
  }

  private setAutoRechargeResponse = (response: IResponseBillingAutorecharge) => {
    this.autorecharge = response
    this._backup = response.backup
  }

  handleUpdateBillingAutoRecharge = async (params: IParamsAutorecharge, onSuccess?: () => void) => {
    if (!this.autorecharge) return

    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await BillingApi.updateBillingAutoRecharge(params)
      this.setAutoRechargeResponse(data)
      toastStore.add({
        type: 'success',
        title: `Auto-Recharge ${data.is_active ? 'enabled' : 'disabled'}`,
      })
      onSuccess?.()
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  setIsActive = (status: boolean) => {
    if (!this.autorecharge) return
    this.handleUpdateBillingAutoRecharge({
      threshold: this.autorecharge.threshold || 100,
      amount: this.autorecharge.amount || 1000,
      source: this.autorecharge?.source,
      is_active: status,
      backup: this.autorecharge.backup,
    })
  }

  setBackup = (id: string) => {
    this._backup = id
  }

  get is_active() {
    return Boolean(this.autorecharge?.is_active)
  }

  get threshold_in_credits() {
    return this.autorecharge?.threshold_in_credits || null
  }
  get amount_in_credits() {
    return this.autorecharge?.amount_in_credits || null
  }

  get source() {
    return this.autorecharge?.source || null
  }

  get backup() {
    return this._backup
  }
}
