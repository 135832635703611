export const getFileLabelByExtension = (extension: string) => {
  const ext = extension.toLowerCase()

  switch (ext) {
    case 'jpg':
    case 'jpeg':
      return 'JPG image'
    case 'png':
      return 'PNG image'
    case 'gif':
      return 'GIF image'
    case 'mp4':
      return 'MP4 video'
    case 'mov':
      return 'MOV video'
    case 'mp3':
      return 'MP3 audio'
    case 'pdf':
      return 'PDF file'
    case 'doc':
      return 'DOC file'
    case 'docx':
      return 'DOCX file'
    case 'csv':
      return 'CSV file'
    case 'xls':
      return 'XLS file'
    case 'xlsx':
      return 'XLSX file'
    case 'vcf':
      return 'vCard'
    case 'txt':
      return 'TXT file'
    case 'markdn':
    case 'markdown':
    case 'md':
    case 'mdown':
      return 'MD file'
    case 'html':
      return 'HTML file'
    default:
      return 'File'
  }
}
