import { createContext, useContext } from 'react'
import { type MenuAIAutomationLayoutStore } from './../store/menuAIAutomationLayoutStore'

export const MenuAIAutomationLayoutContext = createContext<MenuAIAutomationLayoutStore | null>(null)

export const useMenuAIAutomationLayoutContext = () => {
  const context = useContext(MenuAIAutomationLayoutContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with MenuAIAutomationLayoutContext'
    )

  return context
}
