import { IOptInMethodTypes } from 'entities/Compliance'

export enum TollFreeRoutes {
  root = 'toll-free',
  view = 'view',
  steps = 'steps',
}

export enum TollFreeRoutesSteps {
  numbers = 'numbers',
  businessInfo = 'business-info',
  contactInfo = 'contact-info',
  useCase = 'use-case',
  optInMethod = 'opt-in-method',
  optInOnlineFormContent = 'opt-in-online-form-consent',
  optInPaperFormContent = 'opt-in-paper-form-consent',
  optInViaTextContent = 'opt-in-via-text-consent',
  optInQrCodeContent = 'opt-in-qr-code-consent',
  forbiddenMessageTopics = 'forbidden-message-topics',
}

export const IOptInMethodsMap: Partial<Record<TollFreeRoutesSteps, IOptInMethodTypes>> = {
  [TollFreeRoutesSteps.optInOnlineFormContent]: IOptInMethodTypes.onlineForm,
  [TollFreeRoutesSteps.optInPaperFormContent]: IOptInMethodTypes.paperForm,
  [TollFreeRoutesSteps.optInViaTextContent]: IOptInMethodTypes.text,
  [TollFreeRoutesSteps.optInQrCodeContent]: IOptInMethodTypes.qrCode,
}

export const IOptInYTypeMap: Partial<Record<IOptInMethodTypes, TollFreeRoutesSteps>> = {
  [IOptInMethodTypes.onlineForm]: TollFreeRoutesSteps.optInOnlineFormContent,
  [IOptInMethodTypes.paperForm]: TollFreeRoutesSteps.optInPaperFormContent,
  [IOptInMethodTypes.text]: TollFreeRoutesSteps.optInViaTextContent,
  [IOptInMethodTypes.qrCode]: TollFreeRoutesSteps.optInQrCodeContent,
}
