import { SettingsRoutes } from 'pages/settings'
import { KnowledgeBaseRoutes } from './type'

const knowledgeBase = `${KnowledgeBaseRoutes.root}`
const knowledgeSettingBase = `${SettingsRoutes.root}/${KnowledgeBaseRoutes.root}`

export const KnowledgeBasePath = {
  root: knowledgeBase,
  settingRoot: knowledgeSettingBase,
}
