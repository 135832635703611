import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { IntegrationDetailsStore } from 'pages/settings/pages/integrations/store/integrationDetailsStore'
import { useEventIntegrationDisconnectedSettingsDetails } from 'pages/settings/pages/integrations/events/EventIntegrationDisconnectedSettings'
import { useEventIntegrationConnectedSettingsDetails } from 'pages/settings/pages/integrations/events/EventIntegrationConnectedSettings'

const IntegrationDetailsContext = createContext<IntegrationDetailsStore | null>(null)

export const useIntegrationDetailsContext = () => {
  const context = useContext(IntegrationDetailsContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with IntegrationDetailsProvider'
    )

  return context
}

export const IntegrationDetailsProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams()
  const store = useMemo(() => new IntegrationDetailsStore(id), [])

  useEventIntegrationConnectedSettingsDetails(store)
  useEventIntegrationDisconnectedSettingsDetails(store)

  useEffect(() => {
    void store.getIntegrationDetails()
    return store.dispose
  }, [])

  return (
    <IntegrationDetailsContext.Provider value={store}>
      {children}
    </IntegrationDetailsContext.Provider>
  )
}
