import classNames from 'classnames'
import { Icon, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export type InputCheckboxProps = {
  checked: boolean
  onChecked: () => void
  label?: string
  customLabel?: JSX.Element
  className?: string
}

export const InputCheckbox = ({
  checked,
  onChecked,
  label = '',
  customLabel,
  className,
}: InputCheckboxProps) => {
  return (
    <div className={classNames(styles.wrap, className)} onClick={onChecked}>
      {checked ? (
        <div className={styles.checkboxChecked}>
          <Icon icon={'checkboxChecked'} />
        </div>
      ) : (
        <div className={styles.box} />
      )}
      {customLabel && customLabel}
      {!customLabel && (
        <Typography variant={'text-input-field'} ariaLabel={'label'}>
          {label}
        </Typography>
      )}
    </div>
  )
}
