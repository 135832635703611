// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IdQvZj3pBQXLtMPBF6dL .Q9DDhTRcSLSj__h2mR_Q{max-width:240px;padding:11px 8px 11px 12px}.IdQvZj3pBQXLtMPBF6dL .Q9DDhTRcSLSj__h2mR_Q>div{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.IdQvZj3pBQXLtMPBF6dL .zKti2t5_fKFK2K5VaCRN{padding:12px}.IdQvZj3pBQXLtMPBF6dL .fWJOMqi_RYH4_6BzOIf0{padding:8px;padding-right:9px;min-height:40px;align-items:center}.IdQvZj3pBQXLtMPBF6dL .OT2gYZ8rukf4N4AlG45w{margin:8px 0 24px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/businessProfile/pages/BusinessInfo/ui/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,eAAA,CACA,0BAAA,CAEA,gDACE,aAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAIJ,4CACE,YAAA,CAGF,4CACE,WAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAGF,4CACE,iBAAA","sourcesContent":[".wrap {\n  .businessSelect {\n    max-width: 240px;\n    padding: 11px 8px 11px 12px;\n\n    & > div {\n      display: block;\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n\n  .selectPadding {\n    padding: 12px;\n  }\n\n  .selectRegionsPadding {\n    padding: 8px;\n    padding-right: 9px;\n    min-height: 40px;\n    align-items: center;\n  }\n\n  .noEmailCheckbox {\n    margin: 8px 0 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "IdQvZj3pBQXLtMPBF6dL",
	"businessSelect": "Q9DDhTRcSLSj__h2mR_Q",
	"selectPadding": "zKti2t5_fKFK2K5VaCRN",
	"selectRegionsPadding": "fWJOMqi_RYH4_6BzOIf0",
	"noEmailCheckbox": "OT2gYZ8rukf4N4AlG45w"
};
export default ___CSS_LOADER_EXPORT___;
