import { observer } from 'mobx-react-lite'
import { Fragment, UIEvent, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Icon, SpinnerLoader } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { Activity } from 'entities/Activity/model/Activity'
import { Message } from 'entities/Message/model/Message'
import { MessageCard } from 'widgets/MessageCard'
import { DayContent } from 'widgets/DayContent/DayContent'
import { useEventMessageTranscriptCompleted } from 'widgets/ConversationMessages/events/EventMessageTranscriptCompleted'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { useEventMessageUpdatedNew } from 'widgets/ConversationMessages/events/EventMessageUpdatedNew'
import { useEventMessageReceivedNew } from 'widgets/ConversationMessages/events/EventMessageReceivedNew'
import { useEventVoicemailReceived } from 'widgets/ConversationMessages/events/EventVoicemailReceived'
import { useEventActivityCreated } from 'widgets/ConversationMessages/events/EventActivityCreated'
import { useEventTyping } from 'widgets/ConversationMessages/events/EventTyping'
import styles from './styles.module.scss'

export const ConversationContent = observer(() => {
  const store = useConversationMessagesContext()
  const refWrap = useRef<HTMLDivElement>(null)
  const refBottom = useRef<HTMLDivElement>(null)
  const [visibleArrowBottom, setVisibleArrowBottom] = useState(false)
  const conversation = conversationStore.currentItem
  const isEmpty = Boolean(
    (!Object.entries(store.groupMessagesByDay).length &&
      !store.loadingPrevious &&
      !store.loading) ??
      conversation?.contact_id
  )

  useEffect(() => {
    if (refBottom.current && store.scrollBottomTrigger) {
      refBottom.current.scrollIntoView()
    }
  }, [store.scrollBottomTrigger])

  useEventMessageUpdatedNew(conversationStore, store)
  useEventMessageReceivedNew(conversationStore, store, contactsStore)
  useEventVoicemailReceived(conversationStore, store)
  useEventMessageTranscriptCompleted(conversationStore, store)
  useEventActivityCreated(store)
  useEventTyping(store)

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    if (e.target instanceof Element) {
      const isVisible = e.target.scrollHeight - e.target?.scrollTop - e.target.clientHeight > 100
      setVisibleArrowBottom(isVisible)
      store.setHandleReceiveNewScroll(!isVisible)

      const prevScrollHeight = e.target.scrollHeight || 0
      if (e.target?.scrollTop <= 100 && store.hasPrevious) {
        const onSuccess = () => {
          if (refWrap.current) {
            refWrap.current.scrollTop = refWrap.current.scrollHeight - prevScrollHeight
          }
        }
        store.loadPreviousMessages(onSuccess)
      }
    }
  }

  const groupMessagesByDay = Object.entries(store.groupMessagesByDay)
  const renderEmptyState = () => {
    if (store.isConversationNew) {
      if (
        !store.conversationNewSearchStore?.hasContacts ||
        !store.conversationNewSearchStore?.hasInbox
      )
        return null
    }

    if (isEmpty) {
      return (
        <div className={styles.empty}>
          <div className={styles.empty__icon}>
            <Icon icon={'conversation'} fontSize={24} />
          </div>
          <div className={styles.empty__title}>No conversation yet</div>
          <div className={styles.empty__desc}>Send a message!</div>
        </div>
      )
    }

    return null
  }

  return (
    <div className={styles.wrap} ref={refWrap} onScroll={onScroll}>
      <div className={styles.top}>{renderEmptyState()}</div>
      <div className={styles.center}>
        {(store.loadingPrevious || store.loading) && (
          <div className={styles.loading}>
            <SpinnerLoader size={16} />
          </div>
        )}
        {groupMessagesByDay.map(([day, messages], i) => {
          const scheduledMessages = messages.filter(
            (message) => message instanceof Message && message.is_schedule
          )

          const ordinaryMessages = messages.filter(
            (message) => message instanceof Activity || !message.is_schedule
          )

          return (
            <Fragment key={day}>
              {!!ordinaryMessages.length && (
                <DayContent
                  day={day}
                  isLastDay={i === groupMessagesByDay.length - 1}
                  items={ordinaryMessages}
                  conversationMessagesStore={store}
                />
              )}
              {!!scheduledMessages.length && (
                <DayContent
                  day={day}
                  isLastDay={i === groupMessagesByDay.length - 1}
                  items={scheduledMessages}
                  isScheduled={true}
                  conversationMessagesStore={store}
                />
              )}
            </Fragment>
          )
        })}
        {!!store.sendingMessages.length && (
          <div className={styles.warpSending}>
            {store.sendingMessages.map((message) => (
              <MessageCard
                key={message.client_id}
                message={message}
                avatarInfo={usersStore.user?.avatarInfo}
                selectedSearch={store.conversationSearchStore?.selectedSearch}
                setScrollBottomTrigger={store.setScrollBottomTrigger}
                callContentStore={store.callContentStore}
                conversationMessagesStore={store}
              />
            ))}
          </div>
        )}
        {visibleArrowBottom && (
          <div className={styles.wrapArrow}>
            <button
              className={classNames(styles.arrowBottom, {
                [styles.isUnread]: store.isUnread,
              })}
              onClick={() => store.setScrollBottomTrigger()}
            >
              <Icon icon={'arrowDown'} />
            </button>
          </div>
        )}
      </div>
      <div ref={refBottom} />
    </div>
  )
})
