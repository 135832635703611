import { makeAutoObservable } from 'mobx'
import { SingletonRequest } from 'shared/lib'
import { BillingApi } from 'entities/Billing/api/billing'
import { IResponsePlan } from 'entities/Billing/api/types'
import { BillingPlan } from 'entities/Billing/model/BillingPlan'

export class BillingPlansStore {
  private _plansMap: Map<string, BillingPlan> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  private _fetchPlans = async () => {
    try {
      const { data } = await BillingApi.getPlans()

      this.addItems(data.monthly)
      this.addItems(data.annual)
    } catch (e) {
      console.log(e)
    }
  }

  fetchPlans = new SingletonRequest(this._fetchPlans, { cache: true }).request

  addItems = (response: IResponsePlan[]) => {
    response.forEach((item) => {
      this.addItem(item)
    })
  }

  addItem = (response: IResponsePlan) => {
    this._plansMap.set(response.id, new BillingPlan(response))
  }

  getItem = (id: string) => {
    return this._plansMap.get(id)
  }
}
