import classNames from 'classnames'
import { ReactNode } from 'react'
import styles from 'shared/ui/CardsFilter/styles.module.scss'
import { type ITooltipProps, Tooltip } from 'shared/ui/Tooltip'
import { Typography } from 'shared/ui/Typography'
import { numberFormat } from 'shared/lib'

export type ICardFilter<T = string> = {
  key: T
  title: string
  amount?: number | null
  amountContent?: ReactNode
  percentage?: number | null
  active?: boolean
  disabled?: boolean
  notInteractable?: boolean
  tooltipProps?: ITooltipProps
}

export type ICardFilterProps<T> = {
  activeKey?: T
  onActiveFilter?: (tab: ICardFilter<T>) => void
}

type ICardFilterPropsExpanded<T> = ICardFilterProps<T> & {
  item: ICardFilter<T>
}

export const CardFilter = <T,>({
  item,
  onActiveFilter,
  activeKey,
}: ICardFilterPropsExpanded<T>) => {
  const {
    key,
    title,
    amount,
    amountContent,
    percentage,
    active,
    disabled,
    notInteractable,
    tooltipProps,
  } = item

  const handleTabClick = () => {
    onActiveFilter && onActiveFilter(item)
  }

  const amountContentRender = () => {
    if (amountContent) {
      return amountContent
    }
    if (amount === undefined) {
      return '–'
    }
    return numberFormat({ value: amount || 0 })
  }

  const card = (
    <div
      key={key as string}
      className={classNames(styles.item, {
        [styles.active]: active || activeKey === key,
        [styles.disabled]: disabled,
        [styles.notInteractable]: notInteractable,
      })}
      onClick={handleTabClick}
    >
      <Typography ariaLabel={'tab-title'} variant={'body-rg-regular'} className={styles.title}>
        {title}
      </Typography>
      <Typography ariaLabel={'tab-amount'} variant={'heading-md'} className={styles.amount}>
        {amountContentRender()}
      </Typography>
      <Typography
        ariaLabel={'tab-percentage'}
        variant={'body-sm-regular'}
        className={styles.percentage}
      >
        {percentage === undefined ? '–' : `${percentage}%`}
      </Typography>
    </div>
  )

  if (tooltipProps) {
    return <Tooltip {...tooltipProps}>{card}</Tooltip>
  }

  return card
}
