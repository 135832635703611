// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P6FFfmswHYNVI6dHLuqA{display:flex;flex-direction:column;align-items:center}.EVwlXGXG5qQSgdA8rUXJ{margin-bottom:20px}.sRXvnyxViT72D4vgaLbM{cursor:pointer;position:relative;padding:32px 24px;border-radius:6px;border:1px solid var(--border-neutral-default);background:var(--background-primary-inverted-primary)}.sRXvnyxViT72D4vgaLbM:hover{border:1px solid var(--interaction-neutral-hovered)}.fUPpPIQZCoceNIzJgjgN{display:none}", "",{"version":3,"sources":["webpack://./src/shared/ui/UploadDoc/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAEF,sBACE,kBAAA,CAEF,sBACE,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,8CAAA,CACA,qDAAA,CAEF,4BACE,mDAAA,CAEF,sBACE,YAAA","sourcesContent":[".dropZone {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.uploadIcon {\n  margin-bottom: 20px;\n}\n.dragAndDropContainer {\n  cursor: pointer;\n  position: relative;\n  padding: 32px 24px;\n  border-radius: 6px;\n  border: 1px solid var(--border-neutral-default);\n  background: var(--background-primary-inverted-primary);\n}\n.dragAndDropContainer:hover {\n  border: 1px solid var(--interaction-neutral-hovered);\n}\n.fileInput {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropZone": "P6FFfmswHYNVI6dHLuqA",
	"uploadIcon": "EVwlXGXG5qQSgdA8rUXJ",
	"dragAndDropContainer": "sRXvnyxViT72D4vgaLbM",
	"fileInput": "fUPpPIQZCoceNIzJgjgN"
};
export default ___CSS_LOADER_EXPORT___;
