import { makeAutoObservable, runInAction } from 'mobx'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { IParamsContacts } from 'entities/Contacts/api/types'
import { IParamsFiltersList } from 'entities/Broadcast/api/types'
import { ContactIntegration } from 'entities/Integrations/model/ContactIntegration'
import { EnrollmentFilters } from 'widgets/ContactEnrollment'

export class EnrollmentContactsTableStore {
  loading = false
  itemsMap: Map<number | string, Contact | ContactIntegration> = new Map()

  page = 1
  length = 25
  search = ''
  sortOrder: 'desc' | 'asc' = 'desc'
  sortBy = 'created_at'
  total = 0
  filters: EnrollmentFilters | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }

  get filtersListGroups(): IParamsFiltersList {
    return {
      contacts: this.filters?.contacts.map((item) => item.id) || [],
      segments: this.filters?.segments.map((item) => item.id) || [],
      tags: this.filters?.tags.map((item) => item.id) || [],
      advanced: this.filters?.filters.map((item) => item.filtersParams) || [],
    }
  }

  get useHubspotList() {
    if (!this.filtersListGroups.advanced) return false

    const countHubspotFilters = this.filtersListGroups.advanced.reduce((count, item) => {
      const countFiltersListFilters = item.filtersList.reduce((count, item) => {
        const countFilters = item.filters.reduce((count, item) => {
          if (item.key === 'hubspot' || item.key === 'hubspot-lists') return count + 1
          return count
        }, 0)
        return count + countFilters
      }, 0)

      const countFiltersListIntegrationLists = item.filtersList.reduce((count, item) => {
        const countIntegrationLists =
          item.integration_lists?.reduce((count, item) => {
            if (item.integration_key === 'hubspot' || item.integration_key === 'hubspot-lists')
              return count + 1
            return count
          }, 0) || 0
        return count + countIntegrationLists
      }, 0)

      return countFiltersListFilters + countFiltersListIntegrationLists
    }, 0)

    return countHubspotFilters === 1
  }

  get expandedParams(): IParamsContacts {
    return {
      page: this.page,
      length: this.length,
      search: this.search,
      sortOrder: this.sortOrder,
      sortBy: [this.sortBy],
      filtersListGroups: this.filtersListGroups,
    }
  }

  onChangePagination = (page: number, length: number) => {
    this.page = page
    this.length = length

    this.loadContacts()
  }

  reset = () => {
    this.loading = false
    this.page = 1
    this.length = 25
    this.search = ''
    this.sortOrder = 'desc'
    this.sortBy = 'created_at'
    this.filters = null
    this.itemsMap.clear()
  }

  loadContacts = async () => {
    try {
      this.itemsMap.clear()

      runInAction(() => {
        this.loading = true
      })

      let results: Array<ContactIntegration | Contact> = []
      let total = 0

      if (!this.useHubspotList) {
        const { data } = await ContactsApi.getContactsList(this.expandedParams)

        total = data.total || 0
        results = contactsStore.addItems(data.data || [])
      } else {
        const { data } = await ContactsApi.getContactsHubspotList(this.expandedParams)

        total = data.total || 0
        results = data.data.map((item) => new ContactIntegration(item))
      }

      runInAction(() => {
        results.forEach((item) => {
          this.itemsMap.set(item.id, item)
        })
        this.total = total
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setTotal = (value: number) => {
    this.total = value
  }

  setFilter = (filters: EnrollmentFilters) => {
    this.filters = filters
  }
}
