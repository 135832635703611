// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v3TbUMLj33Y0wQ5W8vRk{overflow:auto;margin-top:9px}.BzDSfEmBZoEZ903BJ1CF{display:inline-flex;margin-top:40px;margin-bottom:10px}.YWf6uvWptTyhgFNmkhWo{margin-bottom:24px}.owcJyyDaPS6zCH7N9ne4{border-top:1px solid var(--border-neutral-default)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/localNumbers/ui/UseCaseModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,cAAA,CAEF,sBACE,mBAAA,CACA,eAAA,CACA,kBAAA,CAEF,sBACE,kBAAA,CAGF,sBACE,kDAAA","sourcesContent":[".wrap {\n  overflow: auto;\n  margin-top: 9px;\n}\n.title {\n  display: inline-flex;\n  margin-top: 40px;\n  margin-bottom: 10px;\n}\n.optInMethodTitle {\n  margin-bottom: 24px;\n}\n\n.topBorder {\n  border-top: 1px solid var(--border-neutral-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "v3TbUMLj33Y0wQ5W8vRk",
	"title": "BzDSfEmBZoEZ903BJ1CF",
	"optInMethodTitle": "YWf6uvWptTyhgFNmkhWo",
	"topBorder": "owcJyyDaPS6zCH7N9ne4"
};
export default ___CSS_LOADER_EXPORT___;
