import { observer } from 'mobx-react-lite'
import { ReactElement, useMemo, FC } from 'react'
import { IconButton, Tooltip, toastStore } from 'shared/ui'
import { downloadFile } from 'shared/lib'
import { Message } from 'entities/Message/model/Message'
import { conversationStore } from 'entities/Conversation'
import { scheduleStore } from 'widgets/ConversationSchedule/store/conversationScheduleStore'
import { useMessageCardContext } from 'widgets/MessageCard/context'
import styles from './styles.module.scss'
import { IMessageCardType, MessageCardType } from '../types'

type IMessageActionTooltipProps = {
  message: Message
  type: IMessageCardType
  children: ReactElement
}

const HOVER_MESSAGE_DELAY = 500

export const MessageActionTooltip: FC<IMessageActionTooltipProps> = observer(
  ({ type, children }) => {
    const { message, conversationMessagesStore } = useMessageCardContext()
    const { id, isOutbound, isRetryError, fromBroadcast, isFailed } = message
    const isShowRetry = !fromBroadcast && isRetryError && isFailed

    const onCopy = async () => {
      try {
        await window.navigator.clipboard.writeText(message.copyText)
        toastStore.add({
          title: 'Copied',
          type: 'info',
        })
      } catch (e) {
        console.error(e)
      }
    }

    const onShare = async () => {
      try {
        await window.navigator.clipboard.writeText(`${window.location.href}?messageId=${id}`)
        toastStore.add({
          title: 'URL has been copied to your clipboard',
          type: 'info',
        })
      } catch (e) {
        console.error(e)
      }
    }

    const onDownload = async () => {
      if (message.media[0].source) {
        downloadFile(message.media[0].source)
      }
    }

    const onSendNow = async () => {
      try {
        if (conversationMessagesStore) {
          await conversationMessagesStore.delete(message.id)
          await conversationMessagesStore.sendMessage({
            message: {
              ...message.messageRequestData,
              send_at: '',
              is_contact_timezone: false,
            },
            conversationId: message.conversation_id,
          })
          conversationMessagesStore.onCloseEdit()
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onEdit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation()

      if (conversationMessagesStore) {
        conversationMessagesStore.setScheduleMessage(true)
        conversationMessagesStore.messageFieldStore.setEditMessage(message)
        conversationMessagesStore.setIsEditMessage(true)
      }
    }

    const onReschedule = () => {
      if (conversationMessagesStore) {
        scheduleStore.setOpenModal({
          contactTZ: conversationMessagesStore.getContactTZ(),
          send_at: message.send_at ? new Date(message.send_at) : null,
          is_contact_timezone: message.is_contact_timezone,
          stop_on_response: message.stop_on_response,
          onSchedule: ({ scheduledData }) =>
            conversationMessagesStore.updateMessage({
              message: { ...message.paramsUpdateMessage, ...scheduledData },
              messageId: message.id,
            }),
        })
        conversationMessagesStore.onCloseEdit()
      }
    }

    const onDelete = async () => {
      try {
        if (conversationMessagesStore) {
          await conversationMessagesStore.delete(message.id)
          toastStore.add({
            type: 'info',
            title: 'Scheduled message deleted',
          })
          conversationStore.currentItem?.onRemoveScheduledMessageId(message.id)
          conversationMessagesStore.onCloseEdit()
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onRetry = async () => {
      if (conversationMessagesStore) {
        await conversationMessagesStore.retry(message.id)
      }
    }

    const actions = useMemo(() => {
      if (message.sending) {
        return null
      }
      const buttons: ReactElement[] = []
      if (message.is_schedule) {
        buttons.push(
          <IconButton
            key='sendNow'
            onClick={onSendNow}
            icon={'arrowUpCircle'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Send now',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_sendNow'
          />,
          <IconButton
            key='edit'
            onClick={onEdit}
            icon={'edit02'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Edit',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_edit'
          />,
          <IconButton
            key='reschedule'
            onClick={onReschedule}
            icon={'clockFastForward'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Reschedule',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_reschedule'
          />
        )

        if (message.copyText) {
          buttons.push(
            <IconButton
              key={'copy'}
              onClick={onCopy}
              icon={'copy'}
              variant={'icon'}
              color={'secondary'}
              size={'small'}
              tooltipProps={{
                label: 'Copy text',
                placement: 'top',
              }}
              ariaLabel='MessageActionTooltip_copy'
            />
          )
        }

        buttons.push(
          <IconButton
            key='delete'
            onClick={onDelete}
            icon={'delete'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Delete',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_delete'
          />
        )

        return buttons
      }

      if (message.images.length && type === MessageCardType.Images) {
        buttons.push(
          <IconButton
            key='mediaDownload'
            onClick={() => message.downloadMedia('image', 'gif')}
            icon={'download'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Download',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_mediaDownload_images'
          />
        )
      }

      if (message.previewGifs.length && type === MessageCardType.GifPreview) {
        buttons.push(
          <IconButton
            key='mediaDownload'
            onClick={() => message.downloadMedia('previewGif')}
            icon={'download'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Download',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_mediaDownload_gifPreview'
          />
        )
      }

      if (message.isOnlyVCard && type === MessageCardType.OtherAttachment) {
        buttons.push(
          <IconButton
            key='vcardDownload'
            onClick={onDownload}
            icon={'download'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Download',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_vcardDownload'
          />
        )
      }

      if (isShowRetry) {
        buttons.push(
          <IconButton
            key='retry'
            onClick={onRetry}
            icon={'refresh'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Retry',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_retry'
          />
        )
      }

      if (
        message.copyText &&
        (type === MessageCardType.Text || type === MessageCardType.GifPreview)
      ) {
        buttons.push(
          <IconButton
            key='copy'
            onClick={onCopy}
            icon={'copy'}
            variant={'icon'}
            color={'secondary'}
            size={'small'}
            tooltipProps={{
              label: 'Copy text',
              placement: 'top',
            }}
            ariaLabel='MessageActionTooltip_copy_gifPreview'
          />
        )
      }

      buttons.push(
        <IconButton
          key='share'
          onClick={onShare}
          icon={'share'}
          variant={'icon'}
          color={'secondary'}
          size={'small'}
          tooltipProps={{
            label: 'Share',
            placement: 'top',
          }}
          ariaLabel='MessageActionTooltip_share'
        />
      )

      return buttons
    }, [message])

    return (
      <Tooltip
        enterDelay={HOVER_MESSAGE_DELAY}
        leaveDelay={HOVER_MESSAGE_DELAY}
        enterNextDelay={HOVER_MESSAGE_DELAY}
        label={!message.isConversationsHistory && <div className={styles.wrap}>{actions}</div>}
        arrow={false}
        color={'white'}
        placement={isOutbound ? 'top-end' : 'top-start'}
        margin={-6}
        marginLeft={6}
        marginRight={6}
        padding={4}
        isDisplayGrid
      >
        {children}
      </Tooltip>
    )
  }
)
