import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Label } from 'shared/ui/Label'
import { TextField } from 'shared/ui/TextField'
import { Box, Button, Checkbox, Collapse, Grid, Icon, Tooltip, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { uiStore } from 'shared/store/uiStore'
import { AdminRoutes } from 'pages/admin/route/type'
import { expirationTimeItems } from 'pages/admin/ui/ManagePlan/ManagePlanForm'
import { SelectPlanForm } from 'pages/admin/ui/SelectPlanForm/SelectPlanForm'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'
import { newOrganizationForm } from '../../models/newOrganizationForm'

import styles from './styles.module.scss'
import { EnumTypeNewOrganization } from '../../store/newOrganizationStore'
import { useNewOrganizationContext } from '../../context'
import { generalFields, valueFormatter } from './fields'

export const NewOrganizationTab = observer(() => {
  const store = useNewOrganizationContext()
  const isTrial = store.type === EnumTypeNewOrganization.EnterpriseTrial
  const { formData } = newOrganizationForm
  const { errorsObj } = store

  const cancel = () => {
    uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.organizations}`)
  }

  const { selectedPlan } = adminOrganizationCustomPlanStore
  const { selectedPlanData } = adminOrganizationPlanStore
  const planData = selectedPlanData(selectedPlan)

  const saveDisabled = newOrganizationForm.isDisabled || !selectedPlan

  const save = () => {
    if (planData?.stripe_id) {
      store.createOrganization(formData, planData.stripe_id)
    }
  }

  useEffect(() => {
    return () => {
      newOrganizationForm.reset()
    }
  }, [])

  return (
    <div className={styles.container}>
      <Collapse title={'General'}>
        <Box mt={2}>
          <Grid container spacing={2} mb={2}>
            {generalFields.map((field, index) => {
              const errorText = errorsObj[field.value] || errorsObj[valueFormatter(field.value)]
              return (
                <Grid item xs={6} key={index}>
                  <Label label={{ text: field.label }} />
                  <TextField
                    Input={field.inputType}
                    variant={'stroke'}
                    size={'medium'}
                    InputProps={{
                      placeholder: field.placeholder,
                    }}
                    value={formData[field.value] as string}
                    onChange={field.onChange}
                  />
                  {errorText && (
                    <div className={styles.error}>
                      <Icon
                        icon='alertTriangleSmall'
                        color='var(--action-contained-destructive-primary-normal)'
                      />
                      <span className={styles.errorText}>{errorText}</span>
                    </div>
                  )}
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Collapse>

      <Collapse title={'Manage plan'}>
        <Box mt={2} marginBottom={3}>
          <SelectPlanForm
            store={newOrganizationForm}
            isTrial={isTrial}
            disablesPaidNumbers={formData.twoWeeksTrial}
          />
        </Box>
      </Collapse>
      {isTrial && (
        <Collapse title={'Enterprise trial settings'}>
          <Grid item xs={6} sx={{ marginBottom: '16px', marginTop: '16px' }}>
            <SelectField
              labelText={'Expiration time'}
              value={formData.expirationTime}
              setValue={newOrganizationForm.setExpirationTime}
              items={expirationTimeItems}
              btnProps={{
                typeBtn: 'stroke',
                size: 'medium',
                justifyContent: 'space-between',
                fontWeight: !true ? 400 : undefined,
                style: {
                  color: !true ? 'var(--content-neutral-primary)' : undefined,
                  height: '40px',
                  width: '354px',
                },
              }}
            />
          </Grid>

          <Grid display='flex' alignItems='center' gap={1} marginBottom={4}>
            <Checkbox checked={formData.ignoreFees} onChange={newOrganizationForm.setIgnoreFees} />
            <Typography ariaLabel='ignoreFees' color='var(--gray-60)'>
              Ignore Fees
            </Typography>
            <Tooltip label='The following fees will be covered by Salesmsg: Carrier fees, PhoneCheckr, etc'>
              <Icon icon='question' color='var(--gray-40)' />
            </Tooltip>
          </Grid>
        </Collapse>
      )}

      <div className={styles.wrapButtons}>
        <Button onClick={cancel} text={'Cancel'} contained={'secondary'} />
        <Button
          onClick={save}
          loading={store.saveLoading}
          disabled={saveDisabled}
          text={'Apply'}
          typeBtn={'contained'}
        />
      </div>
    </div>
  )
})
