import { CircularProgressWithBackground, type IIconsVector, type IStatusProps } from 'shared/ui'

export const StatusComplianceIcons = {
  reject: {
    icon: 'failed' as IIconsVector,
    iconColor: 'var(--red-60)',
    title: 'Rejected',
    intent: 'negative' as IStatusProps['intent'],
    emphasis: 'high' as IStatusProps['emphasis'],
  },
  approved: {
    icon: 'check2' as IIconsVector,
    iconColor: 'var(--green-60)',
    title: 'Approved',
    intent: 'positive' as IStatusProps['intent'],
    emphasis: 'high' as IStatusProps['emphasis'],
  },
  alert: {
    icon: 'alertTriangleYellow' as IIconsVector,
    title: 'Review',
    iconColor: 'var(--yellow-60)',
    intent: 'warning' as IStatusProps['intent'],
    emphasis: 'high' as IStatusProps['emphasis'],
  },
  pendingReview: {
    icon: 'clock' as IIconsVector,
    iconColor: 'var(--content-brand-primary)',
    title: 'Pending Review',
    intent: 'informative' as IStatusProps['intent'],
    emphasis: 'high' as IStatusProps['emphasis'],
  },
  carrierReview: {
    icon: 'clock' as IIconsVector,
    iconColor: 'var(--content-brand-primary)',
    title: 'In Carrier Review',
    intent: 'informative' as IStatusProps['intent'],
    emphasis: 'high' as IStatusProps['emphasis'],
  },
  condition: (percent: number) => {
    return {
      customIcon: <CircularProgressWithBackground value={percent} />,
      title: 'Pending Review',
      intent: 'informative' as IStatusProps['intent'],
      emphasis: 'high' as IStatusProps['emphasis'],
    }
  },
}
