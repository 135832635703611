import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'
import { usersStore } from 'entities/Users'

export interface IGuidelineStore {
  hidden: boolean
  hide: () => void
}

export class GuidelineStaticStore implements IGuidelineStore {
  private _isHidden: boolean | null = null
  private _storageKey: string | null = null
  private _disposeUserId: IReactionDisposer | null = null

  constructor(private _id: string) {
    makeAutoObservable(this)

    this.reactionUserId()
  }

  reactionUserId = () => {
    this._disposeUserId?.()
    this._disposeUserId = reaction(
      () => usersStore.user?.id,
      (userId) => {
        this._storageKey = userId ? `${userId}_${this._id}` : null
        this._isHidden = !!this._storageKey && !!localStorage.getItem(this._storageKey)
      },
      {
        fireImmediately: true,
      }
    )
  }

  get hidden() {
    return !!this._isHidden
  }

  dispose = () => {
    this._disposeUserId?.()
  }

  hide() {
    if (!this._storageKey) return

    this._isHidden = true
    localStorage.setItem(this._storageKey, 'true')
  }
}

export class GuidelineMemoryStore implements IGuidelineStore {
  private _isHidden = false

  constructor() {
    makeAutoObservable(this)
  }

  get hidden() {
    return this._isHidden
  }

  hide() {
    this._isHidden = true
  }
}
