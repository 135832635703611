import { makeAutoObservable } from 'mobx'
import { ISchedulePeriod } from 'shared/ui/Schedule/ui/SchedulePeriod/store/types'

export class SchedulePeriodStore {
  constructor() {
    makeAutoObservable(this)
  }
  period: ISchedulePeriod = 'minutes'
  setPeriod = (value: typeof this.period) => {
    this.period = value
  }
}
