// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YChMdRjpBnZK_78iZ4mh{display:flex;width:100%}.dRXaNDtGQj2OPG7f6yaM{font-size:13px;font-style:normal;font-weight:500;line-height:normal;padding:2px 0}", "",{"version":3,"sources":["webpack://./src/entities/Phone/ui/GetNumberModal/ui/NewNumberSummaryModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":[".actionsWrap {\n  display: flex;\n  width: 100%;\n}\n\n.button {\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  padding: 2px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsWrap": "YChMdRjpBnZK_78iZ4mh",
	"button": "dRXaNDtGQj2OPG7f6yaM"
};
export default ___CSS_LOADER_EXPORT___;
