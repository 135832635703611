import { Box } from '@mui/material'
import { Icon } from 'shared/ui/Icon'
import { Error } from 'shared/ui/Error'
import { type ILabelProps, Label } from 'shared/ui/Label'
import { Typography } from 'shared/ui/Typography'
import { TextField } from '../TextField/TextField'
import type { ITextFieldBaseAsideAction, ITextFieldProps } from '../TextField/types'

import styles from './styles.module.scss'

interface ITextFieldWithLabelProps {
  marginBottom?: number
  marginTop?: string | number
  labelProps?: ILabelProps
  textFieldProps: ITextFieldProps
  bottomLabelProps?: ILabelProps
  errorMessage?: string
  errorBorder?: boolean
  warningText?: string
  rightActions?: ITextFieldBaseAsideAction[]
}

export const TextFieldWithLabel = ({
  marginBottom = 0,
  marginTop = 0,
  labelProps,
  textFieldProps,
  bottomLabelProps,
  errorMessage,
  errorBorder,
  warningText,
  rightActions,
}: ITextFieldWithLabelProps) => {
  return (
    <Box marginBottom={marginBottom} width={'100%'} marginTop={marginTop}>
      {labelProps?.label && <Label marginBottom={4} {...labelProps} />}
      <TextField
        variant={'stroke'}
        size={'medium'}
        {...textFieldProps}
        error={errorMessage || errorBorder}
        rightActions={rightActions}
      />
      {bottomLabelProps && <Label {...bottomLabelProps} />}
      <Error type='text' error={errorMessage} size='small' wrapClassName={styles.error} />
      {warningText && (
        <div className={styles.warning}>
          <Icon icon='alertTriangleYellow' color='var(--yellow-60)' />
          <Typography ariaLabel='warningText' variant='text-input-helper' color='var(--yellow-90)'>
            {warningText}
          </Typography>
        </div>
      )}
    </Box>
  )
}
