// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hmGs8qJXYuiNwUAxEpPa{width:100%;height:6px;overflow:hidden;border-radius:0 6px 6px 0}.hmGs8qJXYuiNwUAxEpPa.QW_jGLAxuRUjmctQYV7O{background:var(--black-20);border-radius:6px}.hmGs8qJXYuiNwUAxEpPa.BuLTeSsJq9Kq4l2j7rxm{border-radius:6px}.hmGs8qJXYuiNwUAxEpPa .xzFy9lFegEvcp_Alxijo{height:100%;background-color:var(--content-brand-primary);transition:.5s}", "",{"version":3,"sources":["webpack://./src/shared/ui/ProgressBar/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,UAAA,CACA,eAAA,CACA,yBAAA,CAEA,2CACE,0BAAA,CACA,iBAAA,CAGF,2CACE,iBAAA,CAGF,4CACE,WAAA,CACA,6CAAA,CACA,cAAA","sourcesContent":[".wrap {\n  width: 100%;\n  height: 6px;\n  overflow: hidden;\n  border-radius: 0 6px 6px 0;\n\n  &.withBg {\n    background: var(--black-20);\n    border-radius: 6px;\n  }\n\n  &.allBorders {\n    border-radius: 6px;\n  }\n\n  .progress {\n    height: 100%;\n    background-color: var(--content-brand-primary);\n    transition: 0.5s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "hmGs8qJXYuiNwUAxEpPa",
	"withBg": "QW_jGLAxuRUjmctQYV7O",
	"allBorders": "BuLTeSsJq9Kq4l2j7rxm",
	"progress": "xzFy9lFegEvcp_Alxijo"
};
export default ___CSS_LOADER_EXPORT___;
