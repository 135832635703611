import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Status } from 'shared/ui'
import type { TollFreeProfile } from 'entities/Compliance/model'
import styles from './styles.module.scss'

type IStatusesTFProps = {
  item: TollFreeProfile
}

export const StatusesTF: FC<IStatusesTFProps> = observer(({ item }) => {
  const { internal_review, under_review, denied, blocked, verified } = item.numbersStatusCounts

  return (
    <div className={styles.statuses}>
      {internal_review > 0 && (
        <Status
          emphasis='high'
          icon='clock'
          intent='informative'
          title={`Pending Review -> ${internal_review}`}
        />
      )}
      {under_review > 0 && (
        <Status
          emphasis='high'
          icon='clock'
          intent='informative'
          title={`In Carrier Review -> ${under_review}`}
        />
      )}
      {denied > 0 && (
        <Status emphasis='high' icon='failed' intent='negative' title={`Rejected -> ${denied}`} />
      )}
      {blocked > 0 && (
        <Status emphasis='high' icon='failed' intent='negative' title={`Blocked -> ${blocked}`} />
      )}
      {verified > 0 && (
        <Status emphasis='high' icon='check2' intent='positive' title={`Approved -> ${verified}`} />
      )}
    </div>
  )
})
