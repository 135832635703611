import * as Sentry from '@sentry/react'
import { reaction } from 'mobx'
import {
  APP_ENV,
  SENTRY_DSN,
  SENTRY_RELEASE,
  SENTRY_PROFILES_SAMPLE_RATE,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE,
} from 'shared/config'
import { usersStore } from 'entities/Users'
import { organizationStore } from 'entities/Organization'

const IS_NOT_SETUP = !SENTRY_DSN && !APP_ENV

type IInitSentryProps = { appName: string }

export const initSentry = ({ appName }: IInitSentryProps) => {
  if (IS_NOT_SETUP) return

  Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_RELEASE,
    environment: APP_ENV,

    tracePropagationTargets: ['int/v5/core'],

    replaysSessionSampleRate: Number(SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 1.0,
    replaysOnErrorSampleRate: Number(SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) || 1.0,
    tracesSampleRate: Number(SENTRY_TRACES_SAMPLE_RATE) || 0.1,
    profilesSampleRate: Number(SENTRY_PROFILES_SAMPLE_RATE) || 0.1,

    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,

        networkRequestHeaders: ['Cache-Control'],
        networkResponseHeaders: ['Referrer-Policy'],
        networkDetailAllowUrls: ['salesmessage.com'],
      }),
      Sentry.replayCanvasIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
  })

  Sentry.setTag('app.name', appName)
  Sentry.getReplay()?.start()

  reaction(
    () => usersStore.user,
    (user) => {
      if (!user) return

      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.full_name,
      })
    }
  )

  reaction(
    () => organizationStore.id,
    (organizationId) => Sentry.setTag('organization.id', organizationId ?? 'null')
  )

  reaction(
    () => organizationStore.agency_id,
    (agencyId) => Sentry.setTag('agency.id', agencyId ?? 'null')
  )
}
