// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xFTTIiAb6FCBoN6P9PwP{min-height:16px;padding:8px 0;display:flex;gap:4px;color:var(--content-primary-tertiary);margin-bottom:4px}.xFTTIiAb6FCBoN6P9PwP:last-of-type{margin-bottom:0}.h6sfloWf3iHp4JpIwh7_{min-height:16px;padding:8px 4px 8px 12px;display:flex}.h6sfloWf3iHp4JpIwh7_.v4Jlw6QIsWPcvD5d3cYV{flex-wrap:wrap;row-gap:8px;column-gap:4px}.h6sfloWf3iHp4JpIwh7_ .QitOn0XFAEpptmxAgtNd{display:flex;gap:8px}.htWetyW1tEZjhk1pGfof{word-break:break-word;width:352px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/ModalInfoRow/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,aAAA,CACA,YAAA,CACA,OAAA,CACA,qCAAA,CACA,iBAAA,CAEA,mCACE,eAAA,CAGJ,sBACE,eAAA,CACA,wBAAA,CACA,YAAA,CAEA,2CACE,cAAA,CACA,WAAA,CACA,cAAA,CAGF,4CACE,YAAA,CACA,OAAA,CAGJ,sBACE,qBAAA,CACA,WAAA","sourcesContent":[".label {\n  min-height: 16px;\n  padding: 8px 0;\n  display: flex;\n  gap: 4px;\n  color: var(--content-primary-tertiary);\n  margin-bottom: 4px;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n.value {\n  min-height: 16px;\n  padding: 8px 4px 8px 12px;\n  display: flex;\n\n  &.triggerValue {\n    flex-wrap: wrap;\n    row-gap: 8px;\n    column-gap: 4px;\n  }\n\n  .row {\n    display: flex;\n    gap: 8px;\n  }\n}\n.textValue {\n  word-break: break-word;\n  width: 352px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "xFTTIiAb6FCBoN6P9PwP",
	"value": "h6sfloWf3iHp4JpIwh7_",
	"triggerValue": "v4Jlw6QIsWPcvD5d3cYV",
	"row": "QitOn0XFAEpptmxAgtNd",
	"textValue": "htWetyW1tEZjhk1pGfof"
};
export default ___CSS_LOADER_EXPORT___;
