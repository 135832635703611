import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Icon, Typography } from 'shared/ui'
import { ITwoFaMethod } from 'widgets/Login/store/type'
import { type LoginStore } from 'widgets/Login/store/loginStore'

import styles from './styles.module.scss'

type IProps = {
  item: ITwoFaMethod
  store: LoginStore
}

export const MethodItem = observer(({ item, store }: IProps) => {
  const { twoFaMethod, setTwoFaMethod } = store

  const isActive = twoFaMethod?.type === item.type

  return (
    <div
      className={classNames(styles.item, { [styles.active]: isActive })}
      onClick={() => setTwoFaMethod(item)}
    >
      <div className={styles.icon}>
        <Icon fontSize={16} icon={item.icon} />
      </div>
      <div className={styles.content}>
        <Typography
          color={'var(--content-primary-primary)'}
          ariaLabel={`twoFaMethodTitle-${item.type}`}
          variant='body-md-regular'
        >
          {item.title}
        </Typography>
        <Typography
          color={'var(--content-primary-tertiary)'}
          ariaLabel={`twoFaMethodDesc-${item.type}`}
          variant='body-sm-regular'
        >
          {item.desc}
        </Typography>
      </div>
    </div>
  )
})
