import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class KeywordPageStore {
  private _isKeywordsActionShown = false

  constructor(private _pageLayoutStore: PageLayoutStore) {
    makeAutoObservable(this)
  }

  get isKeywordsActionShown() {
    return this._isKeywordsActionShown
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  setIsKeywordsActionShown = (isKeywordsActionShown: boolean) => {
    this._isKeywordsActionShown = isKeywordsActionShown
  }
}
