import { useCallback } from 'react'
import { toastStore } from 'shared/ui/Toast'
import { writeTextToClipboard } from 'shared/lib'

export const useCopyToClipboard = (value: string | number | undefined) => {
  const onCopy = useCallback(async () => {
    if (!window?.navigator?.clipboard) return

    try {
      await writeTextToClipboard(`${value}`)
      toastStore.add({
        title: 'Copied to clipboard',
        type: 'info',
      })
    } catch (e) {
      console.error(e)
    }
  }, [value])

  return { onCopy }
}
