import { makeAutoObservable } from 'mobx'
import { type IResponseMediaShort } from 'entities/Attachment'
import { SMSMessageFieldStore } from 'widgets/MessageFormFields/SMSMessageField/store/SMSMessageFieldStore'

export class CampaignSMSStore {
  constructor() {
    makeAutoObservable(this)
  }
  smsMessageFieldStore = new SMSMessageFieldStore({
    onFocus: () => {
      this.setError('')
    },
    messageFieldStoreProps: {
      resetOnUnmount: false,
    },
  })

  init = (message: string, media_url: IResponseMediaShort[]) => {
    this.smsMessageFieldStore.init(message, media_url)
  }

  get messageDataParams() {
    return this.smsMessageFieldStore.messageDataParams
  }

  get messageFieldStore() {
    return this.smsMessageFieldStore.messageFieldStore
  }

  get isError() {
    return !!this.error || this.smsMessageFieldStore.isError
  }

  error = ''
  setError = (value: typeof this.error) => {
    this.error = value
  }

  checkValidate = () => {
    let validate = true
    if (!this.messageDataParams.message.trim().length && !this.messageDataParams.media_url.length) {
      validate = false
      this.setError('Your message is the key – please write it above')
    }
    return validate
  }
}
