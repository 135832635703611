import { makeAutoObservable, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import { organizationStore } from 'entities/Organization'
import { InboxesApi } from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'

export class InboxesTeamStore {
  private _status = new RequestLoadingStatus()
  private _inboxesMap: Map<number | string, Inbox> = new Map()
  private _selectedItemId: Null<number> = null

  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  init = async () => {
    await this.fetchAllInboxes()
    runInAction(() => {
      this._selectedItemId = organizationStore.fallbackTeamId
    })
  }

  fetchAllInboxes = () =>
    this._status.blockingLoadData(async () => {
      const { data } = await InboxesApi.getTeamsAll()
      data.forEach((item) => {
        if (item.type !== 'inbox') return
        this._inboxesMap.set(item.id, new Inbox(item))
      })
    })

  updateFallbackTeamId = (id: number) => organizationStore.updateFallbackTeamId(id)

  selectInbox = (itemId: number) => {
    this._selectedItemId = itemId
    this.updateFallbackTeamId(itemId)
  }

  get selectedInboxId() {
    return this._selectedItemId
  }
  get inboxes() {
    return Array.from(this._inboxesMap.values())
  }
  get status() {
    return this._status.status
  }
}
