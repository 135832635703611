import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useSavedRepliesContext } from 'widgets/SavedReplies/context'
import styles from './styles.module.scss'

export const NewReplyBtn = observer(() => {
  const store = useSavedRepliesContext()
  const { handleNewSavedReply } = store.newSavedReplyStore

  return (
    <div className={styles.wrapBtn}>
      <Button
        icon={'add'}
        text={'Create'}
        contained={'secondary'}
        size={'medium'}
        className={styles.btn}
        onClick={() => handleNewSavedReply()}
      />
    </div>
  )
})
