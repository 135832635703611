import { createContext, useContext } from 'react'
import { NameInputStore } from 'features/createOrEdit/NameInput/store/nameInputStore'

export const NameInputContext = createContext<NameInputStore | null>(null)

export const useNameInputContext = () => {
  const context = useContext(NameInputContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with NameInputContext')

  return context
}
