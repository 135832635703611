import { useState } from 'react'
import { Box } from '@mui/material'
import { IconButton } from 'shared/ui/IconButton/IconButton'
import { IInputTextProps } from '../Input/types'

export const InputPassword = (props: IInputTextProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      height='16px'
    >
      <input {...props} type={showPassword ? 'text' : 'password'} />
      <IconButton
        size='small'
        color='secondary'
        variant='icon'
        ariaLabel='togglePasswordVisibility'
        icon={showPassword ? 'eyeOff' : 'eye'}
        onClick={togglePasswordVisibility}
        style={{ cursor: 'pointer', marginLeft: '8px' }}
      />
    </Box>
  )
}
