export enum AdminRoutes {
  admin = 'admin',
  organizations = 'organizations',
  imports = 'imports',
  compliance = 'compliance_queue',
  status = 'status',
  tenDLC = '10DLC',
  tollFree = 'toll-free',
  customPrices = 'custom_prices',
  customPayments = 'custom_payments',
  create = 'create',
}
