import { observer } from 'mobx-react-lite'
import { InputCheckbox, TextFieldWithLabel, Typography } from 'shared/ui'
import optInOnlineFormApproved from 'shared/assets/images/optInOnlineFormApproved.png'
import optInOnlineFormRejected from 'shared/assets/images/optInOnlineFormRejected.png'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { BlockNumber } from 'pages/settings/pages/compliance/ui/BlockNumber/BlockNumber'
import { ICommonOptInContent } from 'pages/settings/pages/compliance/ui/CommonOptInContent/store/type'
import { OptInContent } from './CommonOptInContent'
import styles from './styles.module.scss'

type ICommonOptInOnlineFormContentProps = ICommonOptInContent & {
  isOnlineFormNextDisabled: boolean
}

export const CommonOptInOnlineFormContent = observer(
  ({
    stepLoading,
    deleteAttachment,
    getOptInImagesAsAttach,
    uploadAttachment,
    increaseStep,
    errorBanner,
    clearAttachmentError,
    getAttachmentError,
    isOnlineFormNextDisabled,
    optInConsentStore,
  }: ICommonOptInOnlineFormContentProps) => {
    const {
      onlineFormUrl,
      isCorrectOnlineFormUrl,
      noPublishedOnlineFormUrl,
      onlineFormUrlLocalError,
      termsAndPrivacyAgreement,
      similarityDomainsWarning,
      checkEmail,
      setOnlineFormUrl,
      setTermsAndPrivacyAgreement,
      handleNoPublishedOnlineFormUrl,
      openApprovedExampleModal,
    } = optInConsentStore

    return (
      <div className={styles.optInContentWrap}>
        <OptInContent
          attachmentData={getOptInImagesAsAttach()}
          stepLoading={stepLoading}
          isNextDisabled={isOnlineFormNextDisabled}
          attachmentError={getAttachmentError()}
          increaseStep={increaseStep}
          clearAttachmentError={clearAttachmentError}
          uploadOptInImageAttachment={uploadAttachment}
          deleteAttachment={(url: string) => deleteAttachment(url)}
          approvedExampleImage={optInOnlineFormApproved}
          rejectedExampleImage={optInOnlineFormRejected}
          errorBanner={errorBanner}
          seeApprovedExampleClick={() => {
            openApprovedExampleModal(
              [optInOnlineFormApproved],
              ['Opt-in / Online Form / Approved example']
            )
          }}
          approvedExampleClick={() =>
            openApprovedExampleModal(
              [optInOnlineFormRejected, optInOnlineFormApproved],
              ['Opt-in / Online Form / Rejected example', 'Opt-in / Online Form / Approved example']
            )
          }
          rejectedExampleClick={() =>
            openApprovedExampleModal(
              [optInOnlineFormApproved, optInOnlineFormRejected],
              ['Opt-in / Online Form / Approved example', 'Opt-in / Online Form / Rejected example']
            )
          }
          firstBlock={{
            title: 'Include compliance language to your Online Form',
            desc: 'Include links to your Terms of Service and Privacy Policy in the compliance language.',
            copyTitle: 'Compliance language',
            copyText:
              'I agree to receive recurring automated text messages at the phone number provided. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our Terms of Service and Privacy Policy.',
            underlineText: ['Terms of Service', 'Privacy Policy'],
          }}
          secondBlock={{
            title: 'Include this paragraph to your Privacy Policy',
            desc: 'Sharing data is not allowed. If text messaging data/consent is not shared, include the paragraph below to your Privacy Policy.',
            copyText:
              'Text messaging originator opt-in data and consent will not be shared with any third parties, excluding aggregators and providers of the Text Message services.',
          }}
        >
          <div className={styles.infoBlock}>
            <BlockNumber className={styles.blockNumber} number={4} />
            <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
              Online Form URL
            </Typography>
            <Typography
              ariaLabel='infoBlockTitle'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
              className={styles.textDesc}
            >
              Enter the full, valid URL that points to your online form. It must be publicly
              accessible, or your submission may be rejected.
            </Typography>

            <TextFieldWithLabel
              marginBottom={1}
              errorMessage={onlineFormUrlLocalError}
              warningText={similarityDomainsWarning}
              textFieldProps={{
                disabled: noPublishedOnlineFormUrl,
                value: onlineFormUrl || '',
                error: !!onlineFormUrlLocalError,
                onChange: setOnlineFormUrl,
                onBlur: (value: string) => {
                  checkEmail(value)
                },
                InputProps: {
                  placeholder: 'e.g. https://www.example.com/contact-us',
                },
              }}
              rightActions={[
                makeTextFieldIcon({
                  icon: isCorrectOnlineFormUrl ? 'check2' : undefined,
                  fontSize: 16,
                  color: 'var(--green-60)',
                }),
              ]}
            />
            <InputCheckbox
              label='My online form is not published yet'
              onChecked={() => handleNoPublishedOnlineFormUrl(!noPublishedOnlineFormUrl)}
              checked={noPublishedOnlineFormUrl}
            />
          </div>

          <div className={styles.infoBlock}>
            <BlockNumber className={styles.blockNumber} number={5} />
            <Typography ariaLabel='infoBlockTitle' variant='body-lg-medium'>
              Terms and Privacy
            </Typography>
            <InputCheckbox
              className={styles.termsOfServiceDesc}
              variant='text-input-field-r'
              color='var(--content-primary-tertiary)'
              label='I confirm that my website footer contains a link to my Terms of Service and Privacy Policy'
              onChecked={setTermsAndPrivacyAgreement}
              checked={termsAndPrivacyAgreement}
            />
          </div>
        </OptInContent>
      </div>
    )
  }
)
