import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Divider, ContainerHeader } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { CustomPriceForm } from 'pages/admin/pages/customPrices/ui/CustomPriceForm/CustomPriceForm'
import { CustomPriceSummary } from 'pages/admin/pages/customPrices/ui/CustomPriceSummary/CustomPriceSummary'
import { adminCustomPricesCreate } from 'pages/admin/pages/customPrices/pages/CustomPricesCreate/store/adminCustomPricesCreate'
import { AdminRoutes } from 'pages/admin'
import { RolloverCredits } from 'pages/admin/pages/customPrices/ui/RolloverCredits/RolloverCredits'
import styles from './styles.module.scss'
import { CustomPriceLayout } from '../../../ui/CustomPriceLayout/CustomPriceLayout'

export const CustomPriceCreate = observer(() => {
  const { form, saveCustomPrice, loading, reset, publishCustomPrice } = adminCustomPricesCreate

  useEffect(() => {
    reset()
  }, [])

  return (
    <CustomPriceLayout>
      <ContainerHeader
        paddingLeft={24}
        actionsProps={{
          gap: 8,
          actions: [
            {
              text: 'Cancel',
              onAction: () => {
                uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.customPrices}`)
              },
              buttonProps: {
                disabled: loading,
              },
              medium: true,
            },
            {
              text: 'Save',
              medium: true,
              buttonProps: {
                disabled: form.disableFormActions || loading,
              },
              onAction: () => saveCustomPrice(),
            },
            {
              text: 'Publish',
              medium: true,
              buttonProps: {
                contained: 'primary',
                disabled: true,
              },
              onAction: () => publishCustomPrice(),
            },
          ],
        }}
        title={'Create new price'}
        titleIconProps={{
          icon: 'dollar',
          fontSize: 20,
          tertiary: true,
        }}
      />

      <Divider sx={{ marginBottom: 1.5 }} />

      <div className={styles.wrap}>
        <CustomPriceForm adminCustomPricesForm={form} />
        <RolloverCredits adminCustomPricesForm={form} />

        {form.showSummary && <CustomPriceSummary adminCustomPricesForm={form} />}
      </div>
    </CustomPriceLayout>
  )
})
