import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { InputNumeric, InputPhone } from 'shared/ui/TextField'
import { getHref } from 'shared/lib'
import { TenDLCStatus } from 'entities/Compliance/ui/TenDLCStatus'
import { complianceStore } from 'entities/Compliance/store/complianceStore'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminTenDLCBrandStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BrandInformation/store/adminTenDLCBrandStore'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import { getRejectReasonRow } from 'pages/admin/pages/compliance'

export const BrandInformation = observer(() => {
  const { setTenDLCData, data } = adminTenDLCDetailStore

  const {
    status,
    isForEdit,
    getError,
    registrationError,
    isSaveLoading,
    isRejected,

    vendor_id,
    edit_vendor_id,
    is_edit_vendor_id,
    set_edit_vendor_id,

    business_name,
    edit_business_name,
    is_edit_business_name,
    set_edit_business_name,

    business_industry,
    edit_business_industry,
    is_edit_business_industry,
    set_edit_business_industry,

    ein,
    edit_ein,
    is_edit_ein,
    set_edit_ein,

    first_name,
    edit_first_name,
    is_edit_first_name,
    set_edit_first_name,

    last_name,
    edit_last_name,
    is_edit_last_name,
    set_edit_last_name,

    phone_number,
    edit_phone_number,
    is_edit_phone_number,
    set_edit_phone_number,

    email,
    edit_email,
    is_edit_email,
    set_edit_email,

    street_address_1,
    edit_street_address_1,
    is_edit_street_address_1,
    set_edit_street_address_1,

    city,
    edit_city,
    is_edit_city,
    set_edit_city,

    state,
    edit_state,
    is_edit_state,
    set_edit_state,

    postal_code,
    edit_postal_code,
    is_edit_postal_code,
    set_edit_postal_code,

    country,
    edit_country,
    is_edit_country,
    set_edit_country,

    business_type,
    edit_business_type,
    is_edit_business_type,
    set_edit_business_type,

    stock_exchange,
    edit_stock_exchange,
    is_edit_stock_exchange,
    set_edit_stock_exchange,

    stock_ticker_symbol,
    edit_stock_ticker_symbol,
    is_edit_stock_ticker_symbol,
    set_edit_stock_ticker_symbol,

    brand_two_fa_verification_email,
    edit_brand_two_fa_verification_email,
    is_edit_brand_two_fa_verification_email,
    set_edit_brand_two_fa_verification_email,

    brand_two_fa_verification_status,
    edit_brand_two_fa_verification_status,
    is_edit_brand_two_fa_verification_status,
    set_edit_brand_two_fa_verification_status,

    onEdit,
    isEdit,
    onSave,
    onClose,
    linkToTCRByBrandId,
  } = adminTenDLCBrandStore

  const rows: IAdminDetailCardRowProps[] = [
    {
      title: 'Twilio id',
      value: vendor_id,
      editValue: edit_vendor_id,
      isUnsaved: is_edit_vendor_id,
      onChange: set_edit_vendor_id,
      editError: getError('vendor_id'),
      renderValue: () => {
        return (
          vendor_id && (
            <>
              <Typography
                variant={'body-md-regular'}
                link
                textDecoration={'none'}
                pointer
                ariaLabel={'website_url'}
              >
                <a href={getHref(linkToTCRByBrandId)} target={'_blank'} rel='noreferrer'>
                  {vendor_id}
                </a>
              </Typography>
            </>
          )
        )
      },
    },
    ...getRejectReasonRow(isRejected, registrationError),
    {
      title: 'Current package',
      value: data?.plan?.current_plan_type || '',
      isNoEdit: true,
    },
    {
      title: 'Applied package',
      value: data?.plan?.planing_plan_type || '',
      isNoEdit: true,
    },
    {
      title: 'Company name',
      value: business_name,
      editValue: edit_business_name,
      isUnsaved: is_edit_business_name,
      onChange: set_edit_business_name,
      editError: getError('business_name'),
    },
    {
      title: 'Vertical',
      value: business_industry,
      editValue: edit_business_industry,
      isUnsaved: is_edit_business_industry,
      onChange: set_edit_business_industry,
      typeEdit: 'select',
      options: complianceStore.businessProfileIndustries,
      loadOptions: complianceStore.loadBusinessProfileIndustries,
      editError: getError('business_industry_id'),
    },
    {
      Input: InputNumeric,
      title: 'EIN',
      value: ein,
      editValue: edit_ein,
      isUnsaved: is_edit_ein,
      onChange: set_edit_ein,
      editError: getError('ein'),
    },
    {
      title: 'First name',
      value: first_name,
      editValue: edit_first_name,
      isUnsaved: is_edit_first_name,
      onChange: set_edit_first_name,
      editError: getError('contact_person.first_name'),
    },
    {
      title: 'Last name',
      value: last_name,
      editValue: edit_last_name,
      isUnsaved: is_edit_last_name,
      onChange: set_edit_last_name,
      editError: getError('contact_person.last_name'),
    },
    {
      Input: InputPhone,
      title: 'Phone number',
      value: phone_number,
      editValue: edit_phone_number,
      isUnsaved: is_edit_phone_number,
      onChange: set_edit_phone_number,
      editError: getError('contact_person.phone_number'),
    },
    {
      title: 'Email address',
      value: email,
      editValue: edit_email,
      isUnsaved: is_edit_email,
      onChange: set_edit_email,
      editError: getError('contact_person.email'),
    },
    {
      title: 'Street address',
      value: street_address_1,
      editValue: edit_street_address_1,
      isUnsaved: is_edit_street_address_1,
      onChange: set_edit_street_address_1,
      editError: getError('street_address_1'),
    },
    {
      title: 'City',
      value: city,
      editValue: edit_city,
      isUnsaved: is_edit_city,
      onChange: set_edit_city,
      editError: getError('city'),
    },
    {
      title: 'State',
      value: state,
      editValue: edit_state,
      isUnsaved: is_edit_state,
      onChange: set_edit_state,
      typeEdit: 'select',
      options: complianceStore.getStates('US'),
      loadOptions: complianceStore.loadBusinessProfileStates,
      editError: getError('state_id'),
    },
    {
      title: 'Postal code',
      value: postal_code,
      editValue: edit_postal_code,
      isUnsaved: is_edit_postal_code,
      onChange: set_edit_postal_code,
      editError: getError('postal_code'),
    },
    {
      title: 'Country',
      value: country && complianceStore.getCountryLabel(country),
      editValue: edit_country,
      isUnsaved: is_edit_country,
      onChange: set_edit_country,
      typeEdit: 'selectValue',
      options: complianceStore.complianceCountries,
      editError: getError('country'),
    },
    {
      title: 'Business type',
      value: business_type,
      editValue: edit_business_type,
      isUnsaved: is_edit_business_type,
      onChange: set_edit_business_type,
      typeEdit: 'select',
      options: complianceStore.businessProfileBusinessRegistrationTypes,
      loadOptions: complianceStore.loadBusinessProfileBusinessRegistrationTypes,
      editError: getError('business_type_id'),
    },
    {
      title: 'Stock exchange',
      value: stock_exchange,
      editValue: edit_stock_exchange,
      isUnsaved: is_edit_stock_exchange,
      onChange: set_edit_stock_exchange,
      typeEdit: 'select',
      options: complianceStore.businessProfileStockExchanges,
      loadOptions: complianceStore.loadGetBusinessProfileStockExchanges,
      editError: getError('stock_exchange_id'),
    },
    {
      title: 'Stock ticker symbol',
      value: stock_ticker_symbol,
      editValue: edit_stock_ticker_symbol,
      isUnsaved: is_edit_stock_ticker_symbol,
      onChange: set_edit_stock_ticker_symbol,
      editError: getError('stock_ticker_symbol'),
    },
    {
      title: '2FA Email',
      value: brand_two_fa_verification_email,
      editValue: edit_brand_two_fa_verification_email,
      isUnsaved: is_edit_brand_two_fa_verification_email,
      onChange: set_edit_brand_two_fa_verification_email,
      editError: getError('brand_two_fa_verification_email'),
    },
    {
      title: '2FA Verification Status',
      value: brand_two_fa_verification_status,
      editValue: edit_brand_two_fa_verification_status,
      isUnsaved: is_edit_brand_two_fa_verification_status,
      onChange: set_edit_brand_two_fa_verification_status,
      typeEdit: 'selectStatus',
      options: complianceStore.businessInformationBrandTwoFaVerificationStatus,
      editError: getError('brand_two_fa_verification_status'),
    },
  ]

  const handleSave = async () => {
    try {
      const res = await onSave()
      if (res?.data) {
        setTenDLCData(res?.data)
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <AdminDetailCard
      title={'Brand information'}
      data={rows}
      additionalTitleContent={status && <TenDLCStatus status={status} />}
      isEdit={isEdit}
      actions={
        isForEdit &&
        (!isEdit ? (
          <Button
            text={'Edit'}
            contained={'secondary'}
            size={'medium'}
            icon={'edit'}
            onClick={onEdit}
          />
        ) : (
          <>
            <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={onClose} />
            <Button
              text={'Save'}
              contained={'primary'}
              size={'medium'}
              onClick={handleSave}
              disabled={isSaveLoading}
            />
          </>
        ))
      }
    />
  )
})
