export const schedulePeriods = ['minutes', 'hours', 'days', 'weeks', 'months'] as const

export type ISchedulePeriod = (typeof schedulePeriods)[number]

export const schedulePeriodTitle: Record<ISchedulePeriod, string> = {
  minutes: 'Minutes',
  hours: 'Hours',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
}
