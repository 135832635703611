import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { isEmail } from 'shared/lib'
import type { IDropdownItem } from 'shared/ui'
import {
  ComplianceApi,
  complianceStore,
  IBusinessType,
  type IParamsSetCampaignPackageData,
  IPlaneTypes,
  type IResponseStockExchanges,
} from 'entities/Compliance'
import { type ComplianceServiceBrand } from 'entities/Compliance/model'
import { tenDlcLimitsStore } from 'entities/TenDlc'
import { type LocalNumbersStepsStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersStepsStore'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers'
import { SettingsPath } from 'pages/settings/route/settingsPath'

const maxStockTickerLength = 10

export class PackageStore {
  constructor(private _localNumbersStore: LocalNumbersStepsStore) {
    makeAutoObservable(this)

    this.initVerificationEmail()
  }

  private _activePlaneType: IPlaneTypes | null = null
  private _businessType: IBusinessType | null = null
  private _stockExchanges: IResponseStockExchanges[] = []
  private _stockTicker = ''
  private _activeStockExchange: string | null = null
  private _verificationEmail = ''
  private _emailLocalError = ''
  private _status: ComplianceServiceBrand['status'] | null = null

  get activePlaneType() {
    return this._activePlaneType
  }

  get businessType() {
    return this._businessType
  }

  get stockExchanges() {
    return this._stockExchanges
  }

  get stockExchangesItems(): IDropdownItem[] {
    return this._stockExchanges.map((exchange) => ({
      id: exchange.value,
      label: exchange.title,
    }))
  }

  get stockTicker() {
    return this._stockTicker
  }

  get activeStockExchange() {
    return this._activeStockExchange
  }

  get verificationEmail() {
    return this._verificationEmail
  }

  get emailLocalError() {
    return this._emailLocalError
  }

  get status() {
    return this._status
  }

  get nextButtonText() {
    return this.localNumbersStore.internalRejectedOnly ? 'Submit my application' : 'Next'
  }

  get isPublicBrand() {
    return this.businessType === IBusinessType.PUBLIC_PROFIT
  }

  get isPrivateBrand() {
    return this.businessType === IBusinessType.PRIVATE_PROFIT
  }

  get isNextDisabled() {
    if (!this.businessType || !this.activePlaneType) return true

    const { dlcPlan } = tenDlcLimitsStore
    const isLowVolumePlanDisabled =
      dlcPlan?.availableForUpdatePlans === IPlaneTypes.STANDARD &&
      this.activePlaneType === IPlaneTypes.LOW_VOLUME_STANDARD

    if (isLowVolumePlanDisabled) return true

    if (this.isPrivateBrand) {
      return !Boolean(this._activePlaneType)
    }
    if (this.isPublicBrand) {
      if (
        !this.verificationEmail.length ||
        !this.activeStockExchange ||
        !this.stockTicker.length ||
        this.emailLocalError.length
      ) {
        return true
      }
    }

    return false
  }

  get localNumbersStore() {
    return this._localNumbersStore
  }

  get packageShowingList() {
    const { complianceServiceBrand } = this._localNumbersStore.complianceLayerStore

    if (complianceServiceBrand?.isPublicBrandFailed) {
      return complianceServiceBrand.plan_type === IPlaneTypes.LOW_VOLUME_STANDARD
        ? [IPlaneTypes.LOW_VOLUME_STANDARD]
        : [IPlaneTypes.STANDARD]
    }

    return [IPlaneTypes.LOW_VOLUME_STANDARD, IPlaneTypes.STANDARD]
  }

  init = ({ data, plan_type, business_email_verification, status }: ComplianceServiceBrand) => {
    this.setActivePlaneType(plan_type)
    this._status = status

    if (data) {
      this.setBusinessType(data.entityType)

      if (data.stockExchange) this.setStockExchange(data.stockExchange)
      if (data.stockSymbol) this.setStockTicker(data.stockSymbol)
    }

    if (business_email_verification?.email) {
      this.setVerificationEmail(business_email_verification.email)
    }
  }

  initVerificationEmail = () => {
    const { pointsOfContact } = this._localNumbersStore.complianceLayerStore
    const initEmail = pointsOfContact.firstContact?.email

    if (initEmail) {
      this.setVerificationEmail(initEmail)
    }
  }

  setActivePlaneType = (activePlaneType: IPlaneTypes | null) => {
    if (!activePlaneType) return

    this._activePlaneType = activePlaneType
  }

  setBusinessType = (businessType: IBusinessType | null) => {
    this._businessType = businessType
  }

  setStockTicker = (stockTicker: string) => {
    if (maxStockTickerLength < stockTicker.length) return

    this._stockTicker = stockTicker
  }

  setStockExchange = (value: string) => {
    this._activeStockExchange = value
  }

  setVerificationEmail = (value: string) => {
    this.setEmailLocalError('')

    this._verificationEmail = value
  }

  setEmailLocalError = (value: string) => {
    this._emailLocalError = value
  }

  checkIsEmail = (email: string) => {
    const isCorrect = isEmail(email)

    const errorText = isCorrect ? '' : 'Please enter a valid email address'

    this.setEmailLocalError(errorText)
  }

  setStockExchanges = (stockExchanges: IResponseStockExchanges[]) => {
    this._stockExchanges = stockExchanges
  }

  getStockExchanges = async () => {
    const { data } = await ComplianceApi.getStockExchanges()

    this.setStockExchanges(data)
  }

  increaseStep = async () => {
    if (
      !this.localNumbersStore.campaignId ||
      !this.activePlaneType ||
      !this.businessType ||
      (this.businessType === IBusinessType.PUBLIC_PROFIT && !this.activeStockExchange)
    )
      return

    try {
      this.localNumbersStore.stepsStore.setStepLoading(true)
      const { internalRejectedOnly } = this.localNumbersStore
      const params: IParamsSetCampaignPackageData = {
        step: 6,
        campaignId: this.localNumbersStore.campaignId,
        planType: this.activePlaneType,
        businessType: String(this.businessType),
      }

      if (this.businessType === IBusinessType.PUBLIC_PROFIT) {
        params.stockExchange = this.activeStockExchange ? this.activeStockExchange : ''
        params.stockTickerSymbol = this.stockTicker
        params.businessVerificationEmail = this.verificationEmail
      }

      const { data } = await ComplianceApi.setCampaignPackageData(params)

      if (internalRejectedOnly) {
        await ComplianceApi.submitLocalNumberApplication({
          ten_dlc_plan: this.activePlaneType,
          ten_dlc_registration_submitted: true,
        })
        const { data } = await ComplianceApi.updateComplianceServicesCampaign({
          step: 7,
          campaignId: this.localNumbersStore.campaignId,
        })

        this.localNumbersStore.complianceLayerStore.set10DLCServiceCampaign(data)

        complianceStore.fetchComplianceServicesList()
      }

      await this.localNumbersStore.complianceLayerStore.set10DLCServiceBrand(data.brand)
      this.localNumbersStore.complianceLayerStore.getCampaignData()

      const nextStepPath = internalRejectedOnly
        ? `/${SettingsPath.compliance.localNumbers.root}`
        : `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.orderSummary}`

      uiStore.changeRoute({
        path: nextStepPath,
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.localNumbersStore.stepsStore.setStepLoading(false)
    }
  }
}
