import axios, { CanceledError, type CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { TableStore } from 'shared/ui/Table'
import { toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import type { IKeyword } from 'entities/Keywords'
import { KeywordsApi } from 'entities/Keywords/api/keywords'
import type {
  IParamsGetKeywords,
  IResponseGetKeywords,
  IResponseKeyword,
} from 'entities/Keywords/api/type'
import { Keyword } from 'entities/Keywords/model/Keyword'
import { FiltersAndSearchStore } from 'widgets/FiltersAndSearch'

export type ISetIsKeywordsActionShown = (isShown: boolean) => void

export type IKeywordsListStoreProps = {
  setIsKeywordsActionShown: ISetIsKeywordsActionShown
}

export class KeywordsListStore {
  private _page = 1
  private _total = 0
  private _limit = 10
  private _isLoading = true
  private _isInitialLoading = true
  private _keywordsMap = new Map<number, IKeyword>()
  private _cancelTokenSource: CancelTokenSource | null = null
  private _tableStore = new TableStore<IKeyword>({
    element: 'keyword',
    sortBy: 'created_at',
    sortOrder: 'desc',
  })
  private _disposeLoadKeywords: IReactionDisposer | null = null
  private _disposeKeywordsActionShown: IReactionDisposer | null = null
  private _disposeRequestParams: IReactionDisposer | null = null
  private _filtersAndSearchStore = new FiltersAndSearchStore({
    getFilters: () => KeywordsApi.getFilters().then(({ data }) => data),
  })

  constructor(setIsKeywordsActionShown: ISetIsKeywordsActionShown) {
    makeAutoObservable(this)

    this.reactionKeywordsActionShown(setIsKeywordsActionShown)

    this.reactionLoadKeywords()
  }

  get requestParams(): IParamsGetKeywords {
    // TODO: move to BE
    const filterList = this._filtersAndSearchStore.params.filtersList.map((item) => ({
      ...item,
      filters: item.filters.map((filter) => {
        if (filter.key === 'keywords.is_active') {
          const mapper = (value: string) => (value === 'active' ? '1' : '0')

          if (Array.isArray(filter.value)) {
            const newFilterValue = filter.value.map(mapper)

            return { ...filter, value: newFilterValue }
          }
        }
        return filter
      }),
    }))

    return {
      limit: this.limit || undefined,
      sortBy: this.tableStore.sortBy,
      sortOrder: this.tableStore.sortOrder,
      term: this._filtersAndSearchStore.params.search,
      filtersList: filterList,
    }
  }

  get paramsGetItems(): IParamsGetKeywords {
    return {
      page: this._page,
      ...this.requestParams,
    }
  }

  get limit() {
    return this._limit
  }

  get isLoading() {
    return this._isLoading
  }

  get isInitialLoading() {
    return this._isInitialLoading
  }

  get page() {
    return this._page
  }

  get tableStore() {
    return this._tableStore
  }

  get total() {
    return this._total
  }

  get isKeywordsEmpty() {
    return (
      !this._isLoading && !this._keywordsMap.size && !this._filtersAndSearchStore.hasSearchParams
    )
  }

  get isNoSearchResults() {
    return (
      !this._isLoading && !this._keywordsMap.size && this._filtersAndSearchStore.hasSearchParams
    )
  }

  get keywords() {
    return Array.from(this._keywordsMap.values())
  }

  get filtersAndSearchStore() {
    return this._filtersAndSearchStore
  }

  reactionLoadKeywords = () => {
    this._disposeLoadKeywords?.()

    this._disposeRequestParams = reaction(
      () => this.requestParams,
      () => {
        this._isLoading = true
        this._page = 1
      }
    )

    this._disposeLoadKeywords = reaction(
      () => this.paramsGetItems,
      () => this.loadKeywords(),
      {
        delay: 500,
      }
    )
  }

  loadKeywords = async (noLoading?: boolean) => {
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        if (!noLoading) {
          this._isLoading = true
        }
      })
      const { data } = await KeywordsApi.getKeywords(this.paramsGetItems, {
        cancelToken: this._cancelTokenSource?.token,
      })

      this.setGetKeywordsResponse(data)
      runInAction(() => {
        this._isLoading = false
      })
    } catch (e) {
      runInAction(() => {
        this._isLoading = e instanceof CanceledError
      })
    } finally {
      runInAction(() => {
        this._isInitialLoading = false
      })
    }
  }

  setGetKeywordsResponse = ({ data, meta }: IResponseGetKeywords) => {
    this._keywordsMap.clear()
    this._tableStore.setRows([])

    this.setKeywordMap(data)
    this._tableStore.setRows(this.keywords)
    this._page = meta.current_page
    this._limit = meta.per_page
    this._total = meta.total
  }

  setKeywordMap = (keywordsResponse: IResponseKeyword[]) => {
    keywordsResponse.forEach((keywordResponse) => {
      try {
        this._keywordsMap.set(keywordResponse.id, new Keyword(keywordResponse))
      } catch (e) {
        console.error(e)
      }
    })
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  reactionKeywordsActionShown = (setIsKeywordsActionShown: ISetIsKeywordsActionShown) => {
    this._disposeKeywordsActionShown?.()
    this._disposeKeywordsActionShown = reaction(
      () => [this._isLoading, this._keywordsMap.size],
      () => setIsKeywordsActionShown(!this.isKeywordsEmpty)
    )
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }

  clearReactions = () => {
    this._disposeKeywordsActionShown?.()
    this._disposeLoadKeywords?.()
    this._disposeRequestParams?.()
  }

  onDelete = (id: number) => {
    let noLoading = true
    this._keywordsMap.delete(id)

    if (this._keywordsMap.size === 0 && this.page > 1) {
      this._page -= 1
      noLoading = false
    }
    void this.loadKeywords(noLoading)
  }

  onBulkDelete = () => {
    const bulkAll = this._tableStore.bulkAllMode
    const ids = this._tableStore.selectedIds as number[]
    const idsLength = ids.length
    let noLoading = true
    const modalId = nanoid()

    const handleDelete = async () => {
      this._isLoading = true
      modalStore.closeModal(modalId)

      try {
        await KeywordsApi.deleteBulkKeywords(ids, bulkAll)
        toastStore.add({
          title: bulkAll ? 'All keywords deleted' : `Keyword${idsLength > 1 ? 's' : ''} deleted`,
          type: 'success',
        })

        this._tableStore.resetSelected()
        this._keywordsMap.clear()
        if (this._keywordsMap.size === 0 && this.page > 1) {
          this._page -= 1
          noLoading = false
        }
        this.loadKeywords(noLoading)
      } catch (error) {
        runInAction(() => {
          this._isLoading = false
          toastStore.add({
            title: 'Something went wrong. Please try again.',
            type: 'error',
          })
        })
      }
    }

    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.ALERT,
      title: bulkAll
        ? 'Delete all keywords?'
        : `Delete ${idsLength} keyword${idsLength > 1 ? 's' : ''}?`,
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: handleDelete,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(modalId),
      },
    })
  }
}
