// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N7Nt7OK__ZfQPfAGCeHI{display:flex;align-items:center;justify-content:center;width:18px;height:16px;border-radius:4px;background-color:var(--background-primary-inverted-disabled)}.IXmisGJh9KZ9NxsIKJfg{display:flex;align-items:center;justify-content:center;gap:4px}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,4DAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA","sourcesContent":[".hotKey {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 18px;\n  height: 16px;\n  border-radius: 4px;\n  background-color: var(--background-primary-inverted-disabled);\n}\n\n.labelWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hotKey": "N7Nt7OK__ZfQPfAGCeHI",
	"labelWrapper": "IXmisGJh9KZ9NxsIKJfg"
};
export default ___CSS_LOADER_EXPORT___;
