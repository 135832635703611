import React, { useEffect, useState } from 'react'
import { uiStore } from 'shared/store/uiStore'
import { type PageLayoutStore } from 'shared/layout'
import { type IResponseRecurringBroadcastById } from 'entities/Broadcast/api/types'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import { BroadcastDetailRecurringStore } from 'widgets/BroadcastDetail/BroadcastDetailRecurring/store/broadcastDetailRecurringStore'
import { BroadcastDetailRecurringContext } from 'widgets/BroadcastDetail/BroadcastDetailRecurring/context/context'
import { Content } from './Content'

type IBroadcastDetailOneTimeProps = {
  broadcastResponse: IResponseRecurringBroadcastById
  pageLayoutStore: PageLayoutStore
}

export const BroadcastDetailRecurring = ({
  broadcastResponse,
  pageLayoutStore,
}: IBroadcastDetailOneTimeProps) => {
  const [store] = useState(
    () => new BroadcastDetailRecurringStore(broadcastResponse, pageLayoutStore)
  )
  useEffect(() => {
    uiStore.setCustomPathLabel(
      `/${BroadcastsRoutes.root}/${BroadcastsRoutes.view}/${broadcastResponse.id}`,
      broadcastResponse.name
    )
  }, [])
  return (
    <BroadcastDetailRecurringContext.Provider value={store}>
      <Content />
    </BroadcastDetailRecurringContext.Provider>
  )
}
