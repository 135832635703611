import { makeAutoObservable } from 'mobx'
import { type IResponseBusinessInformation } from 'entities/Compliance'

export class BusinessInformation {
  private _business_type: IResponseBusinessInformation['business_type'] | null = null
  private _industry: IResponseBusinessInformation['industry'] | null = null
  private _website_url: IResponseBusinessInformation['website_url'] | null = null
  private _social_media_url: IResponseBusinessInformation['social_media_url'] | null = null
  private _regions_of_operation: IResponseBusinessInformation['regions_of_operation'] = []
  private _business_name: IResponseBusinessInformation['business_name'] | null = null
  private _registration_number: IResponseBusinessInformation['registration_number'] | null = null
  private _street_address_1: IResponseBusinessInformation['street_address_1'] | null = null
  private _country: IResponseBusinessInformation['country'] | null = null
  private _city: IResponseBusinessInformation['city'] | null = null
  private _state_id: IResponseBusinessInformation['state_id'] | null = null
  private _street_address_2: IResponseBusinessInformation['street_address_2'] | null = null
  private _zip_code: IResponseBusinessInformation['zip_code'] | null = null
  private _irs_ein_document: IResponseBusinessInformation['irs_ein_document'] | null = null
  private _industry_id: IResponseBusinessInformation['industry_id'] | null = null
  private _business_type_id: IResponseBusinessInformation['business_type_id'] | null = null
  private _reg_progress_status: IResponseBusinessInformation['reg_progress_status'] | null = null
  private _origin!: IResponseBusinessInformation

  constructor(response: IResponseBusinessInformation) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseBusinessInformation) => {
    this._business_type = response.business_type
    this._industry = response.industry
    this._website_url = response.website_url
    this._social_media_url = response.social_media_url
    this._regions_of_operation = response.regions_of_operation
    this._business_name = response.business_name
    this._registration_number = response.registration_number
    this._street_address_1 = response.street_address_1
    this._country = response.country
    this._city = response.city
    this._state_id = response.state_id
    this._street_address_2 = response.street_address_2
    this._zip_code = response.zip_code
    this._irs_ein_document = response.irs_ein_document
    this._industry_id = response.industry_id
    this._business_type_id = response.business_type_id
    this._reg_progress_status = response.reg_progress_status
    this._origin = response
  }

  get origin() {
    return this._origin
  }

  get reg_progress_status() {
    return this._reg_progress_status
  }

  get business_type_id() {
    return this._business_type_id
  }

  get industry_id() {
    return this._industry_id
  }

  get irs_ein_document() {
    return this._irs_ein_document
  }

  get zip_code() {
    return this._zip_code || ''
  }

  get street_address_2() {
    return this._street_address_2 || ''
  }

  get state_id() {
    return this._state_id || ''
  }

  get city() {
    return this._city || ''
  }

  get business_type() {
    if (!this._business_type) {
      return {
        name: '',
      }
    }

    return this._business_type
  }

  get industry() {
    if (!this._industry) {
      return {
        name: '',
      }
    }

    return this._industry
  }

  get website_url() {
    return this._website_url
  }

  get social_media_url() {
    return this._social_media_url
  }

  get regions_of_operation() {
    return this._regions_of_operation
  }

  get business_name() {
    return this._business_name || ''
  }

  get registration_number() {
    return this._registration_number || ''
  }

  get street_address_1() {
    return this._street_address_1 || ''
  }

  get country() {
    return this._country
  }

  get secondLine() {
    return `${this._city}, ${this._state_id} ${this._zip_code}`
  }
}
