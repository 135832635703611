import { makeAutoObservable, IReactionDisposer, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import { isEmail } from 'shared/lib'
import { AuthApi } from 'entities/Auth/api'

export class ForgotPasswordStore {
  private _email = ''
  private _loading = false
  private _success = false

  // Email validation
  private _disposeEmailValidation: IReactionDisposer | null = null
  private _disposeEmailValidationTrigger: IReactionDisposer | null = null
  private _emailError: string | null = null
  private _emailValidationError: string | null = null
  private _emailValidationTrigger: string | null = null

  constructor() {
    makeAutoObservable(this)
    this.reactionEmailValidation()
    this.reactionEmailValidationTrigger()
  }

  get isValidEmail() {
    return isEmail(this._email)
  }

  get hasEmail() {
    return !!this.email
  }

  get email() {
    return this._email
  }

  get loading() {
    return this._loading
  }

  get emailError() {
    return this._emailError
  }

  get success() {
    return this._success
  }

  get emailValidationError() {
    return this._emailValidationError
  }

  sendResetPasswordLink = async () => {
    if (!this.isValidEmail) return
    this.clearErrors()
    try {
      this.setLoading(true)
      await AuthApi.forgotPassword({ email: this.email })
      this._success = true
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        this._emailError = error.response?.data?.email?.[0] || ''
      }
    } finally {
      this.setLoading(false)
    }
  }

  clearErrors = () => {
    if (this._emailError) this._emailError = ''
    if (this._emailValidationError) this._emailValidationError = ''
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setEmail = (value: string) => {
    this._email = value
  }

  triggerEmailValidation = () => {
    this._emailValidationTrigger = nanoid()
  }

  reactionEmailValidation = () => {
    this._disposeEmailValidation?.()
    this._disposeEmailValidation = reaction(
      () => [this._emailValidationTrigger, this.isValidEmail],
      (isValid) => {
        if (isValid) this.clearErrors()
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionEmailValidationTrigger = () => {
    this._disposeEmailValidationTrigger?.()
    this._disposeEmailValidationTrigger = reaction(
      () => this._emailValidationTrigger,
      (value) => {
        this._emailValidationError =
          value && !this.isValidEmail
            ? this.hasEmail
              ? 'Please enter a valid email address'
              : 'Please enter email address'
            : null
        if (this.isValidEmail) {
          this.clearErrors()
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
          this.sendResetPasswordLink()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }
}
