import { type IDropdownItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { type Segment } from 'entities/Segment/model/Segment'
import { type ContactsMenuStore } from 'widgets/ContactsMenu'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'

export const useDropdownItems = (
  segment: Segment,
  contactsMenuStore: ContactsMenuStore
): IDropdownItem[] | undefined => {
  const { onOpen, setSegment, segment: activeSegment, segmentBox } = contactsFilterStore
  const segmentModalStore = contactsMenuStore.segmentModalStore
  const { resetActive } = contactsMenuStore

  return [
    {
      id: 0,
      iconL: 'filter',
      label: 'Edit filters',
      onClick: () => {
        const { pathName } = uiStore
        if (pathName !== '/contacts') {
          uiStore.changeRoute({ path: '/contacts' })
        }

        onOpen(true)
        setSegment(segment)

        segmentBox.setHasChanges(false)
        contactsMenuStore.pageLayoutStore?.handleToggleCollapseIsMobile()
      },
    },
    {
      id: 1,
      iconL: 'edit',
      label: 'Rename',
      onClick: () => {
        segmentModalStore?.openRenameSegmentModal(segment.name)
        setSegment(segment)
        contactsMenuStore.pageLayoutStore?.handleToggleCollapseIsMobile()
      },
    },
    {
      id: 2,
      iconL: 'delete',
      label: 'Delete',
      onClick: () => {
        contactsMenuStore.pageLayoutStore?.handleToggleCollapseIsMobile()
        const deleteHandler = () => {
          if (activeSegment?.id === segment.id) {
            if (uiStore.navigate) {
              uiStore.navigate('/contacts')
            }
            resetActive()
          }
          onOpen(false)
          segmentModalStore?.deleteSegment(segment.id)
          segmentModalStore?.closeModal()
        }

        segmentModalStore?.openDeleteSegmentModal(segment.name, deleteHandler)
      },
    },
  ]
}
