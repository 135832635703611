import { observer } from 'mobx-react-lite'
import React from 'react'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { TypingCard } from 'widgets/TypingCard/TypingCard'
import styles from './styles.module.scss'

export const TypingContent = observer(() => {
  const store = useConversationMessagesContext()

  return (
    <div className={styles.wrap}>
      {store.typings.map((typing) => (
        <TypingCard key={typing.userId} typing={typing} />
      ))}
    </div>
  )
})
