import { runInAction } from 'mobx'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { integrationsStore, IResponseUserIntegration } from 'entities/Integrations'

const IntegrationConnected = {
  handle: (data: IResponseUserIntegration) => {
    runInAction(() => {
      integrationsStore.connectUserIntegration(data)
    })
  },
}

export const EventIntegrationConnected = {
  subscribe: () => websocket.on(WebsocketEvent.IntegrationConnected, IntegrationConnected.handle),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.IntegrationConnected, IntegrationConnected.handle),
}
