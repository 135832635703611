import { ChatbotRoutes } from './type'

const chatbot = `${ChatbotRoutes.root}`
const chatbotAll = `${chatbot}/${ChatbotRoutes.all}`
const chatbotNew = `${chatbot}/${ChatbotRoutes.new}`
const chatbotAnalytic = `${chatbot}/${ChatbotRoutes.analytic}`

export const ChatbotPath = {
  root: chatbot,
  all: chatbotAll,
  new: chatbotNew,
  analytic: chatbotAnalytic,
}
