// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G8H5vq1wzJr5QozK2Cs5{margin-bottom:29px}", "",{"version":3,"sources":["webpack://./src/entities/Phone/ui/QuestionnaireOrSelectNumberModal/ui/StepSelectNumber/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,kBAAA","sourcesContent":[".wrap {\n}\n\n.tabs {\n  margin-bottom: 29px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "G8H5vq1wzJr5QozK2Cs5"
};
export default ___CSS_LOADER_EXPORT___;
