import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { ITabItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { AdminOrganizationsApi, IParamsCreateOrganization } from 'entities/Admin'
import { AdminRoutes } from 'pages/admin/route/type'
import { INewOrganizationForm } from '../type'

export enum EnumTypeNewOrganization {
  PaidOrganization = 'paidOrganization',
  EnterpriseTrial = 'enterpriseTrial',
}

export class NewOrganizationStore {
  tabs: ITabItem<EnumTypeNewOrganization>[] = [
    {
      key: EnumTypeNewOrganization.PaidOrganization,
      name: 'Paid organization (no CC)',
    },
    {
      key: EnumTypeNewOrganization.EnterpriseTrial,
      name: 'Enterprise trial',
    },
  ]

  errorsObj: { [key: string]: string[] } = {}

  saveLoading = false

  private _type: EnumTypeNewOrganization = EnumTypeNewOrganization.PaidOrganization

  get type() {
    return this._type
  }

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {}

  selectTypeTab = (tab: ITabItem<EnumTypeNewOrganization>) => {
    this._type = tab.key
  }

  createOrganization = async (formData: INewOrganizationForm, plan: string) => {
    const data: IParamsCreateOrganization = {
      organization_name: formData.organizationName,
      noCardTest: true,
      plan,
      first_name: formData.name || '',
      last_name: formData.lastName,
      number: formData.phone,
      email: formData.email,
      password: formData.password,
      new_billing: true,
      token: '',
      terms: true,
      country: 'US',
    }

    const isTrial = this.type === EnumTypeNewOrganization.EnterpriseTrial

    if (isTrial) {
      data.is_enterprise_trial = this.type === EnumTypeNewOrganization.EnterpriseTrial
      data.ignore_fees = formData.ignoreFees
      data.expiration_time =
        formData.expirationTime === 'indefinite'
          ? formData.expirationTime
          : Number(formData.expirationTime)
    } else {
      data.is_trial = formData.twoWeeksTrial

      if (!formData.twoWeeksTrial) {
        data.numbers = formData.paidNumbers
        data.seats = formData.paidSeats
      }
    }

    try {
      this.saveLoading = true
      await AdminOrganizationsApi.createOrganization(data)

      uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.organizations}`)
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data) {
          this.errorsObj = e.response.data
        }
      }
      console.error(e)
    } finally {
      this.saveLoading = false
    }
  }
}
