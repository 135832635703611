import { observer } from 'mobx-react-lite'
import { EmptyState, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import quickLinksPreviewImg from 'shared/assets/images/quickLinks/quick_links_preview.png'
import { getCDNAssetURL } from 'shared/lib'
import { useQuickLinksContext } from 'pages/settings/pages/quickLinks/context/quickLinksContext'

import styles from './styles.module.scss'
import { CreateQuickLinkButton, GuideQuickLinkButton } from '../QuickLinkAction'

export const QuickLinkEmpty = observer(() => {
  const { listStore } = useQuickLinksContext()

  const handleCreate = () => listStore.search('', true)

  return (
    <section className={styles.empty}>
      <EmptyState
        title={
          <span className={styles.title}>
            <a
              href={links.quickLinksGuide}
              rel='noreferrer'
              target='_blank'
              className={styles.titlePreview}
            >
              <img alt='Quick links preview' src={getCDNAssetURL(quickLinksPreviewImg)} />
            </a>
            <Typography variant={'heading-md'} ariaLabel='Quick links'>
              Quick links
            </Typography>
          </span>
        }
        actions={
          <div className={styles.actions}>
            <CreateQuickLinkButton onCreate={handleCreate} />
            <GuideQuickLinkButton />
          </div>
        }
        text='Your fastest route to productivity'
        typographyProps={{
          variant: 'body-rg-regular',
          ariaLabel: 'KnowledgeBaseText',
          marginTop: '4px',
        }}
        boxProps={{
          gap: '20px',
          width: 'unset',
          height: 'unset',
        }}
        noIcon
      />
    </section>
  )
})
