import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { EmptyState, IColumn, IRow, layoutStore, Table } from 'shared/ui'
import { Pagination } from 'shared/ui/Table'
import { getCellForNameDefault } from 'shared/helpers/getCellForNameDefault'
import type { Contact } from 'entities/Contacts/model/Contact'
import { ContactIntegration } from 'entities/Integrations/model/ContactIntegration'
import {
  getCellForTags,
  getCellNumber,
  getIntegrationCellMobileNumber,
  getIntegrationCellNumber,
} from 'widgets/ContactsTable/helpers/helpers'
import type { EnrollmentListStore } from 'widgets/ContactEnrollment/store'
import { EnrollmentActions } from '../../EnrollmentActions'
import styles from './styles.module.scss'

type IEnrollmentContactsTableProps = {
  enrollmentListStore: EnrollmentListStore
}

export const EnrollmentContactsTable: FC<IEnrollmentContactsTableProps> = observer(
  ({ enrollmentListStore }) => {
    const { isViewXXsGlobal } = layoutStore
    const { contactsTableStore: store } = enrollmentListStore

    useEffect(() => {
      if (store.total) {
        store.setFilter(enrollmentListStore.filters)
        store.loadContacts()
      }

      return () => {
        store.reset()
      }
    }, [])

    const rows = (): IRow<Contact | ContactIntegration>[] =>
      store.items.map((item) => {
        item.ariaLabel = 'ContactItemRow'

        return item
      })

    const columns: IColumn<Contact | ContactIntegration>[] = enrollmentListStore.existHubspotFilter
      ? [
          {
            field: 'name',
            name: 'Name',
            width: '100%',
            renderRowCell: (row) =>
              getCellForNameDefault({
                fullName: row.fullName,
                avatarInfo: row.avatarInfo,
                width: isViewXXsGlobal ? 180 : undefined,
              }),
          },
          {
            field: 'phone_number',
            name: 'Phone Number',
            renderRowCell: (row) => getIntegrationCellNumber(row as ContactIntegration),
          },
          {
            field: 'mobile_phone_number',
            name: 'Mobile Phone Number',
            renderRowCell: (row) => getIntegrationCellMobileNumber(row as ContactIntegration),
          },
          {
            field: 'email',
            name: 'Email',
          },
        ]
      : [
          {
            field: 'name',
            name: 'Name',
            width: '100%',
            renderRowCell: (row) =>
              getCellForNameDefault({
                fullName: row.fullName,
                avatarInfo: row.avatarInfo,
                width: isViewXXsGlobal ? 180 : undefined,
              }),
          },
          {
            field: 'number',
            name: 'Number',
            renderRowCell: (row) => getCellNumber(row as Contact),
          },
          {
            field: 'email',
            name: 'Email',
          },
          {
            field: 'tag',
            name: 'Tags',
            renderRowCell: (row) => getCellForTags(row as Contact),
          },
        ]

    return (
      <div className={styles.contentContainer}>
        <div className={styles.mainContainer}>
          <section className={styles.previewSection}>
            {!!store.total && (
              <Table
                rows={rows()}
                rowsCount={store.total}
                columns={columns}
                isLoading={store.loading}
                pinnedColumnNames={['name']}
                sortOrder={store.sortOrder}
                sortBy={store.sortBy}
                initPage={store.page}
                noPagination
              />
            )}

            {!store.total && (
              <EmptyState
                iconProps={{ icon: 'contacts', color: 'var(--content-neutral-primary)' }}
                text={'No matching contacts'}
              />
            )}
          </section>

          <footer className={styles.footerActions}>
            {!!store.total && (
              <Pagination
                initPage={store.page}
                rowsCount={store.total}
                limit={store.length}
                onChange={store.onChangePagination}
              />
            )}
            <EnrollmentActions />
          </footer>
        </div>
      </div>
    )
  }
)
