import { Button, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum } from 'entities/Auth'

export const LoginFormBottom = () => {
  const onClick = () => {
    uiStore.changeRoute({
      path: `/${AuthRoutesEnum.register}`,
    })
  }

  return (
    <div>
      <Typography variant={'body-sm-medium'} ariaLabel={'termsAndPolicy'}>
        {'Don’t have a Salesmsg account?'}
        <Button onClick={onClick} typeBtn={'link'} text={'Sign up'} />
      </Typography>
    </div>
  )
}
