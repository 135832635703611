import classnames from 'classnames'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, CopyWrapper } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  IntegrationKey,
  IIntegrationHubspotPropertyFormatted,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { usersStore } from 'entities/Users'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/styles.module.scss'
import { NewHubspotPropertiesDropdown } from 'widgets/NewHubspotPropertiesDropdown'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { HubspotIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/store/hubspotIntegrationStore'
import { ItemTicketsRow } from './ItemTicketsRow'

type ITicketsProps = {
  contact: Contact
  handleClear: (id: string, source_type: IUiSettingsSourceType) => void
  store: HubspotIntegrationStore
}

const Empty = () => <div className={styles.emptyData}>No tickets found</div>

const Tickets = observer(({ contact, handleClear, store }: ITicketsProps) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole
  const contactsDetailsStore = useContactsDetailsContext()

  const {
    newHubspotPropertiesDropdownStore,
    hubspotIntegration,
    draggableTicketsItemsMap,
    hasShowAllTicketsFields,
    showMoreTicketsLabel,
    hubspotInfo,
  } = store

  const tickets = hubspotInfo?.tickets || []

  if (!tickets?.length) return <Empty />

  const ticketList = () => {
    const data: IIntegrationHubspotPropertyFormatted[] = []
    const ticket = tickets?.[0]

    if (ticket) {
      for (const [key, value] of Object.entries(ticket)) {
        data.push({
          key: key,
          label: value?.label ?? '',
          value: value?.value?.toString() ?? '',
        })
      }
    }

    return data.filter((ticket) => ticket.key !== 'subject') || []
  }

  const ticketFullList = () => {
    if (draggableTicketsItemsMap.length) {
      const data: IIntegrationHubspotPropertyFormatted[] = []
      draggableTicketsItemsMap.forEach((key) => {
        const result = ticketList().find((item) => item.key === key)
        if (result) {
          data.push(result)
        }
      })

      return data
    }

    return ticketList()
  }

  const ticket = hasShowAllTicketsFields ? ticketFullList().slice(0, 5) : ticketFullList()

  const hasShowMoreTicketsButton =
    ticketFullList().filter((ticket) => ticket.key !== 'subject').length > 5

  const link = (id: number | null | undefined) => {
    const linkProps = {
      key: IntegrationKey.hubspot,
      integration: hubspotIntegration,
      type: IIntegrationLinkTypes.ticket,
      id,
    }

    return integrationLink(linkProps)
  }

  const handleView = () => {
    contactsDetailsStore.openView('tickets', { tickets }, 'hubspot', IntegrationKey.hubspot)
  }

  const { toggleBlockView } = contactsDetailsGlobalStore

  const actions = () => {
    const hasMore = tickets.length > 1
    const hasShowActions = hasShowMoreTicketsButton || hasMore
    const objectId = tickets[0].id.value

    return (
      <div className={styles.actions}>
        {!isUserViewOnly && (
          <div className={styles.action}>
            <NewHubspotPropertiesDropdown
              store={newHubspotPropertiesDropdownStore}
              sourceType={'hubspot-tickets-draggable-items'}
              objectType={'ticket'}
              objectId={objectId}
            />
          </div>
        )}
        {hasShowActions && (
          <div className={styles.actionsRow}>
            {hasShowMoreTicketsButton && (
              <div className={styles.action}>
                <Button
                  size='small'
                  typeBtn='text'
                  disabled={!contact}
                  text={showMoreTicketsLabel}
                  onClick={() => {
                    toggleBlockView('hubspotDetailsTicketsAllFields')
                  }}
                />
              </div>
            )}
            {hasMore && (
              <div className={styles.action}>
                <Button size='small' typeBtn='text' text='See all tickets' onClick={handleView} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={classnames(styles.contentWrapper)}>
      {tickets.slice(0, 1).map((ticket) => (
        <div key={ticket?.id?.value}>
          <div className={styles.headerWrapper}>
            <CopyWrapper
              copyValue={ticket?.subject?.value || null}
              data={{
                title: ticket?.subject?.value || 'View company',
                link: link(ticket?.id?.value),
                label: 'HubSpot',
              }}
            />
          </div>
        </div>
      ))}
      <ItemTicketsRow ticket={ticket} clearAction={handleClear} />
      {actions && actions()}
    </div>
  )
})

export { Tickets }
