// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RZijUJs9YgXQ0MdRzjdE{display:flex;flex-direction:column;justify-content:center;flex:1;width:100%;max-width:760px;margin:0 auto;padding:0 16px}.RZijUJs9YgXQ0MdRzjdE .JgUP4b28DmZVnhc2zDoP{display:flex;flex-direction:row;justify-content:center;gap:8px}.RZijUJs9YgXQ0MdRzjdE .zfaUYqZ6Rem2EKirMefg{display:flex;flex-direction:column}.RZijUJs9YgXQ0MdRzjdE .rTgAgb_NKPxaZ4YUB7WV{display:flex;align-items:flex-end;margin-bottom:32px;border-radius:16px;overflow:hidden}.RZijUJs9YgXQ0MdRzjdE .rTgAgb_NKPxaZ4YUB7WV img{display:block;width:100%;height:auto}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/quickLinks/ui/QuickLinkEmpty/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,MAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CAEA,4CACE,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CAEA,gDACE,aAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".empty {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 1;\n  width: 100%;\n  max-width: 760px;\n  margin: 0 auto;\n  padding: 0 16px;\n\n  .actions {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 8px;\n  }\n\n  .title {\n    display: flex;\n    flex-direction: column;\n\n    &Preview {\n      display: flex;\n      align-items: flex-end;\n      margin-bottom: 32px;\n      border-radius: 16px;\n      overflow: hidden;\n\n      img {\n        display: block;\n        width: 100%;\n        height: auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "RZijUJs9YgXQ0MdRzjdE",
	"actions": "JgUP4b28DmZVnhc2zDoP",
	"title": "zfaUYqZ6Rem2EKirMefg",
	"titlePreview": "rTgAgb_NKPxaZ4YUB7WV"
};
export default ___CSS_LOADER_EXPORT___;
