import { observer } from 'mobx-react-lite'
import React, { useMemo, useRef } from 'react'
import { ActionItem, ContainerHeader, EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { PageLayoutStore } from 'shared/layout'
import { FiltersAndSearch } from 'widgets/FiltersAndSearch'
import { useCampaignsListContext } from 'pages/campaigns/ui/CampaignsList/context/context'
import { EmptyStateCampaigns } from 'pages/campaigns/ui/EmptyStateCampaigns/EmptyStateCampaigns'
import { CampaignsHelpGuideButton, NewCampaign } from 'pages/campaigns/actions'
import { CampaignsTable } from 'pages/campaigns/ui/CampaignsList/ui/CampaignsTable/CampaignsTable'
import styles from './styles.module.scss'

export type ICampaignsListUiProps = {
  pageLayoutStore: PageLayoutStore
}

export const CampaignsListUi = observer(({ pageLayoutStore }: ICampaignsListUiProps) => {
  const { isEmpty, filtersAndSearchStore, firstLoading } = useCampaignsListContext()
  const ref = useRef<HTMLDivElement | null>(null)

  const { titleIconProps, handleToggleCollapse } = pageLayoutStore

  const actions: ActionItem[] = useMemo(() => {
    if (isEmpty) {
      return []
    }
    return [
      {
        iconButtonComponent: <NewCampaign />,
      },
      {
        iconButtonComponent: <CampaignsHelpGuideButton />,
      },
    ]
  }, [isEmpty])

  if (firstLoading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }

  return (
    <>
      <ContainerHeader
        title={'All campaigns'}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        titleIconAction={handleToggleCollapse}
        paddingLeft={20}
        paddingRight={20}
        actionsGap={8}
        actions={actions}
      />
      {isEmpty && <EmptyStateCampaigns />}
      {!isEmpty && (
        <div ref={ref} className={styles.wrap}>
          <FiltersAndSearch store={filtersAndSearchStore} parent={ref.current} />
          <CampaignsTable />
        </div>
      )}
    </>
  )
})
