import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

export const ConversationAlerts = observer(() => {
  const store = useConversationMessagesContext()
  const { isStatusUnverified, isStatusPending, isStatusNumberBlocked, isStatusDeclined } =
    store.conversationMessageFieldAccessStore

  const handleVerifyNumber = () => {
    uiStore.changeRouteViaVue({
      path: '/settings/organization/compliance',
    })
  }

  const handleContactSupport = () => {
    window.open('https://help.salesmessage.com/en/', '_blank')
  }

  return (
    <>
      {isStatusUnverified && (
        <Alert
          item={{
            title: 'Ready to verify your number?',
            desc: (
              <>Verify your number to avoid carrier filtering and ensure maximum deliverability.</>
            ),
            type: 'infoBlue',
          }}
          actions={
            <Button
              text={'Verify now'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              onClick={handleVerifyNumber}
            />
          }
        />
      )}
      {isStatusPending && (
        <Alert
          item={{
            title: 'Verification in progress',
            desc: 'Your Toll-Free number verification is ongoing.',
            type: 'infoBlue',
          }}
        />
      )}
      {(isStatusNumberBlocked || isStatusDeclined) && (
        <Alert
          item={{
            title: 'Verification unsuccessful',
            desc: (
              <>
                Your Toll-Free number verification was not successful. Please contact support for
                further details.
              </>
            ),
            type: 'error',
          }}
          actions={
            <Button
              text={'Contact support'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              onClick={handleContactSupport}
            />
          }
        />
      )}
    </>
  )
})
