// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._RY5syhK3rkTRUsbko7l{padding:33px 0 8px}.XsrwiDrrHi0cwIK3rzAw{color:var(--content-primary-tertiary);padding-top:16px}.EBvr8OLszrFs1qyXul6x{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/entities/Phone/ui/QuestionnaireOrSelectNumberModal/ui/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,kBAAA,CAGF,sBACE,qCAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,OAAA","sourcesContent":[".wrapContent {\n}\n\n.content {\n  padding: 33px 0 8px;\n}\n\n.description {\n  color: var(--content-primary-tertiary);\n  padding-top: 16px;\n}\n\n.actionsWrap {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_RY5syhK3rkTRUsbko7l",
	"description": "XsrwiDrrHi0cwIK3rzAw",
	"actionsWrap": "EBvr8OLszrFs1qyXul6x"
};
export default ___CSS_LOADER_EXPORT___;
