import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import { ContactsApi } from 'entities/Contacts'
import type { IResponseContactDuplicate, IContactDuplicate } from 'entities/Contacts/api/types'

const defaultPagination = {
  page: 1,
  length: 10,
}

export class ContactsDuplicateTableStore {
  private _itemsMap: Map<number, IContactDuplicate> = new Map()
  private _loading = false
  private _total = 0
  private _paginationData = defaultPagination
  private _disposePagination: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)
    this.reactionPagination()
  }

  get paginationData() {
    return this._paginationData
  }

  get loading() {
    return this._loading
  }

  get total() {
    return this._total
  }

  get noResults() {
    return this._total === 0
  }

  get list() {
    return Array.from(this._itemsMap.values())
  }

  init = () => {
    this.load()
  }

  resetPaginationPage = () => {
    this.changePagination(1, this._paginationData.length)
  }

  reset = () => {
    this._paginationData = defaultPagination
    this._loading = false
    this._total = 0
    this._itemsMap.clear()
    this._disposePagination?.()
  }

  changePagination = (page: number, length: number) => {
    this._paginationData = {
      page,
      length,
    }
  }

  changeTotal = (total: number) => {
    this._total = total
  }

  load = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await ContactsApi.getContactsDuplicate(this._paginationData)

      const results = data.data || []
      const total = data.total || 0

      this.addItems(results)

      runInAction(() => {
        this.changeTotal(total)
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  deleteLocallyAfterMerge = (id: number) => {
    this._itemsMap.delete(id)
  }

  decreaseDuplicateCount = (item?: IContactDuplicate) => {
    item && this.addItem({ ...item, count: item?.count - 1 })
  }

  successMergeHandler = (id: number) => {
    const item = this._itemsMap.get(id)

    if (!item) return

    if (item.count - 1 === 1) {
      this.deleteLocallyAfterMerge(id)
      this.changeTotal(this._total - 1)
    } else {
      this.decreaseDuplicateCount(item)
    }
  }

  addItem = (item: IResponseContactDuplicate) => {
    const id = parseInt(item.formatted_number.replace(/\D/g, ''))
    this._itemsMap.set(id, { id, ...item })
  }

  addItems = (contacts: IResponseContactDuplicate[]) => {
    this._itemsMap.clear()

    contacts.forEach((item) => {
      this.addItem(item)
    })
  }

  reactionPagination = () => {
    this._disposePagination?.()
    this._disposePagination = reaction(
      () => this._paginationData,
      () => {
        this.load()
      }
    )
  }
}

export const contactsDuplicateTableStore = new ContactsDuplicateTableStore()
