import { useEffect } from 'react'
import { websocket } from 'entities/WebSocket/store/websocket'
import { WebsocketEvent } from 'entities/WebSocket/store/type'
import { IResponseEventCallerIdVerificationResult } from 'entities/Phone/api/types'
import { numbersStore } from 'entities/Phone/store/numbers'

const NumberCallerIdVerification = (data: IResponseEventCallerIdVerificationResult) => {
  const number = numbersStore.getItem(data.number_id)

  if (number) {
    number.number_caller_id = {
      caller_id_sid: null,
      verified: data.verificationResult,
      failed_reason: null,
    }

    numbersStore.updateItem(number)
  }
}

export const useEventNumberCallerIdVerification = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.NumberCallerIdVerificationResult, NumberCallerIdVerification)
    }
  }, [websocket.isConnected])
}
