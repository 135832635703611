import { observer } from 'mobx-react-lite'
import { Box, Alert, Typography } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { InputNumeric, makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { manageCreditsStore } from 'pages/admin/pages/organizations'

export const ManageCreditsContent = observer(() => {
  const {
    planCredits,
    setPlanCredits,
    purchasedCredits,
    setPurchasedCredits,
    organizationTotalCredits,
    organizationPlanCredits,
    organizationPurchasedCredits,
    rollover,
    setRollover,
    organizationRollover,
  } = manageCreditsStore
  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={'20px'}
        marginTop={'4px'}
      >
        <Box display={'grid'} gap={'8px'}>
          <Typography variant={'body-rg-regular'} color={'tertiary'} ariaLabel={'Total'}>
            Total
          </Typography>
          <Typography variant={'heading-md'} ariaLabel={'organizationTotalCredits'}>
            {organizationTotalCredits}
          </Typography>
        </Box>
        <Box display={'grid'} gap={'8px'}>
          <Typography variant={'body-rg-regular'} color={'tertiary'} ariaLabel={'Plan'}>
            Plan
          </Typography>
          <Typography variant={'heading-md'} ariaLabel={'organizationPlanCredits'}>
            {organizationPlanCredits}
          </Typography>
        </Box>
        <Box display={'grid'} gap={'8px'}>
          <Typography variant={'body-rg-regular'} color={'tertiary'} ariaLabel={'Roll-over'}>
            Roll-over
          </Typography>
          <Typography variant={'heading-md'} ariaLabel={'N/A'}>
            {organizationRollover}
          </Typography>
        </Box>
        <Box display={'grid'} gap={'8px'}>
          <Typography variant={'body-rg-regular'} color={'tertiary'} ariaLabel={'Purchased'}>
            Purchased
          </Typography>
          <Typography variant={'heading-md'} ariaLabel={'organizationPurchasedCredits'}>
            {organizationPurchasedCredits}
          </Typography>
        </Box>
      </Box>
      <Box display={'grid'} gap={'16px'}>
        <Alert
          item={{
            type: 'infoBlue',
            title: 'Removing credits',
            desc: 'To remove credits add a negative value',
          }}
          marginInline={-4}
        />
        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Plan',
            }}
          />
          <TextField
            Input={InputNumeric}
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={planCredits}
            onChange={setPlanCredits}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>
        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Purchased',
            }}
          />
          <TextField
            Input={InputNumeric}
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={purchasedCredits}
            onChange={setPurchasedCredits}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>
        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Rollover',
            }}
          />
          <TextField
            Input={InputNumeric}
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={rollover}
            onChange={setRollover}
            rightActions={[makeTextFieldClear()]}
          />
          <Typography
            color={'var(--content-primary-secondary)'}
            variant={'text-input-helper'}
            ariaLabel={'N/A'}
          >
            Any added rollover credits will be cleared in 30 days
          </Typography>
        </Box>
      </Box>
    </>
  )
})
