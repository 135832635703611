import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu } from 'shared/ui'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import { MenuOutboundStore } from 'widgets/Menu/ui/MenuOutbound/store/menuOutboundStore'
import { OutboundKey } from 'widgets/Menu/ui/MenuOutbound/constants/menuItems'

export const MenuOutbound = observer(() => {
  const key = OutboundKey
  const [store] = useState(() => new MenuOutboundStore(key))

  useEffect(() => {
    store.menuStore.init()
  }, [])

  if (store.menuStore.loading) return null

  const menuStore = store.menuStore

  return (
    <Menu
      title={'Outbound'}
      menuItems={store.menuItems}
      bottomNode={<TrialProgress />}
      onCheckRoute={menuStore.setCachePath}
    />
  )
})
