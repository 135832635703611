// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KrxTEPAeMXGwOZtbGdvP{display:flex;padding:16px 20px;flex-direction:column;gap:4px;border-radius:8px;background:var(--background-neutral-subtle);margin-top:24px}.TAOpL1bNhfgEx9PwBPxA{margin-bottom:12px}.VKX26Id4pYBFXmoYKvdS{display:flex;gap:8px}.RbdaxO7BOLxbn7xCEJUY{margin-left:26px;margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/SampleMessageModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,qBAAA,CACA,OAAA,CACA,iBAAA,CACA,2CAAA,CACA,eAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,gBAAA,CACA,kBAAA","sourcesContent":[".sampleMessageModalMustIncludeWrap {\n  display: flex;\n  padding: 16px 20px;\n  flex-direction: column;\n  gap: 4px;\n  border-radius: 8px;\n  background: var(--background-neutral-subtle);\n  margin-top: 24px;\n}\n\n.mustIncludeTitle {\n  margin-bottom: 12px;\n}\n\n.mustIncludeLine {\n  display: flex;\n  gap: 8px;\n}\n\n.mustIncludeExampleLine {\n  margin-left: 26px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sampleMessageModalMustIncludeWrap": "KrxTEPAeMXGwOZtbGdvP",
	"mustIncludeTitle": "TAOpL1bNhfgEx9PwBPxA",
	"mustIncludeLine": "VKX26Id4pYBFXmoYKvdS",
	"mustIncludeExampleLine": "RbdaxO7BOLxbn7xCEJUY"
};
export default ___CSS_LOADER_EXPORT___;
