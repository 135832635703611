import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SuccessModalContent } from 'entities/Phone/ui/SuccessModalContent/SuccessModalContent'
import { type GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'

type INewNumberSummaryModalContentProps = {
  store: GetNumberModalStore
  number: string
}

export const NewNumberSummaryModalContent: FC<INewNumberSummaryModalContentProps> = observer(
  ({ number }) => {
    return (
      <SuccessModalContent
        title='Your new number is ready!'
        alertItem={{
          type: 'infoBlue',
          title: 'This is your own texting number for Salesmsg',
          desc: 'Move on to the next step, to start texting with this number',
        }}
        formattedNumber={number}
      />
    )
  }
)
