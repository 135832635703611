import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button } from 'shared/ui'
import { Card } from 'pages/campaigns/ui/CampaignSettings/ui/Card/Card'
import { type CampaignSettingsStore } from '../store/CampaignSettingsStore'
import styles from './styles.module.scss'

type ICampaignSettingsUiProps = {
  store: CampaignSettingsStore
}

export const CampaignSettingsUi = observer(({ store }: ICampaignSettingsUiProps) => {
  const { onSave, onCancel, isUnEnroll, isReEnter } = store

  const [isUnEnrollLocal, setUnEnrollLocal] = useState(isUnEnroll)
  const [isReEnterLocal, setReEnterLocal] = useState(isReEnter)

  const handleSave = () => {
    onSave({
      isUnEnroll: isUnEnrollLocal,
      isReEnter: isReEnterLocal,
    })
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Card
          title={'Unenroll on SMS response'}
          desc={
            <>
              If contact replies to any message from this campaign <br />-{'>'} unenroll from
              campaign
            </>
          }
          value={isUnEnrollLocal}
          setValue={setUnEnrollLocal}
        />
        <Card
          title={'Re-enter Campaign'}
          desc={
            <>
              If a contact completed this campaign, they can be allowed to re-
              <br /> enter it again
            </>
          }
          value={isReEnterLocal}
          setValue={setReEnterLocal}
        />
      </div>
      <div className={styles.actions}>
        <Button text={'Save'} size={'medium'} contained={'primary'} onClick={handleSave} />
        <Button text={'Cancel'} size={'medium'} contained={'secondary'} onClick={onCancel} />
      </div>
    </div>
  )
})
