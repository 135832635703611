import { observer } from 'mobx-react-lite'
import { Icon, Tooltip, Typography } from 'shared/ui'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

import styles from './styles.module.scss'

export const MessageCounter = observer(() => {
  const messageFieldStore = useMessageFieldContext()

  return (
    <div className={styles.messageCounterContent}>
      <Typography variant='text-input-helper' tag='div' ariaLabel='MessageCount'>
        {messageFieldStore.messageRemainsInfo.messageCount}/
        {messageFieldStore.messageRemainsInfo.messageRemains}
      </Typography>

      {messageFieldStore.mergeFieldsCount > 0 && (
        <Tooltip
          label={
            <>
              The length of your merge field <br />
              values may affect credit usage
            </>
          }
          placement={'top'}
        >
          <Icon className={styles.warningIcon} icon='infoCircle' fontSize={16} />
        </Tooltip>
      )}
    </div>
  )
})
