// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZWaByJhNv4vqQsDZt4zQ{padding:32px 0 24px 0;width:100%}._SBhwfwzMSKsbX6pGoB1{height:40px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/NextButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,UAAA,CAGF,sBACE,WAAA","sourcesContent":[".wrap {\n  padding: 32px 0 24px 0;\n  width: 100%;\n}\n\n.btn {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ZWaByJhNv4vqQsDZt4zQ",
	"btn": "_SBhwfwzMSKsbX6pGoB1"
};
export default ___CSS_LOADER_EXPORT___;
