import { observer } from 'mobx-react-lite'
import { layoutStore } from 'shared/layout'
import { Alert, Typography } from 'shared/ui'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import styles from './styles.module.scss'

export const ConversationInboxAlert = observer(() => {
  const { conversationMessageFieldAccessStore } = useConversationMessagesContext()
  const { isHideInboxAlert, conversationInbox, setHideInboxAlert } =
    conversationMessageFieldAccessStore

  if (!layoutStore.isMobileView || isHideInboxAlert) return null

  return (
    <Alert
      item={{
        type: 'infoBlue',
        desc: (
          <div className={styles.wrap}>
            <Typography
              variant={'body-md-regular'}
              color={'var(--light-blue-100'}
              ariaLabel={'Inbox'}
            >
              Inbox:
            </Typography>
            <Typography
              variant={'body-md-semibold'}
              color={'var(--light-blue-100'}
              ariaLabel={'Inbox'}
              ellipsis
              tooltipProps={{
                label: conversationInbox?.name,
              }}
            >
              {conversationInbox?.name}
            </Typography>
          </div>
        ),
      }}
      onClose={setHideInboxAlert}
    />
  )
})
