// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NqNs4Krt8TIhVeDy_nLm{padding:0 44px;overflow-y:auto;overflow-x:hidden}.ZTaRUy18j4Vpr1n1kDhQ{display:flex;flex-direction:column;justify-content:center;flex:1;width:100%;max-width:760px;margin:0 auto;padding:0 16px}.ZTaRUy18j4Vpr1n1kDhQ .pedhWNQTu7bXWatN9AUQ{display:flex;flex-direction:column}.ZTaRUy18j4Vpr1n1kDhQ .l3yDusNOyqRwX1bK0o5V{display:flex;align-items:flex-end;margin-bottom:32px;border-radius:16px;overflow:hidden;min-height:276px}.ZTaRUy18j4Vpr1n1kDhQ .l3yDusNOyqRwX1bK0o5V img{display:block;max-width:492px;width:100%;height:auto}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/quickLinks/ui/QuickLinksPage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,MAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CAEA,4CACE,YAAA,CACA,qBAAA,CAEA,4CACE,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEA,gDACE,aAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".page {\n  padding: 0 44px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.empty {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 1;\n  width: 100%;\n  max-width: 760px;\n  margin: 0 auto;\n  padding: 0 16px;\n\n  .title {\n    display: flex;\n    flex-direction: column;\n\n    &Preview {\n      display: flex;\n      align-items: flex-end;\n      margin-bottom: 32px;\n      border-radius: 16px;\n      overflow: hidden;\n      min-height: 276px;\n\n      img {\n        display: block;\n        max-width: 492px;\n        width: 100%;\n        height: auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "NqNs4Krt8TIhVeDy_nLm",
	"empty": "ZTaRUy18j4Vpr1n1kDhQ",
	"title": "pedhWNQTu7bXWatN9AUQ",
	"titlePreview": "l3yDusNOyqRwX1bK0o5V"
};
export default ___CSS_LOADER_EXPORT___;
