// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WXU87cd468TSbH1maKFo{cursor:pointer;padding:8px;min-height:32px;display:flex;gap:8px;align-items:center}.WXU87cd468TSbH1maKFo:hover{border-radius:6px;background:var(--background-neutral-subtle)}.WXU87cd468TSbH1maKFo .uTMkFZ5l4CSPVwJDmnxQ{margin-left:auto}", "",{"version":3,"sources":["webpack://./src/shared/ui/InputCheckbox/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,4BACE,iBAAA,CACA,2CAAA,CAEF,4CACE,gBAAA","sourcesContent":[".wrap {\n  cursor: pointer;\n  padding: 8px;\n  min-height: 32px;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n\n  &:hover {\n    border-radius: 6px;\n    background: var(--background-neutral-subtle);\n  }\n  .tooltip {\n    margin-left: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "WXU87cd468TSbH1maKFo",
	"tooltip": "uTMkFZ5l4CSPVwJDmnxQ"
};
export default ___CSS_LOADER_EXPORT___;
