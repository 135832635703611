import { makeAutoObservable } from 'mobx'
import type { IActionsProps, IIconsVector } from 'shared/ui'
import { TEAM_NUMERABLE_TYPE } from 'shared/constants/strange'
import type { IResponseTrigger, ITriggerStatus, ITriggerType } from 'entities/Trigger/api/type'
import type { IResponseMediaShort } from 'entities/Attachment'
import type { ISendFromNumbersProps } from 'entities/Inbox/api/types'
import { TriggerStatistic } from 'entities/Trigger/model/TriggerStatistic'
import { Integration } from 'entities/Integrations/model/Integration'
import { IWebhook } from 'entities/Webhook'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { Tag } from 'entities/Tags/model/Tag'
import { User } from 'entities/Users/model/User'
import { inboxesStore } from 'entities/Inbox'

export type ITrigger = Trigger

export class Trigger {
  id: number
  type: ITriggerType
  name: string
  ownerId: number | null
  owner: User | null
  status: ITriggerStatus
  statistics: TriggerStatistic | null = null
  createdAt: string
  integration: Integration | null = null
  webhook: IWebhook | null = null
  message = ''
  media_url: IResponseMediaShort[] = []
  is_track_conversion?: boolean
  conversion_id?: number | null = null
  sendFromNumbersProps: ISendFromNumbersProps[] | null = null
  attachments: Attachment[] = []
  short_urls: IResponseTrigger['short_urls'] | null = []
  tags: Tag[]
  sendFrom: IResponseTrigger['send_from'] | null = null
  number_id: IResponseTrigger['number_id'] | null = null
  actionsProps: IActionsProps | null = null

  constructor(item: IResponseTrigger) {
    this.id = item.id
    this.type = item.is_rvm ? 'ringless' : 'sms'
    this.name = item.name
    this.ownerId = item.owner_id
    this.owner = item.owner ? new User(item.owner) : null
    this.status = item.is_active ? 'active' : 'disabled'
    this.statistics = new TriggerStatistic(item)
    this.createdAt = item.created_at
    this.message = item.message || ''
    this.media_url = item.media_url || []
    this.sendFromNumbersProps = this.getSendFromNumbersProps(item)
    this.integration = item.integration ? new Integration(item.integration) : null
    this.attachments = Array.isArray(item.media_url)
      ? item.media_url.map((media) => new Attachment({ responseMediaShort: media }))
      : []
    this.short_urls = item.short_urls
    this.is_track_conversion = item.is_track_conversion
    this.conversion_id = item.conversion_id
    this.tags = Array.isArray(item.tags) ? item.tags.map((tagResponse) => new Tag(tagResponse)) : []
    this.sendFrom = item.send_from
    this.number_id = item.number_id

    if (item.webhook) {
      this.webhook = new IWebhook(item.webhook)
    }

    makeAutoObservable(this)
  }

  setActionsProps = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }

  syncOrigin(updatedTrigger: IResponseTrigger) {
    this.status = updatedTrigger.is_active ? 'active' : 'disabled'
  }

  getSendIcon = (trigger: IResponseTrigger): IIconsVector | undefined => {
    const number = trigger.number
    if (number?.is_aircall) {
      return 'aircall'
    }
    if (number?.numberable?.numberable_type === TEAM_NUMERABLE_TYPE || !number?.numberable) {
      return 'folder'
    }
    return 'inboxOpen'
  }

  getSendFromNumbersProps = (trigger: IResponseTrigger): ISendFromNumbersProps[] => {
    if (trigger.send_from?.type === 'from_integration') {
      if (trigger.integration.key === 'hubspot') {
        return [
          {
            id: trigger.integration_id as number,
            icon: trigger.integration?.key,
            name: 'User property',
            value: '',
          },
        ]
      }
      if (trigger.integration.key === 'infusionsoft') {
        return [
          {
            id: trigger.integration_id as number,
            icon: trigger.integration?.key,
            name: 'Lead owner',
            value: '',
          },
        ]
      }
    }
    if (trigger.team) {
      return trigger.team.numbers?.map((number) => ({
        id: number?.id,
        icon: this.getSendIcon(trigger),
        name: trigger.team?.name,
        value: number.formatted_number,
      }))
    }
    return [
      {
        id: trigger.number?.id,
        icon: this.getSendIcon(trigger),
        name: trigger.number?.numberable?.name,
        value: trigger.number?.formatted_number,
      },
    ]
  }

  get inboxId() {
    if (this.sendFrom?.options.team_id) {
      const inbox = inboxesStore.getItem(this.sendFrom?.options.team_id)

      return inbox?.id
    }

    if (this.sendFrom?.options.number_id) {
      const inbox = inboxesStore.getInboxByNumber(this.sendFrom?.options.number_id)

      return inbox?.id
    }

    if (this.number_id) {
      const inbox = inboxesStore.getInboxByNumber(this.number_id)

      return inbox?.id
    }
  }
}
