import classNames from 'classnames'
import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { IIntegrationHubspotPropertyFormatted, IDeal } from 'entities/Integrations'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { usersStore } from 'entities/Users'
import { type IDealsRowType } from './DealsTab'

import styles from '../styles.module.scss'
type IDealDataType = {
  key: string
  label: string
  value: string | number | null
}
type IDealsRowProps = {
  deal?: IDeal
  rows?: IDealsRowType[]
  dealFormatted?: IIntegrationHubspotPropertyFormatted[]
  clearAction?: (key: string, source_type: IUiSettingsSourceType) => void
}

const DealsRow = ({ deal, dealFormatted, rows, clearAction }: IDealsRowProps) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole
  const rowsData = rows?.reduce((data: IDealDataType[], type) => {
    if (type === 'amount') {
      data.push({
        key: type,
        label: 'Amount',
        value: deal?.amount?.value || null,
      })
    }

    if (type === 'value') {
      data.push({
        key: type,
        label: 'Value',
        value: deal?.amount?.value || null,
      })
    }

    if (type === 'closeDate') {
      data.push({
        key: type,
        label: 'Close date',
        value: deal?.closedate?.value || null,
      })
    }

    if (type === 'stage') {
      data.push({
        key: type,
        label: 'Stage',
        value: deal?.dealstage?.value || null,
      })
    }

    if (type === 'owner') {
      const name =
        deal?.owner?.value ||
        (deal?.owner?.first_name || deal?.owner?.last_name
          ? `${deal?.owner?.first_name} ${deal?.owner?.last_name}`
          : null)

      data.push({
        key: type,
        label: 'Owner',
        value: name,
      })
    }

    if (type === 'pipeline') {
      data.push({
        key: type,
        label: 'Pipeline',
        value: deal?.pipeline?.value || null,
      })
    }

    return data
  }, [])

  const data = dealFormatted
    ? dealFormatted.filter((deal) => deal.key !== 'dealname')
    : rowsData || []

  return (
    <div className={styles.dealWrapper}>
      {data.map((item) => (
        <div
          className={classNames(styles.itemRow, {
            [styles.emptyPadding]: true,
          })}
          key={item.key}
        >
          {!dealFormatted && <div className={styles.label}>{item.label}</div>}
          {dealFormatted && (
            <TextField
              className={styles.label}
              size='small'
              variant='outline'
              readOnly
              value={item.label || '-'}
              rightActions={[
                makeTextFieldInteractions({
                  type: 'clear',
                  active: !isUserViewOnly,
                  action: () => clearAction?.(item.key, 'hubspot-deals-draggable-items'),
                }),
              ]}
            />
          )}
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value ? String(item.value) : '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { DealsRow }
