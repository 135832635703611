import { observer } from 'mobx-react-lite'
import { AuthLayout } from 'shared/layout'
import { ForgotPasswordStore } from 'widgets/ForgotPassword/store/forgotPasswordStore'
import { ForgotPasswordForm } from 'widgets/ForgotPassword/ui/ForgotPasswordForm/ForgotPasswordForm'
import { SuccessForm } from 'widgets/ForgotPassword/ui/SuccessFrom/SuccessForm'

import styles from './styles.module.scss'

export const ForgotPasswordContent = observer(({ store }: { store: ForgotPasswordStore }) => {
  return (
    <div className={styles.wrap}>
      <AuthLayout
        title={store.success ? undefined : 'Forgot your password?'}
        subtitle={
          store.success ? undefined : (
            <div className={styles.subtitle}>
              {
                "Enter your e-mail address and we'll send you instructions on how to reset your password."
              }
            </div>
          )
        }
        form={store.success ? <SuccessForm store={store} /> : <ForgotPasswordForm store={store} />}
        formClassnames={store.success ? styles.successWrap : undefined}
      />
    </div>
  )
})

export const ForgotPassword = () => {
  const store = new ForgotPasswordStore()
  return <ForgotPasswordContent store={store} />
}

export default ForgotPassword
