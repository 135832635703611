import { FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Avatar, Icon, IDropdownItemProps, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export type IDropdownCardCheckedProps = IDropdownItemProps

export const DropdownCardCheckedRight: FC<IDropdownCardCheckedProps> = observer(
  ({ onChange, item, parent, isHover }) => {
    const renderActive = () => {
      const isActiveValueBoolean = typeof item.activeValue === 'boolean'

      if (isActiveValueBoolean && item.activeValue) {
        return (
          <div className={classnames(styles.icon, styles.active, styles.activeRight)}>
            <Icon icon={'check1'} />
          </div>
        )
      }

      if (item.noRenderEmptyActiveValue) {
        return null
      }

      return <div className={classnames(styles.icon__right)} />
    }

    return (
      <div
        className={classnames(styles.item, {
          [styles.item__disabled]: item.disabled,
          [styles['item--hover']]: isHover,
          ['hasDropdownCardChild']: parent,
        })}
        style={{
          height: item.height,
        }}
        onClick={(e) => {
          e.stopPropagation()

          if (item.disabled) return
          if (item.children?.length) return

          item.onClick?.()
          onChange?.(item, parent)
        }}
      >
        <div className={styles.itemWrap}>
          {item.iconL && (
            <div className={styles.icon}>
              <Icon icon={item.iconL} />
            </div>
          )}
          {item.avatarProps && (
            <div className={styles.icon}>
              <Avatar {...item.avatarProps} />
            </div>
          )}
          {(item.labelContent || item.label) && (
            <div className={classnames(styles.title)}>
              {item.labelContent || item.label}
              {item.labelBrackets && (
                <Typography
                  variant={'body-md-regular'}
                  color={'var(--content-primary-disabled)'}
                  ariaLabel={'labelBrackets'}
                >
                  {' '}
                  ({item.labelBrackets})
                </Typography>
              )}
              {item.description && (
                <Typography
                  ariaLabel={'description'}
                  variant={'body-xs-regular'}
                  color={'tertiary'}
                  tag={'div'}
                  className={styles.desc}
                  ellipsis
                >
                  {item.description}
                </Typography>
              )}
            </div>
          )}
        </div>
        <div className={classnames(styles.itemWrap, styles.rightContent)}>
          {item.labelRight && (
            <Typography
              variant={'body-md-regular'}
              color={'var(--content-primary-primary)'}
              ariaLabel={'labelRight'}
            >
              {item.labelRight}
            </Typography>
          )}
          {renderActive()}
          {item.iconR && (
            <div className={styles.icon__right}>
              <Icon icon={item.iconR} />
            </div>
          )}

          {item.tooltipQuestionProps && (
            <div className={styles.tooltip}>
              <Tooltip {...item.tooltipQuestionProps}>
                <Icon icon={'question'} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    )
  }
)
