import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Tabs, type ITabItem } from 'shared/ui'
import { type ContactsTagsModalStore, type ITagsModalMode } from 'widgets/ContactsTagsModal'
import { ContactsTagsDropdown } from './ContactsTagsDropdown'

export const contactsTagsModalContentTabs: ITabItem<ITagsModalMode>[] = [
  {
    key: 'add',
    name: 'Add tags',
  },
  {
    key: 'remove',
    name: 'Remove tags',
  },
]

type IContactsTagsModalContentProps = {
  contactsTagsModalStore: ContactsTagsModalStore
}

export const ContactsTagsModalContent: FC<IContactsTagsModalContentProps> = observer(
  ({ contactsTagsModalStore }) => {
    const { mode, setMode } = contactsTagsModalStore

    return (
      <div>
        <Tabs<ITagsModalMode>
          tabs={contactsTagsModalContentTabs}
          activeTabKey={mode}
          handleSelectTab={(tab) => setMode(tab.key)}
        />
        <ContactsTagsDropdown contactsTagsModalStore={contactsTagsModalStore} />
      </div>
    )
  }
)
