import { observer } from 'mobx-react-lite'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers/route/type'
import { CommonOptInOnlineFormContent } from 'pages/settings/pages/compliance/ui/CommonOptInContent/ui/CommonOptInOnlineFormContent'
import { RegistrationErrorAlert } from 'pages/settings/pages/compliance/pages/localNumbers/ui/RegistrationErrorAlert/RegistrationErrorAlert'
import { usePathSegment } from 'pages/settings/pages/compliance/hooks/usePathSegment'

export const OptInOnlineFormContent = observer(() => {
  const { optInConsentStore, stepLoading } = useLocalNumbersStepsContext()
  const {
    getOptInImagesAsAttach,
    deleteAttachment,
    clearAttachmentError,
    getOptInAttachmentError,
    campaignOptInContentNextClick,
    uploadOptInImageAttachment,
    isOnlineFormNextDisabled,
    commonOptInConsentStore,
  } = optInConsentStore

  const lastPathSegment = usePathSegment<LocalNumbersRoutes>()

  return (
    <CommonOptInOnlineFormContent
      stepLoading={stepLoading}
      getOptInImagesAsAttach={() =>
        getOptInImagesAsAttach(LocalNumbersRoutes.optInOnlineFormContent)
      }
      deleteAttachment={(url: string) =>
        deleteAttachment(url, LocalNumbersRoutes.optInOnlineFormContent)
      }
      uploadAttachment={(attachments: File[]) =>
        uploadOptInImageAttachment(attachments, lastPathSegment)
      }
      increaseStep={() => campaignOptInContentNextClick(lastPathSegment)}
      clearAttachmentError={() => clearAttachmentError(lastPathSegment)}
      isOnlineFormNextDisabled={isOnlineFormNextDisabled}
      getAttachmentError={() => getOptInAttachmentError(lastPathSegment)}
      optInConsentStore={commonOptInConsentStore}
      errorBanner={<RegistrationErrorAlert />}
    />
  )
})
