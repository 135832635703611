import { Checkbox } from 'shared/ui/Checkbox/Checkbox'
import { TableCell } from 'shared/ui/Table'
import { getAriaLabel } from 'shared/lib'

import styles from './styles.module.scss'

interface ICheckboxCellProps {
  isHeader?: boolean
  selected: boolean
  isIndeterminate?: boolean
  disabled?: boolean
  onChangeValue: (isSelected: boolean) => void
  ariaLabel?: string
}

export const CheckboxCell = ({
  isHeader = false,
  selected,
  isIndeterminate,
  disabled,
  onChangeValue,
  ariaLabel,
}: ICheckboxCellProps) => {
  return (
    <TableCell width='40px' minWidth='40px' isHeader={isHeader} className={styles.checkboxCell}>
      <div className={styles.wrapCheckbox} aria-label={getAriaLabel('Checkbox', ariaLabel)}>
        <Checkbox
          disabled={disabled}
          checked={selected}
          isIndeterminate={isIndeterminate}
          onChange={onChangeValue}
          ariaLabel='TableViewRow'
        />
      </div>
    </TableCell>
  )
}
