import { Navigate, Route, Routes } from 'react-router-dom'
import { TriggersLayout } from 'pages/triggers/layout/TriggersLayout'
import { TriggersTableLayout } from 'pages/triggers/layout/TriggersTableLayout'
import { AllTriggers, SmsTriggers, RinglessTriggers } from 'pages/triggers/pages/triggers'
import { CreateTrigger } from 'pages/triggers/pages/createTrigger'
import { EditTrigger } from 'pages/triggers/pages/editTrigger'
import { DetailTrigger } from 'pages/triggers/pages/detailTrigger'
import { TriggersRoutes } from 'pages/triggers/route/type'
import { TriggersAccessLayout } from 'pages/triggers/layout/TriggersAccessLayout'

export const TriggersRouter = () => {
  return (
    <Routes>
      <Route element={<TriggersAccessLayout />}>
        <Route element={<TriggersLayout />}>
          <Route element={<TriggersTableLayout />}>
            <Route path={TriggersRoutes.all} element={<AllTriggers />} />
            <Route path={TriggersRoutes.sms} element={<SmsTriggers />} />
            <Route path={TriggersRoutes.ringless} element={<RinglessTriggers />} />
          </Route>
          <Route path={`${TriggersRoutes.view}/:id`} element={<DetailTrigger />} />
          <Route path={TriggersRoutes.create} element={<CreateTrigger />} />
          <Route path={`${TriggersRoutes.edit}/:id`} element={<EditTrigger />} />
          <Route
            path={`${TriggersRoutes.clone}/:id`}
            element={<EditTrigger isDuplicate={true} />}
          />
          <Route path={'*'} element={<Navigate to={TriggersRoutes.all} />} />
        </Route>
      </Route>
    </Routes>
  )
}
