import { Alert, Button } from 'shared/ui'
import { IAlert } from 'shared/ui/Alert/types'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { openIntercomNewMessage } from 'entities/Intercom'
import { ITrigger } from 'entities/Trigger/model/Trigger'
import styles from './styles.module.scss'

type ITriggerInvalidAlertProps = {
  className?: string
  trigger: ITrigger
  isBottomActions?: boolean
}

export const TriggerInvalidAlert = ({
  className,
  trigger,
  isBottomActions,
}: ITriggerInvalidAlertProps) => {
  if (!trigger.isInvalid) return null

  const info: Partial<IAlert> = {
    title: 'Suspicious content detected',
    desc: 'Your link has been flagged as spam. If you believe this is an error, contact support for assistance.',
  }

  const handleOpenIntercomWidget = () => {
    openIntercomNewMessage()

    modalStore.closeAllModals()
  }

  const action = () => {
    return (
      <Button
        text='Support'
        typeBtn='outlined'
        size='small'
        contained='secondary'
        className={styles.link}
        onClick={() => handleOpenIntercomWidget()}
      />
    )
  }

  return (
    <div className={className}>
      <Alert
        item={{
          type: 'alert',
          ...info,
        }}
        rightAction={!isBottomActions && action()}
        actions={isBottomActions && action()}
      />
    </div>
  )
}
