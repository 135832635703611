import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button, IColumn, IRow, Table, TextInput } from 'shared/ui'
import { links } from 'shared/constants/links'
import { getCellForNameDefault } from 'shared/helpers/getCellForNameDefault'
import { POWER_DIALER_NAME_LIMIT } from 'shared/constants/limits'
import { callStore } from 'entities/Call'
import { DeviceSettings } from 'entities/DeviceSettings'
import { Contact } from 'entities/Contacts/model/Contact'
import { getCellNumber } from 'widgets/ContactsTable/helpers/helpers'
import { type PowerDialerEditorStore } from 'widgets/PowerDialerEditor/store/powerDialerEditorStore'
import { NumbersInboxesDropDown } from 'widgets/NumbersInboxesList/ui/NumbersInboxesDropDown'
import styles from './styles.module.scss'

type IPowerDialerEditorModalProps = {
  powerDialerStore: PowerDialerEditorStore
}

export const PowerDialerEditorModal = observer(
  ({ powerDialerStore }: IPowerDialerEditorModalProps) => {
    const { deviceSettingsStore, loading, testOutputDevice } = callStore
    const {
      editorActionTooltip,
      editorActionButtonDisabled,
      handleCall,
      handleSaveDraft,
      contacts,
      contactsCount,
      deleteSelectedId,
      showAlert,
      onChangeShowAlert,
      draftLoading,
      callLoading,
      setDefaultInbox,
      numbersInboxesListStore,
      name,
      setName,
      setNameError,
      nameError,
    } = powerDialerStore

    useEffect(() => {
      setDefaultInbox()
    }, [])

    const rows = (): IRow<Contact>[] =>
      contacts.map((item) => {
        item.ariaLabel = 'ContactItemRow'
        item.actionsProps = {
          noVisibleActions: false,
          actions: [
            {
              iconButtonProps: {
                ariaLabel: 'PowerDialerClose',
                icon: 'close',
                variant: 'icon',
                size: 'small',
                color: 'tertiary',
                onClick: () => {
                  deleteSelectedId(item.id)
                },
              },
            },
          ],
        }

        return item
      })
    const columns: IColumn<Contact>[] = [
      {
        field: 'name',
        name: 'Name',
        width: '43%',
        renderRowCell: getCellForNameDefault,
      },
      {
        field: 'number',
        name: 'Number',
        renderRowCell: getCellNumber,
      },
    ]

    return (
      <div>
        <TextInput
          size={'large'}
          placeholder={'Power Dialer campaign name'}
          value={name}
          setValue={setName}
          error={nameError}
          limit={POWER_DIALER_NAME_LIMIT}
          onFocus={() => setNameError('')}
          ariaLabel='PowerDialerName'
          className={styles.name}
        />
        {showAlert && (
          <div className={styles.alert}>
            <Alert
              item={{
                type: 'infoBlue',
                desc: 'Have questions about this feature?',
              }}
              action={
                <Button
                  tag='a'
                  typeBtn={'action'}
                  size={'small'}
                  contained={'primary'}
                  text={'Learn more'}
                  href={links.powerDialer}
                  target='_blank'
                  rel='noreferrer'
                  style={{ textDecoration: 'none' }}
                />
              }
              onClose={() => {
                onChangeShowAlert(false)
              }}
            />
          </div>
        )}

        <div className={styles.section}>
          <NumbersInboxesDropDown store={numbersInboxesListStore} />
          <DeviceSettings
            deviceSettingsStore={deviceSettingsStore}
            dropdownProps={{
              placement: 'bottom-end',
              width: 246,
              margin: 4,
              disabled: loading,
              ariaLabel: 'deviceSettings',
            }}
            variantDropdownBtn={'icon'}
            testOutputDevice={testOutputDevice}
          />
        </div>

        <div className={styles.table}>
          <Table
            columns={columns}
            rows={rows()}
            noPagination
            isHoveredRowActions
            isCursor
            classNameScroll={styles.table__scroll}
          />
        </div>

        <div className={styles.wrapBtn}>
          <Button
            contained='secondary'
            text={'Save as draft'}
            tooltipProps={{
              placement: 'top',
              label: editorActionTooltip,
            }}
            loading={draftLoading}
            disabled={editorActionButtonDisabled || callLoading}
            onClick={handleSaveDraft}
          />
          <Button
            text={`Call ${contactsCount} contacts`}
            tooltipProps={{
              placement: 'top',
              label: editorActionTooltip,
            }}
            loading={callLoading}
            disabled={editorActionButtonDisabled || draftLoading}
            onClick={handleCall}
          />
        </div>
      </div>
    )
  }
)
