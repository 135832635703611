import type { IIconsVector, ITooltipProps } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { usersStore } from 'entities/Users'
import {
  AnalyticsRoutes,
  BroadcastsRoutes,
  ChatbotRoutes,
  ContactsRoutes,
  ConversationRoutes,
} from 'entities/ExportRouters'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { subItemsOutboundActive, subItemsMenuAIAutomationActive } from 'widgets/Menu'

export const commonTooltipProps: ITooltipProps = {
  placement: 'right',
  arrow: false,
  disableInteractive: true,
  slotProps: {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -2],
          },
        },
      ],
    },
  },
}

export type IMenuItemKeys =
  | 'conversation'
  | 'contacts'
  | 'broadcasts'
  | 'chatbot'
  | 'triggers'
  | 'analytics'
  | 'keywords'
  | 'clients'
  | 'workflows'
  | 'campaign'
  | 'powerDialer'
  | 'admin'
  | 'outbound'
  | 'AI_automation'

export type IMenuItem = {
  itemKey: IMenuItemKeys
  icon: IIconsVector
  route: string
  label: string
  isShow?: () => void
}

export const menuItemsV1: IMenuItem[] = [
  {
    itemKey: 'conversation',
    icon: 'conversation',
    route: 'conversations',
    label: 'Conversations',
  },
  {
    itemKey: 'contacts',
    icon: 'contacts',
    route: 'contacts',
    label: 'Contacts',
  },
  {
    itemKey: 'broadcasts',
    icon: 'broadcasts',
    route: 'broadcasts',
    label: 'Broadcasts',
  },
  {
    itemKey: 'chatbot',
    icon: 'chatBot',
    route: 'chatbot',
    label: 'AI Textbot',
  },
  {
    itemKey: 'triggers',
    icon: 'lightning2',
    route: 'triggers',
    label: 'Triggers',
  },
  {
    itemKey: 'analytics',
    icon: 'analytics',
    route: 'analytics',
    label: 'Analytics',
  },
  {
    itemKey: 'keywords',
    icon: 'keywords',
    route: 'keywords',
    label: 'Keywords',
  },
  {
    itemKey: 'clients',
    icon: 'clients',
    route: 'clients',
    label: 'Clients',
  },
  {
    itemKey: 'workflows',
    icon: 'workflows',
    route: 'workflows',
    label: 'Workflows',
  },
  {
    itemKey: 'campaign',
    icon: 'campaigns',
    route: 'campaigns',
    label: 'Campaigns',
    isShow: () =>
      featureFlagsStore.campaigns &&
      !usersStore.user?.isViewOnlyRole &&
      userPermissionsStore.getItem('can_manage_campaigns'),
  },
  {
    itemKey: 'powerDialer',
    icon: 'phoneRightArrows',
    route: 'power-dialer',
    label: 'Power Dialer',
    isShow: () => featureFlagsStore.salesmsg_power_dialer_v2 && !usersStore.user?.isViewOnlyRole,
  },
]

export type IMenuItemSidebar = IMenuItem & {
  activePaths: string[]
}

export const menuItemsV2: IMenuItemSidebar[] = [
  {
    itemKey: 'conversation',
    icon: 'conversation',
    route: ConversationRoutes.root,
    label: 'Inboxes',
    activePaths: [ConversationRoutes.root],
  },
  {
    itemKey: 'AI_automation',
    icon: 'stars',
    route: ChatbotRoutes.root,
    label: 'AI & Automation',
    activePaths: subItemsMenuAIAutomationActive,
  },
  {
    itemKey: 'outbound',
    icon: 'outbound',
    route: BroadcastsRoutes.root,
    label: 'Outbound',
    activePaths: subItemsOutboundActive,
  },
  {
    itemKey: 'contacts',
    icon: 'contacts',
    route: ContactsRoutes.CONTACTS,
    label: 'Contacts',
    activePaths: [ContactsRoutes.CONTACTS],
  },
  {
    itemKey: 'analytics',
    icon: 'analytics',
    route: AnalyticsRoutes.root,
    label: 'Analytics',
    activePaths: [AnalyticsRoutes.root],
  },
]
