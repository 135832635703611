import { makeAutoObservable } from 'mobx'
import { SchedulePeriodStore } from 'shared/ui/Schedule/ui/SchedulePeriod'
import { ICampaignScheduleType, ICreateCampaignStep } from 'entities/Campaign'

export class CampaignScheduleStore {
  schedulePeriodStore
  constructor() {
    this.schedulePeriodStore = new SchedulePeriodStore()
    makeAutoObservable(this)
  }

  get params(): ICreateCampaignStep['schedule'] {
    return {
      type: this.scheduleType,
      options: this.laterConfig,
    }
  }
  get laterConfig() {
    if (this.scheduleType === 'delay') {
      return {
        unit: this.schedulePeriodStore.period,
        value: this.waitValue,
      }
    }
    return null
  }

  init = (params: typeof this.params) => {
    this.scheduleType = params.type
    if (params.type === 'delay' && params.options) {
      this.schedulePeriodStore.setPeriod(params.options?.unit)
      this.waitValue = params.options.value
    }
  }

  scheduleType: ICampaignScheduleType = 'immediately'
  setScheduleType = (value: ICampaignScheduleType) => {
    this.scheduleType = value
  }
  get scheduleText() {
    if (this.scheduleType === 'immediately') {
      return 'Immediately'
    }
    if (this.scheduleType === 'delay') {
      return `${this.waitValue} ${this.schedulePeriodStore.period} later`
    }
    return ''
  }

  waitValue = '30'
  lastValidValue = '30'
  setLastValidValue = (value: string) => {
    this.lastValidValue = value
  }
  setWaitValue = (value: string) => {
    this.waitValue = value
  }
  handleValidate = () => {
    if (!Number(this.waitValue)) {
      this.setWaitValue(this.lastValidValue)
    }
  }
}
