import { FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

type IBlockNumberProps = {
  number: string | number
  isOpen?: boolean
  className?: string
}

export const BlockNumber: FC<IBlockNumberProps> = ({ number, isOpen, className }) => {
  if (isOpen === undefined) {
    return <div className={classNames(styles.blockNumber, className)}>{number}</div>
  }

  return (
    <div
      className={classNames(styles.blockNumber, {
        [styles.blockNumberOpen]: isOpen,
        [styles.blockNumberClosed]: !isOpen,
      })}
    >
      {number}
    </div>
  )
}
