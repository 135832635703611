import { makeAutoObservable } from 'mobx'
import { type IResponseOrganizationBusinessProfile } from 'entities/Compliance'
import { PointsOfContact } from 'entities/Compliance/model/OrganizationBusinessProfile/PointsOfContact'
import { BusinessProfile } from './OrganizationBusinessProfile/BusinessProfile'
import { BusinessInformation } from './OrganizationBusinessProfile/BusinessInformation'
import { BusinessLocation } from './OrganizationBusinessProfile/BusinessLocation'

export class OrganizationBusinessProfile {
  private _businessInformation: BusinessInformation | null = null
  private _businessLocation: BusinessLocation | null = null
  private _businessProfile: BusinessProfile | null = null
  private _pointsOfContact: PointsOfContact = new PointsOfContact([])
  private _terms: IResponseOrganizationBusinessProfile['terms'] | null = null

  constructor(response: IResponseOrganizationBusinessProfile) {
    makeAutoObservable(this)

    this._businessInformation = response.businessInformation
      ? new BusinessInformation(response.businessInformation)
      : null
    this._businessLocation = response.businessLocation
      ? new BusinessLocation(response.businessLocation)
      : null
    this._businessProfile = new BusinessProfile(response.businessProfile, this._businessInformation)
    this._pointsOfContact = new PointsOfContact(response.pointsOfContact)
    this._terms = response.terms
  }

  get pointsOfContact() {
    return this._pointsOfContact
  }

  get businessInformation() {
    return this._businessInformation
  }

  get businessLocation() {
    return this._businessLocation
  }

  get businessProfile() {
    return this._businessProfile
  }

  get terms() {
    return this._terms
  }

  setBusinessInformation = (data: IResponseOrganizationBusinessProfile['businessInformation']) => {
    if (!data) return

    this._businessInformation = new BusinessInformation(data)
  }

  setPointsOfContact = (data: IResponseOrganizationBusinessProfile['pointsOfContact']) => {
    this._pointsOfContact = new PointsOfContact(data)
  }

  setBusinessProfile = (data: IResponseOrganizationBusinessProfile['businessProfile']) => {
    if (!data) return

    this._businessProfile = new BusinessProfile(data, this._businessInformation)
  }

  setBusinessLocation = (data: IResponseOrganizationBusinessProfile['businessLocation']) => {
    if (!data) return

    this._businessLocation = new BusinessLocation(data)
  }
}
