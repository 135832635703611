import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui/IconButton'
import { type IRow } from 'shared/ui/Table'

type IExpandCellProps<TRow> = {
  row: IRow<TRow>
}

export const ExpandCell = observer(<TRow,>({ row }: IExpandCellProps<TRow>) => {
  if (row.is_can_expanded) {
    return (
      <IconButton
        size={'large'}
        icon={row.expanded ? 'chevronDown' : 'chevronRight'}
        color={'transparent'}
        variant={'icon'}
        transparentBackground
        transparentInactive
        fontSize={16}
        onClick={row.toggleExpand}
        ariaLabel='ExpandCall'
      />
    )
  }
  return null
})
