import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { ChoiceTile, type IIconsVector } from 'shared/ui'
import { links } from 'shared/constants/links'
import { IOptInMethodTypes } from 'entities/Compliance'
import { LearnMoreLink } from 'pages/settings/pages/compliance/ui/LearnMoreLink/LearnMoreLink'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import styles from './styles.module.scss'

type IOptInMethodProps = {
  isNextDisabled: boolean
  stepLoading: boolean
  errorAlert?: ReactNode
  onNextClick: () => void
  isOptInMethodActive: (value: IOptInMethodTypes) => boolean
  setOptInMethods: (value: IOptInMethodTypes) => void
}

export const CommonOptInMethod = observer(
  ({
    isNextDisabled,
    stepLoading,
    errorAlert,
    onNextClick,
    isOptInMethodActive,
    setOptInMethods,
  }: IOptInMethodProps) => {
    const handleSetOptInMethod = (value: string) => setOptInMethods(value as IOptInMethodTypes)

    const optInMethods: { icon: IIconsVector; title: string; value: IOptInMethodTypes }[] = [
      {
        icon: 'monitor01',
        title: 'Online Form',
        value: IOptInMethodTypes.onlineForm,
      },
      {
        icon: 'testSMS',
        title: 'via Text',
        value: IOptInMethodTypes.text,
      },
      {
        icon: 'qrCode02',
        title: 'QR Code',
        value: IOptInMethodTypes.qrCode,
      },
      {
        icon: 'file02',
        title: 'Paper Form',
        value: IOptInMethodTypes.paperForm,
      },
    ]

    return (
      <div>
        <PageDescription>
          Select the option(s) around how your contacts opt-in to receive text messages, therefore
          giving consent to receive your messages.
          <LearnMoreLink href={links.complianceLearnMoreLink}>Learn more</LearnMoreLink>
        </PageDescription>

        {errorAlert}

        <div className={styles.wrap}>
          {optInMethods.map((method) => (
            <ChoiceTile
              key={method.value}
              iconProps={{
                fontSize: 20,
                icon: method.icon,
                color: isOptInMethodActive(method.value)
                  ? 'var(--content-brand-primary)'
                  : 'var(--content-primary-tertiary)',
              }}
              title={method.title}
              value={method.value}
              checked={isOptInMethodActive(method.value)}
              setChoiceTile={handleSetOptInMethod}
            />
          ))}
        </div>

        <NextButton
          loading={stepLoading}
          disabled={isNextDisabled || stepLoading}
          text='Next'
          onClick={onNextClick}
        />
      </div>
    )
  }
)
