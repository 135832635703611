import { makeAutoObservable, runInAction } from 'mobx'
import { IParamsFiltersListGroups, IResponseBroadcastById } from 'entities/Broadcast/api/types'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { Segment } from 'entities/Segment/model/Segment'
import { TagsApi } from 'entities/Tags'
import { Tag } from 'entities/Tags/model/Tag'
import { segmentsStore } from 'entities/Segment'
import { ContactFiltersApi } from 'entities/Contacts/api/contactsFilters'
import { EnrollmentFilters, EnrollmentStore } from 'widgets/ContactEnrollment'

export class BroadcastContactsStore {
  private _total = 0
  private _skipped = 0
  private _allTotal = 0
  private _enrollmentFilters = EnrollmentFilters.Empty()
  private _enrollmentStore = new EnrollmentStore({
    filterConfig: {
      makeRequest: () => {
        return ContactFiltersApi.getFilters({
          withCustomFields: true,
          withIntegrationList: true,
          withFullHubSpotList: true,
          skipValues: ['tag'],
        }).then(({ data }) => data)
      },
    },
  })
  private _loading = false
  private _number_id: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get total() {
    return this._total
  }

  get allTotal() {
    return this._allTotal
  }

  get skipped() {
    return this._skipped
  }

  get enrollmentStore() {
    return this._enrollmentStore
  }

  get filterEditorStore() {
    return this._enrollmentStore.filterEditorStore
  }

  get paramsFilters(): IParamsFiltersListGroups {
    return {
      filtersListGroups: {
        contacts: this._enrollmentFilters.contacts.map((contact) => contact.id) ?? [],
        segments: this._enrollmentFilters.segments?.map((segment) => segment.id) ?? [],
        tags: this._enrollmentFilters.tags?.map((tag) => tag.id),
        advanced: this._enrollmentFilters.filters?.map((filter) => filter.filtersParams),
      },
    }
  }

  loadFilters = () => {
    this._enrollmentStore.filterEditorStore.initFilters()
  }

  init = async (response: IResponseBroadcastById) => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const filtersListGroups = {
        advanced: response.filters.filtersListGroups.advanced,
        segments: response.filters.filtersListGroups.segments.map((item) =>
          typeof item === 'number' ? item : item.id
        ),
        tags: response.filters.filtersListGroups.tags.map((item) =>
          typeof item === 'number' ? item : item.id
        ),
        contacts: response.filters.filtersListGroups.contacts.map((item) =>
          typeof item === 'number' ? item : item.id
        ),
      }

      this._number_id = response.filters.number_id

      const { data } = await ContactsApi.getContactsFiltersCount({
        filtersListGroups: filtersListGroups,
        number_id: response.filters.number_id,
        package_count: response.package_count,
      })

      this._total = data.count
      this._skipped = data.invalid_count
      this._allTotal = data.count + data.invalid_count

      const tagsIds = filtersListGroups.tags
      const contactsIds = filtersListGroups.contacts
      const segmentsIds = filtersListGroups.segments

      const [tags, contacts] = await Promise.all([
        this.fetchTags(tagsIds),
        contactsStore.getByIds(contactsIds),
      ])
      const segments = segmentsIds.reduce<Segment[]>((state, id) => {
        const item = segmentsStore.getItem(id)

        if (item) {
          state.push(item)
        }

        return state
      }, [])
      const advanced = filtersListGroups.advanced.map(
        (item) =>
          new Segment({
            id: 0,
            organization_id: 0,
            name: '',
            filters: item,
          })
      )

      this._enrollmentFilters.sync({
        tags: tags,
        segments: segments,
        contacts: contacts,
        filters: advanced,
        number_id: this._number_id,
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  fetchTags = async (ids: number[]) => {
    const tags = await TagsApi.getTagsById(ids)

    return tags.map((tag) => new Tag(tag))
  }

  confirmEnrollment = (total: number, skipped: number) => {
    return new Promise<boolean>((resolve) => {
      resolve(true)
      this._total = total
      this._skipped = skipped
      this._allTotal = total + skipped
    })
  }

  confirmEnrollmentCallback = (isAllowed: boolean, newFilters: EnrollmentFilters) =>
    runInAction(() => {
      if (!isAllowed) return isAllowed

      this._enrollmentFilters = newFilters

      return isAllowed
    })

  editEnrollment = async () => {
    this._enrollmentFilters.sync({
      number_id: this._number_id,
    })

    const filters = this._enrollmentFilters.clone()

    const cancelFilters = () => Promise.resolve(true)

    const confirmFilters = async (
      newFilters: EnrollmentFilters,
      total: number,
      skipped: number
    ) => {
      const isAllowed = await this.confirmEnrollment(total, skipped)
      return this.confirmEnrollmentCallback(isAllowed, filters)
    }

    this._enrollmentStore.openModal({
      filters: filters,
      action: {
        cancel: cancelFilters,
        confirm: confirmFilters,
      },
      title: 'Contacts',
      number_id: this._number_id,
    })
  }

  setInitContactIds = async (contact_ids: number[]) => {
    const [{ data }, contacts] = await Promise.all([
      ContactsApi.getContactsFiltersCount({
        filtersListGroups: {
          contacts: contact_ids,
        },
      }),
      contactsStore.getByIds(contact_ids),
    ])
    runInAction(() => {
      this._total = data.count
      this._skipped = data.invalid_count
      this._allTotal = data.count + data.invalid_count
    })
    if (contacts) {
      this._enrollmentFilters.sync({
        contacts: contacts,
        number_id: this._number_id,
      })
    }
  }

  setNumberId = (value: typeof this._number_id) => {
    this._number_id = value
  }

  loadCount = async (value: typeof this._number_id) => {
    const filters = this._enrollmentFilters.clone()

    this._enrollmentStore.initEnrollmentList({
      number_id: value,
      filters,
      confirmEnrollment: async (total: number, skipped: number) => {
        const isAllowed = await this.confirmEnrollment(total, skipped)
        return this.confirmEnrollmentCallback(isAllowed, filters)
      },
    })
  }
}
