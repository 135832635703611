import type {
  IResponseConversation,
  IResponseConversationDraftMessage,
  IResponseConversationUnread,
  IResponseEventMessageDeletedDraft,
} from 'entities/Conversation/api/types'
import type {
  IResponseUserIntegration,
  IResponseIntegrationDisconnected,
  IResponseIntegrationRefreshed,
} from 'entities/Integrations/api/types'
import type {
  IResponseEventOrganizationCalling,
  IResponseEventUpdateBalance,
} from 'entities/Organization/api/types'
import type {
  IResponseCallQueueParticipant,
  IResponseEventConferenceParticipant,
  IResponseEventConversationBulkFavorite,
} from 'entities/Call/api/types'
import type { IResponseEventMessage, IResponseEventMessagePopper } from 'entities/Message/api/types'
import type {
  IResponseEventInboxCalling,
  IResponseInboxCombine,
  IResponseInboxDeleted,
} from 'entities/Inbox/api/types'
import type {
  IResponseAttachmentUpload,
  IResponseEventAttachmentUploadError,
  IResponseMedia,
  IResponseUploadSuccess,
} from 'entities/Attachment/api/types'
import type { IResponseEventTyping } from 'entities/Typing/api/types'
import type { IResponseEventActivityCreated } from 'entities/Activity/api/types'
import type { IResponseContact } from 'entities/Contacts/api/types'
import type { IResponseEventBroadcast } from 'entities/Broadcast/api/types'
import type { IResponseEventViewing } from 'entities/Viewing'
import type { IResponseKnowledgeBase, IResponseKnowledgeBaseDocument } from 'entities/KnowledgeBase'
import type {
  IResponseEventRegisterOrganizationError,
  IResponseEventTokenCreated,
} from 'entities/Auth'
import { IResponseEventCallerIdVerificationResult } from 'entities/Phone/api/types'

type WasteChar = '-' | '.' | '_'
type NormalChar = '.'

type NormalProp<Prop extends string> = Prop extends `${infer Char}${infer Rest}`
  ? `${Char extends WasteChar ? NormalChar : Char}${NormalProp<Rest>}`
  : Prop

type NormalName<Name extends string> = Name extends `${infer Part}${NormalChar}${infer Rest}`
  ? Capitalize<`${Part}${Capitalize<NormalName<Rest>>}`>
  : Name

type WebsocketEventName<Name extends string> = NormalName<NormalProp<Name>>

export type IWebsocketAction = {
  'conversation.contact.opted-out': IResponseConversation
  'conversation.contact.opted-in': IResponseConversation
  'conversation.reassigned': IResponseConversation
  'conversation.closed': IResponseConversation
  'conversation.opened': IResponseConversation
  'conversation.unread': IResponseConversationUnread
  'conversation.state-changed': IResponseConversation
  'inbox.calling': IResponseEventInboxCalling
  'inbox.created': IResponseInboxCombine
  'inbox.updated': IResponseInboxCombine
  'inbox.deleted': IResponseInboxDeleted
  'integration.connected': IResponseUserIntegration
  'integration.disconnected': IResponseIntegrationDisconnected
  'integration.users.refreshed': IResponseIntegrationRefreshed
  'message.popper': IResponseEventMessagePopper
  'organization.calling': IResponseEventOrganizationCalling
  'account_balance.updated': IResponseEventUpdateBalance
  'conference.participant_join': IResponseEventConferenceParticipant
  'conference.participant_leave': IResponseEventConferenceParticipant
  'conversations.bulk.favorite': IResponseEventConversationBulkFavorite
  'message.received.new': IResponseEventMessage
  'message.updated.new': IResponseEventMessage
  'message.transcript.completed': IResponseEventMessage
  'voicemail.received': IResponseEventMessage
  'conversations.activity.created': IResponseEventActivityCreated
  'client-typing': IResponseEventTyping
  'client-viewing': IResponseEventViewing
  'client-calling': boolean
  'attachment.video.converted': IResponseAttachmentUpload
  'contact.updated': IResponseContact
  'client-draft': IResponseConversationDraftMessage
  'message.deleted.draft': IResponseEventMessageDeletedDraft
  'call.queue_participant_join': IResponseCallQueueParticipant
  'call.queue_participant_leave': IResponseCallQueueParticipant
  'attachment.upload.error': IResponseEventAttachmentUploadError
  'attachment.upload.successful': IResponseUploadSuccess
  'attachment.thumbnail.created': IResponseMedia
  'broadcast.canceled': IResponseEventBroadcast
  'knowledge_base.updated': IResponseKnowledgeBase
  'knowledge_base_document.updated': IResponseKnowledgeBaseDocument
  'token.created': IResponseEventTokenCreated
  'register.organization.error': IResponseEventRegisterOrganizationError
  'number.caller_id_verification_result': IResponseEventCallerIdVerificationResult
}

export const WebsocketEvent: { [Key in keyof IWebsocketAction as WebsocketEventName<Key>]: Key } = {
  ConversationContactOptedOut: 'conversation.contact.opted-out',
  ConversationContactOptedIn: 'conversation.contact.opted-in',
  ConversationReassigned: 'conversation.reassigned',
  ConversationClosed: 'conversation.closed',
  ConversationOpened: 'conversation.opened',
  ConversationUnread: 'conversation.unread',
  ConversationStateChanged: 'conversation.state-changed',
  InboxCalling: 'inbox.calling',
  IntegrationConnected: 'integration.connected',
  IntegrationDisconnected: 'integration.disconnected',
  IntegrationUsersRefreshed: 'integration.users.refreshed',
  MessagePopper: 'message.popper',
  OrganizationCalling: 'organization.calling',
  AccountBalanceUpdated: 'account_balance.updated',
  ConferenceParticipantJoin: 'conference.participant_join',
  ConferenceParticipantLeave: 'conference.participant_leave',
  ConversationsBulkFavorite: 'conversations.bulk.favorite',
  MessageReceivedNew: 'message.received.new',
  MessageUpdatedNew: 'message.updated.new',
  VoicemailReceived: 'voicemail.received',
  ConversationsActivityCreated: 'conversations.activity.created',
  ClientTyping: 'client-typing',
  ClientViewing: 'client-viewing',
  AttachmentVideoConverted: 'attachment.video.converted',
  ContactUpdated: 'contact.updated',
  ClientDraft: 'client-draft',
  MessageDeletedDraft: 'message.deleted.draft',
  CallQueueParticipantJoin: 'call.queue_participant_join',
  CallQueueParticipantLeave: 'call.queue_participant_leave',
  MessageTranscriptCompleted: 'message.transcript.completed',
  InboxUpdated: 'inbox.updated',
  InboxDeleted: 'inbox.deleted',
  InboxCreated: 'inbox.created',
  AttachmentUploadError: 'attachment.upload.error',
  AttachmentUploadSuccessful: 'attachment.upload.successful',
  AttachmentThumbnailCreated: 'attachment.thumbnail.created',
  BroadcastCanceled: 'broadcast.canceled',
  KnowledgeBaseUpdated: 'knowledge_base.updated',
  KnowledgeBaseDocumentUpdated: 'knowledge_base_document.updated',
  TokenCreated: 'token.created',
  RegisterOrganizationError: 'register.organization.error',
  ClientCalling: 'client-calling',
  NumberCallerIdVerificationResult: 'number.caller_id_verification_result',
}
