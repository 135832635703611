import {
  ChatbotInstructionType,
  ChatbotStatus,
  IChatbotAnalyticBase,
  IChatbotConversationStatus,
  IChatbotData,
} from '../model/type'

export type { IChatbotPayload } from '../model/type'

export enum ChatbotListParams {
  Name = 'filters[name]',
  Status = 'filters[status]',
}

export type IParamsGetChatbotList = {
  limit: number | null
  page: number
  [ChatbotListParams.Name]?: string
  [ChatbotListParams.Status]?: ChatbotStatus
}

export type IResponseChatbotList = {
  data: IChatbotFullData[]
  meta: IChatbotListPagination
}

export type IChatbotListPagination = {
  current_page: number
  last_page: number
  per_page: number
  total: number
}

export type IChatbotFullData = IChatbotData & { id: number }

export type IResponseUpdateChatbotStatus = IChatbotFullData

export type IResponseDeleteChatbot = {
  status: string
  message: string
}

export enum ChatbotAnalyticListFilter {
  Status = 'filters[status]',
}

export type IParamsGetChatbotAnalyticList = {
  id: number
  limit: number | null
  page: number
  [ChatbotAnalyticListFilter.Status]?: IChatbotConversationStatus | null
}

export type IResponseChatbotAnalyticList = {
  data: IChatbotAnalyticBase[]
  meta: IChatbotListPagination
}

export type IParamsEnrollContactsInChatbot = {
  chatbot_id: number
  contact_ids: number[]
  apply_filters: boolean
}

export type IChatbotInstruction = {
  type: ChatbotInstructionType
  instruction: string
}
