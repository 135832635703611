import { Outlet } from 'react-router-dom'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { PageLayout } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { MenuAIAutomation } from 'widgets/Menu/ui/MenuAIAutomation/ui/MenuAIAutomation'
import { MenuAIAutomationLayoutStore } from 'widgets/Menu/ui/MenuAIAutomation/store/menuAIAutomationLayoutStore'
import {
  MenuAIAutomationLayoutContext,
  useMenuAIAutomationLayoutContext,
} from 'widgets/Menu/ui/MenuAIAutomation/context/menuAIAutomationLayoutContext'

const MenuAIAutomationLayoutContent = observer(() => {
  const menuLayoutStore = useMenuAIAutomationLayoutContext()

  if (!featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return <Outlet />
  }

  return <PageLayout leftContent={<MenuAIAutomation />} store={menuLayoutStore.pageLayoutStore} />
})

export const MenuAIAutomationLayout = observer(() => {
  const [menuLayoutStore] = useState(() => new MenuAIAutomationLayoutStore())

  return (
    <MenuAIAutomationLayoutContext.Provider value={menuLayoutStore}>
      <MenuAIAutomationLayoutContent />
    </MenuAIAutomationLayoutContext.Provider>
  )
})
