import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CleanupPage } from 'pages/contacts/pages/cleanup'
import { HistoryPage } from 'pages/contacts/pages/history'
import { ContactsPage } from '../ui/contactsPage'
import { ContactsLayout } from '../layout/ContactsLayout'
import { ContactsRoutes } from './type'

export const ContactsRouter = observer(() => {
  return (
    <Routes>
      <Route element={<ContactsLayout />}>
        <Route path={'/'} element={<ContactsPage />} />
        <Route path={'/:id'} element={<ContactsPage />} />
        <Route path={`${ContactsRoutes.ALL}`} element={<ContactsPage />} />
        <Route path={`${ContactsRoutes.HISTORY}`} element={<HistoryPage />} />
        <Route path={`${ContactsRoutes.CLEANUP}`} element={<CleanupPage />} />
        {/* ContactRouts.DUPLICATE and ContactRouts.UNLINKED are the old routs for cleanup in 1.0 */}
        <Route
          path={`${ContactsRoutes.DUPLICATE}`}
          element={<Navigate to={`/${ContactsRoutes.CONTACTS}/${ContactsRoutes.CLEANUP}`} />}
        />
        <Route
          path={`${ContactsRoutes.UNLINKED}`}
          element={<Navigate to={`/${ContactsRoutes.CONTACTS}/${ContactsRoutes.CLEANUP}`} />}
        />
        <Route
          path={'*'}
          element={<Navigate to={`/${ContactsRoutes.CONTACTS}/${ContactsRoutes.ALL}`} />}
        />
      </Route>
    </Routes>
  )
})
