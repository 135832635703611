import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IRow, Icon, Avatar, Typography, Button } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { IUser } from 'entities/Users'
import { IntegrationCrmUser } from 'entities/Integrations/model/IntegrationCrmUser'
import { ICrmUserIntegrationMeta } from 'entities/Integrations'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsUsersProps = {
  row: IRow<IntegrationCrmUser<ICrmUserIntegrationMeta>>
  members: IUser[]
  onUserChange: (memberId: number) => void
  onUserInvite: (userId: number) => void
  onManageInvite: (userId: number) => void
}

export const IntegrationHubspotSettingsUsersCell: React.FC<IIntegrationHubspotSettingsUsersProps> =
  observer(({ row, members, onUserChange, onUserInvite, onManageInvite }) => {
    const [search, onSearch] = useState('')

    const filteredMembers = useMemo(() => {
      if (!search) return members
      const localSearch = search.toLocaleLowerCase()

      return members.filter(
        ({ email, name }) =>
          email.toLocaleLowerCase().includes(localSearch) ||
          name.toLocaleLowerCase().includes(localSearch)
      )
    }, [members, search])

    const setValue = (memberId: number) => {
      onUserChange(memberId === row.user_id ? 0 : memberId)
    }

    const isPending = row.isPending && !row.checkStatus
    const pendingEmail = row.invitationData?.email

    return (
      <SelectField
        placeholder={
          isPending ? (
            <div className={styles.dropdownLablel}>
              <Icon
                className={styles.dropdownAvatar}
                icon={'clock'}
                color={'var(--action-contained-primary-normal)'}
              />
              <Typography
                variant={'body-md-regular'}
                ellipsis
                tooltipProps={{ label: pendingEmail }}
                ariaLabel={'displayInfo'}
              >
                {pendingEmail}
              </Typography>
              <Typography
                className={styles.dropdownName}
                variant={'body-md-regular'}
                ellipsis
                ariaLabel={'email'}
                color={'tertiary'}
              >
                (Pending)
              </Typography>
            </div>
          ) : undefined
        }
        size={'medium'}
        items={filteredMembers.map((member) => ({
          id: member.id,
          labelWidth: '400px',
          label: '',
          labelRight: (
            <Typography
              className={styles.dropdownEmailFixed}
              variant={'body-sm-regular'}
              ellipsis
              tooltipProps={{ label: member.email }}
              ariaLabel={'email'}
              color={'tertiary'}
            >
              {member.email}
            </Typography>
          ),
          labelContent: (
            <div className={styles.dropdownLablel}>
              <Avatar className={styles.dropdownAvatar} info={member.avatarInfo} size={16} />
              <Typography
                className={styles.dropdownName}
                variant={'body-md-regular'}
                ellipsis
                tooltipProps={{ label: member.name }}
                ariaLabel={'displayInfo'}
              >
                {member.name}
              </Typography>
              <Typography
                className={styles.dropdownEmail}
                variant={'body-md-regular'}
                ellipsis
                tooltipProps={{ label: member.email }}
                ariaLabel={'email'}
              >
                {member.email}
              </Typography>
            </div>
          ),
        }))}
        value={row.user_id || undefined}
        setValue={setValue}
        selectProps={{
          placeholder: 'Select User',
          dropdownProps: {
            propsDropdownList: {
              search,
              onSearch,
              width: 420,
              className: styles.dropdown,
            },
            className: styles.dropdown,
            placeholder: 'Search',
            margin: -40,
            underControlComponent: (onCloseMenu) =>
              isPending ? (
                <div className={styles.dropdownManage}>
                  <Icon icon={'clock'} color={'var(--action-contained-primary-normal)'} />
                  <Typography
                    variant={'body-md-regular'}
                    ellipsis
                    tooltipProps={{ label: pendingEmail }}
                    ariaLabel={'displayInfo'}
                  >
                    {pendingEmail}
                  </Typography>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    text='Manage'
                    icon='settings2'
                    typeBtn='text'
                    color='tertiary'
                    size='small'
                    onClick={() => {
                      onManageInvite(row.id)
                      onCloseMenu()
                    }}
                  />
                </div>
              ) : (
                <div
                  className={styles.dropdownButton}
                  onClick={() => {
                    onUserInvite(row.id)
                    onCloseMenu()
                  }}
                >
                  <Icon icon={'add'} /> Invite user
                </div>
              ),
          },
        }}
      />
    )
  })
