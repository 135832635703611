import React, { FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, SpinnerLoader, Tabs } from 'shared/ui'
import { sizeAlertCreator } from 'shared/lib/sizeAlertCreator'
import { featureFlagsStore } from 'entities/FeatureFlags'
import type { MediaLibraryStore } from 'widgets/MediaLibrary/store/mediaLibraryStore'
import { mediaLibraryTabs } from 'widgets/MediaLibrary/types'
import { TabContent } from 'widgets/MediaLibrary/ui/MediaLibraryModal/TabContent'
import { useMediaLibraryEventAttachmentUpload } from 'widgets/MediaLibrary/events/useMediaLibraryEventAttachmentUpload'
import { MediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { MediaLibraryControls } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryControls/MediaLibraryControls'
import styles from './styles.module.scss'

type IMediaLibraryModalContentProps = {
  mediaLibraryStore: MediaLibraryStore
}

export const MediaLibraryModalContent: FC<IMediaLibraryModalContentProps> = observer(
  ({ mediaLibraryStore }) => {
    const {
      selectedContentType,
      handleSelectContentType,
      isAttachmentInitListLoading,
      limitSizeErrors,
      searchTerm,
      currentPage,
      isAircallInbox,
      isSearchResettingForUpload,
      error,
      errorTitle,
      isRinglessSelectVariant,
    } = mediaLibraryStore

    useEffect(() => {
      if (!isSearchResettingForUpload) {
        void mediaLibraryStore.fetchAttachmentList(searchTerm)
      }
    }, [searchTerm, currentPage, selectedContentType])
    useMediaLibraryEventAttachmentUpload(mediaLibraryStore)

    const tabs = useMemo(() => {
      const filteredTabs = mediaLibraryTabs.filter((tab) => {
        if (isAircallInbox && !featureFlagsStore.aircall_new_api) {
          return ['all', 'videos', 'files'].includes(tab.key)
        }
        if (isRinglessSelectVariant) {
          return tab.key === 'audio'
        }
        return true
      })

      if (!isAttachmentInitListLoading) return filteredTabs

      return filteredTabs.map((tab) =>
        tab.key === selectedContentType ? tab : { ...tab, disabled: true }
      )
    }, [isAttachmentInitListLoading, isAircallInbox])

    return (
      <MediaLibraryContext.Provider value={mediaLibraryStore}>
        <div className={styles.libraryContentWrapper}>
          {Array.from(limitSizeErrors.entries()).map(([label, limit]) => {
            const alertData = sizeAlertCreator({ label, limit })
            return (
              <Alert
                key={label}
                item={{
                  type: 'alert',
                  desc: `${alertData.title}. ${alertData.desc}`,
                }}
                marginBottom={4}
                onClose={() => mediaLibraryStore.deleteLimitSizeError(label)}
              />
            )
          })}
          {error && (
            <Alert
              item={{
                type: 'alert',
                desc: error,
                title: errorTitle,
              }}
              marginBottom={16}
            />
          )}
          {isAircallInbox && !featureFlagsStore.aircall_new_api && (
            <Alert
              item={{
                type: 'warning',
                desc: 'MMS is not currently supported by Aircall',
              }}
              marginBottom={24}
            />
          )}
          <MediaLibraryControls />
          <Tabs
            variant={tabs.length > 1 ? 'dividerForFirst' : 'default'}
            tabs={tabs}
            activeTabKey={selectedContentType}
            handleSelectTab={handleSelectContentType}
          />
          {isAttachmentInitListLoading ? (
            <div className={styles.spinnerContainer}>
              <SpinnerLoader />
            </div>
          ) : (
            <TabContent />
          )}
        </div>
      </MediaLibraryContext.Provider>
    )
  }
)
