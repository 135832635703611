import type { IKeyword } from 'entities/Keywords'
import { KeywordDetailsModalContext } from 'pages/keywords/context'
import { DetailsKeywordModalContent } from './DetailsKeywordModalContent'

type IDetailsKeywordModalProps = {
  keyword: IKeyword
}

export const DetailsKeywordModal = ({ keyword }: IDetailsKeywordModalProps) => {
  return (
    <KeywordDetailsModalContext.Provider value={keyword}>
      <DetailsKeywordModalContent />
    </KeywordDetailsModalContext.Provider>
  )
}
