import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { AccordionSection, ContactsList, useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'

export const CallPowerDialerSection = observer(() => {
  const { callPopUpPowerDialerStoreV2, innerRefTooltipCurrent } = useCallPopUpDefaultContext()
  const {
    contactsCount,
    isPause,
    saving,
    handleCheckCredits,
    togglePowerDialerPause,
    handlePowerDialerStop,
    handleSkipContact,
    contacts,
    currentPowerDialerName,
    handleSaveAndExit,
  } = callPopUpPowerDialerStoreV2

  return (
    <AccordionSection show count={contactsCount} title={currentPowerDialerName}>
      <div className={styles.wrapBtn}>
        <Button
          contained={'secondary'}
          iconProps={{
            icon: isPause ? 'play' : 'pause',
          }}
          text={isPause ? 'Resume' : 'Pause'}
          fullWidth
          onClick={() => {
            if (handleCheckCredits()) return

            togglePowerDialerPause()
          }}
        />
        <Button
          contained={'primary'}
          intent={'destructive'}
          iconProps={{
            icon: 'stop',
          }}
          text={'Stop'}
          fullWidth
          onClick={handlePowerDialerStop}
        />
      </div>

      <ContactsList
        items={contacts}
        innerRefCurrent={innerRefTooltipCurrent || undefined}
        onDelete={(item) => handleSkipContact(item)}
      />
      {isPause && (
        <Button
          contained={'secondary'}
          text={'Save and exit'}
          loading={saving}
          fullWidth
          onClick={handleSaveAndExit}
          className={styles.saveAndExitButton}
        />
      )}
    </AccordionSection>
  )
})
