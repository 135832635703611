import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { TypingIndicator } from 'shared/ui/TypingIndicator/TypingIndicator'
import { usersStore } from 'entities/Users'
import { Typing } from 'entities/Typing/model/Typing'
import styles from './styles.module.scss'

type ITypingCardProps = {
  typing: Typing
}

export const TypingCard = observer(({ typing }: ITypingCardProps) => {
  const { userId, first_name, last_name } = typing
  const user = usersStore.getItem(userId)
  const text = user ? user.displayInfo : `${first_name} ${last_name}`.trim()
  return (
    <div className={styles.wrap}>
      <Typography variant={'body-xs-regular'} ariaLabel={'typing'}>
        {text} is typing
      </Typography>
      <TypingIndicator />
    </div>
  )
})
