import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Button, Dropdown } from 'shared/ui'
import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { IHubspotPropertiesObjectType } from 'entities/Integrations'
import { type NewHubspotPropertiesDropdownStore } from 'widgets/NewHubspotPropertiesDropdown'
import { NewHubspotPropertiesDropdownContent } from 'widgets/NewHubspotPropertiesDropdown/ui/NewHubspotPropertiesDropdownContent'
import { NewHubspotPropertiesDropdownContext } from 'widgets/NewHubspotPropertiesDropdown/context/newHubspotPropertiesDropdownContext'

type INewHubspotPropertiesDropdownProps = {
  store: NewHubspotPropertiesDropdownStore
  sourceType: IUiSettingsSourceType
  objectType?: IHubspotPropertiesObjectType
  objectId?: number
}
const NewHubspotPropertiesDropdown: FC<INewHubspotPropertiesDropdownProps> = observer(
  ({ store, sourceType, objectType, objectId }) => {
    return (
      <NewHubspotPropertiesDropdownContext.Provider value={store}>
        <Dropdown
          margin={-25}
          width={260}
          placement='bottom-start'
          triggerComponent={() => (
            <Button
              text={'Add property'}
              icon='add'
              typeBtn='text'
              size={'small'}
              disabled={store.disabledAddButton}
            />
          )}
          customComponent={(_, dropdownId) => (
            <NewHubspotPropertiesDropdownContent
              dropdownId={dropdownId}
              sourceType={sourceType}
              objectType={objectType}
              objectId={objectId}
            />
          )}
        />
      </NewHubspotPropertiesDropdownContext.Provider>
    )
  }
)

export { NewHubspotPropertiesDropdown }
