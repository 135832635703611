import { observer } from 'mobx-react-lite'
import { Box, Button, SearchDropdownInput, TextFieldWithLabel } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import einExample from 'shared/assets/images/einExample.png'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { getCDNAssetURL } from 'shared/lib'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { LinkButton } from 'pages/settings/pages/compliance/ui/LinkButton/LinkButton'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import styles from './styles.module.scss'

export const AutoBusinessFind = observer(() => {
  const { generalInfoStore, companyLocationStore, openDoNotHaveEINModal, changeGeneralInfoTab } =
    useBusinessProfileStepsContext()
  const { einItem, einSearch, setFirstEinItem, isNoEinResults } = generalInfoStore
  const { isUsaLocation } = companyLocationStore
  const linkToInfo = getCDNAssetURL(einExample)

  return (
    <Box>
      <div>
        <Label
          marginBottom={4}
          label={{
            text: isUsaLocation
              ? 'Employer Identification Number (EIN)'
              : 'Canadian Business Number (BN)',
            rightNode: isUsaLocation && (
              <LinkButton text={'Where can I find it?'} link={linkToInfo} />
            ),
          }}
        />
        <SearchDropdownInput
          isNoResults={isNoEinResults}
          items={generalInfoStore.einItemList}
          search={generalInfoStore.einSearch}
          tipTitle='Search by EIN, e.g. 12-3456789'
          width={485}
          errorMessageValue={generalInfoStore.errorMessage}
          onChangeSearch={generalInfoStore.setEinSearch}
          onSetValue={setFirstEinItem}
          textFieldProps={{
            variant: 'stroke',
            size: 'medium',
            InputProps: {
              placeholder: 'Search',
              onBlur: generalInfoStore.checkEinNumber,
              className: styles.searchInput,
            },
            leftActions: [
              makeTextFieldIcon({
                icon: 'search',
                className: styles.searchIcon,
              }),
            ],
          }}
          noResultsMessage={
            <div className={styles.noResultsMessage}>
              No results found. If you do not find your business,{' '}
              <Button
                text={'enter manually'}
                typeBtn='text'
                contained='secondary'
                size='small'
                intent='positive'
                textClassname={styles.enterManuallyButton}
                onClick={() => changeGeneralInfoTab(2)}
              />
            </div>
          }
        />
        <Button
          text={`I don’t have a ${
            isUsaLocation ? 'United States EIN number' : 'Canadian Business Number'
          }`}
          size={'small'}
          typeBtn='informative'
          contained='secondary'
          onClick={openDoNotHaveEINModal}
          className={styles.noHaveEinButton}
        />
      </div>

      {generalInfoStore.einItem?.ein && (
        <div className={styles.einFieldContainer}>
          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: isUsaLocation
                  ? 'Employer Identification Number (EIN)'
                  : 'Canadian Business Number (BN)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einSearch,
              className: styles.textFieldWithLabelInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Legal business name',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.name,
              className: styles.textFieldWithLabelInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street1,
              className: styles.textFieldWithLabelInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address 2 (optional)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street2,
              className: styles.textFieldWithLabelInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'City',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.city,
              className: styles.textFieldWithLabelInput,
            }}
          />

          <Box display='flex' justifyContent='space-between' gap={'12px'}>
            <Box flex={1}>
              <SelectField
                items={[]}
                setValue={() => {}}
                value={einItem?.state}
                btnProps={{
                  size: 'large',
                  disabled: true,
                  text: einItem?.state,
                  style: {
                    height: '40px',
                  },
                }}
                dropdownProps={{
                  disabled: true,
                }}
                labelProps={{
                  label: {
                    text: isUsaLocation ? 'State' : 'Province',
                  },
                }}
              />
            </Box>
            <Box flex={1}>
              <TextFieldWithLabel
                marginBottom={0}
                labelProps={{
                  label: {
                    text: isUsaLocation ? 'ZIP code' : 'Postal code',
                  },
                }}
                textFieldProps={{
                  disabled: true,
                  value: einItem?.zip,
                  className: styles.zipCodeInput,
                }}
              />
            </Box>
          </Box>
        </div>
      )}
    </Box>
  )
})
