import { observer } from 'mobx-react-lite'
import { MessagePreview } from 'widgets/MessagePreview'
import { CampaignMessageStore } from 'pages/campaigns/ui/CampaignMessage'
import { PreviewMessage } from './PreviewMessage/PreviewMessage'
import styles from './styles.module.scss'

type IPreviewProps = {
  orderedMessages: CampaignMessageStore[]
}

export const Preview = observer(({ orderedMessages }: IPreviewProps) => {
  return (
    <MessagePreview
      customContent={
        <div className={styles.wrap}>
          {orderedMessages.map((message) => (
            <PreviewMessage key={message.uid} message={message} />
          ))}
        </div>
      }
    />
  )
})
