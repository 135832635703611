import { observer } from 'mobx-react-lite'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'

import { QuickLinksNoResults } from './QuickLinksNoResults'
import { QuickLinksSearch } from './QuickLinksSearch'

export const ContactsQuickLinksSearch = observer(() => {
  const { selectedContact } = useContactsDetailsContext()
  const contactId = selectedContact?.id

  if (contactId == null) return <QuickLinksNoResults />

  return <QuickLinksSearch contactId={contactId} />
})
