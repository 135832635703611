import { CSSProperties, FC, forwardRef, MouseEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { nanoid } from 'nanoid'
import { styled } from '@mui/material/styles'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import classNames from 'classnames'
import type { ForwardRefComponentProps } from 'shared/ui/Tooltip'
import { IconButton } from 'shared/ui/IconButton'
import { Button } from 'shared/ui/Button'
import { type IDropdownItem, type IDropdownProps } from 'shared/ui/Dropdown'
import { dropDownStore } from 'shared/ui/Dropdown/store'
import { DropdownList } from 'shared/ui/Dropdown/ui/DropdownList/DropdownList'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

const ForwardRefComponent = forwardRef(function ForwardRefComponent(
  props: ForwardRefComponentProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  )
})

const HtmlTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & {
    width?: CSSProperties['width']
    margin?: string | number
    margintop?: number | string
    marginleft?: number | string
    zindex?: number
  }) => (
    <Tooltip
      {...props}
      aria-label={getAriaLabel('HtmlTooltip')}
      classes={{ popper: className }}
      PopperProps={{
        ...props.PopperProps,
        style: { zIndex: props.zindex ?? 1500 },
      }}
    />
  )
)(({ theme, width, margin = 4, margintop, marginleft }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: '"Inter", sans-serif',
    backgroundColor: 'transparent',
    color: 'transparent',
    width,
    maxWidth: width ? 'unset' : 335,
    padding: '0',
    borderRadius: '0',
    fontSize: theme.typography.pxToRem(13),
    border: 'none',
    margin: 0,
  },
  [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: margin,
    marginBottom: margin,
    marginLeft: marginleft,
  },
  ['&[data-popper-placement="top-start"] .top-dropdown-column-reverse-content']: {
    flexFlow: 'column-reverse',
  },
  ['&[data-popper-placement="top"] .top-dropdown-column-reverse-content']: {
    flexFlow: 'column-reverse',
  },
  ['&[data-popper-placement="top-end"] .top-dropdown-column-reverse-content']: {
    flexFlow: 'column-reverse',
  },
  [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: margin,
    marginTop: margin,
    marginLeft: marginleft,
  },
  [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementLeft}`]: {
    marginTop: margintop,
    marginRight: margin,
  },
  [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementRight}`]: {
    marginTop: margintop,
    marginLeft: margin,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--background-neutral-tertiary)',
  },
  [`& .${tooltipClasses.arrow}:before`]: {
    borderRadius: '2px',
  },
}))

export const Dropdown: FC<IDropdownProps> = observer(
  ({
    items = [],
    onChange,
    onPressEnter,
    onToggle = () => {},
    icon = 'add',
    sizeTriggerButton = 'small',
    variantTriggerButton,
    typeButton = 'icon-button',
    placement,
    tooltipProps,
    buttonProps,
    iconButtonProps,
    customComponent,
    triggerComponent,
    width,
    childContainerWidth,
    maxHeight,
    margin,
    marginTop,
    marginLeft,
    withSearch,
    withTextInput,
    bottomActions,
    show = false,
    onClose,
    isHover = false,
    disabled = false,
    parent,
    parentsDropdownId,
    onChangeOpen = () => {},
    textFieldProps,
    className = '',
    onToggleHover,
    PopperProps = null,
    TransitionProps,
    disableStopPropagation = false,
    topListComponent,
    underControlComponent,
    overlayModalsIds,
    autoOpen,
    ariaLabel,
    zIndex = 1500,
    propsDropdownList,
    withToggleTriggerOpen,
    placeholder,
  }) => {
    const [open, setOpen] = useState(false)
    const [nanoId] = useState(nanoid())
    ariaLabel = `Dropdown_${ariaLabel}`

    const handleTooltipClose = () => {
      if (overlayModalsIds) {
        let hasOverlayModal = false

        overlayModalsIds.map((modalId) => {
          if (modalStore.hasModal(modalId)) {
            hasOverlayModal = true
            return
          }
        })

        if (hasOverlayModal) return
      }

      if (open) {
        setOpen(false)
        onToggle(false)
        onClose?.()
      }
    }

    const handleTooltipOpen = (e?: MouseEvent<HTMLDivElement>) => {
      !disableStopPropagation && e?.stopPropagation()

      if (disabled) return

      if (withToggleTriggerOpen) {
        setOpen(!open)
        onToggle(!open)
      } else {
        setOpen(true)
        onToggle(true)
      }

      dropDownStore.allHide(nanoId, parentsDropdownId)
    }

    const handleChange = (item: IDropdownItem, parent?: IDropdownItem) => {
      onChange?.(item, parent)

      if (item.disabledClosedTooltip) return
      handleTooltipClose()
    }

    useEffect(() => {
      dropDownStore.setItem(nanoId, { setOpen: setOpen, open: open })
      autoOpen && setOpen(true)

      return () => {
        dropDownStore.deleteItem(nanoId)
      }
    }, [])

    useEffect(() => {
      if (show) {
        handleTooltipOpen()
      } else {
        handleTooltipClose()
      }
    }, [show])

    useEffect(() => {
      onChangeOpen(open)
    }, [open])

    const component = customComponent ? (
      customComponent(handleTooltipClose, nanoId)
    ) : (
      <DropdownList
        width={width}
        childContainerWidth={childContainerWidth}
        items={items}
        maxHeight={maxHeight}
        onChange={handleChange}
        onPressEnter={onPressEnter}
        parent={parent}
        withSearch={withSearch}
        bottomActions={bottomActions}
        withTextInput={withTextInput}
        textFieldProps={textFieldProps}
        topListComponent={topListComponent?.(handleTooltipClose, nanoId)}
        underControlComponent={underControlComponent?.(handleTooltipClose, nanoId)}
        placeholder={placeholder}
        {...propsDropdownList}
      />
    )

    const renderButton = () => {
      if (triggerComponent) {
        return triggerComponent(open)
      }
      if (typeButton === 'icon-button') {
        return (
          <IconButton
            tag='span'
            icon={icon}
            size={sizeTriggerButton}
            color={'secondary'}
            active={open}
            variant={variantTriggerButton}
            {...iconButtonProps}
            tooltipProps={
              tooltipProps ? { ...tooltipProps, label: open ? '' : tooltipProps.label } : undefined
            }
            disabled={disabled}
            ariaLabel={getAriaLabel('Trigger')}
          />
        )
      }

      return <Button icon={icon} disabled={disabled} {...buttonProps} />
    }

    if (isHover) {
      return (
        <div className={styles.wrap} aria-label={getAriaLabel(ariaLabel)}>
          <HtmlTooltip
            open={open}
            PopperProps={{
              ...PopperProps,
            }}
            TransitionProps={{
              ...TransitionProps,
            }}
            placement={placement}
            arrow={false}
            title={component}
            width={width}
            margin={margin}
            margintop={marginTop}
            marginleft={marginLeft}
            zindex={zIndex}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            aria-label={getAriaLabel(ariaLabel, 'Content')}
          >
            <ForwardRefComponent>
              <div onClick={handleTooltipOpen}>{renderButton()}</div>
            </ForwardRefComponent>
          </HtmlTooltip>
        </div>
      )
    }

    const onMouseLeave = () => {
      onToggleHover?.(false)
    }

    const onMouseEnter = () => {
      onToggleHover?.(true)
    }

    return (
      <div
        className={classNames(styles.wrap, className, {
          [styles.fullDisabled]: disabled,
        })}
        aria-label={getAriaLabel(ariaLabel)}
      >
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div aria-label={getAriaLabel(ariaLabel, 'Wrap')}>
            <HtmlTooltip
              placement={placement}
              arrow={false}
              open={open}
              PopperProps={{
                disablePortal: false,
                ...PopperProps,
              }}
              TransitionProps={{
                ...TransitionProps,
              }}
              onClose={handleTooltipClose}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={component}
              width={width}
              margin={margin}
              margintop={marginTop}
              marginleft={marginLeft}
              zindex={zIndex}
              aria-label={getAriaLabel(ariaLabel, 'Content')}
            >
              <ForwardRefComponent>
                <div
                  onClick={handleTooltipOpen}
                  onMouseLeave={onMouseLeave}
                  onMouseEnter={onMouseEnter}
                  aria-label={getAriaLabel(ariaLabel, 'Trigger')}
                >
                  {renderButton()}
                </div>
              </ForwardRefComponent>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
      </div>
    )
  }
)
