import { FC, CSSProperties, forwardRef, ReactNode, ReactElement, Ref } from 'react'
import classnames from 'classnames'
import TooltipMui, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { type ITooltipSpeedProps } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { getAriaLabel } from 'shared/lib'
import { ITooltipDefaultProps, TooltipDefault } from './TooltipDefault/TooltipDefault'
import { ITooltipSharedLinkProps, TooltipSharedLink } from './TooltipSharedLink/TooltipSharedLink'
import { ITooltipBillingProps, TooltipBilling } from './TooltipBilling/TooltipBilling'
import {
  ITooltipFilteredItemsProps,
  TooltipFilteredItems,
} from './TooltipFilteredContacts/TooltipFilteredContacts'
import {
  ITooltipDescriptionProps,
  TooltipDescription,
} from './TooltipDescription/TooltipDescription'
import styles from './styles.module.scss'

export type ForwardRefComponentProps = {
  children: ReactNode
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface ITooltipProps
  extends ITooltipDescriptionProps,
    ITooltipDefaultProps,
    ITooltipSharedLinkProps,
    ITooltipBillingProps,
    ITooltipFilteredItemsProps,
    ITooltipSpeedProps,
    TooltipProps {
  type?: 'default' | 'description' | 'shared_link' | 'billing' | 'filtered_items'
  title?: string
  padding?: string | number
  width?: CSSProperties['width']
  maxWidth?: CSSProperties['width']
  margin?: number | string
  marginRight?: number | string
  marginLeft?: number | string
  marginTop?: number | string
  marginBottom?: number | string
  children?: ReactElement
  fullWidth?: boolean
  color?: 'white' | 'red' | 'yellow'
  zIndex?: number
  isDisplayGrid?: boolean
  hidden?: boolean
  visibility?: 'visible' | 'hidden'
  open?: boolean
  withoutPointer?: boolean
  onClose?: () => void
  onOpen?: () => void
  ariaLabel?: string
}

export interface TooltipPropsMui extends TooltipProps {
  padding?: string | number
}

const ForwardRefComponent = forwardRef(function ForwardRefComponent(
  props: ForwardRefComponentProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  )
})

const getStyleInfo = (color?: 'white' | 'red' | 'yellow') => {
  if (color === 'white') {
    return {
      backgroundColor: 'var(--background-primary-inverted-primary)',
      color: 'var(--content-primary-primary)',
      boxShadow: 'var(--tooltip-actions-shadow)',
    }
  }
  if (color === 'red') {
    return {
      backgroundColor: 'var(--red-20)',
      color: 'var(--content-negative-secondary)',
    }
  }
  if (color === 'yellow') {
    return {
      backgroundColor: 'var(--yellow-90)',
      color: 'var(--content-warning-secondary)',
    }
  }
  return {
    backgroundColor: 'var(--background-neutral-tertiary)',
    color: 'var(--content-primary-primary-inverted)',
  }
}

const HtmlTooltip = styled(
  ({
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    marginRight,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    marginLeft,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    marginTop,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    marginBottom,
    ...props
  }: TooltipPropsMui & {
    width?: CSSProperties['width']
    maxWidth?: CSSProperties['width']
    color?: 'white' | 'red' | 'yellow'
    margin?: string | number
    marginRight?: string | number
    marginLeft?: string | number
    marginTop?: string | number
    marginBottom?: string | number
    visibility?: 'visible' | 'hidden'
  }) => <TooltipMui {...props} classes={{ popper: className }} />
)(
  ({
    theme,
    padding = '6px 8px',
    width,
    maxWidth = 335,
    margin = 8,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    visibility,
    color,
  }) => {
    const styleInfo = getStyleInfo(color)

    return {
      [`& .${tooltipClasses.tooltip}`]: {
        fontFamily: '"Inter", sans-serif',
        backgroundColor: styleInfo.backgroundColor,
        color: styleInfo.color,
        width,
        maxWidth,
        padding,
        borderRadius: '6px',
        fontSize: theme.typography.pxToRem(13),
        lineHeight: '16px',
        boxShadow: styleInfo.boxShadow,
        visibility,
      },
      [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementBottom}`]: {
        marginRight: marginRight,
        marginLeft: marginLeft,
        marginBottom: marginBottom || margin,
        marginTop: marginTop || margin,
      },
      [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementTop}`]: {
        marginRight: marginRight,
        marginLeft: marginLeft,
        marginBottom: marginBottom || margin,
        marginTop: marginTop || margin,
      },
      [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementLeft}`]: {
        marginRight: margin,
      },
      [`& div.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementRight}`]: {
        marginLeft: margin,
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: styleInfo.backgroundColor,
      },
      [`& .${tooltipClasses.arrow}:before`]: {
        borderRadius: '2px',
      },
    }
  }
)

export const Tooltip: FC<ITooltipProps> = observer(
  ({
    children,
    type = 'default',
    label,
    desc,
    icons = [],
    items = [],
    fullWidth,
    arrow = true,
    zIndex,
    isDisplayGrid,
    className,
    withoutPointer = false,
    breakWord,
    ariaLabel = '',
    ...props
  }) => {
    let component: React.ReactNode = ''

    if (type === 'default')
      component = (
        <TooltipDefault
          label={label}
          icons={icons}
          color={props.color}
          breakWord={breakWord}
          ariaLabel={ariaLabel}
        />
      )
    if (type === 'description') component = <TooltipDescription label={label} desc={desc} />
    if (type === 'shared_link') component = <TooltipSharedLink label={label} />
    if (type === 'billing') component = <TooltipBilling items={items} />
    if (type === 'filtered_items') component = <TooltipFilteredItems items={items} />

    const optionsTooltip = {
      ...props,
      arrow,
      title: component,
      sx: {
        zIndex,
        ...props.sx,
      },
      PopperProps: {
        hidden: uiStore.scrolling,
        ...props.PopperProps,
      },
    }

    if (!label && type === 'default') {
      return <>{children}</>
    }

    const tooltipAriaLabel = `Tooltip_${ariaLabel}`

    return (
      <div
        className={classnames(
          styles.wrap,
          fullWidth && styles.fullWidth,
          isDisplayGrid && styles.grid,
          withoutPointer && styles.withoutPointer,
          className
        )}
        aria-label={getAriaLabel(tooltipAriaLabel)}
      >
        <HtmlTooltip
          {...optionsTooltip}
          TransitionProps={{ timeout: uiStore.scrolling ? 0 : undefined }}
          aria-label={getAriaLabel(tooltipAriaLabel, 'content')}
        >
          <ForwardRefComponent>{children}</ForwardRefComponent>
        </HtmlTooltip>
      </div>
    )
  }
)
