import React from 'react'
import classnames from 'classnames'
import { IconButton, ITooltipProps } from 'shared/ui'
import styles from '../styles.module.scss'

type IIconUrlButtonProps = {
  value: string | number | undefined
  showTooltip?: boolean
  show?: boolean
  onClose?: () => void
  onOpen?: () => void
}

export const IconUrlButton: React.FC<IIconUrlButtonProps> = ({
  value,
  showTooltip = false,
  show = true,
  onOpen,
  onClose,
}) => {
  const handleClose = () => {
    onClose && onClose()
  }

  const handleOpen = () => {
    onOpen && onOpen()
  }

  const tooltipProps: ITooltipProps = {
    label: value,
    placement: 'top',
    disableInteractive: true,
    onClose: handleClose,
    onOpen: handleOpen,
  }

  const onOpenUrl = async () => {
    const url = new URL(`${value}`)
    window.open(url, '_blank')
  }

  return (
    <div
      className={classnames(styles.rightIcon, {
        [styles.hidden]: !show,
      })}
    >
      <IconButton
        icon={'linkExternal'}
        variant={'icon'}
        color={'secondary'}
        size={'small'}
        fontSize={16}
        onClick={onOpenUrl}
        tooltipProps={showTooltip ? tooltipProps : undefined}
        ariaLabel='IconUrlButton'
      />
    </div>
  )
}
