import classnames from 'classnames'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography, Table, IColumn, IconButton, Status } from 'shared/ui'
import { makeTextFieldClear, makeTextFieldIcon, TextField } from 'shared/ui/TextField'
import { type IIntegrationChannel } from 'entities/Integrations'
import { type IntegrationHubspotSettingsChannelsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsChannelsStore'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsChannelsProps = {
  store: IntegrationHubspotSettingsChannelsStore
}

export const IntegrationHubspotSettingsChannels: React.FC<IIntegrationHubspotSettingsChannelsProps> =
  observer(({ store }) => {
    const {
      channels,
      pagination,
      status,
      total,
      onChangePagination,
      search,
      onSearchChange,
      refreshData,
    } = store

    const COLUMNS: IColumn<IIntegrationChannel>[] = useMemo(
      () => [
        {
          field: 'chanel',
          name: 'Channel name',
          width: '50%',
          renderRowCell: ({ name }) => (
            <Typography
              variant={'body-md-regular'}
              ellipsis
              tooltipProps={{ label: name }}
              ariaLabel={'displayInfo'}
            >
              {name}
            </Typography>
          ),
        },
        {
          field: 'status',
          name: 'Status',
          renderHeaderCell: (column) => (
            <div className={styles.refreshCell}>
              <div>{column.name}</div>
              <IconButton
                className={styles.headerIcon}
                iconProps={{ fontSize: 16 }}
                variant={'icon'}
                color={'tertiary'}
                size={'small'}
                icon={status.isLoading ? 'loading3' : 'refresh'}
                ariaLabel='Refresh_data'
                onClick={refreshData}
              />
            </div>
          ),
          renderRowCell: (row) => {
            const params = row.hubspot_channel_meta.active
              ? ({
                  icon: 'check2',
                  title: 'Connected',
                  intent: 'positive',
                } as const)
              : ({
                  icon: 'exclamationCircle',
                  title: 'Disconnected',
                  intent: 'warning',
                } as const)

            return <Status emphasis='high' {...params} />
          },
        },
      ],
      [refreshData, status.isLoading]
    )

    const dataNotExist = !total && !status.isLoading && !status.isIdle

    return (
      <div className={styles.root}>
        <TextField
          size={'medium'}
          onChange={onSearchChange}
          variant={'integrated'}
          value={search}
          InputProps={{
            placeholder: 'Search...',
            className: styles.search,
          }}
          leftActions={[
            makeTextFieldIcon({
              icon: 'search',
              fontSize: 18,
              color: 'var(--content-primary-tertiary)',
              margin: '0 0 0 4px',
            }),
          ]}
          rightActions={[makeTextFieldClear()]}
        />

        <div className={classnames(styles.table, { [styles.loading]: status.isLoading })}>
          <Table
            columns={COLUMNS}
            rows={channels}
            rowsCount={total}
            startPaginationSize={10}
            initPage={pagination.page}
            limit={pagination.limit}
            onChangePagination={onChangePagination}
            noPagination={!total}
            scrollbarProps={{
              autoHeight: true,
              autoHeightMin: 160,
              autoHeightMax: 450,
              viewClassName: styles.viewScrollClassName,
            }}
            bottomTableContent={
              dataNotExist && (
                <tr>
                  <td colSpan={2}>
                    {search ? (
                      <div className={styles.emptySearch}>
                        <Typography
                          variant={'body-md-regular'}
                          ellipsis
                          ariaLabel={'empty_placeholder'}
                          color={'tertiary'}
                        >
                          No HubSpot channels
                        </Typography>
                      </div>
                    ) : (
                      <div className={styles.placeholder}>
                        <Typography
                          variant={'body-rg-medium'}
                          ariaLabel={'empty_placeholder_title'}
                        >
                          No hubspot channels
                        </Typography>
                        <Typography
                          variant={'body-md-regular'}
                          ariaLabel={'empty_placeholder_label'}
                          color={'tertiary'}
                        >
                          To enable this feature and add Salesmsg to your HubSpot Help Desk and
                          Inbox, please reconnect your HubSpot account.
                        </Typography>
                      </div>
                    )}
                  </td>
                </tr>
              )
            }
          />
        </div>
      </div>
    )
  })
