// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yvmj6UVAFDYEXMAQMD9j{margin-bottom:6px}.JUUnecNzgCTx9Sww_vi1{display:flex;align-items:center;height:32px;border-radius:6px;background:var(--background-primary-transparent);margin-bottom:2px}.u828fo5a1Zrcz0VuCd3g{padding:4px 8px;color:var(--content-primary-tertiary)}.KTh4nZlZ3h3HbmZBYbWt{position:relative;padding-left:24px}.KTh4nZlZ3h3HbmZBYbWt:after{position:absolute;top:0;left:16px;bottom:0;width:1px;background-color:var(--gray-30);content:\"\"}.eFJy96QIpD_1bTzoRs7k span{margin:0}", "",{"version":3,"sources":["webpack://./src/shared/ui/Menu/ui/MenuSub/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,gDAAA,CACA,iBAAA,CAKA,sBACE,eAAA,CACA,qCAAA,CAIJ,sBACE,iBAAA,CACA,iBAAA,CAEA,4BACE,iBAAA,CACA,KAAA,CACA,SAAA,CACA,QAAA,CACA,SAAA,CACA,+BAAA,CACA,UAAA,CAIA,2BACE,QAAA","sourcesContent":[".wrap {\n  margin-bottom: 6px;\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  height: 32px;\n  border-radius: 6px;\n  background: var(--background-primary-transparent);\n  margin-bottom: 2px;\n\n  &__content {\n  }\n\n  &__icon {\n    padding: 4px 8px;\n    color: var(--content-primary-tertiary);\n  }\n}\n\n.section {\n  position: relative;\n  padding-left: 24px;\n\n  &:after {\n    position: absolute;\n    top: 0;\n    left: 16px;\n    bottom: 0;\n    width: 1px;\n    background-color: var(--gray-30);\n    content: '';\n  }\n\n  &__item {\n    span {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Yvmj6UVAFDYEXMAQMD9j",
	"label": "JUUnecNzgCTx9Sww_vi1",
	"label__icon": "u828fo5a1Zrcz0VuCd3g",
	"section": "KTh4nZlZ3h3HbmZBYbWt",
	"section__item": "eFJy96QIpD_1bTzoRs7k"
};
export default ___CSS_LOADER_EXPORT___;
