type ISingletonRequestRequest<P> = {
  params?: P
  cache?: boolean
}

type ISingletonRequestConfig = {
  cache?: boolean
}

export class SingletonRequest<T, P> {
  private _promise: Promise<T> | null = null
  private _cache = false

  constructor(private _request: (...args: P[]) => Promise<T>, config?: ISingletonRequestConfig) {
    if (config?.cache) this._cache = config.cache
  }

  request = async (config?: ISingletonRequestRequest<P>) => {
    const params = config?.params ? [config.params] : []
    const cache = Boolean(config?.cache || this._cache)

    if (!this._promise) {
      this._promise = this._request(...params)
    }
    const res = await this._promise

    if (!cache) {
      this._promise = null
    }
    return res
  }
}
