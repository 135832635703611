import { makeAutoObservable } from 'mobx'
import { Call } from '@twilio/voice-sdk'
import { ICallParticipantContact, IResponseCallQueueParticipant } from 'entities/Call'
import { Contact } from 'entities/Contacts/model/Contact'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { Phone } from 'entities/Phone/model/Phone'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import type {
  ICallPopUpDefaultView,
  ICallPopUpPowerDialerStoreOptions,
  ICallPopUpPowerDialerStoreV2InitData,
  ICallTeamParticipant,
} from 'widgets/CallPopUp'

export class CallTeam {
  id!: number
  name!: string
  number!: string
  position!: ICallTeamParticipant['position']
  store: CallPopUpDefaultStore = new CallPopUpDefaultStore()

  private _status: Call.State = Call.State.Closed

  constructor(response: ICallTeamParticipant) {
    this.id = response.id
    this.name = response.name
    this.number = response.number
    this.position = response.position || {
      x: 0,
      y: 0,
    }

    makeAutoObservable(this)
  }

  setView = (view: ICallPopUpDefaultView) => {
    this.store.setView(view)
  }

  setContactFrom = (inbox: Inbox) => {
    this.store.setContactFrom(inbox)
  }

  setContactTo = (contact: Contact) => {
    this.store.setContactTo(contact)
  }

  setStatusCall = (status: Call.State) => {
    this.store.setStatusCall(status)
  }

  setLocalStatusCall = (status: Call.State) => {
    this._status = status
  }

  /**
   * @deprecated
   * This method is deprecated as part of the migration from PowerDialer V1 to PowerDialer V2.
   * Check setPowerDialerData instead.
   */
  addItemsPowerDialer = (items: Contact[]) => {
    this.store.callPopUpPowerDialerStore.addItems(items)
  }

  /**
   * @deprecated
   * This method is deprecated as part of the migration from PowerDialer V1 to PowerDialer V2.
   * Check setPowerDialerData instead.
   */
  addOptionsPowerDialer = (options: ICallPopUpPowerDialerStoreOptions) => {
    this.store.callPopUpPowerDialerStore.addOptions(options)
  }

  /**
   * @deprecated
   * This method is deprecated as part of the migration from PowerDialer V1 to PowerDialer V2.
   * Check setPowerDialerData instead.
   */
  setSyncCachePowerDialer = (team: Inbox, number: Phone, contacts: Contact[]) => {
    this.store.callPopUpPowerDialerStore.sessionCacheStore.setTeam(team)
    this.store.callPopUpPowerDialerStore.sessionCacheStore.setNumber(number)
    this.store.callPopUpPowerDialerStore.sessionCacheStore.setContacts(contacts)
  }

  setPowerDialerInitData = async (initData: ICallPopUpPowerDialerStoreV2InitData) => {
    await this.store.callPopUpPowerDialerStoreV2.setPowerDialerInitData(initData)
  }

  addItemQueue = (item: ICallParticipantContact) => {
    this.store.callPopUpQueueStore.addItem(item)
  }

  setItemQueue = (item: IResponseCallQueueParticipant | null) => {
    this.store.callPopUpQueueStore.setItem(item)
  }

  resetAll = () => {
    this.store.resetReactions()
    this.store.resetPopUp()
    this.store.resetCommon()
  }

  get status() {
    return this._status
  }

  get view() {
    return this.store.view || 'default'
  }

  get hasItemQueue() {
    return this.store.callPopUpQueueStore.hasItem
  }

  /**
   * @deprecated
   * This method is deprecated as part of the migration from PowerDialer V1 to PowerDialer V2.
   * Check hasItemsPowerDialerV2 instead.
   */
  get hasItemsPowerDialer() {
    return this.store.callPopUpPowerDialerStore.hasItems
  }

  get hasItemsPowerDialerV2() {
    return this.store.callPopUpPowerDialerStoreV2.hasContactsToCall
  }

  get hasItemsQueue() {
    return this.store.callPopUpQueueStore.hasItems
  }
}
