// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WXU87cd468TSbH1maKFo{cursor:pointer;padding:8px;min-height:32px;display:flex;gap:8px;align-items:center}.WXU87cd468TSbH1maKFo:hover{border-radius:6px;background:var(--background-neutral-subtle)}.laMTBK7EkcN5zOzvcrbw{width:14px;height:14px;min-width:14px;min-height:14px;border-radius:3.889px;border:1.5px solid var(--interaction-neutral-hovered);background:var(--background-primary-inverted-primary)}.hiGxOXSE5D8mW7YkQ6I2{width:14px;height:14px;min-width:14px;min-height:14px;display:flex;align-items:center;justify-content:center;border-radius:3.889px;background:var(--action-contained-primary-normal);color:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/shared/ui/InputCheckbox/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CACA,4BACE,iBAAA,CACA,2CAAA,CAIJ,sBACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CACA,qDAAA,CACA,qDAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,iDAAA,CACA,gDAAA","sourcesContent":[".wrap{\n  cursor: pointer;\n  padding: 8px;\n  min-height: 32px;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  &:hover{\n    border-radius: 6px;\n    background: var(--background-neutral-subtle);\n  }\n}\n\n.box{\n  width: 14px;\n  height: 14px;\n  min-width: 14px;\n  min-height: 14px;\n  border-radius: 3.889px;\n  border: 1.5px solid var(--interaction-neutral-hovered);\n  background: var(--background-primary-inverted-primary);\n}\n.checkboxChecked{\n  width: 14px;\n  height: 14px;\n  min-width: 14px;\n  min-height: 14px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 3.889px;\n  background: var(--action-contained-primary-normal);\n  color: var(--background-primary-inverted-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "WXU87cd468TSbH1maKFo",
	"box": "laMTBK7EkcN5zOzvcrbw",
	"checkboxChecked": "hiGxOXSE5D8mW7YkQ6I2"
};
export default ___CSS_LOADER_EXPORT___;
