import { observer } from 'mobx-react-lite'
import { FullPageWrapper } from 'shared/ui'
import { PowerDialerEmpty } from './PowerDialerEmpty/PowerDialerEmpty'

const PowerDialerList = observer(() => {
  return (
    <FullPageWrapper>
      <PowerDialerEmpty />
    </FullPageWrapper>
  )
})

export const PowerDialerListPage = () => <PowerDialerList />
