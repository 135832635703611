import { FC } from 'react'
import { Typography } from 'shared/ui'
import { SampleMessageModal } from 'pages/settings/pages/compliance/ui/SampleMessageModal/SampleMessageModal'
import styles from './styles.module.scss'

type IUseCaseExampleModalProps = {
  activeUseCase: string
  example: string
  withMustInclude?: boolean
  includesLinks?: boolean
  includesPhoneNumbers?: boolean
}

export const ExampleModalContent: FC<IUseCaseExampleModalProps> = ({
  activeUseCase,
  example,
  withMustInclude,
  includesLinks,
  includesPhoneNumbers,
}) => {
  return (
    <div className={styles.useCaseExampleModal}>
      <Typography
        ariaLabel='useCaseExampleModal'
        variant='body-md-regular'
        color='var(--content-primary-tertiary)'
      >
        Use case
      </Typography>
      <Typography
        tag='div'
        marginTop={4}
        ariaLabel='UseCaseExampleModal'
        variant='heading-lg'
        color='var(--content-primary-primary)'
      >
        {activeUseCase}
      </Typography>

      <div className={styles.useCaseExampleModalText}>
        <Typography
          ariaLabel='exampleText'
          variant='body-rg-regular'
          color='var(--content-primary-primary)'
        >
          {example}
        </Typography>
      </div>

      {withMustInclude && (
        <SampleMessageModal
          includesLinks={includesLinks}
          includesPhoneNumbers={includesPhoneNumbers}
        />
      )}
    </div>
  )
}
