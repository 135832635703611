import { AxiosResponse } from 'axios'
import type { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'

import type {
  IChatbotPayload,
  IParamsGetChatbotAnalyticList,
  IParamsGetChatbotList,
  IResponseChatbotAnalyticList,
  IResponseChatbotList,
  IChatbotFullData,
  IResponseDeleteChatbot,
  IResponseUpdateChatbotStatus,
  IParamsEnrollContactsInChatbot,
  IChatbotInstruction,
} from './chatbotTypes'

class Api {
  getChatbotList(
    params: IParamsGetChatbotList,
    options?: Omit<AxiosOptions, 'params'>
  ): Promise<AxiosResponse<IResponseChatbotList>> {
    return baseApi.get('chatbot/api/chatbots', { ...options, params })
  }

  getChatbot(id: number, options?: AxiosOptions): Promise<AxiosResponse<IChatbotFullData>> {
    return baseApi.get(`chatbot/api/chatbots/${id}`, options)
  }

  createChatbot(data: IChatbotPayload): Promise<AxiosResponse<IChatbotFullData>> {
    return baseApi.post('chatbot/api/chatbots', data)
  }

  updateChatbot(id: number, data: IChatbotPayload): Promise<AxiosResponse<IChatbotFullData>> {
    return baseApi.put(`chatbot/api/chatbots/${id}`, data)
  }

  enrollChatbot(id: number): Promise<AxiosResponse<void>> {
    return baseApi.post(`chatbot/api/chatbots/enroll/${id}`)
  }

  updateChatbotStatus(
    id: number,
    isActive: boolean
  ): Promise<AxiosResponse<IResponseUpdateChatbotStatus>> {
    return baseApi.put(`chatbot/api/chatbots/status/${id}`, { is_active: isActive })
  }

  deleteChatbot(id: number): Promise<AxiosResponse<IResponseDeleteChatbot>> {
    return baseApi.delete(`chatbot/api/chatbots/${id}`)
  }

  getChatbotAnalyticList(
    { id, ...params }: IParamsGetChatbotAnalyticList,
    options?: Omit<AxiosOptions, 'params'>
  ): Promise<AxiosResponse<IResponseChatbotAnalyticList>> {
    return baseApi.get(`chatbot/api/chatbots/analytics/list/${id}`, { ...options, params })
  }

  enrollContactsInChatbot(
    { chatbot_id, ...params }: IParamsEnrollContactsInChatbot,
    options?: Omit<AxiosOptions, 'params'>
  ) {
    return baseApi.post(`chatbot/api/chatbots/enroll-by-contact-ids/${chatbot_id}`, params, options)
  }

  getInstructions(): Promise<AxiosResponse<IChatbotInstruction[]>> {
    return baseApi.get('chatbot/api/chatbots/instruction-templates')
  }
}

export const ChatbotApi = new Api()
