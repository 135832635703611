import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { DatePickerDropdown } from 'shared/ui/DatePicker/DatePickerDropdown/DatePickerDropdown'
import { uiStore } from 'shared/store/uiStore'
import { TimePicker } from 'shared/ui/DatePicker/TimePicker/TimePicker'
import { Tooltip } from 'shared/ui'
import styles from 'shared/ui/Schedule/ui/Recurring/ui/styles.module.scss'

type IDateEndTimeProps = {
  date: Date | null
  setDate: (value: Date | null) => void
  dropdownId: string
  defaultDate: Date
}

export const DateEndTime = observer((props: IDateEndTimeProps) => {
  const { date, setDate, dropdownId, defaultDate } = props
  const [isOpenDate, setOpenDate] = useState(false)
  const [isOpenTime, setOpenTime] = useState(false)
  return (
    <>
      <Tooltip
        label={'Date'}
        placement={'left'}
        fullWidth
        visibility={isOpenDate ? 'hidden' : 'visible'}
      >
        <div>
          <DatePickerDropdown
            selected={date}
            onChange={setDate}
            parentsDropdownId={[dropdownId]}
            excludeDateIntervals={[
              { start: new Date(0), end: uiStore.dayjs().add(-1, 'day').toDate() },
            ]}
            defaultValue={defaultDate}
            onChangeOpen={setOpenDate}
          />
        </div>
      </Tooltip>
      <Tooltip
        label={'Time'}
        placement={'left'}
        fullWidth
        visibility={isOpenTime ? 'hidden' : 'visible'}
      >
        <div>
          <TimePicker
            selected={date}
            onChange={setDate}
            parentsDropdownId={[dropdownId]}
            textFieldPlaceholderProps={{ className: styles.wrapTimePicker, variant: 'outline' }}
            filteredPassedTime={uiStore.dayjs().toDate()}
            offsetMinutes={uiStore.offsetMinute}
            defaultValue={defaultDate}
            onChangeOpen={setOpenTime}
          />
        </div>
      </Tooltip>
    </>
  )
})
