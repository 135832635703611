import { makeAutoObservable } from 'mobx'
import type { IResponseShortcodeOptOut } from 'entities/Contacts/api/types'
import { usersStore } from 'entities/Users'
import { userPermissionsStore } from 'entities/UsersPermissions'

export class ContactOptIn {
  private readonly _id: number | string
  private readonly _name: string
  private _value: boolean
  private readonly _cacheValue: boolean
  private readonly _formatted_number: string
  private readonly _disabled: boolean

  constructor(item: IResponseShortcodeOptOut) {
    this._id = item.id
    this._disabled = item.disabled
    this._name = item.name
    this._value = item.value
    this._cacheValue = item.value
    this._formatted_number = item.formatted_number

    makeAutoObservable(this)
  }

  get id() {
    return this._id
  }

  get formatted_number() {
    return this._formatted_number
  }

  get name() {
    return this._name
  }

  get isCanNotManualOptIn() {
    return Boolean(
      this._id === 'local' &&
        !this._cacheValue &&
        !usersStore.user?.isOwnerRole &&
        !userPermissionsStore.getItem('can_manual_opt_in')
    )
  }

  get disableLabel() {
    if (this.isCanNotManualOptIn) {
      return 'Access disabled on organisation level'
    }

    if (this._disabled) {
      return 'Contact has unsubscribed from receiving messages from this number'
    }

    return ''
  }

  get disabled() {
    if (this.isCanNotManualOptIn) {
      return true
    }

    return this._disabled
  }

  get value() {
    return this._value
  }

  handleChangeValue = () => {
    this._value = !this._value
  }
}
