import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import { IconButton } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { useCampaignViewContext } from 'pages/campaigns/ui/CampaignView/context/context'
import { Preview } from 'pages/campaigns/ui/CampaignView/ui/Preview/Preview'
import styles from './styles.module.scss'

export const MobilePreview = observer(() => {
  const { campaignMessagesStore } = useCampaignViewContext()
  const messages = campaignMessagesStore.messages

  const modalId = nanoid()

  const onCloseModal = () => {
    modalStore.closeModal(modalId)
  }

  const onMobilePreview = () => {
    modalStore.addModal({
      id: modalId,
      type: 'right',
      contentStyle: {
        width: 380,
      },
      content: (
        <>
          <div className={styles.header}>
            <IconButton
              ariaLabel={'mobilePreviewBtn'}
              icon={'close'}
              variant={'icon'}
              color={'secondary'}
              onClick={onCloseModal}
            />
          </div>
          <div className={styles.wrapPreview}>
            <Preview orderedMessages={messages} />
          </div>
        </>
      ),
    })
  }
  return (
    <IconButton
      ariaLabel={'mobilePreviewBtn'}
      icon={'lineInfoCircle'}
      variant={'icon'}
      color={'secondary'}
      onClick={onMobilePreview}
      tooltipProps={{
        label: 'Message preview',
        placement: 'bottom-end',
      }}
    />
  )
})
