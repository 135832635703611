import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuestionnaireContext } from 'pages/settings/pages/compliance/pages/questionnaire/context/questionnaireContext'
import { StartPage } from './StartPage'
import { QuestionsContent } from './QuestionsContent'

export const QuestionnairePage = observer(() => {
  const { step, complianceLayerStore, handleSetSummary } = useQuestionnaireContext()

  useEffect(() => {
    handleSetSummary(complianceLayerStore.summary)
  }, [complianceLayerStore.summary])

  if (step === 1) return <StartPage />

  return <QuestionsContent />
})
