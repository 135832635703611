import { makeAutoObservable, runInAction } from 'mobx'
import { IParamsSegment, IResponseSegment, SegmentApi } from 'entities/Segment'
import { Segment } from 'entities/Segment/model/Segment'

export class SegmentsStore {
  private segments = new Map<number, Segment>()

  constructor() {
    makeAutoObservable(this)
  }

  get items() {
    return Array.from(this.segments.values())
  }

  hasItem = (id: number) => this.segments.has(id)

  getItem = (id: number) => this.segments.get(id)

  createItem = async (item: IParamsSegment): Promise<Segment> => {
    try {
      const { data } = await SegmentApi.createItem(item)
      const segment = new Segment(data)

      runInAction(() => {
        this.segments.set(segment.id, segment)
      })

      return segment
    } catch (error) {
      throw error
    }
  }

  updateItem = async (id: number, item: IParamsSegment) => {
    const { data } = await SegmentApi.editItem(id, item)
    const segment = this.segments.get(id) as Segment

    runInAction(() => {
      segment.syncOrigin({ ...segment.origin, ...data })
    })

    return segment
  }

  addItem = (item: IResponseSegment) => {
    const id = item.id
    const segment = this.segments.get(id)

    if (segment) {
      segment.syncOrigin({ ...segment.origin, ...item })
    } else {
      const newSegment = new Segment(item)
      this.segments.set(newSegment.id, newSegment)
    }
  }

  addItems = (items: IResponseSegment[]) => {
    items.forEach((item) => {
      this.addItem(item)
    })

    return items.reduce<Segment[]>((state, item) => {
      const segmentId = item.id
      const segment = this.getItem(segmentId)

      return segment ? [...state, segment] : [...state]
    }, [])
  }

  deleteItem = async (itemId: number) => {
    try {
      await SegmentApi.deleteItem(itemId)

      runInAction(() => {
        this.segments.delete(itemId)
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export const segmentsStore = new SegmentsStore()
