import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Avatar, Icon } from 'shared/ui'
import { formatPhoneNumberToInternational, getLabelAsNumberInternationalFormat } from 'shared/lib'
import { linkContactStore } from '../../store/linkContactStore'
import styles from './styles.module.scss'

const TargetContactCard = observer(() => {
  const { targetContact, avatarInfo } = linkContactStore

  return (
    <div className={classnames(styles.wrap, styles.noAction)}>
      <div className={styles.header}>
        <span className={styles.icon}>
          <Icon icon={'salesmsg'} fontSize={11} />
        </span>
        <span className={styles.title}>{'Salesmsg'}</span>
      </div>
      <div className={styles.content}>
        <div className={styles.avatar}>
          <Avatar size={24} info={avatarInfo} />
        </div>
        <div className={styles.user}>
          <div className={styles.nameWrap}>
            <span className={styles.name}>
              {(targetContact?.full_name &&
                getLabelAsNumberInternationalFormat(targetContact.full_name)) ||
                '-'}
            </span>
          </div>
          <span className={styles.email}>{targetContact?.email || '-'}</span>
        </div>
        <div className={styles.phonesWrapper}>
          <div className={styles.phone}>
            <span className={styles.label}>Phone</span>
            <span className={styles.value}>
              {(targetContact?.formatted_number &&
                getLabelAsNumberInternationalFormat(
                  formatPhoneNumberToInternational(targetContact?.formatted_number)
                )) ||
                '-'}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
})

export { TargetContactCard }
