import { makeAutoObservable } from 'mobx'
import { IModal } from 'shared/ui/Modal/store/types'

class ModalStore {
  constructor() {
    makeAutoObservable(this)
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        const lastModal = this.modals[this.modals.length - 1]
        if (lastModal) {
          this.escCloseModal(lastModal.id)
        }
      }
    })
  }

  modalsMap = new Map<string, IModal>()
  isHoverModal = false
  hideModalIdsSet: Set<string> = new Set()
  disabledOnCloseAllModalIds: Set<string> = new Set()
  disabledOnEscCloseModalIds: Set<string> = new Set()
  private _disabledOnEscCloseModal = false

  get modals() {
    return Array.from(this.modalsMap.values())
  }

  hasModal = (id: string) => {
    return this.modalsMap.has(id)
  }

  disabledOverlayClose = (id: string) => {
    const modal = this.modalsMap.get(id)

    return modal && 'disabledOverlayClose' in modal ? Boolean(modal.disabledOverlayClose) : false
  }

  getModal = (id: string) => {
    return this.modalsMap.get(id)
  }

  addModal = <T extends object = object>(modal: IModal<T>) => {
    if ('disabledOnAllClose' in modal && modal.disabledOnAllClose) {
      this.disabledOnCloseAllModalIds.add(modal.id)
    }

    if ('disabledOnEscClose' in modal && modal.disabledOnEscClose) {
      this.disabledOnEscCloseModalIds.add(modal.id)
    }

    this.modalsMap.set(modal.id, modal as IModal<object>)
  }

  removeModal = (id: string) => {
    if (this.disabledOnCloseAllModalIds.has(id)) {
      this.disabledOnCloseAllModalIds.delete(id)
    }
    if (this.disabledOnEscCloseModalIds.has(id)) {
      this.disabledOnEscCloseModalIds.delete(id)
    }
    this.modalsMap.delete(id)
  }

  escCloseModal = (id: string) => {
    if (this._disabledOnEscCloseModal || this.disabledOnEscCloseModalIds.has(id)) return
    this.closeModal(id)
  }

  closeModal = (id: string) => {
    const modal = this.modalsMap.get(id)
    if (modal && 'onClose' in modal && modal.onClose) {
      modal?.onClose()
    } else {
      this.removeModal(id)
    }
  }
  setIsHoverModal = (isHoverModal: typeof this.isHoverModal) => {
    this.isHoverModal = isHoverModal
  }

  setHideModalId = (id: string) => {
    this.hideModalIdsSet.add(id)
  }

  setDisabledOnEscCloseModal = (state: boolean) => {
    this._disabledOnEscCloseModal = state
  }
  removeHideModalId = (id: string) => {
    this.hideModalIdsSet.delete(id)
  }

  closeAllModals = () => {
    this.modalsMap.forEach((modal) => {
      if (!this.disabledOnCloseAllModalIds.has(modal.id)) {
        this.closeModal(modal.id)
      }
    })
  }
}

export default new ModalStore()
