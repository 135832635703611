import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { Box, FullPageWrapper, ITabItem, Tabs } from 'shared/ui'
import { broadcastStore } from 'entities/Broadcast'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { BroadcastsHeader } from 'widgets/BroacastList'
import { useBroadcastPageContext } from 'pages/broadcasts/context/broadcastsPageContext'

export const OneTimeBroadcastLayout = observer(() => {
  const { isEmptyState } = broadcastStore
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const storeBroadcastPage = useBroadcastPageContext()
  const tabs: ITabItem[] = [
    {
      name: 'SMS',
      key: BroadcastsRoutes.sms,
    },
    {
      name: 'Ringless',
      key: BroadcastsRoutes.ringless,
    },
  ]

  const handleSelectTab = (tab: ITabItem) => {
    navigate(tab.key)
  }

  const activeTabKey = pathname.split('/').pop() || ''

  return (
    <FullPageWrapper>
      <BroadcastsHeader
        title={
          featureFlagsStore.salesmsg_design_v2_new_sidebar_menu
            ? 'Broadcasts: One time'
            : 'One time'
        }
        pageLayoutStore={storeBroadcastPage.pageLayoutStore}
      />
      {!isEmptyState && (
        <Box padding={'0 20px 12px 20px'}>
          <Tabs tabs={tabs} activeTabKey={activeTabKey} handleSelectTab={handleSelectTab} />
        </Box>
      )}
      <Outlet />
    </FullPageWrapper>
  )
})
