import { useEffect } from 'react'
import { StepsCommonLayout } from 'pages/settings/pages/compliance/ui/StepsCommonLayout/StepsCommonLayout'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers/route/type'

import { useComplianceLayerContext } from 'pages/settings/pages/compliance/context/complianceLayerContext'
import {
  LocalNumbersStepsProvider,
  useLocalNumbersStepsContext,
} from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'

import { useGetStep } from 'pages/settings/pages/compliance/hooks/useGetStep'
import { useProgressInfoTitle } from '../hooks/useProgressInfoTitle'

export const LocalNumbersLayoutContent = () => {
  const {
    useCaseStore,
    allStepsLength,
    campaignId,
    complianceLayerStore,
    decreaseStep,
    closeSetting,
    getSettingsStep,
  } = useLocalNumbersStepsContext()

  const { getCampaignData } = complianceLayerStore

  useEffect(() => {
    useCaseStore.loadUseCases()
    if (!campaignId) {
      getCampaignData()
    }
  }, [])

  const settingsStep = useGetStep<LocalNumbersRoutes>(getSettingsStep)

  return (
    <StepsCommonLayout
      progressTitle={useProgressInfoTitle()}
      totalSteps={allStepsLength}
      title='A2P 10DLC registration'
      settingsStep={settingsStep}
      closeSetting={closeSetting}
      decreaseStep={(lastPathSegment) => decreaseStep(lastPathSegment as LocalNumbersRoutes)}
    />
  )
}

export const LocalNumbersStepsLayout = () => {
  const complianceLayerStore = useComplianceLayerContext()

  return (
    <LocalNumbersStepsProvider complianceLayerStore={complianceLayerStore}>
      <LocalNumbersLayoutContent />
    </LocalNumbersStepsProvider>
  )
}
