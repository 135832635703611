import { nanoid } from 'nanoid'
import { toastStore } from 'shared/ui'
import { downloadBlob } from 'shared/lib'

interface DownloadWithToastCallbacks {
  onSuccessLogEvent?: () => void
  onErrorLogEvent?: (error: unknown) => void
}

interface GetDataResult {
  blob: Blob
  name: string
}

export async function downloadWithToast(
  getData: () => Promise<GetDataResult>,
  retryFunction?: () => void,
  callbacks?: DownloadWithToastCallbacks
) {
  const fetchId = nanoid()

  try {
    toastStore.add({
      id: fetchId,
      type: 'loading',
      title: 'Preparing download',
      noTimer: true,
      noCloseButton: true,
    })

    const { blob, name } = await getData()
    downloadBlob(blob, name)

    toastStore.remove(fetchId)
    toastStore.add({
      type: 'success',
      title: 'Download ready',
    })

    callbacks?.onSuccessLogEvent?.()
  } catch (error) {
    const retryId = nanoid()
    toastStore.remove(fetchId)
    toastStore.add({
      id: retryId,
      type: 'error',
      title: 'Failed to download',
      action: retryFunction
        ? {
            text: 'Retry',
            onAction: () => {
              toastStore.remove(retryId)
              retryFunction()
            },
          }
        : undefined,
    })

    callbacks?.onErrorLogEvent?.(error)
    console.error(error)
  }
}
