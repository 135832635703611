import { makeAutoObservable } from 'mobx'
import { Segment } from 'entities/Segment/model/Segment'
import type { IResponseFilterSegment, IResponseSegment } from 'entities/Segment'
import {
  groupsToJson,
  segmentFiltersToJson,
  groupsToSegmentFilters,
} from 'widgets/FilterEditor/integration'
import { FilterGroup } from 'widgets/FilterEditor/model/FilterGroup'
import type { FiltersConfig } from 'widgets/FilterEditor/model/FiltersConfig'
import type { FilterItem } from 'widgets/FilterEditor/model/FilterItem'

export class FilterGroups {
  private _groups = new Array<FilterGroup>()
  private _spawnedFilter: FilterItem | null = null

  constructor(private _config: FiltersConfig) {
    makeAutoObservable(this)
  }

  get groups() {
    return this._groups
  }

  get filters() {
    return this._groups.flatMap((group) => group.filters)
  }

  get firstGroup(): FilterGroup | null {
    return this._groups[0] ?? null
  }

  get lastGroup(): FilterGroup | null {
    return this._groups[this._groups.length - 1] ?? null
  }

  get spawnedFilter() {
    return this._spawnedFilter
  }

  get count() {
    return this._groups.reduce((total, group) => total + group.count, 0)
  }

  get countWithValue() {
    return this._groups.reduce((total, group) => total + group.countWithValue, 0)
  }

  get countWithOutValue() {
    return this._groups.reduce((total, group) => total + group.countWithOutValue, 0)
  }

  get isEmpty() {
    return this._groups.length === 0
  }

  get isReady() {
    return !this._groups.flatMap((group) => group.filters).some(({ isEmpty }) => isEmpty)
  }

  setSpawnedFilter(item: FilterItem) {
    this._spawnedFilter = item
  }

  clearSpawnedFilter() {
    this._spawnedFilter = null
  }

  createGroup() {
    const group = new FilterGroup(this, this._config)

    this._groups.push(group)

    return group
  }

  removeGroup(group: FilterGroup) {
    this._groups = this._groups.filter((current) => current !== group)
  }

  clear() {
    this._groups = []
  }

  toSegmentFilters() {
    return groupsToSegmentFilters(this.groups)
  }

  toSegment(props: Partial<Pick<IResponseSegment, 'id' | 'organization_id' | 'name'>> = {}) {
    return new Segment({
      id: props.id ?? -1,
      organization_id: props.organization_id ?? -1,
      name: props.name ?? '',
      filters: groupsToSegmentFilters(this.groups),
    })
  }

  toJson() {
    return groupsToJson(this)
  }

  populateJson(data: Record<string, unknown>[][]) {
    data.map((item) => this.createGroup().populateJson(item))
  }

  populateSegmentFilters(filters: IResponseFilterSegment[]) {
    const json = segmentFiltersToJson(filters)

    json.map((item) => this.createGroup().populateJson(item))
  }

  populateSegment(segment: Segment) {
    const json = segmentFiltersToJson(segment.filtersList)

    json.map((item) => this.createGroup().populateJson(item))
  }
}
