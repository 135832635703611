import { FC } from 'react'
import { NewCustomVoiceContext, NewCustomVoiceStore } from 'widgets/NewCustomVoiceModal/index'
import { NewCustomVoice } from 'widgets/NewCustomVoiceModal/ui/NewCustomVoice'

type IModalContentProps = {
  store: NewCustomVoiceStore
}

export const ModalContent: FC<IModalContentProps> = ({ store }) => {
  return (
    <NewCustomVoiceContext.Provider value={store}>
      <NewCustomVoice />
    </NewCustomVoiceContext.Provider>
  )
}
