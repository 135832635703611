import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useMenuAIAutomationLayoutContext } from 'widgets/Menu/ui/MenuAIAutomation'
import { KeywordPageStore } from 'pages/keywords'
import { KeywordsPageContext } from '../context/keywordsPageContext'

const items: IMenuItemProps[] = [
  {
    key: 'all_keywords',
    type: 'nav',
    to: '/keywords',
    label: 'All keywords',
    icon: 'keywords',
  },
]

export const KeywordsPageLayout = observer(() => {
  const menuLayoutStore = useMenuAIAutomationLayoutContext()
  const [keywordsStore] = useState(() => new KeywordPageStore(menuLayoutStore.pageLayoutStore))

  if (featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return (
      <KeywordsPageContext.Provider value={keywordsStore}>
        <Outlet />
      </KeywordsPageContext.Provider>
    )
  }

  return (
    <KeywordsPageContext.Provider value={keywordsStore}>
      <PageLayout
        leftContent={<Menu title='Keywords' menuItems={items} bottomNode={<TrialProgress />} />}
        store={keywordsStore.pageLayoutStore}
      />
    </KeywordsPageContext.Provider>
  )
})
