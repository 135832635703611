import { runInAction } from 'mobx'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { ICallPeopleStatus, IResponseEventConferenceParticipant } from 'entities/Call'
import type { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'

const ConferenceParticipantJoin = {
  handle: (data: IResponseEventConferenceParticipant, store: CallPopUpDefaultStore) => {
    runInAction(() => {
      const item = store.getParticipant(data.participant_label)

      if (item) {
        const status = data.participant_call_status as ICallPeopleStatus

        item.updateStatus(status)
      }
    })
  },
}

export const EventConferenceParticipantJoin = {
  subscribe: (store: CallPopUpDefaultStore) =>
    websocket.on(
      WebsocketEvent.ConferenceParticipantJoin,
      (data: IResponseEventConferenceParticipant) => {
        ConferenceParticipantJoin.handle(data, store)
      }
    ),
}
