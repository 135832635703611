import React from 'react'
import { Icon, Typography } from 'shared/ui'

export const RecentlySentItem = ({
  btnClass,
  onOpenRecent,
}: {
  btnClass: string
  onOpenRecent: () => void
}) => {
  return (
    <button className={btnClass} onClick={onOpenRecent}>
      <Icon icon={'clockRecent'} fontSize={16} tertiary />
      <Typography variant={'body-md-medium'} ariaLabel={'RecentlySent'}>
        Recently sent
      </Typography>
      <Icon icon={'chevronRight'} fontSize={16} tertiary />
    </button>
  )
}
