import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader, Typography } from 'shared/ui'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { useSavedRepliesContext } from 'widgets/SavedReplies/context'
import { SavedRepliesList } from './SavedRepliesList/SavedRepliesList'
import { SavedRepliesOrder } from './SavedRepliesOrder/SavedRepliesOrder'
import styles from './styles.module.scss'

export const LeftContent = observer(() => {
  const {
    search,
    loading,
    setLeftContentElement,
    favoriteListStore,
    favoriteSavedReplies,
    unfavoriteListStore,
    unfavoriteSavedReplies,
    hasFavorites,
    hasUnfavorites,
    selectedSavedReplyRef,
    useScrollToElement,
    hasSavedReplies,
  } = useSavedRepliesContext()

  const ref = useRef<HTMLDivElement>(null)
  const loadMoreFavoriteRef = useRef<HTMLDivElement>(null)
  const loadMoreUnfavoriteRef = useRef<HTMLDivElement>(null)

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries

    const type = entry.target.getAttribute('data-list-type')

    if (favoriteListStore.hasMore && entry.isIntersecting && type === 'favorite') {
      favoriteListStore.loadMore()
    }

    if (unfavoriteListStore.hasMore && entry.isIntersecting && type === 'unfavorite') {
      unfavoriteListStore.loadMore()
    }
  }

  useEffect(() => {
    if (ref.current) {
      setLeftContentElement(ref.current)
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, { threshold: 0.7 })

    if (loadMoreFavoriteRef.current) {
      observer.observe(loadMoreFavoriteRef.current)
    }

    if (loadMoreUnfavoriteRef.current) {
      observer.observe(loadMoreUnfavoriteRef.current)
    }

    return () => {
      if (loadMoreFavoriteRef.current) {
        observer.unobserve(loadMoreFavoriteRef.current)
      }

      if (loadMoreUnfavoriteRef.current) {
        observer.unobserve(loadMoreUnfavoriteRef.current)
      }
    }
  }, [favoriteListStore.loading, unfavoriteListStore.loading])

  useEffect(() => {
    if (selectedSavedReplyRef?.current && useScrollToElement) {
      selectedSavedReplyRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [selectedSavedReplyRef])

  return (
    <div ref={ref} className={styles.left}>
      {hasSavedReplies && <SavedRepliesOrder />}

      {((!hasSavedReplies && loading) || allMergeFieldsStore.loading) && (
        <div className={styles.empty}>
          <SpinnerLoader size={24} />
        </div>
      )}

      {!hasSavedReplies && !loading && (
        <div className={styles.empty}>
          <Typography
            className={styles.title}
            variant={'body-md-medium'}
            color={'var(--content-primary-tertiary)'}
            ariaLabel={'No results found'}
          >
            {search ? 'No results found' : 'No saved replies'}
          </Typography>
        </div>
      )}

      {hasSavedReplies && !allMergeFieldsStore.loading && (
        <div className={styles.wrapList}>
          {hasFavorites && <SavedRepliesList list={favoriteSavedReplies} title='Favorites' />}
          {!favoriteListStore.loading && hasFavorites && (
            <div data-list-type='favorite' ref={loadMoreFavoriteRef}></div>
          )}
          {hasUnfavorites && (
            <SavedRepliesList
              list={unfavoriteSavedReplies}
              title={hasFavorites ? 'Other replies' : undefined}
            />
          )}
          {!unfavoriteListStore.loading && hasUnfavorites && (
            <div data-list-type='unfavorite' ref={loadMoreFavoriteRef}></div>
          )}
        </div>
      )}
    </div>
  )
})
