import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Box, InputCheckbox, TextFieldWithLabel, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { SelectFieldMultiList } from 'shared/ui/Select/SelectMultiListField/SelectMultiListField'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { ErrorBanner } from 'pages/settings/pages/compliance/pages/businessProfile/ui/ErrorBanner/ErrorBanner'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import styles from './styles.module.scss'

export const BusinessInfo = observer(() => {
  const { businessInfoStore, fieldsError, stepLoading } = useBusinessProfileStepsContext()
  const {
    businessTypes,
    activeBusinessTypeId,
    businessIndustries,
    activeBusinessIndustryId,
    websiteUrl,
    socialMediaUrl,
    noWebsiteUrl,
    websiteUrlLocalError,
    socialMediaUrlError,
    activeRegionsOfOperation,
    regionsOfOperation,
    isNextDisabled,
    isCorrectUrl,
    nextStepClick,
    deleteRegionsOfOperationId,
    setBusinessType,
    setBusinessIndustry,
    loadBusinessTypes,
    loadBusinessIndustries,
    setWebsiteUrl,
    setSocialMediaUrl,
    handleSetNoWebsiteUrl,
    checkUrl,
    setRegionsOfOperationId,
    loadBusinessProfileRegionsOfOperation,
  } = businessInfoStore

  useEffect(() => {
    loadBusinessTypes()
    loadBusinessIndustries()
    loadBusinessProfileRegionsOfOperation()
  }, [])

  return (
    <div className={styles.wrap}>
      <PageDescription>Enter your business details for carrier verification</PageDescription>

      <ErrorBanner />

      <Box
        flex={1}
        display='flex'
        justifyContent={'space-between'}
        gap='12px'
        marginBottom={3}
        marginTop={5}
      >
        <SelectField
          boxWidth='100%'
          labelProps={{
            label: {
              text: 'Business type',
            },
            questionTooltipProps: { label: 'Please select your business entity type', width: 140 },
          }}
          items={businessTypes}
          value={activeBusinessTypeId}
          setValue={setBusinessType}
          btnProps={{
            size: 'large',
            className: styles.businessSelect,
          }}
          dropdownProps={{ width: 'unset' }}
        />
        <SelectField
          boxWidth='100%'
          labelProps={{
            label: {
              text: 'Business industry',
            },
          }}
          items={businessIndustries}
          value={activeBusinessIndustryId}
          setValue={setBusinessIndustry}
          btnProps={{
            size: 'large',
            className: styles.businessSelect,
          }}
          dropdownProps={{ width: 'unset' }}
        />
      </Box>

      <TextFieldWithLabel
        marginBottom={0}
        errorBorder={Boolean(fieldsError?.website_url)}
        errorMessage={websiteUrlLocalError}
        labelProps={{
          marginBottom: 4,
          questionTooltipProps: {
            label: 'You need a website to register',
            desc: 'If you don’t have an official website and are using a social media profile page, click “I don’t have a website”.',
            type: 'description',
            width: 240,
          },
          label: {
            text: 'Business website URL',
          },
        }}
        textFieldProps={{
          disabled: noWebsiteUrl,
          value: websiteUrl || '',
          error: !!websiteUrlLocalError,
          onChange: setWebsiteUrl,
          onBlur: (value: string) => checkUrl(value, 'websiteUrl'),
          InputProps: {
            placeholder: 'e.g. https://www.example.com/',
          },
          className: styles.selectPadding,
        }}
        rightActions={[
          makeTextFieldIcon({
            icon: isCorrectUrl ? 'check2' : undefined,
            fontSize: 16,
            color: 'var(--green-60)',
          }),
        ]}
      />

      <InputCheckbox
        checked={noWebsiteUrl}
        onChecked={handleSetNoWebsiteUrl}
        customLabel={
          <Typography
            ariaLabel='Business_info_email_label'
            variant='text-input-field-r'
            color='var(--content-primary-tertiary)'
          >
            {"I don't have a business website"}
          </Typography>
        }
        className={styles.noEmailCheckbox}
      />

      {noWebsiteUrl && (
        <TextFieldWithLabel
          marginBottom={3}
          errorBorder={Boolean(fieldsError?.social_media_url)}
          errorMessage={socialMediaUrlError}
          labelProps={{
            marginBottom: 8,
            questionTooltipProps: {
              label: "Enter the URL of your company's social media profile page",
              desc: '(e.g. Facebook, Twitter, Linkedin etc)',
              type: 'description',
              width: 226,
            },
            label: {
              text: 'Social media profile',
            },
          }}
          textFieldProps={{
            value: socialMediaUrl || '',
            error: !!socialMediaUrlError,
            onChange: setSocialMediaUrl,
            onBlur: (value: string) => checkUrl(value, 'socialMediaUrl'),
            InputProps: {
              placeholder: 'e.g. https://www.facebook.com/example/',
            },
            className: styles.selectPadding,
          }}
        />
      )}

      <SelectFieldMultiList
        labelText='Regions of Operation'
        labelProps={{
          label: {
            text: 'Business regions of operations',
          },
          questionTooltipProps: {
            label: 'Where do you do business? ',
            type: 'description',
            width: 241,
            desc: 'If you have customers all around the world, go ahead and select all options. Otherwise, just select the ones where your customers are primarily located. ',
          },
        }}
        dropdownProps={{
          textFieldProps: {
            className: styles.selectRegionsPadding,
          },
        }}
        items={regionsOfOperation}
        selectedItems={activeRegionsOfOperation}
        onDelete={({ id }) => deleteRegionsOfOperationId(id as number)}
        onSelect={({ id }) => setRegionsOfOperationId(id as number)}
        fullWidth
      />

      <NextButton
        loading={stepLoading}
        disabled={isNextDisabled || stepLoading}
        text='Next'
        onClick={nextStepClick}
      />
    </div>
  )
})
