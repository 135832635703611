import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { FiltersAndSearch } from 'widgets/FiltersAndSearch'
import { KeywordsTable } from 'pages/keywords/pages/all/ui'
import { useKeywordsListContext } from 'pages/keywords/context'
import { EmptyStateKeywords } from 'pages/keywords/pages/all/ui/EmptyStateKeywords/EmptyStateKeywords'

import styles from './styles.module.scss'

export const KeywordsListContent = observer(() => {
  const [parent, setParent] = useState<HTMLDivElement | null>(null)
  const { filtersAndSearchStore, isKeywordsEmpty, isInitialLoading, loadKeywords } =
    useKeywordsListContext()

  useEffect(() => {
    void loadKeywords()
  }, [])

  if (isInitialLoading) {
    return <SpinnerLoader />
  }

  if (isKeywordsEmpty) {
    return <EmptyStateKeywords />
  }

  return (
    <div ref={setParent} className={styles.keywordsListContentContainer}>
      <FiltersAndSearch store={filtersAndSearchStore} parent={parent} />
      <KeywordsTable />
    </div>
  )
})
