import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { isLink } from 'shared/lib'
import { links } from 'shared/constants/links'
import { makeTextFieldAutoFocus, makeTextFieldHighlightLimit, TextField } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { ACTIVECAMPAIGN_API_CREDENTIALS_LIMIT } from 'shared/constants/limits'
import { PresetContainer, PresetError, PresetErrorContainer, PresetFooter } from 'shared/Preset'
import { Warning } from 'shared/ui/Warning'
import styles from './styles.module.scss'

type IActiveCampaignConnectModalContentProps = {
  getIsProcessing: () => boolean
  onActiveCampaignConnect: (apiUrl: string, apiKey: string) => Promise<void>
  onActiveCampaignModalClose: () => void
}

export const ActiveCampaignConnectModalContent = observer(
  ({
    getIsProcessing,
    onActiveCampaignConnect,
    onActiveCampaignModalClose,
  }: IActiveCampaignConnectModalContentProps) => {
    const isProcessing = getIsProcessing()
    const [url, setUrl] = useState('')
    const [urlLimitReached, setUrlLimitReached] = useState(false)
    const [urlError, setUrlError] = useState('')
    const [urlFocused, setUrlFocused] = useState(false)

    const [key, setKey] = useState('')
    const [keyLimitReached, setKeyLimitReached] = useState(false)
    const [keyError, setKeyError] = useState('')
    const [keyFocused, setKeyFocused] = useState(false)

    const handleUrlFocus = () => {
      setUrlFocused(true)
      setUrlError('')
    }
    const handleUrlBlur = () => {
      setUrlFocused(false)
    }
    const validateUrl = () => {
      const trimmedUrl = url.trim()

      if (!trimmedUrl.length) {
        setUrlError('API URL is required')
        return false
      }
      if (!isLink(trimmedUrl)) {
        setUrlError('Valid URL to your Activecampaign account is required')
        return false
      }
      setUrlError('')
      return true
    }

    const handleKeyFocus = () => {
      setKeyFocused(true)
      setKeyError('')
    }
    const handleKeyBlur = () => {
      setKeyFocused(false)
    }
    const validateKey = () => {
      const trimmedKey = key.trim()

      if (!trimmedKey.length) {
        setUrlError('API KEY is required')
        return false
      }
      setKeyError('')
      return true
    }

    const handleConnectClick = () => {
      const isValidUrl = validateUrl()
      const isValidKey = validateKey()

      if (isValidUrl && isValidKey) onActiveCampaignConnect(url, key)
    }

    return (
      <div>
        <Alert
          item={{
            type: 'infoBlue',
            title:
              'In order to connect to your ActiveCampaign organization, we will need a few things from you.',
            desc: 'These can be found within your ActiveCampaign settings.',
          }}
          marginInline={24}
          marginBottom={16}
          actions={
            <Button
              tag='a'
              typeBtn={'action'}
              size={'small'}
              contained={'primary'}
              text={'Learn more'}
              href={links.activecampaignGuide}
              target='_blank'
              rel='noreferrer'
              className={styles.learnMore}
            />
          }
        />
        <PresetContainer>
          <div>
            <div className={styles.field}>
              <Label
                label={{
                  text: 'API URL',
                  rightText: urlFocused
                    ? `${url.length} of ${ACTIVECAMPAIGN_API_CREDENTIALS_LIMIT}`
                    : null,
                }}
                marginBottom={4}
              />
              <TextField
                ariaLabel='ApiUrlModalInput'
                size='medium'
                variant='stroke'
                InputProps={{ placeholder: 'Enter url' }}
                value={url}
                onChange={setUrl}
                onFocus={handleUrlFocus}
                onBlur={handleUrlBlur}
                mainActions={[
                  makeTextFieldAutoFocus({ withFocus: true }),
                  makeTextFieldHighlightLimit({
                    limit: ACTIVECAMPAIGN_API_CREDENTIALS_LIMIT,
                    onChange: setUrlLimitReached,
                  }),
                ]}
              />
              <PresetErrorContainer>
                {urlLimitReached && (
                  <Warning
                    text={`${ACTIVECAMPAIGN_API_CREDENTIALS_LIMIT}-character limit reached`}
                  />
                )}
                {!!urlError && <PresetError reason={urlError} />}
              </PresetErrorContainer>
            </div>
            <div className={styles.field}>
              <Label
                label={{
                  text: 'API KEY',
                  rightText: keyFocused
                    ? `${key.length} of ${ACTIVECAMPAIGN_API_CREDENTIALS_LIMIT}`
                    : null,
                }}
                marginBottom={4}
              />
              <TextField
                ariaLabel='ApiKeyModalInput'
                size='medium'
                variant='stroke'
                InputProps={{ placeholder: 'Enter key' }}
                value={key}
                onChange={setKey}
                onFocus={handleKeyFocus}
                onBlur={handleKeyBlur}
                mainActions={[
                  makeTextFieldHighlightLimit({
                    limit: ACTIVECAMPAIGN_API_CREDENTIALS_LIMIT,
                    onChange: setKeyLimitReached,
                  }),
                ]}
              />
              <PresetErrorContainer>
                {keyLimitReached && (
                  <Warning
                    text={`${ACTIVECAMPAIGN_API_CREDENTIALS_LIMIT}-character limit reached`}
                  />
                )}
                {!!keyError && <PresetError reason={keyError} />}
              </PresetErrorContainer>
            </div>
          </div>
          <PresetFooter>
            <Button
              text='Cancel'
              size='medium'
              contained='secondary'
              onClick={onActiveCampaignModalClose}
            />
            <Button
              text='Connect'
              size='medium'
              disabled={!url.length || !key.length || isProcessing}
              loading={isProcessing}
              onClick={handleConnectClick}
            />
          </PresetFooter>
        </PresetContainer>
      </div>
    )
  }
)
