import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import type { ReactNode } from 'react'
import { type Blocker } from 'react-router'
import { isEqual } from 'lodash'
import { AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import { toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { TagsControl } from 'entities/Tags'
import { integrationsStore } from 'entities/Integrations'
import { KeywordsApi } from 'entities/Keywords/api/keywords'
import type { IParamsKeyword, IResponseKeyword } from 'entities/Keywords/api/type'
import { SendFromDropdownStore } from 'widgets/SendFromDropdown'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { EnumModeKeyword, type IKeywordCreateField, IKeywordCreateFieldEnum } from './type'
import { KeywordSMSStore } from './keywordSMSStore'

export class KeywordViewStore {
  private _isLoading = true
  private _isSaving = false
  private _name = ''
  private _title = ''
  private _errorsMap = new Map<IKeywordCreateField, ReactNode>()
  private _isDragging = false
  private _mergeFieldsProps = {
    isAllConnectedOrDisconnectedIntegration: false,
    isSelectDefault: true,
    useAllMergeFields: true,
  }
  private _cacheParams: IParamsKeyword | null = null
  private _modalKeepEditing = nanoid()
  private _disposeIsAircallReaction: IReactionDisposer | null = null
  private _mode: EnumModeKeyword = EnumModeKeyword.create
  private _editKeyword: IResponseKeyword | null = null

  private _keywordSMSStore = new KeywordSMSStore({
    onFocus: () => {
      this.removeError('message')
    },
    placeholder: 'Write your auto-reply message...',
    mergeFieldsProps: this._mergeFieldsProps,
  })

  private _textInNumbersStore = new SendFromDropdownStore({
    clearError: () => {
      this.removeError('numbers')
    },
    variant: 'multiselect',
  })

  private _tagsControlStore = new TagsControl()

  constructor(keywordResponse?: IResponseKeyword, isDuplicate?: boolean) {
    makeAutoObservable(this)
    void this.init(keywordResponse, isDuplicate)
  }

  init = async (keywordResponse?: IResponseKeyword, isDuplicate?: boolean) => {
    runInAction(() => {
      this._isLoading = true
    })

    await Promise.all([integrationsStore.fetchIntegrations(), allMergeFieldsStore.loadAllFields()])

    if (!keywordResponse) {
      void this._textInNumbersStore.init()
    }

    if (keywordResponse) {
      if (isDuplicate) {
        runInAction(() => {
          this._title = 'Copy of: ' + keywordResponse.title
        })
      } else {
        runInAction(() => {
          this._editKeyword = keywordResponse
          this._mode = EnumModeKeyword.edit
          this._title = keywordResponse.title
        })
      }

      runInAction(() => {
        this._name = keywordResponse.name
      })

      if (keywordResponse.tags?.length) {
        this._tagsControlStore.init(keywordResponse.tags)
      }
      this._keywordSMSStore.init(keywordResponse)
      void this._textInNumbersStore.init(null, keywordResponse.numbers)
    }

    if (!isDuplicate) {
      runInAction(() => {
        this._cacheParams = this.keywordParams
      })
    }

    this.reactionIsAircall()

    runInAction(() => {
      this._isLoading = false
    })
  }

  get isLoading() {
    return this._isLoading
  }

  get name() {
    return this._name
  }

  get title() {
    return this._title
  }

  get keywordSMSStore() {
    return this._keywordSMSStore
  }

  get textInNumbersStore() {
    return this._textInNumbersStore
  }

  get isDraggingCondition() {
    return this._isDragging
  }

  get tagsControlStore() {
    return this._tagsControlStore
  }

  get errorsMap() {
    return this._errorsMap
  }

  get isSaving() {
    return this._isSaving
  }

  get keywordParams(): IParamsKeyword {
    return {
      id: this.isEditMode && this._editKeyword ? this._editKeyword.id : null,
      name: this.name,
      title: this.title,
      is_active: this.isEditMode && this._editKeyword ? this._editKeyword?.is_active : false,
      numbers: this.textInNumbersStore.multiDropdownStore.multiSelectParams,
      tags: this.tagsControlStore.tags.map((tag) => tag.id),
      ...this.keywordSMSStore.messageDataParams,
    }
  }

  get isExistChanges() {
    return !isEqual(this._cacheParams, this.keywordParams)
  }

  get isEditMode() {
    return this._mode === EnumModeKeyword.edit
  }

  setName = (name: string) => {
    this._name = name
  }

  setTitle = (title: string) => {
    this._title = title
  }

  setError = (key: IKeywordCreateField, error: ReactNode) => {
    if (key === 'numbers') {
      this.textInNumbersStore.setError(error)
    }
    this._errorsMap.set(key, error)
  }

  removeError = (key: IKeywordCreateField) => {
    if (key === 'numbers') {
      this.textInNumbersStore.setError(null)
    }
    this._errorsMap.delete(key)
  }

  setDraggingCondition = (condition: boolean) => {
    this._isDragging = condition
  }

  clearErrors = () => {
    this.textInNumbersStore.clearError?.()
    this.errorsMap.clear()
  }

  private saveKeyword = async (keywordParams: IParamsKeyword): Promise<boolean> => {
    const cacheParams = this._cacheParams
    try {
      runInAction(() => {
        this._isSaving = true
        this._cacheParams = keywordParams
      })

      if (this.isEditMode) {
        await KeywordsApi.updateKeyword(keywordParams)
      } else {
        await KeywordsApi.createKeyword(keywordParams)
      }

      toastStore.add({
        type: 'success',
        title: this.isEditMode ? 'Keyword saved' : 'Keyword created',
      })
      return true
    } catch (e) {
      if (e instanceof AxiosError) {
        const error = e.response?.data?.error

        if (error) {
          toastStore.add({
            type: 'error',
            title: Array.isArray(error) ? error[0] : error,
          })
        } else {
          Object.entries(e.response?.data).map(([key, value]) => {
            if (key in IKeywordCreateFieldEnum) {
              this.setError(key as IKeywordCreateFieldEnum, Array.isArray(value) ? value[0] : value)
            } else {
              toastStore.add({
                type: 'error',
                title: Array.isArray(value) ? value[0] : value,
              })
            }
          })
        }
      }
      this._cacheParams = cacheParams
      console.log(e)
      return false
    } finally {
      runInAction(() => {
        this._isSaving = false
      })
    }
  }

  handleSaveKeyword = async () => {
    this.clearErrors()
    this.checkValidate()
    if (this.errorsMap.size || this.keywordSMSStore.isError) {
      return
    }

    const isSaveSuccessful = await this.saveKeyword(this.keywordParams)
    if (isSaveSuccessful) {
      uiStore.changeRoute({ path: '/keywords' })
    }
  }

  checkValidate = () => {
    const { name, title, numbers } = this.keywordParams
    const lettersNumsSomeSymbolsRegex = /^[A-Za-z0-9_#-]+$/

    if (!name.trim().length) {
      this.setError('name', 'Please enter keyword name')
    }

    if (!lettersNumsSomeSymbolsRegex.test(name)) {
      this.setError(
        'name',
        'Invalid keyword name. Only letters, numbers, some special symbols (_, #, - ) are allowed'
      )
    }

    if (!title.trim().length) {
      this.setError('title', 'Please enter internal description')
    }

    if (!numbers.length) {
      this.setError('numbers', 'Please select the phone number(s)')
    }
  }

  handleShowKeepEditingModal = (blocker: Blocker) => {
    if (blocker.state !== 'blocked') return

    modalStore.addModal({
      id: this._modalKeepEditing,
      type: ModalTypeList.WARNING,
      title: 'Leaving will discard your content',
      primaryAction: {
        text: 'Keep content',
        onAction: () => {
          modalStore.closeModal(this._modalKeepEditing)
        },
      },
      secondaryAction: {
        text: 'Discard',
        onAction: () => {
          const path = blocker.location.pathname
          blocker.proceed()

          uiStore.setRoutesBlocker(null)
          uiStore.changeRoute({
            path,
            type: 'vue',
            method: 'replace',
          })
          modalStore.closeModal(this._modalKeepEditing)
        },
      },
    })
  }

  reset = () => {
    this._disposeIsAircallReaction?.()
  }

  reactionIsAircall = () => {
    this._disposeIsAircallReaction?.()
    this._disposeIsAircallReaction = reaction(
      () => this._textInNumbersStore.multiDropdownStore.isAircall,
      (isAircall) => {
        this._keywordSMSStore.messageFieldStore.setIsCurrentAirCall(isAircall)
      }
    )
  }
}
