import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Switch } from 'shared/ui/Switch'
import { getAriaLabel } from 'shared/lib'

import styles from './styles.module.scss'

export interface ISwitchActionProps {
  active: boolean
  onTrigger: () => Promise<void>
  ariaLabel?: string
  disabled?: boolean
}

export const SwitchAction = ({
  active,
  onTrigger,
  disabled,
  ariaLabel = '',
}: ISwitchActionProps) => {
  const [updating, setUpdating] = useState(false)
  const switchAriaLabel = `SwitchAction${ariaLabel}`

  return (
    <div className={styles.action}>
      <div
        className={styles.actionContent}
        onClick={(event) => {
          if (disabled) return
          event.preventDefault()
          event.stopPropagation()

          if (updating) return

          setUpdating(true)
          onTrigger().finally(() => setUpdating(false))
        }}
        aria-label={getAriaLabel(switchAriaLabel, `${active ? 'Active' : 'Disabled'}`)}
      >
        <div className={styles.actionControl}>
          {updating ? (
            <CircularProgress size={12} />
          ) : (
            <Switch
              disabled={disabled || false}
              className={styles.actionSwitch}
              size='small'
              value={active}
            />
          )}
        </div>
        {active ? 'Active' : 'Disabled'}
      </div>
    </div>
  )
}
