import { makeAutoObservable, runInAction } from 'mobx'
import { OrganizationApi } from 'entities/Organization/api/organization'
import type { IResponseOrganization } from 'entities/Organization/api/types'

class MultiOrganizationStore {
  private _loading = false
  private _multiOrganizationsMap: Map<number, IResponseOrganization> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  fetchMultiOrganizations = async () => {
    try {
      this._loading = true
      const { data } = await OrganizationApi.getMultiOrganizationsList()

      runInAction(() => {
        this.setData(data.data)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  get multiOrganizations() {
    return Array.from(this._multiOrganizationsMap.values())
  }

  setData = (data: IResponseOrganization[]) => {
    this.reset()
    this.setItems(data)
  }

  setItems = (items: IResponseOrganization[]) => {
    items.forEach((item) => this.setItem(item))
  }

  setItem = (item: IResponseOrganization) => {
    this._multiOrganizationsMap.set(item.id, item)
  }

  reset = () => {
    this._multiOrganizationsMap.clear()
  }
}

export const multiOrganizationStore = new MultiOrganizationStore()
