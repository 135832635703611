import { createContext, useContext } from 'react'
import { CampaignViewStore } from 'pages/campaigns/ui/CampaignView/store/CampaignViewStore'

export const CampaignViewContext = createContext<CampaignViewStore | null>(null)

export const useCampaignViewContext = () => {
  const context = useContext(CampaignViewContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignViewContext')

  return context
}
