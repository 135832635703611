// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PujR1ZIvtSGS9G_6elb2{display:flex;align-items:center;gap:12px;padding:12px 0}.qKdgHR3miiSyNP_TuHH9{display:flex;align-items:center;justify-content:center;position:relative;width:32px;height:32px;min-width:32px;border-radius:6px;background-color:var(--black-10)}.Gk1v6ZXDECKpgCqWJTtA{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/DocumentsList/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,gCAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".nameContainer {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 12px 0;\n}\n\n.iconContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  width: 32px;\n  height: 32px;\n  min-width: 32px;\n  border-radius: 6px;\n  background-color: var(--black-10);\n}\n\n.name {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameContainer": "PujR1ZIvtSGS9G_6elb2",
	"iconContainer": "qKdgHR3miiSyNP_TuHH9",
	"name": "Gk1v6ZXDECKpgCqWJTtA"
};
export default ___CSS_LOADER_EXPORT___;
