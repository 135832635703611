import { makeAutoObservable } from 'mobx'
import { type IResponseBusinessLocation } from 'entities/Compliance'

export class BusinessLocation {
  constructor(response: IResponseBusinessLocation) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseBusinessLocation) => {}
}
