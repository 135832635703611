import { FC } from 'react'
import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IOptionItemProps = {
  label?: string
  desc?: string
}

export const OptionItem: FC<IOptionItemProps> = ({ label, desc }) => {
  if (!label) return null

  return (
    <div className={styles.optionWrap}>
      <Typography
        ariaLabel='name'
        variant={'body-md-regular'}
        color='var(--content-primary-primary)'
      >
        {label}
      </Typography>
      {desc && (
        <Typography
          ariaLabel='description'
          variant={'body-sm-regular'}
          color='var(--content-primary-tertiary)'
        >
          {desc}
        </Typography>
      )}
    </div>
  )
}
