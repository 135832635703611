// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A4rEdpGcSPLcGIWMnFnQ{display:flex;flex-direction:row;align-items:center;gap:8px}.A4rEdpGcSPLcGIWMnFnQ.WFgeyNPfbkDqE_RWkoIB{flex-direction:row-reverse}.bej9FgwhO_JOjnYaVeAg{text-decoration:none;line-height:normal}.Qmbl60gzKdasOYSRSnEF{display:none}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAEA,2CACE,0BAAA,CAIJ,sBACE,oBAAA,CACA,kBAAA,CAGF,sBACE,YAAA","sourcesContent":[".actionsContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n\n  &.emptyView {\n    flex-direction: row-reverse;\n  }\n}\n\n.helpButton {\n  text-decoration: none;\n  line-height: normal;\n}\n\n.fileInput {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsContainer": "A4rEdpGcSPLcGIWMnFnQ",
	"emptyView": "WFgeyNPfbkDqE_RWkoIB",
	"helpButton": "bej9FgwhO_JOjnYaVeAg",
	"fileInput": "Qmbl60gzKdasOYSRSnEF"
};
export default ___CSS_LOADER_EXPORT___;
