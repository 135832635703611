import { makeAutoObservable } from 'mobx'
import { type IResponsePointOfContact } from 'entities/Compliance'

export class PointsOfContactItem {
  private _business_title: string | null = null
  private _email: string | null = null
  private _first_name: string | null = null
  private _last_name: string | null = null
  private _phone_number: string | null = null
  private _job_position_id: number | null = null
  private _origin!: IResponsePointOfContact

  constructor(response: IResponsePointOfContact) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponsePointOfContact) => {
    this._business_title = response.business_title
    this._email = response.email
    this._first_name = response.first_name
    this._last_name = response.last_name
    this._phone_number = response.phone_number
    this._job_position_id = response.job_position_id
    this._origin = response
  }

  get origin() {
    return this._origin
  }

  get job_position_id() {
    return this._job_position_id
  }

  get phone_number() {
    return this._phone_number || ''
  }

  get last_name() {
    return this._last_name || ''
  }

  get first_name() {
    return this._first_name || ''
  }

  get business_title() {
    return this._business_title || ''
  }

  get email() {
    return this._email || ''
  }
}
