import { useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'

export const BusinessProfileAccessLayout = observer(() => {
  const store = useBusinessProfileStepsContext()

  useLayoutEffect(() => {
    if (store.complianceLayerStore.businessProfile?.isStatusFailed) return

    uiStore.changeRouteViaVue({
      path: store.businessProfileNavigationStore.nextStep,
    })
  }, [])

  return (
    <>
      <Outlet />
    </>
  )
})
