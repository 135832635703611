import React, { useEffect, FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { nanoid } from 'nanoid'
import { Button } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactsTableStore } from 'widgets/ContactsTable'
import { LinkContactStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/LinkContact/store/linkContactStore'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { LinkContactModalContent } from './LinkContactModalContent/LinkContactModalContent'
import { LinkContactModalActions } from './LinkContactModalActions/LinkContactModalActions'
import styles from './styles.module.scss'

type ILinkContactProps = {
  contact: Contact
  contactsTableStore: ContactsTableStore | null
}

export const LinkContact: FC<ILinkContactProps> = observer(({ contact, contactsTableStore }) => {
  const { contactIntegrationsStore } = useContactsDetailsContext()
  const linkContactStore = useMemo(() => new LinkContactStore(contactIntegrationsStore), [])
  const { reset, searchMatchedContacts, matchedContacts } = linkContactStore

  const hasMatching = (id: number) => {
    return matchedContacts.has(id)
  }

  const matchedCount = (id: number) => {
    return matchedContacts.get(id)?.length || 0
  }

  const modalId = nanoid()

  const handleClose = () => {
    modalStore.removeModal(modalId)
    reset()
  }

  const handleLink = () => {
    contactsTableStore?.resetActiveAndCloseDetailsIsMobile()
    searchMatchedContacts(contact.id, contact.phoneInfo)
    modalStore.addModal({
      id: modalId,
      showCloseButton: false,
      showCloseIcon: true,
      showHeader: true,
      title: 'Link contact',
      paddingTopModal: 60,
      paddingContent: '0px 5px 16px 24px',
      width: 600,
      onClose: handleClose,
      ModalContent: () => (
        <LinkContactModalContent linkContactStore={linkContactStore} contact={contact} />
      ),
      ModalActions: () => (
        <LinkContactModalActions
          linkContactStore={linkContactStore}
          modalId={modalId}
          contact={contact}
        />
      ),
    })
  }

  const btnText = hasMatching(contact.id)
    ? `${matchedCount(contact.id)} matching HubSpot contact(s)`
    : 'Link contact'

  useEffect(() => {
    searchMatchedContacts(contact.id, contact.phoneInfo)
  }, [])

  return (
    <div className={classnames(styles.wrap)}>
      <Button
        size={'medium'}
        contained={'tertiary'}
        text={btnText}
        icon={'link'}
        fullWidth
        onClick={handleLink}
      />
    </div>
  )
})
