import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'
import {
  IntegrationDescription,
  IntegrationListPage,
  IntegrationSettings,
} from 'pages/settings/pages/integrations/pages'
import { IntegrationDetailsLayout } from 'pages/settings/pages/integrations/layout/IntegrationDetailsLayout'
import { IntegrationDetailsIndexRedirect } from 'pages/settings/pages/integrations/route/IntegrationDetailsIndexRedirect'
import { IntegrationsAccessLayout } from 'pages/settings/pages/integrations/layout/IntegrationsAccessLayout'

export const IntegrationsRouter = observer(() => {
  return (
    <Routes>
      <Route element={<IntegrationsAccessLayout />}>
        <Route path={'/'} element={<IntegrationListPage />} />
        <Route path=':id' element={<IntegrationDetailsLayout />}>
          <Route path={IntegrationsRoutes.settings} element={<IntegrationSettings />} />
          <Route path={IntegrationsRoutes.description} element={<IntegrationDescription />} />
          <Route index element={<IntegrationDetailsIndexRedirect />} />
        </Route>
        <Route path={'*'} element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  )
})
