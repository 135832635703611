import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Avatar, IAvatarInfo, Tooltip } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'

export const AvatarContent = observer(({ avatarInfo }: { avatarInfo?: IAvatarInfo }) => {
  const tooltipLabel = useMemo(() => {
    const firstName = avatarInfo?.firstName?.trim()
    const lastName = avatarInfo?.lastName?.trim()
    const number = avatarInfo?.number?.trim()

    const words = []

    if (avatarInfo?.isPhoneAbbr) {
      if (number) words.push(getLabelAsNumberInternationalFormat(number))
    } else {
      if (firstName) words.push(firstName)
      if (lastName) words.push(lastName)
    }

    return !!words.length ? words.join(' ') : null
  }, [avatarInfo])

  return (
    <Tooltip placement='top' label={tooltipLabel}>
      <Avatar info={avatarInfo || {}} />
    </Tooltip>
  )
})
