import { FC } from 'react'
import { Avatar, IconButton, Typography } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import { type Contact } from 'entities/Contacts/model/Contact'
import styles from './styles.module.scss'

type ICallModalsActiveCardProps = {
  item: Contact
  onDelete?: () => void
}

export const CallModalActiveCard: FC<ICallModalsActiveCardProps> = ({
  item,
  onDelete = () => {},
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.card__box}>
        <Avatar info={item.avatarInfo} size={32} />

        <div className={styles.card__info}>
          <Typography
            variant={'body-md-medium'}
            color={'var(--content-primary-primary)'}
            tag={'div'}
            ellipsis
            ariaLabel={'name'}
          >
            {getLabelAsNumberInternationalFormat(item.name)}
          </Typography>
          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-secondary)'}
            tag={'div'}
            ellipsis
            ariaLabel={'formatted_number'}
          >
            {item.number && getLabelAsNumberInternationalFormat(item.number)}
          </Typography>
        </div>
      </div>

      <div className={styles.box}>
        <IconButton
          icon={'close'}
          variant={'icon'}
          color={'tertiary'}
          size={'small'}
          onClick={onDelete}
          ariaLabel={'CallModalActiveCard_delete'}
        />
      </div>
    </div>
  )
}
