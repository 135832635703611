import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { AlertBanner } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { TrialSubscriptionBannerStore } from 'entities/Subscription/ui/TrialSubscriptionBanner/store/trialSubscriptionBannerStore'

export const TrialSubscriptionBanner = observer(() => {
  const [store] = useState(() => new TrialSubscriptionBannerStore())

  useEffect(() => {
    store.init()
  }, [])

  if (!uiStore.location) return null
  if (!store.config) return null

  const { state, pathname } = uiStore.location

  if (store.hide(state, pathname)) return null

  return <AlertBanner ariaLabel={'TrialSubscriptionEnds'} {...store.config} />
})
