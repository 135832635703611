import { Banner } from 'shared/ui'
import { GlobalBanners } from 'widgets/GlobalBanners/GlobalBanners'

const BannerLayout = () => {
  return (
    <>
      <Banner globalBanners={<GlobalBanners />} />
    </>
  )
}

export default BannerLayout
