// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GKhBQRkRS3iCNbKEq79U{display:flex;gap:4px;align-items:center}.MScoq4A4WCw_3eTcF9wm{display:flex;justify-content:flex-end;align-items:flex-end}.UDfspZApnvF3dCSjS9X4{color:var(--content-primary-tertiary);text-align:center;font-size:14px;display:flex;margin-left:4px;cursor:pointer}.UDfspZApnvF3dCSjS9X4.CbgYW78MDYljQG2rPqnO{color:var(--content-negative-primary)}.UDfspZApnvF3dCSjS9X4.FNImuz9jDkKRQiX9bdB0{color:var(--content-brand-primary)}.ReK_js7Ylq7Ot2L50v86{word-break:normal}", "",{"version":3,"sources":["webpack://./src/widgets/MessageCard/ui/DateContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,oBAAA,CAEF,sBACE,qCAAA,CACA,iBAAA,CACA,cAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA,CAEA,2CACE,qCAAA,CAGF,2CACE,kCAAA,CAIJ,sBACE,iBAAA","sourcesContent":[".date {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n\n.wrap {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n}\n.status {\n  color: var(--content-primary-tertiary);\n  text-align: center;\n  font-size: 14px;\n  display: flex;\n  margin-left: 4px;\n  cursor: pointer;\n\n  &.failed {\n    color: var(--content-negative-primary);\n  }\n\n  &.delivered {\n    color: var(--content-brand-primary);\n  }\n}\n\n.wordBreakNormal {\n  word-break: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "GKhBQRkRS3iCNbKEq79U",
	"wrap": "MScoq4A4WCw_3eTcF9wm",
	"status": "UDfspZApnvF3dCSjS9X4",
	"failed": "CbgYW78MDYljQG2rPqnO",
	"delivered": "FNImuz9jDkKRQiX9bdB0",
	"wordBreakNormal": "ReK_js7Ylq7Ot2L50v86"
};
export default ___CSS_LOADER_EXPORT___;
