import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MessageData } from 'entities/Message'
import { type IInputAction, SavedRepliesAction } from 'widgets/MessageField'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

type IConversationSavedRepliesActionProps = {
  noMergeField?: boolean
}

export const ConversationSavedRepliesAction: FC<IConversationSavedRepliesActionProps> = observer(
  ({ noMergeField }) => {
    const store = useConversationMessagesContext()
    const { contact, integration, messageFieldStore } = store
    const { addInputAction } = messageFieldStore

    const onAddSavedReplies = (data: MessageData) => {
      messageFieldStore.setMessageData({
        ...data,
        noMergeField,
        isReplace: false,
        replaceNewRow: false,
      })
    }

    useEffect(() => {
      const inputAction: IInputAction = {
        value: '/',
        action: () => {
          store.savedRepliesStore.openModal({ onAddSavedReplies, integration, contact })
        },
      }

      addInputAction(inputAction)
    }, [])

    return (
      <SavedRepliesAction
        contact={contact}
        integration={integration}
        noMergeField={noMergeField}
        store={store.savedRepliesStore}
        useHotKey
      />
    )
  }
)
