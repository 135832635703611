import { makeAutoObservable } from 'mobx'
import { SingletonRequest } from 'shared/lib'
import { CnamApi } from 'entities/Compliance/api/cnam'
import { type IResponseOrganizationCnam } from 'entities/Compliance/api/cnamType'
import { OrganizationCnam } from 'entities/Compliance/model/OrganizationCnam'

export class CnamStore {
  private _organizationCnam: OrganizationCnam | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get organizationCnam() {
    return this._organizationCnam
  }

  private _fetchOrganizationCnam = async () => {
    try {
      const { data } = await CnamApi.getOrganizationCnam()

      this.setOrganizationCnam(data)
    } catch (e) {
      console.error(e)
    }
  }

  fetchOrganizationCnam = new SingletonRequest(this._fetchOrganizationCnam).request

  setOrganizationCnam = (data: IResponseOrganizationCnam) => {
    this._organizationCnam = new OrganizationCnam(data)

    return this._organizationCnam
  }
}
