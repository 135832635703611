import { observer } from 'mobx-react-lite'
import { CommonOptInPaperFormContent } from 'pages/settings/pages/compliance/ui/CommonOptInContent/ui/CommonOptInPaperFormContent'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import { TollFreeRoutesSteps } from 'pages/settings/pages/compliance/pages/tollFree/route/type'
import { usePathSegment } from 'pages/settings/pages/compliance/hooks/usePathSegment'

export const OptInPaperFormConsent = observer(() => {
  const { optInConsentStore, stepLoading } = useTollFreeStepsContext()
  const {
    getOptInImagesAsAttach,
    deleteAttachment,
    clearAttachmentError,
    getIsNextDisabled,
    getOptInAttachmentError,
    nextStepClick,
    uploadOptInImageAttachment,
    commonOptInConsentStore,
  } = optInConsentStore
  const lastPathSegment = usePathSegment<TollFreeRoutesSteps>()

  return (
    <CommonOptInPaperFormContent
      stepLoading={stepLoading}
      getOptInImagesAsAttach={() =>
        getOptInImagesAsAttach(TollFreeRoutesSteps.optInPaperFormContent)
      }
      deleteAttachment={(url: string) =>
        deleteAttachment(url, TollFreeRoutesSteps.optInPaperFormContent)
      }
      uploadAttachment={(attachments: File[]) =>
        uploadOptInImageAttachment(attachments, lastPathSegment)
      }
      increaseStep={() => nextStepClick(lastPathSegment)}
      clearAttachmentError={() => clearAttachmentError(lastPathSegment)}
      getIsNextDisabled={() => getIsNextDisabled(lastPathSegment)}
      getAttachmentError={() => getOptInAttachmentError(lastPathSegment)}
      optInConsentStore={commonOptInConsentStore}
    />
  )
})
