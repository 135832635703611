import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, type IIconButtonProps } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallDialpadPopUpButton: React.FC<Partial<IIconButtonProps>> = observer((props) => {
  const store = useCallPopUpDefaultContext()
  const { isDialpad, isDialpadDisabled, handleDialpadShow, innerRefTooltipCurrent } =
    store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'blue'}
        variant={'custom'}
        active={isDialpad}
        disabled={isDialpadDisabled}
        ariaLabel='CallDialpadPopUpButton_dialpad'
        onClick={() => {
          handleDialpadShow()
        }}
        iconProps={{
          icon: 'dialpad',
          animation: isDialpad,
        }}
        tooltipProps={{
          label: isDialpad ? 'Hide keypad' : 'Show keypad',
          placement: 'top',
          zIndex: 5001,
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
        {...props}
      />
    </div>
  )
})
