import { makeAutoObservable, runInAction } from 'mobx'
import {
  KnowledgeBaseApi,
  KnowledgeBase,
  type IResponseKnowledgeBase,
} from 'entities/KnowledgeBase'

export class KnowledgeBaseStore {
  private _isKnowledgeBaseLoading = false
  private _knowledgeBase: KnowledgeBase | null = null

  constructor() {
    makeAutoObservable(this)
  }

  loadKnowledgeBase = async () => {
    runInAction(() => {
      this._isKnowledgeBaseLoading = true
    })
    try {
      const { data } = await KnowledgeBaseApi.getKnowledgeBase()
      this.updateKnowledgeBase(data)
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this._isKnowledgeBaseLoading = false
      })
    }
  }

  updateKnowledgeBase = (knowledgeBaseResponse: IResponseKnowledgeBase) => {
    this._knowledgeBase = new KnowledgeBase(knowledgeBaseResponse)
  }

  get knowledgeBase() {
    return this._knowledgeBase
  }

  get chatbotsCount() {
    return this._knowledgeBase?.chatbotsCount ?? 0
  }

  get isKnowledgeBaseLoading() {
    return this._isKnowledgeBaseLoading
  }

  dispose() {}
}
