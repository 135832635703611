import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { AudioWave } from 'shared/ui/AudioPlayer'
import { PlayBtn } from 'shared/ui/Button/ui/PlayBtn'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import styles from 'widgets/Record/ui/RecordActions/RecordPlayer/styles.module.scss'
import { RecordStore } from 'widgets/Record'

export const AudioContent = observer(({ recordStore }: { recordStore: RecordStore }) => {
  const { src, mediaControllerStore } = recordStore
  const { isPlay, currentTime, duration, setDuration } = mediaControllerStore

  const [waveform, setWaveform] = useState<WaveSurfer | null>(null)
  const [playing, setPlaying] = useState(false)

  const onPlay = () => {
    if (waveform) {
      uiStore.setPlayAudioId(src)
      if (playing) {
        waveform.pause()
      } else {
        waveform.play()
      }
      setPlaying(!playing)
    }
  }
  return (
    <>
      <PlayBtn
        isPlay={playing}
        togglePlay={onPlay}
        color={'primary'}
        transparentBackground
        size={'small'}
        fontSize={20}
        className={styles.playBtn}
      />
      <AudioWave
        url={src}
        setDuration={setDuration}
        setPlaying={setPlaying}
        playing={playing}
        id={src}
        waveform={waveform}
        setWaveform={setWaveform}
      />
      <Typography
        variant={'body-md-regular'}
        className={styles.time}
        tag={'div'}
        ariaLabel={'time'}
      >
        {secondsToTime(isPlay ? currentTime : currentTime || duration)}
      </Typography>
    </>
  )
})
