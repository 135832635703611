import { observer } from 'mobx-react-lite'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'
import { ForbiddenMessageCommonTopics } from 'pages/settings/pages/compliance/ui/ForbiddenMessageCommonTopics/ui/ForbiddenMessageCommonTopics'
import { RegistrationErrorAlert } from 'pages/settings/pages/compliance/pages/localNumbers/ui/RegistrationErrorAlert/RegistrationErrorAlert'

export const ForbiddenMessageTopics = observer(() => {
  const { forbiddenMessageStore, stepLoading } = useLocalNumbersStepsContext()

  const {
    forbiddenMessageAgreed,
    isNextStepDisabled,
    nextButtonText,
    setForbiddenMessageAgreed,
    increaseStep,
  } = forbiddenMessageStore

  return (
    <ForbiddenMessageCommonTopics
      nextButtonText={nextButtonText}
      stepLoading={stepLoading}
      setForbiddenMessageAgreed={setForbiddenMessageAgreed}
      isNextStepDisabled={isNextStepDisabled}
      forbiddenMessageAgreed={forbiddenMessageAgreed}
      increaseStep={increaseStep}
      errorAlert={<RegistrationErrorAlert />}
    />
  )
})
