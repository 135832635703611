import React from 'react'
import { Icon, Typography } from 'shared/ui'
import styles from './styles.module.scss'
import type { IMultiselectedItem } from './type'

type IMultiSelectCardInfoProps = {
  selectedItem: IMultiselectedItem
}

export const CardInfo = ({ selectedItem }: IMultiSelectCardInfoProps) => {
  return (
    <>
      <Typography
        className={styles.selectedItemLabel}
        variant={'text-input-field'}
        ariaLabel={'name'}
      >
        <div className={styles.selectedItem__number}>{selectedItem.label}</div>
        {selectedItem.data.is_aircall && (
          <Icon icon={'aircall'} fontSize={16} className={styles.selectedItem__aircallIcon} />
        )}
        <Icon icon={'circleDot'} fontSize={2} className={styles.selectedItem__dot} />

        <div className={styles.selectedItem__name}>{selectedItem.name}</div>
      </Typography>
    </>
  )
}
