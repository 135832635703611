import { CampaignsListUi, ICampaignsListUiProps } from './ui/CampaignsListUi'
import { CampaignsListContext } from './context/context'
import { CampaignsListStore } from './store/CampaignsListStore'

type ICampaignsListProps = {
  store: CampaignsListStore
} & ICampaignsListUiProps

export const CampaignsList = ({ store, ...props }: ICampaignsListProps) => {
  return (
    <CampaignsListContext.Provider value={store}>
      <CampaignsListUi {...props} />
    </CampaignsListContext.Provider>
  )
}
