import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { Icon, Typography } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { links } from 'shared/constants/links'
import { openIntercom } from 'entities/Intercom'
import { Attachment } from 'entities/Attachment/model/Attachment'
import PresentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { type CompliancePageStore } from 'pages/settings/pages/compliance/store/compliancePageStore'
import { StatusComplianceIcons } from 'pages/settings/pages/compliance/constants/statusIcon'
import type { IRegisterSectionProps } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import styles from 'pages/settings/pages/compliance/ui/styles.module.scss'
import { LocalNumbersNavigationStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersNavigationStore'
import { BrandModalContent } from 'pages/settings/pages/compliance/pages/localNumbers/ui/BrandModalContent/BrandModalContent'
import { EstimatedResponseInfo } from 'pages/settings/pages/compliance/ui/EstimatedResponseInfo/EstimatedResponseInfo'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { UseCaseModalContent } from 'pages/settings/pages/compliance/pages/localNumbers/ui/UseCaseModalContent/UseCaseModalContent'

export class LocalNumbersPageStore {
  private _localNumbersNavigationStore: LocalNumbersNavigationStore
  private _modalId = nanoid()

  constructor(private _compliancePageStore: CompliancePageStore) {
    this._localNumbersNavigationStore = new LocalNumbersNavigationStore(
      this._compliancePageStore.complianceLayerStore
    )

    makeAutoObservable(this)
  }

  get complianceLayerStore() {
    return this._compliancePageStore.complianceLayerStore
  }

  get businessInformation() {
    return this.complianceLayerStore.businessInformation
  }

  get localNumberCampaignStatus() {
    if (!this.complianceServiceCampaign) return null

    return this.complianceServiceCampaign.status
  }

  get complianceServiceBrand() {
    return this.complianceLayerStore.complianceServiceBrand
  }

  get localNumberBrandStatus() {
    if (!this.complianceServiceBrand) return null

    return this.complianceServiceBrand.status
  }

  get useCaseName() {
    return this.complianceServiceCampaign?.useCaseTitle
  }

  get localNumberStep() {
    return this.complianceServiceCampaign?.data?.registrationFormStep
  }

  get showUseCaseStatus() {
    if (!this.localNumberCampaignStatus) return false

    return this.localNumberCampaignStatus !== 'in_progress'
  }

  get showBrandStatus() {
    if (!this.localNumberBrandStatus) return false

    return this.localNumberBrandStatus !== 'in_progress'
  }

  get complianceServiceCampaign() {
    return this.complianceLayerStore.complianceServiceCampaign
  }

  get localNumbersSubmitted() {
    return this.complianceServiceCampaign?.isSubmitted
  }

  get brandIconCondition() {
    if (this.complianceServiceBrand?.isStatusFailed) {
      return StatusComplianceIcons.reject
    }
    if (this.complianceServiceBrand?.isStatusPendingReview) {
      return StatusComplianceIcons.pendingReview
    }
    if (this.complianceServiceBrand?.isStatusInCarrierReview) {
      return StatusComplianceIcons.carrierReview
    }
    if (this.complianceServiceBrand?.isStatusApproved) {
      return StatusComplianceIcons.approved
    }

    return null
  }

  get useCaseIconCondition() {
    if (this.complianceServiceCampaign?.isStatusFailed) {
      return StatusComplianceIcons.reject
    }
    if (this.complianceServiceCampaign?.isStatusPendingReview) {
      return StatusComplianceIcons.pendingReview
    }
    if (this.complianceServiceCampaign?.isStatusInCarrierReview) {
      return StatusComplianceIcons.carrierReview
    }
    if (this.complianceServiceCampaign?.isStatusApproved) {
      return StatusComplianceIcons.approved
    }

    return null
  }

  get localNumberDataStatus() {
    if (this._localNumbersNavigationStore.formStep === 0) return null
    const { allStepsLength } = this._compliancePageStore.localNumbersStore
    const stepForContinue = this._localNumbersNavigationStore.getStepForContinue(allStepsLength) - 1

    if (!this.localNumbersSubmitted) {
      return StatusComplianceIcons.condition((stepForContinue / allStepsLength) * 100)
    }

    if (this.localNumberCampaignStatus === 'failed' || this.localNumberBrandStatus === 'failed') {
      return StatusComplianceIcons.reject
    }

    if (
      this.localNumberCampaignStatus === 'pending_review' ||
      this.localNumberBrandStatus === 'pending_review'
    ) {
      return StatusComplianceIcons.pendingReview
    }

    if (
      this.localNumberCampaignStatus === 'in_carrier_review' ||
      this.localNumberBrandStatus === 'in_carrier_review'
    ) {
      return StatusComplianceIcons.carrierReview
    }

    if (
      this.localNumberCampaignStatus === 'approved' &&
      this.localNumberBrandStatus === 'approved'
    ) {
      return StatusComplianceIcons.approved
    }

    return null
  }

  get campaignData() {
    return this.complianceLayerStore.complianceServiceCampaign
  }

  get summary() {
    return this._compliancePageStore.complianceLayerStore.summary
  }

  get registerButtonText() {
    if (this.campaignData?.isSubmitted) return null

    const { allStepsLength } = this._compliancePageStore.localNumbersStore
    const { getStepForContinue, formStep } = this._localNumbersNavigationStore

    return formStep === 0 ? (
      'Register'
    ) : (
      <Typography ariaLabel='Continue' variant='button-medium'>
        {'Continue: '}
        <Typography
          ariaLabel='Step'
          variant='button-medium'
          color='var(--content-primary-tertiary-inverted)'
        >
          Step {getStepForContinue(allStepsLength)}/{allStepsLength}
        </Typography>
      </Typography>
    )
  }

  get lnRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'A2P 10DLC registration',
      titleType: 'body-lg-medium',
      desc: (
        <Typography
          ariaLabel='Local-number_verification_desc'
          variant='body-md-regular'
          tag='div'
          className={styles.tollFreeVerificationDesc}
        >
          Required for sending SMS <Icon icon='arrowRight' className={styles.arrow} /> to
          <Icon className={styles.flag} icon='flagUSA' /> US.
        </Typography>
      ),
      isSecondStep: this.campaignData?.isSubmitted,
      learnMoreLink: links.complianceLearnMoreLink,
      learnMoreLinkClassName: 'newLineLink',
      actionButton: this.registerButtonText
        ? {
            text: this.registerButtonText,
            onClick: () => {
              if (this._compliancePageStore.businessProfile?.isStatusEmpty) {
                this._compliancePageStore.businessProfilePageStore.openBusinessProfileRegisterModal(
                  'LN'
                )

                return
              }

              if (!this.campaignData?.isSubmitted) {
                uiStore.changeRouteViaVue({
                  path: `/${this._localNumbersNavigationStore.nextStep}`,
                })
              }
            },
          }
        : undefined,
    }
  }

  updateModalAction = () => {
    modalStore.removeModal(this._modalId)

    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.localNumbers.useCases}`,
    })
  }

  learnMoreModalAction = () => {
    modalStore.removeModal(this._modalId)
    openIntercom()
  }

  openBrandSummaryModal = () => {
    const iconData = this._compliancePageStore.getIconConditionByTab('LN')
    const brand = this.complianceServiceBrand

    if (!iconData || !brand) return

    modalStore.addModal({
      id: this._modalId,
      title: 'Brand',
      width: 540,
      showCloseButton: false,
      hiddenContent: true,
      ModalContent: () => (
        <BrandModalContent
          updateModalAction={this.updateModalAction}
          learnMoreModalAction={this.learnMoreModalAction}
          complianceLayerStore={this.complianceLayerStore}
          brand={brand}
          statusData={
            <EstimatedResponseInfo
              dayCount={1}
              labelPart='Brand'
              iconData={this.brandIconCondition}
              status={this.localNumberBrandStatus || null}
              submittedAt={this.complianceServiceBrand?.submittedAt}
            />
          }
        />
      ),
    })
  }

  openUseCaseSummaryModal = () => {
    const iconData = this._compliancePageStore.getIconConditionByTab('LN')
    const { complianceServiceCampaign } = this.complianceLayerStore

    if (!iconData || !complianceServiceCampaign) return

    modalStore.addModal({
      id: this._modalId,
      title: 'Use case campaign',
      width: 540,
      showCloseButton: false,
      hiddenContent: true,
      ModalContent: () => (
        <UseCaseModalContent
          updateModalAction={this.updateModalAction}
          learnMoreModalAction={this.learnMoreModalAction}
          serviceCampaign={complianceServiceCampaign}
          openImageModal={this.openImageModal}
          statusData={
            <EstimatedResponseInfo
              dayCount={4}
              labelPart='Use Case'
              iconData={this.useCaseIconCondition}
              status={this.localNumberCampaignStatus || null}
              submittedAt={this.complianceServiceCampaign?.submittedAt}
            />
          }
        />
      ),
    })
  }

  openImageModal = (url: string, name: string) => {
    PresentationStore.setAttachments([new Attachment({ imageUrl: url, name })])
  }
}
