import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseConversation } from 'entities/Conversation/api/types'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { Message } from 'entities/Message/model/Message'
import { filtersStore } from 'features/DropdownFilter'
import { canAddConversationToList, ConversationListStore } from 'widgets/ConversationList'

export const EventConversationOpened = {
  subscribe: (handler: (data: IResponseConversation) => void) =>
    websocket.on(WebsocketEvent.ConversationOpened, handler),
  unsubscribe: (handler: (data: IResponseConversation) => void) =>
    websocket.off(WebsocketEvent.ConversationOpened, handler),
}

export const useEventConversationOpened = (conversationListStore: ConversationListStore) => {
  const handler = (data: IResponseConversation) => {
    const { updateItem } = conversationStore
    const { setFocusItem, addItemOrder, listOrders } = conversationListStore
    const conversation = data
    const item = new Conversation(conversation)
    const message = new Message(conversation.recent_message)
    const itemsPast = listOrders

    if (!filtersStore.isShowConversation(item, message)) return

    if (canAddConversationToList(data)) {
      updateItem(conversation)
      addItemOrder(conversation.id)
    }

    if (!conversationListStore.conversationSearchStore.isActive) return
    setFocusItem([conversation.id], itemsPast)
  }

  useEffect(() => {
    EventConversationOpened.subscribe(handler)

    return () => {
      EventConversationOpened.unsubscribe(handler)
    }
  }, [])
}
