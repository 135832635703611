import { Status } from 'shared/ui'

import styles from './styles.module.scss'

export const ContactsQuickLinksHeader = () => (
  <span className={styles.header}>
    Quick links <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
  </span>
)

export const contactsQuickLinksHeader = <ContactsQuickLinksHeader />
