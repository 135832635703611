import { type IMenuItemProps } from 'shared/ui'
import {
  KnowledgeBasePath,
  CampaignsPath,
  WorkflowsPath,
  KeywordsPath,
  ChatbotPath,
} from 'entities/ExportRouters'

export const AIAutomationKey = 'AIAutomation'

export const subItemsMenuAIAutomationActive = [
  ChatbotPath.root,
  ChatbotPath.all,
  ChatbotPath.new,
  ChatbotPath.analytic,

  KnowledgeBasePath.root,

  CampaignsPath.root,
  CampaignsPath.all,

  WorkflowsPath.root,
  KeywordsPath.root,
]

export enum EnumMenuAIAutomationItemsKey {
  ai_agents = 'ai_agents',
  chatbot = 'chatbot',
  knowledge_base = 'knowledge_base',
  campaigns = 'campaigns',
  workflows = 'workflows',
  keywords = 'keywords',
}

export const listItemsAIAutomation = {
  [EnumMenuAIAutomationItemsKey.chatbot]: ChatbotPath.root,
  [EnumMenuAIAutomationItemsKey.knowledge_base]: KnowledgeBasePath.root,
  [EnumMenuAIAutomationItemsKey.campaigns]: CampaignsPath.root,
  [EnumMenuAIAutomationItemsKey.workflows]: WorkflowsPath.root,
  [EnumMenuAIAutomationItemsKey.keywords]: KeywordsPath.root,
}

export const menuItems: IMenuItemProps<EnumMenuAIAutomationItemsKey>[] = [
  {
    key: EnumMenuAIAutomationItemsKey.ai_agents,
    type: 'sub',
    label: 'AI Agents',
    icon: 'stars',
    items: [
      {
        key: EnumMenuAIAutomationItemsKey.chatbot,
        type: 'nav',
        to: ChatbotPath.root,
        fullPath: true,
        label: 'Agents',
      },
      {
        key: EnumMenuAIAutomationItemsKey.knowledge_base,
        type: 'nav',
        to: KnowledgeBasePath.root,
        fullPath: true,
        label: 'Knowledge base',
      },
    ],
  },
  {
    key: EnumMenuAIAutomationItemsKey.campaigns,
    type: 'nav',
    to: CampaignsPath.root,
    fullPath: true,
    label: 'Campaigns',
    icon: 'campaigns',
    disabled: false,
  },
  {
    key: EnumMenuAIAutomationItemsKey.workflows,
    type: 'nav',
    to: WorkflowsPath.root,
    fullPath: true,
    label: 'Workflows',
    icon: 'workflows',
  },
  {
    key: EnumMenuAIAutomationItemsKey.keywords,
    type: 'nav',
    to: KeywordsPath.root,
    fullPath: true,
    label: 'Keywords',
    icon: 'keywords',
  },
]
