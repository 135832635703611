import { websocket } from 'entities/WebSocket/store'

interface IEventProfileProps {
  id: number | string
}

export class ChannelProfile {
  privateChannelName: string

  constructor(props: IEventProfileProps) {
    this.privateChannelName = `private-profile.${props.id}`
    this.subscribe()
  }

  subscribe() {
    websocket.subscribe(this.privateChannelName)
  }
}
