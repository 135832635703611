import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { type GetNumberPFTModalStore } from 'entities/Phone/ui/GetNumberPFTModal'
import styles from './styles.module.scss'

type IGetNumberPFTModalActionsProps = {
  store: GetNumberPFTModalStore
}

export const GetNumberPFTModalActions: FC<IGetNumberPFTModalActionsProps> = observer(
  ({ store }) => {
    const { disabled, updateNumber } = store

    return (
      <div className={styles.actionsWrap} aria-label={getAriaLabel('GetNumberModalActions')}>
        <Button
          disabled={disabled}
          fullWidth
          typeBtn={'contained'}
          text={'Let’s get started!'}
          size={'large'}
          textClassname={styles.button}
          onClick={updateNumber}
        />
      </div>
    )
  }
)
