const SAMPLE_RATE = 60000 * 5
let lastSample = Date.now()

export const detectWakeMode = () => {
  if (Date.now() - lastSample >= SAMPLE_RATE) {
    console.info('Trigger WakeMode')

    window.postMessage('wakeMode', window.location.origin)
  }
  lastSample = Date.now()
  setTimeout(detectWakeMode, SAMPLE_RATE)
}

export const actionWakeMode = (callBack: (e: MessageEvent) => void) => {
  const action = (e: MessageEvent) => {
    if (e.data === 'wakeMode') {
      callBack(e)
    }
  }

  window.addEventListener('message', action)

  return () => {
    window.removeEventListener('message', action)
  }
}
