import { makeAutoObservable, runInAction } from 'mobx'
import { groupBy } from 'lodash'
import { IDropdownItem } from 'shared/ui'
import { apiOptionsToDropdownItems, apiOptionToDropdownItem, numberFormat } from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance/api/compliance'
import { TenDlcApi } from 'entities/TenDlc'

const countryMap: { [key: string]: IDropdownItem } = {
  US: {
    id: 'US',
    label: 'United States',
  },
  CA: {
    id: 'CA',
    label: 'Canada',
  },
}

const twoFaVerificationStatusMap: { [key: string]: IDropdownItem } = {
  pending: {
    id: 'pending',
    label: 'Pending 2FA Email to be Sent',
  },
  action_required: {
    id: 'action_required',
    label: 'Action Required: Check Email',
  },
  approved: {
    id: 'approved',
    label: 'Approved',
  },
}

class ComplianceStore {
  constructor() {
    makeAutoObservable(this)
  }

  tenDLCUseCases: IDropdownItem[] = []
  tollFreeUseCases: IDropdownItem[] = []
  optInTenDLCTypes: IDropdownItem[] = []

  businessProfileStatesGroup: { [key: string]: Array<IDropdownItem> } = {}
  complianceCountries: IDropdownItem[] = Object.values(countryMap)
  businessProfileBusinessTypes: IDropdownItem[] = []
  businessProfileJobPositions: IDropdownItem[] = []
  businessProfileIndustries: IDropdownItem[] = []
  businessProfileRegionsOfOperation: IDropdownItem[] = []
  businessProfileBusinessRegistrationTypes: IDropdownItem[] = []
  businessProfileStockExchanges: IDropdownItem[] = []

  providers: IDropdownItem[] = []

  tollFreeMessageVolume: IDropdownItem[] = []
  optInTollFreeTypes: IDropdownItem[] = []
  businessInformationBrandTwoFaVerificationStatus: IDropdownItem[] = Object.values(
    twoFaVerificationStatusMap
  )

  loadTenDLCUseCases = async () => {
    if (this.tenDLCUseCases.length) {
      return
    }
    try {
      const response = await TenDlcApi.getTenDlcUseCases({ filter: 'standard' })
      runInAction(() => {
        this.tenDLCUseCases = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }
  loadOptInTenDLCTypes = async () => {
    if (this.optInTenDLCTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getOptInTypes({ filter: 'dlc' })
      runInAction(() => {
        this.optInTenDLCTypes = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadBusinessProfileStates = async () => {
    if (Object.values(this.businessProfileStatesGroup).length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileStates()
      const group = groupBy(response.data, 'country_id')
      runInAction(() => {
        this.businessProfileStatesGroup = Object.fromEntries(
          Object.entries(group).map(([key, value]) => [
            key,
            value.map(apiOptionToDropdownItem) as IDropdownItem[],
          ])
        )
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadBusinessProfileBusinessTypes = async () => {
    if (this.businessProfileBusinessTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileBusinessTypes()
      runInAction(() => {
        this.businessProfileBusinessTypes = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadBusinessProfileJobPositions = async () => {
    if (this.businessProfileJobPositions.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileJobPositions()
      runInAction(() => {
        this.businessProfileJobPositions = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadBusinessProfileIndustries = async () => {
    if (this.businessProfileIndustries.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileIndustries()
      runInAction(() => {
        this.businessProfileIndustries = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadBusinessProfileRegionsOfOperation = async () => {
    if (this.businessProfileRegionsOfOperation.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileRegionsOfOperation()
      runInAction(() => {
        this.businessProfileRegionsOfOperation = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadBusinessProfileBusinessRegistrationTypes = async () => {
    if (this.businessProfileBusinessRegistrationTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileBusinessRegistrationTypes()
      runInAction(() => {
        this.businessProfileBusinessRegistrationTypes = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }
  loadGetBusinessProfileStockExchanges = async () => {
    if (this.businessProfileStockExchanges.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileStockExchanges()
      runInAction(() => {
        this.businessProfileStockExchanges = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  loadProviders = async () => {
    if (this.providers.length) {
      return
    }
    try {
      const response = await ComplianceApi.getProviders()
      runInAction(() => {
        this.providers = response.data.map((item) => ({
          id: item.value,
          label: item.label === 'Tcr' ? 'TCR' : item.label,
        }))
      })
    } catch (e) {
      console.error(e)
    }
  }

  getCountryLabel = (id: string) => {
    if (id === 'usa') {
      return countryMap['US'].label
    }
    return countryMap[id]?.label || id
  }

  getStates = (countryId: string | null): IDropdownItem[] => {
    return this.businessProfileStatesGroup[countryId || 'US'] || []
  }

  loadTollFreeFormFields = async () => {
    if (this.tollFreeMessageVolume.length || this.tollFreeUseCases.length) {
      return
    }
    try {
      const response = await ComplianceApi.getTollFreeFormFields()
      runInAction(() => {
        this.tollFreeMessageVolume = response.data?.monthly_values.map((item) => ({
          id: item,
          label: numberFormat({ value: item }),
        }))
        this.tollFreeUseCases = apiOptionsToDropdownItems(response.data?.use_cases)
      })
    } catch (e) {
      console.error(e)
    }
  }
  loadOptInTollFreeTypes = async () => {
    if (this.optInTollFreeTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getOptInTypes({ filter: 'toll-free' })
      runInAction(() => {
        this.optInTollFreeTypes = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export const complianceStore = new ComplianceStore()
