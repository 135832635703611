import { useMemo } from 'react'
import { KeywordViewStore } from 'pages/keywords/store'
import { KeywordViewContext } from 'pages/keywords/context'
import { KeywordViewContent } from 'pages/keywords/pages/createKeyword/ui/KeywordViewContent'

export const CreateKeyword = () => {
  const store = useMemo(() => new KeywordViewStore(), [])

  return (
    <KeywordViewContext.Provider value={store}>
      <KeywordViewContent />
    </KeywordViewContext.Provider>
  )
}
