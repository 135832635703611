import { makeAutoObservable } from 'mobx'

export class NameInputStore {
  constructor() {
    makeAutoObservable(this)
  }

  name = ''
  setName = (value: typeof this.name) => {
    this.name = value
  }

  error = ''
  setError = (value: typeof this.error) => {
    this.error = value
  }

  init = this.setName
}
