import { makeAutoObservable } from 'mobx'
import type { IDropdownItem } from 'shared/ui'
import { numbersStore } from 'entities/Phone'
import type { Inbox } from 'entities/Inbox/model/Inbox'
import type { Phone } from 'entities/Phone/model/Phone'
import type { PhoneShortCode } from 'entities/Phone/model/PhoneShortCode'
import { IResponseNumber } from 'entities/Phone/api/types'
import type { IMultiSelectedValues } from './types'

type ISendFromMultiDropdownStoreProps = {
  getInboxByNumber: (id: number) => Inbox | undefined
}

export class SendFromMultiDropdownStore {
  private _multiSelectedValuesMap: Map<number, IMultiSelectedValues> = new Map()
  private _multiselectListNumbers: Map<number, Phone[]> = new Map()
  private _getInboxByNumber: (id: number) => Inbox | undefined
  private _aircallNumsIds: number[] = []

  constructor({ getInboxByNumber }: ISendFromMultiDropdownStoreProps) {
    makeAutoObservable(this)
    this._getInboxByNumber = getInboxByNumber
  }

  getMultiSelectNumbers(inbox: Inbox) {
    const nums = inbox.numberIds.map((id) => numbersStore.getItem(id)) as Phone[]

    this._multiselectListNumbers.set(
      inbox.id,
      nums.filter((num) => !this.multiSelectedKeys.includes(num.id))
    )
  }

  filterMultiselectListNumbers = () => {
    const filteredMap = new Map<number, Phone[]>()

    this._multiselectListNumbers.forEach((phones, key) => {
      filteredMap.set(
        key,
        phones.filter((phone) => !this.multiSelectedKeys.includes(phone.id))
      )
    })

    this._multiselectListNumbers = filteredMap
  }

  handleAircallNumbers = (phone: Phone) => {
    if (phone.is_aircall) {
      this._aircallNumsIds.push(phone.id)
    }
  }

  setMultipleItem = (item: IDropdownItem, shortCode?: PhoneShortCode) => {
    const inboxId = Number(item.value)
    const phone = item.data as Phone
    const numberId = shortCode ? phone.id : Number(item.id)
    const itemName = () => {
      if (shortCode) return shortCode.name

      return this._getInboxByNumber(numberId)?.name
    }

    const name = itemName()
    const label = phone?.formatted_number || ''

    this._multiSelectedValuesMap.set(numberId, {
      ...phone,
      inboxId,
      name,
      label,
    } as IMultiSelectedValues)

    this.handleAircallNumbers(phone)
    this.filterMultiselectListNumbers()
  }

  setSelectAllItems = (items: IDropdownItem[]) => {
    items.forEach((item) => this.setMultipleItem(item))
  }

  setSelectedValuesParams = (numbers: IResponseNumber[]) => {
    numbers.forEach((number) => {
      const storeNumber = numbersStore.getItem(number.id)

      const item = {
        id: String(number?.id),
        label: number?.formatted_number,
        data: storeNumber,
        iconL: number?.is_aircall ? 'aircall' : undefined,
      } as IDropdownItem

      if (number.type === 'Short Code') {
        const shortCode = numbersStore.getShortCodeByNumber(Number(number.id))
        if (shortCode) {
          this.setMultipleItem(item, shortCode)
        }
      } else {
        const inbox = this._getInboxByNumber(number.id)
        if (inbox) {
          item.value = String(inbox.id)
          this.setMultipleItem(item)
        }
      }
    })
  }

  removeMultiSelectedItem = (item: IMultiSelectedValues) => {
    this._multiSelectedValuesMap.delete(item.id)
    const inbox = this._getInboxByNumber(item.id)

    this._aircallNumsIds = this._aircallNumsIds.filter((aircallNumber) => aircallNumber !== item.id)

    if (inbox) {
      this.getMultiSelectNumbers(inbox as Inbox)
    }
  }

  get multiselectListNumbers() {
    return Array.from(this._multiselectListNumbers.values())
  }

  get multiselectListNumbersMap() {
    return this._multiselectListNumbers
  }

  get multiSelectedKeys() {
    return Array.from(this._multiSelectedValuesMap.keys())
  }

  get multiSelectedValues() {
    return Array.from(this._multiSelectedValuesMap.values())
  }

  get multiSelectedValuesMap() {
    return this._multiSelectedValuesMap
  }

  get multiSelectParams() {
    return this.multiSelectedKeys
  }

  get isAircall() {
    return !!this._aircallNumsIds.length
  }
}
