// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UbQ7ZGmyBxC9_TcoXpNt{position:absolute;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;justify-content:center;border-radius:4px;z-index:9;background:var(--black-50, rgba(0, 0, 0, 0.4))}", "",{"version":3,"sources":["webpack://./src/shared/ui/Loader/AttachmentLoader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,SAAA,CACA,8CAAA","sourcesContent":[".loader{\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  z-index: 9;\n  background: var(--black-50, rgba(0, 0, 0, 0.40));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "UbQ7ZGmyBxC9_TcoXpNt"
};
export default ___CSS_LOADER_EXPORT___;
