import React, { ReactNode } from 'react'
import { Scrollbar } from 'shared/ui'
import type { Attachment } from 'entities/Attachment/model/Attachment'
import { MessageSMSPreview } from 'widgets/MessagePreview/MessageSMSPreview'
import { MessageFieldStore } from 'widgets/MessageField'
import { MessageRinglessPreview } from 'widgets/MessagePreview/MessageRinglessPreview'
import styles from './styles.module.scss'

interface MessagePreviewProps {
  type?: 'sms' | 'ringless'
  messageStore?: MessageFieldStore | null
  attachment?: Attachment | null
  customContent?: ReactNode
}

export const MessagePreview: React.FC<MessagePreviewProps> = ({
  type,
  messageStore,
  attachment,
  customContent,
}: MessagePreviewProps) => {
  return (
    <div className={styles.preview}>
      <div className={styles.preview__layer}>
        <div className={styles.preview__layerWrap}>
          <Scrollbar>
            {type === 'sms' && messageStore && (
              <MessageSMSPreview messageFieldStore={messageStore} />
            )}
            {type === 'ringless' && attachment && (
              <MessageRinglessPreview attachment={attachment} />
            )}
            {customContent}
          </Scrollbar>
        </div>
      </div>
    </div>
  )
}
