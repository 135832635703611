import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TooltipLink, Typography } from 'shared/ui'
import { TrialTenLimitConversationsTooltipStore } from 'entities/Subscription/ui/TrialTenLimitConversationsTooltip/store/trialTenLimitConversationsTooltipStore'

export const TrialTenLimitConversationsTooltip = observer(() => {
  const [store] = useState(() => new TrialTenLimitConversationsTooltipStore())

  return (
    <>
      <Typography
        className={'body-sm-medium'}
        color={'var(--content-primary-tertiary-inverted)'}
        ariaLabel={'FailedReason'}
      >
        {store.config.desc}
      </Typography>
      {store.config.action && (
        <TooltipLink text={store.config.action.text} onClick={store.config.action.onClick} />
      )}
    </>
  )
})
