import { observer } from 'mobx-react-lite'
import { useMemo, useEffect } from 'react'
import { Box, Button, IconButton, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  QuestionSection,
  getFirstBlockItems,
  getSecondBlockItems,
  getThirdBlockItems,
} from 'entities/Compliance/ui/Questionnaire'
import type { ITargetCountry } from 'entities/Compliance/api/complianceType'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { useQuestionnaireContext } from 'pages/settings/pages/compliance/pages/questionnaire/context/questionnaireContext'
import styles from './styles.module.scss'

export const QuestionsContent = observer(() => {
  const {
    step,
    targetCountry,
    isTaxId,
    numberType,
    complianceLayerStore,
    setStep,
    handleSetTargetCountry,
    handleSetIsTaxId,
    setNumberType,
    saveSummary,
    resetProgress,
    disabled,
    init,
  } = useQuestionnaireContext()

  const { summary } = complianceLayerStore

  const showSecondQuestion = step > 2
  const showThirdQuestion = step > 3

  const firstQuestionHandled = (targetCountry: ITargetCountry) => {
    step === 2 && setStep(3)
    handleSetTargetCountry(targetCountry)
  }

  const secondQuestionHandled = (condition: boolean) => {
    step === 3 && setStep(4)
    handleSetIsTaxId(condition)
  }

  const thirdBlockItems = useMemo(
    () => getThirdBlockItems(numberType, isTaxId, targetCountry, setNumberType),
    [targetCountry, isTaxId, numberType]
  )

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <IconButton
        className={styles.closeIcon}
        ariaLabel='close_setting'
        icon='close'
        size='medium'
        variant='icon'
        color='tertiary'
        onClick={() => {
          setStep(1)

          if (summary?.organization_id) {
            uiStore.changeRoute({
              path: CompliancePath.compliance.root,
            })
          } else {
            resetProgress()
          }
        }}
      />
      <div className={styles.questionsPageWrap}>
        <div className={styles.titleStatus}>
          <Typography ariaLabel='QuestionsPage_title' variant='body-xs-regular'>
            Start here
          </Typography>
        </div>
        <Box padding={'15px 20px 56px 20px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
          <Typography
            ariaLabel='QuestionsPage_header'
            variant='heading-md'
            className={styles.header}
          >
            Get approved to send texts
          </Typography>
          <Typography
            ariaLabel='QuestionsPage_disc'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            Just answer a few questions to get started
          </Typography>
        </Box>
        <div className={styles.questionsBlock}>
          <QuestionSection
            header='Which country do you want to send messages to?'
            number='1'
            containerClassName={styles.firstQuestion}
            items={getFirstBlockItems(targetCountry, firstQuestionHandled)}
          />
          {showSecondQuestion && (
            <QuestionSection
              header='Do you have a business Tax ID?'
              headerDesc='In the US - EIN. In Canada - BN. Other countries not supported yet.'
              number='2'
              containerClassName={styles.firstQuestion}
              items={getSecondBlockItems(isTaxId, secondQuestionHandled)}
            />
          )}
          {showThirdQuestion && (
            <QuestionSection
              header='What numbers will you use to send messages?'
              number='3'
              items={thirdBlockItems}
            />
          )}
        </div>
        {step === 4 && (
          <div className={styles.saveButton}>
            <Button size='large' text='Save' disabled={disabled} onClick={saveSummary} fullWidth />
          </div>
        )}
      </div>
    </>
  )
})
