import { observer } from 'mobx-react-lite'
import { FullPageWrapper } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useKeywordsPageContext } from 'pages/keywords/context'
import { KeywordsList } from './KeywordsList'
import { KeywordsListHeader } from './KeywordsListHeader'

export const AllKeywords = observer(() => {
  const { pageLayoutStore, isKeywordsActionShown, setIsKeywordsActionShown } =
    useKeywordsPageContext()

  return (
    <FullPageWrapper>
      <KeywordsListHeader
        title={featureFlagsStore.salesmsg_design_v2_new_sidebar_menu ? 'Keywords' : 'All Keywords'}
        pageLayoutStore={pageLayoutStore}
        isKeywordsActionShown={isKeywordsActionShown}
      />
      <KeywordsList setIsKeywordsActionShown={setIsKeywordsActionShown} />
    </FullPageWrapper>
  )
})
