import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { InView } from 'react-intersection-observer'
import { layoutStore } from 'shared/layout'
import { uiStore } from 'shared/store/uiStore'
import { IS_APP_LIGHT } from 'shared/config'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { subscriptionStore } from 'entities/Subscription'
import { remindersStore } from 'widgets/Reminders'
import { MessageField } from 'widgets/MessageField'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { MessageSignatureEditor } from 'widgets/MessageField/ui/MessageSignature'
import { ConversationMessageFieldAlerts } from '../ConversationMessageFieldAlerts/ConversationMessageFieldAlerts'
import styles from './styles.module.scss'

export const ConversationMessageField = observer(() => {
  const ref = useRef<HTMLDivElement>(null)
  const store = useConversationMessagesContext()
  const { isCollapse } = contactsDetailsGlobalStore
  const { isMobileViewWithoutMSize } = layoutStore
  const {
    messageFieldStore,
    contact,
    messageSignatureStore,
    isConversationNew,
    showDoubleOptInButton,
    conversation,
    conversationMessageFieldStore,
  } = store
  const { isMessageLocal, isMessageAll, isStatusBlocked } =
    store.conversationMessageFieldAccessStore

  const isRequestAccess = !userPermissionsStore.getItem('can_manage_conversations')
  const isOutOfMoney = remindersStore.creditsStore.noMessageSending
  const isOutOfTrialCredits = remindersStore.trialCreditsStore.noMessageSending
  const isLocked = !!conversation?.is_locked
  const noContact = !contact?.id
  const {
    disabled,
    setMessageFieldElement,
    setDisabledMessageField,
    handleResetMode,
    isDoubleOptInEnabled,
    setSendDisabled,
    setFocusMessageFieldTrigger,
  } = messageFieldStore

  const { isActiveMessageSignature, initMessageSignature } = messageSignatureStore

  useEffect(() => {
    if (conversation) {
      subscriptionStore.trialSubscriptionStore.setConversation(conversation)
    }

    return () => {
      subscriptionStore.trialSubscriptionStore.setConversation(null)
    }
  }, [conversation?.id])

  useEffect(() => {
    initMessageSignature()

    if (ref.current) {
      setMessageFieldElement(ref.current)
      handleResetMode()
    }
  }, [])

  useEffect(() => {
    const isDisabled =
      isMessageAll ||
      isMessageLocal ||
      isDoubleOptInEnabled ||
      isRequestAccess ||
      isOutOfMoney ||
      isOutOfTrialCredits ||
      isStatusBlocked ||
      showDoubleOptInButton

    setDisabledMessageField(isDisabled)
  }, [
    isMessageAll,
    isMessageLocal,
    isDoubleOptInEnabled,
    isRequestAccess,
    isOutOfMoney,
    isOutOfTrialCredits,
    isStatusBlocked,
    showDoubleOptInButton,
    disabled,
  ])

  useEffect(() => {
    const isDisabled = isLocked || noContact

    setSendDisabled(isDisabled)
  }, [isLocked, noContact])

  useEffect(() => {
    const isOnload = new URLSearchParams(window.location.search).has('onload')

    if (
      contact?.id &&
      !uiStore.isFocusingMode &&
      !isConversationNew &&
      !isOnload &&
      !layoutStore.isMobileView
    ) {
      setTimeout(setFocusMessageFieldTrigger)
    }
  }, [contact?.id])

  const additionalContent = isActiveMessageSignature && (
    <div className={styles.wrapTextField}>
      <MessageSignatureEditor store={messageSignatureStore} />
    </div>
  )

  useEffect(() => {
    if (ref.current) {
      const upgradeButtons = ref.current.querySelectorAll('[data-type="upgrade-button"]')
      upgradeButtons.forEach((button, index) => {
        if (index > 0) {
          button.setAttribute('style', 'display: none;')
        }
      })
    }
  })

  const handleInView = (inView: boolean) => {
    if (inView && layoutStore.isMobileView) {
      conversationMessageFieldStore.handleRead()
    }
  }

  return (
    <InView onChange={handleInView} threshold={1}>
      {({ ref: refView }) => (
        <div ref={refView}>
          <div
            className={classNames(styles.messageFieldWrap, {
              [styles.intercomWidget]: !IS_APP_LIGHT && isCollapse && isMobileViewWithoutMSize,
            })}
            ref={ref}
          >
            <MessageField
              messageFieldStore={messageFieldStore}
              additionalContent={additionalContent}
              alertsContent={<ConversationMessageFieldAlerts />}
              isCanCollapse
            />
          </div>
        </div>
      )}
    </InView>
  )
})
