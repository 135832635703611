// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VwgI6FGwEI9qH8CXsnRZ{display:flex;flex-direction:column;gap:2px}.VwgI6FGwEI9qH8CXsnRZ .YgjhQKhpRfXuOgrrjhmQ{width:100%;padding:8px 8.5px !important;color:var(--content-primary-primary)}.tzRHbZjTNejdLcQAJBIm{padding:8.5px 8px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/Menu/StatusSection/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,4CACE,UAAA,CACA,4BAAA,CACA,oCAAA,CAIJ,sBACE,iBAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n\n  .status {\n    width: 100%;\n    padding: 8px 8.5px !important;\n    color: var(--content-primary-primary);\n  }\n}\n\n.title {\n  padding: 8.5px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "VwgI6FGwEI9qH8CXsnRZ",
	"status": "YgjhQKhpRfXuOgrrjhmQ",
	"title": "tzRHbZjTNejdLcQAJBIm"
};
export default ___CSS_LOADER_EXPORT___;
