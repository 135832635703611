import classNames from 'classnames'
import { ReactNode } from 'react'
import { Icon, Tooltip, Typography } from 'shared/ui'

import styles from './styles.module.scss'

export type IModalInfoRowProps = {
  label: ReactNode
  value: ReactNode
  tooltip?: ReactNode
}

export const ModalInfoRow = ({ label, value, tooltip }: IModalInfoRowProps) => (
  <>
    <Typography
      ariaLabel={`${typeof label === 'string' ? label : 'modalLabel'}`}
      variant='text-input-field'
      className={styles.label}
    >
      {label}
      {tooltip && (
        <Tooltip label={tooltip} placement='top'>
          <Icon icon='question' color='var(--content-neutral-primary)' fontSize={14} />
        </Tooltip>
      )}
    </Typography>
    <div className={classNames(styles.value, styles.textValue)}>
      <Typography
        variant='text-input-field-r'
        color='var(--content-primary-primary)'
        ariaLabel={`${label}_value`}
      >
        {value}
      </Typography>
    </div>
  </>
)
