import { Modifier, Options } from '@popperjs/core/lib/types'

export const availableHeight = (
  ariaLabel: string
): Partial<Modifier<'availableHeight', Options>> => ({
  name: 'availableHeight',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    const reference = state.rects.reference
    const popper = state.rects.popper
    const list: HTMLDivElement | null = state.elements.popper.querySelector(
      `[aria-label=${ariaLabel}]`
    )
    const windowHeight = document.documentElement.clientHeight
    if (state.placement.includes('bottom')) {
      const availableHeight = windowHeight - reference.height - reference.y - 4
      const yOffset = availableHeight - popper.height
      if (yOffset < 0) {
        state.styles.popper.height = `${availableHeight}px`

        if (list) {
          list.style.maxHeight = `${availableHeight - 4}px`
          list.style.overflowY = 'auto'
        }
      }
    }
    if (state.placement.includes('top')) {
      const availableHeight = reference.y - 4
      const yOffset = availableHeight - popper.height
      if (yOffset < 0) {
        state.styles.popper.height = `${availableHeight}px`

        if (list) {
          list.style.maxHeight = `${availableHeight - 4}px`
          list.style.overflowY = 'auto'
        }
      }
    }
  },
})
