import { observer } from 'mobx-react-lite'
import { CommonOptInMethod } from 'pages/settings/pages/compliance/ui/CommonOptInMethod/CommonOptInMethod'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'

export const OptInMethod = observer(() => {
  const { optInMethodStore, stepsStore } = useTollFreeStepsContext()
  const { stepLoading } = stepsStore
  const { isNextDisabled, setOptInMethods, isOptInMethodActive, nextStepClick } = optInMethodStore

  return (
    <CommonOptInMethod
      stepLoading={stepLoading}
      isNextDisabled={isNextDisabled}
      onNextClick={() => nextStepClick()}
      isOptInMethodActive={isOptInMethodActive}
      setOptInMethods={setOptInMethods}
    />
  )
})
