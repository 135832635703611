import classnames from 'classnames'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, ProgressBar, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { organizationStore } from 'entities/Organization'
import { TrialProgressStore } from 'entities/Subscription/ui/TrialProgress/store/trialProgressStore'
import styles from './styles.module.scss'

export const TrialProgress = observer(() => {
  const [store] = useState(() => new TrialProgressStore())
  const step = store.step

  useEffect(() => {
    store.init()
  }, [])

  if (store.hide) return null

  const colors = () => {
    if (step <= 3) return 'red'
    if (step <= 7) return 'yellow'

    return 'default'
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Typography
          tag={'div'}
          variant={'body-md-regular'}
          className={styles.title}
          ariaLabel={'Trial ends'}
        >
          Trial ends in {organizationStore.trialEndsAtString}
        </Typography>
        <div className={styles.progressWrap}>
          <ProgressBar
            percent={store.percent}
            className={classnames(styles.progress, {
              [styles[colors()]]: true,
            })}
          />
        </div>
        <div className={styles.button}>
          <Button
            intent='positive'
            text={'Upgrade'}
            fullWidth
            onClick={() => {
              uiStore.changeRoute({
                path: '/settings/billing/overview/upgrade',
                type: 'vue',
              })
            }}
          />
        </div>
      </div>
    </div>
  )
})
