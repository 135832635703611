import { createContext, useContext } from 'react'
import { CampaignsPageStore } from 'pages/campaigns/store/campaignsPageStore'

export const CampaignsPageContext = createContext<CampaignsPageStore | null>(null)

export const useCampaignsPageContext = () => {
  const context = useContext(CampaignsPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignsPageContext')

  return context
}
