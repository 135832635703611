// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xt13O5l4jSMM7s18wfHW{display:flex;flex-direction:column;gap:4px}.d4ufaRsywwd2y6m_6kn8{display:flex;align-items:center;gap:4px}.OlbdM7L4D4dcUpDXeCph{display:grid;padding-top:var(--collapse-container-padding-top, 32px);padding-right:var(--collapse-container-padding-right, 20px);padding-bottom:var(--collapse-container-padding-bottom, 48px);padding-left:var(--collapse-container-padding-left, 20px);margin-bottom:var(--collapse-container-margin-bottom, 10px);border-bottom:1px solid var(--collapse-container-border-color, var(--border-neutral-default))}.ZuIi3b20LSljj8Itqrg8{display:flex;align-items:center;justify-content:center;width:32px;height:32px;background-color:var(--black-10);border-radius:6px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Collapse/CollapseWithIcon/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,uDAAA,CACA,2DAAA,CACA,6DAAA,CACA,yDAAA,CACA,2DAAA,CACA,6FAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,gCAAA,CACA,iBAAA","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.content {\n  display: grid;\n  padding-top: var(--collapse-container-padding-top, 32px);\n  padding-right: var(--collapse-container-padding-right, 20px);\n  padding-bottom: var(--collapse-container-padding-bottom, 48px);\n  padding-left: var(--collapse-container-padding-left, 20px);\n  margin-bottom: var(--collapse-container-margin-bottom, 10px);\n  border-bottom: 1px solid var(--collapse-container-border-color, var(--border-neutral-default));\n}\n\n.iconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  height: 32px;\n  background-color: var(--black-10);\n  border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Xt13O5l4jSMM7s18wfHW",
	"header": "d4ufaRsywwd2y6m_6kn8",
	"content": "OlbdM7L4D4dcUpDXeCph",
	"iconWrapper": "ZuIi3b20LSljj8Itqrg8"
};
export default ___CSS_LOADER_EXPORT___;
