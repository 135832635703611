import type { KnowledgeBase } from 'entities/KnowledgeBase'

export const getDescriptionForDeleteModal = (ids: number[], knowledgeBase: KnowledgeBase) => {
  const { chatbotsCount, documentsCount } = knowledgeBase
  const isMultipleFiles = ids.length > 1
  const isRemovingAllDocuments = ids.length === documentsCount

  if (chatbotsCount === 0) {
    return 'This action cannot be undone.'
  }

  if (isMultipleFiles) {
    if (isRemovingAllDocuments) {
      return 'These files are used by some AI textbots. Deleting them will disable those textbots and may affect responses. This action cannot be undone.'
    }
    if (chatbotsCount > 1) {
      return 'These files are used by multiple AI textbots in the knowledge base. Deleting them may affect their responses. This action cannot be undone.'
    } else {
      return 'These files are used by AI textbot. Deleting them may affect the responses provided. This action cannot be undone.'
    }
  } else {
    if (isRemovingAllDocuments) {
      return 'This file is used by some AI textbots. Deleting it will disable those textbots and may affect responses. This action cannot be undone.'
    }
    if (chatbotsCount > 1) {
      return 'This file is used by multiple AI textbots in the knowledge base. Deleting it may affect their responses. This action cannot be undone.'
    } else {
      return 'This file is used by AI textbot. Deleting it may affect the responses provided. This action cannot be undone.'
    }
  }
}
