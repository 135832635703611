import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { IRow, Table } from 'shared/ui'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { getColumns } from 'widgets/CallHistoryTable/ui/getColumns'
import { CallHistoryStore } from 'widgets/CallHistory'
import styles from './styles.module.scss'

const getRowLink = (row: IRow<CallHistory>) => {
  return String(row.conversation.id)
}

type ICallHistoryTableProps = {
  callHistoryStore: CallHistoryStore
  noPagination?: boolean
}

export const CallHistoryTable: FC<ICallHistoryTableProps> = observer(({ callHistoryStore }) => {
  const { items, total, page, loading, limit, onPaginationModelChange, callHistoryTableStore } =
    callHistoryStore
  const {
    markAsRead,
    visibleColumnsIds,
    hideExpandColumnsIds,
    updateSelectedColumns,
    getSelectedColumns,
    toggleSortOrder,
    changeSortBy,
    sortOrder,
    sortBy,
  } = callHistoryTableStore

  const onRowClick = (callItem: CallHistory) => {
    if (callItem.isUnread) {
      markAsRead(callItem)
    }
  }

  const onSort = (field: string | number) => {
    toggleSortOrder()
    changeSortBy(field as string)
  }

  useEffect(() => {
    if (!visibleColumnsIds.length) {
      getSelectedColumns()
    }
  }, [])

  return (
    <div className={styles.tableWrap}>
      <Table
        columns={getColumns(visibleColumnsIds, callHistoryStore)}
        pinnedColumnNames={['contact']}
        rows={items}
        getRowLink={getRowLink}
        rowsCount={total}
        isLoading={loading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        visibleColumnsIds={visibleColumnsIds}
        setVisibleColumnsIds={updateSelectedColumns}
        limit={limit}
        startPaginationSize={10}
        hideExpandColumnsIds={hideExpandColumnsIds}
        sxTable={{ marginRight: 2, width: 'calc(100% - 16px)' }}
        onRowClick={onRowClick}
        onSort={onSort}
        sortOrder={sortOrder}
        sortBy={sortBy}
      />
    </div>
  )
})
