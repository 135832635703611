// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rOHZlDeo9pGKYNaoekvg{display:flex;flex-direction:column;gap:8px}.Qy5Y8KACdymGK4g7Zcr1{width:100%;text-align:center;color:var(--content-primary-secondary)}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignView/ui/Preview/PreviewMessage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,sBACE,UAAA,CACA,iBAAA,CACA,sCAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.scheduleText {\n  width: 100%;\n  text-align: center;\n  color: var(--content-primary-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rOHZlDeo9pGKYNaoekvg",
	"scheduleText": "Qy5Y8KACdymGK4g7Zcr1"
};
export default ___CSS_LOADER_EXPORT___;
