import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'

const LocalNumbersLayoutContent = observer(() => {
  return (
    <>
      <Outlet />
    </>
  )
})

export const LocalNumbersLayout = () => {
  return <LocalNumbersLayoutContent />
}
