import { ComponentType, HTMLAttributes, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { type IDropdownItem, Dropdown } from 'shared/ui/Dropdown'
import styles from './styles.module.scss'

interface ItemWithLabel {
  label?: string
  name?: string
}

interface IItemsWithCountProps<T> {
  items: T[]
  itemComponent: ComponentType<{ item: T } & HTMLAttributes<HTMLElement>>
  containerWidth: number
  withLeftIcon?: boolean
  width?: number
  disabled?: boolean
}

export const ItemsWithCount = <T extends ItemWithLabel>({
  items,
  itemComponent: ItemComponent,
  containerWidth = 160,
  withLeftIcon = false,
  width = 110,
  disabled = false,
}: IItemsWithCountProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const itemRefs = useRef<(HTMLDivElement | null)[]>([])
  const [canShowCount, setCanShowCount] = useState(0)
  const [reloadContent, setReloadContent] = useState(false)

  const calculateCanShowCount = () => {
    if (containerRef.current) {
      // 40 is width of '+ with count' block
      const containerWidth = containerRef.current.offsetWidth - 40
      let totalWidth = 0
      let count = 0

      items.forEach((_, index) => {
        const itemWidth = itemRefs.current[index]?.offsetWidth ?? 0
        totalWidth += itemWidth

        if (totalWidth <= containerWidth) {
          count++
        }
      })

      setCanShowCount(count)
    }
  }

  useEffect(() => {
    calculateCanShowCount()
    setReloadContent(true)
  }, [])

  useEffect(() => {
    calculateCanShowCount()
    setReloadContent(true)
  }, [containerWidth])

  useEffect(() => {
    if (reloadContent) {
      calculateCanShowCount()
      // We can get width of ItemComponent only after load component to layout.
      // It's a reason to load content twice
      setReloadContent(false)
    }
  }, [reloadContent])

  const remainderCount = items.length - canShowCount
  const showedItems = items.slice(0, canShowCount)
  const hidedItems = items.slice(canShowCount).reduce((acc, item, index) => {
    if (item.name || item.label) {
      const updatedItem: IDropdownItem = {
        id: index,
        label: item.name || item.label || '',
        parentTooltipProps: {
          label: item.name || item.label || '',
          placement: 'left',
          fullWidth: true,
        },
      }

      if (withLeftIcon) {
        updatedItem.customFields = {
          leftIconColor: 'var(--green-60)',
        }
        updatedItem.iconL = 'tagCircle'
      }

      acc.push(updatedItem)
    }

    return acc
  }, [] as IDropdownItem[])

  return (
    <div
      style={{ width: `${containerWidth}px` }}
      ref={containerRef}
      className={classNames(styles.itemsContainer, { [styles.disabled]: disabled })}
    >
      {showedItems.map((item, index) => (
        <div key={index} ref={(ref) => (itemRefs.current[index] = ref)}>
          <ItemComponent item={item} />
        </div>
      ))}
      {remainderCount > 0 && (
        <Dropdown
          ariaLabel={'ItemsWithCount'}
          width={width}
          triggerComponent={() => <div className={styles.remainderCount}>+{remainderCount}</div>}
          items={hidedItems}
          placement='bottom-end'
          disabled={disabled}
        />
      )}
    </div>
  )
}
