import { makeAutoObservable } from 'mobx'
import { UpgradePlanModalFinishStore } from './upgradePlanModalFinishStore'

export class UpgradePlanModalStore {
  private _upgradePlanModalFinishStore = new UpgradePlanModalFinishStore()

  constructor() {
    makeAutoObservable(this)
  }

  openFinishModal = (planId: string) => {
    this._upgradePlanModalFinishStore.openFinishModal(planId)
  }
}
