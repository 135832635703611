import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { Collapse, InputCheckbox, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import { LearnMoreLink } from 'pages/settings/pages/compliance/ui/LearnMoreLink/LearnMoreLink'
import { forbiddenTopics } from 'pages/settings/pages/compliance/ui/ForbiddenMessageCommonTopics/lib/constants'
import { BlockNumber } from 'pages/settings/pages/compliance/ui/BlockNumber/BlockNumber'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import styles from './styles.module.scss'

type IForbiddenMessageCommonTopicsProps = {
  errorAlert?: ReactNode
  stepLoading: boolean
  forbiddenMessageAgreed: boolean
  isNextStepDisabled: boolean
  nextButtonText?: string
  setForbiddenMessageAgreed: () => void
  increaseStep: () => Promise<void>
}

export const ForbiddenMessageCommonTopics = observer(
  ({
    errorAlert,
    stepLoading,
    isNextStepDisabled,
    forbiddenMessageAgreed,
    nextButtonText = 'Next',
    setForbiddenMessageAgreed,
    increaseStep,
  }: IForbiddenMessageCommonTopicsProps) => {
    return (
      <div className={styles.forbiddenTopicsWrap}>
        <PageDescription>
          Certain topics are not permitted over SMS / MMS in the United States or Canada. Activity
          related to these topics may result in your messages being blocked by carriers.
          <LearnMoreLink href={links.complianceLearnMoreLink}>Learn more</LearnMoreLink>
        </PageDescription>

        {errorAlert}

        <div className={styles.topicsWrap}>
          {forbiddenTopics.map((topic) => (
            <Collapse
              key={topic.number}
              isOpen={false}
              title={topic.title}
              titleVariant='body-lg-medium'
              type='withHover'
              headerClassName={styles.headerClassName}
              leftContent={(isOpen) => <BlockNumber number={topic.number} isOpen={isOpen} />}
            >
              <div className={styles.collapseContent}>
                {topic.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    ariaLabel='forbiddenMessageTopics_line'
                    variant='body-rg-regular'
                    color='var(--content-primary-primary)'
                  >
                    {paragraph}
                  </Typography>
                ))}
              </div>
            </Collapse>
          ))}
        </div>

        <InputCheckbox
          className={styles.forbiddenMessageAgreed}
          label='I confirm I will not send messages related to these topics'
          checked={forbiddenMessageAgreed}
          onChecked={setForbiddenMessageAgreed}
        />

        <NextButton
          loading={stepLoading}
          disabled={isNextStepDisabled || stepLoading}
          text={nextButtonText}
          onClick={increaseStep}
        />
      </div>
    )
  }
)
