import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Status } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { ChatbotEnrollStore } from 'widgets/ChatbotEnroll/store/ChatbotEnrollStore'

type IChatbotEnrollButtonProps = {
  contactIds: number[]
}

export const ChatbotEnrollButton = observer(({ contactIds }: IChatbotEnrollButtonProps) => {
  const [loading, setLoading] = useState(false)
  const store = useMemo(() => new ChatbotEnrollStore(featureFlagsStore), [])

  const handleClick = () => {
    setLoading(true)
    store.getValidContactIds(contactIds).then((validContactIds) => {
      setLoading(false)

      if (validContactIds) store.enrollContacts(validContactIds)
    })
  }

  useEffect(() => store.checkActivity(), [])

  if (!store.isOperationEnabled) return null

  return (
    <Button
      text='Engage with Textbot'
      icon='chatBot'
      typeBtn='contained'
      contained='tertiary'
      size='small'
      textRight={<Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />}
      onClick={handleClick}
      loading={loading}
    />
  )
})
