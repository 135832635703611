import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { IOpportunity } from 'entities/Integrations'
import { IntegrationKey } from 'entities/Integrations/model/Integration'

import styles from '../styles.module.scss'

type IItemRowProps = {
  data: IOpportunity
  integrationKey: IntegrationKey | null
}

type IRowItem = {
  id: number | string
  label: string
  value: number | string | null
}

const ItemRow = ({ data, integrationKey }: IItemRowProps) => {
  let rows: IRowItem[] = [
    {
      id: 1,
      label: 'Stage',
      value: data.stage,
    },
    {
      id: 2,
      label: 'Owner',
      value: data.owner,
    },
  ]

  const salesforceRows = [
    {
      id: 1,
      label: 'Amount',
      value: data.amount,
    },
    {
      id: 2,
      label: 'Stage',
      value: data.stage,
    },
  ]

  if (integrationKey === 'salesforce') {
    rows = salesforceRows
  }

  return (
    <div>
      {rows.map((item) => (
        <div className={styles.itemRow} key={item.id}>
          <div className={styles.label}>{item.label}</div>
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value != null ? String(item.value) : '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { ItemRow }
