import { ReactNode, FC } from 'react'
import styles from './styles.module.scss'
import {
  Dropdown,
  DropdownGroup,
  DropdownTextInput,
  type IDropdownItem,
  DropdownContent,
} from '../Dropdown'
import { type ITextFieldProps, makeTextFieldIcon } from '../TextField'
import { Error } from '../Error'

interface ISearchDropdownInputProps {
  items: IDropdownItem[]
  tipTitle?: string
  search: string
  width?: number
  errorMessageValue?: string
  textFieldProps?: ITextFieldProps
  customDropdownItem?: (item: IDropdownItem, parent?: IDropdownItem) => ReactNode
  onChangeSearch: (value: string) => void
  onSetValue: (item: IDropdownItem, parent?: IDropdownItem) => void
  isNoResults?: boolean
  noResultsMessage?: ReactNode
}

export const SearchDropdownInput: FC<ISearchDropdownInputProps> = ({
  items,
  tipTitle,
  search,
  width,
  errorMessageValue,
  textFieldProps,
  onChangeSearch,
  onSetValue,
  isNoResults = false,
  noResultsMessage,
}) => {
  const isShowGroup = () => {
    if (!search.length && tipTitle) return true

    return Boolean(items.length)
  }

  return (
    <>
      <Dropdown
        {...(width ? { width } : {})}
        margin={0}
        triggerComponent={() => (
          <DropdownTextInput
            dropdownTextInputClassName={styles.dropdownTextInput}
            errorMessageValue={errorMessageValue}
            withFocus={false}
            textFieldProps={{
              className: styles.input,
              variant: 'stroke',
              value: search,
              onChange: onChangeSearch,
              InputProps: {
                placeholder: 'Search',
                ...textFieldProps?.InputProps,
              },
              leftActions: [
                makeTextFieldIcon({
                  icon: 'search',
                  color: 'var(--content-primary-tertiary)',
                  fontSize: 16,
                }),
              ],
              ...textFieldProps,
            }}
          />
        )}
        customComponent={(onClose) => (
          <>
            {isShowGroup() && !isNoResults && (
              <DropdownGroup
                title={!search.length && tipTitle ? tipTitle : ''}
                items={items}
                onChange={(item) => {
                  onClose()
                  onSetValue(item)
                }}
                stylesWrap={{
                  borderRadius: '8px',
                  width: '100%',
                  background: 'var(--background-primary-inverted-primary)',
                  boxShadow:
                    '0px 20px 50px 0px rgba(18, 21, 23, 0.05), 0px 10px 30px -5px rgba(18, 21, 23, 0.10), 0px 0px 0px 1px rgba(18, 21, 23, 0.05)',
                }}
              />
            )}
            {isNoResults && <DropdownContent>{noResultsMessage}</DropdownContent>}
          </>
        )}
      />
      <Error type='text' error={errorMessageValue} size='small' wrapClassName={styles.error} />
    </>
  )
}
