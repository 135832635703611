import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Box, EmptyCell, IColumn, IconButton, Table, Tooltip, Typography } from 'shared/ui'
import { IResponseGetHubspotLists } from 'entities/Integrations'
import { type IntegrationHubspotSettingsListsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsListsStore'
import { HubspotListDropdown } from './HubspotListDropdown'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsListsProps = {
  store: IntegrationHubspotSettingsListsStore
}

export const IntegrationHubspotSettingsLists = observer(
  ({ store }: IIntegrationHubspotSettingsListsProps) => {
    const {
      lists,
      allContactslists,
      setHubspotList,
      isLoading,
      onRemoveList,
      setAllLists,
      isAllContactslistsEmpty,
    } = store

    const COLUMNS: IColumn<IResponseGetHubspotLists>[] = useMemo(
      () => [
        {
          field: 'list',
          name: 'HubSpot List',
          width: '66%',
          renderRowCell: ({ name }) => (
            <Typography variant={'body-md-regular'} ellipsis ariaLabel={'ListsName'}>
              {name}
            </Typography>
          ),
        },
        {
          field: 'size',
          name: 'Number of contacts',
          renderRowCell: (row) => (
            <div className={styles.row}>
              <Typography variant={'body-md-regular'} ariaLabel={'ListsSize'}>
                {row.size || 0}
              </Typography>
              <Tooltip label={'Remove list'} placement={'top'}>
                <IconButton
                  variant={'icon'}
                  color={'tertiary'}
                  size={'large'}
                  icon={'delete'}
                  onClick={() => onRemoveList(row.list_id)}
                  ariaLabel='HubspotSettingsLists_remove'
                />
              </Tooltip>
            </div>
          ),
        },
      ],
      []
    )

    return (
      <>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant={'body-lg-medium'} ariaLabel={'Lists'}>
            Lists
          </Typography>
          <HubspotListDropdown
            allLists={allContactslists}
            setList={setHubspotList}
            setAllLists={setAllLists}
            isListsEmpty={isAllContactslistsEmpty}
          />
        </Box>
        {lists.length > 0 && (
          <div className={styles.listsViewWrap}>
            <div className={classNames(styles.table, { [styles.loading]: isLoading })}>
              <Table
                columns={COLUMNS}
                rows={lists}
                scrollbarProps={{
                  autoHeight: true,
                  autoHeightMin: 160,
                  autoHeightMax: 610,
                  viewClassName: styles.viewScrollClassName,
                }}
                noPagination
              />
            </div>
          </div>
        )}
        {!lists.length && (
          <div className={styles.noListsWrap} aria-label={'empty_placeholder'}>
            No HubSpot lists
          </div>
        )}
      </>
    )
  }
)
