import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  IMiniContactCardProps,
  MiniContactCard,
} from 'entities/Contacts/ui/ContactCard/MiniContactCard/MiniContactCard'
import {
  DefaultContactCard,
  IDefaultContactCard,
} from 'entities/Contacts/ui/ContactCard/DefaultContactCard/DefaultContactCard'

type IContactCardProps = IDefaultContactCard | IMiniContactCardProps

export const ContactCard: React.FC<IContactCardProps> = observer((props) => {
  if (props.size === 'mini') {
    return <MiniContactCard {...props} />
  }
  return <DefaultContactCard {...props} />
})
