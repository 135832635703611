import { FC, ReactNode } from 'react'
import { ModalLargeIcon } from 'shared/ui/Icon/ModalLargeIcon/ModalLargeIcon'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { Button, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type INoAvailableTabContentProps = {
  infoText: ReactNode
  editAnswers: () => void
}

export const NoAvailableTabContent: FC<INoAvailableTabContentProps> = ({
  infoText,
  editAnswers,
}) => {
  return (
    <div className={styles.noAvailableTab}>
      <ModalLargeIcon type={ModalTypeList.INFO} />
      <Typography ariaLabel='info' variant={'body-lg-regular'}>
        {infoText}
      </Typography>

      <Button
        className={styles.noAvailableTab__btn}
        text={'Edit answers'}
        contained={'secondary'}
        size={'medium'}
        typeBtn={'stroke'}
        onClick={editAnswers}
      />
    </div>
  )
}
