// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NIMtA6hE7N45QTiRZYOH{display:flex;align-items:center;justify-content:center;margin-right:10px}.ZRDNjv4A90q07IDeOG4r{color:var(--content-primary-primary);display:flex;align-items:center;gap:8px;padding:8px 12px;cursor:pointer}.ZRDNjv4A90q07IDeOG4r *{pointer-events:none}", "",{"version":3,"sources":["webpack://./src/pages/keywords/pages/all/ui/KeywordsTable/TableActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,sBACE,oCAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,gBAAA,CACA,cAAA,CAEA,wBACE,mBAAA","sourcesContent":[".switchWrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 10px;\n}\n\n.activeAction {\n  color: var(--content-primary-primary);\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 12px;\n  cursor: pointer;\n\n  & * {\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchWrap": "NIMtA6hE7N45QTiRZYOH",
	"activeAction": "ZRDNjv4A90q07IDeOG4r"
};
export default ___CSS_LOADER_EXPORT___;
