import { showToast } from 'shared/ui/Toast/lib/showToast'
import { IResponseEventRegisterOrganizationError } from 'entities/Auth/api/types'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { type RegisterMultiorgStore } from '../store/registerMultiorgStore'

const RegisterOrganizationError = {
  handle: (data: IResponseEventRegisterOrganizationError, store?: RegisterMultiorgStore) => {
    if (store) {
      showToast({
        type: 'error',
        title: data.error_message,
      })
      store.setLoading(false)
    }
    console.error(data)
  },
}

export const EventRegisterOrganizationError = {
  subscribe: (store: RegisterMultiorgStore) =>
    websocket.on(
      WebsocketEvent.RegisterOrganizationError,
      (data: IResponseEventRegisterOrganizationError) =>
        RegisterOrganizationError.handle(data, store)
    ),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.RegisterOrganizationError, RegisterOrganizationError.handle),
}
