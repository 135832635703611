import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon } from 'shared/ui'
import { CampaignSchedule } from 'pages/campaigns/ui/CampaignSchedule'
import { useCampaignMessageContext } from '../context/context'
import { IMessageContentProps, MessageContent } from './MessageContent/MessageContent'
import styles from './styles.module.scss'

export type ICampaignMessageUiProps = {
  isDragging: boolean
} & IMessageContentProps

export const CampaignMessageUi = observer(({ ...props }: ICampaignMessageUiProps) => {
  const { campaignScheduleStore } = useCampaignMessageContext()
  return (
    <div className={classNames(styles.wrap, { [styles.isDragging]: props.isDragging })}>
      <div className={styles.scheduleWrap}>
        <div className={styles.wrapArrow}>
          <div className={styles.wrapLine}>
            <div className={styles.line} />
          </div>
        </div>

        <CampaignSchedule store={campaignScheduleStore} />

        <div className={styles.wrapArrow}>
          <Icon icon={'union'} fontSize={20} color={'var(--black-30)'} />
        </div>
      </div>
      <MessageContent {...props} />
    </div>
  )
})
