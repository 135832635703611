import { makeAutoObservable } from 'mobx'
import type { IResponseKnowledgeBaseDocument } from 'entities/KnowledgeBase'

export enum KnowledgeBaseDocumentStatus {
  Uploading = 0,
  UploadingSuccess = 1,
  UploadingFailed = 2,
  Indexation = 100,
  IndexationSuccess = 101,
  IndexationFailed = 102,
  Complete = 200,
  Failed = 300,
}

export class KnowledgeBaseDocument {
  id: number
  name: string
  knowledgeBaseId: number
  status: KnowledgeBaseDocumentStatus
  contentType: string
  extension: string | null = null
  size: number | null = null
  uploadedAt: string
  createdAt: string

  constructor(document: IResponseKnowledgeBaseDocument) {
    this.id = document.id
    this.name = document.name
    this.knowledgeBaseId = document.id
    this.status = document.status
    this.contentType = document.content_type
    this.extension = document.extension
    this.size = document.size
    this.uploadedAt = document.uploaded_at || ''
    this.createdAt = document.created_at || ''

    makeAutoObservable(this)
  }

  getIsUploading = () => {
    return this.status === KnowledgeBaseDocumentStatus.Uploading
  }

  getIsUploadingSuccess = () => {
    return this.status === KnowledgeBaseDocumentStatus.UploadingSuccess
  }

  getIsUploadingFailed = () => {
    return this.status === KnowledgeBaseDocumentStatus.UploadingFailed
  }

  getIsIndexationFailed = () => {
    return this.status === KnowledgeBaseDocumentStatus.IndexationFailed
  }

  getIsIndexing = () => {
    return this.status === KnowledgeBaseDocumentStatus.Indexation
  }
}
