import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ServiceError } from 'shared/ui/ServiceError'

import BannerLayout from './layouts/BannerLayout'

const routes = createRoutesFromElements(
  <Route
    path='/*'
    element={<BannerLayout />}
    errorElement={<ServiceError name='banner_layout' />}
  />
)

const router = wrapCreateBrowserRouter(createBrowserRouter)(routes)

export const BannerService = () => <RouterProvider router={router} />
