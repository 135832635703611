import classNames from 'classnames'
import { Icon } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { Tag } from 'entities/Tags/model/Tag'

import styles from './styles.module.scss'

interface ITagCardProps {
  tag: Tag
  className?: string
  closable?: boolean
  onRemoveTag?: (tag: Tag) => void
}

export const TagCard = ({ tag, className, closable, onRemoveTag }: ITagCardProps) => {
  return (
    <div
      className={classNames(styles.tagWrapper, className)}
      aria-label={getAriaLabel('Added_tagsListItem')}
    >
      <span className={styles.tagColor} />
      <span className={styles.tagLabel}>{tag.label}</span>
      {!!closable && (
        <div
          className={styles.icon}
          onClick={() => onRemoveTag?.(tag)}
          aria-label={getAriaLabel('Remove', 'addedTagsListItem')}
        >
          <Icon icon='close' />
        </div>
      )}
    </div>
  )
}

interface ITagCardCountProps {
  count: number
  className?: string
}

export const TagCardCount = ({ count, className }: ITagCardCountProps) => {
  return (
    <div
      className={classNames(styles.tagWrapper, className)}
      aria-label={getAriaLabel('RestCount_tagsListItem')}
    >
      +{count}
    </div>
  )
}
