// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xOUvZuwALONUsn4G1OFw .jVR7MOtORnHpiBW1tIkn{height:44px;font-size:14;font-weight:500}.xOUvZuwALONUsn4G1OFw .jVR7MOtORnHpiBW1tIkn.pUjpmAyEmyuwoJXzwBAm{margin-top:40px}.xOUvZuwALONUsn4G1OFw .edX3KuYqGtumVztVF_fI{margin-bottom:24px}.xOUvZuwALONUsn4G1OFw .FauLG05SvWaBuwfBzwYL{padding-top:12px;text-align:center;color:var(--content-primary-tertiary)}.xOUvZuwALONUsn4G1OFw .Hjhg6WH4lqWa0rTra6G4{margin:32px 0}.xOUvZuwALONUsn4G1OFw .LI4XsYRiogP1TXS_jpJl{padding:12px;font-weight:400;font-size:14px;line-height:20px}.xOUvZuwALONUsn4G1OFw .LI4XsYRiogP1TXS_jpJl.edX3KuYqGtumVztVF_fI{margin-bottom:4px}.xOUvZuwALONUsn4G1OFw .L1bk3pGp_D7GP0auWShR{margin-top:20px;margin-bottom:4px}.xOUvZuwALONUsn4G1OFw .zVin0n8JT85vPdaXFa2_{margin-top:12px;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/widgets/Register/ui/StepSignUp/ui/SignUpForm/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,WAAA,CACA,YAAA,CACA,eAAA,CAEA,iEACE,eAAA,CAIJ,4CACE,kBAAA,CAGF,4CACE,gBAAA,CACA,iBAAA,CACA,qCAAA,CAGF,4CACE,aAAA,CAGF,4CACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,iBAAA,CAIJ,4CACE,eAAA,CACA,iBAAA,CAGF,4CACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".wrap {\n  .button {\n    height: 44px;\n    font-size: 14;\n    font-weight: 500;\n\n    &.signUp {\n      margin-top: 40px;\n    }\n  }\n\n  .error {\n    margin-bottom: 24px;\n  }\n\n  .termsAndPolicy {\n    padding-top: 12px;\n    text-align: center;\n    color: var(--content-primary-tertiary);\n  }\n\n  .divider {\n    margin: 32px 0;\n  }\n\n  .textField {\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.error {\n      margin-bottom: 4px;\n    }\n  }\n\n  .inputLabel {\n    margin-top: 20px;\n    margin-bottom: 4px;\n  }\n\n  .bottom {\n    margin-top: 12px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "xOUvZuwALONUsn4G1OFw",
	"button": "jVR7MOtORnHpiBW1tIkn",
	"signUp": "pUjpmAyEmyuwoJXzwBAm",
	"error": "edX3KuYqGtumVztVF_fI",
	"termsAndPolicy": "FauLG05SvWaBuwfBzwYL",
	"divider": "Hjhg6WH4lqWa0rTra6G4",
	"textField": "LI4XsYRiogP1TXS_jpJl",
	"inputLabel": "L1bk3pGp_D7GP0auWShR",
	"bottom": "zVin0n8JT85vPdaXFa2_"
};
export default ___CSS_LOADER_EXPORT___;
