import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'

export class ForbiddenMessageStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _forbiddenMessageAgreed = false

  get forbiddenMessageAgreed() {
    return this._forbiddenMessageAgreed
  }

  get isNextStepDisabled() {
    return !this._forbiddenMessageAgreed
  }

  setForbiddenMessageAgreed = () => {
    this._forbiddenMessageAgreed = !this._forbiddenMessageAgreed
  }

  onSubmit = async () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.tollFree.root}`,
    })
  }
}
