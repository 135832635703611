import { FC } from 'react'
import classNames from 'classnames'
import { type InputRadioProps } from 'shared/ui/InputRadio'
import { Typography } from 'shared/ui/Typography'
import { QuestionSectionItems } from './QuestionSectionItems'
import styles from './styles.module.scss'

type IQuestionBlockProps = {
  header: string
  headerDesc?: string
  number: string
  items: InputRadioProps[]
  containerClassName?: string
}

export const QuestionSection: FC<IQuestionBlockProps> = ({
  header,
  headerDesc,
  number,
  items,
  containerClassName,
}) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <Typography
        ariaLabel='QuestionBlock_number'
        variant='body-sm-medium'
        color='var(--content-primary-primary)'
        className={styles.number}
        width={'24px'}
      >
        {number}
      </Typography>
      <div className={styles.questionBlock}>
        <div className={styles.questionBlock__content}>
          <Typography
            ariaLabel='QuestionBlock_header'
            variant='body-lg-medium'
            className={styles.header}
          >
            {header}
          </Typography>
          {headerDesc && (
            <Typography
              ariaLabel='QuestionBlock_header'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
              className={styles.headerDesc}
            >
              {headerDesc}
            </Typography>
          )}
        </div>

        <QuestionSectionItems items={items} />
      </div>
    </div>
  )
}
