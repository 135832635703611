import { observer } from 'mobx-react-lite'
import parse from 'html-react-parser'
import type { IQuickLinkTemplate } from 'entities/QuickLink'
import { textToHtml } from 'widgets/MessageField'

import styles from './styles.module.scss'

const MIN_NON_BREAKING_SIZE = 15
const REST_PART_SIZE = 5

const UrlPart = ({ part }: { part: string }) => {
  if (part.length < MIN_NON_BREAKING_SIZE) return <span className={styles.linkUrlRest}>{part}</span>

  const index = part.length - REST_PART_SIZE

  return (
    <>
      <span className={styles.linkUrlTrim}>{part.substring(0, index)}</span>
      <span className={styles.linkUrlRest}>{part.substring(index)}</span>
    </>
  )
}

export interface IQuickLinkUrlProps {
  template: IQuickLinkTemplate
}

export const QuickLinkUrl = observer(({ template }: IQuickLinkUrlProps) => {
  const content = parse(textToHtml({ text: template.url, ignoreLinks: true }), {
    replace: (node) => {
      if (node.type === 'text' && !node.parent) return <UrlPart part={node.data} />
    },
  })

  return <div className={styles.linkUrl}>{content}</div>
})
