import { makeAutoObservable, observable, reaction, type IReactionDisposer } from 'mobx'
import React from 'react'
import { IAIActionTypeEnum } from 'entities/AIAssistant'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { type IResponseMediaShort } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import {
  EmojiAction,
  EnumVariantMessageField,
  type IMessageFieldStoreProps,
  makeSavedReplyInputAction,
  mergeFieldReplacer,
  MessageFieldStore,
  SavedRepliesAction,
  ShortenLinkAction,
} from 'widgets/MessageField'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import { AIAssistantAction, AiAssistantStore } from 'widgets/AIAssistant'
import { type IMessageDataParams } from 'widgets/MessageFormFields/SMSMessageField/store/type'
import { SavedRepliesStore } from 'widgets/SavedReplies'
import { type MergeFieldProps } from 'widgets/MergeField'

export type ISMSMessageFieldStoreProps = {
  onFocus: () => void
  isCurrentAirCall?: boolean
  placeholder?: string
  messageFieldStoreProps?: IMessageFieldStoreProps
  mergeFieldsProps?: MergeFieldProps
}

export class SMSMessageFieldStore {
  messageFieldStore
  aiAssistantStore
  isDisabledObj = observable.object({ value: true })
  private _savedRepliesStore: SavedRepliesStore
  private _disposeMessageInnerText: IReactionDisposer | null = null

  constructor({
    onFocus,
    isCurrentAirCall,
    placeholder,
    mergeFieldsProps,
    messageFieldStoreProps,
  }: ISMSMessageFieldStoreProps) {
    this.messageFieldStore = new MessageFieldStore({
      isCurrentAirCall,
      placeholder: placeholder || 'Write your message...',
      styles: {
        minHeight: 106,
      },
      variant: EnumVariantMessageField.Underline,
      showActionsItems: 5,
      makeInputActions: (store) => [makeSavedReplyInputAction(store, this._savedRepliesStore)],
      makeEvents: () => ({
        onFocus,
      }),
      isShortUrlPostfix: true,
      ...messageFieldStoreProps,
    })
    this.aiAssistantStore = new AiAssistantStore(this.messageFieldStore, {
      actions: [
        IAIActionTypeEnum.Rephrase,
        IAIActionTypeEnum.MoreFriendly,
        IAIActionTypeEnum.MoreFormal,
        IAIActionTypeEnum.Expand,
        IAIActionTypeEnum.Shorten,
      ],
    })
    this._savedRepliesStore = new SavedRepliesStore({
      mergeFieldProps: mergeFieldsProps,
    })
    this._setActions(mergeFieldsProps)

    makeAutoObservable(this)

    this.reactionMessageInnerText()
  }

  dispose = () => {
    this._disposeMessageInnerText?.()
  }

  reactionMessageInnerText = () => {
    this._disposeMessageInnerText?.()
    this._disposeMessageInnerText = reaction(
      () => this.messageFieldStore.messageInnerText,
      (text) => {
        this.isDisabledObj.value = !text
      },
      {
        fireImmediately: true,
      }
    )
  }
  init = (message: string, media_url: IResponseMediaShort[]) => {
    this.messageFieldStore.setMessageData({
      attachments: media_url?.map((media) => new Attachment({ responseMediaShort: media })) || [],
      message: message || '',
      isFocus: false,
      isReset: false,
      isReplace: true,
    })
  }

  private _setActions = (mergeFieldsProps?: MergeFieldProps) => {
    this.messageFieldStore?.setActions([
      {
        iconButtonComponent: <MediaAction />,
      },
      {
        iconButtonComponent: <SavedRepliesAction store={this._savedRepliesStore} />,
      },
      {
        iconButtonComponent: <EmojiAction />,
      },
      {
        iconButtonComponent: (
          <AIAssistantAction
            aiAssistantStore={this.aiAssistantStore}
            placementMainContent={'bottom-start'}
            isDisabledObj={this.isDisabledObj}
          />
        ),
      },
      {
        iconButtonComponent: (
          <MergeFieldsIconAction
            onAddMergeField={(value) => this.onAddMergeField(value)}
            isAllConnectedOrDisconnectedIntegration
            {...mergeFieldsProps}
          />
        ),
      },
      {
        iconButtonComponent: <ShortenLinkAction />,
      },
    ])
  }

  get isError() {
    return (
      this.messageFieldStore.textLimitReached ||
      this.messageFieldStore.isAttachmentsCountLimitError ||
      (this.messageFieldStore.isAirCallNoSupportMms && !featureFlagsStore.aircall_new_api)
    )
  }

  get segments_count() {
    return this.messageFieldStore.messageRemainsInfo.messageCount
  }

  onAddMergeField = (mergeField: MergeField) => {
    if (this.messageFieldStore.addContent) {
      this.messageFieldStore.addContent(mergeFieldReplacer(mergeField.template) + '&nbsp;')
    }
  }

  get messageDataParams(): IMessageDataParams {
    return {
      media_url: this.messageFieldStore.media_url.map((item) => item.attachmentDraft),
      message: this.messageFieldStore.messageText,
    }
  }
}
