import classNames from 'classnames'
import { ReactNode, FC } from 'react'
import { Alert, Button, Typography } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'
import { openIntercom } from 'entities/Intercom'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { ModalHeader } from 'pages/settings/pages/compliance/ui/ModalHeader/ModalHeader'
import { IModalInfoRowProps } from 'pages/settings/pages/compliance/ui/ModalInfoRow/ModalInfoRow'
import { ModalInfoBlock } from 'pages/settings/pages/compliance/ui/ModalInfoBlock/ModalInfoBlock'
import styles from './styles.module.scss'

type IBusinessProfileModalContentProps = {
  editBusinessProfile: () => void
  errorMessage?: string
  statusData: ReactNode
  closeModal: () => void
  complianceLayerStore: ComplianceLayerStore
}

export const BusinessProfileModalContent: FC<IBusinessProfileModalContentProps> = ({
  errorMessage,
  statusData,
  closeModal,
  editBusinessProfile,
  complianceLayerStore,
}) => {
  const { businessInformation, pointsOfContact } = complianceLayerStore

  if (!businessInformation) return null

  const {
    business_type,
    industry,
    website_url,
    social_media_url,
    regions_of_operation,
    business_name,
    registration_number,
    street_address_1,
    country,
  } = businessInformation

  const firstContact = pointsOfContact.firstContact
  const secondContact = pointsOfContact.secondContact

  const formatRegistrationNumber = (value: string) => `${value.slice(0, 2)}-${value.slice(2)}`

  const generalInfoLines: IModalInfoRowProps[] = [
    { label: 'Legal business name', value: business_name },
    {
      label: 'EIN',
      value: formatRegistrationNumber(registration_number),
      tooltip: 'Employer Identification Number',
    },
    { label: 'Business address', value: street_address_1 },
    { label: 'Country', value: country },
  ]

  const businessInfoLines: IModalInfoRowProps[] = [
    { label: 'Business type', value: business_type.name },
    { label: 'Business industry', value: industry.name },
    {
      label: 'Business website URL',
      value: website_url ? <a href={website_url}>{website_url}</a> : '-',
    },
    {
      label: 'Social media profile',
      value: social_media_url ? <a href={social_media_url}>{social_media_url}</a> : <EmptyCell />,
    },
    {
      label: 'Regions of operation',
      value: (
        <div className={styles.regionsWrap}>
          {regions_of_operation.map(({ name }) => (
            <span className={styles.region} key={name}>
              {name}
            </span>
          ))}
        </div>
      ),
    },
  ]

  const firstContactLines: IModalInfoRowProps[] = [
    { label: 'Name', value: `${firstContact?.first_name} ${firstContact?.last_name}` },
    { label: 'Business email', value: firstContact?.email },
    { label: 'Business title', value: firstContact?.business_title },
    { label: 'Phone number', value: firstContact?.phone_number },
  ]

  const secondContactLines: IModalInfoRowProps[] = [
    { label: 'Name', value: `${secondContact?.first_name} ${firstContact?.last_name}` },
    { label: 'Business email', value: secondContact?.email },
    { label: 'Business title', value: secondContact?.business_title },
    { label: 'Phone number', value: secondContact?.phone_number },
  ]

  return (
    <div className={styles.wrap}>
      <ModalHeader header={business_name} statusData={statusData} />

      <div
        className={classNames({
          [styles.topBorder]: !errorMessage,
        })}
      >
        {errorMessage && (
          <Alert
            item={{
              title: 'Your business profile application was rejected.',
              type: 'error',
              desc: errorMessage,
            }}
            actions={
              <>
                <Button
                  text={'Update'}
                  typeBtn={'outlined'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={() => {
                    editBusinessProfile()
                    closeModal()
                  }}
                />
                <Button
                  text={'Learn more'}
                  typeBtn={'text'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={() => {
                    openIntercom()
                    closeModal()
                  }}
                />
              </>
            }
          />
        )}

        <div>
          <Typography ariaLabel='GeneralInfo' variant='body-rg-medium' className={styles.title}>
            General info
          </Typography>
          <ModalInfoBlock lines={generalInfoLines} />
        </div>

        <div>
          <Typography ariaLabel='BusinessInfo' variant='body-rg-medium' className={styles.title}>
            Business info
          </Typography>
          <ModalInfoBlock lines={businessInfoLines} />
        </div>
        <div>
          <Typography ariaLabel='BusinessInfo' variant='body-rg-medium' className={styles.title}>
            People to contact
          </Typography>
          <ModalInfoBlock lines={firstContactLines} />

          {secondContact && (
            <>
              <div className={styles.divider} />
              <ModalInfoBlock lines={secondContactLines} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
