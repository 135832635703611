import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { DndProvider, getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview'
import { layoutStore, SpinnerLoader } from 'shared/ui'
import { IDropOptions, IInboxCombine, INodeModel, TreeRootInboxes } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import {
  actionMoveToDragDrop,
  actionTeamsFavoriteDragDrop,
} from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/Dropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'
import { MenuSectionTree } from './MenuSectionTree/MenuSectionTree'
import styles from './styles.module.scss'
import { RequestAccessItem } from './RequestAccessItem/RequestAccessItem'

export const ConversationMenuInboxes = observer(() => {
  const store = useConversationMenuContext()
  const {
    selectInboxesFavoritesCount,
    selectInboxesSimpleCount,
    searchFavorite,
    searchSimple,
    loading,
    itemsFavorites,
    filteredItemsInboxes,
    handleUpdateSearchSimple,
    handleUpdateSearchFavorite,
    initInboxes,
    reset,
  } = store
  const isShouldRequestAccess = !!usersStore.user?.isShouldRequestAccess

  const handleDrop = async (
    items: INodeModel<IInboxCombine>[],
    options: IDropOptions<IInboxCombine>,
    dropRoot: string | number
  ) => {
    await actionTeamsFavoriteDragDrop(options, dropRoot, store)
    await actionMoveToDragDrop(options, dropRoot, store)
  }
  const itemsFavoritesCount = selectInboxesFavoritesCount
  const itemsInboxesCount = selectInboxesSimpleCount

  const hideFavoritesInboxes = itemsFavorites.length === 0 && searchFavorite === ''
  const hideSimpleInboxes = filteredItemsInboxes.length === 0 && searchSimple === ''

  useEffect(() => {
    initInboxes()

    return () => {
      reset()
    }
  }, [])

  if (loading) {
    return (
      <div className={classnames(styles.loader)}>
        <SpinnerLoader />
      </div>
    )
  }

  if (isShouldRequestAccess) return <RequestAccessItem />

  const onClickItemTree = () => {
    if (layoutStore.isMediumView) {
      store.handleChangeCollapse(true)
    }
  }

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className={styles.wrap}>
        {!hideFavoritesInboxes && (
          <MenuSectionTree
            items={itemsFavorites}
            count={itemsFavoritesCount}
            title='Favorites'
            root={TreeRootInboxes.FAVORITE}
            useSearch={{
              handleSearch: handleUpdateSearchFavorite,
              search: searchFavorite,
              placeholder: 'Search favorites',
              autofocus: true,
              variant: 'transparent',
            }}
            handleDrop={handleDrop}
            onClickItemTree={onClickItemTree}
          />
        )}
        {!hideSimpleInboxes && (
          <MenuSectionTree
            items={filteredItemsInboxes}
            count={itemsInboxesCount}
            title='Inboxes'
            root={TreeRootInboxes.SIMPLE}
            handleDrop={handleDrop}
            onClickItemTree={onClickItemTree}
            useSearch={{
              handleSearch: handleUpdateSearchSimple,
              search: searchSimple,
              placeholder: 'Search inboxes',
              autofocus: true,
              variant: 'transparent',
            }}
          />
        )}
      </div>
    </DndProvider>
  )
})
