import { useEffect, RefObject, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { numbersStore } from 'entities/Phone'
import { BaseContent, Head, useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'

type ICallPowerDialerBaseContentProps = {
  refWrap: RefObject<HTMLDivElement>
}

export const CallPowerDialerBaseContent: FC<ICallPowerDialerBaseContentProps> = observer(
  ({ refWrap }) => {
    const { callPopUpPowerDialerStoreV2, innerRefTooltipCurrent, contactFrom } =
      useCallPopUpDefaultContext()
    const {
      currentContact,
      callDelayTimer,
      handleSkipCurrentContact,
      handleCall,
      restartCallDelayTimer,
      clearCallDelayTimer,
      disabled,
    } = callPopUpPowerDialerStoreV2
    const number = numbersStore.getItem(contactFrom?.numberId)

    useEffect(() => {
      if (currentContact) {
        restartCallDelayTimer()
      } else {
        clearCallDelayTimer()
      }

      return () => {
        clearCallDelayTimer()
      }
    }, [currentContact])

    if (!currentContact) return null

    return (
      <div className={styles.section}>
        <div ref={refWrap} className={styles.tooltipWrap} />

        <Head
          isIncoming={false}
          wrapElement={innerRefTooltipCurrent}
          name={contactFrom?.name}
          phone={number?.formatted_number}
        />

        <BaseContent
          withPadding={true}
          avatarsInfo={[currentContact.avatarProps.info]}
          name={currentContact.name || currentContact.number}
          number={currentContact.number}
          desc={currentContact.number}
          actionsBottom={
            <div className={styles.powerDialerActions}>
              <Typography
                className={styles.powerDialerActions__label}
                variant={'body-md-medium'}
                ariaLabel={'Next call'}
              >
                Next call in {callDelayTimer} sec...
              </Typography>
              <div className={styles.powerDialerActions__buttons}>
                <Button
                  contained={'secondary'}
                  text={'Skip'}
                  onClick={handleSkipCurrentContact}
                  disabled={disabled}
                />
                <Button
                  intent={'positive'}
                  contained={'primary'}
                  icon={'phone'}
                  text={'Call'}
                  onClick={handleCall}
                  disabled={disabled}
                />
              </div>
            </div>
          }
        />
      </div>
    )
  }
)
