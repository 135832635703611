import { observer } from 'mobx-react-lite'
import { useEffect, FC } from 'react'
import { Button, InputCheckbox } from 'shared/ui'
import { links } from 'shared/constants/links'
import { type ImportContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { contactsHistoryStore } from 'widgets/ContactsHistory/store/ContactsHistoryStore'
import styles from './styles.module.scss'

type IApplyTagsModalActionsProps = {
  importContactsStore: ImportContactsStore
}

export const ApplyTagsModalActions: FC<IApplyTagsModalActionsProps> = observer(
  ({ importContactsStore }) => {
    const {
      termsAgreed,
      isImportAllowed,
      getStatusImportContact,
      changeTermsAgreed,
      openMapFieldsModal,
      handleClose,
      importHandler,
      contactsTagsModalStore,
    } = importContactsStore
    const { selectedListIds } = contactsTagsModalStore
    const { loadImportHistory, getImportContactProgress } = contactsHistoryStore

    const importClick = () => {
      importHandler(selectedListIds, loadImportHistory, getImportContactProgress)
    }

    useEffect(() => {
      !isImportAllowed && getStatusImportContact()
    }, [])
    return (
      <>
        <div className={styles.container}>
          <InputCheckbox
            checked={termsAgreed}
            customLabel={
              <div>
                Agree to our
                <a
                  href={links.termsConditions}
                  target='_blank'
                  className={styles.agreedLink}
                  rel='noreferrer'
                >
                  Terms & Conditions
                </a>
              </div>
            }
            onChecked={changeTermsAgreed}
          />
          <div>
            <Button
              className={styles.backButton}
              text='Back'
              contained='tertiary'
              onClick={() => {
                handleClose()
                openMapFieldsModal()
              }}
            />
            <Button text='Import' disabled={!termsAgreed} onClick={importClick} />
          </div>
        </div>
      </>
    )
  }
)
