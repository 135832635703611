import { makeAutoObservable, runInAction } from 'mobx'
import { setLocalStorageItem } from 'shared/hooks/useLocalStorage'
import { usersStore } from 'entities/Users'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'

type IMenuStoreConfig = {
  key: string
}

export class MenuStore {
  private _loading = true

  constructor(private _config: IMenuStoreConfig) {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([
      usersStore.initPromise,
      userPermissionsStore.initPromise,
      featureFlagsStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
    ])

    runInAction(() => {
      this._loading = false
    })
  }

  setCachePath = (path: string) => {
    const usersId = usersStore.user_owner_id
    const key = this._config.key

    setLocalStorageItem(`${usersId}_menu_${key}`, path)
  }
}
