// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hmGs8qJXYuiNwUAxEpPa{width:100%}.hmGs8qJXYuiNwUAxEpPa .xzFy9lFegEvcp_Alxijo{height:6px;background-color:var(--content-brand-primary);border-top-right-radius:6px;border-bottom-right-radius:6px;transition:.5s}", "",{"version":3,"sources":["webpack://./src/shared/ui/ProgressBar/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,4CACE,UAAA,CACA,6CAAA,CACA,2BAAA,CACA,8BAAA,CACA,cAAA","sourcesContent":[".wrap {\n  width: 100%;\n\n  .progress {\n    height: 6px;\n    background-color: var(--content-brand-primary);\n    border-top-right-radius: 6px;\n    border-bottom-right-radius: 6px;\n    transition: 0.5s;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "hmGs8qJXYuiNwUAxEpPa",
	"progress": "xzFy9lFegEvcp_Alxijo"
};
export default ___CSS_LOADER_EXPORT___;
