import { Navigate, Route, Routes } from 'react-router-dom'
import {
  ChatbotEditPage,
  ChatbotNewPage,
  ChatbotListPage,
  ChatbotAnalyticPage,
} from 'pages/chatbot/pages'
import { ChatbotLayout } from 'pages/chatbot/layout'
import { ChatbotRoute } from './type'

export const ChatbotRoutes = () => {
  return (
    <Routes>
      <Route element={<ChatbotLayout />}>
        <Route index element={<Navigate to={ChatbotRoute.all} />} />
        <Route path={`${ChatbotRoute.all}`} element={<ChatbotListPage />} />
        <Route path={`${ChatbotRoute.new}`} element={<ChatbotNewPage />} />
        <Route path=':chatbotId' element={<ChatbotEditPage />} />
        <Route path={`:chatbotId/${ChatbotRoute.analytic}`} element={<ChatbotAnalyticPage />} />
      </Route>
    </Routes>
  )
}
