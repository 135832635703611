export enum CalendarFilterEnum {
  last30days = 'Last 30 days',
  thisMonth = 'This month',
  lasMonth = 'Last month',
  last3month = 'Last 3 month',
  last6month = 'Last 6 month',
  lastYear = 'Last year',
  custom = 'Custom',
  allTime = 'All time',
}
