import React, { useMemo, useRef, useState } from 'react'
import { Box, IColumn, Tooltip } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats, formatBytes } from 'shared/lib'
import { getCellForNameDefault } from 'shared/helpers/getCellForNameDefault'
import { AttachmentLoader } from 'shared/ui/Loader'
import { getFileLabelByExtension } from 'shared/lib/getFileLabelByExtension'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { usersStore } from 'entities/Users'
import { ImageCard, VideoCard } from 'entities/Attachment'
import { getAttachmentFlags } from 'widgets/MediaLibrary/utils'
import styles from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryTable/styles.module.scss'
import { FileIcon } from 'widgets/FileIcon/FileIcon'

const NameCell = ({ attachment }: { attachment: Attachment }) => {
  const [isTextOverflow, setIsTextOverflow] = useState(false)
  const nameRef = useRef<HTMLSpanElement>(null)

  const checkOverflow = () => {
    if (nameRef.current) {
      const isOverflowing = nameRef.current.offsetWidth < nameRef.current.scrollWidth
      setIsTextOverflow(isOverflowing)
    }
  }

  const { isAudio, isVideo, isImage, isVCard } = getAttachmentFlags(attachment)

  const fileIcon = useMemo(() => {
    if (isAudio) return <FileIcon isAudio={isAudio} fontSize={16} />
    if (isVCard) return <FileIcon isVCard={isVCard} fontSize={16} />

    return <FileIcon docType={attachment.extension || attachment.content_type} fontSize={20} />
  }, [attachment.type])

  const mediaContent = () => {
    if (isImage) return <ImageCard thumbnail fullWidth attachment={attachment} />
    if (isVideo && attachment.thumbnail)
      return <ImageCard thumbnail fullWidth attachment={attachment} />
    if (isVideo && !attachment.thumbnail)
      return <VideoCard thumbnail fullWidth attachment={attachment} />

    return <div className={styles.mediaStub}>{fileIcon}</div>
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
      gap={1}
    >
      <div className={styles.mediaContainer}>
        {attachment.loading && <AttachmentLoader isLibrary />}
        {mediaContent()}
      </div>
      <Tooltip
        label={isTextOverflow && attachment.name ? attachment.name : ''}
        placement='top'
        disableHoverListener={!isTextOverflow}
      >
        <span ref={nameRef} className={styles.name} onMouseEnter={checkOverflow}>
          {attachment.name}
        </span>
      </Tooltip>
    </Box>
  )
}

export const columns: IColumn<Attachment>[] = [
  {
    field: 'name',
    name: 'Name',
    fullName: 'Name',
    renderRowCell: (row) => <NameCell attachment={row} />,
    isDisabledChangeVisible: true,
    maxWidth: 200,
    padding: '12px 8px',
  },
  {
    field: 'type',
    name: 'Type',
    fullName: 'Type',
    renderRowCell: (row) => getFileLabelByExtension(row?.extension ?? ''),
    maxWidth: 120,
  },
  {
    field: 'size',
    name: 'Size',
    fullName: 'Size',
    renderRowCell: (row) => (row.size ? formatBytes(row.size) : '-'),
    maxWidth: 80,
  },
  {
    field: 'uploadedTime',
    name: 'Uploaded time',
    fullName: 'Uploaded time',
    renderRowCell: (row) =>
      row.created_at ? uiStore.dayjs(row.created_at).format(DayjsFormats.fullWithAtDash2) : '-',
    maxWidth: 180,
  },
  {
    field: 'uploadedBy',
    name: 'Uploaded by',
    fullName: 'Uploaded by',
    padding: '0 8px',
    renderRowCell: (row) => {
      const user = usersStore.getItem(row.userId)

      if (user) {
        return getCellForNameDefault({
          fullName: user.full_name || '',
          avatarInfo: user.avatarInfo,
        })
      }

      return ''
    },
    maxWidth: 140,
    isHideByDefault: true,
  },
]
