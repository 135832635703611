import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { SpinnerLoader } from 'shared/ui'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'

export const AccessOrganizationLayout = observer(() => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    Promise.all([organizationStore.initPromise, subscriptionStore.initPromise])
      .then(() => {
        if (subscriptionStore.isAgencyWithoutSubscription) uiStore.changeRoute({ path: '/clients' })
      })
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <div className={'spinner'}>
        <SpinnerLoader />
      </div>
    )
  }

  return <Outlet />
})
