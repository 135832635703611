import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import classNames from 'classnames'
import { Box } from '@mui/material'
import { Typography, Error, Button, Alert } from 'shared/ui'
import {
  makeTextFieldAutoFocus,
  makeInputPattern,
  TextField,
  makeTextFieldHighlight,
} from 'shared/ui/TextField'
import { type LoginStore } from 'widgets/Login/store/loginStore'

import styles from './styles.module.scss'

const InputCode = makeInputPattern({
  format: '######',
  placeholder: '000000',
})

type IProps = {
  store: LoginStore
}

export const TwoFaCodeModalContent = observer(({ store }: IProps) => {
  const {
    setTwoFaCode,
    twoFaCode,
    loading,
    twoFaMethod,
    twoFaCodeError,
    isValidTwoFaCode,
    onVerifyCode,
    twoFaLabel,
    shouldSendTwoFaCode,
    resendTwoFaCode,
    openMethodModal,
    hasResendTimer,
    twoFaSettings,
    resendText,
    disabledCodeResend,
    twoFaCodeAlertError,
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValidTwoFaCode) {
      event.preventDefault()
      onVerifyCode()
    }
  }

  return (
    <div className={styles.wrap}>
      {twoFaCodeAlertError && (
        <Box marginBottom={'36px'}>
          <Alert
            item={{
              type: 'error',
              title: twoFaCodeAlertError,
            }}
          />
        </Box>
      )}

      <Typography
        color={'var(--content-primary-primary)'}
        variant={'body-md-medium'}
        ariaLabel={'twoFaCodeModalTitle'}
      >
        Enter the 6-digit code
      </Typography>
      <Typography
        color={'var(--content-primary-tertiary)'}
        variant={'body-md-regular'}
        ariaLabel={'twoFaCodeModalLabel'}
      >
        {twoFaLabel}
      </Typography>
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: twoFaCodeError,
        })}
        Input={InputCode}
        InputProps={{ onKeyDown }}
        variant='stroke'
        size='medium'
        value={twoFaCode}
        disabled={loading || disabledCodeResend}
        onChange={setTwoFaCode}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlight({ mode: 'error', highlight: !!twoFaCodeError }),
        ]}
      />
      <Error type={'text'} error={twoFaCodeError} />
      {shouldSendTwoFaCode && !hasResendTimer && (
        <div className={styles.wrapResend}>
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'body-md-regular'}
            ariaLabel={'resendTwoFaCode'}
          >
            Haven’t received it?
          </Typography>
          <Button
            paddingInline={4}
            typeBtn='link'
            type='button'
            onClick={resendTwoFaCode}
            disabled={loading}
            text={'Resend new code'}
          />
        </div>
      )}
      {!hasResendTimer && twoFaSettings?.length !== 1 && (
        <div className={classNames({ [styles.wrapResend]: twoFaMethod?.type === 'app' })}>
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'body-md-regular'}
            ariaLabel={'backToMethods'}
          >
            Use a different
          </Typography>
          <Button
            paddingInline={4}
            typeBtn='link'
            type='button'
            onClick={() => openMethodModal(store)}
            disabled={loading}
            text={'method'}
          />
        </div>
      )}

      {hasResendTimer && (
        <div className={styles.wrapResend}>
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'body-md-regular'}
            ariaLabel={'resendTimer'}
          >
            {resendText}
          </Typography>
        </div>
      )}
    </div>
  )
})
