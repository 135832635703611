import { Box, Icon, type IIconsVector, type InputRadioProps, Tooltip, Typography } from 'shared/ui'
import { INumberType, ITargetCountry } from 'entities/Compliance/api/complianceType'
import styles from 'entities/Compliance/ui/Questionnaire/ui/QuestionSection/ui/styles.module.scss'

export const getFirstBlockItems = (
  targetCountry: ITargetCountry | null,
  onChecked: (index: ITargetCountry) => void
): InputRadioProps[] => [
  {
    label: <span className={styles.labelText}>US only</span>,
    checked: targetCountry === 'US',
    leftIcon: 'flagUSA' as IIconsVector,
    onChecked: () => onChecked('US'),
  },
  {
    label: <span className={styles.labelText}>Canada only</span>,
    checked: targetCountry === 'CA',
    leftIcon: 'flagCanada' as IIconsVector,
    onChecked: () => onChecked('CA'),
  },
  {
    customLabel: (
      <div className={styles.customLabel}>
        <Icon icon={'flagUSA'} className={styles.leftIcon} /> US
        <span>+</span>
        <Icon icon={'flagCanada'} className={styles.leftCaIcon} /> Canada
      </div>
    ),
    checked: targetCountry === 'ALL',
    onChecked: () => onChecked('ALL'),
  },
]

export const getSecondBlockItems = (
  isTaxId: boolean | null,
  onChecked: (condition: boolean) => void
): InputRadioProps[] => [
  {
    label: (
      <>
        <Icon icon={'tickSymbol'} className={styles.leftIcon} />
        <span className={styles.taxIdLabelText}>Yes, I do</span>
      </>
    ),
    checked: isTaxId === null ? false : isTaxId,
    onChecked: () => onChecked(true),
  },
  {
    label: (
      <>
        <Icon icon={'crossMark'} className={styles.leftIcon} />
        <span className={styles.taxIdLabelText}>No, I don’t</span>
      </>
    ),
    checked: isTaxId === null ? false : !isTaxId,
    onChecked: () => onChecked(false),
  },
]

export const getThirdBlockItems = (
  numberType: INumberType | null,
  isTaxId: boolean | null,
  targetCountry: ITargetCountry | null,
  onChecked: (type: INumberType) => void
): InputRadioProps[] => {
  const isCanadaOnly = targetCountry === 'CA'
  const isDisabled = !isTaxId && !isCanadaOnly
  const label = 'To send messages from local numbers to the US, you need a business Tax ID.'

  return [
    {
      label: 'Local numbers',
      disabled: isDisabled,
      rightText: (
        <Box display={'flex'} gap={'8px'} alignItems={'center'}>
          <Typography ariaLabel='example' variant='body-rg-regular'>
            e.g. (561) 555-5555
          </Typography>
          {isDisabled && (
            <Tooltip label={label} placement='right' width={193} type='description'>
              <Icon icon='question' color='var(--gray-40)' />
            </Tooltip>
          )}
        </Box>
      ),
      checked: numberType === 'local',
      onChecked: () => onChecked('local'),
    },
    {
      label: 'Toll-free numbers',
      rightText: (
        <Typography ariaLabel='example' variant='body-rg-regular'>
          e.g. (888) 555-5555
        </Typography>
      ),
      checked: numberType === 'toll_free',
      onChecked: () => onChecked('toll_free'),
    },
    {
      label: 'Local numbers + Toll-free numbers',
      disabled: isDisabled,
      rightText: isDisabled ? (
        <Tooltip label={label} placement='right' width={193} type='description'>
          <Icon icon='question' color='var(--gray-40)' />
        </Tooltip>
      ) : (
        ''
      ),
      checked: numberType === 'all',
      onChecked: () => onChecked('all'),
    },
  ]
}
