import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class WorkflowLayoutStore {
  constructor(private _pageLayoutStore: PageLayoutStore) {
    makeAutoObservable(this)
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }
}
