import { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, Button, Icon, Typography } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import { Contact } from 'entities/Contacts/model/Contact'
import { CustomFieldTypes } from 'entities/Contacts'
import { EnrollmentContactPreviewStore } from '../store/enrollmentContactPreviewStore'
import styles from './styles.module.scss'

type IContactPreviewProps = {
  contact: Contact
  onCancel: () => void
  hideActions: boolean
}

export const EnrollmentContactPreview: FC<IContactPreviewProps> = observer(
  ({ contact, onCancel, hideActions }) => {
    const [store] = useState<EnrollmentContactPreviewStore>(
      () => new EnrollmentContactPreviewStore()
    )

    useEffect(() => {
      store.fetchCustomFields(contact.id)

      return () => {
        store.reset()
      }
    }, [contact.id])

    const customFieldIcon = (fieldType: CustomFieldTypes | undefined) => {
      switch (fieldType) {
        case CustomFieldTypes.date:
          return 'calendar'
        case CustomFieldTypes.number:
          return 'number'
        case CustomFieldTypes.url:
          return 'link'
        case CustomFieldTypes.text:
          return 'textInput'
        default:
          return 'textInput'
      }
    }

    return (
      <div className={styles.wrap}>
        <div className={styles.info}>
          <div className={styles.info__avatar}>
            <Avatar info={contact.avatarInfo} size={40} />
          </div>
          <div className={styles.info__content}>
            <Typography
              tag={'div'}
              className={styles.info__title}
              variant={'body-rg-medium'}
              ariaLabel={'name'}
            >
              {getLabelAsNumberInternationalFormat(contact.name)}
            </Typography>
            <Typography
              tag={'div'}
              className={styles.info__desc}
              variant={'body-md-regular'}
              ariaLabel={'formatted_number'}
            >
              {contact.number && getLabelAsNumberInternationalFormat(contact.number)}
            </Typography>
          </div>
        </div>

        <div className={styles.details}>
          <Typography
            tag={'div'}
            className={styles.details__title}
            variant={'body-small-medium'}
            ariaLabel={'Contact details'}
          >
            Contact details
          </Typography>

          <div className={styles.details__content}>
            <div className={styles.field}>
              <span className={styles.field__label}>
                <div className={styles.field__icon}>
                  <Icon icon='phone' />
                </div>
                <Typography
                  className={styles.field__name}
                  variant='text-input-field-r'
                  tag='div'
                  ariaLabel={'Phone'}
                >
                  Phone
                </Typography>
              </span>
              <Typography
                className={styles.field__value}
                variant={'text-input-field-r'}
                tag={'div'}
                ariaLabel={'PhoneValue'}
              >
                {(contact.number && getLabelAsNumberInternationalFormat(contact.number)) || '-'}
              </Typography>
            </div>

            <div className={styles.field}>
              <span className={styles.field__label}>
                <div className={styles.field__icon}>
                  <Icon icon='user' />
                </div>
                <Typography
                  className={styles.field__name}
                  variant='text-input-field-r'
                  tag='div'
                  ariaLabel={'FirstName'}
                >
                  First name
                </Typography>
              </span>
              <Typography
                className={styles.field__value}
                variant={'text-input-field-r'}
                tag={'div'}
                ariaLabel={'FirstNameValue'}
              >
                {contact.first_name || '-'}
              </Typography>
            </div>

            <div className={styles.field}>
              <span className={styles.field__label}>
                <div className={styles.field__icon}>
                  <Icon icon='user' />
                </div>
                <Typography
                  className={styles.field__name}
                  variant='text-input-field-r'
                  tag='div'
                  ariaLabel={'LastName'}
                >
                  Last name
                </Typography>
              </span>
              <Typography
                className={styles.field__value}
                variant={'text-input-field-r'}
                tag={'div'}
                ariaLabel={'LastNameValue'}
              >
                {contact.last_name || '-'}
              </Typography>
            </div>

            <div className={styles.field}>
              <span className={styles.field__label}>
                <div className={styles.field__icon}>
                  <Icon icon='email' />
                </div>
                <Typography
                  className={styles.field__name}
                  variant='text-input-field-r'
                  tag='div'
                  ariaLabel={'Email'}
                >
                  Email
                </Typography>
              </span>
              <Typography
                className={styles.field__value}
                variant={'text-input-field-r'}
                tag={'div'}
                ariaLabel={'EmailValue'}
              >
                {contact.email || '-'}
              </Typography>
            </div>

            {store.customFields.map((customField) => {
              return (
                <div key={customField.field_key} className={styles.field}>
                  <span className={styles.field__label}>
                    <div className={styles.field__icon}>
                      <Icon icon={customFieldIcon(customField.type)} />
                    </div>
                    <Typography
                      className={styles.field__name}
                      variant='text-input-field-r'
                      tag='div'
                      ariaLabel={'Email'}
                    >
                      {customField.name}
                    </Typography>
                  </span>
                  <Typography
                    className={styles.field__value}
                    variant={'text-input-field-r'}
                    tag={'div'}
                    ariaLabel={'EmailValue'}
                  >
                    {customField.value?.value || '-'}
                  </Typography>
                </div>
              )
            })}
          </div>
        </div>

        {!hideActions && (
          <div className={styles.wrapBtn}>
            <Button
              text={'Close'}
              typeBtn={'text'}
              contained={'secondary'}
              intent={'passive'}
              onClick={onCancel}
            />
          </div>
        )}
      </div>
    )
  }
)
