import { FC } from 'react'
import classNames from 'classnames'
import { Checkbox, IDropdownItemProps, Icon, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export const DropdownCardCheckbox: FC<IDropdownItemProps> = ({
  item,
  onChange,
  onChangeCheckbox,
}) => {
  const isCardClick = item.isCardClick
  const onCardClick = () => {
    if (isCardClick) {
      onChange?.(item)
    }
  }
  const content = (
    <div className={styles.contentWrapper}>
      <Checkbox
        size={16}
        disabled={item.disabled}
        checked={!!item.activeValue}
        onChange={(value) => {
          !isCardClick && onChange?.(item)
          onChangeCheckbox?.(value, item)
        }}
      />
      {item.iconL && (
        <div
          className={styles.icon}
          style={{ color: (item.customFields?.leftIconColor as string) || 'inherit' }}
        >
          <Icon icon={item.iconL} />
        </div>
      )}
      <div className={styles.checkboxLabel} style={{ width: item.labelWidth }}>
        <Typography ellipsis variant={'body-md-regular'} ariaLabel={'label'}>
          {item.labelContent || item.label}
        </Typography>

        {!!item.labelCount && (
          <Typography
            variant={'body-xs-medium'}
            color={'var(--content-neutral-primary)'}
            ariaLabel={'labelCount'}
            className={styles.labelCount}
          >
            {item.labelCount}
          </Typography>
        )}
      </div>
    </div>
  )

  return (
    <div
      className={classNames(
        styles.checkboxItemContainer,
        item.disabled && styles.disabledCheckBox,
        !item.disabled && isCardClick && styles.pointer
      )}
      onClick={onCardClick}
    >
      {item.tooltipProps ? <Tooltip {...item.tooltipProps}>{content}</Tooltip> : content}
    </div>
  )
}
