import { AuthLayout } from 'shared/layout'
import { LoginStore } from 'widgets/Login/store/loginStore'
import { LoginForm } from 'widgets/Login/ui/LoginForm/LoginForm'

import styles from './styles.module.scss'

export const Login = () => {
  const store = new LoginStore()
  return (
    <div className={styles.wrap}>
      <AuthLayout
        title='Sign in to Salesmsg'
        form={<LoginForm store={store} />}
        formClassnames={styles.form}
      />
    </div>
  )
}

export default Login
