import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'

export class TrialTenLimitConversationsTooltipStore {
  constructor() {
    makeAutoObservable(this)
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get isCompleted() {
    return this.trialSubscription.isStatusCompleted
  }

  get isAdminOrOwnerRole() {
    return usersStore.user?.isAdminOrOwnerRole
  }

  get config() {
    if (this.isAdminOrOwnerRole) {
      return {
        desc: this.isCompleted ? (
          <>
            You have reached the limit of 10 <br />
            conversations on Trial plan. To remove <br />
            the limits, please upgrade.
          </>
        ) : (
          <>
            You have reached the limit of 10 <br />
            conversations on Trial plan. To remove <br />
            the limits, get approved and upgrade
          </>
        ),
        action: {
          text: this.isCompleted ? 'Upgrade' : 'Get approved',
          onClick: () => {
            if (this.isCompleted) {
              uiStore.changeRouteViaVue({
                path: '/settings/billing/overview/upgrade',
              })
            } else {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance',
              })
            }
          },
        },
      }
    }

    return {
      desc: this.isCompleted ? (
        <>
          You have reached the limit of 10 conversations <br />
          on Trial plan. To remove the limits, please ask <br />
          one of your admins to upgrade.
        </>
      ) : (
        <>
          You have reached the limit of 10 conversations on <br />
          Trial plan. To remove the limits, please ask one of <br />
          your admins to get approved and upgrade.
        </>
      ),
    }
  }
}
