import { makeAutoObservable } from 'mobx'
import {
  IBusinessType,
  type IVettingType,
  type IResponseComplianceServicesBrand,
  IRegistrationError,
} from 'entities/Compliance'

export class ComplianceServiceBrand {
  private _status: IResponseComplianceServicesBrand['status'] | null = null
  private _data: IResponseComplianceServicesBrand['data'] | null = null
  private _plan_type: IResponseComplianceServicesBrand['plan_type'] | null = null
  private _business_email_verification:
    | IResponseComplianceServicesBrand['business_email_verification']
    | null = null
  private _createAt: IResponseComplianceServicesBrand['create_at'] | null = null
  private _submittedAt: IResponseComplianceServicesBrand['submitted_at'] | null = null
  private _registrationErrors: IResponseComplianceServicesBrand['registration_errors'] = []
  private _vetting: IVettingType | null = null

  constructor(response: IResponseComplianceServicesBrand) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseComplianceServicesBrand) => {
    this._status = response.status || null
    this._data = response.data || null
    this._plan_type = response.plan_type || null
    this._business_email_verification = response.business_email_verification || null
    this._registrationErrors = response.registration_errors
    this._createAt = response.create_at
    this._vetting = response.vetting || null
    this._submittedAt = response.submitted_at
  }

  get business_email_verification() {
    return this._business_email_verification
  }

  get plan_type() {
    return this._plan_type
  }

  get status() {
    return this._status
  }

  get createAt() {
    return this._createAt
  }

  get submittedAt() {
    return this._submittedAt
  }

  get vetting() {
    return this._vetting
  }

  get entityType() {
    return this.data?.entityType === IBusinessType.PUBLIC_PROFIT ? 'Public' : 'Private'
  }

  get isPublicType() {
    return this.data?.entityType === IBusinessType.PUBLIC_PROFIT
  }

  get isPrivateType() {
    return this.data?.entityType === IBusinessType.PRIVATE_PROFIT
  }

  get data() {
    if (Array.isArray(this._data)) return null

    return this._data
  }

  get registrationError(): IRegistrationError | undefined {
    return this._registrationErrors[0]
  }

  get isInternalRejected() {
    if (!this.isStatusFailed) return false

    return this.registrationError?.rejection_source === 'admin'
  }

  get isExternalRejected() {
    if (!this.isStatusFailed) return false

    return this.registrationError?.rejection_source !== 'admin'
  }

  get isStatusInProgress() {
    return this._status === 'in_progress'
  }

  get isStatusPendingReview() {
    return this._status === 'pending_review'
  }

  get isStatusInCarrierReview() {
    return this._status === 'in_carrier_review'
  }

  get isStatusApproved() {
    return this._status === 'approved'
  }

  get isStatusFailed() {
    return this._status === 'failed'
  }

  get isPublicBrandFailed() {
    return this.isStatusFailed && this.isPublicType
  }

  get isPrivateBrandFailed() {
    return this.isStatusFailed && !this.isPublicType
  }

  get isPrivateBrandApproved() {
    return this.isStatusApproved && !this.isPublicType
  }

  get stockExchange() {
    return this.data?.stockExchange
  }

  get stockSymbol() {
    return this.data?.stockSymbol
  }

  get is2FAPending() {
    return this.business_email_verification?.status === 'pending'
  }

  get is2FAActionRequired() {
    return this.business_email_verification?.status === 'action_required'
  }
}
