import { CSSProperties, ReactNode, FC } from 'react'
import classNames from 'classnames'
import { ContainerHeader } from 'shared/ui/ContainerHeader/ContainerHeader'
import { type ActionItem, type IMenuItemProps } from 'shared/ui'
import { MenuItem } from 'shared/ui/Menu/ui/MenuItem/MenuItem'
import styles from './styles.module.scss'

type IMenuDefaultProps = {
  title: string
  headerButtons?: ActionItem[]
  menuSearch?: ReactNode
  menuContent?: ReactNode
  menuItems?: Array<IMenuItemProps>
  paddingRight?: CSSProperties['paddingRight']
  bottomNode?: ReactNode
  onCheckRoute?: (path: string) => void
}

export const Menu: FC<IMenuDefaultProps> = ({
  title,
  headerButtons,
  menuSearch,
  menuContent,
  menuItems,
  paddingRight = 11,
  bottomNode,
  onCheckRoute,
}) => {
  return (
    <div
      className={classNames(styles.wrap, {
        [styles.withSearch]: menuSearch,
        [styles.withBottom]: bottomNode,
      })}
    >
      <ContainerHeader
        title={title}
        actions={headerButtons}
        actionsGap={4}
        variant={'transparent'}
        paddingRight={paddingRight}
      />

      {menuSearch && <div className={styles.wrapperSearch}>{menuSearch}</div>}

      <div className={styles.wrapperContent}>
        {menuContent ||
          menuItems?.map((item) => {
            if (item.type === 'nav') {
              return <MenuItem {...item} key={item.key} onCheckRoute={onCheckRoute} />
            }

            if (item.type === 'sub') {
              return <MenuItem {...item} key={item.key} onCheckRoute={onCheckRoute} />
            }

            return <MenuItem {...item} key={item.key} />
          })}
      </div>
      {bottomNode}
    </div>
  )
}
