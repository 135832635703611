import { observer } from 'mobx-react-lite'
import { FC, MouseEventHandler, useEffect, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { Icon, IconButton, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { PlayBtn } from 'shared/ui/Button/ui/PlayBtn'
import { AudioWave } from 'shared/ui/AudioPlayer'
import { uiStore } from 'shared/store/uiStore'
import { IResponseVoice, textToSpeechStore } from 'entities/TextToSpeech'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import styles from './styles.module.scss'

type IVoiceItemProps = {
  voice: IResponseVoice
  onCloseMenu: () => void
}

export const VoiceItem: FC<IVoiceItemProps> = observer(({ voice, onCloseMenu }) => {
  const {
    getVoicePreview,
    selectedVoice,
    onDeleteCustomVoice,
    setSelectedVoice,
    onErrorLoadPreview,
    activeTabKey,
  } = useTextToSpeechContext()
  const [audioSrc, setAudioSrc] = useState('')

  const [waveform, setWaveform] = useState<WaveSurfer | null>(null)
  const [playing, setPlaying] = useState(false)

  const isLoading = textToSpeechStore.loadingPreviewMap.get(voice.id)

  useEffect(() => {
    if (audioSrc && playing && waveform) {
      uiStore.setPlayAudioId(audioSrc)
      waveform.play()
    }
  }, [audioSrc, playing, waveform])

  const togglePlay: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation()
    if (playing) {
      waveform?.pause()
    } else {
      try {
        const src = await getVoicePreview(voice.id)
        if (src) {
          setPlaying(true)
          setAudioSrc(src)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleSelectVoice = () => {
    onCloseMenu()
    setSelectedVoice(voice)
  }

  const onErrorLoad = () => {
    onErrorLoadPreview(voice.id)
    setAudioSrc('')
  }

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    onCloseMenu()
    onDeleteCustomVoice(voice.id)
  }

  return (
    <button className={styles.wrap} onClick={handleSelectVoice}>
      <div className={styles.wrapPlay}>
        <PlayBtn
          isPlay={playing}
          togglePlay={togglePlay}
          size={'small'}
          color={'primary'}
          transparentBackground
          withoutTooltip
          disabled={!!isLoading}
        />
      </div>

      <div className={styles.wrapName}>
        <Typography ariaLabel={getAriaLabel(voice.name)} variant={'text-input-field-r'}>
          {voice.name}
        </Typography>
        {audioSrc && (
          <div onClick={(e) => e.stopPropagation()}>
            <AudioWave
              url={audioSrc}
              waveform={waveform}
              setWaveform={setWaveform}
              id={audioSrc}
              playing={playing}
              setPlaying={setPlaying}
              width={50}
              hide={!playing}
              onErrorLoad={onErrorLoad}
            />
          </div>
        )}
      </div>
      {activeTabKey === 'custom' ? (
        <IconButton
          ariaLabel={'delete'}
          icon={'delete'}
          size={'small'}
          variant={'icon'}
          color={'tertiary'}
          tooltipProps={{
            label: 'Delete',
            placement: 'top',
          }}
          onClick={handleDelete}
        />
      ) : (
        selectedVoice?.id === voice.id && <Icon icon={'check1'} className={styles.check} />
      )}
    </button>
  )
})
