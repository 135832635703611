import { observer } from 'mobx-react-lite'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { Alert } from 'shared/ui'
import { MAX_NAME_LENGTH } from 'shared/constants/maxNameLength'
import { SavedReplyActions } from 'widgets/SavedReplies/ui/NewSavedReplyContent/SavedReplyActions/SavedReplyActions'
import { MessageField } from 'widgets/MessageField'
import type { INewSavedReplyStore } from 'widgets/SavedReplies/store/newSavedReplyStore'
import styles from './styles.module.scss'

interface INewSavedReplyContentProps {
  newSavedReplyStore: INewSavedReplyStore
  disabledVisibility: boolean
}

export const NewSavedReplyContent = observer(
  ({ newSavedReplyStore, disabledVisibility }: INewSavedReplyContentProps) => {
    const { messageFieldStore, title, setTitle, isEdit } = newSavedReplyStore
    const isLengthAlertShow = title.length > MAX_NAME_LENGTH

    return (
      <div className={styles.wrap}>
        <div className={styles.alertContainer} ref={messageFieldStore.setAlertElement} />
        {isLengthAlertShow && (
          <Alert
            item={{
              type: 'alert',
              desc: `Title should not be longer than ${MAX_NAME_LENGTH} characters`,
            }}
            marginBottom={28}
          />
        )}
        <TextField
          className={styles.textField}
          size='medium'
          variant='integrated'
          InputProps={{
            placeholder: 'Title',
          }}
          value={title}
          onChange={setTitle}
          mainActions={[makeTextFieldAutoFocus({ withFocus: !isEdit })]}
          disabled={messageFieldStore.recordAudioState}
        />
        <MessageField messageFieldStore={messageFieldStore} />
        <SavedReplyActions
          newSavedReplyStore={newSavedReplyStore}
          disabledVisibility={disabledVisibility}
        />
      </div>
    )
  }
)
