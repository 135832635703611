import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { EventIntegrationConnected, EventIntegrationDisconnected } from 'entities/Integrations'
import { websocket } from 'entities/WebSocket'
import { EventOrganizationCalling, EventUpdateBalance } from 'entities/Organization'
import { EventInboxCalling } from 'entities/Inbox'
import { EventMessagePopper } from 'entities/Message'
import {
  EventConversationContactOptedIn,
  EventConversationContactOptedOut,
  EventConversationReassigned,
} from 'entities/Conversation'
import { EventContactUpdated } from 'entities/Contacts'
import { EventClientDraft } from 'entities/Conversation/events/EventClientDraft'
import { EventMessageDeletedDraft } from 'entities/Conversation/events/EventMessageDeletedDraft'
import { EventInboxUpdate } from 'entities/Inbox/events/EventInboxUpdate'
import { EventInboxDeleted } from 'entities/Inbox/events/EventInboxDeleted'
import { EventInboxCreated } from 'entities/Inbox/events/EventInboxCreated'
import { EventMessagePopperLog } from 'entities/DataLog/events/EventMessagePopperLog'
import { EventMessageReceivedNewLog } from 'entities/DataLog/events/EventMessageReceivedNewLog'
import { EventConversationUnreadLog } from 'entities/DataLog/events/EventConversationUnreadLog'
import { EventConversationStateChanged } from 'entities/Conversation/events/EventConversationStateChanged'

export const GlobalEvents = observer(() => {
  useEffect(() => {
    if (websocket.isConnected) {
      EventIntegrationConnected.subscribe()
      EventUpdateBalance.subscribe()
      EventIntegrationDisconnected.subscribe()
      EventOrganizationCalling.subscribe()
      EventInboxCalling.subscribe()
      EventInboxCreated.subscribe()
      EventInboxUpdate.subscribe()
      EventInboxDeleted.subscribe()
      EventConversationContactOptedOut.subscribe()
      EventConversationContactOptedIn.subscribe()
      EventConversationReassigned.subscribe()
      EventConversationStateChanged.subscribe()
      EventMessagePopper.subscribe()
      EventContactUpdated.subscribe()
      EventClientDraft.subscribe()
      EventMessageDeletedDraft.subscribe()
      EventMessagePopperLog.subscribe()
      EventMessageReceivedNewLog.subscribe()
      EventConversationUnreadLog.subscribe()
    }
  }, [websocket.isConnected])

  return null
})
