import { Divider } from '@mui/material'
import { KeyboardEvent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import { Alert, Button, Error } from 'shared/ui'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import {
  IInputComponent,
  makeTextFieldAutoFocus,
  makeTextFieldHighlight,
  TextField,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum, authStore } from 'entities/Auth'
import { type LoginStore } from 'widgets/Login'

import styles from './styles.module.scss'

type IFormProps = {
  store: LoginStore
}

export const LoginForm = observer(({ store }: IFormProps) => {
  const { state } = useLocation()

  const {
    setEmail,
    email,
    password,
    setPassword,
    loading,
    emailError,
    passwordError,
    error,
    triggerEmailValidation,
    triggerPasswordValidation,
    signIn,
    hasAlertErrors,
    alertErrors,
    isValidEmail,
    hasPassword,
    setAlertSuccess,
    alertSuccess,
  } = store

  const { googleAuthStore } = authStore

  const handleSignIn = async () => signIn(store)

  const onKeyDownEmail = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerEmailValidation()
      if (hasPassword && isValidEmail) handleSignIn()
    }
  }

  const onKeyDownPassword = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerPasswordValidation()
      if (hasPassword && isValidEmail) handleSignIn()
    }
  }

  const handleResetPassword = () => {
    uiStore.changeRoute({
      path: `/${AuthRoutesEnum.forgotPassword}`,
    })
  }

  useEffect(() => {
    if (state?.alert) setAlertSuccess(state?.alert)
  }, [])

  return (
    <div className={styles.wrap}>
      {error && (
        <Alert
          className={styles.error}
          item={{
            type: 'error',
            desc: error,
          }}
        />
      )}

      <Button
        className={classNames(styles.button, styles.signIn)}
        loading={googleAuthStore.loading}
        disabled={googleAuthStore.loading || loading}
        typeBtn='outlined'
        type='button'
        icon={'google'}
        text={'Sign in with Google'}
        contained={'secondary'}
        onClick={googleAuthStore.onLogin}
        fullWidth
      />

      <div className={styles.divider}>
        <Divider>OR</Divider>
      </div>

      {(hasAlertErrors || alertSuccess) && (
        <div className={styles.alertWrap}>
          {alertErrors.map((error) => (
            <Alert
              key={error}
              item={{
                type: 'alert',
                desc: error,
              }}
            />
          ))}
          {alertSuccess && (
            <Alert
              key={error}
              item={{
                type: 'success',
                desc: alertSuccess,
              }}
              onClose={() => setAlertSuccess('')}
            />
          )}
        </div>
      )}

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, { [styles.error]: !!emailError })}
        variant='stroke'
        disabled={googleAuthStore.loading || loading}
        size='medium'
        InputProps={{ placeholder: 'name@company.com', onKeyDown: onKeyDownEmail }}
        onChange={setEmail}
        value={email}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlight({ mode: 'error', highlight: !!emailError }),
        ]}
      />
      <Error type={'text'} error={emailError} />

      <Label
        label={{
          text: 'Password',
          className: styles.inputLabel,
          rightText: (
            <Button
              paddingInline={0}
              onClick={handleResetPassword}
              typeBtn={'link'}
              text={'Forgot your password?'}
            />
          ),
        }}
      />
      <TextField
        Input={InputPassword as IInputComponent}
        className={classNames(styles.textField, {
          [styles.error]: !!passwordError,
        })}
        variant='stroke'
        InputProps={{
          placeholder: 'Enter your password',
          onKeyDown: onKeyDownPassword,
        }}
        disabled={googleAuthStore.loading || loading}
        size='medium'
        onChange={setPassword}
        value={password}
        mainActions={[makeTextFieldHighlight({ mode: 'error', highlight: !!passwordError })]}
      />
      <Error type={'text'} error={passwordError} />

      <Button
        className={classNames(styles.button, styles.signIn)}
        typeBtn='contained'
        type='button'
        onClick={handleSignIn}
        loading={loading}
        disabled={googleAuthStore.loading || loading}
        text={'Sign in'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default LoginForm
