import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import {
  type Integration,
  IntegrationKey,
  IntegrationsApi,
  isIResponseUserIntegration,
} from 'entities/Integrations'
import {
  WINDOW_FEATURES,
  WINDOW_POLL_INTERVAL,
} from 'features/ManageIntegrationConnection/lib/constants'
import { ActiveCampaignConnectModalContent } from 'features/ManageIntegrationConnection/ui/ActiveCampaignConnectModalContent'
import { createWindowCloseWatcher } from 'features/ManageIntegrationConnection/lib/createWindowCloseWatcher'

export class ManageIntegrationConnectionStore {
  private _processing = false
  private _activeModalId = ''
  private _windowClosePollingTimerId: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  private _openIntegrationAuthWindow = (integration: Integration) => {
    return window.open(
      IntegrationsApi.getConnectIntegrationRedirectUrl(integration.key),
      '_blank',
      WINDOW_FEATURES
    )
  }

  private _checkIntegrationConnected = async (integration: Integration) => {
    try {
      const { data } = await IntegrationsApi.checkIntegrationConnectById(integration.key)

      if (isIResponseUserIntegration(data)) {
        integration.syncUserIntegration(data)
        return data.is_active
      }
      return false
    } catch (e) {
      console.error()
      return false
    }
  }

  private _connectViaOAuth = async (integration: Integration) => {
    runInAction(() => {
      this._processing = true
    })

    try {
      if (integration.shouldReconnect) {
        await this.disconnect(integration)
      }

      const newWindow = this._openIntegrationAuthWindow(integration)
      if (!newWindow) return false

      const { windowCloseWatcherPromise, timerId } = createWindowCloseWatcher(
        newWindow,
        WINDOW_POLL_INTERVAL
      )
      this._windowClosePollingTimerId = timerId
      await windowCloseWatcherPromise
      return await this._checkIntegrationConnected(integration)
    } catch (err) {
      console.error(err)
      return false
    } finally {
      runInAction(() => {
        this._processing = false
      })
      this._clearWindowClosePollingTimer()
    }
  }

  private _showIntegrationConnectStatusToast = (integration: Integration) => {
    if (integration.isConnected) {
      toastStore.add({
        type: 'success',
        title: `${integration.name} connected successfully`,
      })
    } else {
      toastStore.add({
        type: 'error',
        title: 'Something went wrong, try again!',
      })
    }
  }

  private _openActiveCampaignConnectModal = (integration: Integration) => {
    return new Promise<boolean | null>((resolve) => {
      this._activeModalId = nanoid()

      const onActiveCampaignConnect = async (apiUrl: string, apiKey: string) => {
        runInAction(() => {
          this._processing = true
        })
        try {
          if (integration.shouldReconnect) {
            await this.disconnect(integration)
          }

          const { data: userIntegrationResponse } = await IntegrationsApi.connectActiveCampaign({
            integrationKey: integration.key,
            apiUrl,
            apiKey,
          })
          if (isIResponseUserIntegration(userIntegrationResponse)) {
            integration.syncUserIntegration(userIntegrationResponse)
          }
          resolve(integration.isConnected)
        } catch (e) {
          console.error(e)
          resolve(false)
        } finally {
          runInAction(() => {
            this._processing = false
          })
          modalStore.removeModal(this._activeModalId)
        }
      }

      const onActiveCampaignModalClose = () => {
        modalStore.removeModal(this._activeModalId)
        resolve(null)
      }

      modalStore.addModal({
        id: this._activeModalId,
        title: 'Connect to ActiveCampaign',
        pureContent: true,
        width: 480,
        ModalContent: ActiveCampaignConnectModalContent,
        ModalContentProps: {
          getIsProcessing: () => this.processing,
          onActiveCampaignConnect,
          onActiveCampaignModalClose,
        },
        onClose: onActiveCampaignModalClose,
      })
    })
  }

  private _navigateToSalesforceConnectionPage = () => {
    uiStore.changeRoute({
      path: '/settings/integrations/salesforce',
      type: 'vue',
    })
  }

  private _clearWindowClosePollingTimer = () => {
    this._windowClosePollingTimerId && window.clearInterval(this._windowClosePollingTimerId)
  }

  connect = async (integration: Integration) => {
    let isConnected: boolean | null = null

    switch (integration.key) {
      case IntegrationKey.salesforce:
        this._navigateToSalesforceConnectionPage()
        break
      case IntegrationKey.activecampaign:
        isConnected = await this._openActiveCampaignConnectModal(integration)
        break
      default:
        if (!integration.oauth) {
          this._openIntegrationAuthWindow(integration)
          break
        }
        isConnected = await this._connectViaOAuth(integration)
    }

    if (isConnected !== null) {
      this._showIntegrationConnectStatusToast(integration)
    }

    return isConnected
  }

  private _requestDisconnect = async (integration: Integration) => {
    runInAction(() => {
      this._processing = true
    })
    try {
      await IntegrationsApi.disconnectIntegration(integration)
      toastStore.add({
        type: 'info',
        title: `${integration.name} disconnected`,
      })
      return true
    } catch (err) {
      console.error(err)
      toastStore.add({
        type: 'success',
        title: 'Something went wrong, try again!',
      })
      return false
    } finally {
      runInAction(() => {
        this._processing = false
      })
    }
  }

  disconnect = async (integration: Integration) => {
    return new Promise<boolean>((resolve) => {
      const modalId = nanoid()

      const onConfirmDisconnect = async () => {
        const isDisconnected = await this._requestDisconnect(integration)
        modalStore.closeModal(modalId)
        resolve(isDisconnected)
      }

      modalStore.addModal({
        id: modalId,
        type: ModalTypeList.ALERT,
        title: 'Disconnect Integration?',
        desc: `Are you sure you want to disconnect from ${integration.name}? This will cause all integration features for ${integration.name} to stop working immediately.`,
        primaryAction: {
          text: 'Disconnect',
          onAction: onConfirmDisconnect,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: () => {
            modalStore.closeModal(modalId)
            resolve(false)
          },
        },
      })
    })
  }

  get processing() {
    return this._processing
  }

  dispose = () => {
    this._clearWindowClosePollingTimer()
  }
}
