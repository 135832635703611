import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { type IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useMenuOutboundLayoutContext } from 'widgets/Menu/ui/MenuOutbound'
import { BroadcastsPageStore } from 'pages/broadcasts/store/broadcastsPageStore'
import { BroadcastPageContext } from 'pages/broadcasts/context/broadcastsPageContext'

const items: IMenuItemProps[] = [
  {
    key: 'broadcasts',
    type: 'nav',
    to: BroadcastsRoutes.all,
    label: 'All broadcasts',
    icon: 'broadcasts',
  },
  {
    key: 'one_time',
    type: 'nav',
    to: BroadcastsRoutes.oneTime,
    label: 'One time',
    icon: 'arrowRightBold',
  },
  {
    key: 'recurring',
    type: 'nav',
    to: BroadcastsRoutes.recurring,
    label: 'Recurring',
    icon: 'repeatBold',
  },
]

export const BroadcastLayout = observer(() => {
  const menuLayoutStore = useMenuOutboundLayoutContext()
  const pageStore = useMemo(() => new BroadcastsPageStore(menuLayoutStore.pageLayoutStore), [])

  if (featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return (
      <BroadcastPageContext.Provider value={pageStore}>
        <Outlet />
      </BroadcastPageContext.Provider>
    )
  }

  return (
    <BroadcastPageContext.Provider value={pageStore}>
      <PageLayout
        leftContent={<Menu title='Broadcasts' menuItems={items} bottomNode={<TrialProgress />} />}
        store={pageStore.pageLayoutStore}
      />
    </BroadcastPageContext.Provider>
  )
})
