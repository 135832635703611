import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { LocalNumbersStepsStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersStepsStore'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

const LocalNumbersStepsContext = createContext<LocalNumbersStepsStore | null>(null)

export const useLocalNumbersStepsContext = () => {
  const context = useContext(LocalNumbersStepsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with LocalNumbersStepsProvider')

  return context
}

export const LocalNumbersStepsProvider = ({
  complianceLayerStore,
  children,
}: {
  complianceLayerStore: ComplianceLayerStore
  children: ReactNode
}) => {
  const store = useMemo(() => new LocalNumbersStepsStore(complianceLayerStore), [])

  useEffect(() => store.dispose, [])

  return (
    <LocalNumbersStepsContext.Provider value={store}>{children}</LocalNumbersStepsContext.Provider>
  )
}
