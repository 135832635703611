// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V2n5Z0i3xI7jmT59RJ1E{padding:0 64px;overflow-y:auto;overflow-x:hidden}.DiV3rtNb6wErsgssbb5J{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,qBAAA","sourcesContent":[".container {\n  padding: 0 64px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.integrationListHeader {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "V2n5Z0i3xI7jmT59RJ1E",
	"integrationListHeader": "DiV3rtNb6wErsgssbb5J"
};
export default ___CSS_LOADER_EXPORT___;
