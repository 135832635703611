// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gXMnxHR7ATRKut_utYKQ{margin-top:4px}.JQ5HNbLifkJfum1RLQH_{display:flex;align-items:center;gap:4px;margin-top:4px}", "",{"version":3,"sources":["webpack://./src/shared/ui/TextFieldWithLabel/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA","sourcesContent":[".error {\n  margin-top: 4px;\n}\n.warning {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "gXMnxHR7ATRKut_utYKQ",
	"warning": "JQ5HNbLifkJfum1RLQH_"
};
export default ___CSS_LOADER_EXPORT___;
