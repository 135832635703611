import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import Layout from 'shared/layout/Layout/ui/Layout'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum, authStore } from 'entities/Auth'
import { callStore } from 'entities/Call'
import { initPosthog } from 'entities/Posthog'
import { initCanny } from 'entities/Canny'
import { eventHeartbeat } from 'entities/DataLog/events/EventHeartbeatLog'
import { initIntercom } from 'entities/Intercom'
import { numbersStore } from 'entities/Phone'
import { remindersStore } from 'widgets/Reminders'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { getAppData } from 'app/shared/lib'
import { SidebarLayout } from './SidebarLayout'

type AuthLayoutProps = {
  withoutSidebar?: boolean
}

export const AuthLayout = observer(({ withoutSidebar }: AuthLayoutProps) => {
  useEffect(() => {
    if (!authStore.isLoggedIn) {
      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.login}`,
      })
    } else {
      getAppData()
      callStore.initDevice()
      initPosthog()
      initCanny()
      initIntercom(true)
      numbersStore.initStartNumberModal()
      numbersStore.initConnectTwilioModal()
      remindersStore.init()
      allMergeFieldsStore.loadAllFields()

      eventHeartbeat()
    }
  }, [authStore.isLoggedIn])

  if (withoutSidebar) {
    return <Layout />
  }

  return <SidebarLayout />
})
