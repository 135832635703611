import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import React, { MouseEventHandler, useState } from 'react'
import classNames from 'classnames'
import { EnumIconButtonShape, IconButton } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { checkCDN } from 'shared/lib/checkCDN'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { FileIcon } from 'widgets/FileIcon/FileIcon'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import styles from './styles.module.scss'

type IMediaCardProps = {
  handleDownload: MouseEventHandler<HTMLButtonElement>
  extension: string
  source_url: string
  title: string
}
export const MediaCard = observer(
  ({ handleDownload, extension, source_url, title }: IMediaCardProps) => {
    const [isCardHovered, setIsCardHovered] = useState(false)
    const isImage = extension.includes('image')

    const handleMouseEnter = () => {
      setIsCardHovered(true)
    }

    const handleMouseLeave = () => {
      setIsCardHovered(false)
    }

    const handleClickDownload: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.stopPropagation()

      handleDownload(event)
    }
    const onOpenPresentation = () => () => {
      if (!isImage) return

      const attachment = new Attachment({
        imageUrl: source_url,
        id: nanoid(),
      })

      presentationStore.setAttachments([attachment])
    }

    return (
      <div className={styles.card} aria-label={getAriaLabel('MediaCard')}>
        <div
          className={styles.cardContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={onOpenPresentation()}
        >
          <div className={styles.overlay}></div>
          {isImage && (
            <div
              className={classNames(styles.imgContainer, styles.img)}
              aria-label={getAriaLabel('MediaCard', 'img')}
            >
              {source_url && title && (
                <img
                  src={source_url}
                  alt={title}
                  {...(checkCDN(source_url) ? { crossOrigin: 'use-credentials' } : null)}
                />
              )}
            </div>
          )}

          <div className={styles.center}>
            {!isImage && (
              <FileIcon docType={'pdf'} fontSize={20} insideBox={true} boxRounded={true} />
            )}
            {isCardHovered && isImage && (
              <IconButton
                icon={'expand2'}
                color={'darkPrimary'}
                shape={EnumIconButtonShape.Rounded}
                ariaLabel='ShowPresentationMode'
                fontSize={16}
                onClick={onOpenPresentation}
              />
            )}
          </div>
          {isCardHovered && (
            <div className={styles.action}>
              <IconButton
                variant={'contained'}
                color={'secondary'}
                size={'mini'}
                fontSize={16}
                icon={'download'}
                onClick={handleClickDownload}
                ariaLabel='IrsEinDocument_download'
                tooltipProps={{
                  label: 'Download',
                  placement: 'top',
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
)
