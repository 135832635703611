import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { IQuickLinkPayload } from 'entities/QuickLink'
import type { MergeField } from 'widgets/MergeField/types/MergeField'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import {
  EnumVariantMessageField,
  mergeFieldReplacer,
  MessageFieldStore,
} from 'widgets/MessageField'

import { MERGE_FIELD_DROPDOWN_LEVEL } from './constants'

export type IQuickLinkUrlStore = QuickLinkUrlStore

export class QuickLinkUrlStore {
  private _disposeUrlReaction: IReactionDisposer | null = null

  private _urlField = new MessageFieldStore({
    placeholder: 'Enter URL',
    styles: { minHeight: 60, maxHeight: 80 },
    noCounter: true,
    disableLinks: true,
    disableMergeFieldsFallback: true,
    variant: EnumVariantMessageField.Default,
    resetOnUnmount: false,
    makeActions: () => [
      {
        iconButtonComponent: (
          <MergeFieldsIconAction
            onAddMergeField={(value) => this._handleMergeField(value)}
            isAllIntegration
            zIndex={MERGE_FIELD_DROPDOWN_LEVEL}
          />
        ),
      },
    ],
  })

  get urlField() {
    return this._urlField
  }

  get isEmpty() {
    return this._quickLink.isUrlEmpty
  }

  constructor(private _quickLink: IQuickLinkPayload) {
    makeAutoObservable(this)

    this._disposeUrlReaction = reaction(
      () => this._urlField.text,
      (url) => this._quickLink.setUrl(url)
    )
  }

  private _handleMergeField = (field: MergeField) => {
    if (!this._urlField.addContent) return

    const fieldContent = mergeFieldReplacer(field.template)

    if (this._urlField.mergeFieldsCount > 0) {
      const element = this._urlField.messageFieldElement
      const mergeField = element?.querySelector('.mergeField-wrap')
      if (!!element && !!mergeField) {
        mergeField.outerHTML = fieldContent
        this._urlField.setMessageText(element.innerHTML, element.innerText)
      }
    } else {
      this._urlField.addContent(fieldContent)
    }
  }

  sync = () => {
    this._urlField.setMessageData({
      message: this._quickLink.url,
      attachments: [],
      isReset: false,
      isReplace: true,
      isFocus: false,
    })
  }

  dispose = () => {
    this._disposeUrlReaction?.()
  }
}
