import React from 'react'
import ReactDatePicker from 'react-datepicker'
import classNames from 'classnames'
import icons from 'shared/ui/Icon/icons'
import { TimePicker } from 'shared/ui/DatePicker/TimePicker/TimePicker'
import './react-datepicker.css'
import styles from './styles.module.scss'

type IDatePickerTypes = 'single' | 'singleInline' | 'range' | 'singleExpanded' | 'dropdown'

export type IDatePickerProps = {
  type?: IDatePickerTypes
  date?: Date | null
  handleDate?: (date: Date | null) => void
  startDate?: Date | null
  endDate?: Date | null
  handleRangeDate?: (startDate: Date | null, endDate: Date | null) => void
  handleStartDate?: (startDate: Date | null) => void
  handleEndDate?: (endDate: Date | null) => void
  handleClose?: () => void
  excludeDateIntervals?: ReactDatePicker['props']['excludeDateIntervals']
  filteredPassedTime?: Date
  offsetMinutes?: number
  showIcon?: boolean
  dateFormat?: string
  placeholder?: string
  hideTimePicker?: boolean
  disableTimePicker?: boolean
  calendarClassName?: string
  parentsDropdownId?: string[]
  autoFocus?: boolean
  isClearable?: boolean
  withPortal?: boolean
  portalId?: string
  withBorder?: boolean
  customInput?: React.ReactNode
}

const DatePicker: React.FC<IDatePickerProps> = ({
  type = 'single',
  date,
  handleDate,
  handleClose,
  startDate,
  endDate,
  handleRangeDate,
  handleStartDate,
  handleEndDate,
  excludeDateIntervals,
  filteredPassedTime,
  offsetMinutes,
  showIcon = true,
  dateFormat = 'MMM d, yyyy',
  placeholder = '',
  hideTimePicker = false,
  isClearable = false,
  calendarClassName = '',
  parentsDropdownId,
  autoFocus,
  disableTimePicker,
  withPortal,
  portalId,
  withBorder = false,
  customInput,
}) => {
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    handleRangeDate && handleRangeDate(start, end)
  }

  const validDate = (date: Date | null | undefined) => {
    if (!date) return null
    return date
  }

  const handleSelectStartDate = (date: Date | null) => {
    if (!date || !endDate || startDate) return

    const endDateTime = +endDate

    if (endDateTime && startDate === null) {
      if (endDateTime - date.getTime() > 0) {
        handleStartDate && handleStartDate(date)
      }
    }
  }

  const selectCallback = (callback: (date: Date | null) => void = () => {}, date: Date | null) => {
    return callback(date)
  }

  if (type === 'single') {
    return (
      <div className={classNames(styles.wrapSinglePicker, { [styles.withBorder]: withBorder })}>
        <ReactDatePicker
          showIcon={showIcon}
          placeholderText={placeholder}
          customInput={customInput}
          selected={validDate(date)}
          onChange={(date) => handleDate && handleDate(date)}
          icon={icons['calendar']}
          isClearable={isClearable}
          dateFormat={dateFormat}
          onCalendarClose={() => handleClose && handleClose()}
          calendarClassName={styles.calendarClassName}
          withPortal={withPortal}
          portalId={portalId}
        />
      </div>
    )
  }

  const inlinePicker = (
    <ReactDatePicker
      excludeDateIntervals={excludeDateIntervals}
      isClearable={isClearable}
      selected={validDate(date)}
      onChange={(date) => handleDate && selectCallback(handleDate, date)}
      icon={icons['calendar']}
      dateFormat='MMM d, yyyy'
      inline
      calendarClassName={classNames(styles.calendarClassName, calendarClassName)}
    />
  )

  if (type === 'singleInline') {
    return inlinePicker
  }

  if (type === 'singleExpanded') {
    return (
      <>
        <div className={styles.wrapInputs}>
          <ReactDatePicker
            showIcon
            selected={validDate(date)}
            onChange={(date) => handleDate && selectCallback(handleDate, date)}
            icon={icons['calendar']}
            dateFormat='MMM d, yyyy'
            isClearable={isClearable}
            enableTabLoop={false}
            popperContainer={() => null}
            placeholderText={placeholder}
            excludeDateIntervals={excludeDateIntervals}
            withPortal={withPortal}
            portalId={portalId}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
          />

          {!hideTimePicker && (
            <TimePicker
              selected={validDate(date)}
              parentsDropdownId={parentsDropdownId}
              onChange={(date) => handleDate && selectCallback(handleDate, date)}
              filteredPassedTime={filteredPassedTime}
              offsetMinutes={offsetMinutes}
              disabled={disableTimePicker}
            />
          )}
        </div>

        {inlinePicker}
      </>
    )
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.left_picker}>
          <div className={styles.wrapInputs}>
            <ReactDatePicker
              showIcon
              withPortal={withPortal}
              portalId={portalId}
              enableTabLoop={false}
              isClearable={isClearable}
              popperClassName='react-datepicker-popper-hidden'
              selected={validDate(startDate)}
              onChange={(date) => handleStartDate && selectCallback(handleStartDate, date)}
              icon={icons['calendar']}
              dateFormat='MMM d, yyyy'
              placeholderText='Start date'
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
            />

            {!hideTimePicker && (
              <TimePicker
                selected={validDate(startDate)}
                parentsDropdownId={parentsDropdownId}
                onChange={(date) => handleStartDate && selectCallback(handleStartDate, date)}
                filteredPassedTime={filteredPassedTime}
                offsetMinutes={offsetMinutes}
              />
            )}
          </div>
        </div>
        <div className={styles.right_picker}>
          <div className={styles.wrapInputs}>
            <ReactDatePicker
              withPortal={withPortal}
              portalId={portalId}
              showIcon
              isClearable={isClearable}
              enableTabLoop={false}
              popperClassName='react-datepicker-popper-hidden'
              selected={validDate(endDate)}
              onChange={(date) => handleEndDate && selectCallback(handleEndDate, date)}
              icon={icons['calendar']}
              dateFormat='MMM d, yyyy'
              placeholderText='End date'
            />
            {!hideTimePicker && (
              <TimePicker
                selected={validDate(endDate)}
                parentsDropdownId={parentsDropdownId}
                onChange={(date) => handleEndDate && selectCallback(handleEndDate, date)}
                filteredPassedTime={filteredPassedTime}
                offsetMinutes={offsetMinutes}
              />
            )}
          </div>
        </div>
      </div>
      <ReactDatePicker
        showPreviousMonths={!(startDate && endDate)}
        renderCustomHeader={({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
          <div>
            <button
              aria-label='Previous Month'
              className={classNames(
                'react-datepicker__navigation react-datepicker__navigation--previous',
                {
                  [styles.hidden]: customHeaderCount === 1,
                }
              )}
              onClick={decreaseMonth}
            >
              <span
                className={
                  'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                }
              >
                {'<'}
              </span>
            </button>
            <span className='react-datepicker__current-month'>
              {monthDate.toLocaleString('en-US', {
                month: 'long',
                year: 'numeric',
              })}
            </span>
            <button
              aria-label='Next Month'
              className={classNames(
                'react-datepicker__navigation react-datepicker__navigation--next',
                {
                  [styles.hidden]: customHeaderCount === 0,
                }
              )}
              onClick={increaseMonth}
            >
              <span
                className={
                  'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                }
              >
                {'>'}
              </span>
            </button>
          </div>
        )}
        onChange={([from, to]) => onChange([from ?? null, to ?? null])}
        onSelect={handleSelectStartDate}
        monthsShown={2}
        startDate={validDate(startDate)}
        endDate={validDate(endDate)}
        selectsRange
        inline
      />
    </div>
  )
}

export { DatePicker }
