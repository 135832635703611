import { UIEvent, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { ContainerActions, Icon, layoutStore, SpinnerLoader, Typography } from 'shared/ui'
import { actionWakeMode } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { DropdownFilter } from 'features/DropdownFilter'
import { DropdownOrder } from 'features/DropdownOrder'
import { SelectAction } from 'widgets/ConversationList/ui/SelectAction/SelectAction'
import { ConversationCard, ConversationNewCard } from 'widgets/ConversationCard'
import { useConversationListContext } from 'widgets/ConversationList/context'
import { BulkActions } from 'widgets/ConversationList/ui/BulkActions'
import styles from 'widgets/ConversationLayout/ui/styles.module.scss'
import { useEventMessageUpdatedNew } from './../events/EventMessageUpdatedNew'
import { useEventMessageReceivedNew } from './../events/EventMessageReceivedNew'
import { useEventVoicemailReceived } from './../events/EventVoicemailReceived'
import { useEventConversationUnread } from './../events/EventConversationUnread'
import { useEventConversationClosed } from '../events/EventConversationClosed'
import { useEventConversationOpened } from '../events/EventConversationOpened'
import { useEventConversationBulkFavorite } from '../events/EventConversationBulkFavorite'

export const ConversationListContent = observer(() => {
  const listRef = useRef<HTMLDivElement | null>(null)
  const { conversationId } = useParams()
  const store = useConversationListContext()
  const {
    updateConversations,
    searchConversations,
    handleScrollPosition,
    listOrders,
    loadingMore,
    loading,
    isEmpty,
    hasMore,
    hasSelected,
    scrollTrigger,
    scrollPosition,
    onOpenConversation,
  } = store
  const { searchDropdownStore } = store.conversationSearchStore

  const handleScroll = (e: UIEvent) => {
    const target = e.target as HTMLDivElement
    const scrollHeight = target.scrollHeight
    const scrollTop = target.scrollTop
    const offsetHeight = target.offsetHeight

    handleScrollPosition(scrollTop)
    if (loading) return
    if (loadingMore) return
    if (!hasMore) return
    if (scrollHeight <= scrollTop + offsetHeight + 100) {
      if (store.conversationSearchStore.hasSearchParams && !searchDropdownStore.isOpen) {
        if (store.conversationSearchStore.hasMore) searchConversations()
      } else {
        updateConversations()
      }
    }
  }

  useEventMessageReceivedNew(store)
  useEventMessageUpdatedNew(store)
  useEventVoicemailReceived(store)
  useEventConversationUnread(store)
  useEventConversationClosed(store)
  useEventConversationOpened(store)
  useEventConversationBulkFavorite()

  useEffect(() => {
    store.handleChangeFirstLoading(true)
    store.handleChangeVisibleUI(true)
    store.reactionConversationNewInbox()
    store.reactionFilter()
    store.reactionInbox()
    store.reactionOrder()

    return () => {
      store.handleChangeFirstLoading(false)
      store.handleChangeVisibleUI(false)
      store.reset()
      store.resetReactions()
    }
  }, [])

  useEffect(
    () =>
      actionWakeMode(() => {
        store.reset()
        store.load()
      }),
    []
  )

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = scrollPosition
    }
  }, [scrollTrigger])

  const isShouldRequestAccess = !!usersStore.user?.isShouldRequestAccess

  const showActions =
    !store.conversationSearchStore.hasSearchParams && !hasSelected && !isShouldRequestAccess

  return (
    <>
      {hasSelected && (
        <ContainerActions
          leftActions={[{ iconButtonComponent: <SelectAction /> }]}
          rightActions={[{ iconButtonComponent: <BulkActions /> }]}
          disabled={loading}
        />
      )}
      {store.conversationSearchStore.hasSearchParams && (
        <div className={styles.total}>
          <Typography variant={'body-md-medium'} ariaLabel={'results'}>
            {store.conversationSearchStore.total} results
          </Typography>
        </div>
      )}
      {showActions && (
        <ContainerActions
          disabled={loading}
          padding='8px 12px 0 8px'
          leftActions={[{ iconButtonComponent: <DropdownFilter /> }]}
          rightActions={[{ iconButtonComponent: <DropdownOrder /> }]}
        />
      )}
      {loading && !loadingMore && (
        <div className={styles.listStatus}>
          <SpinnerLoader />
        </div>
      )}
      {!loading && isEmpty && !store.conversationSearchStore.hasSearchParams && (
        <div className={styles.listStatus}>
          <Icon icon={'chatEmpty'} fontSize={24} />
          <p>No conversations</p>
        </div>
      )}
      {!loading && isEmpty && store.conversationSearchStore.hasSearchParams && (
        <div className={styles.listStatus}>
          <Icon icon={'filter'} fontSize={24} />
          <p>No matching conversations</p>
        </div>
      )}
      <div
        className={`${styles.inboxes} ${loadingMore ? styles.loadingMoreList : ''}`}
        onScroll={handleScroll}
        ref={listRef}
      >
        {(!loading || loadingMore) &&
          listOrders.map((conversation) => {
            if (conversation.isNew) {
              return (
                <ConversationNewCard
                  key={conversation.id}
                  isActive={Number(conversationId) === conversation.id}
                />
              )
            }

            return (
              <ConversationCard
                key={conversation.id}
                conversation={conversation}
                isActive={!layoutStore.isMobileView && Number(conversationId) === conversation.id}
                onOpenAction={onOpenConversation}
              />
            )
          })}
        {loadingMore && (
          <div className={styles.loadingMore}>
            <SpinnerLoader size={16} />
          </div>
        )}
      </div>
    </>
  )
})
