import { makeAutoObservable } from 'mobx'
import type { IAlertProps } from 'shared/ui/Alert/types'
import { uiStore } from 'shared/store/uiStore'
import { Button } from 'shared/ui'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'

export class TrialContactsLimitAlertStore {
  private _totalAmount = 0

  constructor() {
    makeAutoObservable(this)
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get loading() {
    return this.trialSubscription.loading
  }

  get totalAmount() {
    return this._totalAmount
  }

  setTotalAmount = (value: number) => {
    this._totalAmount = value
  }

  init = async () => {
    if (!subscriptionStore.isTrial) return

    await this.trialSubscription.init()
  }

  get isCompleted() {
    return this.trialSubscription.isStatusCompleted
  }

  get configAlert(): IAlertProps {
    if (this.isAdminOrOwnerRole) {
      return {
        marginInline: 20,
        item: {
          type: 'warning',
          desc: this.isCompleted
            ? 'You have reached the limit of 250 contacts on Trial plan. Upgrade to remove the limits.'
            : 'You have reached the limit of 250 contacts on Trial plan. Get approved and upgrade to remove the limits.',
        },
        action: (
          <Button
            typeBtn='action'
            size='mini'
            text={this.isCompleted ? 'Upgrade' : 'Get approved'}
            onClick={() => {
              if (this.isCompleted) {
                uiStore.changeRouteViaVue({
                  path: '/settings/billing/overview/upgrade',
                })
              } else {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance',
                })
              }
            }}
          />
        ),
      }
    }

    return {
      marginInline: 20,
      item: {
        type: 'warning',
        desc: this.isCompleted
          ? 'You have reached the limit of 250 contacts on Trial plan. To remove the limits, please ask one of your admins to upgrade.'
          : 'You have reached the limit of 250 contacts on Trial plan. To remove the limits, please ask one of your admins to get approved and upgrade.',
      },
    }
  }

  get isAdminOrOwnerRole() {
    return usersStore.user?.isAdminOrOwnerRole
  }

  get hide() {
    if (this.loading) return true
    if (!this._totalAmount) return true
    if (!subscriptionStore.isTrial) return true

    return this._totalAmount <= subscriptionStore.trialActiveContactsCount
  }
}
