import { makeAutoObservable } from 'mobx'
import { PhoneInputStore } from 'shared/ui'
import { isEmail } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { getEmailDomain, getUrlDomain } from 'shared/lib/getDomains'
import type { TollFreeStepsStore } from 'pages/settings/pages/compliance/pages/tollFree/store/tollFreeStepsStore'

export class ContactInfoStore {
  private _firstName = ''
  private _lastName = ''
  private _email = ''
  private _emailLocalError = ''
  private _similarityDomainsWarning = ''
  phoneInputStore = new PhoneInputStore()

  constructor(private _tollFreeStepsStore: TollFreeStepsStore) {
    makeAutoObservable(this)
  }

  get contact() {
    return {
      firstName: this._firstName,
      lastName: this._lastName,
      email: this._email,
      phoneNumber: this.phoneInputStore.number,
    }
  }

  get isContactFilled() {
    if (!this.phoneInputStore.isValidNumber) return false

    return [this._firstName, this._lastName, this._email, this.phoneInputStore.number].every(
      (field) => field.trim().length > 0
    )
  }

  get isNextDisabled() {
    return !this.isContactFilled
  }

  get similarityDomainsWarning() {
    return this._similarityDomainsWarning
  }

  setSimilarityDomainsWarning = (value: string) => {
    this._similarityDomainsWarning = value
  }

  get emailLocalError() {
    return this._emailLocalError
  }

  checkDomainSimilarity = (email: string) => {
    const { websiteUrl } = this._tollFreeStepsStore.businessInfoStore || {}

    if (!websiteUrl) return

    const emailDomain = getEmailDomain(email)
    if (!emailDomain) return false

    const urlObj = new URL(websiteUrl)
    const urlDomain = getUrlDomain(urlObj)
    if (!urlDomain) return false

    const isSimilar = emailDomain === urlDomain
    const errorText =
      "It looks like your business email domain doesn't match your business website domain"

    if (!isSimilar) {
      this.setSimilarityDomainsWarning(errorText)
    }
  }

  checkIsEmail = () => {
    const isCorrect = isEmail(this._email)

    if (isCorrect) {
      this.checkDomainSimilarity(this._email)
      this.setEmailLocalError('')
      return
    }

    const errorText = 'Please enter a valid email address'
    this.setEmailLocalError(errorText)
    this.setSimilarityDomainsWarning('')
  }

  setEmailLocalError = (error: string) => {
    this._emailLocalError = error
  }

  setFirstName = (name: string) => {
    this._firstName = name
  }

  setLastName = (name: string) => {
    this._lastName = name
  }

  setEmail = (email: string) => {
    this.setEmailLocalError('')
    this.setSimilarityDomainsWarning('')
    this._tollFreeStepsStore?.deleteErrorValue('email')

    this._email = email
  }

  nextStepClick = async () => {
    uiStore.changeRoute({
      path: this._tollFreeStepsStore.tollFreeNavigationStore.getNextUrl,
    })
  }
}
