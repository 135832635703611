import { FilterItem } from 'widgets/FilterEditor/model'

export const MultiUsersKeys = new Set([
  'broadcasts.owner_id',
  'workflows.owner_id',
  'keywords.owner_id',
  'campaigns.user_id',
])

export const isMultiUsersWidget = (filterItem: FilterItem) => MultiUsersKeys.has(filterItem.key)
