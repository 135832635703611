import { ReactNode, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, Typography, IDropdownItemProps, DropdownCard } from 'shared/ui'
import styles from './styles.module.scss'

export const DropdownItem: FC<IDropdownItemProps> = observer(
  ({ onChange, onChangeCheckbox, item, parent, isSelect, childContainerWidth = 178 }) => {
    const { children = [], customChildren, wrapLabel } = item
    const hasChildren = Boolean(!item.disabledChildren && (children.length || customChildren))

    // TODO: !!! Wrapper !!!
    const Wrapper = ({ children }: { children: ReactNode }) => {
      return (
        <>
          {wrapLabel && (
            <Typography
              variant={'body-sm-medium'}
              color={'tertiary'}
              tag={'div'}
              className={styles.wrapLabel}
              ariaLabel={'wrapLabel'}
            >
              {wrapLabel}
            </Typography>
          )}
          {children}
          {item.divider && <div className={styles.separator} />}
        </>
      )
    }

    if (hasChildren) {
      return (
        <Wrapper>
          <Dropdown
            ariaLabel={'DropdownItem'}
            triggerComponent={(open) => (
              <DropdownCard
                item={item}
                parent={parent}
                onChange={onChange}
                onChangeCheckbox={onChangeCheckbox}
                isSelect={isSelect}
                isHover={open}
              />
            )}
            placement={'right-start'}
            isHover
            parent={item}
            items={children}
            customComponent={customChildren}
            onChange={onChange}
            margin={'4px'}
            width={childContainerWidth}
            PopperProps={{
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    altAxis: true,
                  },
                },
              ],
            }}
            {...item.childrenDropdownProps}
          />
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <DropdownCard
          item={item}
          parent={parent}
          onChange={onChange}
          onChangeCheckbox={onChangeCheckbox}
          isSelect={isSelect}
        />
      </Wrapper>
    )
  }
)
