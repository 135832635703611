import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { useComplianceLayerContext } from 'pages/settings/pages/compliance/context'
import { SettingsPath } from 'pages/settings/route/settingsPath'

export const ComplianceAccessLayout = observer(() => {
  const { pathname, state } = useLocation()
  const store = useComplianceLayerContext()
  const user = usersStore.user
  const isSummaryReady = Boolean(store.summary?.organization_id)

  useEffect(() => {
    if (!user?.isAdminOrOwnerRole) {
      uiStore.changeRoute({
        path: `/${SettingsPath.personal.root}`,
        type: 'vue',
      })
    } else if (!isSummaryReady) {
      uiStore.changeRouteViaVue({
        path: `/${SettingsPath.compliance.questionnaire.root}`,
        options: {
          state: state,
        },
      })
    }
  }, [pathname, user])

  return (
    <>
      <Outlet />
    </>
  )
})
