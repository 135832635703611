import axios, { CanceledError, CancelTokenSource } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { IWorkflowExecution, WorkflowExecution, WorkflowsApi } from 'entities/Workflow'

const EXECUTION_SIZE = 5

export class ChatbotExecutionStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _cancelSyncExecution: CancelTokenSource | null = null
  private _executionsMap = new Map<string, WorkflowExecution>()

  get executions() {
    return Array.from(this._executionsMap.values())
  }

  terminateExecution = async (executionId: string) => {
    try {
      await WorkflowsApi.terminateExecution(executionId)

      return runInAction(() => {
        this._executionsMap.delete(executionId)

        const isEmpty = !this._executionsMap.size

        return isEmpty
      })
    } catch (error) {
      console.error(error)
    }
  }

  syncLatestExecutions = async (conversationId: number) => {
    this._initCancelSyncExecution()

    try {
      const {
        data: { payload: executions },
      } = await WorkflowsApi.getConversationExecutions(
        conversationId,
        {
          state: 'running',
          workflow_source_type: 'chatbot',
          page: { pointer: 0, size: EXECUTION_SIZE },
        },
        {
          cancelToken: this._cancelSyncExecution?.token,
        }
      )

      runInAction(() => this._syncExecutions(executions))
    } catch (error) {
      if (error instanceof CanceledError) return

      runInAction(() => this._executionsMap.clear())

      console.error(error)
    }
  }

  dispose = () => {
    this._cancelSyncExecution?.cancel()
    this._executionsMap.clear()
  }

  private _syncExecutions = (executions: IWorkflowExecution[]) => {
    // delete expired executions
    this._executionsMap.forEach((execution) => {
      const notExist = executions.some(({ id }) => execution.id !== id)

      if (notExist) this._executionsMap.delete(execution.id)
    })

    // add or update actual executions
    executions.forEach((execution) => {
      const actualExecution = this._executionsMap.get(execution.id)

      if (actualExecution) actualExecution.syncOrigin(execution)
      else this._executionsMap.set(execution.id, new WorkflowExecution(execution))
    })
  }

  private _initCancelSyncExecution = () => {
    this._cancelSyncExecution?.cancel()

    this._cancelSyncExecution = axios.CancelToken.source()
  }
}
