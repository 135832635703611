import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { usersStore } from 'entities/Users'

import styles from './styles.module.scss'

export const QuickLinksEmpty = observer(() => (
  <Typography className={styles.empty} ariaLabel={'Quick links empty'} variant='body-sm-regular'>
    {!!usersStore.user?.isViewOnlyRole ? (
      <>No quick links.</>
    ) : (
      <>
        Click on Manage links to add quick
        <br />
        links for your team.
      </>
    )}
  </Typography>
))
