import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { Icon, Tooltip } from 'shared/ui'
import { IconCopyButton } from 'shared/ui/IconButton/IconCopyButton/IconCopyButton'

import styles from './styles.module.scss'

export type ICopyWrapperProps = {
  copyValue: string | number | null
  children?: React.ReactNode
  data?: {
    title: string
    link: string
  }
}

const CopyWrapper: React.FC<ICopyWrapperProps> = ({ copyValue, children, data }) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const element = titleRef.current
    if (!element) return

    const show = element.scrollWidth > element.clientWidth

    setShowTooltip(show)
  }, [])

  if (data) {
    const dataElement = (
      <div className={classnames(styles.wrap, styles.dataWrap)}>
        <div className={styles.header}>
          <div ref={titleRef} className={styles.title}>
            {data.title}
          </div>
          <a href={data.link} target={'_blank'} rel='noreferrer'>
            <div className={styles.icon}>
              <Icon icon='linkExternal' fontSize={12} />
            </div>
          </a>
        </div>
        <div className={styles.action}>
          {copyValue && <IconCopyButton show value={copyValue} showTooltip />}
        </div>
      </div>
    )

    return (
      <Tooltip fullWidth placement='left' disableInteractive label={showTooltip ? data.title : ''}>
        {dataElement}
      </Tooltip>
    )
  }

  return (
    <div className={classnames(styles.wrap)}>
      <div>{children}</div>
      <div className={styles.action}>
        {copyValue && <IconCopyButton show value={copyValue} showTooltip />}
      </div>
    </div>
  )
}

export { CopyWrapper }
