import { makeAutoObservable, runInAction } from 'mobx'
import { showToast } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { Tag } from 'entities/Tags/model/Tag'
import { type IResponseFilterSegment } from 'entities/Segment'
import { TagsControl } from 'entities/Tags'
import { ContactsTagsModalActions } from '../ui/ContactsTagsModalActions'
import { ContactsTagsModalContent } from '../ui/ContactsTagsModalContent'

export type ITagsModalMode = 'add' | 'remove'

export class ContactsTagsModalStore {
  private _saveCallback: (() => void) | null = null
  private _loading = false
  private _contactIdsList: number[] = []
  private _conversation_filter: string | null = null
  private _teamId: string | number | null = null
  private _mode: ITagsModalMode = 'add'
  private _bulkAll = false
  private _filtersList: IResponseFilterSegment[] | null = null
  private _searchParams: string | null = null
  private _control = new TagsControl()
  private _contactsManageTagsModalId = 'contactsManageTagsModal'
  private _contactsTagsTabChangeModalId = 'contactsTagsTabChangeModal'

  constructor() {
    makeAutoObservable(this)
  }

  get control() {
    return this._control
  }

  get mode() {
    return this._mode
  }

  get loading() {
    return this._loading
  }

  get selectedListIds() {
    return this._control.ids
  }

  get hasSelected() {
    return !!this._control.ids.length
  }

  onOpen({
    ids,
    isBulkAll = false,
    callback,
    onCloseCallback = () => {},
    conversation_filter,
    teamId,
    filtersList,
    searchParams,
  }: {
    ids: number[]
    isBulkAll?: boolean
    callback?: () => void
    onCloseCallback?: (() => void) | undefined
    conversation_filter?: string
    teamId?: string | number | null
    filtersList?: IResponseFilterSegment[]
    searchParams?: string
  }) {
    if (callback) this._saveCallback = callback
    this._bulkAll = isBulkAll
    this._contactIdsList = ids
    this._teamId = teamId || null
    this._filtersList = filtersList || null
    this._searchParams = searchParams || null
    this._conversation_filter = conversation_filter || null

    modalStore.addModal({
      id: this._contactsManageTagsModalId,
      title: 'Manage tags',
      width: 480,
      paddingContent: '0px 24px 16px 24px',
      ModalContentProps: {
        contactsTagsModalStore: this,
      },
      ModalActions: ContactsTagsModalActions,
      ModalContent: ContactsTagsModalContent,
      onClose: () => {
        this._closeModal()
        onCloseCallback()
      },
    })
  }

  setMode = (mode: ITagsModalMode) => {
    if (!!this._control.ids.length) this._confirmModeChange(mode)
    else this._mode = mode
  }

  handleSave = async () => {
    if (this._mode === 'add') this._onAdd()
    if (this._mode === 'remove') this._onRemove()
  }

  private _closeModal = () => {
    this._reset()

    modalStore.removeModal(this._contactsManageTagsModalId)
  }

  private _reset = () => {
    this._control.reset()
    this._mode = 'add'
    this._contactIdsList = []
    this._saveCallback = null
    this._bulkAll = false
  }

  private _onAdd = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await ContactsApi.createContactsTags({
        team_id: this._teamId || undefined,
        tags: this._control.tags,
        contact_ids: this._contactIdsList,
        bulk_all: this._bulkAll,
        conversation_filter: this._conversation_filter || undefined,
        filtersList: this._filtersList || [],
        search: this._searchParams || '',
      })

      const tags = data.map((item) => new Tag(item))

      this._contactIdsList.forEach((id) => {
        contactsStore.addItemTags(id, tags)
      })

      showToast({
        title: 'Tags added',
        type: 'success',
      })

      this._saveCallback?.()
    } catch (e) {
      console.log('ERROR: add tags to contacts: ', e)
    } finally {
      runInAction(() => {
        this._loading = false
        this._closeModal()
      })
    }
  }

  private _onRemove = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await ContactsApi.deleteContactsTags({
        team_id: this._teamId || undefined,
        tags: this.selectedListIds,
        contact_ids: this._contactIdsList,
        remove_all: this._bulkAll,
        conversation_filter: this._conversation_filter || undefined,
        filtersList: this._filtersList || undefined,
        search: this._searchParams || undefined,
      })

      data.contacts.forEach((id) => {
        contactsStore.removeItemTags(Number(id), data.tags)
      })

      showToast({
        title: 'Tags deleted',
        type: 'success',
      })

      this._saveCallback?.()
    } catch (e) {
      console.log('ERROR: add tags to contacts: ', e)
    } finally {
      runInAction(() => {
        this._loading = false
        this._closeModal()
      })
    }
  }

  private _confirmModeChange = (mode: ITagsModalMode) => {
    const handleConfirm = () => {
      modalStore.removeModal(this._contactsTagsTabChangeModalId)
    }

    const handleCancel = () => {
      this._control.reset()
      this._mode = mode
      modalStore.removeModal(this._contactsTagsTabChangeModalId)
    }

    modalStore.addModal({
      id: this._contactsTagsTabChangeModalId,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      disabledOnAllClose: true,
      zIndex: 2000,
      width: 280,
      type: ModalTypeList.WARNING,
      title: 'Switching tabs will discard your selection',
      primaryAction: {
        text: 'Keep tags',
        onAction: handleConfirm,
      },
      secondaryAction: {
        text: 'Discard',
        onAction: handleCancel,
      },
      onClose: handleCancel,
    })
  }
}
