import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import {
  CannedMessagesApi,
  IParamsGetCannedMessages,
  IResponseCannedMessage,
} from 'entities/CannedMessages'

type Props = {
  filter: 'favorite' | 'unfavorite'
  setSavedReplay: (savedReplayResponse: IResponseCannedMessage) => void
}

export class ListSavedRepliesStore {
  private _disposeReactionLoadData: IReactionDisposer | null = null
  private _filter: 'favorite' | 'unfavorite' = 'unfavorite'
  private _setSavedReplay: ((savedReplayResponse: IResponseCannedMessage) => void) | null
  private _page = 1
  private _last_page = 0
  private _length = 20
  private _loading = false
  private _parentParams: IParamsGetCannedMessages = {}
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor({ filter, setSavedReplay }: Props) {
    this._filter = filter
    this._setSavedReplay = setSavedReplay
    this.reactionLoadData()

    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get paramsGetItems(): IParamsGetCannedMessages {
    return {
      ...this._parentParams,
      page: this._page,
      length: this._length,
      filter: this._filter,
    }
  }

  get hasMore() {
    return this._page <= this._last_page
  }

  reset = () => {
    this._disposeReactionLoadData?.()
    this.clear()
  }

  clear = () => {
    this._loading = false
    this._last_page = 0
    this._page = 1
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  loadData = async (params?: IParamsGetCannedMessages) => {
    if (params) this._parentParams = params

    if (this._page > 1 && !this.hasMore) {
      return
    }
    try {
      this._loading = true
      this.initCancelTokenSource()

      const { data } = await CannedMessagesApi.getCannedMessages(this.paramsGetItems, {
        ...(this._cancelTokenSource ? { cancelToken: this._cancelTokenSource.token } : null),
      })

      runInAction(() => {
        this._last_page = data.last_page
        this._loading = false
      })

      if (this._setSavedReplay && data) data.data.forEach(this._setSavedReplay)
    } catch (e) {
      if (e instanceof CanceledError) return
      console.error(e)
      this._loading = false
    }
  }

  loadMore = () => {
    if (this._loading) return

    this._page = this._page + 1
    this._loading = true
  }

  reactionLoadData = () => {
    this._disposeReactionLoadData?.()
    this._disposeReactionLoadData = reaction(
      () => this._page,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
  }
}
