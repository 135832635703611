import { Button } from 'shared/ui/Button'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

interface EmptyCellProps {
  onClick?: () => void
}

export const EmptyCell = ({ onClick }: EmptyCellProps) =>
  onClick ? (
    <Button onClick={onClick} typeBtn='text' textClassname={styles.text} text='—' />
  ) : (
    <Typography
      variant={'body-md-regular'}
      color={'var(--content-primary-disabled'}
      ariaLabel={'EmptyCellText'}
    >
      —
    </Typography>
  )
