// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D0Shmx6Jl4np5Vckp7hf{margin-block:-3px;min-width:20px;height:20px;display:flex;align-items:center;justify-content:center;border-radius:4px;background:var(--background-primary-inverted-primary)}.bi3IPJPEH1VLmvcqIJJ6{width:100%;display:flex;justify-content:space-between;align-items:center}.K3f8b6h_P4FwH9ioezKy{display:flex;justify-content:flex-start;align-items:center}.CR474AMMNffqzdkxmfoP{margin-right:8px;color:var(--action-contained-primary-normal)}.CR474AMMNffqzdkxmfoP:hover{color:var(--action-contained-primary-hovered)}", "",{"version":3,"sources":["webpack://./src/widgets/DropdownIntegrationActionItem/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,qDAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,0BAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CACA,4CAAA,CAEA,4BACE,6CAAA","sourcesContent":[".icon {\n  margin-block: -3px;\n  min-width: 20px;\n  height: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  background: var(--background-primary-inverted-primary);\n}\n\n.title {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.action {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.button {\n  margin-right: 8px;\n  color: var(--action-contained-primary-normal);\n\n  &:hover {\n    color: var(--action-contained-primary-hovered);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "D0Shmx6Jl4np5Vckp7hf",
	"title": "bi3IPJPEH1VLmvcqIJJ6",
	"action": "K3f8b6h_P4FwH9ioezKy",
	"button": "CR474AMMNffqzdkxmfoP"
};
export default ___CSS_LOADER_EXPORT___;
