import { ModalTypeList, ModalType } from 'shared/ui/Modal/store/types'
import {
  InfoModalContent,
  InfoModalContentProps,
} from 'shared/ui/Modal/ui/InfoModalContent/InfoModalContent'
import { DefaultModal } from 'shared/ui/Modal/ui/DefaultModal/DefaultModal'
import { RightModal, IRightModalProps } from 'shared/ui/Modal/ui/RightModal/RightModal'

type IModalContentProps = { modal: ModalType | IRightModalProps['modal']; hide: boolean }

export const ModalContent = ({ modal, hide }: IModalContentProps) => {
  const isDefault = !modal.type || modal.type === ModalTypeList.DEFAULT

  if (modal.type === 'right') {
    return <RightModal modal={modal} hide={hide} />
  }

  if (isDefault) {
    return <DefaultModal modal={modal} hide={hide} />
  }

  return <InfoModalContent modal={modal as InfoModalContentProps['modal']} />
}
