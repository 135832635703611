import { makeAutoObservable, runInAction } from 'mobx'
import { IS_APP_LIGHT } from 'shared/config'
import { SingletonRequest } from 'shared/lib'
import { Phone } from 'entities/Phone/model/Phone'
import type {
  IResponseNumber,
  IResponseNumberShortcodes,
  IResponseNumberVendorIntegrations,
} from 'entities/Phone/api/types'
import { PhoneApi } from 'entities/Phone/api/phone'
import { PhoneShortCode } from 'entities/Phone/model/PhoneShortCode'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'
import { GetNumberPFTModalStore } from 'entities/Phone/ui/GetNumberPFTModal'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { QuestionnaireOrSelectNumberModalStore } from 'entities/Phone/ui/QuestionnaireOrSelectNumberModal'
import { ConnectTwilioModalStore } from 'entities/Phone/ui/ConnectTwilioModal'

class NumbersStore {
  initFetchOrganizationNumberPromise
  private setFinishInitFetchOrganizationNumber: ((value?: unknown) => void) | null = null
  initFetchNumberVendorIntegrationsPromise
  private setFinishInitFetchNumberVendorIntegrations: ((value?: unknown) => void) | null = null

  private _loading = true
  private _numbersMap: Map<number, Phone> = new Map()
  private _numbersUserMap: Map<number, Phone> = new Map()
  private _unassignedNumbersUserMap: Map<number, Phone> = new Map()
  private _numbersShortCodesMap: Map<number, PhoneShortCode> = new Map()
  private _numberOrganizationId: number | null = null
  private _numberVendorIntegrations: IResponseNumberVendorIntegrations | null = null
  private _currentNumberId: number | null = null

  constructor() {
    makeAutoObservable(this)
    this.initFetchOrganizationNumberPromise = new Promise((resolve) => {
      this.setFinishInitFetchOrganizationNumber = resolve
    })
    this.initFetchNumberVendorIntegrationsPromise = new Promise((resolve) => {
      this.setFinishInitFetchNumberVendorIntegrations = resolve
    })
  }

  get loading() {
    return this._loading
  }

  get numbers() {
    return Array.from(this._numbersMap.values())
  }

  get userNumbers() {
    return Array.from(this._numbersUserMap.values())
  }

  get unassignedNumbersUser() {
    return Array.from(this._unassignedNumbersUserMap.values())
  }

  get hasNumbersUser() {
    return Array.from(this._numbersUserMap.values()).length !== 0
  }

  get hasNumberVendorIntegrations() {
    return this._numberVendorIntegrations
  }

  get hasTwilioNumberVendorIntegration() {
    if (!this.hasNumberVendorIntegrations) return false

    return this._numberVendorIntegrations?.hasOwnProperty('twilio-integration')
  }

  get organizationNumber() {
    if (!this._numberOrganizationId) return

    return this.getItem(this._numberOrganizationId)
  }

  initConnectTwilioModal = () => {
    new ConnectTwilioModalStore().init()
  }

  initStartNumberModal = async () => {
    await Promise.all([
      featureFlagsStore.initPromise,
      usersStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
    ])

    if (organizationStore.isTwilioNumberVendor) return

    if (subscriptionStore.isTrial) {
      if (usersStore.user?.isRequirePftNumber) {
        new GetNumberPFTModalStore().open()
      }
    } else if (subscriptionStore.isActive || subscriptionStore.isPastDue) {
      if (usersStore.user?.isAdminOrOwnerRole) {
        new QuestionnaireOrSelectNumberModalStore().init()
      } else if (!usersStore.user?.isViewOnlyRole) {
        new GetNumberModalStore().openDefault()
      }
    }
  }

  initSelectNumberModal = () => {
    if (IS_APP_LIGHT) return

    new GetNumberModalStore().openOnboarding()
  }

  fetchOrganizationNumber = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data: organizationNumber } = await PhoneApi.getOrganizationNumber()

      runInAction(() => {
        if (organizationNumber.id) {
          this._numberOrganizationId = organizationNumber.id
          this.addItem(organizationNumber)
        }
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
      this.setFinishInitFetchOrganizationNumber?.()
    }
  }

  fetchNumberVendorIntegrations = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await PhoneApi.getNumberVendorIntegrations()

      runInAction(() => {
        this._numberVendorIntegrations = data
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
      this.setFinishInitFetchNumberVendorIntegrations?.()
    }
  }

  addItems = (items: IResponseNumber[]) => {
    if (!Array.isArray(items)) return

    items.forEach((item) => {
      this.addItem(item)
    })

    return this._numbersMap
  }

  hasItem = (id: number) => {
    return this._numbersMap.has(id)
  }

  deleteItem = (id: number) => {
    this._numbersMap.delete(id)
  }

  getItem = (id?: number | null) => {
    if (typeof id !== 'number') return

    return this._numbersMap.get(id)
  }

  getItems = (ids?: number[]) => ids?.map(this.getItem).filter(Boolean) as Phone[]

  addItem = (item: IResponseNumber) => {
    const number = this._numbersMap.get(item.id)

    if (number) {
      number.syncOrigin({ ...number.origin, ...item })
    } else {
      this._numbersMap.set(item.id, new Phone(item))
    }

    return this.getItem(item.id)
  }

  updateItem = (item: Phone) => {
    const number = this._numbersMap.get(item.id)

    if (number) {
      number.syncOrigin({ ...number.origin, ...item.origin })
    }

    return this.getItem(item.id)
  }

  private _fetchNumbersShortCodes = async () => {
    try {
      const { data } = await PhoneApi.getNumbersShortcodes()

      data.forEach((item) => {
        this.setItemShortCode(item)
      })
    } catch (e) {
      console.log(e)
    }
  }

  fetchNumbersShortCodes = new SingletonRequest(this._fetchNumbersShortCodes).request

  fetchNumbers = async () => {
    try {
      // default params for core
      const params = {
        page: 1,
        limit: 500,
      }

      const { data } = await PhoneApi.getNumbers(params)

      data.data.forEach((item) => {
        this._numbersUserMap.set(item.id, new Phone(item))
      })
    } catch (e) {
      console.log(e)
    }
  }

  fetchNumbersByIds = async (ids: number[]) => {
    try {
      const result: Phone[] = []
      const params = {
        ids,
      }
      const { data } = await PhoneApi.getNumbers(params)

      data.data.forEach((item) => {
        result.push(new Phone(item))
      })
      return result
    } catch (e) {
      console.log(e)
      return []
    }
  }

  fetchUnnassignedNumbers = async () => {
    try {
      const { data } = await PhoneApi.getUnnassignedNumbers({
        page: 1,
        limit: 500,
      })

      data.data.forEach((item) => {
        this._unassignedNumbersUserMap.set(item.id, new Phone(item))
      })
    } catch (e) {
      console.error(e)
    }
  }

  setItemShortCode = (item: IResponseNumberShortcodes) => {
    if (item.number) {
      this.addItem(item.number)
    }

    this._numbersShortCodesMap.set(item.id, new PhoneShortCode(item))
  }

  getItemShortCode = (id: number) => {
    return this._numbersShortCodesMap.get(id)
  }

  getShortCodeByNumber = (numberId: number) => {
    return this.itemsShortCodes.find((item) => item.numberId === numberId)
  }

  get itemsShortCodes() {
    return Array.from(this._numbersShortCodesMap.values())
  }

  setNumberId = (id: number | null) => {
    this._currentNumberId = id
  }

  get currentNumber() {
    return this.getItem(this._currentNumberId)
  }
}

export const numbersStore = new NumbersStore()
