import { IIconsVector } from 'shared/ui'

export const iconsMapping: Record<string, IIconsVector> = {
  tag: 'tag',
  first_name: 'user',
  last_name: 'user',
  number: 'phone',
  email: 'email',
  message_status: 'messageCircleChecked',
  opt_in: 'toggle',
  source: 'toggle',
  keyword: 'keywords',
  total_received_sms: 'pieChart',
  total_received_call: 'pieChart',
  shortcode: 'hash',
  segments: 'segment',
  hubspot: 'plug',
  'hubspot-lists': 'plug',
  status: 'circleDot',
  created_at: 'calendar',
  owner_id: 'userFilled',
  sent_at: 'calendarArrowRight',
  number_id: 'phone',
  'broadcasts.status': 'circleDot',
  'broadcasts.created_at': 'calendar',
  'broadcasts.owner_id': 'userFilled',
  'broadcasts.sent_at': 'calendarArrowRight',
  'broadcasts.number_id': 'phone',
  'broadcasts_recurring_settings.type': 'repeat',
  'broadcasts_recurring_settings.status': 'circleDot',
}

const customFieldIconMapping: Record<string, IIconsVector> = {
  number: 'number',
  text: 'textInput2',
  url: 'link',
  date: 'calendar',
}

const dateTypes = ['updated_created', 'date_time']

export const getIcon = ({
  type,
  key,
  category,
}: {
  type?: string
  key: string
  category?: string
}): IIconsVector => {
  if (key.includes('custom.') && type) {
    return customFieldIconMapping[type]
  }

  if (category === 'geo-location') {
    return 'makerPin'
  }

  if (category === 'phone-checker') {
    return 'check2'
  }

  if (type && dateTypes.includes(type)) {
    return 'calendar'
  }

  return iconsMapping[key]
}

export const urlSegmentId = 'segment_id'

export const withoutValue = ['is_known', 'is_unknown', 'last_week', 'last_month']

export const datePicker = [
  'date',
  'date_time',
  'date_time_shortcode',
  'updated_created',
  'created_at',
  'date_without_checkbox',
]

export const dateTimePicker = ['date_time', 'date_time_shortcode']

export const dateTextTypes = [
  'is_more_than',
  'is_less_than',
  'updated_in_last',
  'not_updated_in_last',
]

export const selectTypes = ['hubspot', 'hubspot-lists']

export const addableFilterTypes = [
  'area_code',
  'message_received_body',
  'total_received_sms',
  'total_received_call',
  'number',
  'text',
  'url',
  'first_name',
  'last_name',
  'carrier_name',
  'email',
]

export const checkboxList = [
  'country',
  'keyword',
  'opt_in',
  'segments',
  'select',
  'source',
  'time_zone',
  'shortcode',
  'state',
  'lookup_type',
  'message_status',
  'state_code',
  'is_invalid',
  'select_without_checkbox',
]

export const singleDateOperators = ['is_equal_to', 'is_after', 'is_before']
export const rangeDateOperators = ['is_between', 'is_not_between']

export const withBoolKey = ['is_invalid']

export const airCallTooltip = 'MMS is not currently supported by Aircall'

export const MIN_COUNT_CHARTERS_FOR_SEARCH = 3
export const minSearchCountText = `Please enter ${MIN_COUNT_CHARTERS_FOR_SEARCH} or more characters`
