import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Button, showToast } from 'shared/ui'
import { IIntegrationLinkDTO, integrationsStore } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { LinkWithPhonesModalActions } from './LinkWithPhones/LinkWithPhonesModalActions'
import { LinkWithPhonesModalContent } from './LinkWithPhones/LinkWithPhonesModalContent'
import { linkContactStore } from '../../store/linkContactStore'

import styles from './styles.module.scss'

type ILinkContactModalActionsProps = {
  modalId: string
  contact: Contact
}

const LinkContactModalActions = observer(({ modalId, contact }: ILinkContactModalActionsProps) => {
  const {
    reset,
    selectedContact,
    onLink,
    shouldUpdate,
    loading,
    activeTab,
    selectedMatchedId,
    matchedContacts,
    handleSelectedMatchedPhone,
  } = linkContactStore

  const key = selectedContact?.integration_key || selectedContact?.integration_type

  const integration = integrationsStore.getIntegration(key)
  const phoneModalId = nanoid()

  const handleClose = () => {
    reset()
    modalStore.removeModal(modalId)
  }

  const disabled =
    (activeTab === 'manually' && !selectedContact) ||
    (activeTab === 'matching' && !selectedMatchedId)

  const handleLinkManually = async () => {
    if (!integration) return

    const data: IIntegrationLinkDTO = {
      integration_id: integration.id,
      integration_name: integration.name,
      integration_key: integration.key,
      contact_id: contact.id,
      contact_integration_id: selectedContact?.integration_vendor_id || selectedContact?.id || 0,
      first_name: selectedContact?.first_name || '',
      last_name: selectedContact?.last_name || '',
      email: selectedContact?.email || '',
      number: selectedContact?.phoneInfo || '',
      update_contact: shouldUpdate,
    }

    const toast = await onLink(data)

    showToast(toast)

    if (toast.type === 'success') {
      handleClose()
    }
  }

  const handleClosePhonesModal = () => {
    modalStore.removeModal(phoneModalId)
  }

  const handleLinkMatching = async () => {
    const hubspotIntegration = integrationsStore.getIntegration(IntegrationKey.hubspot)
    if (!hubspotIntegration) return

    const matchedContactsList = matchedContacts.get(contact.id)
    if (!matchedContactsList) return

    const matchedContact = matchedContactsList.find(
      (item) => item.integrationVendorId === selectedMatchedId
    )
    if (!matchedContact) return

    if (matchedContact.mobile_phone && matchedContact.phone) {
      modalStore.addModal({
        id: phoneModalId,
        showCloseButton: false,
        showCloseIcon: true,
        showHeader: true,
        title: 'Phone number',
        width: 480,
        paddingTopModal: 120,
        onClose: handleClosePhonesModal,
        ModalContent: () => <LinkWithPhonesModalContent contact={matchedContact} />,
        ModalActions: () => (
          <LinkWithPhonesModalActions
            contact={contact}
            modalId={phoneModalId}
            parentModalId={modalId}
            matchedContact={matchedContact}
            hubspotIntegration={hubspotIntegration}
          />
        ),
      })

      handleSelectedMatchedPhone(matchedContact.phone)

      return
    }

    const data: IIntegrationLinkDTO = {
      integration_id: hubspotIntegration.id,
      integration_name: hubspotIntegration.name,
      integration_key: hubspotIntegration.key,
      contact_id: contact.id,
      contact_integration_id: matchedContact?.integrationVendorId || '',
      first_name: matchedContact?.first_name || '',
      last_name: matchedContact?.last_name || '',
      email: matchedContact?.email || '',
      number: matchedContact?.phone || matchedContact?.mobile_phone || '',
      update_contact: shouldUpdate,
    }

    const toast = await onLink(data)

    showToast(toast)

    if (toast.type === 'success') {
      handleClose()
    }
  }

  const handleLink = () => {
    if (activeTab === 'manually') handleLinkManually()
    if (activeTab === 'matching') handleLinkMatching()
  }

  return (
    <div className={classnames(styles.wrap)}>
      <Button contained='secondary' text='Cancel' onClick={handleClose} disabled={loading} />
      <Button
        loading={loading}
        contained='primary'
        text='Link contact'
        disabled={disabled || loading}
        onClick={handleLink}
      />
    </div>
  )
})

export { LinkContactModalActions }
