import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { GetNumberModalStore } from 'entities/Phone/ui/GetNumberModal'
import { complianceStore } from 'entities/Compliance'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { usersStore } from 'entities/Users'
import { QuestionnaireOrSelectNumberModalContent } from 'entities/Phone/ui/QuestionnaireOrSelectNumberModal/ui/QuestionnaireOrSelectNumberModalContent'
import { QuestionnaireOrSelectNumberModalActions } from 'entities/Phone/ui/QuestionnaireOrSelectNumberModal/ui/QuestionnaireOrSelectNumberModalActions'
import { QuestionnaireStepsStore } from 'entities/Compliance/store/questionnaireStepsStore/questionnaireStepsStore'
import { organizationStore } from 'entities/Organization'

export class QuestionnaireOrSelectNumberModalStore {
  private _idModal = 'questionnaireOrSelectNumberModal'
  private _step = 1
  private _maxStep = 4
  private _numberModalStore = new GetNumberModalStore()
  private _questionnaireStepsStore = new QuestionnaireStepsStore()
  private _disposeShow: IReactionDisposer | null = null
  private _show = false

  constructor() {
    makeAutoObservable(this)
  }

  get questionnaireStepsStore() {
    return this._questionnaireStepsStore
  }

  get numberModalStore() {
    return this._numberModalStore
  }

  get summary() {
    return complianceStore.summary
  }

  get step() {
    return this._step
  }

  get maxStep() {
    return this._maxStep
  }

  get disabled() {
    if (this._step === 1) return this._questionnaireStepsStore.disabledTargetCountry
    if (this._step === 2) return this._questionnaireStepsStore.disabledIsTaxId
    if (this._step === 3) return this._questionnaireStepsStore.disabledNumberType
    if (this._step === 4) return this._numberModalStore.disabled

    return false
  }

  get titleStep() {
    if (this._step === 1) return 'Which country do you want to send messages to?'
    if (this._step === 2) return 'Do you have a business Tax ID?'
    if (this._step === 3) return 'What numbers will you use to send messages?'
    if (this._step === 4) return 'Choose your number'

    return ''
  }

  get descriptionStep() {
    if (this._step === 1) return ''
    if (this._step === 2)
      return 'In the US - EIN. In Canada - BN. Other countries not supported yet.'
    if (this._step === 3) return ''
    if (this._step === 4)
      return 'You can always change this number later or replace with your existing number, landline, or Aircall number.'

    return ''
  }

  get textSubmit() {
    if (this._step === 4) return 'Confirm number'

    return 'Next'
  }

  get hideBack() {
    return !(this._step === 1)
  }

  get isQuestionnaire() {
    return complianceStore.isQuestionnaire
  }

  get isSelectedNumber() {
    return !usersStore.user?.isRequireNumber
  }

  setStep = (step: number) => {
    this._step = step
  }

  nextStep = () => {
    if (this._step === this._maxStep) return

    this.setStep(this._step + 1)
  }

  backStep = () => {
    if (this._step === 1) return

    this.setStep(this._step - 1)
  }

  onNext = async () => {
    const isAgency = organizationStore.isAgency

    if (this._step === 3) {
      await this._questionnaireStepsStore.saveSummary({
        isOpenModalSelectNumber: false,
        isRedirect: isAgency,
      })

      if (this.isSelectedNumber) {
        this.closeModal()
      } else {
        this.nextStep()
      }

      return
    }

    if (this._step === 4) {
      await this._numberModalStore.handleAddNumber({
        isRedirect: isAgency,
      })

      this.closeModal()

      return
    }

    this.nextStep()
  }

  init = async () => {
    this.reactionShow()

    await Promise.all([
      featureFlagsStore.initPromise,
      usersStore.initPromise,
      complianceStore.fetchSummary(),
    ])

    this.initSteps()
  }

  dispose = () => {
    this._disposeShow?.()
  }

  initSummary = () => {
    if (this.summary?.organization_id) {
      this._questionnaireStepsStore.setIsTaxId(this.summary.has_tax_id)
      this._questionnaireStepsStore.setNumberType(this.summary.numbers_type)
      this._questionnaireStepsStore.setTargetCountry(this.summary.target_country)
    }
  }

  initSteps = () => {
    const isQuestionnaire = this.isQuestionnaire
    const isSelectedNumber = this.isSelectedNumber

    if (isQuestionnaire && isSelectedNumber) {
      this.dispose()

      return
    }

    if (isQuestionnaire) {
      this.initSummary()
      this.setStep(4)
      this._show = true

      return
    }

    if (isSelectedNumber) {
      this.dispose()

      return
    }

    this.setStep(1)
    this._show = true
  }

  reactionShow = () => {
    this._disposeShow?.()
    this._disposeShow = reaction(
      () => this._show,
      (value) => {
        if (value) {
          this.open()
        }
      }
    )
  }

  closeModal = () => {
    this.dispose()
    modalStore.removeModal(this._idModal)
  }

  open = async () => {
    await this.init()

    modalStore.addModal({
      id: this._idModal,
      title: 'Get started',
      showCloseIcon: false,
      showCloseButton: false,
      disabledOnAllClose: true,
      disabledOnEscClose: true,
      disabledOverlayClose: true,
      width: 480,
      ModalContentProps: {
        store: this,
      },
      paddingContent: '0px 24px 16px 24px',
      ModalActions: QuestionnaireOrSelectNumberModalActions,
      ModalContent: QuestionnaireOrSelectNumberModalContent,
      onClose: this.closeModal,
    })
  }
}
