// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pTOCEYhhWXVk2lz3c8j_{display:inline-flex;justify-content:space-between;align-items:baseline;gap:4px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsContentItems/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,6BAAA,CACA,oBAAA,CACA,OAAA","sourcesContent":[".titleWithBadge {\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: baseline;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWithBadge": "pTOCEYhhWXVk2lz3c8j_"
};
export default ___CSS_LOADER_EXPORT___;
