// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nuZrlOXetf5z2JlNr_6U{display:flex;border-radius:6px;border:1px solid var(--border-neutral-default);background:var(--background-primary-inverted-primary, #fff);padding:4px 8px;align-items:center;gap:4px}", "",{"version":3,"sources":["webpack://./src/entities/Contacts/ui/ContactCard/MiniContactCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,8CAAA,CACA,2DAAA,CACA,eAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: flex;\n  border-radius: 6px;\n  border: 1px solid var(--border-neutral-default);\n  background: var(--background-primary-inverted-primary, #fff);\n  padding: 4px 8px;\n  align-items: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "nuZrlOXetf5z2JlNr_6U"
};
export default ___CSS_LOADER_EXPORT___;
