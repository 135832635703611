import classnames from 'classnames'
import { FC } from 'react'
import { EnumAlertBannerVariant, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IButtonProps = {
  variant: 'primary' | 'secondary'
  variantBanner: EnumAlertBannerVariant
  text: string
  onClick: () => void
}

export const Button: FC<IButtonProps> = ({ variant, text, variantBanner, onClick }) => {
  return (
    <button
      className={classnames(
        styles.button,
        styles[`button--${variant}`],
        styles[`button--banner_${variantBanner}`]
      )}
      onClick={onClick}
    >
      <Typography variant={'button-small'} ariaLabel={'AlertBanner_button'}>
        {text}
      </Typography>
    </button>
  )
}
