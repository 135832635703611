import { usersStore } from 'entities/Users/store/users'
import { userPermissionsStore } from 'entities/UsersPermissions/store/userPermissions'
import { featureFlagsStore } from 'entities/FeatureFlags/store/featureFlags'

export enum EnumAccessPageKeys {
  broadcasts = 'broadcasts',
  triggers = 'triggers',
  power_dialer = 'power_dialer',
  chatbot = 'chatbot',
  knowledge_base = 'knowledge_base',
  workflows = 'workflows',
  campaigns = 'campaigns',
  keywords = 'keywords',
}

export const promiseCheckAccessPage = async (key: EnumAccessPageKeys) => {
  await Promise.all([
    featureFlagsStore.initPromise,
    usersStore.initPromise,
    userPermissionsStore.initPromise,
  ])

  return checkAccessPage(key)
}

export const checkAccessPage = (key: EnumAccessPageKeys) => {
  const user = usersStore.user
  const isViewOnlyRole = user?.isViewOnlyRole
  const permissionCanManageBroadcasts = userPermissionsStore.getItem('can_manage_broadcasts')
  const permissionCanManageTriggers = userPermissionsStore.getItem('can_manage_triggers')
  const permissionCanManageTextBots = userPermissionsStore.getItem('can_manage_text_bots')
  const permissionCanManageCampaigns = userPermissionsStore.getItem('can_manage_campaigns')

  const canManageBroadcasts = !Boolean(!permissionCanManageBroadcasts || isViewOnlyRole)
  const canManageTriggers = !Boolean(!permissionCanManageTriggers || isViewOnlyRole)
  const canManagePowerDialer = !Boolean(
    !featureFlagsStore.salesmsg_power_dialer_v2 || isViewOnlyRole
  )
  const canManageTextBots = !Boolean(
    !featureFlagsStore.chatbot || isViewOnlyRole || !permissionCanManageTextBots
  )
  const canManageKnowledgeBase = !Boolean(
    !featureFlagsStore.rag || isViewOnlyRole || !permissionCanManageTextBots
  )
  const canManageWorkflows = !Boolean(!featureFlagsStore.canOpenWorkflow || isViewOnlyRole)
  const canManageCampaigns = !Boolean(
    !featureFlagsStore.campaigns || isViewOnlyRole || !permissionCanManageCampaigns
  )
  const canManageKeywords = !Boolean(user?.isViewOnlyRole)

  switch (key) {
    case 'broadcasts':
      return canManageBroadcasts
    case 'triggers':
      return canManageTriggers
    case 'power_dialer':
      return canManagePowerDialer
    case 'chatbot':
      return canManageTextBots
    case 'knowledge_base':
      return canManageKnowledgeBase
    case 'workflows':
      return canManageWorkflows
    case 'campaigns':
      return canManageCampaigns
    case 'keywords':
      return canManageKeywords
    default:
      return true
  }
}
