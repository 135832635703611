// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jdaKuIrbCYbbPZZX5pOR{margin-bottom:4px}.jdaKuIrbCYbbPZZX5pOR a{display:flex;justify-content:center;padding:20px 10px;font-size:24px}.jdaKuIrbCYbbPZZX5pOR .NY84CBNh83he_OKNCIKb{height:24px}", "",{"version":3,"sources":["webpack://./src/widgets/Sidebar/ui/Logo/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,wBACE,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,cAAA,CAEF,4CACE,WAAA","sourcesContent":[".wrap {\n  margin-bottom: 4px;\n\n  a {\n    display: flex;\n    justify-content: center;\n    padding: 20px 10px;\n    font-size: 24px;\n  }\n  .icon {\n    height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "jdaKuIrbCYbbPZZX5pOR",
	"icon": "NY84CBNh83he_OKNCIKb"
};
export default ___CSS_LOADER_EXPORT___;
