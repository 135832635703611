import { observer } from 'mobx-react-lite'
import { useEffect, FC } from 'react'
import { Button, Dropdown } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { SearchElement, FilterEditorAdd } from 'widgets/FilterEditor'
import { FilterEditorProvider, useFilterEditorContext } from 'widgets/FilterEditor/context'
import { type ContactsMenuStore } from 'widgets/ContactsMenu'
import { FiltersContent } from './FiltersContent'
import styles from './styles.module.scss'

type IContactsFiltersBodyProps = {
  contactsMenuStore: ContactsMenuStore
}

const ContactsFiltersBody: FC<IContactsFiltersBodyProps> = observer(({ contactsMenuStore }) => {
  const segmentModalStore = contactsMenuStore.segmentModalStore
  const contactsTableStore = contactsMenuStore.contactsTableStore
  const contactsFilterStore = contactsMenuStore.contactsFilterStore
  const { opened, onOpen, clearSegment, initFilter } = contactsFilterStore
  const filterEditorStore = useFilterEditorContext()
  const hasFilters = Boolean(filterEditorStore.groups.count)

  useEffect(() => {
    initFilter()
  }, [])

  const handleClearSearch = () => {
    clearSegment()
    contactsTableStore?.changeSearchAndResetPaginationPage('')
  }

  if (filterEditorStore.loading) return null

  return (
    <div className={styles.searchWrapper}>
      <div className={styles.searchInputWrap}>
        {hasFilters ? (
          <Dropdown
            ariaLabel={'ContactsFilters'}
            show={opened}
            width={contactsTableStore.tableWidth || undefined}
            overlayModalsIds={[segmentModalStore.idModal]}
            triggerComponent={(open) => (
              <SearchElement active={open} count={filterEditorStore.groups.count} />
            )}
            placement={'bottom-start'}
            onClose={() => onOpen(false)}
            onToggle={onOpen}
            customComponent={(onCloseMenu, dropdownId) => {
              return (
                <FiltersContent
                  filterEditorStore={filterEditorStore}
                  parentsDropdownId={[dropdownId]}
                  onCloseMenu={onCloseMenu}
                  contactsMenuStore={contactsMenuStore}
                  onClearFilters={handleClearSearch}
                />
              )
            }}
          />
        ) : (
          <>
            <FilterEditorAdd
              store={filterEditorStore}
              triggerComponent={(open) => (
                <SearchElement active={open} count={filterEditorStore.groups.count} />
              )}
              onChange={() => {
                onOpen(true)
                contactsFilterStore.setNewSegment()
              }}
            />
          </>
        )}

        <TextField
          ariaLabel='Contacts_SearchField'
          className={styles.textField}
          variant='integrated'
          InputProps={{
            placeholder: 'Search contacts...',
          }}
          onChange={contactsTableStore?.changeSearchAndResetPaginationPage}
          value={contactsTableStore?.search}
          mainActions={[makeTextFieldAutoFocus({ withFocus: !contactsTableStore?.search })]}
        />
      </div>

      {(hasFilters || !!contactsTableStore?.search) && (
        <Button
          contained='secondary'
          tooltipProps={{
            label: 'Clear all',
            disableInteractive: true,
          }}
          typeBtn='text'
          text='Clear all'
          onClick={handleClearSearch}
        />
      )}
    </div>
  )
})

type IContactsFiltersProps = {
  contactsMenuStore: ContactsMenuStore
}

export const ContactsFilters: FC<IContactsFiltersProps> = observer(({ contactsMenuStore }) => (
  <FilterEditorProvider store={contactsMenuStore.contactsFilterStore.filterEditorStore}>
    <ContactsFiltersBody contactsMenuStore={contactsMenuStore} />
  </FilterEditorProvider>
))
