import { useCallback, useState } from 'react'
import { writeTextToClipboard } from 'shared/lib'

export const useCopyToClipboard = (value: string | number | undefined) => {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const onCopy = useCallback(async () => {
    if (!window?.navigator?.clipboard) return

    try {
      await writeTextToClipboard(`${value}`)
    } catch (e) {
      console.error(e)
    }
  }, [value])

  const onClickCopy = useCallback(() => {
    if (copied) return
    onCopy()
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }, [copied])

  return { onClickCopy, setOpen, open, copied }
}
