import { observer } from 'mobx-react-lite'
import { Icon } from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { useQuickLinksContext } from 'pages/settings/pages/quickLinks/context/quickLinksContext'

import styles from './styles.module.scss'

export const QuickLinkSearch = observer(() => {
  const { listStore } = useQuickLinksContext()

  return (
    <div className={styles.search}>
      <Icon icon='search' color='secondary' fontSize={20} padding={6} tertiary />
      <TextField
        className={styles.field}
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
        }}
        onChange={listStore.search}
        value={listStore.term}
      />
    </div>
  )
})
