import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useKeywordsListContext } from 'pages/keywords/context'

export const DeleteAction = observer(() => {
  const { onBulkDelete } = useKeywordsListContext()

  return (
    <Button
      text='Delete'
      icon='delete'
      typeBtn='contained'
      contained='tertiary'
      size='small'
      onClick={onBulkDelete}
    />
  )
})
