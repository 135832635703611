import { observer } from 'mobx-react-lite'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import { ForbiddenMessageCommonTopics } from 'pages/settings/pages/compliance/ui/ForbiddenMessageCommonTopics/ui/ForbiddenMessageCommonTopics'

export const ForbiddenMessageTopics = observer(() => {
  const { forbiddenMessageStore, stepLoading } = useTollFreeStepsContext()

  const { forbiddenMessageAgreed, isNextStepDisabled, setForbiddenMessageAgreed, onSubmit } =
    forbiddenMessageStore

  return (
    <ForbiddenMessageCommonTopics
      nextButtonText='Submit for review'
      stepLoading={stepLoading}
      setForbiddenMessageAgreed={setForbiddenMessageAgreed}
      isNextStepDisabled={isNextStepDisabled}
      forbiddenMessageAgreed={forbiddenMessageAgreed}
      increaseStep={onSubmit}
    />
  )
})
