import { observer } from 'mobx-react-lite'
import { useEffect, FC } from 'react'
import { useUnsavedChanges } from 'shared/hooks'
import { subscriptionStore } from 'entities/Subscription'
import { TrialContactsLimitAlert } from 'entities/Subscription/ui/TrialContactsLimitAlert'
import { ContactsFilters, type ContactsFilterStore } from 'widgets/ContactsFilters'
import { ContactsTable, type ContactsTableStore } from 'widgets/ContactsTable'
import { ContactsContainerActions } from 'widgets/ContactsTable/ui/ContactsContainerActions'
import { CleanupAlert } from 'widgets/CleanupAlert/CleanupAlert'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { type ContactsMenuStore } from 'widgets/ContactsMenu'
import { type SegmentModalStore } from 'widgets/ContactsMenu/ui/SegmentModal'
import { AddFirstContactContent } from 'pages/contacts/ui/AddFirstContactContent/AddFirstContactContent'
import styles from './styles.module.scss'

type IContactsContentProps = {
  contactsMenuStore: ContactsMenuStore
  contactsTableStore: ContactsTableStore
  segmentModalStore: SegmentModalStore
  contactsFilterStore: ContactsFilterStore
}

export const ContactsContent: FC<IContactsContentProps> = observer(
  ({ contactsMenuStore, contactsTableStore, segmentModalStore, contactsFilterStore }) => {
    const {
      isEmptyContactsList,
      isNoContactYet,
      noEmptyFilters,
      setActiveContact,
      getTotalAmount,
    } = contactsTableStore
    const { isNeedSaveChangesModal, onToggle, segmentBox, filterEditorStore } = contactsFilterStore
    const { activeSegment } = contactsMenuStore
    const { getAskIfSaveModal } = segmentModalStore
    const { isTrial } = subscriptionStore

    useEffect(() => {
      setActiveContact(null)

      if (isTrial) {
        getTotalAmount()
      }
    }, [])

    useUnsavedChanges(isNeedSaveChangesModal && segmentBox.hasChanges, (blocker) =>
      getAskIfSaveModal({
        onToggle,
        blocker,
        disabled: !filterEditorStore.groups.isReady,
        filtersList: noEmptyFilters,
      })
    )

    if (isNoContactYet && !activeSegment) {
      return <AddFirstContactContent contactsTableStore={contactsTableStore} />
    }

    const iconType = activeSegment ? 'filter' : 'search'
    const label = activeSegment ? 'No matching contacts' : 'No results found'

    return (
      <div className={styles.contentContainer}>
        <ContactsContainerActions contactsTableStore={contactsTableStore} />
        <CleanupAlert />
        <TrialContactsLimitAlert totalAmount={contactsTableStore.totalAmount} />
        <ContactsFilters contactsMenuStore={contactsMenuStore} />
        <div style={{ display: 'flex', height: '100%' }}>
          {isEmptyContactsList ? (
            <NoResultsFound label={label} icon={iconType} />
          ) : (
            <ContactsTable store={contactsTableStore} />
          )}
        </div>
      </div>
    )
  }
)
