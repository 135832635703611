import { makeAutoObservable } from 'mobx'
import type { IBaseWorkflowData, IFullWorkflowData } from 'entities/Workflow/api'
import { WorkflowEngine } from 'entities/Workflow/engine'

export type IWorkflow = Workflow

export class Workflow {
  static Create(item: IFullWorkflowData) {
    return new Workflow(item)
  }

  private _origin!: IFullWorkflowData

  get id() {
    return this._origin.id
  }

  get name() {
    return this._origin.name
  }

  get enabled() {
    return this._origin.enabled
  }

  get createdAt() {
    return this._origin.created_at
  }

  get updatedAt() {
    return this._origin.updated_at
  }

  get executionsCount() {
    return this._origin.executions_count
  }

  get definition() {
    return WorkflowEngine.uiSettingsToDefinition(this.uiSettings?.data.children ?? null)
  }

  get uiSettings() {
    return this._origin.ui_settings
  }

  get isInvalid() {
    return this._origin.is_invalid
  }

  get baseData(): IBaseWorkflowData {
    return {
      name: this._origin.name,
      ui_settings: this._origin.ui_settings,
      multiple_executions: this._origin.multiple_executions,
      description: this._origin.description,
      definition: this.definition,
    }
  }

  constructor(item: IFullWorkflowData) {
    this.syncOrigin(item)
    makeAutoObservable(this)
  }

  syncOrigin(item: Partial<IFullWorkflowData>) {
    this._origin = {
      ...this._origin,
      ...item,
    }
  }
}
