import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import {
  QuestionnaireProvider,
  useQuestionnaireContext,
} from 'pages/settings/pages/compliance/pages/questionnaire/context/questionnaireContext'
import { useComplianceLayerContext } from 'pages/settings/pages/compliance/context'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'

const QuestionnaireLayoutContent = observer(() => {
  const store = useQuestionnaireContext()
  const { state } = useLocation()
  const isRequireNumber = Boolean(usersStore.user?.isRequireNumber)
  const isSummaryReady = Boolean(store.summary?.organization_id)

  useEffect(() => {
    if (!isRequireNumber && isSummaryReady) {
      uiStore.changeRouteViaVue({
        path: `/${CompliancePath.compliance.root}`,
        method: 'replace',
      })

      return
    }

    if (state === 'started') {
      store.goToStart()
    } else {
      store.resetProgress()
    }
  }, [state, isRequireNumber])

  useEffect(() => {
    if (!isRequireNumber) return

    if (isRequireNumber && isSummaryReady) {
      uiStore.changeRouteViaVue({
        path: `/${CompliancePath.compliance.questionnaire.root}`,
        options: {
          state: 'started',
        },
        method: 'replace',
      })

      return
    }

    uiStore.changeRoute({
      path: `/${CompliancePath.compliance.questionnaire.root}`,
      options: {
        state: store.isStarted ? 'started' : null,
      },
      method: 'replace',
    })
  }, [store.isStarted, usersStore.user?.isRequireNumber])

  return (
    <>
      <Outlet />
    </>
  )
})

export const QuestionnaireLayout = observer(() => {
  const complianceLayerStore = useComplianceLayerContext()

  return (
    <QuestionnaireProvider complianceLayerStore={complianceLayerStore}>
      <QuestionnaireLayoutContent />
    </QuestionnaireProvider>
  )
})
