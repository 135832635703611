import { observer } from 'mobx-react-lite'
import { Box, Switch, Typography } from 'shared/ui'
import { type AdminEdit10DLCExemptOptionsStore } from 'pages/admin/pages/organizations/modals/AdminEdit10DLCExemptOptions/store/adminEdit10DLCExemptOptionsStore'

type IContentProps = {
  store: AdminEdit10DLCExemptOptionsStore
}

export const Admin10DLCExemptOptionsContent = observer(({ store }: IContentProps) => {
  const { isCanadaOnlyStatus, setIsCanadaOnlyStatus, is10DLCExempt, setIs10DLCExempt } = store
  return (
    <>
      <Box display={'grid'} gap={'16px'}>
        <Box display={'flex'} gap={'8px'} alignItems={'center'}>
          <Typography variant={'body-md-regular'} ariaLabel={'Messaging Canada only'}>
            Messaging Canada only
          </Typography>
          <Switch value={isCanadaOnlyStatus} onChange={setIsCanadaOnlyStatus} />
        </Box>
        <Box display={'flex'} gap={'8px'} alignItems={'center'}>
          <Typography variant={'body-md-regular'} ariaLabel={'Messaging Canada only'}>
            10DLC exempt
          </Typography>
          <Switch value={is10DLCExempt} onChange={setIs10DLCExempt} />
        </Box>
      </Box>
    </>
  )
})
