import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponseUserIntegration } from 'entities/Integrations'
import { integrationsStore } from 'entities/Integrations/store/integrations'

type IHandlerEvent = (data: IResponseUserIntegration) => void

export const EventIntegrationConnected = {
  subscribe: (handler: IHandlerEvent) => websocket.on(WebsocketEvent.IntegrationConnected, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.IntegrationConnected, handler),
}

export const useEventIntegrationConnected = () => {
  const handler = (data: IResponseUserIntegration) => {
    integrationsStore.syncUserIntegration(data)
  }
  useEffect(() => {
    if (websocket.isConnected) {
      EventIntegrationConnected.subscribe(handler)
    }
  }, [websocket.isConnected])
}
