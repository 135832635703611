import { createContext, useContext } from 'react'
import { type ContactsPageStore } from './../store/contactsPageStore'

export const ContactsPageContext = createContext<ContactsPageStore | null>(null)

export const useContactsPageContext = () => {
  const context = useContext(ContactsPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ContactsPageContext')

  return context
}
