import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { layoutStore, toastStore } from 'shared/ui'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { ContactsApi } from 'entities/Contacts'
import { customFieldOperation } from 'entities/CustomField'
import { type IImportField } from 'entities/Contacts/api/types'
import { ContactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { ImportContactsModalContent, MapFieldsModalContent } from '../'
import { ContactFromFile, type IContactFromFile } from '../model/ContactFromFile'
import { MapFieldsModalActions } from '../ui/MapFieldsModalActions/MapFieldsModalActions'
import { ApplyTagsModalContent } from '../ui/ApplyTagsModalContent/ApplyTagsModalContent'
import { ApplyTagsModalActions } from '../ui/ApplyTagsModalActions/ApplyTagsModalActions'
import { ImportContactsModalError } from '../ui/ImportContactsModalError/ImportContactsModalError'

export class ImportContactsStore {
  private _modalId = ''
  private _contactsFromFileMap: Map<number, ContactFromFile> = new Map()
  private _headers: string[] = []
  private _mappedValues: Record<string, string> = {}
  private _doNotMapNames: string[] = []
  private _doNotImportRest = false
  private _newFieldHeader: string | null = null
  private _isTermsAgreed = false
  private _importId: number | null = null
  private _importAllowed: null | boolean = null
  private _importModalErrorTitle: null | string = null
  private _importModalErrorDesc: null | string = null
  private _fields: IImportField[] = []
  private _loading = false
  private _contactsTagsModalStore = new ContactsTagsModalStore()

  constructor() {
    makeAutoObservable(this)
  }

  get contactsTagsModalStore() {
    return this._contactsTagsModalStore
  }

  get fields() {
    return this._fields
  }

  get importAllowed() {
    return this._importAllowed
  }

  get noProcessedCount() {
    return (
      this._headers.length - Object.keys(this._mappedValues).length - this._doNotMapNames.length
    )
  }

  get isImportAllowed() {
    return this._importAllowed
  }

  get doNotMapNameList() {
    return this._doNotMapNames
  }

  get mappedFields() {
    return this._mappedValues
  }

  get previewContacts() {
    return Array.from(this._contactsFromFileMap.values()).slice(0, 3)
  }

  get headersFromFile() {
    return this._headers
  }

  get isDoNotImportRest() {
    return this._doNotImportRest
  }

  get termsAgreed() {
    return this._isTermsAgreed
  }

  get importModalError() {
    return {
      title: this._importModalErrorTitle,
      desc: this._importModalErrorDesc,
    }
  }

  get isLoading() {
    return this._loading
  }

  getFieldDataByKey = (key: string) => {
    return this._fields.find((field) => field.key === key)
  }

  setNewFieldHeader = (value: string | null) => {
    this._newFieldHeader = value
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setImportModalError = ({ title, desc }: { title: string | null; desc: string | null }) => {
    this._importModalErrorTitle = title
    this._importModalErrorDesc = desc
  }

  reset = () => {
    this._mappedValues = {}
    this._doNotMapNames = []
    this._doNotImportRest = false
    this._isTermsAgreed = false
    this._importModalErrorTitle = null
    this._importModalErrorDesc = null
  }

  checkIsImportDisabled = (importHistoryCount: number) => {
    return importHistoryCount > 0 && !this.isImportAllowed
  }

  changeTermsAgreed = () => {
    this._isTermsAgreed = !this._isTermsAgreed
  }

  toggleDoNotImportRest = () => {
    this._doNotImportRest = !this._doNotImportRest
  }

  deleteFromMappedValues = (key: string) => {
    delete this._mappedValues[key]
  }

  addDoNotImport = (key: string) => {
    this._doNotMapNames.push(key)
    this.deleteFromMappedValues(key)
  }

  deleteDoNotImport = (header: string) => {
    this._doNotMapNames = this._doNotMapNames.filter((name) => name !== header)
  }

  toggleFilter = ({ key, header }: { key: string; header: string }) => {
    this.deleteDoNotImport(header)

    this._mappedValues[header] = key
  }

  deleteImportHistory = async () => {
    if (this._importId) {
      await ContactsApi.deleteImportHistory(this._importId)
    }
  }

  handleClose = () => {
    modalStore.removeModal(this._modalId)
  }

  handleCloseAndDelete = () => {
    this.handleClose()
    this.deleteImportHistory()
  }

  addContacts = (contacts: IContactFromFile[]) => {
    this.reset()

    contacts.forEach((item, index) => {
      this._contactsFromFileMap.set(index, new ContactFromFile(item))
    })
  }
  setHeaders = (headers: string[]) => {
    this._headers = headers
  }

  openSecondModal = () => {
    this.handleClose()
    this.openApplyTagsModal()
  }

  openModal = () => {
    this._modalId = nanoid()

    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      showCloseButton: false,
      showCloseIcon: true,
      showHeader: true,
      title: 'Import contacts',
      width: 560,
      paddingContent: '4px 24px 24px 24px',
      onClose: () => {
        this.handleClose()
        this.setImportModalError({ title: null, desc: null })
      },
      ModalError: ImportContactsModalError,
      ModalContent: ImportContactsModalContent,
    })
  }

  openMapFieldsModal = () => {
    this._modalId = nanoid()

    const { isViewXs } = layoutStore

    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      showCloseButton: false,
      ModalActions: MapFieldsModalActions,
      showCloseIcon: true,
      showHeader: true,
      title: 'Map fields | 1 of 2',
      width: isViewXs ? 704 : 800,
      paddingContent: '0 24px 0 24px',
      onClose: this.handleCloseAndDelete,
      ModalContent: MapFieldsModalContent,
    })
  }

  openApplyTagsModal = () => {
    this._modalId = nanoid()

    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      showCloseButton: false,
      showCloseIcon: true,
      showHeader: true,
      title: 'Apply tags | 2 of 2',
      width: 560,
      onClose: this.handleCloseAndDelete,
      ModalContentProps: {
        importContactsStore: this,
      },
      ModalContent: ApplyTagsModalContent,
      ModalActions: ApplyTagsModalActions,
    })
  }

  createField = () => {
    customFieldOperation.createField().then((field) => {
      if (!field) return

      this._fields.push({
        ...field,
        category_new: 'custom',
      })

      if (this._newFieldHeader) {
        this.toggleFilter({
          key: field.key,
          header: this._newFieldHeader,
        })
        this.setNewFieldHeader(null)
      }

      toastStore.add({
        title: 'Custom field created',
        type: 'success',
      })
    })
  }

  loadImportFields = async () => {
    const {
      data: { data: fields },
    } = await ContactsApi.getImportFields()

    this._fields = fields
  }

  uploadImportContactsFile = async (file: File) => {
    const formData = new FormData()

    formData.append('file', file, file.name)

    try {
      const { data } = await ContactsApi.uploadImportContactsFile(formData)
      const { autoDetectedRowOptions, is_empty } = data

      if (is_empty) {
        this.setImportModalError({
          title: 'The uploaded CSV file is empty.',
          desc: 'Please upload a file with contact data.',
        })
      }

      this._importId = data.import.id

      Object.keys(autoDetectedRowOptions).forEach((key) => {
        this.toggleFilter({ key: autoDetectedRowOptions[+key], header: this._headers[+key] })
      })

      this.handleClose()
      this.openMapFieldsModal()
    } catch (error) {
      console.error(error)
    } finally {
      this.setLoading(false)
    }
  }

  getStatusImportContact = async () => {
    const { data } = await ContactsApi.getStatusImportContact()

    this._importAllowed = data.allowed

    if (!data.allowed) {
      setTimeout(() => {
        this.getStatusImportContact()
      }, 60 * 1000)
    }
  }

  reviewImport = async (mapping: string[], updateHistory: () => void) => {
    if (this._importId) {
      try {
        await ContactsApi.reviewImport(this._importId, mapping)
      } finally {
        updateHistory()
      }
    }
  }

  startImportContact = async (
    id: number,
    tags: number[],
    updateHistory: () => void,
    checkProgress: (id: number) => void
  ) => {
    const mapping = this._headers.map((header) => {
      if (this._mappedValues[header] === 'tag') {
        return 'tags'
      }

      return this._mappedValues[header] || 'skip'
    })

    const { status } = await ContactsApi.startImportContact(id, {
      mapping,
      tags,
    })

    if (status === 200) {
      updateHistory()
      checkProgress(id)

      toastStore.add({
        title: 'Contacts validation in progress',
        desc: 'Be patient, processing time depends on the phone numbers volume',
        type: 'success',
      })
    }
  }

  importHandler = async (
    tags: number[],
    updateHistory: () => void,
    checkProgress: (id: number) => void
  ) => {
    this.handleClose()
    uiStore.changeRoute({ path: 'contacts/history' })

    if (this.isImportAllowed && this._importId) {
      this.startImportContact(this._importId, tags, updateHistory, checkProgress)
    }
  }
}

export const importContactsStore = new ImportContactsStore()
