// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BkYs6jUl5RtE2cgqUSR2{text-decoration:none}.xtL8OA4FZ4ygMVIWzWpj{margin-top:24px;align-items:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/businessProfile/pages/TermsAndPrivacy/ui/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,oBAAA,CAGF,sBACE,eAAA,CACA,sBAAA","sourcesContent":[".wrap {\n}\n\n.link {\n  text-decoration: none;\n}\n\n.declaration {\n  margin-top: 24px;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "BkYs6jUl5RtE2cgqUSR2",
	"declaration": "xtL8OA4FZ4ygMVIWzWpj"
};
export default ___CSS_LOADER_EXPORT___;
