// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gwaIc5mu4JQMHu9hA1bw{min-width:366px;width:100%;margin:0 auto;display:grid;grid-template-rows:81px 1fr max-content;position:relative}.gwaIc5mu4JQMHu9hA1bw.YzvThihGUOyZ8HQ2Wr76{min-width:404px}.gwaIc5mu4JQMHu9hA1bw.OKgohh8HR86yZ6oKM4zj{grid-template-rows:max-content 1fr max-content}.a_DvJ79r_GGGwjfiRh0Q{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.xvu79Ql61LwFNE22HycH{height:22px}.S7y2F5mW8M9o1GKClDne{opacity:.5}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,uCAAA,CACA,iBAAA,CACA,2CACE,eAAA,CAGF,2CACE,8CAAA,CAIJ,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAGF,sBACE,WAAA,CAEF,sBACE,UAAA","sourcesContent":[".wrap {\n  min-width: 366px;\n  width: 100%;\n  margin: 0 auto;\n  display: grid;\n  grid-template-rows: 81px 1fr max-content;\n  position: relative;\n  &.isViewXXs {\n    min-width: 404px;\n  }\n\n  &.new {\n    grid-template-rows: max-content 1fr max-content;\n  }\n}\n\n.wrapLoader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.footer {\n  height: 22px;\n}\n.disabled {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "gwaIc5mu4JQMHu9hA1bw",
	"isViewXXs": "YzvThihGUOyZ8HQ2Wr76",
	"new": "OKgohh8HR86yZ6oKM4zj",
	"wrapLoader": "a_DvJ79r_GGGwjfiRh0Q",
	"footer": "xvu79Ql61LwFNE22HycH",
	"disabled": "S7y2F5mW8M9o1GKClDne"
};
export default ___CSS_LOADER_EXPORT___;
