import { FC, ReactNode, useState } from 'react'
import classnames from 'classnames'
import { AvatarGroup, IAvatarInfo, Typography, Tooltip, Icon } from 'shared/ui'
import { getLabelAsNumberInternationalFormat, isNumeric } from 'shared/lib'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'
import { HoldStatus } from 'widgets/CallPopUp/ui/components/BaseContent/HoldStatus/HoldStatus'
import { useCopyToClipboard } from 'widgets/CallPopUp/hooks/useCopyToClipboard'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { type CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'

type IBaseContentProps = {
  withPadding?: boolean
  avatarsInfo: IAvatarInfo[]
  name: string
  number?: string
  desc: string
  isHold?: boolean
  actionsTop?: ReactNode
  actionsBottom?: ReactNode
  content?: ReactNode
}

export const BaseContent: FC<IBaseContentProps> = ({
  withPadding,
  avatarsInfo,
  isHold,
  name,
  number,
  desc,
  actionsTop,
  actionsBottom,
  content,
}) => {
  const [showEllipsis, setShowEllipsis] = useState(false)
  const { onClickCopy, setOpen, open, copied } = useCopyToClipboard(number)
  const store = useCallPopUpDefaultContext()
  const { isHover } = store as CallPopUpDefaultStore
  const hasNumber = name.startsWith('+') && isNumeric(name)

  return (
    <div
      className={classnames(styles.content, {
        [styles.withoutPadding]: withPadding,
      })}
    >
      <div
        className={classnames(styles.content__section, styles['content__section--info'], {
          [styles.withPadding]: withPadding,
        })}
      >
        <div className={styles.content__box}>
          <div className={styles.info}>
            <div className={styles.info__avatar}>
              <AvatarGroup items={avatarsInfo} size={40} />
            </div>
            <div className={styles.info__box}>
              <div
                className={classnames(styles.info__label, {
                  [styles.withRightPadding]: !!number,
                })}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
              >
                <Typography
                  ellipsis
                  tag={'div'}
                  ariaLabel='callPopUpName'
                  className={styles.info__title}
                  tooltipProps={{ label: name, zIndex: 5001 }}
                  onShowEllipsis={setShowEllipsis}
                >
                  {name && getLabelAsNumberInternationalFormat(name)}
                </Typography>
                {number && (
                  <Tooltip
                    placement='top'
                    padding={4}
                    label={
                      <>
                        {!hasNumber && showEllipsis && (
                          <Typography
                            tag={'div'}
                            ariaLabel='callPopUpTooltipTitle'
                            variant={'body-sm-regular'}
                            color={'var(--content-primary-tertiary-inverted'}
                            className={styles.info__tooltipTitle}
                          >
                            {name}
                          </Typography>
                        )}
                        <button className={styles.info__button} onClick={onClickCopy}>
                          <div className={styles.info__button__box}>
                            <Icon
                              icon='copy'
                              fontSize={12}
                              color={'var(--content-primary-primary-inverted'}
                            />
                            <Typography
                              tag={'div'}
                              ariaLabel='callPopUpNumber'
                              variant={'body-md-regular'}
                              color={'var(--content-primary-primary-inverted'}
                              className={styles.info__number}
                            >
                              {copied ? 'Copied' : number}
                            </Typography>
                          </div>
                        </button>
                      </>
                    }
                    zIndex={5001}
                    open={open}
                  >
                    <Icon
                      className={classnames(styles.info__icon, {
                        [styles.visible]: isHover,
                      })}
                      icon='infoCircle'
                      fontSize={12}
                      color={'var(--content-neutral-primary)'}
                    />
                  </Tooltip>
                )}
              </div>
              {isHold && <HoldStatus />}
              {!isHold && (
                <div className={styles.info__titleSmall}>
                  {desc && getLabelAsNumberInternationalFormat(desc)}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.content__actions}>{actionsTop}</div>
      </div>

      <div
        className={classnames(styles.content__section, {
          [styles.withPadding]: withPadding,
        })}
      >
        {actionsBottom}
      </div>

      {content}
    </div>
  )
}
