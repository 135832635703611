import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { contactsCleanupStore } from 'widgets/ContactsTable'
import { ContactsDetails } from 'widgets/ContactsDetails'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { contactsHistoryStore } from 'widgets/ContactsHistory/store/ContactsHistoryStore'
import { useContactsPageContext } from 'pages/contacts/context/contactsContext'
import { ContactsContent } from './ContactsContent/ContactsContent'
import styles from './styles.module.scss'

export const ContactsPage = observer(() => {
  const contactsPageStore = useContactsPageContext()
  const {
    contactsMenuStore,
    contactsTableStore,
    segmentModalStore,
    contactsDetailsStore,
    contactsFilterStore,
  } = contactsPageStore
  const { tableStore } = contactsTableStore
  const { isImportAllowed, getStatusImportContact } = importContactsStore

  useEffect(() => {
    const disposeContactsFilterStore = contactsFilterStore.watchChanges()
    contactsTableStore.initReactions()
    contactsFilterStore.initPreselectFilters()

    if (!contactsFilterStore.segmentBox.segment) {
      contactsTableStore.loadContacts()
    }

    contactsCleanupStore.init()
    contactsDetailsGlobalStore.handleSetVariant('contacts')
    contactsDetailsGlobalStore.handleChangeCollapse(true)
    !isImportAllowed && getStatusImportContact()
    contactsHistoryStore.loadImportHistory()
    contactsPageStore.init()

    return () => {
      disposeContactsFilterStore()
      contactsTableStore.dispose()
      contactsTableStore.resetNewContactMode()
      tableStore.resetSelected()
      contactsFilterStore.clearSegment()
      contactsTableStore.resetSorting()
      contactsPageStore.reset()
    }
  }, [])

  return (
    <div className={styles.container}>
      <ContactsContent
        contactsMenuStore={contactsMenuStore}
        contactsTableStore={contactsTableStore}
        segmentModalStore={segmentModalStore}
        contactsFilterStore={contactsFilterStore}
      />
      {contactsDetailsStore ? <ContactsDetails store={contactsDetailsStore} /> : null}
    </div>
  )
})
