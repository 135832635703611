import classNames from 'classnames'
import { MouseEventHandler, FC } from 'react'
import { Icon } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type IScrollToBottomBtnProps = {
  primary: boolean
  open: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const ScrollToBottomBtn: FC<IScrollToBottomBtnProps> = ({ primary, open, onClick }) => {
  if (!open) return null

  return (
    <button
      className={classNames(styles.arrowBtn, {
        [styles.primary]: primary,
      })}
      onClick={onClick}
      aria-label={getAriaLabel('Scroll Bottom Trigger', 'button')}
    >
      <Icon icon={'arrowDown'} />
    </button>
  )
}
