import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { Button } from 'shared/ui/Button'
import { uiStore } from 'shared/store/uiStore'
import { SpinnerLoader } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'

import { QuickLinksPreviewStore } from '../store/QuickLinksPreviewStore'
import { QuickLinksEmpty } from './QuickLinksEmpty'
import { QuickLink } from './QuickLink'
import styles from './styles.module.scss'

export interface IQuickLinksListProps {
  contactId: number
}

export const QuickLinksList = observer(({ contactId }: IQuickLinksListProps) => {
  const { openView } = useContactsDetailsContext()
  const store = useMemo(() => new QuickLinksPreviewStore(contactId), [contactId])

  useEffect(() => store.dispose, [])

  if (store.loading) return <SpinnerLoader />

  return (
    <div>
      <div className={styles.links}>
        {store.isEmpty ? (
          <QuickLinksEmpty />
        ) : (
          store.quickLinks.map((quickLink) => (
            <QuickLink key={quickLink.id} quickLink={quickLink} />
          ))
        )}
      </div>
      <footer className={styles.footer}>
        <div className={styles.actions}>
          {!usersStore.user?.isViewOnlyRole && (
            <Button
              size='small'
              typeBtn='text'
              text={'Manage links'}
              onClick={() => uiStore.changeRoute({ path: '/settings/quick-links' })}
            />
          )}
          {store.hasMore && (
            <Button
              size='small'
              typeBtn='text'
              text={'See all'}
              onClick={() => openView('quick-links')}
            />
          )}
        </div>
      </footer>
    </div>
  )
})
