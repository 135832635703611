import { observer } from 'mobx-react-lite'
import React from 'react'
import { Icon } from 'shared/ui'
import { userSettingsStore } from 'entities/Settings'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import styles from './styles.module.scss'

export const EmptyState = observer(() => {
  const store = useConversationMessagesContext()

  const isEmpty = Boolean(!store.items.length && !store.loadingPrevious && !store.loading)

  if (store.isConversationNew) {
    if (
      !store.conversationNewSearchStore?.hasContacts ||
      !store.conversationNewSearchStore?.hasInbox
    )
      return null
  }
  if (isEmpty) {
    const title = `No ${userSettingsStore.isHideCalls ? 'messages' : 'conversation'} yet`

    return (
      <div className={styles.empty}>
        <div className={styles.empty__icon}>
          <Icon icon={'conversation'} fontSize={24} />
        </div>
        <div className={styles.empty__title}>{title}</div>
        <div className={styles.empty__desc}>Send a message!</div>
      </div>
    )
  }
  return null
})
