import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { authStore, IResponseEventTokenCreated } from 'entities/Auth'
import { ConversationRoutes } from 'entities/ExportRouters'
import { type RegisterMultiorgStore } from '../store/registerMultiorgStore'

const TokenCreated = {
  handle: (data: IResponseEventTokenCreated, store?: RegisterMultiorgStore) => {
    if (store) store.setLoading(false)
    authStore.setSuccessAuthToken(data.token.access_token)
    window.stop()
    window.location.href = `/${ConversationRoutes.root}`
  },
}

export const EventTokenCreated = {
  subscribe: (store: RegisterMultiorgStore) =>
    websocket.on(WebsocketEvent.TokenCreated, (data: IResponseEventTokenCreated) =>
      TokenCreated.handle(data, store)
    ),
  unsubscribe: () => websocket.off(WebsocketEvent.TokenCreated, TokenCreated.handle),
}
