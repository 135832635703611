// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x3Kefhs3_armXRC8VepS{height:55px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/businessProfile/pages/GeneralInfo/store/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA","sourcesContent":[".einItem {\n  height: 55px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"einItem": "x3Kefhs3_armXRC8VepS"
};
export default ___CSS_LOADER_EXPORT___;
