// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z7blgoFWJDDFtFxrZQXx .LymGlvT_t0QWGdwHUOY4{display:flex;flex-direction:column;padding:16px;border-radius:8px;background:var(--background-neutral-subtle);margin-top:40px;margin-bottom:32px}.Z7blgoFWJDDFtFxrZQXx .mHAsg5osT6DVuwYkXS6g{display:flex;justify-content:space-between;margin-bottom:13px}.Z7blgoFWJDDFtFxrZQXx .ehdLrzCzixjgXI5wwxJR{margin-bottom:0}.Z7blgoFWJDDFtFxrZQXx .LAPJdo8wKnChca1eatnR{display:flex;align-items:center;gap:4px}.Z7blgoFWJDDFtFxrZQXx .xaN144RPVQdodQyeuxOw{height:1px;background-color:var(--black-20);margin-bottom:14px;margin-top:4px}.Z7blgoFWJDDFtFxrZQXx .rLTD60Dslo66jQeGC0_t{margin-left:4px}.Z7blgoFWJDDFtFxrZQXx .d74t5Lk_3mYH_E1HOygL{padding-top:20px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/localNumbers/pages/OrderSummary/ui/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,iBAAA,CACA,2CAAA,CACA,eAAA,CACA,kBAAA,CAGF,4CACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,4CACE,eAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,4CACE,UAAA,CACA,gCAAA,CACA,kBAAA,CACA,cAAA,CAGF,4CACE,eAAA,CAGF,4CACE,gBAAA","sourcesContent":[".orderSummary {\n  .summary {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n    border-radius: 8px;\n    background: var(--background-neutral-subtle);\n    margin-top: 40px;\n    margin-bottom: 32px;\n  }\n\n  .summaryLine {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 13px;\n  }\n\n  .totalSummaryLine {\n    margin-bottom: 0;\n  }\n\n  .lineDesc {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n  }\n\n  .divider {\n    height: 1px;\n    background-color: var(--black-20);\n    margin-bottom: 14px;\n    margin-top: 4px;\n  }\n\n  .tooltipDate {\n    margin-left: 4px;\n  }\n\n  .alert {\n    padding-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderSummary": "Z7blgoFWJDDFtFxrZQXx",
	"summary": "LymGlvT_t0QWGdwHUOY4",
	"summaryLine": "mHAsg5osT6DVuwYkXS6g",
	"totalSummaryLine": "ehdLrzCzixjgXI5wwxJR",
	"lineDesc": "LAPJdo8wKnChca1eatnR",
	"divider": "xaN144RPVQdodQyeuxOw",
	"tooltipDate": "rLTD60Dslo66jQeGC0_t",
	"alert": "d74t5Lk_3mYH_E1HOygL"
};
export default ___CSS_LOADER_EXPORT___;
