import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { inboxesStore } from 'entities/Inbox'
import { IResponseEventInboxCalling } from 'entities/Inbox/api/types'

const InboxCalling = {
  handle: (data: IResponseEventInboxCalling) => {
    inboxesStore.updateInboxCalling(data)
  },
}

export const EventInboxCalling = {
  subscribe: () => websocket.on(WebsocketEvent.InboxCalling, InboxCalling.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.InboxCalling, InboxCalling.handle),
}
