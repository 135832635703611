import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { debounce } from 'lodash'
import { IQuickLink, IResponseQuickLinksList, QuickLink, QuickLinkApi } from 'entities/QuickLink'

export type IQuickLinksSearchStore = QuickLinksSearchStore

export class QuickLinksSearchStore {
  private _term = ''

  private _loading = true
  private _initialLoading = true
  private _loadingScheduled = false

  private _cancelTokenSource: CancelTokenSource | null = null

  private _quickLinks = new Map<number, IQuickLink>()

  constructor(private _contactId: number) {
    makeAutoObservable(this)
  }

  get term() {
    return this._term
  }

  get loading() {
    return this._loading
  }

  get initialLoading() {
    return this._initialLoading
  }

  get loadingScheduled() {
    return this._loadingScheduled
  }

  get quickLinks() {
    return Array.from(this._quickLinks.values())
  }

  get isEmpty() {
    return !this._quickLinks.size
  }

  get noTerm() {
    return !this._term.length
  }

  search = async (term: string, immediate = false) => {
    this._loadingScheduled = true
    this._term = term

    const makeSearch = async () => {
      runInAction(() => this._quickLinks.clear())

      const { data } = await QuickLinkApi.getContactLinks(
        this._contactId,
        { search: this._term, length: 100 },
        { cancelToken: this._cancelTokenSource?.token }
      )

      runInAction(() => this._setQuickLinks(data))
    }

    immediate ? this._tryMakeRequest(makeSearch) : this._tryMakeRequestDelay(makeSearch)
  }

  dispose = () => {
    this._cancelTokenSource?.cancel()
  }

  private _setQuickLinks = (links: IResponseQuickLinksList) => {
    this._quickLinks.clear()
    links.forEach((item) => {
      const quickLink = new QuickLink(item)

      this._quickLinks.set(quickLink.id, quickLink)
    })
  }

  private _initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _tryMakeRequest = async (action: () => Promise<void>) => {
    runInAction(() => {
      this._loading = true
      this._initCancelTokenSource()
    })

    try {
      await action()
      runInAction(() => {
        this._loading = false
        this._initialLoading = false
        this._loadingScheduled = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
      })

      console.error(error)
    }
  }

  private _tryMakeRequestDelay = debounce(this._tryMakeRequest, 500)
}
