import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { AudioPlayer } from 'shared/ui/AudioPlayer'
import { ITableConfig } from 'shared/ui'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import styles from 'widgets/CallHistoryTable/ui/styles.module.scss'
import { CallHistoryStore } from 'widgets/CallHistory'

type ICellAudioProps = {
  url: string
  isUnread: boolean
  callItem: CallHistory
  callHistoryStore: CallHistoryStore
  tableConfig?: ITableConfig
}

export const CellAudio: FC<ICellAudioProps> = observer(
  ({ url, isUnread, callItem, tableConfig, callHistoryStore }) => {
    const handlePlaying = (info: boolean) => {
      if (isUnread && info) {
        callHistoryStore.callHistoryTableStore.markAsRead(callItem)
      }
    }

    return (
      <div className={styles.audioWrap}>
        <div className={styles.audio}>
          <AudioPlayer
            url={url}
            widthWave={108}
            size={'small'}
            colorType={tableConfig?.isHover ? 'white' : 'gray'}
            isClosed={true}
            handlePlaying={handlePlaying}
          />
        </div>
      </div>
    )
  }
)
