import { makeAutoObservable } from 'mobx'
import { Chatbot } from 'entities/Chatbot'
import { Segment } from 'entities/Segment/model/Segment'
import { segmentsStore } from 'entities/Segment'
import { contactsStore } from 'entities/Contacts'
import { TagsApi } from 'entities/Tags'
import { Tag } from 'entities/Tags/model/Tag'
import { ContactFiltersApi } from 'entities/Contacts/api/contactsFilters'
import { EnrollmentFilters, EnrollmentStore } from 'widgets/ContactEnrollment'

export class ChatbotEnrollment {
  private _enrollmentStore = new EnrollmentStore({
    filterConfig: {
      makeRequest: () => {
        return ContactFiltersApi.getFilters({
          workflowSource: 'chatbot',
          skipValues: ['tag'],
        }).then(({ data }) => {
          // Hide in chatbot.
          // Revert the changes after fix on backend https://salesmsg.atlassian.net/browse/SWR-16655

          return {
            filters: data.filters.filter((item) => item.key !== 'inbox'),
            operators: data.operators,
          }
        })
      },
    },
  })

  public enrollmentFilters = EnrollmentFilters.Empty()

  constructor(private _chatbot: Chatbot) {
    makeAutoObservable(this)
  }

  initFilters = () => this._enrollmentStore.filterEditorStore.initFilters()

  editEnrollment = () => {
    const filters = this.enrollmentFilters.clone()

    const cancelFilters = () => Promise.resolve(true)
    const confirmFilters = async (newFilters: EnrollmentFilters) => {
      this.enrollmentFilters = newFilters

      this._chatbot.filters.syncTagIds(this.enrollmentFilters.tags)
      this._chatbot.filters.syncContactIds(this.enrollmentFilters.contacts)
      this._chatbot.filters.syncSegmentIds(this.enrollmentFilters.segments)
      this._chatbot.filters.syncAdvanced(this.enrollmentFilters.filters)

      return true
    }

    this._enrollmentStore.openModal({
      title: 'Contacts',
      allowEmptyCount: true,
      filters,
      action: {
        cancel: cancelFilters,
        confirm: confirmFilters,
      },
    })
  }

  syncFilters = async () => {
    const [tags, contacts] = await Promise.all([
      TagsApi.getTagsById(this._chatbot.filters.tagIds).then((tags) =>
        tags.map((tag) => new Tag(tag))
      ),
      contactsStore.getByIds(this._chatbot.filters.contactIds),
    ])

    const segments = this._chatbot.filters.segmentIds
      .map(segmentsStore.getItem)
      .filter(Boolean) as Segment[]

    const filters = this._chatbot.filters.advanced.map(
      (filters) => new Segment({ id: 0, organization_id: 0, name: '', filters, is_favorite: false })
    )

    this.enrollmentFilters.sync({ tags, segments, contacts, filters })
  }

  dispose = () => {}
}
