import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, IIconsVector, Tooltip, Typography } from 'shared/ui'
import icons from 'shared/ui/Icon/icons'
import { MoreDropdownProps } from 'shared/ui/Actions/Actions'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { MergeFieldsStore } from 'widgets/MergeField'
import styles from 'widgets/MergeField/ui/styles.module.scss'
import { MergeField } from 'widgets/MergeField/types/MergeField'

export const MergeFieldContentItem = observer(
  ({
    field,
    onCloseMenu,
    mergeFieldStore,
  }: MoreDropdownProps & {
    field: MergeField
    mergeFieldStore: MergeFieldsStore
  }) => {
    const { onAddMergeField, integration, isAllIntegration } = mergeFieldStore
    const [hover, setHover] = useState(false)
    const onSelectMergeField = (field: MergeField) => {
      onAddMergeField && onAddMergeField(field)
      onCloseMenu && onCloseMenu()
    }
    const getMergeFieldIconByKey = (key: IntegrationKey): IIconsVector => {
      if (key in IntegrationKey) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (icons[key]) {
          return key as IIconsVector
        }
      }
      return IntegrationKey.salesmessage
    }
    const onMouseEnter = () => {
      setHover(true)
    }
    const onMouseLeave = () => {
      setHover(false)
    }
    const disabled =
      !isAllIntegration &&
      (!field.value ||
        (!!integration && field.integrationKey === integration.key && !integration?.is_connected))
    return (
      <Button
        key={field.name}
        onClick={() => onSelectMergeField(field)}
        disabled={disabled}
        typeBtn={'menuItem'}
        icon={getMergeFieldIconByKey(field.integrationKey)}
        paddingInline={8}
        iconClassName={styles.icon}
        titleContent={
          <div className={styles.mergeFieldTitle}>
            <Typography
              variant={'body-md-regular'}
              ellipsis
              tag={'div'}
              className={styles.leftContent}
              ariaLabel={'label'}
            >
              {field.label}
            </Typography>
            <div className={styles.rightContent}>
              <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={styles.wrapRightTitle}
              >
                <Typography
                  variant={'body-xs-regular'}
                  color={'var(--content-primary-tertiary)'}
                  ellipsis
                  tag={'div'}
                  ariaLabel={'value'}
                >
                  {field.value}
                </Typography>
              </div>

              <Tooltip label={field.value} open={hover} placement={'right'}>
                <div className={styles.tooltip} />
              </Tooltip>
            </div>
          </div>
        }
      />
    )
  }
)
