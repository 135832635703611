import { IAPiOption } from 'shared/api'
import { IDropdownItem } from 'shared/ui'

export function apiOptionToDropdownItem(option: IAPiOption | null): IDropdownItem | null {
  if (!option) {
    return null
  }
  return {
    id: option.id,
    label: option.name || option.label || option.title || '',
    short_label: option.abbreviation,
  }
}

export function apiOptionsToDropdownItems(options: IAPiOption[]): IDropdownItem[] {
  const items: IDropdownItem[] = []
  options?.forEach((option) => {
    const item = apiOptionToDropdownItem(option)
    if (item) {
      items.push(item)
    }
  })
  return items
}

export const prepareApiOptionToDropdownItem = (
  value: string | null | undefined
): IDropdownItem | null => {
  if (!value) return null

  return apiOptionToDropdownItem({ id: value, name: value })
}
