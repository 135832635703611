import { observer } from 'mobx-react-lite'
import React from 'react'
import { InputCheckbox, TextFieldWithLabel, Typography } from 'shared/ui'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import styles from './styles.module.scss'

type IWebsiteFieldsProps = {
  fieldsError: Record<string, string> | null
  websiteUrlLocalError: string
  noWebsiteUrl: boolean
  websiteUrl: string | null
  setWebsiteUrl: (url: string | null) => void
  handleSetNoWebsiteUrl: () => void
  validateWebsiteUrl: (url: string, type: 'websiteUrl' | 'socialMediaUrl') => void
  socialMediaUrlError: string
  socialMediaUrl: string | null
  setSocialMediaUrl: (url: string | null) => void
}

export const WebsiteFields = observer(
  ({
    fieldsError,
    websiteUrlLocalError,
    noWebsiteUrl,
    websiteUrl,
    setWebsiteUrl,
    handleSetNoWebsiteUrl,
    validateWebsiteUrl,
    socialMediaUrlError,
    socialMediaUrl,
    setSocialMediaUrl,
  }: IWebsiteFieldsProps) => {
    return (
      <>
        <TextFieldWithLabel
          marginBottom={0}
          errorBorder={Boolean(fieldsError?.website_url)}
          errorMessage={websiteUrlLocalError}
          labelProps={{
            marginBottom: 4,
            questionTooltipProps: {
              label: 'You need a website to verify',
              desc: 'If you don’t have an official website and are using a social media profile page, click “I don’t have a website”.',
              type: 'description',
              width: 240,
            },
            label: {
              text: 'Business website URL',
            },
          }}
          textFieldProps={{
            disabled: noWebsiteUrl,
            value: websiteUrl || '',
            error: !!websiteUrlLocalError,
            onChange: setWebsiteUrl,
            onBlur: (value: string) => validateWebsiteUrl(value, 'websiteUrl'),
            InputProps: {
              placeholder: 'e.g. https://www.example.com/',
            },
            className: styles.selectPadding,
          }}
          rightActions={[
            makeTextFieldIcon({
              icon: websiteUrl && !websiteUrlLocalError ? 'check2' : undefined,
              fontSize: 16,
              color: 'var(--green-60)',
            }),
          ]}
        />
        <InputCheckbox
          checked={noWebsiteUrl}
          onChecked={handleSetNoWebsiteUrl}
          customLabel={
            <Typography
              ariaLabel='Business_info_email_label'
              variant='text-input-field-r'
              color='var(--content-primary-tertiary)'
            >
              {"I don't have a business website"}
            </Typography>
          }
          className={styles.noEmailCheckbox}
        />
        {noWebsiteUrl && (
          <TextFieldWithLabel
            marginTop={'24px'}
            errorBorder={Boolean(fieldsError?.social_media_url)}
            errorMessage={socialMediaUrlError}
            labelProps={{
              marginBottom: 8,
              questionTooltipProps: {
                label: "Enter the URL of your company's social media profile page",
                desc: '(e.g. Facebook, Twitter, Linkedin etc)',
                type: 'description',
                width: 226,
              },
              label: {
                text: 'Social media profile',
              },
            }}
            textFieldProps={{
              value: socialMediaUrl || '',
              error: !!socialMediaUrlError,
              onChange: setSocialMediaUrl,
              onBlur: (value: string) => validateWebsiteUrl(value, 'socialMediaUrl'),
              InputProps: {
                placeholder: 'e.g. https://www.facebook.com/example/',
              },
              className: styles.selectPadding,
            }}
          />
        )}
      </>
    )
  }
)
