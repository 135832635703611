import { observer } from 'mobx-react-lite'
import { useCompliancePageContext } from 'pages/settings/pages/compliance/context'
import { RegisterSection } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import { SummaryLocalNumbers } from 'pages/settings/pages/compliance/ui/Summary/SummaryLocalNumbers'

export const LocalNumbersPage = observer(() => {
  const { localNumbersPageStore } = useCompliancePageContext()

  return (
    <>
      <RegisterSection {...localNumbersPageStore.lnRegisterSectionProps} />
      <SummaryLocalNumbers />
    </>
  )
})
