import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { IconButton } from 'shared/ui'
import { callStore } from 'entities/Call'
import {
  BaseContent,
  CallConferencePopUpButton,
  CallConferencePopUpContent,
  CallDialpadPopUpButton,
  CallDialpadPopUpContent,
  CallDisconnectPopUpButton,
  CallHoldPopUpButton,
  CallMessagePopUpButton,
  CallMessagePopUpContent,
  CallMicPopUpContent,
  CallMicPopUpIncomingButton,
  CallMicPopUpOutgoingButton,
  CallRecordPopUpButton,
  CallTransferPopUpButton,
  CallTransferPopUpContent,
  CallVoicemailDropButton,
  CallVoicemailDropContent,
  useCallPopUpDefaultContext,
} from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from '../../styles.module.scss'

export const CallBaseContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const {
    isConference,
    isTransfer,
    isMessage,
    isDialpad,
    isHold,
    isMic,
    label,
    isShowVoicemailDropContent,
    isShowVoicemailDropButton,
    avatarsInfo,
    contactsNames,
    contactsPhones,
    isOneParticipant,
  } = store as CallPopUpDefaultStore

  return (
    <>
      <BaseContent
        withPadding={isShowVoicemailDropContent}
        avatarsInfo={avatarsInfo}
        isHold={isHold}
        name={contactsNames}
        number={isOneParticipant ? contactsPhones : undefined}
        desc={label}
        actionsTop={
          <>
            {callStore.isAcceptVoice && (
              <>
                {isShowVoicemailDropButton && <CallVoicemailDropButton />}
                <CallDisconnectPopUpButton />
              </>
            )}
          </>
        }
        actionsBottom={
          <>
            {callStore.isAcceptVoice && (
              <div className={styles.foot}>
                <div className={styles.foot__item}>
                  <CallMicPopUpOutgoingButton />
                </div>
                <div className={styles.foot__item}>
                  <CallRecordPopUpButton />
                </div>
                <div className={styles.foot__item}>
                  <CallDialpadPopUpButton />
                </div>
                <div className={styles.foot__item}>
                  <CallHoldPopUpButton />
                </div>
                <div className={styles.foot__item}>
                  <CallTransferPopUpButton />
                </div>
                <div className={styles.foot__item}>
                  <CallConferencePopUpButton />
                </div>
              </div>
            )}

            {!callStore.isAcceptVoice && (
              <div className={classnames(styles.foot, styles['foot--incoming'])}>
                <div className={styles.foot__item}>
                  <IconButton
                    size={'large'}
                    color={'green'}
                    icon={'phone'}
                    fullWidth
                    tooltipProps={{
                      zIndex: 5001,
                      label: 'Accept',
                      placement: 'top',
                    }}
                    onClick={() => {
                      callStore.acceptTwilio()
                    }}
                    ariaLabel='CallBaseContent_accept'
                  />
                </div>
                <div className={styles.foot__item}>
                  <CallDisconnectPopUpButton fullWidth={true} />
                </div>
                <div className={styles.foot__item}>
                  <CallMicPopUpIncomingButton />
                </div>
                <div className={styles.foot__item}>
                  <CallMessagePopUpButton />
                </div>
              </div>
            )}
          </>
        }
        content={
          <>
            {isMic && <CallMicPopUpContent />}
            {isDialpad && <CallDialpadPopUpContent />}
            {isMessage && <CallMessagePopUpContent />}
            {isTransfer && <CallTransferPopUpContent />}
            {isConference && <CallConferencePopUpContent />}
            {isShowVoicemailDropContent && <CallVoicemailDropContent />}
          </>
        }
      />
    </>
  )
})
