import classNames from 'classnames'
import { ReactNode } from 'react'
import { type TextStylesType, Typography } from 'shared/ui/Typography/Typography'
import { Checkbox } from 'shared/ui/Checkbox/Checkbox'
import { Icon } from 'shared/ui/Icon'
import { type ITooltipProps, Tooltip } from 'shared/ui/Tooltip'
import styles from './styles.module.scss'

export type InputCheckboxProps = {
  checked: boolean
  onChecked: () => void
  label?: ReactNode
  customLabel?: ReactNode
  className?: string
  color?: string
  variant?: TextStylesType
  questionTooltipProps?: ITooltipProps
}

export const InputCheckbox = ({
  checked,
  onChecked,
  label = '',
  customLabel,
  className,
  color,
  questionTooltipProps,
  variant = 'text-input-field',
}: InputCheckboxProps) => {
  return (
    <div className={classNames(styles.wrap, className)} onClick={onChecked}>
      <Checkbox checked={checked} size={16} />
      {customLabel && customLabel}
      {!customLabel && (
        <Typography variant={variant} ariaLabel={'label'} color={color}>
          {label}
        </Typography>
      )}
      {questionTooltipProps && (
        <Tooltip placement={'top'} {...questionTooltipProps} className={styles.tooltip}>
          <Icon icon={'question'} color={'var(--content-neutral-primary)'} fontSize={14} />
        </Tooltip>
      )}
    </div>
  )
}
