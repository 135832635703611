// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bZkAhSZzyM3nULzAZwHE{display:grid;grid-template-columns:140px auto}.bZkAhSZzyM3nULzAZwHE.K4Dzx9ZL5pAJtMBFPFHl{row-gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/ModalInfoBlock/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gCAAA,CAEA,2CACE,WAAA","sourcesContent":[".grid {\n  display: grid;\n  grid-template-columns: 140px auto;\n\n  &.gridGap {\n    row-gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "bZkAhSZzyM3nULzAZwHE",
	"gridGap": "K4Dzx9ZL5pAJtMBFPFHl"
};
export default ___CSS_LOADER_EXPORT___;
