import { observer } from 'mobx-react-lite'
import { FullPageWrapper } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { BroadcastList, BroadcastsHeader } from 'widgets/BroacastList'
import { useBroadcastPageContext } from 'pages/broadcasts/context/broadcastsPageContext'

export const RecurringBroadcasts = observer(() => {
  const storeBroadcastPage = useBroadcastPageContext()

  return (
    <FullPageWrapper>
      <BroadcastsHeader
        title={
          featureFlagsStore.salesmsg_design_v2_new_sidebar_menu
            ? 'Broadcasts: Recurring'
            : 'Recurring'
        }
        pageLayoutStore={storeBroadcastPage.pageLayoutStore}
      />
      <BroadcastList storeProps={{ type: 'recurring' }} />
    </FullPageWrapper>
  )
})
