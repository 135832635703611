import { uiStore } from 'shared/store/uiStore'
import { Button } from 'shared/ui'
import { AdminRoutes } from 'pages/admin/route/type'

export const NewOrganizationButton = () => {
  const onClick = () => {
    uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.organizations}/${AdminRoutes.create}`)
  }
  return (
    <Button
      text={'New organization'}
      typeBtn='contained'
      contained='secondary'
      onClick={onClick}
      iconProps={{ icon: 'add' }}
      size={'medium'}
    />
  )
}
