import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { authStore, type IResponseEventTokenCreated } from 'entities/Auth'
import { ConversationRoutes } from 'entities/ExportRouters'
import { RegisterStore } from 'widgets/Register/store/registerStore'

const TokenCreated = {
  handle: (data: IResponseEventTokenCreated, store?: RegisterStore) => {
    if (store) store.setLoading(false)
    authStore.setSuccessAuthToken(data.token.access_token)
    window.stop()
    window.location.href = `/${ConversationRoutes.root}`
  },
}

export const EventTokenCreated = {
  subscribe: (store: RegisterStore) =>
    websocket.on(WebsocketEvent.TokenCreated, (data: IResponseEventTokenCreated) =>
      TokenCreated.handle(data, store)
    ),
  unsubscribe: () => websocket.off(WebsocketEvent.TokenCreated, TokenCreated.handle),
}
