import { makeAutoObservable } from 'mobx'
import { RinglessMessageFieldStore } from 'widgets/MessageFormFields/RinglessMessageField'

export class CampaignRinglessStore {
  constructor() {
    makeAutoObservable(this)
  }
  ringlessMessageFieldStore = new RinglessMessageFieldStore()

  init = this.ringlessMessageFieldStore.init

  get ringlessMessageDataParams() {
    return this.ringlessMessageFieldStore.ringlessMessageDataParams
  }

  get error() {
    return this.ringlessMessageFieldStore.error
  }

  setError = this.ringlessMessageFieldStore.setError

  checkValidate = () => {
    let validate = true
    if (!this.ringlessMessageDataParams.media_url.length) {
      validate = false
      this.setError('Your message is the key – please select an audio')
    }
    return validate
  }
}
