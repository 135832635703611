import { useEffect, useRef, useState, FC } from 'react'
import classNames from 'classnames'
import { toastStore } from 'shared/ui/Toast'
import { Error } from 'shared/ui/Error'
import { type ILabelProps, Label } from 'shared/ui/Label/Label'
import { Textarea } from 'shared/ui/Textarea/Textarea'
import styles from './styles.module.scss'

export type IErrorType = 'toast' | 'bottomText'

type ITextareaWithLabelProps = {
  name?: string
  placeholder: string
  labelProps: ILabelProps
  error?: string | null
  value: string
  limit?: number
  minLimit?: number
  rows?: number
  errorType?: IErrorType
  className?: string
  onBlur?: (value: string) => void
  onChange: (value: string) => void
  classNameTextarea?: string
}

export const TextareaWithLabel: FC<ITextareaWithLabelProps> = ({
  name = 'message',
  placeholder,
  labelProps,
  error,
  value,
  limit,
  minLimit,
  rows = 4,
  errorType,
  className,
  onBlur,
  onChange,
  classNameTextarea,
}) => {
  const isDirty = useRef(false)
  const [limitReached, setLimitReached] = useState(false)
  const [minLengthError, setMinLengthError] = useState('')

  const handleChange = (value: string) => {
    onChange(value)
    setMinLengthError('')

    if (limit != null) setLimitReached(value.length > limit)

    isDirty.current = true
  }

  const handleBlur = (value: string) => {
    onBlur?.(value)

    if (minLimit && value.length < minLimit) {
      setMinLengthError(`The ${name} must be at least ${minLimit} characters`)
    }
  }

  const getErrorMessage = () => {
    if (!limit) return null

    const limitView = new Intl.NumberFormat('en', {
      maximumFractionDigits: 0,
    }).format(limit)

    return `The ${name} must not exceed ${limitView} characters`
  }

  const localErrorMessage = getErrorMessage()

  useEffect(() => {
    if (
      limit != null &&
      isDirty.current &&
      limitReached &&
      errorType === 'toast' &&
      localErrorMessage
    ) {
      toastStore.add({
        title: localErrorMessage,
        type: 'error',
      })
    }
  }, [limitReached])

  const showLimitError = errorType === 'bottomText' && localErrorMessage && limitReached
  const isShowBottomError = showLimitError || error || minLengthError

  return (
    <div className={classNames(styles.textareaWrap, className)}>
      {labelProps.label && <Label marginBottom={4} {...labelProps} />}

      <Textarea
        rows={rows}
        placeholder={placeholder}
        error={!!error || !!isShowBottomError || limitReached}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNameTextarea}
      />

      {isShowBottomError && (
        <Error type={'text'} error={error || minLengthError || localErrorMessage} />
      )}
    </div>
  )
}
