import { FC } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { Status, Typography } from 'shared/ui'
import type { IStatusProps } from 'shared/ui/Status'
import { uiStore } from 'shared/store/uiStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile'
import type { IActiveTab } from 'pages/settings/pages/compliance/store/types'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers'
import { TollFreeRoutes } from 'pages/settings/pages/compliance/pages/tollFree'
import { CallingRoutes } from 'pages/settings/pages/compliance/pages/calling'
import styles from './styles.module.scss'

export type IStatusShortProps = Omit<IStatusProps, 'emphasis' | 'intent'> & {
  tab: IActiveTab
}

export type IStatusSectionProps = {
  title: string
  titleClassName?: string
  items: IStatusShortProps[]
}

type tabActive = {
  [key in IActiveTab]: string
}

export const StatusSection: FC<IStatusSectionProps> = ({ title, titleClassName, items }) => {
  const { pathname } = useLocation()

  if (!items.length) return null

  const tabPathActive: tabActive = {
    BP: `/${SettingsPath.compliance.root}/${BusinessProfileRoutes.root}`,
    LN: `/${SettingsPath.compliance.root}/${LocalNumbersRoutes.root}`,
    Calling: `/${SettingsPath.compliance.root}/${CallingRoutes.root}`,
    TF: `/${SettingsPath.compliance.root}/${TollFreeRoutes.root}`,
  }
  const isActive = (tab: IActiveTab) => {
    return pathname === tabPathActive[tab]
  }

  const onClick = (tab: IActiveTab) => {
    uiStore.changeRouteViaVue({
      path: tabPathActive[tab],
    })
  }

  return (
    <div className={styles.wrap}>
      <Typography
        ariaLabel='ComplianceMainPageStatusSection_title'
        variant='button-small'
        className={classNames(styles.title, titleClassName)}
      >
        {title}
      </Typography>

      {items.map((item) => (
        <Status
          className={styles.status}
          key={item.title}
          customIcon={item.customIcon}
          rightIcon
          rightIconSize={16}
          icon={item.icon}
          iconColor={item.iconColor}
          emphasis='high'
          size='medium'
          intent='noBorder'
          title={item.title}
          onClick={() => {
            onClick(item.tab)
          }}
          isActive={isActive(item.tab)}
        />
      ))}
    </div>
  )
}
