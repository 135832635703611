import React, { MouseEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, IconButton } from 'shared/ui'
import { IResponseContactUnlinkedConflictItem } from 'entities/Contacts/api/types'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { linkContactStore } from '../../store/linkContactStore'
import styles from './styles.module.scss'

const MatchedContactsCards = observer(() => {
  const {
    selectedMatchedId,
    handleMatchedContactId,
    matchedContactsList,
    integrationName,
    integrationKey,
    integrationIcon,
  } = linkContactStore

  const userIntegration = integrationsStore.getUserIntegration(integrationKey || undefined)

  const handleViewContact = async (
    e: MouseEvent<HTMLButtonElement>,
    contact: IResponseContactUnlinkedConflictItem
  ) => {
    if (!integrationKey) return null

    const linkProps = {
      key: integrationKey,
      contactId: contact.id,
      integration: userIntegration,
      type: IIntegrationLinkTypes.contact,
      id: contact.id,
    }

    const link = integrationLink(linkProps)
    if (!link) return

    e.stopPropagation()
    window.open(link, '_blank')
  }

  return (
    <div className={classnames(styles.wrap)}>
      <div className={styles.header}>
        <span className={styles.icon}>
          {integrationIcon && <Icon icon={integrationIcon} fontSize={11} />}
        </span>
        <span className={styles.title}>{integrationName}</span>
      </div>
      {matchedContactsList.map((contact, idx) => (
        <div
          key={contact.id}
          className={classnames(styles.content, {
            [styles.selected]: selectedMatchedId === contact.id,
            [styles.last]: idx + 1 === matchedContactsList.length,
          })}
          onClick={() => handleMatchedContactId(contact.id)}
        >
          <div className={styles.avatar}>
            {selectedMatchedId === contact.id ? (
              <div className={styles.selectedIcon}>
                <Icon
                  icon='checkboxChecked'
                  fontSize={16}
                  color='var(--content-primary-primary-inverted)'
                />
              </div>
            ) : (
              <div className={styles.defaultIcon}>
                <Icon
                  icon='radioButtonUncheckedFilled'
                  fontSize={24}
                  color='var(--interaction-neutral-hovered)'
                />
              </div>
            )}
          </div>
          <div className={styles.user}>
            <div className={styles.nameWrap}>
              <span className={styles.name}>
                {contact.full_name?.trim() ? contact.full_name : 'View contact'}
              </span>
              <div className={styles.linkIcon}>
                <IconButton
                  size={'small'}
                  color={'tertiary'}
                  variant={'icon'}
                  icon='linkExternal'
                  tooltipProps={{
                    placement: 'top',
                    label: `Open in ${integrationName}`,
                    disableInteractive: true,
                  }}
                  onClick={(e) => handleViewContact(e, contact)}
                  ariaLabel='MatchedContactsCards_viewContact'
                />
              </div>
            </div>
            <span className={styles.email}>{contact.email || '-'}</span>
          </div>
          <div className={styles.phonesWrapper}>
            <div className={styles.phone}>
              <span className={styles.label}>Phone</span>
              <span className={styles.value}>{contact.phone || '-'}</span>
            </div>
            {integrationKey === IntegrationKey.hubspot && (
              <div className={styles.phone}>
                <span className={styles.label}>Mobile Phone</span>
                <span className={styles.value}>{contact.mobile_phone || '-'}</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
})

export { MatchedContactsCards }
