// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".olGb9pnP2MM00fb0TvIz{display:flex;height:100%}.ftMO4oi3DSamA3Xrb_LD{width:100%;padding:30px 80px 0;max-width:620px;overflow-y:auto}.c9bqjo5TB966YUCDB2gd{position:relative}.CJ2Q8a_M4I2DWdXIDqEA{display:flex;justify-content:center;border-left:1px solid var(--border-neutral-default);padding:60px 64px 0}.T_n3xS_2ckIy4Xh4RENp{display:inline-block;padding:3.5px 8px;border-radius:4px;background:var(--gray-a-15);margin-bottom:24px}.PHmmTBBGFLG39WzQe5kh{padding-top:32px}.VUVkd9LM751yU3c4F1BG{margin-left:8px;margin-bottom:6px}.VUVkd9LM751yU3c4F1BG:last-of-type{margin-bottom:0}.E4mnWFkLiKa_zF9i_1ar{margin-top:30px;display:flex;gap:8px}.R41pzecl57cVwfu_wkVL{height:100%}", "",{"version":3,"sources":["webpack://./src/pages/keywords/pages/createKeyword/ui/KeywordViewContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,mBAAA,CACA,eAAA,CACA,eAAA,CAEA,sBACE,iBAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CACA,mDAAA,CACA,mBAAA,CAGF,sBACE,oBAAA,CACA,iBAAA,CACA,iBAAA,CACA,2BAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CAEA,sBACE,eAAA,CACA,iBAAA,CAEA,mCACE,eAAA,CAKN,sBACE,eAAA,CACA,YAAA,CACA,OAAA,CAGF,sBACE,WAAA","sourcesContent":[".wrap {\n  display: flex;\n  height: 100%;\n}\n\n.keywordViewBox {\n  width: 100%;\n  padding: 30px 80px 0;\n  max-width: 620px;\n  overflow-y: auto;\n\n  &__isRelative {\n    position: relative;\n  }\n}\n\n.keywordPreviewBox {\n  display: flex;\n  justify-content: center;\n  border-left: 1px solid var(--border-neutral-default);\n  padding: 60px 64px 0;\n}\n\n.label {\n  display: inline-block;\n  padding: 3.5px 8px;\n  border-radius: 4px;\n  background: var(--gray-a-15);\n  margin-bottom: 24px;\n}\n\n.section {\n  padding-top: 32px;\n\n  &__box {\n    margin-left: 8px;\n    margin-bottom: 6px;\n\n    &:last-of-type {\n      margin-bottom: 0;\n    }\n  }\n}\n\n.actionsWrap {\n  margin-top: 30px;\n  display: flex;\n  gap: 8px;\n}\n\n.loading {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "olGb9pnP2MM00fb0TvIz",
	"keywordViewBox": "ftMO4oi3DSamA3Xrb_LD",
	"keywordViewBox__isRelative": "c9bqjo5TB966YUCDB2gd",
	"keywordPreviewBox": "CJ2Q8a_M4I2DWdXIDqEA",
	"label": "T_n3xS_2ckIy4Xh4RENp",
	"section": "PHmmTBBGFLG39WzQe5kh",
	"section__box": "VUVkd9LM751yU3c4F1BG",
	"actionsWrap": "E4mnWFkLiKa_zF9i_1ar",
	"loading": "R41pzecl57cVwfu_wkVL"
};
export default ___CSS_LOADER_EXPORT___;
