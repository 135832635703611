import { FC, useEffect } from 'react'
import classNames from 'classnames'
import { EnumIconButtonShape } from 'shared/ui/IconButton'
import { Dropdown, DropdownContent, layoutStore } from 'shared/ui'
import { conversationStore } from 'entities/Conversation'
import { inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { PowerDialerActionDropdownItem } from 'widgets/PowerDialer'
import { PowerDialerEditorDropdownItem } from 'widgets/PowerDialerEditor'
import { CreateContactActionDropdownItem } from '../Actions/CreateContactActionDropdownItem'
import { MakeCallActionDropdownItem } from '../Actions/MakeCallDropdownItem'
import { NewMessageActionDropdownItem } from '../Actions/NewMessageDropdownItem'

import styles from './styles.module.scss'

type IConversationHeaderListDropdownProps = {
  disabled: boolean
  ariaLabel?: string
  onChangeCall?: () => void
  onChangeNew?: () => void
  onChangeContactCreate?: () => void
}

export const ConversationHeaderListDropdown: FC<IConversationHeaderListDropdownProps> = ({
  disabled,
  ariaLabel,
  onChangeCall,
  onChangeNew,
  onChangeContactCreate,
}) => {
  const { isActionDisabled } = conversationStore
  const { salesmsg_power_dialer_v2 } = featureFlagsStore
  const isPFTInbox = inboxesStore.currentInbox?.isPFTInbox

  const onEventHotKey = (e: KeyboardEvent) => {
    const osMac = navigator.userAgent.indexOf('Mac') !== -1
    const comboKey = osMac ? e.metaKey : e.ctrlKey

    if (comboKey) {
      if (e.code === 'KeyM') {
        if (isActionDisabled) return

        e.preventDefault()

        onChangeNew?.()
      }

      if (e.code === 'KeyC') {
        if (document.getSelection()?.toString().length) return
        if (isPFTInbox) return

        e.preventDefault()

        onChangeCall?.()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onEventHotKey)

    return () => {
      window.removeEventListener('keydown', onEventHotKey)
    }
  }, [isActionDisabled, isPFTInbox])

  const { isMediumView } = layoutStore

  return (
    <Dropdown
      ariaLabel={ariaLabel || 'HeaderList'}
      icon={'add'}
      placement={'right-start'}
      disabled={disabled}
      iconButtonProps={{
        disabled: disabled,
        transparentBackground: disabled,
        fontSize: 20,
        shape: EnumIconButtonShape.Rounded,
        color: disabled ? 'transparent' : 'tertiary',
        ariaLabel: 'DropdownHeaderList_actionButton',
      }}
      sizeTriggerButton={'medium'}
      customComponent={(onCloseMenu) => (
        <DropdownContent
          className={classNames(styles.dropdownContent, {
            [styles.dropdownContentMobile]: isMediumView,
          })}
        >
          <NewMessageActionDropdownItem
            onClick={() => {
              onChangeNew?.()
              onCloseMenu()
            }}
          />
          <MakeCallActionDropdownItem
            onClick={() => {
              onChangeCall?.()
              onCloseMenu()
            }}
          />
          {salesmsg_power_dialer_v2 ? (
            <PowerDialerEditorDropdownItem
              onClick={() => {
                onCloseMenu()
              }}
            />
          ) : (
            <PowerDialerActionDropdownItem
              onClick={() => {
                onCloseMenu()
              }}
            />
          )}
          <CreateContactActionDropdownItem
            onClick={() => {
              onChangeContactCreate?.()
              onCloseMenu()
            }}
          />
        </DropdownContent>
      )}
    />
  )
}
