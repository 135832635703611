import { PropsWithChildren, createContext, useContext, useEffect, useMemo, FC } from 'react'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

const ComplianceLayerContext = createContext<ComplianceLayerStore | null>(null)

export const useComplianceLayerContext = () => {
  const context = useContext(ComplianceLayerContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ComplianceLayerProvider')

  return context
}

export const ComplianceLayerProvider: FC<PropsWithChildren> = ({ children }) => {
  const store = useMemo(() => new ComplianceLayerStore(), [])

  useEffect(() => store.dispose, [])

  return <ComplianceLayerContext.Provider value={store}>{children}</ComplianceLayerContext.Provider>
}
