import { makeAutoObservable } from 'mobx'
import type { IResponseTollFreeNumber } from 'entities/Compliance'

export class TollFreeProfileNumber {
  private _id: number | null = null
  private _verified_status: IResponseTollFreeNumber['verified_status'] | null = null

  constructor(response: IResponseTollFreeNumber) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseTollFreeNumber) => {
    this._id = response.id
    this._verified_status = response.verified_status
  }

  get id() {
    return this._id
  }

  get status() {
    return this._verified_status || ''
  }

  get isStatusInProgress() {
    return this._verified_status === 'Unverified'
  }

  get isStatusInCarrierReview() {
    return this._verified_status === 'Under Review'
  }

  get isStatusVerified() {
    return this._verified_status === 'Verified'
  }

  get isStatusPendingReview() {
    return this._verified_status === 'Internal Review'
  }

  get isStatusRejected() {
    return this._verified_status === 'Denied'
  }

  get isStatusBlocked() {
    return this._verified_status === 'Blocked'
  }
}
