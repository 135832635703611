// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QvNN0KbP622wvlqBBQcI{display:flex;gap:10px;align-items:center}.B612zq37qpRBzJgzm6uC{display:flex;gap:8px;margin-top:12px}.JOX0n6eWHxD4hokWxkd1{margin-top:24px;overflow-y:auto}.bT9VWsOAUkLYgjdhZZmF{display:flex;flex-direction:column;gap:16px;margin-top:32px}.sE5L4jde1lyiQG_WraqQ{letter-spacing:-0.4px;max-width:256px}.iaqNm9Cxe486kqffVJDO{max-width:226px}.fRXUZQHrH7cq2EPLcn5j{margin-top:24px}", "",{"version":3,"sources":["webpack://./src/pages/keywords/pages/all/ui/KeywordsModals/Details/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGF,sBACE,qBAAA,CACA,eAAA,CAGF,sBACE,eAAA,CAGF,sBACE,eAAA","sourcesContent":[".titleWrap {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n.info {\n  display: flex;\n  gap: 8px;\n  margin-top: 12px;\n}\n\n.bodyWrap {\n  margin-top: 24px;\n  overflow-y: auto;\n}\n\n.keywordParamsWrap {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 32px;\n}\n\n.keywordName {\n  letter-spacing: -0.4px;\n  max-width: 256px;\n}\n\n.keywordTitle {\n  max-width: 226px;\n}\n\n.alert {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrap": "QvNN0KbP622wvlqBBQcI",
	"info": "B612zq37qpRBzJgzm6uC",
	"bodyWrap": "JOX0n6eWHxD4hokWxkd1",
	"keywordParamsWrap": "bT9VWsOAUkLYgjdhZZmF",
	"keywordName": "sE5L4jde1lyiQG_WraqQ",
	"keywordTitle": "iaqNm9Cxe486kqffVJDO",
	"alert": "fRXUZQHrH7cq2EPLcn5j"
};
export default ___CSS_LOADER_EXPORT___;
