import { CampaignMessagesContext } from './context/context'
import { CampaignMessagesStore } from './store/CampaignMessagesStore'
import { CampaignMessagesUi } from './ui/CampaignMessagesUi'

type ICampaignMessagesProps = {
  store: CampaignMessagesStore
}

export const CampaignMessages = ({ store }: ICampaignMessagesProps) => {
  return (
    <CampaignMessagesContext.Provider value={store}>
      <CampaignMessagesUi />
    </CampaignMessagesContext.Provider>
  )
}
