import { makeAutoObservable, runInAction } from 'mobx'
import type {
  IKeysUserPermissions,
  IResponseUserPermissions,
} from 'entities/UsersPermissions/api/types'
import { UsersPermissionsApi } from 'entities/UsersPermissions/api/usersPermissions'

class UserPermissionsStore {
  initPromise
  private _setFinishInit: ((value?: unknown) => void) | null = null
  private _loading = true
  private _permissions: IResponseUserPermissions | null = null
  private _request: null | ReturnType<(typeof UsersPermissionsApi)['getUsersPermissions']> = null

  constructor() {
    this.initPromise = new Promise((resolve) => {
      this._setFinishInit = resolve
    })

    makeAutoObservable(this)
  }

  private _fetchUserPermissions = async () => {
    try {
      if (this._request) return this._request.then()
      runInAction(() => {
        this._loading = true
      })

      this._request = UsersPermissionsApi.getUsersPermissions()
      const { data: userPermissions } = await this._request

      runInAction(() => {
        this._permissions = userPermissions
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  fetchUserPermissions = async () => {
    await this._fetchUserPermissions()
    this._setFinishInit?.()
  }

  getItem = (key: IKeysUserPermissions) => {
    if (!this._permissions) return false

    return this._permissions[key]
  }

  get loading() {
    return this._loading
  }
}

export const userPermissionsStore = new UserPermissionsStore()
