import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Divider, Box, Switch, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { adminEditAgencyFranchiseContractContentStore } from 'pages/admin/pages/organizations/modals/AdminEditAgencyFranciseContract/store/adminEditAgencyFranchiseContractContentStore'
import { adminOrganizationStore } from 'pages/admin/pages/organizations'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'
import styles from './styles.module.scss'

export const AdminEditAgencyFranchiseContractContent = observer(() => {
  const {
    is_agency,
    setAgency,
    is_franchise,
    setFranchise,
    agency_plan,
    set_agency_plan,
    master_plan,
    set_master_plan,
    // selected_plans,
    // set_clients_plans,
    // removePlan,
  } = adminEditAgencyFranchiseContractContentStore

  const { loadCustomPrice, customPricesItems } = adminOrganizationStore
  const { fetchPlans } = adminOrganizationPlanStore

  useEffect(() => {
    fetchPlans()

    if (is_franchise) {
      loadCustomPrice()
    }
  }, [is_franchise])

  return (
    <div className={styles.wrap}>
      <Box>
        <Box>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography
                marginBottom={2}
                tag={'div'}
                variant={'body-md-regular'}
                ariaLabel={'title'}
                color={'var(--content-primary-primary)'}
              >
                Agency
              </Typography>
              <Typography
                variant={'body-md-regular'}
                color={'var(--content-primary-secondary)'}
                ariaLabel={'description'}
              >
                Enable Agency features
              </Typography>
            </Box>
            <Switch value={is_agency} onChange={setAgency} />
          </Box>
          {/* THE Code commented temporarily for the ticket SWR-13951, in future need revert */}
          {/* {is_agency && (*/}
          {/*  <>*/}
          {/*    <Label*/}
          {/*      label={{*/}
          {/*        text: 'Plans for Clients',*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <Select*/}
          {/*      values={selected_plans}*/}
          {/*      text='Plans for Clients'*/}
          {/*      items={options}*/}
          {/*      type={'multi'}*/}
          {/*      onSelectValues={set_clients_plans}*/}
          {/*      fullWidth*/}
          {/*      dropdownProps={{*/}
          {/*        ariaLabel: 'Plans',*/}
          {/*        width: 432,*/}
          {/*        triggerComponent: () => (*/}
          {/*          <Box*/}
          {/*            sx={{*/}
          {/*              border: '1px solid var(--interaction-neutral-hovered)',*/}
          {/*              borderRadius: '6px',*/}
          {/*            }}*/}
          {/*            padding={'8px'}*/}
          {/*            gap={'2px'}*/}
          {/*            display={'flex'}*/}
          {/*            flexWrap={'wrap'}*/}
          {/*          >*/}
          {/*            {!selected_plans.length && (*/}
          {/*              <Box*/}
          {/*                sx={{*/}
          {/*                  border: '1px solid var(--border-neutral-default)',*/}
          {/*                  borderRadius: '6px',*/}
          {/*                  width: 'auto',*/}
          {/*                }}*/}
          {/*                display={'flex'}*/}
          {/*                alignItems={'center'}*/}
          {/*                justifyContent={'space-between'}*/}
          {/*                padding={'4px'}*/}
          {/*              >*/}
          {/*                <Typography*/}
          {/*                  variant={'body-md-regular'}*/}
          {/*                  color={'var(--content-primary-primary)'}*/}
          {/*                  ariaLabel={'value'}*/}
          {/*                >*/}
          {/*                  Standard Salesmsg monthly and annual plans*/}
          {/*                </Typography>*/}
          {/*                <IconButton*/}
          {/*                  variant={'icon'}*/}
          {/*                  color={'secondary'}*/}
          {/*                  size={'small'}*/}
          {/*                  icon={'close'}*/}
          {/*                  ariaLabel={'close'}*/}
          {/*                />*/}
          {/*              </Box>*/}
          {/*            )}*/}
          {/*            {selected_plans*/}
          {/*              .map((id) => selectedPlanData(id))*/}
          {/*              .filter((plan) => !!plan)*/}
          {/*              .map((plan) => (*/}
          {/*                <Box*/}
          {/*                  key={plan?.key}*/}
          {/*                  sx={{*/}
          {/*                    border: '1px solid var(--border-neutral-default)',*/}
          {/*                    borderRadius: '6px',*/}
          {/*                    width: 'auto',*/}
          {/*                  }}*/}
          {/*                  display={'flex'}*/}
          {/*                  alignItems={'center'}*/}
          {/*                  justifyContent={'space-between'}*/}
          {/*                  padding={'4px'}*/}
          {/*                >*/}
          {/*                  <Typography*/}
          {/*                    variant={'body-md-regular'}*/}
          {/*                    color={'var(--content-primary-primary)'}*/}
          {/*                    ariaLabel={'value'}*/}
          {/*                  >*/}
          {/*                    {plan?.name}*/}
          {/*                  </Typography>*/}
          {/*                  <IconButton*/}
          {/*                    variant={'icon'}*/}
          {/*                    ariaLabel={'close'}*/}
          {/*                    color={'secondary'}*/}
          {/*                    size={'small'}*/}
          {/*                    onClick={() => removePlan(String(plan?.key))}*/}
          {/*                    icon={'close'}*/}
          {/*                  />*/}
          {/*                </Box>*/}
          {/*              ))}*/}
          {/*          </Box>*/}
          {/*        ),*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </>*/}
          {/* )}*/}
        </Box>
        <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} orientation={'horizontal'} />
        <Box>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography
                marginBottom={2}
                tag={'div'}
                variant={'body-md-regular'}
                ariaLabel={'title'}
                color={'var(--content-primary-primary)'}
              >
                Franchise
              </Typography>
              <Typography
                variant={'body-md-regular'}
                color={'var(--content-primary-secondary)'}
                ariaLabel={'description'}
              >
                Enable Franchise features
              </Typography>
            </Box>
            <Switch value={is_franchise} onChange={setFranchise} />
          </Box>
        </Box>
      </Box>
      {is_franchise && (
        <>
          <SelectField
            labelText={'Default plan for clients'}
            placeholder={'Choose an option'}
            items={customPricesItems}
            value={master_plan}
            setValue={set_master_plan}
          />
          <SelectField
            labelText={'Plan for agency billing'}
            placeholder={'Choose an option'}
            items={customPricesItems}
            value={agency_plan}
            setValue={set_agency_plan}
          />
        </>
      )}
    </div>
  )
})
