import { observer } from 'mobx-react-lite'
import { KEYWORD_NAME_LIMIT } from 'shared/constants/limits'
import { TextInput } from 'shared/ui'
import { useKeywordViewContext } from 'pages/keywords/context'
import styles from './styles.module.scss'

export const KeywordName = observer(() => {
  const { setName, name, errorsMap, removeError } = useKeywordViewContext()

  const error = errorsMap.get('name')

  return (
    <TextInput
      size={'large'}
      placeholder={'Keyword (e.g. “OFFER”)'}
      value={name}
      setValue={setName}
      error={error}
      limit={KEYWORD_NAME_LIMIT}
      onFocus={() => removeError('name')}
      className={styles.keywordName}
      ariaLabel='KeywordName'
    />
  )
})
