import { makeAutoObservable } from 'mobx'
import type { IActionsProps } from 'shared/ui'
import type { IResponseMediaShort } from 'entities/Attachment'
import type { IResponseNumber } from 'entities/Phone/api/types'
import type { IKeywordStatus, IResponseKeyword } from 'entities/Keywords/api/type'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { Tag } from 'entities/Tags/model/Tag'
import type { ISendFromNumbersProps } from 'entities/Inbox/api/types'
import { inboxesStore } from 'entities/Inbox'
import { KeywordStatistics } from './KeywordStatistics'

export type IKeyword = Keyword

export class Keyword {
  createdAt: string
  deletedAt: string | null = null
  disabledAt: string | null = null
  id: number
  status: IKeywordStatus
  media_url: IResponseMediaShort[] = []
  message = ''
  name: string
  numbers: IResponseNumber[]
  organizationId: number | null
  ownerId: number | null
  statistics: KeywordStatistics
  title = ''
  updatedAt: string
  actionsProps: IActionsProps | null = null
  attachments: Attachment[] = []
  textInNumbersProps: ISendFromNumbersProps[] = []
  tags: Tag[]

  constructor(item: IResponseKeyword) {
    this.id = item.id
    this.name = item.name
    this.ownerId = item.owner_id
    this.status = item.status === 'invalid' ? 'invalid' : item.is_active ? 'active' : 'disabled'
    this.statistics = new KeywordStatistics(item.statistics)
    this.createdAt = item.created_at
    this.deletedAt = item.deleted_at
    this.disabledAt = item.disabled_at
    this.updatedAt = item.updated_at
    this.message = item.message || ''
    this.title = item.title || ''
    this.media_url = item.media_url || []
    this.organizationId = item.organization_id
    this.numbers = item.numbers
    this.attachments = Array.isArray(item.media_url)
      ? item.media_url.map((media) => new Attachment({ responseMediaShort: media }))
      : []
    this.textInNumbersProps = this.getTextInNumbersProps(item)
    this.tags = Array.isArray(item.tags) ? item.tags.map((tagResponse) => new Tag(tagResponse)) : []

    makeAutoObservable(this)
  }

  setActionsProps = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }

  syncOrigin(updatedKeyword: IResponseKeyword) {
    this.status =
      updatedKeyword.status === 'invalid'
        ? 'invalid'
        : updatedKeyword.is_active
        ? 'active'
        : 'disabled'
  }

  getTextInNumbersProps = (keyword: IResponseKeyword): ISendFromNumbersProps[] => {
    return keyword.numbers.map((number) => {
      return {
        icon: 'inboxOpen',
        name: number?.numberable?.name,
        value: number?.formatted_number,
        iconR: number?.is_aircall ? 'aircall' : undefined,
      }
    })
  }

  get inboxId() {
    const inbox = this.numbers
      .map((number) => inboxesStore.getInboxByNumber(number.id))
      .find((inbox) => inbox?.id)

    return inbox?.id
  }

  get isInvalid() {
    return this.status === 'invalid'
  }
}
