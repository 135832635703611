import { observer } from 'mobx-react-lite'
import { SchedulePeriodStore } from 'shared/ui/Schedule/ui/SchedulePeriod/store/SchedulePeriodStore'
import { Dropdown, IDropdownItem, IDropdownProps } from 'shared/ui'
import {
  ISchedulePeriod,
  schedulePeriods,
  schedulePeriodTitle,
} from 'shared/ui/Schedule/ui/SchedulePeriod/store/types'
import { makeTextFieldIcon, TextFieldPlaceholder } from 'shared/ui/TextField'
import styles from './styles.module.scss'

type ISchedulePeriodProps = {
  store: SchedulePeriodStore
} & Omit<IDropdownProps, 'items' | 'onSelectValue' | 'value'>

export const SchedulePeriod = observer(({ store, ...props }: ISchedulePeriodProps) => {
  const { period, setPeriod } = store

  const items: IDropdownItem[] = schedulePeriods.map((item) => ({
    id: item,
    label: schedulePeriodTitle[item],
    activeValue: period === item,
  }))
  const onChange = (item: IDropdownItem) => {
    setPeriod(item.id as ISchedulePeriod)
  }
  return (
    <Dropdown
      className={styles.wrap}
      items={items}
      onChange={onChange}
      margin={0}
      triggerComponent={() => (
        <TextFieldPlaceholder
          variant={'outline'}
          size={'small'}
          rightActions={[
            makeTextFieldIcon({
              icon: 'chevronDown',
            }),
          ]}
          placeholder={schedulePeriodTitle[period]}
          placeholderClassName={styles.placeholder}
        />
      )}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              offset: ({ reference }) => {
                return [-2, -reference.height - 4]
              },
            },
          },
        ],
      }}
      {...props}
    />
  )
})
