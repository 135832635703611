import { useBlocker } from 'react-router'
import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { CampaignBlockerStore } from './store/CampaignBlockerStore'

type ICampaignBlockerProps = {
  store: CampaignBlockerStore
}

export const CampaignBlocker = observer(({ store }: ICampaignBlockerProps) => {
  const { isExistChanges, handleShowBlockerModal } = store
  const blocker = useBlocker(() => isExistChanges)

  const beforeUnloadHandler = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault()
  }, [])

  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [])

  useEffect(() => {
    handleShowBlockerModal(blocker)
  }, [blocker])

  useEffect(() => {
    if (isExistChanges) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [isExistChanges])
  return null
})
