import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { MoreDropdownProps } from 'shared/ui/Actions/Actions'
import { shortLinkStore } from 'entities/ShortLink/store/shortLinkStore'

type IShortLinkDropdownItemProps = MoreDropdownProps & {
  onAddShortLink: (link: string) => void
  onOpenModal: () => void
}

export const ShortLinkDropdownItem: FC<IShortLinkDropdownItemProps> = observer(
  ({ onAddShortLink, onCloseMenu, onOpenModal }) => {
    const onOpenShortenLinkModal = () => {
      onCloseMenu && onCloseMenu()
      onOpenModal && onOpenModal()
      shortLinkStore.onOpenModal({
        onAddLink: onAddShortLink,
      })
    }
    return (
      <Button
        typeBtn={'menuItem'}
        text={'Shorten URL'}
        icon={'link'}
        onClick={onOpenShortenLinkModal}
      />
    )
  }
)
