import { createRoot } from 'react-dom/client'

import 'app/styles/all.scss'
import './service/PublicAPI'

import { CallPopupService } from './service/CallPopup'
import { SidebarService } from './service/Sidebar'
import { SettingsMenuService } from './service/SettingsMenu'
import { OverlayService } from './service/Overlay'
import { NextService } from './service/Next'
import { BannerService } from './service/Banners'

/* Call popup service */

window.initCallPopup = () => {
  const element = document.getElementById('portal')
  if (!!element) createRoot(element).render(<CallPopupService />)
}

/* Sidebar service */

window.initSidebar = () => {
  const element = document.getElementById('sidebar')

  if (!!element) {
    const sidebar = createRoot(element)

    window.resetSidebar = () => sidebar.unmount()

    sidebar.render(<SidebarService />)
  }
}

/* Settings menu service */

window.initSettingsMenu = () => {
  const element = document.getElementById('settings-menu')

  if (!!element) {
    const settingsMenu = createRoot(element)

    window.resetSettingsMenu = () => settingsMenu.unmount()

    settingsMenu.render(<SettingsMenuService />)
  }
}

/* Overlay service */

window.initOverlayReact = () => {
  const element = document.getElementById('app-overlay')
  if (!!element) createRoot(element).render(<OverlayService />)
}

/* Banner Service */

window.initBannerReact = () => {
  const element = document.getElementById('alert-banners')
  if (!!element) {
    const root = createRoot(element)

    window.resetBannerReact = () => root.unmount()

    root.render(<BannerService />)
  }
}

/* Next service */

window.initNextReact = () => {
  const element = document.getElementById('app-next')

  if (!!element) {
    const next = createRoot(element)

    window.resetNextReact = () => next.unmount()

    next.render(<NextService />)
  }
}
