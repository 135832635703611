import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import dayjs from 'dayjs'
import { cloneDeep, debounce } from 'lodash'
import { Call } from '@twilio/voice-sdk'
import {
  formatPhoneNumberInternational,
  formatPhoneNumberNational,
  formatPhoneNumberNationalNumber,
  getLabelAsNumberInternationalFormat,
  removeSpacesFromNumericString,
  validPhoneNumber,
} from 'shared/lib'
import { showToast, toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  CallApi,
  callStore,
  IParamsUpdateVoicemailDrop,
  IParamsVoiceCallNewParticipant,
  IParamsVoiceCallTransfer,
} from 'entities/Call'
import { ContactsApi } from 'entities/Contacts'
import { InboxesApi, inboxesStore } from 'entities/Inbox'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { IParamsCreateMessage, messageStore } from 'entities/Message'
import { numbersStore } from 'entities/Phone'
import { CallPeople } from 'entities/Call/model/CallPeople'
import { Contact } from 'entities/Contacts/model/Contact'
import { IParamsConversationCreate } from 'entities/Conversation/api/types'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { IResponseInboxCombine } from 'entities/Inbox/api/types'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { userSettingsStore } from 'entities/Settings'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { CallPopUpQueueStore } from 'widgets/CallPopUp/store/callPopUpQueueStore'
import { EventConferenceParticipantJoin } from 'widgets/CallPopUp/events/EventConferenceParticipantJoin'
import { EventConferenceParticipantLeave } from 'widgets/CallPopUp/events/EventConferenceParticipantLeave'
import type { ICallPopUpDefaultView, IVoicemailDropAudiofile } from 'widgets/CallPopUp'
import { MessageFieldStore } from 'widgets/MessageField'
import { CallPopUpPowerDialerStore } from 'widgets/CallPopUp/store/callPopUpPowerDialerStore'
import { CallPopUpPowerDialerStoreV2 } from 'widgets/CallPopUp/store/callPopUpPowerDialerStoreV2'

export class CallPopUpDefaultStore {
  private _showPopUp = false
  private _contactFrom: Inbox | null = null
  private _contactTo: Contact | null = null
  private _search = ''
  private _isValidNumber: boolean | null = null
  private _innerRefTooltipCurrent: HTMLDivElement | null = null
  private _participantsMap: Map<string, CallPeople> = new Map()
  private _contactsMap: Map<number, Contact> = new Map()
  private _contactsLoading = false
  private _inboxesMap: Map<number | string, Inbox> = new Map()
  private _inboxesLoading = false
  private _cancelTokenSource: CancelTokenSource | null = null

  private _status: Call.State = Call.State.Closed
  private _disposeContactsSearch: IReactionDisposer | null = null
  private _disposeTransferSearch: IReactionDisposer | null = null
  private _disposePopUp: IReactionDisposer | null = null
  private _disposeCheckParticipants: IReactionDisposer | null = null
  private _disposeStatusCall: IReactionDisposer | null = null
  private _organisationCallAutoRecord: IReactionDisposer | null = null

  private _conversation: Conversation | null = null
  private _visibleUI = false

  private _isHover = false

  // call view
  private _view: ICallPopUpDefaultView | null = null

  // mic
  private _isMic = false
  private _isMicDisabled = false
  private _isMute = false
  private _isIncomingMute = false
  private _isIncomingMuteDisabled = false

  // record
  private _isRecord = false
  private _isRecordDisabled = false
  private _isSetupRecord = false

  // dialpad
  private _isDialpad = false
  private _isDialpadDisabled = false
  private _searchDialpad = ''

  // hold
  private _isHold = false
  private _isHoldLoading = false
  private _isHoldDisabled = false
  private _holdCount = 0

  // message
  private _isMessage = false
  private _isMessageDisabled = false
  messageFieldStore = new MessageFieldStore({
    placeholder: 'Write reply...',
    styles: {
      minHeight: 65,
    },
  })

  // transfer
  private _isTransfer = false
  private _isTransferLoading = false
  private _isTransferDisabled = false
  private _isTransferWaiting = false

  // conference
  private _isConference = false
  private _isConferenceParticipants = false
  private _isConferenceParticipantsLoading = false
  private _isConferenceDisabled = false
  private _conferenceName = ''
  private _conferenceSid = ''
  private _conferenceParticipantsLimit = 9
  private _debounceFetchContactsSearch

  // disconnect
  private _isDisconnect = false

  // voicemail drop
  private _isShowVoicemailDropContent = false
  private _voicemailDropSearchKey = ''
  private _isLoadingVoicemailDrop = false

  // queue
  callPopUpQueueStore: CallPopUpQueueStore = new CallPopUpQueueStore()

  // power dialer
  callPopUpPowerDialerStore = new CallPopUpPowerDialerStore()
  // power dialer V2
  callPopUpPowerDialerStoreV2 = new CallPopUpPowerDialerStoreV2()

  constructor() {
    makeAutoObservable(this)

    this._debounceFetchContactsSearch = debounce(this.fetchContactSearch, 1000)
    this.reactionStatusCall()
    this.reactionPopUp()
    this.reactionCheckParticipants()
    this.initInboxes()
    this.reactionOrganisationCallAutoRecord()
  }

  initInboxes = () => {
    this.fetchAllInboxes()
  }

  setView = (view: ICallPopUpDefaultView) => {
    this._view = view
  }

  // refWrap
  initRefWrap = (element: HTMLDivElement | null) => {
    this._innerRefTooltipCurrent = element
  }

  // mic
  initMic = async () => {
    await callStore.deviceSettingsStore.syncDevicesStream()

    callStore.reactionSelectedAudioOutput()
    callStore.reactionSelectedAudioInput()

    runInAction(() => {
      this._isRecord = callStore.isRecord
    })
  }

  resetMic = () => {
    callStore.deviceSettingsStore.removeStream()
  }

  resetReactions = () => {
    this._disposeContactsSearch?.()
    this._disposeTransferSearch?.()
    this._disposePopUp?.()
    this._disposeCheckParticipants?.()
    this._disposeStatusCall?.()
    this._organisationCallAutoRecord?.()
  }

  resetCommon = () => {
    this._search = ''
    this._isValidNumber = null
    this._conversation = null
    this._isDisconnect = false
    this._contactFrom = null
    this._isShowVoicemailDropContent = false
    this._voicemailDropSearchKey = ''
    this._isLoadingVoicemailDrop = false
  }

  resetPopUp = () => {
    // mic
    this._isMic = false
    this._isMicDisabled = false
    this._isMute = false
    this._isIncomingMute = false
    this._isIncomingMuteDisabled = false

    // record
    this._isRecord = false
    this._isRecordDisabled = false
    this._isSetupRecord = false

    // dialpad
    this._isDialpad = false
    this._isDialpadDisabled = false
    this._searchDialpad = ''

    // hold
    this._isHold = false
    this._isHoldLoading = false
    this._isHoldDisabled = false
    this._holdCount = 0

    // message
    this._isMessage = false
    this._isMessageDisabled = false
    this.messageFieldStore.reset()

    // transfer
    this._isTransfer = false
    this._isTransferLoading = false
    this._isTransferDisabled = false
    this._isTransferWaiting = false

    // conference
    this._isConference = false
    this._isConferenceParticipants = false
    this._isConferenceParticipantsLoading = false
    this._isConferenceDisabled = false
    this._conferenceName = ''
    this._conferenceSid = ''
    this.resetParticipants()
    this._contactsMap.clear()
    this._contactsLoading = false
    this._cancelTokenSource = null
  }

  fetchAllInboxes = async () => {
    if (this._inboxesLoading) return

    try {
      runInAction(() => {
        this._inboxesLoading = true
      })

      const { data } = await InboxesApi.getTeamsAll()

      data.forEach((item) => this.addInboxItem(item))
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._inboxesLoading = false
      })
    }
  }

  addInboxItem = (data: IResponseInboxCombine) => {
    if (data.type !== 'inbox') return

    this._inboxesMap.set(data.id, new Inbox(data))
    numbersStore.addItems(data.numbers)
  }

  resetParticipants = () => {
    this._participantsMap.forEach((item) => {
      item.resetIncrementTime()
    })
    this._participantsMap.clear()
  }

  setShowPopUp = (status: boolean) => {
    this._showPopUp = status
  }

  // mic
  handleShowMic = () => {
    this._isDialpad = false
    this._isMic = !this._isMic
  }

  // mute
  handleMute = (status?: boolean | undefined) => {
    this._isMute = typeof status === 'boolean' ? status : !this._isMute

    callStore.handleMute(this._isMute)
  }

  handleIncomingMute = () => {
    if (this._isIncomingMute) return

    this._isIncomingMute = !this._isIncomingMute

    callStore.handleMuteIncomingNotification(this._isIncomingMute)
  }

  // record
  handleRecord = async (type: 'toggle' | 'init' = 'toggle') => {
    if (!callStore.connect) return

    if (type === 'toggle') {
      runInAction(() => {
        this._isRecord = !this._isRecord
      })
    }

    if (type === 'init') {
      if (!callStore.isRecord) return

      runInAction(() => {
        this._isRecord = callStore.isRecord
      })
    }

    try {
      if (!this._isSetupRecord) {
        await CallApi.updateVoiceRecordStatus({
          call_id: callStore.connect.parameters.CallSid,
          record: 'start',
        })

        runInAction(() => {
          this._isSetupRecord = true
        })
      }

      await CallApi.updateVoiceRecordStatus({
        call_id: callStore.connect.parameters.CallSid,
        record: this._isRecord ? 'on' : 'off',
      })
    } catch (e) {
      console.error(e)
    }
  }

  // dialpad
  handleDialpadShow = () => {
    this._isMic = false
    this._isHold = false
    this._isDialpad = !this._isDialpad
    this._isShowVoicemailDropContent = false
  }

  handleDialpadSearch = (value: string) => {
    this._searchDialpad += value

    callStore.sendDigitsTwilio(value)
  }

  // hold
  handleHoldToggle = async (type: 'default' | 'transfer' = 'default') => {
    if (!callStore.connect) return
    if (this._isHoldLoading) return

    try {
      runInAction(() => {
        this._isHold = !this._isHold

        if (type === 'default') {
          this._isHoldLoading = true
        }

        // reset
        this._isMic = false
        this._isDialpad = false

        // disabled
        this.handleMute(true)
        this._isMicDisabled = true
        this._isRecordDisabled = false
        this._isHoldDisabled = true
        this._isTransferDisabled = true

        if (!this.isCreatedConference) {
          this._isConferenceDisabled = true
          this._isConference = false
        }
      })

      if (this.isCreatedConference) {
        if (this._isHold) {
          await CallApi.updateVoiceConferenceBySidHold(this._conferenceSid)

          this.participants.forEach((item) => {
            runInAction(() => {
              item.updateIsHold(true)
              item.updateIsHoldDisabled(true)
            })
          })
        } else {
          await CallApi.updateVoiceConferenceBySidResume(this._conferenceSid)

          this.participants.forEach((item) => {
            runInAction(() => {
              item.updateIsHold(false)
              item.updateIsHoldDisabled(false)
            })
          })
        }
      } else {
        if (this._isHold) {
          const { data: response } = await CallApi.updateVoiceCallHold({
            call_sid: callStore.connect.parameters.CallSid,
            send_segment_event: type === 'default',
            is_first_hold: this._holdCount === 0,
          })

          if (response.success) {
            runInAction(() => {
              this._holdCount += 1
            })
          }
        } else {
          await CallApi.updateVoiceCallUnhold({
            call_sid: callStore.connect.parameters.CallSid,
            send_segment_event: type === 'default',
          })
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        if (!this._isHold) {
          this.handleMute(false)
        }
        this._isHoldLoading = false
        this._isMicDisabled = false
        this._isRecordDisabled = false
        this._isHoldDisabled = false
        this._isTransferDisabled = false
        this._isConferenceDisabled = this._isHold
      })
    }
  }

  // message
  initMessageField = () => {
    this.messageFieldStore.setSendActionBtnProps({
      onClick: this.handleMessageSend,
      ariaLabel: 'CallPopUpDefaultStore_handleMessageSend',
    })
    this.messageFieldStore.setFocusMessageFieldTrigger()
  }

  handleMessageSend = async () => {
    if (!callStore.connect) return
    if (!callStore.contactTo) return
    if (!callStore.contactFrom) return
    if (this.messageFieldStore.loading) return

    try {
      runInAction(() => {
        this.messageFieldStore.loading = true
      })

      const paramsMessage: IParamsCreateMessage = {
        media_url: [],
        manual_created: true,
        message: this.messageFieldStore.text,
        send_at: '',
        stop_on_response: false,
        type: null,
        should_save_draft: true,
        call_sid: callStore.connect.parameters.CallSid,
        sub_source: null,
      }

      const paramsConversation: IParamsConversationCreate = {
        contact_id: callStore.contactTo.id,
        team_id: callStore.contactFrom.id,
      }

      const conversation = await conversationStore.createConversation(paramsConversation)

      if (conversation) {
        await messageStore.createMessageByConversationId(conversation.id, paramsMessage)

        await callStore.disconnectTwilio()
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.messageFieldStore.loading = false

        toastStore.add({
          title: 'Message sent',
          type: 'success',
        })
      })
    }
  }

  handleMessageShow = () => {
    this._isMessage = !this._isMessage
  }

  // transfer
  resetTransfer = () => {
    this._search = ''
  }

  handleTransferShow = async () => {
    if (!callStore.connect) return

    try {
      runInAction(() => {
        this._isTransferLoading = true
        this._isTransferDisabled = true
      })

      if (!this._isHold) {
        await this.handleHoldToggle('transfer')
      }

      if (this._isTransfer && this._isHold) {
        await this.handleHoldToggle('transfer')
      }

      runInAction(() => {
        this._isTransfer = !this._isTransfer

        this.handleMute(this._isTransfer)

        // disabled
        this._isHoldDisabled = this._isTransfer
        this._isDialpadDisabled = this._isTransfer
        this._isMicDisabled = this._isTransfer
        this._isConferenceDisabled = this._isTransfer
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._isTransferLoading = false
        this._isTransferDisabled = false
      })
    }
  }

  handleTransferCall = async (item?: CallPeople) => {
    if (!callStore.connect) return

    try {
      this._isTransferWaiting = true
      if (this.callPopUpPowerDialerStore.hasItems) {
        this.callPopUpPowerDialerStore.setPause(true)
      }
      if (this.callPopUpPowerDialerStoreV2.hasContactsToCall) {
        this.callPopUpPowerDialerStoreV2.setPause(true)
      }

      const params: IParamsVoiceCallTransfer = {
        call_sid: callStore.connect.parameters.CallSid,
      }

      if (item?.type === 'inbox') {
        params.inbox_id = item.id
      }

      if (item?.type === 'number') {
        params.number = formatPhoneNumberNational(item.id.toString())
      }

      const { data } = await CallApi.updateVoiceCallTransfer(params)

      if (data.success) {
        this._isTransfer = false
      }
    } catch (e) {
      console.error(e)
    } finally {
      this._isTransferWaiting = false

      toastStore.add({
        title: 'Call transferred',
        type: 'success',
      })
    }
  }

  // conference
  resetConference = () => {
    this._search = ''
    this._contactsMap.clear()
  }

  handleConferenceShow = () => {
    this._isConference = !this._isConference
  }

  fetchContactSearch = async () => {
    try {
      const {
        data: { results },
      } = await ContactsApi.getContactsSearch(
        {
          term: removeSpacesFromNumericString(this._search),
          page: 1,
          skip_opted_out: 0,
        },
        {
          ...(this._cancelTokenSource ? { cancelToken: this._cancelTokenSource.token } : null),
        }
      )

      this._contactsMap.clear()

      results.forEach((item) => {
        this._contactsMap.set(item.id, new Contact(item))
      })

      this.handleValidateNumber()
    } catch (e) {
      console.error(e)
    } finally {
      this._contactsLoading = false
    }
  }

  handleValidateNumber = () => {
    if (!this.total) {
      const isValidNumber = validPhoneNumber(this._search)

      if (isValidNumber) {
        const numberContacts = this.participants.filter(
          (contact) => contact.national_number === formatPhoneNumberNationalNumber(this._search)
        )
        const numberInbox =
          this._contactFrom?.type === 'inbox'
            ? numbersStore.getItem(this._contactFrom?.numberId)?.national_number
            : undefined
        const isCreateNumberContact = numberContacts.length
        const isCreateNumberInbox = numberInbox === formatPhoneNumberNationalNumber(this._search)
        const isCreated = isCreateNumberContact || isCreateNumberInbox

        if (isCreated) {
          this._isValidNumber = null

          return
        }
      }

      this._isValidNumber = !isValidNumber
    } else {
      this._isValidNumber = null
    }
  }

  handleContactsSearch = async () => {
    this._cancelTokenSource?.cancel?.()
    this._cancelTokenSource = axios.CancelToken.source()
    this._contactsLoading = true
    this._isValidNumber = null

    await this._debounceFetchContactsSearch()
  }

  getParticipant = (id: string) => {
    return this._participantsMap.get(id)
  }

  hasParticipant = (id: string) => {
    return this._participantsMap.has(id)
  }

  deleteParticipant = (id: string) => {
    this._participantsMap.delete(id)
  }

  addParticipant = (item: CallPeople) => {
    item = cloneDeep(item)

    if (item.type === 'number') {
      item.avatarProps = {
        info: {
          firstName: String(item.id),
          color: 'var(--orange-60)',
        },
      }
      item.name = formatPhoneNumberNational(String(item.id))
    }

    const callPeopleItem = new CallPeople(item)

    this._participantsMap.set(callPeopleItem.uniqueId, callPeopleItem)
  }

  handleAddParticipant = async (item: CallPeople) => {
    if (!callStore.connect) return
    if (!callStore.contactFrom) return
    if (!callStore.contactTo) return

    try {
      runInAction(() => {
        this._isConferenceParticipantsLoading = true
      })

      if (this._participantsMap.has(item.uniqueId)) {
        runInAction(() => {
          this._isConferenceParticipants = true
          this._isConferenceParticipantsLoading = false
        })

        return
      }

      runInAction(() => {
        this._isConferenceParticipants = true

        if (!this.isCreatedConference) {
          const currentUniqueId = this._participantsMap.get(`contact_id:${callStore.contactTo?.id}`)

          if (currentUniqueId) {
            currentUniqueId.updateIsParticipant(true)
          }
        }

        this.addParticipant(item)

        const itemParticipant = this.getParticipant(item.uniqueId)

        if (itemParticipant) {
          itemParticipant.updateStatus('calling')
          itemParticipant.updateIsParticipant(true)
        }
      })

      const new_participant: IParamsVoiceCallNewParticipant = {
        contact_id: null,
        inbox_id: null,
        number: null,
      }

      if (item.type === 'contact') {
        new_participant.contact_id = item.id
      }

      if (item.type === 'inbox') {
        new_participant.inbox_id = item.id
      }

      if (item.type === 'number') {
        new_participant.number = `+1${item.id}`
      }

      if (this.isCreatedConference) {
        await CallApi.updateVoiceCallBySidConferenceBySid(
          callStore.connect.parameters.CallSid,
          this._conferenceSid,
          {
            new_participant: new_participant,
            current_inbox_id: callStore.contactFrom.id,
            conference_name: this._conferenceName,
          }
        )
      } else {
        const { data } = await CallApi.updateVoiceCallByCallSidConference(
          callStore.connect.parameters.CallSid,
          {
            active_call: {
              from: `inbox_id:${callStore.contactFrom.id}`,
              to: `contact_id:${callStore.contactTo.id}`,
            },
            new_participant: new_participant,
            current_inbox_id: callStore.contactFrom.id,
          }
        )

        if (data.success) {
          runInAction(() => {
            this._conferenceName = data.conference_name
            this._conferenceSid = data.conference_sid
          })
        }
      }
    } catch (e) {
      this.deleteParticipant(item.uniqueId)
      console.error(e)
    } finally {
      runInAction(() => {
        this._isConferenceParticipantsLoading = false
      })
    }
  }

  handleChangeHoldParticipant = async (item: CallPeople) => {
    try {
      runInAction(() => {
        item.updateIsHoldLoading(true)
        item.updateIsHold(!item.isHold)
      })

      await CallApi.updateVoiceConferenceBySidParticipant(this._conferenceSid, {
        action: item.isHold ? 'hold' : 'return',
        participant: item.uniqueId,
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        item.updateIsHoldLoading(false)
      })
    }
  }

  handleDeleteParticipant = async (item: CallPeople) => {
    try {
      runInAction(() => {
        item.updateIsDeleteLoading(true)
      })

      await CallApi.updateVoiceConferenceBySidParticipant(this._conferenceSid, {
        action: 'remove',
        participant: item.uniqueId,
      })

      runInAction(() => {
        this.deleteParticipant(item.uniqueId)
        item.resetIncrementTime()
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        item.updateIsDeleteLoading(false)
      })
    }
  }

  handleChangeConferenceParticipantsStatus = (status: boolean) => {
    this._isConferenceParticipants = status
  }

  handleSearch = (value: string) => {
    this._search = value
  }

  clearSearch = () => {
    this._search = ''
  }

  handleSetVisibleUI = (status: boolean) => {
    this._visibleUI = status
  }

  handleDisconnect = async (id?: string) => {
    if (!callStore.connect) return
    if (this._isDisconnect) return

    try {
      runInAction(() => {
        this._isDisconnect = true
      })

      if (id === 'end_all') {
        const { data } = await CallApi.updateVoiceConferenceBySidEnd(this._conferenceSid)

        if (data.success === true) {
          runInAction(() => {
            this._isDisconnect = false
          })

          showToast({
            title: 'Call ended',
            type: 'success',
          })

          return
        }
      }

      if (id === 'leave_call') {
        await callStore.disconnectTwilio(false)

        runInAction(() => {
          this._isDisconnect = false
        })

        showToast({
          title: 'You left the call',
          type: 'success',
        })

        return
      }

      await callStore.disconnectTwilio(false)

      if (!this.isCreatedConference) {
        const { data } = await CallApi.updateVoiceCallCallEnd(callStore.connect.parameters.CallSid)

        if (data.success === true) {
          runInAction(() => {
            this._isDisconnect = false
          })

          return
        }
      }

      runInAction(() => {
        this._isDisconnect = false
      })
    } catch (e) {
      console.error(e)

      runInAction(() => {
        this._isDisconnect = false
      })
    }
  }

  reactionContactsSearch = () => {
    this._disposeContactsSearch?.()
    this._disposeContactsSearch = reaction(
      () => this._search,
      (value) => {
        if (value.length >= 3) {
          this.handleContactsSearch()
        } else {
          this._contactsMap.clear()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionTransferSearch = () => {
    this._disposeTransferSearch?.()
    this._disposeTransferSearch = reaction(
      () => this._search,
      () => {
        this.handleValidateNumber()
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPopUp = () => {
    this._disposePopUp?.()
    this._disposePopUp = reaction(
      () => this._showPopUp,
      async (value) => {
        let eventConferenceParticipantJoin = null
        let eventConferenceParticipantLeave = null

        if (value) {
          this.handleSetVisibleUI(true)
          await this.initMic()

          eventConferenceParticipantJoin = EventConferenceParticipantJoin.subscribe(this)
          eventConferenceParticipantLeave = EventConferenceParticipantLeave.subscribe(this)
        }

        if (!value) {
          this.handleSetVisibleUI(false)
          this.resetPopUp()

          if (eventConferenceParticipantJoin) {
            eventConferenceParticipantJoin()
          }

          if (eventConferenceParticipantLeave) {
            eventConferenceParticipantLeave()
          }
        }
      }
    )
  }

  reactionCheckParticipants = () => {
    this._disposeCheckParticipants?.()
    this._disposeCheckParticipants = reaction(
      () => this.participants.length,
      (length) => {
        if (length === 1) {
          this._isConferenceParticipants = false
          this._isConference = false

          return
        }

        if (!length) {
          this._isConferenceParticipants = false
          this._isConference = false
          this.resetParticipants()
        }
      }
    )
  }

  reactionStatusCall = () => {
    this._disposeStatusCall?.()
    this._disposeStatusCall = reaction(
      () => this._status,
      (value) => {
        if (
          value === Call.State.Open ||
          value === Call.State.Ringing ||
          value === Call.State.Connecting ||
          value === Call.State.Pending
        ) {
          this.setShowPopUp(true)
        }

        if (value === Call.State.Open) {
          this.participants[0]?.updateStatus('connected')
          this.handleRecord('init')
        }

        if (value === Call.State.Closed) {
          this.setShowPopUp(false)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionOrganisationCallAutoRecord = () => {
    this._organisationCallAutoRecord?.()
    this._organisationCallAutoRecord = reaction(
      () => this.status,
      (status) => {
        if (status === Call.State.Open && organizationStore.isEnabledOrganisationCallAutoRecord) {
          this._isRecordDisabled = true

          if (!this.isRecord) this.handleRecord('toggle')
        }
      }
    )
  }

  setStatusCall = (value: Call.State) => {
    this._status = value
  }

  setContactFrom = (inbox: Inbox) => {
    this._contactFrom = inbox
    this.handleGetConversation()
  }

  setContactTo = (contact: Contact) => {
    this._contactTo = contact
    const participant = new CallPeople({
      id: contact.id,
      name: contact.name,
      desc: contact.number && getLabelAsNumberInternationalFormat(contact.number),
      national_number: contact.national_number,
      avatarProps: {
        info: contact.avatarInfo,
        fontSize: 12,
        size: 24,
      },
      type: 'contact',
    })

    this.addParticipant(participant)
  }

  handleGetConversation = async () => {
    try {
      const contactFrom = callStore.contactFrom
      const contactTo = callStore.contactTo
      let conversation: Conversation | null = null

      if (!contactFrom) return
      if (!contactTo) return

      const { data } = await ConversationsApi.getConversationsFindByContacts({
        team_id: contactFrom.id,
        contacts: [contactTo.id],
      })

      if (!data) {
        const item = await conversationStore.createConversation({
          contact_id: contactTo.id,
          team_id: contactFrom.id,
        })

        if (item) {
          conversation = item
        }
      } else {
        conversation = new Conversation(data)
      }

      this._conversation = conversation
    } catch (e) {
      console.log(e)
    }
  }

  handleGoToConversation = async () => {
    if (!this._conversation) return

    if (inboxesStore.currentInboxId !== this._conversation.inbox_id) {
      await inboxesStore.handleUpdateTeamInbox(this._conversation.inbox_id)
    }

    if (this._visibleUI) {
      uiStore.changeRoute({
        path: `/conversations/${this._conversation.id}`,
      })
    }
  }

  handleVoicemailDropContent = () => {
    this._isShowVoicemailDropContent = !this._isShowVoicemailDropContent
  }

  handleSearchVoicemailDrop = (key: string) => {
    this._voicemailDropSearchKey = key
  }

  handleChoiceVoicemailDrop = async (data: IVoicemailDropAudiofile) => {
    const { id, name } = data

    if (!callStore.connect) return

    try {
      this._isLoadingVoicemailDrop = true

      const params: IParamsUpdateVoicemailDrop = {
        callSid: callStore.connect.parameters.CallSid,
        dropId: id,
      }
      const { data } = await CallApi.updateVoicemailDrop(params)

      if (data.success) {
        toastStore.add({
          title: 'Voicemail drop completed',
          type: 'success',
        })

        const payload = {
          event_id: 'voicemail_drop_used',
          file_selected: name,
        }

        eventLogStore.logEvent('Voicemail Drop Used', payload, {
          groupId: organizationStore.id,
        })

        await callStore.disconnectTwilio()
      }
    } catch (e) {
      console.error(e)
    } finally {
      this._isLoadingVoicemailDrop = false
    }
  }

  getDuration = (src: string) => {
    return new Promise(function (resolve) {
      const audio = new Audio()

      audio.onloadedmetadata = function () {
        resolve(audio.duration)
      }
      audio.src = src
    })
  }

  setHover = (status: boolean) => {
    this._isHover = status
  }

  get isHover() {
    return this._isHover
  }

  get inboxesList(): CallPeople[] {
    return Array.from(this._inboxesMap.values())
      .filter((inbox) => this._contactFrom?.id !== inbox.id)
      .filter((inbox) => !this.hasParticipant(`inbox_id:${inbox.id}`))
      .filter((inbox) => {
        if (callStore.contactFrom?.id === inbox.id) return false

        return (
          callStore.contactTo?.national_number !==
          numbersStore.getItem(inbox.numberId)?.national_number
        )
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
      .filter((inbox) => {
        const number = numbersStore.getItem(inbox.numberId)

        return Boolean(
          inbox.name.toUpperCase().includes(this._search.toUpperCase()) ||
            number?.national_number.toUpperCase().includes(this._search.toUpperCase()) ||
            number?.formatted_number.toUpperCase().includes(this._search.toUpperCase()) ||
            number?.number.toUpperCase().includes(this._search.toUpperCase())
        )
      })
      .reduce<CallPeople[]>((state, inbox) => {
        if (inbox.type === 'inbox') {
          const callPeople = new CallPeople({
            id: inbox.id,
            name: inbox.name,
            national_number: numbersStore.getItem(inbox.numberId)?.national_number,
            desc: inbox.numberOrCount,
            avatarProps: {
              info: {
                icon: inbox.icon,
              },
              iconProps: {
                fontSize: 12,
              },
            },
            type: 'inbox',
          })

          return [callPeople, ...state]
        }

        return state
      }, [])
  }

  get contactsList(): CallPeople[] {
    const contacts = Array.from(this._contactsMap.values())
    const contactFromNumber = numbersStore.getItem(this._contactFrom?.numberId)

    return contacts
      .filter((contact) => contactFromNumber?.national_number !== contact.national_number)
      .filter((contact) => !this.hasParticipant(`contact_id:${contact.id}`))
      .map(
        (contact) =>
          new CallPeople({
            id: contact.id,
            name: contact.name,
            desc: contact.number,
            national_number: contact.national_number,
            avatarProps: {
              info: contact.avatarInfo,
              fontSize: 12,
              size: 24,
            },
            type: 'contact',
          })
      )
  }

  get isShowVoicemailDropButton() {
    return userSettingsStore.settingVoicemailDrop?.settings.active && callStore.isOutgoing
  }

  get voicemailDropsAudioList() {
    if (this._voicemailDropSearchKey) {
      return userSettingsStore.settingVoicemailDrop?.settings.audiofiles.filter((file) =>
        file.name.toLocaleLowerCase().includes(this._voicemailDropSearchKey.toLocaleLowerCase())
      )
    }

    return userSettingsStore.settingVoicemailDrop?.settings.audiofiles
  }

  get isEmptyVoicemailDropsAudioList() {
    return !this.voicemailDropsAudioList?.length && !this._voicemailDropSearchKey
  }

  get label() {
    if (!callStore.contactTo) return ''

    if (callStore.status === Call.State.Pending) {
      return 'Incoming call...'
    }

    if (callStore.status === Call.State.Ringing) {
      return 'Calling...'
    }

    return dayjs(this.participants[0]?.timestamp).format('mm:ss')
  }

  get isCreatedConference() {
    return Boolean(this._conferenceSid)
  }

  get participants(): CallPeople[] {
    return Array.from(this._participantsMap.values())
  }

  get avatarsInfo() {
    return this.participants.map((item) => item.avatarProps.info)
  }

  get contactsNames() {
    const participants = this.participants
    if (participants.length === 1) {
      return participants[0].name
    }

    return `${participants[0].name} + ${participants.filter((e, i) => i !== 0).length} more`
  }
  get contactsPhones() {
    const participants = this.participants
    if (participants.length === 1) {
      return formatPhoneNumberInternational(participants[0].national_number || '')
    }

    return `${formatPhoneNumberInternational(participants[0].national_number || '')} + ${
      participants.filter((e, i) => i !== 0).length
    } more`
  }

  get isOneParticipant() {
    return this.participants.length === 1
  }

  get total() {
    return this.inboxesList.length + this.contactsList.length
  }

  get isParticipants() {
    return Boolean(this.participants.length)
  }

  get checkParticipantsLimit() {
    return this.participants.length >= this._conferenceParticipantsLimit
  }

  get isDisconnect() {
    return this._isDisconnect
  }

  get status() {
    return this._status
  }

  get showPopUp() {
    return this._showPopUp
  }

  get view() {
    return this._view
  }

  get contactFrom() {
    return this._contactFrom
  }

  get contactTo() {
    return this._contactTo
  }

  get isStatusClose() {
    return this._status === Call.State.Closed
  }

  get innerRefTooltipCurrent() {
    return this._innerRefTooltipCurrent
  }

  get search() {
    return this._search
  }

  get contactsLoading() {
    return this._contactsLoading
  }

  get inboxesLoading() {
    return this._inboxesLoading
  }

  get isValidNumber() {
    return this._isValidNumber
  }

  get isConference() {
    return this._isConference
  }

  get isDialpad() {
    return this._isDialpad
  }

  get isTransfer() {
    return this._isTransfer
  }

  get isMessage() {
    return this._isMessage
  }

  get isHold() {
    return this._isHold
  }

  get isMic() {
    return this._isMic
  }

  get isShowVoicemailDropContent() {
    return this._isShowVoicemailDropContent
  }

  get isConferenceDisabled() {
    return this._isConferenceDisabled
  }

  get isConferenceParticipants() {
    return this._isConferenceParticipants
  }

  get isConferenceParticipantsLoading() {
    return this._isConferenceParticipantsLoading
  }

  get isDialpadDisabled() {
    return this._isDialpadDisabled
  }

  get searchDialpad() {
    return this._searchDialpad
  }

  get isHoldLoading() {
    return this._isHoldLoading
  }

  get isHoldDisabled() {
    return this._isHoldDisabled
  }

  get isMessageDisabled() {
    return this._isMessageDisabled
  }

  get isIncomingMute() {
    return this._isIncomingMute
  }

  get isIncomingMuteDisabled() {
    return this._isIncomingMuteDisabled
  }

  get isMute() {
    return this._isMute
  }

  get isMicDisabled() {
    return this._isMicDisabled
  }

  get isRecord() {
    return this._isRecord
  }

  get isRecordDisabled() {
    return this._isRecordDisabled
  }

  get isTransferDisabled() {
    return this._isTransferDisabled
  }

  get isTransferLoading() {
    return this._isTransferLoading
  }

  get isTransferWaiting() {
    return this._isTransferWaiting
  }

  get isLoadingVoicemailDrop() {
    return this._isLoadingVoicemailDrop
  }

  get voicemailDropSearchKey() {
    return this._voicemailDropSearchKey
  }

  get callRecordPopUpButtonTooltipLabel() {
    if (organizationStore.isEnabledOrganisationCallAutoRecord) {
      return 'Call recording enabled globally'
    }

    if (this.isRecord) {
      return 'Stop call recording'
    }

    return 'Record call'
  }
}
