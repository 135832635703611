import React from 'react'
import { Box } from '@mui/material'
import { IColumn, Avatar, Typography, Icon, IIconsVector } from 'shared/ui'
import {
  IResponseContactUnlinked,
  IResponseContactUnlinkedSource,
  IResponseContactUnlinkedConflictItem,
} from 'entities/Contacts/api/types'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { LinkContact } from './LinkContact'

import styles from './styles.module.scss'

const Integration = (integration: string) => {
  switch (integration) {
    case 'salesmsg-hubspot':
      return IntegrationKey.hubspot
    case 'salesmsg-infusionsoft':
      return IntegrationKey.infusionsoft
    case 'salesmsg-activecampaign':
      return IntegrationKey.activecampaign
    case 'salesmsg-pipedrive':
      return IntegrationKey.pipedrive
    case 'salesmsg-salesforce':
      return IntegrationKey.salesforce
  }

  return null
}

const ContactRowCell = ({ sourceJSON }: { sourceJSON: string }) => {
  const source: IResponseContactUnlinkedSource = JSON.parse(sourceJSON)

  const colors = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']
  const color = colors[+source.id % colors.length]

  const firstName = source.full_name?.split(' ')?.[0] || undefined
  const lastName = source.full_name?.split(' ')?.[1] || undefined

  const avatarInfo = {
    firstName,
    lastName,
    color,
    phone: source.formatted_number,
    image: undefined,
  }

  const name = source.full_name || source.email || source.formatted_number

  return (
    <Box
      sx={{
        alignItems: 'center',
        height: '32px',
      }}
      gap={1}
      display='flex'
    >
      <Avatar info={avatarInfo} size={24} />
      <Typography ellipsis variant={'body-md-regular'} tag={'div'} ariaLabel={'name'}>
        {name}
      </Typography>
    </Box>
  )
}

const PhoneRowCell = ({ sourceJSON }: { sourceJSON: string }) => {
  const source: IResponseContactUnlinkedSource = JSON.parse(sourceJSON)

  return (
    <Typography variant={'body-md-regular'} tag={'div'} ariaLabel={'formatted_number'}>
      {source.formatted_number}
    </Typography>
  )
}

const IntegrationRowCell = ({
  conflictItemsJSON,
  integration,
}: {
  conflictItemsJSON: string
  integration: string
}) => {
  const items: IResponseContactUnlinkedConflictItem[] = JSON.parse(conflictItemsJSON)

  const integrationIcon: IIconsVector | null = Integration(integration)

  return (
    <Box
      sx={{
        alignItems: 'center',
        height: '32px',
      }}
      gap={1}
      display='flex'
    >
      {integrationIcon && (
        <span className={styles.icon}>
          <Icon icon={integrationIcon} fontSize={11} />
        </span>
      )}
      <Typography variant={'body-md-regular'} tag={'div'} ariaLabel={'matching'}>
        {`${items.length} matching`}
      </Typography>
    </Box>
  )
}

const LinkRowCell = ({
  sourceJSON,
  conflictItemsJSON,
  integration,
}: {
  sourceJSON: string
  conflictItemsJSON: string
  integration: string
}) => {
  const contact: IResponseContactUnlinkedSource = JSON.parse(sourceJSON)
  const matchedContacts: IResponseContactUnlinkedConflictItem[] = JSON.parse(conflictItemsJSON)

  const contactIntegration: IntegrationKey | null = Integration(integration)

  return (
    <LinkContact
      contact={contact}
      matchedContacts={matchedContacts}
      integration={contactIntegration}
    />
  )
}

export const columns: IColumn<IResponseContactUnlinked>[] = [
  {
    field: 'name',
    name: 'Name',
    fullName: 'Name',
    renderRowCell: (row) => <ContactRowCell sourceJSON={row.source} />,
    maxWidth: 260,
  },
  {
    field: 'number',
    name: 'Number',
    renderRowCell: (row) => <PhoneRowCell sourceJSON={row.source} />,
    maxWidth: 210,
  },
  {
    field: 'integration',
    name: 'Integration',
    renderRowCell: (row) => (
      <IntegrationRowCell conflictItemsJSON={row.conflict_items} integration={row.direction} />
    ),
    maxWidth: 560,
  },
  {
    field: 'actions',
    name: ' ',
    renderRowCell: (row) => (
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '32px',
        }}
        gap={1}
        display='flex'
      >
        <LinkRowCell
          sourceJSON={row.source}
          conflictItemsJSON={row.conflict_items}
          integration={row.direction}
        />
      </Box>
    ),
  },
]
