import { makeAutoObservable, runInAction } from 'mobx'

export type NotificationType = 'error' | 'warning'

export interface INotification {
  id: string
  type: NotificationType
  message: string
  createdAt: number
}

export class KnowledgeBaseNotificationStore {
  private _notifications = new Map<string, INotification>()
  private _timers = new Map<string, number>()

  constructor() {
    makeAutoObservable(this)
  }

  addError = (message: string) => {
    this._addNotification('error', message)
  }

  addWarning = (message: string) => {
    this._addNotification('warning', message)
  }

  clearNotification = (id: string) => {
    this._notifications.delete(id)
    const timerId = this._timers.get(id)
    if (timerId) {
      clearTimeout(timerId)
      this._timers.delete(id)
    }
  }

  private _clearAllNotifications = () => {
    this._notifications.clear()
    for (const timerId of this._timers.values()) {
      window.clearTimeout(timerId)
    }
    this._timers.clear()
  }

  private _addNotification = (type: NotificationType, message: string) => {
    const id = `${Date.now()}-${Math.random()}`
    const createdAt = Date.now()

    const newNotification: INotification = {
      id,
      type,
      message,
      createdAt,
    }

    this._notifications.set(id, newNotification)

    const timerId = window.setTimeout(() => {
      runInAction(() => {
        this._notifications.delete(id)
        this._timers.delete(id)
      })
    }, 5000)

    this._timers.set(id, timerId)
  }

  get notifications(): INotification[] {
    return Array.from(this._notifications.values()).sort((a, b) => b.createdAt - a.createdAt)
  }

  dispose = () => {
    this._clearAllNotifications()
  }
}
