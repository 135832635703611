import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { ChatbotStatusBadge } from 'pages/chatbot/ui/ChatbotStatus'
import { ChatbotInvalidAlert } from 'pages/chatbot/ui'

import { useDetailsContext } from '../detailsContext'
import styles from './styles.module.scss'

export const DetailsHeader = observer(() => {
  const { data } = useDetailsContext()

  return (
    <header className={styles.detailsHeader}>
      <Typography ariaLabel='chatbotName' variant='heading-lg' ellipsis>
        {data.name}
      </Typography>
      <ChatbotStatusBadge status={data.status} />
      <ChatbotInvalidAlert data={data} isBottomActions className={styles.invalidAlertContainer} />
    </header>
  )
})
