// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TnPG_o7FFk3dk4MM42bd{width:24px;height:24px;border-radius:50%;display:grid;place-content:center}.TnPG_o7FFk3dk4MM42bd.xZb6XPT_L9toElJR8rjI{width:16px;height:16px}.TnPG_o7FFk3dk4MM42bd.ANgM2T3rcgfHOUMFuMYM{background:var(--background-brand-default)}", "",{"version":3,"sources":["webpack://./src/shared/ui/AutomatedAvatar/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,YAAA,CACA,oBAAA,CACA,2CACE,UAAA,CACA,WAAA,CAEF,2CACE,0CAAA","sourcesContent":[".wrap {\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  display: grid;\n  place-content: center;\n  &.small {\n    width: 16px;\n    height: 16px;\n  }\n  &.bg {\n    background: var(--background-brand-default);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "TnPG_o7FFk3dk4MM42bd",
	"small": "xZb6XPT_L9toElJR8rjI",
	"bg": "ANgM2T3rcgfHOUMFuMYM"
};
export default ___CSS_LOADER_EXPORT___;
