import { FC } from 'react'
import { Icon, Status, Tooltip, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import type { IStatusProps } from 'shared/ui/Status'
import type {
  IResponseBusinessProfile,
  IResponseBusinessProfileStatus,
  IResponseLocalNumbersStatus,
} from 'entities/Compliance/api/complianceType'
import styles from './styles.module.scss'

type IEstimatedResponseInfoProps = {
  status?: IResponseBusinessProfileStatus | IResponseLocalNumbersStatus | null
  submittedAt?: IResponseBusinessProfile['submitted_at'] | null
  iconData: IStatusProps | null
  dayCount: number
  labelPart: string
}

export const EstimatedResponseInfo: FC<IEstimatedResponseInfoProps> = ({
  status,
  submittedAt,
  iconData,
  dayCount,
  labelPart,
}) => {
  if (!iconData || !iconData.title) return null

  return (
    <div className={styles.statusLine}>
      <Status
        emphasis='high'
        intent={iconData.intent}
        title={iconData.title}
        icon={iconData.icon}
      />
      {(status === 'under_review' || status === 'in_carrier_review') && submittedAt && (
        <>
          <Typography
            ariaLabel='AfterRegisterModalContent_status'
            variant='body-xs-regular'
            color='var(--amber-80)'
            className={styles.statusText}
          >
            {`Estimated response: ${dayCount} business day`}
          </Typography>
          <Tooltip
            label={`Please allow ${dayCount} business day for carriers to review your ${labelPart}.`}
            desc={`Submitted on ${uiStore
              .dayjs(submittedAt)
              .format(DayjsFormats.fullWithAtAndYear)}`}
            type='description'
            placement='top'
            width={260}
          >
            <Icon icon='question' color='var(--content-neutral-primary)' fontSize={12} />
          </Tooltip>
        </>
      )}
    </div>
  )
}
