import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { IOptInMethodTypes } from 'entities/Compliance'
import type { TollFreeStepsStore } from 'pages/settings/pages/compliance/pages/tollFree/store/tollFreeStepsStore'

export class OptInMethodStore {
  constructor(private _tollFreeStepsStore: TollFreeStepsStore) {
    makeAutoObservable(this)
  }

  private _optInMethods: IOptInMethodTypes[] = []

  get optInMethods() {
    return this._optInMethods
  }

  get isNextDisabled() {
    return !this.optInMethods.length
  }

  nextStepClick = async () => {
    uiStore.changeRoute({
      path: this._tollFreeStepsStore.tollFreeNavigationStore.getNextUrl,
    })
  }

  setOptInMethods = (value: IOptInMethodTypes) => {
    this.optInMethods.includes(value)
      ? (this._optInMethods = this.optInMethods.filter((item) => item !== value))
      : (this._optInMethods = [...this.optInMethods, value])
  }

  isOptInMethodActive = (value: IOptInMethodTypes) => this.optInMethods.includes(value)
}
