import type { IResponseNumber } from 'entities/Phone/api/types'

export type IResponseProvider = {
  label: string
  value: string
}

export type IParamsOptInTypes = {
  filter: 'dlc' | 'toll-free'
}

export type IResponseCountryState = {
  id: string
  country_id: string
  name: string
}

export type IResponseTollFreeNumber = IResponseNumber & {
  toll_free_verification_errors: string[]
  individual_toll_free_profile: IResponseTollFree
}

export type IResponseTollFree = {
  id: number
  organization_id: number
  title: string
  monthly_volume: number
  use_case_description: string
  use_case_id: number
  sample_message_1: string
  sample_message_2: string | null
  completed: true
  created_at: string
  updated_at: string
  explanation_description: string | null
  use_case: {
    id: number
    title: string
    description: string
    sample_message_1: string
    sample_message_2: string
  }
  opt_in_methods: {
    id: number
    key: string
    title: string
    description: string | null
  }[]
  opt_in_data: {
    id: number
    opt_in_method_id: number
    description: string
    files: {
      id: number
      source_url: string
    }[]
  }[]
  is_parent: boolean
  numbers: IResponseTollFreeNumber[]
}

export type IResponseOrganizationTollFree = {
  data: IResponseTollFree[]
}

export type IResponseOrganizationTollFreeFormFields = {
  monthly_values: number[]
  use_cases: Array<{ id: number; title: string }>
}

// TenDlcVoiceIntegrity
export type IResponseCallingVoiceStatus =
  | 'under_review'
  | 'approved'
  | 'failed'
  | 'in_review'
  | 'pending'

export type IResponseVoiceIntegrityAdditionalInfo = {
  average_business_day_call_volume: number
  business_employee_count: number
  email: string
  end_user_sid: null
  id: number
  notes: string
  organization_id: number
  status: IResponseCallingVoiceStatus
  use_case: string
  voice_integrity_sid: null
}

export type IResponseVoiceIntegrity = {
  created_at: string
  id: number
  name: string | null
  organization_id: number
  service_type: string
  status: IResponseCallingVoiceStatus
  submitted_at: string | null
  twilio_service_errors: []
  updated_at: string
  vendor_id: number | null
}

export type IParamsUpdateVoiceIntegrity = {
  average_business_day_call_volume: string
  business_employee_count: string
  notes: string
  use_case: string
}

export type IResponseTenDlcVoiceIntegrity = {
  additionalInfo: IResponseVoiceIntegrityAdditionalInfo
  voiceIntegrity: IResponseVoiceIntegrity | null
  terms: string | null
}

// ShakenStir
export type IParamsUpdateShakenStirTerms = {
  terms_accepted: boolean
}

type IResponseShakenStir = {
  created_at: string
  id: number
  name: string | null
  organization_id: number
  service_type: string
  status: IResponseCallingVoiceStatus
  submitted_at: string | null
  twilio_service_errors: []
  updated_at: string
  vendor_id: number | null
}

export type IResponseOrganizationShakenStir = {
  shakenStir: IResponseShakenStir
  terms: {
    created_at: string
  } | null
}

export type IResponseComplianceSummary = {
  vetting_score: null
  approved_campaigns: null
  compliance_provider: null
  services_data: null
  ten_dlc_limits_reached: null
  ten_dlc_plan: null
  organization_id: number | null
  has_tax_id: boolean | null
  target_country: ITargetCountry | null
  numbers_type: INumberType | null
}

export type IResponseEinItem = {
  city: string
  ein: string
  ein_formatted: string
  name: string
  state: string
  street1: string
  street2: string
  zip: string
}

export type IResponseGetEinData = {
  data: IResponseEinItem[]
}

export type ICompanyLocation = 'US' | 'CA'

export type IIrsEinDocument = {
  business_information_id: number
  created_at: string
  extension: string
  id: number
  size: string
  source_url: string
  title: string
  updated_at: string
}

export type IResponseBusinessInformation = {
  business_name: string
  business_type_id: number | null
  business_type: {
    id: number
    name: string
  } | null
  industry: {
    id: number
    name: string
  }
  city: string
  country: ICompanyLocation
  organization_id: number
  industry_id: number
  social_media_url: string | null
  regions_of_operation: { id: number; name: string }[]
  reg_progress_status: string
  registration_number: string
  irs_ein_document: IIrsEinDocument | null
  registration_type_id: number
  state_id: string
  street_address_1: string
  street_address_2: string | null
  website_url: string | null
  zip_code: string
}

export interface IParamsBusinessInformation
  extends Omit<
    IResponseBusinessInformation,
    'regions_of_operation' | 'business_type' | 'industry'
  > {
  regions_of_operation: number[]
}

export type ITargetCountry = 'US' | 'CA' | 'ALL'
export type INumberType = 'toll_free' | 'local' | 'all'

export type IParamsSaveComplianceSummary = {
  target_country: ITargetCountry
  have_tax_ein: boolean
  numbers_type: INumberType
}

export type IParamsSetBpBusinessInformation = {
  business_information: IParamsBusinessInformation
  step: number
}

export type IParamsSetBpBusinessInformationLocation = {
  business_information: {
    country: ICompanyLocation
  }
  step: number
}

export type IResponseBusinessProfileStatus = 'under_review' | 'approved' | 'failed' | 'in_review'

export type IResponseLocalNumbersStatus =
  | 'in_progress'
  | 'pending_review'
  | 'in_carrier_review'
  | 'approved'
  | 'failed'

export type IResponseTwilioServiceError = {
  error_message: string
}

export type IResponseBusinessProfile = {
  status: IResponseBusinessProfileStatus
  twilio_service_errors: IResponseTwilioServiceError[]
  created_at: string
  submitted_at: string | null
}

export type IResponseBusinessLocation = null

export type IResponseBusinessTerms = {
  created_at: string
}

export type IResponseOrganizationBusinessProfile = {
  businessInformation: IResponseBusinessInformation | null
  businessLocation: IResponseBusinessLocation | null
  businessProfile: IResponseBusinessProfile | null
  pointsOfContact: IResponsePointOfContact[]
  terms: IResponseBusinessTerms | null
}

export type IResponsePointOfContact = {
  first_name: string
  last_name: string
  email: string
  business_title: string
  job_position_id: number
  phone_number: string
  contacts_confirmed: true
  company_profile_fields: {
    first_name: string
    last_name: string
    phone_number: string
  }
  job_position: {
    id: number
    name: string
  }
}

export type IParamsSetBpBusinessPointsOfContact = {
  contacts_confirmed: boolean
  points_of_contact: {
    business_title: string
    email: string
    first_name: string
    job_position_id: string
    last_name: string
    phone_number: string
  }[]
  step: number
}

export type IParamsSetBpBusinessTerms = {
  terms_accepted: boolean
  step: number
}

export type IResponseUseCase = {
  description: string
  useCase: string
  useCaseTitle: string
}

export type IResponseOnlineFormUrlCheck = {
  domain_check: boolean
  reachable: boolean
}

export type IOnlineFormUrlCheck = {
  onlineFormUrl: string
}

export type IParamsUpdateComplianceServicesCampaign = {
  step: number
  useCaseData?: IResponseUseCase
  vendorKey?: string
  campaignId?: number | null
  sampleMessagesData?: IResponseSampleMessagesData
  optInMethods?: string[]
  optInData?: IResponseOptInData
}

export type IParamsSetCampaignPackageData = {
  step: number
  campaignId: number
  planType: string
  businessType: string
  stockExchange?: string
  stockTickerSymbol?: string
  businessVerificationEmail?: string
}

export type IResponseSampleMessagesData = {
  sampleMessage1: string
  sampleMessage2: string
  includeAgeGatedContent: boolean
  includeEmbeddedLinks: boolean
  includePhoneNumbers: boolean
}

export enum IOptInMethodTypes {
  onlineForm = 'ONLINE_FORM',
  paperForm = 'PAPER_FORM',
  text = 'TEXT',
  qrCode = 'QR_CODE',
}

export type IOptInMethodNames = {
  title: string
  value: IOptInMethodTypes
}

export type IResponseOptInData = {
  optInMethod: IOptInMethodTypes
  optInImages: IResponseOptInUpload[]
  onlineFormUrl: string | null
  noPublishedOnlineFormUrl: boolean | null
}

export type IRegistrationError = {
  id: number
  message: string
  rejection_source: 'admin' | 'tcr' | 'twilio' | 'subscription_cancellation'
}

export type IResponseComplianceServicesCampaign = {
  id: number
  brand_id: number | null
  data: {
    useCaseData: IResponseUseCase | null
    sampleMessagesData: IResponseSampleMessagesData | null
    optInData: IResponseOptInData[] | null
    messageFlow: string | null
    registrationFormStep: number | null
  }
  registration_errors: IRegistrationError[]
  organization_id: number
  number_vendor_key: string
  status: IResponseLocalNumbersStatus
  create_at: string
  submitted_at: string | null
  updated_at: string
  vendor_id: null
}

export enum IPlaneTypes {
  LOW_VOLUME_STANDARD = 'LOW_VOLUME_STANDARD',
  STANDARD = 'STANDARD',
}

export enum IBusinessType {
  PUBLIC_PROFIT = 'PUBLIC_PROFIT',
  PRIVATE_PROFIT = 'PRIVATE_PROFIT',
}

export type IVettingType = {
  id: number
  organization_id: number
  brand_id: number
  tcr_id: string
  token: string | null
  score: number
  status: string
}

export type IResponseComplianceServicesBrand = {
  id: number
  organization_id: number
  vendor_id: string | null
  data: {
    firstName: string | null
    lastName: string | null
    displayName: string | null
    companyName: string | null
    phone: string | null
    street: string | null
    city: string | null
    state: string | null
    postalCode: string | null
    country: string | null
    email: string | null
    brandRelationship: string | null
    vertical: string | null
    mock: false | null
    ein: string | null
    einIssuingCountry: null
    entityType: IBusinessType | null
    website: string | null
    socialMediaProfile: null
    stockSymbol?: string | null
    stockExchange?: string | null
    businessContactEmail: null
  } | null
  business_email_verification?: {
    email: string
    status: string
  }
  plan_type: IPlaneTypes
  status: IResponseLocalNumbersStatus
  registration_errors: IRegistrationError[]
  compliance_provider_key: string
  create_at: string
  submitted_at: string | null
  vetting?: IVettingType
}

export type IResponseGetComplianceServicesList = {
  campaigns: IResponseComplianceServicesCampaign[]
  brand: IResponseComplianceServicesBrand | null
  vetting: []
}

export type IResponseComplianceServicesBrandPackage = {
  brand: IResponseComplianceServicesBrand
}

export type IParamsSubmitLocalNumberApplication = {
  ten_dlc_registration_submitted: boolean
  ten_dlc_plan: string
}

export type IParamsDeleteAttachment = {
  sourceUrl: string
}

export type IResponseOptInUpload = {
  sourceUrl: string
  name: string
  size: string
  uid: string
}

export type IResponseStockExchanges = {
  title: string
  value: string
}
