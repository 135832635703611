export const MAX_FILES = 5
export const MAX_FILE_SIZE = 10 * 1024 * 1024

export const UPLOAD_LIMIT_EXCEEDED = 'Upload limit exceeded. Only 5 files can be uploaded at once.'
export const STORAGE_IS_FULL = 'Your storage is full! Remove files to create more space.'
export const UPLOAD_SIZE_ERROR = 'Upload failed. Please upload a file under 10 MB.'
export const FORMAT_NOT_SUPPORTED =
  'The file format isn’t supported for upload. Please use one of these formats: PDF, MD, TXT, DOCX, HTML, CSV, XLS, XLSX.'
export const UPLOAD_FAILED = 'The file couldn’t be uploaded. Please try again.'
export const INDEXATION_FAILED =
  'Something went wrong while processing the file. Please try again later.'
export const INDEXATION_RETRY =
  'One or more documents failed to process. You can retry processing them or re-upload the files.'
