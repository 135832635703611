import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SwitchAction } from 'shared/ui/SwitchAction'
import { CircularProgress, DividerCustom, Switch } from 'shared/ui'
import { IKeyword } from 'entities/Keywords'
import { IResponseKeyword } from 'entities/Keywords/api/type'
import styles from './styles.module.scss'

type IKeywordActionProps = {
  keyword: IKeyword
  toggleKeywordStatus: (trigger: IKeyword) => Promise<IResponseKeyword>
}

export const KeywordSwitchAction = observer(
  ({ keyword, toggleKeywordStatus }: IKeywordActionProps) => (
    <>
      <SwitchAction
        active={keyword.status === 'active'}
        onTrigger={() =>
          toggleKeywordStatus(keyword).then((updatedKeyword) => keyword.syncOrigin(updatedKeyword))
        }
        ariaLabel='Keyword'
      />
      <DividerCustom variant={'dropdown'} />
    </>
  )
)

export const KeywordSwitch = observer(({ keyword, toggleKeywordStatus }: IKeywordActionProps) => {
  const [isActiveUpdating, setIsActiveUpdating] = useState(false)

  const handleToggleKeywordStatus = () => {
    setIsActiveUpdating(true)
    toggleKeywordStatus(keyword)
      .then((updatedKeyword) => keyword.syncOrigin(updatedKeyword))
      .finally(() => setIsActiveUpdating(false))
  }

  return (
    <div className={styles.switchWrap}>
      {isActiveUpdating ? (
        <CircularProgress
          size={20}
          variant='indeterminate'
          customColor='var(--light-blue-70)'
          thickness={3}
        />
      ) : (
        <Switch
          className={styles.activeActionSwitch}
          onChange={handleToggleKeywordStatus}
          size='middle'
          value={keyword.status === 'active'}
          disabled={keyword.isInvalid}
        />
      )}
    </div>
  )
})
