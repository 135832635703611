import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'

const BusinessProfileLayoutContent = observer(() => {
  return (
    <>
      <Outlet />
    </>
  )
})

export const BusinessProfileLayout = () => {
  return <BusinessProfileLayoutContent />
}
