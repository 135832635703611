import { ReactNode, useState } from 'react'
import classNames from 'classnames'
import { Error } from 'shared/ui/Error'
import { Warning } from 'shared/ui/Warning'
import {
  type ITextFieldProps,
  makeTextFieldAutoFocus,
  makeTextFieldHighlightLimit,
  TextField,
} from 'shared/ui/TextField'

import styles from './styles.module.scss'

export type ITextInputProps = {
  className?: string
  size?: 'small' | 'lg-medium' | 'medium' | 'large'
  placeholder: string
  value: string
  setValue: (value: string) => void
  error?: ReactNode
  setError?: (error: string) => void
  isAutoFocus?: boolean
  limit?: number
  onFocus?: () => void
} & Omit<ITextFieldProps, 'size'>

export const TextInput = ({
  className,
  size = 'medium',
  value,
  setValue,
  placeholder,
  error,
  setError,
  isAutoFocus,
  limit,
  onFocus,
  ...props
}: ITextInputProps) => {
  const [limitReached, setLimitReached] = useState(false)
  const handleTextFieldFocus = () => {
    onFocus?.()
    setError?.('')
  }

  return (
    <div className={classNames(styles.input, styles[size], className)}>
      <TextField
        variant='integrated'
        value={value}
        onChange={setValue}
        InputProps={{ placeholder, onFocus: handleTextFieldFocus }}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: isAutoFocus }),
          makeTextFieldHighlightLimit({
            limit: limit ?? Number.POSITIVE_INFINITY,
            onChange: setLimitReached,
          }),
        ]}
        {...props}
      />
      {!!error && <Error type={'text'} error={error} />}
      {limit != null && limitReached && <Warning text={`${limit}-character limit reached`} />}
    </div>
  )
}
