import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import { IQuickLinkTemplate } from 'entities/QuickLink'
import { useQuickLinksContext } from 'pages/settings/pages/quickLinks/context/quickLinksContext'

import styles from './styles.module.scss'

export interface ICreateQuickLinkButtonProps {
  onCreate: (quickLink: IQuickLinkTemplate) => void
}

export const CreateQuickLinkButton = observer(({ onCreate }: ICreateQuickLinkButtonProps) => {
  const [isCreating, setCreating] = useState(false)
  const { operationStore } = useQuickLinksContext()

  const handleCreate = () => {
    setCreating(true)
    operationStore.create().then((link) => {
      if (link) onCreate(link)
      setCreating(false)
    })
  }

  return (
    <Button
      text='Add link'
      size='medium'
      loading={isCreating}
      iconProps={{ icon: 'add' }}
      onClick={handleCreate}
    />
  )
})

export const GuideQuickLinkButton = observer(() => (
  <Button
    className={styles.guideButton}
    tag={'a'}
    href={links.quickLinksGuide}
    text='Help guide'
    target='_blank'
    rel='noreferrer'
    size='medium'
    contained='secondary'
    iconProps={{ icon: 'info' }}
  />
))
