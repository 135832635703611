import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { Call } from '@twilio/voice-sdk'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { links } from 'shared/constants/links'
import { CallContact } from 'entities/Call/model/CallContact'
import { Contact } from 'entities/Contacts/model/Contact'
import { conversationStore } from 'entities/Conversation'
import { callStore } from 'entities/Call'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { eventLogStore } from 'entities/EventLog'
import { billingStore } from 'entities/Billing'
import { contactsStore } from 'entities/Contacts'
import { Phone } from 'entities/Phone/model/Phone'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { powerDialerApi } from 'entities/PowerDialer/api/powerDialer'
import { ContactFinalStatus, PowerDialerStatus } from 'entities/PowerDialer/api/types'
import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'
import type { ICallPopUpPowerDialerStoreV2InitData } from 'widgets/CallPopUp/store/types'
import { CallPopUpPowerDialerSessionStoreV2 } from 'widgets/CallPopUp/store/callPopUpPowerDialerSessionStoreV2'

const CALL_DELAY_DEFAULT = 5_000
const KEEP_ALIVE_INTERVAL = 10_000
const CONTACTS_PER_PAGE = 10

export class CallPopUpPowerDialerStoreV2 {
  private _contactsMap: Map<number, CallContact> = new Map()
  private _allContactsIds: number[] = []
  private _contactsPage = 1
  private _currentContact: CallContact | null = null

  private _callFromNumber: Phone | null = null
  private _callFromTeam: Inbox | null = null
  private _powerDialer: PowerDialer | null = null

  private _modalStopId = ''
  private _saving = false
  private _isPause = false
  private _isCallStarted = false
  private _callProcessing = false
  private _contactsFinalizing = new Set<number>()
  private _removePowerDialer: (teamId: number) => void = () => {}

  private _buyCreditsModalStore = new BuyCreditsModalStore()
  private _powerDialerSessionStore = new CallPopUpPowerDialerSessionStoreV2()

  private _callDelayTimer = CALL_DELAY_DEFAULT
  private _timerInterval: ReturnType<typeof setInterval> | null = null
  private _keepAliveInterval: ReturnType<typeof setInterval> | null = null

  private _disposeCallStatus: IReactionDisposer | null = null
  private _disposePause: IReactionDisposer | null = null
  private _disposeItems: IReactionDisposer | null = null
  private _disposeDisabledCall: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this._reactionCallStatus()
    this._reactionPause()
    this._reactionContacts()
    this._reactionBusyCall()
  }

  setPowerDialerInitData = async (options: ICallPopUpPowerDialerStoreV2InitData) => {
    const { number, team, powerDialer, removePowerDialer } = options
    this._callFromNumber = number
    this._callFromTeam = team
    this._powerDialer = powerDialer
    this._removePowerDialer = removePowerDialer
    this._startKeepAlive()

    this._sessionCacheStore.setPowerDialerId(options.powerDialer.id)
    this._allContactsIds = [...powerDialer.contactsIdUnprocessed]
    this._contactsPage = 1

    await this._fetchNextContacts()
  }

  private async _fetchNextContacts() {
    const from = (this._contactsPage - 1) * CONTACTS_PER_PAGE
    const to = this._contactsPage * CONTACTS_PER_PAGE

    if (from >= this._allContactsIds.length) {
      return
    }

    const nextChunk = this._allContactsIds.slice(from, to)

    try {
      const contacts = await contactsStore.getByIds(nextChunk)
      if (contacts) {
        this._addContacts(contacts)
      }
      this._contactsPage++
    } catch (e) {
      console.error(e)
    }
  }

  private _startKeepAlive = () => {
    this._stopKeepAlive()
    if (!this._powerDialer) return

    this._keepAlive()
    this._keepAliveInterval = setInterval(() => {
      this._keepAlive()
    }, KEEP_ALIVE_INTERVAL)
  }

  private _stopKeepAlive() {
    if (!this._keepAliveInterval) return

    clearInterval(this._keepAliveInterval)
    this._keepAliveInterval = null
  }

  private _keepAlive = async () => {
    if (!this._powerDialer) return

    try {
      await powerDialerApi.keepPowerDialerAlive(this._powerDialer.id)
    } catch (e) {
      console.error()
    }
  }

  private _addContacts = (items: Contact[]) => {
    items.forEach((item) => {
      this._addContact(item)
    })

    if (this.contacts.length) {
      this._currentContact = this.contacts[0]
    }
  }

  private _addContact = (contact: Contact) => {
    const item = new CallContact(
      {
        contact: {
          id: contact.id,
          first_name: contact.first_name,
          last_name: contact.last_name,
          full_name: contact.full_name,
          color: contact.color,
          formatted_number: contact.formatted_number || '',
          number: contact.number || '',
        },
        call: {
          sid: '',
        },
      },
      false
    )

    this._contactsMap.set(item.id, item)
  }

  private _deleteContact = (id: number) => {
    this._contactsMap.delete(id)
  }

  private _updatePowerDialerStatus = async (status: PowerDialerStatus) => {
    if (!this._powerDialer) return
    try {
      return await powerDialerApi.changePowerDialerStatus(this._powerDialer.id, status)
    } catch (e) {
      console.log(e)
    }
  }

  private _resolveContactStatus = async (contactId: number, status: ContactFinalStatus) => {
    if (!this._powerDialer) return
    this._contactsFinalizing.add(contactId)
    try {
      return await powerDialerApi.resolveContactInPowerDialer(
        this._powerDialer.id,
        contactId,
        status
      )
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._contactsFinalizing.delete(contactId)
      })
    }
  }

  handleSaveAndExit = async () => {
    runInAction(() => {
      this._saving = true
    })
    try {
      await this._updatePowerDialerStatus(PowerDialerStatus.Paused)
      this._resetPowerDialer()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._saving = false
      })
    }
  }

  handleSkipCurrentContact = async () => {
    if (!this._currentContact) return

    this.clearCallDelayTimer()
    this._resolveContactStatus(this._currentContact.id, 'skipped')
    this._deleteContact(this._currentContact.id)

    if (this.hasContactsToCall) {
      this._currentContact = this.contacts[0]
    }
  }

  handleSkipContact = async (item: CallContact) => {
    this._resolveContactStatus(item.id, 'skipped')
    this._deleteContact(item.id)
  }

  handleCall = async () => {
    if (!this._currentContact) return
    if (!this._callFromTeam) return
    if (!this._callFromNumber) return
    if (!this._powerDialer) return
    if (this.disabled) return

    try {
      if (this.handleCheckCredits()) {
        this.setPause(true)

        return
      }

      this.clearCallDelayTimer()

      runInAction(() => {
        this._callProcessing = true
      })

      callStore.setStartedCall(true)
      const isAllowed = await callStore.checkMicrophone()

      if (!isAllowed) {
        callStore.setStartedCall(false)
        this.setPause(true)

        toastStore.add({
          type: 'error',
          title: 'Microphone access required',
          desc: 'To enable calling, please allow Salesmsg to access your microphone.',
          action: {
            link: links.enableMicrophone,
            text: 'Learn more',
          },
        })

        return
      }

      const item = this._currentContact
      const currentContactId = item.id
      const conversation = await conversationStore.createConversation({
        contact_id: item.id,
        team_id: this._callFromTeam.id,
        number_id: this._callFromNumber.id,
      })

      if (conversation) {
        this._isCallStarted = true
        await callStore.connectTwilio(conversation.id, { powerDialerId: this._powerDialer.id })
        this._resolveContactStatus(currentContactId, 'contacted')

        runInAction(() => {
          if (item) {
            this._deleteContact(item.id)
          }
        })
      }
    } catch (e) {
      console.log(e)

      this.restartCallDelayTimer()
    } finally {
      runInAction(() => {
        this._callProcessing = false
      })
    }
  }

  togglePowerDialerPause = () => {
    this.setPause(!this._isPause)

    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: this._isPause ? 'paused' : 'resumed',
      },
      { groupId: organizationStore.id }
    )
  }

  handlePowerDialerStop = () => {
    this._modalStopId = nanoid()

    this.clearCallDelayTimer()

    modalStore.addModal({
      id: this._modalStopId,
      type: ModalTypeList.ALERT,
      title: 'Are you sure?',
      desc: "If stopped you won't be able to continue the session. Pause to resume later from the Power Dialer campaigns page.",
      primaryAction: {
        text: 'Stop anyway',
        onAction: async () => {
          eventLogStore.logEvent(
            'Power Dialer Used',
            {
              event_id: 'power_dialer_used',
              action: 'stopped',
            },
            { groupId: organizationStore.id }
          )

          await this._updatePowerDialerStatus(PowerDialerStatus.Finished)

          modalStore.removeModal(this._modalStopId)
          this._resetPowerDialer()
        },
      },
      additionalSecondaryAction: {
        text: 'Pause and save',
        onAction: async () => {
          await this._updatePowerDialerStatus(PowerDialerStatus.Paused)

          modalStore.removeModal(this._modalStopId)
          this._resetPowerDialer()
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          this.startCallDelayTimer()
          modalStore.closeModal(this._modalStopId)
        },
      },
      zIndex: 5001,
    })
  }

  handleCheckCredits = () => {
    const trialCredits = organizationStore.trialCredits
    const accountCredits = organizationStore.accountCredits
    const isTrial = subscriptionStore.isTrial

    if (isTrial && trialCredits <= 0) {
      toastStore.add({
        title: 'Your organization is out of message credits',
        type: 'error',
      })

      return true
    }

    if (!isTrial && accountCredits <= 0) {
      if (billingStore.autorecharge?.is_active) return false

      this._buyCreditsModalStore.openModal()

      return true
    }

    return false
  }

  setPause = (value: boolean) => {
    this._isPause = value
  }

  private _reactionCallStatus = () => {
    this._disposeCallStatus?.()
    this._disposeCallStatus = reaction(
      () => callStore.status,
      (status) => {
        if (status !== Call.State.Closed) {
          if (this._isCallStarted) {
            this._currentContact = null
          } else {
            this.setPause(true)
          }
        }

        if (status === Call.State.Closed) {
          if (!this._isPause && this.hasContactsToCall) {
            this._currentContact = this.contacts[0]
          }

          this._isCallStarted = false
        }
      }
    )
  }

  private _reactionPause = () => {
    this._disposePause?.()
    this._disposePause = reaction(
      () => this.isPause,
      (value) => {
        if (value) {
          this._currentContact = null
        } else if (this.hasContactsToCall) {
          this._currentContact = this.contacts[0]
        }
      }
    )
  }

  private _reactionContacts = () => {
    this._disposeItems?.()
    this._disposeItems = reaction(
      () => this.contactsCount,
      (contactsCount) => {
        if (!contactsCount) {
          this._updatePowerDialerStatus(PowerDialerStatus.Finished)
          this._resetPowerDialer()
        }

        if (this.isPause) return

        this._currentContact = contactsCount ? this.contacts[0] : null
      }
    )
  }

  private _reactionBusyCall = () => {
    this._disposeDisabledCall?.()
    this._disposeDisabledCall = reaction(
      () => callStore.isBusy,
      (value) => {
        if (value) {
          this.clearCallDelayTimer()
        }
      }
    )
  }

  private startCallDelayTimer = () => {
    this._timerInterval = setInterval(() => {
      runInAction(() => {
        this._callDelayTimer -= 1000

        if (this._callDelayTimer === 0) {
          this.clearCallDelayTimer()
          this.handleCall()
        }
      })
    }, 1000)
  }

  restartCallDelayTimer = () => {
    this._callDelayTimer = CALL_DELAY_DEFAULT
    this.clearCallDelayTimer()
    this.startCallDelayTimer()
  }

  clearCallDelayTimer = () => {
    if (!this._timerInterval) return

    clearInterval(this._timerInterval)
  }

  private get _sessionCacheStore() {
    return this._powerDialerSessionStore
  }

  get currentContact() {
    return this._currentContact
  }

  get currentPowerDialerName() {
    return this._powerDialer?.name || 'Power Dialer'
  }

  get contacts() {
    return Array.from(this._contactsMap.values())
  }

  get contactsCount() {
    return this._contactsMap.size
  }

  get callDelayTimer() {
    return dayjs(this._callDelayTimer).format('s')
  }

  get isPause() {
    return this._isPause
  }

  get saving() {
    return this._saving
  }

  get contactsFinalizing() {
    return this._contactsFinalizing
  }

  get hasContactsToCall() {
    return Boolean(this._contactsMap.size)
  }

  get disabled() {
    return this._callProcessing || callStore.isBusy
  }

  private _resetPowerDialer = () => {
    const teamId = this._callFromTeam?.id
    this._stopKeepAlive()
    this._disposeCallStatus?.()
    this._disposePause?.()
    this._disposeItems?.()
    this._disposeDisabledCall?.()
    this._currentContact = null
    this._callFromNumber = null
    this._callFromTeam = null
    this._powerDialer = null
    this._contactsMap.clear()
    this._sessionCacheStore.reset()
    this.clearCallDelayTimer()

    if (teamId) {
      this._removePowerDialer(teamId)
    }
  }
}
