import { createContext, useContext } from 'react'
import type { IKeyword } from 'entities/Keywords'

export const KeywordDetailsModalContext = createContext<IKeyword | null>(null)

export const useKeywordDetailsModalContext = () => {
  const context = useContext(KeywordDetailsModalContext)

  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with KeywordDetailsModalContext'
    )
  }

  return context
}
