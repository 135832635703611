import { createContext, useContext } from 'react'
import { type MenuOutboundLayoutStore } from '../store/menuOutboundLayoutStore'

export const MenuOutboundLayoutContext = createContext<MenuOutboundLayoutStore | null>(null)

export const useMenuOutboundLayoutContext = () => {
  const context = useContext(MenuOutboundLayoutContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with MenuOutboundLayoutContext')

  return context
}
