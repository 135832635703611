import { Navigate, Route, Routes, useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'
import { subscriptionStore } from 'entities/Subscription'
import { SettingsLayout } from '../layout/SettingsLayout'
import { SettingsRoutesEnum } from './type'

export const SettingsRoutesContent = observer(() => {
  const { isCanceled } = subscriptionStore
  const { pathname } = useLocation()

  if (isCanceled && pathname === '/settings/billing/overview') {
    return <Navigate to='/settings/personal/settings/profile' />
  }

  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route index element={<div>Settings Home</div>} />
        <Route path={SettingsRoutesEnum.compliance} element={<div>Compliance content</div>} />
        <Route path='*' element={<div>Page Not Found</div>} />
      </Route>
    </Routes>
  )
})
