import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import {
  IQuickLinkTemplate,
  QuickLinkPayload,
  QuickLinkApi,
  QuickLinkTemplate,
} from 'entities/QuickLink'
import { QuickLinkModal } from 'pages/settings/pages/quickLinks/ui/QuickLinkModal'

import { DIALOG_LEVEL } from './constants'

export class QuickLinkOperationStore {
  private _createQuickLinkModalId = 'createQuickLinkTemplateModalId'
  private _editQuickLinkModalId = 'editQuickLinkTemplateModalId'
  private _deleteQuickLinkModalId = 'deleteQuickLinkTemplateModalId'

  constructor() {
    makeAutoObservable(this)
  }

  create = (): Promise<IQuickLinkTemplate | null> =>
    new Promise((resolve) => {
      const payload = new QuickLinkPayload()

      const onClose = () => {
        modalStore.removeModal(this._createQuickLinkModalId)
        resolve(null)
      }

      const onComplete = async () => {
        const { data } = await QuickLinkApi.createLinkTemplate(payload.toJSON())

        modalStore.removeModal(this._createQuickLinkModalId)

        return resolve(new QuickLinkTemplate(data))
      }

      modalStore.addModal({
        id: this._createQuickLinkModalId,
        title: 'Add link',
        pureContent: true,
        ModalContent: QuickLinkModal,
        ModalContentProps: { completeText: 'Add', payload, onComplete, onClose },
        width: 480,
        zIndex: DIALOG_LEVEL,
        disabledOverlayClose: true,
        onClose,
      })
    })

  edit = (template: IQuickLinkTemplate): Promise<IQuickLinkTemplate | null> =>
    new Promise((resolve) => {
      const payload = template.payload

      const onClose = () => {
        modalStore.removeModal(this._editQuickLinkModalId)
        resolve(null)
      }

      const onComplete = async () => {
        const { data } = await QuickLinkApi.updateLinkTemplate(template.id, payload.toJSON())
        template.syncOrigin(data)

        modalStore.removeModal(this._editQuickLinkModalId)

        return resolve(template)
      }

      modalStore.addModal({
        id: this._editQuickLinkModalId,
        title: 'Edit link',
        pureContent: true,
        ModalContent: QuickLinkModal,
        ModalContentProps: { completeText: 'Update', payload, onComplete, onClose },
        width: 480,
        zIndex: DIALOG_LEVEL,
        disabledOverlayClose: true,
        onClose,
      })
    })

  delete = (id: number): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      const handleDelete = () => {
        modalStore.removeModal(this._deleteQuickLinkModalId)
        QuickLinkApi.deleteLinkTemplate(id).then(
          () => resolve(true),
          () => resolve(false)
        )
      }

      const handleCancel = () => {
        modalStore.removeModal(this._deleteQuickLinkModalId)
        resolve(false)
      }

      modalStore.addModal({
        id: this._deleteQuickLinkModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: DIALOG_LEVEL,
        width: 280,
        type: ModalTypeList.ALERT,
        title: 'Delete link?',
        desc: 'This action cannot be undone',
        primaryAction: {
          text: 'Delete',
          onAction: handleDelete,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })

  dispose = () => {
    modalStore.removeModal(this._deleteQuickLinkModalId)
    modalStore.removeModal(this._editQuickLinkModalId)
    modalStore.removeModal(this._createQuickLinkModalId)
  }
}
