// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nfcHUbZz9u1dAWKV2Q_4{display:flex;flex-direction:column;gap:4px}.RlKTmzNzEwSQp25LZon2{display:flex;align-items:center;gap:4px}.YsWWs0lGKk1K65_g__oW{display:flex;flex-direction:column;gap:2px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".header {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.subtitle {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.tooltipContent {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "nfcHUbZz9u1dAWKV2Q_4",
	"subtitle": "RlKTmzNzEwSQp25LZon2",
	"tooltipContent": "YsWWs0lGKk1K65_g__oW"
};
export default ___CSS_LOADER_EXPORT___;
