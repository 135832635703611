import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Dropdown, Icon, IDropdownItem, SpinnerLoader, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { useSendFromDropdownContext } from 'widgets/SendFromDropdown/context/sendFromDropdownContext'
import { MultiSelectCardInfo } from './MultiSelectCardInfo'
import styles from './styles.module.scss'

type ISendFromMultiTriggerProps = {
  limit?: number
}
export const SendFromMultiTrigger = observer(({ limit = 3 }: ISendFromMultiTriggerProps) => {
  const {
    loading,
    itemsCount,
    multiDropdownStore: { multiSelectedValues },
    iconField,
  } = useSendFromDropdownContext()

  const restNumsCount = multiSelectedValues.length - limit
  const limitedNums = multiSelectedValues.slice(0, limit)
  const showNumsRestCount = restNumsCount > 0

  const hidedItems = multiSelectedValues.slice(limit).reduce((acc, item) => {
    const updatedItem: IDropdownItem = {
      id: String(item?.id),
      label: `${item.name}:  ${item.formatted_number}`,
      iconL: item.type === 'Short Code' ? 'hash2' : 'inboxOpen',
      data: item,
      iconR: item.is_aircall ? 'aircall' : undefined,
    }
    acc.push(updatedItem)

    return acc
  }, [] as IDropdownItem[])

  const content = () => {
    if (loading) {
      return (
        <div className={styles.loaderTrigger}>
          <SpinnerLoader size={10} indeterminatecolor={'var(--content-primary-tertiary)'} />
        </div>
      )
    }

    if (!itemsCount) {
      return (
        <Typography
          className={classnames(styles.triggerLabel)}
          variant={'text-input-field'}
          ariaLabel={'No results found'}
        >
          No results found
        </Typography>
      )
    }

    if (!multiSelectedValues.length) {
      return (
        <Typography
          className={classnames(styles.triggerLabel, styles.triggerLabel__placeholder)}
          variant={'text-input-field'}
          ariaLabel={'Select phone number'}
        >
          Select phone number
        </Typography>
      )
    }

    return (
      <div className={styles.triggerContainer}>
        {limitedNums.map((item) => (
          <div
            className={styles.triggerItem}
            key={item.id}
            aria-label={getAriaLabel('MultiSelectTrigger', 'selectedItem')}
          >
            <MultiSelectCardInfo selectedItem={item} />
          </div>
        ))}
        {showNumsRestCount && (
          <Dropdown
            ariaLabel={'ItemsWithCount'}
            triggerComponent={() => <div className={styles.remainderCount}>+{restNumsCount}</div>}
            items={hidedItems}
            placement='bottom-end'
          />
        )}
      </div>
    )
  }

  return (
    <div className={styles.trigger}>
      {!multiSelectedValues.length && (
        <div className={styles.icon}>
          <Icon icon={iconField} fontSize={16} />
        </div>
      )}
      {content()}
    </div>
  )
})
