import { observer } from 'mobx-react-lite'
import { ReactNode, FC } from 'react'
import { AlertBanner } from 'shared/ui/AlertBanner'
import { bannerStore } from 'shared/ui/Banner/store/bannerStore'

type IBannerProps = {
  globalBanners: ReactNode
}

export const Banner: FC<IBannerProps> = observer(({ globalBanners }) => {
  const { items } = bannerStore
  return (
    <div>
      {items.map((item) => (
        <AlertBanner key={item.id} {...item.alert} actionPrimary={item.action} />
      ))}
      {globalBanners}
    </div>
  )
})
