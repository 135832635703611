// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gbyHPX_ETdOLI3CEo9Nc{position:relative;display:inline-flex}", "",{"version":3,"sources":["webpack://./src/shared/ui/Icon/CircularProgress/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,mBAAA","sourcesContent":[".wrap {\n  position: relative;\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "gbyHPX_ETdOLI3CEo9Nc"
};
export default ___CSS_LOADER_EXPORT___;
