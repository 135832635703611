import { useState, FC, ReactNode } from 'react'
import classnames from 'classnames'
import { Typography } from 'shared/ui/Typography'
import { Icon } from 'shared/ui/Icon'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type ToggleBlockProps = {
  isOpen?: boolean
  title: ReactNode
  borders?: {
    left?: boolean
    right?: boolean
    top?: boolean
    bottom?: boolean
    all?: boolean
  }
  isDragging?: boolean
  hideBottomMargin?: boolean
  margin?: string
  padding?: string
  children: ReactNode
  titleCount?: number
  showCount?: boolean
  ariaLabel?: string
  onToggle?: (status: boolean) => void
  disabled?: boolean
  hideDraggingIcon?: boolean
}

export const ToggleBlock: FC<ToggleBlockProps> = ({
  borders,
  isDragging,
  title,
  titleCount,
  margin,
  padding,
  children,
  hideBottomMargin,
  showCount,
  ariaLabel,
  onToggle,
  isOpen: isOpenProps = false,
  disabled,
  hideDraggingIcon,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenProps)

  const handleToggle = () => {
    if (disabled) return

    onToggle && onToggle(!isOpen)
    setIsOpen(!isOpen)
  }

  const styleBody = {
    ...(margin && isOpen ? { margin } : null),
    ...(padding && isOpen ? { padding } : null),
  }

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.borderLeft]: borders?.left,
        [styles.borderRight]: borders?.right,
        [styles.borderTop]: borders?.top,
        [styles.borderBottom]: borders?.bottom,
        [styles.borderAll]: borders?.all,
        [styles.dragging]: isDragging,
        [styles.disabled]: disabled,
      })}
    >
      <div
        className={classnames(styles.header, {
          [styles.open]: isOpen,
        })}
        onClick={handleToggle}
        aria-label={
          ariaLabel ? getAriaLabel(`${ariaLabel}_ToggleContainer`) : getAriaLabel('ToggleContainer')
        }
      >
        <Typography
          variant='body-md-medium'
          ariaLabel={
            ariaLabel ? getAriaLabel(`${ariaLabel}_ToggleBlock`) : getAriaLabel('ToggleBlock')
          }
          tag='div'
        >
          <span className={styles.title}>
            <div
              className={classnames(styles.iconDotsDrag, {
                [styles.hideDraggingIcon]: hideDraggingIcon,
              })}
            >
              <Icon icon={'dotsDrag'} fontSize={12} />
            </div>
            {title}
          </span>
          {showCount && <span className={styles.count}> {titleCount}</span>}
        </Typography>
        <div className={styles.icon}>
          <Icon icon='chevronDown' />
        </div>
      </div>
      {isOpen && (
        <div
          className={classnames(styles.body, {
            [styles.hideBottomMargin]: hideBottomMargin,
          })}
          style={styleBody}
        >
          {children}
        </div>
      )}
    </div>
  )
}
