import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { TextFieldPlaceholder } from 'shared/ui/TextField'
import styles from './styles.module.scss'

export type IEnrollmentSearchTriggerProps = {
  open?: boolean
}

export const EnrollmentSearchTrigger: FC<IEnrollmentSearchTriggerProps> = observer(() => {
  return (
    <TextFieldPlaceholder
      variant='stroke'
      size='small'
      placeholder={<div className={styles.placeholder}>{'Search tag, segment or contact'}</div>}
    />
  )
})
