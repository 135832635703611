export type ISampleMessageExamplesType = {
  [key: string]: {
    defaultMessage: string
    includeLinksMessage: string
    includePhoneNumbersMessage: string
    fullMessage: string
  }
}

export const sampleMessageExamples: ISampleMessageExamplesType = {
  CUSTOMER_CARE: {
    defaultMessage:
      'Example Inc: Your service appointment is confirmed for tomorrow, March 1st, between 2-4PM. Reply C to confirm or R to reschedule. Reply STOP to opt out.',
    includeLinksMessage:
      'Example Inc: Your service appointment is confirmed for tomorrow, March 1st, between 2-4PM. Visit https://example.com/appointments for details. Reply C to confirm or R to reschedule. Reply STOP to opt out.',
    includePhoneNumbersMessage:
      'Example Inc: Your service appointment is confirmed for tomorrow, March 1st, between 2-4PM. Call us at (800) 555-1234 for assistance. Reply C to confirm or R to reschedule. Reply STOP to opt out',
    fullMessage:
      'Example Inc: Your service appointment is confirmed for tomorrow, March 1st, between 2-4PM. Visit https://example.com/appointments for details or call us at (800) 555-1234 for assistance. Reply C to confirm or R to reschedule. Reply STOP to opt out.',
  },
  MARKETING: {
    defaultMessage:
      'Example Inc: Flash Sale! 40% off all summer items for the next 48 hours. Use code SUMMER40 at checkout. Reply STOP to unsubscribe.',
    includeLinksMessage:
      'Example Inc: Flash Sale! 40% off all summer items for the next 48 hours. Shop now at https://example.com/summer-sale. Use code SUMMER40 at checkout. Reply STOP to unsubscribe.',
    includePhoneNumbersMessage:
      'Example Inc: Flash Sale! 40% off all summer items for the next 48 hours. Call (800) 555-1234 to order by phone. Use code SUMMER40 at checkout. Reply STOP to unsubscribe.',
    fullMessage:
      'Example Inc: Flash Sale! 40% off all summer items for the next 48 hours. Shop now at https://example.com/summer-sale or call (800) 555-1234 to order by phone. Use code SUMMER40 at checkout. Reply STOP to unsubscribe.',
  },
  MIXED: {
    defaultMessage:
      "Example Inc: Your order #5678 has shipped! Don't forget our Buy One Get One 50% off sale ends tomorrow! Reply STOP to opt out.",
    includeLinksMessage:
      "Example Inc: Your order #5678 has shipped! Track it here: https://ex.co/track5678. Don't forget our Buy One Get One 50% off sale ends tomorrow! Reply STOP to opt out.",
    includePhoneNumbersMessage:
      "Example Inc: Your order #5678 has shipped! Call (800) 555-1234 for assistance. Don't forget our Buy One Get One 50% off sale ends tomorrow! Reply STOP to opt out.",
    fullMessage:
      "Example Inc: Your order #5678 has shipped! Track it here: https://ex.co/track5678 or call (800) 555-1234 for assistance. Don't forget our Buy One Get One 50% off sale ends tomorrow! Reply STOP to opt out.",
  },
  HIGHER_EDUCATION: {
    defaultMessage:
      'Example Inc University: Registration for Spring 2025 courses begins March 15th at 9AM. Reply STOP to opt out.',
    includeLinksMessage:
      'Example Inc University: Registration for Spring 2025 courses begins March 15th at 9AM. Visit https://exu.edu/register for course listings. Reply STOP to opt out.',
    includePhoneNumbersMessage:
      'Example Inc University: Registration for Spring 2025 courses begins March 15th at 9AM. Call (800) 555-1234 for advising assistance. Reply STOP to opt out.',
    fullMessage:
      'Example Inc University: Registration for Spring 2025 courses begins March 15th at 9AM. Visit https://exu.edu/register for course listings or call (800) 555-1234 for advising assistance. Reply STOP to opt out.',
  },
  LOW_VOLUME: {
    defaultMessage:
      "Example Inc Café: Your loyalty reward (free coffee) expires in 3 days. We're also introducing new spring menu items next week! Reply STOP to opt out.",
    includeLinksMessage:
      'Example Inc Café: Your loyalty reward (free coffee) expires in 3 days. Check our new menu at https://excafe.com/menu. Reply STOP to opt out.',
    includePhoneNumbersMessage:
      'Example Inc Café: Your loyalty reward (free coffee) expires in 3 days. Call us at (800) 555-1234 to place an order for pickup. Reply STOP to opt out.',
    fullMessage:
      'Example Inc Café: Your loyalty reward (free coffee) expires in 3 days. Check our new menu at https://excafe.com/menu or call us at (800) 555-1234 to place an order for pickup. Reply STOP to opt out.',
  },
  ACCOUNT_NOTIFICATION: {
    defaultMessage:
      'Example Inc Alert: A login attempt was made to your account from a new device on 2/26 at 3:15PM. If this was you, reply YES. If not, please contact support immediately. Reply STOP to opt out.',
    includeLinksMessage:
      "Example Inc Alert: A login attempt was made to your account from a new device on 2/26 at 3:15PM. Verify at https://example.com/verify if this wasn't you. Reply STOP to opt out.",
    includePhoneNumbersMessage:
      "Example Inc Alert: A login attempt was made to your account from a new device on 2/26 at 3:15PM. Call (800) 555-1234 immediately if this wasn't you. Reply STOP to opt out.",
    fullMessage:
      "Example Inc Alert: A login attempt was made to your account from a new device on 2/26 at 3:15PM. Verify at https://example.com/verify or call (800) 555-1234 immediately if this wasn't you. Reply STOP to opt out.",
  },
  DELIVERY_NOTIFICATION: {
    defaultMessage:
      'Example Inc: Your package #TN4567890 has been delivered to your front door at 2:45PM. Thank you for choosing our delivery service! Reply STOP to opt out.',
    includeLinksMessage:
      'Example Inc: Your package #TN4567890 has been delivered to your front door at 2:45PM. View delivery photo: https://ex.io/p/TN4567890. Reply STOP to opt out.',
    includePhoneNumbersMessage:
      'Example Inc: Your package #TN4567890 has been delivered to your front door at 2:45PM. Call (800) 555-1234 with questions. Reply STOP to opt out.',
    fullMessage:
      'Example Inc: Your package #TN4567890 has been delivered to your front door at 2:45PM. View delivery photo: https://ex.io/p/TN4567890 or call (800) 555-1234 with questions. Reply STOP to opt out.',
  },
}
