import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import {
  AdminComplianceApi,
  IParamsCreatePublishTenDLC,
  IParamsCreateRejectTenDLC,
  IResponseAdminTenDLCById,
} from 'entities/Admin/compliance'
import { IResponseAdminOrganizationById } from 'entities/Admin'
import { complianceStore } from 'entities/Compliance'
import { adminTenDLCBusinessProfileStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BusinessProfile/store/adminTenDLCBusinessProfileStore'
import { adminTenDLCBrandStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BrandInformation/store/adminTenDLCBrandStore'
import { adminTenDLCCampaignStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/store/adminTenDLCCampaignStore'
import { ReviewContent } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/ReviewContent/ReviewContent'
import { RejectContent } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/RejectContent/RejectContent'
import { TenDLCModalContent } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/ModalContent/TenDLCModalContent'

class AdminTenDLCDetailStore {
  constructor() {
    makeAutoObservable(this)
  }
  id: number | null = null
  loading = false
  data: IResponseAdminTenDLCById | null = null

  idReviewModal = ''
  idRejectModal = ''
  idDetailModal = ''

  organizationById: IResponseAdminOrganizationById | null = null

  get isHasUnsavedChanges() {
    return (
      adminTenDLCBusinessProfileStore.hasChanges ||
      adminTenDLCBrandStore.hasChanges ||
      adminTenDLCCampaignStore.hasChanges
    )
  }

  get disabledReview() {
    return (
      adminTenDLCBusinessProfileStore.isEdit ||
      adminTenDLCBrandStore.isEdit ||
      adminTenDLCCampaignStore.isEdit
    )
  }

  get isActions() {
    return (
      adminTenDLCBusinessProfileStore.isForReview ||
      adminTenDLCBrandStore.isForReview ||
      adminTenDLCCampaignStore.isForReview
    )
  }

  reset = () => {
    adminTenDLCBusinessProfileStore.reset()
    adminTenDLCBrandStore.reset()
    adminTenDLCCampaignStore.reset()
    this.organizationById = null
    this.data = null
  }

  getData = async (noReset?: boolean) => {
    try {
      if (!this.id) {
        return
      }
      runInAction(() => {
        if (noReset) {
          return
        }
        this.reset()
        this.loading = true
      })
      complianceStore.loadOptInTenDLCTypes()

      const { data } = await AdminComplianceApi.getTenDLCById(this.id)

      this.setTenDLCData(data)
      this.setOrganization(data.organization)
    } catch (e) {
      if (this.id) {
        adminTenDLCBusinessProfileStore.setBusinessProfile(this.id)
        adminTenDLCBrandStore.setBrand(this.id)
        adminTenDLCCampaignStore.setCampaign(this.id)
      }

      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setTenDLCData = (data: IResponseAdminTenDLCById) => {
    this.data = data
    adminTenDLCBusinessProfileStore.setBusinessProfile(
      data.organization?.id,
      data.business_profile_info
    )
    adminTenDLCBrandStore.setBrand(data.organization?.id, data.brand)
    adminTenDLCCampaignStore.setCampaign(data.organization?.id, data.campaign)
  }

  setId = (id: number) => {
    this.id = id
    this.getData()
  }

  onRejectTenDLC = async (params: IParamsCreateRejectTenDLC) => {
    try {
      await AdminComplianceApi.createRejectTenDLC(params)
      await this.getData(true)
      modalStore.closeModal(this.idRejectModal)
    } catch (e) {
      console.error(e)
    }
  }

  onReviewTenDLC = async (params: IParamsCreatePublishTenDLC) => {
    try {
      await AdminComplianceApi.createPublishTenDLC(params)
      await this.getData(true)
      modalStore.closeModal(this.idReviewModal)
    } catch (e) {
      console.error(e)
    }
  }

  onOpenRejectModal = () => {
    this.idRejectModal = nanoid()
    modalStore.addModal({
      id: this.idRejectModal,
      title: 'Manual reject',
      pureContent: true,
      ModalContent: RejectContent,
      width: 480,
    })
  }

  onOpenReviewModal = () => {
    this.idReviewModal = nanoid()
    modalStore.addModal({
      id: this.idReviewModal,
      title: 'Review stage',
      pureContent: true,
      ModalContent: ReviewContent,
      width: 360,
    })
  }

  setOrganization = (organizationById: IResponseAdminOrganizationById) => {
    this.organizationById = organizationById
  }

  onOpenDetailModal = () => {
    this.idDetailModal = nanoid()
    modalStore.addModal({
      title: '10DLC',
      ModalContent: TenDLCModalContent,
      id: this.idDetailModal,
      pureContent: true,
      width: 1200,
      paddingTopModal: 24,
    })
  }
}

export const adminTenDLCDetailStore = new AdminTenDLCDetailStore()
