// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fUeX4KDBQX_UNTw0E4Cs{padding-top:12px;display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/tollFree/ui/SummaryTollFree/SummaryTollFree/SummaryTollFreeItem/StatusesTF/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".statuses {\n  padding-top: 12px;\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statuses": "fUeX4KDBQX_UNTw0E4Cs"
};
export default ___CSS_LOADER_EXPORT___;
