import { makeAutoObservable } from 'mobx'
import { type IResponseCampaignAnalytics } from 'entities/Campaign'

export class CampaignStatistics {
  statistics
  constructor(statistics: IResponseCampaignAnalytics) {
    this.statistics = statistics
    makeAutoObservable(this)
  }
  syncOrigin = (statistics: IResponseCampaignAnalytics) => {
    this.statistics = statistics
  }

  get steps_count() {
    return this.statistics.steps_count
  }

  get enrolled_contacts_count() {
    return this.statistics.enrolled_contacts_count
  }
  get enrolled_contacts_percent() {
    return this.statistics.enrolled_contacts_percent
  }

  get in_progress() {
    return this.statistics.in_progress_count
  }
  get in_progress_percent() {
    return this.statistics.in_progress_percent
  }

  get completed() {
    return this.statistics.completed_count
  }
  get completed_percent() {
    return this.statistics.completed_percent
  }

  get unsubscribed() {
    return this.statistics.unsubscribed_count
  }
  get unsubscribed_percent() {
    return this.statistics.unsubscribed_percent
  }

  get stopped_by_user() {
    return this.statistics.stopped_by_user_count
  }
  get stopped_by_user_percent() {
    return this.statistics.stopped_by_user_percent
  }
}
