// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z4r84L4x5I7o4f1HFRvc .SWYpzkWqDBBU1Dmo60qg{padding:11px 8px 11px 11px}.z4r84L4x5I7o4f1HFRvc .N0P97m0mkoSkeNt_r79m{margin-left:4px;padding:0;font-size:13px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/tollFree/pages/Numbers/ui/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,0BAAA,CAGF,4CACE,eAAA,CACA,SAAA,CACA,cAAA","sourcesContent":[".wrap {\n  .select {\n    padding: 11px 8px 11px 11px;\n  }\n\n  .link {\n    margin-left: 4px;\n    padding: 0;\n    font-size: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "z4r84L4x5I7o4f1HFRvc",
	"select": "SWYpzkWqDBBU1Dmo60qg",
	"link": "N0P97m0mkoSkeNt_r79m"
};
export default ___CSS_LOADER_EXPORT___;
