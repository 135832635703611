import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'shared/ui'
import { availableHeight } from 'shared/lib'
import { FiltersAction, FiltersItems, filtersStore } from 'features/DropdownFilter'

const DropdownFilter = observer(() => {
  const [toggle, setToggle] = React.useState(false)
  const { loadFilters, filters, loading, filter, reset } = filtersStore

  useEffect(() => {
    loadFilters()

    return () => {
      reset()
    }
  }, [])

  return (
    <Dropdown
      ariaLabel={'DropdownFilter'}
      icon={'chatLayout'}
      placement={'bottom-start'}
      typeButton={'button'}
      tooltipProps={{
        label: 'Chat view',
        placement: 'bottom',
      }}
      items={[...FiltersItems(filters)]}
      onChange={(item) => {
        FiltersAction(item)
      }}
      buttonProps={{
        text: filter?.label,
        isRight: true,
        icon: toggle ? 'chevronUp' : 'chevronDown',
        typeBtn: 'text',
        size: 'medium',
      }}
      onToggle={(status) => {
        setToggle(status)
      }}
      disabled={loading}
      PopperProps={{
        modifiers: [availableHeight('DropdownList_Items')],
      }}
    />
  )
})

export { DropdownFilter }
