// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p2IeQ1DigqHBGpjC03Bw{color:var(--content-negative-primary)}.cSsuk5BDbGtCBfTp8ib3{display:flex;gap:0}.cSsuk5BDbGtCBfTp8ib3>div{background:var(--background-primary-inverted-primary)}.cSsuk5BDbGtCBfTp8ib3>div:nth-child(2){margin-left:-6px;z-index:2;box-shadow:0 0 0 1.5px #fff;border-radius:50%}.cSsuk5BDbGtCBfTp8ib3>div:nth-child(3){border-radius:50%;margin-left:-6px;z-index:3;box-shadow:0 0 0 1.5px #fff}.cSsuk5BDbGtCBfTp8ib3>div:nth-child(n+4){display:none}.dO0SYh8iedLpsOyLxAxl{display:flex;align-items:center;gap:8px;height:32px}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/Widget/WidgetStatus/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CAGF,sBACE,YAAA,CACA,KAAA,CAEA,0BACE,qDAAA,CAGF,uCACE,gBAAA,CACA,SAAA,CACA,2BAAA,CACA,iBAAA,CAEF,uCACE,iBAAA,CACA,gBAAA,CACA,SAAA,CACA,2BAAA,CAEF,yCACE,YAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA","sourcesContent":[".warning {\n  color: var(--content-negative-primary);\n}\n\n.group {\n  display: flex;\n  gap: 0;\n\n  & > div {\n    background: var(--background-primary-inverted-primary);\n  }\n\n  & > div:nth-child(2) {\n    margin-left: -6px;\n    z-index: 2;\n    box-shadow: 0 0 0 1.5px #ffffff;\n    border-radius: 50%;\n  }\n  & > div:nth-child(3) {\n    border-radius: 50%;\n    margin-left: -6px;\n    z-index: 3;\n    box-shadow: 0 0 0 1.5px #ffffff;\n  }\n  & > div:nth-child(n + 4) {\n    display: none;\n  }\n}\n\n.box {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "p2IeQ1DigqHBGpjC03Bw",
	"group": "cSsuk5BDbGtCBfTp8ib3",
	"box": "dO0SYh8iedLpsOyLxAxl"
};
export default ___CSS_LOADER_EXPORT___;
