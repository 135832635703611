import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessageDeletedDraft } from 'entities/Conversation/api/types'
import { conversationStore } from 'entities/Conversation'

const MessageDeletedDraft = {
  handle: (data: IResponseEventMessageDeletedDraft) => {
    conversationStore.deleteItemDraft(data.conversation.id)
  },
}

export const EventMessageDeletedDraft = {
  subscribe: () => websocket.on(WebsocketEvent.MessageDeletedDraft, MessageDeletedDraft.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.MessageDeletedDraft, MessageDeletedDraft.handle),
}
