import { createContext, useContext } from 'react'
import { type ChatbotLayoutStore } from 'pages/chatbot/store/chatbotLayoutStore'

export const ChatbotLayoutContext = createContext<ChatbotLayoutStore | null>(null)

export const useChatbotLayoutContext = () => {
  const context = useContext(ChatbotLayoutContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ChatbotLayoutContext')

  return context
}
