import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { MenuItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { integrationsStore } from 'entities/Integrations'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useContactsMenuContext } from 'widgets/ContactsMenu/context/contactsMenuContext'
import { SegmentMenuItem } from 'widgets/ContactsMenu/ui/SegmentMenuItem/SegmentMenuItem'
import styles from './styles.module.scss'

export const SegmentMenu = observer(() => {
  const contactsMenuStore = useContactsMenuContext()
  const {
    segmentsAll,
    segments,
    activeSegment,
    resetActive,
    segmentsSearch,
    setSegmentsSearch,
    contactsTableStore,
    segmentModalStore,
    hubspotLists,
    hubspotListsSearch,
    setHubspotListsSearch,
    favorites,
    favoritesSearch,
    setFavoritesSearch,
    contactsFilterStore,
  } = contactsMenuStore
  const { hasChanges, setHasChanges } = contactsFilterStore.segmentBox
  const [isAllContactsActive, setIsAllContactsActive] = useState<boolean>(false)

  useEffect(() => {
    integrationsStore.fetchIntegrations()
    contactsTableStore.reactionFilters()

    return () => {
      contactsMenuStore.dispose()
    }
  }, [])

  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    const isActiveAllContacts =
      (pathname === '/contacts' || pathname.startsWith('/contacts?')) && !activeSegment

    setIsAllContactsActive(isActiveAllContacts)
  }, [location])

  const navToAllContacts = () => {
    resetActive()
    contactsTableStore.setLoadingContacts(true)
    contactsTableStore.debounceLoadContacts()
    contactsFilterStore.onOpen(false)
    setHasChanges(false)

    if (uiStore.navigate) {
      uiStore.navigate('/contacts')
    }
  }

  return (
    <>
      <MenuItem
        key={'all_contacts'}
        type={'default'}
        title='All contacts'
        icon='userFilled'
        className={styles.allContacts}
        active={isAllContactsActive}
        onClick={() => {
          const newFilterMode = activeSegment?.id === -1
          !newFilterMode && hasChanges
            ? segmentModalStore?.openAskIfSaveModal({
                onToggle: navToAllContacts,
                noSetActive: true,
                filtersList: contactsTableStore.noEmptyFilters,
              })
            : navToAllContacts()
        }}
      />
      <div className={styles.folders}>
        {featureFlagsStore.hubspot_pin_list ? (
          <>
            <SegmentMenuItem
              placeholder={'Search favorites'}
              title={'Favorites'}
              segments={favorites}
              search={favoritesSearch}
              setSearch={setFavoritesSearch}
            />

            <SegmentMenuItem
              placeholder={'Search segments'}
              title={'Segments'}
              segments={segments}
              search={segmentsSearch}
              setSearch={setSegmentsSearch}
            />

            <SegmentMenuItem
              placeholder={'Search hubspot lists'}
              title={'HubSpot lists'}
              segments={hubspotLists}
              search={hubspotListsSearch}
              setSearch={setHubspotListsSearch}
            />
          </>
        ) : (
          <SegmentMenuItem
            placeholder={'Search segments'}
            title={'Segments'}
            segments={segmentsAll}
            search={segmentsSearch}
            setSearch={setSegmentsSearch}
          />
        )}
      </div>
    </>
  )
})
