import { ReactNode, useState } from 'react'
import { Collapse as CollapseMui } from '@mui/material'
import classNames from 'classnames'
import { Icon } from 'shared/ui/Icon/Icon'
import { TextStylesType, Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

export interface ICollapseProps {
  isOpen?: boolean
  opened?: boolean
  children: ReactNode
  title: ReactNode
  titleVariant?: TextStylesType
  type?: 'withHover' | 'init'
  headerClassName?: string
  leftContent?: (isOpen: boolean) => ReactNode
  onClick?: (opened: boolean) => void
  className?: string
}

export const Collapse = ({
  children,
  isOpen = true,
  title,
  titleVariant = 'body-md-medium',
  headerClassName,
  leftContent,
  type,
  onClick,
  opened,
  className,
}: ICollapseProps) => {
  const [localOpen, setLocalOpen] = useState(isOpen)
  const open = onClick ? !!opened : localOpen

  const handleClick = () => {
    if (onClick) {
      onClick(!open)
    } else {
      setLocalOpen(!open)
    }
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div
        className={classNames(styles.header, headerClassName, {
          [styles.withHover]: type === 'withHover',
          [styles.isOpen]: open,
        })}
        onClick={handleClick}
      >
        {leftContent?.(open)}
        <Typography variant={titleVariant} ariaLabel={'Collapse_Title'}>
          {title}
        </Typography>
        <Icon
          icon={open ? 'chevronUp' : 'chevronDown'}
          color='var(--gray-60)'
          className={styles.toggleIcon}
        />
      </div>

      <CollapseMui in={open}>
        <div className={styles.collapseContent}>{children}</div>
      </CollapseMui>
    </div>
  )
}
