import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { FullPageWrapper, SpinnerLoader } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { useIntegrationDetailsContext } from 'pages/settings/pages/integrations/context/integrationDetailsContext'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'
import { SettingsRoutes } from 'pages/settings/route/type'
import { IntegrationHubspotSettings } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettings'

export const IntegrationSettings = observer(() => {
  const { integration, isLoading } = useIntegrationDetailsContext()

  if (!integration) return null
  if (!integration.isSettingsAvailable) {
    uiStore.changeRouteViaVue({
      path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${integration.key}/${IntegrationsRoutes.description}`,
      method: 'replace',
    })
  }

  const Component = useMemo(() => {
    switch (integration.key) {
      case 'hubspot':
        return IntegrationHubspotSettings
      default: {
        return function EmptyHolder() {
          return <div>${integration.name} settings page not exist</div>
        }
      }
    }
  }, [integration])

  return (
    <FullPageWrapper>
      {isLoading ? <SpinnerLoader /> : <Component integration={integration} />}
    </FullPageWrapper>
  )
})
