import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DropdownContent,
  DropdownItem,
  dropDownStore,
  DropdownTextInput,
  Scrollbar,
  SpinnerLoader,
} from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { IIntegrationDropdownItem } from 'widgets/IntegrationDropdown/store/types'
import { useIntegrationDropdownContext } from '../context/integrationDropdownContext'
import styles from './styles.module.scss'

type IIntegrationContentProps = {
  dropdownId: string
}

export const IntegrationContent: FC<IIntegrationContentProps> = observer(({ dropdownId }) => {
  const {
    setDropdownId,
    search,
    setIntegrationSearch,
    integrationsLoading,
    integrationItems,
    setIntegration,
    hideDropDown,
  } = useIntegrationDropdownContext()

  useEffect(() => {
    setDropdownId(dropdownId)

    return () => {
      setIntegrationSearch('')
    }
  }, [])

  if (integrationsLoading) {
    return (
      <DropdownContent>
        <div className={styles.loader}>
          <SpinnerLoader size={20} />
        </div>
      </DropdownContent>
    )
  }

  return (
    <DropdownContent noPadding>
      <DropdownTextInput
        textFieldProps={{
          value: search,
          onChange: setIntegrationSearch,
          InputProps: {
            placeholder: 'Search',
          },
        }}
      />

      <Scrollbar
        autoHeight
        autoHeightMin={0}
        autoHeightMax={208}
        onScroll={() => {
          dropDownStore.allHide(dropdownId)
        }}
      >
        <DropdownContent>
          {integrationItems.map((integrationItem) => (
            <DropdownItem
              key={integrationItem.id}
              item={integrationItem}
              onChange={(item) => {
                const integrationItem = item as IIntegrationDropdownItem
                if (integrationItem.data?.is_connected === false) {
                  hideDropDown()
                  uiStore.changeRoute({
                    path: '/settings/integrations',
                    type: 'vue',
                  })
                  return
                }
                setIntegration(integrationItem)
              }}
            />
          ))}
          {!integrationItems.length && <div className={styles.noResult}>No results found</div>}
        </DropdownContent>
      </Scrollbar>
    </DropdownContent>
  )
})
