import PusherBatchAuthorizer from 'pusher-js-auth'
import Pusher, { Options, AuthorizerOptions, PresenceChannel, Channel } from 'pusher-js'
import { makeAutoObservable } from 'mobx'
import { PUSHER_DRIVER, GET_API_URL, SOKETI_KEY, SOKETI_HOST, SOKETI_CLUSTER } from 'shared/config'
import { IWebsocketAction } from './type'

export * from './type'

interface ISettingsWebsocket {
  SHOULD_LOG: boolean
  KEY: string
}

interface IOptions extends Options, AuthorizerOptions {
  broadcaster?: string
}

class Websocket {
  accessToken?: string | null = null
  socket?: Pusher | null = null
  settings: ISettingsWebsocket
  channels: Map<string, PresenceChannel | Channel> = new Map()

  constructor() {
    this.settings = {
      SHOULD_LOG: false,
      KEY: SOKETI_KEY,
    }
    makeAutoObservable(this)
  }

  connect(accessToken?: string | null, onConnected?: (websocket: Websocket) => void) {
    return new Promise<void>((resolve, reject) => {
      if (this.socket?.connection?.state === 'connected') {
        resolve()

        return
      }

      if (!this.socket) {
        this.accessToken = accessToken

        Pusher.logToConsole = this.settings.SHOULD_LOG
        Pusher.log = this.log
        this.socket = new Pusher(this.settings.KEY, this.config)
        this.socket.connection.bind('error', (err: never) => {
          this.onError(err)
          reject(err)
        })
        this.socket.connection.bind('connected', () => {
          this.onConnected()
          if (onConnected) {
            onConnected(this)
          }
          resolve()
        })
        this.socket.connection.bind('failed', (status: never) => {
          console.error('Socket failed', status)
          window.location.reload()
        })
      }
      return this.socket
    })
  }

  get isConnected() {
    return Boolean(this.socket)
  }

  get config(): IOptions {
    const options: IOptions = {
      authorizer: PusherBatchAuthorizer,
      authDelay: 200,
      broadcaster: 'pusher',
      authEndpoint: `${GET_API_URL()}broadcasting/auth?connection=${PUSHER_DRIVER}`,
    }

    if (this.accessToken) {
      options.auth = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      }
    }

    if (PUSHER_DRIVER === 'soketi') {
      options.wsHost = SOKETI_HOST
      options.enabledTransports = ['ws', 'wss']
      options.disableStats = true
      options.forceTLS = false
    }

    if (PUSHER_DRIVER === 'pusher') {
      options.cluster = SOKETI_CLUSTER
    }

    return options
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  subscribe(channelName?: string) {
    if (!channelName) return
    if (!this.socket) {
      setTimeout(() => {
        this.subscribe(channelName)
      }, 500)

      return
    }

    this.channels.set(channelName, this.socket.subscribe(channelName))
  }

  unsubscribe(channelName: string) {
    if (!this.socket) {
      setTimeout(() => {
        this.unsubscribe(channelName)
      }, 500)

      return
    }

    const channel = this.getChannel(channelName)

    if (channel) {
      this.socket.unsubscribe(channelName)
      this.channels.delete(channelName)
    }
  }

  getChannel(channelName: string) {
    return this.channels.get(channelName)
  }

  on<E extends keyof IWebsocketAction>(event: E, callback: (data: IWebsocketAction[E]) => void) {
    if (!this.socket) console.error('Socket does not exist:', this)
    this.socket?.bind(event, callback)

    return () => this.off(event, callback)
  }

  off<E extends keyof IWebsocketAction>(event: E, callback?: (data: IWebsocketAction[E]) => void) {
    if (!this.socket) console.error('Socket does not exist:', this)
    this.socket?.unbind(event, callback)
  }

  sendEvent = <T>(channelName: string, event: string, data: T) => {
    if (!this.socket) return
    const channel = this.getChannel(channelName)
    if (channel) {
      channel.trigger(event, data)
    }
  }

  onError = (err: never) => {
    this.log('Error: ', err)
  }

  onConnected = () => {
    this.log('Connected: ')
  }

  log = (message: string, ...args: never[]) => {
    if (this.settings.SHOULD_LOG) {
      console.log(`[WS] ${message}`, args)
    }
  }
}

export const websocket = new Websocket()
